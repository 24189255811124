import { useMemo } from 'react'
import {
  CheckoutCombinableReceivable,
  CheckoutReceivables,
  ReceivablesByProduct,
} from 'src/escolas/contexts/checkoutContext'
import { formatCentsToReal } from '@/shared/utils'
import NegotiationFinancialSummary from './NegotiationFinancialSummary'
import { uniq } from 'ramda'
import { ContentContainer, TotalValue } from './styles'
import { Text } from '@gravity/text'
import { Dialog } from '@gravity/dialog'
import { Separator } from '@gravity/separator'

export type InstallmentNegotiationDialogProps = {
  combinedReceivables: CheckoutCombinableReceivable[]
  creditCardFee: number
  isOpen: boolean
  onClose: () => void
  totalAmount: number
}
const InstallmentNegotiationDialog = ({
  isOpen,
  onClose,
  combinedReceivables,
  creditCardFee = 0,
  totalAmount,
}: InstallmentNegotiationDialogProps) => {
  const receivablesByStudentAndProduct = useMemo(() => {
    const checkoutReceivables: CheckoutReceivables[] = []

    if (combinedReceivables?.length) {
      let listOfStudents = uniq(
        combinedReceivables.map(x => {
          const container = {
            student: x.student,
            student_id: x.student_id,
          }
          return container
        })
      )
      let listOfProducts = uniq(
        combinedReceivables.map(x => {
          const container = {
            product: x.product,
            product_id: x.product_id,
          }
          return container
        })
      )
      let newList = combinedReceivables
      if (listOfStudents?.length && listOfProducts?.length) {
        listOfStudents = listOfStudents.sort((a, b) => a.student.localeCompare(b.student))
        listOfProducts = listOfProducts.sort((a, b) => a.product.localeCompare(b.product))

        for (const student of listOfStudents) {
          const studentReceivable: CheckoutReceivables = {
            student_id: null,
            student_name: '',
            products: [],
          }
          for (const product of listOfProducts) {
            const productReceivable: ReceivablesByProduct = {
              product_id: null,
              product_name: '',
              receivables: [],
            }
            for (const receivable of newList) {
              if (
                receivable?.product === product?.product &&
                receivable?.student_id === student?.student_id
              ) {
                productReceivable.receivables.push(receivable)
                newList = newList.filter(x => x.id !== receivable?.id)
              }
            }
            if (productReceivable.receivables.length > 0) {
              productReceivable.product_name = product?.product
              productReceivable.product_id = product?.product_id

              studentReceivable.products.push(productReceivable)
            }
          }
          if (studentReceivable.products.length > 0) {
            studentReceivable.student_name = student?.student
            studentReceivable.student_id = student?.student_id

            checkoutReceivables.push(studentReceivable)
          }
        }
      }
    }
    return checkoutReceivables
  }, [combinedReceivables])

  return (
    <Dialog
      open={isOpen}
      backdrop
      title="Detalhes do pagamento"
      size={2}
      onOpenChange={onClose}
      content={
        <ContentContainer>
          <NegotiationFinancialSummary
            combinedReceivables={receivablesByStudentAndProduct}
            creditCardFee={creditCardFee}
          />
          <Separator className="mt-4 mb-2" color="neutral-2" />
          <TotalValue>
            <Text variant="subtitle-regular">Total</Text>
            <Text variant="subtitle-regular">{formatCentsToReal(totalAmount)}</Text>
          </TotalValue>
        </ContentContainer>
      }
    />
  )
}

export default InstallmentNegotiationDialog
