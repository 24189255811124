import sanitizeHtml from 'sanitize-html'
import { DialogPrimitives } from '@gravity/dialog'
import { Button } from '@gravity/button'
import { Text } from '@gravity/text'
import { UserOutline } from '@gravity/icons'

import { useTrackNotificationModalEvents } from '../NotificationModal/useTrackNotificationModalEvents'
import { useFormatNotificationLink } from '@monorepo/notification/hooks/useFormatNotificationLink'

import { formatDate } from '@/shared/utils'

import type { Category } from '@monorepo/notification/types'

import { NotificationHeading, RichTextContainer } from './styles'

type NotificationDetailsDialogProps = {
  action?: { label: string; url: string }
  category: Category
  contentHtml: string
  date: Date
  isOpen: boolean
  onClose: () => void
  title: string
}

export const NotificationDetailsDialog = ({
  category,
  date,
  title,
  contentHtml,
  action,
  isOpen,
  onClose,
}: NotificationDetailsDialogProps) => {
  const { trackModalClose, trackActionButtonClick } = useTrackNotificationModalEvents()
  const { formattedLinkUrl } = useFormatNotificationLink(action?.url ?? '')

  const formattedDate = formatDate(date, 'dddd, DD/MM/YYYY, HH[h]mm')

  const handleCloseModal = () => {
    trackModalClose()
    onClose()
  }

  const handleNotificationAction = (notificationTitle: string) => {
    window.location.href = formattedLinkUrl

    trackActionButtonClick(notificationTitle)
  }

  return (
    <DialogPrimitives.Root open={isOpen} onOpenChange={open => !open && handleCloseModal()}>
      <DialogPrimitives.Portal>
        <DialogPrimitives.Overlay backdrop />

        <DialogPrimitives.Content
          size={3}
          title={category[0].toUpperCase() + category.substring(1)}
          actionButton={
            !!action && (
              <Button
                color="accent"
                variant="solid"
                onClick={() => handleNotificationAction(title)}
              >
                {action.label}
              </Button>
            )
          }
        >
          <div>
            <NotificationHeading>
              <div className="avatar">
                <UserOutline size="sm" />
              </div>

              <div className="metadata">
                <Text variant="body-2-medium">isaac comunica</Text>
                <Text variant="body-2-regular" className="notification-date">
                  {formattedDate}
                </Text>
              </div>
            </NotificationHeading>

            <Text variant="subtitle-medium">{title}</Text>

            <RichTextContainer
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(contentHtml, {
                  allowedTags: [
                    'p',
                    'div',
                    'li',
                    'h1',
                    'h2',
                    'h3',
                    'h4',
                    'h5',
                    'h6',
                    'div',
                    'ol',
                    'li',
                    'b',
                    'a',
                  ],
                }),
              }}
            />
          </div>
        </DialogPrimitives.Content>
      </DialogPrimitives.Portal>
    </DialogPrimitives.Root>
  )
}
