import { NotFound } from '@/shared/components/NotFound'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import { IrregularStudentTable } from './components/IrregularStudentTable'
import { useIrregularStudentsQuery } from './hooks/useIrregularStudentsQuery'
import { useIrregularityBigNumbersQuery } from '@/shared/hooks/queries/irregularities/useIrregularityBigNumbersQuery'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { usePagination } from '@/shared/hooks/usePagination'
import * as Styled from './styles'
import { Table } from '@gravity/table'
import { IrregularityBigNumbers } from './components/IrregularityBigNumbers'
import { Heading } from '@gravity/heading'
import { useEffect, useState } from 'react'
import { Text } from '@gravity/text'
import { SearchBar } from './components/SearchBar'
import { IrregularStatusFilter } from './components/IrregularStatusFilter'
import { useIrregularStudentsFilter } from './hooks/useIrregularStudentsFilter'
import { useIrregularStudentsEvents } from './hooks/useIrregularStudentsEvents'
import { EmptyState } from './components/EmptyState'
import { useLayout } from '@/shared/hooks/useLayout'
import { Option } from '@gravity/select'
import { useSchoolEnrollmentCycles } from '@/shared/hooks/useSchoolEnrollmentCycles'
import {
  DEFAULT_IRREGULAR_STUDENT_STATUS_FILTERS,
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  IRREGULARITY_PAGE_NAME,
} from './constants'
import { Grid, GridItem } from '@gravity/grid'
import { Error } from '@/shared/components/Error'
import { CircularLoadingPlaceholder } from '@/shared/components/CircularLoadingPlaceholder'

export const IrregularStudentEnrollments = () => {
  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)
  const { school } = useSelectedSchool()
  const { pagination, updatePaginationValue } = usePagination(DEFAULT_PAGE, DEFAULT_PER_PAGE)
  const { enrollmentCycleYears, activeEnrollmentCycleYear } = useSchoolEnrollmentCycles()
  const [studentName, setStudentName] = useState<string>('')

  const { irregularStudentsFilter, updateFilter } = useIrregularStudentsFilter(
    DEFAULT_IRREGULAR_STUDENT_STATUS_FILTERS,
    activeEnrollmentCycleYear ? String(activeEnrollmentCycleYear) : ''
  )

  useEffect(() => {
    if (activeEnrollmentCycleYear) {
      updateFilter({
        ...irregularStudentsFilter,
        referenceYear: String(activeEnrollmentCycleYear),
      })
    }
  }, [activeEnrollmentCycleYear])

  const { data, isLoading, isError, isSuccess } = useIrregularStudentsQuery({
    referenceYear: irregularStudentsFilter.referenceYear,
    schoolId: school?.id ?? '',
    studentName: studentName,
    status: irregularStudentsFilter.irregularStatus,
    pagination: {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
    },
  })

  const {
    data: dataEmptyState,
    isLoading: isLoadingEmptyState,
    isError: isErrorEmptyState,
  } = useIrregularStudentsQuery({
    referenceYear: irregularStudentsFilter.referenceYear,
    schoolId: school?.id ?? '',
    pagination: {
      page: 1,
      per_page: 1,
    },
  })

  const {
    data: dataBigNumbers,
    isLoading: isLoadingBigNumbers,
    isError: isErrorBigNumbers,
  } = useIrregularityBigNumbersQuery({
    referenceYear: irregularStudentsFilter.referenceYear,
    schoolId: school?.id ?? '',
  })

  const {
    sendPageViewEvent,
    sendClickSelectReferenceYearEvent,
    sendClickSearchBarEvent,
  } = useIrregularStudentsEvents()

  useEffect(sendPageViewEvent, [])

  useLayout({ enableSideMenu: true, enableHeader: true, headerTitle: IRREGULARITY_PAGE_NAME })

  if (!isIntegratedSchool) {
    return <NotFound />
  }

  const referenceYearOptions = enrollmentCycleYears?.reverse().map(
    (item): Option => ({
      value: item.toString(),
      label: item.toString(),
    })
  )

  const getStatus = () => {
    if (isError || isErrorBigNumbers || isErrorEmptyState) {
      return 'error'
    }

    if (isLoadingBigNumbers || isLoadingEmptyState) {
      return 'loading'
    }

    return 'success'
  }

  if (getStatus() === 'error') return <Error />
  if (getStatus() === 'loading') return <CircularLoadingPlaceholder />

  return (
    <div className="flex justify-center py-8 h-full">
      <Grid>
        <GridItem>
          <div className="flex gap-5">
            <Heading variant="heading-h3-medium">Pendências de registro</Heading>
            <Styled.StyledSelect
              options={referenceYearOptions}
              size={3}
              variant="surface"
              defaultValue={irregularStudentsFilter.referenceYear || referenceYearOptions[0].value}
              onValueChange={value => {
                updateFilter({ ...irregularStudentsFilter, referenceYear: value })
              }}
              onOpenChange={open => open && sendClickSelectReferenceYearEvent()}
            />
          </div>
        </GridItem>
        {dataEmptyState?.data?.length ? (
          <>
            <GridItem className="pb-6 pt-4">
              <Styled.SubtitleContainer>
                <Text variant="subtitle-regular">
                  {'Pendências de registro impedem que os alunos sejam registrados e os valores sejam ' +
                    'repassados para a sua instituição.'}
                </Text>
                <br />
                <Text variant="subtitle-regular">
                  As correções devem ser feitas no seu sistema.
                </Text>
              </Styled.SubtitleContainer>
            </GridItem>
            <IrregularityBigNumbers
              data={dataBigNumbers?.data}
              isLoading={isLoadingBigNumbers}
              isError={isErrorBigNumbers}
            />
            <GridItem className="pt-6">
              <Styled.SearchBarContainer>
                <SearchBar
                  initialValue={studentName}
                  setValue={setStudentName}
                  placeholder="Buscar por aluno"
                  onClick={sendClickSearchBarEvent}
                />
              </Styled.SearchBarContainer>
              <IrregularStatusFilter
                irregularStudentsFilter={irregularStudentsFilter}
                updateFilter={updateFilter}
              />
              <IrregularStudentTable data={isSuccess ? data?.data : []} isLoading={isLoading} />
              <Table.Root>
                <Table.Pagination
                  page={pagination.page}
                  itemsPerPage={pagination.itemsPerPage}
                  total={data?.pagination?.total ?? 0}
                  options={[
                    { label: '10 alunos por página', value: '10' },
                    { label: '15 alunos por página', value: '15' },
                    { label: '25 alunos por página', value: '25' },
                    { label: '50 alunos por página', value: '50' },
                  ]}
                  onChangePage={newPage => {
                    updatePaginationValue('page', newPage, true)
                    window.scrollTo(0, 0)
                  }}
                  onChangeItemsPerPage={newItensPerPage => {
                    updatePaginationValue('itemsPerPage', newItensPerPage, true)
                  }}
                />
              </Table.Root>
            </GridItem>
          </>
        ) : (
          <GridItem xl="4 / 10" lg="4 / 10" md="4 / 10" sm="2 / 8" xs={4}>
            <EmptyState referenceYear={irregularStudentsFilter.referenceYear} />
          </GridItem>
        )}
      </Grid>
    </div>
  )
}
