import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { Button } from '@gravity/button'
import { ArrowLeft } from '@gravity/icons'

import { useEnrollmentCampaignRedirect } from '@/shared/hooks/useEnrollmentCampaignRedirect'

import { useGetEnrollmentReportCampaignReport } from '../../hooks/queries/enrollmentReport'
import { PageStatusWrapper } from '@/shared/components/PageStatusWrapper'
import { CircularLoadingPlaceholder } from '@/shared/components/CircularLoadingPlaceholder'
import * as Styled from './styles'

export const EnrollmentCampaignReport = () => {
  const { goBack } = useHistory()
  const reportQuery = useGetEnrollmentReportCampaignReport()
  const [iframeStatus, setIframeStatus] = useState<typeof reportQuery.status | undefined>('loading')

  useEnrollmentCampaignRedirect()

  return (
    <PageStatusWrapper status={reportQuery.status}>
      <Styled.Header>
        <Button variant="ghost" iconStart={<ArrowLeft />} onClick={goBack}>
          Voltar
        </Button>
        <Styled.HeaderTextWrapper>
          <Heading variant="heading-h3-medium">Relatório de campanha de rematrícula</Heading>
          <Text variant="body-1-regular">
            Acompanhe os detalhes de todas as campanhas por aluno. Este relatório é atualizado
            diariamente a partir das 08:00, no horário de Brasília.
          </Text>
        </Styled.HeaderTextWrapper>
      </Styled.Header>

      {iframeStatus === 'loading' ? <CircularLoadingPlaceholder /> : null}

      <Styled.Iframe
        src={reportQuery.data?.url}
        style={{ display: iframeStatus === 'success' ? 'block' : 'none' }}
        onLoad={() => setIframeStatus('success')}
      />
    </PageStatusWrapper>
  )
}
