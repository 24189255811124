import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${props => props.theme.gravity.spacing[4]};
  overflow: hidden;
  position: fixed;
  width: 100%;
  top: -152px;
  z-index: 20;
  transform: translateY(0);
  transition: transform 0.3s ease-in-out;
  background-color: ${props => props.theme.gravity.colors['colors-background-neutral-1']};
  border-bottom: 1px solid ${props => props.theme.gravity.colors['colors-border-neutral-2']};
  height: 152px;

  margin-left: -7rem;

  .tabs-container {
    width: 100%;
    overflow-x: auto;
  }

  @media (max-width: 1260px) {
    padding-left: 4rem;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.gravity.spacing[8]};
`
