import { useEffect, useMemo } from 'react'

import { Callout } from '@gravity/callout'
import { Grid, GridItem } from '@gravity/grid'
import { Heading } from '@gravity/heading'

import { useGuardiansQuery } from './hooks/useGuardiansQuery'
import { useQuery } from '@/shared/hooks/useQuery'
import { useGuardiansQueryParams } from '../hooks/useGuardiansQueryParams'
import { UserGuidingGuidesIds, useUserGuidingTrigger } from '@/shared/hooks/useUserGuidingTrigger'
import { useLayout } from '@/shared/hooks/useLayout'
import { SortOrder } from '@/shared/hooks/useOrdering/types'

import { ListGuardiansTable } from './components'
import { GuardiansQueryParamsNameEnum } from '../constants'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useGetPaymentDisputes } from '@/shared/hooks/queries/usePaymentDisputeQueries'
import { PaymentDisputeSummaryState } from '@/shared/models/enums/PaymentDisputeSummaryState.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventPageName } from '@/shared/models/enums/EventPageName.enum'
import { EventIdentifierName } from '@/shared/models/enums/EventIdentifierName.enum'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { GuardianBasicInfo } from './types'
import { formatSearchTerm, getFilterSearchParam } from './utils'

const ListGuardians = () => {
  const { school } = useSelectedSchool()
  const { setOnQueryParam } = useQuery()
  const { canUseAutoTrigger } = useUserGuidingTrigger()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  useLayout({ enableSideMenu: true, enableHeader: true, headerTitle: 'Responsáveis' })

  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  const {
    page,
    itemsPerPage,
    sortBy,
    sortOrder,
    filters,
    updateFilters,
    updateOrderingValue,
    searchTerm,
    setSearchTerm,
  } = useGuardiansQueryParams({})

  const formattedSearchTerm = formatSearchTerm(searchTerm ?? '')

  // This is a workaround to avoid pagination issues when searching for a guardian
  const searchPage = searchTerm ? 1 : page

  const { guardians, isFetchGuardiansLoading } = useGuardiansQuery({
    schoolId: school?.id ?? '',
    pagination: {
      page: searchPage,
      per_page: itemsPerPage,
      sort_by: sortBy,
      sort_order: sortOrder,
    },
    filter: {
      [getFilterSearchParam(searchTerm ?? '')]: formattedSearchTerm,
      ...filters,
    },
  })

  const { paymentDisputesResponse } = useGetPaymentDisputes({
    schoolId: school?.id ?? '',
    status: [PaymentDisputeSummaryState.NOT_STARTED, PaymentDisputeSummaryState.STARTED],
  })

  const quantityOfPaymentDisputes = useMemo(() => {
    const uniqueGuardians = paymentDisputesResponse?.data?.reduce((acc, record) => {
      acc.add(record.guardian.id)
      return acc
    }, new Set())

    return uniqueGuardians ? uniqueGuardians.size : 0
  }, [paymentDisputesResponse?.data])

  const paymentDisputeCalloutText = () => {
    if (quantityOfPaymentDisputes === 1) {
      return 'Você tem 1 responsável que alega cobrança indevida em alguma fatura. Analise as contestações e faça correções, caso necessário.'
    }

    return `Você tem ${quantityOfPaymentDisputes} responsáveis que alegam cobrança indevida em alguma fatura. Analise as contestações e faça correções, caso necessário.`
  }

  const handleCalloutPaymentDisputeClick = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.PAYMENT_DISPUTE,
        name: EventDispatcherEvents.LINK_CLICKED,
        pageName: EventPageName.SEARCH_GUARDIANS,
        identifierName: EventIdentifierName.SEE_PAYMENT_DISPUTES_PAGE,
        customProperties: {
          $link_name: 'Ver cobranças indevidas',
          $payment_disputes_quantity: quantityOfPaymentDisputes,
        },
      })
    }
  }

  const showCallout = quantityOfPaymentDisputes > 0

  useEffect(() => {
    if (quantityOfPaymentDisputes > 0 && isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.PAYMENT_DISPUTE,
        name: EventDispatcherEvents.ALERT_VIEWED,
        pageName: EventPageName.SEARCH_GUARDIANS,
        identifierName: EventIdentifierName.SHOWED_PAYMENT_DISPUTES_CALLOUT,
        customProperties: {
          $payment_disputes_quantity: quantityOfPaymentDisputes,
        },
      })
    }
  }, [quantityOfPaymentDisputes, isInitialized])

  useEffect(() => {
    if (canUseAutoTrigger) {
      setOnQueryParam(
        UserGuidingGuidesIds.LIST_GUARDIANS_PAGE,
        GuardiansQueryParamsNameEnum.guide,
        'replace'
      )
    }
  }, [])

  return (
    <div className="flex justify-center -mx-16">
      <Grid>
        {renderNewHeader && (
          <GridItem xl={12} lg={12} md={12} sm={8} xs={4}>
            <Heading variant="heading-h2-medium" className="pt-8">
              Responsáveis
            </Heading>
          </GridItem>
        )}
        {showCallout && (
          <GridItem xl={12} lg={12} md={12} sm={8} xs={4}>
            <Callout
              className="mt-10"
              text={paymentDisputeCalloutText()}
              linkLabel="Ver cobranças indevidas"
              href={`/${school?.slug}/cobrancas-indevidas`}
              onLinkClick={handleCalloutPaymentDisputeClick}
            />
          </GridItem>
        )}

        <GridItem xl={12} lg={12} md={12} sm={8} xs={4} className="pt-6">
          <ListGuardiansTable
            guardians={guardians?.data ?? ([] as GuardianBasicInfo[])}
            isLoading={isFetchGuardiansLoading}
            total={guardians?.pagination?.total}
            sortOrder={sortOrder}
            setSortOrder={(sortOrder: SortOrder) => updateOrderingValue('sortOrder', sortOrder)}
            setSearchQuery={setSearchTerm}
            searchQuery={searchTerm}
            filters={filters}
            updateFilters={updateFilters}
          />
        </GridItem>
      </Grid>
    </div>
  )
}

export { ListGuardians }
