import styled from 'styled-components'

import { Button } from '@gravity/button'

export const ButtonGroup = styled.div`
  display: flex;
  direction: column;
  align-items: center;
  margin-top: ${props => props.theme.gravity.spacing[10]};
  justify-content: space-between;
`

export const CheckBoxContainer = styled.div`
  display: flex;
  height: ${props => props.theme.gravity.spacing[5]};
  align-items: center;
  margin-top: ${props => props.theme.gravity.spacing[5]};
  gap: ${props => props.theme.gravity.spacing[2]};
`

export const GhostButton = styled(Button)`
  min-width: 186px;
`
