import styled from 'styled-components'

export const Wrapper = styled.footer`
  background: ${({ theme }) => theme.gravity.colors['colors-background-neutral-1']};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${({ theme }) => theme.gravity.spacing[6]} 0;
  display: flex;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.gravity.colors['colors-border-neutral-3']};
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.gravity.spacing[6]};
`

export const ButtonsWrapper = styled.div`
  gap: ${({ theme }) => theme.gravity.spacing[1]};
  display: flex;
`

export const FooterSpaceClearance = styled.div`
  padding-bottom: 136px;
`
