import { useState } from 'react'
import { ChoiceChip } from '@gravity/chip'

import type { FilterSearchProps, SearchCategoryOption } from './types'
import type { SearchCategories } from '@monorepo/school-home/services/school-home-search/types'

import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventPageName } from '@monorepo/school-home/models/EventPageName.enum'
import { EventIdentifierName } from '@monorepo/school-home/models/EventIdentifierName.enum'

import { Container } from './styles'

const SEARCH_OPTIONS: Record<SearchCategoryOption, SearchCategoryOption> = {
  all: 'all',
  students: 'students',
  guardians: 'guardians',
} as const

const SEARCH_CATEGORY_LABELS: Record<SearchCategoryOption, string> = {
  all: 'Todos',
  students: 'Alunos',
  guardians: 'Responsáveis',
}

export const FiltersSearch = ({ onChangeSelectedFilterCategory }: FilterSearchProps) => {
  const [selectedFilter, setSelectedFilter] = useState<SearchCategoryOption>('all')
  const { eventDispatcherClient } = useEventDispatcher()

  const handleFilterCategoryChange = (value: SearchCategoryOption) => {
    eventDispatcherClient.sendEvent({
      name: EventDispatcherEvents.BUTTON_CLICKED,
      scope: EventDispatcherEventScopes.SEARCH_HOME,
      pageName: EventPageName.SCHOOL_HOME,
      identifierName: EventIdentifierName.CLICK_ON_FILTER,
      customProperties: {
        $search_type: SEARCH_CATEGORY_LABELS[value],
      },
    })

    setSelectedFilter(value)

    if (value === 'all') {
      onChangeSelectedFilterCategory(
        (Object.keys(SEARCH_OPTIONS) as SearchCategoryOption[]).filter(
          item => item !== 'all'
        ) as SearchCategories[]
      )
      return
    }

    onChangeSelectedFilterCategory([value])
  }

  return (
    <Container>
      {Object.entries(SEARCH_OPTIONS).map(([, value]) => (
        <ChoiceChip
          key={value}
          selected={selectedFilter === value}
          onSelected={value => handleFilterCategoryChange(value as SearchCategoryOption)}
          text={SEARCH_CATEGORY_LABELS[value]}
          value={value}
          data-testid={value}
        />
      ))}
    </Container>
  )
}
