import { PayoutSubHeader } from '@monorepo/report/components/PayoutReportPageHeader'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { DatePicker, TileDisabledFunctionArgs } from '@monorepo/report/components/DatePicker'

import { useIsaacPayOutsourcedPaymentProvider } from '@/shared/hooks/useIsaacPayOutsourcedPaymentProvider'
import { findIsaacPayPayoutReportByDate } from '../../helpers'
import { IsaacPayPayoutReportHeaderProps } from './types'
import { SchoolReportingPeriod } from '@/shared/models/SchoolChargeOperation'
import { TopHeader } from '@/shared/components/TopHeader'

/**
 * Specialized header component for isaacPay payout report page
 *
 * @param props
 * @param props.isaacPayPayoutReportList List of payout reports
 * @param props.isLoadingIsaacPayPayoutReportList Indicates if loading state should be displayed for the picker
 * @param props.onChangeIsaacPayPayout Callback function called on change payout
 */
export const IsaacPayPayoutReportHeader = ({
  isaacPayPayoutReportList,
  currentViewRange,
  onChangeViewRange,
  onChangeReport,
  selectedDate,
  handleOpenDrawerSummary,
  setSelectedDate,
  payoutReportPeriod,
}: IsaacPayPayoutReportHeaderProps) => {
  const { school } = useSelectedSchool()

  const isOutsourcedProvider = useIsaacPayOutsourcedPaymentProvider(school)

  const isMonthlyViewEnabled = currentViewRange === SchoolReportingPeriod.monthly
  const datePickerTitleTemplate = isMonthlyViewEnabled ? 'MMMM [de] YYYY' : 'DD [de] MMMM [de] YYYY'

  // TODO: Bruno Mello - não mudar o payout aqui -> deixar pro componente de cima
  function handleChangePickerDate(date: Date) {
    setSelectedDate(date)

    if (isMonthlyViewEnabled || isOutsourcedProvider) {
      onChangeReport(null)
      return
    }

    const report = findIsaacPayPayoutReportByDate(isaacPayPayoutReportList, date) ?? null
    onChangeReport(report)
  }

  function checkIfDateShouldBeDisabled({ view, date }: TileDisabledFunctionArgs) {
    if (view !== 'month') return false

    const payout = findIsaacPayPayoutReportByDate(isaacPayPayoutReportList, date)

    return isOutsourcedProvider ? false : !payout
  }

  function getMinPickerDate(): Date {
    const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1)
    const hasPayouts = isaacPayPayoutReportList?.length > 0
    const createdAtFromSchoolChargeConfig = school?.school_charge_config?.created_at

    if (isOutsourcedProvider) {
      return createdAtFromSchoolChargeConfig
        ? new Date(createdAtFromSchoolChargeConfig)
        : firstDayOfYear
    }

    if (isMonthlyViewEnabled && hasPayouts) {
      return isaacPayPayoutReportList[0]?.started_at ?? firstDayOfYear
    }

    return firstDayOfYear
  }

  function getMaxPickerDate(): Date {
    const today = new Date()
    const hasPayouts = isaacPayPayoutReportList?.length > 0

    if (isOutsourcedProvider) return today
    if (!isMonthlyViewEnabled && hasPayouts)
      return isaacPayPayoutReportList[isaacPayPayoutReportList.length - 1]?.ended_at ?? today
    return today
  }

  return (
    <>
      <TopHeader backButtonLink={`/${school?.slug}/repasses`} showHelpButton={false} />
      <PayoutSubHeader
        linkButtonText="Ver resumo"
        onClickAtLinkButton={() => {
          handleOpenDrawerSummary()
        }}
        payoutReportPeriod={payoutReportPeriod}
        shouldShowToggle
        currentReportingPeriodView={currentViewRange}
        onChangeReportingPeriod={onChangeViewRange}
        title="Repasse"
        picker={
          <DatePicker
            valueTemplate={datePickerTitleTemplate}
            variation="ghost"
            maxDetail={isMonthlyViewEnabled ? 'year' : 'month'}
            minDetail="decade"
            minDate={getMinPickerDate()}
            maxDate={getMaxPickerDate()}
            onChange={handleChangePickerDate}
            tileDisabled={checkIfDateShouldBeDisabled}
            value={selectedDate}
          />
        }
      />
    </>
  )
}
