import { GridItem } from '@gravity/grid'
import { ContainerCard, LargeContainerCard, SmallContainerCard, StyledRows } from './styled'
import { Skeleton } from '@gravity/skeleton'

export const SkeletonCards = ({ cardsNumber }: { cardsNumber: number }) => {
  return (
    <>
      {Array.from({ length: cardsNumber }).map((_, index) => (
        <GridItem key={index} lg={4} md={4} sm={3} xs={1}>
          <ContainerCard>
            <StyledRows>
              <Skeleton radius={8} height={24} />
              <Skeleton radius={8} width={145} height={24} />

              {Array.from({ length: 4 }).map((_, index) => (
                <Skeleton key={index} radius={8} fullWidth height={24} />
              ))}
            </StyledRows>
          </ContainerCard>
        </GridItem>
      ))}
    </>
  )
}

export const SkeletonLargeCard = () => {
  return (
    <GridItem lg={8} md={8} sm={5} xs={3}>
      <LargeContainerCard>
        <Skeleton radius={8} fullWidth height={38} />
        <StyledRows>
          {Array.from({ length: 4 }).map((_, index) => (
            <Skeleton key={index} radius={8} fullWidth height={24} />
          ))}
        </StyledRows>
        <Skeleton radius={8} width={353} height={24} />
      </LargeContainerCard>
    </GridItem>
  )
}

export const SkeletonSmallCard = () => {
  return (
    <>
      <GridItem lg={4} md={4} sm={3} xs={1}>
        <SmallContainerCard>
          <Skeleton radius={8} width={214} height={24} />
          <StyledRows>
            {Array.from({ length: 4 }).map((_, index) => (
              <Skeleton key={index} radius={8} width={214} height={24} />
            ))}
          </StyledRows>
          <Skeleton radius={8} width={214} height={24} />
        </SmallContainerCard>
      </GridItem>
    </>
  )
}
