import { StyledInput } from './styles'
import type { InputListPosProps } from './types'
import { Select } from '@gravity/select'

const InputListPos = ({ handleValue, selectedValue, list }: InputListPosProps) => {
  const orderedListPos = list?.sort((a, b) => (a.pdv_name > b.pdv_name ? 1 : -1))
  const value = !selectedValue ? '' : selectedValue
  return (
    <StyledInput maxWidth={294}>
      <Select
        size={3}
        value={value}
        name="list_pos"
        label="Maquininha para a cobrança"
        aria-label="Maquininha para a cobrança"
        placeholder="Selecione a maquininha"
        onValueChange={value => {
          handleValue(value)
        }}
        options={orderedListPos?.map(value => ({
          label: value.pdv_name,
          value: value.pos_id,
        }))}
      />
    </StyledInput>
  )
}

export default InputListPos
