import { useCallback, useMemo, useState } from 'react'
import { Control, useForm, UseFormSetValue } from 'react-hook-form'
import { getAllFilters } from '../../../GuardianDetails/utils/getAllFilters'
import { filtersGroup } from '../../utils'
import { useGuardianInvoicesFilter } from '.'
import { zodResolver } from '@hookform/resolvers/zod'
import { FilterForm, filterSchema } from '../../schemas/filterSchemas'
import { FiltersOptionsQuery } from '@/modules/guardians/services/types'
import {
  FiltersInvoicesFormType,
  GuardiansInvoicesFilterObjectKeys,
  GuardiansInvoicesFilterQueryParams,
  GuardiansInvoicesFilterType,
} from './types'
import { InstallmentType } from '@/shared/interfaces'
import { InstallmentStatus } from '@/modules/guardians/GuardianDetails/constants'
import { useTextFilterOptions } from './useTextFilterOptions'
import { TableFilters } from '@/modules/guardians/GuardianDetailsContractsTab/components/GuardianDetailsContractsTable/types'
import { InvoicesFiltersType } from '../../types'
import { FilterChip } from '../../components/FilterChip'

type useGuardiansFiltersProps = {
  filterOptions?: FiltersOptionsQuery
}

export const useGuardiansInvoicesFilters = ({
  filterOptions: options,
}: useGuardiansFiltersProps) => {
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false)
  const { guardiansInvoicesFilter, updateFilter, clearFilter } = useGuardianInvoicesFilter()

  const filterOptions = useMemo(
    () => ({
      ...options,
      product_ids: options?.products ?? options?.product_ids,
      student_ids: options?.students ?? options?.student_ids,
    }),
    [options]
  )

  const fields: GuardiansInvoicesFilterQueryParams[] = [
    'contract_id',
    'installment_statuses',
    'installment_types',
    'negotiation_id',
    'product_ids',
    'reference_years',
    'student_ids',
  ]

  const fieldsFromURLOnly: Partial<GuardiansInvoicesFilterQueryParams>[] = [
    'contract_id',
    'negotiation_id',
  ]

  const defaultValues: FilterForm = {
    contract_id: filterOptions?.contract_id?.map(contractId => {
      return { ...contractId, selected: false }
    }),
    installment_statuses: filterOptions?.installment_statuses?.map(installmentStatus => {
      return { ...installmentStatus, selected: false }
    }),
    installment_types: filterOptions?.installment_types?.map(installmentType => {
      return { ...installmentType, selected: false }
    }),
    negotiation_id: filterOptions?.negotiation_id?.map(negotiationId => {
      return { ...negotiationId, selected: false }
    }),
    product_ids: filterOptions?.product_ids?.map(product => {
      return { ...product, selected: false }
    }),
    reference_years: filterOptions?.reference_years?.map(reference_years => {
      return { ...reference_years, selected: false }
    }),
    student_ids: filterOptions?.student_ids?.map(student => {
      return { ...student, selected: false }
    }),
    contractId: undefined,
    installmentStatuses: [],
    installmentTypes: [],
    negotiationId: undefined,
    productsArray: [],
    referenceYears: [],
    studentsArray: [],
  }

  const form = useForm<FilterForm>({
    mode: 'all',
    defaultValues,
    resolver: zodResolver(filterSchema),
  })

  const { control, setValue, reset } = form

  const currentFormValues = useMemo(() => {
    return {
      contract_id: form.getValues().contract_id,
      installment_statuses: form.getValues().installment_statuses,
      installment_types: form.getValues().installment_types,
      negotiation_id: form.getValues().negotiation_id,
      product_ids: form.getValues().product_ids,
      reference_years: form.getValues().reference_years,
      student_ids: form.getValues().student_ids,
    }
  }, [
    form.getValues().contract_id,
    form.getValues().installment_statuses,
    form.getValues().installment_types,
    form.getValues().negotiation_id,
    form.getValues().product_ids,
    form.getValues().reference_years,
    form.getValues().student_ids,
  ])

  const updateFieldSelection = useCallback(
    (field: GuardiansInvoicesFilterQueryParams, filterField: GuardiansInvoicesFilterObjectKeys) => {
      const selectedValues: string[] = guardiansInvoicesFilter?.[filterField]

      if (fieldsFromURLOnly.includes(field) && selectedValues) {
        setValue(
          field,
          selectedValues.map(value => ({ value: value, label: value, selected: true }))
        )
        return
      }

      setValue(
        field,
        filterOptions?.[field]?.map((status: { label: string; value: string }) => ({
          ...status,
          selected: selectedValues?.includes(status.value) ?? false,
        }))
      )
    },
    [filterOptions, setValue, guardiansInvoicesFilter]
  )

  const allFiltersOptions = useMemo(() => getAllFilters(filterOptions as FiltersOptionsQuery), [
    filterOptions,
  ])

  const filtersGroupArray = useMemo(
    () =>
      filtersGroup(
        allFiltersOptions,
        (setValue as unknown) as UseFormSetValue<FiltersInvoicesFormType>
      ) ?? [],
    [allFiltersOptions, setValue]
  )

  const handleOpenFilterDialog = () => {
    setIsFilterDialogOpen(true)
  }

  const handleCloseFilterDialog = () => {
    setIsFilterDialogOpen(false)
  }

  const handleClearFilter = () => {
    reset()
    clearFilter()
    setIsFilterDialogOpen(false)
  }

  const filterGroup = filtersGroupArray.map((group, id) => {
    return {
      id,
      title: group.label,
      component: (
        <FilterChip
          control={(control as unknown) as Control<FilterForm>}
          filterType={group.filterName as Partial<GuardiansInvoicesFilterQueryParams>}
        />
      ),
    }
  })

  const filtersArray = useMemo(() => {
    const mapFilters = (
      field: GuardiansInvoicesFilterQueryParams,
      mapFn: (item: any) => { text: string; value: string }
    ) => currentFormValues[field]?.filter(item => item.selected)?.map(mapFn) || []

    const contractIdFilter = mapFilters('contract_id', option => ({
      ...option,
      text: option.label ? `Contrato #${option.label?.substring(0, 6).toUpperCase()}` : '',
    }))

    const installmentStatusesFilter = mapFilters('installment_statuses', option => ({
      ...option,
      text:
        useTextFilterOptions({
          ...option,
          type: 'installment_statuses',
        }) ?? '',
    }))

    const installmentTypesFilter = mapFilters('installment_types', option => ({
      ...option,
      text:
        useTextFilterOptions({
          ...option,
          type: 'installment_types',
        }) ?? '',
    }))

    const negotiationIdFilter = mapFilters('negotiation_id', option => ({
      ...option,
      text: option.label ? `Negociação #${option.label?.substring(0, 6).toUpperCase()}` : '',
    }))

    const productsArray = mapFilters('product_ids', option => ({
      ...option,
      text: option.label ?? '',
    }))

    const referenceYearsFilter = mapFilters('reference_years', option => ({
      ...option,
      text: option.label ?? '',
    }))

    const studentsArray = mapFilters('student_ids', option => ({
      ...option,
      text: option.label ?? '',
    }))

    return [
      ...contractIdFilter,
      ...installmentStatusesFilter,
      ...installmentTypesFilter,
      ...productsArray,
      ...negotiationIdFilter,
      ...referenceYearsFilter,
      ...studentsArray,
    ]
  }, [currentFormValues])

  const filterTable: Partial<InvoicesFiltersType> = useMemo(() => {
    const contract_id =
      currentFormValues.contract_id?.find(contract => contract.selected)?.label ?? undefined

    const installment_statuses =
      currentFormValues.installment_statuses
        ?.filter(status => status.selected)
        .map(status => status.value as InstallmentStatus) ?? []

    const installment_types =
      currentFormValues.installment_types
        ?.filter(status => status.selected)
        .map(status => status.value as InstallmentType) ?? []

    const negotiation_id =
      currentFormValues.negotiation_id?.find(negotiation => negotiation.selected)?.label ??
      undefined

    const product_ids =
      currentFormValues.product_ids
        ?.filter(product => product.selected)
        .map(product => product?.value) ?? []

    const reference_years =
      currentFormValues.reference_years
        ?.filter(negotiation => negotiation.selected)
        .map(year => year?.label) ?? []

    const student_ids =
      currentFormValues.student_ids
        ?.filter(student => student.selected)
        .map(student => student?.value) ?? []

    return {
      contract_id,
      installment_statuses,
      installment_types,
      negotiation_id,
      product_ids,
      reference_years,
      student_ids,
    }
  }, [currentFormValues])

  const handleApplyFilters = (fields: GuardiansInvoicesFilterType) => {
    updateFilter(fields)
    setIsFilterDialogOpen(false)
  }

  const handleRemoveFilter = useCallback(
    (filter: TableFilters) => {
      const convertedFilter = filter.value
      const matchingOption = filtersArray.find(option => option.value.includes(convertedFilter))
      const updatedFilters = { ...currentFormValues }

      const getSelectedValues = (field: GuardiansInvoicesFilterQueryParams) => {
        return (
          updatedFilters[field]?.filter(status => status.selected).map(status => status.value) ?? []
        )
      }

      if (matchingOption) {
        fields.forEach(field => {
          updatedFilters[field]?.forEach(status => {
            if (status.value === convertedFilter) {
              status.selected = false
              setValue(field, updatedFilters[field])
            }
          })
        })
      }

      const filtersToUpdate = {
        contractId: getSelectedValues('contract_id'),
        installmentStatuses: getSelectedValues('installment_statuses'),
        installmentTypes: getSelectedValues('installment_types'),
        negotiationId: getSelectedValues('negotiation_id'),
        productsArray: getSelectedValues('product_ids'),
        referenceYears: getSelectedValues('reference_years'),
        studentsArray: getSelectedValues('student_ids'),
      } as GuardiansInvoicesFilterType
      updateFilter(filtersToUpdate)
    },
    [currentFormValues, filtersGroupArray, setValue, updateFilter]
  )

  return {
    isFilterDialogOpen,
    filtersGroup: filterGroup,
    form,
    filtersArray,
    filterTable,
    setValue,
    handleOpenFilterDialog,
    handleCloseFilterDialog,
    handleApplyFilters,
    handleClearFilter,
    handleRemoveFilter,
    updateFieldSelection,
  }
}
