import styled from 'styled-components'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { Card } from '@gravity/card'

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[4]};
  border-color: ${props => props.theme.gravity.colors['colors-border-neutral-3']};
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.gravity.spacing[2]};
`

export const ColumnContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const GroupTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[2]};
`

export const StyledHeading = styled(Heading)`
  color: ${props => props.theme.gravity.colors['colors-text-main-1']};
`

export const StyledCaption = styled(Text)`
  color: ${props => props.theme.gravity.colors['colors-text-main-3']};
`
