import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useApiClient } from '@/shared/hooks/useApiClient'

import { reportService } from '@/shared/services/report'

import type {
  FetchPayoutDetailsRequestDTO,
  FetchPayoutDetailsResponseDTO,
  DashboardName,
} from '@/shared/services/report/types'
import type { PayoutSummary } from '@monorepo/report/models/PayoutSummary'
import type { UnguaranteedReport } from '@monorepo/report/models/UnguaranteedReport'

export const useDashboardReportQuery = (dashboardName: DashboardName) => {
  const { school } = useSelectedSchool()
  const { apiClient } = useApiClient()

  const service = reportService(apiClient.getClients().privateApi)

  return useQuery({
    queryKey: ['dashboard-report', dashboardName, school?.id],
    queryFn: async () => {
      const { data } = await service.fetchEmbedDashboardReport({
        schoolId: school?.id ?? '',
        dashboardName,
      })

      return {
        url: `${data.url}#font=Roboto&bordered=false&titled=false`,
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 0,
    enabled: !!school?.id,
  })
}

export const useTuitionPayoutReports = (
  schoolId: string,
  options?: UseQueryOptions<PayoutSummary[]>
) => {
  const { apiClient } = useApiClient()

  const service = reportService(apiClient.getClients().privateApi)

  return useQuery<PayoutSummary[]>(
    ['reports', schoolId, 'tuition'],
    async () => {
      return await service.fetchPayoutsSummary(schoolId)
    },
    options
  )
}

export const useTuitionPayoutReport = (
  { payoutId, filter, pagination }: FetchPayoutDetailsRequestDTO<'tuition'>,
  options?: UseQueryOptions<FetchPayoutDetailsResponseDTO<'tuition'>>
) => {
  const { apiClient } = useApiClient()

  const service = reportService(apiClient.getClients().privateApi)

  return useQuery<FetchPayoutDetailsResponseDTO<'tuition'>>(
    ['reports', 'tuition', payoutId, JSON.stringify({ ...filter, ...pagination })],
    async () => {
      return await service.fetchPayoutDetails('tuition', {
        payoutId,
        filter,
        pagination,
      })
    },
    options
  )
}

export const useEnrollmentPayoutReports = (
  schoolId: string,
  options?: UseQueryOptions<UnguaranteedReport[]>
) => {
  const { apiClient } = useApiClient()

  const service = reportService(apiClient.getClients().privateApi)

  return useQuery<UnguaranteedReport[]>(
    ['reports', schoolId, 'enrollment'],
    async () => {
      const { data } = await service.fetchReportList({
        schoolId,
        reportType: 'UNGUARANTEED',
      })

      return data
    },
    options
  )
}
