import type { AxiosInstance } from 'axios'
import qs from 'qs'

import { buildQueryParams } from '../../utils/buildQueryParams'

import type {
  FetchAggregatedProvidersReportRequestDTO,
  FetchPayoutDetailsResponseDTO,
  FetchPayoutMonthAggregationRequestDTO,
  FetchPayoutReportFileRequestDTO,
  FetchAggregatedProvidersReportResponseDTO,
  FetchExternalPaymentsReportListResponseDTO,
  FetchExternalPaymentsReportListRequestDTO,
  FetchUnguaranteedReportFileRequestDTO,
} from './types'
import type { PayoutReportType } from '../../models/PayoutReportType'

export const reportService = (fetcher: AxiosInstance) => {
  return {
    /**
     * Function to fetch a list of financial transactions for a given period.
     * It will include transactions from multiple payouts
     *
     * @param data.schoolID School ID
     * @param data.startDate Interval Start date
     * @param data.endDate Interval End date
     * @param data.payoutDomain Payout domain ('IP' or 'E')
     * @param data.filter Filter options
     * @param data.pagination Pagination options
     *
     * @returns Payout details
     */
    fetchPayoutMonthAggregation: async <T extends PayoutReportType>({
      schoolID,
      startDate,
      endDate,
      payoutDomain,
      filter = {},
      pagination = {},
    }: FetchPayoutMonthAggregationRequestDTO<T>): Promise<
      FetchPayoutDetailsResponseDTO<'isaac-pay'>
    > => {
      const parsedFilters = buildQueryParams(filter)
      const parsedPagination = buildQueryParams(pagination)

      const params = {
        school_id: schoolID,
        start_date: startDate,
        end_date: endDate,
        payout_domain: payoutDomain,
        ...parsedFilters,
        ...parsedPagination,
      }

      const { data } = await fetcher.get<FetchPayoutDetailsResponseDTO<'isaac-pay'>>(
        `/report/financial_transaction`,
        {
          params,
          paramsSerializer: params => qs.stringify(params, { arrayFormat: 'comma' }),
        }
      )

      return data
    },

    /**
     * Function responsible for request payout report file download
     *
     * @param data.id Payout ID
     * @param data.extension File extension
     * @param data.version Payout version
     *
     * @returns Blob file content
     */
    fetchPayoutReportFile: async ({
      extension,
      id,
      version,
    }: FetchPayoutReportFileRequestDTO): Promise<Blob> => {
      const { data } = await fetcher.get<Blob>(`/report/files/${version}/${id}.${extension}`, {
        responseType: 'blob',
      })

      return data
    },

    /**
     * Function responsible for fetching isaac pay external payments
     *
     * @param schoolId School ID
     * @param endDate Interval End date
     * @param startDate Interval Start date
     *
     * @returns Summary of external payments and payouts
     */
    fetchAggregatedProvidersReport: async ({
      schoolId,
      startDate,
      endDate,
      payoutDomain,
    }: FetchAggregatedProvidersReportRequestDTO) => {
      const { data } = await fetcher.get<FetchAggregatedProvidersReportResponseDTO>(
        `/report/aggregated-providers/${schoolId}/total-payment?start_date=${startDate}&end_date=${endDate}&payout_domain=${payoutDomain}`
      )

      return data
    },

    fetchExternalPaymentsReportList: async ({
      schoolId,
      start_date,
      end_date,
      filter = {},
      pagination = {},
    }: FetchExternalPaymentsReportListRequestDTO): Promise<FetchExternalPaymentsReportListResponseDTO> => {
      const parsedFilters = buildQueryParams(filter)
      const parsedPagination = buildQueryParams(pagination)

      const params = {
        ...parsedFilters,
        ...parsedPagination,
      }
      const { data } = await fetcher.get<FetchExternalPaymentsReportListResponseDTO>(
        `receivable/external-payments?school_id=${schoolId}&start_date=${start_date}&end_date=${end_date}`,
        {
          params,
          paramsSerializer: params => qs.stringify(params, { arrayFormat: 'comma' }),
        }
      )
      return data
    },

    /**
     * Function responsible for request unguaranteed report download
     *
     * @param data.schoolID School ID
     * @param data.id Payout ID
     * @param data.extension File extension
     *
     * @returns Axios response with Blob file content
     */
    fetchUnguaranteedReportFile: async ({
      schoolId,
      id,
      extension,
    }: FetchUnguaranteedReportFileRequestDTO): Promise<Blob> => {
      const { data } = await fetcher.get<Blob>(
        `/report/files/unguaranteed/${schoolId}/${id}.${extension}`,
        {
          responseType: 'blob',
        }
      )

      return data
    },
  }
}
