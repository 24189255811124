import { TransactionEventType } from '@monorepo/report/models/report'

export const TRANSACTIONS_IN_ORDER = {
  total_base_amount: {
    label: 'Faturamento',
    description: 'Soma das parcelas de matrículas pagas pelas famílias',
  },
  [TransactionEventType.NEW_CONTRACT]: {
    label: 'Alunos novos',
    description: 'Novos contratos gerados dentro do mês',
  },
  [TransactionEventType.LOST_DISCOUNT]: {
    label: 'Adicional de desconto de antecipação',
    description: 'Adicional de desconto de antecipação',
  },
  [TransactionEventType.APPLIED_DISCOUNT]: {
    label: 'Alteração de valores pela escola',
    description: 'Edição de valores de parcelas já repassadas',
  },
  [TransactionEventType.CANCEL]: {
    label: 'Contratos cancelados',
    description: 'Cancelamento de contratos ou parcelas, seja por desistência ou correções',
  },
  [TransactionEventType.SCHOOL_LIQUIDATION]: {
    label: 'Recebidos na escola ',
    description: 'Valores recebidos diretamente na escola, com liquidação manual das parcelas',
  },
  [TransactionEventType.STONE_TRANSACTION]: {
    label: 'Recebidos na maquininha isaac',
    description: 'Soma dos valores recebidos via maquininha isaac',
  },
  [TransactionEventType.LIQUIDATION_EDITION]: {
    label: 'Edição de recebido na escola',
    description: 'Edição de recebido na escola',
  },
  [TransactionEventType.CORRECTION]: {
    label: 'Correção pontual do repasse',
    description: 'Correção pontual do repasse',
  },
  [TransactionEventType.AGGLUTINATION_DISCOUNT]: {
    label: 'Desconto por aglutinação de parcelas',
    description: 'Desconto por aglutinação de parcelas',
  },
  [TransactionEventType.BALANCE_TRANSFER]: {
    label: 'Valores negativos de repasse de matrícula',
    description: 'Desconto de repasses de matrícula com valor negativo de semanas anteriores',
  },
  total_fee: { label: 'Taxa isaac', description: 'Valor referente à prestação de serviços isaac' },
}

export const ISAAC_PAY_TRANSACTIONS_IN_ORDER = {
  [TransactionEventType.U_PAYMENT_REGISTERED]: { label: 'Faturamento' },
  [TransactionEventType.U_PAYMENT_MANUAL_CANCELED]: {
    label: 'Cancelamento de recebidos na escola',
  },
  [TransactionEventType.U_PAYMENT_CHANGED]: { label: 'Alteração de valores' },
  [TransactionEventType.CANCEL]: { label: 'Contratos Cancelados' },
}

export const ENROLLMENT_TRANSACTIONS_IN_ORDER = {
  [TransactionEventType.U_PAYMENT_REGISTERED]: { label: 'Faturamento' },
  [TransactionEventType.U_PAYMENT_MANUAL_CANCELED]: {
    label: 'Cancelamento de recebidos na escola',
  },
  [TransactionEventType.U_PAYMENT_CHANGED]: { label: 'Alteração de valores' },
  [TransactionEventType.CANCEL]: { label: 'Contratos Cancelados' },
}
