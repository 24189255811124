import styled from 'styled-components'

export const Card = styled.div`
  display: flex;
  padding: 24px 32px 24px 34px;
  flex-direction: column;
  width: 100%;
`

export const CircleIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ecedfb;
  width: 56px;
  min-width: 56px;
  height: 56px;
  margin-right: 32px;
  margin-left: 34px;
  border-radius: 50%;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
`
export const RadioItem = styled.div`
  display: flex;
  width: 25%;
  align-items: center;
`
export const TextItem = styled.div`
  display: flex;
  align-items: center;
  width: 75%;
  margin-left: 20px;
`
export const InfoItem = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 55%;
  text-align: right;
`
