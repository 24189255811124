import { CommonMetadata } from './metadata'
import { Guardian } from './guardian'
import { Installment, TuitionInstallments } from './installment'
import { OldProduct } from './oldProduct'

import { PreContractStudent, Student } from './student'
import { Discount } from './receivable'
import { BufferInstallmentResponse } from './bufferInstallment'

export enum ContractStatus {
  CANCELED = 'CANCELED',
  OPEN = 'OPEN',
  PENDING = 'PENDING',
}

export enum MatriculasApiContractStatus {
  CANCELED = 'CANCELED',
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  PENDING_GUARDIAN_APPROVAL = 'PENDING_GUARDIAN_APPROVAL',
}

export enum PreContractStatuses {
  CREATED_PCS = 'CREATED',
  EFFECTED_PCS = 'EFFECTED',
}

export enum EnrollmentStatuses {
  ENROLLED_CES = 'ENROLLED',
  PRE_ENROLLED_CES = 'PRE_ENROLLED',
}

export enum DebtStatus {
  OVERDUE = 'OVERDUE',
  PENDING = 'PENDING',
  UP_TO_DATE = 'UP_TO_DATE',
  WARNING = 'WARNING',
}

export enum ContractStatusLabel {
  CANCELED = 'Inativo',
  OPEN = 'Ativo',
}

export enum EnrollmentStatus {
  ENROLLED = 'ENROLLED',
  NOT_ENROLLED = 'NOT_ENROLLED',
}

export enum SignPaymentStatus {
  COMPLETE = 'COMPLETE',
  NOT_ENROLLED = 'NOT_ENROLLED',
  PAYMENT_AND_SIGN_PENDING = 'PAYMENT_AND_SIGN_PENDING',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  PRE_ENROLLED = 'PRE_ENROLLED',
  SIGN_PENDING = 'SIGN_PENDING',
}

export enum SignAndPaymentStatus {
  CANCELED = 'CANCELED',
  COMPLETE = 'COMPLETE',
  PAYMENT_AND_SIGN_PENDING = 'PAYMENT_AND_SIGN_PENDING',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  PRE_ENROLLED = 'PRE_ENROLLED',
  SIGN_PENDING = 'SIGN_PENDING',
}

export enum SignAndPaymentStatusLabel {
  CANCELED = 'Inativo',
  COMPLETE = 'Matriculado',
  PAYMENT_AND_SIGN_PENDING = 'Falta pagar e assinar',
  PAYMENT_PENDING = 'Falta pagar',
  PRE_ENROLLED = 'Pré-matriculado',
  SIGN_PENDING = 'Falta assinar',
}

export type PreContract = CommonMetadata & {
  guardian?: Guardian
  has_docusign: boolean
  student?: PreContractStudent
  tuition_installments?: TuitionInstallments
}

export type Contract = CommonMetadata & {
  academic_period_id: uuid | null
  debt_status?: DebtStatus
  discount_description?: string
  due_day: number
  duration_months?: number
  end_month: string
  enrollment_due_day?: number
  enrollment_status?: EnrollmentStatuses
  guardian?: Guardian
  guardian_id: uuid
  installments?: Array<Installment>
  is_reenrollable?: boolean
  isReenrollable?: boolean
  next_year_contracts: Array<Contract>
  pre_contract_installments?: BufferInstallmentResponse[]
  pre_contract_status?: PreContractStatuses
  product?: OldProduct
  product_id: uuid
  reference_year: string
  sign_payment_status?: string
  start_month: string
  status: ContractStatus
  student?: Student
  student_id: uuid
  total_amount: cents
  use_working_dates_tuition?: boolean
}

export type ContractResponse = {
  campaign_id?: string
  can_accept_payment_plan?: boolean
  can_add_tuition?: boolean
  debt_status: DebtStatus
  end_month: string
  guardian?: Guardian
  guardian_id: uuid
  has_enrollment_installment?: boolean
  id: uuid
  is_reenrollable: boolean
  next_year_contract?: NextYearContractResponse
  product?: ProductResponse
  product_id: uuid
  reference_year: string
  sign_payment_status: string
  start_month: string
  status: ContractStatus | MatriculasApiContractStatus
  student?: Student
  student_id: uuid
}

export type ProductResponse = {
  id: uuid
  name: string
}

export type NextYearContractResponse = {
  id: uuid
  sign_payment_status: string
  status: ContractStatus
}

export type GroupGuardian = {
  debt_status: DebtStatus
  guardian_id: string
  name: string
  next_year_sign_status: SignAndPaymentStatus
  products_names: string[]
  students_names: string[]
  tax_id: string
}

export type Enrollment = {
  enrollment_discounts: Array<Discount>
  enrollment_due_day?: number
  enrollment_duration_months: number
  enrollment_start_month?: string
  send_first_enrollment_installment: boolean
  use_working_dates_enrollment: boolean
}

export type CreateContractPayload = Omit<
  Contract,
  'created_at' | 'id' | 'end_month' | 'total_amount' | 'status' | 'next_year_contracts'
> &
  Partial<Enrollment> & {
    custom_enrollment_monthly_amount: number
    custom_monthly_amount: number
    discounts: Array<Discount>
    duration_months: number
    pre_contract_disclaimer_check?: boolean
    previous_year_contract_id: string
    referral?: string
    school_charge_operation?: string
    school_id: string
    send_signable_document: boolean
  }

export enum ContractCancellationReason {
  CANCEL_INSTALLMENT = 'CANCEL_INSTALLMENT',
  DUPLICATED_CONTRACT = 'DUPLICATED_CONTRACT',
  OTHER = 'OTHER',
  STUDENT_LEFT = 'STUDENT_LEFT',
  STUDENT_QUIT = 'STUDENT_QUIT',
  WRONG_AMOUNT = 'WRONG_AMOUNT',
  WRONG_DATE = 'WRONG_DATE',
  WRONG_DISCOUNT = 'WRONG_DISCOUNT',
  WRONG_GUARDIAN_INFO = 'WRONG_GUARDIAN_INFO',
}

export enum ContractCreationSteps {
  CREATE_CONTRACT = 'Criando contrato',
  CREATE_GUARDIAN = 'Criando responsável',
  CREATE_STUDENT = 'Criando aluno',
  FINISHED = 'Concluído',
}

export type ReenrollmentStats = {
  in_progress_count: number
  reenrolled_count: number
  total_open_contracts: number
}

export type CheckDuplicationPayload = {
  duration_months: number
  guardian_name: string
  product_id: uuid
  start_month: string
  student_name: string
}

export type CheckDuplicationResponse = {
  duplicated_contract_id?: uuid
  guardian_id?: uuid
  is_duplicated: boolean
  seconds_elapsed_since_creation?: number
  student_name?: string
}

export type CreateCampaignPayload = Partial<Enrollment> & {
  contract_ids: uuid[]
  custom_enrollment_monthly_amount: number
  custom_monthly_amount: number
  discounts: Array<Discount>
  due_day: number
  duration_months: number
  pre_contract_disclaimer_check: boolean
  product_id: uuid
  recipient_email: string
  reference_year: string
  school_id: uuid
  send_signable_document: boolean
  start_month: string
  use_working_dates_tuition: boolean
}

export type CampaignStatusResponse = {
  campaign_allow: boolean
}

export enum SENT_BY {
  ISAAC = 'Via isaac',
  SCHOOL = 'Via escola',
}

export enum ContractNeverPaidInstallmentType {
  NEVER_PAID = 'NEVER_PAID',
  SETTLED = 'SETTLED',
}

export type ContractNeverPaidInstallment = {
  guardian_name: string
  student_name: string
  total_amount: number
  total_installments: number
  total_products: number
  unique_key: boolean
}

export type ContractNeverPaid = {
  installment_type: ContractNeverPaidInstallmentType
  installments: Array<ContractNeverPaidInstallment>
  total_amount: number
  total_installments: number
  total_students: number
}

export type ContractNeverPaidDetail = {
  contracts?: Array<ContractNeverPaidDetailContract>
  guardian_id: string
  guardian_name: string
  student_name: string
  total_installments: number
  total_products: number
}

export type ContractNeverPaidDetailContract = {
  contract_id: string
  created_at: string
  installments?: Array<ContractNeverPaidDetailContractInstallment>
  number_of_open_installments: number
  product_name: string
  status: ContractStatus
}

export type ContractNeverPaidDetailContractInstallment = {
  installment_due_date: string
  installment_id: string
  installment_order_reference: string
  receivable_amount: number
  receivable_due_date: string
  receivable_id: string
}

export enum ReenrollmentStatus {
  ENROLLED = 'ENROLLED',
  ENROLLMENT_AT_RISK = 'ENROLLMENT_AT_RISK',
  ENROLLMENT_IN_PROGRESS = 'ENROLLMENT_IN_PROGRESS',
  GATE_ERROR = 'GATE_ERROR',
  NOT_REENROLLABLE = 'NOT_REENROLLABLE',
  REENROLLMENT_AVAILABLE = 'REENROLLMENT_AVAILABLE',
  UNAVAILABLE_AT_MOMENT = 'UNAVAILABLE_AT_MOMENT',
}

export enum ReEnrollmentSubStatus {
  GUARDIAN_ENGAGED = 'GUARDIAN_ENGAGED',
  PRE_ENROLLMENT_PAID = 'PRE_ENROLLMENT_PAID',
  PRE_ENROLLMENT_PENDING = 'PRE_ENROLLMENT_PENDING',
  PREVIOUS_YEAR_OVERDUE = 'PREVIOUS_YEAR_OVERDUE',
  PREVIOUS_YEAR_PENDING = 'PREVIOUS_YEAR_PENDING',
  REENROLLMENT_SUSPENDED = 'REENROLLMENT_SUSPENDED',
  SCHOOL_ENROLLMENT_NOT_ALLOWED = 'SCHOOL_ENROLLMENT_NOT_ALLOWED',
  STUDENT_RECORD_PENDING = 'STUDENT_RECORD_PENDING',
  WAITING_ENGAGE = 'WAITING_ENGAGE',
  WAITING_TUITION_CREATION = 'WAITING_TUITION_CREATION',
}

export interface SimplifiedContract {
  actions: {
    can_change_ownership: boolean
  }
  debt_status: DebtStatus
  id: uuid
  pre_contract_status: PreContractStatuses
  product: { id: uuid; name: string }
  reference_year: string
  status: ContractStatus
  student: { id: uuid; name: string }
}
