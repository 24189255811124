import { Table } from '@gravity/table'
import { Tooltip } from '@gravity/tooltip'
import { IconButton } from '@gravity/icon-button'
import { Pencil, DeleteBin, Printer, ListView, ArrowLeftRight } from '@gravity/icons'

import { Contract as ContractData } from '@/shared/interfaces'
import { Contract, ErrorDialog } from '@/modules/guardians/GuardianDetails/types'

import { useGuardianDetailsContractsTableActions } from './useGuardianDetailsContractsTableActions'
import { MoreOptionsPopover } from '@/shared/components/MoreOptionsPopover'
import { PopoverOptions } from '@/shared/components/MoreOptionsPopover/types'

interface GuardianDetailsContractsTableActionsProps {
  contract?: Contract
  contractData?: ContractData
  goToChangeOwnershipPage: (contractId: uuid) => void
  onGeneratePaymentStatement: (contractId: uuid) => void
  openCancelContractDrawer: (contractId: uuid) => void
  setErrorDialog: (errorDialog: ErrorDialog) => void
}

export const GuardianDetailsContractsTableActions = ({
  contract,
  contractData,
  openCancelContractDrawer,
  onGeneratePaymentStatement,
  setErrorDialog,
  goToChangeOwnershipPage,
}: GuardianDetailsContractsTableActionsProps) => {
  if (!contract) return null

  const {
    showEditContractOption,
    showDownloadCarneOption,
    showCancelContractOption,
    showGeneratePaymentStatement,
    handleClickGoToContractEdit,
    handleClickGoToInvoicePage,
    handleClickGoToDownloadCarne,
    handleClickGoToEnrollmentPage,
    sendGenerateStatementEvents,
    sendCancelContractEvent,
  } = useGuardianDetailsContractsTableActions({
    contract,
    contractData,
    setErrorDialog,
  })

  const handleClickGeneratePaymentStatement = () => {
    sendGenerateStatementEvents()
    onGeneratePaymentStatement(contract.id)
  }

  const moreOptions: PopoverOptions[] = [
    { label: 'Ver situação de matrícula', onClick: handleClickGoToEnrollmentPage },
  ]

  if (showGeneratePaymentStatement)
    moreOptions.push({
      label: 'Gerar demonstrativo de pagamento',
      onClick: handleClickGeneratePaymentStatement,
    })

  return (
    <Table.ActionCell
      actions={
        <>
          {showEditContractOption && (
            <Tooltip text="Editar contrato" position="left">
              <IconButton variant="ghost" onClick={handleClickGoToContractEdit}>
                <span>
                  <Pencil size="xs" />
                </span>
              </IconButton>
            </Tooltip>
          )}

          <Tooltip text="Ver faturas" position="left">
            <IconButton variant="ghost" onClick={handleClickGoToInvoicePage}>
              <ListView size="xs" />
            </IconButton>
          </Tooltip>

          {showDownloadCarneOption && (
            <Tooltip text="Imprimir carnê" position="left">
              <IconButton variant="ghost" onClick={handleClickGoToDownloadCarne}>
                <Printer size="xs" />
              </IconButton>
            </Tooltip>
          )}

          {showCancelContractOption && (
            <Tooltip text="Cancelar contrato" position="left">
              <IconButton
                variant="ghost"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation()
                  sendCancelContractEvent()
                  openCancelContractDrawer(contract.id)
                }}
              >
                <DeleteBin size="xs" />
              </IconButton>
            </Tooltip>
          )}

          {contract.actions.can_change_ownership && (
            <Tooltip text="Trocar titularidade" position="left">
              <IconButton variant="ghost" onClick={() => goToChangeOwnershipPage(contract.id)}>
                <ArrowLeftRight size="xs" />
              </IconButton>
            </Tooltip>
          )}

          <MoreOptionsPopover options={moreOptions.filter(Boolean)} />
        </>
      }
    />
  )
}
