import {
  HeartOutline,
  CalendarEventOutline,
  ShakeHandsOutline,
  PriceTagOutline,
  PercentOutline,
  Check,
  ArrowRightUpLong,
  CalendarScheduleOutline,
} from '@gravity/icons'
import { InfoCard } from '../../components/InfoCard'
import { HomeTemplate } from '../../templates/HomeTemplate'
import { RowContainer, RowContainerSpaceBetween, StyledCard, TextGroup } from './styles'
import { IconButton } from '@gravity/icon-button'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { MixpanelEventActions } from '../../constants/MixpanelEventActions.enum'
import { CreditEventComponentNames } from '../../constants/CreditEventComponentNames.enum'
import useMixpanelEventSender from '../../utils/useMixpanelEventSender'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import { Heading } from '@gravity/heading'
import { GridItem } from '@gravity/grid'
import { Text } from '@gravity/text'

export const NoCreditAvailable = () => {
  const { sendCreditEventToMixpanel } = useMixpanelEventSender()
  const isInMaintenance = useUnleashFlag(UnleashFlags.CRD_166_DISABLE_CREDIT_FLOWS)

  const cards = [
    <InfoCard
      key={1}
      icon={<HeartOutline />}
      title="Tenha uma operação saudável com o isaac"
      description="Mantenha a taxa de recebimentos manuais em zero, instruindo o responsável financeiro a efetuar os pagamentos através do meu isaac."
    />,
    <InfoCard
      key={2}
      icon={<CalendarEventOutline />}
      title="Tenha as contas em dia"
      description="Um critério importante para ter mais crédito é pagar as contas sem atraso. Os CNPJs e CPFs dos sócios da escola não podem ter restrições."
    />,
    <InfoCard
      key={3}
      icon={<ShakeHandsOutline />}
      title="Busque renegociar dívidas antigas"
      description="Contratar crédito agora pode comprometer a saúde financeira da escola. Busque renegociar dívidas antigas para que o crédito caiba no orçamento."
    />,
  ]

  return (
    <HomeTemplate
      title={'Saiba como aumentar suas chances  \n de ter crédito no isaac'}
      cardDescription={
        'Sua instituição não possui ofertas disponíveis no momento,  \n mas preparamos algumas dicas de como melhorar sua análise de crédito.'
      }
      isEligible={false}
      cards={cards}
      mainCardDescription="Como melhorar a análise de crédito"
      bottomInfo={
        <>
          <GridItem xl={8} lg={8} md={8} sm={5} xs={3}>
            <StyledCard className="pb-10 gap-[82px]">
              <TextGroup className="gap-6">
                <Heading variant="heading-h2-medium">Conheça o crédito isaac</Heading>
                <TextGroup className="gap-4">
                  <RowContainer>
                    <CalendarScheduleOutline />
                    <Text variant="body-1-regular">
                      Número de parcelas máximo condicionado ao contrato vigente com o isaac
                    </Text>
                  </RowContainer>

                  <RowContainer>
                    <PriceTagOutline />
                    <Text variant="body-1-regular">
                      Campanhas com condições especiais ao longo do ano
                    </Text>
                  </RowContainer>

                  <RowContainer>
                    <PercentOutline />
                    <Text variant="body-1-regular">Taxas competitivas com o mercado</Text>
                  </RowContainer>

                  <RowContainer>
                    <Check />
                    <Text variant="body-1-regular">
                      Elegível a partir do segundo mês de operação
                    </Text>
                  </RowContainer>
                </TextGroup>
              </TextGroup>
              <Text variant="body-2-regular">
                A disponibilidade das ofertas estão sujeitas a análise de crédito.
              </Text>
            </StyledCard>
          </GridItem>
          <GridItem lg={4} xl={4} md={4} sm={3} xs={1}>
            <GridItem md={12}>
              <TextGroup className="gap-4">
                <StyledCard className="gap-4 pt-[61.5px] pb-[61.5px]">
                  <Heading variant="heading-h4-medium">O isaac está aqui para te apoiar</Heading>
                  <Text variant="body-1-regular">
                    Você pode entrar em contato com seu consultor para entender todas as
                    possibilidades do crédito isaac.
                  </Text>
                </StyledCard>

                <StyledCard className="p-8">
                  <RowContainerSpaceBetween>
                    <Heading variant="heading-h4-medium">Dúvidas frequentes</Heading>
                    <IconButton
                      size={2}
                      onClick={() => {
                        sendCreditEventToMixpanel(
                          EventDispatcherEvents.BUTTON_CLICKED,
                          MixpanelEventActions.CLICK,
                          {
                            $page_name: CreditEventComponentNames.FAQ,
                          }
                        )
                        window.open(
                          'https://centraldeajuda.olaisaac.io/credito-isaac/elegibilidade-do-credito-isaac',
                          '_blank'
                        )
                      }}
                    >
                      <ArrowRightUpLong />
                    </IconButton>
                  </RowContainerSpaceBetween>
                </StyledCard>
              </TextGroup>
            </GridItem>
          </GridItem>
        </>
      }
      isInMaintenance={isInMaintenance}
    />
  )
}
