import { StyledContainer, StyledLoadingContainer, StyledMain } from './styles'
import { useEffect } from 'react'
import { DEFAULT_AWAITING_TIME } from '../constants'
import { useHistory, useLocation } from 'react-router-dom'
import { CircularLoading } from '@/shared/components/CircularLoading'
import { Heading } from '@gravity/heading'

export default function Awaiting() {
  const { search, pathname } = useLocation()
  const history = useHistory()

  useEffect(() => {
    setTimeout(() => {
      const urlParams = pathname.split('/')
      const path = urlParams.slice(0, urlParams.length - 1).join('/') + '/faturas'
      history.push({ pathname: path, search })
    }, DEFAULT_AWAITING_TIME)
  }, [])

  return (
    <StyledMain>
      <StyledContainer>
        <StyledLoadingContainer>
          <CircularLoading iconSize={64} />
          <div className="mt-4">
            <Heading variant="heading-h3-regular">
              Aguarde enquanto processamos a criação do contrato...
            </Heading>
          </div>
        </StyledLoadingContainer>
      </StyledContainer>
    </StyledMain>
  )
}
