import { Heading } from '@gravity/heading'
import { ReactNode } from 'react'
import { Text } from '@gravity/text'
import { Grid, GridItem } from '@gravity/grid'
import { useLayout } from '@/shared/hooks/useLayout'

type LayoutTemplateProps = {
  children?: ReactNode
  description?: ReactNode
  footer?: ReactNode
  title?: ReactNode
}

export const LayoutTemplate = ({ children, description, footer, title }: LayoutTemplateProps) => {
  useLayout({
    enableSideMenu: true,
    enableHeader: true,
    headerTitle: 'Migração de dados',
  })

  return (
    <div className="flex h-full justify-center pt-8">
      <Grid className="h-full">
        <GridItem className="flex flex-col">
          <Heading className="mb-2" variant="heading-h3-medium">
            {title}
          </Heading>
          <Text variant="body-1-regular">{description}</Text>
          <div className="flex flex-col grow mt-[42px]">{children}</div>
          <div className="flex justify-end gap-4 py-7">{footer}</div>
        </GridItem>
      </Grid>
    </div>
  )
}
