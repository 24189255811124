import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { Button } from '@gravity/button'
import { useHistory } from 'react-router-dom'
import { ArrowLeft } from '@gravity/icons'
import { useEffect } from 'react'

import { UploadFile } from '@monorepo/enrollment/pages/DigitalSignature/components/UploadFile/index'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { InteractiveCard } from '../../components/InteractiveCard'
import * as Styled from './styles'
import { useDocumentUploadManager } from './hooks/useDocumentUploadManager'

export const DigitalSignature = () => {
  const history = useHistory()
  const isDigitalSignatureEnabled = useUnleashFlag(UnleashFlags.MAT_673_ENABLE_DIGITAL_SIGNATURE)
  const { schoolSlug } = useSelectedSchool()

  const signatureUpload = useDocumentUploadManager('SIGNATURE')
  const contractUpload = useDocumentUploadManager('SCHOOL_DOCUMENT')

  useEffect(() => {
    if (!isDigitalSignatureEnabled) {
      history.push(`/${schoolSlug}/matriculas`)
    }
  }, [isDigitalSignatureEnabled])

  const features = [
    {
      title: 'Modelo de contrato',
      description:
        'Escolha o modelo de contrato que será enviado aos responsáveis financeiros. O formato do arquivo deve ser .pdf.',
      component: (
        <form onSubmit={contractUpload.handleSubmit}>
          <UploadFile
            isLoading={contractUpload.isFetching}
            state={contractUpload.file ? 'success' : 'idle'}
            fileName={contractUpload.file?.name ?? ''}
            fileSize={contractUpload.file?.size}
            allowedFormats=".pdf,.jpg,.png"
            error={contractUpload.errors.file?.message as string}
            onInputChange={contractUpload.handleFileChange}
            onFileSelect={contractUpload.handleFileSelect}
            downloadFile={() => contractUpload.handleDownload(contractUpload.file as File)}
            onRemoveFile={contractUpload.handleRemove}
          />
        </form>
      ),
    },
    {
      title: 'Assinatura da escola',
      description:
        'Os contratos enviados serão assinados automaticamente, para facilitar o processo de aprovação.',
      component: (
        <form onSubmit={signatureUpload.handleSubmit}>
          <UploadFile
            isLoading={signatureUpload.isFetching}
            state={signatureUpload.file ? 'success' : 'idle'}
            fileName={signatureUpload.file?.name ?? ''}
            fileSize={signatureUpload.file?.size}
            allowedFormats=".pdf,.jpg,.png"
            error={signatureUpload.errors.file?.message as string}
            onInputChange={signatureUpload.handleFileChange}
            onFileSelect={signatureUpload.handleFileSelect}
            downloadFile={() => signatureUpload.handleDownload(signatureUpload.file as File)}
            onRemoveFile={signatureUpload.handleRemove}
          />
        </form>
      ),
    },
    {
      title: 'Formulário personalizado',
      description:
        'Você pode solicitar informações específicas dos responsáveis através do formulário personalizado.',
      component: <Button disabled>Definir formulário</Button>,
    },
  ]
  return (
    <Styled.PageWrapper>
      <Button
        variant="ghost"
        iconStart={<ArrowLeft />}
        onClick={() => history.goBack}
        style={{ justifyContent: 'flex-start' }}
      >
        Voltar
      </Button>

      <Heading variant="heading-h3-medium">Contrato digital</Heading>
      <Text variant="body-1-regular">
        A funcionalidade de contrato digital permite que você carregue um template em PDF
        diretamente na plataforma, facilitando o envio para que os responsáveis realizem a
        assinatura de forma prática e segura.
      </Text>
      <Styled.CardsContainer>
        {features.map((feature, index) => (
          <InteractiveCard
            key={index}
            title={feature.title}
            description={feature.description}
            style={{ minHeight: '448px' }}
          >
            {feature.component}
          </InteractiveCard>
        ))}
      </Styled.CardsContainer>
    </Styled.PageWrapper>
  )
}
