import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'

export const GenericError = () => {
  return (
    <div className="flex flex-col gap-4 items-center mt-14">
      <Heading variant="heading-h4-medium">Erro ao carregar conteúdo</Heading>
      <Text>Algo deu errado por aqui, mas você pode tentar novamente daqui a pouco.</Text>
    </div>
  )
}
