import { DialogPrimitives } from '@gravity/dialog'
import { Skeleton } from '@gravity/skeleton'

import { InstallmentDrawerProps } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentsDrawerContainerV2'
import { DrawerVariationsProps } from '@/modules/guardians/InstallmentsDrawerContainer/variations'

export const loading = ({ onCloseDrawer }: InstallmentDrawerProps): DrawerVariationsProps => {
  const fullContent = (
    <DialogPrimitives.Root open onOpenChange={open => !open && onCloseDrawer()}>
      <DialogPrimitives.Overlay backdrop />
      <DialogPrimitives.Content
        size={2}
        title="Carregando..."
        actionButton={<Skeleton width={200} height={32} />}
        cancelButton={<Skeleton width={32} height={32} />}
      >
        <div className="flex flex-col w-full h-full gap-4">
          <div className="pt-1 pb-4 space-y-4">
            <div className="flex space-x-4 items-center">
              <Skeleton width={160} height={21} />
              <Skeleton width={100} height={24} />
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <Skeleton width={160} height={21} />
              <Skeleton width={304} height={25} />
            </div>

            <div className="h-[1px] bg-colors-neutral-2 w-full" />

            <div className="flex flex-col gap-3">
              {[1, 2, 3].map(index => (
                <div key={index} className="flex justify-between items-center">
                  <Skeleton width={160} height={21} />
                  <Skeleton width={100} height={21} />
                </div>
              ))}
            </div>

            <div className="h-[1px] bg-colors-neutral-2 w-full" />

            <div className="flex flex-col gap-2">
              <div className="flex justify-between items-center">
                <Skeleton width={140} height={21} />
                <Skeleton width={120} height={21} />
              </div>
              <Skeleton width={260} height={18} />
            </div>
          </div>
        </div>
      </DialogPrimitives.Content>
    </DialogPrimitives.Root>
  )

  return {
    isDialog: true,
    fullContent,
  }
}
