import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { useApiClient } from '@/shared/hooks/useApiClient'

import { schoolEnrollmentCyclesService } from '@/shared/services/school-enrollment-cycles'
import { GetSchoolEnrollmentCyclesResponse } from '@/shared/services/school-enrollment-cycles/types'

export const useGetSchoolEnrollmentCycles = (
  schoolId: string,
  options?: UseQueryOptions<GetSchoolEnrollmentCyclesResponse>
) => {
  const { apiClient } = useApiClient()

  const service = schoolEnrollmentCyclesService(apiClient.privateApi)

  return useQuery<GetSchoolEnrollmentCyclesResponse>(
    ['school-enrollment-cycle', schoolId],
    () => service.getSchoolEnrollmentCycles(schoolId),
    options
  )
}
