import { useRenegotiationDrawer } from './hooks/useRenegotiationDrawer'
import RenegotiationContent from './RenegotiationContent'
import { InstallmentDrawerProps } from '../InstallmentsDrawerContainerV2'

export const RenegotiationDialog = ({
  installment: installmentInfo,
  onCloseDrawer,
}: InstallmentDrawerProps) => {
  const { receivable, onClose, onSubmit } = useRenegotiationDrawer({
    installmentInfo,
    onCloseDrawer,
  })

  const handleOnClose = () => {
    onClose()
    onCloseDrawer?.()
  }

  return (
    <RenegotiationContent
      onSubmit={onSubmit}
      onFinish={handleOnClose}
      receivables={[receivable]}
      selectedReceivableId={receivable.id}
      successCallback={() => null}
      setSelectedReceivableId={() => null}
    />
  )
}
