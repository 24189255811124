import { useMemo } from 'react'
import { useSchoolEnrollmentCycleContext } from '@monorepo/enrollment/contexts/SchoolEnrollmentContext'
import { LEGACY_CYCLE_YEARS } from '@monorepo/enrollment/constants/enrollment'

export const useSelectYear = () => {
  const {
    schoolEnrollmentCycles,
    updateSelectedEnrollmentCycle,
    selectedEnrollmentCycle,
  } = useSchoolEnrollmentCycleContext()
  const legacyYears = LEGACY_CYCLE_YEARS

  const selectedYear = selectedEnrollmentCycle?.reference_year ?? 0

  const years = useMemo(() => {
    const yearsSet = new Set(legacyYears)

    if (schoolEnrollmentCycles) {
      schoolEnrollmentCycles.map(enrollmentCycle => yearsSet.add(enrollmentCycle.reference_year))
    }

    return Array.from(yearsSet).sort((a, b) => b - a)
  }, [schoolEnrollmentCycles, legacyYears])

  const handleChangeSelectedYear = (year: number) => {
    const selectedEnrollment = schoolEnrollmentCycles.find(
      schoolEnrollmentCycle => schoolEnrollmentCycle.reference_year === year
    )
    if (selectedEnrollment) updateSelectedEnrollmentCycle(selectedEnrollment)
  }

  return { handleChangeSelectedYear, selectedYear, years }
}
