import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button } from '@gravity/button'
import { QuestionOutline } from '@gravity/icons'

import { useQueryClient } from '@tanstack/react-query'

import { useGuardianContractsQuery } from './hooks/useGuardianContractsQuery'
import { GuardianDetailsContractsTable } from './components/GuardianDetailsContractsTable'
import { ContractsDrawerContainer } from '../ContractsDrawerContainer/ContractsDrawerContainer'
import { useGuardianFiltersOptionsQuery } from '../GuardianDetails/hooks/useGuardianFiltersOptionsQuery'
import { TypesOfContractGlossaryDialog } from './components/TypesOfContractGlossaryDialog'
import { ContractDetailsDrawerState } from '@/escolas/components/contract/ContractDetailsDrawer/constants'
import { useGuardianContractsListQuery } from '../GuardianDetails/hooks/useGuardianContractsListQuery'
import { InstallmentsProvider } from '@/shared/contexts/installmentsContext'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useQuery } from '@/shared/hooks/useQuery'
import { FiltersDialog } from '@/shared/components/FiltersDialog'
import { useGuardiansContractsFilters } from './hooks/useGuardianContractsFilter/useFiltersActions'

export const GuardianDetailsContractsTab = () => {
  const { school } = useSelectedSchool()
  const { guardianId } = useParams<{ guardianId: string }>()
  const { query, setOnQueryParam } = useQuery()
  const queryClient = useQueryClient()
  const [currentContractId, setCurrentContractId] = useState<uuid>()
  const [showContractDetailsDrawer, setShowContractDetailsDrawer] = useState(false)
  const [showTypesOfContractDialog, setShowTypesOfContractDialog] = useState(false)
  const [drawerState, setDrawerState] = useState<ContractDetailsDrawerState>(
    ContractDetailsDrawerState.DEFAULT
  )

  const handleOpenContractDetails = (contractId: uuid) => {
    setDrawerState(ContractDetailsDrawerState.DEFAULT)
    setCurrentContractId(contractId)
    setShowContractDetailsDrawer(true)
  }

  const handleOpenCancelContractDrawer = (contractId: uuid) => {
    handleOpenContractDetails(contractId)
    setDrawerState(ContractDetailsDrawerState.CANCEL_CONTRACT)
  }

  const handleGeneratePaymentStatement = (contractId: uuid) => {
    handleOpenContractDetails(contractId)
    setDrawerState(ContractDetailsDrawerState.PAYMENT_STATEMENT)
  }

  const schoolId = school?.id ?? ''

  const { filterOptions, refetchFiltersOptions } = useGuardianFiltersOptionsQuery({
    guardianId,
    schoolId,
  })

  const {
    isFilterDialogOpen,
    filtersGroup,
    form,
    filtersArray,
    filterTable,
    handleCloseFilterDialog,
    handleOpenFilterDialog,
    handleApplyFilters,
    handleClearFilter,
    handleRemoveFilter,
    updateFieldSelection,
  } = useGuardiansContractsFilters({ filterOptions: filterOptions?.data })

  const {
    contracts,
    refetchGuardianContracts,
    isFetchGuardianContractsLoading,
    isRefetchingGuardianContracts,
  } = useGuardianContractsQuery({
    filter: filterTable,
    guardianId,
    schoolId,
  })

  const {
    guardianContractsList,
    isFetchGuardianContractsListLoading,
  } = useGuardianContractsListQuery({
    guardianId,
    schoolId,
  })

  const shouldRefetch = Boolean(query.get('refetch'))

  useEffect(() => {
    if (shouldRefetch) {
      setOnQueryParam('', 'refetch', 'replace')
      refetchFiltersOptions()
      refetchGuardianContracts()
    }
  }, [shouldRefetch])

  useEffect(() => {
    updateFieldSelection('debt_statuses', 'debtStatuses')
    updateFieldSelection('contract_statuses', 'contractStatuses')
    updateFieldSelection('product_ids', 'productsArray')
    updateFieldSelection('negotiation_id', 'negotiationId')
    updateFieldSelection('reference_years', 'referenceYears')
  }, [updateFieldSelection])

  const isLoadingContracts =
    isFetchGuardianContractsLoading ||
    isRefetchingGuardianContracts ||
    isFetchGuardianContractsListLoading

  const contractInfoButtonLabel = 'O que é situação do contrato?'

  return (
    <>
      <div className="flex flex-row w-full justify-end pt-5 pb-3 gap-4">
        <Button
          variant="ghost"
          title={contractInfoButtonLabel}
          onClick={() => setShowTypesOfContractDialog(true)}
          iconStart={<QuestionOutline />}
        >
          {contractInfoButtonLabel}
        </Button>
      </div>

      <GuardianDetailsContractsTable
        guardianId={guardianId}
        contracts={contracts?.data ?? []}
        contractsData={guardianContractsList ?? []}
        isLoading={isLoadingContracts}
        openContractDetails={contractId => handleOpenContractDetails(contractId)}
        openCancelContractDrawer={contractId => handleOpenCancelContractDrawer(contractId)}
        onGeneratePaymentStatement={contractId => handleGeneratePaymentStatement(contractId)}
        filtersArray={filtersArray}
        onClickFilterButton={handleOpenFilterDialog}
        onRemoveFilter={handleRemoveFilter}
      />
      {showContractDetailsDrawer && currentContractId && (
        <InstallmentsProvider>
          <ContractsDrawerContainer
            state={drawerState}
            contractId={currentContractId}
            refetchGuardianContracts={() => {
              queryClient.invalidateQueries(['guardian-legacy-contracts'])
              setOnQueryParam('true', 'refetch', 'replace')

              refetchGuardianContracts()
            }}
            onClose={() => setShowContractDetailsDrawer(false)}
          />
        </InstallmentsProvider>
      )}
      <TypesOfContractGlossaryDialog
        open={showTypesOfContractDialog}
        onClose={() => setShowTypesOfContractDialog(false)}
      />
      <FiltersDialog
        form={form}
        filters={filtersGroup}
        onApplyFilters={handleApplyFilters}
        closeDialog={handleCloseFilterDialog}
        visible={isFilterDialogOpen}
        resetValues={handleClearFilter}
        resetOptions={{ keepValues: true }}
        keepValuesOnClose
      />
    </>
  )
}
