import { Dispatch, SetStateAction } from 'react'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { Contract, ContractStatus, PreContractStatuses } from 'src/shared/interfaces'
import { HotjarEvents, useHotjar } from 'src/shared/hooks/useHotjar'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useJWT } from '@/shared/hooks/useJWT'
import { useHistory } from 'react-router-dom'

export type UseContractDetailsDrawerFooterProps = {
  addContract?: Dispatch<SetStateAction<boolean>>
  cancelContractQueryLoading?: boolean
  contract: Contract
  hasInvoicesWithError?: boolean
  isCheckoutSchool?: boolean
  isReenrollable?: boolean
  setIsConfirmationDialogVisible: (value: boolean) => void
  setShowInvoicesNotGeneratedDialog: (value: boolean) => void
  showAnnualPaidAmountsStatement: boolean
}

export const useContractDetailsDrawerFooter = ({
  contract,
  isCheckoutSchool,
  isReenrollable,
  setIsConfirmationDialogVisible,
  addContract,
  cancelContractQueryLoading = false,
  showAnnualPaidAmountsStatement,
  hasInvoicesWithError,
  setShowInvoicesNotGeneratedDialog,
}: UseContractDetailsDrawerFooterProps) => {
  const { sendHotjarEvent } = useHotjar()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { getUserRolesBySchool, isBackofficeUser } = useJWT()
  const { school, schoolSlug } = useSelectedSchool()
  const { push } = useHistory()

  const considerUserRole = useUnleashFlag(UnleashFlags.PE_233_ENABLE_BASIC_OPERATION_ACCESS_LEVEL)

  const isPreContract = contract?.pre_contract_status !== PreContractStatuses.EFFECTED_PCS
  const isCanceledContract = contract?.status === ContractStatus.CANCELED
  const isCanceledPreContract = isPreContract && isCanceledContract

  const hasEditContractPermission =
    isBackofficeUser || getUserRolesBySchool(school?.id ?? '').has('editar_contrato')

  const sendGenerateStatementEvent = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.CONTRACT_PAGE,
        action: 'click',
        entity: EventDispatcherEntities.PRINT_DEMONSTRATIVE_PAID_VALUES,
        customProperties: {
          $name: 'Gerar demonstrativo de pagamento',
          $contract_id: contract?.id,
        },
      })
    }
  }

  const goToContractEditPage = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.CONTRACT_DRAWER,
        action: 'click',
        customProperties: {
          $button_name: 'Editar contrato',
          $contract_type: isPreContract ? 'PRE_CONTRACT' : 'EFFECTED_CONTRACT',
        },
      })
    }

    push(`/${schoolSlug}/contratos/${contract?.id}/editar`)
  }

  const handleGenerateStatementClick = () => {
    sendHotjarEvent(HotjarEvents.GENERATE_STATEMENT)
    setIsConfirmationDialogVisible(true)
    sendGenerateStatementEvent()
  }

  const showGenerateStatementButton =
    isCanceledPreContract || (!isCheckoutSchool && showAnnualPaidAmountsStatement)

  const showEditContractButton =
    (!considerUserRole || hasEditContractPermission) && isCheckoutSchool

  return {
    showGenerateStatementButton,
    showEditContractButton,
    showAnnualPaidAmountsStatement,
    handleGenerateStatementClick,
    goToContractEditPage,
    contract,
    isReenrollable,
    hasInvoicesWithError,
    setShowInvoicesNotGeneratedDialog,
    addContract,
    cancelContractQueryLoading,
    isPreContract,
  }
}
