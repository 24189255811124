import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useApiClient } from '@/shared/hooks/useApiClient'
import { enrollmentService } from '@monorepo/enrollment/services/enrollmentService'

import { fillEnrollmentApiContracts } from '@monorepo/enrollment/utils/fillEnrollmentApiContracts'

import {
  GetGuardiansRequest,
  GetGuardiansResponse,
  StatusSummaryRequest,
  StatusSummaryResponse,
} from '@monorepo/enrollment/services/enrollmentService/types'
import { ContractResponse } from '@/shared/interfaces'

export const StatusSummaryQuery = 'status-summary'

/**
 * @deprecated Use packages/shared/src/hooks/queries/guardian/index.ts instead
 */
export const useGetGuardians = (
  queryName: string,
  props: GetGuardiansRequest,
  options?: UseQueryOptions<GetGuardiansResponse['data'], XMLHttpRequest>
) => {
  const { apiClient } = useApiClient()

  const service = enrollmentService(apiClient.getClients().basePrivateApi)

  return useQuery<GetGuardiansResponse['data'], XMLHttpRequest>(
    [queryName, props],
    async () => {
      const data = await service.fetchStudentGuardians(props)
      return data.data
    },
    {
      retry: false,
      ...options,
    }
  )
}

export const useGetStatusSummary = (
  props: StatusSummaryRequest,
  options: UseQueryOptions<StatusSummaryResponse['data'], XMLHttpRequest> = {}
) => {
  const { apiClient } = useApiClient()
  const service = enrollmentService(apiClient.getClients().basePrivateApi)

  return useQuery<StatusSummaryResponse['data'], XMLHttpRequest>(
    [StatusSummaryQuery, props],
    async () => {
      const data = await service.fetchStatusSummary(props)
      return data.data
    },
    {
      retry: false,
      ...options,
    }
  )
}

export const useGetMatriculasApiContracts = (studentId: string, schoolId: uuid) => {
  const { apiClient } = useApiClient()

  const service = enrollmentService(apiClient.getClients().basePrivateApi)

  return useQuery<ContractResponse[], XMLHttpRequest>(
    ['matriculas-api-contracts', studentId, schoolId],
    async () => {
      // TODO: matriculas-api should accept guardian_id as query param
      const nullUUID = '00000000-0000-0000-0000-000000000000'

      const matriculasApiContracts = await service.getMatriculasApiLegacyEnrollments(
        nullUUID,
        '0',
        schoolId,
        null,
        studentId
      )

      const fetcher = apiClient.getClients().privateApi
      const filledContracts = (await fillEnrollmentApiContracts(
        fetcher,
        matriculasApiContracts
      )) as ContractResponse[]

      return filledContracts
    },
    {
      enabled: !!studentId,
      retry: false,
    }
  )
}
