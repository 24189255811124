import { Text } from '@gravity/text'
import { Badge, BadgeProps } from '@gravity/badge'
import { Separator } from '@gravity/separator'
import { Callout, CalloutProps } from '@gravity/callout'

type InstallmentDialogHeaderProps = {
  calloutInfo?: {
    color?: CalloutProps['color']
    description: string
  }
  hideSeparator?: boolean
  statusInfo?: {
    color?: BadgeProps['color']
    title: string
  }
  title?: string
}

export const InstallmentDialogHeader = ({
  statusInfo,
  title,
  calloutInfo,
  hideSeparator = false,
}: InstallmentDialogHeaderProps) => {
  return (
    <div className="pt-1 pb-4 space-y-4">
      <div className="flex space-x-4 items-center">
        {title && <Text variant="body-2-regular">{title}</Text>}
        {statusInfo && (
          <Badge color={statusInfo?.color} variant="soft">
            {statusInfo?.title}
          </Badge>
        )}
      </div>
      {calloutInfo && <Callout text={calloutInfo.description} color={calloutInfo.color} />}
      {!hideSeparator && <Separator color="neutral-2" />}
    </div>
  )
}
