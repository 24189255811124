import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useApiClient } from '@/shared/hooks/useApiClient'
import { studentsService } from '@monorepo/students/services/students'

import type {
  FetchEnrollmentCycleSituationRequest,
  FetchEnrollmentCycleSituationResponse,
  FetchStudentContractsRequest,
  FetchStudentContractsResponse,
  FetchStudentEnrollmentCycleRequest,
  FetchStudentEnrollmentCycleResponse,
  FetchStudentFinancesRequest,
  FetchStudentFinancesResponse,
  FetchStudentProfileRequest,
  FetchStudentProfileResponse,
} from '@monorepo/students/services/students/types'

export const useStudentProfile = (
  { studentId }: FetchStudentProfileRequest,
  options?: UseQueryOptions<FetchStudentProfileResponse>
) => {
  const { apiClient } = useApiClient()

  const service = studentsService(apiClient.getClients().bffApi)

  return useQuery<FetchStudentProfileResponse>(
    ['students', studentId, 'profile'],
    async () => {
      const data = await service.fetchStudentProfile({
        studentId,
      })

      return data
    },
    options
  )
}

export const useStudentFinances = (
  { studentId, schoolId }: FetchStudentFinancesRequest,
  options?: UseQueryOptions<FetchStudentFinancesResponse>
) => {
  const { apiClient } = useApiClient()

  const service = studentsService(apiClient.getClients().bffApi)

  return useQuery<FetchStudentFinancesResponse>(
    ['students', studentId, 'finances'],
    async () => {
      const data = await service.fetchStudentFinances({
        studentId,
        schoolId,
      })

      return data
    },
    options
  )
}

export const useStudentEnrollmentCycle = (
  params: FetchStudentEnrollmentCycleRequest,
  options?: UseQueryOptions<FetchStudentEnrollmentCycleResponse>
) => {
  const { apiClient } = useApiClient()

  const service = studentsService(apiClient.privateApi)

  return useQuery<FetchStudentEnrollmentCycleResponse>(
    ['student-enrollment-cycle', params],
    () => service.fetchStudentEnrollmentCycle(params),
    options
  )
}

export const useStudentContracts = (
  { studentId, schoolId }: FetchStudentContractsRequest,
  options?: UseQueryOptions<FetchStudentContractsResponse>
) => {
  const { apiClient } = useApiClient()

  const service = studentsService(apiClient.getClients().bffApi)

  return useQuery<FetchStudentContractsResponse>(
    ['students', studentId, 'contracts'],
    async () => {
      const data = await service.fetchStudentContracts({
        studentId,
        schoolId,
      })

      return data
    },
    options
  )
}

export const useUpdateEnrollmentSituation = (
  { studentId, schoolId, enrollmentCycle }: FetchEnrollmentCycleSituationRequest,
  options?: UseQueryOptions<FetchEnrollmentCycleSituationResponse>
) => {
  const { apiClient } = useApiClient()

  const service = studentsService(apiClient.getClients().basePrivateApi)

  return useQuery<FetchEnrollmentCycleSituationResponse>(
    [],
    async () => {
      const data = await service.updateEnrollmentSituation(schoolId, studentId, enrollmentCycle)

      return data
    },
    options
  )
}
