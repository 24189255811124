import styled, { css } from 'styled-components'
import * as Popover from '@radix-ui/react-popover'

export const Container = styled.div<{ extend: boolean }>`
  width: 100%;
  border: 1px solid ${props => props.theme.gravity.colors['colors-border-neutral-3']};
  border-radius: ${props => props.theme.gravity.spacing[4]};

  :focus-within {
    border: 2px solid ${props => props.theme.gravity.colors['colors-border-neutral-5']};
  }

  .trigger {
    width: 100%;
    border: none;
    background: none;
    outline: none;
    overflow: hidden;
  }

  ${props =>
    props.extend &&
    css`
      border: 2px solid ${props => props.theme.gravity.colors['colors-border-neutral-5']};
      border-bottom: 0px;

      border-radius: ${props =>
          `${props.theme.gravity.spacing[4]} ${props.theme.gravity.spacing[4]}`}
        0px 0px;

      .trigger #input-search-container {
        margin: -1px 0 0 -1px;
      }
    `}

  ${props =>
    !props.extend &&
    css`
      :hover {
        border: 1px solid ${props => props.theme.gravity.colors['colors-border-neutral-5']};
      }
    `}
`

export const Content = styled(Popover.Content)`
  outline: none;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) =>
    `${theme.gravity.spacing[2]} ${theme.gravity.spacing[4]} ${theme.gravity.spacing[6]}`};
  background: ${props => props.theme.gravity.colors['colors-border-neutral-1']};
  gap: ${props => props.theme.gravity.spacing[4]};

  z-index: 1;
  width: calc(var(--radix-popover-trigger-width) + 4px);

  border: 2px solid ${props => props.theme.gravity.colors['colors-border-neutral-5']};
  border-top: 0px;

  border-radius: 0px 0px
    ${props => `${props.theme.gravity.spacing[4]} ${props.theme.gravity.spacing[4]}`};

  box-shadow: ${props => props.theme.gravity.boxShadow[3]};
`

export const ResultSearch = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${props => props.theme.gravity.spacing[4]};
`

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
