import { useHistory } from 'react-router-dom'
import { Grid, GridItem } from '@gravity/grid'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'
import { FullScreenHeader } from '@gravity/header'

import { DefaultARTemplateProps } from './types'

import { TopHeader } from '@/shared/components/TopHeader'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useLayout } from '@/shared/hooks/useLayout'
import { Footer } from '@gravity/footer'

const DefaultARTemplate = ({
  text,
  title,
  child,
  hasBackButton = true,
  previousRoute,
  footerProps,
}: DefaultARTemplateProps) => {
  const { replace } = useHistory()

  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  useLayout({
    enableHeader: false,
    enableSideMenu: !renderNewHeader,
  })

  return (
    <div className="w-full h-full">
      {renderNewHeader ? (
        <FullScreenHeader
          className="fixed left-0 top-0 right-0 z-[99]"
          title={title}
          onClose={() => replace(previousRoute)}
        />
      ) : (
        <div>{hasBackButton && <TopHeader className="px-8" backButtonLink={previousRoute} />}</div>
      )}

      <div className={`flex justify-center ${renderNewHeader ? 'mt-14' : ''}`}>
        <Grid className="pt-10">
          <GridItem
            className="flex flex-col gap-4 h-[100vh]"
            xl="4 / 10"
            lg="4 / 10"
            md="4 / 10"
            sm="2 / 8"
          >
            {!renderNewHeader && (
              <Heading variant="heading-h2-medium" className="mb-4">
                {title}
              </Heading>
            )}
            {text && <Text variant="body-1-regular">{text}</Text>}
            {child}
          </GridItem>
        </Grid>
      </div>
      {footerProps && <Footer {...footerProps} />}
    </div>
  )
}

export default DefaultARTemplate
