import styled from 'styled-components'
import { TextField } from '@gravity/text-field'

export const FormStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[6]};
`

export const TextFieldStyled = styled(TextField)`
  margin-bottom: 0;
`
