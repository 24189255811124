import { Skeleton } from '@gravity/skeleton'
import { CancelModalVariants } from '.'
import { Button } from '@gravity/button'

type CancelContractModalCancelButtonProps = {
  closeModal: () => void
  goToNextStep: () => void
  goToPreviousStep: () => void
  variant: CancelModalVariants
}

export const CancelContractModalCancelButton = ({
  closeModal,
  goToNextStep,
  goToPreviousStep,
  variant,
}: CancelContractModalCancelButtonProps) => {
  if (variant === 'loading') {
    return <Skeleton width={180} height={40} />
  }

  if (variant === 'non-cancellable') {
    return null
  }

  if (variant === 'overdue-act') {
    return (
      <Button size={2} color="error" variant="ghost" onClick={goToNextStep}>
        Cancelar contrato
      </Button>
    )
  }

  if (variant === 'overdue-acknowledge') {
    return (
      <Button size={2} color="error" variant="ghost" onClick={closeModal}>
        Voltar
      </Button>
    )
  }

  if (variant === 'reason') {
    return (
      <Button variant="ghost" onClick={goToPreviousStep}>
        Voltar
      </Button>
    )
  }

  return null
}
