import { Tooltip } from '@gravity/tooltip'
import { Table } from '@gravity/table'

import { IrregularityHeaderType, IrregularityTableProps } from './types'

export const IrregularityTable = ({
  columnHeaders,
  rows,
  ordering,
  onChangeOrdering,
}: IrregularityTableProps) => {
  const renderColumn = (text: string, tooltip?: boolean) => {
    if (tooltip)
      return (
        <Tooltip style={{ width: '400px' }} text={text}>
          <Table.TextCell>{text}</Table.TextCell>
        </Tooltip>
      )

    return <Table.TextCell>{text}</Table.TextCell>
  }

  return (
    <Table.Root>
      <Table.Head>
        {columnHeaders.map(({ key, label, sortable }: IrregularityHeaderType) => (
          <Table.HeaderCell
            key={key}
            name={key}
            sortable={sortable}
            sortOrder={
              ordering.sortBy === key && ordering.sortOrder ? ordering.sortOrder : undefined
            }
            onSort={(sortBy, sortOrder) => {
              onChangeOrdering(sortBy, sortOrder)
            }}
          >
            {label}
          </Table.HeaderCell>
        ))}
      </Table.Head>

      <Table.Body>
        {rows.map(row => (
          <Table.Row key={row.key} highlighOnHover={false}>
            {row.columns[0]?.text && renderColumn(row.columns[0]?.text, row.columns[0]?.tooltip)}

            {row.columns[1]?.text ? (
              renderColumn(row.columns[1]?.text, row.columns[1]?.tooltip)
            ) : (
              <Table.CustomCell>{row.columns[1]?.component}</Table.CustomCell>
            )}

            {row.columns[2]?.text && renderColumn(row.columns[2]?.text, row.columns[2]?.tooltip)}
            {row.columns[3]?.text && renderColumn(row.columns[3]?.text, row.columns[3]?.tooltip)}

            {row.columns[3]?.component && (
              <Table.CustomCell>{row.columns[3]?.component}</Table.CustomCell>
            )}
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  )
}
