import { Text } from '@gravity/text'
import { Link } from '@gravity/link'
import styled from 'styled-components'
import { ExternalLinkOutline } from '@gravity/icons'
import { ReactElement } from 'react'
import { useIrregularStudentsEvents } from '../hooks/useIrregularStudentsEvents'
import { CopyToClipboardIconButton } from './CopyToClipboardIconButton'
import { CopyToClipboardButton } from './CopyToClipboardButton'

import { Tooltip } from '@gravity/tooltip'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { Button } from '@gravity/button'

const BaseboardContainer = styled.div<{ newPendenciesUXEnabled?: boolean; reverse: boolean }>`
  display: flex;
  justify-content: ${({ newPendenciesUXEnabled }) =>
    newPendenciesUXEnabled ? '' : 'space-between'};
  flex-direction: ${({ newPendenciesUXEnabled, reverse }) =>
    newPendenciesUXEnabled && reverse ? 'row-reverse' : 'row'};
  margin-top: ${props => (props.newPendenciesUXEnabled ? props.theme.gravity.spacing[4] : '')};
  padding: ${props => (!props.newPendenciesUXEnabled ? props.theme.gravity.spacing[3] : '')};
  align-items: center;
  background-color: ${props =>
    props.newPendenciesUXEnabled
      ? props.theme.gravity.colors['colors-background-neutral-1']
      : props.theme.gravity.colors['colors-background-neutral-2']};
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 16px;
`

const StyledReferenceMessage = styled.div`
  display: flex;
  align-items: center;
`

const Message = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const ReferenceLink = ({ url }: { url: string }) => {
  const { sendClickRedirectLinkEvent } = useIrregularStudentsEvents()
  const newPendenciesUXEnabled = useUnleashFlag(UnleashFlags.INT_597_NEW_UX_PENDENCIES_PAGE)

  return (
    <>
      {newPendenciesUXEnabled ? (
        <>
          <Button
            variant="outline"
            size={2}
            onClick={() => {
              window.open(encodeURI(url), '_blank')
              sendClickRedirectLinkEvent()
            }}
          >
            <ExternalLinkOutline className="mr-2" />
            Ver pendências no ActiveSoft
          </Button>
        </>
      ) : (
        <StyledLink href={url} onClick={() => sendClickRedirectLinkEvent} target="blank">
          <ExternalLinkOutline />
          <Text style={{ color: 'inherit' }} variant="button-3">
            Corrigir dados no sistema
          </Text>
        </StyledLink>
      )}
    </>
  )
}

export const ReferenceMessage = ({
  copyableTooltip,
  externalId,
  identifier,
}: {
  copyableTooltip: string
  externalId: string
  identifier: string
}) => {
  const { sendClickCopyExternalIDEvent } = useIrregularStudentsEvents()
  const newPendenciesUXEnabled = useUnleashFlag(UnleashFlags.INT_597_NEW_UX_PENDENCIES_PAGE)

  if (!externalId) return <></>

  return (
    <>
      {newPendenciesUXEnabled ? (
        <>
          <Text variant="body-2-regular">
            Copie o {`${identifier}`} para corrigir os dados no seu sistema
          </Text>
          <CopyToClipboardButton
            copyableText={externalId}
            tooltipText="ID copiado"
            onClick={sendClickCopyExternalIDEvent}
          />
        </>
      ) : (
        <StyledReferenceMessage>
          <Message>
            <Text style={{ color: 'inherit' }} variant="button-3">
              {`${identifier}:`}
            </Text>
            <Text style={{ color: 'inherit' }} variant="button-3">
              {externalId}
            </Text>
          </Message>
          <Tooltip text={`Copie o ${identifier} para regularizar no seu sistema`} position="top">
            <div>
              <CopyToClipboardIconButton
                copyableText={externalId}
                tooltipText={copyableTooltip}
                onClick={sendClickCopyExternalIDEvent}
              />
            </div>
          </Tooltip>
        </StyledReferenceMessage>
      )}
    </>
  )
}

export const IrregularityTableBaseboard = ({
  actionElement,
  callToActionMsg,
  referenceLink,
}: {
  actionElement: ReactElement
  callToActionMsg: string
  referenceLink: string
}) => {
  const newPendenciesUXEnabled = useUnleashFlag(UnleashFlags.INT_597_NEW_UX_PENDENCIES_PAGE)

  return (
    <BaseboardContainer
      newPendenciesUXEnabled={newPendenciesUXEnabled}
      reverse={Boolean(referenceLink)}
    >
      {!newPendenciesUXEnabled && <Text variant="body-1-medium">{callToActionMsg}</Text>}
      {actionElement}
    </BaseboardContainer>
  )
}
