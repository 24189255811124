import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { DashboardCard } from '../DashboardCard'
import { DatePicker, TileDisabledFunctionArgs } from '@monorepo/report/components/DatePicker'
import { WeekPicker } from '@monorepo/report/components/WeekPicker'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { isIsaacPaySchool } from '@/shared/utils/isIsaacPaySchool'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

export type EnrollmentPayoutReportData = {
  ended_at: Date
  id: string
  isOpen: boolean
  payoutValue: number
  started_at: Date
}

type EnrollmentReportDashboardCardProps = {
  currentPayout?: EnrollmentPayoutReportData
  enrollmentPayouts: EnrollmentPayoutReportData[]
  hasError: boolean
  isLoading: boolean
  onChange: (enrollmentPayoutReportId: string) => void
  onRetry: () => void
}

/**
 * Specialized dashboard card for enrollment payout report
 *
 * @param props
 * @param props.currenPayout Current selected enrollment payout report
 * @param props.enrollmentPayouts List of available enrollment payout reports
 * @param props.isLoading Indicates if the loading state should be displayed
 * @param props.hasError Indicates if the error state should be displayed
 * @param props.onChange On change callback function
 * @param props.onRetry On retry callback function
 */
export const EnrollmentReportDashboardCard = ({
  currentPayout,
  enrollmentPayouts,
  isLoading,
  hasError,
  onChange,
  onRetry,
}: EnrollmentReportDashboardCardProps) => {
  const { push } = useHistory()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { school } = useSelectedSchool()
  const isIsaacPay = isIsaacPaySchool(school)

  const handleNavigateToEnrollmentPayoutReport = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.REPORT_DASHBOARD,
        name: EventDispatcherEvents.NAVIGATION_TO_ENROLLMENT_REPORT,
        action: 'click',
        customProperties: { $page_name: 'Dashboard de repasses' },
      })

    push(`repasses/matriculas${currentPayout && `?id=${currentPayout.id}`}`)
  }

  const findEnrollmentPayoutByDate = (date: Date) =>
    enrollmentPayouts.find(
      item => dayjs(item.ended_at).format('YYYY-MM-DD') === dayjs(date).format('YYYY-MM-DD')
    )

  const handleChangePayoutReportByDate = (date: Date) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.REPORT_DASHBOARD,
        name: EventDispatcherEvents.ENROLLMENT_PAYOUT_DATE_CHANGE_ON_REPORT_DASHBOARD,
        action: 'click',
      })

    const payout = findEnrollmentPayoutByDate(date)

    if (!payout) return

    onChange(payout.id)
  }

  const handleChangePayoutReportById = (id: string) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.REPORT_DASHBOARD,
        name: EventDispatcherEvents.ENROLLMENT_PAYOUT_DATE_CHANGE_ON_REPORT_DASHBOARD,
        action: 'click',
      })
    onChange(id)
  }

  const checkIfDateShouldBeDisabled = ({ view, date }: TileDisabledFunctionArgs) => {
    if (view !== 'month') return false

    const payout = findEnrollmentPayoutByDate(date)

    return !payout
  }

  const enrollmentMinDate = enrollmentPayouts[0]?.started_at
  const enrollmentMaxDate = enrollmentPayouts.at(-1)?.ended_at

  const hasSidecar = Boolean(currentPayout)

  const datePicker = isIsaacPay ? (
    <DatePicker
      valueTemplate="DD [de] MMMM [de] YYYY"
      variation="ghost"
      maxDetail="month"
      minDetail="decade"
      minDate={enrollmentMinDate}
      maxDate={enrollmentMaxDate}
      onChange={handleChangePayoutReportByDate}
      tileDisabled={checkIfDateShouldBeDisabled}
      value={dayjs(currentPayout?.ended_at).toDate()}
    />
  ) : (
    <WeekPicker<EnrollmentPayoutReportData>
      variation="ghost"
      maxDetail="year"
      minDate={enrollmentMinDate}
      maxDate={enrollmentMaxDate}
      value={currentPayout}
      options={enrollmentPayouts}
      keyExtractor={item => item.id}
      onChange={({ id }) => handleChangePayoutReportById(id)}
    />
  )

  return (
    <DashboardCard
      title="Repasse de matrículas"
      isPayoutOpen={currentPayout?.isOpen ?? true}
      payoutAmountFormat="microcents"
      payoutValue={currentPayout?.payoutValue ?? 0}
      isLoading={isLoading}
      hasError={hasError}
      onRequestDetails={handleNavigateToEnrollmentPayoutReport}
      onRetry={onRetry}
      datePicker={hasSidecar ? datePicker : undefined}
    />
  )
}
