import { Card } from '@gravity/card'
import styled from 'styled-components'

export const IsaacLogoWrapper = styled.div<{
  width?: number | string
}>`
  width: ${({ width }) => width};
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;

  path {
    fill: #fff;
  }
`

export const IsaacLogoCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 5px;

  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${props => props.theme.gravity.colors['colors-background-accent-1']};
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: ${props => props.theme.gravity.spacing[6]};
`

export const SenderTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${props => props.theme.gravity.spacing[2]};
`

export const EmailWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.theme.gravity.spacing[8]};
  box-shadow: ${props => props.theme.gravity.boxShadow[5]};
  border-radius: ${props => props.theme.gravity.borderRadius[2]};
`

export const EmailBody = styled.div`
  padding: ${props => props.theme.gravity.spacing[8]} ${props => props.theme.gravity.spacing[9]};
  > button {
    margin-top: ${props => props.theme.gravity.spacing[2]};
    width: 100%;
  }
`

export const EmailSenderWrapper = styled.div`
  margin: ${props => props.theme.gravity.spacing[4]} ${props => props.theme.gravity.spacing[10]} 0;
`

export const EmailHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${props => props.theme.gravity.spacing[4]};
  background-color: ${props => props.theme.gravity.colors['colors-background-accent-1']};
  padding: ${props => props.theme.gravity.spacing[6]} ${props => props.theme.gravity.spacing[10]};
  border-top-left-radius: ${props => props.theme.gravity.borderRadius[2]};
  border-top-right-radius: ${props => props.theme.gravity.borderRadius[2]};
  h3 {
    color: ${props => props.theme.gravity.colors['colors-text-main-5']};
  }
`

export const TitleImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[10]};
`

export const PaymentConditionBoxWrapper = styled.div`
  display: flex;
  gap: ${props => props.theme.gravity.spacing[6]};
  margin: ${props => props.theme.gravity.spacing[4]} 0;
`

export const PaymentConditionBox = styled(Card)`
  gap: ${props => props.theme.gravity.spacing[2]};
  display: flex;
  flex: none;
  flex-direction: column;
  background-color: ${props => props.theme.gravity.colors['colors-border-neutral-2']};
`

export const EmailFooter = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.theme.gravity.spacing[8]};
  padding: ${props => props.theme.gravity.spacing[8]} ${props => props.theme.gravity.spacing[9]};
  border-bottom-left-radius: ${props => props.theme.gravity.borderRadius[2]};
  border-bottom-right-radius: ${props => props.theme.gravity.borderRadius[2]};
  background-color: ${props => props.theme.gravity.colors['colors-background-accent-3']};
  span {
    color: ${props => props.theme.gravity.colors['colors-text-main-1']};
  }
`

export const FooterIconsWrapper = styled.div`
  display: flex;
  gap: ${props => props.theme.gravity.spacing[4]};
  margin: ${props => props.theme.gravity.spacing[4]} 0;
`

export const EmailSeparator = styled.div`
  width: 100%;
  margin: ${props => props.theme.gravity.spacing[8]} 0;
`
