import { Badge } from '@gravity/badge'
import { GridItem } from '@gravity/grid'

import { LoadingState } from './components/LoadingState'

import { formatCurrencyValue } from '@/shared/utils/numberFormatters'

import { TitleContainer, FlexContainer, ContentContainer, BadgeContainer } from './styles'
import { getPayoutAmountLabel } from '../../utils/getPayoutAmountLabel'
import { Heading } from '@gravity/heading'

type PayoutReportContentMainProps = {
  isLoading: boolean
  isPayoutOpen: boolean
  payoutSubtitleLabel?: string
  payoutValue: number
}

/**
 * Component used to display payout report summary data on payout report pages
 *
 * @param props
 * @param props.payoutValue Payout value in decimal
 * @param props.isPayoutOpen Indicates if payout is open
 * @param props.isLoading Indicates if loading state should be displayed
 * @param props.payoutSubtitleLabel Payout label value for open and close instances
 */
export const PayoutReportContentMain = ({
  payoutValue,
  isPayoutOpen,
  isLoading,
  payoutSubtitleLabel,
}: PayoutReportContentMainProps) => {
  const valueLabel = payoutSubtitleLabel ?? getPayoutAmountLabel(payoutValue < 0)

  if (isLoading) {
    return <LoadingState />
  }

  return (
    <GridItem xl={12} lg={12} md={12} sm={8} xs={4}>
      <FlexContainer>
        <ContentContainer>
          <BadgeContainer>
            {isPayoutOpen ? (
              <Badge variant="soft" color="warning">
                Aberto
              </Badge>
            ) : (
              <Badge variant="soft" color="success">
                Fechado
              </Badge>
            )}
          </BadgeContainer>
          <div className="flex flex-col gap-2">
            <TitleContainer>
              <Heading variant="heading-h4-medium" data-testid="payout-value-label">
                {valueLabel}
              </Heading>
            </TitleContainer>

            <Heading
              variant="heading-h2-medium"
              data-testid="payout-report-value"
              className={
                payoutValue >= 0
                  ? 'text-colors-text-main-2'
                  : 'text-colors-background-semantic-colors-error-1'
              }
            >
              {formatCurrencyValue(payoutValue)}
            </Heading>
          </div>
        </ContentContainer>
      </FlexContainer>
    </GridItem>
  )
}
