import styled from 'styled-components'
import { Text } from '@gravity/text'
import { Tooltip } from '@gravity/tooltip'

export const GrayText = styled(Text)`
  color: ${props => props.theme.gravity.colors['colors-text-main-3']};
`

export const StyledTooltip = styled(Tooltip)`
  z-index: 1400; /* Valor maior que o z-index da gaveta */
`
