import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { CreditType, Eligibility } from '@/shared/services/credit/types'
import { useApiClient } from '@/shared/hooks'
import { creditService } from '@/shared/services/credit'

const anticipationEligibilityQueryKey = 'anticipation-elegibility'

export const useAnticipationEligibility = (
  schoolId: string,
  options?: UseQueryOptions<Eligibility>
) => {
  const { apiClient } = useApiClient()

  const service = creditService(apiClient.getClients().bffApi)

  return useQuery<Eligibility>(
    [anticipationEligibilityQueryKey, schoolId],
    async () => {
      const { data } = await service.getSchoolEligibility(schoolId, CreditType.AR)
      return data
    },
    {
      retry: 1,
      ...options,
    }
  )
}
