import type { RouteDefinition } from '@/shared/core/router'
import { SchoolEnrollmentCycle } from '../pages/SchoolEnrollmentCycle'
import { CampaignsList } from '../pages/Campaigns/CampaignsList'
import { IrregularStudentEnrollments } from '../pages/IrregularStudentEnrollments'
import { NewCampaign } from '../pages/Campaigns/NewCampaign'
import { CampaignDetails } from '../pages/Campaigns/CampaignDetails'
import { AddBulkInstallment } from '../pages/Campaigns/AddBulkInstallment'
import { PersonScore } from '../pages/PersonScore'
import { EnrollmentEntryPage } from '../pages/EnrollmentEntryPage'
import { DigitalSignature } from '../pages/DigitalSignature'

export const routes: RouteDefinition[] = [
  {
    key: 'ENROLLMENT',
    path: '/matriculas',
    component: SchoolEnrollmentCycle,
    exact: true,
  },
  {
    key: 'ENROLLMENT_NEW_ENTRY_PAGE',
    path: '/matriculas/nova-home',
    component: EnrollmentEntryPage,
    exact: true,
  },
  {
    key: 'DIGITAL_SIGNATURE',
    path: '/matriculas/assinatura-digital',
    component: DigitalSignature,
    exact: true,
  },
  {
    key: 'PERSON_SCORE',
    path: '/matriculas/score',
    component: PersonScore,
    exact: true,
  },
  {
    key: 'STUDENT_ENROLLMENT',
    path: '/matriculas/:studentId',
    redirect: '/alunos/:studentId',
    exact: true,
  },
  {
    key: 'ENROLLMENT_CAMPAIGNS',
    path: '/campanhas/:year?',
    component: CampaignsList,
    exact: true,
  },
  {
    key: 'IRREGULARITIES',
    path: '/irregularidades',
    component: IrregularStudentEnrollments,
    exact: true,
  },
  {
    key: 'NEW_ENROLLMENT_CAMPAIGN',
    path: '/campanha/novo',
    component: NewCampaign,
    exact: true,
  },
  {
    key: 'ENROLLMENT_CAMPAIGN_DETAILS',
    path: '/campanhas/:year/:campaignId',
    component: CampaignDetails,
    exact: true,
  },
  {
    key: 'ENROLLMENT_CAMPAIGN_',
    path: '/campanha/:campaignId/adicionar-mensalidades-em-lote',
    component: AddBulkInstallment,
    exact: true,
  },
  {
    // TODO: This is a temporary route to redirect the old campaign details route to the new one. Should be removed sometime in the future.
    key: 'ENROLLMENT_CAMPAIGN_DETAILS_REDIRECT',
    path: '/campanha/:campaignId',
    redirect: '/campanhas/2025/:campaignId',
    exact: true,
  },
]
