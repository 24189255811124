import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { DashedArrowRight } from '@gravity/icons'
import { Button } from '@gravity/button'
import { useToast } from '@gravity/toast'
import { Skeleton } from '@gravity/skeleton'

import { useGetGuardian } from '@/shared/hooks/queries/guardian'
import type { BulkChangeContractOwnershipBody } from '@/modules/contract/services/change-ownership/types'

import * as Styled from './styles'
import type { ReviewStepProps } from './types'
import { SelectedContractCardsList } from '../SelectedContractCardsList'
import { PageFooter } from '../PageFooter'
import { useBulkChangeOwnershipMutation } from './hooks/useBulkChangeOwnershipMutation'
import { GuardianCard } from './components/GuardianCard'

export const ReviewStep = ({
  contracts,
  selectedContractIds,
  newGuardian,
  schoolId,
  goBackBtnDisabled,
  isSelected,
  currentGuardianId,
  onComplete,
  onClickGoBack,
}: ReviewStepProps) => {
  const getCurrentGuardianQuery = useGetGuardian({ guardianId: currentGuardianId, schoolId })

  const bulkChangeOwnershipMutation = useBulkChangeOwnershipMutation()
  const selectedContracts = contracts.filter(c => isSelected(c.id))

  const { toast } = useToast()

  const handleSubmit = async () => {
    if (!newGuardian) return

    const contractsPayload: BulkChangeContractOwnershipBody[] = selectedContracts.map(c => ({
      contract_id: c.id,
      new_guardian: newGuardian,
      reason: 'OTHER',
    }))
    try {
      const data = await bulkChangeOwnershipMutation.mutateAsync({
        contracts: contractsPayload,
      })

      toast({
        title: 'Troca de titularidade concluída com sucesso',
        type: 'success',
      })
      onComplete(data[0].new_guardian_id)
    } catch (_) {
      toast({
        title: 'Ocorreu um erro ao tentar transferir a titularidade',
        type: 'error',
      })
    }
  }
  return (
    <>
      <Styled.Wrapper>
        <Heading variant="heading-h4-medium">Dados dos responsáveis envolvidos na operação</Heading>

        <Styled.GuardianSection>
          {getCurrentGuardianQuery.isLoading && (
            <>
              <Skeleton height={320} fullWidth />
              <Skeleton height={320} fullWidth />
            </>
          )}

          {getCurrentGuardianQuery.isSuccess && (
            <>
              <GuardianCard arrowType="leaving" title="Sai da titularidade">
                <Heading variant="heading-h4-medium">{getCurrentGuardianQuery.data.name}</Heading>
                <Text>{getCurrentGuardianQuery.data.tax_id}</Text>
                <Text>{getCurrentGuardianQuery.data.email}</Text>
                <Text>{getCurrentGuardianQuery.data.phone_number}</Text>
              </GuardianCard>
              <GuardianCard arrowType="entering" title="Entra na titularidade">
                <Heading variant="heading-h4-medium">{newGuardian.name}</Heading>
                <Text>{newGuardian.tax_id}</Text>
                <Text>{newGuardian.email}</Text>
                <Text>{newGuardian.phone_number}</Text>
              </GuardianCard>
            </>
          )}
        </Styled.GuardianSection>

        <Styled.ContractSection>
          <Heading variant="heading-h4-medium">Contratos sendo transferidos</Heading>
          <SelectedContractCardsList selectedContractIds={selectedContractIds} />
        </Styled.ContractSection>
      </Styled.Wrapper>

      <PageFooter
        goBackBtnDisabled={goBackBtnDisabled}
        onClickGoBack={onClickGoBack}
        SubmitButton={
          <Button
            loading={bulkChangeOwnershipMutation.isLoading}
            onClick={handleSubmit}
            aria-label="Avançar para o próximo step"
            variant="solid"
            iconEnd={<DashedArrowRight aria-hidden="true" role="img" />}
          >
            Salvar
          </Button>
        }
      />
    </>
  )
}
