import styled from 'styled-components'
import { Button as GravityButton } from '@gravity/button'
import { Combobox as GravityCombobox } from '@gravity/combobox'

export const Button = styled(GravityButton)`
  height: 40px;
  margin-left: ${props => props.theme.gravity.spacing[6]};
`

export const Combobox = styled(GravityCombobox)`
  width: 100%;
`
export const SelectProductRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`
