/* eslint-disable no-debugger */
import { ReactNode } from 'react'
import { Dialog } from '@gravity/dialog'
import copy from 'copy-to-clipboard'
import { Text } from '@gravity/text'
import { Button } from '@gravity/button'
import { Link } from '@gravity/link'

import { Installment } from '@/shared/interfaces'
import { CircularLoading } from '@/shared/components/CircularLoading'
import { FileCopy } from '@gravity/icons'

type Case = {
  content: ReactNode
  isActive: boolean
  secondaryAction?: ReactNode
  showActions: boolean
  title: string
}

type Props = {
  installment?: Installment
  isError: boolean
  isLoading: boolean
  isSuccess: boolean
  onClose: () => void
}

/**
 * @deprecated The component must be migrated to the new design system.
 */

export const AcceptPaymentPlanDialog = ({
  installment,
  isError,
  isLoading,
  isSuccess,
  onClose,
}: Props) => {
  if (!installment || !installment?.receivables) return <></>

  // The installment has just been created, so its safe to get the first receivable
  const invoice = installment?.receivables[0]?.invoice

  const cases: Case[] = [
    {
      isActive: isError,
      title: 'Erro ao gerar parcela de pré-matrícula',
      content: (
        <Text variant="body-2-regular">
          A parcela de pré-matrícula não pôde ser gerada no momento. Você pode acessar a segunda via
          através da página de parcelas do responsável financeiro.
        </Text>
      ),
      showActions: true,
      secondaryAction: (
        <Button variant="solid" onClick={onClose}>
          Ok, entendi
        </Button>
      ),
    },
    {
      isActive: isLoading,
      title: 'Gerar parcela de pré-matrícula',
      content: (
        <div className="flex flex-col m-1 w-full content-center items-center">
          <div className="flex justify-center mb-4">
            <CircularLoading iconSize={30} />
          </div>
          <Text variant="body-2-regular">
            Estamos gerando a parcela de pré-matrícula. Por favor, aguarde um instante.
          </Text>
        </div>
      ),
      showActions: false,
    },
    {
      isActive: isSuccess,
      title: 'Pré-matrícula gerada com sucesso',
      content: (
        <>
          <div className="flex flex-col">
            <Text variant="body-1-medium">
              A parcela de pré-matrícula foi gerada. O responsável será notificado por e-mail, e o
              link da parcela é:
            </Text>
            {invoice ? (
              <Link
                underline="auto"
                href={invoice.checkout_url}
                target="_blank"
                rel="noreferrer"
                className="pt-6"
              >
                {invoice.checkout_url}
              </Link>
            ) : null}
          </div>
        </>
      ),
      showActions: true,
      secondaryAction: (
        <>
          <Button
            variant="ghost"
            onClick={() => copy(invoice?.checkout_url ?? '')}
            iconStart={<FileCopy />}
          >
            Copiar link
          </Button>
          <Button variant="solid" onClick={onClose}>
            Ok, entendi
          </Button>
        </>
      ),
    },
  ]

  const currentCase = cases.find(i => i.isActive)

  if (!currentCase) {
    return null
  }

  return (
    <Dialog
      open
      size={2}
      title={currentCase.title}
      content={currentCase.content}
      actionButton={currentCase.showActions && currentCase.secondaryAction}
    />
  )
}
