import { Controller, UseFormReturn, FieldError } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { SelectPrimitives } from '@gravity/select'
import { Text } from '@gravity/text'
import { TextField as GravityTextField } from '@gravity/text-field'
import { dissoc, path } from 'ramda'
import { formatCentsToReal, roundCents } from 'src/shared/utils'
import { ChangeEvent, useEffect, useState } from 'react'
import { AddDiscountsFormType } from '@/shared/interfaces'

export const defaultDiscountsOptions = [
  { name: 'Desconto permanente', value: -1, description: 'Desconto independe do vencimento' },
  { name: 'Mantêm até vencimento', value: 0, description: 'Desconto para pagamento pontual' },
  { name: 'Antes do vencimento', value: null, description: 'Desconto para pagamento adiantado' },
]

type DiscountFormFieldsProps = {
  amountDefaultValue?: number
  amountFieldName?: string
  daysBeforeDueDateDefaultValue?: number
  daysBeforeDueDateFieldName?: string
  daysBeforeDueDateLabelFieldName?: string
  descriptionDefaultValue?: string
  descriptionFieldName?: string
  form: UseFormReturn<any>
  totalAmountPaid: number
}

function DiscountFormFields({
  amountDefaultValue,
  amountFieldName = 'amount',
  daysBeforeDueDateDefaultValue,
  daysBeforeDueDateFieldName = 'days_before_due_date',
  daysBeforeDueDateLabelFieldName = 'label_days_before_due_date',
  descriptionDefaultValue,
  descriptionFieldName = 'description',
  form,
  totalAmountPaid,
}: DiscountFormFieldsProps) {
  const [
    shouldShowCustomDaysBeforeDueDateInput,
    setShouldShowCustomDaysBeforeDueDateInput,
  ] = useState<boolean>(false)
  const { control, formState, setValue, watch, getValues } = form
  const invalidAmountError: FieldError | undefined = path(
    amountFieldName.split('.'),
    formState.errors
  )
  const invalidDescription = Boolean(path(descriptionFieldName.split('.'), formState.errors))
  const discountDescription = watch(descriptionFieldName)
  const daysBeforeDueDateLabel = watch(daysBeforeDueDateLabelFieldName)
  const daysBeforeDueDate = watch(daysBeforeDueDateFieldName)
  const discountAmount = watch(amountFieldName)
  const percentageValue = (discountAmount / totalAmountPaid) * 100
  const onPercentageValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    const percent = inputValue.replace('%', '')
    const newDiscountAmount = roundCents((totalAmountPaid * +percent) / 100)
    setValue(amountFieldName, newDiscountAmount, { shouldValidate: true })
  }
  useEffect(() => {
    daysBeforeDueDate <= 0 && setShouldShowCustomDaysBeforeDueDateInput(false)
    daysBeforeDueDateLabel === 'Antes do vencimento' &&
      setShouldShowCustomDaysBeforeDueDateInput(true)
  }, [daysBeforeDueDateLabel, daysBeforeDueDate])

  useEffect(() => {
    const selectedValue = defaultDiscountsOptions.filter(
      option => option.value === daysBeforeDueDate || option.value === null
    )[0]

    if (daysBeforeDueDate !== null) {
      setValue(daysBeforeDueDateFieldName, selectedValue?.value || daysBeforeDueDate, {
        shouldValidate: true,
      })

      setValue(daysBeforeDueDateLabelFieldName, selectedValue?.name, {
        shouldValidate: true,
      })
    }
  }, [])

  const validateDiscountValue = (value: number) => {
    if (value <= 0) {
      return 'O valor do desconto não pode ser zero'
    }

    if (value >= totalAmountPaid) {
      return 'O valor do desconto aplicado não pode ser maior ou igual que o valor total de cada parcela.'
    }

    return true
  }

  const validateDiscountsSum = () => {
    const discounts: AddDiscountsFormType['discounts'] = getValues()?.discounts

    const discountsTotal = discounts.reduce((total, discount) => total + (discount.amount ?? 0), 0)

    if (discountsTotal >= totalAmountPaid) {
      return 'Descontos separados que somam 100% da parcela não são permitidos.'
    }

    return true
  }

  return (
    <>
      <div className="mb-3 mt-1">
        <div className="mb-3">
          <div>
            <Controller
              rules={{ required: true }}
              control={control}
              name={descriptionFieldName}
              defaultValue={descriptionDefaultValue}
              render={({ field, field: { value } }) => (
                <GravityTextField
                  id={descriptionFieldName}
                  aria-label="Motivo do desconto"
                  label="Motivo do desconto"
                  data-testid="add-discount-description"
                  size={2}
                  {...field}
                  value={value || discountDescription}
                  error={invalidDescription}
                  errorMessage={invalidDescription ? 'Insira um motivo para o desconto' : ''}
                />
              )}
            />
          </div>
        </div>
        <div className="flex flex-column justify-between">
          <div>
            <Controller
              rules={{
                required: true,
                validate: { validateDiscountValue, validateDiscountsSum },
              }}
              control={control}
              name={amountFieldName}
              defaultValue={amountDefaultValue}
              render={({ field, field: { onChange, value } }) => (
                <NumberFormat
                  {...dissoc('onChange', field)}
                  onValueChange={value => {
                    onChange(value.floatValue || '')
                  }}
                  aria-label="Valor"
                  data-testid="add-discount-amount"
                  id={amountFieldName}
                  customInput={GravityTextField}
                  variant="outlined"
                  label="Valor"
                  format={formatCentsToReal}
                  size={2}
                  className="w-80"
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  value={value}
                  error={Boolean(invalidAmountError)}
                  helperText={invalidAmountError?.message}
                />
              )}
            />
          </div>
          <div>
            <NumberFormat
              id={`${amountFieldName}-percent`}
              onChange={onPercentageValueChange}
              customInput={GravityTextField}
              variant="outlined"
              label="Percentual"
              aria-label="Percentual"
              data-testid="add-discount-percent"
              suffix="%"
              value={percentageValue}
              decimalScale={2}
              size={2}
              InputProps={{
                inputProps: { min: 0, max: 100 },
              }}
            />
          </div>
        </div>
      </div>
      <div className="mb-3">
        <div aria-label="Desconto válido até">
          <Controller
            rules={{ required: true }}
            control={control}
            name={daysBeforeDueDateLabelFieldName}
            render={({ field: { value, ...rest } }) => (
              <SelectPrimitives.Root
                defaultValue={value}
                onValueChange={eventValue => {
                  const selectedValue = defaultDiscountsOptions.filter(
                    option => option.name === eventValue
                  )[0]

                  setValue(daysBeforeDueDateFieldName, selectedValue?.value, {
                    shouldValidate: true,
                  })

                  setValue(daysBeforeDueDateLabelFieldName, selectedValue?.name, {
                    shouldValidate: true,
                  })
                }}
                {...rest}
              >
                <SelectPrimitives.Label label="Desconto válido até" />
                <SelectPrimitives.Trigger
                  data-testid="add-discount-type"
                  fullWidth
                  size={3}
                  className="py-2 h-auto mt-2"
                  placeholder="Desconto válido até..."
                />

                <SelectPrimitives.Content>
                  {defaultDiscountsOptions.map(({ name, value: optionValue, description }) => (
                    <SelectPrimitives.Item
                      key={optionValue}
                      value={name}
                      data-testid={name}
                      size={2}
                    >
                      <div className="flex flex-col">
                        <Text className="text-inherit">{name}</Text>
                        <Text className="text-inherit" variant="body-2-regular">
                          {description}
                        </Text>
                      </div>
                    </SelectPrimitives.Item>
                  ))}
                </SelectPrimitives.Content>
              </SelectPrimitives.Root>
            )}
          />
        </div>
      </div>
      {shouldShowCustomDaysBeforeDueDateInput ? (
        <div className="mb-3">
          <div>
            <Controller
              rules={{ required: true }}
              control={control}
              name={daysBeforeDueDateFieldName}
              defaultValue={daysBeforeDueDateDefaultValue ?? ''}
              render={({ field: { value } }) => (
                <NumberFormat
                  id={daysBeforeDueDateFieldName}
                  customInput={GravityTextField}
                  variant="outlined"
                  label="Dias antes do vencimento"
                  size={2}
                  InputProps={{
                    inputProps: { min: 1 },
                  }}
                  value={value || ''}
                  onValueChange={e => {
                    if (e.floatValue) {
                      setValue(daysBeforeDueDateFieldName, e.floatValue, { shouldValidate: true })
                    }
                  }}
                  data-testid="add-discount-days-before-due-date"
                />
              )}
            />
          </div>
        </div>
      ) : null}
    </>
  )
}

export default DiscountFormFields
