import { Badge, BadgeProps } from '@gravity/badge'
import {
  IrregularStudentStatus,
  irregularStatusTranslationDict,
  IrregularStudentStatusPayloadResponse,
} from '../types'

export type Props = {
  status: IrregularStudentStatus
}
const irregularStatusBadgeParamsDict: Record<
  IrregularStudentStatusPayloadResponse,
  { color: BadgeProps['color']; text: string }
> = {
  [IrregularStudentStatus.FINISHED]: {
    text: irregularStatusTranslationDict[IrregularStudentStatus.FINISHED],
    color: 'success',
  },
  [IrregularStudentStatus.IN_PROGRESS]: {
    text: irregularStatusTranslationDict[IrregularStudentStatus.IN_PROGRESS],
    color: 'warning',
  },
  [IrregularStudentStatus.NOT_STARTED]: {
    text: irregularStatusTranslationDict[IrregularStudentStatus.NOT_STARTED],
    color: 'error',
  },
}

export const IrregularStatusBadge = ({ status }: Props) => {
  const statusLowerCased = status.toLowerCase() as IrregularStudentStatusPayloadResponse
  if (!irregularStatusBadgeParamsDict[statusLowerCased]) {
    return null
  }

  return (
    <Badge variant="soft" color={irregularStatusBadgeParamsDict[statusLowerCased].color}>
      {irregularStatusBadgeParamsDict[statusLowerCased].text}
    </Badge>
  )
}
