import { Tooltip } from '@gravity/tooltip'
import { Text } from '@gravity/text'
import { Checkbox } from '@gravity/checkbox'

import * as Styled from './styles'
import type { ContractListItemProps } from './types'

export const ContractListItem = ({
  disabled,
  text,
  isSelected,
  onSelectContract,
}: ContractListItemProps) => {
  const isTooltipHidden = disabled === false

  return (
    <Styled.Wrapper>
      <Tooltip
        text="Esse contrato possui apenas parcelas pagas, negociadas ou vencidas."
        position="bottom"
        hidden={isTooltipHidden}
      >
        <div>
          <Checkbox
            variant="surface"
            disabled={disabled}
            checked={isSelected}
            size={1}
            onCheckedChange={onSelectContract}
          />
        </div>
      </Tooltip>

      <Styled.DescriptionWrapper disabled={disabled}>
        <Text>{text}</Text>
      </Styled.DescriptionWrapper>
    </Styled.Wrapper>
  )
}
