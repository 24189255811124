import { DatepickerPrimitives as Datepicker } from '@gravity/datepicker'
import styled from 'styled-components'

export const SplitInputRow = styled.div`
  margin-top: ${props => props.theme.gravity.spacing[4]};
  display: flex;
  justify-content: space-between;
  gap: ${props => props.theme.gravity.spacing[6]};
  > div {
    flex: 1;
  }
`

export const DatepickerTrigger = styled(Datepicker.Trigger)`
  width: 100%;
`

export const InputRow = styled.div`
  margin-top: ${props => props.theme.gravity.spacing[4]};
`

export const InputLabelWrapper = styled.div`
  margin: ${props => props.theme.gravity.spacing[8]} 0 ${props => props.theme.gravity.spacing[4]};
`
