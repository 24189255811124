import { useEffect, useState } from 'react'
import { Drawer } from '@olaisaac/design-system'
import { Text } from '@gravity/text'
import useHelperEditRegistration from './useHelperEditRegistration'
import ConfirmationEditDialog from './ConfirmationEditDialog'
import { useHistory, useLocation } from 'react-router-dom'
import { UnleashFlags, useUnleashFlag } from 'src/shared/hooks/useUnleashFlag'
import { EditGuardianFormSection } from '@/escolas/router/responsavel/[guardianId]/contratos/constants'
import ConfirmationDialog from '@/shared/components/ConfirmationDialog'
import { Student } from '../../types'
import { MomentsOfAlert } from './contants'
import { EditRegistrationDrawerProps } from './types'
import CollapseGuardian from '@/escolas/components/contract/EditRegistrationDrawer/CollapseGuardian'
import CollapseAddress from '@/escolas/components/contract/EditRegistrationDrawer/CollapseAddress'
import { EditNotification } from './EditNotification'
import { Accordion } from '@gravity/accordion'
import { Button } from '@gravity/button'
import { Skeleton } from '@gravity/skeleton'
import { Close } from '@gravity/icons'
import { IconButton } from '@gravity/icon-button'
import { Heading } from '@gravity/heading'

export const CollapsesMap = new Map<string, EditGuardianFormSection>([
  ['student', EditGuardianFormSection.STUDENT_SECTION],
  ['guardian', EditGuardianFormSection.GUARDIAN_SECTION],
  ['address', EditGuardianFormSection.ADDRESS_SECTION],
])

export const clearCollapses = (searchParams: URLSearchParams) => {
  CollapsesMap.forEach((_, key, map) => {
    searchParams.delete(map.get(key) as string)
  })
}

const EditRegistrationDrawer = ({ isOpen, guardian, refreshData }: EditRegistrationDrawerProps) => {
  const isNewEditRfNameEnabled = useUnleashFlag(UnleashFlags.GC_973_B2BCORE_122_EDIT_RF)

  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [openAlertRfEdit, setOpenAlertRfEdit] = useState<MomentsOfAlert | null>(null)

  const history = useHistory()
  const location = useLocation()
  const { search } = location
  const searchParams = new URLSearchParams(search)

  const students: Student[] = guardian.students

  const studentsIds = students?.map(student => student.id)

  const handleOnClose = () => {
    clearCollapses(searchParams)
    searchParams.delete('abrirCadastro')
    searchParams.delete('ativarEdicao')
    history.push({ search: searchParams.toString() })
  }

  const {
    form,
    handleMenuItemClick,
    isZipValid,
    onZipFieldFilled,
    isFirstRender,
    submitHandler,
    isFeedbackDialogOpen,
    handleCloseFeedbackDialog,
    isStudentsFetched,
  } = useHelperEditRegistration(guardian, studentsIds, handleOnClose)

  useEffect(() => {
    if (isStudentsFetched) {
      handleMenuItemClick()
      setOpenAlertRfEdit(MomentsOfAlert.START_EDIT)
    }
  }, [isStudentsFetched])

  const handleStartRfEditAlert = () => {
    setOpenAlertRfEdit(null)
    if (!searchParams.get('ativarEdicao')) {
      clearCollapses(searchParams)
      searchParams.append(EditGuardianFormSection.GUARDIAN_SECTION, 'true')
      searchParams.append('ativarEdicao', 'true')
      history.push({ search: searchParams.toString() })
    }
  }

  const handleConfirmRfEditButton = () => {
    setOpenAlertRfEdit(MomentsOfAlert.END_EDIT)
  }

  const handleCancelRfEditButton = () => {
    searchParams.delete('ativarEdicao')
    handleOnClose()
    history.push({ search: searchParams.toString() })
  }

  const handleCloseConfirmationEditDialog = () => {
    setIsVisible(false)
    handleCloseFeedbackDialog()
  }

  const handleCloseEditViewMode = () => {
    searchParams.delete('ativarEdicao')
    setOpenAlertRfEdit(null)
    history.push({ search: searchParams.toString() })
  }

  const handleConfirmRfEditAlert = () => {
    setOpenAlertRfEdit(null)
    form.handleSubmit(submitHandler)()
  }

  const handleConfirmationDialog = () => {
    setIsVisible(true)
  }

  const {
    formState: { isValid, isDirty, isSubmitting },
    trigger,
  } = form

  const isEdit = !isNewEditRfNameEnabled || Boolean(searchParams.get('ativarEdicao'))
  const isStartEdit = openAlertRfEdit === MomentsOfAlert.START_EDIT

  return (
    <Drawer open={isOpen}>
      {isStudentsFetched ? (
        <>
          <ConfirmationEditDialog
            form={form}
            submitHandler={submitHandler}
            isVisible={isVisible}
            handleClose={handleCloseConfirmationEditDialog}
            isFeedbackDialogOpen={isFeedbackDialogOpen}
            handleCloseFeedbackDialog={handleCloseFeedbackDialog}
            refreshData={refreshData}
          />

          <div className="p-6">
            <div className="flex flex-1 justify-end items-end">
              <IconButton onClick={handleOnClose} variant="ghost" size={1}>
                <Close />
              </IconButton>
            </div>
            <Heading variant="heading-h4-regular">
              {isNewEditRfNameEnabled ? 'Dados cadastrais' : 'Editar Cadastro'}
            </Heading>
          </div>

          <EditNotification isEdit={isEdit} isNewEditRfNameEnabled={isNewEditRfNameEnabled} />
          <div className="overflow-auto flex-1">
            <Accordion.Root type="single" collapsible onValueChange={() => trigger()}>
              <CollapseGuardian edit={isEdit} guardian={guardian} form={form} />
              <CollapseAddress
                edit={isEdit}
                form={form}
                isZipValid={isZipValid}
                isFirstRender={isFirstRender}
                onZipFieldFilled={onZipFieldFilled}
              />
            </Accordion.Root>
          </div>

          <div className="flex flex-col p-6 gap-4 border-solid border-t border-x-0 border-b-0 border-colors-border-neutral-3">
            {isNewEditRfNameEnabled ? (
              isEdit && (
                <>
                  <Button
                    disabled={!(isValid && isDirty)}
                    fullWidth
                    onClick={handleConfirmRfEditButton}
                    loading={isSubmitting}
                  >
                    Concluir edição
                  </Button>
                  <Button variant="ghost" fullWidth onClick={handleCancelRfEditButton}>
                    Cancelar
                  </Button>
                </>
              )
            ) : (
              <Button
                disabled={!isValid || !isDirty}
                fullWidth
                onClick={handleConfirmationDialog}
                loading={isSubmitting}
              >
                Salvar
              </Button>
            )}
          </div>
          <ConfirmationDialog
            isVisible={Boolean(openAlertRfEdit)}
            onClose={isStartEdit ? handleCloseEditViewMode : () => setOpenAlertRfEdit(null)}
            submitHandler={isStartEdit ? handleStartRfEditAlert : handleConfirmRfEditAlert}
            size={2}
            title={`Novas faturas podem ser geradas, você quer ${
              isStartEdit ? 'continuar' : 'concluir'
            } a edição?`}
            backButtonLabel={isStartEdit ? 'Apenas visualizar' : 'Voltar'}
            buttonLabel="Continuar"
          >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.8rem' }}>
              <Text variant="body-2-regular">
                Antes de você continuar, saiba que ao editar nome e/ou endereço, faturas anteriores
                serão reemitidas e enviadas novas faturas para o e-mail do responsável financeiro.
              </Text>
              <Text variant="body-2-regular">
                A alteração de e-mail e/ou telefone não irá atualizar faturas.{' '}
              </Text>
              <Text variant="body-2-regular">Você deseja continuar?</Text>
            </div>
          </ConfirmationDialog>
        </>
      ) : (
        <div className="flex flex-col p-4 gap-4">
          <Skeleton fullWidth height={100} />
          <Skeleton fullWidth height={100} />
          <Skeleton fullWidth height={50} />
          <Skeleton fullWidth height={50} />
        </div>
      )}
    </Drawer>
  )
}

export default EditRegistrationDrawer
