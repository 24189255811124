import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { Dialog } from '@gravity/dialog'
import { Select } from '@gravity/select'
import { Callout } from '@gravity/callout'
import { Button } from '@gravity/button'
import { useToast } from '@gravity/toast'

import {
  updatePaymentDisputeItemStatusSchema,
  UpdatePaymentDisputeItemStatusSchemaForm,
} from './schema'
import { PaymentDisputeItemState } from '@/shared/models/enums/PaymentDisputeItemState.enum'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutateOnUpdatePaymentDisputeItemStatus } from '@/shared/hooks/queries/usePaymentDisputeQueries'
import { queryClient } from '@/shared/contexts/ReactQuery'
import { EventIdentifierName } from '@/shared/models/enums/EventIdentifierName.enum'

type PaymentDisputeItemDenyProps = {
  onClose: () => void
  onSendEvent: (eventName: EventIdentifierName, buttonName: string) => void
  open: boolean
  paymentDisputeId: string
  paymentDisputeItemId: string
}

const denialReasonOptions = [
  {
    label: 'Não reconheço o comprovante',
    value: 'NOT_RECOGNIZE_RECEIPT',
  },
  {
    label: 'O comprovante é de outra fatura',
    value: 'RECEIPT_FOR_ANOTHER_INVOICE',
  },
  {
    label: 'O comprovante está rasurado',
    value: 'RECEIPT_SMUDGED',
  },
  {
    label: 'O contrato está duplicado',
    value: 'CONTRACT_DUPLICATED',
  },
  {
    label: 'Outro motivo',
    value: 'OTHER_REASON',
  },
]

export const PaymentDisputeItemDeny = ({
  open,
  onClose,
  paymentDisputeId,
  paymentDisputeItemId,
  onSendEvent,
}: PaymentDisputeItemDenyProps) => {
  const { toast } = useToast()

  const {
    reset,
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<UpdatePaymentDisputeItemStatusSchemaForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(updatePaymentDisputeItemStatusSchema),
    defaultValues: {
      id: paymentDisputeId,
      payment_dispute_item_id: paymentDisputeItemId,
      status: PaymentDisputeItemState.DENIED,
      denial_reason: '',
    },
  })

  const {
    mutateAsync: mutateOnUpdatePaymentDisputeItemStatus,
    isLoading: isLoadingOnUpdatePaymentDisputeItemStatus,
  } = useMutateOnUpdatePaymentDisputeItemStatus({
    onSuccess() {
      queryClient.invalidateQueries(['payment-dispute-items'])
      queryClient.invalidateQueries(['payment-disputes'])

      toast({ type: 'success', title: 'Alterações salvas' })
      reset()
      onClose()
    },
    onError: () => toast({ type: 'error', title: 'Erro ao salvar alterações' }),
  })

  const handleClose = (buttonName: 'Fechar' | 'Cancelar') => {
    onSendEvent(EventIdentifierName.CLOSE_PAYMENT_DISPUTE_ITEM_DENY_MODAL, buttonName)

    reset()
    onClose()
  }

  const onSubmit: SubmitHandler<UpdatePaymentDisputeItemStatusSchemaForm> = async data => {
    onSendEvent(EventIdentifierName.SUBMIT_PAYMENT_DISPUTE_ITEM_DENY_MODAL, 'Salvar')

    await mutateOnUpdatePaymentDisputeItemStatus({
      denial_reason: data.denial_reason,
      id: data.id,
      payment_dispute_item_id: data.payment_dispute_item_id,
      status: PaymentDisputeItemState.DENIED,
    })
  }

  return (
    <Dialog
      size={2}
      open={open}
      onOpenChange={() => handleClose('Fechar')}
      title="Negar contestação"
      description={
        <Callout text="Ao negar essa contestação, o time do isaac continuará cobrando o responsável financeiro, o que pode gerar consequências como a negativação deste responsável." />
      }
      content={
        <Controller
          control={control}
          name="denial_reason"
          render={({ field }) => (
            <Select
              size={3}
              variant="surface"
              label="Motivo da recusa"
              placeholder="Selecione um motivo"
              fullWidth
              value={field.value}
              onValueChange={value => field.onChange(value)}
              options={denialReasonOptions}
            />
          )}
        />
      }
      actionButton={
        <Button
          loading={isLoadingOnUpdatePaymentDisputeItemStatus}
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
          size={2}
        >
          Salvar
        </Button>
      }
      cancelButton={
        <Button onClick={() => handleClose('Cancelar')} size={2} variant="ghost">
          Cancelar
        </Button>
      }
    />
  )
}
