import { Text } from '@gravity/text'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ContractDetailsDrawer } from '@/escolas/components/contract/ContractDetailsDrawer/ContractDetailsDrawer'
import { ContractDetailsDrawerState } from '@/escolas/components/contract/ContractDetailsDrawer/constants'
import { InvoicesNotGeneratedDialog } from '@/escolas/components/contract/InvoicesNotGeneratedDialog/InvoicesNotGeneratedDialog'
import { NewContractFormDefaults } from '@/escolas/components/contract/create/types'
import { useContract, useNavigation } from '@/escolas/hooks'
import { useGetContract } from '@/escolas/hooks/useGetContract'
import { useGetInvoicesByIds } from '@/escolas/hooks/useGetInvoicesByIds'
import ConfirmationDialog from '@/shared/components/ConfirmationDialog'
import { Contract } from '@/shared/interfaces'

import { getInvoicesIds } from '../utils/getInvoicesIds'
import { IncomeTaxStatementDialog } from './components/IncomeTaxStatementDialog'

type ContractsDrawerContainerProps = {
  contractId: uuid
  onClose: () => void
  refetchGuardianContracts: () => void
  state?: ContractDetailsDrawerState
}

export const ContractsDrawerContainer = ({
  contractId,
  onClose,
  state = ContractDetailsDrawerState.DEFAULT,
}: ContractsDrawerContainerProps) => {
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState<boolean>(
    state === ContractDetailsDrawerState.PAYMENT_STATEMENT
  )
  const [drawerState, setDrawerState] = useState<ContractDetailsDrawerState>(state)
  const [showInvoicesNotGeneratedDialog, setShowInvoicesNotGeneratedDialog] = useState<boolean>(
    false
  )

  const [
    showChangeContractOwnershipDialog,
    setShowChangeContractOwnershipDialog,
  ] = useState<boolean>(state === ContractDetailsDrawerState.CHANGE_CONTRACT_OWNERSHIP)
  const { getNavigationUrl, schoolId } = useNavigation()
  const history = useHistory<NewContractFormDefaults>()
  let invoicesWithError: any[] | undefined = []

  const { setContract } = useContract()

  const { contract, isFetched } = useGetContract(contractId, schoolId) as {
    contract: Contract
    isFetched: boolean
    refetch: () => void
  }

  const [invoiceIds, setInvoiceIds] = useState<uuid[]>([])
  if (schoolId) {
    const invoicesById = useGetInvoicesByIds(invoiceIds, schoolId, false)
    invoicesWithError = invoicesById.invoicesWithError
  }

  // --- Rematrícula -->  Usado em: ContractActionsCheckout_old.tsx |
  const reEnrollStudent = () => {
    const path = getNavigationUrl({
      path: `contratos`,
    })
    const params = new URLSearchParams()

    params.append('guardianId', contract.guardian_id)
    params.append('studentId', contract.student_id)

    history.push({
      pathname: `${path}/novo`,
      search: params.toString(),
    })
  }

  const redirectToChangeContractOwnership = () => {
    setShowChangeContractOwnershipDialog(false)
    history.push(getNavigationUrl({ path: `/contratos/${contract?.id}/alterar-titularidade` }))
  }

  const handleContractDrawerClose = () => {
    onClose()
  }

  const hasInvoicesWithError =
    Boolean(invoicesWithError?.length && invoicesWithError?.length > 0) ?? false

  useEffect(() => {
    if (contract) {
      setContract(contract)
      setInvoiceIds(getInvoicesIds(contract))
    }
  }, [contract])

  /* Modal Troca de Titularidade */
  if (
    drawerState === ContractDetailsDrawerState.CHANGE_CONTRACT_OWNERSHIP ||
    showChangeContractOwnershipDialog
  )
    return (
      <ConfirmationDialog
        isVisible
        size={2}
        onClose={() => {
          setShowChangeContractOwnershipDialog(false)
          onClose()
        }}
        submitHandler={redirectToChangeContractOwnership}
        backButtonLabel="Voltar"
        buttonLabel="Continuar"
        title="O contrato anterior será cancelado a partir das parcelas a vencer:"
      >
        <div className="flex flex-col gap-4">
          <Text variant="body-1-regular">
            Antes de você continuar, saiba que ao realizar uma alteração de titularidade{' '}
            <b>o contrato atual será cancelado a partir das parcelas a vencer</b> e ficará inativo
            (mas não se preocupe, você ainda poderá visualizá-lo).
          </Text>
          <Text variant="body-1-regular">
            Um <b>novo contrato será criado</b> com os dados do novo responsável financeiro;
          </Text>
          <Text variant="body-1-regular">
            <b>
              Parcelas com vencimento para o dia de hoje, ficarão vinculadas ao contrato anterior.
              Novos boletos não serão gerados.
            </b>
          </Text>
          <Text variant="body-1-regular">Você deseja continuar?</Text>
        </div>
      </ConfirmationDialog>
    )

  /* Modal Gerar Demonstrativo */
  if (drawerState === ContractDetailsDrawerState.PAYMENT_STATEMENT || isConfirmationDialogVisible)
    return (
      <IncomeTaxStatementDialog
        setIsOpen={() => setIsConfirmationDialogVisible(false)}
        onClose={onClose}
      />
    )

  return (
    <>
      {/* Default Drawer */}
      <ContractDetailsDrawer
        // Dados e refetch
        contract={contract}
        // Controle do Drawer
        isOpen={isFetched}
        onClose={handleContractDrawerClose}
        drawerState={drawerState}
        setDrawerState={setDrawerState}
        // Rematrícula
        isReenrollable={contract?.isReenrollable}
        addContract={() => reEnrollStudent()}
        // Cashier
        hasInvoicesWithError={hasInvoicesWithError}
        setShowInvoicesNotGeneratedDialog={setShowInvoicesNotGeneratedDialog}
        // Gerar Demonstrativo
        setIsConfirmationDialogVisible={setIsConfirmationDialogVisible}
        // Categoria da escola
        isCheckoutSchool
      />

      {/* Modal Cashier */}
      <InvoicesNotGeneratedDialog
        isOpen={showInvoicesNotGeneratedDialog}
        setIsOpen={setShowInvoicesNotGeneratedDialog}
      />
    </>
  )
}
