import { ChoiceChip } from '@gravity/chip'
import type { Control } from 'react-hook-form'
import { useFieldArray } from 'react-hook-form'
import { FilterForm } from '../schemas/filterSchemas'

interface Props {
  control: Control<FilterForm>
  filterType: 'debtStatus'
}

export const FilterChip = ({ control, filterType }: Props) => {
  const { fields, update } = useFieldArray({
    control,
    name: filterType,
  })

  const handleChoiceSelected = (index: number) => {
    update(index, {
      ...fields[index],
      selected: !fields[index]?.selected || false,
    })
  }

  return (
    <div className="flex flex-wrap gap-2">
      {fields.map((field, index) => (
        <ChoiceChip
          key={field.value}
          text={field.text}
          value={field.value}
          selected={field.selected}
          onSelected={() => handleChoiceSelected(index)}
        />
      ))}
    </div>
  )
}
