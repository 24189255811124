import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import type { ReenrollmentStatus } from '@/shared/interfaces'

import { useStudentsEnrollmentsFilter } from '@monorepo/enrollment/hooks/useStudentsEnrollmentsFilter'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EnrollmentEventDispatcherEvents } from '@/shared/models/enums/EnrollmentEventDispatcherEvents.enum'
import * as Styled from './styles'
import { Badge } from '@gravity/badge'
import { statusParamsDict } from '@monorepo/enrollment/components/StatusBadge'
import { Text } from '@gravity/text'
import { Skeleton } from '@gravity/skeleton'
import { Heading } from '@gravity/heading'

export type BigNumbersCardProps = {
  isLoading: boolean
  status: ReenrollmentStatus
  value?: number
}

export const BigNumbersCard = ({ status, value, isLoading }: BigNumbersCardProps) => {
  const { updateFilter, studentsEnrollmentsFilter } = useStudentsEnrollmentsFilter()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const isSelected = studentsEnrollmentsFilter.enrollmentStatus.includes(status)
  if (isLoading)
    return (
      <Styled.LoadingCard>
        <Skeleton fullWidth />
        <Skeleton fullWidth />
      </Styled.LoadingCard>
    )
  return (
    <Styled.CardContainer
      role="option"
      aria-selected={isSelected}
      aria-label={statusParamsDict[status]?.text ?? ''}
      onClick={() => {
        const activeFilters = studentsEnrollmentsFilter.enrollmentStatus
        const nextFilters = activeFilters.includes(status)
          ? activeFilters.filter(e => e !== status)
          : [...activeFilters, status]

        updateFilter({
          enrollmentStatus: nextFilters,
          enrollmentSubStatus: studentsEnrollmentsFilter.enrollmentSubStatus,
        })
        isInitialized &&
          eventDispatcherClient.sendEvent({
            scope: EventDispatcherEventScopes.ENROLLMENT_CYCLE,
            name: EnrollmentEventDispatcherEvents.CLICKED,
            action: 'click',
            customProperties: { $value: status, $Card_name: 'BIG_NUMBERS_CARD' },
          })
      }}
      isSelected={isSelected}
    >
      <Styled.BigNumberWrapper>
        <Heading variant="heading-h4-medium">{value}</Heading>
        <Text>alunos</Text>
      </Styled.BigNumberWrapper>
      <Styled.StatusWrapper>
        <Badge
          variant={statusParamsDict[status]?.variant ?? 'soft'}
          color={statusParamsDict[status]?.color}
        >
          {statusParamsDict[status]?.text ?? ''}
        </Badge>
      </Styled.StatusWrapper>
    </Styled.CardContainer>
  )
}
