import { Dialog } from '@gravity/dialog'
import { useRevokeContractMutation, useInstallmentsToUpdateQuery } from '../../hooks/queries'
import { useApiClient } from '@/shared/hooks/useApiClient'
import { newContractAPI } from '@/modules/contract/services'
import { ContractCancellationReason, InstallmentStatuses } from '@/shared/interfaces'
import { Installment } from '@/modules/contract/services/types'
import { useEffect, useState } from 'react'
import { formatCancelContractModalTitle } from './CancelContractModalTitle'
import { CancelContractModalActionButton } from './CancelModalActionButton'
import { CancelContractModalCancelButton } from './CancelContractModalCancelButton'
import { CancelContractModalContent } from './CancelContractModalContent'
import { CancelContractModalDescription } from './CancelContractModalDescription'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { UseCancelContractModalReturn } from './useCancelContractModal'
import { AxiosError } from 'axios'
import { useToast } from '@gravity/toast'
import { v4 as uuidv4 } from 'uuid'

export type CancelModalVariants =
  | 'loading'
  | 'non-cancellable'
  | 'overdue-acknowledge'
  | 'overdue-act'
  | 'reason'

export type CancelModalOverdueVariants = Extract<
  CancelModalVariants,
  'overdue-acknowledge' | 'overdue-act'
>

type CancelContractModalProps = {
  onError?: (error: unknown) => void
  onSuccess?: () => void
  overdueVariant?: CancelModalOverdueVariants
  state: UseCancelContractModalReturn
}

export const installmentHasInterestOrFine = (installment: Installment) => {
  return installment.current_fine + installment.current_interest > 0
}

export const CancelContractModal = ({
  onError,
  onSuccess,
  overdueVariant = 'overdue-act',
  state,
}: CancelContractModalProps) => {
  const { toast } = useToast()

  const { apiClient } = useApiClient()
  const api = newContractAPI(apiClient.privateApi)
  const [cancelContractState, setCancelContractState] = useState<CancelModalVariants>('loading')

  const { contractId, isOpen, open, close, installmentsIdsToPreCancel } = state

  const { school } = useSelectedSchool()

  const { data: response, status, fetchStatus } = useInstallmentsToUpdateQuery(
    api,
    {
      contractId: contractId ?? '',
      schoolId: school?.id ?? '',
    },
    { enabled: !!(contractId && school?.id) }
  )

  const installments = response?.data?.installments ?? []

  const hasOverdueInstallments = installments.some(installmentHasInterestOrFine)

  const is_installments_left_without_canceling = !!response?.data
    ?.is_installments_left_without_canceling

  const selectModalState = () => {
    if (status === 'loading' || fetchStatus === 'fetching') {
      return setCancelContractState('loading')
    }

    if (status === 'success') {
      if (response?.data?.can_cancel_contract === false) {
        return setCancelContractState('non-cancellable')
      }

      if (hasOverdueInstallments || is_installments_left_without_canceling) {
        return setCancelContractState(overdueVariant)
      }

      setCancelContractState('reason')
    }
  }

  const handleOpenChange = (value: boolean) => {
    if (!value) {
      return close()
    }

    open()
  }

  useEffect(() => {
    if (isOpen) {
      selectModalState()
    }
  }, [status, fetchStatus, isOpen])

  // Cancel Contract
  const [radioOption, setRadioOption] = useState('')
  const [otherReasonDescription, setOtherReasonDescription] = useState('')
  const { isLoading, mutateAsync: executeRevokeContract } = useRevokeContractMutation({
    onSuccess: () => {
      close()
      onSuccess?.()
    },
    onError: error => {
      close()
      onError?.(error)
    },
  })

  const otherReasonDisabled = radioOption !== ContractCancellationReason.OTHER

  const canSubmit = radioOption !== '' && (otherReasonDescription !== '' || otherReasonDisabled)

  const handleSubmit = async () => {
    if (!school) return

    try {
      if (!response?.data) throw new Error('Contract not found')

      const correlationID = uuidv4()

      await executeRevokeContract({
        contract: response.data,
        correlationID,
        installmentsIdsToPreCancel,
        schoolId: school.id,
        description: otherReasonDescription,
        reason: radioOption as ContractCancellationReason,
      })

      toast({
        title: 'Contrato cancelado com sucesso.',
        type: 'success',
      })
    } catch (error) {
      if (
        (error as AxiosError)?.response?.status === 403 &&
        (error as AxiosError)?.response?.data?.errors[0]?.message ===
          'forbidden: school has reached the contract cancellation limit'
      ) {
        toast({
          style: { width: '440px' },
          type: 'error',
          title: 'Cancelamento de contrato indisponível',
          description:
            'Por questões de segurança, esta ação está indisponível. Caso necessite realizar essa operação, procure o time de suporte.',
        })
      } else {
        toast({
          title: 'Não foi possível cancelar esse contrato',
          type: 'error',
        })
      }
    }
  }

  const overdueTuitionInstallments =
    installments?.filter(ins => ins.status === InstallmentStatuses.OVERDUE) ?? []

  return (
    <Dialog
      size={3}
      backdrop
      modal
      open={isOpen}
      onOpenChange={handleOpenChange}
      title={formatCancelContractModalTitle({
        variant: cancelContractState,
        is_installments_left_without_canceling,
        hasOverdueInstallments,
        installmentsQuantity: overdueTuitionInstallments?.length ?? 0,
      })}
      description={<CancelContractModalDescription variant={cancelContractState} />}
      content={
        <CancelContractModalContent
          hasOverdueInstallments={hasOverdueInstallments}
          is_installments_left_without_canceling={is_installments_left_without_canceling}
          installments={overdueTuitionInstallments}
          radioOption={radioOption}
          otherReasonDescription={otherReasonDescription}
          setOtherReasonDescription={setOtherReasonDescription}
          setRadioOption={setRadioOption}
          variant={cancelContractState}
        />
      }
      actionButton={
        <CancelContractModalActionButton
          canSubmit={canSubmit}
          closeModal={close}
          contractId={contractId}
          goToNextStep={() => setCancelContractState('reason')}
          onSubmit={handleSubmit}
          submitting={isLoading}
          variant={cancelContractState}
        />
      }
      cancelButton={
        <CancelContractModalCancelButton
          goToNextStep={() => setCancelContractState('reason')}
          goToPreviousStep={() => {
            if (hasOverdueInstallments || is_installments_left_without_canceling) {
              return setCancelContractState(overdueVariant)
            }

            close()
          }}
          closeModal={close}
          variant={cancelContractState}
        />
      }
    />
  )
}
