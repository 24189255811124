import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Heading } from '@gravity/heading'
import { Button } from '@gravity/button'
import { Grid, GridItem } from '@gravity/grid'
import { ArrowLeft } from '@gravity/icons'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useGetFamilyRegularizationReport } from '../../hooks/queries/familyRegularizationReport'
import { CircularLoadingPlaceholder } from '@/shared/components/CircularLoadingPlaceholder'
import { Error } from '@/shared/components/Error'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useLayout } from '@/shared/hooks/useLayout'

import * as Styled from './styles'

export const FamilyRegularizationReport = () => {
  const { schoolSlug } = useSelectedSchool()
  const { push } = useHistory()
  const reportQuery = useGetFamilyRegularizationReport()
  const [iframeStatus, setIframeStatus] = useState<typeof reportQuery.status | undefined>('loading')

  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  useLayout({ enableSideMenu: true, enableHeader: renderNewHeader })

  const goToReportsList = () => push(`/${schoolSlug}/relatorios`)

  if (reportQuery.status === 'error') return <Error />
  if (reportQuery.status === 'loading') return <CircularLoadingPlaceholder />

  return (
    <div className="w-full h-screen max-h-screen flex justify-center">
      <Grid>
        <GridItem xl={12} lg={12} md={12} sm={8} xs={4}>
          <Styled.Header>
            <Button variant="ghost" iconStart={<ArrowLeft />} onClick={goToReportsList}>
              Voltar
            </Button>
            {renderNewHeader ? (
              <Heading variant="heading-h2-medium" className="title">
                Relatório de regularização das famílias
              </Heading>
            ) : (
              <Heading variant="heading-h3-medium" className="title">
                Relatório de regularização das famílias
              </Heading>
            )}
          </Styled.Header>

          {iframeStatus === 'loading' ? <CircularLoadingPlaceholder /> : null}

          <Styled.Iframe
            id="fidc-report"
            src={reportQuery.data?.url}
            style={{ display: iframeStatus === 'success' ? 'block' : 'none' }}
            onLoad={() => setIframeStatus('success')}
          />
        </GridItem>
      </Grid>
    </div>
  )
}
