import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.gravity.spacing[4]} 0;

  transition: max-width 0.3s cubic-bezier(0.42, 0.29, 0.39, 0.83);

  .grid-container {
    row-gap: ${({ theme }) => theme.gravity.spacing[6]};
  }

  .new-product-button {
    display: flex;
    justify-content: right;
  }
`
