import { InteractiveCard } from '@gravity/interactive-card'
import styled from 'styled-components'

type CardContainerProps = {
  isSelected: boolean
}

export const CardContainer = styled(InteractiveCard)<CardContainerProps>`
  border-color: ${props =>
    props.isSelected && props.theme.gravity.colors['colors-interaction-primary-4']};
  background-color: ${props =>
    props.isSelected && props.theme.gravity.colors['colors-background-accent-3']};
`

export const StatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const BigNumberWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: ${props => props.theme.gravity.spacing[2]};
`

export const LoadingCard = styled.div`
  display: flex;
  height: 112px;
  padding: ${props => props.theme.gravity.spacing[3]};
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: ${props => props.theme.gravity.spacing[2]};
  border-radius: ${props => props.theme.gravity.borderRadius[2]};
  border: 1px solid ${props => props.theme.gravity.colors['colors-border-neutral-3']};
`
