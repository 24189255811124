import { PaymentDisputeSummaryCard } from '../PaymentDisputeSummaryCard'
import { GridItem } from '@gravity/grid'

export type PaymentDisputeSummaryProps = {
  loading?: boolean
  totalFinish?: number
  totalInReview?: number
  totalPending?: number
}

export const PaymentDisputeSummary = ({
  loading,
  totalFinish,
  totalInReview,
  totalPending,
}: PaymentDisputeSummaryProps) => {
  return (
    <>
      <GridItem className="summary" xl={4} lg={4} md={4} sm="1 / 4" xs={4}>
        <PaymentDisputeSummaryCard loading={loading} status="pending" total={totalPending} />
      </GridItem>
      <GridItem className="summary" xl={4} lg={4} md={4} sm="4 / 7" xs={4}>
        <PaymentDisputeSummaryCard loading={loading} status="in_review" total={totalInReview} />
      </GridItem>
      <GridItem className="summary" xl={4} lg={4} md={4} sm="7 / 9" xs={4}>
        <PaymentDisputeSummaryCard loading={loading} status="finish" total={totalFinish} />
      </GridItem>
    </>
  )
}
