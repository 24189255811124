import { useOrdering } from '@/shared/hooks/useOrdering'
import { Table } from '@gravity/table'
import {
  ReportTableProps,
  FilterItem,
  TableData,
  SearchName,
  DEFAULT_PAGINATION,
  DEFAULT_SEARCH_NAME,
  Pagination,
  PaginationEvents,
} from './types'
import { Text } from '@gravity/text'
import { useQueryParams } from '@monorepo/report/hooks/useReportQueryParams'
import { SortOrderLowerCase } from '@/shared/hooks/useOrdering/types'
import {
  ComponentTypes,
  FilterKeys,
  TransactionEvents,
  TransactionEventType,
} from '@monorepo/report/models/report'
import { useEventChanges } from '@monorepo/report/hooks/useEventChanges'
import { useState } from 'react'
import { ReportFilterDialog } from '../ReportFilterDialog'

export const ReportTable = <ColumnNames extends string, Filter extends Record<string, any>>({
  availableFilters,
  columns,
  rows,
  emptyStateMessage = 'Nenhum resultado encontrado',
  filterHook,
  filterTypes = {} as ReportTableProps<Filter>['filterTypes'],
  totalItems = 0,
  isLoading,
  handleRowClick,
  hasFilters = true,
  onPagination,
  onSearch,
  onSort,
  reportPage,
  tranformationFields = [{ field: FilterKeys.PRODUCTS, type: ComponentTypes.CHECKBOX }],
}: ReportTableProps<Filter>) => {
  const { ordering, updateOrdering, toUpperCase, toLowerCase } = useOrdering<ColumnNames>()
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false)
  const { filter, updateFilter } = filterHook
    ? filterHook()
    : // eslint-disable-next-line @typescript-eslint/no-empty-function
      { filter: {} as Filter, updateFilter: (_: Filter) => {} }
  const { params: pagination, updateParams: updatePagination } = useQueryParams<Pagination>(
    DEFAULT_PAGINATION
  )
  const { params: searchName, updateParams: updateSearchName } = useQueryParams<SearchName>(
    DEFAULT_SEARCH_NAME
  )
  const isFilteringEnabled = hasFilters && !!filterTypes && !!filterHook

  const removeFilter = (filterToBeRemoved: FilterItem) => {
    if (!isFilteringEnabled) return

    Object.entries(filterTypes).forEach(([key]) => {
      const filterKey = key as keyof Filter
      const currentFilter = filter[filterKey]

      if (Array.isArray(currentFilter) && currentFilter.includes(filterToBeRemoved.value)) {
        updateFilter({
          ...filter,
          [filterKey]: currentFilter.filter((item: string) => item !== filterToBeRemoved.value),
        })
      }
    })
  }

  const getFilterLabel = (filterValue: string): string => {
    for (const key in filterTypes) {
      const filterArray = availableFilters[key as keyof typeof availableFilters]
      const filterItem = filterArray?.find(item => item.value === filterValue)
      if (filterItem) return filterItem.label
    }
    return ''
  }

  const getTransformedFilters = () => {
    return tranformationFields.reduce(
      (acc, { field, type }) => {
        if (acc[field]) {
          return {
            ...acc,
            [field]: (acc[field] as Array<{ label: string; value: string }>).map(filter => ({
              ...filter,
              component: type,
            })),
          }
        }
        return acc
      },
      { ...availableFilters }
    )
  }

  const activeFilters = (): FilterItem[] =>
    Object.entries(filter).flatMap(([_, values]) =>
      Array.isArray(values) && values.length > 0
        ? values.map(value => ({ text: getFilterLabel(value), value }))
        : []
    )

  const renderLoadingState = () => (
    <>
      {Array.from({ length: totalItems === 0 ? 5 : totalItems }, (_, i) => (
        <Table.Row key={`loading-row-${i}`}>
          {columns.map(column => (
            <Table.LoadingCell key={column.name} />
          ))}
        </Table.Row>
      ))}
    </>
  )

  const renderEmptyState = () => (
    <Table.Row>
      <Table.CustomCell colSpan={columns.length}>
        <Text className="text-gray-500 mx-auto">{emptyStateMessage}</Text>
      </Table.CustomCell>
    </Table.Row>
  )

  const getEventChanges = (events: TransactionEvents) => {
    return useEventChanges([...(Object.keys(events) as TransactionEventType[])])
  }

  const renderTableRow = (row: TableData) => {
    const excludedKeyRows = ['id', 'key', 'events']
    const eventChanges = row.events ? getEventChanges(row.events) : []

    return (
      <Table.Row key={row.key} onClick={() => handleRowClick(row)}>
        {Object.keys(row)
          .filter(
            (key: string) => row[key as keyof TableData] != null && !excludedKeyRows.includes(key)
          )
          .map((key: string) => {
            const value = row[key as keyof TableData]
            if (value != null) {
              return (
                <Table.TextCell key={`${row.key}-${key}`}>
                  {typeof value === 'object' ? JSON.stringify(value) : value.toString()}
                </Table.TextCell>
              )
            }
            return null
          })}

        <span>
          {eventChanges?.length > 0 && (
            <Table.BadgeCell
              key={`${row.key}-badge`}
              badgeVariant="soft"
              badgesValues={eventChanges.map(({ text, color }) => ({ value: text, color }))}
            />
          )}
        </span>
      </Table.Row>
    )
  }

  const renderTableRows = () => rows.map(renderTableRow)

  const renderTableContent = () => {
    if (rows.length === 0) return renderEmptyState()
    return renderTableRows()
  }

  return (
    <>
      <Table.Root>
        <Table.Search
          variant={isFilteringEnabled ? 'search-and-filters' : 'search-only'}
          filters={activeFilters()}
          onClickFilterButton={() => setIsFilterDialogOpen(true)}
          initialSearch={searchName.name}
          onSearch={searchNameValue => {
            updateSearchName({ name: searchNameValue })
            onSearch?.(searchNameValue)
          }}
          onRemoveFilter={removeFilter}
        />
        <Table.Head>
          {columns.map(column => (
            <Table.HeaderCell
              className="whitespace-nowrap"
              key={column.name}
              helpText={column.description}
              sortable={!!column.sortable}
              sortOrder={
                ordering.sortBy === column.name ? toLowerCase(ordering.sortOrder) : undefined
              }
              name={column.name}
              onSort={(name: string, order: SortOrderLowerCase) => {
                updateOrdering({ sortBy: name as ColumnNames, sortOrder: toUpperCase(order) })
                onSort?.(name, order)
              }}
            >
              {column.displayName}
            </Table.HeaderCell>
          ))}
        </Table.Head>
        <Table.Body>{isLoading ? renderLoadingState() : renderTableContent()}</Table.Body>
        <Table.Pagination
          page={pagination.page}
          total={totalItems}
          itemsPerPage={pagination.itemsPerPage}
          onChangePage={page => {
            updatePagination({ page })
            onPagination?.(PaginationEvents.PAGE, page.toString())
          }}
          onChangeItemsPerPage={(itemsPerPage: number) => {
            updatePagination({ itemsPerPage })
            onPagination?.(PaginationEvents.ITEMS_PER_PAGE, itemsPerPage.toString())
          }}
        />
      </Table.Root>

      {isFilteringEnabled && (
        <ReportFilterDialog<Filter>
          isOpen={isFilterDialogOpen}
          reportPage={reportPage}
          filterTypes={filterTypes}
          availableFilters={getTransformedFilters()}
          filterHook={filterHook}
          onClose={() => setIsFilterDialogOpen(false)}
        />
      )}
    </>
  )
}
