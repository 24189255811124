import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`

interface ContainerProps {
  open: boolean
}

export const Container = styled.div<ContainerProps>`
  pointer-events: ${({ open }) => (open ? 'all' : 'none')};

  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: all 0.3s ease-in-out;

  padding: ${props => props.theme.gravity.spacing[6]};
  gap: ${props => props.theme.gravity.spacing[4]};

  margin-top: ${props => props.theme.gravity.spacing[8]};
  box-shadow: ${props => props.theme.gravity.boxShadow[3]};
  border-radius: ${props => props.theme.gravity.borderRadius[2]};

  background: ${props => props.theme.gravity.colors['colors-background-neutral-1']};

  flex: 1;
  display: flex;
  flex-direction: column;

  position: absolute;
  bottom: 0;
  right: 50px;

  z-index: 1000;
  white-space: nowrap;
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.gravity.spacing[5]};
  cursor: pointer;
`
