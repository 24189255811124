import { Fragment } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { Text } from '@gravity/text'

import { Container } from './styles'
import { ItemStudentSearch } from '../ItemStudentSearch'
import { ItemGuardianSearch } from '../ItemGuardianSearch'

import { StorageItemType } from '@monorepo/school-home/types/StorageItem'
import { SEARCH_TYPES } from '@monorepo/school-home/constants/SEARCH_TYPES'

export type ResultStoredSectionProps = {
  handleAddItemInStorage: (item: StorageItemType) => void
  handleRemoveItem: (id: string) => void
  inputSearch: string
  isEmptyResultForSearchStored: boolean
  storedSearch: StorageItemType[]
}

export const ResultStoredSection = ({
  handleAddItemInStorage,
  handleRemoveItem,
  storedSearch,
  isEmptyResultForSearchStored,
  inputSearch,
}: ResultStoredSectionProps) => {
  const { url } = useRouteMatch()

  return (
    <Container data-testid="result-search-stored">
      {!inputSearch.length && isEmptyResultForSearchStored && (
        <Text variant="body-1-regular">Não há buscas recentes</Text>
      )}

      {storedSearch.map(item => (
        <Fragment key={item.id}>
          {item.type === SEARCH_TYPES.STUDENTS && (
            <ItemStudentSearch
              type="history"
              id={item.id}
              onClick={handleAddItemInStorage}
              onRemove={handleRemoveItem}
              key={item.id}
              mainProduct={item?.mainProduct}
              name={item.name}
              guardianName={item.guardianName}
              url={url}
              inputSearch={inputSearch}
            />
          )}
          {item.type === SEARCH_TYPES.GUARDIANS && (
            <ItemGuardianSearch
              type="history"
              id={item.id}
              onRemove={handleRemoveItem}
              onClick={handleAddItemInStorage}
              key={item.id}
              name={item.name}
              students={item.students}
              url={url}
              inputSearch={inputSearch}
            />
          )}
        </Fragment>
      ))}
    </Container>
  )
}
