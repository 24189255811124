import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { formatCurrencyValue, formatNumberMicroCentsToReal } from '@/shared/utils/numberFormatters'
import { Description } from './styles'
import { PayoutTransfersSectionRootProps } from './types'
import { Separator } from '@gravity/separator'

export const PayoutTransfersSectionRoot = ({
  icon: Icon,
  iconColor,
  totalLabel,
  label,
  description,
  totalAmount,
  children,
  isEmpty,
  emptySectionMessage,
}: PayoutTransfersSectionRootProps) => {
  const formatAmountValue = (amount: number) => {
    const decimalAmount = formatNumberMicroCentsToReal(amount)

    return formatCurrencyValue(decimalAmount)
  }

  return (
    <div className="flex flex-col gap-4 p-6 pb-0 border-b border-colors-border-neutral-2">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <Icon style={{ fontSize: '1.5rem', color: iconColor }} data-testid="icon-element" />

          <Heading variant="heading-h4-regular" data-testid="section-title">
            {label}
          </Heading>
        </div>
      </div>

      <div className="flex flex-col gap-6">
        <Description variant="body-2-regular" data-testid="description-element">
          {description}
        </Description>

        {isEmpty && (
          <Description variant="body-2-regular" data-testid="description-element">
            {emptySectionMessage}
          </Description>
        )}

        {children}
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex justify-between items-center">
          <Text variant="body-1-medium">{totalLabel}</Text>
          <Text variant="body-1-medium">{formatAmountValue(totalAmount)}</Text>
        </div>
        <Separator orientation="horizontal" color="neutral-2" />
      </div>
    </div>
  )
}
