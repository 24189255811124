import { ComponentError } from './Errors'
import NoInternetImage from '../../assets/sem-internet.svg'
import PageNotFoundImage from '../../assets/pagina-nao-encontrada.svg'
import CoffeeImage from '../../assets/coffee.png'

type CashoutErrorProps = {
  handleRetryButton?: () => void
}

export const GenericCashoutError = ({
  handleRetryButton = () => {
    location.reload()
  },
}: CashoutErrorProps) => {
  return (
    <ComponentError
      src={PageNotFoundImage}
      title="Algo deu errado"
      description="Por favor, aguarde um momento e tente novamente."
      titleButton="Tente novamente"
      handleRetryButton={handleRetryButton}
    />
  )
}

type SimulationCashoutErrorProps = {
  handleRetryButton: () => void
}

export const SimulationCashoutError = ({ handleRetryButton }: SimulationCashoutErrorProps) => {
  return (
    <ComponentError
      src={CoffeeImage}
      handleRetryButton={handleRetryButton}
      title="Não foi possível concluir a simulação"
      titleButton="Inicio"
      description={`Ocorreu um erro inesperado e estamos trabalhando para resolvê-lo.\n Por favor, tente novamente mais tarde.`}
    />
  )
}

export const NetworkError = () => {
  return (
    <ComponentError
      src={NoInternetImage}
      title="Sem internet"
      description={`Por favor, verifique sua conexão com a internet ou aguarde um momento\n e tente novamente.`}
      titleButton="Tente novamente"
    />
  )
}
