import { useUnleashFlag, UnleashFlags } from '@/shared/hooks'
import { Button } from '@gravity/button'
import { Dialog } from '@gravity/dialog'

type CustomDialogProps = {
  isVisible: boolean
  onClose: () => void
  submitHandler: () => void
}

export const CustomDialog = ({ submitHandler, isVisible, onClose }: CustomDialogProps) => {
  const resolveInsteadOfDeletingIrregularity = useUnleashFlag(
    UnleashFlags.INT_702_RESOLVE_INSTEAD_OF_DELETING_IRREGULARITY
  )

  return (
    <Dialog
      title="Marcar como resolvidas?"
      description={
        resolveInsteadOfDeletingIrregularity
          ? 'Ao marcar as pendências como resolvidas, elas não aparecerão mais na lista de correções pendentes mas poderão ser acessadas no filtro de correções concluídas.'
          : 'Ao marcar as pendências como resolvidas, elas não aparecerão mais na lista e não serão atualizadas no seu sistema.'
      }
      open={isVisible}
      size={2}
      backdrop
      onOpenChange={open => {
        if (!open && isVisible) onClose()
      }}
      actionButton={
        <Button color="error" onClick={submitHandler}>
          Confirmar
        </Button>
      }
      cancelButton={
        <Button variant="ghost" onClick={onClose}>
          Cancelar
        </Button>
      }
    />
  )
}
