import { TextField } from '@gravity/text-field'
import { IconButton } from '@gravity/icon-button'
import { Close, Search } from '@gravity/icons'
import { useState } from 'react'
import { maskCPF } from '@monorepo/enrollment/utils/inputUtils/maskInput'
import theme from '@/shared/theme'

type TaxIdInputProps = {
  error?: boolean
  errorMessage?: string
  onChange: (value: string) => void
  value: string
}

export const TaxIdInput = ({ value, onChange, error, errorMessage }: TaxIdInputProps) => {
  const [localValue, setLocalValue] = useState(value)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    const maskedValue = maskCPF(newValue)
    setLocalValue(maskedValue)
    onChange(maskedValue.replace(/\D/g, ''))
  }

  const handleClear = () => {
    setLocalValue('')
    onChange('')
  }

  return (
    <TextField
      name="tax-id-input"
      value={localValue}
      onChange={handleChange}
      size={3}
      placeholder="Digite apenas os números, sem pontos ou traços"
      label="Pesquise o responsável por CPF"
      data-testid="tax-id-input"
      iconStart={<Search color={theme.gravity.colors['colors-background-neutral-5']} />}
      iconButton={
        <IconButton size={1} onClick={handleClear}>
          <Close />
        </IconButton>
      }
      error={error}
      errorMessage={errorMessage}
      maxLength={14}
    />
  )
}
