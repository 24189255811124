import { useEffect, useState } from 'react'
import dayjs from 'dayjs'

import { DatepickerPrimitives as Datepicker } from '@gravity/datepicker'
import { DashedArrowLeft, DashedArrowRight } from '@gravity/icons'

import { NegotiationCard } from './components/NegotiationCard'
import { NegotiationEmpty } from './components/NegotiationEmpty'

import { useGuardianHistory } from './hooks/useGuardianHistory'
import { useGuardianHistoryNegotiation } from './hooks/useGuardianHistoryNegotiation'

import {
  Wrapper,
  WrapperSeparator,
  DatepickerWrapper,
  DatepickerTrigger,
  Heading,
  IconButton,
} from './styles'

export const GuardianDetailsHistoryTab = () => {
  const [negotiationId, setNegotiationId] = useState<string>('')

  const {
    now,
    lastPeriod,
    nextPeriod,
    selectedPeriod,
    handleApplyFilter,
    isSameDate,
    guardianHistory,
    isLoading,
    isEmpty,
    isCurrentMonthSelected,
    handleEventDatepicker,
    handleEventDateNavigation,
    handleEventCollapsible,
  } = useGuardianHistory()

  const {
    guardianHistoryNegotiation,
    isError: isNegotiationError,
    isLoading: isNegotiationLoading,
  } = useGuardianHistoryNegotiation(negotiationId)

  const handleOnValueChange = (selectedNegotiationId: string) => {
    setNegotiationId(selectedNegotiationId)
    handleEventCollapsible()
  }

  useEffect(() => {
    if (isNegotiationError) {
      setNegotiationId('')
    }
  })

  return (
    <Wrapper>
      <DatepickerWrapper>
        <Datepicker.Root>
          <DatepickerTrigger variant="surface" size={3} onClick={handleEventDatepicker}>
            {selectedPeriod ? dayjs(selectedPeriod).format('MMMM YYYY') : 'Selecione o mês'}
          </DatepickerTrigger>
          <Datepicker.Calendar
            minDetail="month"
            maxDetail="year"
            maxDate={now.toDate()}
            value={selectedPeriod}
            onChange={value => {
              handleApplyFilter(value as Date)
            }}
          />
        </Datepicker.Root>

        <WrapperSeparator />

        <IconButton
          side="left"
          variant="soft"
          disabled={isLoading}
          onClick={() => {
            handleApplyFilter(lastPeriod.toDate())
            handleEventDateNavigation('left')
          }}
        >
          <DashedArrowLeft size="xs" />
        </IconButton>
        <IconButton
          side="right"
          variant="soft"
          disabled={isLoading || isCurrentMonthSelected}
          onClick={() => {
            handleApplyFilter(nextPeriod.toDate())
            handleEventDateNavigation('right')
          }}
        >
          <DashedArrowRight size="xs" />
        </IconButton>
      </DatepickerWrapper>

      {isLoading &&
        Array.from({ length: 3 }).map((_, index) => (
          <NegotiationCard
            key={index}
            isLoading={isLoading}
            negotiationId={negotiationId}
            handleOnValueChange={handleOnValueChange}
          />
        ))}

      {isEmpty && <NegotiationEmpty />}

      {!isEmpty && !isLoading && (
        <>
          <Heading variant="body-1-medium">
            {`Negociações realizadas em ${dayjs(selectedPeriod).format('MMMM [de] YYYY')}`}
          </Heading>

          {guardianHistory?.map((info, ind) => (
            <NegotiationCard
              negotiationId={negotiationId}
              handleOnValueChange={handleOnValueChange}
              key={info.id}
              isLoading={isLoading}
              isNegotiationLoading={info.id === negotiationId && isNegotiationLoading}
              info={info}
              negotiation={guardianHistoryNegotiation}
              showDateSeparator={isSameDate(
                info.created_at,
                guardianHistory?.[ind - 1]?.created_at
              )}
              onClick={() => {
                if (negotiationId === info.id) {
                  setNegotiationId('')
                }
              }}
            />
          ))}
        </>
      )}
    </Wrapper>
  )
}
