import { TextField } from '@gravity/text-field'
import NumberFormat from 'react-number-format'
import { formatCentsToReal } from 'src/shared/utils'
import { StyledInputMinDown } from './styles'
import type { InputMinDownProps } from './types'

const WrapperTextField = props => {
  return <TextField {...props} errorMessage={props.helperText} size={3} />
}

const InputMinDown = ({
  handleValue,
  selectedValue,
  minAmount,
  maxAmount,
  disabled = false,
  label,
}: InputMinDownProps) => {
  const invalidAmount = selectedValue < minAmount || selectedValue > maxAmount

  const messageError = () => {
    if (!invalidAmount) return ''
    if (selectedValue < minAmount) return `O valor mínimo é de ${formatCentsToReal(minAmount)}`
    return `O valor máximo é de ${formatCentsToReal(maxAmount)}`
  }

  return (
    <StyledInputMinDown marginRight={32} minWidth={294} disabled={disabled}>
      <NumberFormat
        id="min_down_payment"
        onValueChange={({ floatValue }) => {
          handleValue(floatValue)
        }}
        customInput={WrapperTextField}
        variant="outlined"
        label={label}
        placeholder={label}
        error={invalidAmount}
        helperText={messageError()}
        format={formatCentsToReal}
        value={selectedValue}
        style={{ minWidth: '255px', maxWidth: '294px' }}
        disabled={disabled}
      />
    </StyledInputMinDown>
  )
}

export default InputMinDown
