import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 12px;
  margin-left: 64px;
`

export const ChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  gap: 20px;
  margin-bottom: 0px;
  width: 100%;
`
