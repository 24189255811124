import styled from 'styled-components'

export const Form = styled.form`
  margin-top: ${props => props.theme.gravity.spacing[8]};
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Content = styled.div`
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.theme.gravity.spacing[4]};
`

export const FormWrapper = styled.div`
  margin-top: ${props => props.theme.gravity.spacing[8]};
  height: -webkit-fill-available;
  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`
