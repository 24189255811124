import { UseFormReturn, FieldArrayWithId } from 'react-hook-form'

import { OfferItem } from '../OfferItem'
import { EnrollmentCampaignFormProps } from '../../types'
import { useOfferList } from './useOfferList'

type Props = {
  form: UseFormReturn<EnrollmentCampaignFormProps, any>
  handleRemoveOfferClick: (index: number) => () => void
  isRemoveButtonDisabled: boolean
  offerFields: FieldArrayWithId<EnrollmentCampaignFormProps, 'offers', 'id'>[]
  referenceYear: number
}

export const OfferList = ({
  form,
  referenceYear,
  offerFields,
  isRemoveButtonDisabled,
  handleRemoveOfferClick,
}: Props) => {
  const { validateOfferDueDate, calculateTheLatestOfferDate } = useOfferList({
    form,
  })

  return (
    <div role="list" aria-label="Lista de ofertas">
      {offerFields?.map((offer, index) => (
        <OfferItem
          referenceYear={referenceYear}
          disableRemoveButton={isRemoveButtonDisabled}
          form={form}
          key={offer.id}
          index={index}
          onClickRemove={handleRemoveOfferClick(index)}
          onDateChange={calculateTheLatestOfferDate}
          validateOfferDueDate={date => validateOfferDueDate(date, index)}
        />
      ))}
    </div>
  )
}
