import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Grid, GridItem } from '@gravity/grid'
import { Button } from '@gravity/button'
import { Heading } from '@gravity/heading'

import { ArrowLeft } from '@gravity/icons'

import { Container, Header, Iframe } from './styles'
import { Error } from '@/shared/components/Error'
import { CircularLoadingPlaceholder } from '@/shared/components/CircularLoadingPlaceholder'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useGetFidcReport } from '../../hooks/queries/fidcReport'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useLayout } from '@/shared/hooks/useLayout'

export const FidcConfirmationReports = () => {
  const { schoolSlug } = useSelectedSchool()
  const { push } = useHistory()
  const { data, status } = useGetFidcReport()
  const [iframeStatus, setIframeStatus] = useState<typeof status | undefined>('loading')

  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  useLayout({ enableSideMenu: true, enableHeader: renderNewHeader })

  const Child = {
    loading: <CircularLoadingPlaceholder />,
    error: <Error />,
    success: (
      <>
        {iframeStatus === 'loading' ? <CircularLoadingPlaceholder /> : null}
        <Iframe
          id="fidc-report"
          src={data?.url}
          style={{ display: iframeStatus === 'success' ? 'block' : 'none' }}
          onLoad={() => setIframeStatus('success')}
        />
      </>
    ),
  }

  return (
    <Container>
      <Grid className="grid-container">
        <GridItem xs={4} sm={8} md={12} lg={12} xl={12}>
          <Header>
            <Button
              variant="ghost"
              iconStart={<ArrowLeft />}
              onClick={() => push(`/${schoolSlug}/relatorios`)}
            >
              Voltar
            </Button>
            {renderNewHeader ? (
              <Heading variant="heading-h2-medium" className="title">
                Relatório de validação de contratos 2025
              </Heading>
            ) : (
              <Heading variant="heading-h3-medium" className="title">
                Relatório de validação de contratos 2025
              </Heading>
            )}
          </Header>
        </GridItem>

        <GridItem xs={4} sm={8} md={12} lg={12} xl={12} className="iframe-item">
          {Child[status]}
        </GridItem>
      </Grid>
    </Container>
  )
}
