import { useAgreement } from '@/escolas/contexts/agreementContext'
import { useNavigation } from '@/escolas/hooks'
import { useSchool } from '@/escolas/hooks/useSchool'
import { useSendCheckoutEvent } from './useSendCheckoutEvents'
import { formatCentsToReal, formatCPF } from '@/shared/utils'
import { HelpButtonEntryProps } from '../components/StepFooter/types'

export const useHelp = ({ helpButtonEntry }: { helpButtonEntry?: HelpButtonEntryProps }) => {
  const { totalAmount, agreementSimulations } = useAgreement()
  const { school } = useSchool()
  const { guardian } = useNavigation()
  const sendCheckoutEvent = useSendCheckoutEvent()

  const getMessage = () => {
    const schoolName = school.name

    const introduction = `Olá, sou do colégio/escola ${schoolName} e preciso de ajuda com o pagamento do(a) Responsável ${
      guardian?.name
    }, CPF ***.***.${formatCPF(guardian?.tax_id).slice(
      -6
    )}, que está tentando pagar as seguintes parcelas.`

    const list = agreementSimulations.map(value => {
      return {
        productName: value.receivables[0].product,
        studentName: value.receivables[0].student,
        receivable: value.receivables,
      }
    })
    let listInstallment: string[] = []
    let finalAmount = 0
    if (helpButtonEntry) {
      finalAmount = helpButtonEntry.amount

      listInstallment = [
        `\n\nAluno: ${helpButtonEntry.studentName}\nProduto: ${
          helpButtonEntry.productName
        }\n${`Parcela ${helpButtonEntry.orderReference}: ${formatCentsToReal(
          helpButtonEntry.amount
        )}`}`,
      ]
    } else {
      finalAmount = totalAmount

      listInstallment = list.map(value => {
        return `\n\nAluno: ${value.studentName}\nProduto: ${
          value.productName
        }${value.receivable.reduce(
          (acc, receivable) => `${acc}
    Parcela ${receivable.orderReference}: ${formatCentsToReal(receivable.amount)}`,
          ''
        )}`
      })
    }

    return `${introduction}${listInstallment.reduce(
      (acc, value) => acc + value,
      ''
    )}\n\nTotal: ${formatCentsToReal(finalAmount)}`.trim()
  }

  return { getMessage, sendCheckoutEvent }
}
