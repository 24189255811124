import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { useApiClient } from '@/shared/hooks/useApiClient'
import {
  ProductsByReferenceYearRequest,
  ProductsByReferenceYearResponse,
} from '@monorepo/enrollment/services/campaigns/types'
import { enrollmentCampaignService } from '@monorepo/enrollment/services/campaigns'

export const productsByReferenceYearKey = 'products-by-reference-year'

export const useFetchProductsByReferenceYear = (
  params: ProductsByReferenceYearRequest,
  options?: UseQueryOptions<ProductsByReferenceYearResponse>
) => {
  const { apiClient } = useApiClient()

  const service = enrollmentCampaignService(apiClient.privateApi)

  return useQuery<ProductsByReferenceYearResponse>(
    [productsByReferenceYearKey, params],
    () => service.fetchProductsByReferenceYear(params),
    options
  )
}
