import { Button } from '@gravity/button'
import { Grid } from '@gravity/grid'
import { Heading } from '@gravity/heading'
import {
  ContentContainer,
  ErrorContainer,
  ErrorImage,
  StyledGridItem,
  StyledText,
  TextContainer,
  WrapperContainer,
} from './styles'
import { TopHeader } from '@/shared/components/TopHeader'

type Props = {
  description?: string
  handleRetryButton?: () => void
  src: string
  title: string
  titleButton: string
}

export const ComponentError = ({
  src,
  title,
  titleButton,
  description,
  handleRetryButton = () => {
    location.reload()
  },
}: Props) => {
  return (
    <WrapperContainer>
      <TopHeader />
      <ErrorContainer>
        <Grid>
          <StyledGridItem>
            <ErrorImage src={src} alt={title} />
            <ContentContainer>
              <TextContainer>
                <Heading variant="heading-h2-medium">{title}</Heading>
                <StyledText variant="body-1-regular">{description}</StyledText>
              </TextContainer>
              <Button size={2} onClick={handleRetryButton}>
                {titleButton}
              </Button>
            </ContentContainer>
          </StyledGridItem>
        </Grid>
      </ErrorContainer>
    </WrapperContainer>
  )
}
