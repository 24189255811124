import { useRef } from 'react'

import { Header } from './components/Header'
import { NewSidemenu } from '../NewSidemenu'

import { useScrollToTop } from '@/shared/hooks/useScrollToTop'
import type { LayoutProps } from '@/shared/contexts/Layout/types'

/**
 * Component that represents the base structure (shell) of all school pages
 *
 * @param props.title Header title
 * @param props.isHeaderVisible Indicates if header should be displayed
 * @param props.isSideMenuVisible Indicates if side-menu should be displayed
 * @param props.children
 */

export const NewLayout = ({
  isSideMenuVisible,
  isHeaderVisible,
  children,
}: Omit<LayoutProps, 'title'>) => {
  const mainRef = useRef<HTMLElement>(null)

  useScrollToTop(mainRef)

  return (
    <>
      {isSideMenuVisible && <NewSidemenu />}

      <div className="relative flex flex-col w-full">
        {isHeaderVisible && (
          <div className="sticky top-0 z-[99]">
            <Header />
          </div>
        )}

        <main className="flex-1" ref={mainRef}>
          {children}
        </main>
      </div>
    </>
  )
}
