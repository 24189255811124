import { UseFormReturn } from 'react-hook-form'
import * as Sentry from '@sentry/react'

import { useApi } from '@/utils/hooks/useApi'

import { EditGuardianFormData } from '../types'

export const useAddressAutoFill = (form: UseFormReturn<EditGuardianFormData>) => {
  const { api } = useApi()
  const formZip = form.watch('address.zip')

  const onZipFieldFilled = async (zip: string) => {
    try {
      const address = await api.guardians.getAddressFromZip(zip)
      if (!address) return
      form.setValue('address', address)
    } catch (error) {
      Sentry.captureException(error, { level: 'error' })
    }
  }

  const handleZipInputBlur = () => {
    if (formZip.length !== 8) return
    onZipFieldFilled(formZip)
  }

  return { handleZipInputBlur }
}
