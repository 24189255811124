import { IconButton } from '@gravity/icon-button'
import { Close } from '@gravity/icons'

interface RemoveButtonProps {
  id: string
  onClick?: (id: string) => void
}

export const RemoveButton = ({ onClick, id }: RemoveButtonProps) => {
  return (
    <IconButton
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()

        if (onClick) onClick(id)
      }}
      data-testid="remove-button"
      variant="ghost"
      size={1}
      className="remove-button"
    >
      <Close />
    </IconButton>
  )
}
