import type { RouteDefinition } from '@/shared/core/router'
import { UploadPage } from './pages/UploadPage'
import { UploadPage as UploadPageV2 } from './v2/pages/UploadPage'
import { useJWT, UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { ValidatePage } from './pages/ValidatePage'
import { BatchPage } from './pages/BatchPage'
import { PreviewPage } from './pages/PreviewPage'
import { StudentsPage } from './v2/pages/StudentsPage'
import { EditStudentPage } from './v2/pages/EditStudentPage'
import { SummaryPage } from './v2/pages/SummaryPage'

const canAccess = (): boolean => {
  const { isBackofficeUser } = useJWT()
  const ffEnabled = useUnleashFlag(UnleashFlags.EFI_395_PI_ONBOARDING)

  return isBackofficeUser || ffEnabled
}

const canAccessV2 = () => {
  const { isBackofficeUser } = useJWT()

  return isBackofficeUser
}

export const routes: RouteDefinition[] = [
  {
    key: 'ONBOARDING',
    path: '/migrar-dados',
    component: UploadPage,
    exact: true,
    canAccess,
  },
  {
    key: 'ONBOARDING_PREVIEW',
    path: '/migrar-dados/:batchID/pre-visualizar',
    component: PreviewPage,
    exact: true,
    canAccess,
  },
  {
    key: 'ONBOARDING_VALIDATE',
    path: '/migrar-dados/:batchID/validar',
    component: ValidatePage,
    exact: true,
    canAccess,
  },
  {
    key: 'BATCH',
    path: '/migrar-dados/:batchID/migrar',
    component: BatchPage,
    exact: true,
    canAccess,
  },
  {
    key: 'V2-UPLOAD',
    path: '/v2/migrar-dados',
    component: UploadPageV2,
    exact: true,
    canAccess: canAccessV2,
  },
  {
    key: 'V2-STUDENTS-PAGE',
    path: '/v2/migrar-dados/:batchID',
    component: StudentsPage,
    exact: true,
    canAccess: canAccessV2,
  },
  {
    key: 'V2-EDIT-STUDENT-PAGE',
    path: '/v2/migrar-dados/:batchID/alunos/:studentID',
    component: EditStudentPage,
    exact: true,
    canAccess: canAccessV2,
  },
  {
    key: 'V2-SUMMARY-PAGE',
    path: '/v2/migrar-dados/:batchID/migrar',
    component: SummaryPage,
    exact: true,
    canAccess: canAccessV2,
  },
]
