import { Skeleton } from '@gravity/skeleton'
import { CancelModalVariants } from '.'
import { useHistory } from 'react-router-dom'
import { Button } from '@gravity/button'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { InstallmentStatuses } from '@/shared/interfaces'

type CancelContractModalActionButtonProps = {
  canSubmit: boolean
  closeModal: () => void
  contractId: string | null
  goToNextStep: () => void
  onSubmit: () => void
  submitting: boolean
  variant: CancelModalVariants
}

export const CancelContractModalActionButton = ({
  canSubmit,
  closeModal,
  contractId,
  goToNextStep,
  onSubmit,
  submitting,
  variant,
}: CancelContractModalActionButtonProps) => {
  const history = useHistory()
  const { schoolSlug } = useSelectedSchool()

  const navigateToOverdueInstallments = () => {
    if (!contractId) return
    history.push(
      `/${schoolSlug}/contratos/${contractId}/editar?status=${InstallmentStatuses.OVERDUE}`
    )
    closeModal()
  }

  if (variant === 'loading') {
    return <Skeleton width={180} height={40} />
  }

  if (variant === 'non-cancellable') {
    return (
      <Button onClick={closeModal} size={2}>
        Ok, entendi
      </Button>
    )
  }

  if (variant === 'overdue-act') {
    return (
      <Button onClick={navigateToOverdueInstallments} size={2}>
        Ver parcelas
      </Button>
    )
  }

  if (variant === 'overdue-acknowledge') {
    return (
      <Button color="error" onClick={goToNextStep} size={2}>
        Continuar cancelamento
      </Button>
    )
  }

  if (variant === 'reason') {
    return (
      <Button
        variant="solid"
        disabled={!canSubmit}
        color="error"
        onClick={onSubmit}
        loading={submitting}
      >
        Confirmar cancelamento
      </Button>
    )
  }
  return null
}
