import { isEmpty } from 'ramda'
import { InstallmentType } from 'src/shared/interfaces'
import type { FormDiscount } from './types'

export function getConditionalValueRender({
  customEnrollmentMonthlyAmount,
  customMonthlyAmount,
  discounts,
  enrollmentDiscounts,
}: {
  customEnrollmentMonthlyAmount: number
  customMonthlyAmount: number
  discounts: Array<FormDiscount>
  enrollmentDiscounts: Array<FormDiscount>
}) {
  const setDiscountError = (discount: FormDiscount) => {
    discount.error = {}
    if (!discount?.description) {
      discount.error.description = 'Insira um motivo de desconto'
    }

    if (!discount?.amount) {
      discount.error.amount = 'Insira um desconto válido'
    }

    if (discount.days_before_due_date === null) {
      discount.error.days_before_due_date = 'Insira uma quantidade de dias'
    }

    if (discount?.days_before_due_date > 120) {
      discount.error.days_before_due_date = 'Insira um valor menor ou igual a 120'
    }

    if (discount?.days_before_due_date < -1) {
      discount.error.days_before_due_date = 'Insira uma quantidade de dias'
    }

    if (discount.error) {
      console.error(discount.error)
    }

    return !!discount.error
  }

  const allDiscounts = [...discounts, ...enrollmentDiscounts]
  allDiscounts.forEach(setDiscountError)

  const discountIsCompleted = (discount: FormDiscount, type: InstallmentType) => {
    const enrollmentDiff = (customEnrollmentMonthlyAmount - discount?.amount) / 100
    const tuitiontDiff = (customMonthlyAmount - discount?.amount) / 100

    if (type === InstallmentType.ENROLLMENT) {
      if (enrollmentDiff <= 1) {
        discount.error.amount = 'A parcela deve ser maior que R$ 1,00'
      }
    }

    if (type === InstallmentType.TUITION) {
      if (tuitiontDiff <= 1) {
        discount.error.amount = 'A mensalidade deve ser maior que R$ 1,00'
      }
    }

    const hasError = !isEmpty(discount?.error ?? {})
    return !hasError
  }

  const discountsAreCompleted =
    discounts.length > 0
      ? discounts.every(discount => discountIsCompleted(discount, InstallmentType.TUITION))
      : true

  const enrollmentIsCompleted =
    enrollmentDiscounts.length > 0
      ? enrollmentDiscounts.every(d => discountIsCompleted(d, InstallmentType.ENROLLMENT))
      : true

  const canSaveContract = discountsAreCompleted && enrollmentIsCompleted

  return { canSaveContract }
}
