import { useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  UserAddOutline,
  FileEditOutline,
  MoneyDollarBoxOutline,
  MailOpenOutline,
} from '@gravity/icons'

import { useSetPageTitle } from '@/shared/hooks/useSetPageTitle'
import { formatDate } from '@/shared/utils'
import { useSchoolEnrollmentCycleContext } from '@monorepo/enrollment/contexts/SchoolEnrollmentContext'
import { useCancelProposal } from '@monorepo/enrollment/hooks/mutations/useCancelProposal'
import { CloseCampaignDialog } from '@monorepo/enrollment/components/CloseCampaignDialog'
import { DetailsBigNumber } from '@monorepo/enrollment/pages/Campaigns/CampaignDetails/components/DetailsBigNumber'
import { PaymentPlansDialog } from '@monorepo/enrollment/pages/Campaigns/CampaignDetails/components/PaymentPlansDialog'
import { useEnrollmentCampaignRedirect } from '@/shared/hooks/useEnrollmentCampaignRedirect'
import { CampaignDetailsHeader } from './components/CampaignDetailsHeader'
import { CalloutCampaignDetails } from './components/Callout'
import { CancelProposalDialog } from '@monorepo/enrollment/components/CancelProposalDialog'
import { useAddTuitionToOneCampaignStudent } from '@monorepo/enrollment/hooks/useAddTuitionToOneCampaignStudent'
import { useCampaignsList } from '@monorepo/enrollment/pages/Campaigns/CampaignsList/hooks/useCampaignsList'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { CampaignStatus } from '@monorepo/enrollment/services/enrollmentService/types'
import { PageWrapper } from '@/shared/components/PageWrapper'

import { AddTuitionDialog } from '../../../components/AddTuitionDialog'
import * as Styled from './styles'
import type {
  CloseCampaignState,
  URLParams,
  AddTuitionToCampaignState,
  CampaignDetailsType,
} from './types'
import { StudentsTable } from './components/StudentsTable'

export const CampaignDetails = () => {
  useEnrollmentCampaignRedirect()
  useSetPageTitle('Campanha de rematrícula - isaac')

  const { year: selectedCampaingsYear } = useParams<{ year: string }>()

  const [showCloseCampaignDialog, setShowCloseCampaignDialog] = useState(false)
  const [showCampaignPaymentPlansDialog, setShowCampaignPaymentPlansDialog] = useState(false)
  const [closeCampaignDialogState, setCloseCampaignDialogState] = useState<CloseCampaignState>({
    enrollmentID: '',
    openDialog: false,
  })
  const [addTuitionDialogState, setAddTuitionDialogState] = useState<AddTuitionToCampaignState>({
    enrollmentID: '',
    studentName: '',
    openDialog: false,
  })

  const history = useHistory()

  const { campaignId } = useParams<URLParams>()

  const { school } = useSelectedSchool()

  const { schoolEnrollmentCycles } = useSchoolEnrollmentCycleContext()
  const selectedEnrollmentCycle = schoolEnrollmentCycles?.find(
    cycle => cycle.reference_year === parseInt(selectedCampaingsYear)
  )

  const {
    mutate: { mutate: mutateCancelProposal, isLoading: isLoadingMutation },
    onSuccessDefault,
  } = useCancelProposal()

  const handleRemoveStudentFromCampaign = (enrollmentID: string) => {
    if (!school) return
    mutateCancelProposal(
      { enrollmentID, schoolID: school.id },
      {
        onSuccess: () => {
          onSuccessDefault()
          setCloseCampaignDialogState({ enrollmentID: '', openDialog: false })
        },
      }
    )
  }

  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)

  const schoolId = selectedEnrollmentCycle?.school_id ?? school?.id

  const referenceYear = selectedEnrollmentCycle?.reference_year

  const schoolEnrollmentCycleId =
    selectedEnrollmentCycle?.school_enrollment_cycle_id ??
    searchParams.get('school_enrollment_cycle_id') ??
    ''

  const CampaignParams = {
    campaignId,
    schoolId,
    schoolEnrollmentCycleId,
    pagination: {
      page: 1,
      per_page: 1,
    },
  }

  const {
    isLoadingAddTuitionMutation,
    handleSubmitAddTuitionToProposal,
    form,
    handleCloseDialogForm,
  } = useAddTuitionToOneCampaignStudent({ campaignId, school, setAddTuitionDialogState })

  const { campaigns, isLoading: isLoadingCampaignList } = useCampaignsList(CampaignParams)

  const campaignDetails = useMemo(() => {
    return {
      id: campaigns?.[0]?.id,
      name: campaigns?.[0]?.name,
      productName: campaigns?.[0]?.product_name,
      status: campaigns?.[0]?.status,
      endDate: campaigns?.[0]?.end_date,
      hasPaymentPlan: campaigns?.[0]?.has_payment_plan,
      paymentPlans: campaigns?.[0]?.payment_plans,
      engagedStudentsCount: campaigns?.[0]?.engaged_students_count,
      emailsRead: campaigns?.[0]?.total_of_campaign_emails_read,
      studentsCount: campaigns?.[0]?.students_count,
      paidEnrollmentsCount: campaigns?.[0]?.paid_enrollments_count,
      closedType: campaigns?.[0]?.closed_type,
    } as CampaignDetailsType
  }, [campaigns])

  const formattedDate = formatDate(campaignDetails.endDate, 'DD/MM/YYYY')

  const handleCampaignClose = (success: boolean) => {
    if (!success) return

    searchParams.set('status', CampaignStatus.CLOSED)
    history.replace({ search: searchParams.toString() })
  }

  return (
    <PageWrapper>
      <Styled.PageContainer isLoading={isLoadingCampaignList}>
        <CampaignDetailsHeader
          campaignId={campaignId}
          campaignDetails={campaignDetails}
          isLoading={isLoadingCampaignList}
          formattedDate={formattedDate}
          setShowCampaignPaymentPlansDialog={setShowCampaignPaymentPlansDialog}
          setShowCloseCampaignDialog={setShowCloseCampaignDialog}
        />

        <CalloutCampaignDetails
          campaignDetails={campaignDetails}
          formattedDate={formattedDate}
          isLoading={isLoadingCampaignList}
        />
        <Styled.BigNumbersWrapper>
          <DetailsBigNumber
            title={<UserAddOutline />}
            value={campaignDetails?.studentsCount?.toString()}
            description="Alunos incluídos"
            isLoading={isLoadingCampaignList}
          />
          <DetailsBigNumber
            title={<MailOpenOutline />}
            value={campaignDetails?.emailsRead?.toString()}
            description="Emails abertos"
            isLoading={isLoadingCampaignList}
          />
          <DetailsBigNumber
            title={<FileEditOutline />}
            value={campaignDetails?.engagedStudentsCount?.toString()}
            description="Alunos interessados"
            isLoading={isLoadingCampaignList}
          />
          {campaignDetails.hasPaymentPlan && (
            <DetailsBigNumber
              title={<MoneyDollarBoxOutline />}
              value={(campaignDetails?.paidEnrollmentsCount ?? 0).toString()}
              description="Pré-matrículas pagas"
              isLoading={isLoadingCampaignList}
            />
          )}
        </Styled.BigNumbersWrapper>
        <StudentsTable
          hasPaymentPlan={campaignDetails.hasPaymentPlan}
          campaignId={campaignId}
          isLoading={isLoadingCampaignList}
          setCloseCampaignDialogState={setCloseCampaignDialogState}
          setAddTuitionDialogState={setAddTuitionDialogState}
        />
      </Styled.PageContainer>

      <PaymentPlansDialog
        visible={showCampaignPaymentPlansDialog}
        closeDialog={() => setShowCampaignPaymentPlansDialog(false)}
        paymentPlans={campaignDetails.paymentPlans}
      />

      <CloseCampaignDialog
        visible={showCloseCampaignDialog}
        closeDialog={() => setShowCloseCampaignDialog(false)}
        campaignId={campaignId}
        hasPaymentPlan={campaignDetails.hasPaymentPlan}
        onFinish={handleCampaignClose}
      />
      {closeCampaignDialogState.enrollmentID && (
        <CancelProposalDialog
          open={closeCampaignDialogState.openDialog}
          onConfirm={() => {
            handleRemoveStudentFromCampaign(closeCampaignDialogState.enrollmentID)
          }}
          onClose={() => {
            setCloseCampaignDialogState({ enrollmentID: '', openDialog: false })
          }}
          isLoading={isLoadingMutation}
        />
      )}
      {addTuitionDialogState.enrollmentID && addTuitionDialogState.studentName && (
        <AddTuitionDialog
          open={addTuitionDialogState.openDialog}
          enrollmentID={addTuitionDialogState.enrollmentID}
          onConfirm={handleSubmitAddTuitionToProposal}
          onClose={handleCloseDialogForm}
          form={form}
          isLoading={isLoadingAddTuitionMutation}
          referenceYear={referenceYear as number}
          studentName={addTuitionDialogState.studentName}
        />
      )}
    </PageWrapper>
  )
}
