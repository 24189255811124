import { BankTransfer, Payout, ReportAggregations } from '@monorepo/report/models/report'

export enum TransactionEventType {
  AGGLUTINATION_DISCOUNT = 'AGGLUTINATION_DISCOUNT',
  APPLIED_DISCOUNT = 'APPLIED_DISCOUNT',
  BALANCE_TRANSFER = 'BALANCE_TRANSFER',
  CANCEL = 'CANCEL',
  CORRECTION = 'CORRECTION',
  EXISTING_CONTRACT = 'EXISTING_CONTRACT',
  LIQUIDATION_EDITION = 'LIQUIDATION_EDITION',
  LOST_DISCOUNT = 'LOST_DISCOUNT',
  NEW_CONTRACT = 'NEW_CONTRACT',
  ORIGINAL_AMOUNT_CHANGED = 'ORIGINAL_AMOUNT_CHANGED',
  PAYMENT_SCHEDULE = 'PAYMENT_SCHEDULE',
  SCHOOL_LIQUIDATION = 'SCHOOL_LIQUIDATION',
  STONE_TRANSACTION = 'STONE_TRANSACTION',

  U_PAYMENT_CHANGED = 'U_PAYMENT_CHANGED',
  U_PAYMENT_MANUAL_CANCELED = 'U_PAYMENT_MANUAL_CANCELED',
  U_PAYMENT_REGISTERED = 'U_PAYMENT_REGISTERED',
}

export enum PayoutStatus {
  OPEN = 'OPEN',
  PAID = 'PAID',
}

export enum PayoutVersion {
  INITIAL_PV = 1,
  DASHBOARD_PV = 2,
}

export type PayoutSummary = {
  enrollment_take_rate: number | null
  enrollment_take_rate_amount: number | null
  final_amount: number
  final_discounts_amount: number
  final_gross_amount: number
  has_enrollment_payout: boolean | null
  payout_due_date: datestring
  payout_id: uuid
  payout_status: PayoutStatus
  pos_payments: number | null
  take_rate: number
  take_rate_amount: number | null
  version: PayoutVersion
}

export type UnguaranteedReport = {
  agregations: ReportAggregations
  bank_transfers?: BankTransfer[]
  ended_at: Date
  payout: Payout
  payout_id: string
  started_at: Date
  take_rate: number
}
