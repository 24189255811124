import styled from 'styled-components'

import theme from '@/shared/theme'

import { MAX_STEP_WIDTH } from '../../constants'
import { Card } from '@gravity/card'

const { spacing } = theme.primitiveTokens

export const Container = styled.div`
  max-width: ${MAX_STEP_WIDTH}px;
  margin: 0 auto;
`
export const CardStyled = styled(Card)`
  padding: 0;
`

export const ContentWrapper = styled.div`
  position: absolute;
  right: ${spacing[7]};
`
