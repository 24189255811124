import theme from '@/shared/theme'
import { Text } from '@gravity/text'
import styled from 'styled-components'

const { spacing } = theme.primitiveTokens

export const Wrapper = styled.div`
  text-align: center;
`

export const Subtitle = styled(Text)`
  margin-bottom: ${spacing[6]};
`
