import { Callout } from '@gravity/callout'
import * as S from './styles'
import loadingImg from '@/escolas/assets/Isaac_Junho_Previsibilidade 1.png'
import { Heading } from '@gravity/heading'

interface Props {
  negotiationId?: string
}

const LoadingLiquidation = ({ negotiationId }: Props) => {
  const description = `A negociação agora é a #${negotiationId.slice(0, 6).toUpperCase()}`
  return (
    <S.Wrapper>
      <img src={loadingImg} width={381} />
      <Heading>Registrando recebimento</Heading>
      <S.Subtitle>isso pode levar alguns segundos</S.Subtitle>
      <Callout text={`O código identificador da negociação mudou. ${description}`} />
    </S.Wrapper>
  )
}

export default LoadingLiquidation
