import { useContext } from 'react'
import {
  useEventDispatcher as useEventDispatcherOlaisaac,
  IEventDispatcher,
} from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherContext } from '@/shared/contexts/EventDispatcherContext'

type EventDispatcherHookValue = {
  eventDispatcherClient: IEventDispatcher
  handleChangeIsEnabled: (value: boolean) => void
  isInitialized: boolean
}

export const useEventDispatcher = (): EventDispatcherHookValue => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcherOlaisaac()
  const { handleChangeIsEnabled } = useContext(EventDispatcherContext)

  return { isInitialized, eventDispatcherClient, handleChangeIsEnabled }
}
