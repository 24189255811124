import { useEffect, useState } from 'react'
import {
  Contract,
  ContractStatus,
  Discount,
  Installment,
  InstallmentType,
  PreContractStatuses,
  SignAndPaymentStatus,
  SignAndPaymentStatusLabel,
} from 'src/shared/interfaces'
import { prop, sum } from 'ramda'
import { ContractDetailsSummary } from './ContractDetailsSummary'
import { AlertBanner } from '@gravity/alert-banner'
import { BufferInstallmentResponse } from 'src/shared/interfaces/bufferInstallment'

export type ContractDetailsDrawerDefaultContentProps = {
  contract: Contract
  contractStatus: string
}

type ContractInstallmentProps = {
  discounts: number
  installment: number
  installments: Installment[] | null
  installments_quantity: number
  totalAmount: number
}

export type ContractInstallments = {
  annuity: {
    totalAmount: number
    totalDiscounts: number
    totalWithDiscounts: number
  }
  enrollment: ContractInstallmentProps
  isaacPay: ContractInstallmentProps
  tuition: ContractInstallmentProps
}

export const sumDiscounts = (discounts: Discount[]) =>
  discounts?.reduce((acum, curr) => acum + (curr.amount ?? 0), 0) ?? 0

const defaultContractInstallments = {
  annuity: {
    totalAmount: 0,
    totalDiscounts: 0,
    totalWithDiscounts: 0,
  },
  enrollment: {
    discounts: 0,
    installment: 0,
    installments: [] as any[],
    installments_quantity: 0,
    totalAmount: 0,
  },
  tuition: {
    discounts: 0,
    installment: 0,
    installments: [] as any[],
    installments_quantity: 0,
    totalAmount: 0,
  },
  isaacPay: {
    discounts: 0,
    installment: 0,
    installments: [] as any[],
    installments_quantity: 0,
    totalAmount: 0,
  },
}

const calculateBufferInstallmentsForContractDetails = (
  bufferInstallments: BufferInstallmentResponse[] | undefined
): ContractInstallmentProps => {
  if (!bufferInstallments || bufferInstallments.length === 0) {
    return {
      discounts: 0,
      installment: 0,
      installments: null,
      installments_quantity: 0,
      totalAmount: 0,
    }
  }

  const firstInstallment = bufferInstallments[0]

  const monthlyAmount = firstInstallment.original_amount
  const totalMonthlyDiscount = sumDiscounts(firstInstallment.discounts)
  const intallmentsQuantity = bufferInstallments.length
  const totalAmount = monthlyAmount * intallmentsQuantity

  return {
    discounts: totalMonthlyDiscount,
    installment: monthlyAmount,
    installments: null, // not used for contract details drawer
    installments_quantity: bufferInstallments.length,
    totalAmount,
  }
}

const ContractDetailsDrawerDefaultContent = ({
  contract,
  contractStatus,
}: ContractDetailsDrawerDefaultContentProps) => {
  const [contractInstallments, setContractInstallments] = useState<ContractInstallments>(
    defaultContractInstallments
  )

  const [isEnrolled, setIsEnrolled] = useState(false)
  const [isPreEnrolled, setIsPreEnrolled] = useState(false)
  const [isCanceled, setIsCanceled] = useState(false)
  const [isPending, setIsPending] = useState(false)

  const getInstallmentsTotalAmount = (installments: any) => {
    return sum(installments.map(prop('amount')))
  }

  const getInstallmentsDiscountsTotalAmount = (installments: any) => {
    const discounts = installments?.map(prop('receivables'))[0]?.map(prop('discounts'))[0]

    return discounts?.length ? sum(discounts.map(prop('amount'))) : 0
  }

  const getContractInstallmentsDetails = (currentContract: Contract) => {
    const enrollmentInstallments = currentContract?.installments?.filter(({ type }) => {
      return type === InstallmentType.ENROLLMENT
    })

    const isaacPayInstallments = currentContract?.installments?.filter(({ type }) => {
      return type === InstallmentType.ISAACPAY
    })

    const enrollmentAmount = enrollmentInstallments ? enrollmentInstallments[0]?.amount : 0

    const enrollment = {
      discounts: getInstallmentsDiscountsTotalAmount(enrollmentInstallments),
      installment: enrollmentAmount,
      installments: enrollmentInstallments,
      installments_quantity: enrollmentInstallments?.length,
      totalAmount: getInstallmentsTotalAmount(enrollmentInstallments),
    }

    const isaacPayAmount = isaacPayInstallments ? isaacPayInstallments[0]?.amount : 0

    const isaacPay = {
      discounts: getInstallmentsDiscountsTotalAmount(isaacPayInstallments),
      installment: isaacPayAmount,
      installments: isaacPayInstallments,
      installments_quantity: isaacPayInstallments?.length,
      totalAmount: getInstallmentsTotalAmount(isaacPayInstallments),
    }

    let tuition: ContractInstallmentProps

    const tuitionInstallments = contract?.installments?.filter(({ type }) => {
      return type === InstallmentType.TUITION
    })

    if (
      currentContract.pre_contract_status === PreContractStatuses.EFFECTED_PCS ||
      (tuitionInstallments && tuitionInstallments.length > 0)
    ) {
      const tuitionAmount = tuitionInstallments ? tuitionInstallments[0]?.amount : 0
      tuition = {
        discounts: getInstallmentsDiscountsTotalAmount(tuitionInstallments),
        installment: tuitionAmount,
        installments: tuitionInstallments ?? null,
        installments_quantity: tuitionInstallments ? tuitionInstallments.length : 0,
        totalAmount: getInstallmentsTotalAmount(tuitionInstallments),
      }
    } else {
      tuition = calculateBufferInstallmentsForContractDetails(
        currentContract.pre_contract_installments
      )
    }

    const enrollmentTotalDiscount = enrollment.discounts * (enrollment.installments_quantity ?? 0)
    const tuitionTotalDiscount = tuition.discounts * tuition.installments_quantity
    const isaacPayTotalDiscount = isaacPay.discounts * (isaacPay.installments_quantity ?? 0)

    const annuityTotalAmount = enrollment.totalAmount + tuition.totalAmount + isaacPay.totalAmount

    const annuity_with_discounts =
      annuityTotalAmount - (enrollmentTotalDiscount + tuitionTotalDiscount + isaacPayTotalDiscount)

    const annuity_total_discounts =
      enrollmentTotalDiscount + tuitionTotalDiscount + isaacPayTotalDiscount

    const annuity = {
      totalWithDiscounts: annuity_with_discounts,
      totalDiscounts: annuity_total_discounts,
      totalAmount: annuityTotalAmount,
    }

    setContractInstallments({
      annuity,
      enrollment: enrollment as any,
      tuition,
      isaacPay: isaacPay as any,
    })
  }
  useEffect(() => {
    contract && getContractInstallmentsDetails(contract)
  }, [contract])

  useEffect(() => {
    if (!contractStatus) return
    setIsEnrolled(contractStatus === SignAndPaymentStatus.COMPLETE)
    setIsCanceled(contractStatus === SignAndPaymentStatus.CANCELED)
    setIsPreEnrolled(contractStatus === SignAndPaymentStatus.PRE_ENROLLED)
    setIsPending(contractStatus === ContractStatus.PENDING)
  }, [contractStatus])

  return (
    <div className="flex flex-col gap-4">
      {isEnrolled && (
        <AlertBanner
          color="success"
          className="px-4 rounded-2"
          description={
            SignAndPaymentStatusLabel[contractStatus as keyof typeof SignAndPaymentStatusLabel]
          }
        />
      )}
      {isPreEnrolled && (
        <AlertBanner
          color="info"
          className="px-4 rounded-2"
          description={
            SignAndPaymentStatusLabel[contractStatus as keyof typeof SignAndPaymentStatusLabel]
          }
        />
      )}
      {isCanceled && (
        <AlertBanner color="neutral" className="px-4 rounded-2" description="Inativo" />
      )}
      {isPending && (
        <AlertBanner color="warning" className="px-4 rounded-2" description="Em andamento" />
      )}
      <ContractDetailsSummary contractInstallments={contractInstallments} />
    </div>
  )
}

export default ContractDetailsDrawerDefaultContent
