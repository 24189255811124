import { Dialog } from '@gravity/dialog'
import { Button } from '@gravity/button'

export type TutorialVideoDialogProps = {
  description: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  src: string
  title: string
}

export const TutorialVideoDialog = (props: TutorialVideoDialogProps) => {
  const { isOpen, setIsOpen, title, description, src } = props

  const onClose = () => setIsOpen(false)

  return (
    <>
      <Dialog
        title={title}
        description={description}
        content={
          <iframe
            width="816"
            height="331"
            src={src}
            title={title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        }
        size={3}
        backdrop
        open={isOpen}
        onOpenChange={open => {
          if (!open && isOpen) onClose()
        }}
        actionButton={
          <Button size={2} onClick={onClose}>
            Ok, entendi
          </Button>
        }
      />
    </>
  )
}
