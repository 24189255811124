import { Redirect, useRouteMatch, Switch } from 'react-router-dom'

import useRouterReferenceYear from '@/escolas/hooks/useRouterReferenceYear'
import { useUserGuidingTrigger } from '@/shared/hooks/useUserGuidingTrigger'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { FIRST_AVAILABLE_YEAR } from '@monorepo/enrollment/constants/enrollment'

import NewContractPageRoute from '@/escolas/router/[schoolSlug]/contratos/novo'
import { Breadcrumb } from '@/shared/contexts/BreadcrumbsContext'
import { useOnboardingRedirect } from '@/escolas/hooks/useOnboardingRedirect'
import { LoadingBox } from '@/shared/components/LoadingBox'
import { CircularLoading } from '@/shared/components/CircularLoading'

import envConfig from '@/config'

export default () => {
  useUserGuidingTrigger()

  const initialSupportedYear = FIRST_AVAILABLE_YEAR
  const { isExact } = useRouteMatch()

  const { schoolSlug } = useSelectedSchool()

  const referenceYearParam = useRouterReferenceYear()
  const referenceYear = referenceYearParam?.match(/^(20)\d{2}$/)?.[0]
  const referenceYearNumber = Number(referenceYear)

  const { path, url } = useRouteMatch()

  const { SELF_ONBOARDING_REDIRECT_URL } = envConfig
  const { data: onboardingRedirectData, isFetching, isFetched } = useOnboardingRedirect()

  const { onboarding_redirect, self_onboarding_school_id } = onboardingRedirectData || {}

  const breadcrumbs: Breadcrumb[] = [{ path: url, name: 'Consultar contratos' }]

  const shouldRedirect = Boolean(onboarding_redirect && self_onboarding_school_id)

  if (isFetching) {
    return (
      <LoadingBox>
        <CircularLoading />
      </LoadingBox>
    )
  }

  if (isFetched && shouldRedirect) {
    window.location.replace(`${SELF_ONBOARDING_REDIRECT_URL}/escolas/${self_onboarding_school_id}`)
    return <></>
  }

  if (!initialSupportedYear) {
    return <Redirect to={{ pathname: `/${schoolSlug}` }} />
  }

  if (isExact && referenceYearNumber >= initialSupportedYear) {
    return <Redirect to={{ pathname: `/${schoolSlug}/matriculas` }} />
  }

  return <Switch>{NewContractPageRoute({ path, breadcrumbs })}</Switch>
}
