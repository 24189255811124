import styled from 'styled-components'
import { Text } from '@gravity/text'

const StyledText = styled(Text)`
  overflow-wrap: break-word;
`

export const ArithmeticOperator = styled(StyledText)`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.gravity.spacing[7]} ${({ theme }) => theme.gravity.spacing[3]} 0;
  font-weight: ${({ theme }) => theme.gravity.fontWeight.medium};
  font-size: ${({ theme }) => theme.gravity.fontSize.lg};
  color: ${({ theme }) => theme.gravity.colors['colors-text-main-2']};
`

export const SelectOverDueTitle = styled(StyledText)`
  line-height: ${({ theme }) => theme.gravity.lineHeight.normal};
  margin-bottom: ${({ theme }) => theme.gravity.spacing[1]};
  color: ${({ theme }) => theme.gravity.colors['colors-text-main-2']};
`

export const SecondaryText = styled(Text)`
  color: ${({ theme }) => theme.gravity.colors['colors-text-main-3']};
`

export const ContractSummaryProgressText = styled(Text)`
  color: ${({ theme }) => theme.gravity.colors['colors-text-main-3']};
  margin-top: -${({ theme }) => theme.gravity.spacing[4]};
  margin-bottom: ${({ theme }) => theme.gravity.spacing[4]};
  text-align: center;
`

export const SaveContractButtonDocker = styled('div')`
  padding: ${({ theme }) => theme.gravity.spacing[6]};
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.gravity.colors['colors-border-neutral-3']};
`

export const SaveContractDrawerHeaderContainer = styled('div')`
  padding: ${({ theme }) => theme.gravity.spacing[6]};
  border-bottom: 1px solid ${({ theme }) => theme.gravity.colors['colors-border-neutral-3']};
`

export const DrawerContent = styled('div')`
  flex: 1 1 auto;
  padding: ${({ theme }) => theme.gravity.spacing[6]};
  overflow-y: auto;
`
