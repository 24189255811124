import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'

import { CampaignProvider, useCampaignContext } from '@monorepo/enrollment/contexts/CampaignContext'
import { useSchoolEnrollmentCycleContext } from '@monorepo/enrollment/contexts/SchoolEnrollmentContext'
import { useEnrollmentCampaignRedirect } from '@/shared/hooks/useEnrollmentCampaignRedirect'

import { PaymentConditionsStep } from './components/PaymentConditionsStep'
import { StudentsStep } from './components/StudentsStep'
import { CampaignPublicityStep } from './components/CampaignPublicityStep'
import { ReviewStep } from './components/ReviewStep'
import { FullScreenHeader } from '@gravity/header'
import { Stepper } from '@gravity/stepper'
import { useLayout } from '@/shared/hooks/useLayout'
import { Grid, GridItem } from '@gravity/grid'

const campaignSteps = [
  { label: 'Condições', component: PaymentConditionsStep },
  { label: 'Alunos', component: StudentsStep },
  { label: 'Divulgação', component: CampaignPublicityStep },
  { label: 'Revisão', component: ReviewStep },
]

const NewCampaignWrapper = () => {
  useLayout({ enableSideMenu: false, enableHeader: false })

  const {
    stepper: { activeStep, goToNextStep },
  } = useCampaignContext()
  const history = useHistory()

  const { selectedEnrollmentCycle } = useSchoolEnrollmentCycleContext()

  const ActiveComponent = campaignSteps[activeStep].component

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [activeStep])

  return (
    <>
      <FullScreenHeader
        title="Nova campanha"
        className="fixed top-0 left-0 right-0 z-[99]"
        onClose={() => history.goBack()}
      />
      <div className="flex justify-center my-32">
        <Grid>
          <GridItem md="3 / 11" lg="3 / 11" xl="3 / 11">
            <Stepper.Root activeStep={activeStep}>
              {campaignSteps.map((_, index) => (
                <Stepper.Step key={index} step={index}>
                  <Stepper.StatusIcon />
                  <Stepper.Title>{campaignSteps[index].label}</Stepper.Title>
                </Stepper.Step>
              ))}
            </Stepper.Root>
            {selectedEnrollmentCycle && (
              <ActiveComponent
                enrollmentCycle={selectedEnrollmentCycle}
                onFinish={() => {
                  goToNextStep()
                }}
              />
            )}
          </GridItem>
        </Grid>
      </div>
    </>
  )
}

export const NewCampaign = () => {
  useEnrollmentCampaignRedirect()

  return (
    <CampaignProvider>
      <NewCampaignWrapper />
    </CampaignProvider>
  )
}
