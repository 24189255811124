import { formatCentsToReal } from 'src/shared/utils'
import type { PaymentPlanMethodsProps } from './types'
import { Text } from '@gravity/text'
import { BankCard2Outline } from '@gravity/icons'

export const PaymentPlanDebitCard = ({
  totalAmount,
  isPaymentFeeEnabled,
}: PaymentPlanMethodsProps) => {
  const noFeeText = isPaymentFeeEnabled ? '' : ' sem juros'
  return (
    <div className="flex mb-1 items-center">
      <BankCard2Outline />
      <Text variant="body-2-regular" className="text-colors-text-main-3">
        <b>Cartão de débito:</b> {`à vista de ${formatCentsToReal(totalAmount)}${noFeeText}`}
      </Text>
    </div>
  )
}
