import { Button } from '@gravity/button'
import { useHistory, useParams } from 'react-router-dom'
import { OverrideBatchTrigger } from '../components/OverrideBatch/OverrideBatchTrigger'
import { pageNames } from '../../hooks/eventContext'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

export const DashboardFooter = () => {
  const { batchID } = useParams<{ batchID: string }>()
  const { schoolSlug } = useSelectedSchool()

  const history = useHistory()

  const handleNavigate = async () => {
    return history.push(`/${schoolSlug}/migrar-dados/${batchID}/validar`)
  }

  return (
    <>
      <OverrideBatchTrigger pageName={pageNames.PREVIEW_PAGE} />
      <Button onClick={handleNavigate}>Continuar</Button>
    </>
  )
}
