import { Button } from '@gravity/button'
import { Dialog } from '@gravity/dialog'
import { Text } from '@gravity/text'
import { Question } from '@gravity/icons'

export const AcknowledgeBlockedCancelDialog = () => {
  return (
    <Dialog
      size={1}
      title="Cancelamento de parcelas e contrato indisponível"
      backdrop
      cancelButton={<Button variant="solid">Ok, entendi</Button>}
      content={
        <Text variant="body-2-regular">
          Por questões de segurança, esta ação está indisponível. Caso necessite realizar essa
          operação, procure o time de suporte.
        </Text>
      }
      trigger={
        <Button variant="ghost">
          <Question />
        </Button>
      }
    />
  )
}
