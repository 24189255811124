import { useHistory } from 'react-router-dom'

import { useGuardianNavigation } from '@/shared/hooks/useGuardianNavigation'
import { useSteps } from '@gravity/stepper'

export default function useStepsEntryFlow() {
  const steps = [0, 1, 2]
  const { activeStep: currentStep, setActiveStep: setCurrentStep } = useSteps({
    defaultStep: 0,
    count: steps.length,
  })
  const history = useHistory()
  const { pageToReturn } = useGuardianNavigation()
  const stepsTitles = ['Parcelas selecionadas', 'Forma de pagamento', 'Conclusão']

  const handleOnFinishStep = () => {
    if (pageToReturn) return history.push(pageToReturn)
    return history.goBack()
  }

  const goBack = () => {
    if (currentStep === 0) {
      return handleOnFinishStep()
    }
    setCurrentStep(prev => prev - 1)
  }

  const goToNextStep = () => {
    if (currentStep === stepsTitles.length - 1) return
    setCurrentStep(prev => prev + 1)
  }

  const handleStepperItemClick = (index: number) => {
    if (currentStep === stepsTitles.length - 1) return
    if (index < currentStep) {
      setCurrentStep(index)
    }
  }

  return {
    stepsTitles,
    goBack,
    steps,
    currentStep,
    goToNextStep,
    handleOnFinishStep,
    handleStepperItemClick,
  }
}
