import { Controller, UseFormReturn } from 'react-hook-form'
import { omit } from 'ramda'

import { DATE_FORMAT } from 'src/shared/utils'
import { useAgglutination } from 'src/escolas/hooks'
import { DatepickerPrimitives as Datepicker } from '@gravity/datepicker'
import dayjs from 'dayjs'

export type AgglutinationDueDateProps = {
  disableForm: boolean
  dueDate?: string
  form: UseFormReturn<any>
}

const AgglutinationDueDate = ({ form, disableForm }: AgglutinationDueDateProps) => {
  const { control } = form

  const { dueDate, setDueDate } = useAgglutination()
  const dueDateAsNewDate = dueDate ? new Date(dueDate) : null

  return (
    <div className="flex flex-col">
      <Controller
        rules={{ required: true }}
        name="due_date"
        control={control}
        defaultValue={dueDateAsNewDate}
        render={({ field, field: { value, onChange } }) => (
          <Datepicker.Root>
            <Datepicker.Label label="Nova data de vencimento" />
            <Datepicker.Trigger disabled={disableForm} size={3}>
              {value ? dayjs(value).format(DATE_FORMAT) : 'Selecione uma data'}
            </Datepicker.Trigger>
            <Datepicker.Calendar
              {...omit(['ref'], field)}
              minDate={new Date()}
              value={value}
              onChange={date => {
                if (date && date instanceof Date) {
                  onChange(date)
                  setDueDate((date as Date).toISOString())
                }
              }}
            />
          </Datepicker.Root>
        )}
      />
    </div>
  )
}

export default AgglutinationDueDate
