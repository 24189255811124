import { AnticipationButton } from './styles'
import { APIResponse } from '@/shared/interfaces/api'
import { useHistory } from 'react-router-dom'
import { PayoutAnticipationButtonProps } from './types'
import { useAnticipationChooseOffer } from '../../../hooks/queries/useAnticipationChooseOffer'
import { useContextResponseSimulation } from '@monorepo/credit/contexts/PayoutAnticipationContext'
import { useState } from 'react'
import useMixpanelEventSender from '@monorepo/credit/utils/useMixpanelEventSender'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { MixpanelEventActions } from '@monorepo/credit/constants/MixpanelEventActions.enum'
import { CreditEventComponentNames } from '@monorepo/credit/constants/CreditEventComponentNames.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'

export const AnticipationChooseOfferButton = ({
  redirectionWhenSuccess,
  handleError,
  size = 2,
  variant = 'solid',
  color = 'accent',
  text = 'Solicitar antecipação',
}: PayoutAnticipationButtonProps) => {
  const [isClicked, setIsClicked] = useState(false)

  const { replace } = useHistory()
  const { responseSimulation } = useContextResponseSimulation()
  const { mutateAsync: mutateChooseOffer } = useAnticipationChooseOffer()
  const { sendCreditEventToMixpanel } = useMixpanelEventSender()

  const handleAnticipationButtonClick = async () => {
    setIsClicked(true)
    mutateChooseOffer({
      orderId: responseSimulation.orderId,
      chooseOfferId: responseSimulation.offer.id,
    }).then(async (success: APIResponse) => {
      // Redireciona para página desejada
      if (success) {
        sendCreditEventToMixpanel(
          EventDispatcherEvents.BUTTON_CLICKED,
          MixpanelEventActions.CLICK,
          {
            $button_name: CreditEventComponentNames.CONFIRM_ANTICIPATION,
            $page_name: CreditEventComponentNames.SIMULATION_RESULT,
          },
          EventDispatcherEventScopes.PAYOUT_ANTICIPATION
        )

        replace(redirectionWhenSuccess)
      } else {
        // Trata o erro de escolha da oferta
        handleError()
      }
    })
  }

  return (
    <AnticipationButton
      size={size}
      variant={variant}
      color={color}
      onClick={handleAnticipationButtonClick}
      loading={isClicked}
    >
      {text}
    </AnticipationButton>
  )
}
