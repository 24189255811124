import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventPageName } from '@/modules/app/models/EventPageName.enum'
import { EventIdentifierName } from '@/modules/app/models/EventIdentifierName.enum'

export const useSidemenuEvents = () => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const defaultProps = {
    scope: EventDispatcherEventScopes.SIDE_MENU,
    name: EventDispatcherEvents.MENU_CLICKED,
    pageName: EventPageName.APP,
  }

  const handleClickEnrollmentPath = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        ...defaultProps,
        identifierName: EventIdentifierName.NAVIGATE_TO_OPERATION_ENROLLMENTS,
        customProperties: {
          $Button_name: 'MATRÍCULAS_2024_SIDE_MENU',
          $Menu_name: 'MENU_LATERAL',
        },
      })
  }

  const handleClickGuardianPath = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        ...defaultProps,
        identifierName: EventIdentifierName.NAVIGATE_TO_OPERATION_GUARDIANS,
      })
  }

  const handleClickTransfersPath = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        ...defaultProps,
        identifierName: EventIdentifierName.NAVIGATE_TO_FINANCIAL_TRANSFERS,
      })
  }

  const handleClickDashboardPath = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        ...defaultProps,
        identifierName: EventIdentifierName.NAVIGATE_TO_AER_MANAGEMENT_DASHBOARD,
        customProperties: {
          $Button_name: 'Painel de gestão',
        },
      })
  }

  const handleClickReportsPath = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        ...defaultProps,
        identifierName: EventIdentifierName.NAVIGATE_TO_AER_REPORTS,
        customProperties: {
          $button_label: 'Relatórios',
        },
      })
  }

  const handleClickCreditPath = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        ...defaultProps,
        identifierName: EventIdentifierName.NAVIGATE_TO_FINANCIAL_CREDIT,
        customProperties: { $button_name: 'Menu de crédito' },
      })
  }

  const handleClickIrregularitiesPath = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        ...defaultProps,
        identifierName: EventIdentifierName.NAVIGATE_TO_OPERATION_IRREGULARITIES,
      })
  }

  const handleClickProductsPath = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        ...defaultProps,
        identifierName: EventIdentifierName.NAVIGATE_TO_OPERATION_PRODUCTS,
      })
  }

  const handleClickCommunicationsPath = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        ...defaultProps,
        identifierName: EventIdentifierName.NAVIGATE_TO_INITIAL_PAGE,
      })
  }

  return {
    handleClickEnrollmentPath,
    handleClickGuardianPath,
    handleClickTransfersPath,
    handleClickDashboardPath,
    handleClickReportsPath,
    handleClickCreditPath,
    handleClickIrregularitiesPath,
    handleClickProductsPath,
    handleClickCommunicationsPath,
  }
}
