import { formatCentsToReal } from 'src/shared/utils'
import { Text } from '@gravity/text'

type InstallmentDetails = {
  discounts: number
  installment: number
  installments_quantity: number
  totalAmount: number
}

export const InstallmentSection = ({
  title,
  installments,
  testIdPrefix,
}: {
  installments: InstallmentDetails
  testIdPrefix: string
  title: string
}) => {
  return (
    <div className="flex flex-col gap-2">
      <Text variant="subtitle-medium">{title}</Text>
      <div className="flex justify-between">
        <Text variant="body-1-regular">Total</Text>
        <Text variant="body-1-regular" data-testid={`${testIdPrefix}-total-amount`}>
          {formatCentsToReal(installments.totalAmount)}
        </Text>
      </div>
      <div className="flex justify-between">
        <Text variant="body-1-regular">Descontos</Text>
        <Text variant="body-1-regular" data-testid={`${testIdPrefix}-discounts-total-amount`}>
          {`- ${formatCentsToReal(installments.discounts)}`}
        </Text>
      </div>
      <div className="flex justify-between">
        <Text variant="body-1-regular">Parcelas</Text>
        <Text variant="body-1-regular" data-testid={`${testIdPrefix}-installments`}>
          {`${installments.installments_quantity}x de ${formatCentsToReal(
            installments.installment - installments.discounts
          )}`}
        </Text>
      </div>
    </div>
  )
}
