import { Discount, DiscountType } from 'src/shared/interfaces'
import { formatCentsToReal } from 'src/shared/utils'
import { Text } from '@gravity/text'
import { Tooltip } from '@gravity/tooltip'
import { IconButton } from '@gravity/icon-button'
import { Close } from '@gravity/icons'

export type FinancialSummaryDiscountProps = {
  discount: Discount
  discountType: DiscountType
  testId?: string
}

export const discountsInfoMap = {
  [DiscountType.PERPETUAL]: {
    name: 'Permanente',
    description: 'Desconto independe do vencimento',
  },
  [DiscountType.DUE_PAYMENT]: {
    name: 'Pontualidade',
    description: 'Desconto válido até o vencimento',
  },
  [DiscountType.EARLY_PAYMENT]: {
    name: 'Antecipação',
    description: 'Desconto para pagamento adiantado',
  },
  [DiscountType.ADDITIONAL_ON_MANUAL_LIQUIDATION]: {
    name: 'Baixa manual',
    description: 'Desconto aplicado durante a baixa manual',
  },
  [DiscountType.KEPT_ON_MANUAL_LIQUIDATION]: {
    name: '',
    description: '',
  },
}

const FinancialSummaryDiscount = ({
  discountType,
  discount,
  testId,
}: FinancialSummaryDiscountProps) => (
  <div className="flex justify-between pb-2" data-testid={testId}>
    <div className="flex justify-between">
      <div className="w-4 h-4 border-l-2 border-b-2 border-gray-300 rounded-bl mr-2" />
      <div className="flex justify-between items-center">
        <Text variant="body-2-regular" className="text-colors-text-main-3 break-words">
          {discount?.description || discountsInfoMap[discountType]?.description}
        </Text>
        <Tooltip text={discountsInfoMap[discountType]?.name}>
          <IconButton variant="ghost" size={1}>
            <Close />
          </IconButton>
        </Tooltip>
      </div>
    </div>
    <Text variant="body-2-regular" className="text-colors-text-main-3">
      -{formatCentsToReal(discount.amount ?? 0)}
    </Text>
  </div>
)

export default FinancialSummaryDiscount
