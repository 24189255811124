import { useEffect } from 'react'
import { ChosenOfferCardProps } from './types'
import { Text } from '@gravity/text'
import {
  ColumnContainer,
  RowContainer,
  StyledCard,
  StyledHeading,
  StyledCaption,
  GroupTextContainer,
} from './styles'
import ModalCustomDialog from '../Modal'
import { useOrderDropout } from '../../hooks/queries/useOrderDropout'
import { useContextCreditOrderId } from '../../contexts/CreditDataContext'
import { useHistory } from 'react-router-dom'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { SeparatorLine } from '../SeparatorLine'
import useMixpanelEventSender from '../../utils/useMixpanelEventSender'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { MixpanelEventActions } from '../../constants/MixpanelEventActions.enum'
import { CreditEventComponentNames } from '../../constants/CreditEventComponentNames.enum'
import { Button } from '@gravity/button'
import { formattingFee } from '../../utils/formatFee'

export const ChosenOfferCard = ({ creditValue, offer, canCancel }: ChosenOfferCardProps) => {
  const history = useHistory()
  const { schoolSlug } = useSelectedSchool()
  const { mutateAsync } = useOrderDropout()
  const { creditOrderId } = useContextCreditOrderId()
  const { sendCreditEventToMixpanel } = useMixpanelEventSender()

  const redirect = () => {
    if (typeof window !== undefined) {
      window.location.reload()
    } else {
      history.push(`/${schoolSlug}/credito`)
    }
  }

  useEffect(() => {
    sendCreditEventToMixpanel(EventDispatcherEvents.PAGE_VIEWED, MixpanelEventActions.PAGE_VIEW, {
      $page_name: CreditEventComponentNames.CREDIT_SUMMARY_PAGE,
      $modality_type: offer.category,
    })
  }, [])

  return (
    <StyledCard>
      <GroupTextContainer>
        <Text variant="subtitle-medium">{`Resumo da oferta solicitada em ${offer.chosenOfferDate}`}</Text>
        <StyledHeading variant="heading-h4-medium">Crédito de {creditValue}</StyledHeading>
      </GroupTextContainer>

      <SeparatorLine />
      <GroupTextContainer>
        <Text variant="subtitle-medium">Número de parcelas</Text>
        <Text variant="body-1-regular">{`${offer.totalInstallments} parcelas de ${offer.installmentValue}`}</Text>
      </GroupTextContainer>

      <SeparatorLine />

      <RowContainer>
        <ColumnContainer>
          <GroupTextContainer>
            <Text variant="subtitle-medium">Forma de pagamento</Text>
            <Text variant="body-1-regular">Desconto no repasse</Text>
          </GroupTextContainer>
        </ColumnContainer>
        <ColumnContainer>
          <GroupTextContainer>
            <Text variant="subtitle-medium">Comece a pagar em</Text>
            <Text variant="body-1-regular">{offer.firstDiscountDate}</Text>
          </GroupTextContainer>
        </ColumnContainer>
      </RowContainer>

      <SeparatorLine />

      <RowContainer>
        <ColumnContainer>
          <GroupTextContainer>
            <Text variant="subtitle-medium">Taxa de juros mensal</Text>
            <Text variant="body-1-regular">{formattingFee.monthlyFee(offer.monthlyFee)}</Text>
          </GroupTextContainer>
        </ColumnContainer>
        <ColumnContainer>
          <GroupTextContainer>
            <Text variant="subtitle-medium">Juros e IOF anuais (CET)</Text>
            <Text variant="body-1-regular">{formattingFee.annualFee(offer.annualFee)}</Text>
          </GroupTextContainer>
        </ColumnContainer>
      </RowContainer>

      <SeparatorLine />

      <Text variant="subtitle-medium">Total a pagar</Text>
      <Text variant="body-1-regular">{offer.totalAmount}</Text>

      <StyledCaption variant="caption-medium">
        O valor da parcela acima é aproximado e pode sofrer alterações pela data de desembolso.
        Condições da oferta válidas para os próximos 7 dias a partir da data da solicitação.
      </StyledCaption>

      <ModalCustomDialog
        size={2}
        style={{ width: '509px' }} // TODO: remove this field when fix the DS sizes
        confirmButton={{ label: 'Cancelar solicitação', variation: 'solid', color: 'error' }}
        backButton={{ label: 'Manter solicitação', variation: 'ghost' }}
        onClose={() => {
          sendCreditEventToMixpanel(
            EventDispatcherEvents.BUTTON_CLICKED,
            MixpanelEventActions.CLICK,
            {
              $button_name: 'Manter solicitação',
            }
          )
        }}
        title="Cancelar solicitação de crédito?"
        submitHandler={(value: string) => {
          sendCreditEventToMixpanel(
            EventDispatcherEvents.BUTTON_CLICKED,
            MixpanelEventActions.CLICK,
            {
              $button_name: 'Cancelar solicitação',
            }
          )

          mutateAsync({
            creditOrderId: creditOrderId,
            type: 'cancel',
            message: value,
          }).finally(() => redirect())
        }}
        labelReason="Qual foi o principal motivo para cancelar a solicitação?"
        description={`Cancelar a solicitação não resultará em custos adicionais, no entanto,  \n a oferta será perdida e as condições podem variar.`}
      >
        {canCancel ? (
          <Button color="error" variant="ghost" fullWidth>
            Cancelar solicitação
          </Button>
        ) : null}
      </ModalCustomDialog>
    </StyledCard>
  )
}
