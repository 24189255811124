import { Container, StyledSelect } from './styles'
import { CreditGracePeriodDropdownProps } from './types'

const CreditGracePeriodDropdown = ({
  title,
  gracePeriodDate,
  setGracePeriodDate,
  gracePeriodDates,
  placeholder,
}: CreditGracePeriodDropdownProps) => {
  return (
    <Container>
      <StyledSelect
        data-testid="credit-grace-period-dropdown"
        size={3}
        variant="surface"
        placeholder={placeholder}
        label={title}
        options={gracePeriodDates ?? []}
        onValueChange={value => {
          const selectedGracePeriodDate = gracePeriodDates?.find(e => e.value === value) ?? null

          if (selectedGracePeriodDate) {
            setGracePeriodDate(selectedGracePeriodDate)
          }
        }}
        defaultValue={gracePeriodDate?.value}
      />
    </Container>
  )
}

export default CreditGracePeriodDropdown
