import { Dayjs } from 'dayjs'
import { UseFormReturn, Control } from 'react-hook-form'
import { Pos } from 'src/shared/interfaces'
import { NegotiationFlowMetaData } from '../../hooks/useNegotiationPaymentFormsAgreement'

export enum CREDIT_CARD_PAYMENT_OPTIONS {
  PAYMENT_LINK = 'payment-link',
  POS = 'pos',
}

export type InputMinDownProps = {
  disabled?: boolean
  handleValue: (value: any) => void
  label?: string
  maxAmount: number
  minAmount: number
  selectedValue: number
}

export type InputListPosProps = {
  handleValue: (value: any) => void
  list: Pos[]
  selectedValue: string
}

export type InputCreditCardOptionProps = {
  handleValue: (value: any) => void
  selectedValue: string
}

export type InputDueDateProps = {
  handleValue: (value: Dayjs) => void
  label: string
  maxDate: Date
  minDate: Date
  selectedValue: Date
}

export type InputInstallmentsProps = {
  handleValue: (value: number) => void
  maxInstallment: number
  selectedValue: number
  totalAmount: number
}

export interface InputDateProps {
  form: UseFormReturn<NegotiationFlowMetaData>
  maxDate: Date
}

export interface InputMinDownPaymentProps {
  form: UseFormReturn<NegotiationFlowMetaData>
  maxInstallment: number
  minAmount: cents
  selectDownPayment: cents
  setSelectedDownPayment: (value: number) => void
  totalValue: cents
}

export interface InputIsLinkPaymentProps {
  form: UseFormReturn<NegotiationFlowMetaData>
  handlePaymentLink: (value: any) => void
}

export interface InputSelectPosProps {
  form: UseFormReturn<NegotiationFlowMetaData>
  list: Pos[]
}

export interface FormControlPosProps {
  control: Control<any>
  handleSelect: (value: any) => void
  listPos: Pos[]
}

export interface InputInstallmentProps {
  form: UseFormReturn<NegotiationFlowMetaData>
  maxInstallments: number
  totalAmount: number
}

export interface StyledInputMinDownProps {
  disabled: boolean
}
