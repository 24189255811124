import { Controller, UseFormReturn } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { Guardian } from 'src/shared/interfaces'

import { Accordion } from '@gravity/accordion'
import { TextField } from '@gravity/text-field'
import { UnleashFlags, useUnleashFlag } from 'src/shared/hooks/useUnleashFlag'
import { formatCPF, validateEmail, validatePhoneNumber } from 'src/shared/utils'

export type CollapseGuardianProps = {
  edit?: boolean
  form: UseFormReturn<any>
  guardian: Guardian
}

const CollapseGuardian = ({ edit = false, guardian, form }: CollapseGuardianProps) => {
  const isNewEditRfNameEnabled = useUnleashFlag(UnleashFlags.GC_973_B2BCORE_122_EDIT_RF)
  const { control } = form

  return (
    <Accordion.Item value="guardian">
      <Accordion.Trigger>Informações do Responsável</Accordion.Trigger>
      <Accordion.Content>
        <div className="flex flex-col gap-4">
          <TextField
            fullWidth
            name="CPF"
            defaultValue={formatCPF(guardian.tax_id)}
            disabled
            size={3}
          />
          {isNewEditRfNameEnabled ? (
            <Controller
              rules={{
                required: true,
              }}
              name="name"
              control={control}
              render={({ field, field: { value }, fieldState: { error } }) => {
                return (
                  <TextField
                    {...field}
                    value={value}
                    fullWidth
                    name="Nome do Responsável Financeiro"
                    error={Boolean(error)}
                    errorMessage={error ? 'O nome é obrigatório' : ''}
                    disabled={!edit}
                    size={3}
                  />
                )
              }}
            />
          ) : (
            <TextField
              fullWidth
              name="Nome do Responsável Financeiro"
              defaultValue={guardian.name}
              disabled
              size={3}
            />
          )}
          <Controller
            rules={{
              validate: v => (v ? validateEmail(v) : true),
              max: 254,
              min: 3,
              required: true,
            }}
            name="email"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                name="Email"
                id="edit-guardian-email-input"
                fullWidth
                error={Boolean(error)}
                errorMessage={error ? 'E-mail inválido' : ''}
                disabled={!edit}
                size={3}
              />
            )}
          />
          <Controller
            rules={{
              required: true,
              validate: validatePhoneNumber,
            }}
            name="phone_number"
            control={control}
            render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
              <NumberFormat
                {...rest}
                label="Celular"
                id="edit-guardian-phone-number-input"
                data-testid="edit-guardian-phone-number-input"
                type="tel"
                format="(##) #####-####"
                mask="_"
                onValueChange={currentValue => {
                  onChange(currentValue.value)
                }}
                value={value}
                customInput={TextField}
                variant="outlined"
                error={Boolean(error)}
                helperText={error ? 'Telefone inválido' : ''}
                disabled={!edit}
                size={3}
              />
            )}
          />
        </div>
      </Accordion.Content>
    </Accordion.Item>
  )
}

export default CollapseGuardian
