import { ReenrollmentStatus } from '@/shared/interfaces'
import { Badge } from '@gravity/badge'
import type { BadgeProps } from '@gravity/badge'

export type Props = {
  disabled?: boolean
  status: string
}
export const statusParamsDict: PartialRecord<
  ReenrollmentStatus,
  { color: BadgeProps['color']; text: string; variant?: BadgeProps['variant'] }
> = {
  [ReenrollmentStatus.NOT_REENROLLABLE]: { color: 'error', text: 'Não rematriculável' },
  [ReenrollmentStatus.REENROLLMENT_AVAILABLE]: { color: 'accent', text: 'Disponível' },
  [ReenrollmentStatus.ENROLLMENT_IN_PROGRESS]: { color: 'warning', text: 'Em andamento' },
  [ReenrollmentStatus.ENROLLMENT_AT_RISK]: {
    color: 'error',
    text: 'Em risco',
    variant: 'solid',
  },
  [ReenrollmentStatus.ENROLLED]: { color: 'success', text: 'Matriculado' },
  [ReenrollmentStatus.GATE_ERROR]: { color: 'neutral', text: 'Indisponível' },
  [ReenrollmentStatus.UNAVAILABLE_AT_MOMENT]: { color: 'neutral', text: 'Indisponível' },
}

// Type guard function to check if a string is a key of ReenrollmentStatus
const isReenrollmentStatus = (status: string): status is ReenrollmentStatus =>
  Object.values(ReenrollmentStatus).includes(status as ReenrollmentStatus)

export const StatusBadge = ({ status, disabled }: Props) => {
  if (!isReenrollmentStatus(status) || !statusParamsDict[status]) {
    return null
  }

  return (
    <Badge
      variant={statusParamsDict[status]?.variant ?? 'soft'}
      color={statusParamsDict[status]?.color}
      style={disabled ? { opacity: 0.5 } : {}}
    >
      {statusParamsDict[status]?.text ?? ''}
    </Badge>
  )
}
