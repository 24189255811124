import styled from 'styled-components'

export const NegotiationTableContainer = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
  margin-bottom: ${props => props.theme.gravity.spacing[6]};

  padding: 1px;

  align-items: start;
`
