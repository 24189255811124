import { useApiClient } from '@/shared/hooks'
import {
  GetBatchSummaryParams,
  GetBatchSummaryResponse,
  GetStudentDetailsParams,
  GetStudentDetailsResponse,
  GetBatchUnfinishedStudentsParams,
  GetBatchUnfinishedStudentsResponse,
  PatchStudentDetailsParams,
} from './types'
import { AxiosInstance } from 'axios'

export const onboardingApi = (fetcher: AxiosInstance) => ({
  getBatchSummary: async (params: GetBatchSummaryParams) => {
    return fetcher.get<GetBatchSummaryResponse>(`/onboarding_batches/${params.batchID}/summary`)
  },
  getStudentDetails: async (params: GetStudentDetailsParams) => {
    return fetcher.get<GetStudentDetailsResponse>(
      `/onboarding_batches/${params.batchID}/students/${params.studentID}/details`
    )
  },
  getBatchUnfinishedStudents: async (params: GetBatchUnfinishedStudentsParams) => {
    const { batchID, search, page, per_page } = params

    return fetcher.get<GetBatchUnfinishedStudentsResponse>(
      `/onboarding_batches/${batchID}/students`,
      {
        params: {
          search,
          page,
          per_page,
        },
      }
    )
  },
  patchStudentDetails: async (params: PatchStudentDetailsParams) => {
    return fetcher.patch<PatchStudentDetailsParams>(
      `/onboarding_batches/${params.batch_id}/students/${params.id}/details`,
      params
    )
  },
})

export const useOnboardingApi = () => {
  const { apiClient } = useApiClient()

  return onboardingApi(apiClient.privateApiV2)
}
