import { LayoutTemplate } from '@monorepo/onboarding/LayoutTemplate'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useParams, useHistory } from 'react-router-dom'
import { useGetOnboardingBatch } from '../../hooks/useFetchOnboardingBatches'
import { LoadingPage } from '../Loading'
import { EventProvider } from '@monorepo/onboarding/hooks/eventContext'
import { DashboardContent } from './DashboardContent'
import { DashboardFooter } from './DashboardFooter'

const PageComponent = () => {
  const { schoolSlug } = useSelectedSchool()
  const { batchID } = useParams<{ batchID: string }>()
  const history = useHistory()

  const { isLoading: isGetOnboardingLoading, data } = useGetOnboardingBatch(
    { batchID },
    {
      onSuccess(data) {
        if (data.status === 'CANCELED') return history.push(`/${schoolSlug}/migrar-dados/`)
      },
      onError(error) {
        console.error(error)
        history.push(`/${schoolSlug}/migrar-dados/`)
      },
    }
  )

  if (isGetOnboardingLoading || data === undefined) {
    return <LoadingPage />
  }

  return (
    <LayoutTemplate
      title="Visão geral"
      description="Confira os principais dados da escola para garantir que a planilha importada está correta. Caso identifique alguma divergência, substitua a planilha antes de avançar para as etapas de correção."
      footer={<DashboardFooter />}
    >
      <DashboardContent />
    </LayoutTemplate>
  )
}

export const PreviewPage = () => {
  return (
    <EventProvider>
      <PageComponent />
    </EventProvider>
  )
}
