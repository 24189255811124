import { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, GridItem } from '@gravity/grid'
import { FullScreenHeader } from '@gravity/header'

import { TopHeader } from '@/shared/components/TopHeader'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useLayout } from '@/shared/hooks/useLayout'

export const DefaultPageTemplate = ({
  children,
  previousRoute,
  title,
}: {
  children: ReactElement
  previousRoute: string
  title: string
}) => {
  const { replace } = useHistory()
  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  useLayout({
    enableHeader: false,
    enableSideMenu: !renderNewHeader,
  })

  return (
    <>
      {renderNewHeader ? (
        <FullScreenHeader
          className="fixed left-0 top-0 right-0 z-[99]"
          title={title}
          onClose={() => replace(previousRoute)}
        />
      ) : (
        <TopHeader className="px-8" backButtonLink={previousRoute} />
      )}

      <div className={`flex justify-center ${renderNewHeader ? 'mt-14' : ''}`}>
        <Grid className="grid-container mt-8">
          <GridItem
            xl="4 / 10"
            lg="4 / 10"
            md="4 / 10"
            xs={4}
            sm={8}
            className="grid-item content-item flex justify-center"
          >
            <div className="w-full">{children}</div>
          </GridItem>
        </Grid>
      </div>
    </>
  )
}
