import { useState } from 'react'
import { Ordering, SortOrder } from '../components/IrregularityTable/types'
import { InstallmentIrregularityTableItem, Irregularity } from '@monorepo/enrollment/services/types'
import { IrregularityParams } from '../types'

export const useSortOrder = () => {
  const [order, setOrder] = useState<Ordering>({
    sortBy: 'pending_column',
    sortOrder: 'desc',
  })

  const updateOrder = (sortBy: string, sortOrder: SortOrder) => {
    setOrder({ sortBy, sortOrder })
  }

  const sortRows = <T extends InstallmentIrregularityTableItem | Irregularity>(
    rows: T[],
    dict: Record<string, IrregularityParams>
  ) => {
    rows.sort((after, before) => {
      const keyAfter = after.irregularity_type as keyof typeof dict
      const keyBefore = before.irregularity_type as keyof typeof dict
      const valueAfter = dict[keyAfter]?.name
      const valueBefore = dict[keyBefore]?.name
      if (valueAfter && valueBefore && valueAfter < valueBefore) {
        return order.sortOrder === 'asc' ? 1 : -1
      }
      if (valueAfter && valueBefore && valueAfter > valueBefore) {
        return order.sortOrder === 'asc' ? -1 : 1
      }
      return 0
    })
  }

  return { order, updateOrder, sortRows }
}
