import type { SimulationPaymentMethod } from '@/modules/guardians/models/agreement'

export enum InvoiceStatus {
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  PAID = 'PAID',
}

export interface PaymentTableProps {
  amount: cents
  due_date: datestring
  formOfPaymentLabel: string
  idForReceipt: uuid
  installmentLabel: string
  invoiceStatus: InvoiceStatus
  onSuccess?: () => void
  paid_date?: datestring
  paymentLink: string
  paymentMethod?: SimulationPaymentMethod
  stepId?: string
}
