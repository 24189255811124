import { formatCentsToReal } from 'src/shared/utils'
import { WrapperColumn } from './InformationTitle'
import { ReceivableStatuses } from 'src/shared/interfaces'
import { AgreementSimulationCombined } from '@/modules/guardians/models/agreement'
import { getReceivableStatus } from '../helpers/getReceivableStatus'
import { Text } from '@gravity/text'
import { Link } from '@gravity/link'

export interface InformationTitleAgreementProps {
  agreementSimulations: AgreementSimulationCombined[]
  handleClick: () => void
  negotiationID?: string
  positionAgreement: number
  totalAmount: number
}

const formatTitleV2 = (student: string, product: string) => {
  return `${student} - ${product}`
}

const formatInstallmentsV2 = (
  count: number,
  isOverdue: boolean,
  totalAmount: number,
  position: number
) => {
  const needPlural = count === 1 ? '' : 's'
  if (!isOverdue) {
    return `${position}º pagamento - ${count} parcela${needPlural} a vencer - ${formatCentsToReal(
      totalAmount
    )}`
  }
  return `${position}º pagamento - ${count} parcela${needPlural} vencida${needPlural} - ${formatCentsToReal(
    totalAmount
  )}`
}

const InformationTitleAgreement = ({
  handleClick,
  agreementSimulations,
  negotiationID,
  totalAmount,
  positionAgreement,
}: InformationTitleAgreementProps) => {
  const isOverdue = agreementSimulations[positionAgreement].receivables.every(
    receivable => getReceivableStatus(receivable) === ReceivableStatuses.OVERDUE
  )
  const student = agreementSimulations[positionAgreement].receivables[0].student.split(' ')[0]
  const product = agreementSimulations[positionAgreement].receivables[0].product
  const count = agreementSimulations[positionAgreement].receivable_ids.length

  const negotiationShortID = (negotiationID || '')?.slice(0, 6)?.toUpperCase()

  return (
    <WrapperColumn>
      <Text variant="subtitle-medium">
        {negotiationID ? `Negociação #${negotiationShortID}` : formatTitleV2(student, product)}
      </Text>
      <div className="flex gap-2">
        <Text variant="body-2-regular">
          {formatInstallmentsV2(count, isOverdue, totalAmount, positionAgreement + 1)}
        </Text>
        <Link onClick={handleClick}>Ver detalhes</Link>
      </div>
    </WrapperColumn>
  )
}

export default InformationTitleAgreement
