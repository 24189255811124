import { useEffect } from 'react'
import { useSetPageTitle } from '@/shared/hooks/useSetPageTitle'
import { useHistory } from 'react-router-dom'

import { useEnrollmentCampaignRedirect } from '@/shared/hooks/useEnrollmentCampaignRedirect'
import { Stepper, useSteps } from '@gravity/stepper'
import { useSchoolEnrollmentCycleContext } from '@monorepo/enrollment/contexts/SchoolEnrollmentContext'
import { useAddBulkInstallmentStore } from '@monorepo/enrollment/store/AddBulkInstallmentStore'

import { useIsPageEnabled } from './hooks/useIsPageEnabled'
import { PaymentPlanStep } from './components/PaymentPlanStep'
import { CustomizationStep } from './components/CustomizationStep'
import { FullScreenHeader } from '@gravity/header'
import { useLayout } from '@/shared/hooks/useLayout'
import { Grid, GridItem } from '@gravity/grid'

export const AddBulkInstallment = () => {
  useEnrollmentCampaignRedirect()
  useIsPageEnabled()
  useSetPageTitle('Adicionar mensalidades: - isaac')
  useLayout({ enableSideMenu: false, enableHeader: false })
  const history = useHistory()

  const addBulkInstallmentSteps = [
    {
      label: 'Plano de pagamento',
      component: PaymentPlanStep,
    },
    { label: 'Personalização', component: CustomizationStep },
  ]

  const { selectedEnrollmentCycle } = useSchoolEnrollmentCycleContext()

  const { activeStep, goToPreviousStep, goToNextStep } = useSteps({
    defaultStep: 0,
    count: addBulkInstallmentSteps.length,
  })

  const ActiveComponent = addBulkInstallmentSteps[activeStep].component

  const { resetStore } = useAddBulkInstallmentStore()

  useEffect(() => {
    return () => {
      resetStore()
    }
  }, [])

  return (
    <>
      <FullScreenHeader
        title="Adicionar mensalidades"
        className="fixed top-0 left-0 right-0 z-[99]"
        onClose={() => history.goBack()}
      />
      <div className="flex justify-center my-32">
        <Grid>
          <GridItem md="3 / 11" lg="3 / 11" xl="3 / 11">
            <Stepper.Root activeStep={activeStep}>
              {addBulkInstallmentSteps.map((_, index) => (
                <Stepper.Step key={index} step={index}>
                  <Stepper.StatusIcon />
                  <Stepper.Title>{addBulkInstallmentSteps[index].label}</Stepper.Title>
                </Stepper.Step>
              ))}
            </Stepper.Root>
            {selectedEnrollmentCycle && (
              <ActiveComponent
                enrollmentCycle={selectedEnrollmentCycle}
                onLeave={goToPreviousStep}
                onFinish={goToNextStep}
              />
            )}
          </GridItem>
        </Grid>
      </div>
    </>
  )
}
