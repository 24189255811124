import { GridItem, Grid } from '@gravity/grid'
import styled from 'styled-components'

export const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const CenterGrid = styled(Grid)`
  margin: 0 auto;
`
export const CustomGridItem = styled(GridItem)`
  display: flex;
  flex-direction: column;
`

export const StepWrapper = styled.div`
  [role='progressbar'] {
    width: 100%;
  }
`
