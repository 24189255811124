import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query'

import { queryClient } from '@/shared/contexts/ReactQuery'
import { useApiClient } from '@/shared/hooks/useApiClient'

import { productsService } from '@monorepo/products/services/products'

import type {
  FetchProductsRequest,
  FetchProductsResponse,
  FetchGetProductClassifications,
} from '@monorepo/products/services/products/types'

// TODO: Move only 'useProducts' to shared package, its used in others modules (enrollment...)
export const useProducts = (
  { schoolId, pagination, ids }: FetchProductsRequest,
  options?: UseQueryOptions<FetchProductsResponse>
) => {
  const { apiClient } = useApiClient()

  const service = productsService(apiClient.privateApi)

  return useQuery<FetchProductsResponse>(
    ['products', schoolId, ids, JSON.stringify({ ...pagination })],
    async () => {
      const data = await service.fetchProducts({
        schoolId,
        pagination,
        ids,
      })

      return data
    },
    options
  )
}

export const useInfiniteProducts = (
  { schoolId, pagination, ids }: FetchProductsRequest,
  options?: UseInfiniteQueryOptions<FetchProductsResponse>
) => {
  const { apiClient } = useApiClient()

  const service = productsService(apiClient.privateApi)

  return useInfiniteQuery<FetchProductsResponse>(
    ['products', schoolId, ids, JSON.stringify({ ...pagination })],
    async ({ pageParam = 1 }) => {
      const data = await service.fetchProducts({
        schoolId,
        pagination: {
          ...pagination,
          page: pageParam,
        },
        ids,
      })

      return data
    },
    {
      getNextPageParam: lastPage => {
        const page = lastPage?.pagination?.page ?? 0
        const currentTotalOfItems = page * (lastPage?.pagination?.per_page ?? 0)
        const hasNextPage = currentTotalOfItems < (lastPage?.pagination?.total ?? 0)
        return hasNextPage ? page + 1 : false
      },
      ...options,
    }
  )
}

export const useMutateOnCreateProduct = () => {
  const { apiClient } = useApiClient()

  const service = productsService(apiClient.privateApi)

  return useMutation(service.createProduct, {
    onSuccess: async response => {
      await queryClient.invalidateQueries(['products'])

      return response
    },
  })
}

export const useMutateOnUpdateProduct = () => {
  const { apiClient } = useApiClient()

  const service = productsService(apiClient.privateApi)

  return useMutation(service.updateProduct, {
    onSuccess: async response => {
      await queryClient.invalidateQueries(['products'])

      return response
    },
  })
}

export const useMutateOnDeleteProduct = () => {
  const { apiClient } = useApiClient()

  const service = productsService(apiClient.privateApi)

  return useMutation(service.deleteProduct, {
    onSuccess: () => queryClient.invalidateQueries(['products']),
  })
}

export const useProductClassifications = (
  options?: UseQueryOptions<FetchGetProductClassifications>
) => {
  const { apiClient } = useApiClient()

  const service = productsService(apiClient.privateApi)

  return useQuery<FetchGetProductClassifications>(
    ['classifications'],
    async () => {
      const data = await service.getClassifications()

      return data
    },
    options
  )
}
