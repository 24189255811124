import styled from 'styled-components'

import { Table } from '@gravity/table'

export const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20vh;
`
export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20vh;
`

export const TableWrapper = styled.div`
  width: 100%;
  margin-top: ${props => props.theme.gravity.spacing[8]};
`

export const TableHeaderCell = styled(Table.HeaderCell)`
  span {
    -webkit-line-clamp: 2;
  }
`

type TableRowProps = {
  disabled: boolean
}

export const TableRow = styled(Table.Row)<TableRowProps>`
  ${({ disabled }) =>
    disabled &&
    `
    path {
      color: ${props => props.theme.gravity.colors['colors-background-neutral-4']};
    }
  `}
  span {
    -webkit-line-clamp: 2;
  }
`
