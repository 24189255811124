import { useState, useEffect } from 'react'

import { NetworkError } from '@/shared/components/CashoutErrors'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from './ErrorFallback'
import * as Sentry from '@sentry/react'
import { ErrorBoundaryProps } from './types'

const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  const handleOffline = () => {
    setIsOnline(false)
  }

  useEffect(() => {
    window.addEventListener('offline', handleOffline)
  }, [])

  if (!isOnline) {
    return <NetworkError />
  }

  return (
    <ReactErrorBoundary
      FallbackComponent={() => <ErrorFallback />}
      onError={(error, _errorInfo) => {
        Sentry.captureException(error, { level: 'error' })
      }}
    >
      {children}
    </ReactErrorBoundary>
  )
}

export default ErrorBoundary
