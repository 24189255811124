import { productsList, studentList } from '@/shared/mocks'
import {
  CONTRACT_STATUS_OPTIONS,
  DEBT_STATUS_OPTIONS,
} from '../GuardianDetailsContractsTab/components/GuardianDetailsContractsFilter/constants'
import { INSTALLMENT_STATUSES, INSTALLMENT_TYPES } from '../GuardianDetails/constants'

export const guardiansFiltersMock = () => {
  const filters = {
    debt_statuses: DEBT_STATUS_OPTIONS,
    contract_statuses: CONTRACT_STATUS_OPTIONS,
    reference_years: [
      {
        label: '2021',
        value: '2021',
      },
      {
        label: '2022',
        value: '2022',
      },
    ],
    products: [
      {
        label: productsList[0].name,
        value: productsList[0].id,
      },
      {
        label: productsList[1].name,
        value: productsList[1].id,
      },
    ],
    students: [
      {
        label: studentList[0].name,
        value: studentList[0].id,
      },
      {
        label: studentList[1].name,
        value: studentList[1].id,
      },
    ],
    installment_statuses: INSTALLMENT_STATUSES,
    installment_types: INSTALLMENT_TYPES,
    negotiation_id: [
      { label: 'Negotiation ID 1', value: 'negotiation-id-1' },
      { label: 'Negotiation ID 2', value: 'negotiation-id-2' },
    ],
  }
  return { data: filters }
}
