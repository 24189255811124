import { AlertBanner } from '@gravity/alert-banner'
import { useGuardianAlertBannerInfos } from './useGuardianAlertBannerInfos'
import { GuardianAlertBannerProps } from './types'

export const GuardianAlertBanner = ({
  guardianTaxId,
  isError,
  isLoading,
}: GuardianAlertBannerProps) => {
  const {
    shouldShowAlertBanner,
    description,
    redirectLink,
    linkLabel,
    onLinkClick,
  } = useGuardianAlertBannerInfos(guardianTaxId, isError, isLoading)

  if (!shouldShowAlertBanner) {
    return null
  }

  return (
    <AlertBanner
      icon={false}
      description={description}
      href={redirectLink}
      linkLabel={linkLabel}
      onLinkClick={onLinkClick}
    />
  )
}
