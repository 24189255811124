import { Controller, useController } from 'react-hook-form'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'
import { Combobox } from '@gravity/combobox'
import type { Option } from '@gravity/combobox'

import type { FormProps, SelectProductProps } from './types'
import { useProductValidation } from './hooks/useProductValidation'
import { excludeTeachingMaterialProducts } from './utils/excludeTeachingMaterialProducts'

import { useProductSelect } from '@monorepo/enrollment/pages/Campaigns/NewCampaign/hooks/useSelectAutocomplete'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useAllProductsList } from '@monorepo/enrollment/pages/Campaigns/NewCampaign/components/PaymentConditionsStep/components/SelectedProductInput/hooks/useAllProductsList'
import { UnleashFlags, useJWT, useUnleashFlag } from '@/shared/hooks'
import { useMemo } from 'react'

const SelectProduct = ({ form, referenceYear }: SelectProductProps) => {
  const productYear = referenceYear
  const { school } = useSelectedSchool()
  const { isSchool } = useJWT()
  const isHybridModel = useUnleashFlag(UnleashFlags.ISP_38_ENABLE_HYBRID_MODEL_CHARGING)

  const productList = useAllProductsList(school?.id)
  const initialOptions = useMemo(() => {
    const list = productList?.data || []
    if (isSchool && isHybridModel) {
      return excludeTeachingMaterialProducts(list)
    }
    return list
  }, [productList, isSchool, isHybridModel])

  const { control, clearErrors } = form

  const { field } = useController({
    name: 'product_id',
    control: form.control,
    defaultValue: undefined,
  })

  const {
    isSearchingOptions,
    filteredOptions,
    noOptionsText,
    handleSearch,
    resetField,
  } = useProductSelect<FormProps>({
    form,
    initialOptions,
  })

  const productToOption = (productId: string) => {
    if (!productId) return undefined

    const product = initialOptions.find(product => product.id === productId)
    if (!product) return undefined

    return {
      label: product.name,
      value: product.id,
    }
  }

  const handleOptionChange = (selectedOption: Option | null) => {
    if (!selectedOption) {
      resetField()
      return
    }

    clearErrors('product_id')
    const productItem = initialOptions.find(product => product.id === selectedOption.value)

    if (productItem) {
      form.setValue('product_id', productItem.id)
      form.setValue('product', productItem)
    }
  }

  const { validateProductRequired, validateStudentIsInCampaign } = useProductValidation({
    form,
    productYear,
    schoolId: school?.id || '',
  })

  return (
    <>
      <div className="mb-3 mt-8">
        <Heading variant="heading-h4-medium" className="mb-2">
          Selecione o produto{' '}
          <span style={{ fontWeight: 'normal' }}>(Ano letivo {productYear})</span>
        </Heading>
        <Text variant="subtitle-regular">
          O produto selecionado será para onde o aluno(a) será matriculado.
        </Text>
      </div>
      <div className="mt-6">
        <Controller
          rules={{ validate: { validateProductRequired, validateStudentIsInCampaign } }}
          control={control}
          name="product_id"
          render={({ fieldState: { error } }) => (
            <Combobox
              size={3}
              value={productToOption(field.value)}
              options={filteredOptions.map(option => ({
                label: option.name,
                value: option.id,
              }))}
              onChangeValue={handleOptionChange}
              onSearch={handleSearch}
              placeholder="Produto"
              emptyPlaceholder={noOptionsText}
              isLoadingOptions={isSearchingOptions}
              hasError={Boolean(error?.message)}
              enableHelperTextSlot={Boolean(error?.message)}
              helperText={error?.message}
            />
          )}
        />
      </div>
    </>
  )
}

export default SelectProduct
