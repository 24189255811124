import { CheckboxCircleOutline, FolderOutline, TimerOutline } from '@gravity/icons'
import { FilterToggle } from '@monorepo/enrollment/components/FilterToggle'
import type { FilterOption } from '@monorepo/enrollment/components/FilterToggle'

interface CampaignsFilterProps {
  onChange: (value: string) => void
}

export enum CampaignFilterValues {
  ALL = '',
  ENDED = 'ENDED',
  OPEN = 'OPEN',
}

export const CampaignsFilter = ({ onChange }: CampaignsFilterProps) => {
  const filterOptions: FilterOption[] = [
    {
      value: CampaignFilterValues.ALL,
      label: 'Todas',
      icon: <FolderOutline />,
    },
    {
      value: CampaignFilterValues.OPEN,
      label: 'Iniciadas',
      icon: <CheckboxCircleOutline />,
    },
    {
      value: CampaignFilterValues.ENDED,
      label: 'Encerradas',
      icon: <TimerOutline />,
    },
  ]

  return <FilterToggle filterOptions={filterOptions} onChange={onChange} />
}
