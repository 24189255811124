import { formatter } from '@/modules/guardians/utils/formatter'
import { LiquidationSource, PaymentMethod } from '@/shared/interfaces'
import { InstallmentStatus } from '@/modules/guardians/GuardianDetails/constants'
import { InstallmentDrawerPaid } from '@/modules/guardians/InstallmentsDrawerContainer/types'

export const useFooterMessage = ({
  dueDate,
  status,
  paid,
}: {
  dueDate: string
  paid?: InstallmentDrawerPaid
  status?: InstallmentStatus
}) => {
  if (!status) {
    return ''
  }

  if (status === 'PAID' && paid) {
    const formattedPaidAmount = formatter.amount(paid.amount)
    const formattedPaidDate = formatter.full_date(paid.date)

    if (paid.payment_source === LiquidationSource.IUGU) {
      const messagesMap = {
        [PaymentMethod.BANK_SLIP_PAYMENT_METHOD]: `Pago ${formattedPaidAmount} no boleto em ${formattedPaidDate}`,
        [PaymentMethod.CREDIT_CARD_PAYMENT_METHOD]: `Pago ${formattedPaidAmount} no cartão de crédito - link de pagamento em ${formattedPaidDate}`,
        [PaymentMethod.DEBIT_CARD_PAYMENT_METHOD]: `Pago ${formattedPaidAmount} no cartão de débito - link de pagamento em ${formattedPaidDate}`,
        [PaymentMethod.PIX_PAYMENT_METHOD]: `Pago ${formattedPaidAmount} no PIX em ${formattedPaidDate}`,
        [PaymentMethod.SCHOOL_PORTFOLIO_REPURCHASE]: 'Baixa registrada por recompra de carteira',
      } as Record<PaymentMethod, string>

      return (
        messagesMap[paid.payment_method] ?? `Pago ${formattedPaidAmount} em ${formattedPaidDate}`
      )
    }

    if (
      [LiquidationSource.PAGARME_CHECKOUT, LiquidationSource.STONE_CHECKOUT].includes(
        paid.payment_source
      )
    ) {
      const messagesMap = {
        [PaymentMethod.CREDIT_CARD_PAYMENT_METHOD]: `Pago ${formattedPaidAmount} no cartão de crédito - maquininha em ${formattedPaidDate}`,
        [PaymentMethod.DEBIT_CARD_PAYMENT_METHOD]: `Pago ${formattedPaidAmount} no cartão de débito - maquininha em ${formattedPaidDate}`,
      } as Record<PaymentMethod, string>

      return (
        messagesMap[paid.payment_method] ?? `Pago ${formattedPaidAmount} em ${formattedPaidDate}`
      )
    }

    if (paid.payment_source === LiquidationSource.SCHOOL_ACCOUNT) {
      return `Recebimento manual na conta da escola em ${formattedPaidDate}`
    }

    if (paid.payment_source === LiquidationSource.ISAAC_ACCOUNT) {
      return `Recebimento manual na conta do Isaac em ${formattedPaidDate}`
    }

    return `Pago ${formattedPaidAmount} em ${formattedPaidDate}`
  }

  if (status === 'OVERDUE') {
    return `Vencido em ${formatter.full_date_br(dueDate)}`
  }

  return `Vencimento em ${formatter.full_date_br(dueDate)}`
}
