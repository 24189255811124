import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useLayout } from '@/shared/hooks/useLayout'
import { Grid, GridItem } from '@gravity/grid'
import { Skeleton } from '@gravity/skeleton'

const StatusStepper = () => {
  return (
    <div className="flex flex-col p-6 w-full">
      <div className="flex flex-col items-start gap-2 flex-1 w-full mb-[25px]">
        <Skeleton radius={8} height={24} fullWidth />
        <Skeleton radius={8} height={24} fullWidth />
      </div>

      <div className="flex flex-col items-start gap-2 flex-1 w-full mb-[25px]">
        <Skeleton radius={8} height={24} fullWidth />
        <Skeleton radius={8} height={24} fullWidth />
      </div>

      <div className="flex justify-between w-full mb-[25px]">
        <div className="flex flex-col items-start gap-2 flex-1">
          <Skeleton radius={8} height={24} fullWidth />
          <Skeleton radius={8} height={24} fullWidth />
        </div>
        <div className="flex flex-col items-start gap-2 flex-1">
          <Skeleton radius={8} height={24} fullWidth />
          <Skeleton radius={8} height={24} fullWidth />
        </div>
      </div>

      <div className="flex justify-between w-full mb-[25px]">
        <div className="flex flex-col items-start gap-2 flex-1">
          <Skeleton radius={8} height={24} fullWidth />
          <Skeleton radius={8} height={24} fullWidth />
        </div>
        <div className="flex flex-col items-start gap-2 flex-1">
          <Skeleton radius={8} height={24} fullWidth />
          <Skeleton radius={8} height={24} fullWidth />
        </div>
      </div>

      <div className="flex flex-col items-start gap-2 flex-1 w-full mb-[25px]">
        <Skeleton radius={8} height={24} fullWidth />
        <Skeleton radius={8} height={24} fullWidth />
      </div>

      <div className="flex flex-col items-start gap-2 flex-1 w-full mb-[25px]">
        <Skeleton radius={8} height={24} width={149} />
        <Skeleton radius={8} height={24} fullWidth />
        <Skeleton radius={8} height={24} fullWidth />
        <Skeleton radius={8} height={24} fullWidth />
      </div>
    </div>
  )
}
const ChoseOfferCard = () => {
  return (
    <div className="w-full flex flex-col items-start">
      <Skeleton className="mb-[16px]" radius={8} height={24} width={288} />
      <Skeleton className="mb-[16px]" radius={8} height={24} width={201} />
      <Skeleton className="mb-[16px]" radius={8} height={24} fullWidth />
      <div className="flex flex-col items-start w-[470px] gap-[4px] mt-[8px] mb-[12px]">
        <div className="flex flex-col items-start gap-2 flex-1 w-full mb-[12px]">
          <Skeleton radius={8} height={24} width={185} />
          <Skeleton radius={8} height={24} width={401} />
        </div>
        <div className="flex flex-col items-start gap-2 flex-1 w-full mb-[12px]">
          <Skeleton radius={8} height={24} width={185} />
          <Skeleton radius={8} height={24} width={401} />
        </div>
        <div className="flex flex-col items-start gap-2 flex-1 w-full mb-[12px]">
          <Skeleton radius={8} height={24} width={185} />
          <Skeleton radius={8} height={24} width={401} />
          <Skeleton radius={8} height={24} width={401} />
        </div>
        <div className="flex flex-col items-start gap-2 flex-1 w-full mb-[12px]">
          <Skeleton radius={8} height={24} width={185} />
          <Skeleton radius={8} height={24} width={401} />
          <Skeleton radius={8} height={24} width={401} />
        </div>
        <div className="flex flex-col items-start gap-2 flex-1 w-full mb-[12px]">
          <Skeleton radius={8} height={24} width={185} />
          <Skeleton radius={8} height={24} width={401} />
        </div>
      </div>
      <div className="flex flex-col items-start gap-2 flex-1 w-full mb-[12px]">
        <Skeleton radius={8} height={24} fullWidth />
        <Skeleton radius={8} height={24} fullWidth />
        <Skeleton radius={8} height={24} width={83} />
      </div>
    </div>
  )
}

export const LoadingPaginCreditSummary = () => {
  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  useLayout({
    enableHeader: renderNewHeader,
    enableSideMenu: true,
  })

  return (
    <div className="flex justify-center pt-14">
      <Grid>
        <GridItem lg={6} md={6} sm={4}>
          <ChoseOfferCard />
        </GridItem>
        <GridItem lg="8 / 13" md="8 / 13" sm="6 / 9">
          <StatusStepper />
        </GridItem>
      </Grid>
    </div>
  )
}
