import { Discount, InstallmentType } from '@/shared/interfaces'
import { formatCentsToReal } from '@/shared/utils'
import { calculateCommomAmount, calculateTotalDiscount } from '../../utils'
import { Installment } from '@/modules/contract/services/types'
import * as Styled from './styles'
import { TotalItemProps } from './types'
import { Grid, GridItem } from '@gravity/grid'
import { Text } from '@gravity/text'

const formatTotalDetail = (installments: Installment[]) => {
  const commomAmount = calculateCommomAmount(installments)

  let text = `em ${installments.length}x`

  if (commomAmount > 0) {
    text += ` de ${formatCentsToReal(commomAmount)}`
  }

  return text
}

const TotalItem = ({ title, amount, detail }: TotalItemProps) => {
  return (
    <div aria-label={title} role="group" className="flex flex-col gap-1 items-end">
      <Text variant="body-1-medium" className="text-colors-text-main-3">
        {title}
      </Text>
      <Text variant="button-1">{formatCentsToReal(amount)}</Text>
      <Text variant="caption-regular" className="text-colors-text-main-3">
        {detail}
      </Text>
    </div>
  )
}

type TableTotalsProps = {
  installments: Installment[]
}

export const TableTotals = ({ installments }: TableTotalsProps) => {
  const allDiscounts = installments.reduce<Discount[]>(
    (allDiscounts, installment) => [...allDiscounts, ...installment.discounts],
    []
  )

  const enrollmentInstallments = installments.filter(
    i => i.receivable_type === InstallmentType.ENROLLMENT
  )
  const tuitionInstallments = installments.filter(
    i => i.receivable_type === InstallmentType.TUITION
  )

  return (
    <Grid
      className="rounded-b-2 border border-solid p-4 border-colors-border-neutral-3"
      style={{
        marginLeft: 'unset',
      }}
    >
      <GridItem xl={12} lg={12} md={12} sm={8} xs={4}>
        <Styled.TableFooterContainer>
          <TotalItem
            title="Descontos aplicados"
            amount={calculateTotalDiscount(allDiscounts)}
            detail=""
          />
          <TotalItem
            title="Valor total da pré-matrícula"
            amount={enrollmentInstallments.reduce((a, b) => a + b.amount, 0)}
            detail={formatTotalDetail(enrollmentInstallments)}
          />
          <TotalItem
            title="Valor total das mensalidades"
            amount={tuitionInstallments.reduce((a, b) => a + b.amount, 0)}
            detail={formatTotalDetail(tuitionInstallments)}
          />
        </Styled.TableFooterContainer>
      </GridItem>
    </Grid>
  )
}
