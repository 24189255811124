import { Tooltip } from '@gravity/tooltip'
import { IconButton } from '@gravity/icon-button'
import { Table } from '@gravity/table'
import type { CustomizationStepActionTableProps } from '../CustomizationStepTable/types'
import { useAddBulkInstallmentStore } from '@monorepo/enrollment/store/AddBulkInstallmentStore'
import { ArrowGoBack, PencilOutline, Prohibited2Outline } from '@gravity/icons'

export const CustomizationStepActionTable = ({
  enrollmentID,
  isStudentRemovedFromBulkAdd,
  setAddCustomTuitionDialogState,
  studentName,
}: CustomizationStepActionTableProps) => {
  const {
    setExcludedEnrollmentIds,
    removeEnrollmentFromExcludedEnrollmentIds,
    removeCustomInstallmentsFormData,
  } = useAddBulkInstallmentStore()
  return (
    <Table.ActionCell
      actions={
        <>
          {!isStudentRemovedFromBulkAdd && (
            <Tooltip text="Personalizar mensalidades" position="left">
              <IconButton
                variant="ghost"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation()
                  setAddCustomTuitionDialogState({ enrollmentID, studentName, openDialog: true })
                }}
              >
                <PencilOutline />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip
            text={isStudentRemovedFromBulkAdd ? 'Restaurar aluno' : 'Remover aluno'}
            position="left"
          >
            <IconButton
              variant="ghost"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                if (isStudentRemovedFromBulkAdd) {
                  removeEnrollmentFromExcludedEnrollmentIds(enrollmentID)
                  return
                }
                setExcludedEnrollmentIds(enrollmentID)
                removeCustomInstallmentsFormData(enrollmentID)
              }}
            >
              {isStudentRemovedFromBulkAdd ? <ArrowGoBack /> : <Prohibited2Outline />}
            </IconButton>
          </Tooltip>
        </>
      }
    />
  )
}
