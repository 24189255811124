import { Button } from '@gravity/button'
import { usePostSubmitBatchMutation } from '../../hooks/usePostSubmitBatchMutation'
import { useParams } from 'react-router-dom'
import { useToast } from '@gravity/toast'
import { RefetchOptions, RefetchQueryFilters, QueryObserverResult } from '@tanstack/react-query'
import { OnboardingBatch } from '../../services/types/onboardingBatch'
import { OverrideBatchTrigger } from '../components/OverrideBatch/OverrideBatchTrigger'
import { pageNames, useEvents } from '../../hooks/eventContext'
import { Dialog } from '@gravity/dialog'
import { useState } from 'react'
import { Text } from '@gravity/text'
import { Link } from '@gravity/link'
import styled from 'styled-components'
import { Checkbox } from '@gravity/checkbox'

interface DashboardFooterProps {
  refetchGetOnboardingBatch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<OnboardingBatch, unknown>>
}

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const CheckboxContainer = styled.div`
  display: flex;
  align-items: start;
  gap: 8px;
`

export const DashboardFooter = ({ refetchGetOnboardingBatch }: DashboardFooterProps) => {
  const { batchID } = useParams<{ batchID: string }>()
  const { toast } = useToast()
  const event = useEvents()
  const [open, setOpen] = useState(false)
  const [termsChecked, setTermsChecked] = useState(false)

  const {
    mutate: mutateStartMigration,
    isLoading: isStartMigrationLoading,
  } = usePostSubmitBatchMutation()

  const handleSubmit = async () => {
    event?.confirmSubmitBatchClicked()

    mutateStartMigration(
      { batchID },
      {
        onSuccess: async () => {
          setOpen(false)
          await refetchGetOnboardingBatch()
        },
        onError: error => {
          toast({
            title: 'Erro ao tentar migrar os dados',
            type: 'error',
          })
          console.error(error)
        },
      }
    )
  }

  const handleOpenChange = (open: boolean) => {
    if (open) {
      event?.submitBatchButtonClicked()
    }

    if (!open) {
      event?.cancelSubmitBatchClicked()
    }

    setOpen(open)
  }

  return (
    <>
      <OverrideBatchTrigger pageName={pageNames.BATCH_PAGE} />
      <Dialog
        backdrop
        size={2}
        open={open}
        onOpenChange={handleOpenChange}
        trigger={<Button>Migrar contratos</Button>}
        title="Aviso sobre importação de alunos"
        description={
          <DescriptionContainer>
            <Text variant="body-2-regular">
              Ao importar os alunos para a plataforma, as famílias poderão receber comunicados de{' '}
              <Link
                href="https://centraldeajuda.olaisaac.io/iniciando-a-parceria/o-que-e-dda-e-como-apoiar-os-responsaveis-financeiros"
                target="_blank"
                rel="noopener noreferrer"
              >
                Débito Direto Autorizado (DDA)
              </Link>{' '}
              emitidos pelos seus bancos. Esses DDAs são emitidos em nome das cobranças do isaac.
            </Text>
            <Text variant="body-2-regular">
              Os kits de comunicação com materiais explicativos já foram enviados. Utilize-os para
              orientar os responsáveis sobre o processo.
            </Text>
            <CheckboxContainer>
              <Checkbox
                size={1}
                checked={termsChecked}
                onCheckedChange={(checked: boolean) => setTermsChecked(checked)}
              />
              <Text variant="body-2-regular">
                Estou ciente de que as famílias poderão receber notificações do isaac, incluindo DDA
                referentes às cobranças, e desejo continuar com a importação.
              </Text>
            </CheckboxContainer>
          </DescriptionContainer>
        }
        actionButton={
          <Button loading={isStartMigrationLoading} onClick={handleSubmit} disabled={!termsChecked}>
            Iniciar migração
          </Button>
        }
        cancelButton={<Button variant="ghost">Cancelar</Button>}
      />
    </>
  )
}
