import { useQuery } from '@/shared/hooks/useQuery'
import { AlertBanner } from '@gravity/alert-banner'

export const PendingEnrollmentProcessingAlert = () => {
  const { setOnQueryParam } = useQuery()

  const toggleUpdate = () => {
    setOnQueryParam('true', 'refetch', 'replace')
  }

  return (
    <div className="py-6">
      <AlertBanner
        className="px-4"
        color="warning"
        title="A geração de faturas está levando mais tempo que o previsto"
        description={`Estamos em processo de criação das faturas, e o procedimento pode estar demorando mais do que o previsto. Pedimos que aguarde um momento e, em seguida, clique em "atualizar" para verificar o andamento do processo.`}
        linkLabel="Atualizar"
        onLinkClick={toggleUpdate}
      />
    </div>
  )
}
