import { ReenrollmentStatus } from '@/shared/interfaces'
import theme from '@/shared/theme'

export const useStatusData = (status: ReenrollmentStatus) => {
  const statusColor: Record<ReenrollmentStatus, { color: string }> = {
    [ReenrollmentStatus.NOT_REENROLLABLE]: {
      color: theme.gravity.colors['colors-background-semantic-colors-error-2'],
    },
    [ReenrollmentStatus.REENROLLMENT_AVAILABLE]: {
      color: theme.gravity.colors['colors-background-accent-3'],
    },
    [ReenrollmentStatus.ENROLLMENT_IN_PROGRESS]: {
      color: theme.gravity.colors['colors-background-semantic-colors-warning-2'],
    },
    [ReenrollmentStatus.ENROLLMENT_AT_RISK]: {
      color: theme.gravity.colors['colors-background-semantic-colors-error-2'],
    },
    [ReenrollmentStatus.ENROLLED]: {
      color: theme.gravity.colors['colors-background-neutral-2'],
    },
    [ReenrollmentStatus.GATE_ERROR]: { color: theme.gravity.colors['colors-background-neutral-2'] },
    [ReenrollmentStatus.UNAVAILABLE_AT_MOMENT]: {
      color: theme.gravity.colors['colors-background-neutral-2'],
    },
  }

  const statusContent: Record<ReenrollmentStatus, { text: string }> = {
    [ReenrollmentStatus.NOT_REENROLLABLE]: { text: '' },
    [ReenrollmentStatus.REENROLLMENT_AVAILABLE]: { text: '' },
    [ReenrollmentStatus.ENROLLMENT_IN_PROGRESS]: { text: '' },
    [ReenrollmentStatus.ENROLLMENT_AT_RISK]: { text: '' },
    [ReenrollmentStatus.ENROLLED]: { text: '' },
    [ReenrollmentStatus.GATE_ERROR]: {
      text:
        'Desculpe o inconveniente. Estamos trabalhando para resolver o problema e exibir a situação de matrícula do aluno. Por favor, tente novamente mais tarde.',
    },
    [ReenrollmentStatus.UNAVAILABLE_AT_MOMENT]: {
      text:
        'Desculpe o inconveniente. Estamos trabalhando para resolver o problema e exibir a situação de matrícula do aluno. Por favor, tente novamente mais tarde.',
    },
  }

  return {
    statusColor: statusColor[status]?.color ?? statusColor.UNAVAILABLE_AT_MOMENT.color,
    statusText: statusContent[status]?.text ?? '',
  }
}
