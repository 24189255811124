import { Accordion } from '@gravity/accordion'

import { PaymentDisputeSummary } from '@/shared/models/PaymentDisputeSummary'
import { PaymentDisputeItem } from '@/shared/models/PaymentDisputeItem'

import { paymentDisputeAccordionContentVariations } from './variations'

type PaymentDisputeAccordionContentProps = {
  loading?: boolean
  paymentDispute: PaymentDisputeSummary
  paymentDisputeItems: PaymentDisputeItem[]
}

export const PaymentDisputeAccordionContent = ({
  loading = false,
  paymentDispute,
  paymentDisputeItems,
}: PaymentDisputeAccordionContentProps) => {
  const component = paymentDisputeAccordionContentVariations[paymentDispute.type]({
    loading,
    paymentDispute,
    paymentDisputeItems,
  })

  return (
    <Accordion.Content className="px-4 pt-4 pb-4 bg-colors-background-neutral-1 border border-t-0 border-solid border-colors-border-neutral-2 rounded-bl-2 rounded-br-2">
      {component}
    </Accordion.Content>
  )
}
