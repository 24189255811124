import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useBlockNavigation } from '@/shared/hooks/useBlockNavigation'
import { ConfirmQuitDialog } from '../ConfirmQuitDialog'
import { useEvents } from '../../hooks/eventContext'
import { Footer } from '@gravity/footer'

export const PageFooter = ({
  hasUnsavedChanges,
  isSubmitting,
  isNotificationOpen,
  handleSubmit,
}: {
  handleSubmit: () => void
  hasUnsavedChanges: boolean
  isNotificationOpen: boolean
  isSubmitting: boolean
}) => {
  const events = useEvents()
  const [isConfirmQuitDialogOpen, setIsConfirmQuitDialogOpen] = useState(false)
  const history = useHistory()

  const { userIsLeaving, unblock, destinationPath } = useBlockNavigation()

  const handleConfirmLeaving = () => {
    unblock()

    userIsLeaving ? history.push(destinationPath) : history.goBack()
  }

  useEffect(() => {
    if (!userIsLeaving) {
      return
    }

    if (hasUnsavedChanges) {
      setIsConfirmQuitDialogOpen(true)
    } else {
      handleConfirmLeaving()
    }
  }, [userIsLeaving])

  return (
    <>
      <Footer
        secondaryButton={{
          label: 'Cancelar edição',
          onClick: () => {
            events?.cancelEditContract()

            if (!hasUnsavedChanges) {
              history.goBack()
              return
            }
            setIsConfirmQuitDialogOpen(true)
          },
        }}
        primaryButton={{
          label: 'Salvar edições',
          onClick: () => {
            events?.saveEdits()
            handleSubmit()
          },
          loading: isSubmitting,
          disabled: isNotificationOpen || !hasUnsavedChanges,
        }}
      />
      <ConfirmQuitDialog
        open={isConfirmQuitDialogOpen}
        onConfirm={handleConfirmLeaving}
        onClose={() => {
          setIsConfirmQuitDialogOpen(false)
          unblock()
        }}
      />
    </>
  )
}
