import { Dialog } from '@gravity/dialog'
import { Button } from '@gravity/button'
import { useEffect, useState } from 'react'

export enum ErrorDialogNames {
  SAVE_CANCEL_ERROR = 'SAVE_CANCEL_ERROR',
  SAVE_CANCEL_ONLY_ERROR = 'SAVE_CANCEL_ONLY_ERROR',
  SAVE_UPDATE_ERROR = 'SAVE_UPDATE_ERROR',
}

type SelectErrorDialogNameParams = {
  cancelSuccess: boolean
  hasCancel: boolean
  hasUpdate: boolean
  updateSuccess: boolean
}

export const selectErrorDialogName = ({
  hasUpdate,
  updateSuccess,
  hasCancel,
  cancelSuccess,
}: SelectErrorDialogNameParams): ErrorDialogNames | null => {
  const conditions: Array<[ErrorDialogNames, boolean]> = [
    [ErrorDialogNames.SAVE_CANCEL_ONLY_ERROR, hasCancel && !hasUpdate && !cancelSuccess],
    [ErrorDialogNames.SAVE_CANCEL_ERROR, hasUpdate && updateSuccess && hasCancel && !cancelSuccess],
    [ErrorDialogNames.SAVE_UPDATE_ERROR, hasUpdate && !updateSuccess],
  ]

  for (const [dialogName, condition] of conditions) {
    if (condition) {
      return dialogName
    }
  }

  return null
}

const dialogProps: Record<ErrorDialogNames, { text: string; title: string }> = {
  [ErrorDialogNames.SAVE_CANCEL_ERROR]: {
    title: 'Erro ao salvar o cancelamento',
    text:
      'Suas edições foram salvas com sucesso. No entanto, ocorreu um erro ao processar os cancelamentos no contrato. Por favor, tente efetuar o cancelamento novamente.',
  },
  [ErrorDialogNames.SAVE_CANCEL_ONLY_ERROR]: {
    title: 'Erro ao cancelar parcelas',
    text:
      'Ocorreu um erro ao cancelar as parcelas do contrato. Por favor, tente novamente mais tarde.',
  },
  [ErrorDialogNames.SAVE_UPDATE_ERROR]: {
    title: 'Erro ao salvar edições',
    text:
      'Ocorreu um erro ao processar as edições no contrato. Por favor, tente novamente mais tarde.',
  },
}

export const ErrorDialog = ({
  isError,
  dialogName,
  onClose,
}: {
  dialogName: ErrorDialogNames | null
  isError: boolean
  onClose: () => void
}) => {
  const [showErrorDialog, setShowErrorDialog] = useState(false)

  useEffect(() => {
    if (isError) setShowErrorDialog(true)
  }, [isError])

  const close = () => {
    setShowErrorDialog(false)
    onClose()
  }

  if (dialogName === null) {
    return null
  }

  const handleDialogOpenChange = (open: boolean) => {
    if (!open && showErrorDialog) close()
  }

  return (
    <Dialog
      open={showErrorDialog}
      onOpenChange={handleDialogOpenChange}
      title={dialogProps[dialogName].title}
      backdrop
      size={2}
      cancelButton={<Button onClick={close}>Ok, entendi</Button>}
      content={<div>{dialogProps[dialogName].text}</div>}
    />
  )
}
