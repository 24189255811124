import type { ReactNode } from 'react'

import { ProviderComposer } from '@/shared/core/providers'
import { SchoolEnrollmentCycleProvider } from '@monorepo/enrollment/contexts/SchoolEnrollmentContext'
import { SelectedSchoolProvider } from '@/shared/contexts/SelectedSchoolContext'
import { SchoolLayoutProvider } from '@/modules/app/contexts/SchoolRoutesProviders/SchoolLayoutProvider'

type SchoolRoutesProvidersProps = {
  children: ReactNode
}

/**
 * Providers for routes after school slug
 */
export const SchoolRoutesProviders = ({ children }: SchoolRoutesProvidersProps) => {
  return (
    <ProviderComposer
      providers={[SelectedSchoolProvider, SchoolLayoutProvider, SchoolEnrollmentCycleProvider]}
    >
      {children}
    </ProviderComposer>
  )
}
