import { useSchoolEnrollmentCycles } from '@/shared/hooks/useSchoolEnrollmentCycles'
import { useParams } from 'react-router-dom'

const useRouterReferenceYear = (): string | undefined => {
  const { referenceYear } = useParams<{ referenceYear: string }>()

  const { enrollmentCycleYears } = useSchoolEnrollmentCycles()
  if (enrollmentCycleYears?.includes(Number(referenceYear))) {
    return referenceYear
  }
}

export default useRouterReferenceYear
