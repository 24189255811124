import { installment } from '@/modules/guardians/InstallmentsDrawerContainer/variations/Installment/installment'
import { loading } from '@/modules/guardians/InstallmentsDrawerContainer/variations/Loading/loading'
import { negotiation } from '@/modules/guardians/InstallmentsDrawerContainer/variations/Negotiation/negotiation'
import { renegotiation } from '@/modules/guardians/InstallmentsDrawerContainer/variations/Renegotiation/renegotiation'

export type DrawerVariationsProps = {
  content?: JSX.Element
  footer?: JSX.Element
  fullContent?: JSX.Element
  header?: JSX.Element
  isDialog?: boolean
}

export const drawerVariations = {
  installment,
  loading,
  negotiation,
  renegotiation,
}
