import { useApi } from '@/utils/hooks/useApi'
import { InvoiceProviders } from 'src/shared/interfaces'
import { Tooltip } from '@gravity/tooltip'
import { IconButton } from '@gravity/icon-button'
import { FileList3 } from '@gravity/icons'

export type PrintReceiptButtonProps = {
  label?: string
  onSuccess?: () => void
  provider?: InvoiceProviders | string
  receivableId: uuid
}

const PrintReceiptButton = ({
  label = 'Gerar comprovante de pagamento',
  receivableId,
  onSuccess,
}: PrintReceiptButtonProps) => {
  const { api } = useApi()

  const handleClick = async () => {
    try {
      const response = await api.receivables.printReceipt(receivableId)
      const file = new Blob([response.data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      const newWindow = window.open(fileURL, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    } catch {
    } finally {
      if (onSuccess) onSuccess()
    }
  }

  return (
    <>
      <Tooltip text={label} position="left">
        <IconButton data-testid="print_receipt_button" variant="ghost" onClick={handleClick}>
          <FileList3 size="xs" />
        </IconButton>
      </Tooltip>
    </>
  )
}

export default PrintReceiptButton
