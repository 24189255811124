import qs from 'qs'
import { Button } from '@gravity/button'
import { Dialog } from '@gravity/dialog'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { Text } from '@gravity/text'
import { Separator } from '@gravity/separator'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { formatMicroCentsToReal, prettifyFullName } from '@/shared/utils'
import { TRANSACTIONS_IN_ORDER } from '@monorepo/report/constants/transactionsInOrder'
import { REPORT_PAGE_NAMES } from '@monorepo/report/constants/REPORT_PAGE_NAMES'

import { FinancialDetails, TransactionEventType } from '@monorepo/report/models/report'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

import { Heading } from '@gravity/heading'
import { GrayText, HeaderContainer } from './styles'
import { ChangesChip } from '@monorepo/report/components/ChangesChip'

type EnrollmentReportTableRowDetailDrawerProps = {
  contractId: uuid
  contractReferenceYear?: string
  financialDetails?: FinancialDetails
  guardianId?: string
  isOpen: boolean
  onClose: () => void
  productId?: string
  productName: string
  receiptDate: Date
  studentId?: string
  studentName: string
}

export const EnrollmentReportTableRowDetailDrawer = ({
  guardianId,
  contractReferenceYear,
  studentId,
  studentName,
  productId,
  productName,
  financialDetails,
  receiptDate,
  isOpen,
  onClose,
}: EnrollmentReportTableRowDetailDrawerProps) => {
  const { school } = useSelectedSchool()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const handleNavigateToContract = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_REPORT,
        name: EventDispatcherEvents.ENROLLMENT_PAYOUT_VIEW_CONTRACT,
        action: 'click',
        customProperties: {
          $page_name: REPORT_PAGE_NAMES.ENROLLMENT_PAYOUT_REPORT,
        },
      })

    const route = `/${school?.slug}/responsaveis/${guardianId}/faturas`

    const filtersQueryParams = qs.stringify({
      student_ids: studentId,
      product_ids: productId,
      reference_years: contractReferenceYear,
    })

    window.open(`${route}?${filtersQueryParams}`, '_blank')
  }

  const formatDate = (date: Date) => {
    const formatter = new Intl.DateTimeFormat('pt-BR')

    return formatter.format(date)
  }
  const convertEventsKeysToArray = financialDetails?.events
    ? (Object.keys(financialDetails?.events) as Array<TransactionEventType>)
    : []

  const values = {
    ...financialDetails?.events,
    total_base_amount: financialDetails?.total_base_amount,
    total_fee: financialDetails?.total_fee && -1 * financialDetails?.total_fee,
  }

  const studentNameFormatted = prettifyFullName(studentName)

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      onClose()
    }
  }

  const dialogContent = (
    <div className="flex-1 flex flex-col gap-4">
      <HeaderContainer>
        <Heading variant="heading-h4-medium">{studentNameFormatted}</Heading>
        <GrayText variant="body-1-regular">{productName}</GrayText>
        <div className="flex flex-row items-center flex-wrap gap-2">
          <ChangesChip tags={[...convertEventsKeysToArray]} limit={15} />
        </div>
      </HeaderContainer>

      <Separator orientation="horizontal" color="neutral-2" />
      <div className="flex flex-row justify-between">
        <Text variant="body-1-medium" className="label">
          Data de recebimento
        </Text>

        <Text variant="body-1-medium">{formatDate(receiptDate)}</Text>
      </div>

      <Separator orientation="horizontal" color="neutral-2" />

      {Object.keys(TRANSACTIONS_IN_ORDER).map(key => {
        const valueKey = key as keyof typeof values
        // define that const because have a problem with type checking
        const k = values[valueKey]
        if (k) {
          return (
            <div key={valueKey} className="flex flex-row justify-between">
              <Text variant="body-1-regular" className="label">
                {TRANSACTIONS_IN_ORDER[valueKey as keyof typeof TRANSACTIONS_IN_ORDER].label}
              </Text>
              {k && <Text variant="body-1-regular">{formatMicroCentsToReal(k)}</Text>}
            </div>
          )
        }

        return null
      })}
      <Separator orientation="horizontal" color="neutral-2" />

      <div className="flex flex-row justify-between">
        <Text variant="body-1-medium" className="label">
          Valor do repasse
        </Text>

        {financialDetails?.total && (
          <Text variant="body-1-medium">{formatMicroCentsToReal(financialDetails?.total)}</Text>
        )}
      </div>
    </div>
  )

  return (
    <Dialog
      open={isOpen}
      onOpenChange={handleOpenChange}
      title=""
      size={2}
      backdrop
      content={dialogContent}
      actionButton={
        <Button fullWidth onClick={handleNavigateToContract}>
          Ir para contrato
        </Button>
      }
    />
  )
}
