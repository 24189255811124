import styled from 'styled-components'

import { Button } from '@gravity/button'
import { Select } from '@gravity/select'
import { Skeleton } from '@gravity/skeleton'
import { Card } from '@gravity/card'
import { Text } from '@gravity/text'

export const CheckBoxContainer = styled.div`
  display: flex;
  height: ${props => props.theme.gravity.spacing[5]};
  align-items: center;
  margin-top: ${props => props.theme.gravity.spacing[5]};
  gap: ${props => props.theme.gravity.spacing[2]};
`

export const GhostButton = styled(Button)`
  min-width: 186px;
`

export const StyledOverlay = styled.div`
  opacity: 0.87;
  position: absolute;
  background: ${props => props.theme.gravity.colors['colors-background-neutral-1']};
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ContainerCard = styled(Card)`
  margin-top: ${props => props.theme.gravity.spacing[6]};
  padding: ${props => props.theme.gravity.spacing[6]};
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[4]};
  width: 100%;
  border-radius: 8px;
  background-color: ${props => props.theme.gravity.colors['colors-background-neutral-2']};
`

export const ResultAnticipationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[4]};
`

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-end;
`

export const ColumnContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const HeaderContainer = styled.div``

export const StyledSelect = styled(Select)<{ $fullWith?: boolean }>`
  width: ${props => (props.$fullWith ? '100%' : '300px')};
  margin-bottom: ${props => props.theme.gravity.spacing[3]};
`

export const StyledSkeleton = styled(Skeleton)`
  margin-bottom: ${props => props.theme.gravity.spacing[2]};
`

export const GrayText = styled(Text)`
  color: ${props => props.theme.gravity.colors['colors-text-main-3']};
`
