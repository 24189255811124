import { Button } from '@gravity/button'
import { Dialog } from '@gravity/dialog'
import { Text } from '@gravity/text'

type PendingDataDialogProps = {
  onCancel: () => void
  onConfirm: () => void
  open: boolean
  setOpen: (open: boolean) => void
}

export const PendingDataDialog = ({
  open,
  setOpen,
  onConfirm,
  onCancel,
}: PendingDataDialogProps) => {
  return (
    <Dialog
      open={open}
      onOpenChange={setOpen}
      title="Dados de contato pendentes"
      size={2}
      backdrop
      content={
        <Text variant="body-2-regular" className="text-colors-text-main-2">
          Há dados de contato dos responsáveis pendentes. Você pode prosseguir para a importação,
          mas a correção desses dados{' '}
          <Text variant="body-2-medium">
            é obrigatória e deverá ser feita pela Plataforma isaac após a conclusão.
          </Text>
        </Text>
      }
      actionButton={
        <Button variant="solid" size={2} onClick={onConfirm}>
          Prosseguir
        </Button>
      }
      cancelButton={
        <Button variant="outline" size={2} onClick={onCancel}>
          Cancelar
        </Button>
      }
    />
  )
}
