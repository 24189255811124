import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { Button } from '@gravity/button'
import { Text } from '@gravity/text'
import { Skeleton } from '@gravity/skeleton'
import { DashedArrowLeft } from '@gravity/icons'
import { useSidemenu } from '@gravity/sidemenu'

import { StudentStatusBadge } from '../StudentStatusBadge'

import type { ReenrollmentStatus } from '@/shared/interfaces'

import { Container, SubContainer } from './styles'

export type HeaderProps = {
  isLoading: boolean
  isNewSidemenuEnabled?: boolean
  studentName: string
  studentStatus: ReenrollmentStatus
}

export const Header = ({
  studentName,
  studentStatus,
  isLoading,
  isNewSidemenuEnabled = false,
}: HeaderProps) => {
  const navigate = useNavigate()
  const { state } = useSidemenu()

  const [scrolled, setScrolled] = useState<boolean>(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      setScrolled(scrollPosition > 60)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Container
      scrolled={scrolled}
      newSidemenu={isNewSidemenuEnabled}
      sidemenuState={state}
      data-testid="student-page-header-container"
    >
      <div className="content">
        <Button
          variant="ghost"
          size={1}
          onClick={() => navigate(-1)}
          iconStart={<DashedArrowLeft />}
        >
          Voltar
        </Button>
        <SubContainer scrolled={scrolled}>
          {isLoading ? (
            <>
              <Skeleton width={200} radius={6} />
              <Text variant="body-2-regular">Aluno</Text>
              <Skeleton width={84} radius={6} />
            </>
          ) : (
            <>
              <Text variant="body-2-medium">{studentName}</Text>
              <Text variant="body-2-regular">Aluno</Text>
              <StudentStatusBadge status={studentStatus} />
            </>
          )}
        </SubContainer>
      </div>
    </Container>
  )
}
