import styled from 'styled-components'
import { Card } from '@gravity/card'
import { Text } from '@gravity/text'

export const CardStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[6]};
  padding: ${props => props.theme.gravity.spacing[10]};
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[4]};
`

export const CardTitleAndDate = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CardValue = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${props => props.theme.gravity.spacing[2]};
`

export const CardValueLabel = styled(Text)`
  font-size: ${props => props.theme.gravity.fontSize.sm};
  line-height: ${props => props.theme.gravity.lineHeight.normal};
  color: ${props => props.theme.gravity.colors['colors-text-main-3']};
`

export const Amount = styled(Text)<{ isNegative?: boolean }>`
  font-size: ${props => props.theme.gravity.fontSize['2xl']};
  font-weight: ${props => props.theme.gravity.fontWeight.medium};
  line-height: ${props => props.theme.gravity.lineHeight.tight};
  color: ${({ isNegative, theme }) =>
    isNegative
      ? theme.gravity.colors['colors-background-semantic-colors-error-1']
      : theme.gravity.colors['colors-text-main-2']};
`

export const Actions = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
`
