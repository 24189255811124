import { IrregularityTable } from './IrregularityTable'
import { IrregularityTableTitle, TextWithDescription } from './IrregularityTableTitle'

import {
  InstallmentIrregularityType,
  InstallmentType,
  IrregularityParams,
  installmentIrregularityParamsDict,
  installmentTypeParamsDict,
} from '../types'
import {
  InstallmentRequest,
  InstallmentIrregularityTableItem,
} from '@monorepo/enrollment/services/types'

import {
  IrregularityTableBaseboard,
  ReferenceLink,
  ReferenceMessage,
} from './IrregularityTableBaseboard'

import { Text } from '@gravity/text'
import { IrregularityHeaderType } from './IrregularityTable/types'
import { useSortOrder } from '../hooks/useSortOrder'
import { Check, AlertOutline, QuestionOutline, MoneyDollarCircleOutline } from '@gravity/icons'
import styled from 'styled-components'
import { IconButton } from '@gravity/icon-button'
import { Tooltip } from '@gravity/tooltip'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { TutorialVideoDialog } from './VideoDialog'
import { useState } from 'react'
import { useIrregularStudentsEvents } from '../hooks/useIrregularStudentsEvents'

interface InstallmentIrregularityTableProps {
  externalId: string
  installmentRequests: InstallmentRequest[]
  referenceLink: string
}

interface InstallmentIrregularity {
  irregularity_type: string
  resolved_at: string
  type: string
}

const CenteredDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
  width: 100%;
`

const RightAlignedDiv = styled.div`
  display: flex;
  margin-left: auto;
`

const neededActionColumn = {
  key: 'needed_action_column',
  label: 'Ação necessária',
  sortable: false,
}
let shouldShowConcludedMessage = false

let showTutorialVideoActivesoft = false

const COLUMN_HEADERS: Array<IrregularityHeaderType> = [
  { key: 'pending_column', label: 'Pendência', sortable: true },
  { key: 'installments_column', label: 'Parcelas', sortable: false },
  neededActionColumn,
  { key: 'resolved_column', label: 'Status da ação', sortable: false },
]

export const ResolvedColumn = ({
  description,
  isResolved,
  title,
  videoUrl,
}: {
  description: string
  isResolved: boolean
  title: string
  videoUrl: string
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const { sendVideoModalViewedEvent } = useIrregularStudentsEvents()

  if (isResolved) {
    return (
      <CenteredDiv>
        <Check className="text-colors-text-main-3" />
        <Text variant="body-1-regular">Concluída</Text>
      </CenteredDiv>
    )
  }

  return (
    <>
      <TutorialVideoDialog
        title={title}
        description={description}
        src={videoUrl}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />

      <CenteredDiv>
        <AlertOutline className="text-colors-text-main-3" />
        <Text variant="body-1-regular">Pendente</Text>

        {showTutorialVideoActivesoft && Boolean(videoUrl) && (
          <RightAlignedDiv>
            <Tooltip text="Como corrigir no seu sistema" position="left">
              <IconButton
                variant="ghost"
                onClick={() => {
                  setIsOpen(true)
                  sendVideoModalViewedEvent()
                }}
              >
                <QuestionOutline />
              </IconButton>
            </Tooltip>
          </RightAlignedDiv>
        )}
      </CenteredDiv>
    </>
  )
}

const Row = ({
  installment_request_type,
  irregularities,
  irregularity_type,
}: InstallmentIrregularityTableItem) => {
  const irregularityTranslation =
    installmentIrregularityParamsDict[irregularity_type as InstallmentIrregularityType]
  const installmentTypeTranslation =
    installmentTypeParamsDict[installment_request_type as InstallmentType]
  const rowKey = `${installment_request_type}-${irregularity_type}`

  const occurrences = irregularities.filter(
    irregularity => irregularity.resolved_at === null || irregularity.resolved_at === ''
  ).length

  let message = `${occurrences} parcelas irregulares`
  if (occurrences === 1) {
    message = `${occurrences} parcela irregular`
  }

  const textWithDescriptionKey = `${rowKey}-${occurrences}`

  return {
    key: rowKey,
    resolved: occurrences === 0,
    columns: [
      {
        text: irregularityTranslation?.name,
      },
      {
        component: (
          <TextWithDescription
            key={textWithDescriptionKey}
            description={message}
            text={installmentTypeTranslation || ''}
          />
        ),
      },
      {
        text: irregularityTranslation?.action,
        tooltip: true,
      },
      {
        component: (
          <ResolvedColumn
            isResolved={occurrences === 0}
            title={irregularityTranslation?.title || ''}
            description={irregularityTranslation?.description || ''}
            videoUrl={irregularityTranslation?.src || ''}
          />
        ),
      },
    ],
  }
}

function makeRows(
  installmentRequests: InstallmentRequest[],
  sortRows: (
    rows: InstallmentIrregularityTableItem[],
    dict: Record<string, IrregularityParams>
  ) => void
) {
  const installmentRequestWithIrregularity: InstallmentIrregularity[] = []

  installmentRequests.forEach(installmentRequest => {
    const irregularities = installmentRequest.installment_irregularities || []
    irregularities.forEach(irregularity => {
      installmentRequestWithIrregularity.push({
        type: installmentRequest.type,
        irregularity_type: irregularity.irregularity_type,
        resolved_at: irregularity.resolved_at,
      })
    })
  })

  let installmentIrregularityTableItemsMap: { [key: string]: InstallmentIrregularityTableItem } = {}
  installmentIrregularityTableItemsMap = installmentRequestWithIrregularity.reduce(
    (acc: { [key: string]: InstallmentIrregularityTableItem }, item) => {
      const key = `${item.type}-${item.irregularity_type}`
      if (!acc[key]) {
        acc[key] = {
          installment_request_type: item.type,
          irregularity_type: item.irregularity_type,
          irregularities: [],
        }
      }
      acc[key].irregularities.push({
        irregularity_type: item.irregularity_type,
        resolved_at: item.resolved_at,
      })
      return acc
    },
    {}
  )

  const tableItems = Object.values(installmentIrregularityTableItemsMap)

  sortRows(tableItems, installmentIrregularityParamsDict)

  const rows = Object.values(tableItems).map(item => Row(item))

  const allIrregularitiesAreResolved = rows.reduce((isResolved, row) => {
    isResolved &&= row.resolved
    return isResolved
  }, true)

  shouldShowConcludedMessage = rows.length > 0 && allIrregularitiesAreResolved
  if (shouldShowConcludedMessage) {
    neededActionColumn.label = 'Ação feita'
  }

  return rows
}

const getActionMessage = (referenceLink: string, externalId: string): JSX.Element => {
  return referenceLink ? (
    <ReferenceLink url={referenceLink} />
  ) : (
    <ReferenceMessage
      identifier="ID do aluno"
      externalId={externalId}
      copyableTooltip="ID do aluno copiado"
    />
  )
}

export const InstallmentIrregularityTable = ({
  externalId,
  installmentRequests,
  referenceLink,
}: InstallmentIrregularityTableProps) => {
  const { order, updateOrder, sortRows } = useSortOrder()
  showTutorialVideoActivesoft = useUnleashFlag(UnleashFlags.INT_598_SHOW_TUTORIAL_VIDEO_ACTIVESOFT)

  return (
    <div>
      <IrregularityTableTitle title="Plano de pagamento" icon={<MoneyDollarCircleOutline />} />
      <IrregularityTable
        rows={makeRows(installmentRequests, sortRows)}
        columnHeaders={COLUMN_HEADERS}
        ordering={order}
        onChangeOrdering={updateOrder}
      />
      <IrregularityTableBaseboard
        callToActionMsg={
          shouldShowConcludedMessage
            ? 'Dados corrigidos no seu sistema'
            : 'Corrija os dados no seu sistema'
        }
        actionElement={
          shouldShowConcludedMessage ? <></> : getActionMessage(referenceLink, externalId)
        }
        referenceLink={referenceLink}
      />
    </div>
  )
}
