import { setToClipboard } from '@/shared/utils'
import { Button } from '@gravity/button'
import { FileCopyOutline } from '@gravity/icons'
import { useToast } from '@gravity/toast'
import styled from 'styled-components'

type CopyToClipboardButtonProps = {
  copyableText: string
  onClick?: () => void
  tooltipText: string
}

const StyledButton = styled(Button)`
  margin-left: ${props => props.theme.gravity.spacing[2]};
`

export const CopyToClipboardButton = ({
  copyableText,
  tooltipText,
  onClick,
}: CopyToClipboardButtonProps) => {
  const { toast } = useToast()

  return (
    <StyledButton
      variant="ghost"
      size={1}
      iconStart={<FileCopyOutline />}
      onClick={e => {
        e.stopPropagation()
        setToClipboard(copyableText)
        toast({
          title: tooltipText,
          type: 'success',
          duration: 2000,
        })
        if (onClick) onClick()
      }}
    >
      Copiar ID
    </StyledButton>
  )
}
