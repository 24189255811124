import { PAYOUT_REPORT_STATUS } from '@monorepo/report/constants/PAYOUT_REPORT_STATUS'

import type { UnguaranteedReport } from '@monorepo/report/models/UnguaranteedReport'
import type { EnrollmentPayoutReportData } from '../../../../components/EnrollmentReportDashboardCard'

/**
 * Utility function to parse payout to a compatible format with Enrollment dashboard card component
 *
 * @param payout Enrollment payout report to be parsed
 *
 * @returns Parsed enrollment payout report
 */
export const enrollmentPayoutReportDataMapper = (
  payout: UnguaranteedReport
): EnrollmentPayoutReportData => {
  const calculatedPayoutValue = (() => {
    const payoutValue = payout.agregations.financial_details.total
    const negativePayoutValue = payout.agregations.financial_details.events?.BALANCE_TRANSFER ?? 0

    return payoutValue - negativePayoutValue
  })()

  return {
    id: payout.payout_id,
    isOpen: payout.payout.status === PAYOUT_REPORT_STATUS.OPEN,
    payoutValue: calculatedPayoutValue,
    started_at: payout.started_at,
    ended_at: payout.ended_at,
  }
}
