import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventIdentifierName } from '@/shared/models/enums/EventIdentifierName.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { IrregularStudentStatus, irregularStatusTranslationDict } from '../types'
import { IrregularStudentItem } from '@monorepo/enrollment/services/types'
import { IRREGULARITY_EVENT_PAGE_NAME } from '../constants'

export const useIrregularStudentsEvents = () => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const sendPageViewEvent = () => {
    if (!isInitialized) return

    // DEPRECATED
    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.IRREGULARITY_ENROLLMENTS_PAGE,
      name: EventDispatcherEvents.PAGE_VIEWED,
      action: 'page_view',
      entity: 'pendencias',
    })

    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.INTEGRATIONS,
      pageName: IRREGULARITY_EVENT_PAGE_NAME,
      name: EventDispatcherEvents.PAGE_VIEWED,
      identifierName: EventIdentifierName.IRREGULARITIES_PAGE,
    })
  }

  const sendClickAccordionEvent = (irregularStudentItem: IrregularStudentItem) => {
    if (!isInitialized) return

    // DEPRECATED
    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.IRREGULARITY_ENROLLMENTS_PAGE,
      name: EventDispatcherEvents.DROPDOWN_CLICKED,
      action: 'click',
      entity: 'pendencias',
      customProperties: {
        $dropdown_name: 'Pendências de um aluno',
        $student_enrollment_request_aggregation_id: irregularStudentItem.id,
      },
    })

    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.INTEGRATIONS,
      pageName: IRREGULARITY_EVENT_PAGE_NAME,
      name: EventDispatcherEvents.DROPDOWN_CLICKED,
      identifierName: EventIdentifierName.DROPDOWN_STUDENT_IRREGULARITIES,
      customProperties: {
        $dropdown_name: 'Pendências de um aluno',
        $student_enrollment_request_aggregation_id: irregularStudentItem.id,
      },
    })
  }

  const sendClickSelectReferenceYearEvent = () => {
    if (!isInitialized) return

    // DEPRECATED
    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.IRREGULARITY_ENROLLMENTS_PAGE,
      name: EventDispatcherEvents.BUTTON_CLICKED,
      action: 'click',
      entity: 'pendencias',
      customProperties: {
        $button_name: 'Período letivo',
      },
    })

    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.INTEGRATIONS,
      pageName: IRREGULARITY_EVENT_PAGE_NAME,
      name: EventDispatcherEvents.BUTTON_CLICKED,
      identifierName: EventIdentifierName.DROPDOWN_REFERENCE_YEAR,
      customProperties: {
        $button_name: 'Período letivo',
      },
    })
  }

  const sendClickSearchBarEvent = () => {
    if (!isInitialized) return

    // DEPRECATED
    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.IRREGULARITY_ENROLLMENTS_PAGE,
      name: EventDispatcherEvents.BUTTON_CLICKED,
      action: 'click',
      entity: 'pendencias',
      customProperties: {
        $button_name: 'Busca de alunos',
      },
    })

    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.INTEGRATIONS,
      pageName: IRREGULARITY_EVENT_PAGE_NAME,
      name: EventDispatcherEvents.BUTTON_CLICKED,
      identifierName: EventIdentifierName.SEARCH_STUDENTS_IRREGULARITIES,
      customProperties: {
        $button_name: 'Busca de alunos',
      },
    })
  }

  const sendClickStatusFilterBadgeEvent = (status: IrregularStudentStatus) => {
    if (!isInitialized) return

    // DEPRECATED
    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.IRREGULARITY_ENROLLMENTS_PAGE,
      name: EventDispatcherEvents.CHIP_CLICKED,
      action: 'click',
      entity: 'pendencias',
      customProperties: {
        $chip_clicked: irregularStatusTranslationDict[status],
      },
    })

    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.INTEGRATIONS,
      pageName: IRREGULARITY_EVENT_PAGE_NAME,
      name: EventDispatcherEvents.CHIP_CLICKED,
      identifierName: EventIdentifierName.FILTER_CHIP_CLICKED_IRREGULARITIES,
      customProperties: {
        $chip_clicked: irregularStatusTranslationDict[status],
      },
    })
  }

  const sendClickSelectProductEvent = () => {
    if (!isInitialized) return

    // DEPRECATED
    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.IRREGULARITY_ENROLLMENTS_PAGE,
      name: EventDispatcherEvents.SELECT_TRIGGER_CLICKED,
      action: 'click',
      entity: 'pendencias',
      customProperties: {
        $select_trigger_name: 'Produto',
      },
    })

    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.INTEGRATIONS,
      pageName: IRREGULARITY_EVENT_PAGE_NAME,
      name: EventDispatcherEvents.SELECT_TRIGGER_CLICKED,
      identifierName: EventIdentifierName.SELECT_PRODUCT_IRREGULARITIES,
      customProperties: {
        $select_trigger_name: 'Produto',
      },
    })
  }

  const sendClickCopyStudentNameEvent = () => {
    if (!isInitialized) return

    // DEPRECATED
    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.IRREGULARITY_ENROLLMENTS_PAGE,
      name: EventDispatcherEvents.BUTTON_CLICKED,
      action: 'click',
      entity: 'pendencias',
      customProperties: {
        $button_name: 'Copiar nome de aluno',
      },
    })

    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.INTEGRATIONS,
      pageName: IRREGULARITY_EVENT_PAGE_NAME,
      name: EventDispatcherEvents.BUTTON_CLICKED,
      identifierName: EventIdentifierName.COPY_STUDENT_NAME,
      customProperties: {
        $button_name: 'Copiar nome de aluno',
      },
    })
  }

  const sendClickCopyExternalIDEvent = () => {
    if (!isInitialized) return

    // DEPRECATED
    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.IRREGULARITY_ENROLLMENTS_PAGE,
      name: EventDispatcherEvents.BUTTON_CLICKED,
      action: 'click',
      entity: 'pendencias',
      customProperties: {
        $button_name: 'Copiar external ID',
      },
    })

    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.INTEGRATIONS,
      pageName: IRREGULARITY_EVENT_PAGE_NAME,
      name: EventDispatcherEvents.BUTTON_CLICKED,
      identifierName: EventIdentifierName.COPY_EXTERNAL_ID,
      customProperties: {
        $button_name: 'Copiar ID',
      },
    })
  }

  const sendClickRedirectLinkEvent = () => {
    if (!isInitialized) return

    // DEPRECATED
    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.IRREGULARITY_ENROLLMENTS_PAGE,
      name: EventDispatcherEvents.BUTTON_CLICKED,
      action: 'click',
      entity: 'pendencias',
      customProperties: {
        $button_name: 'Clicar em link de redirecionamento',
      },
    })

    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.INTEGRATIONS,
      pageName: IRREGULARITY_EVENT_PAGE_NAME,
      name: EventDispatcherEvents.BUTTON_CLICKED,
      identifierName: EventIdentifierName.REDIRECT_ACTIVESOFT_PENDING_ISSUES,
      customProperties: {
        $button_name: 'Ver pendências no ActiveSoft',
      },
    })
  }

  const sendClickMarkAsFinishedEvent = () => {
    if (!isInitialized) return

    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.INTEGRATIONS,
      pageName: IRREGULARITY_EVENT_PAGE_NAME,
      name: EventDispatcherEvents.LINK_CLICKED,
      identifierName: EventIdentifierName.MARK_AS_FINISHED_IRREGULARITY,
      customProperties: {
        $link_name: 'Marcar como resolvidas',
      },
    })
  }

  const sendVideoModalViewedEvent = () => {
    if (!isInitialized) return

    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.INTEGRATIONS,
      pageName: IRREGULARITY_EVENT_PAGE_NAME,
      name: EventDispatcherEvents.MODAL_VIEWED,
      identifierName: EventIdentifierName.MODAL_TUTORIAL_ACTIVESOFT,
      customProperties: {
        $modal_name: 'Tutorial ActiveSoft',
      },
    })
  }

  const sendMarkAsFinishedConfirmation = () => {
    if (!isInitialized) return

    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.INTEGRATIONS,
      pageName: IRREGULARITY_EVENT_PAGE_NAME,
      name: EventDispatcherEvents.BUTTON_CLICKED,
      identifierName: EventIdentifierName.MARK_AS_FINISHED_IRREGULARITY_CONFIRMATION,
      customProperties: {
        $button_name: 'Confirmar remoção',
      },
    })
  }

  return {
    sendPageViewEvent,
    sendClickAccordionEvent,
    sendClickSelectReferenceYearEvent,
    sendClickSearchBarEvent,
    sendClickStatusFilterBadgeEvent,
    sendClickSelectProductEvent,
    sendClickCopyStudentNameEvent,
    sendClickCopyExternalIDEvent,
    sendClickRedirectLinkEvent,
    sendClickMarkAsFinishedEvent,
    sendVideoModalViewedEvent,
    sendMarkAsFinishedConfirmation,
  }
}
