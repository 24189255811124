import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { usePaymentCreditCard, useSelectPos } from 'src/escolas/hooks'
import InputDueDate from '@/modules/guardians/Negotiation/components/Inputs/InputDueDate'
import InputInstallments from '@/modules/guardians/Negotiation/components/Inputs/InputInstallments'
import InputListPos from '@/modules/guardians/Negotiation/components/Inputs/InputListPos'
import InputCreditCardPaymentOptions from '@/modules/guardians/Negotiation/components/Inputs/InputCreditCardPaymentOptions'
import { CREDIT_CARD_PAYMENT_OPTIONS } from '@/modules/guardians/Negotiation/components/Inputs/types'
import { Pos } from 'src/shared/interfaces'
import { getEndOfToday, getStartOfToday } from 'src/shared/utils'
import { FormPropsWithAgreement } from './types'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { Container } from './styles'

const CreditCardForm = ({
  handleValidate,
  agreement,
  handleValue,
  paymentPlan,
  paymentOption,
  disablePaymentLink,
}: FormPropsWithAgreement) => {
  const [installments, setInstallments] = useState<number>(1)
  const [
    creditCardPaymentOption,
    setCreditCardPaymentOption,
  ] = useState<CREDIT_CARD_PAYMENT_OPTIONS>(CREDIT_CARD_PAYMENT_OPTIONS.POS)
  const [pos, setPos] = useState<Pos>()

  const { isCreditCardFlow } = usePaymentCreditCard()
  const isDatePickerDisabled = useUnleashFlag(
    UnleashFlags.REG_497_DISABLE_DATE_PICKER_ON_CREDIT_CARD_FLOW
  )

  let minDate = getStartOfToday().add(1, 'day').toDate()
  let maxDate = getStartOfToday().add(7, 'day').toDate()

  if (isDatePickerDisabled && isCreditCardFlow) {
    minDate = getStartOfToday().toDate()
    maxDate = getStartOfToday().toDate()
  }

  const [dueDate, setDueDate] = useState<Date>(minDate)

  const { listPos } = useSelectPos()

  useEffect(() => {
    if (listPos.length === 0) {
      setCreditCardPaymentOption(CREDIT_CARD_PAYMENT_OPTIONS.PAYMENT_LINK)
    }

    if (creditCardPaymentOption === CREDIT_CARD_PAYMENT_OPTIONS.POS && listPos.length === 1) {
      setPos(listPos[0])
    }
  }, [listPos, creditCardPaymentOption])

  useEffect(() => {
    if (creditCardPaymentOption === CREDIT_CARD_PAYMENT_OPTIONS.PAYMENT_LINK) {
      setPos(undefined)
    }

    if (creditCardPaymentOption === CREDIT_CARD_PAYMENT_OPTIONS.POS) {
      setDueDate(minDate)
    }
  }, [creditCardPaymentOption])

  useEffect(() => {
    let isValid = true
    if (!creditCardPaymentOption) {
      isValid = false
    }

    const maxInstallment = paymentPlan?.max_installments_allowed ?? 1
    if (installments > maxInstallment || installments < 0) {
      isValid = false
    }

    if (
      creditCardPaymentOption === CREDIT_CARD_PAYMENT_OPTIONS.PAYMENT_LINK &&
      (!dayjs(dueDate).isValid() ||
        dayjs(dueDate).endOf('day').isBefore(getEndOfToday()) ||
        dayjs(dueDate).isAfter(getEndOfToday().add(7, 'day').toDate()))
    ) {
      isValid = false
    }

    if (creditCardPaymentOption === CREDIT_CARD_PAYMENT_OPTIONS.POS && !pos) {
      isValid = false
    }

    if (isValid) {
      handleValue({
        dueDate,
        installments,
        creditCardPaymentOption,
        pos,
        minDownPayment: 0,
        paymentOption,
      })
    }
    handleValidate(isValid)
  }, [installments, dueDate, creditCardPaymentOption, pos])

  const showOptions = listPos.length !== 0 && !disablePaymentLink

  const showDueDate = creditCardPaymentOption === CREDIT_CARD_PAYMENT_OPTIONS.PAYMENT_LINK

  const showListPos =
    creditCardPaymentOption === CREDIT_CARD_PAYMENT_OPTIONS.POS && listPos.length > 1

  const handlePos = (id: string) => setPos(listPos.find(pos => id === pos.pos_id))

  return (
    <Container>
      <InputInstallments
        maxInstallment={paymentPlan?.max_installments_allowed ?? 0}
        totalAmount={agreement?.total_amount ?? 0}
        handleValue={(value: number) => setInstallments(value)}
        selectedValue={installments}
      />
      {showOptions && (
        <InputCreditCardPaymentOptions
          handleValue={setCreditCardPaymentOption}
          selectedValue={creditCardPaymentOption}
        />
      )}
      {showDueDate && (
        <InputDueDate
          label="Data de vencimento da parcela"
          maxDate={maxDate}
          minDate={minDate}
          selectedValue={dueDate}
          handleValue={(date: Dayjs) => setDueDate(date?.toDate() || null)}
        />
      )}
      {showListPos && (
        <InputListPos handleValue={handlePos} selectedValue={pos?.pos_id ?? ''} list={listPos} />
      )}
    </Container>
  )
}

export default CreditCardForm
