import { FC } from 'react'
import { Button } from '@gravity/button'
import { Dialog } from '@gravity/dialog'
import { Text } from '@gravity/text'
import * as Styled from './styles'
import { SelectedInstallmentsText } from '../SelectedInstallmentsText'
import { Installment } from '@/modules/contract/services/types'
import { InstallmentStatuses, InstallmentType } from '@/shared/interfaces/installment'
import { formatCentsToReal } from '@/shared/utils/numberFormatters'
import { calculateTotalDiscount, canCancelInstallment } from '../../utils'

type CancellingDialogProps = {
  installments: Installment[]
  onClose: () => void
  onConfirm: (selectedInstallmentIds: string[]) => void
  selectedInstallments: Installment[]
}

enum statusesOrTypeCases {
  // If it is needed to add more, please add if it has one or more type and status i.e.: OPEN_PAID_OVERDUE, ISAACPAY_OPEN_OVERDUE
  ENROLLMENT_ONLY_PAID = 'ENROLLMENT_ONLY_PAID',
  ENROLLMENT_OVERDUE_PAID = 'ENROLLMENT_OVERDUE_PAID',
  ONLY_OPEN = 'ONLY_OPEN',
  ONLY_OVERDUE = 'ONLY_OVERDUE',
  ONLY_PAID = 'ONLY_PAID',
  OVERDUE_PAID = 'OVERDUE_PAID',
  TYPE_ISAACPAY = 'TYPE_ISAACPAY',
}

const checkSelectedStatusesOrType = (
  selectedInstallments: Installment[]
): statusesOrTypeCases | undefined => {
  if (
    selectedInstallments.every(
      (installment: Installment) => installment.receivable_type === InstallmentType.TUITION
    )
  ) {
    if (
      selectedInstallments.every(
        (installment: Installment) =>
          installment.status === InstallmentStatuses.PAID &&
          installment.receivable_type === InstallmentType.TUITION
      )
    )
      return statusesOrTypeCases.ONLY_PAID
    if (
      selectedInstallments.every(
        installment =>
          installment.status === InstallmentStatuses.OPEN &&
          installment.receivable_type === InstallmentType.TUITION
      )
    )
      return statusesOrTypeCases.ONLY_OPEN
    if (
      selectedInstallments.every(
        installment =>
          installment.status === InstallmentStatuses.OVERDUE &&
          installment.receivable_type === InstallmentType.TUITION
      )
    )
      return statusesOrTypeCases.ONLY_OVERDUE
  }

  if (
    selectedInstallments.some(
      (installment: Installment) =>
        installment.status === InstallmentStatuses.OVERDUE &&
        installment.receivable_type === InstallmentType.TUITION
    ) &&
    selectedInstallments.some(
      (installment: Installment) =>
        installment.status === InstallmentStatuses.OPEN &&
        installment.receivable_type === InstallmentType.TUITION
    )
  )
    return statusesOrTypeCases.OVERDUE_PAID

  if (
    selectedInstallments.some(
      installment => installment.receivable_type === InstallmentType.ISAACPAY
    )
  ) {
    return statusesOrTypeCases.TYPE_ISAACPAY
  }

  if (
    selectedInstallments.every(
      installment => installment.receivable_type === InstallmentType.ENROLLMENT
    )
  ) {
    if (
      selectedInstallments.some(
        installment => installment.status === InstallmentStatuses.OVERDUE
      ) &&
      selectedInstallments.some(installment => installment.status === InstallmentStatuses.PAID)
    )
      return statusesOrTypeCases.ENROLLMENT_OVERDUE_PAID
    if (selectedInstallments.some(installment => installment.status === InstallmentStatuses.PAID))
      return statusesOrTypeCases.ENROLLMENT_ONLY_PAID
  }
}

type MessageToDisplayProps = {
  selectedInstallments: Installment[]
}

const MessageToDisplay = ({ selectedInstallments }: MessageToDisplayProps) => {
  const isMultipleInstallments = selectedInstallments.length > 1

  const sumFineAndInterest = selectedInstallments.reduce((acc, installment) => {
    if (installment.status === InstallmentStatuses.OVERDUE) {
      acc += Number(installment.current_fine) + Number(installment.current_interest)
    }
    return acc
  }, 0)

  const sumSelectedInstallments = selectedInstallments.reduce((acc, installment) => {
    const discountsAmount = calculateTotalDiscount(installment.discounts)

    if (installment.status === InstallmentStatuses.OVERDUE) {
      acc += Number(installment.amount) - discountsAmount
    }
    return acc
  }, 0)

  const totalAmountToBeDiscounted = sumSelectedInstallments + sumFineAndInterest

  const openInstallmentMessage = (
    <Styled.List>
      <ul>
        <li>
          <Text variant="body-2-regular">
            O valor {isMultipleInstallments ? `das parcelas` : `da parcela`} será zerado;
          </Text>
        </li>
        <li>
          <Text variant="body-2-regular">
            {isMultipleInstallments
              ? `Os responsáveis financeiros não receberão`
              : `O responsável financeiro não receberá`}{' '}
            nenhuma cobrança relacionada à parcela.
          </Text>
        </li>
      </ul>
    </Styled.List>
  )

  const overdueInstallmentMessage = (
    <>
      <li>
        <Text variant="body-2-regular">
          Valor das mensalidades: {formatCentsToReal(sumSelectedInstallments)}
        </Text>
      </li>
      <li>
        <Text variant="body-2-regular">
          Valores de multa e juros em atraso: {formatCentsToReal(sumFineAndInterest)}
        </Text>
      </li>
    </>
  )

  const selectedStatuses = checkSelectedStatusesOrType(selectedInstallments)

  switch (selectedStatuses) {
    case statusesOrTypeCases.TYPE_ISAACPAY:
      return openInstallmentMessage
    case statusesOrTypeCases.ONLY_OPEN:
      return openInstallmentMessage
    case statusesOrTypeCases.ONLY_OVERDUE:
      return (
        <>
          <Text variant="body-2-regular" className="mb-6">
            Ao cancelar essa parcela vencida, será descontado no próximo repasse:
          </Text>
          <Styled.List>
            <ul>
              {overdueInstallmentMessage}
              <Text variant="body-2-medium">
                Valor total a ser descontado: {formatCentsToReal(totalAmountToBeDiscounted)}
              </Text>
            </ul>
          </Styled.List>
        </>
      )
    case statusesOrTypeCases.OVERDUE_PAID:
      return (
        <>
          <Text variant="body-2-regular">
            Ao cancelar essas parcelas vencidas, será deduzido o{' '}
            <Text variant="body-2-medium">
              valor de {formatCentsToReal(totalAmountToBeDiscounted)}{' '}
            </Text>
            no próximo repasse. Este montante é composto por:
          </Text>
          <Styled.List>
            <ul>{overdueInstallmentMessage}</ul>
          </Styled.List>
          <Text variant="body-2-regular">Para as parcelas a vencer:</Text>
          {openInstallmentMessage}
        </>
      )
    case statusesOrTypeCases.ENROLLMENT_ONLY_PAID:
      return (
        <Text variant="body-2-regular" className="mb-6">
          <Text variant="body-2-regular" className="text-colors-text-semantic-colors-error-1">
            Atenção:
          </Text>{' '}
          esta parcela já foi paga e o estorno do pagamento para o responsável deverá ser feito pela
          escola.
        </Text>
      )
    case statusesOrTypeCases.ENROLLMENT_OVERDUE_PAID:
      return (
        <>
          <Text variant="body-2-regular" className="mb-5">
            Ao cancelar parcelas(s) paga(s): O estorno do pagamento para o responsável deverá ser
            feito pela escola.
          </Text>
          <Text variant="body-2-regular">Ao cancelar parcela(s) vencida(s):</Text>
          <Styled.List>
            <ul>
              <li>
                <Text variant="body-2-regular">O valor das parcelas será zerado;</Text>
              </li>
              <li>
                <Text variant="body-2-regular">
                  Os responsáveis financeiros não receberão nenhuma cobrança relacionada à parcela.
                </Text>
              </li>
            </ul>
          </Styled.List>
        </>
      )
    default:
      return openInstallmentMessage
  }
}

export const CancellingDialog: FC<CancellingDialogProps> = ({
  installments,
  onClose,
  selectedInstallments,
  onConfirm,
}) => {
  const isEveryInstallmentSelectedAndOverdue = () => {
    const cancellableInstallmentIDs = installments
      .filter(i => canCancelInstallment(i))
      .map(i => i.backoffice_installment_id)
    const selectedEveryCancellableInstallment = cancellableInstallmentIDs.every((i: uuid) =>
      selectedInstallments.map(instalmment => instalmment.backoffice_installment_id).includes(i)
    )

    return (
      selectedEveryCancellableInstallment &&
      selectedInstallments.every(instalmment => instalmment.status === InstallmentStatuses.OVERDUE)
    )
  }
  return (
    <Dialog
      open
      size={2}
      backdrop
      onOpenChange={onClose}
      title="Cancelar parcelas"
      description={<SelectedInstallmentsText count={selectedInstallments.length} />}
      content={
        <div className="flex flex-col">
          <MessageToDisplay selectedInstallments={selectedInstallments} />
          <Text variant="body-2-regular" className="mb-6">
            Você só pode reverter o cancelamento da parcela antes de salvar a edição do contrato.
            Depois disso, é uma ação irreversível.
          </Text>
          {isEveryInstallmentSelectedAndOverdue() && (
            <Text variant="body-1-medium">
              O cancelamento das parcelas não altera o status do contrato: ele permanecerá ativo.
            </Text>
          )}
        </div>
      }
      actionButton={
        <Button
          variant="solid"
          color="error"
          onClick={() =>
            onConfirm(
              selectedInstallments.map(instalmment => instalmment.backoffice_installment_id)
            )
          }
        >
          Continuar cancelamento
        </Button>
      }
      cancelButton={
        <Button variant="ghost" onClick={onClose}>
          Voltar
        </Button>
      }
    />
  )
}
