import { PAYOUT_SPREADSHEET_FILE_VERSION } from '@/shared/constants/report'
import { useApiClient } from '@/shared/hooks'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { ConstEnumValue } from '@/shared/models/utils'
import { downloadFile, formatDate } from '@/shared/utils'
import { useToast } from '@gravity/toast'
import { ReportPageTableHeader } from '@monorepo/report/components/ReportPageTableHeader'
import { REPORT_PAGE_NAMES } from '@monorepo/report/constants/REPORT_PAGE_NAMES'
import { PayoutReportStatus } from '@monorepo/report/models/PayoutReportStatus'
import { reportService } from '@monorepo/report/services/report'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

type Props = {
  fileVersion?: ConstEnumValue<typeof PAYOUT_SPREADSHEET_FILE_VERSION>
  isEmptyPayout: boolean
  isLoading: boolean
  isNewVersionPayout?: boolean
  payoutId: string
  payoutReportDate?: Date
  tuitionPayoutReportStatus?: PayoutReportStatus
}

export const TuitionPayoutReportTableHeader = ({
  fileVersion = PAYOUT_SPREADSHEET_FILE_VERSION.V2,
  isEmptyPayout,
  isLoading,
  isNewVersionPayout,
  payoutId,
  payoutReportDate = new Date(),
  tuitionPayoutReportStatus = 'OPEN',
}: Props) => {
  const { apiClient } = useApiClient()
  const { school } = useSelectedSchool()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { toast } = useToast()

  const formattedTuitionPayoutReportDate = formatDate(payoutReportDate, 'DD-MM-YYYY')

  const filenames = {
    excel: `analitico-${formattedTuitionPayoutReportDate}-${school?.slug}.xlsx`,
  }

  const getFileVersion = (isNewVersionPayout: boolean, docType: 'current' | 'previous') => {
    if (!isNewVersionPayout) return PAYOUT_SPREADSHEET_FILE_VERSION.V1

    if (docType === 'current') {
      return fileVersion
    }

    return fileVersion === PAYOUT_SPREADSHEET_FILE_VERSION.V3
      ? PAYOUT_SPREADSHEET_FILE_VERSION.V2
      : PAYOUT_SPREADSHEET_FILE_VERSION.V1
  }

  const handleDownloadReport = async (
    payoutId: string,
    filename: string,
    fileVersion: ConstEnumValue<typeof PAYOUT_SPREADSHEET_FILE_VERSION>
  ) => {
    const extension = 'xlsx'
    const service = reportService(apiClient.getClients().privateApi)

    try {
      await downloadFile(filename, extension, () =>
        service.fetchPayoutReportFile({
          id: payoutId,
          extension,
          version: fileVersion,
        })
      )
    } catch {
      toast({
        title:
          'Erro ao baixar o relatório. Tente novamente mais tarde ou entre em contato com nosso suporte.',
        type: 'error',
      })
    } finally {
      isInitialized &&
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.PAYOUT_REPORT,
          name: EventDispatcherEvents.TUITION_PAYOUT_DOWNLOAD_CSV,
          action: 'file_download',
          customProperties: {
            $file_version: fileVersion,
            $page_name: REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT,
          },
        })
    }
  }

  return (
    <ReportPageTableHeader
      isEmptyPayout={isEmptyPayout}
      isLoading={isLoading}
      isNewVersionPayout={isNewVersionPayout}
      onPreviousModelDownload={() =>
        handleDownloadReport(payoutId, filenames.excel, getFileVersion(true, 'previous'))
      }
      onSheetDownload={() =>
        handleDownloadReport(payoutId, filenames.excel, getFileVersion(true, 'current'))
      }
      tuitionPayoutReportStatus={tuitionPayoutReportStatus}
    />
  )
}
