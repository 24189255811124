import { GridItem } from '@gravity/grid'

import { PayoutCardContainer } from '../../styles'
import { TitleContainer, PayoutSkeleton, AmountContainer } from './styles'
import { ColumnReceipts, ReceiptsCardContainer } from '../PaymentReporter/styles'

const Contents = () => (
  <>
    <ColumnReceipts>
      <PayoutSkeleton width={200} height={25} />
      <PayoutSkeleton width={164} height={24} />
    </ColumnReceipts>
    <PayoutSkeleton width={86} height={25} />
  </>
)

export const LoadingTuitionSubHeader = () => {
  return (
    <>
      <GridItem xl={6} lg={6} md={6} sm={4} xs={2}>
        <PayoutCardContainer>
          <TitleContainer>
            <PayoutSkeleton width={200} height={40} />
            <PayoutSkeleton width={86} height={40} />
          </TitleContainer>
          <AmountContainer>
            <PayoutSkeleton width={350} height={38} />
            <PayoutSkeleton width={164} height={21} />
          </AmountContainer>
          <PayoutSkeleton width={200} height={40} />
        </PayoutCardContainer>
      </GridItem>

      <GridItem xl={6} lg={6} md={6} sm={4} xs={2}>
        <div className="flex flex-col gap-6">
          <ReceiptsCardContainer>
            <Contents />
          </ReceiptsCardContainer>
          <ReceiptsCardContainer>
            <Contents />
          </ReceiptsCardContainer>
        </div>
      </GridItem>
    </>
  )
}
