import { RadioGroupItem } from '@gravity/radio'
import { PropsWithChildren } from 'react'
import { Card, CircleIcon, InfoItem, RadioItem, Row, TextItem } from './styles'
import { OptionCardProps } from './types'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'
import { BankCard2Outline, BarcodeBoxOutline } from '@gravity/icons'

export const PAYMENT_CARD: Record<string, { icon: React.ReactNode; name: string }> = {
  CREDIT_CARD: {
    name: 'Cartão de crédito',
    icon: <BankCard2Outline className="text-colors-background-accent-1" />,
  },
  DEBIT_CARD: {
    name: 'Cartão de débito',
    icon: <BankCard2Outline className="text-colors-background-accent-1" />,
  },
  BANK_SLIP: {
    name: 'Boleto e Pix',
    icon: <BarcodeBoxOutline className="text-colors-background-accent-1" />,
  },
}

const OptionCard = ({
  informationText,
  type,
  onSelect,
  isPaymentFeeEnabled,
  isSelected,
  children,
}: PropsWithChildren<OptionCardProps>) => {
  const { name, icon } = PAYMENT_CARD[type]

  return (
    <div style={{ display: 'flex', width: '100%', marginBottom: 0 }}>
      <Card>
        <Row>
          <RadioItem onClick={() => onSelect(type)}>
            <RadioGroupItem value={type} checked={isSelected} />
            <CircleIcon>{icon}</CircleIcon>
          </RadioItem>
          <TextItem onClick={() => onSelect(type)}>
            <span style={{ width: '70%' }}>
              <Heading variant="heading-h4-medium">{name}</Heading>
              {isPaymentFeeEnabled && (
                <Text variant="body-2-regular" className="text-colors-text-main-3">
                  {informationText?.bottomLeft}
                </Text>
              )}
            </span>
            <InfoItem>
              {isPaymentFeeEnabled ? (
                <>
                  <Heading variant="heading-h4-medium">{informationText?.info}</Heading>
                  <Text variant="body-2-regular" className="text-colors-text-main-3">
                    {informationText?.bottomRight}
                  </Text>
                </>
              ) : (
                <Text variant="body-2-regular">{informationText?.info}</Text>
              )}
            </InfoItem>
          </TextItem>
        </Row>
        {isSelected && children}
      </Card>
    </div>
  )
}

export default OptionCard
