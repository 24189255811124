import type { ReactNode } from 'react'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import type { SchoolEnrollmentCycle } from '../../services/types'

import { useGetSchoolEnrollmentCycles } from '@/shared/hooks/queries/useGetSchoolEnrollmentCycles'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { AcademicPeriod } from '@/shared/services/school-enrollment-cycles/types'

type SchoolEnrollmentCycleProviderProps = {
  children: ReactNode
}

type SchoolEnrollmentContextType = {
  hasErrorOnFetchCycles: boolean
  isFetchingCycles: boolean
  retryFetchCycles: () => Promise<void>
  schoolEnrollmentCycles: SchoolEnrollmentCycle[]
  selectedEnrollmentCycle?: SchoolEnrollmentCycle
  updateSelectedEnrollmentCycle: (selectedEnrollmentCycle: SchoolEnrollmentCycle) => void
}
export const legacyYearsSchoolEnrollmentCycles: SchoolEnrollmentCycle[] = [
  {
    created_at: '',
    enabled: true,
    expires_at: '',
    reference_year: 2023,
    school_enrollment_cycle_id: '1',
    school_id: '',
    updated_at: '',
    academic_period_id: '',
    academic_period: {
      year: 2023,
      view: '2023',
    } as AcademicPeriod,
  },
  {
    created_at: '',
    enabled: true,
    expires_at: '',
    reference_year: 2022,
    school_enrollment_cycle_id: '2',
    school_id: '',
    updated_at: '',
    academic_period_id: '',
    academic_period: {
      year: 2022,
      view: '2022',
    } as AcademicPeriod,
  },
  {
    created_at: '',
    enabled: true,
    expires_at: '',
    reference_year: 2021,
    school_enrollment_cycle_id: '3',
    school_id: '',
    updated_at: '',
    academic_period_id: '',
    academic_period: {
      year: 2021,
      view: '2021',
    } as AcademicPeriod,
  },
]

export const SchoolEnrollmentCycleContext = createContext({} as SchoolEnrollmentContextType)

export const SchoolEnrollmentCycleProvider = ({ children }: SchoolEnrollmentCycleProviderProps) => {
  const [selectedEnrollmentCycle, setSelectedEnrollmentCycle] = useState<SchoolEnrollmentCycle>()

  const { school } = useSelectedSchool()
  const {
    data: response,
    isFetching,
    isError,
    refetch,
  } = useGetSchoolEnrollmentCycles(school?.id ?? '', { enabled: !!school })
  const schoolEnrollmentCyclesRequest = response?.data ?? []

  const schoolEnrollmentCycles = schoolEnrollmentCyclesRequest
    ? [...schoolEnrollmentCyclesRequest, ...legacyYearsSchoolEnrollmentCycles]
    : []

  const updateSelectedEnrollmentCycle = (selectedEnrollmentCycle: SchoolEnrollmentCycle) => {
    return setSelectedEnrollmentCycle(selectedEnrollmentCycle)
  }

  useEffect(() => {
    if (schoolEnrollmentCycles.length > 0) {
      setSelectedEnrollmentCycle(schoolEnrollmentCycles[0])
    }
  }, [schoolEnrollmentCyclesRequest])

  const contextValue: SchoolEnrollmentContextType = useMemo(
    () => ({
      hasErrorOnFetchCycles: isError,
      isFetchingCycles: isFetching,
      selectedEnrollmentCycle,
      retryFetchCycles: async () => {
        await refetch()
      },
      schoolEnrollmentCycles,
      updateSelectedEnrollmentCycle,
    }),
    [schoolEnrollmentCyclesRequest, selectedEnrollmentCycle, isFetching, isError]
  )

  return (
    <SchoolEnrollmentCycleContext.Provider value={contextValue}>
      {children}
    </SchoolEnrollmentCycleContext.Provider>
  )
}

export const useSchoolEnrollmentCycleContext = () => {
  const context = useContext(SchoolEnrollmentCycleContext)
  if (context === undefined) {
    throw new Error(
      'useSchoolEnrollmentCycleContext must be used within a SchoolEnrollmentCycleProvider'
    )
  }

  return context
}
