import { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { useQueryClient } from '@tanstack/react-query'
import { v4 as uuidv4 } from 'uuid'
import { Add, CalendarEventOutline, DeleteBinOutline, MoneyDollarBoxOutline } from '@gravity/icons'
import { useSetPageTitle } from '@/shared/hooks/useSetPageTitle'
import { Text } from '@gravity/text'
import { Grid, GridItem } from '@gravity/grid'
import { FullScreenHeader } from '@gravity/header'
import { useToast } from '@gravity/toast'

import { Installment, Contract } from '@/modules/contract/services/types'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { UnleashFlags, useJWT, useUnleashFlag } from '@/shared/hooks'

import { PageFooter } from './components/PageFooter/index'
import { SelectedInstallmentsText } from './components/SelectedInstallmentsText'
import {
  calculateHasUnsavedChanges,
  canAddDiscount,
  canEditAmount,
  filterSelectedInstallments,
  canEditDueDates,
  canCancel,
  cancelContract,
  revokeContract,
} from './utils'
import * as Styled from './styles'
import { useUpdateInstallments } from './hooks/useUpdateInstallments'
import { PageHeader } from './components/PageHeader/index'
import { EditAmountDialog, EditAmountForm } from './components/EditAmountDialog/index'
import { EditDueDateDialog } from './components/EditDueDateDialog/index'
import { AddDiscountDialog, AddDiscountForm } from './components/AddDiscountDialog/index'
import { InstallmentsTable } from './components/InstallmentsTable/index'
import { TableTotals } from './components/TableTotals/index'
import { createUpdateInstallmentsPayload } from './utils/createUpdateInstallmentsPayload'
import { PersonalData } from './components/PersonalData/index'
import { PersonalDataInfo } from './components/PersonalData/types'
import { EditActionButton } from './components/EditActionButton'
import {
  useCancelInstallmentsMutation,
  useContractDetailsQuery,
  useInstallmentsToUpdateQuery,
  useUpdateInstallmentsMutation,
} from './hooks/queries'
import { GoBackButton } from '@/shared/components/GoBackButton'
import {
  Contract as InterfacesContract,
  InstallmentStatuses,
  InstallmentType,
} from '@/shared/interfaces'
import { BackButtonWrapper, Container } from './styles'
import { CancellingDialog } from './components/CancellingDialog'
import { contractAPI } from '@/modules/contract/services'
import { ErrorDialog, ErrorDialogNames, selectErrorDialogName } from './components/ErrorDialog'
import { ErrorDialog as GenericErrorDialog } from '@/shared/components/ErrorDialog'
import { EventProvider, useEvents } from './hooks/eventContext'
import { HotjarEvents, useHotjar } from '@/shared/hooks/useHotjar'
import { AcknowledgeBlockedCancelDialog } from './components/AcknowledgeBlockedCancelDialog'
import { NotAllowedDialog } from '@/shared/components/NotAllowedDialog'
import { useCancelContractModal } from './components/CancelContractModal/useCancelContractModal'
import { CancelContractModal } from './components/CancelContractModal'
import { useLayout } from '@/shared/hooks/useLayout'

export enum DialogNames {
  ADD_DISCOUNT = 'ADD_DISCOUNT',
  CANCEL = 'CANCEL',
  EDIT_AMOUNT = 'EDIT_AMOUNT',
  EDIT_DUE_DATE = 'EDIT_DUE_DATE',
}

export const dialogEventHash: Record<DialogNames, string> = {
  [DialogNames.ADD_DISCOUNT]: 'Descontos',
  [DialogNames.CANCEL]: 'Cancelar',
  [DialogNames.EDIT_AMOUNT]: 'Valor da parcela',
  [DialogNames.EDIT_DUE_DATE]: 'Vencimentos',
}

type EditContractProps = {
  api: contractAPI
  contractId: uuid
  enableCancelContractFF?: boolean
  enableCancelFF?: boolean
  enableEditAmountFF?: boolean
  isHybridModel?: boolean
  redirectOnSuccess?: boolean
  schoolBlockedForCancel?: boolean
  schoolId: uuid
}

const PageComponent = ({
  api,
  contractId,
  schoolId,
  redirectOnSuccess = true,
  enableCancelFF = true,
  enableEditAmountFF = false,
  schoolBlockedForCancel = false,
  enableCancelContractFF = true,
  isHybridModel = false,
}: EditContractProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isErrorOnSubmit, setIsErrorOnSubmit] = useState(false)
  const [dialogName, setDialogName] = useState<DialogNames | ''>('')
  const [errorDialogName, setErrorDialogName] = useState<ErrorDialogNames | null>(null)
  const [showNotAllowedDialog, setShowNotAllowedDialog] = useState(false)

  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)
  useLayout({ enableSideMenu: !renderNewHeader, enableHeader: false })

  const { toast } = useToast()

  const savedInstallments = useRef<Installment[]>()
  const queryClient = useQueryClient()

  const updateInstallmentsMutation = useUpdateInstallmentsMutation(api)
  const cancelInstallmentsMutation = useCancelInstallmentsMutation(api)
  const { data: response, isLoading, isError } = useInstallmentsToUpdateQuery(api, {
    contractId,
    schoolId,
  })

  const {
    data: contractDetails = null,
    isLoading: isLoadingContractDetails,
    refetch: refetchContractDetails,
  } = useContractDetailsQuery(api, {
    contractId,
  })

  const contract = response?.data ?? null

  const personalData: PersonalDataInfo = {
    studentName: contract?.student_name ?? '',
    productName: contract?.product_name ?? '',
    guardianName: contract?.guardian_name ?? '',
    referenceYear: contract?.reference_year ?? '',
  }

  const {
    setUpdatedInstallments,
    selectedInstallmentIds,
    setSelectedInstallmentIds,
    updateAmounts,
    addDiscounts,
    updateDueDates,
    removeDiscounts,
    updatedInstallments,
  } = useUpdateInstallments(contract?.installments ?? [])

  const events = useEvents()
  useEffect(() => {
    if (!contract) {
      return
    }

    events?.setContract(contract)
    events?.setSelectedInstallmentIds(selectedInstallmentIds)
  }, [contract, selectedInstallmentIds])

  const { sendHotjarEvent } = useHotjar()
  useEffect(() => {
    sendHotjarEvent(HotjarEvents?.EDIT_CONTRACT_PAGE)
  }, [])

  const [installmentIDsToCancel, setInstallmentIDsToCancel] = useState<uuid[]>([])

  useSetPageTitle('Editar Contrato')

  const { school } = useSelectedSchool()
  const history = useHistory()
  const { isBackofficeUser } = useJWT()

  const product = contract?.product
  const isSchoolSupply = String(product?.product_category_id) === '4'
  const blocked = isHybridModel && isSchoolSupply && !isBackofficeUser

  useEffect(() => {
    if (blocked) {
      setShowNotAllowedDialog(true)
    }
  }, [blocked])

  useEffect(() => {
    if (!isLoading && !isError && contract) {
      setUpdatedInstallments(contract.installments)
      savedInstallments.current = contract.installments
      events?.pageViewed()
    }
  }, [isLoading, isError, contract, contractId, schoolId])

  const { search } = useLocation()

  const cancelContractNewRules = useUnleashFlag(UnleashFlags.EFI_215_CANCEL_CONTRACT_NEW_RULES)

  const cancelContractModalState = useCancelContractModal({ contractId })

  const isSelected = selectedInstallmentIds.length > 0
  const installments = contract !== null ? updatedInstallments : []
  const selectedInstallments = filterSelectedInstallments(installments, selectedInstallmentIds)

  useEffect(() => {
    const installmentsIdsToPreCancel = selectedInstallments
      .filter(
        installment =>
          installment.status === InstallmentStatuses.PAID &&
          installment.receivable_type === InstallmentType.ENROLLMENT
      )
      .map(installment => installment.backoffice_installment_id)

    //  PAID ENROLLMENT installments are not canceled by default in the revoke contract mutation,
    //  so they need to be canceled before canceling the contract
    cancelContractModalState.setInstallmentsIdsToPreCancel(installmentsIdsToPreCancel)
  }, [selectedInstallmentIds])

  useEffect(() => {
    const searchParams = new URLSearchParams(search)
    const status = searchParams.get('status')?.toUpperCase()

    if (Object.values(InstallmentStatuses).includes(status as InstallmentStatuses)) {
      const InstallmentsIdsToSelect =
        contract?.installments
          ?.filter(installment => installment.status === status)
          ?.map(installment => installment.backoffice_installment_id) ?? []
      setSelectedInstallmentIds(InstallmentsIdsToSelect)
    }
  }, [search, contract])

  const handleCloseDialog = () => {
    setDialogName('')
  }

  const handleCancel = () => {
    if (dialogName in DialogNames) events?.clickCancelBtn(dialogName as DialogNames)
    handleCloseDialog()
  }

  const handleSaveEditAmount = (values: EditAmountForm) => {
    updateAmounts(values)
    if (dialogName in DialogNames) events?.editAmount(dialogName as DialogNames)
    handleCloseDialog()
  }

  const handleAddDiscount = (values: AddDiscountForm, percentMode: boolean) => {
    addDiscounts(values, percentMode)
    events?.addDiscount(values, percentMode)
    handleCloseDialog()
  }

  const handleEditDueDates = (dueDates: datestring[], workingDayMode: boolean) => {
    updateDueDates(dueDates)
    events?.editDueDate(workingDayMode)
    handleCloseDialog()
  }

  const handleDeleteDiscount = (installmentId: uuid, discountIndexToDelete: number) => {
    removeDiscounts(installmentId, discountIndexToDelete)
    events?.removeDiscount(installmentId, discountIndexToDelete)
  }

  const handleCancelInstallments = (selectedInstallmentIds: string[]) => {
    setInstallmentIDsToCancel(
      Array.from(new Set([...installmentIDsToCancel, ...selectedInstallmentIds]))
    )
    handleCloseDialog()
  }

  const goToGuardianInstallmentsPage = () => {
    if (redirectOnSuccess && contract && school) {
      const { guardian_id, product_id, student_id } = contract

      history.push({
        pathname: `/${school.slug}/responsaveis/${guardian_id}/faturas?student_ids=${student_id}&product_ids=${product_id}`,
      })
    }
  }

  const cleanUpOnSuccess = () => {
    setSelectedInstallmentIds([])
    setInstallmentIDsToCancel([])
    refetchContractDetails()
    queryClient.invalidateQueries(['guardian-details-installments'])
    goToGuardianInstallmentsPage()
  }

  const handleSubmit = async () => {
    if (!contract) return

    setIsSubmitting(true)
    setIsErrorOnSubmit(false)

    const updateInstallmentsPayload = createUpdateInstallmentsPayload(
      contract?.installments || [],
      updatedInstallments
    )
    const hasUpdate = updateInstallmentsPayload.length > 0
    let updateSuccess = false

    const hasCancel = installmentIDsToCancel.length > 0
    let cancelSuccess = false

    try {
      const correlationID = uuidv4()

      const updateInstallmentsPayload = createUpdateInstallmentsPayload(
        contract?.installments || [],
        updatedInstallments
      )

      if (hasUpdate) {
        await updateInstallmentsMutation.mutateAsync({
          schoolId,
          contractId: contract?.id || '',
          updateInstallmentsRequest: updateInstallmentsPayload,
          correlationID,
        })

        updateSuccess = true
      }

      if (hasCancel) {
        await cancelInstallmentsMutation.mutateAsync({
          schoolId,
          contractId: contract?.id || '',
          installmentIDs: installmentIDsToCancel,
          correlationID,
        })

        cancelSuccess = true
      }

      cleanUpOnSuccess()
      toast({
        type: 'success',
        title: 'As edições no contrato foram salvas.',
      })
    } catch (error) {
      events?.submitError()
      console.error(error)

      Sentry.captureException(error)

      setIsErrorOnSubmit(true)
      setErrorDialogName(
        selectErrorDialogName({ hasUpdate, updateSuccess, hasCancel, cancelSuccess })
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  const openDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, name: DialogNames) => {
    setDialogName(name)
    const buttonText = (e.target as HTMLButtonElement).textContent
    events?.clickEditAction(buttonText ?? '')
  }

  const dialogComponent =
    {
      [DialogNames.EDIT_AMOUNT]: (
        <EditAmountDialog
          onClose={handleCancel}
          onConfirm={handleSaveEditAmount}
          selectedInstallments={selectedInstallments}
        />
      ),
      [DialogNames.EDIT_DUE_DATE]: (
        <EditDueDateDialog
          api={api}
          onClose={handleCancel}
          onConfirm={handleEditDueDates}
          selectedInstallments={selectedInstallments}
          allInstallments={installments}
          referenceYear={contract?.reference_year ?? ''}
        />
      ),
      [DialogNames.ADD_DISCOUNT]: (
        <AddDiscountDialog
          onClose={handleCancel}
          onConfirm={handleAddDiscount}
          selectedInstallments={selectedInstallments}
        />
      ),
      [DialogNames.CANCEL]: (
        <CancellingDialog
          onClose={handleCancel}
          onConfirm={handleCancelInstallments}
          selectedInstallments={selectedInstallments}
          installments={installments}
        />
      ),
      '': null,
    }[dialogName] || null

  const [discountEnabled, discountTooltip] = canAddDiscount(
    selectedInstallments,
    installmentIDsToCancel
  )
  const [dueDateEnabled, dueDateTooltip] = canEditDueDates(
    installments,
    selectedInstallmentIds,
    installmentIDsToCancel
  )
  const [amountEnabled, amountTooltip] = canEditAmount(
    selectedInstallments,
    enableEditAmountFF,
    installmentIDsToCancel
  )
  const [cancelEnabled, cancelTooltip] = canCancel(selectedInstallments, {
    cancelContractNewRules,
    enableCancelFF,
    schoolBlockedForCancel,
    isAdmin: isBackofficeUser,
  })

  const shouldCancelContract = cancelContractNewRules
    ? revokeContract(updatedInstallments, selectedInstallmentIds)
    : cancelContract(updatedInstallments, selectedInstallmentIds)

  const hasUnsavedChanges = calculateHasUnsavedChanges(
    savedInstallments.current ?? [],
    installments,
    installmentIDsToCancel
  )

  return (
    <>
      <Container>
        {renderNewHeader && (
          <FullScreenHeader
            className="fixed top-0 left-0 right-0 z-[99]"
            title="Editar contrato"
            onClose={() => history.goBack()}
          />
        )}
        <Grid className={renderNewHeader ? 'mt-[80px]' : ''}>
          <GridItem>
            <Styled.FooterSpaceClearance>
              {!renderNewHeader && (
                <BackButtonWrapper>
                  <GoBackButton />
                </BackButtonWrapper>
              )}
              <PageHeader
                showTitle={!renderNewHeader}
                isLoading={isLoadingContractDetails}
                contract={contractDetails as Pick<InterfacesContract, 'created_at' | 'status'>}
              />
              <PersonalData isLoading={isLoading} data={personalData} />
              {blocked ? (
                <NotAllowedDialog
                  title="Não é possível editar este contrato"
                  description="Contratos de material didático só podem ser cancelados. Em caso de dúvida, procure o suporte."
                  onConfirm={goToGuardianInstallmentsPage}
                  visible={showNotAllowedDialog}
                  closeDialog={() => setShowNotAllowedDialog(false)}
                />
              ) : (
                <>
                  <Text variant="button-1">Plano de pagamento</Text>
                  <InstallmentsTable
                    contract={contract as Contract}
                    data={installments}
                    isLoading={isLoading}
                    onDeleteDiscount={handleDeleteDiscount}
                    selectedInstallmentIds={selectedInstallmentIds}
                    setSelectedInstallmentIds={setSelectedInstallmentIds}
                    installmentIDsToCancel={installmentIDsToCancel}
                    setInstallmentIDsToCancel={setInstallmentIDsToCancel}
                    enableCancelFF={enableCancelFF}
                  />

                  {isSelected && (
                    <Styled.StickyComponent>
                      <Grid
                        className="rounded-b-2 border border-solid border-colors-border-neutral-3"
                        style={{
                          marginLeft: 'unset',
                        }}
                      >
                        <GridItem xl={12} lg={12} md={12} sm={8} xs={4}>
                          <div className="p-4">
                            <Styled.SelectedTableFooterContainer>
                              <SelectedInstallmentsText count={selectedInstallmentIds.length} />
                              <EditActionButton
                                icon={<Add />}
                                text="Aplicar descontos"
                                tooltip={discountTooltip}
                                disabled={!discountEnabled}
                                onClick={e => openDialog(e, DialogNames.ADD_DISCOUNT)}
                              />
                              <EditActionButton
                                icon={<MoneyDollarBoxOutline />}
                                text="Editar valor das parcelas"
                                tooltip={amountTooltip}
                                disabled={!amountEnabled}
                                onClick={e => openDialog(e, DialogNames.EDIT_AMOUNT)}
                              />
                              <EditActionButton
                                icon={<CalendarEventOutline />}
                                text="Editar vencimentos"
                                tooltip={dueDateTooltip}
                                disabled={!dueDateEnabled}
                                onClick={e => openDialog(e, DialogNames.EDIT_DUE_DATE)}
                              />
                              {enableCancelContractFF && (
                                <EditActionButton
                                  icon={<DeleteBinOutline />}
                                  text={
                                    shouldCancelContract ? 'Cancelar contrato' : 'Cancelar parcelas'
                                  }
                                  color="error"
                                  tooltip={cancelTooltip}
                                  disabled={!cancelEnabled}
                                  onClick={e => {
                                    if (!shouldCancelContract) {
                                      return openDialog(e, DialogNames.CANCEL)
                                    }

                                    return cancelContractModalState.open()
                                  }}
                                />
                              )}
                              {schoolBlockedForCancel && !isBackofficeUser && (
                                <AcknowledgeBlockedCancelDialog />
                              )}
                            </Styled.SelectedTableFooterContainer>
                          </div>
                        </GridItem>
                      </Grid>
                    </Styled.StickyComponent>
                  )}

                  {!isSelected && (
                    <GridItem xl={12} lg={12} md={12} sm={8} xs={4}>
                      <TableTotals installments={installments} />
                    </GridItem>
                  )}
                </>
              )}
              <PageFooter
                hasUnsavedChanges={hasUnsavedChanges}
                isSubmitting={isSubmitting}
                isNotificationOpen={false}
                handleSubmit={handleSubmit}
              />
            </Styled.FooterSpaceClearance>
          </GridItem>
        </Grid>
      </Container>
      {dialogComponent}
      <CancelContractModal
        state={cancelContractModalState}
        overdueVariant="overdue-acknowledge"
        onSuccess={() => {
          cleanUpOnSuccess()
          queryClient.invalidateQueries(['update-contract'])
          queryClient.invalidateQueries(['guardian-details-contracts'])
        }}
        onError={error => {
          console.error(error)

          setIsErrorOnSubmit(true)
          setErrorDialogName(
            selectErrorDialogName({
              hasUpdate: false,
              updateSuccess: false,
              hasCancel: true,
              cancelSuccess: false,
            })
          )
        }}
      />
      <GenericErrorDialog isError={isError} />
      <ErrorDialog
        isError={isErrorOnSubmit}
        dialogName={errorDialogName}
        onClose={() => setIsErrorOnSubmit(false)}
      />
    </>
  )
}

export const EditContract = (props: EditContractProps) => (
  <EventProvider>
    <PageComponent {...props} />
  </EventProvider>
)
