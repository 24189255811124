import { IconButton } from '@gravity/icon-button'
import { Tooltip } from '@gravity/tooltip'
import { Pencil, DeleteBin } from '@gravity/icons'

type RowActionsProps = {
  onRequestEdit: () => void
  onRequestRemove: () => void
}

export const RowActions = ({ onRequestEdit, onRequestRemove }: RowActionsProps) => {
  return (
    <>
      <Tooltip text="Editar">
        <IconButton variant="ghost" onClick={() => onRequestEdit()} data-testid="edit">
          <Pencil />
        </IconButton>
      </Tooltip>

      <Tooltip text="Excluir">
        <IconButton variant="ghost" onClick={() => onRequestRemove()} data-testid="remove">
          <DeleteBin />
        </IconButton>
      </Tooltip>
    </>
  )
}
