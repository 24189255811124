import { useEffect } from 'react'
import { Table } from '@gravity/table'
import { Tooltip } from '@gravity/tooltip'

import { Wrapper, TableRow } from './styles'
import { GuardianFinancialSummaryTableProps } from './types'

import { GuardianInvoicesTableActions } from '@/modules/guardians/GuardianDetailsInvoicesTab/components/GuardianInvoicesTableActions'
import { useGuardianDetailsFinancial } from '@/modules/guardians/GuardianDetailsFinancialSummaryTab/hooks/useGuardianDetailsFinancial'
import { formatter } from '@/modules/guardians/GuardianDetailsFinancialSummaryTab/utils/formatter'

import {
  InstallmentRowType,
  InstallmentStatus,
} from '@/modules/guardians/GuardianDetails/constants'

export const GuardianFinancialSummaryTable = ({
  allCheckable,
  installments,
  loading,
  onRowClick,
}: GuardianFinancialSummaryTableProps) => {
  const {
    rows,
    batchSelection,
    enableBatchSelection,
    handleRowSelect,
    handleSelectAll,
  } = useGuardianDetailsFinancial({ installments })

  const isOverdueEnrollment = ({
    row_type,
    status,
  }: {
    row_type: InstallmentRowType
    status: InstallmentStatus
  }) =>
    row_type === InstallmentRowType.ENROLLMENT_INSTALLMENT && status === InstallmentStatus.OVERDUE

  useEffect(() => {
    if (allCheckable && !loading && rows?.length > 0) {
      handleSelectAll(true)
    }
  }, [loading, rows?.length])

  return (
    <Wrapper>
      <Table.Root>
        <Table.Head>
          <Table.HeaderCell
            className="max-w-8 w-8"
            name="checkbox"
            enableCheckbox={allCheckable && enableBatchSelection}
            onChange={handleSelectAll}
            checked={batchSelection}
          />
          <Table.HeaderCell minWidth={80} name="due_date">
            Vencimento
          </Table.HeaderCell>
          <Table.HeaderCell minWidth={80} name="installment_order">
            Fatura
          </Table.HeaderCell>
          <Table.HeaderCell name="student">Aluno</Table.HeaderCell>
          <Table.HeaderCell name="product_name">Produto</Table.HeaderCell>
          <Table.HeaderCell minWidth={80} name="amount">
            Valor
          </Table.HeaderCell>
          <Table.HeaderCell minWidth={80} name="installment_status">
            Situação
          </Table.HeaderCell>
        </Table.Head>

        <Table.Body>
          {loading ? (
            <>
              <Table.Row>
                <Table.CustomCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
              </Table.Row>

              <Table.Row>
                <Table.CustomCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
              </Table.Row>

              <Table.Row>
                <Table.CustomCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
              </Table.Row>
            </>
          ) : (
            rows?.map(row => (
              <TableRow key={row.urn} onClick={() => onRowClick(row)} disabled={row.disabled}>
                {isOverdueEnrollment(row) ? (
                  <Tooltip
                    text="Altere a data de vencimento desta fatura para que ela possa ser paga."
                    position="right"
                    style={{ maxWidth: '262px' }}
                  >
                    <Table.CustomCell
                      className="max-w-8 w-8"
                      enableCheckbox={row.enableCheckboxVisibility}
                      onChange={() => handleRowSelect(row.urn)}
                      checked={row.checked}
                      disabled={!row.enableCheckbox}
                    />
                  </Tooltip>
                ) : (
                  <Table.CustomCell
                    className="max-w-8 w-8"
                    enableCheckbox={row.enableCheckboxVisibility}
                    onChange={() => handleRowSelect(row.urn)}
                    checked={row.checked}
                    disabled={!row.enableCheckbox}
                  />
                )}
                <Table.TextCell>{formatter.fullDate(row.due_date)}</Table.TextCell>
                <Table.TextCell
                  subtext={formatter.orderReference(row.installment_order?.order_reference)}
                >
                  {formatter.installmentType(row.installment_order?.row_type)}
                </Table.TextCell>
                <Table.TextCell>{row.student?.name}</Table.TextCell>
                <Table.TextCell>{row.product_name}</Table.TextCell>
                <Table.TextCell>{formatter.amount(row.amount)}</Table.TextCell>
                <Table.BadgeCell badgeVariant="soft" badgeColor={row.installment_status?.color}>
                  {row.installment_status?.text}
                </Table.BadgeCell>

                <GuardianInvoicesTableActions installment={row} />
              </TableRow>
            ))
          )}
        </Table.Body>
      </Table.Root>
    </Wrapper>
  )
}
