export interface Step {
  goBackBtnDisabled: boolean
  onClickGoBack: () => void
  onComplete: () => void
}

export enum Steps {
  CONTRACT_SELECTION = 0,
  ADD_NEW_GUARDIAN = 1,
  REVIEW = 2,
}
