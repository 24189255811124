import styled from 'styled-components'

export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const YearAndCampaignWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const TotalAndContractActions = styled.div<{ hasLegacyYears?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ hasLegacyYears }) => (hasLegacyYears ? 'flex-end' : 'space-between')};
  width: 100%;
  align-items: center;
`
export const ReferenceYear = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${props => props.theme.gravity.spacing[4]};
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: ${props => props.theme.gravity.spacing[4]};
`
export const ScoreAndContract = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.gravity.spacing[4]};
`
