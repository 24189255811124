import styled from 'styled-components'
import { Accordion } from '@gravity/accordion'

export const AccordionItem = styled(Accordion.Item)`
  margin-bottom: ${props => props.theme.gravity.spacing[4]};
`

export const AccordionContainer = styled.div`
  margin-top: ${props => props.theme.gravity.spacing[8]};
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[6]};
  margin-bottom: ${props => props.theme.gravity.spacing[6]};
`

export const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20vh;
`

export const AccordionTitleContainer = styled.div`
  display: flex;
  padding-bottom: ${props => props.theme.gravity.spacing[2]};
  align-items: center;
  gap: ${props => props.theme.gravity.spacing[3]};
`
