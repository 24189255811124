import { useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'

import { APIResponse } from '@/shared/interfaces/api'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { LoadingPaginCreditSummary } from '@monorepo/credit/components/LoadingCreditStatus'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'

import {
  useContextChosenOffer,
  useContextCreditOrderId,
  useContextCreditSimulation,
  useContextCreditValue,
  useContextRequestSimulation,
  useContextStepperMap,
  useReasonForChosenPolicyType,
} from '../../contexts/CreditDataContext'
import { useChooseOffer } from '../../hooks/queries/useChooseOffer'
import { CreditEventComponentNames } from '../../constants/CreditEventComponentNames.enum'
import { MixpanelEventActions } from '../../constants/MixpanelEventActions.enum'
import useMixpanelEventSender from '../../utils/useMixpanelEventSender'
import { useGetLock } from '../../hooks/queries/useGetLock'

import DefaultTemplate from '../../templates/DefaultTemplate'
import PageExitConfirm from '../../components/PageExitConfirm'
import CreditSummaryCard from '../../components/CreditSummaryCard'

export const CreditSummary = () => {
  const { sendCreditEventToMixpanel } = useMixpanelEventSender()

  const { push } = useHistory()
  const { schoolSlug } = useSelectedSchool()

  const { creditSimulation } = useContextCreditSimulation()
  const { chosenOffer, setChosenOffer } = useContextChosenOffer()
  const { setCreditValue } = useContextCreditValue()
  const { reasonForChosenPolicyType } = useReasonForChosenPolicyType()
  const { setCreditOrderId } = useContextCreditOrderId()
  const { setStepperMap } = useContextStepperMap()
  const { requestSimulation } = useContextRequestSimulation()
  const { mutateAsync } = useChooseOffer()
  const { school } = useSelectedSchool()

  const schoolId = school?.id ?? ''

  const { data: orderLock, isLoading: isLoadingLock, refetch: refreshLock } = useGetLock(schoolId)
  const statusPage = `/${schoolSlug}/credito/estado`
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    sendCreditEventToMixpanel(EventDispatcherEvents.PAGE_VIEWED, MixpanelEventActions.PAGE_VIEW, {
      $page_name: CreditEventComponentNames.SIMULATION_RESULT,
    })
  }, [])

  const isButtonDisabled = !isLoadingLock ? orderLock?.isLocked : true

  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  const handleRequestCredit = async () => {
    sendCreditEventToMixpanel(EventDispatcherEvents.BUTTON_CLICKED, MixpanelEventActions.CLICK, {
      $button_name: CreditEventComponentNames.REQUEST_CREDIT,
      $selected: requestSimulation.reason,
      $note: requestSimulation.note,
      $request_value: chosenOffer.totalAmount,
      $installments: chosenOffer.totalInstallments,
      $selected_period: requestSimulation.gracePeriodDate?.label,
      $options_period: requestSimulation.gracePeriodDates?.map(date => date.label),
      $modality_type: chosenOffer.category,
    })

    setIsLoading(true)

    mutateAsync({
      orderId: creditSimulation.orderId,
      chooseOfferId: chosenOffer.id,
    }).then(async (response: APIResponse) => {
      if (response) {
        const chosenOfferSelected = {
          ...chosenOffer,
          chosenOfferDate: new Date().toLocaleDateString('pt-BR', { timeZone: 'UTC' }),
        }
        setCreditValue(creditSimulation.creditValue)
        setChosenOffer(chosenOfferSelected)
        setCreditOrderId(creditSimulation.orderId)
        setStepperMap({ step: 0, finished: true })
        push(statusPage)
      } else {
        location.href = statusPage
      }
    })
  }

  const handleSimulateNewOffer = () => {
    sendCreditEventToMixpanel(EventDispatcherEvents.BUTTON_CLICKED, MixpanelEventActions.CLICK, {
      $button_name: CreditEventComponentNames.SIMULATE_NEW_OFFER,
    })
    refreshLock()
    push(`/${schoolSlug}/credito/simulacao`)
  }

  // TODO: this is a quick solution to redirect user if there is no data in context
  // we should persist data in browser storage or use useNavigate to avoid this
  if (!creditSimulation) {
    return <Redirect to={`/${schoolSlug}/credito`} />
  }
  if (isLoading) {
    return <LoadingPaginCreditSummary />
  }

  return (
    <DefaultTemplate
      title="Resumo da sua oferta"
      previousRoute={
        renderNewHeader ? `/${schoolSlug}/credito` : `/${schoolSlug}/credito/simulacao`
      }
      footerProps={{
        primaryButton: {
          onClick: handleRequestCredit,
          label: 'Solicitar crédito',
          disabled: isButtonDisabled,
          tooltip: isButtonDisabled
            ? 'Já existe um pedido da mesma modalidade em aberto'
            : undefined,
        },
        secondaryButton: {
          onClick: handleSimulateNewOffer,
          label: 'Simular nova oferta',
        },
      }}
      child={
        <>
          <div className="pb-16">
            <CreditSummaryCard
              chosenOffer={chosenOffer}
              setChosenOffer={setChosenOffer}
              reasonForChosenPolicyType={reasonForChosenPolicyType}
              {...creditSimulation}
            />
          </div>

          <PageExitConfirm
            title="Nos ajude a construir a oferta de crédito ideal para você"
            labelReason="Quais pontos da oferta simulada não te atenderam?"
            confirmButtonLabel="Sair da oferta"
            backButtonLabel="Continuar na oferta"
          />
        </>
      }
    />
  )
}
