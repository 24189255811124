import { Card as DSCard } from '@gravity/card'
import { Badge as DSBadge } from '@gravity/badge'

import { Text } from '@gravity/text'
import styled from 'styled-components'

type CardProps = {
  isSelected: boolean
}

export const Card = styled(DSCard)<CardProps>`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.gravity.spacing[6]};
  border: 1px solid;
  border-color: ${props =>
    props.isSelected
      ? props.theme.gravity.colors['colors-interaction-secondary-4']
      : props.theme.gravity.colors['colors-border-neutral-2']};
  background-color: ${props => props.isSelected && '#F7F8FD'};
  border-radius: ${props => props.theme.gravity.borderRadius[2]};
  cursor: pointer;
  &:hover {
    background-color: ${props =>
      !props.isSelected && props.theme.gravity.colors['colors-background-accent-3']};
  }
`
export const BigNumberText = styled(Text)`
  font-size: ${props => props.theme.gravity.fontSize.xl};
  font-weight: ${props => props.theme.gravity.fontWeight.medium};
  line-height: ${props => props.theme.gravity.spacing[9]};
`
export const CurrencySymbol = styled(Text)`
  font-size: ${props => props.theme.gravity.fontSize.sm};
  font-weight: ${props => props.theme.gravity.fontWeight.normal};
  align-self: flex-end;
  margin-right: ${props => props.theme.gravity.spacing[2]};
  line-height: ${props => props.theme.gravity.spacing[6]};
`
export const Badge = styled(DSBadge)`
  max-width: fit-content;
  margin-bottom: ${props => props.theme.gravity.spacing[4]};
  font-weight: ${props => props.theme.gravity.fontWeight.medium};
`
export const CurrencyWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
export const CardSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.gravity.spacing[6]};
  border: 1px solid;
  border-color: ${props => props.theme.gravity.colors['colors-border-neutral-2']};
  border-radius: ${props => props.theme.gravity.borderRadius[2]};
  & > div:nth-child(1) {
    margin-bottom: ${props => props.theme.gravity.spacing[4]};
  }
  & > div:nth-child(2) {
    margin-bottom: ${props => props.theme.gravity.spacing[2]};
  }
`
