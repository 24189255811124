import { Dialog } from '@gravity/dialog'
import { Button } from '@gravity/button'
import { Text } from '@gravity/text'
import { ContentDialog } from './InvoicesNotGeneratedDialog.styles'

export type InvoicesNotGeneratedDialogProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const InvoicesNotGeneratedDialog = (props: InvoicesNotGeneratedDialogProps) => {
  const { isOpen, setIsOpen } = props

  const onClose = () => setIsOpen(false)

  return (
    <>
      <Dialog
        title="Faturas do contrato não geradas"
        backdrop
        open={isOpen}
        onOpenChange={open => {
          if (!open && isOpen) onClose()
        }}
        size={2}
        content={
          <ContentDialog>
            <Text variant="body-2-regular">
              As faturas desse contrato ainda não foram geradas por isso não é possível realizar
              essa operação.
            </Text>
            <Text variant="body-2-regular">
              Verifique se algum dos itens abaixo estão corretos nesse contrato:
            </Text>
            <Text variant="body-2-regular">
              <ul style={{ listStylePosition: 'inside' }}>
                <li>CEP</li>
                <li>CPF</li>
                <li>Data de vencimento</li>
              </ul>
            </Text>
          </ContentDialog>
        }
        actionButton={
          <Button onClick={onClose} data-testid="botao-fechar-modal">
            Fechar
          </Button>
        }
      />
    </>
  )
}
