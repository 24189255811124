import { useQuery } from '@tanstack/react-query'
import { useApiClient } from '@/shared/hooks'
import { creditService } from '@/shared/services/credit'
import { CreditReason, ReasonsType } from '@/shared/services/credit/types'

export const creditReasonsQueryKey = 'payout-anticipation-reasons'

export const useGetPayoutAnticipationReasons = () => {
  const { apiClient } = useApiClient()

  const services = creditService(apiClient.getClients().bffApi)

  return useQuery<CreditReason[]>(
    [creditReasonsQueryKey],
    async () => {
      const { data } = await services.getCreditReasons(ReasonsType.payout)
      return data
    },
    { retry: 2, suspense: true }
  )
}
