import type { StatusSummaryResponse } from '@monorepo/enrollment/services/enrollmentService/types'
import { ReenrollmentStatus } from '@/shared/interfaces'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { BigNumbersCard } from '../BigNumbersCard'
import * as Styled from './styles'

const statusByPriority = [
  ReenrollmentStatus.REENROLLMENT_AVAILABLE,
  ReenrollmentStatus.NOT_REENROLLABLE,
  ReenrollmentStatus.ENROLLMENT_AT_RISK,
  ReenrollmentStatus.ENROLLMENT_IN_PROGRESS,
  ReenrollmentStatus.ENROLLED,
]

type BigNumbersGroupProps = {
  data?: StatusSummaryResponse['data']
  isError: boolean
  isLoading: boolean
}

export const BigNumbersGroup = ({ data, isLoading, isError }: BigNumbersGroupProps) => {
  const { school } = useSelectedSchool()

  if (isError) {
    return null
  }

  return (
    <Styled.Container role="listbox" aria-label="Filtro de status" aria-multiselectable="true">
      {statusByPriority.map(status => {
        const value = data?.status[status]

        if ((!isLoading && !value) || !school) {
          return null
        }

        return <BigNumbersCard key={status} status={status} value={value} isLoading={isLoading} />
      })}
    </Styled.Container>
  )
}
