import { useState } from 'react'
import { Popover } from '@gravity/popover'

import { useJWT } from '@/shared/hooks/useJWT'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { AvatarDropdownTrigger } from './components/AvatarDropdownTrigger'
import { AvatarDropdownUserInfo } from './components/AvatarDropdownUserInfo'
import { AvatarDropdownOptions } from './components/AvatarDropdownOptions'

/**
 * Component that represents de logged user dropdown options
 */
export const AvatarDropdown = () => {
  const { school } = useSelectedSchool()
  const { user, getUserGroupNameLabel } = useJWT()

  const [isOpen, setIsOpen] = useState(false)

  const userGroupNameLabel = getUserGroupNameLabel(school?.id ?? '')

  return (
    <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
      <AvatarDropdownTrigger>{user?.first_name[0]}</AvatarDropdownTrigger>

      <Popover.Content className="z-[99]" side="bottom" align="end">
        <AvatarDropdownUserInfo
          avatarText={user?.first_name[0]}
          text={user ? `${user.first_name} ${user.last_name}` : ''}
          subText={userGroupNameLabel}
        />

        <AvatarDropdownOptions onSelectOption={() => setIsOpen(false)} />
      </Popover.Content>
    </Popover.Root>
  )
}
