import styled from 'styled-components'

import { Card } from '@gravity/card'

export const Wrapper = styled(Card)`
  padding: 32px 24px;
  margin-top: ${({ theme }) => theme.gravity.spacing[1]};
  border-radius: ${({ theme }) => theme.gravity.spacing[1]};
  border: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  margin-bottom: 150px;
`

export const CardSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  margin-top: ${({ theme }) => theme.gravity.spacing[1]};
  margin-bottom: ${({ theme }) => theme.gravity.spacing[1]};
`

export const Footer = styled.div`
  position: fixed;
  left: 96px;
  bottom: 0px;
  right: 0px;
  padding: ${({ theme }) => theme.gravity.spacing[1]} ${({ theme }) => theme.gravity.spacing[2]};
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FooterContent = styled.div`
  max-width: 654px;
  margin: 0 auto;
  padding-right: 12%;
  display: flex;
  justify-content: space-between;
`

export const FooterBtnWrapper = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.gravity.spacing[2]};
`

export const StudentNameWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.gravity.spacing[3]};
`

export const ContentWrapper = styled.div`
  margin-top: ${({ theme }) => theme.gravity.spacing[2]};
`
