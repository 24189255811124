import { useQuery } from '@/shared/hooks/useQuery'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { REPORT_PAGE_NAMES } from '@monorepo/report/constants/REPORT_PAGE_NAMES'
import { Add, DashedArrowRight, Subtract } from '@gravity/icons'
import { IconButton } from '@gravity/icon-button'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'
import { ReceiptsCardContainer, RowReceipts, ColumnReceipts } from './styles'

type PaymentReporterProps = {
  deductionsValue: string
  incomeValue: string
  onRequestOpenPayoutTransfersDrawer: () => void
}

const CARD_LABELS = {
  income: 'Ver todos os recebimentos',
  deductions: 'Ver todos os descontos',
}

export const PaymentReporter = ({
  incomeValue,
  deductionsValue,
  onRequestOpenPayoutTransfersDrawer,
}: PaymentReporterProps) => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { setOnQueryParam } = useQuery()

  const handleCardLinkClick = (section: 'income' | 'deductions') => {
    onRequestOpenPayoutTransfersDrawer()
    setOnQueryParam(section, 'transfer_section', 'replace')

    const buttonLabel = CARD_LABELS[section]

    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICK,
        scope: EventDispatcherEventScopes.PAYOUT_REPORT,
        action: 'click',
        customProperties: {
          $page_name: REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT,
          $button_label: buttonLabel,
        },
      })
  }

  return (
    <>
      <ReceiptsCardContainer
        style={{ marginBottom: '16px' }}
        onClick={() => handleCardLinkClick('income')}
      >
        <RowReceipts>
          <Add />
          <ColumnReceipts>
            <Heading variant="heading-h4-medium">Recebimentos</Heading>
            <Text variant="subtitle-regular">{incomeValue}</Text>
          </ColumnReceipts>
        </RowReceipts>
        <ColumnReceipts>
          <IconButton variant="ghost" size={2}>
            <DashedArrowRight />
          </IconButton>
        </ColumnReceipts>
      </ReceiptsCardContainer>
      <ReceiptsCardContainer onClick={() => handleCardLinkClick('deductions')}>
        <RowReceipts>
          <Subtract />

          <ColumnReceipts>
            <Heading variant="heading-h4-medium">Descontos</Heading>
            <Text variant="subtitle-regular">{deductionsValue}</Text>
          </ColumnReceipts>
        </RowReceipts>
        <ColumnReceipts>
          <IconButton variant="ghost" size={2}>
            <DashedArrowRight />
          </IconButton>
        </ColumnReceipts>
      </ReceiptsCardContainer>
    </>
  )
}
