import dayjs, { extend } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { formatCentsToReal } from '@/shared/utils'
import { Row } from '@/modules/guardians/types'
import { INSTALLMENT_ROW_TYPES, NEW_INSTALLMENT_ROW_TYPES } from '../GuardianDetails/constants'

dayjs.locale('pt-br')
extend(utc)

const full_date = (value: Row['due_date']) => dayjs.utc(dayjs(value)).format('DD/MM/YYYY')

const full_date_br = (value: Row['due_date']) => dayjs(value).format('DD/MM/YYYY')

const amount = (value: Row['amount']) => formatCentsToReal(value)

const order_reference = (value: Row['installment_order']['order_reference']) =>
  value.split('/').join(' de ')

const installmentType = (value: Row['installment_order']['row_type']) =>
  INSTALLMENT_ROW_TYPES[value]

const newInstallmentType = (value: Row['installment_order']['row_type']) =>
  NEW_INSTALLMENT_ROW_TYPES[value]

const negotiation_id = (value: Row['negotiation_id']) => `#${value?.slice(0, 6)}`.toUpperCase()

const competence_date = (value: Row['installment_info']['competence_date']) => {
  return value.length > 1
    ? value.reduce((prev, curr, idx, arr) => {
        if (idx === arr.length - 1) return `${prev}${dayjs(curr, 'YYYY-MM').format('MMM. YYYY')}`
        prev = `${prev}${dayjs(curr, 'YYYY-MM').format('MMM')}, `
        return prev
      }, '')
    : dayjs(value[0], 'YYYY-MM').format('MMM. YYYY')
}

export const formatter = {
  full_date,
  full_date_br,
  amount,
  order_reference,
  negotiation_id,
  installmentType,
  newInstallmentType,
  competence_date,
}
