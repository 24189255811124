import styled from 'styled-components'
import { Controller, UseFormReturn, FieldArrayWithId } from 'react-hook-form'
import { Card } from '@gravity/card'
import { Text } from '@gravity/text'
import { Button as GravityButton } from '@gravity/button'
import { Pencil, DeleteBin } from '@gravity/icons'
import { AddDiscountsFormType } from 'src/shared/interfaces'
import DiscountFormFields from 'src/escolas/components/DiscountFormFields'
import {
  formatCentsToReal,
  leftZeroPadding,
  formatNumberToTwoFractionDigits,
} from 'src/shared/utils'
import { EnrollmentFormFields } from '../contract/create/types'

const SecondaryText = styled(Text)`
  color: ${props => props.theme.gravity.colors['colors-text-main-3']};
`

export type DiscountFormProps = {
  data: FieldArrayWithId<EnrollmentFormFields, 'discounts', 'id'>
  form: UseFormReturn<EnrollmentFormFields | AddDiscountsFormType>
  idPrefix: string
  index: number
  removeDiscount: (index: number) => void
  totalAmount: number
}

const DiscountForm = ({ form, totalAmount, data, index, removeDiscount }: DiscountFormProps) => {
  const { control, watch, trigger } = form

  const [
    amount,
    description,
    days_before_due_date,
    isFinished,
    label_days_before_due_date,
  ] = watch([
    `discounts.${index}.amount` as const,
    `discounts.${index}.description` as const,
    `discounts.${index}.days_before_due_date` as const,
    `discounts.${index}.isFinished` as const,
    `discounts.${index}.label_days_before_due_date` as const,
  ]) as [number, string, number, boolean, string]

  const amountFieldName = `discounts.${index}.amount` as const

  const isValidDescription = Boolean(description)
  const daysBeforeDueDateOptionDescription =
    days_before_due_date > 0
      ? `${days_before_due_date} dias antes do vencimento`
      : label_days_before_due_date

  const invalidAmount = Boolean(form.getFieldState(amountFieldName).error)
  const isValidDaysBeforeDueDate =
    daysBeforeDueDateOptionDescription &&
    (daysBeforeDueDateOptionDescription === 'Antes do vencimento'
      ? Boolean(days_before_due_date > 0)
      : days_before_due_date >= -1)
  const isFinishButtonDisabled = invalidAmount || !isValidDaysBeforeDueDate || !isValidDescription

  const percentage = formatNumberToTwoFractionDigits((amount / totalAmount) * 100)

  return (
    <>
      <Card key={data.id}>
        <Controller
          rules={{ required: true, validate: Boolean }}
          control={control}
          name={`discounts.${index}.isFinished` as const}
          defaultValue={isFinished}
          render={({ field: { onChange, value } }) => (
            <div data-testid={`add-discount-${index}-content`}>
              <div className="flex justify-between items-center mb-2">
                <SecondaryText variant="caption-regular">
                  Desconto nº {leftZeroPadding(index + 1, 2)}
                </SecondaryText>
                <div className="flex">
                  {isFinished && (
                    <GravityButton
                      size={2}
                      iconStart={<Pencil />}
                      variant="ghost"
                      aria-label="editar desconto"
                      onClick={() => onChange(!value)}
                      type="button"
                    />
                  )}
                  <GravityButton
                    size={2}
                    iconStart={<DeleteBin />}
                    variant="ghost"
                    aria-label="remover desconto"
                    onClick={() => removeDiscount(index)}
                    type="button"
                  />
                </div>
              </div>
              <div style={{ display: isFinished ? 'block' : 'none' }}>
                <>
                  <Text>{description}</Text>
                  <div className="flex flex-row items-center justify-between w-48 my-2">
                    <SecondaryText className="inline">Valor: </SecondaryText>
                    <Text variant="subtitle-medium" className="inline font-bold">
                      <strong>{formatCentsToReal(amount)}</strong>
                    </Text>
                    <SecondaryText className="inline"> — </SecondaryText>
                    <Text variant="subtitle-medium" className="inline">
                      <strong>{` ${percentage}%`}</strong>
                    </Text>
                  </div>
                  <div className="my-3">
                    {days_before_due_date > 0 && (
                      <SecondaryText className="inline">Válido até </SecondaryText>
                    )}
                    <SecondaryText className="inline">
                      {daysBeforeDueDateOptionDescription}
                    </SecondaryText>
                  </div>
                </>
              </div>
              <div style={{ display: !isFinished ? 'block' : 'none' }}>
                <div>
                  <div>
                    <DiscountFormFields
                      form={form}
                      amountDefaultValue={data.amount ?? undefined}
                      amountFieldName={amountFieldName}
                      daysBeforeDueDateDefaultValue={data.days_before_due_date ?? undefined}
                      daysBeforeDueDateFieldName={`discounts.${index}.days_before_due_date`}
                      daysBeforeDueDateLabelFieldName={`discounts.${index}.label_days_before_due_date`}
                      descriptionDefaultValue={data.description}
                      descriptionFieldName={`discounts.${index}.description`}
                      totalAmountPaid={totalAmount}
                    />
                  </div>
                  <GravityButton
                    fullWidth
                    variant="ghost"
                    type="button"
                    disabled={isFinishButtonDisabled}
                    data-testid="save-discount-button"
                    onClick={() => {
                      onChange(!value)
                      trigger()
                    }}
                  >
                    Salvar desconto
                  </GravityButton>
                </div>
              </div>
            </div>
          )}
        />
      </Card>
    </>
  )
}

export default DiscountForm
