import { useState } from 'react'

import { useApi } from '@/utils/hooks/useApi'
import { Receivable } from '@/shared/interfaces'
import { Text } from '@gravity/text'
import { DialogPrimitives } from '@gravity/dialog'
import { Button } from '@gravity/button'

export type ChangePaymentDialogProps = {
  backButtonLabel?: string
  buttonLabel?: string
  isConfirmDisabled?: () => boolean
  isPayableByCreditCard?: boolean
  isVisible: boolean
  onClose: () => void
  receivableId: string
  replaceReceivables: (receivables: Array<Receivable>) => void
  setShowChangePaymentModal: (value: boolean) => void
  setShowFailedDialog: (value: boolean) => void
  title: string
}

export const ChangePaymentDialog = ({
  buttonLabel = 'Confirmar alteração',
  backButtonLabel,
  isVisible,
  onClose,
  isPayableByCreditCard,
  setShowFailedDialog,
  title,
  isConfirmDisabled,
  receivableId,
  setShowChangePaymentModal,
  replaceReceivables,
}: ChangePaymentDialogProps) => {
  const { api } = useApi()
  const [successfulStep, setSuccessfulStep] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [newReceivable, setNewReceivable] = useState<Receivable>()

  const message = isPayableByCreditCard
    ? 'Ao alterar o pagamento para boleto, o responsável financeiro receberá a nova fatura por e-mail e poderá visualizá-la no aplicativo bancário. Além disso, um novo registro será gerado na conta da escola.'
    : 'Ao alterar o pagamento para cartão, a parcela selecionada só poderá ser paga por esse meio de pagamento. Após a confirmação, o boleto referente a essa parcela será automaticamente cancelado.'

  const messageFinalState = isPayableByCreditCard
    ? 'Você pode reverter essa alteração e gerar uma nova cobrança para pagamento em cartão para a parcela selecionada no menu anterior.'
    : 'Você pode reverter essa alteração e gerar um novo boleto para a parcela selecionada no menu anterior.'

  const secondaryMessage = 'Deseja confirmar a alteração?'

  const openFailedModal = () => {
    setShowFailedDialog(true)
    setShowChangePaymentModal(false)
  }

  const submitChangePaymentMethod = async () => {
    setIsLoading(true)
    return await api.receivables
      .changePaymentMethod(receivableId)
      .then(data => {
        setSuccessfulStep(true)
        setNewReceivable(data)
      })
      .catch(() => openFailedModal())
      .finally(() => setIsLoading(false))
  }
  const closeDialog = () => {
    replaceReceivables([newReceivable as Receivable])
    setShowChangePaymentModal(false)
  }

  return (
    <DialogPrimitives.Root open={isVisible} onOpenChange={open => !open && onClose()}>
      <DialogPrimitives.Overlay backdrop />
      <DialogPrimitives.Content
        data-testid="feedback-dialog"
        size={2}
        title={successfulStep ? 'Link da cobrança gerado com sucesso' : title}
        cancelButton={
          backButtonLabel &&
          !successfulStep && (
            <Button size={2} variant="ghost" onClick={onClose} disabled={isLoading}>
              {backButtonLabel}
            </Button>
          )
        }
        actionButton={
          <Button
            size={2}
            loading={isLoading}
            variant="solid"
            onClick={!successfulStep ? submitChangePaymentMethod : closeDialog}
            disabled={isConfirmDisabled?.()}
          >
            {successfulStep ? 'Ok, entendi' : buttonLabel}
          </Button>
        }
      >
        <div className="flex flex-col gap-4 justify-center">
          {successfulStep ? (
            <Text variant="body-2-regular">{messageFinalState}</Text>
          ) : (
            <Text variant="body-2-regular">{message}</Text>
          )}
          {!successfulStep && <Text variant="body-2-regular">{secondaryMessage}</Text>}
        </div>
      </DialogPrimitives.Content>
    </DialogPrimitives.Root>
  )
}

export default ChangePaymentDialog
