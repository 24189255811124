import { TransactionEventType } from '@monorepo/report/models/report'
import { InstallmentType, InstallmentTypeLabel } from '@/shared/interfaces'

export enum VariationColors {
  ERROR = 'error',
  NEUTRAL = 'neutral',
  SUCCESS = 'success',
  WARNING = 'warning',
}

type EventChange = { color: VariationColors; text: string }

const paramsInstallmentDict: PartialRecord<InstallmentType, EventChange> = {
  [InstallmentType.TUITION]: {
    color: VariationColors.NEUTRAL,
    text: InstallmentTypeLabel.TUITION,
  },
  [InstallmentType.ENROLLMENT]: {
    color: VariationColors.NEUTRAL,
    text: InstallmentTypeLabel.ENROLLMENT,
  },
}

const paramsEventDict: PartialRecord<TransactionEventType, EventChange> = {
  [TransactionEventType.NEW_CONTRACT]: {
    color: VariationColors.SUCCESS,
    text: 'Novo aluno',
  },
  [TransactionEventType.LOST_DISCOUNT]: {
    color: VariationColors.SUCCESS,
    text: 'Adicional desc. antecipação',
  },
  [TransactionEventType.APPLIED_DISCOUNT]: {
    color: VariationColors.WARNING,
    text: 'Novo desconto',
  },
  [TransactionEventType.CANCEL]: {
    color: VariationColors.ERROR,
    text: 'Cancelado',
  },
  [TransactionEventType.SCHOOL_LIQUIDATION]: {
    color: VariationColors.WARNING,
    text: 'Recebido na escola',
  },
  [TransactionEventType.LIQUIDATION_EDITION]: {
    color: VariationColors.WARNING,
    text: 'Edição de recebido na escola',
  },
  [TransactionEventType.CORRECTION]: {
    color: VariationColors.WARNING,
    text: 'Correção pontual',
  },
  [TransactionEventType.AGGLUTINATION_DISCOUNT]: {
    color: VariationColors.WARNING,
    text: 'Desconto por aglutinação de parcelas',
  },
  [TransactionEventType.ORIGINAL_AMOUNT_CHANGED]: {
    color: VariationColors.WARNING,
    text: 'Edição de valor',
  },
}

const transformEventDict = (eventDict: typeof paramsEventDict) => {
  return Object.entries(eventDict).reduce<PartialRecord<TransactionEventType, EventChange>>(
    (acc, [key, event]) => {
      switch (key) {
        case TransactionEventType.NEW_CONTRACT:
          return {
            ...acc,
            [key]: {
              color: event.color,
              text: 'Novo contrato',
            },
          }
        case TransactionEventType.APPLIED_DISCOUNT:
          return {
            ...acc,
            [key]: {
              color: event.color,
              text: 'Edição de desconto',
            },
          }
        default:
          return {
            ...acc,
            [key]: event,
          }
      }
    },
    {}
  )
}

export const useEventChanges = (
  tags: Array<TransactionEventType | InstallmentType>,
  limit: number = tags.length
) => {
  const transformedEventDict = transformEventDict(paramsEventDict)
  const paramsDict = { ...transformedEventDict, ...paramsInstallmentDict }
  const available = tags.filter(type => paramsDict[type])

  const eventChanges: EventChange[] = []
  available?.slice(0, limit)?.forEach((type, _) => {
    const params = paramsDict[type]
    if (params) {
      eventChanges.push(params)
    }
  })

  return eventChanges
}
