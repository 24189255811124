import { Accordion } from '@gravity/accordion'
import { Controller, UseFormReturn } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { UFs } from 'src/shared/utils'
import { defaultAddress } from '../create/constants'
import { TextField } from '@gravity/text-field'
import { Select } from '@gravity/select'

export type CollapseAddressProps = {
  edit?: boolean
  form: UseFormReturn<any>
  isFirstRender: boolean
  isZipValid: boolean
  onZipFieldFilled: (zip: cep) => void
}

const CollapseAddress = ({
  edit = false,
  form,
  isZipValid,
  isFirstRender,
  onZipFieldFilled,
}: CollapseAddressProps) => {
  const { control, reset, getValues, setError, clearErrors } = form
  const formValues = getValues()
  const defaultValue = ''
  const clearAddressErrors = (fields: any[]) => {
    clearErrors([...fields])
  }

  const resetZipCode = () => {
    reset(
      {
        ...formValues,
        address: { ...defaultAddress },
      },
      { keepErrors: true }
    )

    setError('address.zip', {
      type: 'required',
    })

    clearAddressErrors(['address.street', 'address.number', 'address.city', 'address.state_code'])
  }

  return (
    <Accordion.Item value="address">
      <Accordion.Trigger>Endereço</Accordion.Trigger>
      <Accordion.Content>
        <div className="flex flex-col gap-4">
          <Controller
            rules={{
              minLength: 8,
              maxLength: 8,
              validate: () => isZipValid,
            }}
            name="address.zip"
            control={control}
            render={({ field: { onChange, value, ...rest }, fieldState: { error, invalid } }) => (
              <NumberFormat
                {...rest}
                label="CEP"
                id="edit-guardian-zip-input"
                type="tel"
                format="#####-###"
                mask="_"
                onValueChange={currentValue => {
                  if (!currentValue.value) {
                    return resetZipCode()
                  }

                  onChange(currentValue.value)

                  if (!isFirstRender) {
                    currentValue.value.length === 8 && onZipFieldFilled(currentValue.value)
                  }
                }}
                value={value}
                customInput={TextField}
                variant="outlined"
                error={Boolean(error?.type || invalid)}
                helperText={error?.type || invalid ? 'CEP inválido' : ''}
                disabled={!edit}
                size={3}
              />
            )}
          />
          <Controller
            rules={{ required: true }}
            name="address.street"
            control={control}
            render={({ field: { value, ...rest }, fieldState: { error } }) => (
              <TextField
                {...rest}
                label="Rua"
                id="edit-guardian-street-input"
                fullWidth
                value={value || defaultValue}
                error={Boolean(error?.type && isZipValid)}
                errorMessage={error?.type && isZipValid ? 'Rua inválida' : ''}
                disabled={!edit}
                size={3}
              />
            )}
          />
          <Controller
            rules={{
              required: true,
            }}
            name="address.number"
            control={control}
            render={({ field: { value, ...rest }, fieldState: { error } }) => (
              <TextField
                {...rest}
                label="Número"
                id="edit-guardian-number-input"
                value={value || defaultValue}
                error={Boolean(error?.type && isZipValid)}
                errorMessage={error?.type && isZipValid ? 'Número inválido' : ''}
                disabled={!edit}
                size={3}
              />
            )}
          />
          <Controller
            rules={{
              required: false,
            }}
            name="address.additional_information"
            control={control}
            render={({ field: { value, ...rest } }) => (
              <TextField
                {...rest}
                label="Complemento (opcional)"
                id="edit-guardian-additional-information-input"
                value={value || defaultValue}
                disabled={!edit}
                size={3}
              />
            )}
          />
          <Controller
            rules={{ required: true }}
            name="address.city"
            control={control}
            render={({ field: { value, ...rest }, fieldState: { error } }) => (
              <TextField
                {...rest}
                label="Cidade"
                id="edit-guardian-city-input"
                value={value || defaultValue}
                error={Boolean(error?.type && isZipValid)}
                errorMessage={error?.type && isZipValid ? 'Cidade inválida' : ''}
                disabled={!edit}
                size={3}
              />
            )}
          />
          <Controller
            rules={{ required: true }}
            name="address.state_code"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Select
                onValueChange={onChange}
                value={value || defaultValue}
                size={3}
                hasError={Boolean(error?.type && isZipValid)}
                variant="surface"
                placeholder="Selecione"
                label="Estado"
                options={UFs.map(v => ({ label: v, value: v }))}
                disabled={!edit}
              />
            )}
          />
        </div>
      </Accordion.Content>
    </Accordion.Item>
  )
}

export default CollapseAddress
