import type { PaymentPlanMethodsProps } from './types'
import { BarcodeBoxOutline } from '@gravity/icons'
import { formatBankSlipInfoText } from '@/modules/guardians/Negotiation/helpers/textBankSlip'
import { Text } from '@gravity/text'

export const InfoPaymentPlanBankSlip = ({
  paymentPlan,
  totalAmount,
  isPaymentFeeEnabled,
}: PaymentPlanMethodsProps) => {
  const { max_installments_allowed, min_down_payment } = paymentPlan
  const numberOfInstallments = max_installments_allowed

  const infoText = formatBankSlipInfoText(
    numberOfInstallments,
    totalAmount,
    min_down_payment,
    isPaymentFeeEnabled
  )

  return (
    <div className="flex mb-1 items-center">
      <BarcodeBoxOutline />
      <Text variant="body-2-regular" className="text-colors-text-main-3">
        <b>Boleto/PIX:</b> {infoText}
      </Text>
    </div>
  )
}
