import type { ReactNode } from 'react'

import { Skeleton } from '@gravity/skeleton'
import * as Styled from './styles'

import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'

type Props = {
  description: string
  isLoading: boolean
  onClick?: () => void
  title: string | ReactNode
  value: string
}
export const DetailsBigNumber = ({
  title,
  value,
  description,
  onClick,
  isLoading = false,
}: Props) => {
  return isLoading ? (
    <Styled.BigNumberSkeleton>
      <Skeleton fullWidth height={24} radius={8} />
      <Skeleton fullWidth height={24} radius={8} />
    </Styled.BigNumberSkeleton>
  ) : (
    <Styled.BigNumberCard onClick={onClick}>
      <Text className="text-lg">{title}</Text>
      <Heading variant="heading-h4-medium">{value}</Heading>
      <Text>{description}</Text>
    </Styled.BigNumberCard>
  )
}
