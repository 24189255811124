import { Accordion } from '@gravity/accordion'
import { Heading } from '@gravity/heading'
import { CloseCircleOutline } from '@gravity/icons'
import { Table } from '@gravity/table'
import { Contract } from '@monorepo/onboarding/services/types/onboardingContract'
import { Product } from '@monorepo/onboarding/services/types/onboardingProduct'

export const Contracts = () => {
  const mockedContracts: Contract[] = [
    {
      id: '1',
      created_at: '2021-01-01',
      batch_id: '1',
      charges: [
        {
          accrual_date: '2021-01-01',
          amount: '100',
          contract_id: '1',
          due_date: '2021-01-01',
          situation: 'paid',
          type: 'charge',
          paid_date: '2021-01-01',
          created_at: '2021-01-01',
          id: '1',
          discounts: [],
          is_valid: true,
          validations: [],
        },
        {
          accrual_date: '2021-01-01',
          amount: '100',
          contract_id: '1',
          due_date: '2021-01-01',
          situation: 'paid',
          type: 'charge',
          paid_date: '2021-01-01',
          created_at: '2021-01-01',
          id: '2',
          discounts: [],
          is_valid: true,
          validations: [],
        },
      ],
      error_code: '',
      finished_at: '2021-01-01',
      guardian_id: '1',
      product_id: '1',
      student_id: '1',
      product: {
        id: '1',
        course: 'Ensino Médio',
        grade: '3º ano',
        reference_year: '2024',
        created_at: '2021-01-01',
        validations: [],
        is_valid: true,
        batch_id: '1',
      },
      guardian: {
        id: '1',
        name: 'John Doe',
        email: 'john.doe@example.com',
        tax_id: '1234567890',
        created_at: '2021-01-01',
        validations: [],
        is_valid: true,
        batch_id: '1',
        external_id: '1234567890',
        address_additional_information: '1234567890',
        address_number: '1234567890',
        address_street: '1234567890',
        address_city: '1234567890',
        address_state_code: '1234567890',
        address_zip: '1234567890',
        phone_number: '1234567890',
      },
      student: {
        id: '1',
        birth_date: '2021-01-01',
        created_at: '2021-01-01',
        name: 'John Doe',
        validations: [],
        is_valid: true,
        situation: '',
        tax_id: '1234567890',
        batch_id: '1',
        external_id: '1234567890',
      },
      status: 'PENDING',
    },
  ]

  const composeFullProductName = (product: Product) => {
    const { reference_year, course, grade } = product

    return [reference_year, course, grade].join(' - ')
  }

  return (
    <div className="flex flex-col gap-6">
      <Heading variant="heading-h4-medium">Cobranças</Heading>

      {mockedContracts.map((contract, index) => (
        <Accordion.Root key={`contract-${index}`} type="single" collapsible>
          <Accordion.Item value={`contract-${index}`}>
            <Accordion.Trigger
              descriptionList={[
                {
                  text: 'Correções pendentes',
                  iconStart: (
                    // Accordion overrides styles and pass size="16px", which is incompatible with @gravity/icons
                    <span>
                      <CloseCircleOutline size="xs" />
                    </span>
                  ),
                },
              ]}
            >
              {composeFullProductName(contract.product)}
            </Accordion.Trigger>

            <Accordion.Content>
              <Table.Root>
                <Table.Head>
                  <Table.HeaderCell name="accrual_date">Competência</Table.HeaderCell>
                  <Table.HeaderCell name="accrual_date">Parcela</Table.HeaderCell>
                  <Table.HeaderCell name="due_date">Vencimento</Table.HeaderCell>
                  <Table.HeaderCell name="status">Status</Table.HeaderCell>
                </Table.Head>

                <Table.Body>
                  {contract.charges.map(charge => (
                    <Table.Row key={charge.id}>
                      <Table.TextCell>{charge.accrual_date}</Table.TextCell>
                      <Table.TextCell>{charge.type}</Table.TextCell>
                      <Table.TextCell>{charge.due_date}</Table.TextCell>
                      <Table.BadgeCell badgeVariant="soft" badgeColor="error">
                        3 erros
                      </Table.BadgeCell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table.Root>
            </Accordion.Content>
          </Accordion.Item>
        </Accordion.Root>
      ))}
    </div>
  )
}
