import styled from 'styled-components'
import { Tooltip } from '@gravity/tooltip'
import { Text } from '@gravity/text'

export const StyledTooltip = styled(Tooltip)`
  margin-left: ${props => props.theme.gravity.spacing[1]};
  z-index: 1400; /* Valor maior que o z-index da gaveta */
`

export const SecondaryInfoText = styled(Text)`
  color: ${({ theme }) => theme.gravity.colors['colors-text-main-3']};
`
