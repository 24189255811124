import React from 'react'
import { useInstallmentDrawerContext } from '@/modules/guardians/contexts/InstallmentDrawerContext'
import { useDrawerActionsInstallment } from './useDrawerActionsInstallment'
import { LinkM, FileList3Outline } from '@gravity/icons'

export const useInstallmentDrawerButtons = (onCloseDrawer: () => void) => {
  const { installmentDrawerInfo } = useInstallmentDrawerContext()

  const {
    isLoading,
    isDisabledManualLiquidation,
    isHiddenManualLiquidation,
    isDisabledRenegotiation,
    isHiddenRenegotiation,
    isDisabledChangePaymentMethod,
    isHiddenChangePaymentMethod,
    isHiddenEditManualLiquidation,
    handleClickManualLiquidation,
    handleClickRenegotiation,
    handleClickChangePaymentMethod,
    handleClickSharePaymentLink,
    handleClickPrintReceipt,
    handleClickEditManualLiquidation,
  } = useDrawerActionsInstallment(onCloseDrawer)

  if (!installmentDrawerInfo.status) {
    return null
  }

  const buttonTitle =
    installmentDrawerInfo.status === 'PAID' ? 'Gerar comprovante' : 'Compartilhar link'
  const buttonAction =
    installmentDrawerInfo.status === 'PAID' ? handleClickPrintReceipt : handleClickSharePaymentLink
  const buttonIcon =
    installmentDrawerInfo.status === 'PAID'
      ? React.createElement(FileList3Outline)
      : React.createElement(LinkM)

  return {
    isLoading,
    buttonTitle,
    buttonAction,
    buttonIcon,
    isHiddenEditManualLiquidation,
    handleClickEditManualLiquidation,
    menuItems: [
      {
        title: 'Registrar recebimento',
        onClick: handleClickManualLiquidation,
        disabled: isDisabledManualLiquidation,
        hidden: isHiddenManualLiquidation,
      },
      {
        title: 'Renegociar',
        onClick: handleClickRenegotiation,
        disabled: isDisabledRenegotiation,
        hidden: isHiddenRenegotiation,
      },
      {
        title: 'Alterar forma de pagamento',
        onClick: handleClickChangePaymentMethod,
        disabled: isDisabledChangePaymentMethod,
        hidden: isHiddenChangePaymentMethod,
        helpDescription: isDisabledChangePaymentMethod
          ? 'Não é possível alterar a forma de pagamento de uma parcela vencida'
          : '',
      },
    ],
  }
}
