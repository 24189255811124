import { Text } from '@gravity/text'
import type { StepComponentProps } from '@/modules/guardians/Negotiation/types'
import { useAgreement } from 'src/escolas/contexts/agreementContext'
import { formatCentsToReal } from 'src/shared/utils'
import { useSendCheckoutEvent } from '@/modules/guardians/Negotiation/hooks/useSendCheckoutEvents'

import * as S from './styles'
import AgreementList from './components/AgreementList'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import StepFooter from '../../components/StepFooter'

const AgreementListStep = ({ id, onFinish, goBack }: StepComponentProps) => {
  const { agreementSimulations, totalAmount, positionAgreement } = useAgreement()

  const sendCheckoutEvent = useSendCheckoutEvent()

  function handleGoBack() {
    sendCheckoutEvent(EventDispatcherEntities.CHECKOUT_FIRST_STEP_BACK, {
      name: 'Parcelas selecionadas - Voltar',
      location: id,
    })
    goBack()
  }

  function handleOnClickContinue() {
    sendCheckoutEvent(EventDispatcherEntities.CHECKOUT_FIRST_STEP_CONTINUE, {
      name: 'Parcelas selecionadas - Continuar',
    })
    onFinish()
  }
  return (
    <S.Container>
      <div style={{ marginTop: 32, marginBottom: 20 }}>
        <Text
          variant="body-1-regular"
          style={{ textAlign: 'center', display: 'block', width: '100%' }}
        >
          O valor total de <b>{formatCentsToReal(totalAmount)}</b> será divido nesses{' '}
          {agreementSimulations.length} pagamentos e<br /> serão pagos em sequência:
        </Text>
      </div>

      <AgreementList agreements={agreementSimulations} />

      <StepFooter
        showHelpButton
        stepName={id}
        goBack={handleGoBack}
        btnPrimary={{
          onClick: handleOnClickContinue,
          hideIcon: true,
          label: `Começar o ${positionAgreement + 1}º pagamento`,
        }}
      />
    </S.Container>
  )
}

export default AgreementListStep
