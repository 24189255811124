import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { AlertOutline, FileTextOutline, PencilOutline } from '@gravity/icons'

import { NOTIFICATION_STATUS } from '../../constants'
import type { Notification } from '../../types'

import { OverviewSectionPlaceholder } from './OverviewSectionPlaceholder'
import { CategoryColors, Container, IconContainer } from './styles'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'

const hasUnreadNotificationsByCategory = (notifications: Notification[], category: string) =>
  notifications.filter(
    notification =>
      notification.category === category && notification.status === NOTIFICATION_STATUS.UNREAD
  ).length > 0

export type OverviewSectionProps = {
  category: string
  isLoading: boolean
  notifications: Notification[]
}

export const OverviewSection = (props: OverviewSectionProps) => {
  const { notifications, isLoading } = props
  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  const categories = [
    {
      title: 'Informes',
      color: 'colors-background-neutral-3',
      icon: <FileTextOutline size="xs" />,
      details: hasUnreadNotificationsByCategory(notifications, 'informes')
        ? {
            title: 'Existem informes não lidos',
            fontWeight: 'medium',
          }
        : {
            title: 'Todos informes lidos',
          },
    },
    {
      title: 'Alertas',
      color: 'colors-background-semantic-colors-warning-2',
      icon: <AlertOutline size="xs" />,
      details: hasUnreadNotificationsByCategory(notifications, 'alertas')
        ? {
            title: 'Existem alertas não lidos',
            fontWeight: 'medium',
          }
        : {
            title: 'Todos alertas lidos',
          },
    },
    {
      title: 'Tarefas',
      color: 'colors-interaction-secondary-2',
      icon: <PencilOutline size="xs" />,
      details: hasUnreadNotificationsByCategory(notifications, 'tarefas')
        ? {
            title: 'Existem tarefas não lidas',
            fontWeight: 'medium',
          }
        : {
            title: 'Todas tarefas lidas',
          },
    },
  ]

  return (
    <Container>
      {renderNewHeader ? (
        <Heading variant="heading-h2-medium">Quadro de avisos</Heading>
      ) : (
        <Heading variant="heading-h4-medium">Visão geral</Heading>
      )}
      <div className="cards">
        {isLoading ? (
          <OverviewSectionPlaceholder />
        ) : (
          categories.map(category => (
            <div key={category.title} className="content" data-testid="content">
              <IconContainer
                className="icon"
                data-testid="icon"
                color={category.color as CategoryColors}
              >
                {category.icon}
              </IconContainer>
              <div className="description">
                <Heading className="title" data-testid="title" variant="heading-h4-medium">
                  {category.title}
                </Heading>
                <Text className={`details ${category.details.fontWeight}`} data-testid="details">
                  {category.details.title}
                </Text>
              </div>
            </div>
          ))
        )}
      </div>
    </Container>
  )
}
