import { InformationOutline, CalendarEvent } from '@gravity/icons'
import { Separator } from '@gravity/separator'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'

import { convertToUTCDate, formatMicroCentsToReal } from '@/shared/utils'

import { ENROLLMENT_TRANSACTIONS_IN_ORDER } from '@monorepo/report/constants/transactionsInOrder'
import { GrayText, StyledTooltip } from '../../styles'

/**
 * Drawer para conteúdo de pagamento. Este conteúdo é para provedores de pagamento de matrícula.
 * @param props
 * @param props.bankTransfers Resumo das transferências bancárias
 * @param props.values Valores das transferências em cada pagamento
 * @param props.total Valor total do pagamento
 * @param props.total_fee Valor total da taxa
 * @param props.enrollment_end_date Data final do período de matrícula
 */
export const EnrollmentPayoutDrawerContent = ({
  bankTransfers,
  values,
  total,
  total_fee,
  enrollment_end_date,
}) => {
  const formatDate = (date: Date) => {
    const formatter = new Intl.DateTimeFormat('pt-BR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })

    return formatter.format(date)
  }

  return (
    <>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-6 pt-6 px-6">
          {Object.keys(ENROLLMENT_TRANSACTIONS_IN_ORDER).map(
            key =>
              !!values[key] && (
                <div className="flex justify-between items-center" key={key}>
                  <GrayText variant="body-1-regular" className="flex items-center">
                    {ENROLLMENT_TRANSACTIONS_IN_ORDER[key].label}

                    {ENROLLMENT_TRANSACTIONS_IN_ORDER[key].description && (
                      <StyledTooltip text={ENROLLMENT_TRANSACTIONS_IN_ORDER[key].description}>
                        <span>
                          <InformationOutline />
                        </span>
                      </StyledTooltip>
                    )}
                  </GrayText>

                  <Text variant="body-1-medium">{formatMicroCentsToReal(values[key])}</Text>
                </div>
              )
          )}

          {Object.keys(ENROLLMENT_TRANSACTIONS_IN_ORDER).some(key => !!values[key]) && (
            <Separator orientation="horizontal" color="neutral-2" />
          )}

          <div className="flex justify-between items-center">
            <GrayText variant="body-1-regular" className="flex items-center">
              Taxa isaac
              <StyledTooltip text="Valor referente à prestação de serviços isaac">
                <span className="flex items-center">
                  <InformationOutline size="xs" />
                </span>
              </StyledTooltip>
            </GrayText>

            <Text variant="body-1-medium">{formatMicroCentsToReal(total_fee)}</Text>
          </div>

          <Separator orientation="horizontal" color="neutral-2" />

          <div className="flex justify-between items-center">
            <Text variant="body-1-medium" className="max-w-[15rem]">
              {total < 0
                ? 'Valor pendente a ser descontado do repasse de mensalidades'
                : 'Valor total'}
            </Text>

            <Text variant="body-1-medium" className="text-gray">
              {formatMicroCentsToReal(total)}
            </Text>
          </div>

          <Separator orientation="horizontal" color="neutral-2" />

          <div className="flex justify-between items-center">
            <GrayText variant="body-1-regular">Matrículas pagas até</GrayText>
            <Text variant="body-1-medium">{formatDate(enrollment_end_date)}</Text>
          </div>
        </div>
        <Separator orientation="horizontal" color="neutral-2" />
      </div>
      {bankTransfers.length > 0 && (
        <>
          <div className="flex flex-col gap-6 p-6">
            <div className="flex items-center gap-2">
              <CalendarEvent size="sm" />
              <Heading variant="heading-h4-regular">Transferências programadas</Heading>
            </div>

            <div className="flex flex-col gap-4">
              {bankTransfers.map((item, index) => (
                <div key={index} className="flex justify-between items-center">
                  <Text variant="body-1-regular" className="text-gray">
                    {convertToUTCDate(item.date).format('DD/MM/YYYY')}
                  </Text>

                  <Text variant="body-1-medium">{formatMicroCentsToReal(item.amount)}</Text>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  )
}
