import type { AxiosInstance } from 'axios'

import {
  GetPaymentDisputesRequest,
  GetPaymentDisputesResponse,
  GetPaymentDisputeItemsRequest,
  GetPaymentDisputeItemsResponse,
  UpdatePaymentDisputeItemStatusRequest,
  UpdatePaymentDisputeItemStatusResponse,
  GetPaymentDisputeStatusSummaryResponse,
} from '@/shared/interfaces/paymentDispute'

export const paymentDisputeService = (fetcher: AxiosInstance) => ({
  /**
   * Fetches a list of payment disputes for a given school.
   *
   * @param schoolId - The ID of the school to fetch payment disputes for.
   * @param status - The status of the payment disputes to fetch.
   * @param pagination - An object containing pagination parameters.
   *
   * @returns A promise that resolves with an object containing the payment disputes and pagination metadata.
   */
  fetchPaymentDisputeListBySchool: async ({
    schoolId,
    status,
    pagination,
  }: GetPaymentDisputesRequest): Promise<GetPaymentDisputesResponse> => {
    const { data } = await fetcher.get<GetPaymentDisputesResponse>(`/payment-dispute`, {
      params: {
        school_id: schoolId,
        status,
        ...pagination,
      },
    })

    return data
  },
  /**
   * Fetches a list of payment dispute items for a given payment dispute.
   *
   * @param id - The ID of the payment dispute to fetch items for.
   *
   * @returns A promise that resolves with an object containing the payment dispute items.
   */
  getPaymentDisputeItems: async ({
    id,
  }: GetPaymentDisputeItemsRequest): Promise<GetPaymentDisputeItemsResponse> => {
    const { data } = await fetcher.get<GetPaymentDisputeItemsResponse>(
      `payment-dispute/${id}/items`
    )

    return data
  },
  /**
   * Updates the status of a payment dispute item.
   *
   * @param payload - An object containing the details of the payment dispute item to update,
   * including the item ID, payment dispute item ID, new status, and denial reason.
   *
   * @returns A promise that resolves with an object containing the updated payment dispute item ID.
   */
  updatePaymentDisputeItemStatus: async (
    payload: UpdatePaymentDisputeItemStatusRequest
  ): Promise<UpdatePaymentDisputeItemStatusResponse> => {
    const { data } = await fetcher.patch<UpdatePaymentDisputeItemStatusResponse>(
      `payment-dispute/update-item-status`,
      payload
    )

    return data
  },
  /**
   * Fetches the payment dispute status summary for a specific school.
   *
   * @param schoolId - The UUID of the school for which to retrieve the payment dispute status summary.
   *
   * @returns A promise that resolves with an object containing the payment dispute status summary response.
   */
  fetchPaymentDisputeStatusSummaryBySchool: async (
    schoolId: uuid
  ): Promise<GetPaymentDisputeStatusSummaryResponse> => {
    const { data } = await fetcher.get<GetPaymentDisputeStatusSummaryResponse>(
      `/payment-dispute/status-summary`,
      {
        params: {
          school_id: schoolId,
        },
      }
    )

    return data
  },
})
