import NumberFormat from 'react-number-format'
import { TextField } from '@gravity/text-field'

import type { TaxIdInputProps } from './types'

export const TaxIdInput = ({ form }: TaxIdInputProps) => {
  const { register, formState, getValues, setValue } = form
  const { errors } = formState

  return (
    <NumberFormat
      label="CPF:"
      format="###.###.###-##"
      type="tel"
      placeholder="___.___.___-__"
      value={getValues('tax_id')}
      onValueChange={async currentValue => {
        setValue('tax_id', currentValue.value)
      }}
      fullWidth
      customInput={TextField}
      size={3}
      error={!!errors?.tax_id?.message}
      errorMessage={errors?.tax_id?.message}
      {...register('tax_id')}
    />
  )
}
