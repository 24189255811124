import { useHistory } from 'react-router-dom'
import { DashedArrowLeft } from '@gravity/icons'
import { Button } from '@gravity/button'

type GoBackButtonProps = {
  aditionalOnClick?: () => void
}
export const GoBackButton = ({ aditionalOnClick = () => null }: GoBackButtonProps) => {
  const history = useHistory()
  return (
    <Button
      variant="ghost"
      size={1}
      onClick={() => {
        history.goBack()
        aditionalOnClick()
      }}
      iconStart={<DashedArrowLeft />}
    >
      Voltar
    </Button>
  )
}
