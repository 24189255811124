import { useState } from 'react'
import { Close, DownloadOutline } from '@gravity/icons'
import { Button } from '@gravity/button'
import { Drawer } from '@olaisaac/design-system'
import { Heading } from '@gravity/heading'
import { Separator } from '@gravity/separator'
import { IconButton } from '@gravity/icon-button'
import { Text } from '@gravity/text'

import { useSnackbar } from '@/shared/hooks'
import { useApiClient } from '@/shared/hooks/useApiClient'

import { downloadFile } from '@/shared/utils'

import {
  BankTransfer,
  FinancialDetails,
  PayoutVersion,
  TransactionEventType,
} from '@monorepo/report/models/report'

import { FetchAggregatedProvidersReportResponseDTO } from '@monorepo/report/services/report/types'
import { IsaacPayPayoutDrawerContent } from './components/IsaacPayPayoutDrawerContent'
import { IsaacPayExternalPaymentDrawerContent } from './components/IsaacPayExternalPaymentDrawerContent'
import { reportService } from '@monorepo/report/services/report'
import { StyledTooltip } from './styles'

type IsaacPaySummaryInfo = {
  isaacPay_end_date: Date
  payoutBankTransfers?: BankTransfer[]
  payoutId: string
  payoutVersion: PayoutVersion
}

type SummaryDrawerProps = {
  externalPaymentSummary?: FetchAggregatedProvidersReportResponseDTO['data']
  financialDetails?: FinancialDetails
  isButtonDisabled: boolean
  isMonthlyViewEnabled: boolean
  isOpen: boolean
  isOutsourcedProvider?: boolean
  isPayoutOpen: boolean
  onClose: () => void
  payoutData: IsaacPaySummaryInfo
  summaryPeriod?: 'mensal' | 'semanal'
}

/**
 * Component to display IsaacPay payment summary data
 *
 * @param props
 * @param props.isOpen Indicates if drawer should be opened
 * @param props.isPayoutOpen Represents payout status
 * @param props.onClose Callback function called on request close
 * @param props.payoutData Payout summary data
 * @param props.summaryPeriod IsaacPay summary period type
 */
export const IsaacPaySummaryDrawer = ({
  externalPaymentSummary,
  financialDetails,
  payoutData,
  summaryPeriod = 'semanal',
  isPayoutOpen,
  isMonthlyViewEnabled,
  isOpen,
  isOutsourcedProvider,
  isButtonDisabled,
  onClose,
}: SummaryDrawerProps) => {
  const { apiClient } = useApiClient()
  const { setMessage, setVariation, setIsOpen } = useSnackbar()

  const showDownloadButtonForIsaacPaySummary = !isButtonDisabled

  const [isLoadingAction, setIsLoadingAction] = useState(false)

  const handleDownloadReport = async (payoutId: string, filename: string) => {
    const service = reportService(apiClient.getClients().privateApi)

    try {
      setIsLoadingAction(true)
      await downloadFile(filename, 'pdf', () =>
        service.fetchPayoutReportFile({ version: 2, id: payoutId, extension: 'pdf' })
      )
    } catch {
      setMessage('Falha ao baixar arquivo. Tente novamente mais tarde.')
      setVariation('error')
      setIsOpen(true)
    } finally {
      setIsLoadingAction(false)
    }
  }

  const values = {
    ...financialDetails?.events,
    total_base_amount: financialDetails?.total_base_amount,
  }

  function getTotal(): number {
    if (!isMonthlyViewEnabled) {
      return financialDetails?.total - (values[TransactionEventType.BALANCE_TRANSFER] || 0)
    }

    return externalPaymentSummary?.total || 0
  }

  function getTotalFee(): number {
    if (!isMonthlyViewEnabled) {
      return -1 * financialDetails?.total_fee
    }

    return -1 * externalPaymentSummary?.total_fee
  }

  const bankTransfers = payoutData?.payoutBankTransfers || []

  return (
    <Drawer open={isOpen}>
      <div className="flex flex-col h-full">
        <div className="flex flex-col gap-6 pt-6 items-center" data-testid="drawer-header">
          <div className="flex px-6 w-full justify-end">
            <IconButton onClick={onClose} size={2} variant="ghost" aria-label="Fechar">
              <Close />
            </IconButton>
          </div>
          <div className="flex flex-col gap-6 px-6 w-full">
            <Heading variant="heading-h3-medium">Resumo</Heading>
            <Text variant="body-1-regular" className="text-gray-500">
              {summaryPeriod === 'mensal'
                ? 'Veja o resumo do seu período mensal.'
                : 'Veja o resumo do seu período semanal.'}
            </Text>
          </div>
          <Separator orientation="horizontal" color="neutral-2" />
        </div>

        <div className="flex-1 overflow-auto px-6 py-6">
          {isOutsourcedProvider ? (
            <IsaacPayExternalPaymentDrawerContent
              externalPaymentSummary={externalPaymentSummary}
              bankTransfers={bankTransfers}
            />
          ) : (
            <IsaacPayPayoutDrawerContent
              total={getTotal()}
              total_fee={getTotalFee()}
              bankTransfers={bankTransfers}
              values={values}
            />
          )}
        </div>

        {showDownloadButtonForIsaacPaySummary && (
          <div className="p-6">
            <StyledTooltip
              text={
                isPayoutOpen
                  ? 'Disponível apenas para repasses fechados'
                  : 'Baixar o arquivo .pdf do período'
              }
            >
              <Button
                fullWidth
                onClick={() => handleDownloadReport(payoutData.payoutId, 'resumo')}
                loading={isLoadingAction}
                disabled={isPayoutOpen}
                data-testid="download-button"
                iconStart={<DownloadOutline size="sm" />}
              >
                {summaryPeriod === 'mensal' ? 'Baixar resumo' : 'Baixar extrato'}
              </Button>
            </StyledTooltip>
          </div>
        )}
      </div>
    </Drawer>
  )
}
