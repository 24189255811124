import { formatCentsToReal } from 'src/shared/utils'

export const getNotification = (minAmount: cents, totalAmount: number): { text: string } => {
  const isOneLumpPayment = totalAmount === minAmount

  return {
    text: isOneLumpPayment
      ? 'Para esse acordo, o pagamento com boleto só pode ser à vista.'
      : `O valor da entrada deve ser no mínimo de ${formatCentsToReal(minAmount)}`,
  }
}
