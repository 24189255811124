import { RiLoader5Line } from '@remixicon/react'
import { Container } from './styles'

type CircularLoadingProps = {
  iconSize?: number
}

export const CircularLoading = ({ iconSize = 20 }: CircularLoadingProps) => (
  <Container data-testid="circular-loading" $size={iconSize}>
    <RiLoader5Line className="loader-icon" size={iconSize} />
  </Container>
)
