import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import { IconButton } from '@gravity/icon-button'
import { Tooltip } from '@gravity/tooltip'
import { Text } from '@gravity/text'

import { More2Outline } from '@gravity/icons'

import { MoreOptionsPopoverProps } from './types'
import * as Styled from './styles'

export const MoreOptionsPopover = ({ options }: MoreOptionsPopoverProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleOptionClick = (onClick: () => void) => {
    onClick()
    handlePopoverClose()
  }

  const open = Boolean(anchorEl)

  const renderIconButton = (hideTooltip: boolean, children: React.ReactNode) => {
    if (!hideTooltip)
      return (
        <Tooltip text="Outras opções" position="left">
          {children}
        </Tooltip>
      )
    return children
  }

  return (
    <Styled.Wrapper>
      {renderIconButton(
        open,
        <IconButton
          data-testid="more-options"
          aria-owns={open ? 'more-options-onclick-popover' : undefined}
          aria-haspopup="true"
          onClick={handlePopoverOpen}
          onMouseEnter={handlePopoverClose}
          variant="ghost"
        >
          <More2Outline size="xs" />
        </IconButton>
      )}
      <Styled.Container
        id="more-options-onclick-popover"
        open={open}
        onMouseLeave={handlePopoverClose}
      >
        {options.map(option => (
          <Styled.Content
            key={uuidv4()}
            data-testid={option.label}
            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              e.stopPropagation()
              handleOptionClick(option.onClick)
            }}
          >
            <Text variant="body-2-regular">{option.label}</Text>
          </Styled.Content>
        ))}
      </Styled.Container>
    </Styled.Wrapper>
  )
}
