import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useApiClient } from '@/shared/hooks/useApiClient'

import { reportService } from '@/shared/services/report'

import type {
  FetchPayoutDetailsRequestDTO,
  FetchPayoutDetailsResponseDTO,
} from '@/shared/services/report/types'

export const useIsaacpayPayoutReport = (
  { payoutId, filter, pagination }: FetchPayoutDetailsRequestDTO<'isaac-pay'>,
  options?: UseQueryOptions<FetchPayoutDetailsResponseDTO<'isaac-pay'>>
) => {
  const { apiClient } = useApiClient()

  const service = reportService(apiClient.getClients().privateApi)

  return useQuery<FetchPayoutDetailsResponseDTO<'isaac-pay'>>(
    ['payout', 'isaac-pay', payoutId, 'details', JSON.stringify({ ...filter, ...pagination })],
    async () => {
      return await service.fetchPayoutDetails('isaac-pay', {
        payoutId,
        filter,
        pagination,
      })
    },
    options
  )
}
