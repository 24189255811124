import styled from 'styled-components'

export const ContentContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
`
export const BottomContainer = styled.div`
  display: flex;
  flex-flow: column;
  padding: 24px;
  border-top: 1px solid rgb(218, 218, 218);
`

export const TotalValue = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const ItemSummary = styled.div`
  padding-bottom: 1;
  display: flex;
  justify-content: space-between;
`

export const SeparatorSummary = styled.div`
  margin-bottom: 20px;
`
