import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { Add, DashedArrowLeft, DashedArrowRight } from '@gravity/icons'
import { Button } from '@gravity/button'
import { useForm } from 'react-hook-form'

import type { FormProps } from '@monorepo/enrollment/pages/Campaigns/CampaignDetails/components/InstallmentForm/types'
import type { StepPageProps } from '@monorepo/enrollment/components/Stepper/types'

import { InstallmentForm } from '../../../CampaignDetails/components/InstallmentForm'
import { useAddBulkInstallmentStore } from '@monorepo/enrollment/store/AddBulkInstallmentStore'

import * as Styled from './styles'
import { Footer } from '@gravity/footer'
import { useParams } from 'react-router-dom'
import { URLParams } from '../../types'
import { useCampaignDetails } from '../../hooks/useCampaignDetails'

export const PaymentPlanStep = ({ onFinish, enrollmentCycle }: StepPageProps) => {
  const { campaignId } = useParams<URLParams>()
  const { campaignDetails } = useCampaignDetails(campaignId)

  const { setFormData, formData } = useAddBulkInstallmentStore()

  const form = useForm<FormProps>({
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: formData,
  })

  const handleSubmit = async (formData: FormProps) => {
    setFormData(formData)
    onFinish()
  }

  return (
    <Styled.Form onSubmit={form.handleSubmit(form => handleSubmit(form))}>
      <Heading variant="heading-h3-medium">Plano de pagamento</Heading>

      <Text variant="body-2-regular" className="mt-2">
        Produto: {campaignDetails.productName}
      </Text>

      <Styled.Content>
        <Text variant="body-1-regular">
          Defina o número de parcelas, seus valores e a data de vencimento. Alunos que já tiveram
          mensalidades adicionadas individualmente não terão seus valores atualizados.
        </Text>

        <Styled.FormWrapper>
          <InstallmentForm
            form={form}
            referenceYear={enrollmentCycle?.reference_year}
            renderDiscountBtn={(disabled, onClick) => (
              <Button
                className="mt-8"
                fullWidth
                disabled={disabled}
                variant="outline"
                iconEnd={<Add />}
                onClick={onClick}
                type="button"
              >
                Adicionar desconto
              </Button>
            )}
          />
        </Styled.FormWrapper>
      </Styled.Content>
      <Footer
        className="z-[99]"
        secondaryButton={{
          label: 'Anterior',
          iconStart: <DashedArrowLeft />,
          disabled: true,
        }}
        primaryButton={{
          type: 'submit',
          label: 'Próximo',
          iconEnd: <DashedArrowRight />,
          disabled: !form.formState.isValid,
        }}
      />
    </Styled.Form>
  )
}
