import { useState, useEffect } from 'react'
import { useForm, Controller, useController, FieldValues } from 'react-hook-form'
import { Button } from '@gravity/button'
import { Header } from '@gravity/header'
import { IconButton } from '@gravity/icon-button'
import { Close, Add } from '@gravity/icons'
import { useToast } from '@gravity/toast'

import { useLayout } from '@/shared/hooks/useLayout'
import IsaacLogo from '@/shared/assets/isaac-logo.svg'
import { Combobox } from '@gravity/combobox'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { useSendCommunicationMutation } from './hooks/useSendCommunicationMutation'
import { useJWT, useApiClient } from '@/shared/hooks'
import { messagesService } from '@monorepo/messages/services/messagesService'
import { Destinatario, Option, FormData } from './types'

export const SendMessagePage = () => {
  const { handleSubmit, control, setValue } = useForm()
  const [destinatarios, setDestinatarios] = useState<Destinatario[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [selectedDestinatariosCount, setSelectedDestinatariosCount] = useState(0)

  const { toast } = useToast()
  const { school } = useSelectedSchool()
  const { userId } = useJWT()

  useLayout({ enableSideMenu: false, enableHeader: false, headerTitle: 'Novo Comunicado' })
  const sendCommunicationMutation = useSendCommunicationMutation()

  const { apiClient } = useApiClient()
  const messagesServiceInstance = messagesService(apiClient.getClients().bffApi)

  useEffect(() => {
    if (searchValue !== undefined && searchValue !== null) {
      const fetchDestinatarios = async () => {
        const response = await messagesServiceInstance.searchRecipients({
          search: searchValue ?? '',
          entityId: '',
        })
        const fieldDestinatarios: Destinatario[] = response.result.data.map(e => ({ name: e.name }))
        setDestinatarios(fieldDestinatarios)
      }
      fetchDestinatarios()
    }
  }, [searchValue])

  const { field } = useController({
    name: 'destinatariosSelecionados',
    control: control,
    defaultValue: null,
  })

  const destinatarioToOption = (destinatario: Destinatario | null) => {
    if (!destinatario) return undefined
    return {
      label: destinatario.name,
      value: destinatario.name,
    }
  }

  const handleOptionChange = (selectedOption: Option | null) => {
    if (!selectedOption) {
      setValue('destinatariosSelecionados', [])
      return
    }

    const destinatarioItem =
      destinatarios.find(destinatario => destinatario.name === selectedOption.value) || null

    setValue('destinatariosSelecionados', [destinatarioItem])
    setSelectedDestinatariosCount(1)
  }

  const handleSearch = (searchValue: string) => {
    setSearchValue(searchValue)
  }

  const onSubmit = async (data: FieldValues) => {
    const formData: FormData = {
      destinatariosSelecionados: data.destinatariosSelecionados,
      mensagemDoComunicado: data.mensagemDoComunicado,
      tituloDoComunicado: data.tituloDoComunicado,
    }

    setLoading(true)
    try {
      sendCommunicationMutation.mutate({
        schoolId: school?.id ?? '',
        senderUserIdIsaac: userId,
        content: formData.mensagemDoComunicado,
        recipientStudentIds: [
          '4e4890d2-c11b-4e62-99b6-4c25cdcb5d18',
          'd0b34379-2678-488b-9fd7-43e612dd6f16',
          '41cc55d4-9889-4d2f-9033-afce6a5dfbac',
          '2dfbfed7-8587-4213-abb8-ecb50eab9bbc',
          'efd956a8-8038-42b5-99a6-e99a3ac8cd07',
          '9e68265e-93f3-4c71-a022-96903d046371',
          'a26e5ee8-ed29-4b44-9017-fbc83f0d493e',
          '8df35471-a49c-44bb-8ea1-c2d6d1b1cf91',
          '6cf2c7b0-df9a-481b-ac76-da8e6f58a8f4',
          'b5c3f0a4-90d6-47c1-b2c7-0946999ae724',
          'f88c2ea1-c9bf-482a-abf7-cf723e654903',
          '0cc5fce9-a7cb-45c9-96a1-ec32fe1a6654',
        ],
        recipientUserIdsIsaac: [],
        subject: formData.tituloDoComunicado,
      })

      toast({
        type: 'success',
        title: 'Comunicado enviado com sucesso',
      })
    } catch (_) {
      toast({
        type: 'error',
        title: 'Erro ao enviar comunicado',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex flex-col justify-center items-center">
      <Header.Root className="bg-background-neutral-1 border-3 border-border-neutral-1">
        <img src={IsaacLogo} alt="isaac" className="w-14" />
        <Header.Navigation>
          <span className="font-semibold text-xl">Novo comunicado</span>
        </Header.Navigation>
        <Header.Actions>
          <IconButton variant="ghost">
            <Close />
          </IconButton>
        </Header.Actions>
      </Header.Root>
      <div className="flex flex-col justify-center w-[1110px] mt-[10px]">
        <div className="font-medium text-base mt-4">
          Destinatários (
          {selectedDestinatariosCount > 0 ? `${selectedDestinatariosCount}` : 'Nenhum Selecionado'})
        </div>
        <div className="flex flex-wrap">
          {/* Adicionar ação do botão quando existir o fluxo de seleção de destinatários via modal */}
          <Button
            className="w-[233px] h-[40px] bg-colors-interaction-primary-5 hover:bg-colors-interaction-primary-6 mt-4"
            disabled
          >
            <Add className="w-4 text-colors-text-main-2" />
            <div className="font-medium ml-2 text-sm text-colors-text-main-2">
              Adicionar destinatários
            </div>
          </Button>
          <Controller
            name="destinatariosSelecionados"
            control={control}
            render={() => (
              <Combobox
                className="mt-4 ml-3 flex-grow"
                placeholder="Pesquisar destinatários"
                size={3}
                options={
                  searchValue !== ''
                    ? destinatarios
                        .filter(destinatario => destinatario.name.includes(searchValue))
                        .map(destinatario => ({
                          label: destinatario.name,
                          value: destinatario.name,
                        }))
                    : []
                }
                value={destinatarioToOption(field.value)}
                onChangeValue={handleOptionChange}
                onSearch={handleSearch}
                emptyPlaceholder="Nenhum destinatário encontrado"
                isLoadingOptions={false}
              />
            )}
          />
        </div>
        <Controller
          name="tituloDoComunicado"
          control={control}
          render={({ field }) => (
            <input
              placeholder="Digite aqui o título do comunicado..."
              className="w-full h-[64px] ml-1 outline-none mt-12 border-none focus:text-colors-text-main-2 placeholder:text-colors-text-main-4 font-bold text-colors-text-main-2 text-2xl"
              {...field}
            />
          )}
        />

        <Controller
          name="mensagemDoComunicado"
          control={control}
          render={({ field }) => (
            <textarea
              placeholder="Escreva sua mensagem aqui..."
              className="w-full pt-4 pl-1 font-sans h-[460px] resize-none mt-4 outline-none rounded-2 border-none align-top focus:text-colors-text-main-2 placeholder:text-colors-text-main-4 text-colors-text-main-2 text-base"
              {...field}
            />
          )}
        />
      </div>
      <Button size={1} className="mt-6" onClick={handleSubmit(onSubmit)} loading={loading}>
        Enviar
      </Button>
    </div>
  )
}
