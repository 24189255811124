import { InfoCardProps } from './types'
import { ColumnContainer, StyledCard } from './styles'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'

export const InfoCard = ({ icon, title, description }: InfoCardProps) => {
  return (
    <StyledCard>
      <ColumnContainer>
        {icon} <Heading variant="heading-h4-medium">{title}</Heading>
        <Text variant="body-1-regular">{description}</Text>
      </ColumnContainer>
    </StyledCard>
  )
}
