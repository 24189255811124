import { address1, guardian1, studentList } from '@/shared/mocks'
import { DebtStatus } from '@/shared/interfaces'
import { GetGuardianResponse } from '@/shared/services/guardian/types'

export const getGuardianMock = async (): Promise<GetGuardianResponse> => {
  const guardianStudents = studentList.map(student => ({
    name: student.name,
    id: student.id,
  }))
  const guardian = { ...guardian1 }

  return {
    data: {
      id: guardian.id,
      name: guardian.name,
      tax_id: guardian.tax_id,
      debt_status: DebtStatus.UP_TO_DATE,
      students: guardianStudents,
      address: address1,
      address_id: guardian.address_id,
      phone_number: guardian.phone_number,
      email: guardian.email,
      created_at: guardian.created_at,
      updated_at: guardian.updated_at,
    },
  }
}
