import { useCallback, useEffect, useMemo, useState } from 'react'

import { useQuery } from '@/shared/hooks/useQuery'

import { countSelectedFilters } from '@/shared/utils/countSelectedFilters'

import type {
  GuardiansInvoicesFilterObjectKeys,
  GuardiansInvoicesFilterQueryParams,
  GuardiansInvoicesFilterType,
} from './types'

export const useGuardianInvoicesFilter = () => {
  const { query, setMultipleQueryParams } = useQuery()

  const getFiltersFromUrl = (): GuardiansInvoicesFilterType => {
    const parseQueryParamArray = (paramName: GuardiansInvoicesFilterQueryParams) => {
      return query.get(paramName)?.split(',') ?? []
    }

    const data: Record<GuardiansInvoicesFilterObjectKeys, string[]> = {
      contractId: parseQueryParamArray('contract_id'),
      installmentStatuses: parseQueryParamArray('installment_statuses'),
      installmentTypes: parseQueryParamArray('installment_types'),
      negotiationId: parseQueryParamArray('negotiation_id'),
      productsArray: parseQueryParamArray('product_ids'),
      referenceYears: parseQueryParamArray('reference_years'),
      studentsArray: parseQueryParamArray('student_ids'),
    }

    return (data as unknown) as GuardiansInvoicesFilterType
  }
  const [
    guardiansInvoicesFilter,
    setGuardiansInvoicesFilter,
  ] = useState<GuardiansInvoicesFilterType>(() => getFiltersFromUrl())

  useEffect(() => {
    const checkIfHasChanges = (
      urlFilter: GuardiansInvoicesFilterType,
      stateFilter: GuardiansInvoicesFilterType
    ) => {
      let hasChanges = false

      Object.keys(stateFilter).every(key => {
        const stateValue = stateFilter[key as keyof typeof stateFilter]
        const urlValue = urlFilter[key as keyof typeof urlFilter]

        const stateValueString = Array.isArray(stateValue) ? stateValue.join(',') : stateValue
        const urlValueString = Array.isArray(urlValue) ? urlValue.join(',') : urlValue

        if (stateValueString !== urlValueString) {
          hasChanges = true
          return false
        }

        return true
      })
      return hasChanges
    }

    const urlGuardiansInvoicesFilter = getFiltersFromUrl()

    const hasChangesOnFilter = checkIfHasChanges(
      urlGuardiansInvoicesFilter,
      guardiansInvoicesFilter
    )

    hasChangesOnFilter && setGuardiansInvoicesFilter(urlGuardiansInvoicesFilter)
  }, [query])

  const updateFilter = useCallback(
    (
      updatedFilter: GuardiansInvoicesFilterType = {
        contractId: [],
        installmentStatuses: [],
        installmentTypes: [],
        negotiationId: [],
        productsArray: [],
        referenceYears: [],
        studentsArray: [],
      }
    ) => {
      const data: Array<{
        name: GuardiansInvoicesFilterQueryParams
        value: string
      }> = [
        {
          name: 'contract_id',
          value: updatedFilter.contractId?.toString() ?? '',
        },
        {
          name: 'installment_statuses',
          value: updatedFilter.installmentStatuses?.join(','),
        },
        {
          name: 'installment_types',
          value: updatedFilter.installmentTypes?.join(','),
        },
        {
          name: 'negotiation_id',
          value: updatedFilter.negotiationId?.toString() ?? '',
        },
        {
          name: 'product_ids',
          value: updatedFilter.productsArray?.join(','),
        },
        {
          name: 'reference_years',
          value: updatedFilter.referenceYears?.join(','),
        },
        {
          name: 'student_ids',
          value: updatedFilter.studentsArray?.join(','),
        },
      ]
      setMultipleQueryParams(data)
    },
    [setMultipleQueryParams]
  )

  const clearFilter = useCallback(() => {
    const data: Array<{
      name: GuardiansInvoicesFilterQueryParams
      value?: string
    }> = [
      { name: 'contract_id', value: '' },
      { name: 'installment_statuses', value: '' },
      { name: 'installment_types', value: '' },
      { name: 'negotiation_id', value: '' },
      { name: 'product_ids', value: '' },
      { name: 'reference_years', value: '' },
      { name: 'student_ids', value: '' },
    ]
    setMultipleQueryParams(data)
  }, [setMultipleQueryParams])

  const guardiansInvoicesFilterCount = useMemo(
    () => countSelectedFilters(guardiansInvoicesFilter),
    [guardiansInvoicesFilter]
  )

  return {
    clearFilter,
    guardiansInvoicesFilter,
    guardiansInvoicesFilterCount,
    updateFilter,
  }
}
