import dayjs from 'dayjs'
import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useCampaignContext } from '@monorepo/enrollment/contexts/CampaignContext'
import { useManageOfferConditions } from './useManageOfferConditions'
import { EnrollmentCampaignFormProps } from '../types'
import { StepPageProps } from '@monorepo/enrollment/components/Stepper/types'
import { useCampaignNameCheckQuery } from '@monorepo/enrollment/pages/Campaigns/NewCampaign/components/PaymentConditionsStep/hooks/useCampaignNameCheckQuery'
import { useSelectedYear } from '@monorepo/enrollment/pages/Campaigns/CampaignsList/hooks/useSelectedYear'
import { useEnrollmentMixpanelEvents } from '@monorepo/enrollment/hooks/useEnrollmentMixpanelEvents'

export const usePaymentConditionsStep = ({ onFinish, enrollmentCycle }: StepPageProps) => {
  const { setConditions, conditions } = useCampaignContext()
  const [isSubmitButtonDisabled, setDisableSubmitButton] = useState(false)
  const tomorrowDate = dayjs().add(1, 'day').toDate()
  const lastDayOfYearDate = dayjs(`${enrollmentCycle.reference_year}-12-01`).endOf('month').toDate()

  const { selectedEnrollmentCycle } = useSelectedYear(enrollmentCycle.reference_year.toString())
  const [campaignName, setCampaignName] = useState<string>('')
  const [productId, setProductId] = useState<string>('')
  const { sendPageViewEvent, sendButtonClickEvent } = useEnrollmentMixpanelEvents()

  const {
    setError,
    clearErrors,
    formState: { errors },
  } = useForm()
  const { data, refetch } = useCampaignNameCheckQuery(
    {
      productId,
      name: campaignName,
      schoolEnrollmentCycleId: selectedEnrollmentCycle?.school_enrollment_cycle_id ?? '',
      schoolId: selectedEnrollmentCycle?.school_id ?? '',
    },
    {
      // This query is only used for validation before enabling the user to proceed to the next step
      enabled: false,
    }
  )

  const form = useForm<EnrollmentCampaignFormProps>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: conditions ?? {
      name: '',
      product: null,
      advancePaymentInstallment: '',
      end_date: null,
      offers: [],
    },
  })

  const {
    isAddOfferBtnDisabled,
    addNewOfferInput,
    offerFields,
    isRemoveButtonDisabled,
    handleRemoveOfferClick,
  } = useManageOfferConditions(form)

  form.watch(['advancePaymentInstallment', 'product', 'end_date', 'name', 'offers'])
  const { advancePaymentInstallment, product, end_date, name, offers } = form.getValues()

  const validateFieldsSubmit = (data: EnrollmentCampaignFormProps) => {
    if (data.advancePaymentInstallment === 'Não') data.offers = []
    return data
  }

  const handleSubmit = async (formData: EnrollmentCampaignFormProps) => {
    formData = validateFieldsSubmit(formData)
    setCampaignName(formData?.name)
    setProductId(formData?.product?.id ?? '')
    const result = (await refetch())?.data?.data?.is_available
    if (!result)
      return setError('name', {
        type: 'custom',
        message: 'campaign identification in use',
      })
    clearErrors('name')
    sendButtonClickEvent('continuar_condicoes')
    setConditions(formData)
    onFinish()
  }

  const disableSubmitButtonVerification = () => {
    if (!end_date || advancePaymentInstallment === '' || name === '') return true
    if (advancePaymentInstallment === 'Sim') return offers.some(offer => !offer.amount)
    return false
  }

  useEffect(() => {
    setDisableSubmitButton(disableSubmitButtonVerification())
  }, [end_date, advancePaymentInstallment, name, JSON.stringify(offers), data?.data?.is_available])

  useEffect(() => {
    if (errors?.name?.message === 'campaign identification in use') clearErrors('name')
  }, [name, product])

  useEffect(() => {
    sendPageViewEvent('CONDICOES_CAMPANHA')
  }, [])

  return {
    form,
    tomorrowDate,
    lastDayOfYearDate,
    isSubmitButtonDisabled,
    handleSubmit,
    isAddOfferBtnDisabled,
    addNewOfferInput,
    offerFields,
    isRemoveButtonDisabled,
    handleRemoveOfferClick,
    advancePaymentInstallment,
    product,
    offers,
    errors,
  }
}
