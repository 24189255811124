import { AxiosInstance } from 'axios'
import qs from 'qs'
import {
  UseGuardianContractsQueryProps,
  UseGuardianContractsQueryResponse,
  UseGuardianFiltersOptionsQueryProps,
  UseGuardiansQueryProps,
  UseGuardiansQueryResponse,
  UseInstallmentsQueryProps,
  UseInstallmentsQueryResponse,
  UseNegotiationInstallmentQueryProps,
  UseInstallmentsByUrnQueryProps,
  UseInstallmentsByUrnQueryResponse,
  UseGuardianFinanceSummaryQueryProps,
  UseGuardianFinanceSummaryQueryResponse,
  GuardianHistoryQueryProps,
  GuardianHistoryQueryResponse,
  GuardianHistoryNegotiationQueryResponse,
  GuardianNegotiationQueryResponse,
} from './types'

export const guardianService = (fetcher: AxiosInstance) => {
  return {
    /**
     * Function responsible for fetch Guardians List
     *
     * @returns A list of guardians (paginated)
     */
    fetchGuardiansList: async (
      params: UseGuardiansQueryProps
    ): Promise<UseGuardiansQueryResponse> => {
      const { data } = await fetcher.get<UseGuardiansQueryResponse>(`/guardians`, {
        params: { ...params, school_id: params.schoolId },
        paramsSerializer: p => qs.stringify(p, { arrayFormat: 'comma', skipNulls: true }),
      })

      return data
    },

    /**
     * Function responsible for fetch all guardian's contracts data
     *
     * @param data.guardianId Guardian ID
     * @param data.filter Optional filters (query params)
     * @param data.schoolId School ID
     *
     * @returns All guardians's contracts
     */
    fetchGuardianContracts: async ({
      guardianId,
      filter,
      schoolId,
    }: UseGuardianContractsQueryProps): Promise<UseGuardianContractsQueryResponse> => {
      const { data } = await fetcher.get<UseGuardianContractsQueryResponse>(`/contract`, {
        params: {
          ...filter,
          guardian_id: guardianId,
          school_id: schoolId,
        },
        paramsSerializer: p => qs.stringify(p, { arrayFormat: 'comma', skipNulls: true }),
      })

      return data
    },

    /**
     * Function responsible for fetch all installments of guardians's contracts by urn list
     *
     * @param data.guardianId Guardian ID
     * @param data.urns Installment urn list
     *
     * @returns Installments of guardian's contracts
     */
    fetchInstallmentsByUrn: async ({
      urns,
      guardianId,
    }: UseInstallmentsByUrnQueryProps): Promise<UseInstallmentsByUrnQueryResponse> => {
      const { data } = await fetcher.get<UseInstallmentsByUrnQueryResponse>(`/installment/urns`, {
        params: {
          guardian_id: guardianId,
        },
        paramsSerializer: p => qs.stringify(p, { arrayFormat: 'comma', skipNulls: true }),
      })

      return {
        ...data,
        data: data.data.filter(i => urns.includes(i.urn)).filter(Boolean),
      }
    },
    /**
     * Function responsible for fetch all installments of guardians's contracts
     *
     * @param data.guardianId Guardian ID
     * @param data.filter Optional filters (query params)
     *
     * @returns Installments of guardian's contracts
     */
    fetchInstallments: async ({
      schoolId,
      guardianId,
      filter,
    }: UseInstallmentsQueryProps): Promise<UseInstallmentsQueryResponse> => {
      const { data } = await fetcher.get<UseInstallmentsQueryResponse>(`/installment`, {
        params: {
          ...filter,
          guardian_id: guardianId,
          school_id: schoolId,
        },
        paramsSerializer: p => qs.stringify(p, { arrayFormat: 'comma', skipNulls: true }),
      })

      return data
    },
    /**
     * Function responsible for fetch all open installments from current month, if there no open installments it return all paid installments
     *
     * @param data.guardianId Guardian ID
     * @param data.schoolId School ID
     *
     * @returns Installments of guardian's contracts
     * */

    fetchGuardianFinanceSummary: async ({
      guardianId,
      schoolId,
    }: UseGuardianFinanceSummaryQueryProps): Promise<UseGuardianFinanceSummaryQueryResponse> => {
      const { data } = await fetcher.get<UseGuardianFinanceSummaryQueryResponse>(
        `/installment/guardian-finance-summary`,
        {
          params: {
            guardian_id: guardianId,
            school_id: schoolId,
          },
          paramsSerializer: p => qs.stringify(p, { arrayFormat: 'comma', skipNulls: true }),
        }
      )
      return data
    },
    /**
     * Function responsible for fetch all guardian's filters
     * @param data.guardianId Guardian ID
     * @param data.schoolId School ID
     * @returns Guardian's filters e.g { debt_status: [] }
     */
    fetchGuardianFiltersOptions: async ({
      guardianId,
      schoolId,
    }: UseGuardianFiltersOptionsQueryProps) => {
      const { data } = await fetcher.get(`/contract/filters`, {
        params: { guardian_id: guardianId, school_id: schoolId },
      })

      return data
    },
    /**
     * Function responsible for fetch a single negotiation installment
     * @param urn Installment urn
     * @returns A negotiation installment
     */
    fetchGuardianNegotiationInstallments: async ({
      urn,
      schoolId,
      guardianId,
    }: UseNegotiationInstallmentQueryProps) => {
      const { data } = await fetcher.get(`/installment/${urn}/guardian-negotiation-drawer`, {
        params: { school_id: schoolId, guardian_id: guardianId },
      })
      return data.data
    },
    /**
     * Function responsible for fetch a single installment
     * @param urn Installment urn
     * @returns A installment drawer info
     */
    fetchGuardianInstallmentDrawer: async ({
      urn,
      schoolId,
      guardianId,
    }: UseNegotiationInstallmentQueryProps) => {
      const { data } = await fetcher.get(`/installment/${urn}/guardian-installment-drawer`, {
        params: { school_id: schoolId, guardian_id: guardianId },
      })
      return data.data
    },
    /**
     * Function responsible for fetching a negotiation from a guardian
     * @param data.id Negotiation ID
     * @returns Negotiation
     */
    fetchGuardianNegotiation: async (id: uuid): Promise<GuardianNegotiationQueryResponse> => {
      const { data } = await fetcher.get(`/negotiation/${id}`)
      return data
    },
    /**
     * Function responsible for fetching a negotiation details from a guardian
     * @param data.id Negotiation ID
     * @returns NegotiationDetails
     */
    fetchGuardianNegotiationDetails: async (
      id: uuid
    ): Promise<GuardianHistoryNegotiationQueryResponse> => {
      const { data } = await fetcher.get(`/negotiation/${id}/details`)
      return data.data
    },
    /**
     * Function responsible for fetching the negotiations' history for a guardian
     * @param data.guardianId Guardian ID
     * @param data.schoolId School ID
     * @param data.period `YYYY-MM` formatted date
     * @returns Negotiations' history
     */
    fetchGuardianNegotiationsHistory: async ({
      guardianId,
      schoolId,
      period,
    }: GuardianHistoryQueryProps): Promise<GuardianHistoryQueryResponse> => {
      const { data } = await fetcher.get(`/negotiation`, {
        params: { guardian_id: guardianId, school_id: schoolId, period },
      })
      return data.data
    },
  }
}
