import { Link } from 'react-router-dom-v5-compat'
import styled from 'styled-components'

const SIDEBAR_MENU_LEFT_BAR_WIDTH = '2px'

export const MenuItem = styled(Link)<{ $isActive: boolean }>(props => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `0 ${props.theme.gravity.spacing[2]}`,
  position: 'relative',
  textDecoration: 'none',

  span: {
    color: props.$isActive ? props.theme.gravity.colors['colors-text-main-1'] : '',
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: `${SIDEBAR_MENU_LEFT_BAR_WIDTH}`,

    backgroundColor: props.$isActive
      ? props.theme.gravity.colors['colors-background-accent-1']
      : props.theme.gravity.colors['colors-border-neutral-2'],
    transition: 'background-color 0.3s',
  },
}))
