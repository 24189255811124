import { useEffect } from 'react'
import { LayoutTemplate } from '../../LayoutTemplate'
import { Button } from '@gravity/button'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useFetchOnboardingBatches } from '../../hooks/useFetchOnboardingBatches'
import { useHistory } from 'react-router-dom'
import { useToast } from '@gravity/toast'
import { useUploadOnboardingFileMutation } from '../../hooks/useUploadOnboardingFileMutation'
import { LoadingPage } from '../Loading'
import { GenericError } from '../components/GenericError'
import { EventProvider, useEvents } from '../../hooks/eventContext'
import { FileUpload, useFileUpload } from '@gravity/file-upload'
import { Upload2Outline } from '@gravity/icons'
import { Text } from '@gravity/text'

const PageComponent = () => {
  const { school, schoolSlug } = useSelectedSchool()
  const schoolId = school?.id ?? ''
  const { toast } = useToast()

  const fileUpload = useFileUpload({
    maxFiles: 1,
    accept: '.xlsx',
    onFileReject: (_, error) => {
      if (error === 'maxFiles') {
        return toast({
          title: 'Selecione apenas um arquivo',
          type: 'alert',
        })
      }
    },
  })

  const file = fileUpload.acceptedFiles?.[0]?.file ?? null

  const history = useHistory()
  const events = useEvents()

  const {
    mutateAsync: executeUploadOnboardingFileMutation,
    isLoading: isLoadingUpload,
  } = useUploadOnboardingFileMutation({
    onError: () => {
      toast({
        title: 'Erro ao tentar importar planilha',
        type: 'error',
      })
    },
  })

  const { isLoading, isError } = useFetchOnboardingBatches(
    { schoolId: school?.id ?? '' },
    {
      onSuccess(data) {
        if (data.length === 0) return

        const onboardingBatch = data[0]
        if (onboardingBatch.status === 'PROCESSING')
          return history.push(`/${schoolSlug}/migrar-dados/${onboardingBatch.id}/migrar`)

        history.push(`/${schoolSlug}/migrar-dados/${onboardingBatch.id}/validar`)
      },
      onError() {
        toast({
          title: 'Erro ao tentar recuperar lote em andamento',
          type: 'error',
        })
      },
      retry: false,
    }
  )

  const handleFileSubmit = async () => {
    try {
      if (!file || !schoolId) return

      events?.startUploadButtonClicked()

      const onboardingBatch = await executeUploadOnboardingFileMutation({
        file,
        schoolId,
      })

      return history.push(`/${schoolSlug}/migrar-dados/${onboardingBatch.id}/pre-visualizar`)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    events?.uploadPageViewed()
  }, [])

  if (isLoading) {
    return <LoadingPage />
  }

  if (isError) {
    return <GenericError />
  }

  return (
    <LayoutTemplate
      title="Importar planilha de contratos"
      description="Ao importar a sua planilha, a Plataforma isaac vai identificar dados cadastrais de alunos e responsáveis, informações de plano de pagamento de produtos e serviços."
      footer={
        <>
          <Button disabled={!file} onClick={handleFileSubmit} loading={isLoadingUpload}>
            Começar
          </Button>
        </>
      }
    >
      <div className="w-full flex flex-col items-center gap-6">
        <div className="w-full flex flex-col items-center gap-2">
          <Text variant="body-1-regular" className="text-colors-text-main-2">
            Escolha uma planilha para migrar
          </Text>
          <Text variant="caption-regular" className="text-colors-text-main-2 mt-2">
            Formatos de documentos permitidos: XLSX
          </Text>
        </div>

        <FileUpload.RootProvider value={fileUpload}>
          <FileUpload.Trigger iconStart={<Upload2Outline />} variant="outline">
            Selecionar arquivo
          </FileUpload.Trigger>
          <FileUpload.List />
        </FileUpload.RootProvider>
      </div>
    </LayoutTemplate>
  )
}

export const UploadPage = () => {
  return (
    <EventProvider>
      <PageComponent />
    </EventProvider>
  )
}
