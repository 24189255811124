import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { Badge, BadgeProps } from '@gravity/badge'

type BigNumberProps = {
  badgeText: string
  headingText: React.ReactNode
  subtitleText?: React.ReactNode
} & Pick<BadgeProps, 'variant' | 'color'>

export const BigNumber = ({
  badgeText,
  headingText,
  subtitleText,
  variant,
  color,
}: BigNumberProps) => {
  return (
    <div className="flex flex-col gap-2">
      <div>
        <Badge variant={variant} color={color}>
          {badgeText}
        </Badge>
      </div>
      <Heading variant="heading-h2-medium">
        <span>{headingText}</span>
        {subtitleText && <Text variant="subtitle-regular"> {subtitleText}</Text>}
      </Heading>
    </div>
  )
}
