import { useEffect, useRef, useState } from 'react'
import {
  ColumnContainer,
  CommentText,
  ContainerCard,
  FeesContainer,
  KeyText,
  RowContainer,
  SkeletonsContainer,
  Wrapper,
} from './styles'
import { CreditSummaryProps } from './types'
import { Option, Select } from '@gravity/select'
import { Skeleton } from '@gravity/skeleton'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import useMixpanelEventSender from '../../utils/useMixpanelEventSender'
import { MixpanelEventActions } from '../../constants/MixpanelEventActions.enum'
import { SeparatorLine } from '../SeparatorLine'
import { Callout } from '@gravity/callout'
import { CreditType } from '@/shared/services/credit/types'
import { useReasonForChosenPolicy } from '../../hooks/useReasonForChosenPolicy'
import { CreditEventComponentNames } from '../../constants/CreditEventComponentNames.enum'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { formattingFee } from '@monorepo/credit/utils/formatFee'
import { QuestionOutline } from '@gravity/icons'
import { Button } from '@gravity/button'

const CreditSummaryCard = ({
  creditValue,
  offers,
  chosenOffer,
  setChosenOffer,
  reasonForChosenPolicyType,
}: CreditSummaryProps) => {
  const { getCalloutMessage } = useReasonForChosenPolicy()
  /**
   * //TODO: remove condition and filter after handing in the cpcr
   */

  const dropdownOptions: Option[] = offers.map(offer => ({
    label: `${
      offer.totalInstallments === 1 ? '1 parcela' : `${offer.totalInstallments} parcelas`
    } de ${offer.installmentValue}`,
    value: offer.totalInstallments.toString(),
  }))

  const { sendCreditEventToMixpanel } = useMixpanelEventSender()

  const getIndexInitializeOption = () => {
    const primaryIndexCP = offers.findIndex(e => e.category === CreditType.CP)
    const optionSelected = dropdownOptions[primaryIndexCP > 0 ? primaryIndexCP : 0]
    return optionSelected
  }

  const [installment, setInstallment] = useState<Option>(getIndexInitializeOption())
  const [showSkeleton, setShowSkeleton] = useState(false)
  const isCategoryCPCR = useRef(false)

  useEffect(() => {
    setShowSkeleton(true)
    setTimeout(() => {
      setShowSkeleton(false)
    }, 1000)
    const offer = offers.find(e => e.totalInstallments.toString() === installment.value)
    const isOfferCPCR = offer?.category === CreditType.CPCR
    if (offer) {
      setChosenOffer(offer)
    }
    isCategoryCPCR.current = isOfferCPCR
  }, [installment])

  const handleDropdownValueChange = (value: string) => {
    sendCreditEventToMixpanel(EventDispatcherEvents.BUTTON_CLICKED, MixpanelEventActions.CLICK, {
      $button_name: CreditEventComponentNames.NUMBER_OF_INSTALLMENTS,
    })

    const installmentSelected = dropdownOptions.find(e => e?.value === value)
    if (installmentSelected) {
      setInstallment(installmentSelected)
    }
  }
  const onHelpClick = () => {
    window.open('https://centraldeajuda.olaisaac.io', '_blank')
  }
  const enabledCallout = isCategoryCPCR.current

  return (
    <ContainerCard>
      <Wrapper>
        <div>
          <KeyText variant="subtitle-medium">Valor simulado</KeyText>
          <Heading variant="heading-h4-medium">{creditValue}</Heading>
        </div>

        <SeparatorLine />

        <div>
          <KeyText variant="subtitle-medium">Número de parcelas</KeyText>

          <Select
            size={3}
            variant="surface"
            options={dropdownOptions}
            value={installment.value}
            onValueChange={value => handleDropdownValueChange(value)}
          />
        </div>

        {enabledCallout && (
          <Callout
            text={getCalloutMessage(reasonForChosenPolicyType)}
            href="https://centraldeajuda.olaisaac.io/credito-isaac/entenda-as-condicoes-especiais-do-credito-atrelado-a-renovacao-"
            color="gray"
          />
        )}

        <SeparatorLine />

        <RowContainer>
          <ColumnContainer>
            <KeyText variant="subtitle-medium">Forma de pagamento</KeyText>
            <Text variant="body-1-regular">Desconto no repasse</Text>
          </ColumnContainer>

          <ColumnContainer>
            <KeyText variant="subtitle-medium">Comece a pagar em</KeyText>
            <Text variant="body-1-regular">{chosenOffer.firstDiscountDate}</Text>
          </ColumnContainer>
        </RowContainer>

        <SeparatorLine />

        <RowContainer>
          {showSkeleton ? (
            <SkeletonsContainer>
              <Skeleton radius={8} fullWidth />
              <Skeleton radius={8} fullWidth />
            </SkeletonsContainer>
          ) : (
            <FeesContainer>
              <KeyText variant="subtitle-medium">Taxa de juros mensal</KeyText>
              <Text variant="body-1-regular">
                {formattingFee.monthlyFee(chosenOffer.monthlyFee)}
              </Text>
            </FeesContainer>
          )}

          {showSkeleton ? (
            <SkeletonsContainer>
              <Skeleton radius={8} fullWidth />
              <Skeleton radius={8} fullWidth />
            </SkeletonsContainer>
          ) : (
            <FeesContainer>
              <KeyText variant="subtitle-medium">Juros e IOF anuais (CET)</KeyText>
              <Text variant="body-1-regular">{formattingFee.annualFee(chosenOffer.annualFee)}</Text>
            </FeesContainer>
          )}
        </RowContainer>

        <SeparatorLine />

        {showSkeleton ? (
          <SkeletonsContainer>
            <Skeleton radius={8} fullWidth />
            <Skeleton radius={8} fullWidth />
          </SkeletonsContainer>
        ) : (
          <div>
            <KeyText variant="subtitle-medium">Total a pagar</KeyText>
            <Text variant="body-1-regular">{chosenOffer.totalAmount}</Text>
          </div>
        )}
        <div className="flex justify-between items-center">
          <CommentText className="whitespace-pre-line" variant="caption-medium">
            {`O valor da parcela acima é aproximado e pode sofrer\nalterações pela data de desembolso.Condições da oferta\nsimulada válidas para a data de hoje.`}
          </CommentText>
          <Button variant="ghost" size={1} iconStart={<QuestionOutline />} onClick={onHelpClick}>
            Ajuda
          </Button>
        </div>
      </Wrapper>
    </ContainerCard>
  )
}

export default CreditSummaryCard
