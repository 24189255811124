export enum EventIdentifierName {
  CLEAR_STORAGE = 'limpar_historico',
  CLICK_ON_FILTER = 'clique_no_filtro',
  CLICK_ON_RECENT_SEARCH = 'clique_na_busca_recente',
  CLICK_ON_SEARCH = 'clique_na_busca',
  CLICK_ON_SEARCH_RESULT = 'clique_no_resultado',
  OPEN_BANNER_LINK = 'abrir_link_do_banner',
  OPEN_REPORT_PAGE = 'abrir_pagina_de_repasse',
  QUICK_ACCESS = 'abrir_pagina_através_do_acesso_rapido',
  REMOVE_RECENT_SEARCH = 'apagar_busca_recente',
}
