import { useFetchChargesQuery } from '@monorepo/onboarding/hooks/useFetchChargesQuery'
import { usePagination } from '@/shared/hooks/usePagination'
import { Accordion } from '@gravity/accordion'
import { Heading } from '@gravity/heading'
import { GraduationCapOutline, UserOutline } from '@gravity/icons'
import { useParams } from 'react-router-dom'
import { ChargeTable } from './ChargeTable'
import { Product } from '@monorepo/onboarding/services/types/onboardingProduct'
import { GenericError } from '../GenericError'
import { NoInvalidData } from '../NoInvalidData'
import { Loading } from './Loading'
import { PaginationFooter } from '../PaginationFooter'
import { Table } from '@gravity/table'
import { useEvents } from '@monorepo/onboarding/hooks/eventContext'
import { useState, useEffect } from 'react'
import { EditChargeDialog } from './EditChargeDialog'
import { Charge } from '@monorepo/onboarding/services/types/onboardingCharge'

const DEFAULT_ITEMS_PER_PAGE = 10

const composeFullProductName = (product: Product) => {
  const { reference_year, course, grade } = product

  return [reference_year, course, grade].join(' - ')
}

export const ContractCharges = () => {
  const { batchID } = useParams<{ batchID: string }>()
  const { pagination, updatePaginationValue } = usePagination(1, DEFAULT_ITEMS_PER_PAGE)
  const [selectedCharge, setSelectedCharge] = useState<Charge | null>(null)
  const events = useEvents()

  useEffect(() => {
    events?.contractChargesTabViewed()
  }, [])

  const params = {
    batchID,
    pagination: {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
    },
  }

  const { data, isError, isLoading } = useFetchChargesQuery(params)

  const contracts = data?.data

  if (isError) return <GenericError />

  if (isLoading || !contracts) {
    return <Loading />
  }

  if (contracts.length === 0) return <NoInvalidData />

  return (
    <>
      <div className="flex flex-col gap-6">
        {contracts?.map(contract => (
          <Accordion.Root type="single" key={contract.id} collapsible>
            <Accordion.Item value={contract.id}>
              <Accordion.Trigger
                descriptionList={[
                  {
                    iconStart: (
                      // Accordion overrides styles and pass size="16px", which is incompatible with @gravity/icons
                      <span>
                        <UserOutline size="xs" />
                      </span>
                    ),
                    text: `Responsável financeiro: ${contract?.guardian?.name}`,
                  },
                  {
                    iconStart: (
                      <span>
                        <GraduationCapOutline size="xs" />
                      </span>
                    ),
                    text: `Aluno: ${contract?.student?.name}`,
                  },
                ]}
              >
                <Heading variant="heading-h4-medium" style={{ textTransform: 'capitalize' }}>
                  {composeFullProductName(contract.product)}
                </Heading>
              </Accordion.Trigger>
              <Accordion.Content>
                <ChargeTable
                  charges={contract.charges}
                  setSelectedCharge={(charge: Charge | null) => setSelectedCharge(charge)}
                />
              </Accordion.Content>
            </Accordion.Item>
          </Accordion.Root>
        ))}

        <Table.Root>
          <PaginationFooter
            pagination={pagination}
            total={data?.pagination?.total ?? 0}
            updatePaginationValue={updatePaginationValue}
          />
        </Table.Root>
      </div>

      {selectedCharge && (
        <EditChargeDialog
          selectedCharge={selectedCharge}
          setSelectedCharge={(charge: Charge | null) => setSelectedCharge(charge)}
          params={params}
        />
      )}
    </>
  )
}
