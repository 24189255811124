import { useHistory } from 'react-router-dom'
import { Popover } from '@gravity/popover'
import { FileList3Outline, LogoutBoxOutline, TeamOutline } from '@gravity/icons'

import { useApi } from '@/utils/hooks/useApi'
import { useEventDispatcher } from '@/shared/hooks/useEventDispatcher'
import { useJWT } from '@/shared/hooks/useJWT'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { client } from '@/shared/integrations/sorting-hat'

import { shouldShowUserManagementMenu } from '@/modules/app/components/Header/components/UserMenu'

import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventIdentifierName } from '@/shared/models/enums/EventIdentifierName.enum'
import { PRIVACY_POLICY_URL, TERMS_FILE_URL } from '../constants'

const DROPDOWN_ELEMENTS = [
  {
    id: 'access-management',
    Component: (props: { onClick: () => void }) => (
      <>
        <Popover.Divider />
        <Popover.Item icon={<TeamOutline />} {...props}>
          Gestão de usuários
        </Popover.Item>
      </>
    ),
  },
  {
    id: 'terms',
    Component: (props: { onClick: () => void }) => (
      <Popover.Item icon={<FileList3Outline />} {...props}>
        Termos de uso
      </Popover.Item>
    ),
  },
  {
    id: 'privacy_policy',
    Component: (props: { onClick: () => void }) => (
      <Popover.Item icon={<FileList3Outline />} {...props}>
        Política de Privacidade
      </Popover.Item>
    ),
  },
  {
    id: 'logout',
    Component: (props: { onClick: () => void }) => (
      <>
        <Popover.Divider />
        <Popover.Item icon={<LogoutBoxOutline />} {...props}>
          Sair
        </Popover.Item>
      </>
    ),
  },
] as const

type Action = typeof DROPDOWN_ELEMENTS[number]['id']

type AvatarDropdownOptionsProps = {
  onSelectOption: VoidFunction
}

export const AvatarDropdownOptions = ({ onSelectOption }: AvatarDropdownOptionsProps) => {
  /**
   * TODO: Use apiClient
   */
  const { api } = useApi()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { getUserGroupNameBySchool, isBackofficeUser } = useJWT()
  const { school } = useSelectedSchool()
  const { push } = useHistory()

  const sendEvent = (actionId: Action) => {
    const eventIdentifierNames: Record<Action, EventIdentifierName> = {
      logout: EventIdentifierName.DROPDOWN_LOGOUT,
      privacy_policy: EventIdentifierName.DROPDOWN_PRIVACY_POLICY,
      terms: EventIdentifierName.DROPDOWN_TERMS,
      'access-management': EventIdentifierName.DROPDOWN_ACCESS_MANAGEMENT,
    }

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.HEADER_MENU,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        identifierName: eventIdentifierNames[actionId],
      })
    }
  }

  const handleAction = async (actionId: Action) => {
    sendEvent(actionId)
    onSelectOption()

    if (actionId === 'logout') {
      try {
        await api.auth.logout()
        client.logout()
        return
      } catch (err) {
        client.logout()
        return
      }
    }

    if (actionId === 'access-management') {
      push(`/${school?.slug}/acessos`)
      return
    }

    const ACTION_URLS = {
      privacy_policy: PRIVACY_POLICY_URL,
      terms: TERMS_FILE_URL,
    }

    window.open(ACTION_URLS[actionId], '_blank')
  }

  const dropdownOptions = (() => {
    const displayAccessManagement = shouldShowUserManagementMenu(
      isBackofficeUser,
      school?.is_staged,
      getUserGroupNameBySchool(school?.id ?? '')
    )

    if (displayAccessManagement) {
      return DROPDOWN_ELEMENTS
    }

    return DROPDOWN_ELEMENTS.filter(item => item.id !== 'access-management')
  })()

  return (
    <>
      {dropdownOptions.map(({ Component: Option, id }) => (
        <Option key={id} onClick={async () => await handleAction(id)} />
      ))}
    </>
  )
}
