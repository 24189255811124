import { useContextSimulation } from '@monorepo/credit/contexts/PayoutAnticipationContext'
import { ContainerCard, GrayText, ResultAnticipationContainer, RowContainer } from './styles'
import { AnticipationSummaryProps } from './types'
import { Separator } from '@gravity/separator'
import { Text } from '@gravity/text'
import { dateToFormattedString } from '@monorepo/credit/utils/formatDate'
import { Heading } from '@gravity/heading'
import { formattingFee } from '@monorepo/credit/utils/formatFee'

const PayoutAnticipationSummaryCard = ({ requestValue, offer }: AnticipationSummaryProps) => {
  const { reason } = useContextSimulation()

  return (
    <ContainerCard>
      <RowContainer>
        <GrayText variant="subtitle-medium">Motivo selecionado</GrayText>
        <Text variant="body-1-regular">{reason.label}</Text>
      </RowContainer>

      <RowContainer>
        <GrayText variant="subtitle-medium">Valor simulado</GrayText>
        <Text variant="body-1-regular">{requestValue}</Text>
      </RowContainer>

      <Separator color="neutral-2" orientation="horizontal" />
      <ResultAnticipationContainer>
        <RowContainer>
          <GrayText variant="subtitle-medium">Valor a receber</GrayText>
          <Heading variant="heading-h4-medium">{requestValue}</Heading>
        </RowContainer>

        <RowContainer>
          <GrayText variant="body-1-regular">Data de recebimento</GrayText>
          <Text variant="body-1-regular">{offer.disbursementDate}</Text>
        </RowContainer>

        <RowContainer>
          <GrayText variant="body-1-regular">{`Deságio (${formattingFee.monthlyFee(
            offer.appliedFee
          )})`}</GrayText>
          <Text variant="body-1-regular">{offer.feeValue}</Text>
        </RowContainer>

        <RowContainer>
          <GrayText variant="body-1-regular">Valor que será descontado</GrayText>
          <Text variant="body-1-regular">{offer.totalAmount}</Text>
        </RowContainer>

        <RowContainer>
          <GrayText variant="body-1-medium">Valor descontado no repasse de</GrayText>
          <Text variant="body-1-medium">{dateToFormattedString(offer.discountDate)}</Text>
        </RowContainer>
      </ResultAnticipationContainer>
    </ContainerCard>
  )
}

export default PayoutAnticipationSummaryCard
