import styled from 'styled-components'
import { InteractiveCard } from '@gravity/interactive-card'

export type VariantType = 'primary' | 'secondary'

export const getValueByVariant = (
  variant: VariantType,
  primaryValue: string,
  secondaryValue: string
) => (variant === 'primary' ? primaryValue : secondaryValue)

export const StyledInteractiveCard = styled(InteractiveCard)<{ $variant?: VariantType }>`
  /* Apply the exact styles from your old ButtonLink component */
  display: flex;
  flex-direction: column;
  background-color: transparent;
  padding: ${props => props.theme.gravity.spacing[4]} ${props => props.theme.gravity.spacing[8]};
  border-radius: ${props => props.theme.gravity.borderRadius[4]};
  border: 1px solid ${props => props.theme.gravity.colors['colors-border-neutral-2']};
  text-decoration: none;
  color: ${props => props.theme.gravity.colors['colors-text-main-3']};
  height: 100%;
  width: 100%;

  .title {
    color: ${props => props.theme.gravity.colors['colors-text-main-3']};
  }

  &:hover {
    background: ${props => props.theme.gravity.colors['colors-interaction-secondary-2']};
    outline: 1px solid ${props => props.theme.gravity.colors['colors-border-accent-1']};
    outline-offset: 0;

    .title,
    .button-link-icon {
      color: ${props => props.theme.gravity.colors['colors-text-main-1']};
    }
  }

  &:active {
    background-color: ${props => props.theme.gravity.colors['colors-interaction-secondary-2']};
    outline: 2px solid ${props => props.theme.gravity.colors['colors-interaction-primary-2']};
  }

  &:focus-visible {
    outline: 1px solid ${props => props.theme.gravity.colors['colors-interaction-primary-2']};
  }
`

export const IconWrapper = styled.div`
  height: ${props => props.theme.gravity.spacing[6]};
  margin-bottom: ${props => props.theme.gravity.spacing[1]};
  display: flex;
`
