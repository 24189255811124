import { useEffect, useState } from 'react'
import { useFlagsStatus, useUnleashContext } from '@unleash/proxy-client-react'
import * as Sentry from '@sentry/react'

import { useEventDispatcher } from '@/shared/hooks/useEventDispatcher'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useBeamer } from '@/shared/hooks/useBeamer'
import { useJWT } from '@/shared/hooks/useJWT'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import { mixpanel } from '@/shared/integrations/mixpanel'

import { REALMS } from '@/shared/constants'

import { getUserRole } from '@/modules/app/utils/getUserRole'
import { useUserSchoolGroup } from '@/shared/hooks/queries/schoolGroups'

export const useLoadLayoutData = () => {
  const updateContext = useUnleashContext()
  const { flagsReady, flagsError } = useFlagsStatus()
  const { isInitialized, eventDispatcherClient, handleChangeIsEnabled } = useEventDispatcher()
  const { userId, isAdmin, hasPayoutReportAccess: isMaintainer, isGroupManager, email } = useJWT()
  const beamer = useBeamer()

  const [isUnleashContextUpdated, setIsUnleashContextUpdated] = useState(false)

  const { school, hasErrorOnFetchSchool, retryFetchSchool } = useSelectedSchool({
    onError: err => Sentry.captureException(err, { level: 'error' }),
  })

  const isFetchEnabled = !!school?.id && isGroupManager

  const {
    data,
    isLoading: isGroupLoading,
    isError: hasErrorOnFetchSchoolGroup,
    refetch: refetchGroup,
  } = useUserSchoolGroup(school?.id ?? '', {
    enabled: isFetchEnabled,
    onError: err => {
      Sentry.captureException(err, { level: 'error' })
    },
  })

  const schoolGroup = data?.data.school_group ?? null
  const isUnleashMetricError = !!flagsError?.stack.includes('Metrics')

  const unleashUpdateContext = async (
    schoolId: string | undefined,
    schoolGroupId: string | undefined
  ) => {
    if (!schoolId || (isGroupManager && !schoolGroupId)) return

    setIsUnleashContextUpdated(false)

    const properties = {
      schoolId,
      userEmail: email,
    }

    if (schoolGroupId) {
      Object.assign(properties, { schoolGroupId })
    }

    await updateContext({ properties, userId: schoolId })

    setIsUnleashContextUpdated(true)
  }

  useEffect(() => {
    unleashUpdateContext(school?.id, schoolGroup?.id)

    if (school) {
      beamer.init()

      handleChangeIsEnabled(!school.is_staged)
    }
  }, [school, schoolGroup])

  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)

  useEffect(() => {
    if (isInitialized && flagsReady && school) {
      eventDispatcherClient.identifyUser({ userId })
      eventDispatcherClient.setGlobalProperties(currentProps => ({
        ...currentProps,
        $realm: isAdmin ? REALMS.ADMIN_REALM : REALMS.SCHOOL_REALM,
        customProperties: {
          ...currentProps.customProperties,
          $role: getUserRole(isGroupManager, isMaintainer),
          $school: school.name,
          $school_id: school.id,
          $isIntegratedSchool: isIntegratedSchool,
          $is_staged: school?.is_staged ?? false,
        },
      }))
    }
  }, [
    isAdmin,
    isInitialized,
    flagsReady,
    school,
    userId,
    isGroupManager,
    isMaintainer,
    isIntegratedSchool,
  ])

  useEffect(() => {
    if (!school) return

    mixpanel.setSuperProperties({
      $school: school.name,
      $school_id: school.id,
      $realm: isAdmin ? REALMS.ADMIN_REALM : REALMS.SCHOOL_REALM,
      $role: getUserRole(isGroupManager, isMaintainer),
    })
  }, [school, isAdmin, isMaintainer])

  useEffect(() => {
    mixpanel.identifyUser({
      userId: userId,
    })
  }, [])

  const retry = () => {
    if (hasErrorOnFetchSchool) {
      retryFetchSchool()
    }

    if (hasErrorOnFetchSchoolGroup) {
      refetchGroup()
    }

    if (flagsError) {
      // Performs a full reload of the application (current page)
      location.reload()
    }
  }

  return {
    isLoading: !school || (isFetchEnabled && isGroupLoading) || !isUnleashContextUpdated,
    hasErrorOnLoad: (hasErrorOnFetchSchool ||
      hasErrorOnFetchSchoolGroup ||
      (flagsError && !isUnleashMetricError)) as boolean,
    retry,
  }
}
