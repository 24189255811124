import styled from 'styled-components'
import { Card } from '@gravity/card'
import { Text } from '@gravity/text'

// Containers
export const ContainerCard = styled(Card)`
  border-color: ${props => props.theme.gravity.colors['colors-border-neutral-3']};
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[4]};
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.gravity.spacing[2]};
`

export const ColumnContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const SkeletonsContainer = styled(ColumnContainer)`
  gap: ${props => props.theme.gravity.spacing[2]};
`

export const FeesContainer = styled.div`
  flex: 1;
`

// Texts
export const KeyText = styled(Text)`
  display: block;
  margin-bottom: ${props => props.theme.gravity.spacing[2]};
`

export const CommentText = styled(Text)`
  color: ${props => props.theme.gravity.colors['colors-text-main-3']};
`
