import { useHistory, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { Accordion } from '@gravity/accordion'
import { Heading } from '@gravity/heading'
import { Skeleton } from '@gravity/skeleton'
import { Text } from '@gravity/text'
import { GraduationCapOutline, FileText, FolderOutline, ListView } from '@gravity/icons'

import {
  GuardianHistory,
  GuardianHistoryNegotiation,
  NegotiationPaymentMethod,
} from '@/modules/guardians/services/types'
import { formatCentsToReal, formatDateWithTimezone } from '@/shared/utils'

import { NegotiationTable } from '../NegotiationTable'

import {
  AccordionTriggerWrapper,
  AccordionTriggerItems,
  AccordionTriggerItem,
  AccordionItem,
  DateSeparator,
  NegotiationInfo,
  AccordionContent,
  NegotiationInfoContainer,
  NegotiationContentSubtitle,
  NegotiationActionsContainer,
} from './styles'
import { Button } from '@gravity/button'
import { GuardiansInstallmentsQueryParamsNameEnum } from '@/modules/guardians/GuardianDetails/constants'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'

const CHANNELS = {
  SAMUCA: 'Atendimento isaac',
  'PI-ADMIN': 'meu isaac',
  'PI-ESCOLA': 'Atendimento na escola',
}

const PAYMENT_METHODS: Partial<Record<NegotiationPaymentMethod, string>> = {
  [NegotiationPaymentMethod.BANK_SLIP]: 'Boleto ou Pix',
  [NegotiationPaymentMethod.CREDIT_CARD]: 'Cartão de crédito',
  [NegotiationPaymentMethod.HYBRID]: 'Híbrido',
  [NegotiationPaymentMethod.PIX]: 'Pix',
}

const EXEMPTION_REASONS = {
  consumer_defense: 'Procon e Demandas Judiciais',
  extraordinary: 'Óbito ou doença grave',
  incorrect_data: 'E-mail desatualizado ou não enviado',
  is_angry: 'Responsável atritado',
  is_inactive: 'Responsável inativo',
  isaac_error: 'Ajuste isaac',
  local_holiday: 'Feriado local',
  public_complaint: 'Desconto excepcional',
  school_exception: 'Solicitado pela escola',
  social_media: 'Desconto excepcional',
}

type InfoProps = {
  title: string
  value: string
  valueWeight?: 'regular' | 'medium'
}

const Info = ({ title, value, valueWeight = 'regular' }: InfoProps) => (
  <NegotiationInfo>
    <Text variant="body-2-regular">{title}</Text>
    <Text variant={valueWeight === 'regular' ? 'body-1-regular' : 'body-1-medium'}>{value}</Text>
  </NegotiationInfo>
)

type NegotiationCardProps = {
  handleOnValueChange: (selectedNegotiationId: string) => void
  info?: GuardianHistory
  isDisabled?: boolean
  isLoading: boolean
  isNegotiationLoading?: boolean
  negotiation?: GuardianHistoryNegotiation
  negotiationId: uuid
  onClick?: () => void
  showDateSeparator?: boolean
}

export const NegotiationCard = ({
  handleOnValueChange,
  info,
  isDisabled,
  isLoading,
  isNegotiationLoading,
  negotiation,
  negotiationId,
  onClick,
  showDateSeparator,
}: NegotiationCardProps) => {
  const history = useHistory()
  const { schoolSlug } = useSelectedSchool()
  const { guardianId } = useParams<{ guardianId: string }>()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const formatListToString = (list: { name: string }[], nameToMultiple: string) => {
    if (list.length === 1) return list[0].name
    if (list.length > 1) return `${list.length} ${nameToMultiple}`
    return ''
  }

  const formatExemptionReasons = (reason: string) => {
    return EXEMPTION_REASONS[reason as keyof typeof EXEMPTION_REASONS] ?? ''
  }

  const paymentMethod =
    PAYMENT_METHODS[negotiation?.payment_method as keyof typeof PAYMENT_METHODS] ?? '-'
  const exemptionReason = formatExemptionReasons(
    negotiation?.exemption_reasons?.[0] as keyof typeof EXEMPTION_REASONS
  )

  const shortId = info?.short_id?.toUpperCase()

  const handleGoToInvoicesPage = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.GUARDIANS,
        action: 'click',
        customProperties: {
          $page_name: 'Historico RF',
          $button_name: 'Ver na aba de faturas',
        },
      })
    }

    const params = new URLSearchParams()
    params.append(GuardiansInstallmentsQueryParamsNameEnum.NEGOTIATION_ID, negotiation?.id ?? '')

    history.push(`/${schoolSlug}/responsaveis/${guardianId}/faturas?${params.toString()}`)
  }

  const handleGoToContractPage = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.GUARDIANS,
        action: 'click',
        customProperties: {
          $page_name: 'Historico RF',
          $button_name: 'Situacao do contrato',
        },
      })
    }

    const params = new URLSearchParams()
    params.append(GuardiansInstallmentsQueryParamsNameEnum.NEGOTIATION_ID, negotiation?.id ?? '')

    history.push(`/${schoolSlug}/responsaveis/${guardianId}/contratos?${params.toString()}`)
  }

  if (isLoading) {
    return (
      <>
        <DateSeparator>
          <Skeleton width={150} height={24} />
        </DateSeparator>
        <Accordion.Root disabled={isLoading} type="single">
          <AccordionItem value={`item-${uuidv4()}`} disabled>
            <Accordion.Trigger>
              <AccordionTriggerWrapper>
                <Skeleton width={200} height={24} />

                <AccordionTriggerItems>
                  <AccordionTriggerItem>
                    <Skeleton radius="full" width={24} height={24} />
                    <Skeleton width={130} height={24} />
                  </AccordionTriggerItem>
                  <AccordionTriggerItem>
                    <Skeleton radius="full" width={24} height={24} />
                    <Skeleton width={130} height={24} />
                  </AccordionTriggerItem>
                </AccordionTriggerItems>
              </AccordionTriggerWrapper>
            </Accordion.Trigger>
          </AccordionItem>
        </Accordion.Root>
      </>
    )
  }

  return (
    <>
      {showDateSeparator && (
        <DateSeparator>
          <Text variant="body-2-regular">
            {formatDateWithTimezone(info?.created_at ?? Date(), 'D MMM [•] dddd')?.toLowerCase()}
          </Text>
        </DateSeparator>
      )}

      <Accordion.Root
        disabled={isLoading}
        type="single"
        value={negotiationId}
        onValueChange={handleOnValueChange}
      >
        <AccordionItem
          value={info?.id ?? ''}
          disabled={isDisabled || isNegotiationLoading}
          isLoading={isNegotiationLoading}
        >
          <Accordion.Trigger onClick={onClick}>
            <AccordionTriggerWrapper>
              <Heading variant="heading-h4-medium">{`Negociação #${shortId}`}</Heading>
              <AccordionTriggerItems>
                <AccordionTriggerItem>
                  <GraduationCapOutline size="xs" />
                  <Text variant="body-2-regular">
                    {info?.students.length ? formatListToString(info?.students, 'alunos') : ''}
                  </Text>
                </AccordionTriggerItem>
                <AccordionTriggerItem>
                  <FileText size="xs" />
                  <Text variant="body-2-regular">
                    {info?.products.length ? formatListToString(info?.products, 'produtos') : ''}
                  </Text>
                </AccordionTriggerItem>
              </AccordionTriggerItems>
            </AccordionTriggerWrapper>
          </Accordion.Trigger>

          {negotiation?.id && (
            <AccordionContent>
              <NegotiationInfoContainer columns={3}>
                <Info title="ID" value={shortId ?? ''} valueWeight="medium" />
                <Info
                  title="Canal"
                  value={CHANNELS[info?.channel as keyof typeof CHANNELS]}
                  valueWeight="medium"
                />
                <Info
                  title="Data e hora"
                  value={formatDateWithTimezone(
                    info?.created_at ?? Date(),
                    'DD/MM/YYYY [às] HH:mm'
                  )}
                  valueWeight="medium"
                />
              </NegotiationInfoContainer>

              <div>
                <NegotiationContentSubtitle variant="body-1-medium">
                  Faturas negociadas
                </NegotiationContentSubtitle>

                <NegotiationTable table={negotiation?.original_receivables} type="original" />

                <NegotiationInfoContainer columns={4}>
                  <Info
                    title="Valor original"
                    value={formatCentsToReal(negotiation?.original_amount ?? 0, true)}
                  />
                  <Info
                    title="Multas e juros"
                    value={'+ ' + formatCentsToReal(negotiation?.total_taxes ?? 0, true)}
                  />
                  <Info
                    title="Desconto aplicado"
                    value={'- ' + formatCentsToReal(negotiation?.total_discounts ?? 0, true)}
                  />
                  <Info
                    title="Total a pagar"
                    value={formatCentsToReal(info?.total_amount ?? 0, true)}
                    valueWeight="medium"
                  />
                </NegotiationInfoContainer>

                <NegotiationInfoContainer columns={2}>
                  <Info title="Forma de pagamento" value={paymentMethod} />
                  {negotiation?.total_discounts > 0 && (
                    <Info title="Motivo do desconto" value={exemptionReason} />
                  )}
                </NegotiationInfoContainer>
              </div>

              <div>
                <NegotiationContentSubtitle variant="body-1-medium">
                  Faturas resultantes da negociação
                </NegotiationContentSubtitle>

                <NegotiationTable
                  table={negotiation?.resultant_receivables}
                  type="resultant"
                  shortId={shortId}
                />
              </div>

              <NegotiationActionsContainer>
                {info?.status !== 'CANCELED' && (
                  <Button iconStart={<ListView />} variant="ghost" onClick={handleGoToInvoicesPage}>
                    Ver na aba de faturas
                  </Button>
                )}
                <Button
                  iconStart={<FolderOutline />}
                  variant="ghost"
                  onClick={handleGoToContractPage}
                >
                  Ver situação do contrato
                </Button>
              </NegotiationActionsContainer>
            </AccordionContent>
          )}
        </AccordionItem>
      </Accordion.Root>
    </>
  )
}
