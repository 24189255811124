import { Skeleton } from '@gravity/skeleton'
import { InteractiveCard } from '@gravity/interactive-card'

export const GuardiansLoading = () => {
  return (
    <>
      {[0, 1].map(guardian => (
        <div key={guardian} data-testid="loading">
          <InteractiveCard fullWidth>
            <div className="content loading">
              <Skeleton variant="neutral" width={100} height={20} />
              <Skeleton variant="neutral" width={200} height={25} />
            </div>
          </InteractiveCard>
        </div>
      ))}
    </>
  )
}
