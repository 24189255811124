import styled from 'styled-components'
import { Text } from '@gravity/text'
import { Card } from '@gravity/card'

export const CardWrapper = styled(Card)``

export const InformationHeader = styled.div`
  display: flex;
  margin-bottom: 0.5px;
  justify-content: space-between;
  padding-top: 3px;
`

export const BoldTypography = styled(Text)`
  font-weight: 700;
`

export const AgreementNumber = styled(Text)`
  margin-bottom: 4px;
  color: ${props => props.theme.gravity.colors['colors-text-main-3']};
`

export const Subtitle = styled(Text)`
  margin-bottom: 12px;
  color: ${props => props.theme.gravity.colors['colors-text-main-3']};
`
