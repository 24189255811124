import { ChoiceChip } from '@gravity/chip'
import { Control, FieldArrayWithId, useFieldArray } from 'react-hook-form'

import { FilterForm } from '../../schemas/filterSchemas'
import { useCallback } from 'react'
import { GuardiansInvoicesFilterQueryParams } from '../../hooks/useGuardianInvoicesFilter/types'
import { useTextFilterOptions } from '../../hooks/useGuardianInvoicesFilter/useTextFilterOptions'

interface Props {
  control: Control<FilterForm>
  filterType: GuardiansInvoicesFilterQueryParams
}

export const FilterChip = ({ control, filterType }: Props) => {
  const { fields, update } = useFieldArray({
    control,
    name: filterType,
  })

  const handleChoiceSelected = (index: number) => {
    update(index, {
      ...fields[index],
      selected: !fields[index]?.selected || false,
    })
  }

  const handleSelected = useCallback(
    index => {
      handleChoiceSelected(index)
    },
    [filterType, fields, update, handleChoiceSelected]
  )

  return (
    <div className="flex flex-wrap gap-x-2 gap-y-4 [&>svg]:box-content">
      {fields.map((field: FieldArrayWithId<FilterForm>, index: number) => {
        return (
          <ChoiceChip
            key={field.value}
            text={useTextFilterOptions({
              value: field.value,
              label: field.label,
              type: filterType,
            })}
            value={field.value}
            selected={field.selected}
            onSelected={() => handleSelected(index)}
          />
        )
      })}
    </div>
  )
}
