import { formatCurrencyAmount } from '@monorepo/report/utils/formatCurrencyAmount'
import { SecondaryInfoText, StyledTooltip } from './styles'
import { Text } from '@gravity/text'
import { InformationOutline } from '@gravity/icons'
import { PayoutTransfersSectionSubCategoryProps } from './types'

export const PayoutTransfersSectionSubCategory = ({
  title,
  tooltip,
  secondaryInfo,
  items = [],
  total,
}: PayoutTransfersSectionSubCategoryProps) => {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-1">
          <Text variant="body-1-medium">{title}</Text>

          {tooltip && (
            <StyledTooltip text={tooltip}>
              <span>
                <InformationOutline size="xs" />
              </span>
            </StyledTooltip>
          )}
        </div>

        {total !== undefined && (
          <Text variant="subtitle-medium">{formatCurrencyAmount(total)}</Text>
        )}
      </div>

      {!!secondaryInfo && (
        <div className="flex">
          <SecondaryInfoText variant="body-2-regular">{secondaryInfo}</SecondaryInfoText>
        </div>
      )}

      {items.length > 0 && (
        <div className="flex flex-col gap-4 mt-2">
          {items.map(({ label, value, secondaryInfo }) => (
            // TODO: after upgrade to React 18, replace key by useId hook
            <div
              key={`${label}-${String(value)}-${secondaryInfo}`}
              className="flex justify-between items-start"
            >
              <div className="flex flex-col gap-1">
                <SecondaryInfoText variant="body-1-regular">{label}</SecondaryInfoText>

                {!!secondaryInfo && (
                  <SecondaryInfoText variant="body-2-regular">{secondaryInfo}</SecondaryInfoText>
                )}
              </div>

              <Text variant="subtitle-medium">{formatCurrencyAmount(value)}</Text>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
