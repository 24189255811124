import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, Controller } from 'react-hook-form'
import { Select } from '@gravity/select'
import NumberFormat from 'react-number-format'
import { TextField } from '@gravity/text-field'
import { Callout } from '@gravity/callout'
import { Footer } from '@gravity/footer'
import { DashedArrowRight } from '@gravity/icons'

import { defaultFormData, EditGuardianFormSchema, UFSelectionOptions } from './constants'
import type { EditGuardianFormData } from './types'
import { useAddressAutoFill } from './hooks/useAddressAutoFill'

type Props = {
  initialFormData?: EditGuardianFormData
  onClickCancel: () => void
  onClickChangeCpfCallout: () => void
  onSubmit: (data: EditGuardianFormData) => void
  showSubmitBtnLoader: boolean
}

export const GuardianFormFields = ({
  showSubmitBtnLoader,
  initialFormData,
  onSubmit,
  onClickCancel,
  onClickChangeCpfCallout,
}: Props) => {
  const form = useForm<EditGuardianFormData>({
    mode: 'onChange',
    defaultValues: initialFormData ?? defaultFormData,
    resolver: zodResolver(EditGuardianFormSchema),
  })

  const { errors } = form.formState

  const { handleZipInputBlur } = useAddressAutoFill(form)

  const gerErrorProps = (errorMsg?: string) => ({
    error: !!errorMsg,
    errorMessage: errorMsg,
  })

  return (
    <form className="flex flex-col gap-4" onSubmit={form.handleSubmit(onSubmit)}>
      <NumberFormat
        disabled
        label="CPF:"
        aria-label="CPF do responsável"
        format="###.###.###-##"
        type="tel"
        placeholder="___.___.___-__"
        value={form.getValues('tax_id')}
        onValueChange={async currentValue => form.setValue('tax_id', currentValue.value)}
        fullWidth
        customInput={TextField}
        size={3}
        {...gerErrorProps(errors?.tax_id?.message)}
        {...form.register('tax_id')}
      />

      <Callout
        text="Para alterar o CPF do responsável financeiro, é necessário transferir os contratos para o um novo CPF"
        linkLabel="Clique aqui"
        href="#"
        onLinkClick={onClickChangeCpfCallout}
      />

      <TextField
        size={3}
        fullWidth
        label="Nome"
        placeholder="Informe nome completo"
        aria-label="Nome do responsável"
        {...gerErrorProps(errors?.name?.message)}
        {...form.register('name')}
      />

      <div className="flex wp-full justify-between gap-4">
        <TextField
          fullWidth
          label="E-mail"
          placeholder="Informe um e-mail válido"
          aria-label="E-mail do responsável"
          size={3}
          {...gerErrorProps(errors?.email?.message)}
          {...form.register('email')}
        />

        <NumberFormat
          aria-label="Celular"
          label="Celular"
          format="(##) #####-####"
          placeholder="(__) _____-____"
          mask="_"
          type="tel"
          value={form.getValues('phone_number')}
          onValueChange={async currentValue => {
            form.setValue('phone_number', currentValue.value)
          }}
          fullWidth
          customInput={TextField}
          size={3}
          {...gerErrorProps(errors?.phone_number?.message)}
          {...form.register('phone_number')}
        />
      </div>

      <Callout text="Ao informar e-mail e celular do responsável financeiro, você garante maior eficiência na gestão de cobranças." />

      <div className="flex wp-full justify-between gap-4">
        <NumberFormat
          label="CEP"
          aria-label="CEP"
          format="#####-###"
          placeholder="_____-___"
          type="text"
          value={form.getValues('address.zip')}
          onValueChange={async currentValue => {
            form.setValue('address.zip', currentValue.value)
          }}
          fullWidth
          customInput={TextField}
          size={3}
          {...gerErrorProps(errors?.address?.zip?.message)}
          {...form.register('address.zip')}
          onBlur={handleZipInputBlur}
        />

        <TextField
          size={3}
          fullWidth
          label="Logradouro"
          aria-label="Logradouro"
          placeholder="Avenida, rua, alameda, praça"
          {...gerErrorProps(errors?.address?.street?.message)}
          {...form.register('address.street')}
        />
      </div>

      <div className="flex wp-full justify-between gap-4">
        <TextField
          size={3}
          fullWidth
          label="Número"
          aria-label="Número de edifício, casa, lote"
          placeholder="Número de edifício, casa, lote"
          {...gerErrorProps(errors?.address?.number?.message)}
          {...form.register('address.number')}
        />

        <TextField
          size={3}
          fullWidth
          label="Complemento"
          placeholder="Número de apartamento, casa"
          {...gerErrorProps(errors?.address?.additional_information?.message)}
          {...form.register('address.additional_information')}
        />
      </div>

      <div className="flex wp-full justify-between gap-4">
        <TextField
          size={3}
          fullWidth
          label="Cidade"
          aria-label="Cidade"
          placeholder="Informe a cidade"
          {...gerErrorProps(errors?.address?.city?.message)}
          {...form.register('address.city')}
        />

        <Controller
          control={form.control}
          name="address.state_code"
          render={({ field }) => (
            <Select
              size={3}
              fullWidth
              label="UF"
              placeholder="Selecione o estado"
              options={UFSelectionOptions}
              value={field.value}
              hasError={!!errors?.address?.state_code?.message}
              helperText={errors?.address?.state_code?.message}
              enableHelperTextSlot
              onValueChange={value => field.onChange(value)}
            />
          )}
        />
      </div>

      <Footer
        columns={6}
        className="z-[99]"
        ghostButton={{
          label: 'Cancelar',
          variant: 'outline',
          onClick: onClickCancel,
        }}
        primaryButton={{
          type: 'submit',
          label: 'Salvar',
          iconEnd: <DashedArrowRight />,
          loading: showSubmitBtnLoader,
        }}
      />
    </form>
  )
}
