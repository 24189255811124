import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { schoolsEnrollmentCycleService } from '@monorepo/enrollment/services'
import {
  CampaignNameCheckRequest,
  GetCampaignNameCheckResponse,
} from '@monorepo/enrollment/services/types'
import { useApiClient } from '@/shared/hooks/useApiClient'

const CampaignNameCheckKey = 'campaign-name-check'

export const useCampaignNameCheckQuery = (
  params: CampaignNameCheckRequest,
  options?: UseQueryOptions<GetCampaignNameCheckResponse>
) => {
  const { apiClient } = useApiClient()
  const service = schoolsEnrollmentCycleService(apiClient.privateApi)

  return useQuery<GetCampaignNameCheckResponse>(
    [CampaignNameCheckKey, params],
    () => service.fetchSchoolNameAvailability(params),
    options
  )
}
