import { useState } from 'react'
import { Close, DownloadOutline } from '@gravity/icons'
import { Button } from '@gravity/button'
import { Drawer } from '@olaisaac/design-system'
import { Heading } from '@gravity/heading'
import { Separator } from '@gravity/separator'
import { IconButton } from '@gravity/icon-button'
import { Text } from '@gravity/text'

import { useApiClient } from '@/shared/hooks/useApiClient'
import { useSnackbar } from '@/shared/hooks'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'

import { downloadFile } from '@/shared/utils'

import { REPORT_PAGE_NAMES } from '@monorepo/report/constants/REPORT_PAGE_NAMES'

import {
  BankTransfer,
  FinancialDetails,
  PayoutVersion,
  TransactionEventType,
} from '@monorepo/report/models/report'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { EnrollmentPayoutDrawerContent } from './components/EnrollmentPayoutDrawerContent'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { reportService } from '@monorepo/report/services/report'
import { StyledTooltip } from './styles'

type EnrollmentSummaryInfo = {
  enrollment_end_date: Date
  payoutBankTransfers?: BankTransfer[]
  payoutId: string
  payoutVersion: PayoutVersion
}

type SummaryDrawerProps = {
  financialDetails?: FinancialDetails
  isOpen: boolean
  isPayoutOpen: boolean
  onClose: () => void
  payoutData: EnrollmentSummaryInfo
  summaryPeriod?: 'mensal' | 'semanal'
}

/**
 * Component to display enrollment payment summary data
 *
 * @param props
 * @param props.isOpen Indicates if drawer should be opened
 * @param props.isPayoutOpen Represents payout status
 * @param props.onClose Callback function called on request close
 * @param props.payoutData Payout summary data
 * @param props.summaryPeriod Enrollment summary period type
 */
export const EnrollmentSummaryDrawer = ({
  financialDetails,
  payoutData,
  summaryPeriod = 'semanal',
  isPayoutOpen,
  isOpen,
  onClose,
}: SummaryDrawerProps) => {
  const { apiClient } = useApiClient()
  const { school } = useSelectedSchool()
  const { setMessage, setVariation, setIsOpen } = useSnackbar()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const showDownloadButtonForEnrollmentSummary = useUnleashFlag(
    UnleashFlags.SHOW_DOWNLOAD_BUTTON_FOR_ENROLLMENT_SUMMARY
  )

  const [isLoadingAction, setIsLoadingAction] = useState(false)

  const handleDownloadReport = async (payoutId: string, filename: string) => {
    const service = reportService(apiClient.getClients().privateApi)

    try {
      setIsLoadingAction(true)
      await downloadFile(filename, 'pdf', () =>
        service.fetchUnguaranteedReportFile({
          schoolId: school.id ?? '',
          id: payoutId,
          extension: 'pdf',
        })
      )
    } catch {
      setMessage('Falha ao baixar arquivo. Tente novamente mais tarde.')
      setVariation('error')
      setIsOpen(true)
    } finally {
      setIsLoadingAction(false)

      isInitialized &&
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.ENROLLMENT_REPORT,
          name: EventDispatcherEvents.ENROLLMENT_PAYOUT_DOWNLOAD_SUMMARY,
          action: 'file_download',
          customProperties: {
            $page_name: REPORT_PAGE_NAMES.ENROLLMENT_PAYOUT_REPORT,
          },
        })
    }
  }

  const values = {
    ...financialDetails?.events,
    total_base_amount: financialDetails?.total_base_amount,
  }

  const total = financialDetails?.total - (values[TransactionEventType.BALANCE_TRANSFER] || 0)
  const total_fee = -1 * financialDetails?.total_fee

  const bankTransfers = payoutData?.payoutBankTransfers || []

  return (
    <Drawer open={isOpen}>
      <div className="flex flex-col h-full">
        <div className="flex flex-col gap-6 pt-6 items-center" data-testid="drawer-header">
          <div className="flex px-6 w-full justify-end">
            <IconButton onClick={onClose} size={2} variant="ghost" aria-label="Fechar">
              <Close />
            </IconButton>
          </div>
          <div className="flex flex-col gap-6 px-6 w-full">
            <Heading variant="heading-h3-medium">Resumo</Heading>
            <Text variant="body-1-regular" className="text-gray-500">
              {summaryPeriod === 'mensal'
                ? 'Veja o resumo do seu período mensal.'
                : 'Veja o resumo do seu período semanal.'}
            </Text>
          </div>
          <Separator orientation="horizontal" color="neutral-2" />
        </div>

        <div className="flex-1 overflow-auto">
          <EnrollmentPayoutDrawerContent
            total={total}
            total_fee={total_fee}
            bankTransfers={bankTransfers}
            values={values}
            enrollment_end_date={payoutData.enrollment_end_date}
          />
        </div>

        {showDownloadButtonForEnrollmentSummary && (
          <div className="p-6">
            <StyledTooltip
              text={
                isPayoutOpen
                  ? 'Disponível apenas para repasses fechados'
                  : 'Baixar o arquivo .pdf do período'
              }
            >
              <Button
                fullWidth
                onClick={() => handleDownloadReport(payoutData.payoutId, 'resumo')}
                loading={isLoadingAction}
                disabled={isPayoutOpen}
                data-testid="download-button"
                iconStart={<DownloadOutline size="sm" />}
              >
                {summaryPeriod === 'mensal' ? 'Baixar resumo' : 'Baixar extrato'}
              </Button>
            </StyledTooltip>
          </div>
        )}
      </div>
    </Drawer>
  )
}
