import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import dayjs from 'dayjs'

import { UnleashFlags, useJWT, useUnleashFlag, useUnleashFlag as unleashFlag } from '@/shared/hooks'
import { useApi } from '@/utils/hooks/useApi'
import { setToClipboard } from '@/shared/utils'
import { LiquidationSource } from '@/shared/interfaces'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { HotjarEvents, useHotjar } from '@/shared/hooks/useHotjar'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { useIsaacPayOutsourcedPaymentProvider } from '@/shared/hooks/useIsaacPayOutsourcedPaymentProvider'
import { useSendManualLiquidationEvent } from '@/modules/guardians/ManualLiquidation/hooks/manualLiquidation/useSendManualLiquidationEvents'
import useManualLiquidationNavigation from '@/shared/hooks/useManualLiquidationNavigation'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { useInstallmentDrawerContext } from '@/modules/guardians/contexts/InstallmentDrawerContext'
import { useQuery } from '@/shared/hooks/useQuery'
import {
  InstallmentStatus,
  InstallmentType,
  NegotiationType,
} from '@/modules/guardians/GuardianDetails/constants'
import { useGuardianNavigation } from '@/shared/hooks/useGuardianNavigation'
import { useGuardianDetailsInvoiceEvents } from '@/modules/guardians/GuardianDetailsInvoicesTab/hooks/useGuardianDetailsInvoiceEvents'
import { School } from '@/shared/models/School'
import { useToast } from '@gravity/toast'

export interface useDrawerActionsInstallmentReturn {
  handleClickChangePaymentMethod: () => void
  handleClickEditManualLiquidation: () => void
  handleClickManualLiquidation: () => void
  handleClickPrintReceipt: () => void
  handleClickRenegotiation: () => void
  handleClickSharePaymentLink: (e: React.MouseEvent<HTMLButtonElement>) => void
  handleSuccessChangePaymentMethod: () => void
  isDisabledChangePaymentMethod: boolean
  isDisabledManualLiquidation: boolean
  isDisabledRenegotiation: boolean
  isHiddenChangePaymentMethod: boolean
  isHiddenEditManualLiquidation: boolean
  isHiddenManualLiquidation: boolean
  isHiddenRenegotiation: boolean
  isLoading: boolean
  setShowChangePaymentModal: (show: boolean) => void
}

export const useDrawerActionsInstallment = (
  onCloseDrawer: () => void
): useDrawerActionsInstallmentReturn => {
  const [isLoading, setIsLoading] = useState(false)

  const { api } = useApi()
  const { isBackofficeUser, getUserRolesBySchool } = useJWT()
  const { setOnQueryParam } = useQuery()
  const { school } = useSelectedSchool()

  const { toast } = useToast()
  const { search: currentPageQueryParams } = useLocation()
  const isOutsourcedProvider = useIsaacPayOutsourcedPaymentProvider(school as School)
  const sendManualLiquidationEvent = useSendManualLiquidationEvent()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { sendHotjarEvent } = useHotjar()
  const {
    installmentDrawerInfo: {
      type,
      status,
      is_from_credit_card_flow,
      urn,
      contract_id,
      invoice,
      negotiation,
      paid,
      receivable_type,
    },
    setSelectedDrawer,
    setShowChangePaymentModal,
  } = useInstallmentDrawerContext()
  const { setPageToReturn } = useGuardianNavigation()

  const { goToManualLiquidation, goToEditManualLiquidation } = useManualLiquidationNavigation({
    urn,
    callbackParams: currentPageQueryParams,
  })

  const { sendEvent: sendGuardianDetailsInvoiceEvent } = useGuardianDetailsInvoiceEvents()

  const isEnabledBasicOperationAccessLevel = unleashFlag(
    UnleashFlags.PE_233_ENABLE_BASIC_OPERATION_ACCESS_LEVEL
  )

  const isRenegotiateAndAgglutinateButtonEnabled = useUnleashFlag(
    UnleashFlags.REG_2042_ENABLE_RENEGOTIATE_AND_AGGLUTINATE_BUTTONS
  )

  const isDownPayment = type === InstallmentType.DOWN_PAYMENT
  const isNegotiationV2 = negotiation?.type === NegotiationType.NEGOTIATION_API_V2

  const isEnrollmentReceivable = receivable_type === 'ENROLLMENT'
  const isOpenReceivable = [
    InstallmentStatus.OPEN,
    InstallmentStatus.OVERDUE,
    InstallmentStatus.DUE_TODAY,
  ].includes(status)
  const isOverdueReceivable = status === InstallmentStatus.OVERDUE
  const isFromCreditCardFlow = is_from_credit_card_flow

  const isManualLiquidation = [
    LiquidationSource.ISAAC_ACCOUNT,
    LiquidationSource.SCHOOL_ACCOUNT,
  ].includes(paid?.payment_source as LiquidationSource)

  const userRoles = getUserRolesBySchool(school?.id ?? '')

  const isManualLiquidationCreateBlockedForNoAccessRole =
    isEnabledBasicOperationAccessLevel && !userRoles.has('criar_baixa_manual') && !isBackofficeUser

  const isManualLiquidationEditBlockedForNoAccessRole =
    isEnabledBasicOperationAccessLevel && !userRoles.has('editar_baixa_manual') && !isBackofficeUser

  const isHiddenManualLiquidation =
    (isDownPayment && isNegotiationV2 && isOverdueReceivable) ||
    isManualLiquidationCreateBlockedForNoAccessRole ||
    !isOpenReceivable ||
    isFromCreditCardFlow

  const isDisabledManualLiquidation = isHiddenManualLiquidation || !isOpenReceivable

  const isEditable =
    !isOpenReceivable &&
    !isFromCreditCardFlow &&
    isManualLiquidation &&
    !isManualLiquidationEditBlockedForNoAccessRole

  const isPaymentSourceIsaacAccount =
    isEditable && paid?.payment_source === LiquidationSource.ISAAC_ACCOUNT

  const canEditManualLiquidation = isBackofficeUser
    ? isPaymentSourceIsaacAccount
    : isEnabledBasicOperationAccessLevel
    ? isEditable && userRoles.has('editar_baixa_manual')
    : isEditable

  const isHiddenEditManualLiquidation = !canEditManualLiquidation

  const isHiddenRenegotiation =
    isDownPayment ||
    !isBackofficeUser ||
    !isRenegotiateAndAgglutinateButtonEnabled ||
    isFromCreditCardFlow ||
    !isOpenReceivable

  const isDisabledRenegotiation =
    isHiddenRenegotiation || !isOpenReceivable || isEnrollmentReceivable

  const isHiddenChangePaymentMethod =
    !isOpenReceivable || !isOutsourcedProvider || isFromCreditCardFlow

  const isDisabledChangePaymentMethod = isHiddenChangePaymentMethod || isOverdueReceivable

  const handleClickManualLiquidation = () => {
    sendManualLiquidationEvent(
      EventDispatcherEvents.BUTTON_CLICKED,
      EventDispatcherEntities.MANUAL_LIQUIDATION,
      undefined,
      {
        name: 'Registrar recebimento',
        receivable_id: urn,
        selected_date: dayjs().format(),
        contract_id: contract_id,
      }
    )
    onCloseDrawer()
    sendHotjarEvent(HotjarEvents.MANUAL_LIQUIDATION)
    setPageToReturn()
    goToManualLiquidation()
  }

  const handleClickRenegotiation = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.INSTALLMENT_DRAWER,
        entity: EventDispatcherEntities.RENEGOTIATE,
        action: 'click',
        customProperties: {
          $name: 'Renegociar',
          $receivable_id: urn,
        },
      })

    sendHotjarEvent(HotjarEvents.RENEGOTIATE_INSTALLMENT)
    setSelectedDrawer('renegotiation')
  }

  const handleClickChangePaymentMethod = () => {
    setShowChangePaymentModal(true)
  }

  const handleClickSharePaymentLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onCloseDrawer()

    sendGuardianDetailsInvoiceEvent('button', {
      name: 'Copiar link de cobrança',
      receivable_id: urn,
      contract_id,
    })

    setToClipboard(invoice?.payment_link ?? '')

    toast({
      type: 'success',
      title: 'Link copiado com sucesso',
    })
  }

  const handleClickPrintReceipt = async () => {
    setIsLoading(true)

    sendGuardianDetailsInvoiceEvent('button', {
      name: 'Gerar comprovante',
      receivable_id: urn,
      contract_id: contract_id,
    })

    const response = await api.receivables.printReceipt(urn.replace('urn:receivable:', ''))
    const file = new Blob([response.data], { type: 'application/pdf' })
    const fileURL = URL.createObjectURL(file)
    const newWindow = window.open(fileURL, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null

    onCloseDrawer()
  }

  const handleClickEditManualLiquidation = () => {
    onCloseDrawer()
    sendHotjarEvent(HotjarEvents.EDIT_MANUAL_LIQUIDATION)
    setPageToReturn()
    goToEditManualLiquidation()
  }

  const handleSuccessChangePaymentMethod = () => {
    setShowChangePaymentModal(false)
    setOnQueryParam('true', 'refetch', 'replace')
    onCloseDrawer()
  }

  return {
    isLoading,
    isDisabledManualLiquidation,
    isHiddenManualLiquidation,
    isDisabledRenegotiation,
    isHiddenRenegotiation,
    isDisabledChangePaymentMethod,
    isHiddenChangePaymentMethod,
    isHiddenEditManualLiquidation,
    setShowChangePaymentModal,
    handleSuccessChangePaymentMethod,
    handleClickManualLiquidation,
    handleClickRenegotiation,
    handleClickChangePaymentMethod,
    handleClickSharePaymentLink,
    handleClickPrintReceipt,
    handleClickEditManualLiquidation,
  }
}
