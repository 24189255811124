import { Table } from '@gravity/table'

import { IconButton } from '@gravity/icon-button'
import { Tooltip } from '@gravity/tooltip'

import { PencilOutline } from '@gravity/icons'
import { useEvents } from '@monorepo/onboarding/hooks/eventContext'

export const TableAction = ({ onClick }: { onClick: () => void }) => {
  const events = useEvents()

  return (
    <Table.ActionCell
      actions={
        <Tooltip text="Editar dados" position="left">
          <IconButton
            variant="ghost"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation()
              events?.editButtonClicked()
              onClick()
            }}
          >
            <PencilOutline />
          </IconButton>
        </Tooltip>
      }
    />
  )
}
