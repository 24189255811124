import { GridItem } from '@gravity/grid'
import { Skeleton } from '@gravity/skeleton'

/**
 * Loading state for payout report sub header card component
 */
export const LoadingState = () => {
  return (
    <GridItem
      xl={12}
      lg={12}
      md={12}
      sm={8}
      xs={4}
      className="flex flex-col gap-4"
      data-testid="loading-state"
    >
      <Skeleton width={100} height={24} />
      <Skeleton width={200} height={24} />
      <Skeleton width={222} height={24} />
    </GridItem>
  )
}
