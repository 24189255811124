import { useApiClient } from '@/shared/hooks'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { downloadFile, formatDate } from '@/shared/utils'
import { useToast } from '@gravity/toast'
import { REPORT_PAGE_NAMES } from '@monorepo/report/constants/REPORT_PAGE_NAMES'
import { PayoutReportStatus } from '@monorepo/report/models/PayoutReportStatus'
import { reportService } from '@monorepo/report/services/report'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { useIsaacPayOutsourcedPaymentProvider } from '@/shared/hooks/useIsaacPayOutsourcedPaymentProvider'
import { ReportPageTableHeader } from '@monorepo/report/components/ReportPageTableHeader'

type Props = {
  isEmptyPayout: boolean
  isLoading: boolean
  isMonthlyViewEnabled?: boolean
  isNewVersionPayout?: boolean
  payoutId: string
  payoutReportEndDate?: Date
  payoutReportStartDate?: Date
  tuitionPayoutReportStatus?: PayoutReportStatus
}

export const IsaacPayPayoutReportTableHeader = ({
  isEmptyPayout,
  isLoading,
  isMonthlyViewEnabled = false,
  isNewVersionPayout,
  payoutId,
  payoutReportEndDate = new Date(),
  payoutReportStartDate = new Date(),
  tuitionPayoutReportStatus = 'OPEN',
}: Props) => {
  const { apiClient } = useApiClient()
  const { school } = useSelectedSchool()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { toast } = useToast()
  const isOutsourcedProvider = useIsaacPayOutsourcedPaymentProvider(school)
  const shouldShowButton = !isOutsourcedProvider && !isMonthlyViewEnabled

  const startDate = formatDate(payoutReportStartDate, 'DD/MM/YYYY')
  const endDate = formatDate(payoutReportEndDate, 'DD/MM/YYYY')

  const filename = `analítico-${startDate}-${endDate}-${school?.slug}.xlsx`

  const handleDownloadReport = async (payoutId: string, filename: string) => {
    const service = reportService(apiClient.getClients().privateApi)

    try {
      await downloadFile(filename, 'xlsx', () =>
        service.fetchPayoutReportFile({
          version: 2,
          id: payoutId,
          extension: 'xlsx',
        })
      )
    } catch {
      toast({
        title:
          'Erro ao baixar o relatório. Tente novamente mais tarde ou entre em contato com nosso suporte.',
        type: 'error',
      })
    } finally {
      isInitialized &&
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.ISAAC_PAY_REPORT,
          name: EventDispatcherEvents.ISAAC_PAY_PAYOUT_DOWNLOAD_CSV,
          action: 'file_download',
          customProperties: {
            $file_version: 2,
            $page_name: REPORT_PAGE_NAMES.ISAAC_PAY_PAYOUT_REPORT,
          },
        })
    }
  }

  if (!shouldShowButton) {
    return null
  }

  return (
    <ReportPageTableHeader
      isEmptyPayout={isEmptyPayout}
      isLoading={isLoading}
      isNewVersionPayout={isNewVersionPayout}
      onSheetDownload={() => handleDownloadReport(payoutId, filename)}
      tuitionPayoutReportStatus={tuitionPayoutReportStatus}
    />
  )
}
