import { useMutation, useQuery, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query'
import {
  FetchInstallmentsToUpdateRequest,
  UpdateInstallmentsRequest,
  CancelInstallmentsRequest,
  Installment,
  ContractDetailsRequest,
  Contract,
} from '@/modules/contract/services/types'
import { contractAPI, newContractAPI } from '@/modules/contract/services'
import { sortInstallmentsToUpdate } from '../../utils/sortInstallmentsToUpdate'
import { queryClient } from '@/shared/contexts/ReactQuery'
import {
  ContractCancellationReason,
  InstallmentStatuses,
  InstallmentType,
  Contract as FullContract,
  Product,
} from '@/shared/interfaces'
import { canCancelInstallment } from '../../utils'
import { useApiClient } from '@/shared/hooks'
import { contractService } from '@/shared/services/contracts'
import { ContractServiceAPI } from '@/shared/services/contracts/types'

type QueryResponse = {
  data: Contract & { product: Product } & {
    can_cancel_contract: boolean
    is_installments_left_without_canceling: boolean
  }
}

export const useInstallmentsToUpdateQuery = (
  api: contractAPI,
  { contractId, schoolId }: FetchInstallmentsToUpdateRequest,
  options?: UseQueryOptions<QueryResponse>
) => {
  const queryKey = ['update-contract', contractId, schoolId]

  return useQuery<QueryResponse>(
    queryKey,
    async () => {
      const response = await api.fetchInstallmentsToUpdate({
        contractId,
        schoolId,
      })

      const productResponse = await api.getProduct({ productID: response.data.product_id })

      return {
        ...response,
        data: {
          ...response.data,
          installments: sortInstallmentsToUpdate(response.data.installments),
          product: productResponse.data,
        },
      }
    },
    options
  )
}

type UpdateInstallmentsMutationParams = {
  contractId: uuid
  correlationID: uuid
  schoolId: string
  updateInstallmentsRequest: UpdateInstallmentsRequest
}

export const useUpdateInstallmentsMutation = (api: contractAPI) => {
  return useMutation(
    (params: UpdateInstallmentsMutationParams) => {
      return api.updateInstallments(
        params.schoolId,
        params.contractId,
        params.updateInstallmentsRequest,
        params.correlationID
      )
    },
    {
      onSettled: async response => {
        await queryClient.invalidateQueries(['update-contract'])
        return response
      },
    }
  )
}

export const useCancelInstallmentsMutation = (api: contractAPI) => {
  return useMutation(
    (params: CancelInstallmentsRequest) => {
      return api.cancelInstallments(params)
    },
    {
      onSettled: async response => {
        await queryClient.invalidateQueries(['update-contract'])
        return response
      },
    }
  )
}

type HandleCancelContractParams = {
  contractId: uuid
  correlationID: uuid
  description?: string
  installments: Installment[]
  reason: ContractCancellationReason
  schoolId: uuid
}

type HandleRevokeContractParams = {
  contract: Contract
  correlationID: uuid
  description?: string
  installmentsIdsToPreCancel?: uuid[]
  reason: ContractCancellationReason
  schoolId: uuid
}

/**
 * The cancel contract api does not cancel overdue installments for school users.
 * To work around this issue, we first cancel the overdue installments, then cancel the contract,
 * which also cancels the open installments.
 */
const handleCancelContract = async (
  api: contractAPI,
  {
    contractId,
    correlationID,
    schoolId,
    installments,
    description = 'empty',
    reason,
  }: HandleCancelContractParams
) => {
  const cancellableInstallments = installments.filter(i => canCancelInstallment(i))

  const overdueInstallmentIDs = cancellableInstallments
    .filter(i => i.status === InstallmentStatuses.OVERDUE)
    .map(i => i.backoffice_installment_id)

  if (overdueInstallmentIDs.length > 0) {
    await api.cancelInstallments({
      contractId: contractId,
      correlationID: correlationID,
      installmentIDs: overdueInstallmentIDs,
      schoolId: schoolId,
    })
  }

  const firstInstallmentId = cancellableInstallments.find(
    i =>
      i.receivable_type === InstallmentType.TUITION &&
      (i.status === InstallmentStatuses.OPEN || i.status === InstallmentStatuses.DUE_TODAY)
  )?.backoffice_installment_id

  await api.cancelContract({
    contractId: contractId,
    correlationID: correlationID,
    description: description,
    firstInstallmentId,
    reason: reason,
    schoolId: schoolId,
    triggeredBy: 'EDIT_CONTRACT',
  })
}

const handleRevokeContract = async (
  service: ContractServiceAPI,
  contractAPI: contractAPI,
  {
    contract,
    schoolId,
    description = 'empty',
    reason,
    correlationID,
    installmentsIdsToPreCancel = [],
  }: HandleRevokeContractParams
) => {
  if (installmentsIdsToPreCancel.length > 0) {
    await contractAPI.cancelInstallments({
      contractId: contract.id,
      correlationID,
      installmentIDs: installmentsIdsToPreCancel,
      schoolId: schoolId,
    })
  }

  await service.revoke(
    contract.id,
    {
      cancellation_description: description,
      cancellation_reason: reason,
    },
    schoolId,
    (contract as unknown) as FullContract
  )
}

export const useCancelContractMutation = (api: contractAPI) => {
  return useMutation(
    (params: HandleCancelContractParams) => {
      return handleCancelContract(api, params)
    },
    {
      onSettled: async response => {
        await queryClient.invalidateQueries(['update-contract'])
        await queryClient.invalidateQueries(['guardian-details-contracts'])
        return response
      },
    }
  )
}

export const useRevokeContractMutation = (
  options?: UseMutationOptions<unknown, unknown, HandleRevokeContractParams, unknown>
) => {
  const { apiClient } = useApiClient()

  const contractAPI = newContractAPI(apiClient.privateApi)
  const contractServiceAPI = contractService(apiClient.getClients().privateApi)

  return useMutation((params: HandleRevokeContractParams) => {
    return handleRevokeContract(contractServiceAPI, contractAPI, params)
  }, options)
}

export const useContractDetailsQuery = (
  api: contractAPI,
  { contractId }: ContractDetailsRequest
) => {
  return useQuery(['contract-details', contractId], async () => {
    const response = await api.contractDetails({ contractId })

    return response.data
  })
}
