import { Text } from '@gravity/text'
import styled from 'styled-components'

export const InputErrorText = styled(Text)`
  color: ${props => props.theme.gravity.colors['colors-interaction-dangerous-1']};
`

export const InputWrapper = styled.div<{ hasError: boolean }>`
  > span {
    margin-bottom: ${props => props.theme.gravity.spacing[2]};
    line-height: 24px;
  }

  ${({ hasError }) =>
    hasError &&
    `
    button, .MuiOutlinedInput-notchedOutline {
      --tw-ring-shadow: 0 0 #000 !important;
      border: 1px solid ${props => props.theme.gravity.colors['colors-interaction-dangerous-1']};
    }
    .MuiOutlinedInput-root > fieldset {
      border-width: 1px !important;
       border: 1px solid ${props =>
         props.theme.gravity.colors['colors-interaction-dangerous-1']} !important;
    }
    span, .MuiFormHelperText-root {
      color: ${props => props.theme.gravity.colors['colors-interaction-dangerous-1']};
    }
    .MuiFormHelperText-root {
      margin-left: 0px;
      font-size: ${props => props.theme.gravity.fontSize.sm}
    }
  `}
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  gap: ${props => props.theme.gravity.spacing[12]};
  > * {
    flex: 1;
  }
`
