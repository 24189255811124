import { AlertBanner } from '@gravity/alert-banner'

import { useAlertBannerInfos } from './useAlertBannerInfos'

export const AlertBannerSection = () => {
  const {
    shouldShowAlertBanner,
    title,
    description,
    redirectLink,
    linkLabel,
    onLinkClick,
  } = useAlertBannerInfos()

  if (!shouldShowAlertBanner) {
    return null
  }
  return (
    <AlertBanner
      title={title}
      description={description}
      linkLabel={linkLabel}
      href={redirectLink}
      onLinkClick={onLinkClick}
    />
  )
}
