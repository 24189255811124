import type { FieldValues, UseFormReset, UseFormReturn } from 'react-hook-form'
import { useState } from 'react'

import { DialogPrimitives } from '@gravity/dialog'
import { Button } from '@gravity/button'
import { Text } from '@gravity/text'
import { Separator } from '@gravity/separator'

interface Filter {
  component: React.ReactNode
  id: number
  title: string
}

interface Props {
  closeDialog: () => void
  contentClassName?: string
  filters: Array<Filter>
  form: UseFormReturn<any>
  keepValuesOnClose?: boolean
  onApplyFilters: (filters: any) => void
  onResetFilters?: () => void
  resetOptions?: Parameters<UseFormReset<FieldValues>>[1]
  resetValues: any
  size?: 1 | 2 | 3
  visible: boolean
}

export const FiltersDialog = ({
  closeDialog,
  contentClassName,
  onApplyFilters,
  onResetFilters,
  filters,
  form,
  keepValuesOnClose,
  resetValues,
  resetOptions,
  size = 3,
  visible,
}: Props) => {
  const { control, handleSubmit, reset } = form
  const [tempFilters, setTempFilters] = useState(null)

  const handleDialogOpenChange = (open: boolean) => {
    if (!open && visible) closeDialog()
  }

  const handleApplyFilters = (filters: any) => {
    setTempFilters(null)
    onApplyFilters(filters)
  }

  const handleResetFilters = (resetOptions?: Parameters<UseFormReset<FieldValues>>[1]) => {
    setTempFilters(resetValues)

    if (onResetFilters) {
      onResetFilters()
    }

    reset(resetValues, resetOptions)
  }

  const handleClose = (open: boolean) => {
    if (!keepValuesOnClose) {
      if (tempFilters) {
        reset(tempFilters)
      } else {
        reset(control._options.defaultValues)
      }
    }

    handleDialogOpenChange(open)
  }

  return (
    <DialogPrimitives.Root open={visible} onOpenChange={handleClose}>
      <DialogPrimitives.Overlay backdrop />
      <DialogPrimitives.Content
        data-testid="filters-dialog"
        title="Filtros da tabela"
        size={size}
        actionButton={
          <Button data-testid="apply-filters" onClick={handleSubmit(handleApplyFilters)} size={2}>
            Aplicar filtros
          </Button>
        }
        cancelButton={
          <Button
            onClick={e => {
              e.preventDefault()
              handleResetFilters(resetOptions)
            }}
            size={2}
            variant="ghost"
            data-testid="remove-filters"
          >
            Remover filtros
          </Button>
        }
        containerClassName={`[&_svg]:box-content ${contentClassName}`}
      >
        <div className="flex flex-1 flex-col overflow-y-auto pb-8 gap-8">
          {filters.map((filter, index) => (
            <div data-testid="filter" className="flex flex-col gap-8" key={filter.id}>
              <Text variant="subtitle-medium">{filter.title}</Text>
              <div>{filter.component}</div>
              {index < filters.length - 1 && (
                <Separator orientation="horizontal" color="neutral-2" />
              )}
            </div>
          ))}
        </div>
      </DialogPrimitives.Content>
    </DialogPrimitives.Root>
  )
}
