import type { FetchPayoutDetailsResponseDTO } from '@/shared/services/report/types'
import type { PayoutTransactionEvents } from '@monorepo/report/models/PayoutTransactionEvents'
import { FilterOptions } from '@monorepo/report/models/report'

/**
 * Utility function to parse filter options for isaacPay payout report page
 *
 * @param data Filter options
 *
 * @returns Parsed filter options
 */
export const buildIsaacPayPayoutReportFilterOptions = (
  data?: FetchPayoutDetailsResponseDTO<'isaac-pay'>['data']['filters_included']
): FilterOptions => {
  if (data) {
    return {
      events: data.events as Array<{ label: string; value: PayoutTransactionEvents }>,
      products: data.products,
      amountSigns: data.amount_signs as Array<{ label: string; value: 'POSITIVE' | 'NEGATIVE' }>,
    }
  }

  return {
    events: [],
    products: [],
    amountSigns: [],
  }
}
