import styled from 'styled-components'
import { Text } from '@gravity/text'
import { TextField } from '@gravity/text-field'
export const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-top: ${({ theme }) => theme.gravity.spacing[1]};
`
export const StyledBoxFields = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: centes;
`

export const StyledTextRadio = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  color: ${props => props.theme.gravity.colors['colors-text-main-3']};
`

export const WrapperRadio = styled.div`
  display: flex;
  align-items: flex-start;
`

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.gravity.spacing[1]};
`

export const LargeStyledForm = styled.div`
  width: 100%;
  padding-right: ${({ theme }) => theme.gravity.spacing[5]};
`

export const NoWrapTypography = styled(Text)`
  white-space: nowrap;
  color: ${props => props.theme.gravity.colors['colors-text-main-3']};
`

export const StyledTypograph = styled(Text)`
  margin-top: ${({ theme }) => theme.gravity.spacing[1]};
`

export const NoWrapTypographyDisabled = styled(NoWrapTypography)`
  color: ${props => props.theme.gravity.colors['colors-text-main-2']};
`
export const StyledBoxLoading = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  align-items: center;
  height: 100%;
  background: white;
  z-index: 3;
  justify-content: center;
  box-sizing: border-box;
`

export const StyledBoxIndex = styled.div`
  z-index: 4;
`

export const StyledTextArea = styled(TextField)`
  max-width: 752px;
  width: 100%;
`
export const StyledHelpTextError = styled.div`
  color: ${props => props.theme.gravity.colors['colors-text-semantic-colors-error-1']};
`
