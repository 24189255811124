import styled, { css } from 'styled-components'

const SIDEBAR_MENU_LEFT_BAR_WIDTH = '2px'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  gap: ${props => props.theme.gravity.spacing[4]};
`

const GLOBAL_HEADER_HEIGHT = 72
const STUDENTS_HEADER_HEIGHT = 64

export const FloatMenuContainer = styled.aside<{ newSidemenu: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[4]};

  position: sticky;
  z-index: 97;

  ${props =>
    props.newSidemenu
      ? css`
          top: calc(
            ${props => props.theme.gravity.spacing[6]} +
              ${GLOBAL_HEADER_HEIGHT + STUDENTS_HEADER_HEIGHT}px
          );
        `
      : css`
          top: calc(${props => props.theme.gravity.spacing[6]} + 60px);
        `}

  height: fit-content;
  min-width: fit-content;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: ${SIDEBAR_MENU_LEFT_BAR_WIDTH};
    background-color: ${props => props.theme.gravity.colors['colors-border-neutral-2']};
  }
`
