import type { Dispatch, SetStateAction } from 'react'
import type { GetCampaignStudentResponse } from '@monorepo/enrollment/services/enrollmentService/types'

import type { PaginationHookType, PaginationType } from '@/shared/hooks/usePagination/types'
import type { AddTuitionToCampaignState } from '@monorepo/enrollment/pages/Campaigns/CampaignDetails/types'

export type CustomizationTableProps = {
  setAddCustomTuitionDialogState: Dispatch<SetStateAction<AddTuitionToCampaignState>>
  setEnableAddInstallments: (status: boolean) => void
}

export type CustomizationTableViewProps = {
  campaignStudentsData?: GetCampaignStudentResponse
  columnHeaders: ColumnProps[]
  excludedEnrollmentIds: string[]
  isLoading: boolean
  noDataMessage?: string
  pagination: PaginationType
  rows: TableRowProps[]
  setAddCustomTuitionDialogState: Dispatch<SetStateAction<AddTuitionToCampaignState>>
  updatePaginationValue: PaginationHookType['updatePaginationValue']
}
export type CustomizationDialogProps = {
  addCustomTuitionDialogState: AddTuitionToCampaignState
  setAddCustomTuitionDialogState: Dispatch<SetStateAction<AddTuitionToCampaignState>>
}

export type CustomizationStepActionTableProps = {
  enrollmentID: string
  isStudentRemovedFromBulkAdd: boolean
  setAddCustomTuitionDialogState: Dispatch<SetStateAction<AddTuitionToCampaignState>>
  studentName: string
}

export enum columnNames {
  STUDENT_NAME = 'student_name',
  TOTAL_AMOUNT = 'total_amount',
  TUITION_AMOUNT_WITH_DISCOUNT = 'tuition_amount_with_discount',
  TUITION_QUANTITY = 'tuition_quantity',
}
type ColumnName = `${columnNames}`

export type TableRowProps = {
  columns: Record<columnNames, string>
  enrollmentID: string
  key: string
}

export type ColumnProps = {
  key: ColumnName
  label: string
  minWidth: string
  sortable?: boolean
}
