import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.gravity.spacing['14']};
  gap: ${({ theme }) => theme.gravity.spacing['8']};
`

export const GuardianSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.gravity.spacing['6']};
  margin-bottom: ${({ theme }) => theme.gravity.spacing['6']};
  > div {
    flex: 1;
  }
`

export const ContractSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.gravity.spacing['6']};
`
