import { Skeleton } from '@gravity/skeleton'

type Props = {
  isLoading: boolean
}

export const GuardianFormFieldsLoader = ({ isLoading }: Props) => {
  if (!isLoading) return null
  return (
    <div className="flex flex-col gap-4" data-testid="guardian-form-fields-loader">
      <Skeleton height={72} fullWidth />
      <Skeleton height={74} fullWidth />
      <Skeleton height={72} fullWidth />

      <div className="flex wp-full justify-between gap-4">
        <Skeleton height={72} fullWidth />
        <Skeleton height={72} fullWidth />
      </div>

      <Skeleton height={74} fullWidth />

      <div className="flex wp-full justify-between gap-4">
        <Skeleton height={72} fullWidth />
        <Skeleton height={72} fullWidth />
      </div>

      <div className="flex wp-full justify-between gap-4">
        <Skeleton height={72} fullWidth />
        <Skeleton height={72} fullWidth />
      </div>

      <div className="flex wp-full justify-between gap-4">
        <Skeleton height={72} fullWidth />
        <Skeleton height={72} fullWidth />
      </div>
    </div>
  )
}
