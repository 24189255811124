import styled from 'styled-components'
import type { StyledInputMinDownProps } from './types'

export const StyledInput = styled.div<{
  marginRight?: number
  maxWidth?: number
  minWidth?: number
}>`
  ${({ marginRight, maxWidth, minWidth }) =>
    `
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    ${marginRight && `margin-right: ${marginRight}px;`}
    ${maxWidth && `max-width: ${maxWidth}px;`}
    ${minWidth && `min-width: ${minWidth}px;`}
    min-width: 255px;
    justify-content: center;
  `}
`

export const StyledInputMinDown = styled(StyledInput)<StyledInputMinDownProps>`
  ${({ disabled, theme }) =>
    disabled &&
    `
      fieldset {
        border-color: ${theme.primitiveTokens.colors.gray[5]} !important;
      }
    `}
`
