import dayjs from 'dayjs'

import StatusBadge, { StatusBadgeColor } from '@/shared/components/StatusBadge'
import PrintReceiptButton from '@/modules/guardians/Negotiation/components/PrintReceiptButton'
import type { PaymentRowProps } from '@/modules/guardians/Negotiation/types'
import { CheckoutStatus, PaymentMethod } from 'src/shared/interfaces'
import { formatCentsToReal } from 'src/shared/utils'

import { Table } from '@gravity/table'
import { Wrapper } from './styles'

const PaymentTable = ({
  idForReceipt,
  amount,
  paymentMethod,
  status,
  onSuccess,
  installmentsNumber,
}: PaymentRowProps) => {
  const getPaymentStatus = () => {
    switch (status) {
      case CheckoutStatus.ABORTED:
        return {
          badgeColor: 'error',
          label: 'Abortado',
        }
      case CheckoutStatus.FINISHED:
        return {
          badgeColor: 'success',
          label: 'Paga',
        }
      case CheckoutStatus.ON_HOLD:
        return {
          badgeColor: 'grey',
          label: 'Aguardando',
        }
      case CheckoutStatus.PROCESSING:
        return {
          badgeColor: 'grey',
          label: 'Processando',
        }
    }
  }

  const paymentStatus = getPaymentStatus() as { badgeColor: StatusBadgeColor; label: string }

  const paymentMethodLabelMap: Record<PaymentMethod, string> = {
    [PaymentMethod.BANK_SLIP_PAYMENT_METHOD]: 'Boleto bancário    ',
    [PaymentMethod.CREDIT_CARD_PAYMENT_METHOD]: 'Cartão de crédito',
    [PaymentMethod.DEBIT_CARD_PAYMENT_METHOD]: 'Cartão de débito',
    [PaymentMethod.BANK_CHECK]: '',
    [PaymentMethod.BANK_DEPOSIT]: '',
    [PaymentMethod.BANK_TRANSFER]: '',
    [PaymentMethod.CASH]: '',
    [PaymentMethod.DIRECT_DEBIT]: '',
    [PaymentMethod.OTHER]: '',
    [PaymentMethod.PIX_PAYMENT_METHOD]: 'Pix',
    [PaymentMethod.SUBSCRIPTION]: '',
    [PaymentMethod.SCHOOL_PORTFOLIO_REPURCHASE]: '',
  }
  const numberInstallments = installmentsNumber ?? 1
  const amountPaid = amount / numberInstallments
  return (
    <Wrapper>
      <Table.Root>
        <Table.Head>
          <Table.HeaderCell name="installment">Parcela 1 de 1</Table.HeaderCell>
          <Table.HeaderCell name="payment_method">Meio de pagamento</Table.HeaderCell>
          <Table.HeaderCell name="payment_date">Data de pagamento</Table.HeaderCell>
          <Table.HeaderCell name="status">Situação</Table.HeaderCell>
          <Table.HeaderCell name="actions">Ações</Table.HeaderCell>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.TextCell>{formatCentsToReal(amount)}</Table.TextCell>
            <Table.TextCell subtext={`${numberInstallments}x de ${formatCentsToReal(amountPaid)}`}>
              {paymentMethodLabelMap[paymentMethod]}
            </Table.TextCell>
            <Table.TextCell>{`Hoje, ${dayjs().format('D MMMM')}`}</Table.TextCell>
            <Table.CustomCell>
              <div className="flex flex-row items-center gap-2">
                <StatusBadge color={paymentStatus.badgeColor} /> {paymentStatus.label}
              </div>
            </Table.CustomCell>
            <Table.CustomCell>
              <PrintReceiptButton
                receivableId={idForReceipt}
                label="Gerar comprovante"
                onSuccess={onSuccess}
              />
            </Table.CustomCell>
          </Table.Row>
        </Table.Body>
      </Table.Root>
    </Wrapper>
  )
}

export default PaymentTable
