import { useCallback, useEffect, useState } from 'react'
import { useQuery } from '@/shared/hooks/useQuery'

type QueryParams = Record<string, string | number>

const toSnakeCase = (str: string) => {
  return str.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()
}

export const useQueryParams = <T extends QueryParams>(defaultParams: T) => {
  const { query, setMultipleQueryParams } = useQuery()

  const getParamsFromUrl = (): T => {
    const params: Partial<T> = {}
    for (const key in defaultParams) {
      const value = query.get(toSnakeCase(key))
      if (value !== null) {
        params[key] = isNaN(Number(value))
          ? (value as T[Extract<keyof T, string>])
          : (Number(value) as T[Extract<keyof T, string>])
      } else {
        params[key] = defaultParams[key]
      }
    }
    return params as T
  }

  const [params, setParams] = useState<T>(() => getParamsFromUrl())

  useEffect(() => {
    const urlParams = getParamsFromUrl()
    const hasChanges = Object.keys(defaultParams).some(key => urlParams[key] !== params[key])
    if (hasChanges) {
      setParams(urlParams)
    }
  }, [query])

  const updateParams = useCallback(
    (updatedParams: Partial<T>) => {
      const data: Array<{ name: string; value: string }> = []
      for (const key in updatedParams) {
        if (updatedParams[key] !== undefined) {
          data.push({ name: toSnakeCase(key), value: updatedParams[key]?.toString() || '' })
        }
      }
      setMultipleQueryParams(data, 'replace')
    },
    [setMultipleQueryParams]
  )

  return { params, updateParams }
}
