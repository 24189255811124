import { Text } from '@gravity/text'

import { formatCentsToReal } from 'src/shared/utils'

import InstallmentCard from '../InstallmentCard'

import { v4 as uuidv4 } from 'uuid'

import * as S from './styles'
import { InstallmentDetailProps } from './types'
import StepFooter from '../StepFooter'
import { Separator } from '@gravity/separator'
import { useEffect } from 'react'

const InstallmentDetail = ({
  installmentsByStudents,
  stepName,
  totalAmount,
  onFinish,
  goBack,
  isLoading,
  hideDiscount = false,
  helpButtonEntry,
}: InstallmentDetailProps) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <S.ContentWrapper>
      <S.Wrapper>
        {installmentsByStudents.map((student, index) => (
          <div key={student.studentId}>
            {index / 2 !== 0 && (
              <div className="my-4">
                <Separator color="neutral-2" />
              </div>
            )}

            <S.StudentNameWrapper>
              <Text variant="subtitle-medium">{student.studentName}</Text>
            </S.StudentNameWrapper>

            {student.products.map(product => (
              <InstallmentCard
                key={product.productId + uuidv4()}
                productName={product.productName}
                installmentsLabel={product.consolidatedMonthNames}
                total={formatCentsToReal(product.finalAmount)}
                installmentsPrice={formatCentsToReal(product.baseAmount)}
                finesAndInterest={formatCentsToReal(product.finesAndInterest)}
                discount={formatCentsToReal(-product.discounts)}
                isOverdue={!!student.isOverdue}
                hideDiscount={hideDiscount}
              />
            ))}
          </div>
        ))}

        <StepFooter
          goBack={goBack}
          totalAmount={totalAmount}
          stepName={stepName ?? ''}
          showHelpButton
          helpButtonEntry={helpButtonEntry}
          btnPrimary={{
            loading: isLoading,
            onClick: onFinish,
          }}
        />
      </S.Wrapper>
    </S.ContentWrapper>
  )
}

export default InstallmentDetail
