import React from 'react'
import { CardVariant } from '../hooks/useStatusDetailCards'
import { TimeOutline, Alert, CheckboxCircle } from '@gravity/icons'

export const iconPath = ({ variant }: { variant: CardVariant }) => {
  return {
    [CardVariant.WARNING]: React.createElement(TimeOutline),
    [CardVariant.ALERT]: React.createElement(Alert),
    [CardVariant.SUCCESS]: React.createElement(CheckboxCircle),
  }[variant]
}
