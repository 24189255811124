import { UnleashFlags, useJWT, useUnleashFlag } from '@/shared/hooks'
import { CreateProduct } from '../pages/CreateProduct'
import { Products } from '../pages/Products'

import type { RouteDefinition } from '@/shared/core/router'

export const routes: RouteDefinition[] = [
  {
    key: 'PRODUCTS',
    path: '/produtos',
    component: Products,
    exact: true,
  },
  {
    key: 'CREATE-PRODUCTS-V2',
    path: '/v2/produtos/novo',
    component: CreateProduct,
    exact: true,
    canAccess: () => {
      const { isBackofficeUser } = useJWT()
      const ffEnabled = useUnleashFlag(UnleashFlags.ATV_568_ENABLE_CREATE_PRODUCT_V2)

      return isBackofficeUser || ffEnabled
    },
  },
]
