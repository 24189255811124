import { useEffect, useState } from 'react'
import { LayoutTemplate } from '../../components/LayoutTemplate'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useFetchOnboardingBatches } from '../../../hooks/useFetchOnboardingBatches'
import { useHistory } from 'react-router-dom'
import { useToast } from '@gravity/toast'
import { useUploadOnboardingFileMutation } from '../../../hooks/useUploadOnboardingFileMutation'
import { Loading } from '../../components/Loading'
import { GenericError } from '../../components/GenericError'
import { EventProvider, useEvents } from '../../contexts/eventsContext'
import { FileUpload, useFileUpload } from '@gravity/file-upload'
import { DashedArrowRight, Upload2Outline } from '@gravity/icons'
import { Text } from '@gravity/text'
import { Checkbox } from '@gravity/checkbox'

const PageComponent = () => {
  const { school, schoolSlug } = useSelectedSchool()
  const [checked, setChecked] = useState<boolean>(false)
  const schoolId = school?.id ?? ''
  const { toast } = useToast()

  const fileUpload = useFileUpload({
    maxFiles: 1,
    accept: '.xlsx',
    onFileReject: (_, error) => {
      if (error === 'maxFiles') {
        return toast({
          title: 'Selecione apenas um arquivo',
          type: 'alert',
        })
      }
    },
  })

  const file = fileUpload.acceptedFiles?.[0]?.file ?? null

  const history = useHistory()
  const events = useEvents()

  const {
    mutateAsync: executeUploadOnboardingFileMutation,
    isLoading: isLoadingUpload,
  } = useUploadOnboardingFileMutation({
    onError: () => {
      toast({
        title: 'Erro ao tentar importar planilha',
        type: 'error',
      })
    },
  })

  const { isLoading, isError } = useFetchOnboardingBatches(
    { schoolId: school?.id ?? '' },
    {
      onSuccess(data) {
        if (data.length === 0) return

        const onboardingBatch = data[0]
        if (onboardingBatch.status === 'PROCESSING')
          return history.push(`/${schoolSlug}/migrar-dados/${onboardingBatch.id}/migrar`)

        history.push(`/${schoolSlug}/migrar-dados/${onboardingBatch.id}/validar`)
      },
      onError() {
        toast({
          title: 'Erro ao tentar recuperar lote em andamento',
          type: 'error',
        })
      },
      retry: false,
    }
  )

  const handleFileSubmit = async () => {
    try {
      if (!file || !schoolId) return

      events?.startUploadButtonClicked()

      const onboardingBatch = await executeUploadOnboardingFileMutation({
        file,
        schoolId,
      })

      return history.push(`/${schoolSlug}/v2/migrar-dados/${onboardingBatch.id}`)
    } catch (error) {
      console.error(error)
    }
  }

  const navigateToHome = () => {
    return history.push(`/${schoolSlug}`)
  }

  useEffect(() => {
    events?.uploadPageViewed()
  }, [])

  if (isError) {
    return <GenericError />
  }

  return (
    <LayoutTemplate
      title="Ferramenta de importação"
      onClose={navigateToHome}
      primaryButton={{
        disabled: !file || checked !== true || isLoading,
        onClick: handleFileSubmit,
        loading: isLoadingUpload,
        label: 'Próximo',
        iconEnd: <DashedArrowRight />,
      }}
    >
      <div className="w-full h-full flex flex-col justify-between">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <div className="flex flex-col gap-6 items-center">
              <div className="flex flex-col items-center gap-2">
                <Text variant="body-1-regular" className="text-colors-text-main-2">
                  Escolha uma planilha para migrar
                </Text>
                <Text variant="caption-regular" className="text-colors-text-main-2 mt-2">
                  Formatos de documentos permitidos: XLSX
                </Text>
              </div>

              <FileUpload.RootProvider value={fileUpload}>
                <FileUpload.Trigger iconStart={<Upload2Outline />} variant="outline">
                  Selecionar arquivo
                </FileUpload.Trigger>
                <FileUpload.List />
              </FileUpload.RootProvider>
            </div>

            <div className="flex items-start gap-2">
              <Checkbox
                size={1}
                checked={checked}
                onCheckedChange={value => setChecked(Boolean(value))}
              />
              <Text variant="body-2-regular">
                Ao prosseguir, confirmo que todas as informações inseridas são autênticas e
                vinculadas a um aluno regularmente matriculado. O fornecimento de dados falsos ou
                inconsistentes poderá resultar na rescisão do contrato, suspensão de benefícios e
                responsabilização conforme previsto em contrato."
              </Text>
            </div>
          </>
        )}
      </div>
    </LayoutTemplate>
  )
}

export const UploadPage = () => {
  return (
    <EventProvider>
      <PageComponent />
    </EventProvider>
  )
}
