import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useApiClient } from '@/shared/hooks/useApiClient'

import { paymentDisputeService } from '@/shared/services/payment-dispute'

import {
  GetPaymentDisputesRequest,
  GetPaymentDisputesResponse,
  GetPaymentDisputeItemsRequest,
  GetPaymentDisputeItemsResponse,
  UpdatePaymentDisputeItemStatusRequest,
  UpdatePaymentDisputeItemStatusResponse,
  GetPaymentDisputeStatusSummaryResponse,
} from '@/shared/interfaces/paymentDispute'

const paymentDisputeWithItemsKey = 'payment-dispute-items'
const paymentDisputesKey = 'payment-disputes'

export const useGetPaymentDisputeItems = (
  params: GetPaymentDisputeItemsRequest,
  options?: UseQueryOptions<GetPaymentDisputeItemsResponse>
) => {
  const { apiClient } = useApiClient()

  const service = paymentDisputeService(apiClient.getClients().privateApi)

  return useQuery<GetPaymentDisputeItemsResponse>(
    [paymentDisputeWithItemsKey, params],
    () => service.getPaymentDisputeItems(params),
    options
  )
}

export const useMutateOnUpdatePaymentDisputeItemStatus = (
  options: UseMutationOptions<
    UpdatePaymentDisputeItemStatusResponse,
    unknown,
    UpdatePaymentDisputeItemStatusRequest
  >
) => {
  const { apiClient } = useApiClient()

  const service = paymentDisputeService(apiClient.getClients().privateApi)

  return useMutation(service.updatePaymentDisputeItemStatus, options)
}

export const useGetPaymentDisputes = (
  params: GetPaymentDisputesRequest,
  options?: UseQueryOptions<GetPaymentDisputesResponse, XMLHttpRequest>
) => {
  const { apiClient } = useApiClient()

  const service = paymentDisputeService(apiClient.privateApi)

  const queryKey = [paymentDisputesKey, params]

  const {
    data: paymentDisputesResponse,
    refetch: refetchPaymentDisputes,
    isLoading: isLoadingPaymentDisputes,
    isRefetching,
    isError,
    isSuccess,
  } = useQuery<GetPaymentDisputesResponse, XMLHttpRequest>(
    queryKey,
    () => service.fetchPaymentDisputeListBySchool(params),
    options
  )

  return {
    paymentDisputesResponse,
    refetchPaymentDisputes,
    isLoadingPaymentDisputes,
    isRefetching,
    isError,
    isSuccess,
  }
}

export const useGetPaymentDisputeStatusSummary = (
  schoolId: uuid,
  options?: UseQueryOptions<GetPaymentDisputeStatusSummaryResponse, XMLHttpRequest>
) => {
  const { apiClient } = useApiClient()

  const service = paymentDisputeService(apiClient.privateApi)

  const queryKey = [paymentDisputesKey, schoolId]

  const {
    data: paymentDisputeStatusSummary,
    refetch: refetchPaymentDisputeStatusSummary,
    isLoading: isLoadingPaymentDisputeStatusSummary,
    isRefetching,
    isError,
    isSuccess,
  } = useQuery<GetPaymentDisputeStatusSummaryResponse, XMLHttpRequest>(
    queryKey,
    () => service.fetchPaymentDisputeStatusSummaryBySchool(schoolId),
    options
  )

  return {
    paymentDisputeStatusSummary,
    refetchPaymentDisputeStatusSummary,
    isLoadingPaymentDisputeStatusSummary,
    isRefetching,
    isError,
    isSuccess,
  }
}
