import { useEffect, useMemo } from 'react'
import type { ColumnProps, CustomizationTableProps, TableRowProps } from './types'
import { columnNames } from './types'

import { View } from './view'
import { useCalculateAmount } from './hooks/useCalculateAmount'

import { useFetchCampaignStudents } from '@monorepo/enrollment/hooks/queries/useCampaignQueries'
import { usePagination } from '@/shared/hooks/usePagination'
import { useParams } from 'react-router-dom'
import type { URLParams } from '@monorepo/enrollment/pages/Campaigns/AddBulkInstallment/types'
import { CampaignStudentExcludesRequest } from '@monorepo/enrollment/services/enrollmentService/types'
import { useAddBulkInstallmentStore } from '@monorepo/enrollment/store/AddBulkInstallmentStore'

import { formatCentsToReal } from '@/shared/utils'
import type { FormProps } from '@monorepo/enrollment/pages/Campaigns/CampaignDetails/components/InstallmentForm/types'

export const DEFAULT_ITEMS_PER_PAGE = 10

export const CustomizationStepTable = ({
  setAddCustomTuitionDialogState,
  setEnableAddInstallments,
}: CustomizationTableProps) => {
  const { pagination, updatePaginationValue } = usePagination(1, DEFAULT_ITEMS_PER_PAGE)

  const paginationSettings = {
    page: pagination.page,
    per_page: pagination.itemsPerPage,
  }
  const { campaignId } = useParams<URLParams>()
  const { data: campaignStudentsData, isLoading: isLoadingStudents } = useFetchCampaignStudents(
    {
      campaignId,
      excludes: [
        CampaignStudentExcludesRequest.enrollmentCanceled,
        CampaignStudentExcludesRequest.enrollmentWithTuition,
      ],
      pagination: paginationSettings,
    },
    {
      staleTime: 0,
    }
  )

  const {
    customInstallmentsFormData,
    excludedEnrollmentIds,
    formData,
  } = useAddBulkInstallmentStore()

  const { calculateTotalAmount, calculateTuitionAmountWithDiscount } = useCalculateAmount()

  const campaignStudents = campaignStudentsData?.data?.students ?? []

  useEffect(() => {
    if (campaignStudents?.length > 0) {
      setEnableAddInstallments(true)
    }
  }, [campaignStudents])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const rows = useMemo((): TableRowProps[] => {
    return campaignStudents.map(student => {
      const customConfig: FormProps =
        customInstallmentsFormData.get(student.enrollment_id) ?? formData

      const { duration_months, custom_monthly_amount, discounts } = customConfig
      const valueWithDiscount = calculateTuitionAmountWithDiscount(custom_monthly_amount, discounts)
      return {
        columns: {
          [columnNames.STUDENT_NAME]: student.student_name,
          [columnNames.TUITION_QUANTITY]: duration_months.toString(),
          [columnNames.TUITION_AMOUNT_WITH_DISCOUNT]: formatCentsToReal(
            valueWithDiscount.toString() ?? '0'
          ),
          [columnNames.TOTAL_AMOUNT]: formatCentsToReal(
            calculateTotalAmount(valueWithDiscount, +duration_months).toString() ?? '0'
          ),
        },
        key: student.enrollment_id,
        enrollmentID: student.enrollment_id,
      }
    })
  }, [
    campaignStudents,
    customInstallmentsFormData,
    formData,
    excludedEnrollmentIds,
    calculateTotalAmount,
    calculateTuitionAmountWithDiscount,
  ])

  const columnHeaders: Array<ColumnProps> = [
    { key: columnNames.STUDENT_NAME, label: 'Aluno', sortable: false, minWidth: '190px' },
    {
      key: columnNames.TUITION_QUANTITY,
      label: 'Número de parcelas',
      sortable: false,
      minWidth: '136px',
    },
    {
      key: columnNames.TUITION_AMOUNT_WITH_DISCOUNT,
      label: 'Valor da parcela com desconto',
      sortable: false,
      minWidth: '136px',
    },
    {
      key: columnNames.TOTAL_AMOUNT,
      label: 'Valor Total',
      sortable: false,
      minWidth: '136px',
    },
  ]

  return (
    <View
      pagination={pagination}
      campaignStudentsData={campaignStudentsData}
      columnHeaders={columnHeaders}
      isLoading={isLoadingStudents}
      excludedEnrollmentIds={excludedEnrollmentIds}
      rows={rows}
      setAddCustomTuitionDialogState={setAddCustomTuitionDialogState}
      updatePaginationValue={updatePaginationValue}
      noDataMessage="Todos alunos desta campanha já têm mensalidades adicionadas"
    />
  )
}
