import { validateCpf } from '@/shared/utils'
import { z } from 'zod'

export const EditStudentSchema = z
  .object({
    name: z.string().trim().min(1, { message: 'Informe o nome do aluno' }),
    birthDate: z
      .string()
      .refine(dateString => dateString !== '', {
        message: 'Informe a data de nascimento',
      })
      .refine(
        value => {
          const stringDate = value.trim()

          const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/
          const match = stringDate.trim().match(regex)

          if (!match) {
            return false
          }

          const day = parseInt(match[1], 10)
          const month = parseInt(match[2], 10)
          const year = parseInt(match[3], 10)

          if (month < 1 || month > 12) {
            return false
          }

          const daysInMonth = new Date(year, month, 0).getDate()
          if (day < 1 || day > daysInMonth) {
            return false
          }

          return true
        },
        {
          message: 'Informe uma data válida',
        }
      ),
    taxID: z.string().optional(),
    noTaxID: z.boolean(),
  })
  .refine(
    schema => {
      return schema.noTaxID || schema.taxID?.length === 11
    },
    { message: 'Informe um CPF válido', path: ['taxID'] }
  )
  .refine(
    schema => {
      return schema.noTaxID || validateCpf(schema.taxID)
    },
    { message: 'Informe um CPF válido', path: ['taxID'] }
  )

export type EditStudentForm = z.infer<typeof EditStudentSchema>
