import theme from '@/shared/theme'
import styled from 'styled-components'

const { spacing } = theme.primitiveTokens

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .table {
    display: flex;
    justify-content: center;
    margin: 0 -${spacing[16]};
  }
`

export const TopPageWrapper = styled.div`
  background-color: ${props => props.theme.gravity.colors['colors-background-neutral-2']};
  margin: 0 -${spacing[16]};

  display: flex;
  justify-content: center;
`

export const AlertBannerWrapper = styled.div`
  margin: 0 -${spacing[16]};
`

export const Header = styled.div`
  margin: ${spacing[8]} 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: ${spacing[6]};
`

export const CardContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: ${spacing[8]};
  min-width: 100%;
`

export const TabContainer = styled.div`
  padding-bottom: 96px;
`
