import { EmptyState as GravityEmptyState } from '@gravity/emptystate'
import { Check } from '@gravity/icons'
export const EmptyState = ({ referenceYear }: { referenceYear: string }) => {
  return (
    <GravityEmptyState
      title={`Tudo certo por aqui: sem correções necessárias em ${referenceYear}!`}
      subtitle="É essencial manter as correções em dia para garantir que as cobranças aconteçam. Ah, não esquece de checar os períodos letivos anteriores, tá?"
      type="success"
      icon={<Check size="xl" />}
    />
  )
}
