import { useEffect, useState } from 'react'

import { Button } from '@gravity/button'
import { Tooltip } from '@gravity/tooltip'
import { LoopRight } from '@gravity/icons'

import { timestampToMMSS } from '../../utils/timestampToMMSS'
import { UnleashFlags, useUnleashFlag, useSnackbar } from '@/shared/hooks'
import { useRefreshStudentSituation } from './hooks/useRefreshStudentSituation'
import { useCountdown } from './hooks/useCountdown'

import { LOCALSTORAGE_TIMESTAMP_KEY, RETRY_TIMEOUT_MS } from './constants'
import { UpdateEnrollmentStatusProps } from './types'

export const UpdateEnrollmentStatus = ({
  schoolId,
  studentIds,
  isLoadingInstallments,
}: UpdateEnrollmentStatusProps) => {
  const [installmentsHaveBeenUpdated, setInstallmentsHaveBeenUpdated] = useState<boolean>(false)
  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)

  const { setIsOpen, setMessage, setVariation, setKeepOpen } = useSnackbar()

  const hasBufferInstallments = studentIds?.length > 0

  const { handleRefreshStudentsSituation, isLoading } = useRefreshStudentSituation(
    schoolId,
    studentIds
  )

  const { setExpTimestamp, shouldWait, timestampDiff } = useCountdown(setShowTooltip)

  const showStudentSituationSuccess = () => {
    setMessage('A situação das parcelas foi atualizada com sucesso')
    setVariation('success')
    setIsOpen(true)
  }

  if (!hasBufferInstallments && installmentsHaveBeenUpdated) {
    showStudentSituationSuccess()
  }

  useEffect(() => {
    setKeepOpen(true)

    return () => {
      setKeepOpen(false)
    }
  }, [])

  const showStudentSituationWarning = () => {
    setMessage(
      'Se algum pagamento foi feito pode ser que ele ainda não tenha sido compensado, tente novamente mais tarde.'
    )
    setVariation('warning')
    setIsOpen(true, {
      title: 'Não houve mudanças na situação da parcela.',
    })
  }

  const handleButtonClick = async () => {
    setShowTooltip(false)
    await handleRefreshStudentsSituation()
    setInstallmentsHaveBeenUpdated(true)
  }

  useEffect(() => {
    if (!isLoadingInstallments && installmentsHaveBeenUpdated) {
      const exp = Date.now() + RETRY_TIMEOUT_MS
      localStorage.setItem(LOCALSTORAGE_TIMESTAMP_KEY, exp.toString())
      setExpTimestamp(exp)

      showStudentSituationWarning()
      setInstallmentsHaveBeenUpdated(false)
    }
  }, [isLoadingInstallments])

  const isLoadingInstallmentsOrLoadingSituation = isLoadingInstallments || isLoading

  if (!hasBufferInstallments || isIntegratedSchool) {
    return null
  }

  return (
    <Tooltip
      text={`Utilize caso tenha recebido algum pagamento\nque pode alterar a situação de parcela do aluno.\nPara pagamentos em boleto, o tempo de\ncompensação pode ser até 3 dias.`}
      hidden={!showTooltip || shouldWait || isLoadingInstallmentsOrLoadingSituation}
      position="bottom"
    >
      <Button
        data-testid="update-enrollment-status-button"
        variant="ghost"
        onClick={handleButtonClick}
        loading={isLoadingInstallmentsOrLoadingSituation}
        disabled={shouldWait || isLoadingInstallments}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {!shouldWait && <LoopRight size="sm" className="mr-3 max-h-5 max-w-5" />}
        <span>
          {shouldWait
            ? `Atualização disponível em ${timestampToMMSS(timestampDiff)}`
            : 'Atualizar situação'}
        </span>
      </Button>
    </Tooltip>
  )
}
