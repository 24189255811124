import { useCallback, useEffect, useMemo, useState } from 'react'
import type { QueryObserverResult } from '@tanstack/react-query'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import {
  useEnrollmentPayoutReports,
  useTuitionPayoutReports,
  useTuitionPayoutReport,
} from '@/shared/hooks/queries/report'

import { formatNumberMicroCentsToReal } from '@/shared/utils/numberFormatters'
import { findPayoutByDate } from '@monorepo/report/utils/findPayoutByDate'
import { tuitionPayoutReportDataMapper } from './utils/tuitionPayoutReportDataMapper'
import { enrollmentPayoutReportDataMapper } from './utils/enrollmentPayoutReportDataMapper'

import type { PayoutSummary } from '@monorepo/report/models/PayoutSummary'
import type { UnguaranteedReport } from '@monorepo/report/models/UnguaranteedReport'
import type { TuitionPayoutReportData } from '../../components/TuitionReportDashboardCard'
import type { EnrollmentPayoutReportData } from '../../components/EnrollmentReportDashboardCard'
import type { FetchPayoutDetailsResponseDTO } from '@/shared/services/report/types'
import { IsaacPayPayoutReportData } from '../../components/IsaacPayReportDashboardCard'
import { useIsaacPayPayoutReports } from '@monorepo/report/hooks/queries/useIsaacPayPayoutReports'
import { isaacPayPayoutReportDataMapper } from './utils/isaacPayPayoutReportDataMapper'

type PayoutReportDashboardDataValue = {
  currentEnrollmentPayoutReport: EnrollmentPayoutReportData | undefined
  currentIsaacPayPayoutReport: IsaacPayPayoutReportData | undefined
  currentTuitionPayoutReport: TuitionPayoutReportData | undefined
  enrollmentError: boolean
  enrollmentLoading: boolean
  enrollmentPayoutReportList: EnrollmentPayoutReportData[]
  isaacPayError: boolean
  isaacPayLoading: boolean
  isaacPayPayoutReportList: IsaacPayPayoutReportData[]
  refetchEnrollment: () => Promise<QueryObserverResult<UnguaranteedReport[], unknown>>
  refetchIsaacPay: () => Promise<QueryObserverResult<UnguaranteedReport[], unknown>>
  refetchTuition: () =>
    | Promise<QueryObserverResult<PayoutSummary[], unknown>>
    | Promise<QueryObserverResult<FetchPayoutDetailsResponseDTO<'tuition'>, unknown>>
  tuitionError: boolean
  tuitionLoading: boolean
  tuitionPayoutDetailsLoading: boolean
  tuitionPayoutReportList: TuitionPayoutReportData[]
  updateCurrentEnrollmentPayoutReport: (payout: EnrollmentPayoutReportData) => void
  updateCurrentIsaacPayPayoutReport: (payout: IsaacPayPayoutReportData) => void
  updateCurrentTuitionPayoutReport: (payout: TuitionPayoutReportData) => void
}

/**
 * Custom hook that exports payout report dashboard data
 */
export const usePayoutReportDashboardData = (): PayoutReportDashboardDataValue => {
  const { school } = useSelectedSchool()

  const [
    currentTuitionPayoutReport,
    setCurrentTuitionPayoutReport,
  ] = useState<TuitionPayoutReportData>()

  const [
    currentEnrollmentPayoutReport,
    setCurrentEnrollmentPayoutReport,
  ] = useState<EnrollmentPayoutReportData>()

  const [
    currentIsaacPayPayoutReport,
    setCurrentIsaacPayPayoutReport,
  ] = useState<IsaacPayPayoutReportData>()

  const {
    data: tuitionData,
    isError: tuitionError,
    isLoading: tuitionLoading,
    refetch: refetchTuition,
  } = useTuitionPayoutReports(school?.id ?? '', { enabled: !!school?.id })

  /**
   * INFO
   *
   * This api request is needed because the payout list `tuitionData` returns
   * the payout amount as `null` for opened payouts
   */
  const {
    data: tuitionPayoutDetailsData,
    isError: tuitionPayoutDetailsError,
    isLoading: tuitionPayoutDetailsLoading,
    refetch: refetchTuitionPayoutDetails,
  } = useTuitionPayoutReport(
    { payoutId: currentTuitionPayoutReport?.id ?? '' },
    { enabled: !!currentTuitionPayoutReport?.isOpen }
  )

  const {
    data: enrollmentData,
    isError: enrollmentError,
    isLoading: enrollmentLoading,
    refetch: refetchEnrollment,
  } = useEnrollmentPayoutReports(school?.id ?? '', { enabled: !!school?.id })

  const {
    data: isaacPayData,
    isError: isaacPayError,
    isLoading: isaacPayLoading,
    refetch: refetchIsaacPay,
  } = useIsaacPayPayoutReports(school?.id ?? '', { enabled: !!school?.id })

  useEffect(() => {
    if (!tuitionPayoutDetailsData) return

    const payoutValue = formatNumberMicroCentsToReal(
      tuitionPayoutDetailsData.data.agregations.financial_details.total
    )

    setCurrentTuitionPayoutReport(prevState => {
      if (!prevState) return undefined

      return { ...prevState, payoutValue }
    })
  }, [tuitionPayoutDetailsData])

  useEffect(() => {
    if (!tuitionData) return

    if (tuitionData.length === 0) return

    const payout = findPayoutByDate(new Date(), tuitionData)

    const currentPayout = payout ?? tuitionData[tuitionData.length - 1]

    setCurrentTuitionPayoutReport(tuitionPayoutReportDataMapper(currentPayout))
  }, [tuitionData])

  useEffect(() => {
    if (!enrollmentData) return

    if (enrollmentData.length === 0) return

    const currentPayout = enrollmentData[enrollmentData.length - 1]

    setCurrentEnrollmentPayoutReport(enrollmentPayoutReportDataMapper(currentPayout))
  }, [enrollmentData])

  useEffect(() => {
    if (!isaacPayData) return

    if (isaacPayData.length === 0) return

    const currentPayout = isaacPayData[isaacPayData.length - 1]

    setCurrentIsaacPayPayoutReport(isaacPayPayoutReportDataMapper(currentPayout))
  }, [isaacPayData])

  const updateCurrentTuitionPayoutReport = useCallback(
    (payout: TuitionPayoutReportData) => {
      setCurrentTuitionPayoutReport(payout)
    },
    [setCurrentTuitionPayoutReport]
  )

  const updateCurrentEnrollmentPayoutReport = useCallback(
    (payout: EnrollmentPayoutReportData) => {
      setCurrentEnrollmentPayoutReport(payout)
    },
    [setCurrentEnrollmentPayoutReport]
  )

  const updateCurrentIsaacPayPayoutReport = useCallback(
    (payout: IsaacPayPayoutReportData) => {
      setCurrentIsaacPayPayoutReport(payout)
    },
    [setCurrentIsaacPayPayoutReport]
  )

  const hookValue = useMemo<PayoutReportDashboardDataValue>(() => {
    return {
      tuitionPayoutReportList: tuitionData?.map(item => tuitionPayoutReportDataMapper(item)) ?? [],
      currentTuitionPayoutReport,
      tuitionError: tuitionError || tuitionPayoutDetailsError,
      tuitionLoading,
      tuitionPayoutDetailsLoading: currentTuitionPayoutReport?.isOpen
        ? tuitionPayoutDetailsLoading
        : false,
      refetchTuition: tuitionError ? refetchTuition : refetchTuitionPayoutDetails,
      updateCurrentTuitionPayoutReport,
      enrollmentPayoutReportList:
        enrollmentData?.map(item => enrollmentPayoutReportDataMapper(item)) ?? [],
      currentEnrollmentPayoutReport,
      enrollmentError,
      enrollmentLoading,
      refetchEnrollment,
      updateCurrentEnrollmentPayoutReport,
      isaacPayPayoutReportList:
        isaacPayData?.map(item => isaacPayPayoutReportDataMapper(item)) ?? [],
      currentIsaacPayPayoutReport,
      isaacPayError,
      isaacPayLoading,
      refetchIsaacPay,
      updateCurrentIsaacPayPayoutReport,
    }
  }, [
    tuitionData,
    tuitionError,
    tuitionLoading,
    refetchTuition,
    tuitionPayoutDetailsError,
    tuitionPayoutDetailsLoading,
    refetchTuitionPayoutDetails,
    currentTuitionPayoutReport,
    updateCurrentTuitionPayoutReport,
    enrollmentData,
    enrollmentError,
    enrollmentLoading,
    refetchEnrollment,
    currentEnrollmentPayoutReport,
    updateCurrentEnrollmentPayoutReport,
    isaacPayData,
    isaacPayError,
    isaacPayLoading,
    refetchIsaacPay,
    currentIsaacPayPayoutReport,
    updateCurrentIsaacPayPayoutReport,
  ])

  return hookValue
}
