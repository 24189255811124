import { Text } from '@gravity/text'

import { Receivable } from 'src/shared/interfaces'
import { CombinableReceivable } from 'src/escolas/contexts/receivablesAgglutinationContext'
import { formatCentsToReal } from 'src/shared/utils'

export type SelectedReceivableAgglutinationProps = {
  baseAmount: cents
  receivable?: Receivable
  receivables: CombinableReceivable[]
}

const SelectedReceivableAgglutination = ({
  receivables,
  baseAmount,
}: SelectedReceivableAgglutinationProps) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2 justify-between">
        <Text variant="body-1-medium">Parcelas Selecionadas</Text>
      </div>
      {receivables?.map(({ amount, orderReference }) => (
        <div className="flex gap-2 justify-between" key={orderReference}>
          <Text variant="body-1-regular">Parcela {orderReference}</Text>
          <Text variant="body-1-regular">{formatCentsToReal(amount)}</Text>
        </div>
      ))}
      <div className="flex gap-2 justify-between">
        <Text variant="body-1-regular">Valor parcial</Text>
        <Text variant="body-1-regular">{formatCentsToReal(baseAmount)}</Text>
      </div>
    </div>
  )
}

export default SelectedReceivableAgglutination
