import { DiscountType, Receivable } from 'src/shared/interfaces'
import { formatCentsToReal } from 'src/shared/utils'
import { Text } from '@gravity/text'
import { Separator } from '@gravity/separator'
import FinancialSummaryDiscount from './FinancialSummaryDiscount'

export type ReceivableFinancialSummaryProps = {
  isAgglutinated?: boolean
  receivable: Receivable
}

const ReceivableFinancialSummary = ({
  isAgglutinated,
  receivable,
}: ReceivableFinancialSummaryProps) => {
  const labelBaseAmount = isAgglutinated ? 'Novo valor padrão' : 'Valor padrão'

  const expectedLabel = (receivable?.total_amount_paid ?? 0) > 0 ? 'Valor pago' : 'Valor esperado'
  const expectedValue = receivable?.current_amount

  const discounts = receivable?.discounts

  const filterDiscountsByType = (discountType: DiscountType) =>
    discounts?.filter(discount => discount.type === discountType)

  const perpetualDiscounts = filterDiscountsByType(DiscountType.PERPETUAL)
  const earlyPaymentDiscounts = filterDiscountsByType(DiscountType.EARLY_PAYMENT)
  const duePaymentDiscounts = filterDiscountsByType(DiscountType.DUE_PAYMENT)
  const additionalDiscounts = filterDiscountsByType(DiscountType.ADDITIONAL_ON_MANUAL_LIQUIDATION)

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex justify-between">
        <Text variant="body-1-regular">{labelBaseAmount}</Text>
        <Text variant="body-1-regular">{formatCentsToReal(receivable?.original_amount ?? 0)}</Text>
      </div>
      <div className="flex justify-between" data-testid={`${DiscountType.PERPETUAL}-summary`}>
        <Text variant="body-1-regular">Desconto permanente</Text>
        <Text variant="body-1-regular">
          -{formatCentsToReal(receivable?.current_perpetual_discount ?? 0)}
        </Text>
      </div>
      {perpetualDiscounts?.map((perpetualDiscount, index) => (
        <FinancialSummaryDiscount
          key={`${DiscountType.PERPETUAL}-${index}`}
          testId={`${DiscountType.PERPETUAL}-${index}`}
          discountType={DiscountType.PERPETUAL}
          discount={perpetualDiscount}
        />
      ))}
      <div className="flex justify-between" data-testid={`${DiscountType.DUE_PAYMENT}-summary`}>
        <Text variant="body-1-regular">Desconto de pontualidade</Text>
        <Text variant="body-1-regular">
          -{formatCentsToReal(receivable?.current_due_payment_discount ?? 0)}
        </Text>
      </div>
      {duePaymentDiscounts?.map((duePaymentDiscount, index) => (
        <FinancialSummaryDiscount
          key={`${DiscountType.DUE_PAYMENT}-${index}`}
          testId={`${DiscountType.DUE_PAYMENT}-${index}`}
          discountType={DiscountType.DUE_PAYMENT}
          discount={duePaymentDiscount}
        />
      ))}
      <div className="flex justify-between" data-testid={`${DiscountType.EARLY_PAYMENT}-summary`}>
        <Text variant="body-1-regular">Desconto de antecipação</Text>
        <Text variant="body-1-regular">
          -{formatCentsToReal(receivable?.current_early_payment_discount ?? 0)}
        </Text>
      </div>
      {earlyPaymentDiscounts?.map((earlyPaymentDiscount, index) => (
        <FinancialSummaryDiscount
          key={`${DiscountType.EARLY_PAYMENT}-${index}`}
          testId={`${DiscountType.EARLY_PAYMENT}-${index}`}
          discountType={DiscountType.EARLY_PAYMENT}
          discount={earlyPaymentDiscount}
        />
      ))}
      <div
        className="flex justify-between"
        data-testid={`${DiscountType.ADDITIONAL_ON_MANUAL_LIQUIDATION}-summary`}
      >
        <Text variant="body-1-regular">Desconto na baixa</Text>
        <Text variant="body-1-regular">
          -{formatCentsToReal(receivable?.current_additional_discount ?? 0)}
        </Text>
      </div>
      {additionalDiscounts?.map((additionalDiscount, index) => (
        <FinancialSummaryDiscount
          key={`${DiscountType.ADDITIONAL_ON_MANUAL_LIQUIDATION}-${index}`}
          testId={`${DiscountType.ADDITIONAL_ON_MANUAL_LIQUIDATION}-${index}`}
          discountType={DiscountType.ADDITIONAL_ON_MANUAL_LIQUIDATION}
          discount={additionalDiscount}
        />
      ))}
      {(receivable?.credit_card_fee ?? 0) > 0 && (
        <div className="flex justify-between">
          <Text variant="body-1-regular">Taxa do cartão</Text>
          <Text variant="body-1-regular">
            {formatCentsToReal(receivable?.credit_card_fee ?? 0)}
          </Text>
        </div>
      )}
      <div className="flex justify-between">
        <Text variant="body-1-regular">Multa</Text>
        <Text variant="body-1-regular">{formatCentsToReal(receivable?.current_fine ?? 0)}</Text>
      </div>
      <div className="flex justify-between">
        <Text variant="body-1-regular">Juros</Text>
        <Text variant="body-1-regular">{formatCentsToReal(receivable?.current_interest ?? 0)}</Text>
      </div>
      <div className="flex justify-between">
        <Text variant="subtitle-medium">{expectedLabel}</Text>
        <Text variant="subtitle-medium">{formatCentsToReal(expectedValue ?? 0)}</Text>
      </div>
      <div className="mt-4">
        <Separator color="neutral-2" />
      </div>
    </div>
  )
}

export default ReceivableFinancialSummary
