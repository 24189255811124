import styled, { css } from 'styled-components'

import type { DescriptionProps } from './types'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: ${({ theme }) => theme.gravity.spacing['2']};
`
export const DescriptionWrapper = styled.span<DescriptionProps>`
  ${props =>
    props.disabled &&
    css`
      > span {
        color: ${props.theme.gravity.colors['colors-text-main-4']};
      }
    `}
`
