import { Skeleton } from '@gravity/skeleton'
import { Grid, GridItem } from '@gravity/grid'
import { ResponsiveContainer } from './styles'
import {
  SkeletonCards,
  SkeletonLargeCard,
  SkeletonSmallCard,
} from '../../../components/SkeletonCards'

export const LoadingHome = () => {
  return (
    <>
      <ResponsiveContainer>
        <Grid className="grid-1">
          <GridItem>
            <Skeleton radius={8} fullWidth height={485} />
          </GridItem>
          <div />
        </Grid>
      </ResponsiveContainer>
      <ResponsiveContainer>
        <Grid className="grid-2">
          <GridItem>
            <Skeleton radius={8} width={548} height={38} />
          </GridItem>
          <SkeletonCards cardsNumber={3} />

          <SkeletonLargeCard />

          <SkeletonSmallCard />
        </Grid>
      </ResponsiveContainer>
    </>
  )
}
