import { StepTitleProps } from './types'
import * as S from './styles'
import { formatInstallmentTextCount } from '../../helpers/formatInfoText'
import { Text } from '@gravity/text'

const StepTitle = ({
  studentName,
  productName,
  overdueCount,
  ondueCount,
  totalAmount,
}: StepTitleProps) => {
  return (
    <S.WrapperColumn>
      <Text variant="subtitle-medium">{`${studentName} - ${productName}`}</Text>
      <S.Wrapper>
        <Text variant="body-2-regular">
          {formatInstallmentTextCount(overdueCount, ondueCount, totalAmount)}
        </Text>
      </S.Wrapper>
    </S.WrapperColumn>
  )
}

export default StepTitle
