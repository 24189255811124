import styled from 'styled-components'

export const ActionButtonsWrapper = styled.div`
  display: flex;
  align-items: start;
  gap: ${props => props.theme.gravity.spacing[4]};
`
export const ActionsContainer = styled.div`
  margin-top: ${props => props.theme.gravity.spacing[2]};
  margin-bottom: ${props => props.theme.gravity.spacing[6]};
  display: flex;
  justify-content: space-between;
  align-content: center;
  gap: ${props => props.theme.gravity.spacing[6]};
`
export const ActionNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.gravity.spacing[2]};
  margin-bottom: ${props => props.theme.gravity.spacing[2]};
`
export const HeaderSkeleton = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: ${props => props.theme.gravity.spacing[20]};
  width: 100%;
`
export const ActionButtonsSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const InfoHeaderSkeleton = styled.div`
  display: flex;
  gap: ${props => props.theme.gravity.spacing[2]};
  flex-direction: column;
  width: 100%;

  & > :nth-child(3) {
    width: 50%;
  }
`
export const MainHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
`
