import styled from 'styled-components'
import { Card } from '@gravity/card'

export const StyledRows = styled(Card)`
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: ${props => props.theme.gravity.spacing[4]};
`

export const ContainerCard = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${props => props.theme.gravity.spacing[8]};
`

export const SmallContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[12]};
  padding: ${props => props.theme.gravity.spacing[14]};
`
export const LargeContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[10]};
  padding: ${props => props.theme.gravity.spacing[14]};
`
