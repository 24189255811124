import { useAgreement } from 'src/escolas/contexts/agreementContext'
import { PaymentPlan, SimulationPaymentMethod } from '@/modules/guardians/models/agreement'
import { getOptionInformations } from '../../helpers/OptionInformationText'
import CardForm from '../Forms/CardForm'
import { Text } from '@gravity/text'
import type { MethodsProps } from './types'
import {
  RadioGroupStyled,
  Container,
  FullWidthDiv,
  Title,
} from '@/modules/guardians/Negotiation/EntryNegotiation/steps/SelectPaymentMethod/styles'
import OptionCard from '@/modules/guardians/Negotiation/components/OptionCard/OptionCard'
import { usePaymentCreditCard } from 'src/escolas/hooks'
import { Separator } from '@gravity/separator'
import { CardStyled } from '../../styles'

const PosMethod = ({ handleValidate, totalAmount, handleValue }: MethodsProps) => {
  const { selectedSimulationPaymentMethod, setSelectedSimulationPaymentMethod } = useAgreement()
  const { paymentFee } = usePaymentCreditCard()

  const isEnabledCreditCardFee = paymentFee?.enabled && paymentFee?.value > 0
  const totalAmountWithFee = paymentFee?.enabled ? totalAmount + paymentFee.value : totalAmount

  const defaultPlan: PaymentPlan = {
    max_installments_allowed: 1,
    min_down_payment: 0,
    payment_method: SimulationPaymentMethod.CREDIT_CARD,
  }

  return (
    <Container>
      <Title>
        <Text variant="subtitle-regular">Selecione a forma de pagamento</Text>
      </Title>
      <CardStyled className="mb-4">
        <RadioGroupStyled
          style={{ display: 'flex', flexDirection: 'column' }}
          aria-label="form of payment"
          name="radio-buttons-form-of-payment"
        >
          <FullWidthDiv>
            <OptionCard
              informationText={getOptionInformations(
                SimulationPaymentMethod.CREDIT_CARD,
                totalAmountWithFee,
                defaultPlan,
                paymentFee
              )}
              type={SimulationPaymentMethod.CREDIT_CARD}
              isPaymentFeeEnabled={isEnabledCreditCardFee}
              isSelected={selectedSimulationPaymentMethod === SimulationPaymentMethod.CREDIT_CARD}
              onSelect={setSelectedSimulationPaymentMethod}
            >
              <CardForm
                handleValidate={handleValidate}
                handleValue={handleValue}
                paymentPlan={defaultPlan}
                paymentOption={SimulationPaymentMethod.CREDIT_CARD}
                maxInstallment={1}
                totalAmount={totalAmountWithFee}
              />
            </OptionCard>
          </FullWidthDiv>
          <Separator color="neutral-2" />
          <FullWidthDiv>
            <OptionCard
              informationText={getOptionInformations(
                SimulationPaymentMethod.DEBIT_CARD,
                totalAmount,
                defaultPlan,
                paymentFee
              )}
              type={SimulationPaymentMethod.DEBIT_CARD}
              isPaymentFeeEnabled={isEnabledCreditCardFee}
              isSelected={selectedSimulationPaymentMethod === SimulationPaymentMethod.DEBIT_CARD}
              onSelect={setSelectedSimulationPaymentMethod}
            >
              <CardForm
                handleValidate={handleValidate}
                handleValue={handleValue}
                paymentPlan={defaultPlan}
                paymentOption={SimulationPaymentMethod.DEBIT_CARD}
                maxInstallment={1}
                totalAmount={totalAmount}
              />
            </OptionCard>
          </FullWidthDiv>
        </RadioGroupStyled>
      </CardStyled>
    </Container>
  )
}

export default PosMethod
