import { PaymentDisputeSummary } from '@/shared/models/PaymentDisputeSummary'
import { PaymentDisputeItem } from '@/shared/models/PaymentDisputeItem'
import { PaymentDisputeType } from '@/shared/models/enums/PaymentDisputeType.enum'
import { PreviousPayment } from './PreviousPayment'
import { StudentLeftSchool } from './StudentLeftSchool'

export type PaymentDisputeAccordionContentVariationsProps = {
  loading: boolean
  paymentDispute: PaymentDisputeSummary
  paymentDisputeItems: PaymentDisputeItem[]
}

export const paymentDisputeAccordionContentVariations = {
  [PaymentDisputeType.PREVIOUS_PAYMENT]: PreviousPayment,
  [PaymentDisputeType.STUDENT_LEFT_SCHOOL]: StudentLeftSchool,
}
