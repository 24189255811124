import type { AxiosInstance } from 'axios'

import type {
  GetStudentEnrollmentCycleResponse,
  IrregularStudentsRequest,
  IrregularStudentsResponse,
  StudentEnrollmentCycleRequest,
  CampaignNameCheckRequest,
  GetCampaignNameCheckResponse,
  DisclaimersResponse,
  DisclaimersRequest,
} from './types'

export const schoolsEnrollmentCycleService = (fetcher: AxiosInstance) => {
  return {
    /**
     * Function responsible for get all enabled enrollment cycles for a student
     *
     * @param request schoolId and studentId
     *
     * @returns StudentEnrollmentCycle
     */
    fetchStudentEnrollmentCycle: async (
      request: StudentEnrollmentCycleRequest
    ): Promise<GetStudentEnrollmentCycleResponse> => {
      return (
        await fetcher.get<GetStudentEnrollmentCycleResponse>(
          `student/enrollment/${request.studentId}/enabled-cycles`,
          {
            params: {
              school_id: request.schoolId,
            },
          }
        )
      ).data
    },
    /**
     *
     * Function responsible to check if the campaign name is valid
     *
     * @param CampaignNameCheckRequest
     *
     * @returns GetCampaignNameCheckResponse
     */
    fetchSchoolNameAvailability: async (
      request: CampaignNameCheckRequest
    ): Promise<GetCampaignNameCheckResponse> => {
      return (
        await fetcher.get<GetCampaignNameCheckResponse>(
          `campaign/${request.schoolId}/check-name-availability`,
          {
            params: {
              school_enrollment_cycle_id: request.schoolEnrollmentCycleId,
              name: request.name,
              school_id: request.schoolId,
              product_id: request.productId,
            },
          }
        )
      ).data
    },
    /**
     *
     * Function responsible for get disclaimers for a school in a reference year
     *
     * @param DisclaimersRequest
     *
     * @returns DisclaimersResponse
     */
    fetchDisclaimers: async (request: DisclaimersRequest) => {
      return (
        await fetcher.get<DisclaimersResponse>(`/student/${request.schoolId}/disclaimers`, {
          params: {
            reference_year: request.referenceYear,
          },
        })
      ).data
    },
  }
}

export const IrregularEnrollmentService = (fetcher: AxiosInstance) => {
  return {
    /**
     *
     * Function responsible for get paginated irregular students data
     *
     * @param IrregularStudentsRequest
     *
     * @returns IrregularStudentsResponse[]
     */
    fetchIrregularStudents: async (
      request: IrregularStudentsRequest
    ): Promise<IrregularStudentsResponse> => {
      return (
        await fetcher.get<IrregularStudentsResponse>(`/enrollment-request/students`, {
          params: {
            school_id: request.schoolId,
            reference_year: request.referenceYear,
            status: request.status,
            student_name: request.studentName,
            ...request.pagination,
          },
        })
      ).data
    },

    /**
     *
     * Function responsible for canceling open irregularities of an enrollment request
     *
     * @param enrollmentRequestId
     *
     * @returns empty response and 204 status code for success
     */
    cancelOpenIrregularities: async (enrollmentRequestId: uuid) => {
      return await fetcher.put(
        `/enrollment-request/${enrollmentRequestId}/cancel-open-irregularities`,
        {}
      )
    },
  }
}
