import { Card } from '@gravity/card'
import { Text } from '@gravity/text'
import { Dialog } from '@gravity/dialog'
import { Button } from '@gravity/button'
import { Pencil } from '@gravity/icons'
import { TextField } from '@gravity/text-field'
import { Select } from '@gravity/select'
import { useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useApi } from '@/utils/hooks/useApi'
import { Address } from 'src/shared/interfaces'
import { UFs, formatCPF, validateEmail, validatePhoneNumber } from 'src/shared/utils'
import { defaultAddress } from '../../constants'
import { FormProps, GuardianFormNames } from '../../types'
import { Grid, GridItem } from '@gravity/grid'

const guardianHeaderText = (
  taxIDFound: boolean,
  sameGuardianSelected: boolean,
  guardianName: string,
  taxID: string
): JSX.Element | string => {
  if (sameGuardianSelected) {
    return 'Responsável selecionado:'
  }

  if (taxIDFound) {
    return (
      <>
        Responsável <strong>{guardianName}</strong> encontrado:
      </>
    )
  }

  return (
    <>
      Adicione um novo responsável com o CPF <strong>{formatCPF(taxID)}</strong>
    </>
  )
}

type ConfirmEditingDialogProps = {
  onConfirm: () => void
  open: boolean
  setOpen: (open: boolean) => void
}

const ConfirmEditingDialog = ({ open, setOpen, onConfirm }: ConfirmEditingDialogProps) => {
  const handleClose = () => setOpen(false)

  return (
    <>
      <Dialog
        open={open}
        size={2}
        backdrop
        onOpenChange={setOpen}
        title="Editar dados do responsável financeiro"
        content={
          <Text>
            As edições serão refletidas para todos os contratos que esse responsável financeiro
            estiver vinculado.
          </Text>
        }
        actionButton={
          <Button
            onClick={() => {
              onConfirm()
              handleClose()
            }}
          >
            Continuar com a edição
          </Button>
        }
        cancelButton={
          <Button variant="ghost" onClick={handleClose}>
            Cancelar
          </Button>
        }
      />
    </>
  )
}

type GuardianTextFieldsProps = {
  form: UseFormReturn<FormProps>
  isEditing: boolean
  sameGuardianSelected: boolean
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
  taxIDFound: boolean
}

const CardContainer = ({
  children,
  isEditing,
  taxIDFound,
  sameGuardianSelected,
}: {
  children: React.ReactNode
  isEditing: boolean
  sameGuardianSelected: boolean
  taxIDFound: boolean
}) => {
  let variant: 'ghost' | 'surface' = 'ghost'
  let className = ''

  if (sameGuardianSelected || taxIDFound) {
    variant = 'surface'
    className = 'bg-colors-background-neutral-2 border-colors-border-neutral-3'
  }

  if (isEditing) {
    variant = 'surface'
    className = 'border-colors-border-neutral-3'
  }

  return (
    <Card variant={variant} className={className + ' pl-0 pt-3'}>
      {children}
    </Card>
  )
}

export const GuardianTextFields = ({
  form,
  isEditing,
  sameGuardianSelected,
  setIsEditing,
  taxIDFound,
}: GuardianTextFieldsProps) => {
  const { api } = useApi()
  const { control, setValue, trigger, reset, getValues } = form
  const [zipInputFocused, setZipInputFocused] = useState(false)
  const [open, setOpen] = useState(false)

  const formValues = getValues()

  const setFormAddressValues = (address: Address) => {
    setValue(GuardianFormNames.ADDRESS, address)

    trigger([
      GuardianFormNames.ZIP,
      GuardianFormNames.STREET,
      GuardianFormNames.CITY,
      GuardianFormNames.STATE_CODE,
    ])
  }

  const OnZipFieldFilled = async (zip: cep) => {
    await api.guardians
      .getAddressFromZip(zip)
      .then(setFormAddressValues)
      .catch(() => {
        setValue(GuardianFormNames.ADDRESS, defaultAddress)
      })
  }

  const isNewGuardian = !taxIDFound && !sameGuardianSelected
  const disabled = !isEditing && !isNewGuardian

  return (
    <>
      <ConfirmEditingDialog open={open} setOpen={setOpen} onConfirm={() => setIsEditing(true)} />
      <CardContainer
        taxIDFound={taxIDFound}
        sameGuardianSelected={sameGuardianSelected}
        isEditing={isEditing}
      >
        <div className="flex justify-end items-center gap-4 mb-6 min-h-12">
          <Text variant="body-1-regular" className="flex-1 pl-3">
            {guardianHeaderText(
              taxIDFound,
              sameGuardianSelected,
              formValues.guardian.name,
              formValues.guardian.tax_id
            )}
          </Text>
          {!isNewGuardian && !isEditing && (
            <Button
              size={2}
              iconStart={<Pencil />}
              variant="ghost"
              onClick={() => setOpen(true)}
              type="button"
            >
              Editar
            </Button>
          )}
        </div>
        <Grid className="items-end md:min-w-0">
          <GridItem sm={2} md={4} lg={3}>
            <NumberFormat
              id={GuardianFormNames.TAX_ID}
              disabled
              type="tel"
              value={formValues.guardian.tax_id}
              format="###.###.###-##"
              label="CPF"
              size={3}
              customInput={TextField}
            />
          </GridItem>

          <GridItem sm={5} md={7} lg={5} xl={6}>
            <Controller
              rules={{ required: true }}
              name={GuardianFormNames.NAME}
              control={control}
              render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
                <TextField
                  {...rest}
                  name={GuardianFormNames.NAME}
                  value={value}
                  id={GuardianFormNames.NAME}
                  onChange={onChange}
                  label="Nome do responsável financeiro"
                  error={Boolean(error?.type)}
                  errorMessage="Insira um nome válido"
                  disabled={sameGuardianSelected || taxIDFound}
                  size={3}
                />
              )}
            />
          </GridItem>
        </Grid>
        <Grid className="mt-2 items-end md:min-w-0">
          <GridItem sm={3} md={4} lg={3}>
            <Controller
              rules={{ required: true, validate: validatePhoneNumber }}
              name={GuardianFormNames.PHONE_NUMBER}
              control={control}
              render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
                <NumberFormat
                  {...rest}
                  disabled={disabled}
                  id={GuardianFormNames.PHONE_NUMBER}
                  value={value}
                  type="tel"
                  label="Telefone ou celular"
                  format="(##) #####-####"
                  mask="_"
                  onValueChange={currentValue => {
                    if (!currentValue) {
                      return reset(
                        {
                          ...formValues,
                          guardian: { ...formValues.guardian, phone_number: '' },
                        },
                        { keepErrors: true }
                      )
                    }
                    onChange(currentValue.value)
                  }}
                  customInput={TextField}
                  size={3}
                  error={Boolean(error?.type)}
                  errorMessage="Insira um número válido"
                />
              )}
            />
          </GridItem>
          <GridItem sm={4} md={7} lg={5} xl={6}>
            <Controller
              rules={{
                required: true,
                validate: {
                  validateEmail,
                },
              }}
              name={GuardianFormNames.EMAIL}
              control={control}
              render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
                <TextField
                  {...rest}
                  name={rest.name}
                  disabled={disabled}
                  id={GuardianFormNames.EMAIL}
                  label="Email"
                  value={value}
                  onChange={currentValue => {
                    if (!currentValue) {
                      return reset(
                        {
                          ...formValues,
                          guardian: { ...formValues.guardian, email: '' },
                        },
                        { keepErrors: true }
                      )
                    }
                    onChange(currentValue)
                  }}
                  size={3}
                  error={Boolean(error?.type)}
                  errorMessage={error?.type ? error?.message ?? 'E-mail inválido' : ''}
                />
              )}
            />
          </GridItem>
        </Grid>
        <Grid className="mt-2 gap-y-2 items-end md:min-w-0">
          <GridItem sm={2} md={3}>
            <Controller
              rules={{ required: true, minLength: 8, maxLength: 8 }}
              name={GuardianFormNames.ZIP}
              control={control}
              render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
                <NumberFormat
                  {...rest}
                  disabled={disabled}
                  id={GuardianFormNames.ZIP}
                  value={value}
                  type="tel"
                  label="CEP"
                  format="#####-###"
                  onFocus={() => setZipInputFocused(true)}
                  onBlur={() => setZipInputFocused(false)}
                  onValueChange={currentValue => {
                    if (!currentValue.value) {
                      return reset(
                        {
                          ...formValues,
                          guardian: {
                            ...formValues.guardian,
                            address: defaultAddress,
                          },
                        },
                        { keepErrors: true }
                      )
                    }
                    onChange(currentValue.value)

                    if (zipInputFocused) {
                      currentValue.value.length === 8 && OnZipFieldFilled(currentValue.value)
                    }
                  }}
                  customInput={TextField}
                  size={3}
                  error={Boolean(error?.type)}
                  errorMessage="CEP inválido"
                />
              )}
            />
          </GridItem>
          <GridItem sm={5} md={6} lg={4} xl={5}>
            <Controller
              rules={{ required: true }}
              name={GuardianFormNames.STREET}
              control={control}
              render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
                <TextField
                  {...rest}
                  disabled={disabled}
                  id={GuardianFormNames.STREET}
                  value={value}
                  label="Logradouro (rua, avenida, praça)"
                  onChange={currentValue => {
                    if (!currentValue) {
                      return reset(
                        {
                          ...formValues,
                          guardian: {
                            ...formValues.guardian,
                            address: { ...formValues.guardian.address, street: '' },
                          },
                        },
                        { keepErrors: true }
                      )
                    }
                    onChange(currentValue)
                  }}
                  size={3}
                  error={Boolean(error?.type)}
                  errorMessage="Logradouro inválido"
                />
              )}
            />
          </GridItem>

          <GridItem sm={2} md={2} lg={1} xl={1}>
            <Controller
              name={GuardianFormNames.ADDRESS_NUMBER}
              control={control}
              render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
                <TextField
                  {...rest}
                  disabled={disabled}
                  id={GuardianFormNames.ADDRESS_NUMBER}
                  value={value}
                  label="Número"
                  onChange={currentValue => {
                    if (!currentValue) {
                      return reset(
                        {
                          ...formValues,
                          guardian: {
                            ...formValues.guardian,
                            address: { ...formValues.guardian.address, number: '' },
                          },
                        },
                        { keepErrors: true }
                      )
                    }
                    onChange(currentValue)
                  }}
                  size={3}
                  error={Boolean(error?.type)}
                  errorMessage="Insira um número"
                />
              )}
            />
          </GridItem>
        </Grid>
        <Grid className="mt-2 gap-y-2 items-end md:min-w-0 lg:min-w-0">
          <GridItem sm={4} md={5} lg={4}>
            <Controller
              name={GuardianFormNames.ADDITIONAL_INFO}
              control={control}
              render={({ field: { value, ...rest } }) => (
                <TextField
                  {...rest}
                  disabled={disabled}
                  id={GuardianFormNames.ADDITIONAL_INFO}
                  label="Complemento (opcional)"
                  value={value}
                  size={3}
                />
              )}
            />
          </GridItem>

          <GridItem sm={3} md={6} lg={4}>
            <Controller
              rules={{ required: true }}
              name={GuardianFormNames.CITY}
              control={control}
              render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
                <TextField
                  {...rest}
                  disabled={disabled}
                  id={GuardianFormNames.CITY}
                  value={value}
                  label="Cidade"
                  onChange={currentValue => {
                    if (!currentValue) {
                      return reset(
                        {
                          ...formValues,
                          guardian: {
                            ...formValues.guardian,
                            address: { ...formValues.guardian.address, city: '' },
                          },
                        },
                        { keepErrors: true }
                      )
                    }
                    onChange(currentValue)
                  }}
                  size={3}
                  error={Boolean(error?.type)}
                  errorMessage="Insira uma cidade"
                />
              )}
            />
          </GridItem>

          <GridItem sm={2} md={4} lg={3}>
            <Controller
              rules={{ required: true }}
              control={control}
              name={GuardianFormNames.STATE_CODE}
              render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
                <>
                  <Select
                    {...rest}
                    disabled={disabled}
                    defaultValue={value}
                    label="UF"
                    value={value}
                    size={3}
                    onValueChange={currentValue => {
                      if (!currentValue) return

                      onChange(currentValue)
                    }}
                    options={UFs.map(state => ({ label: state, value: state }))}
                    hasError={Boolean(error?.type)}
                  />
                  {error && (
                    <Text
                      className="text-colors-text-semantic-colors-error-1"
                      variant="caption-regular"
                    >
                      Informe um UF válido
                    </Text>
                  )}
                </>
              )}
            />
          </GridItem>
        </Grid>
      </CardContainer>
    </>
  )
}
