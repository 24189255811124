import { Badge } from '@gravity/badge'
import { GridItem } from '@gravity/grid'
import { LoadingTuitionSubHeader } from './components/LoadingState'
import { Heading } from '@gravity/heading'
import { PayoutCardContainer, ContainerRowGroup, ContainerGroup, ContainerTitle } from './styles'
import { getPayoutAmountLabel } from '../../utils/getPayoutAmountLabel'
import { PaymentReporter } from './components/PaymentReporter'
import { formatCurrencyAmount, sanitizePayoutValue } from '../../utils/formatCurrencyAmount'
import { useContextHistoryPayoutDateSelected } from '../../contexts/PayoutAnticipationContext'
import { PayoutAnticipationButton } from '../PayoutAnticipationButton'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import {
  useGetEligibility,
  useGetOrderLock,
} from '../../pages/TuitionPayoutReport/hooks/useTuitionPayoutReportData'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import { useEffect } from 'react'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { REPORT_PAGE_NAMES } from '../../constants/REPORT_PAGE_NAMES'
import { formatCurrencyValue } from '@/shared/utils'
import { AnticipationCaption } from '../../pages/PayoutReportDashboard/components/DashboardCard/components/AnticipationCaption'

type TuitionMainContentProps = {
  deductionsValue: number
  incomeValue: number
  isHourIncluded?: boolean
  isLoading: boolean
  isPayoutOpen: boolean
  linkButtonText: string
  onClickAtLinkButton: () => void
  onRequestOpenPayoutTransfersDrawer: () => void
  payoutReportPeriod: {
    endDate?: Date
    startDate?: Date
  }
  payoutSubtitleLabel?: string
  payoutValue: number
}

/**
 * Component used to display payout report summary data on payout report pages
 *
 * @param props
 * @param props.payoutValue Payout value in decimal
 * @param props.isPayoutOpen Indicates if payout is open
 * @param props.isLoading Indicates if loading state should be displayed
 * @param props.payoutSubtitleLabel Payout label value for open and close instances
 * @param props.incomeValue Income value in decimal
 * @param props.deductionsValue Deductions value in decimal
 * @param props.onRequestOpenPayoutTransfersDrawer Callback to request opening the payout transfers drawer
 */
export const TuitionMainContent = ({
  payoutValue,
  isPayoutOpen,
  isLoading,
  payoutSubtitleLabel,
  incomeValue,
  deductionsValue,
  onRequestOpenPayoutTransfersDrawer,
}: TuitionMainContentProps) => {
  const isNegative = payoutValue < 0
  const valueLabel = payoutSubtitleLabel ?? getPayoutAmountLabel(isNegative)
  const { school } = useSelectedSchool()
  const isInMaintenance = useUnleashFlag(UnleashFlags.CRD_166_DISABLE_CREDIT_FLOWS)
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const schoolId = school?.id ?? ''

  const isPayoutAnticipationEnabled = useUnleashFlag(UnleashFlags.CRD_84_ENABLE_PAYOUT_ANTECIPATION)

  const hasPayoutAnticipationAccess = isPayoutAnticipationEnabled

  const sendMixpanelEvent = ({
    errorScope,
    isEligibleForAR,
  }: {
    errorScope?: string
    isEligibleForAR?: boolean
  }) => {
    isInitialized &&
      hasPayoutAnticipationAccess &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.PAYOUT_ANTICIPATION,
        name: EventDispatcherEvents.PAGE_VIEWED,
        action: 'page_view',
        customProperties: {
          $page_name: REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT,
          $payment_advance: isEligibleForAR,
          $error_advance: !!errorScope,
          $error_scope: errorScope,
        },
      })
  }

  const {
    data: eligibility,
    isLoading: isLoadingEligibility,
    isError: isErrorEligibility,
  } = useGetEligibility(
    schoolId,
    () => sendMixpanelEvent({ errorScope: 'eligibility' }),
    !isInMaintenance
  )

  const { data: orderLock, isLoading: isLoadingLock, isError: isErrorLock } = useGetOrderLock(
    schoolId,
    () => sendMixpanelEvent({ errorScope: 'lock' }),
    !isInMaintenance
  )

  const hasAnticipationError = !!isErrorLock || !!isErrorEligibility

  const { historyPayoutDateSelected } = useContextHistoryPayoutDateSelected()

  const isSelectedOlderMonth: boolean =
    !!historyPayoutDateSelected &&
    new Date(historyPayoutDateSelected).getMonth() < new Date().getMonth()

  useEffect(() => {
    if (!isLoadingEligibility && !isLoadingLock) {
      sendMixpanelEvent({ isEligibleForAR: eligibility?.eligibility?.eligible?.AR })
    }
  }, [isLoadingEligibility, isLoadingLock])

  if (isLoading || isLoadingLock || isLoadingEligibility) {
    return <LoadingTuitionSubHeader />
  }

  const getPayoutStatus = () => {
    if (isPayoutOpen) {
      return (
        <Badge variant="soft" color="warning">
          Aberto
        </Badge>
      )
    }

    return (
      <Badge variant="soft" color="success">
        Fechado
      </Badge>
    )
  }

  return (
    <>
      <GridItem xl={6} lg={6} md={6} sm={4} xs={2}>
        <PayoutCardContainer>
          <ContainerRowGroup>
            <ContainerTitle>
              <Heading variant="heading-h4-medium" data-testid="payout-value-label">
                {valueLabel}
              </Heading>
            </ContainerTitle>
            <div>{getPayoutStatus()}</div>
          </ContainerRowGroup>

          <ContainerGroup>
            <Heading
              variant="heading-h2-medium"
              data-testid="payout-report-value"
              className={
                isNegative
                  ? 'text-colors-background-semantic-colors-error-1'
                  : 'text-colors-text-main-2'
              }
            >
              {sanitizePayoutValue(formatCurrencyValue(payoutValue))}
            </Heading>
            <AnticipationCaption
              isLoadingEligibility={isLoadingEligibility}
              isSelectedOlderMonth={isSelectedOlderMonth}
              hasErrorMessage={hasAnticipationError}
              pageName={REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT}
            />
          </ContainerGroup>

          <PayoutAnticipationButton
            orderLock={orderLock}
            isSelectedOlderMonth={isSelectedOlderMonth}
            pageName={REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT}
          />
        </PayoutCardContainer>
      </GridItem>

      <GridItem xl={6} lg={6} md={6} sm={4} xs={2} className="flex flex-col justify-between">
        <PaymentReporter
          incomeValue={sanitizePayoutValue(formatCurrencyAmount(incomeValue))}
          deductionsValue={sanitizePayoutValue(formatCurrencyAmount(deductionsValue))}
          onRequestOpenPayoutTransfersDrawer={onRequestOpenPayoutTransfersDrawer}
        />
      </GridItem>
    </>
  )
}
