import { ChangeEvent, FocusEventHandler, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { numberFormat } from '@gravity/text-field'
import { Button } from '@gravity/button'

import { Option } from '@/shared/utils/types'
import { decimalAdjust, formatCurrencyValue, HOUR_IN_MILLIS } from '@/shared/utils'
import {
  CreateCreditSimulationRequest,
  CreditReason,
  CreditSimulationResponse,
  CreditType,
  GracePeriodDate,
} from '@/shared/services/credit/types'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { PersistedStatePrefix, usePersistState } from '@/shared/hooks/usePersistState'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { FormStyled, TextFieldStyled } from './styles'
import DefaultTemplate from '../../templates/DefaultTemplate'
import {
  useContextCreditOrderId,
  useContextCreditSimulation,
  useContextEligibility,
  useContextRequestSimulation,
  useReasonForChosenPolicyType,
} from '../../contexts/CreditDataContext'
import { useCreateCreditSimulation } from '../../hooks/queries/useCreateCreditSimulation'
import { LoadingSimulation } from './LoadingSimulation'
import ReasonDropdown from '../../components/ReasonDropdown'
import { Footer } from '../../components/Footer'
import { MixpanelEventActions } from '../../constants/MixpanelEventActions.enum'
import { CreditEventComponentNames } from '../../constants/CreditEventComponentNames.enum'
import useMixpanelEventSender from '../../utils/useMixpanelEventSender'
import CreditGracePeriodDropdown from '../../components/CreditGracePeriodDropdown'
import { useReasonForChosenPolicy } from '../../hooks/useReasonForChosenPolicy'
import { useGetCreditReasons } from '../../hooks/queries/useGetCreditReasons'
import { removeCentsAndRoundUp } from '../../utils/formatNumber'
import { useGetGracePeriodDates } from '../../utils/useGetGracePeriodDates'
import { QuestionOutline } from '@gravity/icons'

// Arbitrary maximum value for simulations, regardless of the user's pre-approved value. Represents R$ 10.000.000,00
const MAX_ABSOLUTE_SIMULATION_VALUE = 10_000_000_00

const defaultTTL = HOUR_IN_MILLIS

enum SimulationValueError {
  MAX_PA_EXCEEDED,
  MAX_ABSOLUTE_VALUE_EXCEEDED,
}

const stringToNumber = (value: string) => Number(value.replace(/[^0-9]/g, ''))

export const Simulation = () => {
  const { push } = useHistory()

  const { sendCreditEventToMixpanel } = useMixpanelEventSender()

  const title = 'Simulação de crédito'

  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  const [maxPaExceeded, setMaxPaExceeded] = useState<boolean>(false)
  const [loadingSummary, setLoadingSummary] = useState<boolean>(false)
  const { school, schoolSlug } = useSelectedSchool()
  const [requestValue, setRequestValue] = usePersistState<string>(
    `${PersistedStatePrefix.CreditSimulation}:${schoolSlug}:simulation-value`,
    '0,00',
    { TTL_ms: defaultTTL }
  )
  const [reason, setReason] = usePersistState<Option<CreditReason>>(
    `${PersistedStatePrefix.CreditSimulation}:${schoolSlug}:simulation-reason`,
    null,
    { TTL_ms: defaultTTL }
  )
  const [note, setNote] = usePersistState<string>(
    `${PersistedStatePrefix.CreditSimulation}:${schoolSlug}:simulation-reason-note`,
    '',
    { TTL_ms: defaultTTL }
  )
  const [gracePeriodDate, setGracePeriodDate] = usePersistState<GracePeriodDate>(
    `${PersistedStatePrefix.CreditSimulation}:${schoolSlug}:simulation-grace-period-date`,
    {} as GracePeriodDate,
    { TTL_ms: defaultTTL }
  )

  const [gracePeriodDates, setGracePeriodDates] = useState<GracePeriodDate[]>([])

  const { eligibility } = useContextEligibility()
  const { setCreditSimulation } = useContextCreditSimulation()
  const { setCreditOrderId } = useContextCreditOrderId()
  const { setRequestSimulation } = useContextRequestSimulation()
  const { setReasonForChosenPolicyType } = useReasonForChosenPolicyType()
  const { mutateAsync } = useCreateCreditSimulation()
  const { getReasonForChosenPolicyType } = useReasonForChosenPolicy()
  const { data: reasons } = useGetCreditReasons()
  const gracePeriodDatesOptions = useGetGracePeriodDates()
  useEffect(() => {
    sendCreditEventToMixpanel(EventDispatcherEvents.PAGE_VIEWED, MixpanelEventActions.PAGE_VIEW, {
      $page_name: CreditEventComponentNames.SIMULATION_PAGE,
    })
    setGracePeriodDates(gracePeriodDatesOptions)

    if (gracePeriodDatesOptions) {
      setGracePeriodDate(gracePeriodDatesOptions[0])
    }
  }, [])

  // TODO: redirects the user if there is no eligibility data in context, it is preferable to use useHistory instead
  useEffect(() => {
    if (!eligibility) {
      push(`/${schoolSlug}/credito`)
    }
  }, [eligibility])

  const valueAsCents = stringToNumber(requestValue)

  const eligibleToCredit = eligibility?.eligibility?.eligible ?? { CP: false, CPCR: false }
  const paToCredit = eligibility?.eligibility?.pa ?? { CP: 0, CPCR: 0 }

  const maxPa = decimalAdjust('floor', eligibleToCredit.CPCR ? paToCredit.CPCR : paToCredit.CP, 2)
  const isEqualPa = valueAsCents / 100 === maxPa
  const isValueGreaterThanMaxPa = valueAsCents / 100 > maxPa
  const isValueGreaterThanMaxAbsolute = valueAsCents === 0
  const isValueNotSet = valueAsCents === 0
  const isReasonOrNoteNotSet = reason == null || (reason?.requiredNote && note.trim().length === 0)
  const isAnyInputNotValid = isValueNotSet || isReasonOrNoteNotSet || isValueGreaterThanMaxAbsolute
  const isButtonDisabled = isAnyInputNotValid || (maxPaExceeded && isValueGreaterThanMaxPa)

  const showGracePeriod = eligibility?.eligibleForGracePeriod && gracePeriodDates.length > 1

  const errorMessagesForValueInput: Record<SimulationValueError, string> = {
    [SimulationValueError.MAX_ABSOLUTE_VALUE_EXCEEDED]: 'Valor máximo atingindo',
    [SimulationValueError.MAX_PA_EXCEEDED]: `Valor máximo pré-aprovado: ${formatCurrencyValue(
      maxPa
    )}`,
  }

  const getErrorCode = (): SimulationValueError | null => {
    if (isValueGreaterThanMaxPa && maxPaExceeded) {
      return SimulationValueError.MAX_PA_EXCEEDED
    } else if (valueAsCents > MAX_ABSOLUTE_SIMULATION_VALUE) {
      return SimulationValueError.MAX_ABSOLUTE_VALUE_EXCEEDED
    }
    return null
  }
  const valueErrorCode = getErrorCode()

  const getCreditTypes = (requestValueInput: number, firstDiscountDate: string | null) => {
    const requestValue = requestValueInput / 100

    const validateCreditTypeWithCP = () => {
      const containsGracePeriod = eligibility?.gracePeriod?.CP?.some(
        e => String(e) === firstDiscountDate
      )

      const gracePeriodCP = firstDiscountDate === undefined ? true : containsGracePeriod

      const isEligibleGracePeriod = eligibility?.gracePeriod?.CP?.length > 0

      if (
        eligibleToCredit.CP &&
        paToCredit.CP >= requestValue &&
        (!isEligibleGracePeriod || gracePeriodCP)
      ) {
        return [CreditType.CP]
      }
      return []
    }
    const validateCreditTypeWithCPCR = () => {
      const containsGracePeriod = eligibility?.gracePeriod?.CPCR?.some(
        e => String(e) === firstDiscountDate
      )

      const gracePeriodCPCR = firstDiscountDate === undefined ? true : containsGracePeriod
      const isEligibleGracePeriod = eligibility?.gracePeriod?.CPCR?.length > 0

      if (
        eligibleToCredit.CPCR &&
        paToCredit.CPCR >= requestValue &&
        (!isEligibleGracePeriod || gracePeriodCPCR)
      ) {
        return [CreditType.CPCR]
      }
      return []
    }

    const creditTypesInput = [...validateCreditTypeWithCP(), ...validateCreditTypeWithCPCR()]

    return creditTypesInput
  }

  const handleSimulateButtonClick = () => {
    if (isValueGreaterThanMaxPa) {
      setMaxPaExceeded(true)
      return
    }

    const requestValueNumber = Number(removeCentsAndRoundUp(requestValue.replace(/[^0-9]/g, '')))
    const schoolId = school?.id ?? ''
    const data: CreateCreditSimulationRequest = {
      note: note,
      reason: reason?.label ?? '',
      requestedValue: requestValueNumber,
      schoolId: schoolId,
      gracePeriodDate: gracePeriodDate,
      gracePeriodDates: gracePeriodDates,
      firstDiscountDate: null,
      creditTypes: [],
    }

    data.firstDiscountDate = gracePeriodDate ? gracePeriodDate.value : null

    data.creditTypes = getCreditTypes(data.requestedValue, data.firstDiscountDate)

    sendCreditEventToMixpanel(EventDispatcherEvents.BUTTON_CLICKED, MixpanelEventActions.CLICK, {
      $button_name: CreditEventComponentNames.SIMULATE_NOW,
      $selected: reason?.label,
      $value: requestValue,
      $maximum: isValueGreaterThanMaxPa ? 'more' : isEqualPa ? 'equal' : 'less',
      $selected_period: gracePeriodDate?.label,
      $options_period: gracePeriodDates.map(date => date.label),
      $modality_type: data.creditTypes,
    })

    setLoadingSummary(true)
    mutateAsync(data).then((response: CreditSimulationResponse) => {
      setRequestSimulation({
        reason: data?.reason,
        note: data?.note ?? '',
        gracePeriodDate: data?.gracePeriodDate,
        gracePeriodDates: data?.gracePeriodDates,
      })
      setCreditSimulation(response.data)
      setLoadingSummary(false)
      setCreditOrderId(response.data.orderId)
      const reasonForChosenPolicyType = getReasonForChosenPolicyType(eligibility, data, response)
      if (reasonForChosenPolicyType) {
        setReasonForChosenPolicyType(reasonForChosenPolicyType)
      }

      push(`/${schoolSlug}/credito/resumo`)
    })
  }

  const handleChangeSimulationValue = (value: string) => {
    setRequestValue(value)
  }

  const handleOnBlurSimulationValue: FocusEventHandler<HTMLInputElement> = event => {
    const splitValue = event.target.value.split(',')[0]
    event.target.value = splitValue ? `${splitValue},00` : '0,00'
    setRequestValue(event.target.value)
  }

  const onHelpClick = () => {
    window.open('https://centraldeajuda.olaisaac.io', '_blank')
  }

  if (loadingSummary) {
    return (
      <LoadingSimulation
        title={title}
        previousRoute={
          renderNewHeader ? `/${schoolSlug}/credito` : `/${schoolSlug}/credito/simulacao`
        }
      />
    )
  }

  return (
    <DefaultTemplate
      title={title}
      previousRoute={`/${schoolSlug}/credito`}
      footerProps={{
        primaryButton: {
          onClick: handleSimulateButtonClick,
          label: 'Simular agora',
          disabled: isButtonDisabled,
        },
      }}
      child={
        <>
          <FormStyled>
            <ReasonDropdown
              title="Como o crédito vai apoiar sua instituição?"
              reasons={reasons}
              reason={reason}
              setReason={setReason}
              note={note}
              setNote={setNote}
            />

            <TextFieldStyled
              data-testid="request-credit-value"
              name="creditValue"
              defaultValue={requestValue}
              size={3}
              placeholder="0,00"
              inputPrefix="R$"
              label="Qual o valor desejado?"
              error={valueErrorCode !== null}
              errorMessage={
                valueErrorCode !== null ? errorMessagesForValueInput[valueErrorCode] : ''
              }
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                handleChangeSimulationValue(event.target.value)
                numberFormat(event)
              }}
              onBlur={handleOnBlurSimulationValue}
            />

            {showGracePeriod && (
              <CreditGracePeriodDropdown
                title="Quando gostaria de começar a pagar?"
                placeholder="Escolha o mês do repasse"
                gracePeriodDate={gracePeriodDate}
                setGracePeriodDate={setGracePeriodDate}
                gracePeriodDates={gracePeriodDates}
              />
            )}
          </FormStyled>
          <Footer />
          <div className="flex justify-start">
            <Button variant="ghost" size={1} iconStart={<QuestionOutline />} onClick={onHelpClick}>
              Ajuda
            </Button>
          </div>
        </>
      }
    />
  )
}
