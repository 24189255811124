import { themes } from '@gravity/tokens'
import { brandPrimitiveTokens, brandHumanizedTokens } from '@olaisaac/design-system'

/**
 * @deprecated
 */

export const theme = {
  primitiveTokens: brandPrimitiveTokens,
  humanizedTokens: brandHumanizedTokens,
  gravity: themes.standard,
}

/**
 * TODO: Remove export default
 */
export default theme
