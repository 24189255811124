import { useHistory } from 'react-router-dom'

import AgreementListStep from '../steps/AgreementListStep'
import FormOfPaymentStep from '../steps/FormOfPaymentStep'
import InstallmentsOverview from '../steps/InstallmentsOverview'
import SelectConclusion from '../components/SelectConclusion/SelectConclusion'
import { useAgreement } from 'src/escolas/contexts/agreementContext'
import { FormOfPayment } from '@/modules/guardians/models/agreement'
import PaymentOptionStep from '../steps/PaymentOptionsStep'
import { useSelectPos } from 'src/escolas/hooks'
import isPosAvailable from '../helpers/isPosAvailable'
import { useGuardianNavigation } from '@/shared/hooks/useGuardianNavigation'
import { useNegotiationV2 } from './useNegotiationV2'
import { useSteps as useComponentStep } from '@gravity/stepper'

export enum StepsNegotiationFlow {
  AGREEMENT_LIST = 'AGREEMENT_LIST',
  CONCLUSION = 'CONCLUSION',
  FORM_OF_PAYMENT = 'FORM_OF_PAYMENT',
  OVERVIEW = 'OVERVIEW',
  PAYMENT_METHOD_AGREEMENT = 'PAYMENT_METHOD_AGREEMENT',
}

export default function useSteps() {
  const steps = ['Parcelas selecionadas', 'Forma de pagamento', 'Conclusão']
  const {
    activeStep: currentStep,
    setActiveStep: setCurrentStep,
    getStepStatus,
  } = useComponentStep({
    count: steps.length,
  })
  const history = useHistory()
  const { selectedFormOfPayment, agreementSimulations, setSelectedFormOfPayment } = useAgreement()
  const { pageToReturn } = useGuardianNavigation()
  const { listPos } = useSelectPos()
  const { isNegotiationDownPayment } = useNegotiationV2()

  function handleStepperItemClick(index: number) {
    if (currentStep === steps.length - 1) return

    if (index < currentStep) {
      setCurrentStep(index)
    }
  }

  function goToPayAgreementStep() {
    setCurrentStep(3)
  }

  function goToAgreementListStep() {
    setCurrentStep(2)
  }

  function goToFormOfPaymentStep() {
    setCurrentStep(1)
  }

  function nextStep() {
    setCurrentStep(prev => prev + 1)
  }

  function handleOnFinishStep() {
    nextStep()
  }

  const shouldBypassFormOfPayment = !isPosAvailable(agreementSimulations) || listPos?.length === 0

  const shouldBypassListAgreement =
    selectedFormOfPayment === FormOfPayment.POS ||
    selectedFormOfPayment === FormOfPayment.POS_V2 ||
    agreementSimulations.length === 1

  function handleNextStepFormOfPayment() {
    if (shouldBypassListAgreement) {
      goToPayAgreementStep()
      return
    }

    nextStep()
  }

  function handleNextInstallmentOverview() {
    if (shouldBypassFormOfPayment) {
      setSelectedFormOfPayment(FormOfPayment.OTHER)
      if (shouldBypassListAgreement) {
        goToPayAgreementStep()
        return
      }
      goToAgreementListStep()
      return
    }

    goToFormOfPaymentStep()
  }

  function handleGoBackSteps() {
    if (currentStep === 0) {
      if (pageToReturn) return history.push(pageToReturn)
      return history.goBack()
    }

    if (currentStep === 2) {
      if (shouldBypassFormOfPayment) {
        setCurrentStep(0)
        return
      }
      goToFormOfPaymentStep()
      return
    }

    if (currentStep === 3) {
      if (shouldBypassListAgreement) {
        if (shouldBypassFormOfPayment) {
          setCurrentStep(0)
          return
        }
        goToFormOfPaymentStep()
        return
      }
      goToAgreementListStep()
      return
    }

    setCurrentStep(prev => prev - 1)
  }

  function handleGoBack() {
    if (currentStep === 0) {
      if (pageToReturn) return history.push(pageToReturn)
      return history.goBack()
    }

    if (selectedFormOfPayment === FormOfPayment.POS || agreementSimulations.length === 1) {
      goToFormOfPaymentStep()
      return
    }

    setCurrentStep(prev => prev - 1)
  }

  function handleOnForward() {
    handleStepperItemClick(3)
  }

  const getActiveStep = (currentStep: number) => {
    const activeStepEnum = [
      StepsNegotiationFlow.OVERVIEW,
      StepsNegotiationFlow.FORM_OF_PAYMENT,
      StepsNegotiationFlow.AGREEMENT_LIST,
      StepsNegotiationFlow.PAYMENT_METHOD_AGREEMENT,
      StepsNegotiationFlow.CONCLUSION,
    ][currentStep]

    switch (activeStepEnum) {
      case StepsNegotiationFlow.OVERVIEW:
        return 0
      case StepsNegotiationFlow.FORM_OF_PAYMENT:
      case StepsNegotiationFlow.AGREEMENT_LIST:
      case StepsNegotiationFlow.PAYMENT_METHOD_AGREEMENT:
        return 1
      case StepsNegotiationFlow.CONCLUSION:
        return 2
    }
  }

  const stepComponents = [
    <InstallmentsOverview
      key="installment_step"
      onFinish={handleNextInstallmentOverview}
      goBack={handleGoBackSteps}
      id={StepsNegotiationFlow.OVERVIEW}
    />,
    <FormOfPaymentStep
      key="form_of_payment_step"
      id={StepsNegotiationFlow.FORM_OF_PAYMENT}
      onFinish={handleNextStepFormOfPayment}
      goBack={handleGoBackSteps}
    />,
    <AgreementListStep
      key="agreement_list_step"
      id={StepsNegotiationFlow.AGREEMENT_LIST}
      onFinish={handleOnFinishStep}
      goBack={handleGoBackSteps}
    />,
    <PaymentOptionStep
      id={StepsNegotiationFlow.PAYMENT_METHOD_AGREEMENT}
      key="payment_method_agreement_step"
      onFinish={handleOnFinishStep}
      goBack={handleGoBackSteps}
      onForward={handleOnForward}
    />,
    <SelectConclusion
      id={StepsNegotiationFlow.CONCLUSION}
      key="conclusion_step"
      onFinish={handleGoBack}
      goBack={() => history.goBack()}
      goToPayAgreementStep={goToPayAgreementStep}
      isNegotiationDownPayment={isNegotiationDownPayment}
    />,
  ]

  return {
    currentStep,
    getActiveStep,
    stepComponents,
    steps,
    getStepStatus,
    handleStepperItemClick,
  }
}
