import { Grid, GridItem } from '@gravity/grid'
import { useHistory, useLocation } from 'react-router-dom'
import { Wrapper, TopPageWrapper, TabContainer, AlertBannerWrapper } from './styles'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { replaceTabInPathname } from './utils/replaceTabInPathname'
import { useQuery } from '@/shared/hooks/useQuery'
import { GuardianDetailsTabs } from './constants'

import { GuardianDetailsContractsTab } from '../GuardianDetailsContractsTab/GuardianDetailsContractsTab'
import { GuardianDetailsFinancialSummaryTab } from '../GuardianDetailsFinancialSummaryTab/GuardianDetailsFinancialSummaryTab'

import { GuardianDetailsCard } from './components/GuardianDetailsCard'
import { getParamGuardianId } from './utils/getParamGuardianId'
import {
  StateQueryParamsEnum,
  useGuardiansStateQueryParams,
} from '../hooks/useGuardiansStateQueryParams'
import EditRegistrationDrawer from './features/EditRegistrationDrawer/EditRegistrationDrawer'

import { FeedbacksCashier } from './features/FeedbacksCashier/FeedbacksCashier'
import { useSetPageTitle } from '@/shared/hooks/useSetPageTitle'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { UserGuidingGuidesIds } from '@/shared/hooks/useUserGuidingTrigger'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { GuardiansQueryParamsNameEnum } from '../constants'
import { ContractProvider } from '@/shared/contexts/contractContext'
import { queryClient } from '@/shared/contexts/ReactQuery'
import { PendingEnrollmentProcessingAlert } from './components/PendingEnrollmentProcessingAlert'
import { GuardianDetailsHeader } from './components/GuardianDetailsHeader'
import { GuardianDetailsCompactedHeader } from './components/GuardianDetailsCompactedHeader'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { Text } from '@gravity/text'
import { Tabs } from '@gravity/tabs'
import { Calendar2, FileListOutline, FolderOutline, Wallet3Outline } from '@gravity/icons'
import { GuardianDetailsInvoicesTab } from '@/modules/guardians/GuardianDetailsInvoicesTab'
import { GuardianDetailsHistoryTab } from '@/modules/guardians/GuardianDetailsHistoryTab'
import { GenericError } from '@/shared/components/GenericError'
import { useGetGuardian } from '@/shared/hooks/queries/guardian'
import { GuardianAlertBanner } from './components/GuardianAlertBanner'
import { useLayout } from '@/shared/hooks/useLayout'

type NewTabsProps = {
  activeTab: GuardianDetailsTabs
  onChangeFinancialSummaryTab: (tab: GuardianDetailsTabs) => void
  onChangeTab: (tab: GuardianDetailsTabs) => void
  setShowGeneratingInvoicesLoading: Dispatch<SetStateAction<boolean>>
  setShowPendingEnrollmentProcessingAlert: Dispatch<SetStateAction<boolean>>
  showGeneratingInvoicesLoading: boolean
  showPendingEnrollmentProcessingAlert: boolean
}

const TabsTriggers = () => (
  <Tabs.List background="gray">
    <Tabs.Trigger
      value={GuardianDetailsTabs.RESUMO}
      iconStart={<Wallet3Outline size="xs" style={{ maxHeight: '16px', maxWidth: '16px' }} />}
    >
      <Text variant="button-2" className="text-inherit">
        Resumo financeiro
      </Text>
    </Tabs.Trigger>

    <Tabs.Trigger
      value={GuardianDetailsTabs.FATURAS}
      iconStart={<FileListOutline size="xs" style={{ maxHeight: '16px', maxWidth: '16px' }} />}
    >
      <Text variant="button-2" className="text-inherit">
        Faturas
      </Text>
    </Tabs.Trigger>

    <Tabs.Trigger
      value={GuardianDetailsTabs.CONTRATOS}
      iconStart={<FolderOutline size="xs" style={{ maxHeight: '16px', maxWidth: '16px' }} />}
    >
      <Text variant="button-2" className="text-inherit">
        Contratos
      </Text>
    </Tabs.Trigger>

    <Tabs.Trigger
      value={GuardianDetailsTabs.HISTORICO}
      iconStart={<Calendar2 size="xs" style={{ maxHeight: '16px', maxWidth: '16px' }} />}
    >
      <Text variant="button-2" className="text-inherit">
        Histórico
      </Text>
    </Tabs.Trigger>
  </Tabs.List>
)

const NewTabs = ({
  activeTab,
  showPendingEnrollmentProcessingAlert,
  setShowPendingEnrollmentProcessingAlert,
  setShowGeneratingInvoicesLoading,
  onChangeTab,
  onChangeFinancialSummaryTab,
}: NewTabsProps) => (
  <Tabs.Root
    value={activeTab}
    onValueChange={value => onChangeTab(value as GuardianDetailsTabs)}
    style={{ marginTop: '32px' }}
  >
    <TabsTriggers />

    <Tabs.Content
      value={GuardianDetailsTabs.RESUMO}
      key={GuardianDetailsTabs.RESUMO + '-tab-content'}
      tabIndex={-1}
    >
      <GuardianDetailsFinancialSummaryTab changeActiveTab={onChangeFinancialSummaryTab} />
    </Tabs.Content>

    <Tabs.Content
      value={GuardianDetailsTabs.FATURAS}
      key={GuardianDetailsTabs.FATURAS + '-tab-content'}
      tabIndex={-1}
    >
      <FeedbacksCashier
        setShowGeneratingInvoicesLoading={value => setShowGeneratingInvoicesLoading(value)}
      />
      {showPendingEnrollmentProcessingAlert && <PendingEnrollmentProcessingAlert />}

      <TabContainer>
        <GuardianDetailsInvoicesTab
          setShowPendingEnrollmentProcessingAlert={setShowPendingEnrollmentProcessingAlert}
        />
      </TabContainer>
    </Tabs.Content>

    <Tabs.Content
      value={GuardianDetailsTabs.CONTRATOS}
      key={GuardianDetailsTabs.CONTRATOS + '-tab-content'}
      tabIndex={-1}
    >
      <GuardianDetailsContractsTab />
    </Tabs.Content>

    <Tabs.Content
      value={GuardianDetailsTabs.HISTORICO}
      key={GuardianDetailsTabs.HISTORICO + '-tab-content'}
      tabIndex={-1}
    >
      <GuardianDetailsHistoryTab />
    </Tabs.Content>
  </Tabs.Root>
)

export const GuardianDetails = () => {
  const history = useHistory()

  const { query, setOnQueryParam } = useQuery()
  const { pathname, search } = useLocation()
  const { school } = useSelectedSchool()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)
  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  useLayout({ enableSideMenu: true, enableHeader: renderNewHeader })

  const headerRef = useRef<HTMLDivElement>(null)

  const guardianId = getParamGuardianId()

  const {
    data: guardian,
    isLoading: isFetchGuardianLoading,
    isFetched: isFetchGuardianFetched,
    isError: isFetchGuardianError,
    refetch: refetchGuardian,
  } = useGetGuardian({ guardianId, schoolId: school?.id ?? '' }, { enabled: !!school?.id })

  const {
    statesQueryParams,
    handleEditRegistrationDrawerClose,
    openEditRegistrationDrawer,
  } = useGuardiansStateQueryParams()

  const [showGeneratingInvoicesLoading, setShowGeneratingInvoicesLoading] = useState<boolean>(false)
  const [
    showPendingEnrollmentProcessingAlert,
    setShowPendingEnrollmentProcessingAlert,
  ] = useState<boolean>(false)
  const abrirCadastro = Boolean(statesQueryParams.get(StateQueryParamsEnum.ABRIR_CADASTRO))

  useSetPageTitle(guardian ? `${guardian.name} - isaac` : `Responsáveis - isaac`)

  const refreshData = () => {
    refetchGuardian()
    handleEditRegistrationDrawerClose()
  }

  const [activeTab, setActiveTab] = useState<GuardianDetailsTabs>(() => {
    const tab = Object.values(GuardianDetailsTabs).find(substring => pathname.includes(substring))
    return tab ?? GuardianDetailsTabs.RESUMO
  })

  const defaultTabsSearchParams = {
    [GuardianDetailsTabs.RESUMO]: '',
    [GuardianDetailsTabs.CONTRATOS]: '',
    [GuardianDetailsTabs.HISTORICO]: '',
    [GuardianDetailsTabs.FATURAS]: '',
  }

  const [tabsSearchParams, setTabsSearchParams] = useState(defaultTabsSearchParams)

  useEffect(() => {
    const foundTab = Object.values(GuardianDetailsTabs).find(tab => pathname.includes(tab))

    if (foundTab === GuardianDetailsTabs.RESUMO) {
      queryClient.invalidateQueries(['guardian-finance-summary'])
    }

    const currentTab = { [foundTab ?? '']: search }
    setTabsSearchParams(tabs => ({ ...tabs, ...currentTab }))

    setActiveTab(foundTab ?? GuardianDetailsTabs.RESUMO)
  }, [pathname, search])

  const sendEvent = (scope: EventDispatcherEventScopes, name: EventDispatcherEvents) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope,
        name,
        action: 'click',
      })
  }

  const getEventName = (value: GuardianDetailsTabs) => {
    if (value === GuardianDetailsTabs.FATURAS)
      return EventDispatcherEvents.GUARDIAN_INSTALLMENTS_LIST
    if (value === GuardianDetailsTabs.CONTRATOS) return EventDispatcherEvents.GUARDIAN_CONTRACT_LIST
  }

  const handleActiveTab = (value: GuardianDetailsTabs) => {
    setActiveTab(value)

    const eventName = getEventName(value)

    if (eventName) {
      sendEvent(EventDispatcherEventScopes.GUARDIANS, eventName)
    }

    const newPath = replaceTabInPathname(pathname, value)
    const newTabSearch = tabsSearchParams[value] as string

    history.replace({
      pathname: newPath,
      search: newTabSearch,
    })

    window.scrollTo(0, 0)
  }

  const guideId = isIntegratedSchool
    ? UserGuidingGuidesIds.GUARDIAN_DETAILS_PAGE_INTEGRATED_SCHOOL
    : UserGuidingGuidesIds.GUARDIAN_DETAILS_PAGE

  useEffect(() => {
    setOnQueryParam(guideId, GuardiansQueryParamsNameEnum.guide, 'replace')
  }, [isIntegratedSchool, activeTab])

  const shouldRefetch = Boolean(query.get('refetch'))

  useEffect(() => {
    if (shouldRefetch) {
      queryClient.invalidateQueries({
        predicate: query => {
          const queryKeyName = String(query.queryKey[0])
          return Boolean(queryKeyName.match(/^guardian-details-.*/g))
        },
      })
    }
  }, [shouldRefetch])

  useEffect(() => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.GUARDIANS,
        name: EventDispatcherEvents.PAGE_VIEWED,
        action: 'page_view',
        customProperties: {
          $name: 'Resumo financeiro',
        },
      })
    }
  }, [])

  if (isFetchGuardianError || (isFetchGuardianFetched && !guardian)) {
    return (
      <GenericError
        onRetry={() => {
          window.location.reload()
        }}
      />
    )
  }

  return (
    <Wrapper>
      <ContractProvider>
        {guardian && (
          <GuardianDetailsCompactedHeader
            guardian={guardian}
            isLoading={isFetchGuardianLoading}
            originalHeaderRef={headerRef}
          >
            <div className="tabs-container">
              <Tabs.Root
                value={activeTab}
                onValueChange={value => handleActiveTab(value as GuardianDetailsTabs)}
              >
                <TabsTriggers />
              </Tabs.Root>
            </div>
          </GuardianDetailsCompactedHeader>
        )}

        <TopPageWrapper ref={headerRef}>
          <Grid>
            <GridItem>
              <GuardianDetailsHeader
                schoolSlug={school?.slug ?? ''}
                guardianId={guardianId}
                isLoading={isFetchGuardianLoading}
                openEditRegistrationDrawer={openEditRegistrationDrawer}
              />
              <GuardianDetailsCard
                guardian={guardian}
                isLoading={isFetchGuardianLoading}
                openEditRegistrationDrawer={openEditRegistrationDrawer}
              />
            </GridItem>
          </Grid>
        </TopPageWrapper>
        <AlertBannerWrapper>
          <GuardianAlertBanner
            guardianTaxId={guardian?.tax_id ?? ''}
            isLoading={isFetchGuardianLoading}
            isError={isFetchGuardianError}
          />
        </AlertBannerWrapper>
        <div className="table">
          <Grid>
            <GridItem>
              <NewTabs
                activeTab={activeTab}
                onChangeTab={handleActiveTab}
                onChangeFinancialSummaryTab={setActiveTab}
                showGeneratingInvoicesLoading={showGeneratingInvoicesLoading}
                setShowGeneratingInvoicesLoading={setShowGeneratingInvoicesLoading}
                showPendingEnrollmentProcessingAlert={showPendingEnrollmentProcessingAlert}
                setShowPendingEnrollmentProcessingAlert={setShowPendingEnrollmentProcessingAlert}
              />
            </GridItem>
          </Grid>
        </div>

        {abrirCadastro && isFetchGuardianFetched && guardian && (
          <EditRegistrationDrawer
            isOpen={Boolean(abrirCadastro)}
            onClose={handleEditRegistrationDrawerClose}
            guardian={guardian}
            refreshData={refreshData}
          />
        )}
      </ContractProvider>
    </Wrapper>
  )
}
