import { useEffect, useRef, useState } from 'react'

import { validateTimestamp } from '../../../utils/validateTimestamp'
import { INTERVAL_MS, LOCALSTORAGE_TIMESTAMP_KEY } from '../constants'

export const useCountdown = (setShowTooltip: (value: boolean) => void) => {
  const [expirationTimestamp, setExpTimestamp] = useState<number>(0)
  const [timestampDiff, setTimestampDiff] = useState<number>(0)
  const intervalId = useRef<NodeJS.Timeout>()

  useEffect(() => {
    const exp = localStorage.getItem(LOCALSTORAGE_TIMESTAMP_KEY)
    setExpTimestamp(validateTimestamp(exp as string))
  }, [])

  useEffect(() => {
    const calcTimestampDiff = () => expirationTimestamp - Date.now()

    setTimestampDiff(calcTimestampDiff())
    intervalId.current = setInterval(() => {
      const diff = calcTimestampDiff()
      if (diff <= 0) setShowTooltip(false)
      setTimestampDiff(diff)
    }, INTERVAL_MS)

    return () => {
      clearInterval(intervalId.current)
    }
  }, [expirationTimestamp])

  const shouldWait = timestampDiff > 0

  if (!shouldWait) {
    clearInterval(intervalId.current)
  }

  return {
    shouldWait,
    setExpTimestamp,
    timestampDiff,
  }
}
