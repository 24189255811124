import styled from 'styled-components'

import { MAX_STEP_WIDTH } from '../../constants'

export const Container = styled.div`
  max-width: ${MAX_STEP_WIDTH}px;
  margin: 0 auto;
  padding-bottom: 150px;
`

export const ListItemSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
`
