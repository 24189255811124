import { useEffect } from 'react'

import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { Callout } from '@gravity/callout'
import { Button } from '@gravity/button'
import { DashedArrowRight } from '@gravity/icons'

import { useGetGuardians } from '@/shared/hooks/queries/guardian'

import * as Styled from './styles'
import type { AddNewGuardianFormData, AddNewGuardianStepProps } from './types'
import { PageFooter } from '../PageFooter'
import { AddNewGuardianInputs } from './components/AddNewGuardianInputs'
import { useAddNewGuardianForm } from './hooks/useAddNewGuardianForm'
import { TaxIdInput } from './components/TaxIdInput'

export const AddNewGuardianStep = ({
  schoolId,
  schoolSlug,
  newGuardian,
  onComplete,
  goBackBtnDisabled,
  onClickGoBack,
  setNewGuardian,
}: AddNewGuardianStepProps) => {
  const { form, taxId, isTaxIdValid } = useAddNewGuardianForm(newGuardian)

  const getGuardiansQuery = useGetGuardians(
    { schoolId: schoolId, taxID: taxId },
    { enabled: isTaxIdValid }
  )

  const guardianExists =
    isTaxIdValid && getGuardiansQuery.isSuccess && getGuardiansQuery.data?.length > 0

  const onSubmit = (data: AddNewGuardianFormData) => {
    setNewGuardian(data)
    onComplete()
  }

  useEffect(() => {
    const newFormData = getGuardiansQuery.data?.[0] ?? {}
    const currTaxId = form.getValues('tax_id')
    form.reset({ ...newFormData, tax_id: currTaxId })
  }, [getGuardiansQuery.data])

  const openGuardianPage = () => window.open(`/${schoolSlug}/responsaveis/${taxId}`, '_blank')

  return (
    <>
      <Styled.HeaderWrapper>
        <Heading variant="heading-h4-medium">Troca de titularidade de contrato</Heading>
        <Text variant="subtitle-regular">Informe os dados do novo responsável financeiro</Text>
      </Styled.HeaderWrapper>

      <Styled.Form onSubmit={form.handleSubmit(onSubmit)}>
        <TaxIdInput form={form} />

        {guardianExists && (
          <Callout
            text="Boa notícia: este responsável já existe na Plataforma isaac!"
            linkLabel="Visualizar responsável"
            href="#"
            onLinkClick={openGuardianPage}
          />
        )}

        {isTaxIdValid && (
          <AddNewGuardianInputs
            form={form}
            disableAllInputs={guardianExists}
            isLoading={getGuardiansQuery.isLoading}
          />
        )}

        <PageFooter
          goBackBtnDisabled={goBackBtnDisabled}
          onClickNextBtn={onComplete}
          onClickGoBack={onClickGoBack}
          SubmitButton={
            <Button
              aria-label="Avançar para o próximo step"
              variant="solid"
              type="submit"
              iconEnd={<DashedArrowRight aria-hidden="true" role="img" />}
              disabled={getGuardiansQuery.isLoading}
            >
              Próximo
            </Button>
          }
        />
      </Styled.Form>
    </>
  )
}
