import styled from 'styled-components'

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.gravity.spacing['13']};
  gap: ${({ theme }) => theme.gravity.spacing['2']};
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.gravity.spacing['8']};
  gap: ${({ theme }) => theme.gravity.spacing['6']};
`
