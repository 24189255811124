import { Table } from '@gravity/table'
import { Tooltip } from '@gravity/tooltip'
import { ProductDeliveryItem } from '@monorepo/account-reports/services/product_deliveries/types'

export type Props = {
  deliveryItem: ProductDeliveryItem
}

export const RenderDeliveryItem = ({ deliveryItem }: Props) => {
  if (deliveryItem.delivery_not_applied) {
    return (
      <Tooltip text="Para mais informações, entre em contato com o suporte">
        <Table.TextCell>A entrega não se aplica</Table.TextCell>
      </Tooltip>
    )
  }

  return (
    <Tooltip text="Para mudar o status de entrega, entrar em contato com o suporte">
      <Table.TextCell enableCheckbox checked disabled subtext={`${deliveryItem.delivery_date}`}>
        Entregue
      </Table.TextCell>
    </Tooltip>
  )
}
