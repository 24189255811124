import React, { InputHTMLAttributes } from 'react'
import { Search } from '@gravity/icons'

import { Container, Input } from './styles'

export const InputSearch = React.forwardRef<HTMLDivElement, InputHTMLAttributes<HTMLInputElement>>(
  (props, forwardedRef) => (
    <Container id="input-search-container" ref={forwardedRef}>
      <Search size="sm" className="icon-search" />
      <Input type="text" placeholder="Busque aluno ou responsável por nome" {...props} />
    </Container>
  )
)
