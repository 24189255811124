import { Filter } from '@monorepo/report/hooks/useIsaacPayPayoutReportFilter/types'
import { FilterOptions } from '@monorepo/report/models/report'

/**
 * Utility function to parse filter options for isaacPay external payment report page
 *
 * @param data Filter options
 *
 * @returns Parsed filter options
 */
export const mergePayoutAndExternalPaymentFilters = (
  ...objects: FilterOptions[]
): FilterOptions<Filter> => {
  const mergedObject: FilterOptions = {
    events: [],
    products: [],
    amountSigns: [],
  }

  const productLabelsMap: { [label: string]: boolean } = {}

  for (const obj of objects) {
    // Merge events and amountSigns arrays
    mergedObject.events && mergedObject.events.push(...(obj.events || []))
    mergedObject.amountSigns && mergedObject.amountSigns.push(...(obj.amountSigns || []))

    if (!obj.products) continue

    // Add unique product labels to the map
    for (const product of obj.products) {
      if (!productLabelsMap[product.label]) {
        productLabelsMap[product.label] = true
        mergedObject.products && mergedObject.products.push(product)
      }
    }
  }

  return mergedObject as FilterOptions<Filter>
}
