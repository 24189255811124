import { createContext } from 'react'
import api from '@/utils/api'
import { ApiClient } from '@/shared/core/api'
import { useApiClient } from '@/shared/hooks'

type ApiContext = {
  api: ReturnType<typeof api>
  correlationId: string
  setCorrelationId: (correlationId: string) => void
}

export const ApiContext = createContext<ApiContext>(null)
const apiClient = new ApiClient()

export const ApiProvider = ({ children }) => {
  const { correlationId, setCorrelationId } = useApiClient()

  return (
    <ApiContext.Provider
      value={{
        correlationId,
        setCorrelationId,
        api: api(apiClient, setCorrelationId),
      }}
    >
      {children}
    </ApiContext.Provider>
  )
}
