import { Controller, UseFormReturn } from 'react-hook-form'
import { Select } from '@gravity/select'

export const formOfPaymentOptions = [
  { name: 'Boleto ou Pix', value: ['PIX', 'BANK_SLIP'], key: 'PIX_BANKSLIP' },
  { name: 'Cartão de crédito', value: ['CREDIT_CARD'], key: 'CREDIT_CARD' },
]

const options = formOfPaymentOptions.map(({ name, key }) => ({
  label: name,
  value: key,
}))

type DiscountAgglutinationProps = {
  defaultValue?: string[]
  disableForm: boolean
  form: UseFormReturn<any>
  paymentMethod?: string
}

function AgglutinationPayment({
  defaultValue = formOfPaymentOptions.find(option => option.key === 'PIX_BANKSLIP')?.value,
  paymentMethod = 'payment_method',
  form,
  disableForm,
}: DiscountAgglutinationProps) {
  const { control, setValue } = form

  return (
    <Controller
      rules={{ required: true, validate: v => v?.length > 0 }}
      control={control}
      name={paymentMethod}
      defaultValue={defaultValue}
      render={({ field: { value, ref: _, ...rest } }) => {
        const valueAsKey = formOfPaymentOptions.find(
          option => option.value.toString() === value.toString()
        )?.key
        return (
          <Select
            {...rest}
            data-testid={paymentMethod}
            label="Forma de pagamento"
            placeholder="Selecione uma forma de pagamento"
            value={valueAsKey}
            onValueChange={eventValue => {
              const selectedValue = formOfPaymentOptions.filter(
                option => option.key === eventValue
              )[0]

              setValue(paymentMethod, selectedValue?.value, {
                shouldValidate: true,
              })
            }}
            disabled={disableForm}
            options={options}
            size={3}
          />
        )
      }}
    />
  )
}

export default AgglutinationPayment
