import React, { useState } from 'react'
import { Stepper } from '@gravity/stepper'
import { StatusStepperProps } from './types'
import { CardStep, Description, HeaderTitle, Title } from './styles'
import { useContextChosenOffer } from '../../contexts/CreditDataContext'
import { CreditType } from '@/shared/services/credit/types'

const chooseStep = (creditType: CreditType) => {
  const commonSteps = [
    {
      label: 'Coleta de documentos',
      description:
        'Contrato social e suas alterações \n Documento com foto dos representantes legais',
      finishedDescription: 'Concluído',
    },
    {
      label: 'Assinatura de contrato',
      description: 'Aditivo de contrato \n CCB (Cédula de Crédito Bancário)',
      finishedDescription: 'Concluído',
    },
    {
      label: 'Transferência do crédito',
      description: 'Você irá receber a transferência na data combinada',
      finishedDescription: 'Concluído',
    },
  ]

  if (creditType === CreditType.CPCR) {
    return [
      {
        label: 'Solicitação recebida \n com sucesso!',
        description:
          'Para prosseguir com a oferta, renove seu contrato com o isaac. Seu consultor entrará em contato para dar continuidade à contratação.',
        finishedDescription: 'Contratação iniciada',
      },
      {
        label: 'Renovação de contrato',
        description: 'Parceria da escola com o isaac',
        finishedDescription: 'Concluído',
      },
      ...commonSteps,
    ]
  } else {
    return [
      {
        label: 'Solicitação de crédito',
        description:
          'Em até 2 dias úteis, a equipe de crédito entrará em \n contato para continuar a contratação.',
        finishedDescription: 'Contratação iniciada',
      },
      ...commonSteps,
    ]
  }
}

export function StatusStepper({ statusStepper }: StatusStepperProps) {
  const [activeStep] = useState(statusStepper.step)
  const { chosenOffer } = useContextChosenOffer()

  const isStepCompleted = (currentStep: number) => {
    return activeStep > currentStep || (currentStep === activeStep && statusStepper.finished)
  }

  return (
    <div className="flex justify-center items-center w-full">
      <div className="w-full max-w-3xl mb-4">
        <HeaderTitle>
          <Title variant="heading-h2-medium">{`Solicitação recebida \ncom sucesso!`}</Title>
          <Description variant="body-1-regular">
            Veja abaixo os próximos passos da contratação de crédito
          </Description>
        </HeaderTitle>
        <Stepper.Root activeStep={activeStep} orientation="vertical">
          {chooseStep(chosenOffer.category).map((step, index) => {
            const isFirstStep = index === 0
            const isSecondStepCompleted = isStepCompleted(1)
            const showFinishedDescription =
              isStepCompleted(index) && (!isFirstStep || isSecondStepCompleted)
            return (
              <CardStep key={index} step={index}>
                <Stepper.Header defaultIcon>{step.label}</Stepper.Header>
                <Stepper.Description>
                  {showFinishedDescription ? step.finishedDescription : step.description}
                </Stepper.Description>
              </CardStep>
            )
          })}
        </Stepper.Root>
      </div>
    </div>
  )
}
