import { DialogPrimitives } from '@gravity/dialog'
import type { EnrollmentPaymentPlan } from '@monorepo/enrollment/services/enrollmentService/types'
import { formatDate, formatCentsToReal } from '@/shared/utils'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'
import * as Styled from './styles'
import { CalendarOutline } from '@gravity/icons'
import { TextField } from '@gravity/text-field'
import theme from '@/shared/theme'

interface Props {
  handleDialogOpenChange: (open: boolean) => void
  paymentPlans: EnrollmentPaymentPlan[] | null
  visible: boolean
}

export const View = ({ visible, paymentPlans, handleDialogOpenChange }: Props) => {
  return (
    <DialogPrimitives.Root open={visible} onOpenChange={handleDialogOpenChange}>
      <DialogPrimitives.Overlay backdrop />
      <DialogPrimitives.Content size={3} title="Ver condições de pagamento">
        <Styled.PaymentPlansWrapper>
          {paymentPlans?.map((paymentPlan, idx) => (
            <div key={paymentPlan?.due_date}>
              <Heading variant="heading-h4-medium">{`Condição de pagamento ${idx + 1}`}</Heading>
              <Styled.RowWrapper>
                <Styled.InfoWrapper>
                  <Text variant="subtitle-medium">Qual o valor da antecipação?</Text>
                  <TextField
                    name="advance-payment-value"
                    size={3}
                    placeholder={formatCentsToReal(paymentPlan?.amount?.toString() ?? '0')}
                    disabled
                  />
                </Styled.InfoWrapper>
                <Styled.InfoWrapper>
                  <Text variant="subtitle-medium">Qual a data de validade?</Text>
                  <TextField
                    name="due-date"
                    size={3}
                    placeholder={
                      paymentPlan.due_date
                        ? formatDate(paymentPlan.due_date, 'DD/MM/YYYY')
                        : undefined
                    }
                    iconStart={
                      <CalendarOutline color={theme.gravity.colors['colors-text-main-4']} />
                    }
                    disabled
                  />
                </Styled.InfoWrapper>
              </Styled.RowWrapper>
            </div>
          ))}
        </Styled.PaymentPlansWrapper>
      </DialogPrimitives.Content>
    </DialogPrimitives.Root>
  )
}
