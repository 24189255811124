import { useHistory } from 'react-router-dom'
import { Text } from '@gravity/text'
import { FileList3Outline, LogoutBoxOutline, QuestionOutline, TeamOutline } from '@gravity/icons'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { useJWT } from '@/shared/hooks/useJWT'
import { useApi } from '@/utils/hooks/useApi'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { client } from '@/shared/integrations/sorting-hat'

import { ContextDropdown } from '@/shared/components/ContextDropdown'

import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import type { UserGroupNames } from '@/shared/integrations/sorting-hat/types'

import { FAQ_URL, PRIVACY_POLICY_URL, TERMS_FILE_URL } from './constants'

type UserMenuProps = {
  subtitle: string
  title: string
  variation?: 'primary' | 'secondary'
}

export const shouldShowUserManagementMenu = (
  isBackofficeRealm: boolean,
  isSchoolStaged: boolean | undefined,
  userGroupName: UserGroupNames | undefined
): boolean => {
  if (isBackofficeRealm) return false
  if (isSchoolStaged && userGroupName !== 'gestor') return false
  return true
}

/**
 * User options dropdown component for header
 *
 * @param props.title Component title
 * @param props.subtitle Component subtitle
 */
export const UserMenu = ({ title, subtitle }: UserMenuProps) => {
  /**
   * TODO: Use apiClient
   */
  const { api } = useApi()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { schoolSlug, school } = useSelectedSchool()
  const { push } = useHistory()
  const { getUserGroupNameBySchool, isBackofficeUser } = useJWT()
  const loggedUserGroupName = getUserGroupNameBySchool(school?.id as string)

  const sendEvent = (option: 'faq' | 'terms' | 'logout' | 'privacyPolicy') => {
    const events: Record<'faq' | 'terms' | 'logout' | 'privacyPolicy', EventDispatcherEvents> = {
      faq: EventDispatcherEvents.FAQ_ACCESS,
      terms: EventDispatcherEvents.TERMS_CONDITIONS_PDF_ACCESS,
      logout: EventDispatcherEvents.LOGOUT_CLICK,
      privacyPolicy: EventDispatcherEvents.PRIVACY_POLICY_ACCESS,
    }

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.HEADER_MENU,
        name: events[option],
        action: 'click',
      })
    }
  }

  const handleContextDropdownAction = (action: 'terms' | 'faq' | 'privacyPolicy') => {
    sendEvent(action)

    const ACTION_URLS = {
      privacyPolicy: PRIVACY_POLICY_URL,
      terms: TERMS_FILE_URL,
      faq: FAQ_URL,
    }

    window.open(ACTION_URLS[action], '_blank')
  }

  const handleLogoutClick = async () => {
    sendEvent('logout')

    try {
      await api.auth.logout()
      client.logout()
    } catch (err) {
      client.logout()
    }
  }

  const lowerCasedTitle = title.toLowerCase()

  const menuOptions = [
    {
      label: 'Central de Ajuda',
      name: 'faq',
      icon: <QuestionOutline />,
      onClick: () => handleContextDropdownAction('faq'),
    },
    {
      label: 'Termos de uso',
      name: 'terms',
      icon: <FileList3Outline />,
      onClick: () => handleContextDropdownAction('terms'),
    },
    {
      label: 'Política de Privacidade',
      name: 'privacyPolicy',
      icon: <FileList3Outline />,
      onClick: () => handleContextDropdownAction('privacyPolicy'),
    },
    { label: 'Sair', name: 'logout', onClick: handleLogoutClick, icon: <LogoutBoxOutline /> },
  ]

  if (shouldShowUserManagementMenu(isBackofficeUser, school?.is_staged, loggedUserGroupName)) {
    menuOptions.unshift({
      label: 'Gestão de usuários',
      name: 'access-management',
      icon: <TeamOutline />,
      onClick: () => {
        push(`/${schoolSlug}/acessos`)

        if (isInitialized) {
          eventDispatcherClient.sendEvent({
            scope: EventDispatcherEventScopes.ACCESS_MANAGEMENT,
            name: EventDispatcherEvents.BUTTON_CLICKED,
            action: 'click',
            customProperties: {
              $button_name: 'gestao_de_acessos',
            },
          })
        }
      },
    })
  }

  return (
    <div className="flex items-center justify-end gap-4">
      <div className="text-right">
        <Text variant="body-2-medium" as="p" style={{ textTransform: 'capitalize' }}>
          {lowerCasedTitle}
        </Text>

        <Text variant="caption-regular" as="p" className="text-colors-text-main-3">
          {subtitle}
        </Text>
      </div>

      <div>
        <ContextDropdown options={menuOptions} />
      </div>
    </div>
  )
}
