import { formatter } from '@/modules/guardians/utils/formatter'
import { Text } from '@gravity/text'

import { getDiscountTitle } from '@/modules/guardians/InstallmentsDrawerContainer/utils'

import {
  InfoText,
  DiscountInfo,
  DiscountInfoHeader,
  DiscountInfoItem,
  DiscountInfoItemWrapper,
  DiscountTotalAmount,
} from './styles'
import { DiscountType } from '@/shared/interfaces'

interface DiscountProps {
  amount: number
  days_before_due_date: number
  description: string
  is_active: boolean
  limit_date: string
}

interface DiscountsProps {
  discountPercentage: string
  isActive: boolean
  items: DiscountProps[]
  totalAmount: number
  type: DiscountType
}

export const Discount = ({
  type,
  isActive,
  totalAmount,
  items,
  discountPercentage,
}: DiscountsProps) => {
  return (
    <DiscountInfo>
      <DiscountInfoHeader>
        <Text variant="body-1-regular">{getDiscountTitle(type)}</Text>
        <DiscountTotalAmount disabled={!isActive} variant="body-1-regular">
          {`- ${formatter.amount(totalAmount)}`} ({discountPercentage}%)
        </DiscountTotalAmount>
      </DiscountInfoHeader>

      <DiscountInfoItemWrapper>
        {items.map(({ description, amount, is_active, limit_date, days_before_due_date }, key) => (
          <DiscountInfoItem key={key}>
            <InfoText disabled={!is_active} variant="body-2-regular">{`${description} 
            ${
              days_before_due_date >= 0 ? `- válido até ${formatter.full_date_br(limit_date)}` : ''
            }`}</InfoText>
            <InfoText disabled={!is_active} variant="body-2-regular">
              {formatter.amount(amount)}
            </InfoText>
          </DiscountInfoItem>
        ))}
      </DiscountInfoItemWrapper>
    </DiscountInfo>
  )
}
