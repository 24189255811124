import { Tooltip } from '@gravity/tooltip'
import { InformationOutline } from '@gravity/icons'
import { Description } from './styles'
import { Text } from '@gravity/text'
import { PayoutTransfersSectionCategoryTitleProps } from './types'

export const PayoutTransfersSectionCategoryTitle = ({
  children,
  tooltip,
  description,
}: PayoutTransfersSectionCategoryTitleProps) => {
  return (
    <div className="flex flex-col gap-4 max-w-[15rem]">
      <Text variant="body-1-medium" className="flex items-center gap-1">
        {children}

        {tooltip && (
          <Tooltip text={tooltip}>
            <span>
              <InformationOutline size="sm" />
            </span>
          </Tooltip>
        )}
      </Text>

      {description && <Description variant="body-2-regular">{description}</Description>}
    </div>
  )
}
