import { Text } from '@gravity/text'
import { CardVariant } from '../../../hooks/useStatusDetailCards'
import { iconPath } from '../../../utils/iconPath'
import { EnrolledTextContainer } from '../styles'

export const EnrolledText = ({ variant, text }: { text: string; variant: CardVariant }) => {
  return (
    <EnrolledTextContainer>
      <div className="icon-container">{iconPath({ variant })}</div>
      <Text variant="body-1-regular">{text}</Text>
    </EnrolledTextContainer>
  )
}
