import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useOnboardingApi } from '../services/api'
import { GetBatchSummaryParams, GetBatchSummaryResponse } from '../services/types'

export const BATCH_SUMMARY_QUERY_KEY = 'v2-batch-summary'

export const useBatchSummaryQuery = (
  { batchID }: GetBatchSummaryParams,
  options?: UseQueryOptions<GetBatchSummaryResponse>
) => {
  const api = useOnboardingApi()

  return useQuery<GetBatchSummaryResponse>(
    [BATCH_SUMMARY_QUERY_KEY, batchID],
    async () => {
      const response = await api.getBatchSummary({ batchID })

      return response.data
    },
    options
  )
}
