import styled from 'styled-components'
import { Text } from '@gravity/text'

export const Container = styled.div`
  display: flex;
  max-width: 750px;
  padding: ${props => props.theme.gravity.spacing[8]} 0 ${props => props.theme.gravity.spacing[14]}
    0;
  flex-direction: column;
  align-items: flex-start;
  gap: ${props => props.theme.gravity.spacing[4]};
`

export const PageDescription = styled(Text)`
  color: ${props => props.theme.gravity.colors['colors-text-main-3']};
`
