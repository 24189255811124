import * as Sentry from '@sentry/react'

import config from '@/config'

enum SENTRY_DSN {
  GUARDIANS = 'https://117b1d8f1889446794a66de878573919@o995329.ingest.sentry.io/5954449',
  SCHOOLS = 'https://5f6f4fe9528a4ee1bfb6128ce3885e1d@o995329.ingest.sentry.io/6218124',
}

const initDefaults: Sentry.BrowserOptions = {
  dsn: SENTRY_DSN.SCHOOLS,
  environment: config.ENV,
  release: config.GIT_TAG,
  tracesSampleRate: 0.3,
}

export const initSentry = (options: Sentry.BrowserOptions) =>
  Sentry.init({
    ...initDefaults,
    ...options,
  })
