import { StepComponentProps } from '@/modules/guardians/Negotiation/types'
import { StepsNegotiationFlow } from '../../../hooks/useSteps'
import StepTitle from '../../../components/StepTitle'
import Conclusion from '../../../steps/Conclusion'
import { HelpButtonEntryProps } from '../../../components/StepFooter/types'

const EntryConclusion = ({
  entryInformation,
  onFinish,
  goBack,
  conclusionInfo,
}: StepComponentProps) => {
  if (!conclusionInfo.receivableId) return null

  const helpButtonEntry: HelpButtonEntryProps = {
    productName: entryInformation.product[0].name,
    studentName: entryInformation.student.name,
    amount: entryInformation.value,
    orderReference: entryInformation.orderReference,
  }

  return (
    <>
      <StepTitle
        ondueCount={1}
        overdueCount={0}
        productName={entryInformation.product[0].name}
        studentName={entryInformation.student.name}
        totalAmount={entryInformation.value}
      />
      <Conclusion
        id={StepsNegotiationFlow.CONCLUSION}
        key="conclusion_step"
        goBack={goBack}
        onFinish={onFinish}
        conclusionInfo={conclusionInfo}
        helpButtonEntry={helpButtonEntry}
      />
    </>
  )
}

export default EntryConclusion
