import styled from 'styled-components'
import { Heading } from '@gravity/heading'
import { Text as GravityText } from '@gravity/text'

export const HeadingStyled = styled(Heading)`
  margin-bottom: ${props => props.theme.gravity.spacing[10]};
`

export const Text = styled(GravityText)`
  margin-bottom: ${props => props.theme.gravity.spacing[17]};
`

export const ErrorStateWrapper = styled.div`
  padding-top: ${props => props.theme.gravity.spacing[11]};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
`
