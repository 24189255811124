import { useState, useEffect } from 'react'
import { Installment } from '@/modules/guardians/GuardianDetails/types'
import {
  InstallmentRowType,
  InstallmentStatus,
  InstallmentType,
  ReceivableType,
} from '@/modules/guardians/GuardianDetails/constants'
import { Row } from '@/modules/guardians/GuardianDetailsInvoicesTab/components/GuardianInvoicesTable/types'
import { includes } from 'ramda'
import { formatter } from '@/modules/guardians/utils/formatter'
import { BadgeProps } from '@gravity/badge'
import useCheckoutCart from '@/modules/guardians/hooks/useCheckoutCart'

interface UseGuardianDetailsInvoiceProps {
  installments: Installment[]
}

export const paramsDict: PartialRecord<
  InstallmentStatus,
  { color: BadgeProps['color']; text: string }
> = {
  AGGLUTINATED: { color: 'neutral', text: 'Aglutinada' },
  CANCELED: { color: 'neutral', text: 'Cancelada' },
  DUE_TODAY: { color: 'warning', text: 'Vence hoje' },
  OPEN: { color: 'accent', text: 'A vencer' },
  OVERDUE: { color: 'error', text: 'Vencido' },
  PAID: { color: 'success', text: 'Pago' },
  PAID_AND_CANCELED: { color: 'neutral', text: 'Paga e cancelada' },
  RENEGOTIATED: { color: 'neutral', text: 'Renegociada' },
  PENDING: { color: 'neutral', text: 'Aguardando' },
}

export const getRowType = (
  type: Installment['type'],
  receivable_type: Installment['receivable_type']
) => {
  if (type === InstallmentType.DOWN_PAYMENT) return InstallmentRowType.NEGOTIATION_START

  if (receivable_type === ReceivableType.NEGOTIATION) return InstallmentRowType.NEGOTIATION

  if (receivable_type === ReceivableType.ENROLLMENT)
    return InstallmentRowType.ENROLLMENT_INSTALLMENT

  return InstallmentRowType.INSTALLMENT
}

export const mapDataToRows = (rawData: Installment[]): Row[] => {
  return rawData?.map(item => {
    const row_type = getRowType(item.type, item.receivable_type)
    const order_reference = formatter.order_reference(item.order_reference)

    const isStatusOpen = includes(item.status, [
      InstallmentStatus.DUE_TODAY,
      InstallmentStatus.OPEN,
      InstallmentStatus.OVERDUE,
    ])

    const competecenceDate = item.accrual_date ?? item.competence_date

    const { color, text } = paramsDict[item.status] ?? {
      color: 'accent',
      text: '',
    }

    return {
      ...item,
      key: item.urn,
      row_type,
      data: {
        accrual_date: formatter.competence_date(competecenceDate),
        enableCheckbox:
          !(
            item.receivable_type === ReceivableType.ENROLLMENT &&
            item.status === InstallmentStatus.OVERDUE
          ) && isStatusOpen,
        enableCheckboxVisibility: isStatusOpen,
        amount: formatter.amount(item.amount),
        checked: false,
        disabled: includes(item.status, [InstallmentStatus.PENDING, InstallmentStatus.CANCELED]),
        type: formatter.newInstallmentType(row_type),
        competence_date: formatter.competence_date(item.competence_date),
        order_reference:
          row_type === InstallmentRowType.NEGOTIATION_START
            ? `Entrada - ${order_reference}`
            : order_reference,
        due_date: formatter.full_date(item.due_date),
        status: {
          color,
          text,
        },
        negotiation_id: item.negotiation_id
          ? `Negociação ${formatter.negotiation_id(item.negotiation_id)}`
          : '',
      },
    }
  })
}

export const useGuardianDetailsInvoice = ({ installments }: UseGuardianDetailsInvoiceProps) => {
  const [rows, setRows] = useState(mapDataToRows(installments))
  const { addInstallments } = useCheckoutCart()

  const handleRowSelect = (urn: string) => {
    setRows(prevState =>
      prevState.map(item =>
        item.urn === urn ? { ...item, data: { ...item.data, checked: !item.data.checked } } : item
      )
    )
  }

  const handleSelectAll = (select: boolean | 'indeterminate') => {
    if (select === 'indeterminate') return

    setRows(prevState =>
      prevState.map(item =>
        item.data.enableCheckbox ? { ...item, data: { ...item.data, checked: select } } : item
      )
    )
  }

  const batchSelection: boolean | 'indeterminate' = (() => {
    const selectedItems = rows.filter(item => item.data.checked).length
    const totalItems = rows.filter(item => item.data.enableCheckbox).length

    if (selectedItems === 0) return false

    if (selectedItems === totalItems) return true

    return 'indeterminate'
  })()

  const enableBatchSelection = !!rows.filter(item => item.data.enableCheckbox).length

  useEffect(() => {
    setRows(mapDataToRows(installments))
  }, [installments])

  useEffect(() => {
    addInstallments(rows.filter(item => item.data.checked).filter(Boolean))
  }, [rows])

  return {
    rows,
    enableBatchSelection,
    batchSelection,
    handleRowSelect,
    handleSelectAll,
  }
}
