import { useState } from 'react'
import { SelectPaymentMethodProps } from './types'

import { CardStyled, Container, FullWidthDiv, RadioGroupStyled, Title } from './styles'
import OptionCard from '../../../components/OptionCard/OptionCard'
import { SimulationPaymentMethod } from '@/modules/guardians/models/agreement'
import CardForm from '../../../steps/PaymentOptionsStep/component/Forms/CardForm'
import { PaymentOptionsValue } from '../../../types'
import { formatCentsToReal } from '@/shared/utils'
import Footer from '../../../components/StepFooter/StepFooter'
import StepTitle from '../../../components/StepTitle/StepTitle'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import CalloutPaymentLink from '../../../components/CalloutPaymentLink'
import { Separator } from '@gravity/separator'
import { HelpButtonEntryProps } from '../../../components/StepFooter/types'
import { Text } from '@gravity/text'

const SelectPaymentMethod = ({
  isLoading,
  goBack,
  onFinish,
  entryInformation,
}: SelectPaymentMethodProps) => {
  const [selectOption, setSelectOption] = useState<SimulationPaymentMethod>()
  const [isValid, setIsValid] = useState<boolean>(false)
  const [chosenOption, setChosenOption] = useState<PaymentOptionsValue>()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const handleChosenOption = (value: PaymentOptionsValue) => {
    setChosenOption(value)
  }

  const helpButtonEntry: HelpButtonEntryProps = {
    productName: entryInformation.product[0].name,
    studentName: entryInformation.student.name,
    amount: entryInformation.value,
    orderReference: entryInformation.orderReference,
  }

  const handleValidate = (value: boolean) => setIsValid(value)

  const handleFinish = () => {
    const customParams =
      chosenOption?.paymentOption === SimulationPaymentMethod.CREDIT_CARD
        ? {
            $Total_amount: entryInformation.value,
            $Payment_conditions:
              chosenOption?.installments === 1
                ? 'à vista'
                : `parcelado em ${chosenOption?.installments}x`,
          }
        : { $Total_amount: entryInformation.value }

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.INSTALLMENT_NEGOTIATIONS,
        entity: 'Pagamento',
        name:
          chosenOption?.paymentOption === SimulationPaymentMethod.CREDIT_CARD
            ? EventDispatcherEvents.CLICKED_PAY_NEGOTIATION_2_ENTRY_CREDIT
            : EventDispatcherEvents.CLICKED_PAY_NEGOTIATION_2_ENTRY_DEBIT,
        action: 'click',
        customProperties: customParams,
      })
    }
    onFinish(chosenOption)
  }

  const oneSumpText = `${formatCentsToReal(entryInformation.value)} à vista sem juros`

  const creditInformationText =
    entryInformation.maxInstallment === 1
      ? oneSumpText
      : `Até ${entryInformation.maxInstallment}x de ${formatCentsToReal(
          entryInformation.value / entryInformation.maxInstallment
        )} sem juros`

  return (
    <Container>
      <StepTitle
        ondueCount={1}
        overdueCount={0}
        productName={entryInformation.product[0].name}
        studentName={entryInformation.student.name}
        totalAmount={entryInformation.value}
      />
      <Title>
        <Text variant="subtitle-regular">Selecione a forma de pagamento</Text>
      </Title>
      <CardStyled className="mb-4">
        <RadioGroupStyled
          style={{ display: 'flex', flexDirection: 'column' }}
          aria-label="form of payment"
          name="radio-buttons-form-of-payment"
        >
          <FullWidthDiv key={SimulationPaymentMethod.CREDIT_CARD}>
            <OptionCard
              informationText={{ info: creditInformationText }}
              isSelected={selectOption === SimulationPaymentMethod.CREDIT_CARD}
              onSelect={setSelectOption}
              type={SimulationPaymentMethod.CREDIT_CARD}
            >
              <CardForm
                handleValidate={handleValidate}
                handleValue={handleChosenOption}
                paymentOption={SimulationPaymentMethod.CREDIT_CARD}
                maxInstallment={entryInformation.maxInstallment}
                totalAmount={entryInformation.value}
              />
            </OptionCard>
          </FullWidthDiv>
          <Separator color="neutral-2" />
          <FullWidthDiv key="DEBIT_CARD">
            <OptionCard
              informationText={{ info: oneSumpText }}
              isSelected={selectOption === SimulationPaymentMethod.DEBIT_CARD}
              onSelect={setSelectOption}
              type={SimulationPaymentMethod.DEBIT_CARD}
            >
              <CardForm
                handleValidate={handleValidate}
                handleValue={handleChosenOption}
                paymentOption={SimulationPaymentMethod.DEBIT_CARD}
                maxInstallment={1}
                totalAmount={entryInformation.value}
              />
            </OptionCard>
          </FullWidthDiv>
        </RadioGroupStyled>
      </CardStyled>
      <CalloutPaymentLink
        installmentId={entryInformation?.negotiationID}
        link={entryInformation?.paymentLink}
        type="DOWN_PAYMENT"
      />
      <Footer
        goBack={goBack}
        showHelpButton
        btnPrimary={{
          loading: isLoading,
          onClick: handleFinish,
          disabled: !isValid || isLoading,
        }}
        stepName="payment-method"
        helpButtonEntry={helpButtonEntry}
      />
    </Container>
  )
}

export default SelectPaymentMethod
