import { Fragment, useState, useEffect, useRef, Dispatch, SetStateAction } from 'react'
import { Controller, SubmitHandler, useFieldArray, useForm, UseFormReturn } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { DatepickerPrimitives as Datepicker } from '@gravity/datepicker'
import { Button } from '@gravity/button'
import { Select } from '@gravity/select'
import { TextField } from '@gravity/text-field'
import { Text } from '@gravity/text'
import { Callout } from '@gravity/callout'
import ReceivableFinancialSummary from '@/modules/guardians/InstallmentsDrawerContainer/RenegotiationDialog/ReceivableFinancialSummary'
import { useApi } from '@/utils/hooks/useApi'
import { Receivable } from 'src/shared/interfaces'
import { Heading } from '@gravity/heading'
import { propEq, repeat, sum, prop, range } from 'ramda'
import {
  formatCentsToReal,
  DATE_FORMAT,
  leftZeroPadding,
  formatDate,
  getStartOfToday,
} from 'src/shared/utils'
import dayjs from 'dayjs'
import { Separator } from '@gravity/separator'
import { InstallmentDialogForm } from '../InstallmentDialogForm'
import { InstallmentDialogHeader } from '../components/InstallmentDialogHeader'

const possibleInstallmentAmount = range(1, 13)
export const formOfPaymentOptions = [
  { name: 'Boleto ou Pix', value: JSON.stringify(['PIX', 'BANK_SLIP']), key: 'PIX_BANKSLIP' },
  { name: 'Cartão de crédito', value: JSON.stringify(['CREDIT_CARD']), key: 'CREDIT_CARD' },
]

export type RenegotiationContentProps = {
  form?: UseFormReturn<RenegotiationFormType>
  onFinish: () => void
  onSubmit?: SubmitHandler<RenegotiationFormType>
  receivables: Array<Receivable>
  selectedReceivableId: uuid
  setSelectedReceivableId: Dispatch<SetStateAction<uuid>>
  successCallback: (receivables: Array<Receivable>) => void
}

export type InstallmentFormValues = {
  amount: number
  due_date: Date
}

export type RenegotiationFormType = {
  installments: InstallmentFormValues[]
  numberOfInstallments: number | ''
  payment_method: string
  totalAmount: number
}

const getInstallmentAmounts = (totalAmount: number, numberOfInstallments: number): number[] => {
  if (!numberOfInstallments) {
    return []
  }
  const installmentAmount = Math.floor(totalAmount / numberOfInstallments)
  const firstInstallmentExtra = totalAmount % numberOfInstallments

  return [
    installmentAmount + firstInstallmentExtra,
    ...repeat<number>(installmentAmount, numberOfInstallments - 1),
  ]
}

const RenegotiationContent = ({
  receivables,
  selectedReceivableId,
  onFinish,
  onSubmit,
}: RenegotiationContentProps) => {
  const { api } = useApi()
  const [shouldCreateNewInstallmentField, setShouldCreateNewInstallmentField] = useState(false)
  const [showBankSlipOptions, setShowBankSlipOptions] = useState(false)
  const [showCreditOptions, setShowCreditOptions] = useState(false)
  const form = useForm<RenegotiationFormType>({
    mode: 'onChange',
    defaultValues: {
      numberOfInstallments: '',
      payment_method: '',
    },
  })
  const { reset, control, getValues, setValue, trigger } = form
  const { fields: installmentFields, append, remove } = useFieldArray({
    control,
    name: 'installments',
  })
  const installmentsRef = useRef<HTMLDivElement>(null)

  const receivable = receivables?.find(propEq('id', selectedReceivableId))
  const isEnrollmentBlock = receivable?.installment?.type === 'ENROLLMENT'

  let [numberOfInstallments, totalAmount] = getValues([
    'numberOfInstallments',
    'totalAmount',
    'payment_method',
  ])
  let futureDates: datestring[]

  const setInstallmentDueDates = () => {
    const newInstallments = getValues('installments')
    if (newInstallments && newInstallments.length && futureDates && futureDates.length) {
      if (showBankSlipOptions) {
        if (newInstallments.length === futureDates.length) {
          for (let i = 0; i < newInstallments.length; i++) {
            futureDates[i] = dayjs(futureDates[i]).toString()
            newInstallments[i].due_date = dayjs(futureDates[i]).utc().toDate()
          }
        }
      } else if (showCreditOptions) {
        futureDates[0] = dayjs(futureDates[0]).toString()
        newInstallments[0].due_date = dayjs(futureDates[0]).utc().toDate()
      }
      setValue('installments', newInstallments)
      trigger()
    }
  }

  const removeInstallmentsAndTriggerCreation = () => {
    remove()
    setShouldCreateNewInstallmentField(true)
  }

  const getWorkingDates = async (form: RenegotiationFormType) => {
    const { payment_method, numberOfInstallments } = form
    return await api.date
      .getWorkingDueDates({
        date: dayjs().add(1, 'day').utc().format('YYYY-MM-DDT00:00:00Z'),
        number_due_dates:
          payment_method && payment_method.length > 1
            ? parseInt(numberOfInstallments.toString())
            : 1,
      })
      .then(({ data }) => {
        futureDates = data ? data.filter(x => !x.includes('0001')) : data
        setInstallmentDueDates()
      })
  }

  const onAddFineAndInstallmentButtonClick = () => {
    const newTotalAmount =
      totalAmount + (receivable?.current_fine ?? 0) + (receivable?.current_interest ?? 0)

    if (!newTotalAmount) {
      setValue('totalAmount', receivable?.current_amount ?? 0, { shouldValidate: true })
    } else {
      setValue('totalAmount', newTotalAmount, { shouldValidate: true })
    }
    removeInstallmentsAndTriggerCreation()
  }

  const onSingleInstallmentChange = () => {
    const installments = getValues('installments')

    const newTotalAmount = sum(installments.map(prop('amount')))
    if (!newTotalAmount) {
      setValue('totalAmount', receivable?.current_amount ?? 0, { shouldValidate: true })
    } else {
      setValue('totalAmount', newTotalAmount, { shouldValidate: true })
    }
  }

  const createNewInstallmentsFromTotalAmount = () => {
    if (!totalAmount) {
      totalAmount = receivable?.current_amount ?? 0
      setValue('totalAmount', receivable?.current_amount ?? 0, { shouldValidate: true })
    }
    if (!shouldCreateNewInstallmentField) {
      if (installmentsRef.current) {
        installmentsRef.current.scrollIntoView({ behavior: 'smooth' })
      }
      return
    }

    if (showBankSlipOptions) {
      const instalmentAmounts = getInstallmentAmounts(
        totalAmount,
        Number(numberOfInstallments).valueOf()
      )
      const newInstallments = instalmentAmounts.map<InstallmentFormValues>(amount => ({
        amount,
        due_date: dayjs().toDate(),
      }))
      append(newInstallments)
    } else if (showCreditOptions) {
      const instalmentAmounts = getInstallmentAmounts(totalAmount, 1)
      const newInstallments = instalmentAmounts.map<InstallmentFormValues>(amount => ({
        amount,
        due_date: dayjs().toDate(),
      }))
      append(newInstallments)
    }
    trigger()

    setShouldCreateNewInstallmentField(false)
  }

  useEffect(() => {
    if (numberOfInstallments) {
      getWorkingDates(form.getValues())
    }
  }, [numberOfInstallments])

  // Este effect é necessário para chamar a função append do useFieldArray após o remove do mesmo.
  // Isso é uma limitaçao da lib react-hook-form, eles tem que ser usados em renders diferentes
  // https://react-hook-form.com/api/usefieldarray
  useEffect(createNewInstallmentsFromTotalAmount, [shouldCreateNewInstallmentField])

  // Existem um bug prevenindo que a validação do campo de data de vencimento funcione na primeira renderização
  // Adicionar o reset do formuário, foi uma forma de resolver o problema
  useEffect(() => {
    reset()
    setShowBankSlipOptions(false)
    setShowCreditOptions(false)
  }, [])
  const selectFormOfPayment = (value: any) => {
    if (value && value.indexOf('CREDIT_CARD') >= 0) {
      form.setValue('numberOfInstallments', '')
      form.setValue('installments', [])
      setShowBankSlipOptions(false)
      setShowCreditOptions(true)
      trigger()
    } else {
      form.setValue('numberOfInstallments', '')
      if (isEnrollmentBlock) {
        form.setValue('numberOfInstallments', 1)
        setShouldCreateNewInstallmentField(true)
      }
      form.setValue('installments', [])
      setShowBankSlipOptions(true)
      setShowCreditOptions(false)
      trigger()
    }
  }

  return (
    <InstallmentDialogForm title="Renegociar" onClose={onFinish} onSubmit={onSubmit} form={form}>
      <InstallmentDialogHeader title="Mensalidade" />
      <div className="flex flex-col gap-y-4">
        <ReceivableFinancialSummary receivable={receivable as Receivable} />
        <Controller
          rules={{ required: true, validate: v => v > 0 }}
          control={control}
          name="totalAmount"
          render={({ field: { onChange, value } }) => (
            <div className="flex flex-col gap-2">
              <Text variant="subtitle-medium">Novo valor total</Text>
              <NumberFormat
                data-testid="totalAmount-input"
                id="totalAmount-input"
                onValueChange={value => {
                  onChange(value.floatValue)
                }}
                onChange={removeInstallmentsAndTriggerCreation}
                customInput={TextField}
                variant="outlined"
                label=""
                placeholder="R$ 0,00"
                format={formatCentsToReal}
                value={value}
                size={3}
                min={0}
              />
            </div>
          )}
        />
        <Button
          fullWidth
          variant="outline"
          data-testid="interest-and-fines-button"
          onClick={onAddFineAndInstallmentButtonClick}
        >
          Adicionar multa e juros ao novo valor total
        </Button>
        <Separator color="neutral-2" />
        <Controller
          rules={{ required: true, validate: v => v?.length > 0 }}
          control={control}
          name="payment_method"
          render={({ field: { value, onChange } }) => (
            <Select
              size={3}
              name="payment_method-select"
              label="Forma de pagamento"
              variant="surface"
              fullWidth
              placeholder="Selecione uma forma de pagamento"
              aria-label="Forma de pagamento"
              value={value}
              onValueChange={(currentValue: string) => {
                selectFormOfPayment(currentValue)
                onChange(currentValue)
              }}
              options={formOfPaymentOptions.map(payment_method => ({
                label: payment_method.name,
                value: payment_method.value,
              }))}
            />
          )}
        />
        {showBankSlipOptions && (
          <>
            <Separator color="neutral-2" />
            <Controller
              rules={{ required: true }}
              control={control}
              name="numberOfInstallments"
              render={({ field: { value, onChange } }) => (
                <Select
                  size={3}
                  name="numberOfInstallments-select"
                  label="Renegociar em quantas parcelas?"
                  variant="surface"
                  placeholder="Selecione o número de parcelas"
                  aria-label="Renegociar em quantas parcelas?"
                  value={value.toString()}
                  onValueChange={currentValue => {
                    removeInstallmentsAndTriggerCreation()
                    onChange(currentValue)
                  }}
                  options={possibleInstallmentAmount.map(value => ({
                    label: value === 1 ? 'À vista' : `${value}x`,
                    value: value.toString(),
                  }))}
                />
              )}
            />
          </>
        )}
        {showCreditOptions && (
          <>
            <Callout text="Negociações em cartão de crédito aparecem como uma parcela única na nossa plataforma. Não importando o máximo de parcelas que você selecionar abaixo." />
            <Separator color="neutral-2" />
            <Controller
              rules={{ required: true }}
              control={control}
              name="numberOfInstallments"
              render={({ field: { value, onChange } }) => (
                <Select
                  size={3}
                  label="Quantidade máxima de parcelas"
                  aria-label="Parcelas"
                  variant="surface"
                  placeholder="Selecione o número de parcelas"
                  name="numberOfInstallments-select"
                  value={value.toString()}
                  onValueChange={currentValue => {
                    removeInstallmentsAndTriggerCreation()
                    onChange(currentValue)
                  }}
                  options={possibleInstallmentAmount.map(value => ({
                    label: value === 1 ? 'À vista' : `${value}x`,
                    value: value.toString(),
                  }))}
                />
              )}
            />
            <Separator color="neutral-2" />
            <div>
              {installmentFields.map((data, index) => (
                <Controller
                  key={data.id}
                  rules={{ required: true }}
                  name={`installments.${index}.due_date`}
                  control={control}
                  defaultValue={data.due_date}
                  render={({ field }) => (
                    <Datepicker.Root>
                      <Datepicker.Label label="Nova data de vencimento" />
                      <Datepicker.Trigger
                        size={3}
                        variant="surface"
                        className="w-full"
                        placeholder="Selecione uma data de vencimento"
                      >
                        {field.value ? formatDate(field.value, DATE_FORMAT) : null}
                      </Datepicker.Trigger>
                      <Datepicker.Calendar
                        value={field.value}
                        minDate={getStartOfToday().toDate()}
                        minDetail="month"
                        maxDetail="month"
                        onChange={date => field.onChange(date)}
                      />
                    </Datepicker.Root>
                  )}
                />
              ))}
            </div>
          </>
        )}
        {showBankSlipOptions && Boolean(installmentFields.length) && (
          <>
            <Separator color="neutral-2" />
            <Heading variant="heading-h4-medium" ref={installmentsRef}>
              Informe valor e vencimento
            </Heading>
          </>
        )}
        {showBankSlipOptions &&
          installmentFields.map((data, index, array) => (
            <Fragment key={data.id}>
              <Text variant="body-1-medium">
                Parcela {leftZeroPadding(index + 1, 2)}/{leftZeroPadding(array.length, 2)}
              </Text>
              <div className="flex flex-col gap-2">
                <Text variant="subtitle-medium">Valor</Text>
                <Controller
                  rules={{ required: true, validate: v => v > 0 }}
                  control={control}
                  name={`installments.${index}.amount` as const}
                  defaultValue={data.amount}
                  render={({ field: { onChange, value } }) => (
                    <NumberFormat
                      data-testid="installment-amount-input"
                      id={`installment-amount-input-${index}`}
                      onValueChange={value => {
                        onChange(value.floatValue)
                        onSingleInstallmentChange()
                      }}
                      customInput={TextField}
                      variant="outlined"
                      label=""
                      format={formatCentsToReal}
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      value={value}
                      size={3}
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  rules={{ required: true }}
                  name={`installments.${index}.due_date` as const}
                  control={control}
                  defaultValue={data.due_date}
                  render={({ field }) => (
                    <Datepicker.Root>
                      <Datepicker.Label label="Vencimento" />
                      <Datepicker.Trigger
                        size={3}
                        variant="surface"
                        className="w-full"
                        placeholder="Selecione uma data de vencimento"
                      >
                        {field.value ? formatDate(field.value, DATE_FORMAT) : null}
                      </Datepicker.Trigger>
                      <Datepicker.Calendar
                        value={field.value}
                        minDate={getStartOfToday().toDate()}
                        minDetail="month"
                        maxDetail="month"
                        onChange={date => field.onChange(date)}
                      />
                    </Datepicker.Root>
                  )}
                />
              </div>
              {index < array.length - 1 && <Separator color="neutral-2" />}
            </Fragment>
          ))}
      </div>
    </InstallmentDialogForm>
  )
}

export default RenegotiationContent
