import type { StudentsEnrollmentsFilterType } from '@monorepo/enrollment/hooks/useStudentsEnrollmentsFilter/types'
import { useGetStatusSummary } from '@monorepo/enrollment/hooks/queries/enrollment'
import { useEnrollmentCycle } from '@monorepo/enrollment/hooks/queries/useEnrollmentCycle'
import { usePagination } from '@/shared/hooks/usePagination'
import { isLegacyYear } from '@monorepo/enrollment/hooks/useInitialSupportedCycleYear'
import { useUnleashFlag, UnleashFlags } from '@/shared/hooks'
import { EnrollmentCycleRequest } from '@monorepo/enrollment/services/enrollmentService/types'

type UseEnrollmentDataProps = {
  academicPeriodId: uuid | undefined
  name: string
  schoolId: string
  selectedYear: number
  studentsEnrollmentsFilter: StudentsEnrollmentsFilterType
}

const setFilter = (
  academicPeriodId: uuid | undefined,
  isAcademicPeriodDrodpdownEnabled: boolean,
  isLegacy: boolean,
  selectedYear: number
): Pick<EnrollmentCycleRequest, 'academicPeriodIds' | 'referenceYear' | 'referenceYears'> => {
  if (isAcademicPeriodDrodpdownEnabled && academicPeriodId) {
    return {
      academicPeriodIds: [academicPeriodId],
    }
  }

  if (isLegacy) {
    return {
      referenceYear: selectedYear.toString(),
    }
  }

  return {
    referenceYears: [selectedYear, selectedYear - 1].map(String),
  }
}

export const useEnrollmentData = ({
  academicPeriodId,
  schoolId,
  selectedYear,
  name,
  studentsEnrollmentsFilter,
}: UseEnrollmentDataProps) => {
  const { pagination, updatePaginationValue } = usePagination(1, 25)
  const isLegacy = isLegacyYear()
  const isAcademicPeriodDrodpdownEnabled = useUnleashFlag(
    UnleashFlags.FAC_176_ENABLE_ACADEMIC_PERIOD_DROPDOWN
  )

  const filterByYearOrAcademicPeriod = setFilter(
    academicPeriodId,
    isAcademicPeriodDrodpdownEnabled,
    isLegacy,
    selectedYear
  )

  const { data: enrollmentsData, isLoading: enrollmentDataLoading, isError } = useEnrollmentCycle({
    ...filterByYearOrAcademicPeriod,
    schoolId,
    name,
    enrollmentStatus: studentsEnrollmentsFilter.enrollmentStatus,
    enrollmentSubStatus: studentsEnrollmentsFilter.enrollmentSubStatus,
    pagination: {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
    },
  })

  const {
    data: dataSummary,
    isLoading: isLoadingSummary,
    isError: isErrorSummary,
  } = useGetStatusSummary(
    {
      schoolId,
      referenceYear: String(selectedYear),
    },
    {
      enabled: !isLegacy,
    }
  )

  return {
    enrollmentsData,
    dataSummary,
    pagination,
    updatePaginationValue,
    isLoading: enrollmentDataLoading,
    isLoadingSummary: !isLegacy && isLoadingSummary,
    isError,
    isErrorSummary,
  }
}
