import { Fragment } from 'react'
import { formatCentsToReal } from 'src/shared/utils'
import {
  CheckoutCombinableReceivable,
  CheckoutReceivables,
} from 'src/escolas/contexts/checkoutContext'
import dayjs from 'dayjs'
import { prettifyFullName } from 'src/shared/utils/namePrettifiers'
import { useAgreement } from '@/escolas/contexts/agreementContext'
import { SimulationPaymentMethod } from '@/modules/guardians/models/agreement'
import { ItemSummary, SeparatorSummary } from './styles'
import { Separator } from '@gravity/separator'
import { Text } from '@gravity/text'

export type NegotiationFinancialSummaryProps = {
  combinedReceivables: CheckoutReceivables[]
  creditCardFee?: number
}
export type TypographyType = {
  isDiscount?: boolean
  value: string
  variation: any
}
const NewTypography = ({ variation, value, isDiscount }: TypographyType) => {
  return (
    <Text variant={variation} style={{ marginBottom: 10 }}>
      {isDiscount ? ' - ' : ''}
      {value}
    </Text>
  )
}
const NegotiationFinancialSummary = ({
  combinedReceivables,
  creditCardFee,
}: NegotiationFinancialSummaryProps) => {
  const { selectedSimulationPaymentMethod, agreementSimulations } = useAgreement()

  const findCreditCardFeeByProduct = (combinableReceivables: CheckoutCombinableReceivable[]) => {
    if (!creditCardFee || selectedSimulationPaymentMethod !== SimulationPaymentMethod.CREDIT_CARD)
      return 0

    const simulations: Record<string, number> = combinableReceivables?.reduce((acc, { id }) => {
      const simulation = agreementSimulations?.find(simulation =>
        simulation?.receivable_ids.includes(id)
      )
      return { ...acc, [simulation?.agreement_simulation_id]: simulation?.credit_card_fee ?? 0 }
    }, {})

    return Object.values(simulations).reduce((acc, value) => acc + value, 0)
  }

  const findValues = (receivables: CheckoutCombinableReceivable[]) => {
    return receivables.reduce(
      (acc, curr) => {
        const duePaymentDiscount = acc.duePaymentDiscount + curr.lost_due_payment_discount

        const fineAndInterest =
          acc.finesAndInterest + (curr.current_fine ?? 0) + (curr.current_interest ?? 0)

        const baseAmount = acc.baseAmount + curr.amount

        return {
          finesAndInterest: fineAndInterest,
          baseAmount: baseAmount,
          duePaymentDiscount: duePaymentDiscount,
        }
      },
      {
        finesAndInterest: 0,
        duePaymentDiscount: 0,
        baseAmount: 0,
      }
    )
  }
  return (
    <>
      {combinedReceivables.map(student => (
        <Fragment key={student.student_id}>
          <Text variant="body-2-regular" className="text-colors-text-main-3 mb-[10px] block">
            {prettifyFullName(student.student_name)}
          </Text>

          {student.products.map(product => {
            const values = findValues(product.receivables)
            const creditCardFeeByProduct = findCreditCardFeeByProduct(product.receivables)
            const subTotal = values?.baseAmount + creditCardFeeByProduct
            return (
              <Fragment key={product.product_id}>
                <NewTypography variation="subtitle-medium" value={product.product_name} />
                <SeparatorSummary>
                  <Separator color="neutral-2" />
                </SeparatorSummary>
                {product.receivables.map(receivable => {
                  const currentAmount =
                    receivable?.amount -
                    (receivable?.current_fine ?? 0) -
                    (receivable?.current_interest ?? 0) -
                    (receivable?.current_perpetual_discount ?? 0) -
                    (receivable?.current_early_payment_discount ?? 0) -
                    (receivable?.current_due_payment_discount ?? 0)
                  return (
                    <Fragment key={receivable.id}>
                      <ItemSummary>
                        <NewTypography
                          variation="subtitle-regular"
                          value={dayjs(receivable?.competenceDate).utc().format('MMMM')}
                        />
                        <NewTypography
                          variation="subtitle-regular"
                          value={formatCentsToReal(currentAmount)}
                        />
                      </ItemSummary>
                    </Fragment>
                  )
                })}
                <ItemSummary>
                  <NewTypography variation="subtitle-regular" value="Multa e Juros" />

                  <NewTypography
                    variation="subtitle-regular"
                    value={formatCentsToReal(values?.finesAndInterest)}
                  />
                </ItemSummary>
                <ItemSummary>
                  <NewTypography
                    variation="subtitle-regular"
                    value="Desconto de pontualidade devolvido"
                  />

                  <NewTypography
                    variation="subtitle-regular"
                    value={formatCentsToReal(values?.duePaymentDiscount)}
                    isDiscount
                  />
                </ItemSummary>

                {Boolean(creditCardFeeByProduct) && (
                  <ItemSummary>
                    <NewTypography variation="subtitle-regular" value="Taxa do cartão" />

                    <NewTypography
                      variation="subtitle-regular"
                      value={formatCentsToReal(creditCardFeeByProduct)}
                    />
                  </ItemSummary>
                )}
                <SeparatorSummary>
                  <Separator color="neutral-2" />
                </SeparatorSummary>
                <ItemSummary>
                  <NewTypography variation="subtitle-regular" value="Subtotal" />

                  <NewTypography variation="subtitle-regular" value={formatCentsToReal(subTotal)} />
                </ItemSummary>
              </Fragment>
            )
          })}
        </Fragment>
      ))}
    </>
  )
}

export default NegotiationFinancialSummary
