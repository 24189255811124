import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useApi } from '@/utils/hooks/useApi'
import { Address, GuardianStudentsType } from 'src/shared/interfaces'
import { useNavigation } from 'src/escolas/hooks'
import { UnleashFlags, useUnleashFlag } from 'src/shared/hooks/useUnleashFlag'
import { useGuardianStudents } from '@/escolas/hooks/useGuardianStudents'
import { defaultAddress } from '@/escolas/components/contract/create/constants'
import { Guardian } from '../../types'
import { EditGuardianFormType } from './types'
import { useToast } from '@gravity/toast'

const useHelperEditRegistration = (
  guardian: Guardian,
  studentsId: string[],
  handleClose: () => void
) => {
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const [isZipValid, setIsZipValid] = useState<boolean>(true)
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState<boolean>(false)
  const { toast } = useToast()

  const isNewEditRfNameEnabled = useUnleashFlag(UnleashFlags.GC_973_B2BCORE_122_EDIT_RF)

  const { api } = useApi()
  const { schoolId } = useNavigation()

  const {
    updateGuardianStudents: updateGuardianStudentsMutation,
    guardianStudents,
    isStudentsFetched,
  } = useGuardianStudents(guardian.id, studentsId)

  const form = useForm<EditGuardianFormType>({
    mode: 'onChange',
  })

  const { trigger, setValue } = form

  const setFormAddressValues = (address: Omit<Address, 'created_at' | 'updated_at' | 'id'>) => {
    setValue('address.zip', address?.zip)
    setValue('address.street', address?.street)
    setValue('address.number', address?.number)
    setValue('address.city', address?.city)
    setValue('address.state_code', address?.state_code)
    setValue('address.additional_information', address?.additional_information)

    trigger()
  }

  const setFormStudentValues = (studentsFromQuery: GuardianStudentsType['students']) => {
    const students = studentsFromQuery?.map(student => ({
      id: student.student_id,
      name: student.student_data.name,
    }))

    setValue('students', students)

    trigger()
  }

  const setFormValues = () => {
    setValue('id', guardianStudents?.guardian_id)
    setValue('email', guardianStudents?.guardian?.email)
    isNewEditRfNameEnabled && setValue('name', guardianStudents?.guardian?.name)
    setValue('phone_number', guardianStudents?.guardian?.phone_number)
    setValue('tax_id', guardianStudents?.guardian?.tax_id)

    setFormAddressValues(guardianStudents?.guardian?.address)
    setFormStudentValues(guardianStudents?.students)
    setIsFirstRender(false)
  }

  const onZipFieldFilled = async (zip: cep) => {
    await api.guardians
      .getAddressFromZip(zip)
      .then(validAddress => {
        setIsZipValid(true)
        setFormAddressValues({
          ...validAddress,
        })
      })
      .catch(() => {
        setIsZipValid(false)
        setFormAddressValues({ ...defaultAddress, zip })
      })
  }

  const handleMenuItemClick = () => {
    setFormValues()
  }

  const handleCloseFeedbackDialog = () => {
    setIsFeedbackDialogOpen(false)
  }

  const onSubmit = async (form: EditGuardianFormType) => {
    if (!schoolId) {
      return
    }

    const updatedGuardianStudents = await updateGuardianStudentsMutation({
      newGuardianStudents: { ...form },
      schoolID: schoolId,
    })

    if (!updatedGuardianStudents) return

    if (isNewEditRfNameEnabled) {
      toast({
        title: 'Cadastro editado com sucesso.',
        type: 'success',
      })
      return
    }

    toast({
      title: 'Cadastro editado com sucesso.',
      type: 'success',
    })
  }

  const submitHandler = async (form: EditGuardianFormType) => {
    try {
      await onSubmit(form)

      handleClose()
      window.location.reload()
    } catch (errors) {
      setIsFeedbackDialogOpen(true)
    }
  }

  return {
    form,
    isFirstRender,
    isZipValid,
    handleMenuItemClick,
    onZipFieldFilled,
    submitHandler,
    isFeedbackDialogOpen,
    handleCloseFeedbackDialog,
    isStudentsFetched,
  }
}

export default useHelperEditRegistration
