import dayjs from 'dayjs'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'

import { formatCentsToReal } from '@/shared/utils'

import type { HeaderProps } from './types'
import * as S from './styles'

const Header = ({ title, finalAmount, totalDiscount = 0, isLoading, installment }: HeaderProps) => {
  const installmentDueMonth = dayjs(installment?.due_date).format('MMM')

  const installmentOrder = installment?.order_reference?.replace('/', ' de ')

  const showDiscount = totalDiscount > 0

  const amountWithoutDiscount = (finalAmount ?? 0) + totalDiscount

  return (
    <S.Wrapper>
      {title && (
        <>
          <Heading variant="heading-h2-medium" style={{ fontWeight: 'bold' }}>
            {title}
          </Heading>
          <S.Separator />
        </>
      )}
      <S.FlexContainer>
        <Text variant="body-2-regular" className="text-colors-text-main-3">
          {`${installment?.student.name} - ${installment?.products?.[0]?.name}`}
        </Text>

        <Text variant="body-2-regular" className="text-colors-text-main-3">
          Valor recebido
        </Text>
      </S.FlexContainer>
      <S.FlexContainer>
        <Heading variant="heading-h3-regular">
          {`Parcela ${installmentOrder} - ${installmentDueMonth}`}
        </Heading>

        {isLoading ? (
          <S.StyledLoading />
        ) : (
          <S.FlexContainer style={{ flexDirection: 'column', alignItems: 'center' }}>
            <S.FlexContainer
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            >
              {showDiscount ? (
                <S.FullAmount>{formatCentsToReal(amountWithoutDiscount)}</S.FullAmount>
              ) : null}
              <Heading variant="heading-h3-regular">{formatCentsToReal(finalAmount ?? 0)}</Heading>
            </S.FlexContainer>
          </S.FlexContainer>
        )}
      </S.FlexContainer>
    </S.Wrapper>
  )
}

export default Header
