import { useHistory } from 'react-router-dom'

import {
  campaignStatusToTextMapper,
  CampaignStatus,
} from '@monorepo/enrollment/services/enrollmentService/types'
import { useEnrollmentMixpanelEvents } from '@monorepo/enrollment/hooks/useEnrollmentMixpanelEvents'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { useBulkInstallmentBtnState } from './hooks/useBulkInstallmentBtnState'
import type { CampaignDetailsHeaderProps } from './types'
import { View } from './view'

export const CampaignDetailsHeader = ({
  campaignDetails,
  formattedDate,
  setShowCampaignPaymentPlansDialog,
  setShowCloseCampaignDialog,
  isLoading,
  campaignId,
}: CampaignDetailsHeaderProps) => {
  const { sendButtonClickEvent } = useEnrollmentMixpanelEvents()
  const history = useHistory()

  const { schoolSlug } = useSelectedSchool()

  const {
    addInstallmentBtnTooltip,
    isAddInstallmentBtnDisabled,
    isAddInstallmentBtnLoading,
  } = useBulkInstallmentBtnState({ campaignId })

  const handleGoBackClick = history.goBack

  const statusText = campaignDetails.status
    ? campaignStatusToTextMapper[campaignDetails.status]
    : ''

  const statusColor = campaignDetails.status === CampaignStatus.OPEN ? 'accent' : 'neutral'

  const handleAddInstallmentBtnClick = () =>
    history.push({
      pathname: `/${schoolSlug}/campanha/${campaignDetails.id}/adicionar-mensalidades-em-lote`,
      search: '',
    })

  return (
    <View
      campaignDetails={campaignDetails}
      formattedDate={formattedDate}
      setShowCampaignPaymentPlansDialog={setShowCampaignPaymentPlansDialog}
      statusColor={statusColor}
      statusText={statusText}
      handleGoBackClick={handleGoBackClick}
      setShowCloseCampaignDialog={setShowCloseCampaignDialog}
      sendButtonClickEvent={sendButtonClickEvent}
      isLoading={isLoading}
      isAddInstallmentBtnDisabled={isAddInstallmentBtnDisabled}
      addInstallmentBtnTooltip={addInstallmentBtnTooltip}
      isAddInstallmentBtnLoading={isAddInstallmentBtnLoading}
      handleAddInstallmentBtnClick={handleAddInstallmentBtnClick}
    />
  )
}
