import { useCallback, useMemo, useState } from 'react'
import { useForm, UseFormSetValue } from 'react-hook-form'
import { getAllFilters } from '../../../GuardianDetails/utils/getAllFilters'
import { filtersGroup } from '../../utils'
import { FilterChip } from '../../components/FilterChip'
import { useGuardianContractsFilter } from '.'
import { zodResolver } from '@hookform/resolvers/zod'
import { FilterForm, filterSchema } from '../../schemas/filterSchemas'
import { TableFilters } from '../../components/GuardianDetailsContractsTable/types'
import { useTextFilterOptions } from './useTextFilterOptions'
import {
  GuardiansContractsFilterObjectKeys,
  GuardiansContractsFilterQueryParams,
  GuardiansContractsFilterType,
} from './types'
import { ContractsFiltersType } from '../../types'
import { ContractStatus, DebtStatus } from '@/shared/interfaces'
import { FiltersOptionsQuery } from '@/modules/guardians/services/types'
import { FiltersContractsFormType } from '../../components/GuardianDetailsContractsFilter/types'

type useGuardiansFiltersProps = {
  filterOptions?: FiltersOptionsQuery
}

export const useGuardiansContractsFilters = ({
  filterOptions: options,
}: useGuardiansFiltersProps) => {
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false)
  const { guardiansContractsFilter, updateFilter, clearFilter } = useGuardianContractsFilter()

  const filterOptions = useMemo(
    () => ({
      ...options,
      product_ids: options?.products ?? options?.product_ids,
    }),
    [options]
  )

  const fields: GuardiansContractsFilterQueryParams[] = [
    'debt_statuses',
    'contract_statuses',
    'product_ids',
    'negotiation_id',
    'reference_years',
  ]

  const fieldsFromURLOnly: Partial<GuardiansContractsFilterQueryParams>[] = ['negotiation_id']

  const defaultValues: FilterForm = {
    debt_statuses: filterOptions?.debt_statuses?.map(debtStatus => {
      return {
        ...debtStatus,
        selected: false,
      }
    }),
    contract_statuses: filterOptions?.contract_statuses?.map(status => {
      return {
        ...status,
        selected: false,
      }
    }),
    product_ids: filterOptions?.product_ids?.map(product => {
      return {
        ...product,
        selected: false,
      }
    }),
    reference_years: filterOptions?.reference_years?.map(reference_years => {
      return {
        ...reference_years,
        selected: false,
      }
    }),
    negotiation_id: filterOptions?.negotiation_id?.map(negotiation_id => {
      return {
        ...negotiation_id,
        selected: false,
      }
    }),
    debtStatuses: [],
    contractStatuses: undefined,
    productsArray: [],
    negotiationId: undefined,
    referenceYears: [],
  }

  const form = useForm<FilterForm>({
    mode: 'all',
    defaultValues,
    resolver: zodResolver(filterSchema),
  })

  const { control, setValue, reset } = form

  const updateFieldSelection = useCallback(
    (
      field: GuardiansContractsFilterQueryParams,
      filterField: GuardiansContractsFilterObjectKeys
    ) => {
      const selectedValues: string[] = guardiansContractsFilter[filterField]

      if (fieldsFromURLOnly.includes(field) && selectedValues) {
        setValue(
          field,
          selectedValues.map(value => ({ value: value, label: value, selected: true }))
        )
        return
      }

      setValue(
        field,
        filterOptions?.[field]?.map((status: { label: string; value: string }) => ({
          ...status,
          selected: selectedValues?.includes(status.value),
        }))
      )
    },
    [filterOptions, setValue, guardiansContractsFilter]
  )

  const allFiltersOptions = useMemo(() => getAllFilters(filterOptions as FiltersOptionsQuery), [
    filterOptions,
  ])

  const filtersGroupArray =
    filtersGroup(
      allFiltersOptions,
      (setValue as unknown) as UseFormSetValue<Omit<FiltersContractsFormType, 'status'>>
    ) ?? []

  filtersGroupArray?.forEach(filter => {
    if (filter.filterName === 'status') {
      filter.filterName = 'contract_statuses'
    }
  })

  const handleOpenFilterDialog = () => {
    setIsFilterDialogOpen(true)
  }

  const handleCloseFilterDialog = () => {
    setIsFilterDialogOpen(false)
  }

  const handleClearFilter = () => {
    reset()
    clearFilter()
    setIsFilterDialogOpen(false)
  }

  const filterGroup = filtersGroupArray.map((group, id) => {
    return {
      id,
      title: group.label,
      component: (
        <FilterChip
          control={control}
          filterType={group.filterName as Partial<GuardiansContractsFilterQueryParams>}
        />
      ),
    }
  })

  const filtersArray = useMemo(() => {
    const formValues = {
      debt_statuses: form.getValues().debt_statuses,
      contract_statuses: form.getValues().contract_statuses,
      product_ids: form.getValues().product_ids,
      negotiation_id: form.getValues().negotiation_id,
      reference_years: form.getValues().reference_years,
    }

    const mapFilters = (
      field: keyof typeof formValues,
      mapFn: (item: any) => { text: string; value: string }
    ) => formValues[field]?.filter(item => item.selected)?.map(mapFn) || []

    const debtStatuses = mapFilters('debt_statuses', debtStatus => ({
      text: useTextFilterOptions({ value: debtStatus.value, label: debtStatus.label }) ?? '',
      value: debtStatus.value ?? '',
    }))

    const contractStatuses = mapFilters('contract_statuses', status => ({
      text: useTextFilterOptions({ value: status.value, label: status.label }) ?? '',
      value: status.value ?? '',
    }))

    const productsArray = mapFilters('product_ids', product => ({
      text: product.label ?? '',
      value: product.value ?? '',
    }))

    const negotiationId = mapFilters('negotiation_id', option => ({
      ...option,
      text: option.label ? `Negociação #${option.label?.substring(0, 6).toUpperCase()}` : '',
    }))

    const referenceYearsFilters = mapFilters('reference_years', year => ({
      text: year.label ?? '',
      value: year.value ?? '',
    }))

    return [
      ...debtStatuses,
      ...contractStatuses,
      ...negotiationId,
      ...productsArray,
      ...referenceYearsFilters,
    ]
  }, [
    form.getValues().reference_years,
    form.getValues().debt_statuses,
    form.getValues().contract_statuses,
    form.getValues().product_ids,
    form.getValues().negotiation_id,
  ])

  const filterTable: Partial<ContractsFiltersType> = useMemo(() => {
    const debt_statuses =
      form
        .getValues()
        .debt_statuses?.filter(debtStatus => debtStatus.selected)
        .map(debtStatus => debtStatus?.label as DebtStatus) ?? []

    const status =
      (form.getValues().contract_statuses?.find(negotiation => negotiation.selected)
        ?.label as ContractStatus) ?? undefined

    const product_ids =
      form
        .getValues()
        .product_ids?.filter(product => product.selected)
        .map(product => product?.value) ?? []

    const negotiation_id =
      form.getValues().negotiation_id?.find(negotiation => negotiation.selected)?.label ?? undefined

    const reference_years =
      form
        .getValues()
        .reference_years?.filter(negotiation => negotiation.selected)
        .map(year => year?.label) ?? []

    return {
      debt_statuses,
      status,
      negotiation_id,
      product_ids,
      reference_years,
    }
  }, [
    form.getValues().reference_years,
    form.getValues().debt_statuses,
    form.getValues().contract_statuses,
    form.getValues().product_ids,
    form.getValues().negotiation_id,
  ])

  const handleApplyFilters = ({
    debtStatuses,
    contractStatuses,
    productsArray,
    negotiationId,
    referenceYears,
  }: GuardiansContractsFilterType) => {
    updateFilter({
      debtStatuses: debtStatuses,
      contractStatuses: contractStatuses,
      productsArray: productsArray,
      negotiationId: negotiationId,
      referenceYears: referenceYears,
    })
    setIsFilterDialogOpen(false)
  }

  const handleRemoveFilter = useCallback(
    (filter: TableFilters) => {
      const convertedFilter = filter.value
      const matchingOption = filtersArray.find(option => option.value.includes(convertedFilter))
      const updatedFilters = form.getValues()

      const getSelectedValues = (field: GuardiansContractsFilterQueryParams) => {
        return (
          updatedFilters[field]?.filter(status => status.selected).map(status => status.value) ?? []
        )
      }

      if (matchingOption) {
        fields.forEach(field => {
          updatedFilters[field]?.forEach(status => {
            if (status.value === convertedFilter) {
              status.selected = false
              setValue(field, updatedFilters[field])
            }
          })
        })
      }

      const filtersToUpdate = {
        debtStatuses: getSelectedValues('debt_statuses'),
        contractStatuses: getSelectedValues('contract_statuses'),
        productsArray: getSelectedValues('product_ids'),
        negotiationId: getSelectedValues('negotiation_id'),
        referenceYears: getSelectedValues('reference_years'),
      } as GuardiansContractsFilterType
      updateFilter(filtersToUpdate)
    },
    [
      form.getValues().reference_years,
      form.getValues().debt_statuses,
      form.getValues().contract_statuses,
      form.getValues().product_ids,
      form.getValues().negotiation_id,
      updateFilter,
      setValue,
      updateFilter,
    ]
  )

  return {
    isFilterDialogOpen,
    filtersGroup: filterGroup,
    form,
    filtersArray,
    filterTable,
    setValue,
    handleOpenFilterDialog,
    handleCloseFilterDialog,
    handleApplyFilters,
    handleClearFilter,
    handleRemoveFilter,
    updateFieldSelection,
  }
}
