import { useState } from 'react'
import dayjs from 'dayjs'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { Grid, GridItem } from '@gravity/grid'
import { GenericCashoutError } from '@/shared/components/CashoutErrors'
import { EnrollmentPayoutReportHeader } from './components/EnrollmentPayoutReportHeader'
import { PayoutReportContentMain } from '../../components/PayoutReportSubHeader'
import { EnrollmentSummaryDrawer } from './components/EnrollmentSummaryDrawer'
import { EnrollmentPayoutReportTableHeader } from './components/EnrollmentPayoutReportTableHeader'
import { EnrollmentReportTable } from './components/EnrollmentReportTable'
import { EnrollmentReportTableRowDetailDrawer } from './components/EnrollmentReportTableRowDetailDrawer'
import { useEnrollmentPayoutReportData } from './hooks/useEnrollmentPayoutReportData'
import { formatNumberMicroCentsToReal } from '@/shared/utils/numberFormatters'
import { buildEnrollmentPayoutReportFilterOptions } from './utils/buildEnrollmentPayoutReportFilterOptions'
import { PAYOUT_REPORT_STATUS } from '../../constants/PAYOUT_REPORT_STATUS'
import { REPORT_PAGE_NAMES } from '../../constants/REPORT_PAGE_NAMES'
import type { FinancialTransactionProps } from '@monorepo/report/models/report'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { Container, ScrollableContent } from '../TuitionPayoutReport/styles'

type PageDrawers = 'enrollment_summary' | 'student_detail'

/**
 * Enrollment payout report page component
 */
export const EnrollmentPayoutReport = () => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const [selectedInstallment, setSelectedInstallment] = useState<FinancialTransactionProps>()
  const [isPayoutSummaryDrawerOpen, setIsPayoutSummaryDrawerOpen] = useState(false)
  const [isStudentDetailsDrawerOpen, setIsStudentDetailsDrawerOpen] = useState(false)

  const {
    enrollmentPayoutReportList,
    enrollmentPayoutReportDetails,
    isFetchingEnrollmentPayoutReportDetails,
    hasErrorOnFetch,
    refetch,
  } = useEnrollmentPayoutReportData()

  const drawers: Record<PageDrawers, React.Dispatch<React.SetStateAction<boolean>>> = {
    enrollment_summary: setIsPayoutSummaryDrawerOpen,
    student_detail: setIsStudentDetailsDrawerOpen,
  }

  const closeDrawers = () => {
    Object.entries(drawers).forEach(([, setDrawerVisibility]) => setDrawerVisibility(false))
  }

  const handleOpenDrawer = (name: PageDrawers) => {
    closeDrawers()

    drawers[name](true)
  }

  const isPayoutOpen =
    enrollmentPayoutReportDetails?.data.payout.status === PAYOUT_REPORT_STATUS.OPEN

  const availableFilters = buildEnrollmentPayoutReportFilterOptions(
    enrollmentPayoutReportDetails?.data.filters_included
  )

  const isTableEmpty = (enrollmentPayoutReportDetails?.pagination?.total ?? 0) === 0

  const calculatedPayoutValue = (() => {
    const payoutValue =
      enrollmentPayoutReportDetails?.data.agregations.financial_details?.total ?? 0
    const negativePayoutValue =
      enrollmentPayoutReportDetails?.data.agregations.financial_details.events?.BALANCE_TRANSFER ??
      0

    return formatNumberMicroCentsToReal(payoutValue - negativePayoutValue)
  })()

  if (hasErrorOnFetch) {
    return <GenericCashoutError handleRetryButton={refetch} />
  }

  return (
    <Container>
      <ScrollableContent>
        <Grid className="gap-14">
          <GridItem xl={12} lg={12} md={12} sm={8} xs={4}>
            <EnrollmentPayoutReportHeader
              enrollmentPayoutReportList={enrollmentPayoutReportList}
              onChangeEnrollmentPayout={closeDrawers}
              handleOpenDrawerSummary={() => {
                handleOpenDrawer('enrollment_summary')
              }}
            />
          </GridItem>

          <PayoutReportContentMain
            payoutValue={calculatedPayoutValue}
            isPayoutOpen={isPayoutOpen}
            isLoading={isFetchingEnrollmentPayoutReportDetails}
          />

          <GridItem xl={12} lg={12} md={12} sm={8} xs={4}>
            <EnrollmentPayoutReportTableHeader
              isLoading={isFetchingEnrollmentPayoutReportDetails}
              isNewVersionPayout={enrollmentPayoutReportDetails?.data?.payout?.version === 2}
              isEmptyPayout={isTableEmpty}
              payoutId={enrollmentPayoutReportDetails?.data?.payout_id ?? ''}
              payoutReportStartDate={dayjs(
                enrollmentPayoutReportDetails?.data?.started_at ?? undefined
              ).toDate()}
              payoutReportEndDate={dayjs(
                enrollmentPayoutReportDetails?.data?.ended_at ?? undefined
              ).toDate()}
              tuitionPayoutReportStatus={enrollmentPayoutReportDetails?.data?.payout?.status}
            />
          </GridItem>

          <GridItem xl={12} lg={12} md={12} sm={8} xs={4} className="pb-5">
            <EnrollmentReportTable
              isNewVersionPayout
              isLoading={isFetchingEnrollmentPayoutReportDetails}
              transactions={enrollmentPayoutReportDetails?.data?.financial_transactions ?? []}
              availableFilters={availableFilters}
              totalItems={enrollmentPayoutReportDetails?.pagination?.total ?? 0}
              onRowClick={params => {
                const findInstallment = enrollmentPayoutReportDetails?.data?.financial_transactions?.find(
                  installment => installment?.referrals?.installment_id === params.id
                )

                if (findInstallment) {
                  setSelectedInstallment(findInstallment)
                  handleOpenDrawer('student_detail')
                }

                isInitialized &&
                  eventDispatcherClient.sendEvent({
                    scope: EventDispatcherEventScopes.ENROLLMENT_REPORT,
                    name: EventDispatcherEvents.ENROLLMENT_PAYOUT_REPORT_DETAILS,
                    action: 'click',
                    customProperties: {
                      $page_name: REPORT_PAGE_NAMES.ENROLLMENT_PAYOUT_REPORT,
                    },
                  })
              }}
            />
          </GridItem>
        </Grid>
      </ScrollableContent>

      {/* TODO: Move component to report module */}
      <EnrollmentSummaryDrawer
        financialDetails={enrollmentPayoutReportDetails?.data?.agregations.financial_details}
        payoutData={{
          payoutId: enrollmentPayoutReportDetails?.data.payout_id ?? '',
          payoutVersion: enrollmentPayoutReportDetails?.data.payout.version ?? 2,
          payoutBankTransfers: enrollmentPayoutReportDetails?.data.bank_transfers ?? [],
          enrollment_end_date: dayjs(
            enrollmentPayoutReportDetails?.data?.ended_at ?? undefined
          ).toDate(),
        }}
        isPayoutOpen={false}
        isOpen={isPayoutSummaryDrawerOpen}
        onClose={() => setIsPayoutSummaryDrawerOpen(false)}
      />

      <EnrollmentReportTableRowDetailDrawer
        guardianId={selectedInstallment?.referrals.guardian_id}
        contractReferenceYear={selectedInstallment?.referrals.contract_reference_year}
        contractId={selectedInstallment?.referrals.contract_id ?? ''}
        studentId={selectedInstallment?.referrals.student_id}
        studentName={selectedInstallment?.referrals.student_name ?? ''}
        productId={selectedInstallment?.referrals.product_id}
        productName={selectedInstallment?.referrals.product_name ?? ''}
        financialDetails={selectedInstallment?.financial_detail}
        receiptDate={dayjs(selectedInstallment?.referrals.last_receivable_paid_date).toDate()}
        isOpen={isStudentDetailsDrawerOpen}
        onClose={() => setIsStudentDetailsDrawerOpen(false)}
      />
    </Container>
  )
}
