import { UFs } from '@/shared/utils'
import { Accordion } from '@gravity/accordion'
import { CloseCircleOutline } from '@gravity/icons'
import { Select } from '@gravity/select'
import { TextField } from '@gravity/text-field'
import { EditStudentFormProps } from '@monorepo/onboarding/v2/hooks/useEditStudentForm'
import { useFormContext } from 'react-hook-form'

type GuardianAccordionProps = {
  index: number
  isSingleGuardian: boolean
}

export const GuardianAccordion = ({ index, isSingleGuardian }: GuardianAccordionProps) => {
  const { register, setValue, getValues, watch } = useFormContext<EditStudentFormProps>()

  return (
    <Accordion.Root
      key={`guardian-${index}`}
      type="single"
      collapsible
      defaultValue={isSingleGuardian ? `guardian-${index}` : undefined}
    >
      <Accordion.Item value={`guardian-${index}`}>
        <Accordion.Trigger
          descriptionList={[
            {
              text: 'Correções pendentes',
              // Accordion overrides styles and pass size="16px", which is incompatible with @gravity/icons
              iconStart: (
                <span>
                  <CloseCircleOutline size="xs" />
                </span>
              ),
            },
          ]}
        >
          {getValues(`guardians.${index}.name`)}
        </Accordion.Trigger>

        <Accordion.Content>
          <div className="flex flex-col gap-6">
            <div className="flex flex-row gap-4">
              <TextField
                fullWidth
                size={2}
                label="Nome completo"
                {...register(`guardians.${index}.name`)}
              />

              <TextField
                fullWidth
                size={2}
                label="CPF"
                {...register(`guardians.${index}.tax_id`)}
              />
            </div>

            <div className="flex flex-row gap-4">
              <TextField
                fullWidth
                size={2}
                label="E-mail"
                {...register(`guardians.${index}.email`)}
              />

              <TextField
                fullWidth
                size={2}
                label="Celular"
                {...register(`guardians.${index}.phone_number`)}
              />
            </div>

            <div className="flex flex-row gap-4">
              <TextField
                fullWidth
                size={2}
                label="CEP"
                {...register(`guardians.${index}.address_zip`)}
              />

              <TextField
                fullWidth
                size={2}
                label="Logradouro"
                {...register(`guardians.${index}.address_street`)}
              />
            </div>

            <div className="flex flex-row gap-4">
              <TextField
                fullWidth
                size={2}
                label="Número"
                {...register(`guardians.${index}.address_number`)}
              />

              <TextField
                fullWidth
                size={2}
                label="Complemento"
                {...register(`guardians.${index}.address_additional_information`)}
              />
            </div>

            <div className="flex flex-row gap-4">
              <TextField
                fullWidth
                size={2}
                label="Município"
                {...register(`guardians.${index}.address_city`)}
              />

              <Select
                fullWidth
                size={2}
                variant="surface"
                label="UF"
                value={watch(`guardians.${index}.address_state_code`)}
                options={UFs.map(UF => ({
                  label: UF,
                  value: UF,
                }))}
                onValueChange={value => {
                  setValue(`guardians.${index}.address_state_code`, value, { shouldDirty: true })
                }}
                {...register(`guardians.${index}.address_state_code`)}
              />
            </div>
          </div>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  )
}
