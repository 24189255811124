import styled from 'styled-components'

export const ResponsiveContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${props => props.theme.gravity.spacing[14]};
`

export const StepperContainer = styled.div`
  display: flex;
  flex-direction: column;
`
