import { InformationOutline, CalendarEvent } from '@gravity/icons'
import { Separator } from '@gravity/separator'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'

import { convertToUTCDate, formatMicroCentsToReal } from '@/shared/utils'

import { ISAAC_PAY_TRANSACTIONS_IN_ORDER } from '@monorepo/report/constants/transactionsInOrder'
import { GrayText, StyledTooltip } from '../../styles'

/**
 * Drawer for payout content. This content is for internal payout providers
 * @param props
 * @param props.bankTransfers Summary of bank transfers
 * @param props.values Values of transfers in each payout
 * @param props.total Total payout value
 * @param props.total_fee Total fee value
 */
export const IsaacPayPayoutDrawerContent = ({ bankTransfers, values, total, total_fee }) => {
  return (
    <div className="flex flex-col gap-6">
      {Object.keys(ISAAC_PAY_TRANSACTIONS_IN_ORDER).map(
        key =>
          !!values[key] && (
            <div className="flex justify-between items-center" key={key}>
              <GrayText variant="body-1-regular" className="flex items-center">
                {ISAAC_PAY_TRANSACTIONS_IN_ORDER[key].label}

                {ISAAC_PAY_TRANSACTIONS_IN_ORDER[key].description && (
                  <StyledTooltip text={ISAAC_PAY_TRANSACTIONS_IN_ORDER[key].description}>
                    <span>
                      <InformationOutline />
                    </span>
                  </StyledTooltip>
                )}
              </GrayText>

              <Text variant="body-1-medium">{formatMicroCentsToReal(values[key])}</Text>
            </div>
          )
      )}

      {Object.keys(ISAAC_PAY_TRANSACTIONS_IN_ORDER).some(key => !!values[key]) && (
        <Separator orientation="horizontal" color="neutral-2" />
      )}

      <div className="flex justify-between items-center">
        <GrayText variant="body-1-regular" className="flex items-center">
          Taxa isaac
          <StyledTooltip text="Valor referente à prestação de serviços isaac">
            <span className="flex items-center">
              <InformationOutline size="xs" />
            </span>
          </StyledTooltip>
        </GrayText>

        <Text variant="body-1-medium">{formatMicroCentsToReal(total_fee)}</Text>
      </div>

      <Separator orientation="horizontal" color="neutral-2" />

      <div className="flex justify-between items-center">
        <Text variant="body-1-medium" className="max-w-[15rem]">
          {total < 0 ? 'Valor pendente a ser descontado do repasse' : 'Valor total'}
        </Text>

        <Text variant="body-1-medium" className="text-gray">
          {formatMicroCentsToReal(total)}
        </Text>
      </div>

      {bankTransfers.length > 0 && (
        <>
          <Separator orientation="horizontal" color="neutral-2" />

          <div className="flex flex-col gap-6 p-6">
            <div className="flex items-center gap-2">
              <CalendarEvent size="sm" />
              <Heading variant="heading-h4-regular">Transferências programadas</Heading>
            </div>

            <div className="flex flex-col gap-4">
              {bankTransfers.map((item, index) => (
                <div key={index} className="flex justify-between items-center">
                  <Text variant="body-1-regular" className="text-gray">
                    {convertToUTCDate(item.date).format('DD/MM/YYYY')}
                  </Text>

                  <Text variant="body-1-medium">{formatMicroCentsToReal(item.amount)}</Text>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
