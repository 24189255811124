import { useState } from 'react'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { Tabs } from '@gravity/tabs'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'
import { Grid, GridItem } from '@gravity/grid'

import { useLayout } from '@/shared/hooks/useLayout'
import { UnleashFlags, useJWT, useUnleashFlag } from '@/shared/hooks'
import { useHasGroupManagerAccess } from '@/shared/hooks/useHasGroupManagerAccess'
import { EventPageName } from '@monorepo/management/models/EventPageName.enum'
import { EventIdentifierName } from '@monorepo/management/models/EventIdentifierName.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { CircularLoadingPlaceholder } from '@/shared/components/CircularLoadingPlaceholder'
import { useDashboardReportQuery } from '@/shared/hooks/queries/report'

import { DashboardEmbedded } from './components/DashboardEmbedded'
import {
  useSchoolGroupEnrollmentManagementQuery,
  useSchoolGroupManagementQuery,
} from './hooks/useSchoolGroupManagementQuery'
import { useSchoolManagementQuery } from './hooks/useSchoolManagementQuery'
import { useDashboardTabs } from './hooks/useDashboardTabs'
import { ManagementDashboardsTab } from './types'
import { MANAGEMENT_DASHBOARDS_TABS } from './constants'

import { Content, Container } from './styles'

export const ManagementDashboards = () => {
  useLayout({
    enableSideMenu: true,
    enableHeader: true,
    headerTitle: 'Painel de gestão',
  })
  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  const { hasPayoutReportAccess, isGroupManager } = useJWT()
  const { status, isLoading } = useHasGroupManagerAccess({ enabled: isGroupManager })
  const isUnitManagement = (!isGroupManager && hasPayoutReportAccess) || status === 'error'

  const dashboardTabs = useDashboardTabs(isUnitManagement)
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const {
    data: dataGroupManagement,
    status: statusGroupManagement,
    refetch: refetchGroupManagement,
  } = useSchoolGroupManagementQuery()

  const {
    data: dataSchoolManagement,
    status: statusSchoolManagement,
    refetch: refetchSchoolManagement,
  } = useSchoolManagementQuery()

  const enrollmentPanelByGroupQuery = useSchoolGroupEnrollmentManagementQuery()
  const enrollmentPanelByUnitQuery = useDashboardReportQuery('enrollment_management_panel_by_unit')

  const [activeTab, setActiveTab] = useState<MANAGEMENT_DASHBOARDS_TABS>(
    MANAGEMENT_DASHBOARDS_TABS.SCHOOL_UNIT
  )

  const sendTabEvent = (tabName: string) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.MANAGEMENT_DASHBOARD,
        name: EventDispatcherEvents.BUTTON_CLICK,
        pageName: EventPageName.MANAGEMENT_DASHBOARDS,
        identifierName: EventIdentifierName.CHANGE_TAB_MANAGEMENT_DASHBOARD,
        customProperties: {
          $tab_name: tabName,
        },
      })
  }

  const handleTabChange = (tab: ManagementDashboardsTab) => {
    setActiveTab(tab.value)
    sendTabEvent(tab.label)
  }

  if (isGroupManager && isLoading) {
    return <CircularLoadingPlaceholder />
  }

  if (dashboardTabs.length === 1) {
    return (
      <Container>
        <Grid>
          {renderNewHeader && (
            <GridItem className="pb-8">
              <Heading variant="heading-h2-medium">Painel de gestão</Heading>
            </GridItem>
          )}
          <GridItem xl={12} lg={12} md={12} sm={8} xs={4}>
            <Content>
              <DashboardEmbedded
                id="unit-management-dashboard"
                url={dataSchoolManagement?.url ?? ''}
                status={statusSchoolManagement}
                refetch={refetchSchoolManagement}
                style={{ padding: '2.5rem 4rem 0' }}
                isVisible
              />
            </Content>
          </GridItem>
        </Grid>
      </Container>
    )
  }

  return (
    <Container>
      <Grid>
        {renderNewHeader && (
          <GridItem className="pb-8">
            <Heading variant="heading-h2-medium">Painel de gestão</Heading>
          </GridItem>
        )}
        <GridItem xl={12} lg={12} md={12} sm={8} xs={4}>
          <Content>
            <Tabs.Root
              style={{ height: '100%' }}
              defaultValue={dashboardTabs[0].value}
              value={activeTab}
              onValueChange={value => {
                const tab = dashboardTabs.find(tab => tab.value === value)
                if (tab) {
                  handleTabChange(tab)
                }
              }}
            >
              <Tabs.List background="gray">
                {dashboardTabs.map(tab => (
                  <Tabs.Trigger key={tab.value} value={tab.value}>
                    <Text variant="button-2" className="text-inherit">
                      {tab.label}
                    </Text>
                  </Tabs.Trigger>
                ))}
              </Tabs.List>
              <Tabs.Content className="content" value={MANAGEMENT_DASHBOARDS_TABS.SCHOOL_UNIT}>
                <DashboardEmbedded
                  id="unit-management-dashboard"
                  url={dataSchoolManagement?.url ?? ''}
                  status={statusSchoolManagement}
                  refetch={refetchSchoolManagement}
                  isVisible={activeTab === MANAGEMENT_DASHBOARDS_TABS.SCHOOL_UNIT}
                />
              </Tabs.Content>
              <Tabs.Content className="content" value={MANAGEMENT_DASHBOARDS_TABS.UNIT_ENROLLMENT}>
                <DashboardEmbedded
                  id="enrollment-management-dashboard-by-unit"
                  url={enrollmentPanelByUnitQuery.data?.url ?? ''}
                  status={enrollmentPanelByUnitQuery.status}
                  refetch={enrollmentPanelByUnitQuery.refetch}
                  isVisible={activeTab === MANAGEMENT_DASHBOARDS_TABS.UNIT_ENROLLMENT}
                />
              </Tabs.Content>
              <Tabs.Content className="content" value={MANAGEMENT_DASHBOARDS_TABS.SCHOOL_GROUP}>
                <DashboardEmbedded
                  id="group-management-dashboard"
                  url={dataGroupManagement?.url ?? ''}
                  status={statusGroupManagement}
                  refetch={refetchGroupManagement}
                  isVisible={activeTab === MANAGEMENT_DASHBOARDS_TABS.SCHOOL_GROUP}
                />
              </Tabs.Content>
              <Tabs.Content
                className="content"
                value={MANAGEMENT_DASHBOARDS_TABS.SCHOOL_GROUP_ENROLLMENT}
              >
                <DashboardEmbedded
                  id="enrollment-management-dashboard-by-group"
                  url={enrollmentPanelByGroupQuery.data?.url ?? ''}
                  status={enrollmentPanelByGroupQuery.status}
                  refetch={enrollmentPanelByGroupQuery.refetch}
                  isVisible={activeTab === MANAGEMENT_DASHBOARDS_TABS.SCHOOL_GROUP_ENROLLMENT}
                />
              </Tabs.Content>
            </Tabs.Root>
          </Content>
        </GridItem>
      </Grid>
    </Container>
  )
}
