import { formatCentsToReal } from 'src/shared/utils'

export const ListOptionInstallments = (maxInstallmentEnrollment: number, totalAmount: cents) => {
  const listItens = [{ label: `1x de ${formatCentsToReal(totalAmount)} - sem juros`, value: '1' }]

  for (let i = 2; i <= maxInstallmentEnrollment; i++) {
    const text = `${i}x`
    listItens.push({
      label: `${text} de ${formatCentsToReal(totalAmount / i)} - sem juros`,
      value: i.toString(),
    })
  }
  return listItens
}
