import { Table } from '@gravity/table'
import { TableErrorBadge } from '../TableErrorBadge'
import { Charge } from '@monorepo/onboarding/services/types/onboardingCharge'
import dayjs from 'dayjs'
import { formatCentsToReal } from '@/shared/utils'
import { isFieldInvalid } from '@monorepo/onboarding/utils/isFieldInvalid'
import { TableAction } from '../TableAction'

type ChargeTableProps = {
  charges: Charge[]
  setSelectedCharge: (charge: Charge) => void
}

const formatDateAsMonthAndYear = (dateString: string) => {
  const date = dayjs(dateString, 'DD/MM/YYYY')

  return date.format('MMM. YYYY')
}

export const ChargeTable = ({ charges, setSelectedCharge }: ChargeTableProps) => {
  const tableData = {
    columns: [
      {
        id: 'accrual_date',
        renderCellError: <TableErrorBadge />,
        renderCell: (item: Charge) => (
          <Table.TextCell>{formatDateAsMonthAndYear(item.accrual_date)}</Table.TextCell>
        ),
      },
      {
        id: 'type',
        renderCellError: <TableErrorBadge />,
        renderCell: (item: Charge) => (
          <Table.TextCell style={{ textTransform: 'capitalize' }}>{item.type}</Table.TextCell>
        ),
      },
      {
        id: 'due_date',
        renderCellError: <TableErrorBadge />,
        renderCell: (item: Charge) => <Table.TextCell>{item.due_date}</Table.TextCell>,
      },
      {
        id: 'amount',
        renderCellError: <TableErrorBadge />,
        renderCell: (item: Charge) => (
          <Table.TextCell>{formatCentsToReal(item.amount)}</Table.TextCell>
        ),
      },
      {
        id: 'discount',
        renderCellError: <TableErrorBadge />,
        renderCell: ({ discounts }: Charge) => {
          const badgeColor = discounts.length > 0 ? 'accent' : 'neutral'
          const badgeContent =
            discounts.length > 0
              ? `${discounts.length} descontos aplicados`
              : 'Nenhum desconto aplicado'

          return (
            <Table.BadgeCell badgeColor={badgeColor} badgeVariant="soft">
              {badgeContent}
            </Table.BadgeCell>
          )
        },
      },
      {
        id: 'situation',
        renderCellError: <TableErrorBadge />,
        renderCell: (item: Charge) => (
          <Table.TextCell style={{ textTransform: 'capitalize' }}>{item.situation}</Table.TextCell>
        ),
      },
    ],
  }

  return (
    <>
      <Table.Root>
        <Table.Head>
          <Table.HeaderCell name="accrual_date" minWidth={80}>
            Competência
          </Table.HeaderCell>
          <Table.HeaderCell name="type" minWidth={80}>
            Parcela
          </Table.HeaderCell>
          <Table.HeaderCell name="due_date">Vencimento</Table.HeaderCell>
          <Table.HeaderCell name="amount">Valor da parcela</Table.HeaderCell>
          <Table.HeaderCell name="discount">Descontos</Table.HeaderCell>
          <Table.HeaderCell name="situation">Situação</Table.HeaderCell>
        </Table.Head>
        <Table.Body>
          {charges.map(charge => (
            <Table.Row key={charge.id}>
              {tableData.columns.map(column => {
                if (isFieldInvalid('charge', column.id, charge.validations)) {
                  return column.renderCellError
                }

                // Error in due_date for paid charges should be displayed in the situation column
                if (
                  column.id === 'situation' &&
                  isFieldInvalid('charge', 'due_date', charge.validations)
                ) {
                  return column.renderCellError
                }

                return column.renderCell(charge)
              })}

              <TableAction
                onClick={() => {
                  setSelectedCharge(charge)
                }}
              />
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </>
  )
}
