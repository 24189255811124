import { forwardRef, useReducer } from 'react'
import dayjs from 'dayjs'
import { IconButton } from '@gravity/icon-button'
import { Text } from '@gravity/text'
import { Pencil, EyeOffOutline, EyeOutline } from '@gravity/icons'

import { SectionContainer } from '../SectionContainer'
import { SectionErrorState } from '../SectionErrorState'
import { ItemsLoading } from './components/ItemsLoading'
import { ActionsLoading } from './components/ActionsLoading'

import { formatDate } from '@/shared/utils'

import { ActionsContainer, Container, Info } from './styles'

type ProfileSectionProps = {
  birthDate: string | null
  disableEdit?: boolean
  hasError?: boolean
  id: string
  isLoading?: boolean
  onRequestEditProfile: () => void
  onRetry: () => void
  onSendToggleObfuscateEvent: (status: boolean) => void
  schoolName: string
  taxId: string | null
}

export const ProfileSection = forwardRef<HTMLElement, ProfileSectionProps>(
  (
    {
      id,
      isLoading = true,
      hasError = false,
      disableEdit = true,
      schoolName,
      birthDate,
      taxId,
      onRequestEditProfile,
      onSendToggleObfuscateEvent,
      onRetry,
    },
    ref
  ) => {
    const [obfuscateInfo, toggleObfuscateInfo] = useReducer((state: boolean) => !state, true)

    const formattedBirthDate = (() => {
      if (birthDate === null) return 'Não informado'

      const diff = dayjs(birthDate).diff(new Date(), 'years') * -1
      const formattedDate = formatDate(birthDate, 'DD/MM/YYYY')

      return `${formattedDate} - ${diff} anos`
    })()

    const formattedTaxId = (() => {
      if (taxId === null) return 'Não informado'

      if (obfuscateInfo) {
        return `***.***.***-${taxId.slice(-2)}`
      }

      return taxId.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')
    })()

    const handleToggleObfuscateInfo = () => {
      onSendToggleObfuscateEvent(!obfuscateInfo)
      toggleObfuscateInfo()
    }

    return (
      <SectionContainer
        id={id}
        ref={ref}
        isLoading={isLoading}
        title="Dados cadastrais"
        action={
          !hasError && (
            <ActionsContainer data-testid="actions-container">
              {isLoading ? (
                <ActionsLoading />
              ) : (
                <>
                  <IconButton
                    variant="ghost"
                    onClick={handleToggleObfuscateInfo}
                    disabled={taxId === null}
                    data-testid="toggle-visibility"
                  >
                    {obfuscateInfo ? <EyeOutline /> : <EyeOffOutline />}
                  </IconButton>

                  <IconButton
                    variant="ghost"
                    onClick={onRequestEditProfile}
                    data-testid="edit-profile"
                    disabled={disableEdit}
                  >
                    <Pencil />
                  </IconButton>
                </>
              )}
            </ActionsContainer>
          )
        }
      >
        <Container>
          {hasError ? (
            <SectionErrorState onRetry={onRetry} />
          ) : isLoading ? (
            <ItemsLoading />
          ) : (
            <>
              <Info>
                <Text className="label" variant="body-2-regular">
                  Unidade
                </Text>
                <Text variant="body-1-medium" data-testid="school-name">
                  {schoolName}
                </Text>
              </Info>

              <Info>
                <Text className="label" variant="body-2-regular">
                  Nascimento
                </Text>
                <Text variant="body-1-medium" data-testid="birth-date">
                  {formattedBirthDate}
                </Text>
              </Info>

              <Info>
                <Text className="label" variant="body-2-regular">
                  CPF
                </Text>
                <Text variant="body-1-medium" data-testid="tax-id">
                  {formattedTaxId}
                </Text>
              </Info>
            </>
          )}
        </Container>
      </SectionContainer>
    )
  }
)
