import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useNavigation } from '@/escolas/hooks'

import { useGuardiansStateQueryParams } from '@/modules/guardians/hooks/useGuardiansStateQueryParams'
import { useGuardiansInstallmentsQuery } from '@/modules/guardians/GuardianDetailsInstallmentsTab/hooks/useGuardiansInstallmentsQuery'
import { useGuardiansInstallmentsQueryParams } from '@/modules/guardians/GuardianDetailsInstallmentsTab/hooks/useGuardiansInstallmentsQueryParams'

import {
  EditGuardianFormMode,
  EditGuardianFormSection,
  INCREMENT_INTERVAL_VALUE,
  INITIAL_INTERVAL_VALUE,
  MAX_ATTEMPTS,
  MAX_INTERVAL_VALUE,
} from '@/escolas/router/responsavel/[guardianId]/contratos/constants'

import { AlertBanner } from '@gravity/alert-banner'
import { InvoiceErrorDescriptionEnum } from '../../constants'
import { Invoice } from '../../types'
import { sortInvoiceErrorsByPriority } from './utils/sortInvoicesErrorsByPriority'

type FeedbacksCashierProps = {
  setShowGeneratingInvoicesLoading: Dispatch<SetStateAction<boolean>>
}

export const FeedbacksCashier = ({ setShowGeneratingInvoicesLoading }: FeedbacksCashierProps) => {
  const { schoolId } = useNavigation()
  const { guardianId } = useParams<{ guardianId: string }>()
  const [isTimerBanner, setIsTimerBanner] = useState(false)
  const [timer, setTimer] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { filters: installmentsFilters } = useGuardiansInstallmentsQueryParams()

  const {
    guardiansInstallments,
    refetchGuardiansInstallments,
    isFetchGuardiansInstallmentsLoading,
    isRefetchingGuardiansInstallments,
  } = useGuardiansInstallmentsQuery({
    guardianId,
    schoolId,
    filter: installmentsFilters,
  })

  const [invoiceThatHasError, setInvoiceThatHasError] = useState<Invoice | null>(null)

  const attempt = timer / INCREMENT_INTERVAL_VALUE

  const startTimer = () => {
    if (timer === MAX_INTERVAL_VALUE) {
      return setTimer(INITIAL_INTERVAL_VALUE)
    }
    setTimer(timer + INCREMENT_INTERVAL_VALUE)
  }

  const { openEditRegistrationDrawer } = useGuardiansStateQueryParams()

  const getInvoicesStatus = () => {
    startTimer()
    setShowGeneratingInvoicesLoading(true)
    setIsLoading(true)
    refetchGuardiansInstallments()
    setIsTimerBanner(true)
  }

  const invoiceErrorsMap = {
    [InvoiceErrorDescriptionEnum.CPF]: {
      description:
        'Não foi possível gerar os boletos “a vencer” entre em contato com o nosso time de suporte.',
      actionComponent: null,
      linkLabel: '',
    },
    [InvoiceErrorDescriptionEnum.CEP]: {
      description:
        'Encontramos um problema com o CEP informado, faça a correção para que os boletos possam ser gerados e enviados para o responsável.',
      actionComponent: () =>
        openEditRegistrationDrawer(
          EditGuardianFormMode.EDIT_MODE,
          EditGuardianFormSection.ADDRESS_SECTION
        ),
      linkLabel: 'Editar CEP',
    },
    [InvoiceErrorDescriptionEnum.DUE_DATE]: {
      description:
        'Não foi possível gerar os boletos “a vencer” entre em contato com o nosso time de suporte.',
      actionComponent: null,
      linkLabel: '',
    },
    [InvoiceErrorDescriptionEnum.DEFAULT]: {
      description:
        'Estamos processando os boletos e isso pode demorar mais tempo do que o esperado. Aguarde um momento e clique em "atualizar" para consultar a situação do processo novamente.',
      actionComponent: () => {
        if (attempt >= MAX_ATTEMPTS) return
        getInvoicesStatus()
      },
      linkLabel: 'Atualizar',
    },
  }

  const canShowCashierFeedback = !isFetchGuardiansInstallmentsLoading || isTimerBanner

  useEffect(() => {
    const installments = guardiansInstallments?.data

    if (installments?.length) {
      const invoicesWithErrors = installments
        .filter(i => i?.invoice?.err_description)
        .map(i => i.invoice)

      if (invoicesWithErrors.length > 0) {
        const sortedInvoices = sortInvoiceErrorsByPriority(invoicesWithErrors)
        const invoiceWithHighestPriority = sortedInvoices[0]

        setInvoiceThatHasError(invoiceWithHighestPriority)
      } else {
        setInvoiceThatHasError(null)
      }
    }
  }, [guardiansInstallments])

  useEffect(() => {
    if (isRefetchingGuardiansInstallments) {
      setShowGeneratingInvoicesLoading(isLoading)
      setIsLoading(false)
    } else {
      setShowGeneratingInvoicesLoading(false)
    }
  }, [isRefetchingGuardiansInstallments])

  const errorDescription =
    invoiceThatHasError?.err_description || InvoiceErrorDescriptionEnum.DEFAULT
  const { description, actionComponent, linkLabel } =
    invoiceErrorsMap[errorDescription] || invoiceErrorsMap[InvoiceErrorDescriptionEnum.DEFAULT]

  return (
    <>
      {canShowCashierFeedback && invoiceThatHasError ? (
        <div className="mt-4 py-3" key={`${invoiceThatHasError.err_description}`}>
          <AlertBanner
            color="warning"
            title="Boletos não foram gerados"
            description={description}
            onLinkClick={actionComponent}
            linkLabel={linkLabel}
          />
        </div>
      ) : null}
    </>
  )
}
