import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@gravity/button'
import { Heading } from '@gravity/heading'
import { ArrowLeft } from '@gravity/icons'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useJWT } from '@/shared/hooks/useJWT'
import { useGetIsaacPayIncomesReport } from '../../hooks/queries/isaacPayIncomesReport'
import { CircularLoadingPlaceholder } from '@/shared/components/CircularLoadingPlaceholder'

import { PageStatusWrapper } from '@/shared/components/PageStatusWrapper'
import * as Styled from './styles'

export const IsaacPayIncomeReport = () => {
  const { schoolSlug } = useSelectedSchool()
  const { push } = useHistory()
  const { hasPayoutReportAccess } = useJWT()
  const reportQuery = useGetIsaacPayIncomesReport()
  const [iframeStatus, setIframeStatus] = useState<typeof reportQuery.status | undefined>('loading')

  const goToReportsList = () => push(`/${schoolSlug}/relatorios`)

  useEffect(() => {
    if (!hasPayoutReportAccess) {
      goToReportsList()
    }
  }, [hasPayoutReportAccess])

  return (
    <PageStatusWrapper status={reportQuery.status}>
      <Styled.Header>
        <Button variant="ghost" iconStart={<ArrowLeft />} onClick={goToReportsList}>
          Voltar
        </Button>
        <Heading className="title">Visão de recebimentos</Heading>
      </Styled.Header>

      {iframeStatus === 'loading' ? <CircularLoadingPlaceholder /> : null}

      <Styled.Iframe
        id="isaac-pay-income-report"
        src={reportQuery.data?.url}
        style={{ display: iframeStatus === 'success' ? 'block' : 'none' }}
        onLoad={() => setIframeStatus('success')}
      />
    </PageStatusWrapper>
  )
}
