import styled from 'styled-components'
import { IconButton as DSIconButton } from '@gravity/icon-button'

export const CardContainer = styled.div`
  display: flex;
  width: 336px;
  min-width: 336px;
  align-items: center;
  padding: ${props => props.theme.gravity.spacing[4]};
  gap: ${props => props.theme.gravity.spacing[4]};
  border-radius: ${props => props.theme.gravity.borderRadius[2]};
  border: 1px solid ${props => props.theme.gravity.colors['colors-border-neutral-2']};
  background: ${props => props.theme.gravity.colors['colors-background-neutral-1']};
  :hover {
    background: ${props => props.theme.gravity.colors['colors-interaction-secondary-2']};
  }
  :first-of-type {
    margin-left: ${props => props.theme.gravity.spacing[16]};
  }
  :last-of-type {
    margin-right: ${props => props.theme.gravity.spacing[16]};
  }
`

export const CardAvatar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: ${props => props.theme.gravity.spacing[10]};
  height: ${props => props.theme.gravity.spacing[10]};
  border-radius: ${props => props.theme.gravity.borderRadius[2]};
  background: ${props => props.theme.gravity.colors['colors-interaction-secondary-2']};
`

interface IconProps {
  cursor?: 'pointer' | 'default'
}

export const IconButton = styled(DSIconButton)<IconProps>`
  cursor: ${({ cursor }) => cursor ?? 'pointer'};
  ${props => `visibility: ${props.hidden ? 'hidden' : 'visible'}`};
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: ${props => props.theme.gravity.spacing[1]};
  flex: 1 0 0;
`
