import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { useApiClient } from '@/shared/hooks/useApiClient'
import { schoolsEnrollmentCycleService } from '@monorepo/enrollment/services'
import { DisclaimersRequest, DisclaimersResponse } from '@monorepo/enrollment/services/types'
import { SOFTCACHE_OPTIONS_BASE } from '@/shared/constants'

const DisclaimersKey = 'enrollment-disclaimers'

export const useFetchDisclaimers = (
  params: DisclaimersRequest,
  options?: UseQueryOptions<DisclaimersResponse>
) => {
  const { apiClient } = useApiClient()
  const service = schoolsEnrollmentCycleService(apiClient.privateApi)

  const { schoolId, referenceYear } = params

  return useQuery<DisclaimersResponse>(
    [DisclaimersKey, schoolId, referenceYear],
    () => {
      return service.fetchDisclaimers(params)
    },
    {
      ...SOFTCACHE_OPTIONS_BASE,
      ...options,
    }
  )
}
