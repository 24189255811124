import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Badge } from '@gravity/badge'
import { Skeleton } from '@gravity/skeleton'
import { Text } from '@gravity/text'
import { GraduationCap, ExternalLink } from '@gravity/icons'

import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { ReenrollmentStatus } from '@/shared/interfaces'

import * as Styled from './styles'
import { StudentCardProps } from './types'

export const StudentCardSkeleton = () => {
  return (
    <Styled.CardContainer>
      <Styled.CardAvatar>
        <Styled.IconButton size={1} color="accent" variant="ghost" cursor="default">
          <GraduationCap size="xs" />
        </Styled.IconButton>
      </Styled.CardAvatar>
      <Styled.CardContent>
        <Skeleton radius={2} width={248} />
        <Skeleton radius={2} width={248} />
      </Styled.CardContent>
    </Styled.CardContainer>
  )
}

export const StudentCard = ({ student }: StudentCardProps) => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { schoolSlug } = useSelectedSchool()
  const history = useHistory()

  const [cardOnHover, setCardOnHover] = useState(false)

  const sendEvent = () => {
    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.GUARDIANS,
      name: EventDispatcherEvents.CARD_VIEW_STUDENT_PAGE,
      action: 'click',
    })
  }

  const redirectToStudentPage = () => {
    isInitialized && sendEvent()
    history.push(`/${schoolSlug}/alunos/${student.student_id}`)
  }

  const getStudentStatusDict = {
    [ReenrollmentStatus.NOT_REENROLLABLE]: { color: 'error' as const, text: 'Não rematriculável' },
    [ReenrollmentStatus.REENROLLMENT_AVAILABLE]: {
      color: 'accent' as const,
      text: 'Rematrícula disponível',
    },
    [ReenrollmentStatus.ENROLLMENT_IN_PROGRESS]: {
      color: 'warning' as const,
      text: 'Matrícula em andamento',
    },
    [ReenrollmentStatus.ENROLLMENT_AT_RISK]: {
      color: 'error' as const,
      text: 'Matrícula em risco',
    },
    [ReenrollmentStatus.ENROLLED]: { color: 'success' as const, text: 'Matriculado' },
    [ReenrollmentStatus.GATE_ERROR]: { color: 'warning' as const, text: 'Indisponível no momento' },
    [ReenrollmentStatus.UNAVAILABLE_AT_MOMENT]: {
      color: 'warning' as const,
      text: 'Indisponível no momento',
    },
  }

  return (
    <Styled.CardContainer
      onClick={redirectToStudentPage}
      onMouseEnter={() => setCardOnHover(true)}
      onMouseLeave={() => setCardOnHover(false)}
    >
      <Styled.CardAvatar>
        <Styled.IconButton size={1} color="accent" variant="ghost" className="cursor-default">
          <GraduationCap size="xs" />
        </Styled.IconButton>
      </Styled.CardAvatar>
      <Styled.CardContent>
        <Text
          variant="subtitle-medium"
          className="whitespace-nowrap text-ellipsis overflow-hidden max-w-[200px]"
        >
          {student?.student_name}
        </Text>
        {!!student?.student_status && (
          <Badge color={getStudentStatusDict[student?.student_status]?.color} variant="soft">
            {getStudentStatusDict[student?.student_status]?.text}
          </Badge>
        )}
      </Styled.CardContent>
      <Styled.IconButton
        size={1}
        color="accent"
        variant="ghost"
        className="cursor-default"
        hidden={!cardOnHover}
      >
        <ExternalLink />
      </Styled.IconButton>
    </Styled.CardContainer>
  )
}
