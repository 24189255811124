import type { AxiosInstance } from 'axios'
import {
  SendCommunicationRequest,
  SendCommunicationResponse,
  SearchRecipientsQueryVariables,
  SearchRecipientsQueryResponse,
} from './types'

export const messagesService = (fetcher: AxiosInstance) => {
  return {
    sendCommunication: async (
      params: SendCommunicationRequest
    ): Promise<SendCommunicationResponse> => {
      return await fetcher.post('trpc/sendSingleCommunicationMessage', params)
    },
    searchRecipients: async (
      params: SearchRecipientsQueryVariables
    ): Promise<{
      result: SearchRecipientsQueryResponse
    }> => {
      const response = await fetcher.get(`trpc/searchRecipients?input={"text":"${params.search}"}`)
      return response.data
    },
  }
}
