import { AxiosInstance } from 'axios'
import {
  IrregularityBigNumbersRequest,
  IrregularityBigNumbersResponse,
  GetEnrollmentRequestResponse,
} from './types'

export const irregularitiesService = (fetcher: AxiosInstance) => {
  return {
    /**
     *
     * Function responsible for getting irregularity page's big numbers
     *
     * @param IrregularityBigNumbersRequest
     *
     * @returns IrregularityBigNumbers
     */
    fetchIrregularityBigNumbers: async (
      request: IrregularityBigNumbersRequest
    ): Promise<IrregularityBigNumbersResponse> => {
      return (
        await fetcher.get<IrregularityBigNumbersResponse>(`/enrollment-request/status-summary`, {
          params: {
            school_id: request.schoolId,
            reference_year: request.referenceYear,
          },
        })
      ).data
    },
  }
}

export const enrollmentRequestService = (fetcher: AxiosInstance) => {
  return {
    /**
     * Function responsible for get all enrollment requests
     *
     * @param schoolId School ID
     * @param guardianTaxID Guardian Tax ID
     *
     * @returns GetEnrollmentRequestResponse
     */
    getEnrollmentRequests: async (
      guardianTaxId: string,
      schoolId: string,
      status: string[] = []
    ): Promise<GetEnrollmentRequestResponse> => {
      const { data } = await fetcher.get<GetEnrollmentRequestResponse>(`enrollment-request`, {
        params: { guardian_tax_id: guardianTaxId, school_id: schoolId, status: status },
      })
      return data
    },
  }
}
