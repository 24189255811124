import { Button } from '@gravity/button'
import { PencilOutline } from '@gravity/icons'

export const CreateMessagePage = () => {
  /* Aqui deverá ser feita a configuração da rota para a tela de nova mensagem */
  const handleNovoComunicado = () => {
    console.log('handleNovoComunicado')
  }

  return (
    <div className="flex justify-between m-12 items-center mt-18">
      <span className="font-bold text-3xl">Comunicados</span>
      <Button variant="solid" onClick={handleNovoComunicado}>
        <PencilOutline className="w-[16px]" />
        <span className="ml-2 font-medium text-base">Novo comunicado</span>
      </Button>
    </div>
  )
}
