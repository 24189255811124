import { useEffect, useState } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import { Sidemenu, useSidemenu, SelectorValue, SelectorOption } from '@gravity/sidemenu'
import {
  GraduationCap,
  GraduationCapOutline,
  Home,
  HomeOutline,
  MoneyDollarCircle,
  MoneyDollarCircleOutline,
  BarChart,
  BarChartOutline,
  Chat3,
  Chat3Outline,
} from '@gravity/icons'
import { debounce } from 'throttle-debounce'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import { useJWT } from '@/shared/hooks/useJWT'
import { useSchools } from '@/modules/app/hooks/queries/schools'

import { getNavigationURL } from './utils/getNavigationURL'
import { OperationCategoryItems } from './categories-itens/operation'
import { FinancialCategoryItems } from './categories-itens/financial'
import { AnalyticsAndReportsCategoryItems } from './categories-itens/analytics-and-reports'

import { SIDEMENU_STATE_STORAGE_KEY } from '../../constants'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventPageName } from '@/modules/app/models/EventPageName.enum'
import { EventIdentifierName } from '@/modules/app/models/EventIdentifierName.enum'

export const NewSidemenu = () => {
  const { push } = useHistory()
  const { hasPayoutReportAccess } = useJWT()
  const { school } = useSelectedSchool()
  const { state } = useSidemenu()

  const [schoolFilterInput, setSchoolFilterInput] = useState('')

  const { data, isFetching } = useSchools(
    {
      nameFilter: schoolFilterInput,
      pagination: { per_page: 15 },
    },
    { keepPreviousData: true }
  )
  const schools = data?.data ?? []
  const options: SelectorOption[] =
    schools.map(item => ({ label: item.name, value: item.slug })) ?? []

  const currentOption: SelectorValue | undefined = school
    ? { label: school.name, value: school.slug }
    : undefined

  const enableMessages = useUnleashFlag(UnleashFlags.CPU_468_ENABLE_MESSAGES_PAGE)

  useEffect(() => {
    try {
      localStorage.setItem(SIDEMENU_STATE_STORAGE_KEY, state)
    } catch (error) {
      console.error('Failed to save sidemenu state to localStorage:', error)
    }
  }, [state])

  const handleSearch = debounce(300, (term: string) => {
    setSchoolFilterInput(term)
  })

  const handleChangeSchool = (option: SelectorOption) => {
    push({
      pathname: school?.slug
        ? location.pathname.replace(school.slug, String(option.value))
        : `/${String(option.value)}`,
    })
  }

  const homePath = `/${school?.slug}/`
  const messagesPath = getNavigationURL({ schoolSlug: school?.slug, path: '/comunicados' })

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const handleClickInitialPage = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.SIDE_MENU,
        name: EventDispatcherEvents.MENU_CLICKED,
        pageName: EventPageName.APP,
        identifierName: EventIdentifierName.NAVIGATE_TO_INITIAL_PAGE,
        customProperties: { $button_name: 'Menu de crédito' },
      })
  }

  return (
    <Sidemenu.Root className="z-[100]" id="userguiding-sidemenu">
      <Sidemenu.Header />

      <Sidemenu.Selector
        options={options}
        isLoadingOptions={isFetching}
        disabled={false}
        emptyPlaceholder="Nenhuma unidade encontrada"
        searchInputPlaceholder="Procure por uma unidade"
        value={currentOption}
        onSearch={handleSearch}
        onChangeValue={handleChangeSchool}
      />

      <Sidemenu.Content>
        <Sidemenu.Category
          text="Página inicial"
          as={Link}
          selectedIcon={<Home />}
          icon={<HomeOutline />}
          active={Boolean(useRouteMatch({ path: homePath, exact: true }))}
          to={`/${school?.slug}`}
          onClick={handleClickInitialPage}
        />
        {enableMessages && (
          <Sidemenu.Category
            as={Link}
            text="Comunicação"
            selectedIcon={<Chat3 />}
            icon={<Chat3Outline />}
            active={Boolean(useRouteMatch({ path: messagesPath }))}
            to={messagesPath}
          />
        )}
        <Sidemenu.Category
          text="Operação"
          icon={<GraduationCapOutline />}
          selectedIcon={<GraduationCap />}
          active={Boolean(useRouteMatch({ path: `/${school?.slug}/matriculas` }))}
        >
          <OperationCategoryItems />
        </Sidemenu.Category>

        {hasPayoutReportAccess && (
          <Sidemenu.Category
            text="Financeiro"
            icon={<MoneyDollarCircleOutline />}
            selectedIcon={<MoneyDollarCircle />}
            active={Boolean(useRouteMatch({ path: `/${school?.slug}/repasses` }))}
          >
            <FinancialCategoryItems />
          </Sidemenu.Category>
        )}

        <Sidemenu.Category
          text="Análises e relatórios"
          icon={<BarChartOutline />}
          selectedIcon={<BarChart />}
          active={Boolean(useRouteMatch({ path: `/${school?.slug}/painel-de-gestao` }))}
        >
          <AnalyticsAndReportsCategoryItems />
        </Sidemenu.Category>
      </Sidemenu.Content>
    </Sidemenu.Root>
  )
}
