import { DebtStatusProps, debtStatusVariant } from './types'
import { Badge } from '@gravity/badge'

export const DebtStatus = ({ status }: DebtStatusProps) => {
  return (
    <Badge color={debtStatusVariant[status].color} variant="soft">
      {debtStatusVariant[status].text}
    </Badge>
  )
}
