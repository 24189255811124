import { Skeleton } from '@gravity/skeleton'
import { CardStyled } from '../../styles'
import { RowContainer, TitleRowContainer } from './styles'

/**
 * Loading state for dashboard card component
 */
export const LoadingState = () => {
  return (
    <CardStyled data-testid="loading-state">
      <TitleRowContainer>
        <Skeleton radius={6} width={75} height={32} />
        <Skeleton radius={6} height={40} fullWidth />
      </TitleRowContainer>
      <RowContainer>
        <Skeleton radius={6} width={164} height={21} />
        <Skeleton radius={6} height={38} fullWidth />
        <Skeleton radius={6} width={164} height={21} />
      </RowContainer>

      <Skeleton radius={6} height={40} fullWidth />
    </CardStyled>
  )
}
