import styled from 'styled-components'
import { GridItem } from '@gravity/grid'
import { Text } from '@gravity/text'

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: ${props => props.theme.gravity.spacing[14]};
`

export const WrapperContainer = styled.div``

export const StyledGridItem = styled(GridItem)`
  gap: ${props => props.theme.gravity.spacing[10]};
  display: flex;
  flex-direction: column;
  padding-top: ${props => props.theme.gravity.spacing[14]};
`

export const ErrorImage = styled.img`
  display: block;
  margin: 0 auto;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[6]};
  align-items: center;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[2]};
  text-align: center;
`

export const StyledText = styled(Text)`
  white-space: break-spaces;
`
