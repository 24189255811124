import { EmptyState } from './components/EmptyState'
import { TuitionReportDashboardCard } from './components/TuitionReportDashboardCard'
import { EnrollmentReportDashboardCard } from './components/EnrollmentReportDashboardCard'
import { IsaacPayReportDashboardCard } from './components/IsaacPayReportDashboardCard'

import { useLayout } from '@/shared/hooks/useLayout'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { usePayoutReportDashboardData } from './hooks/usePayoutReportDashboardData'

import { Content } from './styles'
import { isIsaacPaySchool } from '@/shared/utils/isIsaacPaySchool'
import { PageHeader } from '@monorepo/report/pages/PayoutReportDashboard/components/PageHeader'
import { Grid, GridItem } from '@gravity/grid'

/**
 * Payout report dashboard page
 */
export const PayoutReportDashboard = () => {
  useLayout({ enableSideMenu: true, enableHeader: true, headerTitle: 'Repasses' })
  const {
    tuitionPayoutReportList,
    currentTuitionPayoutReport,
    tuitionLoading,
    tuitionPayoutDetailsLoading,
    tuitionError,
    refetchTuition,
    updateCurrentTuitionPayoutReport,
    enrollmentPayoutReportList,
    currentEnrollmentPayoutReport,
    enrollmentLoading,
    enrollmentError,
    refetchEnrollment,
    updateCurrentEnrollmentPayoutReport,
    isaacPayPayoutReportList,
    currentIsaacPayPayoutReport,
    isaacPayLoading,
    isaacPayError,
    refetchIsaacPay,
    updateCurrentIsaacPayPayoutReport,
  } = usePayoutReportDashboardData()

  const { school } = useSelectedSchool()

  const handleChangeTuitionPayoutReport = (id: string) => {
    const payoutReport = tuitionPayoutReportList.find(item => item.id === id)

    if (!payoutReport) return

    updateCurrentTuitionPayoutReport(payoutReport)
  }

  const handleChangeEnrollmentPayoutReport = (id: string) => {
    const payoutReport = enrollmentPayoutReportList.find(item => item.id === id)

    if (!payoutReport) return

    updateCurrentEnrollmentPayoutReport(payoutReport)
  }

  const handleChangeIsaacPayPayoutReport = (id: string) => {
    const payoutReport = isaacPayPayoutReportList.find(item => item.id === id)

    if (!payoutReport) return

    updateCurrentIsaacPayPayoutReport(payoutReport)
  }

  const isLoading = tuitionLoading || enrollmentLoading || isaacPayLoading
  const hasError = tuitionError || enrollmentError || isaacPayError

  const hasTuitionPayoutReport = tuitionPayoutReportList.length > 0
  const hasEnrollmentPayoutReport = enrollmentPayoutReportList.length > 0
  const hasIsaacPayPayoutReport = isaacPayPayoutReportList.length > 0
  const hasPayoutReports =
    hasTuitionPayoutReport || hasEnrollmentPayoutReport || hasIsaacPayPayoutReport

  const isSchoolIsaacPay = isIsaacPaySchool(school)

  return (
    <div className="flex justify-center">
      <Grid>
        <GridItem xl={12} lg={12} md={12} sm={8}>
          <PageHeader />
        </GridItem>
        {!hasPayoutReports && !isLoading && !hasError ? (
          <GridItem xl={12} lg={12} md={12} sm={8}>
            <EmptyState />
          </GridItem>
        ) : (
          <GridItem xl={12} lg={12} md={12} sm={8}>
            <Content>
              {(hasTuitionPayoutReport || tuitionLoading || tuitionError) && !isSchoolIsaacPay && (
                <TuitionReportDashboardCard
                  currentPayout={currentTuitionPayoutReport}
                  tuitionPayouts={tuitionPayoutReportList}
                  isLoading={tuitionLoading}
                  isLoadingPayoutAmount={tuitionPayoutDetailsLoading}
                  hasError={tuitionError}
                  onChange={handleChangeTuitionPayoutReport}
                  onRetry={refetchTuition}
                />
              )}

              {(hasIsaacPayPayoutReport || isaacPayLoading || isaacPayError) &&
                isSchoolIsaacPay && (
                  <IsaacPayReportDashboardCard
                    currentPayout={currentIsaacPayPayoutReport}
                    isaacPayPayouts={isaacPayPayoutReportList}
                    isLoading={isaacPayLoading}
                    hasError={isaacPayError}
                    onChange={handleChangeIsaacPayPayoutReport}
                    onRetry={refetchIsaacPay}
                  />
                )}

              {(hasEnrollmentPayoutReport || enrollmentLoading || enrollmentError) && (
                <EnrollmentReportDashboardCard
                  currentPayout={currentEnrollmentPayoutReport}
                  enrollmentPayouts={enrollmentPayoutReportList}
                  isLoading={enrollmentLoading}
                  hasError={enrollmentError}
                  onChange={handleChangeEnrollmentPayoutReport}
                  onRetry={refetchEnrollment}
                />
              )}
            </Content>
          </GridItem>
        )}
      </Grid>
    </div>
  )
}
