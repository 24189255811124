import styled from 'styled-components'

import { MAX_STEP_WIDTH } from '../../constants'
import { Card } from '@gravity/card'

export const Container = styled.div`
  max-width: ${MAX_STEP_WIDTH}px;
  padding-bottom: 150px;
  margin: 0 auto;
`

export const ListWrapper = styled(Card)<{ marginTop?: boolean }>`
  ${({ marginTop }) => (marginTop ? `margin-top: ${props => props.theme.gravity.spacing[4]};` : '')}
  padding: 0px;
`

export const Title = styled.div`
  margin-top: 48px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`
