import { Card } from '@gravity/card'
import { RadioGroup } from '@gravity/radio'
import styled from 'styled-components'

export const CardStyled = styled(Card)`
  padding: 0px;
`

export const RadioGroupStyled = styled(RadioGroup)`
  gap: 0;
`
export const Container = styled.div`
  display: flex;
  margin-bottom: 136px;
  flex-direction: column;
  margin-top: 28px;
`
export const Title = styled.div`
  margin-top: 28px;
  margin-bottom: 12px;
`
export const FullWidthDiv = styled.div`
  width: 100%;
`
