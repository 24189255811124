import { productsList, studentList } from '@/shared/mocks'
import { FiltersOptionsQuery, UseGuardianFiltersOptionsQueryResponse } from '../../services/types'
import {
  INSTALLMENT_STATUSES,
  INSTALLMENT_TYPES,
} from '@/modules/guardians/GuardianDetails/constants'
import {
  CONTRACT_STATUS_OPTIONS,
  DEBT_STATUS_OPTIONS,
} from '../../GuardianDetailsContractsTab/components/GuardianDetailsContractsFilter/constants'

export const guardianFiltersOptionsMock: FiltersOptionsQuery = {
  debt_statuses: DEBT_STATUS_OPTIONS,
  contract_statuses: CONTRACT_STATUS_OPTIONS,
  reference_years: [
    {
      label: '2021',
      value: '2021',
    },
    {
      label: '2022',
      value: '2022',
    },
  ],
  products: [
    {
      label: productsList[0].name,
      value: productsList[0].id,
    },
    {
      label: productsList[1].name,
      value: productsList[1].id,
    },
  ],
  students: [
    {
      label: studentList[0].name,
      value: studentList[0].id,
    },
    {
      label: studentList[1].name,
      value: studentList[1].id,
    },
  ],
  installment_statuses: INSTALLMENT_STATUSES,
  installment_types: INSTALLMENT_TYPES,
}

export const getGuardianFiltersOptionsMock = async (): Promise<UseGuardianFiltersOptionsQueryResponse> => {
  return {
    data: guardianFiltersOptionsMock,
  }
}
