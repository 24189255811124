import React, { useMemo } from 'react'
import { Button, ButtonProps } from '@gravity/button'
import * as Styled from './styles'

export const EditActionButton = ({
  color,
  text,
  disabled,
  tooltip,
  icon,
  onClick,
  variant = 'ghost',
}: {
  color?: ButtonProps['color']
  disabled: boolean
  icon: React.ReactElement
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  text: string
  tooltip: string
  variant?: ButtonProps['variant']
}) => {
  const button = useMemo(() => {
    return (
      <span>
        <Button
          onClick={onClick}
          iconStart={icon}
          variant={variant}
          disabled={disabled}
          color={color}
        >
          {text}
        </Button>
      </span>
    )
  }, [text, disabled, onClick])

  if (tooltip !== '') {
    return (
      <Styled.Tooltip text={tooltip} position="bottom">
        {button}
      </Styled.Tooltip>
    )
  }
  return button
}
