import dayjs from 'dayjs'

import { useGetSchoolEnrollmentCycles } from '@/shared/hooks/queries/useGetSchoolEnrollmentCycles'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import type { SchoolEnrollmentCycle } from '@/shared/services/school-enrollment-cycles/types'

import { LEGACY_CYCLE_YEARS } from '@monorepo/enrollment/constants/enrollment'

export const useSchoolEnrollmentCycles = () => {
  const { school } = useSelectedSchool()
  const { data: response } = useGetSchoolEnrollmentCycles(school?.id as string, {
    enabled: !!school?.id,
  })
  const schoolEnrollmentCycles = response?.data

  const legacyYears = LEGACY_CYCLE_YEARS

  const isEnrollmentCycleActive = (enrollmentCycle: SchoolEnrollmentCycle) => {
    if (!enrollmentCycle.enabled) return false

    if (enrollmentCycle.expires_at) {
      return dayjs().isBefore(dayjs(enrollmentCycle.expires_at))
    }

    return enrollmentCycle.reference_year >= dayjs().year()
  }

  const activeEnrollmentCycleYear = schoolEnrollmentCycles?.find(isEnrollmentCycleActive)
    ?.reference_year

  const hasAnyEnabledCycle: boolean = schoolEnrollmentCycles?.some(isEnrollmentCycleActive) ?? false

  // Returns the list of all enrollment cycle years signed up by the school including the legacy years
  // Since legacy years are the years that are hardcoded in the frontend, we need to validate if
  // it is expected to show them in the list if a new school is enrolled after 2023.
  const enrollmentCycleYears = [
    ...legacyYears,
    ...(schoolEnrollmentCycles?.map(enrollmentCycle => enrollmentCycle.reference_year).reverse() ??
      []),
  ]

  // Returns the list of all enrollment cycle years that are enabled.
  // The array must contain 0, 1 or 2 elements.
  // If it contains 0 elements, probably the school has canceled the contract.
  const enabledEnrollmentCyclesYears = schoolEnrollmentCycles
    ?.filter(isEnrollmentCycleActive)
    .map(enrollmentCycle => enrollmentCycle.reference_year)

  return {
    activeEnrollmentCycleYear,
    enrollmentCycleYears,
    enabledEnrollmentCyclesYears,
    hasAnyEnabledCycle,
    schoolEnrollmentCycles,
    isEnrollmentCycleActive,
  }
}
