import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { ContractDetailsDrawerState } from 'src/escolas/components/contract/ContractDetailsDrawer/constants'
import ContractDetailsDrawerDefaultContent from 'src/escolas/components/contract/ContractDetailsDrawer/ContractDetailsDrawerDefaultContent'
import {
  Contract,
  ContractStatus,
  ReceivableStatuses,
  SignAndPaymentStatus,
  Receivable,
} from 'src/shared/interfaces'

import { DialogPrimitives } from '@gravity/dialog'
import { Button } from '@gravity/button'

import { useContractDetailsDrawerFooter } from './hooks/useContractDetailsDrawerFooter'
import { ContractCheckoutActions } from '../ContractActionsCheckout/ContractActionsCheckout'
import { CircularLoading } from '@/shared/components/CircularLoading'
import { FailureFeedbackContent, FailureFeedbackDialog } from '../../modal/FeedbackCarneDialog'

export type ContractDetailsDrawerProps = {
  addContract?: Dispatch<SetStateAction<boolean>>
  contract: Contract
  drawerState: ContractDetailsDrawerState
  hasInvoicesWithError: boolean
  isCheckoutSchool?: boolean
  isOpen: boolean
  isReenrollable?: boolean
  onClose: () => void
  setDrawerState: Dispatch<SetStateAction<ContractDetailsDrawerState>>
  setIsConfirmationDialogVisible: (value: boolean) => void
  setShowInvoicesNotGeneratedDialog: (value: boolean) => void
}

export type ButtonHeaderContainerProps = {
  showGoBack: boolean
}

export const ContractDetailsDrawer = ({
  contract,
  isCheckoutSchool,
  isOpen,
  isReenrollable,
  onClose,
  setIsConfirmationDialogVisible,
  addContract,
  hasInvoicesWithError,
  setShowInvoicesNotGeneratedDialog,
}: ContractDetailsDrawerProps) => {
  const [contractStatus, setContractStatus] = useState<string | null>(null)
  const [showAnnualPaidAmountsStatement, setShowAnnualPaidAmountsStatement] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [carneErrorMessage, setCarneErrorMessage] = useState('')

  const {
    showEditContractButton,
    showGenerateStatementButton,
    handleGenerateStatementClick,
    goToContractEditPage,
  } = useContractDetailsDrawerFooter({
    contract,
    isCheckoutSchool,
    isReenrollable,
    setIsConfirmationDialogVisible,
    addContract,
    showAnnualPaidAmountsStatement,
    hasInvoicesWithError,
    setShowInvoicesNotGeneratedDialog,
  })

  const changeContractStatus = () => {
    const { status, sign_payment_status } = contract

    if (status === ContractStatus.PENDING) {
      return setContractStatus(ContractStatus.PENDING)
    }

    return status === ContractStatus.OPEN
      ? setContractStatus(
          SignAndPaymentStatus[sign_payment_status as keyof typeof SignAndPaymentStatus]
        )
      : setContractStatus(ContractStatus.CANCELED)
  }

  const hasPaidReceivables = (c: Contract = contract) => {
    let flatReceivables: Receivable[] = []

    c?.installments?.forEach(({ receivables }) => {
      if (receivables) {
        flatReceivables = [...flatReceivables, ...receivables]
      }
    })
    return flatReceivables.some(({ status }) => status === ReceivableStatuses.PAID)
  }

  useEffect(() => {
    if (!contract) return
    changeContractStatus()
    // feature toggle: the left side of the condition below must be removed when the test is over
    setShowAnnualPaidAmountsStatement(hasPaidReceivables(contract))
  }, [contract])

  const headerTitle = `Contrato ${contract?.reference_year}`
  const headerCheckoutTitle = `${contract?.product?.name} - ${contract?.reference_year}`

  const handleFeedbackOnClose = () => {
    onClose()
    setCarneErrorMessage('')
  }

  const ActionButton = () => {
    if (showGenerateStatementButton) {
      return (
        <Button
          fullWidth
          onClick={handleGenerateStatementClick}
          data-testid="generate-statement-button"
        >
          Gerar demonstrativo de pagamento
        </Button>
      )
    }

    if (showEditContractButton) {
      return (
        <Button color="accent" variant="solid" onClick={goToContractEditPage}>
          Editar Contrato
        </Button>
      )
    }
  }

  const OptionsButton = () => {
    if (contract?.status === ContractStatus.PENDING || showGenerateStatementButton) return null

    return (
      <ContractCheckoutActions
        onClose={onClose}
        setCarneErrorMessage={setCarneErrorMessage}
        setIsLoading={setIsLoading}
        contractId={contract?.id}
        hasInvoicesWithError={hasInvoicesWithError}
        setShowInvoicesNotGeneratedDialog={setShowInvoicesNotGeneratedDialog}
        addContract={addContract}
        isReenrollable={isReenrollable}
        showAnnualPaidAmountsStatement={showAnnualPaidAmountsStatement}
        clickPaidAmountStatement={value => setIsConfirmationDialogVisible(value)}
      />
    )
  }

  return (
    <>
      <FailureFeedbackDialog
        isVisible={!!carneErrorMessage}
        buttonLabel="Entendi"
        onClose={handleFeedbackOnClose}
        submitHandler={handleFeedbackOnClose}
      >
        <FailureFeedbackContent err={carneErrorMessage} />
      </FailureFeedbackDialog>
      <DialogPrimitives.Root open={isOpen} onOpenChange={open => !open && onClose()}>
        <DialogPrimitives.Overlay backdrop />
        <DialogPrimitives.Content
          size={2}
          title={isCheckoutSchool ? headerCheckoutTitle : headerTitle}
          cancelButton={!isLoading && OptionsButton()}
          actionButton={!isLoading && ActionButton()}
        >
          <div className="flex flex-col w-full h-full">
            {isLoading ? (
              <CircularLoading iconSize={64} />
            ) : (
              <ContractDetailsDrawerDefaultContent
                contract={contract}
                contractStatus={contractStatus ?? ''}
              />
            )}
          </div>
        </DialogPrimitives.Content>
      </DialogPrimitives.Root>
    </>
  )
}
