import { UnleashFlags, useJWT, useUnleashFlag } from '@/shared/hooks'
import { useSchoolEnrollmentCycles } from '@/shared/hooks/useSchoolEnrollmentCycles'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useIrregularityBigNumbersQuery } from '@/shared/hooks/queries/irregularities/useIrregularityBigNumbersQuery'
import { IrregularityBigNumbersResponse } from '@/shared/services/irregularities/types'
import { formatCurrencyValue } from '@/shared/utils'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventPageName } from '@monorepo/school-home/models/EventPageName.enum'
import { EventIdentifierName } from '@/shared/models/enums/EventIdentifierName.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

export type AlertBannerInfos = {
  description?: string
  linkLabel?: string
  onLinkClick?: () => void
  redirectLink?: string
  shouldShowAlertBanner: boolean
  title?: string
}

const sumBlockedAmounts = (data: IrregularityBigNumbersResponse): [number, string] => {
  const openItems = (data?.data?.in_progress?.count || 0) + (data?.data?.not_started?.count || 0)
  const blockedAmount =
    (data?.data?.in_progress?.amount || 0) + (data?.data?.not_started?.amount || 0)

  return [openItems, formatCurrencyValue(blockedAmount / 100)]
}

export const useAlertBannerInfos = (): AlertBannerInfos => {
  const isNewUXPendenciesPageEnabled = useUnleashFlag(UnleashFlags.INT_597_NEW_UX_PENDENCIES_PAGE)
  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)
  const { hasPayoutReportAccess: isMaintainer } = useJWT()

  const { school } = useSelectedSchool()
  const { activeEnrollmentCycleYear } = useSchoolEnrollmentCycles()

  const { data, isLoading, isError } = useIrregularityBigNumbersQuery({
    referenceYear: (activeEnrollmentCycleYear ?? 0).toString(),
    schoolId: school?.id ?? '',
  })

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const clickEvent = () => {
    if (!isInitialized) return
    return eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.INTEGRATIONS,
      pageName: EventPageName.SCHOOL_HOME,
      name: EventDispatcherEvents.LINK_CLICKED,
      identifierName: EventIdentifierName.REDIRECT_FIX_PENDING_ISSUES,
      customProperties: {
        $link_name: 'Corrigir pendências',
      },
    })
  }

  if (isLoading || isError) {
    return { shouldShowAlertBanner: false }
  }

  const [openItems, blockedAmount] = sumBlockedAmounts(data)

  if (!(openItems > 0 && isNewUXPendenciesPageEnabled && isIntegratedSchool)) {
    return { shouldShowAlertBanner: false }
  }

  const link = `/${school?.slug}/irregularidades`
  const title = isMaintainer
    ? `Você possui ${blockedAmount} de receita bloqueada`
    : `Você tem ${openItems} pendências de registro`
  const description = isMaintainer
    ? `${openItems} pendências de registro estão impedindo parte do repasse da receita. Regularize as pendências o quanto antes.`
    : `As pendências de registro impedem a criação de boletos e permitem que os alunos estudem sem cobrança.`

  return {
    shouldShowAlertBanner: true,
    title,
    description,
    redirectLink: link,
    linkLabel: 'Corrigir pendências',
    onLinkClick: clickEvent,
  }
}
