import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { Skeleton } from '@gravity/skeleton'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import { PageHeaderProps } from './types'
import * as Styled from './styles'
import { ContractStatus } from '@/shared/interfaces'

const formatDate = (value: datestring) =>
  dayjs(value).format('DD [de] MMM. [de] YYYY').toLocaleLowerCase()

export const PageHeader = ({ isLoading, contract, showTitle = true }: PageHeaderProps) => {
  return (
    <Styled.Header>
      {showTitle && <Heading variant="heading-h1-medium">Editar contrato</Heading>}
      {isLoading || !contract ? (
        <Skeleton width={288} height={32} />
      ) : (
        <>
          <Text variant="subtitle-regular">
            {`Contrato criado em ${formatDate(contract.created_at)}`}
          </Text>

          {contract?.status === ContractStatus.CANCELED && (
            <Text variant="subtitle-regular">&nbsp;/&nbsp;Contrato cancelado</Text>
          )}
        </>
      )}
    </Styled.Header>
  )
}
