import { useMemo } from 'react'
import { useFetchPersonScore } from '@monorepo/enrollment/hooks/queries/useFetchPersonScore'
import { ScoreNumber } from '../types'

type Props = {
  schoolId: string
  taxId: string
}

type Score = {
  earliestAcademicCycle: number | undefined
  isCurrentlyDefault: boolean
  isError: boolean
  isFetching: boolean
  personScore: ScoreNumber
}

export const useScore = ({ schoolId, taxId }: Props): Score => {
  const { data: personScoreResponse, isFetching, isError } = useFetchPersonScore(
    {
      school_id: schoolId,
      tax_id: taxId,
    },
    { enabled: taxId !== undefined }
  )

  const earliestAcademicCycle = useMemo(() => {
    if (personScoreResponse?.data?.academic_cycle_list?.length) {
      return Math.min(...personScoreResponse.data.academic_cycle_list)
    }
    return undefined
  }, [personScoreResponse])

  const personScore = personScoreResponse?.data?.person_score
  const isCurrentlyDefault = personScoreResponse?.data?.is_currently_default

  return {
    isFetching,
    isError,
    earliestAcademicCycle: earliestAcademicCycle,
    isCurrentlyDefault: isCurrentlyDefault,
    personScore: personScore,
  }
}
