import { useState } from 'react'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useApiClient, useSnackbar } from '@/shared/hooks'
import { accountReportsService } from '@monorepo/account-reports/services/account-reports'

import { downloadFile } from '@/shared/utils'

import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { IR_BASE_FOLDER_NAME, ReportTypes } from '../../models/enums/ReportTypes.enum'
import { EventPageName } from '@monorepo/account-reports/models/enums/EventPageName.enum'
import { EventIdentifierName } from '@monorepo/account-reports/models/enums/EventIdentifierName.enum'

export const useDownloadIRReport = () => {
  const { apiClient } = useApiClient()
  const service = accountReportsService(apiClient.getClients().privateApi)
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { school } = useSelectedSchool()
  const { setMessage, setIsOpen } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)

  const sendEvent = ({
    fileName,
    isDownloadSuccessful,
    referenceYear,
  }: {
    fileName: string
    isDownloadSuccessful: boolean
    referenceYear: number
  }) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.DOWNLOAD_FILE,
        scope: EventDispatcherEventScopes.REPORTS,
        pageName: EventPageName.REPORTS,
        identifierName: EventIdentifierName.DOWNLOAD_TAX_STATEMENT,
        customProperties: {
          $is_download_successful: isDownloadSuccessful,
          $report: ReportTypes.IR,
          $button_name: 'Baixar',
          $reference_year: referenceYear,
          $file_name: fileName,
        },
      })
  }

  const downloadIRReport = async (selectedYear: number) => {
    setIsLoading(true)

    const schoolId = school?.id ?? ''

    let isDownloadSuccessful = true
    const fileName =
      selectedYear === 2023 ? schoolId : `${IR_BASE_FOLDER_NAME}/${selectedYear}/${school?.id}`

    try {
      const { url } = await service.getPresignedUrl({
        extension: 'zip',
        file_name: fileName,
        method: 'GET',
      })

      const zipFileDownload = async (fileName: string) => {
        const { data } = await service.downloadFile(fileName)
        return data
      }

      await downloadFile(schoolId, 'zip', () => zipFileDownload(url))
    } catch (error) {
      isDownloadSuccessful = false
      setMessage(
        'Erro ao baixar relatório. Por favor tente novamente mais tarde ou entre em contato com o nosso suporte.'
      )
      setIsOpen(true, { variation: 'error' })
    } finally {
      setIsLoading(false)
      sendEvent({
        fileName,
        isDownloadSuccessful,
        referenceYear: selectedYear,
      })
    }
  }

  return {
    downloadIRReport,
    isLoading,
  }
}
