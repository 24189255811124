import { ReactNode, useEffect, useState } from 'react'
import { Sidemenu, SidemenuState } from '@gravity/sidemenu'

import { NewLayout } from '@/modules/app/components/NewLayout'
import { Layout } from '@/modules/app/components/Layout'
import { useLoadLayoutData } from '@/modules/app/hooks/useLoadLayoutData'

import { LayoutProvider } from '@/shared/contexts/Layout'
import { UnleashFlags, useJWT, useUnleashFlag } from '@/shared/hooks'
import { CircularLoadingPlaceholder } from '@/shared/components/CircularLoadingPlaceholder'
import { GenericError } from '@/shared/components/GenericError'
import { SchoolTransition } from '@/modules/app/components/SchoolTransition'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useIsFirstRender } from '@/shared/hooks/useIsFirstRender'
import { useSchools } from '@/escolas/hooks'

import { SIDEMENU_STATE_STORAGE_KEY } from '@/modules/app/constants'

export type SchoolLayoutProviderProps = {
  children: ReactNode
}

export const SchoolLayoutProvider = ({ children }: SchoolLayoutProviderProps) => {
  const { isLoading, hasErrorOnLoad, retry } = useLoadLayoutData()
  const { isBackofficeUser, isGroupManager } = useJWT()
  const { schoolSlug } = useSelectedSchool()
  const { schools } = useSchools()

  const renderNewSidemenu = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  const [hasSchoolSlugChange, setHasSchoolSlugChange] = useState(false)
  const [isComplete, setIsComplete] = useState(false)

  const isFirstRender = useIsFirstRender()

  useEffect(() => {
    if (!isFirstRender) {
      setHasSchoolSlugChange(true)
    }

    setIsComplete(false)
  }, [schoolSlug])

  const shouldUseSchoolTransitionLoading = (() => {
    if (isBackofficeUser || !isGroupManager) return false

    return hasSchoolSlugChange
  })()

  const currentSchoolName = (() => {
    if (!schoolSlug || schools.length === 0) return ''

    const school = schools.find(school => school.slug === schoolSlug)

    return school?.name ?? ''
  })()

  if (shouldUseSchoolTransitionLoading && currentSchoolName && (isLoading || !isComplete)) {
    return (
      <SchoolTransition
        schoolName={currentSchoolName}
        onComplete={() => {
          setIsComplete(true)
          setHasSchoolSlugChange(false)
        }}
      />
    )
  }

  if (isLoading) return <CircularLoadingPlaceholder />
  if (hasErrorOnLoad) return <GenericError onRetry={retry} />

  return (
    <Sidemenu.Provider
      initialState={
        (localStorage.getItem(SIDEMENU_STATE_STORAGE_KEY) as SidemenuState | null) || 'expanded'
      }
    >
      <LayoutProvider LayoutComponent={renderNewSidemenu ? NewLayout : Layout}>
        {children}
      </LayoutProvider>
    </Sidemenu.Provider>
  )
}
