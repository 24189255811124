import { Skeleton } from '@gravity/skeleton'
import * as Styled from './styles'
import { PersonalDataProps } from './types'
import { Text } from '@gravity/text'
import { Grid, GridItem } from '@gravity/grid'

const PersonalDataItem = ({
  title,
  text,
  isLoading,
}: {
  isLoading: boolean
  text: string
  title: string
}) => {
  return (
    <Styled.ItemContainer>
      <Text variant="body-2-regular" color="gray">
        {title}
      </Text>
      {isLoading ? (
        <Skeleton height={32} width={96} />
      ) : (
        <Text variant="body-1-regular">{text}</Text>
      )}
    </Styled.ItemContainer>
  )
}

export const PersonalData = ({ data, isLoading }: PersonalDataProps) => {
  return (
    <div>
      <Text variant="button-1">Produto e dados cadastrais</Text>
      <div>
        <Grid
          className="rounded-2 border border-solid mb-8 mt-6 border-colors-border-neutral-3"
          style={{
            marginLeft: 'unset',
          }}
        >
          <GridItem xl="1 / 12" lg="1 / 12" md="1 / 12" sm={8} xs={4}>
            <Styled.PersonalDataContainer>
              <PersonalDataItem
                title="Período letivo"
                text={data.referenceYear}
                isLoading={isLoading}
              />
              <PersonalDataItem title="Produto" text={data.productName} isLoading={isLoading} />
              <PersonalDataItem title="Aluno" text={data.studentName} isLoading={isLoading} />
              <PersonalDataItem
                title="Responsável financeiro"
                text={data.guardianName}
                isLoading={isLoading}
              />
            </Styled.PersonalDataContainer>
          </GridItem>
        </Grid>
      </div>
    </div>
  )
}
