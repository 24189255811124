import { InstallmentDrawerProps } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentsDrawerContainerV2'
import { DrawerVariationsProps } from '@/modules/guardians/InstallmentsDrawerContainer/variations'
import { InstallmentDialog } from '../../InstallmentDialog/InstallmentDialog'

export const installment = ({
  installment: installmentInfo,
  onCloseDrawer,
}: InstallmentDrawerProps): DrawerVariationsProps => {
  const fullContent = (
    <InstallmentDialog installment={installmentInfo} onCloseDrawer={onCloseDrawer} />
  )

  return {
    isDialog: true,
    fullContent,
  }
}
