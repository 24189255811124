import dayjs from 'dayjs'

const modalShownByGuardianIdKey = (guardianId: string) => `modalShown_${guardianId}`

export const storeModalShownDate = (guardianId: uuid): void => {
  sessionStorage.setItem(modalShownByGuardianIdKey(guardianId), dayjs().toISOString())
}

export const getModalShownDate = (guardianId: uuid): string | null => {
  return sessionStorage.getItem(modalShownByGuardianIdKey(guardianId)) || null
}
