import styled from 'styled-components'
import { Card } from '@gravity/card'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.gravity.spacing[4]};
`

export const StyledCard = styled(Card)`
  margin-top: ${props => props.theme.gravity.spacing[14]};
  display: flex;
  flex-direction: column;
  border-radius: ${props => props.theme.gravity.borderRadius[6]};
  padding: ${props => props.theme.gravity.spacing[14]};
  border: none;
  background-color: ${props => props.theme.gravity.colors['colors-background-accent-3']};
  gap: ${props => props.theme.gravity.spacing[6]};
`
