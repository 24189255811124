import { formatCentsToReal } from '@/shared/utils'
import { Card } from '@gravity/card'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import {
  GraduationCapOutline,
  MoneyDollarCircleOutline,
  FolderOutline,
  PriceTagOutline,
} from '@gravity/icons'
import { useEvents } from '@monorepo/onboarding/hooks/eventContext'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useBatchSummaryQuery } from '../../hooks/useBatchSummaryQuery'
import { GenericError } from '../components/GenericError'
import { FullSkeleton } from '../Loading'

export const DashboardContent = () => {
  const { batchID } = useParams<{ batchID: string }>()
  const events = useEvents()

  const { isLoading, data, isError } = useBatchSummaryQuery(
    { batchID },
    {
      onError(error) {
        console.error(error)
      },
    }
  )
  useEffect(() => {
    events?.batchPageDashboardViewed()
  }, [])

  if (isError) return <GenericError />

  if (isLoading || data === undefined) {
    return <FullSkeleton />
  }

  return (
    <div className="grid grid-cols-2">
      <div className="col-span-2 lg:col-span-1">
        <Card variant="ghost">
          <MoneyDollarCircleOutline />
          <Heading
            className="text-colors-text-semantic-colors-success-1"
            variant="heading-h1-medium"
          >
            {formatCentsToReal(data.total_amount)}
          </Heading>
          <Text variant="body-1-regular">Valor total de receita a ser migrado</Text>
        </Card>
      </div>
      <div className="col-span-2 lg:col-span-1">
        <Card variant="ghost">
          <PriceTagOutline />
          <Heading className="text-colors-text-semantic-colors-error-1" variant="heading-h1-medium">
            {formatCentsToReal(data.total_discount_amount)}
          </Heading>
          <Text variant="body-1-regular">Valor total de desconto a ser migrado</Text>
        </Card>
      </div>
      <div className="col-span-2 lg:col-span-1">
        <Card variant="ghost">
          <GraduationCapOutline />
          <Heading variant="heading-h1-medium">
            {data.student_count}{' '}
            <Text variant="subtitle-medium">
              {`(${data.student_full_scholarship_count} bolsistas integrais)`}
            </Text>
          </Heading>
          <Text variant="body-1-regular">Alunos a serem migrados</Text>
        </Card>
      </div>
      <div className="col-span-2 lg:col-span-1">
        <Card variant="ghost">
          <FolderOutline />
          <Heading variant="heading-h1-medium">{data.contract_count}</Heading>
          <Text variant="body-1-regular">Contratos a serem migrados</Text>
        </Card>
      </div>
    </div>
  )
}
