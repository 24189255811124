import { useMutation } from '@tanstack/react-query'

import { changeOwnershipService } from '@/modules/contract/services/change-ownership'
import type { BulkChangeOwnershipRequest } from '@/modules/contract/services/change-ownership/types'
import { useApiClient } from '@/shared/hooks'

export const useBulkChangeOwnershipMutation = () => {
  const { apiClient } = useApiClient()
  const service = changeOwnershipService(apiClient.getClients().privateApi)

  return useMutation((params: BulkChangeOwnershipRequest) => service.bulkChangeOwnership(params), {
    onSuccess: response => response,
  })
}
