import { useHistory } from 'react-router-dom'

import { Skeleton } from '@gravity/skeleton'
import { Grid, GridItem } from '@gravity/grid'
import { FullScreenHeader } from '@gravity/header'

import { RowFlexContainer, ColumnContainer } from './styles'

import { TopHeader } from '@/shared/components/TopHeader'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useLayout } from '@/shared/hooks/useLayout'

export const LoadingSimulation = ({
  previousRoute,
  title,
}: {
  previousRoute: string
  title: string
}) => {
  const { replace } = useHistory()
  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  useLayout({
    enableHeader: false,
    enableSideMenu: !renderNewHeader,
  })

  return (
    <div>
      {renderNewHeader ? (
        <FullScreenHeader
          className="fixed left-0 top-0 right-0 z-[99]"
          title={title}
          onClose={() => replace(previousRoute)}
        />
      ) : (
        <TopHeader backButtonLink={previousRoute} />
      )}

      <div className={`flex justify-center ${renderNewHeader ? 'mt-14' : ''}`}>
        <Grid className="mt-8">
          <GridItem lg="4 / 10" md="4 / 10" sm="3 / 7">
            <div className="flex flex-col gap-14">
              <Skeleton radius={8} fullWidth />

              <div className="flex flex-col gap-6">
                <ColumnContainer>
                  <Skeleton radius={8} fullWidth />
                  <Skeleton radius={8} fullWidth />
                </ColumnContainer>
                <ColumnContainer>
                  <Skeleton radius={8} fullWidth />
                  <Skeleton radius={8} fullWidth />
                </ColumnContainer>

                <RowFlexContainer>
                  <ColumnContainer>
                    <Skeleton radius={8} fullWidth />
                    <Skeleton radius={8} fullWidth />
                  </ColumnContainer>
                  <ColumnContainer>
                    <Skeleton radius={8} fullWidth />
                    <Skeleton radius={8} fullWidth />
                  </ColumnContainer>
                </RowFlexContainer>

                <RowFlexContainer>
                  <ColumnContainer>
                    <Skeleton radius={8} fullWidth />
                    <Skeleton radius={8} fullWidth />
                  </ColumnContainer>
                  <ColumnContainer>
                    <Skeleton radius={8} fullWidth />
                    <Skeleton radius={8} fullWidth />
                  </ColumnContainer>
                </RowFlexContainer>

                <ColumnContainer>
                  <Skeleton radius={8} fullWidth />
                  <Skeleton radius={8} fullWidth />
                </ColumnContainer>
              </div>

              <Skeleton radius={8} fullWidth />

              <RowFlexContainer>
                <Skeleton radius={8} fullWidth />
                <Skeleton radius={8} fullWidth />
              </RowFlexContainer>
            </div>
          </GridItem>
        </Grid>
      </div>
    </div>
  )
}
