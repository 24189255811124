import { setToClipboard } from '@/shared/utils'
import { IconButton } from '@gravity/icon-button'
import { FileCopyOutline } from '@gravity/icons'
import { useToast } from '@gravity/toast'

type CopyToClipboardButtonProps = {
  copyableText: string
  onClick?: () => void
  tooltipText: string
}

export const CopyToClipboardIconButton = ({
  copyableText,
  tooltipText,
  onClick,
}: CopyToClipboardButtonProps) => {
  const { toast } = useToast()

  return (
    <IconButton
      variant="ghost"
      size={1}
      onClick={e => {
        e.stopPropagation()
        setToClipboard(copyableText)
        toast({
          title: tooltipText,
          type: 'success',
          duration: 2000,
        })
        if (onClick) onClick()
      }}
    >
      <FileCopyOutline />
    </IconButton>
  )
}
