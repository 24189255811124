import styled from 'styled-components'

export const TableContainer = styled.div`
  table {
    cursor: pointer;
    thead {
      cursor: default;
      tr {
        cursor: default;
        th {
          cursor: default;
        }
      }
    }
  }
`
