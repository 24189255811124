import { Card } from '@gravity/card'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.gravity.spacing[4]};
`

export const CardWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${props => props.theme.gravity.spacing[8]} ${props => props.theme.gravity.spacing[10]};
  background-color: ${props => props.theme.gravity.colors['colors-background-neutral-2']};
  gap: ${props => props.theme.gravity.spacing[6]};
`
