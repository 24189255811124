import { PropsWithChildren } from 'react'
import * as S from './styles'
import type { InputBoxProps } from './types'
import { Accordion } from '@gravity/accordion'

const InputBox = ({
  onClick,
  name,
  isDisabled,
  children,
  title,
  subTitle,
}: PropsWithChildren<InputBoxProps>) => {
  return (
    <Accordion.Item value={name} disabled={isDisabled}>
      <Accordion.Trigger
        onClick={_ => {
          onClick()
        }}
        descriptionList={[{ text: subTitle }]}
      >
        <S.Title variant="subtitle-medium">{title}</S.Title>
      </Accordion.Trigger>
      <Accordion.Content>{children}</Accordion.Content>
    </Accordion.Item>
  )
}

export default InputBox
