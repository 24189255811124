import { Controller, useFieldArray } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useEffect } from 'react'
import { DatepickerPrimitives as DatePicker } from '@gravity/datepicker'
import { Select } from '@gravity/select'
import { TextField } from '@gravity/text-field'
import { Checkbox } from '@gravity/checkbox'
import { Separator } from '@gravity/separator'
import { Button as GravityButton } from '@gravity/button'
import { Add } from '@gravity/icons'

import {
  formatCentsToReal,
  DATE_FORMAT,
  isDayTodayOrAfter,
  isCurrentMonthOrAfter,
  makeIsCurrentCycleOrAfter,
  renderNumericSelectOptions,
  MONTH_FORMAT,
} from 'src/shared/utils'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'
import { Switch } from '@gravity/switch'

import { EnrollmentProps } from './types'

import { AddDiscountFormType, Discount } from '@/shared/interfaces'
import DiscountForm from '../../DiscountForm'
import { CURRENT_YEAR } from '@/shared/constants'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import { useMaxInstallmentValidation } from './hooks/useMaxInstallmentValidation'
import { Callout } from '@gravity/callout'
import * as Styled from './styles'
import SelectDueDay from './SelectDueDay'

const EnrollmentForm = ({
  form,
  enrollmentForm,
  handleEnrollmentFormFieldsChange,
  isEnrollmentEnabled,
  setEnrollmentEnabled,
}: EnrollmentProps) => {
  const { control, formState, getValues, setValue, trigger } = form
  const formValues = getValues()
  const enrollmentInstallments = +getValues('enrollment_duration_months')
  const enrollmentStartMonth = getValues('enrollment_start_month')
  const isCurrentCycleOrAfter = makeIsCurrentCycleOrAfter(formValues.reference_year)
  const enrollmentTotalAmount = formValues.custom_enrollment_monthly_amount * enrollmentInstallments

  const {
    maxEnrollmentInstallments,
    minEnrollmentInstallments,
    isSelectedEnrollmentDurationMonthsValid,
    enrollmentDurationMonthsValidationText,
    enrollmentDurationMonthsValidation,
  } = useMaxInstallmentValidation({
    form,
  })

  const hasMultipleIEnrollmentInstallments = enrollmentInstallments > 1

  const {
    control: enrollmentDiscountsFormControl,
    getValues: enrollmentDiscountsFormGetValues,
  } = enrollmentForm

  const enrollmentDiscountsFormValues = enrollmentDiscountsFormGetValues()
  const enrollmentDiscounts = enrollmentDiscountsFormValues?.discounts

  const { fields: enrollmentDiscountFields, append, remove } = useFieldArray({
    control: enrollmentDiscountsFormControl,
    name: 'discounts',
  })
  const canAddDiscount = isEnrollmentEnabled && enrollmentDiscounts?.length < 3

  const removeDiscount = (index: number) => remove(index)

  const appendDiscount = (enrollmentDiscount: Discount & Partial<AddDiscountFormType>) => {
    append({
      amount: enrollmentDiscount?.amount,
      days_before_due_date: enrollmentDiscount?.days_before_due_date,
      description: enrollmentDiscount?.description,
      isFinished: false,
      label_days_before_due_date: enrollmentDiscount?.label_days_before_due_date ?? '',
    })
  }

  const appendNewDiscount = () => {
    appendDiscount({
      amount: null,
      days_before_due_date: null,
      description: '',
    })
  }

  function resetDiscounts() {
    handleEnrollmentFormFieldsChange({
      discounts: [],
    })
  }

  function toggleEnableEnrollmentHandler() {
    resetDiscounts()
    setEnrollmentEnabled(!isEnrollmentEnabled)
  }

  useEffect(() => {
    setValue('enrollment_start_month', null)
    setValue('enrollment_due_day', null)
  }, [hasMultipleIEnrollmentInstallments])

  useEffect(() => {
    if (enrollmentInstallments && enrollmentStartMonth) {
      trigger('enrollment_duration_months')
    }
  }, [enrollmentInstallments, enrollmentStartMonth])

  const todayDate = new Date()
  const currentDateSelected = (value: Dayjs) => dayjs.utc(value)

  const formatDateValue = (value: Dayjs) => {
    const formattedDate = new Date(value.toDate())
    return formattedDate
  }

  return (
    <>
      <div className="flex flex-row justify-between items-center mt-15 mb-2 ">
        <Heading variant="heading-h4-medium" className="mb-2">
          Pré-matrícula
        </Heading>
        <Switch
          checked={isEnrollmentEnabled}
          onCheckedChange={toggleEnableEnrollmentHandler}
          label="Solicitar pré-matrícula"
        />
      </div>
      {isEnrollmentEnabled && (
        <>
          <Text variant="subtitle-regular">
            O valor da parcela é definido a partir das configurações do produto. Caso queira, você
            pode alterar o valor da parcela abaixo.
          </Text>

          <Callout
            className="mt-6"
            text="Para vencimento informado no passado, atualizamos o vencimento da primeira parcela em 4 dias. Funciona assim: se você está no dia 10/01 e informa o vencimento para 05/01, a primeira parcela vai ter vencimento para 14/01."
          />

          <div className="flex justify-between items-center mb-1 mt-4">
            <>
              <Controller
                name="enrollment_duration_months"
                control={control}
                rules={{
                  required: true,
                  validate: value => isSelectedEnrollmentDurationMonthsValid(value),
                }}
                render={({ field: { value, onChange, ...rest } }) => (
                  <Select
                    {...rest}
                    placeholder="0"
                    label="Parcelas"
                    data-testid="enrollment_duration-select"
                    value={value ? String(value) : ''}
                    onValueChange={valueChange => onChange(parseInt(valueChange))}
                    size={3}
                    fullWidth
                    hasError={Boolean(formState.errors.enrollment_duration_months)}
                    options={renderNumericSelectOptions({
                      start: minEnrollmentInstallments,
                      end: maxEnrollmentInstallments,
                    })}
                  />
                )}
              />
            </>
            <Styled.ArithmeticOperator>x</Styled.ArithmeticOperator>
            <Controller
              rules={{ required: true, validate: v => v >= 100 }}
              control={control}
              name="custom_enrollment_monthly_amount"
              render={({ field: { onChange, value, ref, ...rest } }) => (
                <NumberFormat
                  {...rest}
                  id="custom_enrollment_monthly_amount-input"
                  inputRef={ref}
                  onValueChange={({ floatValue }) => {
                    onChange(floatValue || 0)
                  }}
                  customInput={TextField}
                  placeholder="R$ 0,00"
                  label="Valor da parcela"
                  size={3}
                  fullWidth
                  format={formatCentsToReal}
                  value={value}
                  error={Boolean(formState.errors.custom_monthly_amount)}
                  errorMessage={
                    formState.errors.custom_enrollment_monthly_amount
                      ? 'Insira um valor válido'
                      : ''
                  }
                />
              )}
            />
            <Styled.ArithmeticOperator>=</Styled.ArithmeticOperator>
            <NumberFormat
              id="enrollment-total-amount"
              customInput={TextField}
              value={enrollmentTotalAmount}
              placeholder="R$ 0,00"
              label="Valor da pré-matrícula"
              disabled
              size={3}
              fullWidth
              format={formatCentsToReal}
            />
          </div>
          {enrollmentDurationMonthsValidation && (
            <Text className="text-colors-text-semantic-colors-error-1" variant="caption-regular">
              {enrollmentDurationMonthsValidationText}
            </Text>
          )}
          <div className="my-8">
            <Text variant="body-1-regular" className="text-colors-text-main-2">
              {hasMultipleIEnrollmentInstallments ? 'Início das parcelas' : 'Data de vencimento'}
            </Text>
            <Text variant="body-2-regular" className="mb-4 mt-1 block text-colors-text-main-3">
              {hasMultipleIEnrollmentInstallments
                ? 'Selecione o mês de início das parcelas'
                : 'Selecione o dia de vencimento da pré-matrícula'}
            </Text>

            {hasMultipleIEnrollmentInstallments ? (
              <Controller
                rules={{
                  required: isEnrollmentEnabled,
                  validate: isEnrollmentEnabled
                    ? value =>
                        value !== null &&
                        isCurrentCycleOrAfter(value) &&
                        isCurrentMonthOrAfter(value)
                    : undefined,
                }}
                name="enrollment_start_month"
                control={control}
                render={({ field }) => (
                  <DatePicker.Root>
                    <DatePicker.Trigger
                      data-testid="multiple-enrollment-installments-calendar-trigger"
                      size={3}
                      placeholder={MONTH_FORMAT}
                      disabled={!isEnrollmentEnabled}
                    >
                      {field?.value && currentDateSelected(field.value).format(MONTH_FORMAT)}
                    </DatePicker.Trigger>
                    <DatePicker.Calendar
                      value={field?.value ? formatDateValue(field.value) : todayDate}
                      minDetail="month"
                      maxDetail="year"
                      minDate={todayDate}
                      maxDate={
                        Number(formValues.reference_year) >= CURRENT_YEAR
                          ? new Date(Number(formValues.reference_year), 11, 31)
                          : new Date(Number(CURRENT_YEAR), 11, 31)
                      }
                      onChange={value => {
                        const date = dayjs(value as Date)
                        field.onChange(date)
                      }}
                    />
                  </DatePicker.Root>
                )}
              />
            ) : (
              <Controller
                rules={{
                  required: isEnrollmentEnabled,
                  validate: isEnrollmentEnabled
                    ? value =>
                        value !== null && isCurrentCycleOrAfter(value) && isDayTodayOrAfter(value)
                    : undefined,
                }}
                name="enrollment_start_month"
                control={control}
                render={({ field }) => (
                  <DatePicker.Root>
                    <DatePicker.Trigger
                      data-testid="enrollment-installments-calendar-trigger"
                      size={3}
                      placeholder={DATE_FORMAT}
                      disabled={!isEnrollmentEnabled}
                    >
                      {field?.value && currentDateSelected(field.value).format(DATE_FORMAT)}
                    </DatePicker.Trigger>
                    <DatePicker.Calendar
                      value={field?.value ? formatDateValue(field.value) : todayDate}
                      minDate={todayDate}
                      maxDate={
                        Number(formValues.reference_year) >= CURRENT_YEAR
                          ? new Date(Number(formValues.reference_year), 11, 31)
                          : new Date(Number(CURRENT_YEAR), 11, 31)
                      }
                      onChange={value => {
                        const date = dayjs(value as Date)
                        field.onChange(date)
                      }}
                    />
                  </DatePicker.Root>
                )}
              />
            )}
          </div>
          {hasMultipleIEnrollmentInstallments && (
            <div className="my-4">
              <div className="mt-6 mb-4">
                <Text variant="body-1-regular" className="text-colors-text-main-2">
                  Data de vencimento
                </Text>
                <Text variant="body-2-regular" className="mb-4 mt-1 block text-colors-text-main-3">
                  Selecione a regra de dia de vencimento das parcelas
                </Text>
              </div>
              <SelectDueDay
                form={form}
                inputName="enrollment_due_day"
                radioName="use_working_dates_enrollment"
              />
            </div>
          )}

          {enrollmentDiscounts?.length ? (
            <>
              <div className="mb-1 mt-2">
                <Text variant="body-1-regular">Descontos</Text>
              </div>
              <div className="mb-2">
                <Text variant="body-2-regular" className="text-colors-text-main-3">
                  Cadastre os descontos que entrarão na pré-matrícula
                </Text>
              </div>
            </>
          ) : null}

          {enrollmentDiscountFields?.map((data, index) => (
            <div key={data.id} className="w-[70%]">
              <DiscountForm
                data={data}
                index={index}
                form={enrollmentForm}
                idPrefix="enrollment"
                totalAmount={formValues.custom_enrollment_monthly_amount}
                removeDiscount={removeDiscount}
              />
            </div>
          ))}
          {canAddDiscount && (
            <div className="my-8 w-2/3">
              <GravityButton
                fullWidth
                variant="outline"
                type="button"
                iconEnd={<Add />}
                className="flex justify-between"
                onClick={() => appendNewDiscount()}
              >
                Adicionar desconto na pré-matrícula
              </GravityButton>
            </div>
          )}
          <div className="mt-3">
            <Controller
              name="send_first_enrollment_installment_message"
              control={control}
              render={({ field: { value, ...rest } }) => (
                <>
                  <div className="mt-2 mr-2">
                    <Checkbox
                      {...rest}
                      checked={value}
                      // eslint-disable-next-line react/jsx-handler-names
                      onCheckedChange={rest.onChange}
                      size={2}
                      text="Enviar automaticamente a primeira cobrança pelo isaac ao salvar o contrato"
                    />
                  </div>

                  <div className="pl-10 mt-1">
                    <Text variant="body-2-regular" className="text-colors-text-main-3">
                      Assim que o contrato for salvo, o isaac enviará automaticamente a primeira
                      cobrança para o responsável. Caso não queira que o isaac faça esse envio,
                      desmarque essa opção. Independentemente disso, a cobrança estará sempre
                      disponível na Plataforma isaac.
                    </Text>
                  </div>
                </>
              )}
            />
          </div>
        </>
      )}
      <div className="mt-12 mb-12">
        <Separator color="neutral-2" />
      </div>
    </>
  )
}

export default EnrollmentForm
