import { Dispatch, SetStateAction, useState } from 'react'
import { IconButton } from '@gravity/icon-button'
import { Popover } from '@gravity/popover'
import { Text } from '@gravity/text'
import { More2 } from '@gravity/icons'

import { useApi } from '@/utils/hooks/useApi'
import { downloadFile } from '@/shared/utils'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import { HotjarEvents, useHotjar } from '@/shared/hooks/useHotjar'
import { useContract } from '@/escolas/hooks'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

import { PUBLIC_DOMAIN } from '@/utils/api'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useIsaacPayOutsourcedPaymentProvider } from '@/shared/hooks/useIsaacPayOutsourcedPaymentProvider'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { PopoverContentStyled } from './styles'

export type ContractCheckoutActionsProps = {
  addContract?: Dispatch<SetStateAction<boolean>>
  clickPaidAmountStatement: (value: boolean) => void
  contractId: string
  hasInvoicesWithError?: boolean
  isReenrollable?: boolean
  onClose: () => void
  setCarneErrorMessage: Dispatch<SetStateAction<string>>
  setIsLoading: Dispatch<SetStateAction<boolean>>
  setShowInvoicesNotGeneratedDialog: (status: boolean) => void
  showAnnualPaidAmountsStatement: boolean
}
export const ContractCheckoutActions = ({
  addContract,
  clickPaidAmountStatement,
  contractId,
  hasInvoicesWithError,
  isReenrollable,
  setShowInvoicesNotGeneratedDialog,
  showAnnualPaidAmountsStatement,
  setIsLoading,
  setCarneErrorMessage,
  onClose,
}: ContractCheckoutActionsProps) => {
  const { api } = useApi()
  const { school } = useSelectedSchool()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { sendHotjarEvent } = useHotjar()
  const { contract } = useContract()

  const [optionsOpen, setOptionsOpen] = useState(false)

  const isOutsourcedProvider = useIsaacPayOutsourcedPaymentProvider(school)

  const isNewFormToExportCarneEnabled = useUnleashFlag(
    UnleashFlags.PEX_447_CHANGE_FORM_TO_EXPORT_CARNE_PI
  )

  const useDownloadCarne = async (contractId: string) => {
    const carneFileDownload = async (contractId: string) => {
      await api.contracts.downloadCarne(contractId).then(async data => {
        if (data.status > 200) {
          const error = await data.data.text()
          setCarneErrorMessage(error)
        } else {
          downloadFile(`carne-${contractId}`, 'pdf', async () => await data.data)
          onClose()
        }
      })
    }

    try {
      setCarneErrorMessage('')
      await carneFileDownload(contractId)
    } finally {
      setIsLoading(false)
    }
  }

  const printCarne = () => {
    const printContractURL = `${PUBLIC_DOMAIN}/contract/${contractId}/print-slips`

    if (hasInvoicesWithError) {
      setShowInvoicesNotGeneratedDialog(true)
    } else {
      if (isInitialized)
        eventDispatcherClient.sendEvent({
          name: EventDispatcherEvents.BUTTON_CLICKED,
          scope: EventDispatcherEventScopes.CONTRACT_PAGE,
          action: 'click',
          entity: EventDispatcherEntities.PRINT_BANKSLIP,
          customProperties: {
            $name: 'Imprimir carnê',
            $contract_id: contractId,
          },
        })
      if (isNewFormToExportCarneEnabled) {
        setIsLoading(true)
        useDownloadCarne(contract?.id)
      } else {
        window.open(printContractURL)
      }
    }
  }

  const sendGenerateStatementEvent = () => {
    if (isInitialized)
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.CONTRACT_PAGE,
        action: 'click',
        entity: EventDispatcherEntities.PRINT_DEMONSTRATIVE_PAID_VALUES,
        customProperties: {
          $name: 'Gerar demonstrativo de pagamento',
          $contract_id: contract?.id,
        },
      })
  }

  return (
    <Popover.Root open={optionsOpen} onOpenChange={setOptionsOpen}>
      <Popover.Trigger
        asChild
        size={3}
        data-testid="contract-actions-button"
        aria-label="Outras opções"
        aria-controls="long-menu"
        aria-haspopup="true"
      >
        <IconButton variant="ghost">
          <More2 size="sm" />
        </IconButton>
      </Popover.Trigger>
      <PopoverContentStyled id="long-menu" side="top" align="center">
        {showAnnualPaidAmountsStatement && (
          <Popover.Item
            onClick={() => {
              sendHotjarEvent(HotjarEvents.GENERATE_STATEMENT_CHECKOUT)
              clickPaidAmountStatement(true)
              sendGenerateStatementEvent()
            }}
            data-testid="generate-statement-button"
          >
            <Text>Gerar demonstrativo de pagamento</Text>
          </Popover.Item>
        )}

        {!isOutsourcedProvider && (
          <Popover.Item onClick={printCarne}>
            <Text>Imprimir carnê</Text>
          </Popover.Item>
        )}

        {isReenrollable && (
          <Popover.Item
            onClick={() => {
              addContract?.(true)
            }}
          >
            <Text>Rematricular</Text>
          </Popover.Item>
        )}
      </PopoverContentStyled>
    </Popover.Root>
  )
}
