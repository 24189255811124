import { useParams } from 'react-router-dom'

import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useRedirectTo } from '@/shared/hooks/useRedirectTo'

import { CURRENT_YEAR } from '@/shared/constants'

import type { UseManualLiquidationNavigationProps } from './types'

const useManualLiquidationNavigation = ({
  urn,
  guardianId: guardianIdProp,
  callbackParams,
}: Partial<UseManualLiquidationNavigationProps>) => {
  const { redirectTo } = useRedirectTo()

  const { referenceYear, guardianId: guardianIdParam } = useParams<{
    guardianId: uuid
    referenceYear: string
  }>()

  const guardianId = guardianIdProp || guardianIdParam

  const isManualLiquidationPageEnabled = useUnleashFlag(
    UnleashFlags.PAYMENTS_PEX_273_ENABLE_MANUAL_LIQUIDATION_PAGE
  )

  const isEditManualLiquidationPageEnabled = useUnleashFlag(
    UnleashFlags.PAYMENTS_PEX_288_ENABLE_EDIT_MANUAL_LIQUIDATION_PAGE
  )

  const year = referenceYear || CURRENT_YEAR

  const getCallbackParams = (callbackParams: string) => {
    if (!callbackParams) return ''
    const params = new URLSearchParams()
    params.append('callback', btoa(callbackParams))
    return `?${params.toString()}`
  }

  const goToManualLiquidationPage = () => {
    const path = `/${year}/responsavel/${guardianId}/${urn}/manual-liquidation/add${getCallbackParams(
      callbackParams ?? ''
    )}`

    redirectTo(path)
  }

  const goToEditManualLiquidationPage = () => {
    const path = `/${year}/responsavel/${guardianId}/${urn}/manual-liquidation/edit${getCallbackParams(
      callbackParams ?? ''
    )}`

    redirectTo(path)
  }

  const goToEditManualLiquidation = () => {
    if (isEditManualLiquidationPageEnabled) {
      goToEditManualLiquidationPage()
    }
  }

  const goToManualLiquidation = () => {
    if (isManualLiquidationPageEnabled) {
      goToManualLiquidationPage()
    }
  }

  return { goToManualLiquidation, goToEditManualLiquidation }
}

export default useManualLiquidationNavigation
