import { useState } from 'react'
import { Dialog } from '@gravity/dialog'
import { Select } from '@gravity/select'
import { TextField } from '@gravity/text-field'
import { Button } from '@gravity/button'
import { Text } from '@gravity/text'
import { RadioGroup, RadioGroupItem } from '@gravity/radio'
import { Controller, FieldErrorsImpl, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { formatCentsToReal } from 'src/shared/utils'
import { Installment } from '@/modules/contract/services/types'
import {
  DiscountRule,
  DiscountRuleLabels,
  MINIMUM_AMOUNT_CENTS,
  MINIMUM_DISCOUNT_AMOUNT_CENTS,
  validateDiscountAmount,
  validateDiscountPercentage,
} from '../../utils'
import { SelectedInstallmentsText } from '../SelectedInstallmentsText'
import { Callout } from '@gravity/callout'
import { InstallmentStatuses } from '@/shared/interfaces'
import dayjs from 'dayjs'

const discountRuleOptions = [
  DiscountRule.PERPETUAL,
  DiscountRule.DUE_PAYMENT,
  DiscountRule.EARLY_PAYMENT,
]

const discountRuleToDefaultDaysBeforeDueDate = (rule: DiscountRule): number => {
  if (rule === DiscountRule.PERPETUAL) {
    return -1
  }

  return 0
}

enum Field {
  AMOUNT = 'amount',
  DAYS_BEFORE_DUE_DATE = 'days_before_due_date',
  DESCRIPTION = 'description',
  DISCOUNT_RULE = 'discountRule',
  PERCENTAGE = 'percentage',
}

export type AddDiscountForm = {
  [Field.AMOUNT]: cents | null
  [Field.DAYS_BEFORE_DUE_DATE]: number
  [Field.DESCRIPTION]: string
  [Field.DISCOUNT_RULE]: DiscountRule
  [Field.PERCENTAGE]: number | null
}

const REQUIRED_MESSAGES = {
  [Field.AMOUNT]: 'Por favor, informe o valor do desconto.',
  [Field.DAYS_BEFORE_DUE_DATE]: 'Por favor, informe a quantidade de dias.',
  [Field.DESCRIPTION]: 'Por favor, forneça um nome para o desconto.',
  [Field.PERCENTAGE]: 'Por favor, informe a porcentagem do desconto.',
}

const errorProps = (errors: Partial<FieldErrorsImpl<AddDiscountForm>>, field: Field) => {
  return {
    error: Boolean(errors[field]),
    helperText: errors[field]?.message ?? '',
  }
}

export const AddDiscountDialog = ({
  onClose,
  onConfirm,
  selectedInstallments,
}: {
  onClose: () => void
  onConfirm?: (e: AddDiscountForm, percentMode: boolean) => void
  selectedInstallments: Installment[]
}) => {
  const [percentMode, setPercentMode] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
    watch,
  } = useForm<AddDiscountForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      [Field.DESCRIPTION]: '',
      [Field.AMOUNT]: null,
      [Field.DAYS_BEFORE_DUE_DATE]: discountRuleToDefaultDaysBeforeDueDate(DiscountRule.PERPETUAL),
      [Field.DISCOUNT_RULE]: DiscountRule.PERPETUAL,
      [Field.PERCENTAGE]: null,
    },
  })
  watch(Field.DISCOUNT_RULE)

  const hasOverdueInstallments = selectedInstallments.some(
    installment => installment.status === InstallmentStatuses.OVERDUE
  )

  const discountRuleSelect = () => {
    return discountRuleOptions.map(rule => {
      return {
        label: DiscountRuleLabels[rule]?.name,
        value: rule,
      }
    })
  }

  return (
    <Dialog
      open
      data-testid="test-discount-dialog"
      title="Aplicar descontos"
      onOpenChange={onClose}
      size={2}
      backdrop
      content={
        <div className="px-6 flex flex-col flex-1">
          <div className="mb-6">
            <SelectedInstallmentsText count={selectedInstallments.length} />
          </div>
          <Controller
            name={Field.DESCRIPTION}
            control={control}
            rules={{ required: REQUIRED_MESSAGES[Field.DESCRIPTION] }}
            render={({ field: { onChange } }) => (
              <TextField
                name="Nome do desconto"
                label="Nome do desconto"
                id={Field.DESCRIPTION}
                onChange={onChange}
                size={3}
                error={errorProps(errors, Field.DESCRIPTION).error}
                errorMessage={errorProps(errors, Field.DESCRIPTION).helperText}
              />
            )}
          />

          <section className="my-6">
            <Text variant="body-2-regular">Preencher com base em:</Text>
            <div className="flex gap-4 mt-2">
              <RadioGroup
                defaultValue="amount"
                className="flex flex-row"
                aria-label="Discount mode options"
                name="radio-buttons-group"
              >
                <RadioGroupItem
                  value="amount"
                  id="amount"
                  label="Valor do desconto"
                  onClick={() => setPercentMode(false)}
                />
                <RadioGroupItem
                  value="percentage"
                  id="percentage"
                  label="Porcentagem do desconto"
                  onClick={() => setPercentMode(true)}
                />
              </RadioGroup>
            </div>
          </section>

          {!percentMode && (
            <Controller
              name={Field.AMOUNT}
              control={control}
              rules={{
                required: REQUIRED_MESSAGES[Field.AMOUNT],
                validate: {
                  minValue: value =>
                    (value && value >= MINIMUM_DISCOUNT_AMOUNT_CENTS) ||
                    `O valor do desconto deve ser superior a ${formatCentsToReal(
                      MINIMUM_DISCOUNT_AMOUNT_CENTS
                    )}`,
                  netInstallmentAmount: value =>
                    (value && validateDiscountAmount(value, selectedInstallments)) ||
                    `O valor ${
                      selectedInstallments.length > 1 ? 'de cada parcela' : 'da parcela'
                    } com os descontos aplicados deve ser superior a ${formatCentsToReal(
                      MINIMUM_AMOUNT_CENTS
                    )}`,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div className="mb-0 mr-4">
                  <NumberFormat
                    data-testid="add-discount-amount"
                    id={Field.AMOUNT}
                    onValueChange={({ floatValue }) => onChange(floatValue)}
                    customInput={TextField}
                    size={3}
                    variant="outlined"
                    label="Valor"
                    format={formatCentsToReal}
                    value={value}
                    error={errorProps(errors, Field.AMOUNT).error}
                    errorMessage={errorProps(errors, Field.AMOUNT).helperText}
                  />
                </div>
              )}
            />
          )}

          {percentMode && (
            <Controller
              name={Field.PERCENTAGE}
              control={control}
              rules={{
                required: REQUIRED_MESSAGES[Field.PERCENTAGE],
                validate: {
                  minValue: value =>
                    (value && value > 0) || 'O valor do desconto deve ser superior a zero.',
                  maxValue: value =>
                    (value && value < 100) || 'O valor do desconto deve ser inferior a 100%.',
                  netInstallmentAmount: value =>
                    value
                      ? validateDiscountPercentage(value, selectedInstallments)
                      : `O valor líquido da parcela deve ser superior a ${formatCentsToReal(
                          MINIMUM_AMOUNT_CENTS
                        )}`,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div className="mb-0 mr-4">
                  <NumberFormat
                    id={Field.PERCENTAGE}
                    suffix="%"
                    onValueChange={({ floatValue }) => onChange(floatValue)}
                    customInput={TextField}
                    size={3}
                    variant="outlined"
                    label="Porcentagem"
                    decimalScale={2}
                    value={value}
                    error={errorProps(errors, Field.PERCENTAGE).error}
                    errorMessage={errorProps(errors, Field.PERCENTAGE).helperText}
                  />
                </div>
              )}
            />
          )}

          <Controller
            name={Field.DISCOUNT_RULE}
            control={control}
            render={({ field: { value, onChange } }) => (
              <section className="my-6">
                <Select
                  value={value}
                  size={2}
                  label="Regra de desconto"
                  options={discountRuleSelect()}
                  enableHelperTextSlot
                  helperText={value ? DiscountRuleLabels[value as DiscountRule].description : ''}
                  onValueChange={e => {
                    onChange(e)
                    const value = e as DiscountRule
                    const days = discountRuleToDefaultDaysBeforeDueDate(value)
                    setValue(Field.DAYS_BEFORE_DUE_DATE, days)
                  }}
                />
              </section>
            )}
          />

          {getValues().discountRule === DiscountRule.EARLY_PAYMENT && (
            <Controller
              name={Field.DAYS_BEFORE_DUE_DATE}
              control={control}
              rules={{
                required: REQUIRED_MESSAGES[Field.DAYS_BEFORE_DUE_DATE],
                validate: {
                  minValue: v => v >= 1 || 'O número de dias deve ser superior a 1',
                  maxValue: v => v <= 120 || 'O número de dias deve ser igual ou inferior a 120',
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div className="mt-0 mb-8">
                  <TextField
                    name="Dias antes do vencimento"
                    label="Dias antes do vencimento"
                    id={Field.DAYS_BEFORE_DUE_DATE}
                    value={value}
                    onChange={onChange}
                    size={3}
                    defaultValue={0}
                    type="number"
                    error={errorProps(errors, Field.DAYS_BEFORE_DUE_DATE).error}
                    errorMessage={errorProps(errors, Field.DAYS_BEFORE_DUE_DATE).helperText}
                  />
                </div>
              )}
            />
          )}

          {hasOverdueInstallments && (
            <div className="mt-6">
              <Callout
                text={`Ao aplicar desconto nesta parcela, a data do seu vencimento será recalculada para amanhã, ${dayjs()
                  .add(1, 'day')
                  .format('DD/MM/YYYY')}.`}
              />
            </div>
          )}
        </div>
      }
      actionButton={
        <Button
          variant="solid"
          onClick={handleSubmit(e => onConfirm?.(e, percentMode))}
          disabled={!isValid}
        >
          Aplicar
        </Button>
      }
      cancelButton={
        <Button variant="ghost" onClick={onClose}>
          Cancelar
        </Button>
      }
    />
  )
}
