import { Dispatch, SetStateAction } from 'react'
import { useHistory } from 'react-router-dom'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { AgreementSimulation } from '@/modules/guardians/models/agreement'

import { useAgreement } from '@/escolas/contexts/agreementContext'
import { useAgreementSimulationQuery } from '@/escolas/hooks'
import { CURRENT_YEAR } from '@/shared/constants'

import { MIN_CART_ITEMS } from '../../GuardianDetails/constants'
import { getParamGuardianId } from '../../GuardianDetails/utils/getParamGuardianId'
import { ErrorDialog } from '../../GuardianDetails/features/GuardianDetailsFooter/types'
import adaptCombinedReceivablesToAgreementParam from '../../GuardianDetails/features/GuardianDetailsFooter/utils/adaptCombinedReceivablesToAgreementParam'
import useGuardiansInstallmentsByUrnQuery from '../../ManualLiquidation/hooks/manualLiquidation/useGuardianInstallmentsQueryByUrn'
import adaptInstallmentV2ToCombinedReceivables from '../helpers/adaptInstallmentV2ToCombinableReceivable'
import useCheckoutCart from '../../hooks/useCheckoutCart'
import { useGuardianNavigation } from '@/shared/hooks/useGuardianNavigation'
import { parseDownPaymentsToAgreentSimulationsCombined } from '../helpers/parseDownPaymentsToAgreentSimulationsCombined'

type UseNegotiationProps = {
  setErrorDialog: Dispatch<SetStateAction<ErrorDialog>>
  setLoading: Dispatch<SetStateAction<boolean>>
}

export const useNegotiation = ({ setLoading, setErrorDialog }: UseNegotiationProps) => {
  const { schoolSlug } = useSelectedSchool()
  const { setPageToReturn } = useGuardianNavigation()
  const { checkoutCart } = useCheckoutCart()
  const { initAgreementSimulationFlow } = useAgreement()

  const guardianId = getParamGuardianId()
  const history = useHistory()

  const urns = checkoutCart.map(item => item.urn)

  const guardiansInstallmentsByUrnQuery = useGuardiansInstallmentsByUrnQuery(
    {
      guardianId,
      urns,
    },
    {
      enabled: false,
    }
  )

  const receivables = checkoutCart?.filter(i => i.urn.includes('urn:receivable'))

  const agreementSimulationParams = adaptCombinedReceivablesToAgreementParam(
    receivables,
    guardianId
  )

  const agreementSimulationQuery = useAgreementSimulationQuery(agreementSimulationParams)

  const validateCheckoutCart = () => {
    if (checkoutCart?.length < MIN_CART_ITEMS)
      return {
        isValid: false,
        message: 'Antes de pagar, selecione as parcelas a serem pagas.',
      }

    return { isValid: true, message: null }
  }

  const goToNegotiation = async () => {
    const url = `/${schoolSlug}/${CURRENT_YEAR}/responsavel/${guardianId}/negociacao`

    setPageToReturn()
    setLoading(true)

    try {
      let agreements: AgreementSimulation[]

      if (receivables.length) {
        const simulationsResult = await agreementSimulationQuery.refetch({
          throwOnError: true,
        })
        agreements = simulationsResult?.data
      }

      const installmentsResult = await guardiansInstallmentsByUrnQuery.refetchGuardiansInstallments(
        { throwOnError: true }
      )

      const installments = installmentsResult?.data?.data

      const receivablesCombined = adaptInstallmentV2ToCombinedReceivables(installments)

      const agreementSimulationsCombined =
        agreements?.map(agreement => ({
          ...agreement,
          receivables: receivablesCombined?.filter(receivable =>
            agreement.receivable_ids.includes(receivable.id)
          ),
        })) ?? []

      const downPayments = receivablesCombined.filter(
        receivable => receivable.type === 'DOWN_PAYMENT'
      )

      const parsedDownPayments = parseDownPaymentsToAgreentSimulationsCombined(
        downPayments,
        installments
      )

      initAgreementSimulationFlow(
        [...parsedDownPayments, ...agreementSimulationsCombined],
        receivablesCombined
      )

      history.push(url)
    } catch (e) {
      const tryAgain = () => {
        setErrorDialog({ visible: false })
        goToNegotiation()
      }
      setErrorDialog({
        visible: true,
        onTryAgain: tryAgain,
      })
    } finally {
      setLoading(false)
    }
  }

  return {
    goToNegotiation,
    validateCheckoutCart,
  }
}
