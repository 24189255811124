import { Select } from '@gravity/select'
import { TextField } from '@gravity/text-field'
import { Text } from '@gravity/text'
import { Button as GravityButton } from '@gravity/button'
import { Heading } from '@gravity/heading'
import { Add } from '@gravity/icons'
import { DatepickerPrimitives as DatePicker } from '@gravity/datepicker'
import { useEffect, useState } from 'react'
import { Controller, FieldArrayWithId, useFieldArray } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { AddDiscountFormType, Discount, Product } from '@/shared/interfaces'
import {
  DATE_FORMAT,
  formatCentsToReal,
  isCurrentMonthOrAfter,
  isDayTodayOrAfter,
  makeIsCurrentCycleOrAfter,
  MONTH_FORMAT,
  renderNumericSelectOptions,
} from 'src/shared/utils'

import DiscountForm from '../../DiscountForm'
import EnrollmentForm from './EnrollmentForm'
import { EnrollmentFormFields, PaymentPlanFormProps } from './types'
import { getDefaultEnrollmentFormFields } from './utils'
import { CURRENT_YEAR } from '@/shared/constants'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useMaxInstallmentValidation } from './hooks/useMaxInstallmentValidation'
import { Callout } from '@gravity/callout'
import * as Styled from './styles'
import SelectDueDay from './SelectDueDay'

export const PaymentPlanForm = ({
  form,
  enrollmentFormFields,
  setEnrollmentFormFields,
  discountsForm,
  enrollmentForm,
  isEnrollmentEnabled,
  setEnrollmentEnabled,
  product,
  amountExceedsLimitError,
  clearAmountExceedsLimitError,
}: PaymentPlanFormProps) => {
  const [canEnableEnrollment, setCanEnableEnrollment] = useState<boolean>(false)
  const isTuitionDisabled = useUnleashFlag(UnleashFlags.MAT_957_PRODUCT_WITHOUT_TUITION)

  const { formState, control, getValues, setValue, watch, trigger } = form
  const formValues = getValues()
  watch([
    'enrollment_duration_months',
    'enrollment_due_day',
    'use_working_dates_enrollment',
    'duration_months',
    'due_day',
    'product_id',
    'custom_enrollment_monthly_amount',
    'custom_monthly_amount',
    'start_month',
  ])

  const { enrollment_duration_months, duration_months, start_month } = formValues

  const {
    maxTuitionInstallments,
    minTuitionInstallments,
    isSelectedTuitionDurationMonthsValid,
    tuitionDurationMonthsValidationText,
    tuitionDurationMonthsValidation,
  } = useMaxInstallmentValidation({ form, isTuitionDisabled })

  useEffect(() => {
    if (enrollment_duration_months === '1') {
      setValue('use_working_dates_enrollment', 'false')
    }
  }, [enrollment_duration_months])

  useEffect(() => {
    if (duration_months === '1') {
      setValue('use_working_dates_tuition', 'false')
    }
  }, [duration_months])

  const discountsFormControl = discountsForm?.control
  const discountsFormGetValues = discountsForm?.getValues

  const discountsFormValues = discountsFormGetValues?.()
  const discounts = discountsFormValues?.discounts

  const { fields: discountFields, append, remove } = useFieldArray({
    control: discountsFormControl,
    name: 'discounts',
  })

  const removeDiscount = (index: number) => remove(index)

  const appendDiscount = (discount: Discount & Partial<AddDiscountFormType>) => {
    append({
      amount: discount?.amount,
      days_before_due_date: discount?.days_before_due_date,
      description: discount?.description,
      isFinished: false,
      label_days_before_due_date: discount?.label_days_before_due_date ?? '',
    })
  }

  const appendNewDiscount = () => {
    appendDiscount({
      amount: null,
      days_before_due_date: null,
      description: '',
    })
  }

  const enrollmentFormFieldsChangeHandler = (newFormField: Partial<EnrollmentFormFields>) => {
    setEnrollmentFormFields(prevState => {
      return { ...prevState, ...newFormField }
    })
  }

  const shouldShowDiscountAddCondition = discounts && discounts?.length < 3
  const durationMonthsString = getValues('duration_months')
  const durationMonths = durationMonthsString ? +durationMonthsString : 0
  const hasMultipleInstallments = durationMonths > 1
  const installmentTotalAmount = formValues.custom_monthly_amount * durationMonths

  const isCurrentCycleOrAfter = makeIsCurrentCycleOrAfter(formValues.reference_year)

  useEffect(() => {
    setValue('start_month', isTuitionDisabled ? dayjs() : null)
    setValue('due_day', isTuitionDisabled ? dayjs().date() : null)
  }, [hasMultipleInstallments, isTuitionDisabled])

  const updateProductPaymentFields = (product: Product) => {
    const { setValue } = form

    setValue('custom_monthly_amount', product.monthly_amount)
    setValue('custom_enrollment_monthly_amount', product.enrollment_amount)

    setCanEnableEnrollment(product.enrollment_amount > 0)
    setEnrollmentEnabled(product.enrollment_amount > 0)
    setEnrollmentFormFields(getDefaultEnrollmentFormFields(product.enrollment_amount))

    if (product.installments_quantity > 0) {
      const productTuitionInstallmentsQuantity = Math.min(
        product.installments_quantity,
        maxTuitionInstallments
      )

      setValue(
        'duration_months',
        isTuitionDisabled ? '0' : productTuitionInstallmentsQuantity.toString(),
        {
          shouldValidate: true,
        }
      )
    }

    if (product.enrollment_installments_quantity > 0) {
      setValue('enrollment_duration_months', product.enrollment_installments_quantity.toString(), {
        shouldValidate: true,
      })
    }
  }
  useEffect(() => {
    if (!product) return

    if (!product.id) {
      setCanEnableEnrollment(false)
      setEnrollmentEnabled(false)
      setEnrollmentFormFields(getDefaultEnrollmentFormFields(0))
      return
    }

    updateProductPaymentFields(product)
  }, [product, isTuitionDisabled])

  useEffect(() => {
    if (duration_months && start_month) {
      trigger('duration_months')
    }
  }, [duration_months, start_month])

  const todayDate = new Date()
  const currentDateSelected = (value: Dayjs) => dayjs.utc(value)

  const formatDateValue = (value: Dayjs) => {
    const formattedDate = new Date(value.toDate())
    return formattedDate
  }

  const getFormMonthlyAmountErrorsMessages = () => {
    if (formState.errors.custom_monthly_amount) {
      return 'Insira um valor válido'
    }
    if (amountExceedsLimitError) {
      return 'O valor da parcela parece muito alto, verifique e tente novamente.'
    }
  }
  return (
    <div>
      {canEnableEnrollment && enrollmentForm && (
        <EnrollmentForm
          form={form}
          enrollmentForm={enrollmentForm}
          enrollmentFormFields={enrollmentFormFields}
          isEnrollmentEnabled={isEnrollmentEnabled}
          setEnrollmentEnabled={setEnrollmentEnabled}
          handleEnrollmentFormFieldsChange={enrollmentFormFieldsChangeHandler}
        />
      )}

      {!isTuitionDisabled && (
        <div className="my-3">
          <div className="mt-9 mb-6">
            <Heading variant="heading-h4-medium" className="mb-2">
              Mensalidade
            </Heading>
            <Text variant="subtitle-regular">
              O valor da mensalidade é definido a partir das configurações do produto. Caso queira,
              você pode alterar o valor da parcela abaixo.
            </Text>
          </div>

          <Callout text="Para vencimento informado no passado, atualizamos o vencimento da primeira parcela em 4 dias. Funciona assim: se você está no dia 10/01 e informa o vencimento para 05/01, a primeira parcela vai ter vencimento para 14/01." />
          <div className="flex justify-between items-center mb-1 mt-8">
            <Controller
              name="duration_months"
              control={control}
              rules={{
                required: !isEnrollmentEnabled,
                validate: value => isSelectedTuitionDurationMonthsValid(value),
              }}
              render={({ field: { value, onChange, ...rest } }) => (
                <Select
                  {...rest}
                  placeholder="0"
                  label="Parcelas"
                  data-testid="duration_months-select"
                  value={value ? String(value) : ''}
                  onValueChange={valueChange => onChange(parseInt(valueChange))}
                  size={3}
                  fullWidth
                  hasError={Boolean(formState.errors.duration_months)}
                  options={renderNumericSelectOptions({
                    start: minTuitionInstallments,
                    end: maxTuitionInstallments,
                  })}
                />
              )}
            />
            <Styled.ArithmeticOperator>x</Styled.ArithmeticOperator>
            <Controller
              rules={{ required: true, validate: v => v >= 100 }}
              control={control}
              name="custom_monthly_amount"
              render={({ field: { onChange, value, ref, ...rest } }) => (
                <div className="w-full flex flex-col relative">
                  <NumberFormat
                    {...rest}
                    id="custom_monthly_amount-input"
                    inputRef={ref}
                    onValueChange={({ floatValue }) => {
                      onChange(floatValue)
                      clearAmountExceedsLimitError()
                    }}
                    customInput={TextField}
                    placeholder="R$ 0,00"
                    label="Valor da Parcela"
                    size={3}
                    fullWidth
                    format={formatCentsToReal}
                    value={value}
                    error={
                      Boolean(formState.errors.custom_monthly_amount) || amountExceedsLimitError
                    }
                  />

                  <Text
                    variant="body-2-regular"
                    className="text-colors-text-semantic-colors-error-1 absolute mt-[5rem] line-clamp-2 w-fit"
                  >
                    {getFormMonthlyAmountErrorsMessages()}
                  </Text>
                </div>
              )}
            />
            <Styled.ArithmeticOperator>=</Styled.ArithmeticOperator>
            <NumberFormat
              id="installment-total-amount"
              customInput={TextField}
              value={installmentTotalAmount}
              placeholder="R$ 0,00"
              label="Valor Total"
              disabled
              size={3}
              fullWidth
              format={formatCentsToReal}
            />
          </div>

          {tuitionDurationMonthsValidation && (
            <Text className="text-colors-text-semantic-colors-error-1" variant="caption-regular">
              {tuitionDurationMonthsValidationText}
            </Text>
          )}

          <div className="my-8">
            <Text variant="body-1-regular" className="text-colors-text-main-2">
              {hasMultipleInstallments ? 'Início das mensalidades' : 'Data de vencimento'}
            </Text>
            <Text variant="body-2-regular" className="mb-4 mt-1 block text-colors-text-main-3">
              {hasMultipleInstallments
                ? 'Selecione o mês de início das parcelas'
                : 'Selecione o dia de vencimento'}
            </Text>
            {hasMultipleInstallments ? (
              <Controller
                rules={{
                  required: true,
                  validate: value =>
                    value !== null && isCurrentCycleOrAfter(value) && isCurrentMonthOrAfter(value),
                }}
                name="start_month"
                control={control}
                render={({ field }) => (
                  <DatePicker.Root>
                    <DatePicker.Trigger
                      data-testid="multiple-installments-payment-calendar-trigger"
                      size={3}
                      placeholder={MONTH_FORMAT}
                    >
                      {field?.value && currentDateSelected(field.value).format(MONTH_FORMAT)}
                    </DatePicker.Trigger>
                    <DatePicker.Calendar
                      value={field?.value ? formatDateValue(field.value) : todayDate}
                      minDetail="month"
                      maxDetail="year"
                      minDate={todayDate}
                      maxDate={
                        Number(formValues.reference_year) >= CURRENT_YEAR
                          ? new Date(Number(formValues.reference_year), 11, 31)
                          : new Date(Number(CURRENT_YEAR), 11, 31)
                      }
                      onChange={value => {
                        const date = dayjs(value as Date)
                        field.onChange(date)
                      }}
                    />
                  </DatePicker.Root>
                )}
              />
            ) : (
              <Controller
                rules={{
                  required: true,
                  validate: value =>
                    value !== null && isCurrentCycleOrAfter(value) && isDayTodayOrAfter(value),
                }}
                name="start_month"
                control={control}
                render={({ field }) => (
                  <DatePicker.Root>
                    <DatePicker.Trigger
                      data-testid="payment-calendar-trigger"
                      size={3}
                      placeholder={DATE_FORMAT}
                    >
                      {field?.value && currentDateSelected(field.value).format(DATE_FORMAT)}
                    </DatePicker.Trigger>
                    <DatePicker.Calendar
                      value={field?.value ? formatDateValue(field.value) : todayDate}
                      minDate={todayDate}
                      maxDate={
                        Number(formValues.reference_year) >= CURRENT_YEAR
                          ? new Date(Number(formValues.reference_year), 11, 31)
                          : new Date(Number(CURRENT_YEAR), 11, 31)
                      }
                      onChange={value => {
                        const date = dayjs(value as Date)
                        field.onChange(date)
                      }}
                    />
                  </DatePicker.Root>
                )}
              />
            )}
          </div>
          {hasMultipleInstallments && (
            <div className="mb-3 mt-4">
              <div className="mb-2 mt-4">
                <Text variant="body-1-regular" className="text-colors-text-main-2">
                  Data de vencimento
                </Text>
                <Text variant="body-2-regular" className="mb-4 mt-1 block text-colors-text-main-3">
                  Selecione a regra de dia de vencimento das mensalidades
                </Text>
              </div>
              <SelectDueDay form={form} inputName="due_day" radioName="use_working_dates_tuition" />
            </div>
          )}
          {discounts?.length ? (
            <div>
              <div className="mb-1 mt-2">
                <Text variant="body-1-regular">Descontos</Text>
              </div>
              <div className="mt-2 mb-4">
                <Text variant="body-2-regular">
                  Cadastre os descontos que entrarão em todo o contrato
                </Text>
              </div>
            </div>
          ) : null}
          {discountFields?.map((data, index) => (
            <div key={data.id} className="w-[70%] my-3">
              {discountsForm && (
                <DiscountForm
                  data={data as FieldArrayWithId<EnrollmentFormFields, 'discounts', 'id'>}
                  index={index}
                  form={discountsForm}
                  idPrefix="tuition"
                  totalAmount={formValues.custom_monthly_amount}
                  removeDiscount={removeDiscount}
                />
              )}
            </div>
          ))}
          {shouldShowDiscountAddCondition && (
            <div className="my-8 w-2/3">
              <GravityButton
                fullWidth
                type="button"
                variant="outline"
                data-testid="save-discount-button"
                iconEnd={<Add />}
                className="flex justify-between"
                onClick={() => appendNewDiscount()}
              >
                Adicionar desconto às mensalidades
              </GravityButton>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default PaymentPlanForm
