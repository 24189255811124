import { ReactNode } from 'react'
import { Grid, GridItem } from '@gravity/grid'
import { useLayout } from '@/shared/hooks/useLayout'
import { FullScreenHeader, FullScreenHeaderProps } from '@gravity/header'
import { Footer, FooterProps } from '@gravity/footer'

type LayoutTemplateProps = {
  children?: ReactNode
} & Pick<FullScreenHeaderProps, 'onClose' | 'title'> &
  Pick<FooterProps, 'primaryButton' | 'ghostButton' | 'secondaryButton'>

export const LayoutTemplate = ({
  children,
  onClose,
  title,
  primaryButton,
  secondaryButton,
  ghostButton,
}: LayoutTemplateProps) => {
  useLayout({
    enableSideMenu: false,
    enableHeader: false,
  })

  return (
    <div className="flex flex-col h-screen">
      <FullScreenHeader title={title} onClose={onClose} />

      <div className="flex flex-col flex-1 overflow-y-auto w-full items-center">
        <Grid className="py-14 flex-1">
          <GridItem className="flex flex-col">{children}</GridItem>
        </Grid>
      </div>

      <Footer
        className="static w-full"
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
        ghostButton={ghostButton}
      />
    </div>
  )
}
