import { useMutation } from '@tanstack/react-query'
import { CreateAnticipationSimulationRequest } from '@/shared/services/credit/types'
import { useApiClient } from '@/shared/hooks'
import { creditService } from '@/shared/services/credit'

export const createAnticipationSimulationQueryKey = 'create-anticipation-simulation'

export const useCreateAnticipationSimulation = () => {
  const { apiClient } = useApiClient()

  const services = creditService(apiClient.getClients().bffApi)

  return useMutation(
    [createAnticipationSimulationQueryKey],
    async (body: CreateAnticipationSimulationRequest) =>
      services.createAnticipationSimulation(body),
    { retry: 2, useErrorBoundary: true }
  )
}
