import { ReactNode, useEffect } from 'react'
import { ErrorState } from './components/ErrorState'
import { LoadingState } from './components/LoadingState'
import { formatNumberMicroCentsToReal } from '@/shared/utils/numberFormatters'
import { getPayoutAmountLabel } from '@monorepo/report/utils/getPayoutAmountLabel'
import {
  CardStyled,
  CardHeader,
  CardTitleAndDate,
  CardValue,
  CardValueLabel,
  Amount,
  Actions,
} from './styles'
import { Badge } from '@gravity/badge'

import { PayoutAnticipationButton } from '@monorepo/report/components/PayoutAnticipationButton'
import { useAnticipationEligibility } from '@monorepo/report/hooks/queries/useAnticipationEligibility'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import {
  useContextEligibility,
  useContextOrderLock,
} from '@monorepo/report/contexts/PayoutAnticipationContext'
import { isIsaacPaySchool } from '@/shared/utils/isIsaacPaySchool'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { REPORT_PAGE_NAMES } from '@monorepo/report/constants/REPORT_PAGE_NAMES'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { useGetLock } from '@monorepo/report/hooks/queries/useGetLock'
import { AnticipationCaption } from './components/AnticipationCaption'
import { Button } from '@gravity/button'
import { OrderLock } from '@monorepo/report/models/PayoutAnticipationTypes'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import { Tooltip } from '@gravity/tooltip'
import { PAYOUT_ANTICIPATION_ORDER_TYPE } from '@monorepo/report/constants/PAYOUT_ANTICIPATION_TYPES'
import { Heading } from '@gravity/heading'

type DashboardCardProps = {
  children?: ReactNode
  datePicker?: ReactNode
  hasError?: boolean
  hasPayoutAnticipation?: boolean
  isLoading?: boolean
  isLoadingPayoutAmount?: boolean
  isPayoutOpen: boolean
  onRequestDetails: () => void
  onRetry: () => void
  payoutAmountFormat: 'decimal' | 'microcents'
  payoutSubtitleLabel?: string
  payoutValue: number
  selectedDate?: Date
  title: string
}

/**
 * Component for payout report dashboard cards
 *
 * @param props
 * @param props.title Card title
 * @param props.isLoading Indicates if loading state should be displayed
 * @param props.isLoadingPayoutAmount Indicates if loading state for payout amount should be displayed
 * @param props.isPayoutOpen Indicates if payout is open
 * @param props.payoutValue Payout value
 * @param props.payoutAmountFormat Indicates the payout amount format
 * @param props.children Picker to be used inside the card
 * @param props.hasError Indicates if error state should be displayed
 * @param props.onRequestDetails Callback function triggered on click at details button
 * @param props.onRetry Callback function for retry button
 * @param props.payoutSubtitleLabel Payout label value for open and close instances
 */
export const DashboardCard = ({
  title,
  isLoading = false,
  isLoadingPayoutAmount = false,
  isPayoutOpen,
  payoutValue,
  payoutAmountFormat,
  children = <></>,
  datePicker,
  hasError = false,
  onRequestDetails,
  onRetry,
  payoutSubtitleLabel,
  hasPayoutAnticipation = false,
  selectedDate,
}: DashboardCardProps) => {
  const { school, isCollege } = useSelectedSchool()
  const isIsaacPay = isIsaacPaySchool(school)
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const schoolId = school?.id ?? ''

  const isInMaintenance = useUnleashFlag(UnleashFlags.CRD_166_DISABLE_CREDIT_FLOWS)
  const isPayoutAnticipationEnabled = useUnleashFlag(UnleashFlags.CRD_84_ENABLE_PAYOUT_ANTECIPATION)
  const hasPayoutAnticipationAccess =
    !isCollege && !isIsaacPay && hasPayoutAnticipation && isPayoutAnticipationEnabled

  const sendMixpanelEvent = ({
    errorScope,
    isEligibleForAR,
  }: {
    errorScope?: string
    isEligibleForAR?: boolean
  }) => {
    isInitialized &&
      hasPayoutAnticipationAccess &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.PAYOUT_ANTICIPATION,
        name: EventDispatcherEvents.PAGE_VIEWED,
        action: 'page_view',
        customProperties: {
          $page_name: REPORT_PAGE_NAMES.PAYOUT_DASHBOARD,
          $payment_advance: isEligibleForAR,
          $error_advance: !!errorScope,
          $error_scope: errorScope,
        },
      })
  }

  const {
    data: lock,
    isLoading: isLoadingLock,
    refetch: refetchLock,
    isError: isErrorLock,
  } = useGetLock(schoolId, {
    enabled: !isInMaintenance && hasPayoutAnticipationAccess,
    onError: () => sendMixpanelEvent({ errorScope: 'lock' }),
  })

  const orderLock: OrderLock = !isLoadingLock
    ? {
        isLocked: lock?.isLocked ?? false,
        creditType: lock?.order?.data?.chosen_offer?.type ?? '',
      }
    : {
        isLocked: false,
        creditType: '',
      }

  const {
    data: eligibility,
    isLoading: isLoadingEligibility,
    refetch: refetchEligibility,
    isError: isErrorEligibility,
  } = useAnticipationEligibility(schoolId, {
    enabled: !isInMaintenance && hasPayoutAnticipationAccess,
    onError: () => sendMixpanelEvent({ errorScope: 'eligibility' }),
  })

  const shouldWaitEligibility =
    !isInMaintenance && hasPayoutAnticipationAccess && isLoadingEligibility
  const isSelectedOlderMonth =
    (selectedDate && selectedDate.getMonth() < new Date().getMonth()) ?? false
  const hasAnticipationError = isErrorLock || isErrorEligibility

  const { setEligibility } = useContextEligibility()
  const { setOrderLock } = useContextOrderLock()

  useEffect(() => {
    if (!isInMaintenance && hasPayoutAnticipationAccess) {
      refetchLock()
      refetchEligibility()
    }
  }, [])

  useEffect(() => {
    if (!isLoadingEligibility && !isLoadingLock && !hasAnticipationError) {
      sendMixpanelEvent({ isEligibleForAR: eligibility?.eligibility?.eligible?.AR })
    }
  }, [isLoadingEligibility, isLoadingLock])

  useEffect(() => {
    if (!isLoadingEligibility && eligibility) {
      setEligibility(eligibility)
    }
  }, [eligibility])

  useEffect(() => {
    if (!isLoadingLock) {
      setOrderLock({
        isLocked: lock?.isLocked ?? false,
        creditType: lock?.order?.data?.chosen_offer?.type ?? '',
      })
    }
  }, [lock])

  const parsePayoutValue = (amount: number, format: 'decimal' | 'microcents') => {
    if (format === 'microcents') {
      return formatNumberMicroCentsToReal(amount)
    }

    return amount
  }

  const isNegative = parsePayoutValue(payoutValue, payoutAmountFormat) < 0

  const formattedValue = (() => {
    const parsedValue = parsePayoutValue(Math.abs(payoutValue), payoutAmountFormat)

    const formatter = new Intl.NumberFormat('pt-BR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })

    return formatter.format(parsedValue)
  })()

  const valueLabel = payoutSubtitleLabel ?? getPayoutAmountLabel(isNegative)

  if (hasError) {
    return <ErrorState title={title} onRetry={onRetry} />
  }

  if (isLoading || shouldWaitEligibility || isLoadingPayoutAmount) {
    return <LoadingState />
  }

  return (
    <CardStyled variant="surface">
      <CardHeader>
        {isPayoutOpen ? (
          <Badge color="warning" variant="soft">
            Aberto
          </Badge>
        ) : (
          <Badge color="success" variant="soft">
            Fechado
          </Badge>
        )}

        <CardTitleAndDate>
          <Heading variant="heading-h4-medium" data-testid="title-element">
            {title}
          </Heading>

          {datePicker || <></>}
        </CardTitleAndDate>
        {children}
      </CardHeader>

      <CardValue>
        <CardValueLabel data-testid="payout-value-label">{valueLabel}</CardValueLabel>

        <Amount isNegative={isNegative} data-testid="payout-value-element">
          {`${isNegative ? '- ' : ''}R$ ${formattedValue}`}
        </Amount>

        {hasPayoutAnticipationAccess ? (
          <AnticipationCaption
            isLoadingEligibility={isLoadingEligibility}
            isSelectedOlderMonth={isSelectedOlderMonth}
            hasErrorMessage={hasAnticipationError}
            pageName={REPORT_PAGE_NAMES.PAYOUT_DASHBOARD}
          />
        ) : (
          <>&nbsp;</>
        )}
      </CardValue>

      <Actions>
        <Button onClick={onRequestDetails}>Ver detalhes</Button>

        {hasPayoutAnticipationAccess && (
          <Tooltip
            hidden={!orderLock.isLocked || orderLock.creditType === PAYOUT_ANTICIPATION_ORDER_TYPE}
            text={`Existe um pedido de crédito em\n andamento. Aguarde sua\n conclusão antes de simular\n uma antecipação.`}
          >
            <span>
              <PayoutAnticipationButton
                orderLock={orderLock}
                isSelectedOlderMonth={isSelectedOlderMonth}
                variant="ghost"
                pageName={REPORT_PAGE_NAMES.PAYOUT_DASHBOARD}
              />
            </span>
          </Tooltip>
        )}
      </Actions>
    </CardStyled>
  )
}
