import { createContext, ReactNode, useCallback, useMemo, useState } from 'react'
import { Provider as TooltipProvider } from '@gravity/tooltip'
import { ToastProvider } from '@gravity/toast'
import { themes } from '@gravity/tokens'

import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import { SnackbarProvider } from '@/shared/contexts/snackbarContext'
import { ProviderComposer } from '@/shared/core/providers'

import { GlobalStyles } from '@/modules/app/styles/global'
import { theme } from '@/shared/theme'

import { DSHighlighterStyles } from '@/modules/app/components/DSHighlighter/DSHighlighterStyles'

import '@/modules/app/styles/index.css'

import '@date-io/dayjs'
import 'dayjs/locale/pt-br'

export type GravityContextValue = {
  gravityTheme: 'standard' | 'ngv'
  updateGravityTheme: (theme: 'standard' | 'ngv') => void
}

export const GravityContext = createContext<GravityContextValue>({} as GravityContextValue)

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [gravityTheme, setGravityTheme] = useState<'standard' | 'ngv'>(() => {
    const gravityTheme = localStorage.getItem('@olaisaac/gravity-theme')

    if (gravityTheme) {
      return gravityTheme as 'standard' | 'ngv'
    }

    return 'standard'
  })

  const updateGravityTheme = useCallback(
    (theme: 'standard' | 'ngv') => {
      localStorage.setItem('@olaisaac/gravity-theme', theme)
      setGravityTheme(theme)
    },
    [setGravityTheme]
  )

  const styledComponentsTheme = useMemo(() => {
    return {
      ...theme,
      gravity: themes[gravityTheme],
    }
  }, [gravityTheme])

  return (
    <ProviderComposer
      providers={[
        ({ children }) => (
          <GravityContext.Provider value={{ gravityTheme, updateGravityTheme }}>
            <div data-theme={gravityTheme}>{children}</div>
          </GravityContext.Provider>
        ),
        ({ children }) => (
          <StyledThemeProvider theme={styledComponentsTheme}>{children}</StyledThemeProvider>
        ),
        ({ children }) => children,
        ({ children }) => <SnackbarProvider>{children}</SnackbarProvider>,
        ToastProvider,
        TooltipProvider,
      ]}
    >
      {children}
      <GlobalStyles />
      <DSHighlighterStyles />
    </ProviderComposer>
  )
}
