import { useEffect, useState } from 'react'
import { ProgressBar } from '@gravity/progress-bar'
import { Heading } from '@gravity/heading'
import { Grid, GridItem } from '@gravity/grid'

import SchoolTransitionImage from '@/modules/app/assets/school-transition-ge.svg'

type SchoolTransitionProps = {
  onComplete?: () => void
  schoolName: string
}

const INTERVAL_DELAY_MS = 550
const MAX_PROGRESS = 100
const ADD_PROGRESS = 35

export const SchoolTransition = ({ onComplete, schoolName }: SchoolTransitionProps) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(state => {
        if (state === MAX_PROGRESS) {
          clearInterval(timer)
          onComplete?.()

          return MAX_PROGRESS
        }

        const newProgress = state + ADD_PROGRESS

        return newProgress > MAX_PROGRESS ? MAX_PROGRESS : newProgress
      })
    }, INTERVAL_DELAY_MS)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <div className="flex flex-col items-center justify-center h-screen w-full p-4">
      <Grid>
        <GridItem xl="3 / 11" lg="3 / 11" md="3 / 11">
          <div className="flex justify-center items-center">
            <img src={SchoolTransitionImage} alt="Preparando a unidade selecionada." />
          </div>
        </GridItem>

        <GridItem xl="3 / 11" lg="3 / 11" md="3 / 11">
          <div className="flex gap-2 items-center justify-center">
            <Heading variant="heading-h3-medium"> Preparando a unidade</Heading>
            <Heading variant="heading-h3-medium" className="text-colors-text-main-1">
              {schoolName}
            </Heading>
          </div>
        </GridItem>

        <GridItem xl="3 / 11" lg="3 / 11" md="3 / 11" sm="2 / 8">
          <div className="flex items-center justify-center pt-6">
            <ProgressBar size={2} value={progress} className="w-full" />
          </div>
        </GridItem>
      </Grid>
    </div>
  )
}
