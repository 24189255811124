import { useCallback, useState } from 'react'
import { TextField } from '@gravity/text-field'
import { Close, Search as SearchIcon } from '@gravity/icons'
import { IconButton } from '@gravity/icon-button'
import { debounce } from 'throttle-debounce'

const DEBOUNCE_DELAY = 1000

type SearchProps = {
  placeholder: string
  setValue: (v: string) => void
  value: string
}

export const Search = ({ value, setValue, placeholder }: SearchProps) => {
  const [localValue, setLocalValue] = useState(value)

  const debouncedSetValue = useCallback(
    debounce(DEBOUNCE_DELAY, (newValue: string) => {
      setValue(newValue)
    }),
    []
  )

  return (
    <TextField
      name="Search"
      aria-label={placeholder}
      placeholder={placeholder}
      iconStart={<SearchIcon />}
      iconButton={
        <IconButton
          onClick={() => {
            setLocalValue('')
            setValue('')
          }}
        >
          <Close />
        </IconButton>
      }
      onChange={e => {
        const newValue = (e.target as HTMLInputElement).value
        setLocalValue(newValue)
        debouncedSetValue(newValue)
      }}
      value={localValue}
      size={3}
    />
  )
}
