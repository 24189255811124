import { Card } from '@gravity/card'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: ${props => props.theme.gravity.spacing[6]};
`

export const SubtitleWrapper = styled.div`
  margin-bottom: ${props => props.theme.gravity.spacing[5]};
`

export const Box = styled(Card)`
  height: 100%;
  gap: ${props => props.theme.gravity.spacing[2]};
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.gravity.colors['colors-border-neutral-2']};
`

export const Footer = styled.div`
  margin-top: auto;
  padding-top: ${props => props.theme.gravity.spacing[8]};
  display: flex;
  justify-content: end;
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  gap: ${props => props.theme.gravity.spacing[6]};
  > * {
    flex: 1;
  }
`
