import { TextField } from '@gravity/text-field'
import { Search } from '@gravity/icons'
import { useEffect, useState, ChangeEvent, useCallback, MouseEvent, MouseEventHandler } from 'react'
import { debounce } from 'throttle-debounce'
import styled from 'styled-components'

const debounceDelay = 900

const StyledTextField = styled(TextField)`
  width: 100%;
`

export interface SearchBarProps {
  initialValue?: string
  onClick?: (e: MouseEvent<HTMLInputElement>) => void
  placeholder: string
  setValue: (v: string) => void
}

export const SearchBar = ({
  placeholder,
  initialValue = '',
  setValue,
  onClick,
}: SearchBarProps) => {
  const onChange = useCallback(debounce(debounceDelay, setValue), [setValue])
  const [inputValue, setInputValue] = useState(initialValue)

  useEffect(() => {
    setInputValue(initialValue)
  }, [initialValue])

  const handleOnChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.currentTarget.value)
    onChange(event.currentTarget.value)
  }

  const handleOnClickInput: MouseEventHandler<HTMLInputElement> = params => {
    onClick && onClick(params)
  }

  return (
    <StyledTextField
      name="search-bar"
      placeholder={placeholder}
      size={3}
      iconStart={<Search className="text-colors-background-neutral-4" />}
      value={inputValue}
      onChange={handleOnChangeInput}
      onClick={handleOnClickInput}
    />
  )
}
