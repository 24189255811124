import { GridItem } from '@gravity/grid'
import { IrregularStudentStatus, irregularStatusList } from '../../types'
import { IrregularityBigNumbersCard } from '../IrregularityBigNumbersCard'
import { IrregularityBigNumbersProps } from './types'

export const IrregularityBigNumbers = ({
  data,
  isError,
  isLoading,
}: IrregularityBigNumbersProps) => {
  if (isLoading) {
    return null
  }

  if (isError) {
    return null
  }

  return (
    <>
      {irregularStatusList.map((status: IrregularStudentStatus) => {
        const value = data?.[status]

        if (!value) {
          return null
        }
        return (
          <GridItem key={status} xl={4} lg={4} md={4} sm={4} xs={2} className="mb-8">
            <IrregularityBigNumbersCard
              status={status}
              student_count={value.count}
              total_amount={value.amount}
            />
          </GridItem>
        )
      })}
    </>
  )
}
