import React from 'react'
import { CheckboxCircleOutline, MoneyDollarCircleOutline } from '@gravity/icons'

export type LogisticsTeachingMaterialTab = {
  icon: React.ReactElement
  label: string
  value: LOGISTICS_TEACHING_MATERIAL_TABS
}

export enum LOGISTICS_TEACHING_MATERIAL_TABS {
  DELIVERY_REGISTRY = 'registro_entregas',
  SALES_REPORT = 'relatorio_vendas',
}

export const LOGISTICS_TABS: Array<LogisticsTeachingMaterialTab> = [
  {
    icon: React.createElement(MoneyDollarCircleOutline),
    label: 'Relatório de vendas',
    value: LOGISTICS_TEACHING_MATERIAL_TABS.SALES_REPORT,
  },
  {
    icon: React.createElement(CheckboxCircleOutline),
    label: 'Registro de entregas',
    value: LOGISTICS_TEACHING_MATERIAL_TABS.DELIVERY_REGISTRY,
  },
]
