import { Badge } from '@gravity/badge'
import { Button } from '@gravity/button'
import { Heading } from '@gravity/heading'
import { Link } from '@gravity/link'
import { Tooltip } from '@gravity/tooltip'
import { Skeleton } from '@gravity/skeleton'

import { CampaignStatus } from '@monorepo/enrollment/services/enrollmentService/types'
import type { ViewProps } from './types'

import * as Styled from './styles'

import { ArrowLeft, Prohibited2Outline } from '@gravity/icons'

export const View = ({
  campaignDetails,
  formattedDate,
  setShowCampaignPaymentPlansDialog,
  statusColor,
  statusText,
  handleGoBackClick,
  setShowCloseCampaignDialog,
  sendButtonClickEvent,
  isLoading,
  isAddInstallmentBtnDisabled,
  handleAddInstallmentBtnClick,
  addInstallmentBtnTooltip,
  isAddInstallmentBtnLoading,
}: ViewProps) => {
  return (
    <>
      <Button variant="ghost" iconStart={<ArrowLeft />} onClick={handleGoBackClick}>
        Voltar
      </Button>
      {isLoading ? (
        <Styled.HeaderSkeleton>
          <Styled.InfoHeaderSkeleton>
            <Skeleton fullWidth height={24} radius={8} />
            <Skeleton fullWidth height={24} radius={8} />
            <Skeleton fullWidth height={24} radius={8} />
          </Styled.InfoHeaderSkeleton>
          <Styled.ActionButtonsSkeleton>
            <Skeleton fullWidth height={24} radius={8} />
          </Styled.ActionButtonsSkeleton>
        </Styled.HeaderSkeleton>
      ) : (
        <Styled.ActionsContainer>
          <Styled.MainHeaderInfo>
            <Styled.ActionNameWrapper>
              <Heading variant="heading-h3-medium">{campaignDetails.name}</Heading>
              <Badge variant="soft" color={statusColor}>
                {statusText}
              </Badge>
            </Styled.ActionNameWrapper>

            <Heading variant="heading-h4-regular">{`${campaignDetails.productName} | Válida até ${formattedDate}`}</Heading>
            {campaignDetails.hasPaymentPlan && (campaignDetails?.paymentPlans?.length ?? 0) > 0 && (
              <Link
                onClick={() => {
                  sendButtonClickEvent('ver_condicoes_acompanhamento')
                  setShowCampaignPaymentPlansDialog(true)
                }}
              >
                Ver condições de pagamento
              </Link>
            )}
          </Styled.MainHeaderInfo>
          <Styled.ActionButtonsWrapper>
            <Button
              disabled={campaignDetails.status !== CampaignStatus.OPEN}
              iconStart={<Prohibited2Outline />}
              onClick={() => {
                sendButtonClickEvent('encerrar_campanha_acompanhamento')
                setShowCloseCampaignDialog(true)
              }}
              variant="outline"
            >
              Encerrar campanha
            </Button>
            <Tooltip
              text={addInstallmentBtnTooltip}
              position="bottom"
              hidden={!addInstallmentBtnTooltip}
            >
              <div>
                <Button
                  disabled={isAddInstallmentBtnDisabled}
                  loading={isAddInstallmentBtnLoading}
                  onClick={() => {
                    sendButtonClickEvent('adicionar_mensalidades')
                    handleAddInstallmentBtnClick()
                  }}
                >
                  Adicionar mensalidades
                </Button>
              </div>
            </Tooltip>
          </Styled.ActionButtonsWrapper>
        </Styled.ActionsContainer>
      )}
    </>
  )
}
