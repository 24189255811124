import { DialogPrimitives } from '@gravity/dialog'
import { Heading } from '@gravity/heading'
import { Separator } from '@gravity/separator'
import { Text } from '@gravity/text'

import { InstallmentDrawerProps } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentsDrawerContainerV2'
import { useInstallmentDialog } from './hooks/useInstallmentDialog'
import { InstallmentDialogHeader } from '../components/InstallmentDialogHeader'
import {
  Discount,
  InfoRow,
  InstallmentDrawerActions,
  InstallmentDrawerButtons,
} from '../components'
import { formatter } from '@/modules/guardians/utils/formatter'
import { calculateDiscountPercentage } from '@/modules/guardians/utils/discounts'

export const InstallmentDialog = ({
  installment: installmentInfo,
  onCloseDrawer,
}: InstallmentDrawerProps) => {
  const { headerTitle, headerStatusInfo, notification, footerMessage } = useInstallmentDialog({
    installment: installmentInfo,
  })

  const {
    discounts,
    student_name,
    product_name,
    original_amount,
    current_amount,
    current_interest,
    current_fine,
    credit_card_fee,
  } = installmentInfo

  return (
    <InstallmentDrawerActions onCloseDrawer={onCloseDrawer}>
      <DialogPrimitives.Root open onOpenChange={open => !open && onCloseDrawer()}>
        <DialogPrimitives.Overlay backdrop />
        <DialogPrimitives.Content
          size={2}
          containerClassName="mt-5"
          title="Detalhes da fatura"
          actionButton={
            <InstallmentDrawerButtons
              onCloseDrawer={onCloseDrawer}
              showMainButton
              showEditButton={false}
              showMenu={false}
              groupButtons={false}
            />
          }
          cancelButton={
            <InstallmentDrawerButtons
              onCloseDrawer={onCloseDrawer}
              showMainButton={false}
              showEditButton
              showMenu
              groupButtons={false}
            />
          }
        >
          <div className="flex flex-col h-full w-full">
            <InstallmentDialogHeader
              title={headerTitle}
              statusInfo={headerStatusInfo}
              calloutInfo={
                notification && {
                  description: notification?.description,
                }
              }
            />

            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <Text variant="body-1-regular" className="text-colors-text-main-3">
                  {student_name}
                </Text>
                <Heading variant="heading-h4-medium">{product_name}</Heading>
              </div>

              <Separator color="neutral-2" />

              <div className="flex flex-col gap-2">
                <InfoRow label="Valor integral" value={formatter.amount(original_amount)} />

                {discounts?.map(({ type, is_active, total_amount, discounts: items }, key) => (
                  <Discount
                    key={key}
                    type={type}
                    isActive={is_active}
                    discountPercentage={calculateDiscountPercentage(total_amount, original_amount)}
                    totalAmount={total_amount}
                    items={items}
                  />
                ))}

                {credit_card_fee > 0 && (
                  <InfoRow
                    label="Taxa do cartão"
                    value={`+ ${formatter.amount(credit_card_fee)}`}
                  />
                )}

                {current_interest > 0 && (
                  <InfoRow label="Juros" value={`+ ${formatter.amount(current_interest)}`} />
                )}

                {current_fine > 0 && (
                  <InfoRow label="Multa" value={`+ ${formatter.amount(current_fine)}`} />
                )}
              </div>

              <Separator color="neutral-2" />

              <div className="flex flex-col">
                <InfoRow label="Valor a pagar" value={formatter.amount(current_amount)} />

                <Text variant="body-2-regular">{footerMessage}</Text>
              </div>
            </div>
          </div>
        </DialogPrimitives.Content>
      </DialogPrimitives.Root>
    </InstallmentDrawerActions>
  )
}
