import { Check } from '@gravity/icons'
import styled from 'styled-components'
import { Heading } from '@gravity/heading'

const StyledRoot = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`

export const NoInvalidData = () => {
  return (
    <StyledRoot>
      <Check size="xl" className="text-colors-interaction-primary-1" />
      <Heading variant="heading-h4-medium">Tudo certo por aqui: sem correções necessárias!</Heading>
    </StyledRoot>
  )
}
