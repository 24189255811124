import { Controller, useController, UseFormReturn } from 'react-hook-form'
import { Text } from '@gravity/text'
import { Product } from '@/shared/interfaces'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { EnrollmentCampaignFormProps } from '@monorepo/enrollment/pages/Campaigns/NewCampaign/components/PaymentConditionsStep/types'
import { useAllProductsList } from './hooks/useAllProductsList'
import * as Styled from './styles'
import { Combobox, Option } from '@gravity/combobox'
import { useProductSelect } from '../../../../hooks/useSelectAutocomplete'

type Props = {
  form: UseFormReturn<EnrollmentCampaignFormProps>
  referenceYear: string
}

export const SelectProductInput = ({ form, referenceYear }: Props) => {
  const { school } = useSelectedSchool()

  const productList = useAllProductsList(school?.id)

  const label = `Qual o produto de ${referenceYear} da campanha?`

  const { field } = useController({
    name: 'product',
    control: form.control,
    defaultValue: null,
  })

  const productToOption = (product: Product | null) => {
    if (!product) return undefined
    return {
      label: product.name,
      value: product.id,
    }
  }
  const initialOptions = productList?.data || []

  const {
    isSearchingOptions,
    filteredOptions,
    noOptionsText,
    handleSearch,
    resetField,
  } = useProductSelect<EnrollmentCampaignFormProps>({
    form,
    initialOptions,
  })

  const handleOptionChange = (selectedOption: Option | null) => {
    if (!selectedOption) {
      resetField()
      return
    }

    const productItem = initialOptions.find(product => product.id === selectedOption.value) || null

    form.setValue('product', productItem)
  }
  return (
    <Styled.Wrapper>
      <Controller
        rules={{ required: true, validate: v => Boolean(v) }}
        control={form.control}
        name="product"
        render={() => (
          <>
            <Text id="select-product-input-label" variant="subtitle-medium">
              {label}
            </Text>
            <Combobox
              size={3}
              value={productToOption(field.value)}
              options={filteredOptions.map(option => ({
                label: option.name,
                value: option.id,
              }))}
              onChangeValue={handleOptionChange}
              onSearch={handleSearch}
              placeholder={label}
              emptyPlaceholder={noOptionsText}
              isLoadingOptions={isSearchingOptions}
            />
          </>
        )}
      />
    </Styled.Wrapper>
  )
}
