import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { useApiClient } from '@/shared/hooks/useApiClient'
import { SOFTCACHE_OPTIONS_BASE } from '@/shared/constants'
import {
  FetchContractsByGuardianRequestDTO,
  FetchContractsByGuardianResponseDTO,
} from '@/shared/services/contracts/types'
import { contractService } from '@/shared/services/contracts'

export const useContractsByGuardian = (
  props: FetchContractsByGuardianRequestDTO,
  options?: UseQueryOptions<FetchContractsByGuardianResponseDTO, XMLHttpRequest>
) => {
  const { apiClient } = useApiClient()
  const service = contractService(apiClient.getClients().privateApiV2)

  const queryKey = ['contracts-by-guardian', props.guardianId, props.filter]

  return useQuery<FetchContractsByGuardianResponseDTO, XMLHttpRequest>(
    queryKey,
    () => {
      return service.fetchContractsByGuardian({
        guardianId: props.guardianId,
        filter: props.filter,
        schoolId: props.schoolId,
      })
    },
    {
      ...SOFTCACHE_OPTIONS_BASE,
      ...options,
    }
  )
}
