import styled from 'styled-components'
import { Card } from '@gravity/card'

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[4]};
`

export const StyledCard = styled(Card)`
  max-width: 330.667px;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${props => props.theme.gravity.spacing[8]};
  border-radius: ${props => props.theme.gravity.borderRadius[6]};
  border: none;
  gap: ${props => props.theme.gravity.spacing[4]};
`
