import { Grid, GridItem } from '@gravity/grid'
import { useEffect, useState } from 'react'
import { FullScreenHeader } from '@gravity/header'
import { useHistory } from 'react-router-dom'
import {
  getIdFromUrn,
  getInformationForPaymentDetailByInstallment,
  getReceivableStatusByInstallment,
  sendEvent,
} from '../../helper'
import * as S from '../../styles'
import { useJWT } from '@/shared/hooks'
import { useApi } from '@/utils/hooks/useApi'
import {
  LiquidationInfoResponse,
  LiquidationPaymentMethod,
  LiquidationPaymentMethodAdminLabel,
  LiquidationSource,
  PaymentMethod,
  Receivable,
  ReceivableStatuses,
  Product,
} from '@/shared/interfaces'
import { REALMS } from '@/shared/constants'
import { UnleashFlags, useUnleashFlag } from 'src/shared/hooks/useUnleashFlag'
import useManageFields from '../../hooks/manualLiquidation/useManageFields'
import useManualLiquidationForm from '../../hooks/manualLiquidation/useManualLiquidationForm'
import { getStatus } from '@/escolas/components/contract/utils'
import useLiquidationInfo from '../../hooks/manualLiquidation/useLiquidationInfo'
import { formatCentsToReal, getEndOfToday, validateEditReason } from '@/shared/utils'
import { MixpanelEventsEnum } from '@/shared/integrations'
import dayjs from 'dayjs'
import { sendEditManualLiquidationEventsByInstallment } from '../helper'
import {
  FailureFeedbackContent,
  FailureFeedbackDialog,
} from '@/shared/components/ConfirmationDialog'
import Header from '../../components/Header'
import { InputBox } from '../../components/InputBox'
import { Controller } from 'react-hook-form'
import { EditReasonField } from '../../components/Fields/EditReasonField'
import { AccountField } from '../../components/Fields/AccountField'
import { PaymentMethodField } from '../../components/Fields/PaymentMethodField'
import { PaidDateField } from '../../components/Fields/PaidDateField'
import { DiscountsField } from '../../components/Fields/DiscountsField'
import Footer from '../../components/Footer'
import Conclusion from '../../components/Conclusion'
import { InstallmentV2 } from '@/modules/guardians/services/types'
import { useGuardianNavigation } from '@/shared/hooks/useGuardianNavigation'
import {
  ManualLiquidationFields,
  ManualLiquidationDiscountPageForm,
} from '@/modules/guardians/ManualLiquidation/types'
import { NotAllowedDialog } from '@/shared/components/NotAllowedDialog'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { Add } from '@gravity/icons'
import { Accordion } from '@gravity/accordion'
import { useLayout } from '@/shared/hooks/useLayout'

export interface Props {
  installment: InstallmentV2
  isFetchGuardiansInstallmentsLoading: boolean
  product: Product
}

const ManualLiquidationEdit = ({
  product,
  installment,
  isFetchGuardiansInstallmentsLoading,
}: Props) => {
  const history = useHistory()
  const { isBackofficeUser, getUserRolesBySchool } = useJWT()
  const { pageToReturn } = useGuardianNavigation()

  const { api } = useApi()

  const [openDiscount, setOpenDiscount] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [receivableLiquidated, setReceivableLiquidated] = useState<Receivable>()
  const [showNotAllowedDialog, setShowNotAllowedDialog] = useState(false)

  const receivableId = getIdFromUrn(installment?.urn)

  const realm = isBackofficeUser ? REALMS.ADMIN_REALM : REALMS.SCHOOL_REALM

  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  useLayout({ enableSideMenu: !renderNewHeader, enableHeader: false })

  const isValidatePaidDateBefore7Days = useUnleashFlag(
    UnleashFlags.PAYMENTS_PEX_273_ENABLE_VALIDATE_PAID_DATE_MIN_7_DAYS_BEFORE
  )

  const isEnabledBasicOperationAccessLevel = useUnleashFlag(
    UnleashFlags.PE_233_ENABLE_BASIC_OPERATION_ACCESS_LEVEL
  )

  const { school } = useSelectedSchool()

  const userRoles = getUserRolesBySchool(school?.id ?? '')

  const isManualLiquidationEditBlockedForNoAccessRole =
    isEnabledBasicOperationAccessLevel && !userRoles.has('editar_baixa_manual') && !isBackofficeUser

  const isHybridModelEnabled = useUnleashFlag(UnleashFlags.ISP_38_ENABLE_HYBRID_MODEL_CHARGING)

  const isEdition = installment?.status === ReceivableStatuses.PAID

  const { toggleField, fieldsStatus, isFieldDisabled, getOpenedField } = useManageFields(
    realm,
    isEdition
  )
  const { handleSubmit, control, watch, getValues, setValue } = useManualLiquidationForm(
    isBackofficeUser,
    installment
  )

  const receivableStatus = getStatus(getReceivableStatusByInstallment(installment))

  watch(['source', 'payment_method', 'paid_date'])
  const { source, payment_method, paid_date, edit_reason } = getValues()

  const {
    isLoading,
    discountInfo,
    form: discountForm,
    clearDiscounts,
    liquidationInfo,
  } = useLiquidationInfo({
    installment: installment,
    paidDate: paid_date,
    installmentIsLoading: isFetchGuardiansInstallmentsLoading,
  })

  const initialDueDateValue =
    installment?.lost_due_payment_discount > 0 ? installment?.current_due_payment_discount : 0
  const initialEarlyDiscountValue =
    installment?.lost_early_payment_discount > 0 ? installment?.current_early_payment_discount : 0
  const initialAdditionalDiscountValue = installment?.current_additional_discount

  const {
    additional_discount_amount,
    apply_lost_due_payment_discount,
    apply_lost_early_payment_discount,
    apply_previous_fines_and_interest,
    apply_fines_and_interest_change,
  } = discountForm.getValues()

  discountForm.watch(['additional_discount_amount'])

  const formValidate = () => {
    if (!source) return false
    if (!validateEditReason(edit_reason || '')) return false
    if (source !== LiquidationSource.ISAAC_ACCOUNT && !payment_method) return false
    if (!paid_date) return false
    if (!discountInfo.validateTotalDiscount) return false
    if (Object.values(fieldsStatus).some(fieldStatus => fieldStatus.isOpen)) return false
    return true
  }

  const isFormValid = formValidate()

  const isSourceIsaacAccount = source === LiquidationSource.ISAAC_ACCOUNT

  const handleGoBack = () => {
    history.replace(pageToReturn || '')
  }

  const handleGoBackWithoutSaving = () => {
    handleGoBack()
    sendEvent(
      receivableId,
      receivableStatus,
      MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_PAGE_FLOW_BACK
    )
  }

  const submitHandler = async () => {
    setLoading(true)

    const resource = api.receivables.patchManualLiquidation

    return await resource(
      receivableId || '',
      {
        additional_discount_amount:
          discountInfo.isAdditionalDiscountEnabled && additional_discount_amount
            ? +additional_discount_amount
            : 0,
        apply_lost_due_payment_discount: apply_lost_due_payment_discount,
        apply_lost_early_payment_discount: apply_lost_early_payment_discount,
        paid_date: dayjs(paid_date).utc().hour(0).toISOString(),
        remove_fines_and_interest: !apply_previous_fines_and_interest,
        remove_fines_and_interest_change: !apply_fines_and_interest_change,
        source,
        payment_method:
          source === LiquidationSource.SCHOOL_ACCOUNT
            ? LiquidationPaymentMethod[payment_method as keyof typeof LiquidationPaymentMethod]
            : undefined,
        edit_reason: edit_reason,
        is_edition: isEdition,
      },
      school?.id || ''
    )
      .then(updatedReceivables => {
        const paidReceivable = updatedReceivables.find(
          receivable => receivable.status === ReceivableStatuses.PAID
        )
        setReceivableLiquidated(paidReceivable)
      })
      .catch(() => setShowErrorModal(true))
      .finally(() => setLoading(false))
  }

  const getSourceSubtitle = () => {
    if (fieldsStatus.SELECT_ACCOUNT.isOpen) return 'Selecione em que conta a parcela foi recebida'
    switch (source) {
      case LiquidationSource.ISAAC_ACCOUNT:
        return 'Conta isaac'
      case LiquidationSource.SCHOOL_ACCOUNT:
        return 'Conta escola'
      default:
        return 'Selecione em que conta a parcela foi recebida'
    }
  }

  const getPaymentFormSubtitle = () => {
    if ((!payment_method && !isSourceIsaacAccount) || fieldsStatus.PAYMENT_FORM.isOpen)
      return 'Selecione o meio de pagamento'
    if (isSourceIsaacAccount) return 'Recebimento conta isaac'
    return LiquidationPaymentMethodAdminLabel[
      payment_method as keyof typeof LiquidationPaymentMethodAdminLabel
    ]
  }

  const getPaidDateSubtitle = () => {
    if (!paid_date || fieldsStatus.PAID_DATE.isOpen) return 'Selecione a data'
    return dayjs(paid_date).format('DD/MM/YYYY')
  }

  const endFirstLoading = !!discountInfo?.finalAmount

  const fineAndInterestPrevious = apply_previous_fines_and_interest
    ? discountInfo.previousFinesAndInterest
    : 0

  const changeFineAndInterest = apply_fines_and_interest_change
    ? discountInfo.fineAndInterestChange
    : 0

  const finalFineAndInterest = fineAndInterestPrevious + changeFineAndInterest

  const addicionalDiscount = discountInfo.isAdditionalDiscountEnabled
    ? Number(additional_discount_amount)
    : 0

  const lostDueDiscount = apply_lost_due_payment_discount ? discountInfo.lostDuePaymentDiscount : 0

  const lostEarlyDiscount = apply_lost_early_payment_discount
    ? discountInfo.lostEarlyPaymentDiscount
    : 0

  const totalDiscount = addicionalDiscount + lostDueDiscount + lostEarlyDiscount

  const fineAndDiscountEqualZero = totalDiscount === 0 && finalFineAndInterest <= 0

  const showButtonAddDiscount = !endFirstLoading || (!openDiscount && fineAndDiscountEqualZero)

  const getDiscountSubtitle = () => {
    if (fieldsStatus.DISCOUNTS.isOpen)
      return 'Selecione as multas e descontos que deseja adicionar ou remover'

    let message = ''
    if (finalFineAndInterest > 0) {
      message = `Multas e juros ${formatCentsToReal(finalFineAndInterest)}`
    }
    if (totalDiscount) {
      if (!message) {
        message = `Desconto de ${formatCentsToReal(totalDiscount)}`
      } else {
        message = message + ` e desconto de ${formatCentsToReal(totalDiscount)}`
      }
    }

    return message
  }

  const getEditReasonSubtitle = () => {
    if (!edit_reason || fieldsStatus.EDIT_REASON.isOpen)
      return 'Descreva o motivo da edição do recebimento'
    return edit_reason
  }

  const sendDiscountEvents = (values: ManualLiquidationDiscountPageForm) => {
    if (changeFineAndInterest > 0) {
      sendEvent(
        receivableId,
        receivableStatus,
        MixpanelEventsEnum.METHODS_INCOMING_EDIT_PENALTY_INTERESTS_CHANGED,
        {
          $changedFinesAndInterest: changeFineAndInterest,
        }
      )
    }

    if (
      discountInfo.lostEarlyPaymentDiscount > 0 &&
      initialEarlyDiscountValue > 0 !== values?.apply_lost_early_payment_discount
    ) {
      sendEvent(
        receivableId,
        receivableStatus,
        MixpanelEventsEnum.METHODS_INCOMING_EDIT_ANTICIPATION_DISCOUNT_CHANGED,
        {
          $newEarlyValue: lostEarlyDiscount,
        }
      )
    }

    if (
      discountInfo.lostDuePaymentDiscount > 0 &&
      initialDueDateValue > 0 !== values?.apply_lost_due_payment_discount
    ) {
      sendEvent(
        receivableId,
        receivableStatus,
        MixpanelEventsEnum.METHODS_INCOMING_EDIT_PUNCTUALITY_DISCOUNT_CHANGED,
        {
          $newDueDateValue: lostDueDiscount,
        }
      )
    }

    if (initialAdditionalDiscountValue !== Number(values?.additional_discount_amount)) {
      sendEvent(
        receivableId,
        receivableStatus,
        MixpanelEventsEnum.METHODS_INCOMING_EDIT_ADDITIONAL_DISCOUNT_CHANGED,
        {
          $newAdditionalDiscountValue: values?.additional_discount_amount,
        }
      )
    }
  }

  const onSubmit = () => {
    const values = discountForm.getValues()
    sendDiscountEvents(values)
    sendEditManualLiquidationEventsByInstallment(values, installment, receivableStatus)
    submitHandler()
  }

  const maxDate = isValidatePaidDateBefore7Days
    ? dayjs(installment?.paid_date).utc()
    : getEndOfToday()

  const isDiscountDisabled = (() => {
    if (discountInfo.isRenegotiated) return true
    return isFieldDisabled(fieldsStatus.DISCOUNTS.name)
  })()

  const { finalTotalDiscount, finalAmount } = getInformationForPaymentDetailByInstallment({
    installment,
    liquidationInfo: liquidationInfo as LiquidationInfoResponse,
    discountInfo,
    totalDiscount,
    paidDate: paid_date,
  })

  const isTeachingMaterial = product?.product_category?.name === 'Material'
  const referenceYear = installment?.contracts[0]?.reference_year

  const manualLiquidationBlocked =
    isTeachingMaterial && referenceYear === '2025' && isHybridModelEnabled

  useEffect(() => {
    if (manualLiquidationBlocked) {
      setShowNotAllowedDialog(true)
    }
  }, [manualLiquidationBlocked])

  return (
    <S.Wrapper>
      {renderNewHeader && (
        <FullScreenHeader
          className="fixed top-0 left-0 right-0 z-[99]"
          title="Editar recebimento"
          onClose={handleGoBack}
        />
      )}
      <Grid className={renderNewHeader ? 'mt-8' : ''}>
        <GridItem xs={4} sm={8} md="3 / 11" lg="3 / 11" xl="3 / 11">
          <FailureFeedbackDialog
            isVisible={showErrorModal}
            buttonLabel="Fechar"
            onClose={() => setShowErrorModal(false)}
            submitHandler={() => setShowErrorModal(false)}
          >
            <FailureFeedbackContent />
          </FailureFeedbackDialog>

          <div>
            <Header
              title={renderNewHeader ? '' : 'Editar recebimento'}
              finalAmount={finalAmount}
              totalDiscount={finalTotalDiscount}
              isLoading={isLoading}
              installment={installment}
            />

            {manualLiquidationBlocked ? (
              <NotAllowedDialog
                title="Não é possível editar um recebimento para este contrato"
                description="A edição de recebimento para contratos de material didático não pode ser feito manualmente. Em caso de dúvida, procure o suporte."
                onConfirm={handleGoBack}
                visible={showNotAllowedDialog}
                closeDialog={() => setShowNotAllowedDialog(false)}
              />
            ) : (
              <>
                {!receivableLiquidated && (
                  <S.Form onSubmit={handleSubmit(onSubmit)}>
                    <Accordion.Root
                      type="single"
                      collapsible
                      value={getOpenedField()?.[0] ?? ''}
                      onValueChange={value => {
                        if (value) toggleField(value as ManualLiquidationFields)
                      }}
                    >
                      <InputBox
                        title="Motivo da edição"
                        subTitle={getEditReasonSubtitle()}
                        name={fieldsStatus.EDIT_REASON.name}
                        isDisabled={isFieldDisabled(fieldsStatus.EDIT_REASON.name)}
                        onClick={() => {
                          if (source) {
                            sendEvent(
                              receivableId,
                              receivableStatus,
                              MixpanelEventsEnum.METHODS_INCOMING_EDIT_REASON_EDITED
                            )
                          }
                        }}
                      >
                        <Controller
                          control={control}
                          name="edit_reason"
                          render={({ field: { onChange, value } }) => (
                            <EditReasonField
                              value={value}
                              onCancel={() => {
                                toggleField(ManualLiquidationFields.EDIT_REASON)
                              }}
                              onSave={newValue => {
                                onChange(newValue)
                                const nextField = isBackofficeUser
                                  ? ManualLiquidationFields.SELECT_ACCOUNT
                                  : ManualLiquidationFields.PAYMENT_FORM
                                toggleField(ManualLiquidationFields.EDIT_REASON, nextField)
                              }}
                            />
                          )}
                        />
                      </InputBox>

                      {isBackofficeUser && (
                        <>
                          <InputBox
                            title="Conta de recebimento da parcela"
                            subTitle={getSourceSubtitle()}
                            name={fieldsStatus.SELECT_ACCOUNT.name}
                            isDisabled={isFieldDisabled(fieldsStatus.SELECT_ACCOUNT.name)}
                            onClick={() => {
                              if (source) {
                                sendEvent(
                                  receivableId,
                                  receivableStatus,
                                  MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_ACCOUNT_CHOSEN_EDITED
                                )
                              }
                            }}
                          >
                            <Controller
                              control={control}
                              name="source"
                              render={({ field: { onChange, value } }) => (
                                <AccountField
                                  value={value}
                                  onCancel={() => {
                                    toggleField(ManualLiquidationFields.SELECT_ACCOUNT)
                                  }}
                                  onSave={newValue => {
                                    onChange(newValue)
                                    if (newValue === LiquidationSource.ISAAC_ACCOUNT) {
                                      setValue('payment_method', '')
                                      toggleField(
                                        ManualLiquidationFields.SELECT_ACCOUNT,
                                        ManualLiquidationFields.PAID_DATE
                                      )
                                      return
                                    }
                                    toggleField(
                                      ManualLiquidationFields.SELECT_ACCOUNT,
                                      ManualLiquidationFields.PAYMENT_FORM
                                    )
                                  }}
                                />
                              )}
                            />
                          </InputBox>
                        </>
                      )}
                      <InputBox
                        title="Forma de pagamento usada"
                        subTitle={getPaymentFormSubtitle()}
                        name={fieldsStatus.PAYMENT_FORM.name}
                        isDisabled={
                          isFieldDisabled(fieldsStatus.PAYMENT_FORM.name) ||
                          !source ||
                          isSourceIsaacAccount
                        }
                        onClick={() => {
                          if (payment_method || isSourceIsaacAccount) {
                            sendEvent(
                              receivableId,
                              receivableStatus,
                              MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_PAYOUT_METHOD_CHOSEN_EDITED
                            )
                          }
                        }}
                      >
                        <Controller
                          control={control}
                          name="payment_method"
                          render={({ field: { onChange, value } }) => (
                            <PaymentMethodField
                              value={value}
                              source={source}
                              realm={realm}
                              onCancel={() => {
                                toggleField(ManualLiquidationFields.PAYMENT_FORM)
                              }}
                              onSave={newValue => {
                                onChange(newValue)
                                toggleField(
                                  ManualLiquidationFields.PAYMENT_FORM,
                                  ManualLiquidationFields.PAID_DATE
                                )
                              }}
                            />
                          )}
                        />
                      </InputBox>

                      <InputBox
                        title="Data de recebimento"
                        subTitle={getPaidDateSubtitle()}
                        name={fieldsStatus.PAID_DATE.name}
                        isDisabled={isFieldDisabled(fieldsStatus.PAID_DATE.name)}
                        onClick={() => {
                          if (paid_date) {
                            sendEvent(
                              receivableId,
                              receivableStatus,
                              MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_DATE_CHOSEN_EDITED
                            )
                          }
                        }}
                      >
                        <Controller
                          control={control}
                          name="paid_date"
                          render={({ field: { onChange, value } }) => (
                            <PaidDateField
                              contractCreationDate={dayjs(installment.contracts?.[0]?.created_at)}
                              isEdition={isValidatePaidDateBefore7Days}
                              value={value ?? maxDate}
                              maxDate={maxDate}
                              onCancel={() => {
                                toggleField(ManualLiquidationFields.PAID_DATE)
                              }}
                              onSave={newDate => {
                                onChange(newDate)
                                toggleField(ManualLiquidationFields.PAID_DATE)
                              }}
                              loading={isLoading}
                              installment={installment}
                            />
                          )}
                        />
                      </InputBox>

                      {!showButtonAddDiscount ? (
                        <InputBox
                          title="Multas e Descontos"
                          subTitle={getDiscountSubtitle()}
                          name={fieldsStatus.DISCOUNTS.name}
                          isDisabled={
                            isFieldDisabled(fieldsStatus.DISCOUNTS.name) ||
                            isLoading ||
                            !discountInfo.validateTotalDiscount
                          }
                          onClick={() => {
                            setOpenDiscount(!fineAndDiscountEqualZero)
                          }}
                        >
                          <DiscountsField
                            discountsInfo={discountInfo}
                            form={discountForm}
                            isLoading={isLoading}
                            onCancel={() => {
                              toggleField(ManualLiquidationFields.DISCOUNTS)
                              clearDiscounts()
                              setOpenDiscount(false)
                            }}
                            onSave={() => {
                              toggleField(ManualLiquidationFields.DISCOUNTS)
                              setOpenDiscount(!fineAndDiscountEqualZero)
                            }}
                          />
                        </InputBox>
                      ) : null}
                    </Accordion.Root>

                    <div style={{ marginTop: '16px' }} />

                    {showButtonAddDiscount && (
                      <S.StyledButton
                        variant="ghost"
                        onClick={() => {
                          setOpenDiscount(true)
                          toggleField(ManualLiquidationFields.DISCOUNTS)
                        }}
                        iconStart={<Add />}
                        disabled={isDiscountDisabled}
                      >
                        Adicionar desconto
                      </S.StyledButton>
                    )}
                    <Footer
                      isLoading={isLoading || loading}
                      enableSubmit={isFormValid}
                      onClickGoBack={handleGoBackWithoutSaving}
                      onClick={handleSubmit(onSubmit)}
                      label="Salvar edições"
                    />
                  </S.Form>
                )}
                {receivableLiquidated && (
                  <>
                    <Conclusion
                      liquidationInfo={{
                        amount: receivableLiquidated.total_amount_paid || 0,
                        orderReference: 'Parcela 1 de 1',
                        paidDate: receivableLiquidated.paid_date || '',
                        paymentMethod: receivableLiquidated.payment_method as PaymentMethod,
                        receivableId: receivableLiquidated.id,
                      }}
                      onSuccess={() => {
                        sendEvent(
                          receivableId,
                          receivableStatus,
                          MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_PAYMENT_RECEIPT_DOWNLOAD
                        )
                      }}
                    />
                    <Footer
                      isLoading={isLoading || loading}
                      enableSubmit={isFormValid && !isManualLiquidationEditBlockedForNoAccessRole}
                      onClickGoBack={handleGoBackWithoutSaving}
                      onClick={handleGoBack}
                      type="button"
                      label="Finalizar edições"
                      hideBackButton
                    />
                  </>
                )}
              </>
            )}
          </div>
        </GridItem>
      </Grid>
    </S.Wrapper>
  )
}

export default ManualLiquidationEdit
