import styled from 'styled-components'
import { Card } from '@gravity/card'

export const PayoutCardContainer = styled(Card)`
  display: flex;
  width: 100%;
  height: 300px;
  padding: ${props => props.theme.gravity.spacing[10]};
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: ${props => props.theme.gravity.borderRadius[4]};
  border: 1px solid ${props => props.theme.gravity.colors['colors-border-neutral-3']};
`

export const ContainerGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${props => props.theme.gravity.spacing[4]};
`

export const ContainerRowGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

export const Amount = styled.p`
  color: ${props => props.theme.gravity.colors['colors-text-main-2']};
  font-size: ${props => props.theme.gravity.fontSize['3xl']};
  font-style: normal;
  font-weight: ${props => props.theme.gravity.fontWeight.medium};
  line-height: 120%;
`

export const ContainerTitle = styled.div`
  display: flex;
  width: 29.75rem;
  height: ${props => props.theme.gravity.spacing[10]};
  align-items: center;
`

export const StrongDescription = styled.strong`
  color: ${props => props.theme.gravity.colors['colors-text-main-2']};
  font-size: ${props => props.theme.gravity.fontSize.sm};
  font-style: normal;
  font-weight: ${props => props.theme.gravity.fontWeight.medium};
  line-height: 150%;
  letter-spacing: ${props => props.theme.gravity.letterSpacing.tight};
`
