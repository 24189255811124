import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { isEmpty } from 'ramda'

import { useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { GridItem } from '@gravity/grid'

import { Tooltip } from '@gravity/tooltip'
import { InformationOutline } from '@gravity/icons'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'
import { IconButton } from '@gravity/icon-button'
import { Separator } from '@gravity/separator'
import { Checkbox } from '@gravity/checkbox'

import { fieldsNamesMapping } from 'src/escolas/components/contract/create/constants'
import { getDefaultEnrollmentFormFields, newEmptyGuardian } from './utils'
import { AddDiscountsFormType, Enrollment } from '@/shared/interfaces'
import ConfirmationDialog, {
  FailureFeedbackContent,
  failureFeedbackTitle,
} from '@/shared/components/ConfirmationDialog'
import ContractSelectYear from './ContractSelectYear'
import ContractSummary from './ContractSummary'
import PaymentPlanForm from './PaymentPlanForm'
import PreContractDisclaimer from './PreContractDisclaimer'
import SelectProduct from './SelectProduct'
import { StudentForm } from './StudentForm'
import GuardianForm from './components/GuardianForm/GuardianForm'
import { getConditionalValueRender } from './conditions'
import useDueDayCalculator from './hooks/useDueDayCalculator'
import { GoBackButton } from '@/shared/components/GoBackButton'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EnrollmentEventDispatcherEvents } from '@/shared/models/enums/EnrollmentEventDispatcherEvents.enum'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useApiClient } from '@/shared/hooks/useApiClient'
import { userCreationService } from '@/modules/contract/services/user-creation'
import type { ContractFormProps, EnrollmentFormFields, FormProps } from './types'
import { useLayout } from '@/shared/hooks/useLayout'
import { FullScreenHeader } from '@gravity/header'
import { useAcademicPeriod } from '@monorepo/enrollment/pages/SchoolEnrollmentCycle/hooks/useAcademicPeriod'

export const ContractForm = ({
  contractCreationProgress,
  onSubmit,
  errorFieldNames,
  isFeedbackDialogOpen,
  isInvoiceLimitError,
  isLoading,
  setReferenceYear,
  referenceYear,
  setIsFeedbackDialogOpen,
  hasGuardianDocumentError,
  clearGuardianDocumentError,
  amountExceedsLimitError,
  clearAmountExceedsLimitError,
}: ContractFormProps) => {
  const [enrollmentFormFields, setEnrollmentFormFields] = useState<EnrollmentFormFields>(
    getDefaultEnrollmentFormFields(0)
  )
  const [isEnrollmentEnabled, setEnrollmentEnabled] = useState<boolean>(false)
  const history = useHistory()
  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  useLayout({ enableSideMenu: !renderNewHeader, enableHeader: false })

  const { apiClient } = useApiClient()
  const { checkUserByEmail } = userCreationService(apiClient.getClients().bffApi)
  const shouldValidateEmailOnContractCreate = useUnleashFlag(
    UnleashFlags.EFI_239_VALIDATE_EMAIL_ON_CONTRACT_CREATE
  )

  const { selectedAcademicPeriod } = useAcademicPeriod()

  const form = useForm<FormProps>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: false,
    defaultValues: {
      disable_send_signable_document: false,
      due_day: undefined,
      use_working_dates_tuition: 'true',
      duration_months: '',
      enrollment_due_day: undefined,
      use_working_dates_enrollment: 'true',
      enrollment_duration_months: '',
      enrollment_start_month: undefined,
      guardian: newEmptyGuardian(),
      product_id: '',
      send_first_enrollment_installment_message: true,
      start_month: undefined,
      student: { id: undefined, name: '', tax_id: '', no_tax_id: false },
      reference_year: referenceYear,
      academic_period_id: selectedAcademicPeriod?.id,
      custom_monthly_amount: undefined,
      custom_enrollment_monthly_amount: undefined,
      pre_contract_disclaimer_check: false,
      product: undefined,
    },
  })
  const { control, handleSubmit: formHandleSubmit, getValues, formState, watch, setError } = form

  watch()
  const formValues = getValues()

  const discountsForm = useForm<AddDiscountsFormType>({
    mode: 'onChange',
  })

  const {
    getValues: discountsFormGetValues,
    formState: discountsFormState,
    trigger: triggerDiscountsForm,
  } = discountsForm

  const discountsFormValues = discountsFormGetValues()

  const discounts = discountsFormValues?.discounts

  const EnrollmentDiscountsForm = useForm<EnrollmentFormFields>({
    mode: 'onChange',
  })

  const {
    getValues: enrollmentDiscountsFormGetValues,
    formState: enrollmentDiscountsFormState,
    trigger: triggerEnrollmentDiscountsForm,
  } = EnrollmentDiscountsForm

  const enrollmentDiscountsFormValues = enrollmentDiscountsFormGetValues()

  const enrollmentDiscounts = enrollmentDiscountsFormValues?.discounts ?? []

  const { errors } = formState
  const { errors: discountErros } = discountsFormState
  const { errors: enrollmentDiscountErros } = enrollmentDiscountsFormState
  const product = getValues('product')
  const durationMonthsString = getValues('duration_months')
  const durationMonths = durationMonthsString ? +durationMonthsString : 0
  const enrollmentDurationMonths = +getValues('enrollment_duration_months')
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const {
    enrollment_start_month,
    enrollment_due_day,
    use_working_dates_enrollment,
    start_month,
    use_working_dates_tuition,
    due_day,
  } = getValues()

  const firstDueDay = useDueDayCalculator(
    start_month,
    due_day || start_month?.date(),
    use_working_dates_tuition === 'true'
  )

  const enrollmentFirstDueDay = useDueDayCalculator(
    enrollment_start_month,
    enrollment_due_day || enrollment_start_month?.date(),
    use_working_dates_enrollment === 'true'
  )

  useEffect(() => {
    if (hasGuardianDocumentError) {
      setError('guardian.tax_id', {
        type: 'custom',
        message:
          'Não pode ser utilizado CPF de menor de idade ou que seja inválido na Receita Federal.',
      })

      clearGuardianDocumentError()
    }
  }, [hasGuardianDocumentError])

  const getFeedbackDialogParameters = (): {
    feedbackDialogContent: React.ReactNode
    feedbackDialogTitle: string
  } => {
    if (isInvoiceLimitError) {
      return {
        feedbackDialogTitle: 'Limite de criação de faturas atingido',
        feedbackDialogContent: <FailureFeedbackContent />,
      }
    }
    if (errorFieldNames?.length) {
      return {
        feedbackDialogTitle: 'Por favor, revise os seguintes campos:',
        feedbackDialogContent: (
          <ul>
            {errorFieldNames.map((name, idx) => (
              <li key={idx}>{fieldsNamesMapping[name as keyof typeof fieldsNamesMapping]}</li>
            ))}
          </ul>
        ),
      }
    }

    return {
      feedbackDialogTitle: failureFeedbackTitle,
      feedbackDialogContent: <FailureFeedbackContent />,
    }
  }

  const { feedbackDialogTitle, feedbackDialogContent } = getFeedbackDialogParameters()

  const handleCloseFeedbackDialog = () => {
    setIsFeedbackDialogOpen(false)
  }

  const getEnrollmentData = () => {
    const enrollmentDueDay =
      +(getValues('enrollment_due_day') ?? 0) || +(getValues('enrollment_start_month')?.date() ?? 0)
    const enrollmentStartMonth = getValues('enrollment_start_month')?.toISOString() ?? ''

    const enrollmentData: Enrollment | Record<string, never> = isEnrollmentEnabled
      ? {
          enrollment_discounts: enrollmentDiscounts?.map(discount => ({
            amount: discount.amount,
            description: discount.description,
            days_before_due_date: discount?.days_before_due_date,
          })),
          enrollment_due_day: enrollmentDueDay,
          use_working_dates_enrollment: getValues('use_working_dates_enrollment') === 'true',
          enrollment_duration_months: +getValues('enrollment_duration_months'),
          enrollment_start_month: enrollmentStartMonth,
          send_first_enrollment_installment: getValues('send_first_enrollment_installment_message'),
        }
      : {}

    return enrollmentData
  }

  const [isEditing, setIsEditing] = useState(false)

  const hasErrors = !isEmpty(errors) || !isEmpty(discountErros) || !isEmpty(enrollmentDiscountErros)

  const handleSubmit = async () => {
    triggerDiscountsForm()
    triggerEnrollmentDiscountsForm()
    window.scrollTo(0, 0)
    const { canSaveContract } = getConditionalValueRender({
      customMonthlyAmount: formValues.custom_monthly_amount,
      customEnrollmentMonthlyAmount: formValues.custom_enrollment_monthly_amount,
      discounts,
      enrollmentDiscounts,
    })

    if (shouldValidateEmailOnContractCreate) {
      try {
        const { guardian } = formValues

        const { is_user_creation_allowed } = await checkUserByEmail({
          email: guardian.email,
          username: guardian.tax_id,
        })

        if (!is_user_creation_allowed) {
          setError('guardian.email', {
            type: 'custom',
            message: 'Este e-mail já é usado por outro responsável. Informe um e-mail novo.',
          })

          return
        }
      } catch (error) {
        console.error(error)
      }
    }

    if (!canSaveContract || hasErrors) {
      console.error({ canSaveContract, hasErrors, errors, discountErros, enrollmentDiscountErros })
      Sentry.captureMessage('Contract form has errors')
      return
    }

    const enrollmentData = getEnrollmentData()

    return onSubmit(enrollmentData, formValues, discounts)
  }

  return (
    <>
      {renderNewHeader && (
        <FullScreenHeader
          className="fixed top-0 left-0 right-0 z-[1201]"
          title="Adicionar contrato"
          onClose={() => history.goBack()}
        />
      )}
      <GridItem xl="1 / 10" lg="1 / 9" md="1 / 8" sm="1 / 6" xs="1 / 3">
        <form onSubmit={formHandleSubmit(handleSubmit)}>
          <div className="my-6">
            {!renderNewHeader && (
              <div className="mt-3 mb-3">
                <GoBackButton
                  aditionalOnClick={() =>
                    isInitialized &&
                    eventDispatcherClient.sendEvent({
                      scope: EventDispatcherEventScopes.ADD_NEW_CONTRACT_PAGE,
                      name: EnrollmentEventDispatcherEvents.CLICKED,
                      action: 'click',
                      customProperties: { $Button_name: 'VOLTAR' },
                    })
                  }
                />
              </div>
            )}
            {renderNewHeader ? (
              <div className="flex items-center mb-10 mt-19">
                <Heading variant="heading-h2-medium" className="mt-8">
                  Dados do contrato
                </Heading>
                <div className="pl-1 flex mt-8">
                  <Tooltip
                    className="max-w-[320px]"
                    text="Aqui você insere todas as informações necessárias para cadastrar um contrato na plataforma. Se o responsável pelo aluno já estiver na base de dados, os dados serão resgatados."
                  >
                    <IconButton type="button" variant="ghost" size={2}>
                      <InformationOutline color="gray" />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            ) : (
              <div className="flex items-center mb-10 mt-6">
                <Heading variant="heading-h1-regular">Adicionar contrato</Heading>
                <div className="pl-1 flex">
                  <Tooltip
                    className="max-w-[320px]"
                    text="Aqui você insere todas as informações necessárias para cadastrar um contrato na plataforma. Se o responsável pelo aluno já estiver na base de dados, os dados serão resgatados."
                  >
                    <IconButton type="button" variant="ghost" size={2}>
                      <InformationOutline color="gray" />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            )}
            <div className="mb-6">
              <ContractSelectYear
                form={form}
                referenceYear={referenceYear}
                setReferenceYear={setReferenceYear}
              />
            </div>
            <div className="my-12">
              <SelectProduct form={form} referenceYear={referenceYear} />
            </div>
            <div>
              <GuardianForm
                form={form}
                setIsFeedbackDialogOpen={setIsFeedbackDialogOpen}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </div>
            <div className="mt-12 mb-12">
              <StudentForm form={form} />
            </div>
            <div className="mb-12">
              <Separator color="neutral-2" />
            </div>
            <PaymentPlanForm
              form={form}
              product={product}
              discountsForm={discountsForm}
              enrollmentForm={EnrollmentDiscountsForm}
              enrollmentFormFields={enrollmentFormFields}
              setEnrollmentFormFields={setEnrollmentFormFields}
              isEnrollmentEnabled={isEnrollmentEnabled}
              setEnrollmentEnabled={setEnrollmentEnabled}
              amountExceedsLimitError={amountExceedsLimitError}
              clearAmountExceedsLimitError={clearAmountExceedsLimitError}
            />
            {product?.envelope_template_referral_id && (
              <>
                <Text variant="body-1-regular">Contrato</Text>
                <div className="mt-2">
                  <Controller
                    defaultValue={false}
                    name="disable_send_signable_document"
                    control={control}
                    render={({ field: { value, ...rest } }) => (
                      <>
                        <div className="mt-3">
                          <Checkbox
                            {...rest}
                            checked={value}
                            // eslint-disable-next-line react/jsx-handler-names
                            onCheckedChange={rest.onChange}
                            text="Não enviar contrato pelo isaac"
                            size={2}
                          />
                        </div>

                        <div className="pl-10 mt-1">
                          <Text variant="body-2-regular" className="text-colors-text-main-3">
                            Ao marcar, escola confirma que o responsável recebeu e assinou o
                            contrato. O responsável não receberá o contrato por intermédio do isaac.
                          </Text>
                        </div>
                      </>
                    )}
                  />
                </div>
              </>
            )}
            <PreContractDisclaimer form={form} />
          </div>
          <ConfirmationDialog
            isVisible={isFeedbackDialogOpen}
            onClose={handleCloseFeedbackDialog}
            submitHandler={handleCloseFeedbackDialog}
            title={feedbackDialogTitle}
          >
            {feedbackDialogContent}
          </ConfirmationDialog>

          <ContractSummary
            contractCreationProgress={contractCreationProgress}
            installments={durationMonths}
            installmentAmount={formValues.custom_monthly_amount}
            discounts={discounts}
            isEnrollmentEnabled={isEnrollmentEnabled}
            enrollmentAmount={formValues.custom_enrollment_monthly_amount}
            enrollmentDiscount={enrollmentDiscounts}
            enrollmentInstallments={enrollmentDurationMonths}
            envelopeTemplateReferralId={product?.envelope_template_referral_id}
            isLoading={isLoading}
            formValues={formValues}
            enrollmentFirstDueDay={enrollmentFirstDueDay}
            firstDueDay={firstDueDay}
            setIsEditing={setIsEditing}
          />
        </form>
      </GridItem>
    </>
  )
}
