import {
  Container,
  GuardianNameContainer,
  GuardianNameContent,
  ContainerCardInfo,
  BoxCardInfo,
  TitleText,
  InfoText,
  WrapText,
} from './styles'

import { CardInfoProps } from './types'
import { useEffect, useState } from 'react'
import { DebtStatus } from '../DebtStatus'
import { Text } from '@gravity/text'
import { formatPhoneNumber, formatCPF } from '@/shared/utils'
import { Skeleton } from '@gravity/skeleton'
import { Tooltip } from '@gravity/tooltip'
import { Link } from '@gravity/link'

const CardInfoPlaceholder = () => {
  return (
    <Container>
      <GuardianNameContent>
        <InfoText variant="body-2-regular">Responsável financeiro</InfoText>
        <GuardianNameContainer>
          <Skeleton width={350} height={30} />
          <Skeleton radius={6} width={84} />
        </GuardianNameContainer>
      </GuardianNameContent>
      <ContainerCardInfo>
        <BoxCardInfo>
          <InfoText variant="body-2-regular">CPF</InfoText>
          <Skeleton width={200} />
        </BoxCardInfo>
        <BoxCardInfo>
          <InfoText variant="body-2-regular">Telefone</InfoText>
          <Skeleton width={200} />
        </BoxCardInfo>
        <BoxCardInfo>
          <InfoText variant="body-2-regular">Email</InfoText>
          <Skeleton width={250} />
        </BoxCardInfo>
      </ContainerCardInfo>
    </Container>
  )
}

const InsertLink = ({ type, onClick }: { onClick: () => void; type: 'email' | 'phone' }) => (
  <Link onClick={onClick} underline="none">
    {type === 'email' ? 'Insira um endereço de e-mail' : 'Insira um número de telefone'}
  </Link>
)

export const CardInfo = ({
  guardian: { cpf = '', email, guardianName, phone, debtStatus },
  isLoading,
  openEditRegistrationDrawer,
}: CardInfoProps) => {
  const [showGuardianNameTooltip, setShowGuardianNameTooltip] = useState<boolean>(false)

  const isOverflowing = (textElement: HTMLElement | null) => {
    if (!textElement) return false
    return textElement.scrollWidth > textElement.clientWidth
  }

  useEffect(() => {
    const guardianNameElement = document.getElementById('guardianName')
    setShowGuardianNameTooltip(isOverflowing(guardianNameElement))
  }, [guardianName])

  if (isLoading) {
    return <CardInfoPlaceholder />
  }

  return (
    <Container>
      <GuardianNameContent>
        <InfoText variant="body-2-regular">Responsável financeiro</InfoText>
        <GuardianNameContainer>
          {showGuardianNameTooltip ? (
            <Tooltip text={guardianName} position="bottom">
              <TitleText id="guardianName" variant="heading-h2-medium">
                {guardianName}
              </TitleText>
            </Tooltip>
          ) : (
            <TitleText id="guardianName" variant="heading-h2-medium">
              {guardianName}
            </TitleText>
          )}
          <DebtStatus status={debtStatus} />
        </GuardianNameContainer>
      </GuardianNameContent>
      <ContainerCardInfo>
        <BoxCardInfo>
          <InfoText variant="body-2-regular">CPF</InfoText>
          <Text variant="subtitle-regular">{formatCPF(cpf)}</Text>
        </BoxCardInfo>
        <BoxCardInfo>
          <InfoText variant="body-2-regular">Telefone</InfoText>
          {phone ? (
            <Text variant="subtitle-regular">{formatPhoneNumber(phone)}</Text>
          ) : (
            <InsertLink type="phone" onClick={openEditRegistrationDrawer} />
          )}
        </BoxCardInfo>
        <BoxCardInfo>
          <InfoText variant="body-2-regular">Email</InfoText>
          {email ? (
            <WrapText variant="subtitle-regular">{email}</WrapText>
          ) : (
            <InsertLink type="email" onClick={openEditRegistrationDrawer} />
          )}
        </BoxCardInfo>
      </ContainerCardInfo>
    </Container>
  )
}
