import styled from 'styled-components'
import { Button } from '@gravity/button'
import { Text } from '@gravity/text'

export const StyledButton = styled(Button)``

export const Form = styled.form`
  margin-top: ${({ theme }) => theme.gravity.spacing[8]};
`

export const TextButton = styled(Text)`
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }
`

export const TextSeePaymentDetail = styled(Text)`
  text-decoration: underline;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 150px;
`
