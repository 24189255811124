import { Link, useRouteMatch } from 'react-router-dom'
import { Sidemenu } from '@gravity/sidemenu'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useJWT } from '@/shared/hooks/useJWT'
import { useSidemenuEvents } from '../hooks/useSidemenuEvents'
import { getNavigationURL } from '../utils/getNavigationURL'

export const AnalyticsAndReportsCategoryItems = () => {
  const { school } = useSelectedSchool()
  const { hasPayoutReportAccess } = useJWT()
  const { handleClickDashboardPath, handleClickReportsPath } = useSidemenuEvents()

  const managementDahsboardPath = `/${school?.slug}/painel-de-gestao`
  const reportsPath = getNavigationURL({ schoolSlug: school?.slug, path: '/relatorios' })

  return (
    <>
      {hasPayoutReportAccess && (
        <Sidemenu.CategoryItem
          as={Link}
          text="Painel de gestão"
          active={Boolean(useRouteMatch({ path: managementDahsboardPath }))}
          onClick={handleClickDashboardPath}
          to={managementDahsboardPath}
        />
      )}
      <Sidemenu.CategoryItem
        as={Link}
        text="Relatórios"
        active={Boolean(useRouteMatch({ path: reportsPath }))}
        onClick={handleClickReportsPath}
        to={reportsPath}
      />
    </>
  )
}
