import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import type { AddNewGuardianFormData } from '../types'
import { AddNewGuardianFormSchema, defaultFormData } from '../constants'

export const useAddNewGuardianForm = (defaultValues: AddNewGuardianFormData | null = null) => {
  const form = useForm<AddNewGuardianFormData>({
    mode: 'onChange',
    defaultValues: defaultValues ?? defaultFormData,
    resolver: zodResolver(AddNewGuardianFormSchema),
  })

  const taxId = form.watch('tax_id')
  const isTaxIdValid = taxId.length === 11 && !form.getFieldState('tax_id').error

  return { form, taxId, isTaxIdValid }
}
