import { IconButton } from '@gravity/icon-button'
import { useHistory } from 'react-router-dom'
import { Heading } from '@gravity/heading'
import { Close } from '@gravity/icons'

export const PageHeader = () => {
  const history = useHistory()
  const handleBackBtnClick = history.goBack

  return (
    <div className="flex justify-between items-center p-2 px-4 shadow-2">
      <div />

      <Heading variant="heading-h4-regular">Troca de titularidade de contrato</Heading>

      <div>
        <IconButton variant="solid" size={2} aria-label="Voltar" onClick={handleBackBtnClick}>
          <Close className="fill-colors-text-main-2" />
        </IconButton>
      </div>
    </div>
  )
}
