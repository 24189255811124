import { forwardRef } from 'react'
import { Button } from '@gravity/button'
import { ShoppingCart2Outline } from '@gravity/icons'

import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { useRedirectTo } from '@/shared/hooks/useRedirectTo'

import { SectionContainer } from '../SectionContainer'
import { FinancesContent } from './components/FinancesContent'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventPageName } from '@monorepo/students/models/EventPageName.enum'
import { EventIdentifierName } from '@monorepo/students/models/EventIdentifierName.enum'

type FinancesSectionProps = {
  guardianId: string
  hasError?: boolean
  hasPendingsWithGuardians?: boolean
  hasTwoOrMoreGuardians: boolean
  id: string
  isLoading?: boolean
  onOpenPaymentDialog: () => void
  onRetry?: () => void
  openAmount: number
  overdueAmount: number
  studentId: string
}

export const FinancesSection = forwardRef<HTMLElement, FinancesSectionProps>(
  (
    {
      id,
      overdueAmount,
      openAmount,
      hasPendingsWithGuardians = false,
      isLoading = true,
      hasError = false,
      onRetry,
      hasTwoOrMoreGuardians,
      guardianId,
      studentId,
      onOpenPaymentDialog,
    },
    ref
  ) => {
    const { redirectTo } = useRedirectTo()
    const { isInitialized, eventDispatcherClient } = useEventDispatcher()

    const handleViewPending = () => {
      if (isInitialized) {
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.STUDENT_PAGE,
          name: EventDispatcherEvents.BUTTON_CLICKED,
          pageName: EventPageName.STUDENT_PAGE,
          identifierName: EventIdentifierName.VIEW_STUDENT_PENDING,
        })
      }

      onOpenPaymentDialog()
    }

    const handlePayButtonClick = () => {
      if (isLoading || hasError) return

      if (isInitialized) {
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.STUDENT_PAGE,
          name: EventDispatcherEvents.BUTTON_CLICKED,
          pageName: EventPageName.STUDENT_PAGE,
          identifierName: EventIdentifierName.TO_PAY_BUTTON,
        })
      }

      if (hasTwoOrMoreGuardians) {
        onOpenPaymentDialog()
      } else {
        redirectTo(`/responsaveis/${guardianId}/faturas?student_ids=${studentId}`)
      }
    }

    return (
      <SectionContainer
        id={id}
        ref={ref}
        title="Financeiro"
        action={
          <Button
            variant="outline"
            iconStart={<ShoppingCart2Outline />}
            disabled={isLoading || hasError}
            onClick={() => handlePayButtonClick()}
          >
            Pagar
          </Button>
        }
      >
        <FinancesContent
          hasError={hasError}
          hasPendingsWithGuardians={hasPendingsWithGuardians}
          isLoading={isLoading}
          onRetry={onRetry}
          openAmount={openAmount}
          overdueAmount={overdueAmount}
          onCalloutLinkClick={handleViewPending}
        />
      </SectionContainer>
    )
  }
)
