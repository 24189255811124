import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, GridItem } from '@gravity/grid'
import { Stepper } from '@gravity/stepper'
import { FullScreenHeader } from '@gravity/header'

import { usePaymentCreditCard, useSelectPos } from 'src/escolas/hooks'
import { useAgreement } from 'src/escolas/contexts/agreementContext'
import { CheckoutStatus } from 'src/shared/interfaces'
import { FormOfPayment, SimulationPaymentMethod } from '@/modules/guardians/models/agreement'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useLayout } from '@/shared/hooks/useLayout'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'

import useFlowValidation from './hooks/useFlowValidation'
import InformationTitle from './components/InformationTitle'
import InstallmentNegotiationDialog from './components/InstallmentNegotiationDialog/InstallmentNegotiationDialog'
import AgreementDetailDialog from './components/AgreementDetailDialog'
import useSteps from './hooks/useSteps'
import InformationTitleAgreement from './components/InformationTitleAgreement'
import { useNegotiationV2 } from './hooks/useNegotiationV2'
import { MAX_STEP_WIDTH } from './constants'

export const DefaultNegotiation = () => {
  const { isNegotiationDownPaymentFinished } = useNegotiationV2()
  const { stepComponents, steps, currentStep, getActiveStep } = useSteps()
  const {
    selectedFormOfPayment,
    selectedSimulationPaymentMethod,
    agreementSimulations,
    agreementStatus,
    positionAgreement,
    totalAmount,
    totalCreditCardFee,
    totalAmountWithFee,
    receivables,
    checkoutData,
    openDrawer,
    setOpenDrawer,
  } = useAgreement()
  const { paymentFee } = usePaymentCreditCard()

  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  useLayout({
    enableHeader: false,
    enableSideMenu: !renderNewHeader,
  })

  const { goBack } = useHistory()

  const { school } = useSelectedSchool()
  useSelectPos(school?.id)
  useFlowValidation()

  const stepperContainerVariant = { 783: 'my-0 mx-auto mt-8 max-w-[783px]' }

  const studentNames = useMemo(() => {
    if (receivables) {
      const studentNamesSet = receivables.reduce((acc, curr) => {
        if (!acc.has(curr.student)) {
          acc.add(curr.student)
        }
        return acc
      }, new Set<string>())
      return Array.from(studentNamesSet)
    }
  }, [receivables])

  const studentNamesByAgreement = useMemo(() => {
    if (agreementSimulations) {
      const studentNamesSet = agreementSimulations.reduce((acc, curr) => {
        if (!acc.has(curr.receivables[0].student)) {
          acc.add(curr.receivables[0].student)
        }
        return acc
      }, new Set<string>())
      return Array.from(studentNamesSet)
    }
  }, [agreementSimulations])

  const combinedReceivablesByAgreement = useMemo(() => {
    const combinedReceivables = agreementSimulations.reduce((acc, curr) => {
      acc.push(...curr.receivables)
      return acc
    }, [])
    return combinedReceivables
  }, [agreementSimulations])

  function closeDrawer() {
    setOpenDrawer(false)
  }

  function shouldShowInformationTitle() {
    if (currentStep === 0) return false
    if (currentStep === 2) return false
    if (currentStep === 1) return false
    if (currentStep === 4 && checkoutData?.status === CheckoutStatus.FAILURE_TO_CONNECT)
      return false

    return true
  }

  const isPOSSelected = (formOfpayment?: FormOfPayment): boolean =>
    Boolean(
      formOfpayment &&
        (formOfpayment === FormOfPayment.POS || formOfpayment === FormOfPayment.POS_V2)
    )

  const getCurrentValues = () => {
    const selectedAgreement = agreementSimulations[positionAgreement]
    if (isPOSSelected(selectedFormOfPayment)) {
      if (
        getActiveStep(currentStep) === 2 &&
        selectedSimulationPaymentMethod === SimulationPaymentMethod.CREDIT_CARD
      ) {
        return {
          currentTotalAmount: totalAmountWithFee,
          currentTotalCreditCardFee: paymentFee?.enabled ? totalCreditCardFee : 0,
        }
      }
      return {
        currentTotalAmount: totalAmount,
        currentTotalCreditCardFee: 0,
      }
    } else {
      if (
        getActiveStep(currentStep) === 2 &&
        selectedSimulationPaymentMethod === SimulationPaymentMethod.CREDIT_CARD
      ) {
        const creditCardFee = (paymentFee?.enabled ? selectedAgreement?.credit_card_fee : 0) ?? 0
        return {
          currentTotalAmount: creditCardFee + selectedAgreement?.total_amount,
          currentTotalCreditCardFee: creditCardFee,
        }
      }
      return {
        currentTotalAmount: selectedAgreement?.total_amount,
        currentTotalCreditCardFee: 0,
      }
    }
  }

  return (
    <>
      {renderNewHeader ? (
        <FullScreenHeader
          className="fixed left-0 top-0 z-[99]"
          title="Pagamento"
          onClose={goBack}
        />
      ) : (
        ''
      )}

      <div className={`flex flex-col items-center ${renderNewHeader ? 'mt-18' : 'mt-0'}`}>
        <Grid>
          <GridItem xl={12} lg={12} md={12} sm={8} xs={4}>
            <div className={stepperContainerVariant[MAX_STEP_WIDTH]}>
              <div className="w-fit m-auto my-4">
                <Stepper.Root activeStep={getActiveStep(currentStep)}>
                  {steps.map((step, index) => (
                    <Stepper.Step key={index} step={index}>
                      <Stepper.StatusIcon />
                      <Stepper.Title>{step}</Stepper.Title>
                    </Stepper.Step>
                  ))}
                </Stepper.Root>
              </div>

              {shouldShowInformationTitle() &&
                (isPOSSelected(selectedFormOfPayment) ? (
                  <InformationTitle
                    students={
                      selectedFormOfPayment && selectedFormOfPayment === FormOfPayment.POS
                        ? studentNames
                        : studentNamesByAgreement
                    }
                    receivables={
                      selectedFormOfPayment && selectedFormOfPayment === FormOfPayment.POS
                        ? receivables
                        : combinedReceivablesByAgreement
                    }
                    handleClick={() => setOpenDrawer(true)}
                    totalAmount={getCurrentValues().currentTotalAmount}
                  />
                ) : (
                  <InformationTitleAgreement
                    totalAmount={getCurrentValues().currentTotalAmount}
                    positionAgreement={positionAgreement}
                    agreementSimulations={agreementSimulations}
                    negotiationID={
                      isNegotiationDownPaymentFinished
                        ? agreementStatus[positionAgreement].negotiationId
                        : undefined
                    }
                    handleClick={() => setOpenDrawer(true)}
                  />
                ))}

              {stepComponents[currentStep]}
            </div>
          </GridItem>

          {isPOSSelected(selectedFormOfPayment) ? (
            <InstallmentNegotiationDialog
              isOpen={openDrawer}
              onClose={closeDrawer}
              combinedReceivables={receivables}
              creditCardFee={getCurrentValues().currentTotalCreditCardFee}
              totalAmount={getCurrentValues().currentTotalAmount}
            />
          ) : (
            <AgreementDetailDialog
              isOpen={openDrawer}
              onClose={closeDrawer}
              creditCardFee={getCurrentValues().currentTotalCreditCardFee}
              totalAmount={getCurrentValues().currentTotalAmount}
            />
          )}
        </Grid>
      </div>
    </>
  )
}

export default DefaultNegotiation
