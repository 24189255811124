import { Skeleton } from '@gravity/skeleton'
import {
  RowContainer,
  PairSkeletonContainer,
  RowFlexContainer,
  ContainerCard,
  GroupSkeletonContainer,
  SkeletonStyled,
  SkeletonTitlePage,
  RowFlexEndContainer,
  HeaderSkeletonForm,
  RowGroupInput,
  BackNavigator,
} from './styles'
import { Card } from '@gravity/card'
import { Separator } from '@gravity/separator'
import { DefaultPageTemplate } from '../../../../templates/DefaultPageTemplate'

export const LoadingSimulation = ({
  previousRoute,
  title,
}: {
  previousRoute: string
  title: string
}) => {
  return (
    <DefaultPageTemplate title={title} previousRoute={previousRoute}>
      <>
        <SkeletonTitlePage radius={8} width={400} height={40} />

        <RowContainer>
          <Card>
            <ContainerCard>
              <PairSkeletonContainer>
                <Skeleton radius={8} fullWidth />
                <Skeleton radius={8} fullWidth />
              </PairSkeletonContainer>

              <Separator color="neutral-2" orientation="horizontal" />

              <GroupSkeletonContainer>
                <Skeleton radius={8} fullWidth />
                <Skeleton radius={8} fullWidth />
                <Skeleton radius={8} fullWidth />
                <Skeleton radius={8} fullWidth />
                <Skeleton radius={8} fullWidth />
              </GroupSkeletonContainer>
            </ContainerCard>
          </Card>
        </RowContainer>

        <SkeletonStyled radius={8} width={453} />

        <RowFlexContainer>
          <Skeleton radius={8} width={179} height={40} />
          <Skeleton radius={8} width={195} height={40} />
        </RowFlexContainer>
      </>
    </DefaultPageTemplate>
  )
}

export const LoadingSimulationForm = ({
  previousRoute,
  title,
}: {
  previousRoute: string
  title: string
}) => (
  <DefaultPageTemplate title={title} previousRoute={previousRoute}>
    <>
      <BackNavigator>
        <Skeleton radius={8} width={79} height={24} />
      </BackNavigator>
      <HeaderSkeletonForm>
        <SkeletonTitlePage radius={8} width={343} height={40} />
        <Skeleton radius={8} fullWidth height={48} />
      </HeaderSkeletonForm>
      <RowContainer>
        <RowGroupInput>
          <Skeleton radius={8} width={330} />
          <Skeleton radius={8} fullWidth height={40} />
        </RowGroupInput>
        <RowGroupInput>
          <Skeleton radius={8} width={263} />
          <Skeleton radius={8} fullWidth height={40} />
          <Skeleton radius={8} width={388} height={20} />
        </RowGroupInput>

        <RowFlexEndContainer>
          <Skeleton radius={8} width={85} height={40} />
        </RowFlexEndContainer>
      </RowContainer>
    </>
  </DefaultPageTemplate>
)
