import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useApiClient } from '../../useApiClient'
import { irregularitiesService } from '@/shared/services/irregularities'
import {
  IrregularityBigNumbersRequest,
  IrregularityBigNumbersResponse,
} from '@/shared/services/irregularities/types'

const irregularityBigNumbersKey = 'irregularity-big-numbers-key'

export const useIrregularityBigNumbersQuery = (
  params: IrregularityBigNumbersRequest,
  options?: UseQueryOptions<IrregularityBigNumbersResponse>
) => {
  const { apiClient } = useApiClient()
  const service = irregularitiesService(apiClient.privateApi)
  return useQuery<IrregularityBigNumbersResponse>(
    [irregularityBigNumbersKey, params],
    () => service.fetchIrregularityBigNumbers(params),
    options
  )
}
