import { Switch } from '@gravity/switch'
import { Text } from '@gravity/text'

import { useState } from 'react'

interface SwitchThemeProps {
  gravityTheme: 'standard' | 'ngv'
  updateGravityTheme: (theme: 'standard' | 'ngv') => void
}

export const SwitchTheme = ({ updateGravityTheme, gravityTheme }: SwitchThemeProps) => {
  const [checked, setChecked] = useState(gravityTheme === 'ngv')

  const handleClick = (isChecked: boolean) => {
    setChecked(isChecked)
    updateGravityTheme(isChecked ? 'ngv' : 'standard')
  }

  return (
    <>
      <Text variant="overline">Standard</Text>
      <Switch
        defaultChecked={gravityTheme === 'ngv'}
        checked={checked}
        onCheckedChange={handleClick}
      />
      <Text variant="overline">NGV</Text>
    </>
  )
}
