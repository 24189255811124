import { InstallmentDrawerProps } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentsDrawerContainerV2'
import { DrawerVariationsProps } from '@/modules/guardians/InstallmentsDrawerContainer/variations'
import { RenegotiationDialog } from '../../RenegotiationDialog'

export const renegotiation = ({
  installment: installmentInfo,
  onCloseDrawer,
}: InstallmentDrawerProps): DrawerVariationsProps => {
  const fullContent = (
    <RenegotiationDialog installment={installmentInfo} onCloseDrawer={onCloseDrawer} />
  )

  return {
    isDialog: true,
    fullContent,
  }
}
