import { Button } from '@gravity/button'
import { useToast } from '@gravity/toast'
import { GitMerge } from '@gravity/icons'
import useCheckoutCart from '@/modules/guardians/hooks/useCheckoutCart'
import { useCanAgglutinate } from '../../hooks/useCanAgglutinate'
import { Installment } from '../../types'
import { useEffect, useState } from 'react'
import { MIN_RECEIVABLES_ALLOWED, ReceivableType } from '../../constants'
import { AgglutinationErrorMap } from './constants'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

type AgglutinationProps = {
  setShowAgglutinationDialog: (value: boolean) => void
}

export const Agglutination = ({ setShowAgglutinationDialog }: AgglutinationProps) => {
  const [receivables, setReceivables] = useState<uuid[]>([])
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const { toast } = useToast()

  const { school } = useSelectedSchool()
  const schoolId = school?.id
  const { checkoutCart } = useCheckoutCart()

  const { data, isCheckCanAgglutinateFetched } = useCanAgglutinate({
    receivable_ids: receivables,
    school_id: schoolId ?? '',
  })

  const handleAgglutinationCheck = () => {
    const receivables = checkoutCart?.map((installment: Installment) => installment.receivable_id)

    if (checkoutCart?.find((installment: Installment) => installment.is_from_credit_card_flow)) {
      return toast({ type: 'error', title: AgglutinationErrorMap.CREDIT_CARD_RECEIVABLES })
    }

    if (receivables?.length < MIN_RECEIVABLES_ALLOWED) {
      return toast({ type: 'error', title: AgglutinationErrorMap.LESS_THAN_TWO_RECEIVABLES })
    }

    if (
      checkoutCart?.find(
        (installment: Installment) => installment.receivable_type === ReceivableType.ENROLLMENT
      )
    ) {
      return toast({ type: 'error', title: AgglutinationErrorMap.ONLY_TUITION_RECEIVABLES_ALLOWED })
    }

    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.INSTALLMENTS_LIST,
        entity: EventDispatcherEntities.AGGLUTINATION,
        action: 'click',
        customProperties: {
          $name: 'Aglutinar',
          $receivables_ids: receivables,
        },
      })
    setReceivables(receivables as uuid[])
  }

  useEffect(() => {
    if (isCheckCanAgglutinateFetched) {
      if (data?.can_agglutinate) return setShowAgglutinationDialog(true)

      const title = AgglutinationErrorMap[data?.reason as keyof typeof AgglutinationErrorMap]
      toast({
        type: 'error',
        title: title ?? 'Aconteceu um erro inesperado',
        description: !title
          ? 'Por favor, tente novamente ou entre em contato com a equipe responsável.'
          : '',
      })
    }
  }, [isCheckCanAgglutinateFetched, receivables])

  return (
    <Button
      variant="ghost"
      aria-label="aglutinar"
      onClick={handleAgglutinationCheck}
      iconStart={<GitMerge />}
    >
      Aglutinar
    </Button>
  )
}
