import { Option, Select } from '@gravity/select'
import { ListOptionInstallments } from './ListOptionInstallments'
import { StyledInput } from './styles'
import type { InputInstallmentsProps } from './types'

const InputInstallments = ({
  maxInstallment,
  totalAmount,
  handleValue,
  selectedValue,
}: InputInstallmentsProps) => {
  return (
    <StyledInput marginRight={32}>
      <Select
        size={3}
        variant="surface"
        name="installments"
        label="Número de parcelas"
        aria-label="Número de parcelas"
        placeholder="Informe o número de parcelas"
        value={selectedValue.toString()}
        onValueChange={value => {
          handleValue(Number(value))
        }}
        options={ListOptionInstallments(maxInstallment, totalAmount) as Option[]}
      />
    </StyledInput>
  )
}

export default InputInstallments
