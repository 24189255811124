import { Table } from '@gravity/table'

export const InstallmentTableLoading = () => (
  <>
    <Table.Row>
      <Table.CustomCell />
      <Table.LoadingCell />
      <Table.LoadingCell />
      <Table.LoadingCell />
      <Table.LoadingCell />
      <Table.LoadingCell />
      <Table.LoadingCell />
      <Table.LoadingCell />
    </Table.Row>

    <Table.Row>
      <Table.CustomCell />
      <Table.LoadingCell />
      <Table.LoadingCell />
      <Table.LoadingCell />
      <Table.LoadingCell />
      <Table.LoadingCell />
      <Table.LoadingCell />
      <Table.LoadingCell />
    </Table.Row>

    <Table.Row>
      <Table.CustomCell />
      <Table.LoadingCell />
      <Table.LoadingCell />
      <Table.LoadingCell />
      <Table.LoadingCell />
      <Table.LoadingCell />
      <Table.LoadingCell />
      <Table.LoadingCell />
    </Table.Row>
  </>
)
