import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import * as S from './styles'
import type { FooterProps } from './types'

const Footer = ({
  enableSubmit = false,
  onClickGoBack,
  onClick,
  isLoading,
  type = 'submit',
  hideBackButton = false,
  label = 'Registrar recebimento',
}: FooterProps) => {
  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  return (
    <S.FooterStyled
      $renderNewHeader={renderNewHeader}
      ghostButton={
        !hideBackButton && onClickGoBack
          ? {
              onClick: onClickGoBack,
              label: 'Cancelar',
              type: 'button',
            }
          : undefined
      }
      primaryButton={{
        disabled: !enableSubmit,
        loading: isLoading,
        onClick: onClick,
        label: label,
        type,
      }}
    />
  )
}

export default Footer
