import { Select, Option } from '@gravity/select'

type CustomSelectProps = {
  error?: boolean
  errorMessage?: string
  label: string
  name: string
  onChange: (name: string, value: string) => void
  options: Option[]
  placeholder?: string
  value?: string
}

export const CustomSelect = ({
  error,
  errorMessage,
  label,
  name,
  onChange,
  options,
  placeholder,
  value,
}: CustomSelectProps) => {
  return (
    <Select
      fullWidth
      size={3}
      name={name}
      variant="surface"
      label={label}
      placeholder={placeholder}
      options={options}
      value={value}
      onValueChange={v => onChange(name, v)}
      enableHelperTextSlot
      hasError={error}
      helperText={error ? errorMessage : ''}
    />
  )
}
