import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { BatchSummaryResponse } from '../services/types/onboardingBatch'
import { useApiClient } from '@/shared/hooks'
import { onboardingAPI } from '../services/onboardingApi'

export const GUARDIAN_LIST_QUERY_KEY = 'getGuardians'

export type BatchSummaryParams = {
  batchID: uuid
}

export const useBatchSummaryQuery = (
  params: BatchSummaryParams,
  options?: UseQueryOptions<BatchSummaryResponse>
) => {
  const { apiClient } = useApiClient()
  const api = onboardingAPI(apiClient.privateApi)

  return useQuery<BatchSummaryResponse>(
    ['batchSummaryQuery', params],
    () => api.getBatchSummary(params),
    {
      // Caching this data might result in outdated or inconsistent data being used across different parts of the application, leading to potential errors or unexpected behavior.
      cacheTime: 0,
      staleTime: 0,
      ...options,
    }
  )
}
