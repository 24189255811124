import { Controller, UseFormReturn, ValidateResult } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import dayjs from 'dayjs'
import { DatepickerPrimitives as Datepicker } from '@gravity/datepicker'
import { Text } from '@gravity/text'
import { TextField } from '@gravity/text-field'
import { Heading } from '@gravity/heading'

import { formatCentsToReal, formatDate, formatRealToCents } from '@/shared/utils'

import { InputErrorText, InputWrapper, Row } from '../../../styles'
import { EnrollmentCampaignFormProps } from '../../types'
import * as Styled from './styles'
import { DeleteBinOutline } from '@gravity/icons'

type Props = {
  disableRemoveButton: boolean
  form: UseFormReturn<EnrollmentCampaignFormProps, any>
  index: number
  onClickRemove: () => void
  onDateChange: (date: Date) => void
  referenceYear: number
  validateOfferDueDate: (date: Date | null) => ValidateResult
}

export const OfferItem = ({
  referenceYear,
  form,
  index,
  disableRemoveButton,
  onClickRemove,
  onDateChange,
  validateOfferDueDate,
}: Props) => {
  const minDate = dayjs().add(1, 'day')
  const maxDate = dayjs().set('year', referenceYear).set('month', 11).endOf('month')
  const title = `Condição de pagamento ${index + 1}`
  const offerTitleId = `offer-title-${index}`

  return (
    <div role="listitem" aria-labelledby={offerTitleId}>
      <div className="flex justify-between mt-8">
        <Heading id={offerTitleId} variant="heading-h4-medium">
          {title}
        </Heading>
        <Styled.DangerIconButton
          variant="soft"
          disabled={disableRemoveButton}
          onClick={onClickRemove}
        >
          <DeleteBinOutline />
        </Styled.DangerIconButton>
      </div>
      <Row>
        <Controller
          rules={{ required: true, min: 100 }}
          control={form.control}
          name={`offers.${index}.amount` as const}
          render={({ field, fieldState }) => (
            <InputWrapper hasError={!!fieldState.error?.type}>
              <Text variant="subtitle-medium">Qual valor da pré-matrícula?</Text>
              <NumberFormat
                placeholder="Digite o valor"
                customInput={TextField}
                format={formatCentsToReal}
                min={0}
                size={3}
                label=""
                value={field.value}
                variant="outlined"
                error={Boolean(fieldState.error?.type)}
                helperText={fieldState.error?.type && 'O valor deve ser maior que R$ 1,00'}
                onChange={event => {
                  field.onChange(Number(formatRealToCents(event.target.value)))
                }}
              />
            </InputWrapper>
          )}
        />

        <Controller
          rules={{ validate: date => validateOfferDueDate(date as Date) }}
          control={form.control}
          name={`offers.${index}.expirationDate` as const}
          render={({ field, fieldState }) => (
            <InputWrapper hasError={Boolean(fieldState.error?.type)}>
              <Datepicker.Root>
                <Text id={`offer-${index}-date-label`} variant="subtitle-medium">
                  Qual a data de validade?
                </Text>
                <Styled.DatepickerTrigger
                  size={3}
                  aria-labelledby={`offer-${index}-date-label`}
                  placeholder="Selecione a data"
                >
                  {field.value ? formatDate(field.value, 'DD/MM/YYYY') : null}
                </Styled.DatepickerTrigger>
                <Datepicker.Calendar
                  value={field.value}
                  minDate={minDate.toDate()}
                  maxDate={maxDate.toDate()}
                  minDetail="decade"
                  onChange={date => {
                    field.onChange(date)
                    onDateChange(date as Date)
                  }}
                />
                <InputErrorText variant="caption-regular">
                  {fieldState.error?.message ?? ''}
                </InputErrorText>
              </Datepicker.Root>
            </InputWrapper>
          )}
        />
      </Row>
    </div>
  )
}
