import type { AgreementSimulationCombined } from '@/modules/guardians/models/agreement'
import AgreementCard from '../AgreementCard'
import { StyledAddIcon, WrapperIcon, WrapperList } from './styles'

export interface AgreementListProps {
  agreements: AgreementSimulationCombined[]
}

const AgreementList = ({ agreements }: AgreementListProps) => (
  <WrapperList>
    {agreements.map((agreement, index) => (
      <div key={`${index + 1}-payment`}>
        {index / 2 !== 0 && (
          <WrapperIcon>
            <StyledAddIcon />
          </WrapperIcon>
        )}

        <AgreementCard agreement={agreement} index={index} />
      </div>
    ))}
  </WrapperList>
)

export default AgreementList
