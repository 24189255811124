import { Controller } from 'react-hook-form'
import { Checkbox } from '@gravity/checkbox'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'
import type { PreContractDisclaimerProps } from './types'

export const PreContractDisclaimer = ({ form }: PreContractDisclaimerProps) => {
  const { control } = form

  return (
    <div className="mt-12">
      <Heading variant="heading-h3-medium">Termos e condições</Heading>
      <div className="mt-4 mb-12">
        <Controller
          rules={{ required: true, validate: v => v }}
          defaultValue={false}
          name="pre_contract_disclaimer_check"
          control={control}
          render={({ field: { value, ...rest }, fieldState: { error } }) => (
            <div>
              <div className="flex items-start">
                <div className="mt-2 mr-2">
                  <Checkbox
                    {...rest}
                    checked={value}
                    // eslint-disable-next-line react/jsx-handler-names
                    onCheckedChange={rest.onChange}
                    data-testid="pre-contract-disclaimer-checkbox"
                    size={2}
                  />
                </div>
                <Text variant="body-1-regular" className="mt-1 pl-1">
                  Ao prosseguir, confirmo que todas as informações inseridas são autênticas e
                  vinculadas a um <strong>aluno regularmente matriculado</strong>. O fornecimento de
                  dados falsos ou inconsistentes poderá resultar na rescisão da parceria, suspensão
                  de benefícios e responsabilização conforme previsto em contrato.
                </Text>
              </div>
              {Boolean(error?.type) && (
                <div className="mt-2 ml-9">
                  <Text
                    className="text-colors-text-semantic-colors-error-1"
                    variant="caption-regular"
                  >
                    Aceite os termos e condições para criar o contrato.
                  </Text>
                </div>
              )}
            </div>
          )}
        />
      </div>
    </div>
  )
}

export default PreContractDisclaimer
