import type { RouteDefinition } from '@/shared/core/router'
import { SendMessagePage } from '../pages/SendMessage'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { CreateMessagePage } from '../pages/CreateMessage'

export const routes: RouteDefinition[] = [
  {
    key: 'MESSAGES_CREATE',
    path: '/nova/mensagem',
    component: SendMessagePage,
    exact: true,
    canAccess: () => {
      const canAccessScreen = useUnleashFlag(UnleashFlags.CPU_468_ENABLE_MESSAGES_PAGE)
      return canAccessScreen
    },
  },
  {
    key: 'MESSAGES_COMMUNICATION',
    path: '/comunicados',
    component: CreateMessagePage,
    exact: true,
    canAccess: () => {
      const canAccessScreen = useUnleashFlag(UnleashFlags.CPU_468_ENABLE_MESSAGES_PAGE)
      return canAccessScreen
    },
  },
]
