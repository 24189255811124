import { Skeleton } from '@gravity/skeleton'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.gravity.spacing['6']};
  margin-top: ${({ theme }) => theme.gravity.spacing['8']};
  margin-bottom: ${({ theme }) => theme.gravity.spacing['8']};
`

export const SplitRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: ${({ theme }) => theme.gravity.spacing['4']};
`

export const AccordionsLoadingState = () => {
  return (
    <Wrapper>
      <Skeleton height={300} fullWidth />

      {Array.from({ length: 8 }, (_, index) => (
        <Skeleton key={index} height={86} fullWidth />
      ))}
    </Wrapper>
  )
}

export const TablesLoadingState = () => {
  return (
    <Wrapper>
      <Skeleton height={64} width={273} />
      <Skeleton height={64} fullWidth />
      <Skeleton height={300} fullWidth />
      <Skeleton height={300} fullWidth />
      <Skeleton height={300} fullWidth />
    </Wrapper>
  )
}
