import { Accordion } from '@gravity/accordion'
import { Checkbox } from '@gravity/checkbox'
import { CloseCircleOutline } from '@gravity/icons'
import { Select } from '@gravity/select'
import { TextField } from '@gravity/text-field'
import { EditStudentFormProps } from '@monorepo/onboarding/v2/hooks/useEditStudentForm'
import { useFormContext } from 'react-hook-form'
import { REFERENCE_YEAR_OPTIONS } from './referenceYearOptions'
import { useState } from 'react'

type ProductAccordionProps = {
  index: number
}

export const ProductAccordion = ({ index }: ProductAccordionProps) => {
  const { register, setValue, watch } = useFormContext<EditStudentFormProps>()

  const [noGrade, setNoGrade] = useState<boolean>(false)

  return (
    <Accordion.Root key={`product-${index}`} type="single" collapsible>
      <Accordion.Item value={`product-${index}`}>
        <Accordion.Trigger
          descriptionList={[
            {
              text: 'Correções pendentes',
              iconStart: (
                // Accordion overrides styles and pass size="16px", which is incompatible with @gravity/icons
                <span>
                  <CloseCircleOutline size="xs" />
                </span>
              ),
            },
          ]}
        >
          {`Produto ${index + 1}`}
        </Accordion.Trigger>

        <Accordion.Content>
          <div className="flex flex-col gap-6">
            <div className="flex flex-row gap-4">
              <TextField
                fullWidth
                size={2}
                label="Curso"
                {...register(`products.${index}.course`)}
              />

              <Select
                fullWidth
                size={2}
                variant="surface"
                label="Ciclo letivo"
                value={watch(`products.${index}.reference_year`)}
                onValueChange={value => {
                  setValue(`products.${index}.reference_year`, value, { shouldDirty: true })
                }}
                options={REFERENCE_YEAR_OPTIONS}
                {...register(`products.${index}.reference_year`)}
              />
            </div>

            <div className="flex flex-row gap-4">
              <TextField
                fullWidth
                size={2}
                label="Série"
                disabled={noGrade}
                {...register(`products.${index}.grade`)}
              />

              <div className="mt-10 w-full">
                <Checkbox
                  size={1}
                  text="O produto não possui série"
                  name={`products.${index}.no_grade`}
                  checked={noGrade}
                  onCheckedChange={checked => {
                    if (checked === 'indeterminate') return

                    setNoGrade(checked)
                    setValue(`products.${index}.grade`, '')
                  }}
                />
              </div>
            </div>
          </div>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  )
}
