import ChangePaymentDialog from '@/escolas/components/modal/ChangePaymentMethodDialog'
import { useDrawerActionsInstallment } from '@/modules/guardians/InstallmentsDrawerContainer/components/InstallmentDrawerActions/hooks/useDrawerActionsInstallment'
import { parseInstallmentToReceivable } from '@/modules/guardians/utils/parseInstallmentToReceivable'
import {
  FailureFeedbackContent,
  FailureFeedbackDialog,
} from '@/shared/components/ConfirmationDialog'
import { useInstallmentDrawerContext } from '@/modules/guardians/contexts/InstallmentDrawerContext'

export interface InstallmentActionDrawerProps {
  onCloseDrawer: () => void
}

export const InstallmentDrawerActions = ({
  onCloseDrawer,
  children,
}: React.PropsWithChildren<InstallmentActionDrawerProps>) => {
  const {
    installmentDrawerInfo,
    showFailedDialog,
    setShowChangePaymentModal,
    showChangePaymentModal,
    setShowFailedDialog,
  } = useInstallmentDrawerContext()

  const { handleSuccessChangePaymentMethod } = useDrawerActionsInstallment(onCloseDrawer)

  if (!installmentDrawerInfo.status) {
    return null
  }

  const receivable = parseInstallmentToReceivable(installmentDrawerInfo)
  const isPayableByCreditCard = installmentDrawerInfo.available_payment_methods?.includes(
    'CREDIT_CARD'
  )
  const changePaymentMethodTitle = isPayableByCreditCard
    ? 'Alterar para pagamento em boleto'
    : 'Alterar para pagamento em cartão'

  return (
    <>
      <FailureFeedbackDialog
        buttonLabel="Voltar"
        isVisible={showFailedDialog}
        onClose={() => setShowFailedDialog(false)}
        submitHandler={() => setShowFailedDialog(false)}
      >
        <FailureFeedbackContent />
      </FailureFeedbackDialog>

      <ChangePaymentDialog
        isVisible={showChangePaymentModal}
        buttonLabel="Confirmar alteração"
        onClose={() => setShowChangePaymentModal(false)}
        backButtonLabel="Voltar"
        title={changePaymentMethodTitle}
        receivableId={receivable?.id}
        setShowChangePaymentModal={setShowChangePaymentModal}
        isPayableByCreditCard={isPayableByCreditCard}
        replaceReceivables={handleSuccessChangePaymentMethod}
        setShowFailedDialog={setShowFailedDialog}
      />

      {!showChangePaymentModal && !showFailedDialog && children}
    </>
  )
}
