import { useState } from 'react'

export type DrawerType = 'summary' | 'studentDetails'

type HookValue = {
  closeAllDrawers: () => void
  isPayoutSummaryDrawerOpen: boolean
  isStudentDetailsDrawerOpen: boolean
  setDrawerOpen: (drawer: DrawerType, isOpen: boolean) => void
}

export function useIsaacPayPayoutReportDrawers(): HookValue {
  const [isPayoutSummaryDrawerOpen, setIsPayoutSummaryDrawerOpen] = useState(false)
  const [isStudentDetailsDrawerOpen, setIsStudentDetailsDrawerOpen] = useState(false)

  function setDrawerOpen(drawer: DrawerType, isOpen: boolean) {
    if (drawer === 'summary') setIsPayoutSummaryDrawerOpen(isOpen)
    if (drawer === 'studentDetails') setIsStudentDetailsDrawerOpen(isOpen)
  }

  function closeAllDrawers() {
    setIsPayoutSummaryDrawerOpen(false)
    setIsStudentDetailsDrawerOpen(false)
  }

  return {
    isPayoutSummaryDrawerOpen,
    isStudentDetailsDrawerOpen,
    setDrawerOpen,
    closeAllDrawers,
  }
}
