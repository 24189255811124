import { useSchoolEnrollmentCycles } from '@/shared/hooks/useSchoolEnrollmentCycles'
import { useSchoolEnrollmentCycleContext } from '@monorepo/enrollment/contexts/SchoolEnrollmentContext'

export const useAcademicPeriod = () => {
  const { isEnrollmentCycleActive } = useSchoolEnrollmentCycles()

  const {
    schoolEnrollmentCycles,
    selectedEnrollmentCycle,
    updateSelectedEnrollmentCycle,
  } = useSchoolEnrollmentCycleContext()

  const cycles = schoolEnrollmentCycles ?? []
  const academicPeriods = cycles.map(enrollmentCycle => enrollmentCycle.academic_period)

  const enabledAcademicPeriods = cycles
    .filter(isEnrollmentCycleActive)
    .map(enrollmentCycle => enrollmentCycle.academic_period)

  const selectedAcademicPeriod = selectedEnrollmentCycle?.academic_period

  const setSelectedAcademicPeriod = (academicPeriodID: uuid) => {
    const selectedEnrollment = schoolEnrollmentCycles.find(
      schoolEnrollmentCycle => schoolEnrollmentCycle.academic_period_id === academicPeriodID
    )

    if (selectedEnrollment) {
      updateSelectedEnrollmentCycle(selectedEnrollment)
    }
  }

  return {
    academicPeriods,
    enabledAcademicPeriods,
    selectedAcademicPeriod,
    schoolEnrollmentCycles,
    setSelectedAcademicPeriod,
  }
}
