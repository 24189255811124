import { Heading } from '@gravity/heading'
import { useGetOnboardingBatch } from '../../hooks/useFetchOnboardingBatches'
import { useParams } from 'react-router-dom'
import { OnboardingBatch } from '../../services/types/onboardingBatch'
import { FullSkeleton } from '../Loading'
import { GenericError } from '../components/GenericError'
import { ProgressBar } from '@gravity/progress-bar'

interface BatchProgressProps {
  style?: React.CSSProperties
}

export const BatchProgress = (props: BatchProgressProps) => {
  const { batchID } = useParams<{ batchID: string }>()

  const { data, isLoading, isError } = useGetOnboardingBatch(
    { batchID },
    {
      onError(error) {
        console.error(error)
      },
      refetchInterval: (data: OnboardingBatch | undefined) => {
        return data?.status !== 'DONE' ? 2000 : false
      },
    }
  )

  if (isError) return <GenericError />

  if (isLoading || !data?.progress) {
    return <FullSkeleton />
  }

  const contractsProcessed = data.progress.FAIL + data.progress.SUCCESS + data.progress.SKIP

  const totalContracts: number = Object.values(data.progress).reduce(
    (acc: number, value: number) => acc + value,
    0
  )

  return (
    <div className="flex flex-col items-center gap-8" {...props}>
      <Heading variant="heading-h3-regular">
        {`${contractsProcessed} contratos migrados de ${totalContracts}`}
      </Heading>
      <ProgressBar value={contractsProcessed} max={totalContracts} className="w-[353px]" />
    </div>
  )
}
