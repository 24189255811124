import { StepComponentProps } from '@/modules/guardians/Negotiation/types'
import { CheckoutStatus } from 'src/shared/interfaces'

import PaymentTable from './components/PaymentTable'
import * as S from './styles'
import { useSendCheckoutEvent } from '../../hooks/useSendCheckoutEvents'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { Callout } from '@gravity/callout'
import StepFooter from '../../components/StepFooter'

const Conclusion = ({ onFinish, conclusionInfo, helpButtonEntry }: StepComponentProps) => {
  if (!conclusionInfo || !conclusionInfo.receivableId) return null

  const sendCheckoutEvent = useSendCheckoutEvent()

  const sendGenerateReceiptEvent = () => {
    sendCheckoutEvent(EventDispatcherEntities.CHECKOUT_SUCCESSFUL_TRANSACTION_GENERATE_RECEIPT, {
      name: 'Gerar recibo',
    })
  }
  return (
    <S.Wrapper>
      <Callout text="Pagamento realizado com sucesso!" />
      <PaymentTable
        idForReceipt={conclusionInfo.receivableId}
        amount={conclusionInfo.amount}
        paymentMethod={conclusionInfo.paymentMethod}
        status={CheckoutStatus.FINISHED}
        onSuccess={sendGenerateReceiptEvent}
        installmentsNumber={conclusionInfo.installmentsNumberSelected}
      />
      <StepFooter
        showHelpButton
        helpButtonEntry={helpButtonEntry}
        stepName="conclusion"
        btnPrimary={{
          onClick: onFinish,
          hideIcon: true,
          label: 'Concluir',
        }}
      />
    </S.Wrapper>
  )
}

export default Conclusion
