import styled from 'styled-components'

import { MAX_STEP_WIDTH } from '../../constants'

export const Wrapper = styled.div`
  padding: 32px 24px;
  max-width: ${MAX_STEP_WIDTH}px;
  width: ${MAX_STEP_WIDTH}px;
  margin: ${({ theme }) => theme.gravity.spacing[1]} auto
    ${({ theme }) => theme.gravity.spacing[20]};
`

export const CardSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  margin-top: ${({ theme }) => theme.gravity.spacing[1]};
  margin-bottom: ${({ theme }) => theme.gravity.spacing[1]};
`
