import styled from 'styled-components'
import { Text } from '@gravity/text'

export const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.gravity.spacing[1]};
  padding: ${({ theme }) => theme.gravity.spacing[1]};
  width: 100%;
  border: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
`

export const WrapperContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const WrapperTitle = styled.div`
  display: flex;
  flex-direction: column;
  text-align: initial;
`

export const Title = styled(Text)`
  margin-bottom: ${({ theme }) => theme.gravity.spacing[1]};
  font-size: 1.25rem;
`
export const SubTitle = styled(Text)``

export const ButtonLabel = styled(Text)`
  color: ${props => props.theme.gravity.colors['colors-text-main-1']};
  margin-right: ${({ theme }) => theme.gravity.spacing[1]};
`

export const CollapseContent = styled.div``
