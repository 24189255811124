import { useHistory, useParams } from 'react-router-dom'
import { FullScreenHeader } from '@gravity/header'
import { Grid, GridItem } from '@gravity/grid'
import { Dialog } from '@gravity/dialog'
import { Text } from '@gravity/text'
import { Button } from '@gravity/button'

import { useLayout } from '@/shared/hooks/useLayout'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { ErrorDialog } from '@/shared/components/ErrorDialog'
import { useGetGuardian } from '@/shared/hooks/queries/guardian'

import { GuardianFormFields } from './components/GuardianFormFields'
import { GuardianFormFieldsLoader } from './components/GuardianFormFieldsLoader'
import { useFormSubmit } from './hooks/useFormSubmit'

export const EditGuardianData = () => {
  const history = useHistory()
  useLayout({ enableSideMenu: false, enableHeader: false })

  const { guardianId } = useParams<{ guardianId: uuid }>()
  const { school, hasErrorOnFetchSchool } = useSelectedSchool()

  const getGuardianQuery = useGetGuardian(
    { guardianId: guardianId, schoolId: school?.id ?? '' },
    { enabled: !!school }
  )

  const {
    isLoading: isSubmitingData,
    handleClickConfirmSubmitDialog,
    handleCloseDialog,
    handleSubmit,
    handleDialogOnOpenChange,
    showConfirmSubmitDialog,
  } = useFormSubmit(guardianId)

  const handleClickChangeCpfCallout = () => {
    if (!school) return
    history.push(`/${school.slug}/contratos/troca-titularidade/${guardianId}`)
  }

  const handleGoBack = () => history.goBack()

  return (
    <>
      <ErrorDialog isError={getGuardianQuery.isError || hasErrorOnFetchSchool} />

      <Dialog
        size={2}
        open={showConfirmSubmitDialog}
        onOpenChange={handleDialogOnOpenChange}
        title="Tem certeza que quer editar esses dados?"
        content={
          <div className="px-4">
            <ul>
              <li>
                <Text>
                  Editar nome ou endereço: as faturas anteriores serão reemitidas e novas faturas
                  serão enviadas para o e-mail do responsável financeiro.
                </Text>
              </li>
              <li className="mt-4">
                <Text>Editar e-mail ou telefone: não irá atualizar as faturas.</Text>
              </li>
            </ul>

            <Text className="block mt-8">Você deseja continuar?</Text>
          </div>
        }
        cancelButton={
          <Button variant="ghost" onClick={handleCloseDialog}>
            Cancelar
          </Button>
        }
        actionButton={
          <Button onClick={handleClickConfirmSubmitDialog}>Continuar com a edição</Button>
        }
      />

      <FullScreenHeader
        className="fixed top-0 left-0 right-0 z-[99]"
        title="Editar dados do responsável financeiro"
        onClose={handleGoBack}
      />
      <Grid className="mt-[56px] mb-[72px] py-14 !mx-auto">
        <GridItem xs={4} sm={8} md="4 / 10" lg="4 / 10" xl="4 / 10">
          <GuardianFormFieldsLoader isLoading={getGuardianQuery.isLoading} />
          {getGuardianQuery.isSuccess && (
            <GuardianFormFields
              initialFormData={getGuardianQuery.data}
              onClickCancel={handleGoBack}
              onSubmit={handleSubmit}
              onClickChangeCpfCallout={handleClickChangeCpfCallout}
              showSubmitBtnLoader={isSubmitingData}
            />
          )}
        </GridItem>
      </Grid>
    </>
  )
}
