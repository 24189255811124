import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import DefaultARTemplate from '@monorepo/credit/templates/DefaultARTemplate'
import {
  ContainerCard,
  GrayText,
  HeaderContainer,
  ResultAnticipationContainer,
  RowContainer,
} from './styles'
import {
  useContextChosenOffer,
  useContextSimulation,
} from '@monorepo/credit/contexts/PayoutAnticipationContext'
import { dateToFormattedString, formatChosenOfferDate } from '@monorepo/credit/utils/formatDate'
import { useEffect, useState } from 'react'
import { useGetOrderStatus } from '@monorepo/credit/hooks/queries/useGetOrderStatus'
import { AnticipationSimulationResponse, CreditType } from '@/shared/services/credit/types'
import { LoadingStatus } from './LoadingStatus'
import { Separator } from '@gravity/separator'
import useMixpanelEventSender from '@monorepo/credit/utils/useMixpanelEventSender'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { MixpanelEventActions } from '@monorepo/credit/constants/MixpanelEventActions.enum'
import { CreditEventComponentNames } from '@monorepo/credit/constants/CreditEventComponentNames.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { Link } from '@gravity/link'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'
import { formattingFee } from '@monorepo/credit/utils/formatFee'

export const PayoutAnticipationConfirmedSummary = () => {
  const { school, schoolSlug } = useSelectedSchool()
  const { chosenOffer, setChosenOffer } = useContextChosenOffer()
  const { requestValue, setRequestValue } = useContextSimulation()
  const [loadingStatus, setLoadingStatus] = useState<boolean>(true)
  const schooldID = school?.id ?? ''
  const title = 'Status da sua antecipação'

  const { data: creditOrder, isLoading } = useGetOrderStatus<AnticipationSimulationResponse>(
    schooldID,
    CreditType.AR
  )

  useEffect(() => {
    if (!isLoading) {
      setLoadingStatus(false)
      if (creditOrder?.data?.offer) {
        setChosenOffer(creditOrder.data.offer)
      }
      if (creditOrder?.data?.offer?.requestValue) {
        setRequestValue(creditOrder.data.offer.requestValue)
      }
    }
  }, [creditOrder])

  const { sendCreditEventToMixpanel } = useMixpanelEventSender()

  useEffect(() => {
    sendCreditEventToMixpanel(
      EventDispatcherEvents.PAGE_VIEWED,
      MixpanelEventActions.PAGE_VIEW,
      {
        $page_name: CreditEventComponentNames.ANTICIPATION_CONFIRMED,
      },
      EventDispatcherEventScopes.PAYOUT_ANTICIPATION
    )
  }, [])

  if (loadingStatus)
    return <LoadingStatus title={title} previousRoute={`/${schoolSlug}/repasses`} />

  return (
    <DefaultARTemplate
      title={title}
      text="Acompanhe o status do seu pedido de antecipação e os próximos passos."
      hasBackButton
      previousRoute={`/${schoolSlug}`}
      child={
        <>
          <Text variant="body-1-regular">
            Em até 1 dia útil, a equipe de crédito entrará em contato para avançar com a
            contratação. Veja os documentos necessários{' '}
            <Link
              href="https://centraldeajuda.olaisaac.io/repasse/o-que-e-antecipacao-de-repasse-no-isaac#documentos-necessrios-para-contratar-antecipao-de-repasse-no-isaac-1"
              target="_blank"
              rel="noreferrer"
              size={3}
            >
              aqui
            </Link>
            .
          </Text>
          <ContainerCard className="flex-grow-0">
            <HeaderContainer>
              <RowContainer>
                <Text variant="subtitle-medium">
                  Antecipação solicitada em {formatChosenOfferDate(chosenOffer.chosenOfferDate)}
                </Text>
              </RowContainer>
            </HeaderContainer>
            <Separator color="neutral-2" orientation="horizontal" />

            <ResultAnticipationContainer>
              <RowContainer>
                <GrayText variant="subtitle-medium">Valor a receber</GrayText>
                <Heading variant="heading-h4-medium">R$ {requestValue}</Heading>
              </RowContainer>

              <RowContainer>
                <GrayText variant="body-1-regular">Data de recebimento</GrayText>
                <Text variant="body-1-regular">{chosenOffer.disbursementDate}</Text>
              </RowContainer>

              <RowContainer>
                <GrayText variant="body-1-regular">{`Deságio (${formattingFee.monthlyFee(
                  chosenOffer.appliedFee
                )})`}</GrayText>
                <Text variant="body-1-regular">{chosenOffer.feeValue}</Text>
              </RowContainer>

              <RowContainer>
                <GrayText variant="body-1-regular">Valor que será descontado</GrayText>
                <Text variant="body-1-regular">{chosenOffer?.totalAmount ?? 0}</Text>
              </RowContainer>

              <RowContainer>
                <GrayText variant="body-1-medium">Valor descontado no repasse de</GrayText>
                <Text variant="body-1-medium">
                  {dateToFormattedString(chosenOffer.discountDate)}
                </Text>
              </RowContainer>
            </ResultAnticipationContainer>
          </ContainerCard>
        </>
      }
    />
  )
}
