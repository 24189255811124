import { useMutation } from '@tanstack/react-query'

import { useApiClient } from '@/shared/hooks'
import { messagesService } from '@monorepo/messages/services/messagesService'
import { SendCommunicationRequest } from '@monorepo/messages/services/messagesService/types'

export const useSendCommunicationMutation = () => {
  const { apiClient } = useApiClient()
  const service = messagesService(apiClient.getClients().bffApi)

  return useMutation((params: SendCommunicationRequest) => service.sendCommunication(params), {
    onSuccess: response => response,
  })
}
