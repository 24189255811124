import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { DatePicker, TileDisabledFunctionArgs } from '@monorepo/report/components/DatePicker'
import { PayoutSubHeader } from '@monorepo/report/components/PayoutReportPageHeader'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useQuery } from '@/shared/hooks/useQuery'
import { findPayoutByDate } from '@monorepo/report/utils/findPayoutByDate'

import { REPORT_PAGE_NAMES } from '@monorepo/report/constants/REPORT_PAGE_NAMES'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { useContextHistoryPayoutDateSelected } from '@monorepo/report/contexts/PayoutAnticipationContext'
import { TuitionPayoutReportHeaderProps } from './types'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useLayout } from '@/shared/hooks/useLayout'
import { TopHeader } from '@/shared/components/TopHeader'
/**
 * Specialized header component for tuition payout report page
 *
 * @param props
 * @param props.tuitionPayoutReportList List of payout reports
 * @param props.isLoadingTuitionPayoutReportList Indicates if loading state should be displayed for the picker
 * @param props.isOnboardingDrawerOpen Indicates if onboarding drawer is open
 * @param props.onRequestOnboarding Callback function called on request drawer open
 * @param props.onChangeTuitionPayout Callback function called on change payout
 * @param props.payoutReportPeriod Object containing start and end date for the payout
 * @param props.isHourIncluded Indicates if the date should include hour
 * @param props.linkButtonText Label to be displayed at link button
 * @param props.onClickAtLinkButton Callback function triggered on click at link button
 */
export const TuitionPayoutReportHeader = ({
  tuitionPayoutReportList,
  onChangeTuitionPayout,
  payoutReportPeriod,
  isHourIncluded,
  linkButtonText,
  onClickAtLinkButton,
}: TuitionPayoutReportHeaderProps) => {
  const { query, setOnQueryParam, setMultipleQueryParams } = useQuery()
  const { school } = useSelectedSchool()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { setHistoryPayoutDateSelected } = useContextHistoryPayoutDateSelected()

  const [selectedTuitionPayoutReport, setSelectedTuitionPayoutReport] = useState<{
    date?: Date
    id: string
  }>()

  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  useLayout({ enableSideMenu: true, enableHeader: renderNewHeader })

  const parseStringToDate = (stringDate?: string) => {
    if (!stringDate) return

    return dayjs(stringDate).add(1, 'day').toDate()
  }

  const urlPayoutId = query.get('id')

  useEffect(() => {
    if (tuitionPayoutReportList.length === 0) return

    let currentPayout = tuitionPayoutReportList.find(item => item.payout_id === urlPayoutId)

    if (!currentPayout) {
      currentPayout = findPayoutByDate(new Date(), tuitionPayoutReportList)
    }

    if (!currentPayout) {
      currentPayout = tuitionPayoutReportList[tuitionPayoutReportList.length - 1]
    }

    setSelectedTuitionPayoutReport({
      id: currentPayout.payout_id,
      date: parseStringToDate(currentPayout.payout_due_date),
    })

    if (urlPayoutId !== currentPayout.payout_id) {
      setOnQueryParam(currentPayout.payout_id, 'id', 'replace')
    }
  }, [urlPayoutId, tuitionPayoutReportList])

  const checkIfDateShouldBeDisabled = ({ view, date }: TileDisabledFunctionArgs) => {
    if (view !== 'year') return false

    return !findPayoutByDate(date, tuitionPayoutReportList)
  }

  const handleChangeTuitionPayoutReport = (date: Date) => {
    if (tuitionPayoutReportList.length === 0) return

    const newCurrentTuitionPayoutReport = findPayoutByDate(date, tuitionPayoutReportList)

    if (!newCurrentTuitionPayoutReport) return

    setMultipleQueryParams(
      [
        { name: 'id', value: newCurrentTuitionPayoutReport.payout_id },
        { name: 'events' },
        { name: 'amount_signs' },
        { name: 'products' },
      ],
      'replace'
    )
    onChangeTuitionPayout()

    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.PAYOUT_REPORT,
        name: EventDispatcherEvents.TUITION_PAYOUT_DATE_CHANGE,
        action: 'click',
        customProperties: {
          $page_name: REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT,
        },
      })

    setHistoryPayoutDateSelected(date.toISOString())
  }

  const tuitionMinDate = parseStringToDate(
    tuitionPayoutReportList[tuitionPayoutReportList.length - 1]?.payout_due_date
  )

  const tuitionMaxDate = parseStringToDate(tuitionPayoutReportList[0]?.payout_due_date)

  return (
    <>
      <TopHeader backButtonLink={`/${school?.slug}/repasses`} showHelpButton={false} />
      <PayoutSubHeader
        isHourIncluded={isHourIncluded}
        linkButtonText={linkButtonText}
        onClickAtLinkButton={onClickAtLinkButton}
        payoutReportPeriod={payoutReportPeriod}
        title="Repasse de mensalidades"
        picker={
          <DatePicker
            variation="ghost"
            maxDetail="year"
            minDate={tuitionMinDate}
            maxDate={tuitionMaxDate}
            tileDisabled={checkIfDateShouldBeDisabled}
            value={selectedTuitionPayoutReport?.date}
            onChange={handleChangeTuitionPayoutReport}
          />
        }
      />
    </>
  )
}
