import { ReactFragment } from 'react'
import { TypesOfContractsGlossaryProps } from './types'
import { ContractStatusBadge } from '../ContractStatusBadge/ContractStatusBadge'
import { PreContractStatuses as ContractStatusType } from '@/shared/interfaces'
import { Accordion } from '@gravity/accordion'
import { DialogPrimitives } from '@gravity/dialog'

export const TypesOfContractGlossaryDialog = ({ open, onClose }: TypesOfContractsGlossaryProps) => {
  const typesList: { description: ReactFragment; type: ContractStatusType }[] = [
    {
      type: ContractStatusType.EFFECTED_PCS,
      description: (
        <>
          Contrato com parcela de pré-matrícula paga (se houver) e sem débitos de anos anteriores.
          Parcelas de mensalidades geradas e garantidas pelo isaac.
        </>
      ),
    },
    {
      type: ContractStatusType.CREATED_PCS,
      description: (
        <>
          Contrato com parcela de pré-matrícula a vencer e/ou é necessário regularizar o pagamento
          das parcelas vencidas no(s) ano(s) anterior(es). Parcelas de mensalidades ainda não foram
          geradas e não estão garantidas pelo isaac.
        </>
      ),
    },
  ]

  return (
    <DialogPrimitives.Root open={open} onOpenChange={open => !open && onClose()}>
      <DialogPrimitives.Portal>
        <DialogPrimitives.Overlay backdrop />
        <DialogPrimitives.Content
          title="Situação do contrato"
          description="A situação do contrato indica quais edições podem ser realizadas."
          size={2}
        >
          <div className="w-full h-full flex flex-col gap-4 py-2">
            {typesList.map(value => {
              return (
                <Accordion.Root key={value.type} type="single" collapsible>
                  <Accordion.Item key={value.type} value={`item-${value.type}`}>
                    <Accordion.Trigger>
                      <ContractStatusBadge status={value.type} enableTooltip={false} />
                    </Accordion.Trigger>
                    <Accordion.Content>{value.description}</Accordion.Content>
                  </Accordion.Item>
                </Accordion.Root>
              )
            })}
          </div>
        </DialogPrimitives.Content>
      </DialogPrimitives.Portal>
    </DialogPrimitives.Root>
  )
}
