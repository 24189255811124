import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { debounce } from 'throttle-debounce'

import { Button } from '@gravity/button'
import { Combobox, Option } from '@gravity/combobox'
import { LogoutBoxRight } from '@gravity/icons'

import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { useSchools } from '@/modules/app/hooks/queries/schools'
import { useApi } from '@/utils/hooks/useApi'

import { client } from '@/shared/integrations/sorting-hat'
import { ON_SELECT_SCHOOL_EVENT_SESSION_STORAGE_KEY } from '@/modules/app/constants'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventPageName } from '@/modules/app/models/EventPageName.enum'
import { EventIdentifierName } from '@/modules/app/models/EventIdentifierName.enum'

import IsaacLogo from '@/shared/assets/isaac-logo.svg'

import { Container } from './styles'

export const SchoolSelectionContent = () => {
  const { api } = useApi()
  const { push } = useHistory()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const [value, setValue] = useState<Option>()
  const [schoolFilterInput, setSchoolFilterInput] = useState('')

  const { data, isFetching } = useSchools(
    {
      nameFilter: schoolFilterInput,
      pagination: { per_page: 15 },
    },
    { keepPreviousData: true }
  )
  const schools = data?.data ?? []
  const options = schools.map(item => ({ label: item.name, value: item.slug })) ?? []

  const handleSelectSchool = (option: Option) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.SCHOOL_SELECTION,
        name: EventDispatcherEvents.OPTION_SELECTED,
        pageName: EventPageName.SCHOOL_SELECTION,
        identifierName: EventIdentifierName.CHANGE_SCHOOL,
        customProperties: {
          $form_action: 'Seleção de unidade',
          $school: option.label,
          $selected_school_slug: option.value,
        },
      })

    sessionStorage.setItem(ON_SELECT_SCHOOL_EVENT_SESSION_STORAGE_KEY, 'true')

    setValue(option)
    push(option.value)
  }

  const handleSearch = debounce(300, (term: string) => {
    setSchoolFilterInput(term)
  })

  /**
   * TODO: refactor to not depend on useApi deprecated hook
   */
  const handleOnLogoutClick = async () => {
    try {
      await api.auth.logout()
      client.logout()
    } catch {
      client.logout()
    }
  }

  return (
    <Container>
      <div className="heading">
        <img src={IsaacLogo} alt="isaac" />

        <Button
          size={2}
          variant="ghost"
          iconStart={<LogoutBoxRight />}
          onClick={handleOnLogoutClick}
        >
          Sair
        </Button>
      </div>

      <Combobox
        size={3}
        label="Escolha uma unidade para começar"
        placeholder="Procure por uma unidade"
        emptyPlaceholder="Nenhuma unidade encontrada"
        options={options}
        isLoadingOptions={isFetching}
        value={value}
        onChangeValue={handleSelectSchool}
        onSearch={handleSearch}
      />
    </Container>
  )
}
