import dayjs from 'dayjs'

import { Dialog } from '@gravity/dialog'
import { TextField } from '@gravity/text-field'
import { Controller, FieldErrorsImpl, SubmitHandler, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { formatCentsToReal } from 'src/shared/utils'
import { Installment } from '@/modules/contract/services/types'
import {
  MINIMUM_AMOUNT_CENTS,
  MAXIMUM_AMOUNT,
  calculateCommomAmount,
  validateEditedAmount,
} from '../../utils'
import { Button } from '@gravity/button'
import { SelectedInstallmentsText } from '../SelectedInstallmentsText'
import { Callout } from '@gravity/callout'
import { Text } from '@gravity/text'
import { InstallmentStatuses } from '@/shared/interfaces'

enum Field {
  AMOUNT = 'amount',
}

export type EditAmountForm = {
  [Field.AMOUNT]: cents
}

const REQUIRED_MESSAGES = {
  [Field.AMOUNT]: 'Por favor, informe o valor da parcela.',
}

const errorProps = (errors: Partial<FieldErrorsImpl<EditAmountForm>>, field: Field) => {
  return {
    error: Boolean(errors[field]),
    helperText: errors[field]?.message ?? '',
  }
}

export const EditAmountDialog = ({
  onClose,
  onConfirm,
  selectedInstallments,
}: {
  onClose: () => void
  onConfirm?: (e: any) => void
  selectedInstallments: Installment[]
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<EditAmountForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { amount: calculateCommomAmount(selectedInstallments) },
  })

  const hasOverdueInstallments = selectedInstallments.some(
    installment => installment.status === InstallmentStatuses.OVERDUE
  )

  return (
    <Dialog
      open
      title="Editar valor da parcela"
      onOpenChange={onClose}
      size={2}
      backdrop
      content={
        <div className="flex flex-col mb-4">
          <div className="mb-6">
            <SelectedInstallmentsText count={selectedInstallments.length} />
          </div>
          <Controller
            rules={{
              required: REQUIRED_MESSAGES[Field.AMOUNT],
              validate: {
                maxValue: v => MAXIMUM_AMOUNT >= v || 'O valor máximo da parcela é R$ 36.000,00',
                minValue: v => v >= MINIMUM_AMOUNT_CENTS || 'O valor mínimo da parcela é R$ 1,00',
                netInstallmentAmount: v =>
                  validateEditedAmount(v, selectedInstallments) ||
                  'As parcelas selecionadas têm descontos aplicados, e a soma dos descontos não pode ultrapassar o valor da parcela.',
              },
            }}
            control={control}
            name={Field.AMOUNT}
            render={({ field: { onChange, value } }) => (
              <>
                <NumberFormat
                  data-testid="amount-input"
                  id={Field.AMOUNT}
                  onValueChange={({ floatValue }) => {
                    if (floatValue === undefined) {
                      onChange(null)
                      return
                    }

                    onChange(floatValue)
                  }}
                  customInput={TextField}
                  size={3}
                  variant="outlined"
                  label="Valor da parcela"
                  format={formatCentsToReal}
                  value={value}
                  {...errorProps(errors, Field.AMOUNT)}
                />
                <Text
                  variant="caption-regular"
                  className="mt-1 text-colors-text-semantic-colors-error-1"
                >
                  {errorProps(errors, Field.AMOUNT).helperText}
                </Text>
              </>
            )}
          />

          {hasOverdueInstallments && (
            <div className="mt-6">
              <Callout
                text={`Ao editar o valor desta parcela, a data do seu vencimento será recalculada para amanhã, ${dayjs()
                  .add(1, 'day')
                  .format('DD/MM/YYYY')}.`}
              />
            </div>
          )}
        </div>
      }
      actionButton={
        <Button
          variant="solid"
          onClick={handleSubmit(onConfirm as SubmitHandler<EditAmountForm>)}
          disabled={!isValid}
        >
          Aplicar
        </Button>
      }
      cancelButton={
        <Button variant="ghost" onClick={onClose}>
          Cancelar
        </Button>
      }
    />
  )
}
