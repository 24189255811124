import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@gravity/button'
import { DialogPrimitives } from '@gravity/dialog'
import { Text } from '@gravity/text'
import { DashedArrowRight } from '@gravity/icons'

import { NotificationCard } from './components/NotificationCard'
import { NotificationCardPlaceholder } from './components/NotificationCardPlaceholder'
import { NotificationDialogErrorState } from './components/NotificationDialogErrorState'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useJWT } from '@/shared/hooks/useJWT'
import { getSegmentFilters } from '@/shared/hooks/useBeamer'
import { useLoadNotifications } from '@monorepo/notification/components/NotificationMenu/useLoadNotifications'
import { useTrackNotificationMenuEvents } from '@monorepo/notification/components/NotificationMenu/useTrackNotificationMenuEvents'

import type { NotificationResponse } from '@monorepo/notification/services/types'

import { DialogContentContainer } from './styles'

type NotificationDialogProps = {
  isOpen: boolean
  onClose: () => void
}

const MAX_NOTIFICATIONS_COUNT = 3

export const NotificationDialog = ({ isOpen, onClose }: NotificationDialogProps) => {
  const { push } = useHistory()
  const jwtResponse = useJWT()
  const { schoolSlug } = useSelectedSchool()

  const {
    trackModalButtonClick,
    trackModalClose,
    trackTitleLinkClick,
    trackFooterLinkClick,
    trackActionButtonClick,
  } = useTrackNotificationMenuEvents()

  const { data, isLoading, isError: hasError, refetch } = useLoadNotifications(
    {
      userId: jwtResponse.userId,
      filter: getSegmentFilters(jwtResponse),
      pagination: {
        count: MAX_NOTIFICATIONS_COUNT,
        page: 0,
      },
    },
    isOpen
  )
  const notifications = data?.notifications ?? []
  const remainingUnreadNotifications = data?.remainingNotificationsCount ?? 0

  useEffect(() => {
    if (isOpen) {
      trackModalButtonClick()
    }
  }, [isOpen])

  const onOpenChange = (open: boolean) => {
    if (!open) {
      onClose()
      trackModalClose()
    }
  }

  const handleNotificationLinkClick = (notification: NotificationResponse) => {
    onClose()
    trackTitleLinkClick(notification)
  }

  const handleActionButtonClick = (notificationTitle: string) => {
    onClose()
    trackActionButtonClick(notificationTitle)
  }

  const handleNavigateToNotifications = () => {
    onClose()
    trackFooterLinkClick()
    push(`/${schoolSlug}/avisos`)
  }

  return (
    <DialogPrimitives.Root open={isOpen} onOpenChange={onOpenChange}>
      <DialogPrimitives.Portal>
        <DialogPrimitives.Overlay backdrop />

        <DialogPrimitives.Content
          size={2}
          title="Quadro de avisos"
          actionButton={
            !hasError && (
              <Button
                color="accent"
                variant="ghost"
                iconEnd={<DashedArrowRight />}
                onClick={handleNavigateToNotifications}
              >
                Ver todas as notificações
              </Button>
            )
          }
        >
          {hasError && <NotificationDialogErrorState onRetry={refetch} />}

          {!hasError && (
            <DialogContentContainer>
              {isLoading && [1, 2].map(key => <NotificationCardPlaceholder key={key} />)}

              {!isLoading &&
                notifications.map(notification => (
                  <NotificationCard
                    key={notification.id}
                    title={notification.title}
                    category={notification.category}
                    date={new Date(notification.date)}
                    linkUrl={`/${schoolSlug}/avisos?id=${notification.id}`}
                    contentHtml={notification.contentHtml}
                    onLinkClick={() => handleNotificationLinkClick(notification)}
                    action={
                      !!notification.linkText && !!notification.linkUrl
                        ? { label: notification.linkText, url: notification.linkUrl }
                        : undefined
                    }
                    onActionClick={() => handleActionButtonClick(notification.title)}
                  />
                ))}

              {remainingUnreadNotifications > 0 && (
                <Text
                  variant="subtitle-medium"
                  className="content-bottom-text"
                  style={{ marginTop: '1rem' }}
                >
                  Mais {remainingUnreadNotifications} avisos para ler
                </Text>
              )}
            </DialogContentContainer>
          )}
        </DialogPrimitives.Content>
      </DialogPrimitives.Portal>
    </DialogPrimitives.Root>
  )
}
