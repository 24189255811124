import { formatCentsToReal } from 'src/shared/utils'
import type { PaymentPlanMethodsProps } from './types'

import { Text } from '@gravity/text'
import { BankCard2Outline } from '@gravity/icons'

export const InfoPaymentPlanCreditCard = ({
  creditCardFee,
  isPaymentFeeEnabled,
  paymentPlan,
  totalAmount,
}: PaymentPlanMethodsProps) => {
  const { max_installments_allowed } = paymentPlan
  const numberOfInstallments = max_installments_allowed
  const total = isPaymentFeeEnabled ? totalAmount + creditCardFee : totalAmount

  let noFeeText = ''
  if (isPaymentFeeEnabled && creditCardFee > 0) {
    noFeeText = ' (com taxa)'
  }
  if (!isPaymentFeeEnabled) {
    noFeeText = ' sem juros'
  }

  const infoText =
    numberOfInstallments > 1
      ? `até ${numberOfInstallments}x de ${formatCentsToReal(
          total / numberOfInstallments
        )}${noFeeText}  `
      : `à vista de ${formatCentsToReal(total)}${noFeeText}`

  return (
    <div className="flex mb-1 items-center">
      <BankCard2Outline />
      <Text variant="body-2-regular" className="text-colors-text-main-3">
        <b>Cartão de crédito:</b> {infoText}
      </Text>
    </div>
  )
}
