import { Button } from '@gravity/button'
import { Pencil } from '@gravity/icons'

import { InstallmentDrawerMenu } from '../InstallmentDrawerMenu/InstallmentDrawerMenu'
import { useInstallmentDrawerButtons } from './hooks/useInstallmentDrawerButtons'

export interface InstallmentDrawerButtonsProps {
  groupButtons?: boolean
  onCloseDrawer: () => void
  showEditButton?: boolean
  showMainButton?: boolean
  showMenu?: boolean
}

export const InstallmentDrawerButtons = ({
  onCloseDrawer,
  showMenu = true,
  showEditButton = true,
  showMainButton = true,
  groupButtons = true,
}: InstallmentDrawerButtonsProps) => {
  const buttonsData = useInstallmentDrawerButtons(onCloseDrawer)

  if (!buttonsData) return null

  const {
    buttonTitle,
    buttonAction,
    buttonIcon,
    isHiddenEditManualLiquidation,
    handleClickEditManualLiquidation,
    menuItems,
    isLoading,
  } = buttonsData

  const mainButton = showMainButton && (
    <Button onClick={buttonAction} iconStart={buttonIcon} loading={isLoading}>
      {buttonTitle}
    </Button>
  )

  const editButton = showEditButton && !isHiddenEditManualLiquidation && (
    <Button variant="ghost" onClick={handleClickEditManualLiquidation} iconStart={<Pencil />}>
      Editar recebimento
    </Button>
  )

  const renderButtons = () => {
    if (!groupButtons) {
      return (
        <>
          {showMainButton && mainButton}
          {showEditButton && !isHiddenEditManualLiquidation && editButton}
        </>
      )
    }

    return (
      <div className="flex flex-1 flex-nowrap gap-2">
        {mainButton}
        {editButton}
      </div>
    )
  }

  return (
    <>
      {renderButtons()}
      {showMenu && <InstallmentDrawerMenu items={menuItems} />}
    </>
  )
}
