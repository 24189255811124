import { DebtStatus } from '@/shared/interfaces'
import { z } from 'zod'

export const debtStatusesItemSchema = z.object({
  label: z.string(),
  value: z.string(),
  selected: z.boolean().default(false),
})

export const contractStatusesItemSchema = z.object({
  label: z.string(),
  value: z.string(),
  selected: z.boolean().default(false),
})

export const productsItemSchema = z.object({
  label: z.string(),
  value: z.string(),
  selected: z.boolean().default(false),
})

export const negotiationIdItemSchema = z.object({
  label: z.string(),
  value: z.string(),
  selected: z.boolean().default(false),
})

export const referenceYearsItemSchema = z.object({
  label: z.string(),
  value: z.string(),
  selected: z.boolean().default(false),
})

export const filterSchema = z
  .object({
    debt_statuses: z.array(debtStatusesItemSchema).optional(),
    contract_statuses: z.array(contractStatusesItemSchema).optional(),
    product_ids: z.array(productsItemSchema).optional(),
    negotiation_id: z.array(negotiationIdItemSchema).optional(),
    reference_years: z.array(referenceYearsItemSchema).optional(),
  })
  .optional()
  .nullable()
  .transform(data => {
    return {
      ...data,
      debtStatuses: data?.debt_statuses
        ? data?.debt_statuses.reduce<DebtStatus[]>((acc, status) => {
            if (status.selected) acc.push(status.value as DebtStatus)
            return acc
          }, [])
        : null,
      contractStatuses:
        data?.contract_statuses?.reduce<string[]>((acc, status) => {
          if (status.selected) acc.push(status.value)
          return acc
        }, []) || undefined,
      productsArray: data?.product_ids
        ? data?.product_ids.reduce<string[]>((acc, status) => {
            if (status.selected) acc.push(status.value)
            return acc
          }, [])
        : null,
      negotiationId: data?.negotiation_id?.reduce<string[]>((acc, status) => {
        if (status.selected) acc.push(status.value)
        return acc
      }, []),
      referenceYears: data?.reference_years
        ? data?.reference_years.reduce<string[]>((acc, status) => {
            if (status.selected) acc.push(status.value)
            return acc
          }, [])
        : null,
    }
  })

export type FilterForm = z.infer<typeof filterSchema>
