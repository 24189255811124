import { useHistory } from 'react-router-dom'
import { Text } from '@gravity/text'

import { InteractiveCardLinkProps } from './types'
import { StyledInteractiveCard, IconWrapper } from './styles'

export const InteractiveCardLink = ({
  href,
  title,
  icon,
  onClick,
  forcedReload = false,
  $variant = 'primary',
  ...rest
}: InteractiveCardLinkProps) => {
  const history = useHistory()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (forcedReload) {
      window.location.href = href
    } else {
      history.push(href)
    }

    onClick?.(event)
  }

  return (
    <StyledInteractiveCard onClick={handleClick} $variant={$variant} focusRing={false} {...rest}>
      <IconWrapper className="button-link-icon">{icon}</IconWrapper>
      <Text variant="subtitle-medium" className="title">
        {title}
      </Text>
    </StyledInteractiveCard>
  )
}
