import styled from 'styled-components'
import { DatepickerPrimitives as Datepicker } from '@gravity/datepicker'
import { IconButton } from '@gravity/icon-button'

export const DatepickerTrigger = styled(Datepicker.Trigger)`
  width: 100%;
  :focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: ${props => props.theme.gravity.colors['colors-border-accent-1']};
  }
`

export const DangerIconButton = styled(IconButton)`
  --tw-ring-shadow: 0 0 #000 !important;
  color: ${props => props.theme.gravity.colors['colors-interaction-dangerous-1']};
  z-index: 2;
  :not([disabled]) {
    border: 1px solid ${props => props.theme.gravity.colors['colors-interaction-dangerous-4']};
  }
  :hover {
    background-color: ${props => props.theme.gravity.colors['colors-interaction-dangerous-4']}20;
  }
`
