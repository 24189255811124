import styled from 'styled-components'
import { Text } from '@gravity/text'

export const GrayText = styled(Text)`
  color: ${({ theme }) => theme.gravity.colors['colors-text-main-3']};
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[2]};
`
