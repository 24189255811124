import NumberFormat from 'react-number-format'
import { Controller } from 'react-hook-form'
import { TextField } from '@gravity/text-field'
import { Skeleton } from '@gravity/skeleton'
import { Select } from '@gravity/select'

import type { AddNewGuardianInputsProps } from './types'
import * as Styled from './styles'
import { UFSelectionOptions } from './constants'

export const AddNewGuardianInputs = ({
  form,
  disableAllInputs,
  isLoading,
}: AddNewGuardianInputsProps) => {
  const { register, formState, getValues, setValue } = form
  const { errors } = formState

  if (isLoading) {
    return (
      <Styled.Wrapper>
        <Skeleton height={72} fullWidth />
        <Skeleton height={72} fullWidth />
        <Skeleton height={72} fullWidth />
        <Styled.SplitRow>
          <Skeleton height={72} fullWidth />
          <Skeleton height={72} fullWidth />
        </Styled.SplitRow>
        <Styled.SplitRow>
          <Skeleton height={72} fullWidth />
          <Skeleton height={72} fullWidth />
        </Styled.SplitRow>
        <Styled.SplitRow>
          <Skeleton height={72} fullWidth />
          <Skeleton height={72} fullWidth />
        </Styled.SplitRow>
      </Styled.Wrapper>
    )
  }

  const gerErrorProps = (errorMsg?: string) => ({
    error: !!errorMsg,
    errorMessage: errorMsg,
  })

  return (
    <Styled.Wrapper>
      <TextField
        size={3}
        fullWidth
        label="Nome"
        placeholder="Informe nome completo"
        {...gerErrorProps(errors?.name?.message)}
        {...register('name')}
        disabled={disableAllInputs}
      />

      <TextField
        label="E-mail"
        placeholder="Informe um e-mail válido"
        aria-label="E-mail do novo responsável"
        size={3}
        disabled={disableAllInputs}
        {...gerErrorProps(errors?.email?.message)}
        {...register('email')}
      />

      <NumberFormat
        aria-label="Número de celular do novo responsável"
        label="Celular"
        format="(##) #####-####"
        placeholder="(__) _____-____"
        mask="_"
        type="tel"
        value={getValues('phone_number')}
        onValueChange={async currentValue => {
          setValue('phone_number', currentValue.value)
        }}
        fullWidth
        customInput={TextField}
        size={3}
        disabled={disableAllInputs}
        {...gerErrorProps(errors?.phone_number?.message)}
        {...register('phone_number')}
      />

      <Styled.SplitRow>
        <NumberFormat
          label="CEP"
          format="#####-###"
          placeholder="_____-___"
          type="text"
          value={getValues('address.zip')}
          onValueChange={async currentValue => {
            setValue('address.zip', currentValue.value)
          }}
          fullWidth
          customInput={TextField}
          size={3}
          disabled={disableAllInputs}
          {...gerErrorProps(errors?.address?.zip?.message)}
          {...register('address.zip')}
        />

        <TextField
          size={3}
          fullWidth
          label="Logradouro"
          placeholder="Avenida, rua, alameda, praça"
          disabled={disableAllInputs}
          {...gerErrorProps(errors?.address?.street?.message)}
          {...register('address.street')}
        />
      </Styled.SplitRow>

      <Styled.SplitRow>
        <TextField
          size={3}
          fullWidth
          label="Número"
          placeholder="Número de edifício, casa, lote"
          disabled={disableAllInputs}
          {...gerErrorProps(errors?.address?.number?.message)}
          {...register('address.number')}
        />

        <TextField
          size={3}
          fullWidth
          label="Complemento"
          placeholder="Número de apartamento, casa"
          disabled={disableAllInputs}
          {...gerErrorProps(errors?.address?.additional_information?.message)}
          {...register('address.additional_information')}
        />
      </Styled.SplitRow>

      <Styled.SplitRow>
        <TextField
          size={3}
          fullWidth
          label="Cidade"
          placeholder="Informe a cidade"
          disabled={disableAllInputs}
          {...gerErrorProps(errors?.address?.city?.message)}
          {...register('address.city')}
        />

        <Controller
          control={form.control}
          name="address.state_code"
          render={({ field }) => (
            <Select
              size={3}
              fullWidth
              label="UF"
              placeholder="Selecione o estado"
              disabled={disableAllInputs}
              options={UFSelectionOptions}
              value={field.value}
              hasError={!!errors?.address?.state_code?.message}
              helperText={errors?.address?.state_code?.message}
              enableHelperTextSlot
              onValueChange={value => field.onChange(value)}
            />
          )}
        />
      </Styled.SplitRow>
    </Styled.Wrapper>
  )
}
