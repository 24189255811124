import {
  FetchAggregatedProvidersReportResponseDTO,
  FetchAggregatedProvidersReportRequestDTO,
  PayoutDomain,
} from '@monorepo/report/services/report/types'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useApiClient } from '@/shared/hooks'
import { reportService } from '@monorepo/report/services/report'

export const useIsaacPayAggregatedProvidersSummary = (
  { schoolId, endDate, startDate }: Omit<FetchAggregatedProvidersReportRequestDTO, 'payoutDomain'>,
  options?: UseQueryOptions<FetchAggregatedProvidersReportResponseDTO['data']>
) => {
  const { apiClient } = useApiClient()

  const service = reportService(apiClient.getClients().privateApi)

  return useQuery<FetchAggregatedProvidersReportResponseDTO['data']>(
    ['aggregated-providers-summary', schoolId, startDate, endDate, PayoutDomain.IsaacPay],
    async () => {
      const { data } = await service.fetchAggregatedProvidersReport({
        schoolId,
        startDate,
        endDate,
        payoutDomain: PayoutDomain.IsaacPay,
      })

      return data
    },
    options
  )
}
