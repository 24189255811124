import { Table } from '@gravity/table'

type LoadingTableProps = {
  columns: number
  rows: number
}

export const LoadingTable = ({ columns, rows }: LoadingTableProps) => {
  return (
    <Table.Body>
      {Array.from({ length: rows }).map((_, index) => (
        <Table.Row key={index}>
          {Array.from({ length: columns }).map((_, index) => (
            <Table.LoadingCell key={index} />
          ))}
        </Table.Row>
      ))}
    </Table.Body>
  )
}
