import styled from 'styled-components'

import { Card } from '@gravity/card'

export const BigNumberSkeleton = styled.div`
  flex: 1;
  & > :first-child {
    width: 25%;
    margin-bottom: ${props => props.theme.gravity.spacing[2]};
  }
  & > :nth-child(2) {
    width: 50%;
  }
`

export const BigNumberCard = styled(Card)`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${props => props.theme.gravity.colors['colors-background-neutral-2']};
  gap: ${props => props.theme.gravity.spacing[2]};
`
