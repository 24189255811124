import { Text } from '@gravity/text'
import { HistoryOutline, GraduationCapOutline } from '@gravity/icons'

import { RemoveButton } from '../RemoveButton'
import type { StorageItemType } from '@monorepo/school-home/types/StorageItem'

import { Container, Link } from './styles'
import { getHighlightWords } from '@monorepo/school-home/pages/SchoolHome/utils/getHighlightWords'

interface ItemStudentSearchProps {
  guardianName: string | undefined
  id: string
  inputSearch: string
  mainProduct: string | undefined
  name: string
  onClick: (data: StorageItemType) => void
  onRemove?: (id: string) => void
  type: 'history' | 'search'
  url: string
}

export const ItemStudentSearch = ({
  guardianName,
  mainProduct,
  name,
  type,
  onRemove,
  onClick,
  id,
  url,
  inputSearch,
}: ItemStudentSearchProps) => {
  return (
    <Container
      data-testid="item-student-search"
      className={type === 'history' ? 'single-row' : 'double-row'}
    >
      <Link
        to={`${url}/alunos/${id}`}
        onClick={() =>
          onClick({
            guardianName: guardianName ?? undefined,
            name,
            mainProduct: mainProduct ?? undefined,
            id,
            type: 'students',
          })
        }
      >
        {type === 'history' ? (
          <HistoryOutline size="sm" className="fill-colors-interaction-primary-7" />
        ) : (
          <GraduationCapOutline size="sm" className="fill-colors-interaction-primary-7" />
        )}
        <div className="student-item">
          <Text
            className="student-name"
            variant="subtitle-regular"
            data-testid="mark-student-name"
            dangerouslySetInnerHTML={{ __html: getHighlightWords(inputSearch, name) }}
          />
          <Text variant="body-2-regular" className="second-text">
            Aluno
          </Text>
          {mainProduct && (
            <Text variant="body-2-regular" className="second-text">
              {mainProduct}
            </Text>
          )}
        </div>
        {guardianName && type !== 'history' && (
          <div className="guardian-item">
            <Text variant="body-2-regular">{guardianName}</Text>
            <Text variant="body-2-regular" className="second-text">
              Responsável
            </Text>
          </div>
        )}
        {type === 'history' && <RemoveButton onClick={onRemove} id={id} />}
      </Link>
    </Container>
  )
}
