import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { useOnboardingApi } from '../services/api'
import { PatchStudentDetailsParams } from '../services/types'

export const usePatchStudentDetails = (
  options?: UseMutationOptions<any, unknown, PatchStudentDetailsParams, unknown>
) => {
  const api = useOnboardingApi()

  return useMutation(async (data: PatchStudentDetailsParams) => {
    const response = await api.patchStudentDetails(data)

    return response.data
  }, options)
}
