import sanitizeHtml from 'sanitize-html'
import { Badge } from '@gravity/badge'
import { Link } from '@gravity/link'
import { Text } from '@gravity/text'
import { Button } from '@gravity/button'

import { useFormatNotificationLink } from '@monorepo/notification/hooks/useFormatNotificationLink'

import { formatDate } from '@/shared/utils/dateFormatters'

import type { Category } from '@monorepo/notification/types'

import { Container, RichTextContainer } from './styles'

type NotificationCardProps = {
  action?: { label: string; url: string }
  category: Category
  contentHtml: string
  date: Date
  linkUrl: string
  onActionClick?: () => void
  onLinkClick?: () => void
  title: string
}

const ALERT_BADGE: Record<Category, JSX.Element> = {
  alertas: (
    <Badge variant="soft" color="warning">
      Alertas
    </Badge>
  ),
  informes: (
    <Badge variant="soft" color="neutral">
      Informes
    </Badge>
  ),
  tarefas: (
    <Badge variant="soft" color="accent">
      Tarefas
    </Badge>
  ),
}

export const NotificationCard = ({
  date,
  category,
  title,
  contentHtml,
  linkUrl,
  action,
  onLinkClick,
  onActionClick,
}: NotificationCardProps) => {
  const formattedDate = formatDate(date, 'DD/MM/YYYY')
  const { formattedLinkUrl } = useFormatNotificationLink(action?.url ?? '')

  function handleClickNotificationAction() {
    window.location.href = formattedLinkUrl
    onActionClick?.()
  }

  return (
    <Container>
      <div className="header">
        {ALERT_BADGE[category]}

        <Text
          as="span"
          variant="body-2-regular"
          className="color-secondary"
          data-testid="date-text"
        >
          {formattedDate}
        </Text>
      </div>

      <div className="text-content">
        <Link
          className="title"
          size={3}
          weight="semibold"
          underline="none"
          href={linkUrl}
          onClick={onLinkClick}
        >
          {title}
        </Link>

        <RichTextContainer
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(contentHtml, {
              allowedTags: [
                'p',
                'div',
                'li',
                'h1',
                'h2',
                'h3',
                'h4',
                'h5',
                'h6',
                'div',
                'ol',
                'li',
                'b',
                'a',
              ],
            }),
          }}
        />
      </div>

      {!!action && (
        <div>
          <Button onClick={() => handleClickNotificationAction()}>{action.label}</Button>
        </div>
      )}
    </Container>
  )
}
