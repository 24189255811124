import { Button } from '@gravity/button'
import { useToggleBypassMutation } from '@monorepo/onboarding/hooks/useToggleBypassMutation'
import { useParams } from 'react-router-dom'
import { useToast } from '@gravity/toast'
import { useQueryClient } from '@tanstack/react-query'
import {
  GET_ONBOARDING_BATCH_QUERY_KEY,
  useGetOnboardingBatch,
} from '@monorepo/onboarding/hooks/useFetchOnboardingBatches'

export const BypassButton = () => {
  const { batchID } = useParams<{ batchID: string }>()
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const { data, isFetching: isFetchingOnboardingBatch } = useGetOnboardingBatch({ batchID })

  const {
    mutateAsync: executeToggleBypass,
    isLoading: isLoadingToggleBypassMutation,
  } = useToggleBypassMutation()

  const handleClick = async () => {
    await executeToggleBypass(
      {
        batchID,
      },
      {
        onSuccess(response) {
          queryClient.invalidateQueries([GET_ONBOARDING_BATCH_QUERY_KEY, batchID])

          if (response.bypass_enabled) {
            return toast({
              // TODO: REVIEW TOAST
              title: 'A escola foi liberada',
              type: 'success',
            })
          }

          return toast({
            // TODO: REVIEW TOAST
            title: 'A escola foi bloqueada',
            type: 'success',
          })
        },
        onError() {
          toast({
            title: 'Não foi possível liberar a escola',
            type: 'error',
          })
        },
      }
    )
  }

  const disabled = isLoadingToggleBypassMutation || isFetchingOnboardingBatch || data === undefined

  return (
    <Button size={1} color="error" variant="outline" onClick={handleClick} disabled={disabled}>
      {data?.bypass_enabled ? 'Bloquear escola' : 'Liberar escola'}
    </Button>
  )
}
