import { Table } from '@gravity/table'
import { ComponentProps } from 'react'
import { Text } from '@gravity/text'

export const TableEmptyState = ({
  columns,
}: {
  columns: Array<ComponentProps<typeof Table.HeaderCell>>
}) => (
  <Table.Row>
    <Table.CustomCell colSpan={columns.length}>
      <Text variant="body-1-regular" className="my-0 mx-auto text-colors-text-main-3">
        Nenhum responsável encontrado
      </Text>
    </Table.CustomCell>
  </Table.Row>
)
