import type { AxiosInstance } from 'axios'
import qs from 'qs'
import dayjs from 'dayjs'

import { buildQueryParams } from '@monorepo/report/utils/buildQueryParams' // REVIEW: acoplamento com report
import { convertToUTCDate } from '@/shared/utils'

import type {
  FetchReportListRequestDTO,
  FetchReportListResponseDTO,
  FetchPayoutDetailsRequestDTO,
  FetchPayoutDetailsResponseDTO,
  GetEmbedDashboardReportRequest,
  GetEmbedReportResponse,
} from './types'
import type { PayoutReportType } from '@monorepo/report/models/PayoutReportType' // REVIEW: acoplamento com report
import type { PayoutSummary } from '@monorepo/report/models/PayoutSummary' // REVIEW: acoplamento com report
import type { APIResponse } from '@/shared/interfaces'

export const reportService = (fetcher: AxiosInstance) => {
  return {
    /**
     * Function responsible for fetching the metabase embed dashboard
     *
     * @param schoolId School ID
     * @param dashboardName Dashboard name
     *
     * @returns Embed report URL
     */
    fetchEmbedDashboardReport: async ({
      schoolId,
      dashboardName,
    }: GetEmbedDashboardReportRequest) => {
      const { data } = await fetcher.get<GetEmbedReportResponse>(
        `/report/dashboard/${dashboardName}/${schoolId}`
      )
      return data
    },

    /**
     * Function responsible for fetch all available payouts summary
     *
     * @param schoolId School ID
     *
     * @returns List of available payouts summary
     */
    fetchPayoutsSummary: async (schoolId: string): Promise<PayoutSummary[]> => {
      const { data: responseData } = await fetcher.get<APIResponse<PayoutSummary[] | null>>(
        `/payout/detailed-payouts/${schoolId}`,
        {
          params: {
            versions: [1, 2],
            school_id: schoolId, // FIXME: [Provisory] Backend needs for permissions
          },
          paramsSerializer: params => qs.stringify(params, { arrayFormat: 'comma' }),
        }
      )

      return responseData.data ?? []
    },

    /**
     * Function responsible for fetch all available reports
     *
     * @param data.schoolID School ID
     * @param data.reportType Report type
     *
     * @returns List of reports of requested type
     */
    fetchReportList: async ({
      schoolId,
      reportType,
      payoutDomain,
    }: FetchReportListRequestDTO): Promise<FetchReportListResponseDTO> => {
      const { data } = await fetcher.get<FetchReportListResponseDTO>(`/report/${schoolId}`, {
        params: {
          report_type: reportType,
          ...(payoutDomain ? { payout_domain: payoutDomain } : {}),
        },
      })

      return {
        ...data,
        data: data.data.map(item => {
          const startedAt = convertToUTCDate(item.started_at).format('YYYY-MM-DD')
          const endedAt = convertToUTCDate(item.ended_at).format('YYYY-MM-DD')

          return {
            ...item,
            started_at: dayjs(startedAt).toDate(),
            ended_at: dayjs(endedAt).toDate(),
          }
        }),
      }
    },

    /**
     * Function to fetch payout report details
     *
     * @param reportType
     * @param data.payoutId Payout ID
     * @param data.filter Filter options
     * @param data.pagination Pagination options
     *
     * @returns Payout details
     */
    fetchPayoutDetails: async <T extends PayoutReportType>(
      reportType: T,
      { payoutId, filter = {}, pagination = {} }: FetchPayoutDetailsRequestDTO<T>
    ): Promise<FetchPayoutDetailsResponseDTO<T>> => {
      const type = reportType === 'tuition' ? 'ISAAC_PLATFORM' : 'UNGUARANTEED_ISAAC_PLATFORM'

      const parsedFilters = buildQueryParams(filter)
      const parsedPagination = buildQueryParams(pagination)

      const params = {
        type,
        ...parsedFilters,
        ...parsedPagination,
      }

      const { data } = await fetcher.get<FetchPayoutDetailsResponseDTO<T>>(
        `/report/detailed-payout/${payoutId}`,
        {
          params,
          paramsSerializer: params => qs.stringify(params, { arrayFormat: 'comma' }),
        }
      )

      return data
    },
  }
}
