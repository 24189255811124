import styled, { DefaultTheme } from 'styled-components'

import type { LinkProps, VariantType } from './types'

const getValueByVariant = (variant: VariantType, primaryValue: string, secondaryValue: string) =>
  variant === 'primary' ? primaryValue : secondaryValue

const enabled = ({ variant, theme }: { theme: DefaultTheme; variant: VariantType }) => {
  const borderColor = getValueByVariant(
    variant,
    theme.gravity.colors['colors-border-accent-1'],
    theme.gravity.colors['colors-border-neutral-5']
  )
  const textColor = getValueByVariant(
    variant,
    theme.gravity.colors['colors-text-main-1'],
    theme.gravity.colors['colors-text-main-2']
  )

  return `
  &:link,
  &:visited {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    padding: ${theme.gravity.spacing[4]} ${theme.gravity.spacing[8]};
    border: 1px solid ${borderColor};
    border-radius: 8px;
    text-decoration: none;
    color: ${textColor};
    height: 100%;

    &:not(:hover):not(:active) .MuiSvgIcon-root{
      color: ${textColor};
    }
  }
`
}

const hover = ({ variant, theme }: { theme: DefaultTheme; variant: VariantType }) => {
  const backgroundColor = getValueByVariant(
    variant,
    theme.gravity.colors['colors-background-accent-1'],
    theme.gravity.colors['colors-background-neutral-5']
  )
  const borderColor = getValueByVariant(
    variant,
    theme.gravity.colors['colors-border-accent-1'],
    theme.gravity.colors['colors-border-neutral-5']
  )

  return `
  &:hover {
    color: ${theme.gravity.colors['colors-text-main-5']};
    background-color: ${backgroundColor};
    border-color: ${borderColor};
  }
`
}

const active = ({ variant, theme }: { theme: DefaultTheme; variant: VariantType }) => {
  const backgroundColor = getValueByVariant(
    variant,
    theme.gravity.colors['colors-interaction-primary-2'],
    theme.gravity.colors['colors-background-neutral-5']
  )
  const borderColor = getValueByVariant(
    variant,
    theme.gravity.colors['colors-interaction-primary-2'],
    theme.gravity.colors['colors-border-neutral-5']
  )
  return `
  &:active {
    background-color: ${backgroundColor};
    border-color: ${borderColor};
  }
`
}

const focus = ({ theme }: { theme: DefaultTheme }) => {
  return `
  &:focus-visible {
    outline: ${theme.gravity.colors['colors-interaction-primary-4']} solid 3px;
  }
`
}

export const Anchor = styled.a<LinkProps>`
  ${({ variant = 'primary', theme }) => {
    return `
    ${enabled({ variant, theme })}
    ${hover({ variant, theme })}
    ${active({ variant, theme })}
    ${focus({ theme })}
  `
  }}
`
