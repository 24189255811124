import { Button } from '@gravity/button'
import { Text } from '@gravity/text'
import { DialogPrimitives } from '@gravity/dialog'
import { Callout } from '@gravity/callout'
import { Checkbox } from '@gravity/checkbox'
import { useRef } from 'react'

type Props = {
  onClose?: () => void
  onConfirm?: () => void
  open: boolean
}

export const DSHighlighterDialog = ({ open, onClose, onConfirm }: Props) => {
  const checkboxRef = useRef<HTMLButtonElement>(null)

  const handleDialogOpenChange = () => {
    if (open && onClose) onClose()
  }

  const handleConfirm = () => {
    if (checkboxRef.current?.ariaChecked) {
      localStorage.setItem('_DS_HIGHLIGHTER_DIALOG_DISABLED', checkboxRef.current.ariaChecked)
    }
    if (onConfirm) onConfirm()
  }

  return (
    <DialogPrimitives.Root open={open} onOpenChange={handleDialogOpenChange}>
      <DialogPrimitives.Overlay backdrop />
      <DialogPrimitives.Content
        size={3}
        title="Acompanhamento na migração de DS"
        actionButton={<Button onClick={handleConfirm}>Habilitar</Button>}
        cancelButton={
          <Button variant="ghost" onClick={onClose}>
            Cancelar
          </Button>
        }
      >
        <div className="grid gap-6">
          <Text variant="body-2-regular">
            Para ajudar a monitorar o progresso da migração para o novo Design System, estamos
            disponibilizando a opção de destacar, dentro da aplicação, quais elementos da página
            utilizam a nova biblioteca e quais ainda fazem uso do antigo Design System.
          </Text>
          <Text variant="body-2-medium">Indicadores:</Text>
          <div className="grid gap-2">
            <div className="flex items-center ml-4 gap-2">
              <div className="flex bg-colors-text-semantic-colors-success-1  rounded-full h-4 w-4" />
              <Text variant="body-2-medium">Componentes importados do novo Design System</Text>
            </div>
            <div className="flex items-center ml-4 gap-2">
              <div className="flex bg-colors-text-semantic-colors-error-1 rounded-full h-4 w-4" />
              <Text variant="body-2-medium">
                Componentes importados do antigo Design System ou diretamente do Material UI.
              </Text>
            </div>
          </div>
          <Callout
            text="Alguns componentes são nativos ou não dependem das bibliotecas mencionadas, portanto, esses elementos não serão destacados."
            className="callout"
          />
          <Checkbox ref={checkboxRef} text="Não mostrar mais essa mensagem" size={1} />
        </div>
      </DialogPrimitives.Content>
    </DialogPrimitives.Root>
  )
}
