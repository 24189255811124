import { FloatMenuItem } from './FloatMenuItem'

import { FloatMenuContainer } from './styles'

export type FloatMenuSection = {
  id: string
  label: string
  subLabel?: string
}

type FloatMenuProps = {
  activeSectionId?: string
  isNewSidemenuEnabled?: boolean
  onSectionActiveCallback: (id: string) => void
  sections: FloatMenuSection[]
}

export const FloatMenu = ({
  sections,
  activeSectionId,
  onSectionActiveCallback,
  isNewSidemenuEnabled = false,
}: FloatMenuProps) => {
  return (
    <FloatMenuContainer newSidemenu={isNewSidemenuEnabled}>
      {sections.map(section => (
        <FloatMenuItem
          {...section}
          key={section.id}
          to={`#${section.id}`}
          replace
          isActive={section.id === activeSectionId}
          onClick={e => {
            if (e.currentTarget) {
              onSectionActiveCallback(section.id)
              e.stopPropagation()
            }
          }}
        />
      ))}
    </FloatMenuContainer>
  )
}
