import { ChoiceChip } from '@gravity/chip'
import { Control, FieldArrayWithId, useFieldArray } from 'react-hook-form'
import { useTextFilterOptions } from '../../hooks/useGuardianContractsFilter/useTextFilterOptions'
import { FilterForm } from '../../schemas/filterSchemas'
import { GuardiansContractsFilterQueryParams } from '../../hooks/useGuardianContractsFilter/types'
import * as Styled from './styles'
import { useCallback } from 'react'

interface Props {
  control: Control<FilterForm>
  filterType: GuardiansContractsFilterQueryParams
}

export const FilterChip = ({ control, filterType }: Props) => {
  const { fields, update } = useFieldArray({
    control,
    name: filterType,
  })

  const handleChoiceSelected = (index: number) => {
    update(index, {
      ...fields[index],
      selected: !fields[index]?.selected || false,
    })
  }

  const handleSelected = useCallback(
    (field, index) => {
      const isContractStatus = filterType === 'contract_statuses'
      const isSelected = fields.some(f => f.selected)

      if (isContractStatus && isSelected && !field.selected) {
        const updatedFields = fields.map(currentField => ({
          ...currentField,
          selected: !currentField.selected,
        }))
        updatedFields.forEach((f, i) => update(i, f))
      } else {
        handleChoiceSelected(index)
      }
    },
    [filterType, fields, update, handleChoiceSelected]
  )

  return (
    <Styled.Wrapper>
      {fields.map((field: FieldArrayWithId<FilterForm>, index: number) => {
        return (
          <ChoiceChip
            key={field.value}
            text={useTextFilterOptions({ value: field.value, label: field.label })}
            value={field.value}
            selected={field.selected}
            onSelected={() => handleSelected(field, index)}
          />
        )
      })}
    </Styled.Wrapper>
  )
}
