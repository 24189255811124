import { useHistory } from 'react-router-dom'
import { AgreementInvoice, SimulationPaymentMethod } from '@/modules/guardians/models/agreement'
import { useAgreement } from 'src/escolas/contexts/agreementContext'
import { InvoiceStatus } from './components/PaymentTableCheckout/types'
import PaymentTableCheckout from './components/PaymentTableCheckout'
import type { AgreementConclusionStepProps } from './types'
import * as S from './styles'
import { useSendCheckoutEvent } from '../../hooks/useSendCheckoutEvents'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { useGuardianNavigation } from '@/shared/hooks/useGuardianNavigation'
import { PaymentTable } from './components/PaymentTable'
import { Callout, CalloutProps } from '@gravity/callout'
import StepFooter from '../../components/StepFooter'

const AgreementConclusionStep = ({
  id,
  goToPayAgreementStep,
  isNegotiationDownPayment,
}: AgreementConclusionStepProps) => {
  const history = useHistory()
  const sendCheckoutEvent = useSendCheckoutEvent()
  const { pageToReturn } = useGuardianNavigation()

  const {
    agreementSimulations,
    agreementStatus,
    positionAgreement,
    setPositionAgreement,
  } = useAgreement()

  const currentAgreementStatus = agreementStatus[positionAgreement]

  const nextAgreementIndex = positionAgreement + 1
  const nextAgreement = agreementSimulations?.[nextAgreementIndex]

  const isNegotiationWithoutPOS =
    isNegotiationDownPayment &&
    !agreementStatus?.[positionAgreement].invoices.find(
      invoice => invoice.payment_method === SimulationPaymentMethod.POS
    )

  function handleFinishBtnClick() {
    if (nextAgreement) {
      setPositionAgreement(nextAgreementIndex)
      goToPayAgreementStep()
    } else {
      sendCheckoutEvent(EventDispatcherEntities.CHECKOUT_LAST_STEP, {
        name: 'Concluir negociação',
      })
      if (pageToReturn) history.push(pageToReturn)
    }
  }

  const getFinishBtnLabel = () => {
    if (!nextAgreement) return 'Concluir negociação'
    return `Ir para ${nextAgreementIndex + 1}º pagamento`
  }

  function getNotificationProps(): CalloutProps {
    const firstInvoicePaymentMethod = currentAgreementStatus.invoices?.[0]?.payment_method

    switch (firstInvoicePaymentMethod) {
      case SimulationPaymentMethod.CREDIT_CARD:
        return {
          text:
            'Aguardando pagamento. Copie e envie o link para que o responsável financeiro consiga fazer o pagamento pelo cartão',
        }

      case SimulationPaymentMethod.BANK_SLIP:
      case SimulationPaymentMethod.PIX:
        return {
          text:
            'Aguardando pagamento do boleto de entrada. Caso tenha recebido em dinheiro ou cheque alguma da(s) parcela(s), não esqueça de registrar o recebimento',
        }

      case SimulationPaymentMethod.POS:
        return {
          text: 'Pagamento realizado com sucesso!',
        }
    }
  }

  function getInstallmentLabel(index: number) {
    const totalOfInvoices = currentAgreementStatus.invoices.length
    if (totalOfInvoices === 1) return 'Parcela 1 de 1'

    if (index === 0) {
      return 'Entrada'
    }

    return `Parcela ${index} de ${totalOfInvoices - 1}`
  }

  function getFormOfPaymentLabel(invoice: AgreementInvoice) {
    switch (invoice.payment_method) {
      case SimulationPaymentMethod.BANK_SLIP:
      case SimulationPaymentMethod.PIX:
        return 'Boleto ou pix'

      case SimulationPaymentMethod.POS:
        return invoice.instalment_credit_card && invoice.instalment_credit_card > 1
          ? `${invoice.instalment_credit_card}x na maquininha`
          : 'À vista na maquininha'

      case SimulationPaymentMethod.CREDIT_CARD:
        return invoice.instalment_credit_card && invoice.instalment_credit_card > 1
          ? `${invoice.instalment_credit_card}x no cartão`
          : 'À vista no cartão'
    }
  }
  const sendGenerateReceiptEvent = () => {
    sendCheckoutEvent(EventDispatcherEntities.CHECKOUT_SUCCESSFUL_TRANSACTION_GENERATE_RECEIPT, {
      name: 'Gerar recibo',
      location: id,
    })
  }
  return (
    <S.Wrapper large={isNegotiationDownPayment}>
      <Callout {...getNotificationProps()} />
      {!isNegotiationWithoutPOS && (
        <div>
          {currentAgreementStatus.invoices.map((invoice, index) => (
            <PaymentTableCheckout
              installmentLabel={getInstallmentLabel(index)}
              formOfPaymentLabel={getFormOfPaymentLabel(invoice) ?? ''}
              key={invoice.id ?? ''}
              idForReceipt={invoice.receivable_id}
              amount={invoice.amount}
              paymentMethod={invoice.payment_method}
              due_date={invoice.due_date}
              paymentLink={invoice?.payment_link ?? ''}
              invoiceStatus={
                invoice.payment_method && invoice.payment_method === SimulationPaymentMethod.POS
                  ? InvoiceStatus.PAID
                  : InvoiceStatus.AWAITING_PAYMENT
              }
              onSuccess={sendGenerateReceiptEvent}
            />
          ))}
        </div>
      )}
      {isNegotiationWithoutPOS && (
        <PaymentTable agreementStatus={agreementStatus?.[positionAgreement]} />
      )}
      <StepFooter
        showHelpButton
        stepName={id}
        btnPrimary={{
          onClick: handleFinishBtnClick,
          hideIcon: true,
          label: getFinishBtnLabel(),
        }}
      />
    </S.Wrapper>
  )
}

export default AgreementConclusionStep
