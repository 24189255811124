import { Text } from '@gravity/text'
import { Skeleton } from '@gravity/skeleton'

import type { SubsectionPlaceholderProps, SubsectionProps } from './types'

import { Container, InfoContainer } from './styles'

export const Subsection = (props: SubsectionProps) => {
  const { title, icon, columnsCount, width, children, variantText = 'subtitle-medium' } = props

  return (
    <Container>
      {title && icon && (
        <Text variant={variantText} className="title">
          {icon}
          {title}
        </Text>
      )}

      <InfoContainer $columnsCount={columnsCount} $width={width}>
        {children}
      </InfoContainer>
    </Container>
  )
}

export const SubsectionPlaceholder = (props: SubsectionPlaceholderProps) => {
  const { children, columnsCount = 3 } = props

  return (
    <Container>
      <Skeleton variant="neutral" width={100} height={15} />
      <InfoContainer $columnsCount={columnsCount}>{children}</InfoContainer>
    </Container>
  )
}
