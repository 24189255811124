import { ChoiceChip } from '@gravity/chip'
import type { Control } from 'react-hook-form'
import { useFieldArray } from 'react-hook-form'
import type { filterForm } from '@monorepo/enrollment/pages/SchoolEnrollmentCycle/schemas/filterSchemas'
import * as Styled from './styles'

interface Props {
  control: Control<filterForm>
  filterType: 'enrollment_status' | 'enrollment_sub_status'
}

export const FilterChip = ({ control, filterType }: Props) => {
  const { fields, update } = useFieldArray({
    control,
    name: filterType,
  })

  const handleChoiceSelected = (index: number) => {
    update(index, {
      ...fields[index],
      selected: !fields[index]?.selected || false,
    })
  }

  return (
    <Styled.Wrapper>
      {fields.map((field, index) => (
        <ChoiceChip
          key={field.value}
          text={field.label}
          value={field.value}
          selected={field.selected}
          onSelected={() => handleChoiceSelected(index)}
        />
      ))}
    </Styled.Wrapper>
  )
}
