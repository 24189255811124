import React, { ReactNode } from 'react'
import { Button } from '@gravity/button'
import { DialogProps, DialogPrimitives } from '@gravity/dialog'
import { Text } from '@gravity/text'
import booksAndApple from '@/shared/assets/booksAndApple.png'
import meditating from '@/shared/assets/meditating.png'
import { useApiClient } from '@/shared/hooks'

const DEFAULT_MESSAGE = 'Por favor, tente novamente ou entre em contato com a equipe responsável.'

const TextAndImageContent = ({
  imageSrc,
  message,
  secondaryMessage,
}: {
  imageSrc: string
  message: string
  secondaryMessage?: React.ReactNode
}) => (
  <>
    <div className="pt-5 flex justify-center text-center">
      <Text variant="body-2-regular">{message}</Text>
    </div>
    {secondaryMessage ? (
      <div className="pt-5 flex justify-center text-center">{secondaryMessage}</div>
    ) : null}
    <div className="flex justify-center">
      <img className="text-center w-1/2 py-4 px-0" src={imageSrc} alt="" />
    </div>
  </>
)

export const FailureFeedbackContent = ({ message = DEFAULT_MESSAGE }: { message?: string }) => {
  const { correlationId } = useApiClient()

  const secondaryMessage = correlationId ? (
    <Text variant="body-2-regular">
      Se o erro persistir, informe este código ao suporte:{' '}
      <strong>#{correlationId.split('-')[0]}</strong>
    </Text>
  ) : null
  return (
    <TextAndImageContent
      message={message}
      imageSrc={booksAndApple}
      secondaryMessage={secondaryMessage}
    />
  )
}

export type ConfirmationDialogProps = {
  backButtonLabel?: string
  buttonLabel?: string
  children?: ReactNode
  isConfirmDisabled?: () => boolean
  isVisible: boolean
  onCancel?: () => void
  onClose: () => void
  shortIds?: string[]
  size?: DialogProps['size']
  submitHandler: () => void
  title?: string
}

const ConfirmationDialog = ({
  buttonLabel = 'Ok, entendi!',
  backButtonLabel,
  children,
  isVisible,
  onClose,
  submitHandler,
  title = '',
  isConfirmDisabled,
  size,
  onCancel,
}: ConfirmationDialogProps) => {
  const handleDialogOpenChange = (open: boolean) => {
    if (!open && isVisible) onClose()
  }

  return (
    <DialogPrimitives.Root open={isVisible} onOpenChange={handleDialogOpenChange}>
      <DialogPrimitives.Overlay backdrop />
      <DialogPrimitives.Content
        size={size}
        title={title}
        data-testid="confirmation-dialog"
        cancelButton={
          backButtonLabel && (
            <Button variant="ghost" onClick={onCancel ?? onClose}>
              {backButtonLabel}
            </Button>
          )
        }
        actionButton={
          <Button onClick={submitHandler} disabled={isConfirmDisabled?.()}>
            {buttonLabel}
          </Button>
        }
      >
        <div className="mb-8">{children}</div>
      </DialogPrimitives.Content>
    </DialogPrimitives.Root>
  )
}

export default ConfirmationDialog

export const failureFeedbackTitle = 'Aconteceu um erro inesperado'

export const FailureFeedbackDialog = ({
  title = failureFeedbackTitle,
  ...rest
}: ConfirmationDialogProps) => (
  <ConfirmationDialog title={title} {...rest}>
    {rest.children ?? <FailureFeedbackContent />}
  </ConfirmationDialog>
)

export const SuccessFeedbackContent = ({
  message = 'Ação realizada com sucesso.',
}: {
  message?: string
}) => <TextAndImageContent message={message} imageSrc={meditating} />

export const successFeedbackTitle = 'Sucesso!'

export const SuccessFeedbackDialog = ({
  title = successFeedbackTitle,
  ...rest
}: ConfirmationDialogProps) => (
  <ConfirmationDialog title={title} {...rest}>
    {rest.children ?? <SuccessFeedbackContent />}
  </ConfirmationDialog>
)
