import { z } from 'zod'

export const debtStatusItemSchema = z.object({
  text: z.string(),
  value: z.string(),
  selected: z.boolean().default(false),
})

export const filterSchema = z
  .object({
    debtStatus: z.array(debtStatusItemSchema).optional(),
  })
  .optional()
  .nullable()
  .transform(data => {
    return {
      ...data,
      debt_status: data?.debtStatus
        ? data?.debtStatus.reduce<string[]>((acc, status) => {
            if (status.selected) acc.push(status.value)
            return acc
          }, [])
        : null,
    }
  })

export type FilterForm = z.infer<typeof filterSchema>
