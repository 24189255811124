import { useContract } from 'src/escolas/hooks'
import { DATE_FORMAT, formatCentsToReal, formatDate } from 'src/shared/utils'
import { ContractInstallments } from './ContractDetailsDrawerDefaultContent'
import { InstallmentSection } from './InstallmentSection'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { isIsaacPaySchool } from '@/shared/utils/isIsaacPaySchool'
import { Separator } from '@gravity/separator'
import { Text } from '@gravity/text'

export type ContractDetailsSummaryProps = {
  contractInstallments: ContractInstallments
}

export const ContractDetailsSummary = ({ contractInstallments }: ContractDetailsSummaryProps) => {
  const { annuity, enrollment, tuition, isaacPay } = contractInstallments
  const { contract } = useContract()
  const { school } = useSelectedSchool()
  const tuitionInstallments = isIsaacPaySchool(school) ? isaacPay : tuition

  return (
    <div className="flex flex-col gap-4">
      {enrollment.installments_quantity > 0 && (
        <InstallmentSection
          title="Pré-matrícula"
          installments={enrollment}
          testIdPrefix="enrollment"
        />
      )}

      <InstallmentSection
        title="Mensalidades"
        installments={tuitionInstallments}
        testIdPrefix="tuition"
      />

      <Separator color="neutral-2" />

      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between">
          <Text variant="body-1-regular">Anuidade</Text>
          <Text variant="body-1-regular">{formatCentsToReal(annuity.totalAmount)}</Text>
        </div>
        <div className="flex flex-row justify-between">
          <Text variant="body-1-regular">Total de descontos</Text>
          <Text variant="body-1-regular">{formatCentsToReal(annuity.totalDiscounts)}</Text>
        </div>
      </div>

      <Separator color="neutral-2" />

      <div className="flex flex-col gap-2">
        <Text variant="body-2-medium">ID do contrato</Text>
        <Text variant="body-1-regular">{contract?.id}</Text>
      </div>
      <div className="flex flex-col gap-2">
        {contract?.created_at && (
          <Text variant="body-1-regular">{`Contrato criado em ${formatDate(
            contract?.created_at,
            DATE_FORMAT
          )}`}</Text>
        )}
      </div>
    </div>
  )
}
