import { Badge } from '@gravity/badge'
import { Table } from '@gravity/table'
import { Text } from '@gravity/text'
import { Tooltip } from '@gravity/tooltip'
import { Guardian } from '@monorepo/onboarding/services/types/onboardingGuardian'

type GuardianTableCellProps = {
  guardians: Guardian[]
}

const NO_NAME_LABEL = 'Não informado'

export const GuardianTableCell = ({ guardians }: GuardianTableCellProps) => {
  const sortedGuardiansNames = guardians
    .map(guardian => guardian.name)
    .sort((a, b) => {
      if (!a) return 1
      if (!b) return -1

      return a.localeCompare(b)
    })

  const [firstGuardian, ...otherGuardians] = sortedGuardiansNames

  return (
    <Table.CustomCell>
      <Text variant="body-2-regular" className="flex items-center gap-2">
        {firstGuardian ? (
          <Text variant="body-2-regular">{firstGuardian}</Text>
        ) : (
          <Text variant="body-2-regular" className="text-colors-text-semantic-colors-error-1">
            {NO_NAME_LABEL}
          </Text>
        )}

        {otherGuardians.length > 0 && (
          <Tooltip text={otherGuardians.map(guardian => guardian || NO_NAME_LABEL).join('\n')}>
            <Badge variant="soft" color="neutral">
              {`+${otherGuardians.length}`}
            </Badge>
          </Tooltip>
        )}
      </Text>
    </Table.CustomCell>
  )
}
