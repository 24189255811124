import styled from 'styled-components'

export const Form = styled.form`
  padding-top: ${props => props.theme.gravity.spacing[6]};
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const ChipsWrapper = styled.div`
  margin-top: ${props => props.theme.gravity.spacing['2']};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > :last-child {
    margin-right: 0;
  }
`
export const ChipContainer = styled.div`
  margin-top: ${props => props.theme.gravity.spacing['2']};
  margin-right: ${props => props.theme.gravity.spacing['2']};
`
