import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useApiClient } from '@/shared/hooks/useApiClient'

import { reportService } from '@/shared/services/report'

import type {
  FetchPayoutDetailsRequestDTO,
  FetchPayoutDetailsResponseDTO,
} from '@/shared/services/report/types'

export const useEnrollmentPayoutReport = (
  { payoutId, filter, pagination }: FetchPayoutDetailsRequestDTO<'enrollment'>,
  options?: UseQueryOptions<FetchPayoutDetailsResponseDTO<'enrollment'>>
) => {
  const { apiClient } = useApiClient()

  const service = reportService(apiClient.getClients().privateApi)

  return useQuery<FetchPayoutDetailsResponseDTO<'enrollment'>>(
    ['payout', 'enrollment', payoutId, 'details', JSON.stringify({ ...filter, ...pagination })],
    async () => {
      return await service.fetchPayoutDetails('enrollment', {
        payoutId,
        filter,
        pagination,
      })
    },
    options
  )
}
