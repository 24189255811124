import styled from 'styled-components'

export const ColumnContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[2]};
`

export const RowFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.gravity.spacing[2]};
`
