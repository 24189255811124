import styled from 'styled-components'
import { Text } from '@gravity/text'

interface InfoTextProps {
  disabled?: boolean
}

export const DiscountTotalAmount = styled(Text)<InfoTextProps>`
  color: ${props =>
    props.disabled
      ? props.theme.gravity.colors['colors-text-main-4']
      : props.theme.gravity.colors['colors-text-main-3']};
  text-decoration: ${props => (props.disabled ? 'line-through' : '')};
`

export const InfoText = styled(Text)<InfoTextProps>`
  color: ${props =>
    props.disabled
      ? props.theme.gravity.colors['colors-text-main-4']
      : props.theme.gravity.colors['colors-text-main-3']};
`

export const DiscountInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[2]};
`

export const DiscountInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const DiscountInfoItemWrapper = styled.div`
  display: flex;
  gap: ${props => props.theme.gravity.spacing[2]};
  flex-direction: column;
`
export const DiscountInfoItem = styled.div`
  display: flex;
  gap: ${props => props.theme.gravity.spacing[4]};
  justify-content: space-between;
`
