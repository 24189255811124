import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
`

export const FooterSpaceClearance = styled.div`
  padding-bottom: 136px;
`

export const SelectedTableFooterContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  flex-wrap: wrap;
`

export const StickyComponent = styled.div`
  background-color: ${props => props.theme.gravity.colors['colors-background-neutral-1']};

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fade-in 0.15s ease-in forwards;

  position: sticky;
  bottom: 136px;

  &&::before {
    content: '';
    display: block;
    position: absolute;
    background-color: ${props => props.theme.gravity.colors['colors-background-neutral-1']};
    min-width: 120%;
    height: 136px;
    bottom: -136px;
  }
`
export const BackButtonWrapper = styled.div`
  margin-top: ${props => props.theme.gravity.spacing[8]};
`
