import styled from 'styled-components'

type PageContainerProps = {
  isLoading: boolean
}
export const PageContainer = styled.div<PageContainerProps>`
  padding: ${props => props.theme.gravity.spacing[12]} 0;
  ${({ isLoading }) =>
    isLoading &&
    `
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      gap: ${props => props.theme.gravity.spacing[20]};
      & > :nth-child(2) {
        margin-top: -${props => props.theme.gravity.spacing[10]};
      }
    `}
`

export const ComingSoonTextWrapper = styled.div`
  margin-top: ${props => props.theme.gravity.spacing[20]};
  h3 {
    color: ${props => props.theme.gravity.colors['colors-text-main-3']} !important;
  }
`
export const BigNumbersWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.gravity.spacing[6]};
  margin-top: ${props => props.theme.gravity.spacing[4]};
`
