import { formatter } from '@/modules/guardians/utils/formatter'
import { InstallmentStatus, InstallmentType } from '@/modules/guardians/GuardianDetails/constants'
import { InstallmentDrawerInfo } from '@/modules/guardians/InstallmentsDrawerContainer/types'
import { useFooterMessage } from '@/modules/guardians/InstallmentsDrawerContainer/hooks/useFooterMessage'

const titleMap = {
  DOWN_PAYMENT: `Entrada da negociação`,
  NEGOTIATION: `Negociação`,
} as Record<InstallmentType, string>

const installmentStatusMap: Partial<
  Record<InstallmentStatus, { color?: 'accent' | 'error' | 'warning' | 'success'; title: string }>
> = {
  AGGLUTINATED: {
    color: undefined,
    title: 'Aglutinada',
  },
  CANCELED: {
    color: 'error',
    title: 'Vencida',
  },
  DUE_TODAY: {
    color: 'accent',
    title: 'A vencer',
  },
  OPEN: {
    color: 'accent',
    title: 'A vencer',
  },
  OVERDUE: {
    color: 'error',
    title: 'Vencida',
  },
  PAID: {
    color: 'success',
    title: 'Pago',
  },
  PENDING: {
    color: undefined,
    title: 'Aguardando pagamento da entrada',
  },
  RENEGOTIATED: {
    color: undefined,
    title: 'Renegociada',
  },
}

export const useNegotiationDrawer = ({ installment }: { installment: InstallmentDrawerInfo }) => {
  const {
    order_reference,
    credit_card_fee,
    discounts,
    due_date,
    is_from_credit_card_flow,
    paid,
    status,
    type,
  } = installment
  const footerMessage = useFooterMessage({ status, dueDate: due_date, paid })
  const negotiationId = formatter.negotiation_id(installment?.negotiation?.id) ?? ''

  const getInstallmentStatus = (
    status: InstallmentStatus
  ): { color?: 'accent' | 'error' | 'warning' | 'success'; title: string } | undefined => {
    return installmentStatusMap[status]
  }

  const getNotification = (
    type: InstallmentType,
    status: InstallmentStatus
  ): { description: string; variation: 'info' | 'warning' | 'success' | 'neutral' } | undefined => {
    if (type === InstallmentType.DOWN_PAYMENT) {
      if ([InstallmentStatus.OPEN, InstallmentStatus.DUE_TODAY].includes(status)) {
        return {
          variation: 'info',
          description:
            'Para a negociação ser confirmada, essa fatura precisa ser paga até o vencimento.',
        }
      }

      if (status === InstallmentStatus.OVERDUE) {
        return {
          variation: 'warning',
          description:
            'A compensação de pagamento leva até 3 dias úteis. Caso o pagamento não seja identificado, esta negociação será cancelada.',
        }
      }
    }

    if (type === InstallmentType.NEGOTIATION) {
      if (status === InstallmentStatus.CANCELED) {
        return {
          variation: 'warning',
          description:
            'Esta parcela já foi negociada, mas a negociação não foi concluída e/ou foi cancelada.',
        }
      }
    }
  }

  const headerTitle =
    type === InstallmentType.NEGOTIATION && order_reference.includes('1/')
      ? `Entrada da negociação ${negotiationId}`
      : `${titleMap[type]} ${negotiationId}`

  return {
    headerTitle,
    headerStatusInfo: getInstallmentStatus(status),
    notification: getNotification(type, status),
    footerMessage,
    hasCompositeAmount:
      [InstallmentStatus.OVERDUE, InstallmentStatus.CANCELED].includes(status) ||
      (is_from_credit_card_flow && credit_card_fee) ||
      discounts?.length > 0,
  }
}
