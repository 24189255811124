import styled from 'styled-components'

export const CustomDialog = styled.div`
  div[role='dialog'] > div {
    margin: 0;
  }

  div[role='dialog'] > div:last-of-type {
    margin-top: ${props => props.theme.gravity.spacing[3]};
  }

  div[role='dialog'] > div:first-of-type span {
    font-size: ${props => props.theme.gravity.fontSize.lg};
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[7]};

  button {
    margin-left: auto;
  }
`
