import type { ReactNode } from 'react'
import { Avatar } from '@gravity/avatar'
import { Popover } from '@gravity/popover'

type AvatarDropdownTriggerProps = {
  children: ReactNode
}

export const AvatarDropdownTrigger = ({ children }: AvatarDropdownTriggerProps) => (
  <Popover.Trigger className="hover:bg-transparent active:bg-transparent" asChild>
    <button>
      <Avatar.Root size={32} color="gray">
        <Avatar.Fallback>{children}</Avatar.Fallback>
      </Avatar.Root>
    </button>
  </Popover.Trigger>
)
