import { ErrorWarningOutline } from '@gravity/icons'
import { Tooltip } from '@gravity/tooltip'
import { Button } from '@gravity/button'
import { Badge } from '@gravity/badge'

import { InstallmentType, InstallmentTypeLabel } from '@/shared/interfaces'
import { TransactionEventType } from '@monorepo/report/models/report'

enum VariationColors {
  ERROR = 'error',
  NEUTRAL = 'neutral',
  SUCCESS = 'success',
  WARNING = 'warning',
}

const paramsInstallmentDict: PartialRecord<
  InstallmentType,
  { color: VariationColors; text: string }
> = {
  [InstallmentType.TUITION]: {
    color: VariationColors.NEUTRAL,
    text: InstallmentTypeLabel.TUITION,
  },
  [InstallmentType.ENROLLMENT]: {
    color: VariationColors.NEUTRAL,
    text: InstallmentTypeLabel.ENROLLMENT,
  },
}

const paramsEventDict: PartialRecord<
  TransactionEventType,
  { color: VariationColors; text: string }
> = {
  [TransactionEventType.NEW_CONTRACT]: {
    color: VariationColors.SUCCESS,
    text: 'Novo aluno',
  },
  [TransactionEventType.LOST_DISCOUNT]: {
    color: VariationColors.SUCCESS,
    text: 'Adicional desc. antecipação',
  },
  [TransactionEventType.APPLIED_DISCOUNT]: {
    color: VariationColors.WARNING,
    text: 'Novo desconto',
  },
  [TransactionEventType.CANCEL]: {
    color: VariationColors.ERROR,
    text: 'Cancelado',
  },
  [TransactionEventType.SCHOOL_LIQUIDATION]: {
    color: VariationColors.WARNING,
    text: 'Recebido na escola',
  },
  [TransactionEventType.LIQUIDATION_EDITION]: {
    color: VariationColors.WARNING,
    text: 'Edição de recebido na escola',
  },
  [TransactionEventType.CORRECTION]: {
    color: VariationColors.WARNING,
    text: 'Correção pontual',
  },
  [TransactionEventType.AGGLUTINATION_DISCOUNT]: {
    color: VariationColors.WARNING,
    text: 'Desconto por aglutinação de parcelas',
  },
  [TransactionEventType.ORIGINAL_AMOUNT_CHANGED]: {
    color: VariationColors.WARNING,
    text: 'Edição de valor',
  },
}

function ChipWithDisclaimer(props: {
  color: VariationColors
  disclaimer: string
  id: string
  index: number
  label: string
  style: React.CSSProperties
}) {
  const { style, label, color, id, index, disclaimer } = props

  return (
    <>
      <Badge key={index} data-id={id} color={color} variant="soft" style={style}>
        {label}
      </Badge>
      <Tooltip text={disclaimer}>
        <ErrorWarningOutline
          style={{
            width: '1.4rem',
            height: '1.4rem',
            color: '#919191',
          }}
        />
      </Tooltip>
    </>
  )
}

type ChangesChipProps = {
  limit: number
  style?: React.CSSProperties
  tags: Array<TransactionEventType | InstallmentType>
  tooltipDisclaimers?: Partial<Record<TransactionEventType | InstallmentType, string>>
}

export const ChangesChip = ({
  tags,
  limit,
  style = {},
  tooltipDisclaimers = {},
}: ChangesChipProps) => {
  const updatedParamEventDict = Object.entries(paramsEventDict).reduce<
    PartialRecord<TransactionEventType, { color: VariationColors; text: string }>
  >((acc, [key, event]) => {
    if (key === TransactionEventType.NEW_CONTRACT) {
      return {
        ...acc,
        [key]: {
          color: event.color,
          text: 'Novo contrato',
        },
      }
    }

    if (key === TransactionEventType.APPLIED_DISCOUNT) {
      return {
        ...acc,
        [key]: {
          color: event.color,
          text: 'Edição de desconto',
        },
      }
    }

    return {
      ...acc,
      [key]: event,
    }
  }, {})

  const paramsDict = { ...updatedParamEventDict, ...paramsInstallmentDict }
  const available = tags.filter(type => paramsDict[type])
  const plus = available.slice(limit).map(type => paramsDict[type]?.text)

  return (
    <>
      {available?.slice(0, limit)?.map((type, index) => {
        const disclaimer = tooltipDisclaimers[type]
        const params = paramsDict[type]
        if (!params) return null

        return disclaimer ? (
          <ChipWithDisclaimer
            disclaimer={disclaimer}
            id={params.text}
            index={index}
            label={params.text}
            style={style}
            color={params.color}
          />
        ) : (
          <Badge
            key={index}
            data-test-id={params.text}
            color={params.color}
            variant="soft"
            style={style}
          >
            {params.text}
          </Badge>
        )
      })}

      {plus.length > 0 && (
        <Tooltip text={plus.join(', ')} style={{ cursor: 'pointer' }}>
          <Button variant="ghost">
            <Badge style={{ margin: 4, cursor: 'pointer' }} color="neutral" variant="soft">
              {`+${plus.length}`}
            </Badge>
          </Button>
        </Tooltip>
      )}
    </>
  )
}
