import { UseFormReturn } from 'react-hook-form'
import { FormProps } from '../types'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'

type Props = {
  form: UseFormReturn<FormProps>
  isTuitionDisabled?: boolean
}

const MAX_ENROLLMENT_INSTALLMENTS = 5
const MAX_TUITION_INSTALLMENTS = 18

export const useMaxInstallmentValidation = ({ form, isTuitionDisabled }: Props) => {
  const isSkipJanuaryCutoffRuleEnabled = useUnleashFlag(
    UnleashFlags.MAT_1113_ENABLE_SKIP_JANUARY_CUTOFF_RULE
  )
  const { getValues, setValue, formState } = form
  const minEnrollmentInstallments = 1
  const minTuitionInstallments = isTuitionDisabled ? 0 : 1

  const { reference_year, start_month, enrollment_start_month } = getValues()

  const today = dayjs().utc()
  const januaryNextYear = dayjs(`${reference_year}-01-01`).utc().add(1, 'year').endOf('month')

  const helperTextDurationMonthsValidation = (monthsDifference: number) => {
    return `Suas parcelas não podem exceder Janeiro de ${Number(reference_year) + 1}.${
      monthsDifference < 1 ? '' : ` Por favor, selecione até ${monthsDifference} parcelas.`
    }`
  }

  const clearPaymentPlanFields = () => {
    setValue('enrollment_duration_months', minEnrollmentInstallments.toString())
    setValue('enrollment_start_month', dayjs())
    setValue('duration_months', minTuitionInstallments.toString())
    setValue('start_month', dayjs())
  }

  const maxTuitionInstallments = isSkipJanuaryCutoffRuleEnabled
    ? MAX_TUITION_INSTALLMENTS
    : januaryNextYear.diff(today, 'months') + 1

  const isTuitionStartMonthValid = start_month && dayjs(start_month).isValid()

  const calcTuitionDurationMonthsLimit = () => {
    const selectedTuitionStartMonth = dayjs(start_month).utc()

    const monthsTuitionDifferenceStartMonth =
      januaryNextYear.diff(selectedTuitionStartMonth, 'months') + 1

    return Math.min(monthsTuitionDifferenceStartMonth, maxTuitionInstallments)
  }

  const isSelectedTuitionDurationMonthsValid = (value: string) => {
    if (isSkipJanuaryCutoffRuleEnabled) return true

    if (isTuitionStartMonthValid) {
      return +value >= minTuitionInstallments && +value <= calcTuitionDurationMonthsLimit()
    }
    return true
  }

  const tuitionDurationMonthsValidation =
    !isSkipJanuaryCutoffRuleEnabled &&
    isTuitionStartMonthValid &&
    formState.errors.duration_months?.type === 'validate'

  const tuitionDurationMonthsValidationText = tuitionDurationMonthsValidation
    ? helperTextDurationMonthsValidation(calcTuitionDurationMonthsLimit())
    : ''

  const maxEnrollmentInstallments = Math.min(
    januaryNextYear.diff(today, 'months') + 1,
    MAX_ENROLLMENT_INSTALLMENTS
  )

  const isEnrollmentStartMonthValid =
    enrollment_start_month && dayjs(enrollment_start_month).isValid()

  const calcEnrollmentDurationMonthsLimit = () => {
    const selectedEnrollmentStartMonth = dayjs(enrollment_start_month).utc()

    const monthsEnrollmentDifferenceStartMonth =
      januaryNextYear.diff(selectedEnrollmentStartMonth, 'months') + 1

    return Math.min(monthsEnrollmentDifferenceStartMonth, MAX_ENROLLMENT_INSTALLMENTS)
  }

  const isSelectedEnrollmentDurationMonthsValid = (value: string) => {
    if (isSkipJanuaryCutoffRuleEnabled) return true

    if (isEnrollmentStartMonthValid) {
      return +value >= 1 && +value <= calcEnrollmentDurationMonthsLimit()
    }
    return true
  }

  const enrollmentDurationMonthsValidation =
    !isSkipJanuaryCutoffRuleEnabled &&
    isEnrollmentStartMonthValid &&
    formState.errors.enrollment_duration_months?.type === 'validate'

  const enrollmentDurationMonthsValidationText = enrollmentDurationMonthsValidation
    ? helperTextDurationMonthsValidation(calcEnrollmentDurationMonthsLimit())
    : ''

  useEffect(() => {
    clearPaymentPlanFields()
  }, [reference_year])

  return {
    maxTuitionInstallments,
    minTuitionInstallments,
    maxEnrollmentInstallments,
    minEnrollmentInstallments,
    isSelectedEnrollmentDurationMonthsValid,
    isSelectedTuitionDurationMonthsValid,
    enrollmentDurationMonthsValidationText,
    tuitionDurationMonthsValidationText,
    enrollmentDurationMonthsValidation,
    tuitionDurationMonthsValidation,
  }
}
