import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Grid, GridItem } from '@gravity/grid'
import { Stepper, useSteps } from '@gravity/stepper'
import { FullScreenHeader } from '@gravity/header'

import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useSetPageTitle } from '@/shared/hooks/useSetPageTitle'
import { useLayout } from '@/shared/hooks/useLayout'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { ErrorDialog } from '@/shared/components/ErrorDialog'

import { ContractSelectionSep } from './components/ContractSelectionStep'
import { PageHeader } from './components/PageHeader'
import * as Styled from './styles'
import { useContracts } from './hooks/useContracts'
import { Steps } from './types'
import { AddNewGuardianStep } from './components/AddNewGuardianStep'
import type { AddNewGuardianFormData } from './components/AddNewGuardianStep/types'
import { ReviewStep } from './components/ReviewStep'
import { defaultFormData } from './components/AddNewGuardianStep/constants'

export const ChangeContractOwnership = () => {
  useSetPageTitle('Troca de titularidade de contrato')
  const history = useHistory()
  useLayout({ enableSideMenu: false, enableHeader: false })

  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  const steps = ['Selecionar contrato', 'Informar novo responsável', 'Revise as edições']
  const { activeStep, goToNextStep, goToPreviousStep } = useSteps({
    count: steps.length,
  })
  const isFirstStep = activeStep === 0

  const { guardianId } = useParams<{ guardianId: uuid }>()
  const { school, hasErrorOnFetchSchool } = useSelectedSchool()
  const selectedSchoolId = school?.id ?? ''

  const {
    contracts,
    handleSelectContract,
    isContractSelected,
    contractsQuery,
    selectedContractIds,
  } = useContracts(guardianId, selectedSchoolId)

  const [newGuardian, setNewGuardian] = useState<AddNewGuardianFormData>(defaultFormData)

  const goToGuardianPage = (newGuardianId: uuid) => {
    if (!school || !newGuardianId) return
    history.push(`/${school.slug}/responsaveis/${newGuardianId}/contratos`)
  }

  useEffect(() => window.scrollTo(0, 0), [activeStep])

  return (
    <>
      <ErrorDialog isError={contractsQuery.isError || hasErrorOnFetchSchool} />

      {renderNewHeader ? (
        <FullScreenHeader
          className="fixed left-0 top-0 right-0 z-[99]"
          title="Troca de titularidade de contrato"
          onClose={() => history.goBack()}
        />
      ) : (
        <PageHeader />
      )}

      <Styled.PageWrapper className={renderNewHeader ? 'mt-32' : 'mt-14'}>
        <Grid>
          <GridItem xs={4} sm={8} md="4 / 10" lg="4 / 10" xl="4 / 10">
            <Styled.StepWrapper>
              <Stepper.Root activeStep={activeStep} className="items-start">
                {steps.map((step, key) => (
                  <Stepper.Step className="flex-1" key={key} step={key}>
                    <Stepper.StatusIcon />
                    <Stepper.Title>{step}</Stepper.Title>
                  </Stepper.Step>
                ))}
              </Stepper.Root>
            </Styled.StepWrapper>

            {activeStep === Steps.CONTRACT_SELECTION && (
              <ContractSelectionSep
                selectedContractIds={selectedContractIds}
                isLoading={contractsQuery.isLoading}
                isSelected={isContractSelected}
                contracts={contracts}
                goBackBtnDisabled={isFirstStep}
                onSelectContract={handleSelectContract}
                onComplete={goToNextStep}
                onClickGoBack={goToPreviousStep}
              />
            )}
            {activeStep === Steps.ADD_NEW_GUARDIAN && (
              <AddNewGuardianStep
                schoolId={selectedSchoolId}
                schoolSlug={school?.slug ?? ''}
                goBackBtnDisabled={isFirstStep}
                onComplete={goToNextStep}
                onClickGoBack={goToPreviousStep}
                newGuardian={newGuardian}
                setNewGuardian={setNewGuardian}
              />
            )}

            {activeStep === Steps.REVIEW && (
              <ReviewStep
                selectedContractIds={selectedContractIds}
                isSelected={isContractSelected}
                contracts={contracts}
                newGuardian={newGuardian}
                currentGuardianId={guardianId}
                schoolId={selectedSchoolId}
                onComplete={goToGuardianPage}
                onClickGoBack={goToPreviousStep}
                goBackBtnDisabled={isFirstStep}
              />
            )}
          </GridItem>
        </Grid>
      </Styled.PageWrapper>
    </>
  )
}
