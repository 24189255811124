import { DashedArrowRight, RefreshOutline } from '@gravity/icons'
import { LayoutTemplate } from '../../components/LayoutTemplate'
import { BypassSwitch } from './BypassSwitch'
import { useJWT } from '@/shared/hooks'
import { Text } from '@gravity/text'
import { BigNumber } from '../../components/BigNumber'
import { useBatchSummaryQuery } from '@monorepo/onboarding/v2/hooks/useBatchSummaryQuery'
import { useParams } from 'react-router-dom'
import { GenericError } from '../../components/GenericError'
import { EventProvider } from '../../contexts/eventsContext'
import { PendingDataDialog } from './PendingDataDialog'
import { useState } from 'react'
import { useGetOnboardingBatch } from '@monorepo/onboarding/hooks/useFetchOnboardingBatches'
import { OverrideBatch } from '@monorepo/onboarding/pages/components/OverrideBatch'
import { StudentsTable } from './StudentsTable'
import { Loading } from '../../components/Loading'

const PageComponent = () => {
  const { isBackofficeUser } = useJWT()
  const { batchID } = useParams<{ batchID: string }>()
  const [isPendingDataDialogOpen, setIsPendingDataDialogOpen] = useState(false)
  const [isOverrideBatchOpen, setIsOverrideBatchOpen] = useState(false)

  const {
    data: summary,
    isLoading: isLoadingSummary,
    isError: isErrorSummary,
  } = useBatchSummaryQuery({ batchID })

  const { data: batch, isLoading: isLoadingBatch, isError: isErrorBatch } = useGetOnboardingBatch({
    batchID,
  })

  // TODO: Change this to the appropriate function
  const navigateToSomewhere = () => {
    console.log('navigate')
  }

  if (isLoadingSummary || isLoadingBatch) {
    return <Loading />
  }

  if (isErrorSummary || isErrorBatch) {
    return <GenericError />
  }

  const { unfinished_students, finished_students, student_full_scholarship_count } = summary

  const hasPendingData = unfinished_students > 0

  return (
    <LayoutTemplate
      onClose={navigateToSomewhere}
      title="Ferramenta de importação"
      primaryButton={{
        disabled: !batch?.can_submit,
        onClick: () => {
          if (hasPendingData) return setIsPendingDataDialogOpen(true)

          // TODO: Change this to the appropriate function
          return navigateToSomewhere()
        },
        label: 'Próximo',
        iconEnd: <DashedArrowRight />,
      }}
      secondaryButton={{
        onClick: () => {
          setIsOverrideBatchOpen(true)
        },
        label: 'Substituir planilha',
        iconStart: <RefreshOutline />,
      }}
    >
      <div className="flex flex-col gap-8">
        {isBackofficeUser && <BypassSwitch />}

        <Text variant="subtitle-regular">Dados identificados na planilha</Text>

        <div className="flex gap-14">
          <BigNumber
            variant="soft"
            color="error"
            badgeText="Correções pendentes"
            headingText={unfinished_students.toString()}
            subtitleText="alunos"
          />

          <BigNumber
            variant="soft"
            color="success"
            badgeText="Prontos para migrar"
            headingText={finished_students.toString()}
            subtitleText="alunos"
          />
        </div>

        <Text variant="body-2-regular" className="text-colors-text-main-3">
          {student_full_scholarship_count} bolsistas integrais identificados.
        </Text>

        <StudentsTable />
      </div>

      <PendingDataDialog
        open={isPendingDataDialogOpen}
        setOpen={setIsPendingDataDialogOpen}
        onConfirm={navigateToSomewhere}
        onCancel={() => setIsPendingDataDialogOpen(false)}
      />

      <OverrideBatch open={isOverrideBatchOpen} onClose={() => setIsOverrideBatchOpen(false)} />
    </LayoutTemplate>
  )
}

export const StudentsPage = () => {
  return (
    <EventProvider>
      <PageComponent />
    </EventProvider>
  )
}
