import { UseFormSetValue } from 'react-hook-form'
import {
  FiltersContractsFormType,
  FiltersContractsGroupType,
} from '../components/GuardianDetailsContractsFilter/types'
import { AllFiltersOptionsType } from '../types'

/**
 * @description Make a group of filters to be used in Filters Drawer.
 * @param allFiltersOptions Options of filters by the API.
 * @param setValue React Hook Form setValue function.
 * @returns A group of filters FiltersGroupType[].
 */
export const filtersGroup = (
  allFiltersOptions: AllFiltersOptionsType,
  setValue: UseFormSetValue<Omit<FiltersContractsFormType, 'status'>>
): FiltersContractsGroupType[] => {
  return [
    {
      label: 'Status do contrato',
      filterName: 'contract_statuses',
      options: allFiltersOptions.contractStatus,
      totalOptions: allFiltersOptions.contractStatus?.length || 0,
      searchText: '',
      onClearFilter: () => setValue('contract_statuses', []),
    },
    {
      label: 'Ano',
      filterName: 'reference_years',
      options: allFiltersOptions.year,
      totalOptions: allFiltersOptions.year?.length || 0,
      searchText: '',
      onClearFilter: () => setValue('reference_years', []),
    },
    {
      label: 'Produto',
      filterName: 'product_ids',
      options: allFiltersOptions.products,
      totalOptions: allFiltersOptions.products?.length || 0,
      searchText: '',
      onClearFilter: () => setValue('product_ids', []),
    },
    {
      label: 'Situação financeira',
      filterName: 'debt_statuses',
      options: allFiltersOptions.debtStatus,
      totalOptions: allFiltersOptions.debtStatus?.length || 0,
      searchText: '',
      onClearFilter: () => setValue('debt_statuses', []),
    },
  ]
}
