import { RadioGroup, RadioGroupItem } from '@gravity/radio'
import { Text } from '@gravity/text'
import { TextField } from '@gravity/text-field'
import { Tooltip } from '@gravity/tooltip'
import { IconButton } from '@gravity/icon-button'
import { Information } from '@gravity/icons'
import { Controller, UseFormReturn } from 'react-hook-form'
import NumberFormat from 'react-number-format'

import { path } from 'ramda'

export type SelectDueDayProps = {
  form: UseFormReturn<any>
  inputName: string
  radioName: string
}

const SelectDueDay = ({ form, radioName, inputName }: SelectDueDayProps) => {
  const { control, getValues, setValue, formState } = form
  const { [radioName]: useWorkingDates } = getValues()

  const hasInputError = Boolean(path(inputName.split('.'), formState.errors))
  const isWorkingDate = useWorkingDates === 'true'
  return (
    <div className="flex items-center">
      <Controller
        rules={{ required: true }}
        control={control}
        aria-label={radioName}
        name={radioName}
        render={({ field: { onChange, name, value } }) => (
          <RadioGroup name={name} value={value} onValueChange={onChange}>
            <div className="flex items-center gap-1">
              <RadioGroupItem
                size={2}
                value="true"
                label="Vencimento por dia útil"
                id="Vencimento por dia útil"
                onChange={() => {
                  setValue(inputName, null)
                }}
              />
              <div className="pl-1 flex">
                <Tooltip text="Vencimento sempre no xº dia útil">
                  <IconButton type="button" variant="ghost" size={1}>
                    <Information color="gray" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            {isWorkingDate && (
              <Controller
                rules={{
                  required: true,
                  validate: value => value > 0 && value <= 25,
                }}
                name={inputName}
                control={control}
                render={({ formState, fieldState, field: { onChange, value, ...rest } }) => {
                  const invalid = value && !(value > 0 && value <= 25)
                  return (
                    <div className="mb-4 pl-7">
                      <div className="flex flex-row items-center">
                        <div className="flex w-[100px]">
                          <NumberFormat
                            {...formState}
                            {...fieldState}
                            {...rest}
                            value={value}
                            data-testid={`working-${inputName}-input`}
                            customInput={TextField}
                            size={3}
                            onValueChange={currentValue => {
                              onChange(currentValue.value)
                            }}
                            error={invalid || hasInputError}
                          />
                        </div>
                        <Text className="ml-3" variant="caption-regular">
                          dia útil cada mês
                        </Text>
                      </div>
                      {(invalid || hasInputError) && (
                        <Text
                          className="text-colors-text-semantic-colors-error-1"
                          variant="caption-regular"
                        >
                          O dia útil precisa ser menor ou igual a 25
                        </Text>
                      )}
                    </div>
                  )
                }}
              />
            )}
            <div className="flex items-center gap-1">
              <RadioGroupItem
                size={2}
                value="false"
                label="Vencimento por dia fixo"
                id="Vencimento por dia fixo"
                onChange={() => {
                  setValue(inputName, null)
                }}
              />
              <Tooltip text="Vencimento sempre no mesmo dia">
                <IconButton type="button" variant="ghost" size={1}>
                  <Information color="gray" />
                </IconButton>
              </Tooltip>
            </div>
            {!isWorkingDate && (
              <Controller
                rules={{ required: true, validate: value => value > 0 && value <= 31 }}
                name={inputName}
                control={control}
                render={({ formState, fieldState, field: { onChange, value, ...rest } }) => {
                  const invalid = value && !(value > 0 && value <= 31)
                  return (
                    <div className="mb-4 pl-7">
                      <div className="w-[100px]">
                        <NumberFormat
                          {...formState}
                          {...fieldState}
                          {...rest}
                          value={value}
                          placeholder="Dia"
                          size={3}
                          data-testid={`fixed-${inputName}-input`}
                          customInput={TextField}
                          onValueChange={currentValue => {
                            onChange(currentValue.value)
                          }}
                          error={invalid || hasInputError}
                        />
                      </div>
                      {(invalid || hasInputError) && (
                        <Text
                          className="text-colors-text-semantic-colors-error-1"
                          variant="caption-regular"
                        >
                          O dia fixo precisa ser menor ou igual a 31
                        </Text>
                      )}
                    </div>
                  )
                }}
              />
            )}
          </RadioGroup>
        )}
      />
    </div>
  )
}

export default SelectDueDay
