import { SchoolReportingPeriod } from '@/shared/models/SchoolChargeOperation'
import { School } from '@/shared/models/School'

export const getPayoutSubtitle = (isPayoutOpen: boolean) => {
  return isPayoutOpen ? 'Valor acumulado' : 'Valor total'
}

export const getSchoolReportingPeriod = (school: School | undefined): SchoolReportingPeriod => {
  return (
    (school?.school_charge_config?.reporting_period as SchoolReportingPeriod) ??
    SchoolReportingPeriod.weekly
  )
}
