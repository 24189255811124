import styled from 'styled-components'

export const Wrapper = styled.div`
  display: inline-grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: ${({ theme }) => theme.gravity.spacing['6']};
  grid-row-gap: ${({ theme }) => theme.gravity.spacing['8']};
`
export const Item = styled.div`
  padding-top: ${({ theme }) => theme.gravity.spacing['2']};
  height: 100%;
  > div {
    height: 100%;
  }
`
