import { useHistory, useParams } from 'react-router-dom'
import { Grid, GridItem } from '@gravity/grid'
import { Stepper } from '@gravity/stepper'
import { FullScreenHeader } from '@gravity/header'

import { CircularLoadingPlaceholder } from '@/shared/components/CircularLoadingPlaceholder'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useLayout } from '@/shared/hooks/useLayout'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useSelectPos } from '@/escolas/hooks'

import EntryStatusDialog from './EntryStatusDialog'
import StatusCheckout from '../components/StatusCheckout/StatusCheckout'
import EntryDetails from './steps/EntryDetails'
import SelectPaymentMethod from './steps/SelectPaymentMethod'
import useStepsEntryFlow from './hooks/useEntryFlowSteps'
import useEntryNegotiation from './hooks/useEntryNegotiation'
import useCheckoutCart from '@/modules/guardians/hooks/useCheckoutCart'
import EntryConclusion from './steps/EntryConclusion'
import { MAX_STEP_WIDTH } from '../constants'

export const EntryNegotiation = () => {
  const { goBack: goBackHistory } = useHistory()
  const { guardianId } = useParams<{ guardianId: uuid }>()
  const {
    stepsTitles,
    steps,
    currentStep,
    goBack,
    goToNextStep,
    handleOnFinishStep,
  } = useStepsEntryFlow()
  const { checkoutCart } = useCheckoutCart()
  const urn = checkoutCart[0].urn

  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  useLayout({
    enableHeader: false,
    enableSideMenu: !renderNewHeader,
  })

  const stepperContainerVariant = { 783: 'my-0 mx-auto mt-8 max-w-[783px]' }

  const {
    checkoutValues,
    showStatusDialog,
    statusDialogContent,
    isError,
    closeErrorDialog,
    handleOnNegotiationStep,
    currentVariantDialog,
    isLoading,
    entryInformation,
    negotiationIsLoading,
    negotiationData,
  } = useEntryNegotiation(goToNextStep, urn, guardianId)

  const posName = statusDialogContent.pos?.pdv_name

  const { school } = useSelectedSchool()
  useSelectPos(school.id)

  const stepComponents = [
    <EntryDetails
      entryInformation={entryInformation}
      key="entry_details_step"
      goBack={goBack}
      onFinish={goToNextStep}
    />,
    <SelectPaymentMethod
      key="select_payment_method_step"
      entryInformation={entryInformation}
      goBack={goBack}
      onFinish={handleOnNegotiationStep}
      isLoading={negotiationIsLoading}
    />,
    <EntryConclusion
      key="conclusion_step"
      goBack={goBack}
      onFinish={handleOnFinishStep}
      conclusionInfo={negotiationData}
      entryInformation={entryInformation}
    />,
  ]

  return (
    <>
      {renderNewHeader ? (
        <FullScreenHeader
          className="fixed left-0 top-0 z-[99]"
          title="Pagamento"
          onClose={goBackHistory}
        />
      ) : (
        ''
      )}

      <div className={`flex flex-col items-center ${renderNewHeader ? 'mt-18' : 'mt-0'}`}>
        <Grid className="grid-container">
          <GridItem xl={12} lg={12} md={12} sm={8} xs={4}>
            <div className={stepperContainerVariant[MAX_STEP_WIDTH]}>
              <div className="w-fit m-auto my-5">
                <Stepper.Root activeStep={currentStep}>
                  {steps.map((_, index) => (
                    <Stepper.Step key={index} step={index}>
                      <Stepper.StatusIcon />
                      <Stepper.Title>{stepsTitles[index]}</Stepper.Title>
                    </Stepper.Step>
                  ))}
                </Stepper.Root>
              </div>
              {isLoading ? <CircularLoadingPlaceholder /> : stepComponents[currentStep]}
              <EntryStatusDialog
                isOpen={showStatusDialog}
                showErrorDialog={isError}
                closeErrorDialog={closeErrorDialog}
                variant={currentVariantDialog}
                posName={posName}
                {...statusDialogContent}
              />
              <StatusCheckout
                closeErrorDialog={checkoutValues.closeCheckoutErrorDialog}
                {...checkoutValues}
                pos={statusDialogContent.pos}
                shortIds={[statusDialogContent?.shortId]}
              />
            </div>
          </GridItem>
        </Grid>
      </div>
    </>
  )
}

export default EntryNegotiation
