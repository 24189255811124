export enum IrregularStudentStatus {
  FINISHED = 'finished',
  IN_PROGRESS = 'in_progress',
  NOT_STARTED = 'not_started',
}

export enum IrregularStudentStatusFilter {
  FINISHED = 'FINISHED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
}

export enum IrregularStudentStatusPayloadResponse {
  finished = 'finished',
  in_progress = 'in_progress',
  not_started = 'not_started',
}

export const irregularStatusList: Array<IrregularStudentStatus> = [
  IrregularStudentStatus.NOT_STARTED,
  IrregularStudentStatus.IN_PROGRESS,
  IrregularStudentStatus.FINISHED,
]

export enum AddressIrregularityType {
  CITY = 'ADDRESS_CITY',
  STATE_CODE = 'ADDRESS_STATE_CODE',
  STREET = 'ADDRESS_STREET',
  ZIP_CODE = 'ADDRESS_ZIP_CODE',
}

export enum GuardianIrregularityType {
  GUARDIAN_EMAIL = 'GUARDIAN_EMAIL',
  GUARDIAN_NAME = 'GUARDIAN_NAME',
  GUARDIAN_PHONE_NUMBER = 'GUARDIAN_PHONE_NUMBER',
}

export enum StudentIrregularityType {
  STUDENT_BIRTH_DATE = 'STUDENT_BIRTH_DATE',
  STUDENT_TAX_ID = 'STUDENT_TAX_ID',
}

export const irregularStatusTranslationDict: Record<IrregularStudentStatus, string> = {
  [IrregularStudentStatus.FINISHED]: 'Correção concluída',
  [IrregularStudentStatus.IN_PROGRESS]: 'Correção iniciada',
  [IrregularStudentStatus.NOT_STARTED]: 'Correção pendente',
}

export const guardianIrregularityParamsDict: Record<
  GuardianIrregularityType | AddressIrregularityType,
  IrregularityParams
> = {
  [AddressIrregularityType.CITY]: {
    name: 'Endereço do responsável',
    action: 'Informar cidade do endereço',
  },
  [AddressIrregularityType.STATE_CODE]: {
    name: 'Endereço do responsável',
    action: 'Informar UF válido para o endereço',
  },
  [AddressIrregularityType.STREET]: {
    name: 'Endereço do responsável',
    action: 'Informar logradouro do endereço',
  },
  [AddressIrregularityType.ZIP_CODE]: {
    name: 'Endereço do responsável',
    action: 'Informar CEP válido para o endereço',
    src: 'https://www.youtube.com/embed/Ck7oP9eX-K0?si=Wkc5Id70Vur6hgyd',
    title: 'Como corrigir pendências do CEP inválido no ActiveSoft',
    description: 'Assista o tutorial de como corrigir pendências do CEP inválido',
  },
  [GuardianIrregularityType.GUARDIAN_EMAIL]: {
    name: 'Contato do responsável',
    action: 'Informar e-mail válido',
  },
  [GuardianIrregularityType.GUARDIAN_NAME]: {
    name: 'Contato do responsável',
    action: 'Informar nome do responsável',
  },
  [GuardianIrregularityType.GUARDIAN_PHONE_NUMBER]: {
    name: 'Contato do responsável',
    action: 'Informar celular válido, que deve ter de 10 a 13 dígitos',
  },
}

export const studentIrregularityParamsDict: Record<StudentIrregularityType, IrregularityParams> = {
  [StudentIrregularityType.STUDENT_TAX_ID]: {
    name: 'Informação do aluno',
    action: 'Informar CPF válido',
  },
  [StudentIrregularityType.STUDENT_BIRTH_DATE]: {
    name: 'Informação do aluno',
    action: 'Informar data de nascimento válida',
  },
}

export enum InstallmentIrregularityType {
  DISCOUNT_AMOUNT = 'DISCOUNT_AMOUNT',
  DISCOUNT_DAYS_BEFORE_DUE_DATE = 'DISCOUNT_DAYS_BEFORE_DUE_DATE',
  DISCOUNT_DESCRIPTION = 'DISCOUNT_DESCRIPTION',
  DISCOUNTS_LENGTH = 'DISCOUNTS_LENGTH',
  DISCOUNTS_TOTAL_AMOUNT = 'DISCOUNTS_TOTAL_AMOUNT',
  DUPLICATED_ACCRUAL_DATE = 'DUPLICATED_ACCRUAL_DATE',
  INSTALLMENT_AMOUNT = 'INSTALLMENT_AMOUNT',
  INSTALLMENT_COMPETENCE_DATE = 'INSTALLMENT_COMPETENCE_DATE',
  INSTALLMENT_DUE_DATE_EXPIRED = 'INSTALLMENT_DUE_DATE_EXPIRED',
  INSTALLMENT_DUE_DATE_MISSING = 'INSTALLMENT_DUE_DATE_MISSING',
  INSTALLMENT_TYPE = 'INSTALLMENT_TYPE',
  INVALID_FORMAT_ACCRUAL_DATE = 'INVALID_FORMAT_ACCRUAL_DATE',
}

export type IrregularityParams = {
  action: string
  description?: string
  name: string
  src?: string
  title?: string
}

export const installmentIrregularityParamsDict: Record<
  InstallmentIrregularityType,
  IrregularityParams
> = {
  [InstallmentIrregularityType.INSTALLMENT_DUE_DATE_MISSING]: {
    name: 'Vencimento da parcela',
    action: 'Informar vencimento da parcela, que não pode ser anterior a hoje',
  },
  [InstallmentIrregularityType.INSTALLMENT_DUE_DATE_EXPIRED]: {
    name: 'Vencimento da parcela',
    action: 'Corrigir vencimento da parcela, que não pode ser anterior a hoje',
    src: 'https://www.youtube.com/embed/qVaNEHewMTE?si=bYiXr7s4eM30DEeZ',
    title: 'Como corrigir pendências de vencimento da parcela no ActiveSoft',
    description: 'Assista o tutorial de como corrigir pendências do vencimento de parcela',
  },
  [InstallmentIrregularityType.INSTALLMENT_COMPETENCE_DATE]: {
    name: 'Competência da parcela',
    action: 'É permitido somente uma parcela por mês de competência neste produto',
  },
  [InstallmentIrregularityType.INVALID_FORMAT_ACCRUAL_DATE]: {
    name: 'Competência da parcela',
    action: 'Corrigir competência da parcela, que deve estar no formato ano-mês',
  },
  [InstallmentIrregularityType.DUPLICATED_ACCRUAL_DATE]: {
    name: 'Duplicação de parcela',
    action: 'É permitido somente uma parcela por mês de competência neste produto',
    src: 'https://www.youtube.com/embed/W8kTbEPw4oI?si=Ruv3g0y2jkURUsj3',
    title: 'Como corrigir pendências de duplicidade de parcela no ActiveSoft',
    description: 'Assista o tutorial de como corrigir pendências de duplicidade de parcela',
  },
  [InstallmentIrregularityType.INSTALLMENT_AMOUNT]: {
    name: 'Valor padrão da parcela',
    action: 'Corrigir valor da parcela, que deve ser maior do que 0',
  },
  [InstallmentIrregularityType.INSTALLMENT_TYPE]: {
    name: 'Tipo da parcela',
    action: 'Corrigir tipo da parcela, que deve ser Pré-matrícula ou Mensalidade',
  },
  [InstallmentIrregularityType.DISCOUNTS_TOTAL_AMOUNT]: {
    name: 'Desconto total da parcela',
    action: 'Corrigir valor total de desconto, que não pode ser maior que da parcela',
    src: 'https://www.youtube.com/embed/fteqw18ylOg?si=B1Q_zyjpC6k5VJKa',
    title: 'Como corrigir pendências do valor de desconto no ActiveSoft',
    description: 'Assista o tutorial de como corrigir pendências do valor de desconto',
  },
  [InstallmentIrregularityType.DISCOUNTS_LENGTH]: {
    name: 'Desconto total da parcela',
    action: 'Corrigir quantidade de descontos, que não pode se maior que 3 por parcela',
  },
  [InstallmentIrregularityType.DISCOUNT_AMOUNT]: {
    name: 'Configuração do desconto',
    action: 'Corrigir valor do desconto, que deve ser maior do que 0',
  },
  [InstallmentIrregularityType.DISCOUNT_DESCRIPTION]: {
    name: 'Configuração do desconto',
    action: 'Corrigir descrição do desconto, que deve ter entre 5 e 100 caracteres',
    src: 'https://www.youtube.com/embed/baqVHH1RjOM?si=KzzwjgpCwsH_u7mr',
    title: 'Como corrigir pendências da descrição de desconto no ActiveSoft',
    description: 'Assista o tutorial de como corrigir pendências da descrição de desconto',
  },
  [InstallmentIrregularityType.DISCOUNT_DAYS_BEFORE_DUE_DATE]: {
    name: 'Configuração do desconto',
    action:
      'Corrigir validade do desconto, que não pode ser depois do vencimento ou mais de 120 dias antes do vencimento',
  },
}

export enum InstallmentType {
  ENROLLMENT = 'ENROLLMENT',
  TUITION = 'TUITION',
  UNKNOWN = 'UNKNOWN',
}

export const installmentTypeParamsDict: Record<InstallmentType, string> = {
  [InstallmentType.ENROLLMENT]: 'Matricula',
  [InstallmentType.TUITION]: 'Mensalidade',
  [InstallmentType.UNKNOWN]: 'Tipo não indicado',
}
