import { Checkbox } from '@gravity/checkbox'
import { ChoiceChip } from '@gravity/chip'
import { Separator } from '@gravity/separator'
import { Text } from '@gravity/text'
import { FilterOptions, ComponentTypes } from '@monorepo/report/models/report'

type Props<T> = {
  availableFilters: FilterOptions<T>
  filterTypes: Record<keyof T, string>
  onFilterSelect: <K extends keyof T>(filterType: K, value: string) => void
  selectedFilters: T
}

export const Content = <Filter extends Record<string, string[]>>({
  availableFilters,
  filterTypes,
  onFilterSelect,
  selectedFilters,
}: Props<Filter>) => {
  const isFilterOptionSelected = (filterType: keyof Filter, value: Filter[keyof Filter][number]) =>
    selectedFilters[filterType]?.includes(value)

  return (
    <div className="w-full flex flex-col gap-8">
      {Object.keys(availableFilters)
        .filter(f => !!availableFilters[f].length)
        .map((filterType, index, filters) => {
          const isCheckbox = availableFilters[filterType].some(
            filter => filter.component === ComponentTypes.CHECKBOX
          )

          return (
            <div className="flex flex-col" key={filterType}>
              <div className="flex flex-col gap-8">
                <Text variant="subtitle-medium">{filterTypes[filterType]}</Text>

                <div className={isCheckbox ? 'flex flex-col gap-4' : 'flex flex-wrap gap-2'}>
                  {availableFilters[filterType].map(filter =>
                    isCheckbox ? (
                      <Checkbox
                        key={filter.label}
                        size={1}
                        text={filter.label}
                        value={filter.value}
                        checked={isFilterOptionSelected(filterType, filter.value)}
                        onCheckedChange={() => onFilterSelect(filterType, filter.value)}
                      />
                    ) : (
                      <ChoiceChip
                        key={filter.label}
                        className="w-fit"
                        text={filter.label}
                        value={filter.value}
                        onSelected={() => onFilterSelect(filterType, filter.value)}
                        selected={isFilterOptionSelected(filterType, filter.value)}
                      />
                    )
                  )}
                </div>
              </div>

              {index !== filters.length - 1 && <Separator color="neutral-2" className="mt-8" />}
            </div>
          )
        })}
    </div>
  )
}
