import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { useApiClient } from '@/shared/hooks/useApiClient'

import { GetEnrollmentRequestResponse } from '@/shared/services/irregularities/types'
import { enrollmentRequestService } from '@/shared/services/irregularities'

export const useGetEnrollmentRequestsQuery = (
  guardianTaxId: string,
  schoolId: string,
  status: string[] = [],
  options?: UseQueryOptions<GetEnrollmentRequestResponse>
) => {
  const { apiClient } = useApiClient()

  const service = enrollmentRequestService(apiClient.privateApi)

  return useQuery<GetEnrollmentRequestResponse>(
    ['enrollment-request', schoolId, guardianTaxId],
    () => service.getEnrollmentRequests(guardianTaxId, schoolId, status),
    options
  )
}
