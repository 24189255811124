import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { FiltersDialog } from '@/shared/components/FiltersDialog'

import { ListGuardiansFilterProps, ListGuardiansFilterType } from './types'
import { OPTIONS } from './constants'
import handleSendEvent from './events'
import { FilterChip } from './FilterChip'
import { FilterForm, filterSchema } from './schemas/filterSchemas'
import { zodResolver } from '@hookform/resolvers/zod'
import { DebtStatus } from '@/shared/interfaces'

const ListGuardiansFilter = ({
  filters,
  updateFilters,
  isOpen,
  setIsOpen,
}: ListGuardiansFilterProps) => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const defaultValues: FilterForm = {
    debtStatus: OPTIONS.map(debtStatus => {
      return {
        ...debtStatus,
        selected: false,
      }
    }),
    debt_status: filters.debt_status ?? [],
  }

  const form = useForm<FilterForm>({
    mode: 'all',
    defaultValues,
    resolver: zodResolver(filterSchema),
  })

  const { setValue, reset } = form

  useEffect(() => {
    setValue(
      'debtStatus',
      OPTIONS.map(debtStatus => {
        return {
          ...debtStatus,
          selected: filters.debt_status?.includes(debtStatus.value as DebtStatus) ?? false,
        }
      })
    )
  }, [open, filters])

  const handleApplyFilters: SubmitHandler<ListGuardiansFilterType> = (
    data: ListGuardiansFilterType
  ) => {
    setValue('debt_status', data.debt_status)
    updateFilters(data)
    setIsOpen(false)
    handleSendEvent(data, isInitialized, eventDispatcherClient)
  }

  const handleClearFilter = () => {
    reset(defaultValues)
  }

  const filtersGroup = [
    {
      id: 1,
      title: 'Situação financeira',
      component: <FilterChip control={form.control} filterType="debtStatus" />,
    },
  ]

  return (
    <>
      <FiltersDialog
        form={form}
        filters={filtersGroup}
        onApplyFilters={handleApplyFilters}
        closeDialog={() => {
          setIsOpen(false)
        }}
        visible={isOpen}
        resetValues={handleClearFilter}
        resetOptions={{ keepValues: true }}
      />
    </>
  )
}

export default ListGuardiansFilter
