import { useContext } from 'react'

import {
  GravityContext,
  GravityContextValue,
} from '@/modules/app/contexts/GlobalProviders/ThemeProvider'

export const useGravityTheme = (): GravityContextValue => {
  return useContext(GravityContext)
}
