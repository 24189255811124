import {
  AgreementSimulationCombined,
  SimulationPaymentMethod,
} from '@/modules/guardians/models/agreement'
import { formatCentsToReal, isDayBeforeToday } from 'src/shared/utils'
import { Separator } from '@gravity/separator'
import { AgreementNumber, BoldTypography, CardWrapper, InformationHeader, Subtitle } from './styles'
import { InfoPaymentPlanBankSlip } from '../InfoPaymentPlans/InfoPaymentPlanBankSlip'
import { InfoPaymentPlanCreditCard } from '../InfoPaymentPlans/InfoPaymentPlanCreditCard'
import { PaymentPlanDebitCard } from '../InfoPaymentPlans/InfoPaymentPlanDebitCard'
import { usePaymentCreditCard } from '@/escolas/hooks'

interface AgreementCardProps {
  agreement: AgreementSimulationCombined
  index: number
}

const AgreementCard = ({ agreement, index }: AgreementCardProps) => {
  const { paymentFee } = usePaymentCreditCard()

  const numberOfReceivables = agreement.receivable_ids.length

  const { credit_card_fee, receivables, total_amount } = agreement
  const { product, student } = receivables[0]

  const isEnabledCreditCardFee = paymentFee?.enabled && credit_card_fee > 0

  const formatedTotalAmount = formatCentsToReal(total_amount)

  const studentFirstName = student.split(' ')[0]

  const title = `${index + 1}º pagamento - ${numberOfReceivables} ${
    isDayBeforeToday(agreement.receivables[0].due_date)
      ? 'parcela(s) vencida(s)'
      : 'parcela(s) a vencer'
  }`

  const subtitle = `${studentFirstName} - ${product}`

  const agreementNumberText = () => {
    const shortId = agreement.agreement_simulation_id
      ? agreement.agreement_simulation_id.substring(0, 6).toUpperCase()
      : agreement.short_id

    if (shortId.length === 6)
      return `Acordo #${shortId}${agreement.type === 'down_payment' ? ' - Entrada' : ''}`
    return ''
  }

  const availableMethods: Record<string, React.ReactNode | null> = {
    [SimulationPaymentMethod.BANK_SLIP]: null,
    [SimulationPaymentMethod.CREDIT_CARD]: null,
    [SimulationPaymentMethod.DEBIT_CARD]: null,
  }

  agreement.payment_plans.forEach(payment_play => {
    if (payment_play.payment_method === SimulationPaymentMethod.BANK_SLIP) {
      availableMethods[SimulationPaymentMethod.BANK_SLIP] = (
        <InfoPaymentPlanBankSlip
          key={SimulationPaymentMethod.BANK_SLIP}
          totalAmount={total_amount}
          paymentPlan={payment_play}
          isPaymentFeeEnabled={isEnabledCreditCardFee}
        />
      )
    }

    if (payment_play.payment_method === SimulationPaymentMethod.CREDIT_CARD) {
      availableMethods[SimulationPaymentMethod.CREDIT_CARD] = (
        <InfoPaymentPlanCreditCard
          key={SimulationPaymentMethod.CREDIT_CARD}
          totalAmount={total_amount}
          paymentPlan={payment_play}
          creditCardFee={credit_card_fee ?? 0}
          isPaymentFeeEnabled={isEnabledCreditCardFee}
        />
      )
      availableMethods[SimulationPaymentMethod.DEBIT_CARD] = (
        <PaymentPlanDebitCard
          key={SimulationPaymentMethod.DEBIT_CARD}
          totalAmount={total_amount}
          isPaymentFeeEnabled={isEnabledCreditCardFee}
        />
      )
    }
  })

  return (
    <CardWrapper>
      <InformationHeader>
        <BoldTypography variant="body-1-regular">{title}</BoldTypography>
        <BoldTypography variant="body-1-regular">{formatedTotalAmount}</BoldTypography>
      </InformationHeader>

      <div style={{ marginTop: 16, marginBottom: 16 }}>
        <Separator color="neutral-2" />
      </div>
      <AgreementNumber variant="caption-regular">{agreementNumberText()}</AgreementNumber>

      <div style={{ marginBottom: 10 }}>
        <Subtitle variant="subtitle-regular">{subtitle}</Subtitle>
      </div>

      <div>{Object.keys(availableMethods).map(method => availableMethods[method])}</div>
    </CardWrapper>
  )
}

export default AgreementCard
