import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from '@gravity/button'
import { Heading } from '@gravity/heading'
import { Grid, GridItem } from '@gravity/grid'
import { ArrowLeft } from '@gravity/icons'

import { CircularLoadingPlaceholder } from '@/shared/components/CircularLoadingPlaceholder'
import { Error } from '@/shared/components/Error'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useLayout } from '@/shared/hooks/useLayout'

import { useGetEnrollmentReport } from '../../hooks/queries/enrollmentReport'
import * as Styled from './styles'

export const EnrollmentReport = () => {
  const { goBack } = useHistory()
  const reportQuery = useGetEnrollmentReport()
  const [iframeStatus, setIframeStatus] = useState<typeof reportQuery.status | undefined>('loading')

  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  useLayout({ enableSideMenu: true, enableHeader: renderNewHeader })

  if (reportQuery.status === 'error') return <Error />
  if (reportQuery.status === 'loading') return <CircularLoadingPlaceholder />

  return (
    <div className="w-full h-screen max-h-screen flex justify-center">
      <Styled.Container>
        <Grid className="grid-container">
          <GridItem xl={12} lg={12} md={12} sm={8} xs={4}>
            <Styled.Header>
              <Button variant="ghost" iconStart={<ArrowLeft />} onClick={goBack}>
                Voltar
              </Button>
              {renderNewHeader ? (
                <Heading variant="heading-h2-medium" className="title">
                  Relatório de matrículas
                </Heading>
              ) : (
                <Heading variant="heading-h3-medium" className="title">
                  Relatório de matrículas
                </Heading>
              )}
            </Styled.Header>
          </GridItem>

          <GridItem xl={12} lg={12} md={12} sm={8} xs={4}>
            {iframeStatus === 'loading' ? <CircularLoadingPlaceholder /> : null}

            <Styled.Iframe
              src={reportQuery.data?.url}
              style={{ display: iframeStatus === 'success' ? 'block' : 'none' }}
              onLoad={() => setIframeStatus('success')}
            />
          </GridItem>
        </Grid>
      </Styled.Container>
    </div>
  )
}
