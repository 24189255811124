import { useHistory } from 'react-router-dom'
import { useRef, useState } from 'react'
import { useToast } from '@gravity/toast'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { useUpdateGuardianMutation } from '../../hooks/queries/useGuardianData'
import { EditGuardianFormData } from '../components/GuardianFormFields/types'

export const useFormSubmit = (guardianId: string) => {
  const [showConfirmSubmitDialog, setShowConfirmSubmitDialog] = useState(false)

  const { school } = useSelectedSchool()
  const updateGuardianMutation = useUpdateGuardianMutation()
  const { toast } = useToast()
  const history = useHistory()
  const formData = useRef<null | EditGuardianFormData>(null)

  const handleSubmit = async (data: EditGuardianFormData) => {
    setShowConfirmSubmitDialog(true)
    formData.current = data
  }

  const sendFormDataToApi = async () => {
    if (!school) return
    if (!formData.current) return

    try {
      await updateGuardianMutation.mutateAsync({
        guardian_id: guardianId,
        guardian: formData.current,
        students: [],
        schoolId: school.id,
      })
      history.push(`/${school.slug}/responsaveis/${guardianId}`)
      toast({
        type: 'success',
        title: 'Cadastro editado com sucesso.',
      })
    } catch (error) {
      toast({
        type: 'error',
        title: 'Ocorreu um erro ao atualizar o responsável financeiro',
        description: 'Por favor, tente novamente ou entre em contato com a equipe responsável.',
      })
    }
  }

  const handleClickConfirmSubmitDialog = () => {
    setShowConfirmSubmitDialog(false)
    sendFormDataToApi()
  }

  const handleCloseDialog = () => setShowConfirmSubmitDialog(false)

  const handleDialogOnOpenChange = (open: boolean) => {
    if (!open && showConfirmSubmitDialog) handleCloseDialog()
  }

  return {
    isLoading: updateGuardianMutation.isLoading,
    showConfirmSubmitDialog,
    handleSubmit,
    handleClickConfirmSubmitDialog,
    handleCloseDialog,
    handleDialogOnOpenChange,
  }
}
