import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  justify-content: center;

  .grid-container {
    grid-template-rows: 106px auto;
    padding-top: ${props => props.theme.gravity.spacing[5]};

    .iframe-item {
      flex: 1;
    }
  }
`

export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  padding-bottom: ${props => props.theme.gravity.spacing[10]};
  position: relative;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .title {
    padding: 1rem 0;
    width: 100%;
    box-shadow: 0 1px 0 0 ${props => props.theme.gravity.colors['colors-background-neutral-3']};
    z-index: 1;
  }
`
