import { useState, useMemo } from 'react'
import { debounce } from 'throttle-debounce'
import { normalizeText } from 'src/shared/utils'
import type { UseProductSelectProps } from '@monorepo/enrollment/pages/Campaigns/NewCampaign/components/StudentsStep/components/SelectAutocomplete/types'
import { FieldValues } from 'react-hook-form'

export const useProductSelect = <T extends FieldValues>({
  form,
  initialOptions,
  onAddProductAction,
}: UseProductSelectProps<T>) => {
  const [isSearchingOptions, setIsSearchingOptions] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState('')

  const noOptionsText = 'Nenhum produto com esse nome foi encontrado'

  const formValues = form.getValues()

  const resetField = () => {
    form.reset({ ...formValues, option: null }, { keepErrors: true })
  }

  const debouncedSearch = debounce(500, (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm)
    setIsSearchingOptions(false)
  })

  const filteredOptions = useMemo(() => {
    return searchTerm
      ? initialOptions.filter(option =>
          normalizeText(option.name).includes(normalizeText(searchTerm))
        )
      : initialOptions
  }, [initialOptions, searchTerm])

  const handleSearch = (newSearchTerm: string) => {
    setIsSearchingOptions(true)
    debouncedSearch(newSearchTerm)
  }

  const handleOptionAction = () => {
    resetField()
    if (onAddProductAction) {
      onAddProductAction()
    }
  }

  return {
    isSearchingOptions,
    filteredOptions,
    noOptionsText,
    handleSearch,
    handleOptionAction,
    resetField,
  }
}
