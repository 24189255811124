import { useRef, useState } from 'react'

import dayjs from 'dayjs'
import { Controller, FieldErrorsImpl, useForm } from 'react-hook-form'

import { Dialog } from '@gravity/dialog'
import { calcFixedDate, DATE_FORMAT, isDayAfterToday, isDayTodayOrAfter } from 'src/shared/utils'
import { DatepickerPrimitives as DatePicker } from '@gravity/datepicker'
import { ChoiceChip } from '@gravity/chip'
import { Text } from '@gravity/text'
import { TextField } from '@gravity/text-field'
import { Button } from '@gravity/button'
import { Installment } from '@/modules/contract/services/types'

import { SelectedInstallmentsText } from '../SelectedInstallmentsText'
import { contractAPI } from '@/modules/contract/services'
import { useEvents } from '../../hooks/eventContext'
import { InstallmentStatuses, InstallmentType } from '@/shared/interfaces'

enum Field {
  DUE_DATE = 'due_date',
  FIXED_DAY = 'FIXED_DAY',
  WORKING_DAY = 'WORKING_DAY',
}

export type EditDueDateForm = {
  [Field.DUE_DATE]: datestring
  [Field.FIXED_DAY]: number | ''
  [Field.WORKING_DAY]: number | ''
}

const REQUIRED_MESSAGES = {
  [Field.DUE_DATE]: 'Por favor, informe uma data para o vencimento.',
  [Field.FIXED_DAY]: 'Por favor, informe um dia fixo para o vencimento.',
  [Field.WORKING_DAY]: 'Por favor, informe um dia útil para o vencimento.',
}

const DATES_IN_THE_PAST_MESSAGE =
  'Uma das parcelas selecionadas pertence ao mês atual. Por isso, informe um dia posterior ao dia de hoje. Se preferir, você pode selecionar apenas as parcelas dos próximos meses e escolher qualquer dia de vencimento.'

const errorProps = (errors: Partial<FieldErrorsImpl<EditDueDateForm>>, field: Field) => {
  return {
    error: Boolean(errors[field]),
    helperText: errors?.[field]?.message ?? '',
  }
}

const calculateMaxDateSingleInstallment = (
  allInstallments: Installment[],
  selectedInstallment: Installment,
  referenceYear: string
) => {
  if (selectedInstallment.receivable_type !== InstallmentType.ENROLLMENT) {
    return dayjs.utc(selectedInstallment.due_date).endOf('month')
  }

  const lastTuitionInstallment = allInstallments
    .filter(installment => installment.receivable_type === InstallmentType.TUITION)
    .at(-1)

  if (lastTuitionInstallment) {
    return dayjs.utc(lastTuitionInstallment.due_date).endOf('month')
  }

  return dayjs.utc().year(Number(referenceYear)).endOf('year')
}

export const EditDueDateDialog = ({
  onClose,
  onConfirm,
  selectedInstallments,
  allInstallments,
  api,
  referenceYear,
}: {
  allInstallments: Installment[]
  api: contractAPI
  onClose: () => void
  onConfirm?: (dueDates: datestring[], workingDayMode: boolean) => void
  referenceYear: string
  selectedInstallments: Installment[]
}) => {
  const [workingDayMode, setWorkingDayMode] = useState(false)
  const isSingleInstallment = selectedInstallments.length === 1
  const dueDates = useRef<datestring[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const events = useEvents()

  const selectedInstallment = selectedInstallments[0]

  const minDateSingleInstallment = dayjs(selectedInstallment.due_date).startOf('month')
  const maxDateSingleInstallment = calculateMaxDateSingleInstallment(
    allInstallments,
    selectedInstallment,
    referenceYear
  )

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    getValues,
  } = useForm<EditDueDateForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      [Field.DUE_DATE]: dayjs(selectedInstallment.due_date).toISOString(),
      [Field.FIXED_DAY]: '',
      [Field.WORKING_DAY]: '',
    },
  })

  const skip = !isSubmitting

  const isTuitionAndPending = () => {
    return selectedInstallments.every(
      i => i.receivable_type === InstallmentType.TUITION && i.status === InstallmentStatuses.PENDING
    )
  }

  const updateDueDate = (value: string) => {
    const dates = selectedInstallments.map(i =>
      calcFixedDate(dayjs(i.competence_date[0]).utc(), parseInt(value) || 0).toISOString()
    )
    dueDates.current = dates
  }

  const validateFixedDates = async () => {
    return dueDates.current.every(d => isDayTodayOrAfter(dayjs(d)))
  }

  const validateWorkingDates = () => {
    return dueDates.current.every(d => isDayTodayOrAfter(dayjs(d)))
  }

  const calculateWorkingDates = async () => {
    setIsLoading(true)
    try {
      const dates = (
        await Promise.all(
          selectedInstallments.map(i =>
            api.getWorkingDateFromNthDay({
              date: dayjs(i.competence_date[0]).utc().toISOString(),
              nth_working_day: getValues().WORKING_DAY || 0,
            })
          )
        )
      ).map(d => d.data)

      dueDates.current = dates

      setIsLoading(false)
      return true
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      return false
    }
  }

  const today = new Date()
  const currentDateSelected = (value: string) => dayjs.utc(value)

  const formatDateValue = (value: string) => {
    const date = new Date(value)
    const localDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    if (isDayAfterToday(dayjs(localDate))) {
      return localDate
    }
    return today
  }

  return (
    <Dialog
      open
      title="Editar vencimento"
      onOpenChange={onClose}
      size={2}
      backdrop
      content={
        <div className="flex flex-col mb-6">
          <div className="mb-6">
            <SelectedInstallmentsText count={selectedInstallments.length} />
          </div>

          {isSingleInstallment && (
            <Controller
              name={Field.DUE_DATE}
              control={control}
              rules={{
                required: REQUIRED_MESSAGES[Field.DUE_DATE],
                validate: {
                  minValue: v =>
                    isDayTodayOrAfter(dayjs(v)) ||
                    'Você precisa inserir uma data posterior a data de hoje.',
                  maxValue: v => {
                    const currentDate = dayjs(selectedInstallment.due_date)
                    const maxDate = dayjs(maxDateSingleInstallment)

                    return (
                      dayjs(v).isBefore(maxDate) ||
                      `A data de vencimento desta parcela, que é ${currentDate.format(
                        'DD/MM/YYYY'
                      )}, pode ser alterada no máximo para ${maxDate.format('DD/MM/YYYY')}.`
                    )
                  },
                },
              }}
              render={({ field: { onChange, value } }) => (
                <DatePicker.Root>
                  <div>
                    <Text variant="subtitle-regular" id="campaign-due-date-label">
                      Data de vencimento
                    </Text>
                  </div>
                  <DatePicker.Trigger
                    data-testid="edit-due-date-calendar-trigger"
                    disabled={false}
                    size={3}
                    placeholder="Selecione a data"
                  >
                    {value && currentDateSelected(value).format(DATE_FORMAT)}
                  </DatePicker.Trigger>
                  <DatePicker.Calendar
                    value={formatDateValue(value)}
                    minDate={minDateSingleInstallment.toDate()}
                    maxDate={maxDateSingleInstallment.toDate()}
                    onChange={value => {
                      const date = dayjs(value as Date)
                      if (value !== null && date.isValid()) {
                        dueDates.current = [date.toISOString()]
                      }

                      onChange(date)

                      events?.clickDatePicker()
                    }}
                  />
                </DatePicker.Root>
              )}
            />
          )}

          {!isSingleInstallment && (
            <section className="mb-6">
              <div className="flex flex-col">
                <Text variant="body-1-regular">Regra de vencimento</Text>
                <Text variant="caption-regular">Selecione a regra e dia de vencimento</Text>
              </div>

              <div className="flex gap-4 mt-4">
                <ChoiceChip
                  text="Vencimento por dia fixo"
                  value="Vencimento por dia fixo"
                  selected={!workingDayMode}
                  onSelected={() => setWorkingDayMode(false)}
                />
                <ChoiceChip
                  text="Vencimento por dia útil"
                  value="Vencimento por dia útil"
                  selected={workingDayMode}
                  onSelected={() => setWorkingDayMode(true)}
                />
              </div>
            </section>
          )}

          {!workingDayMode && !isSingleInstallment && (
            <Controller
              name={Field.FIXED_DAY}
              control={control}
              rules={{
                required: REQUIRED_MESSAGES[Field.FIXED_DAY],
                validate: {
                  minValue: v => Number(v) > 0 || 'O número de dias deve ser maior que zero',
                  maxValue: v =>
                    Number(v) <= 31 ||
                    'O dia fixo deve ser maior que zero e igual ou inferior a 31',
                  dateOnThePast: async () => {
                    return (
                      (await validateFixedDates()) ||
                      isTuitionAndPending() ||
                      DATES_IN_THE_PAST_MESSAGE
                    )
                  },
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  data-testid="edit-due-date-fixed-day"
                  name="Dia fixo"
                  label="Dia fixo"
                  value={value}
                  id={Field.FIXED_DAY}
                  onChange={e => {
                    if (e !== null) {
                      updateDueDate(e.target.value)
                    }

                    onChange(e)
                  }}
                  type="number"
                  size={3}
                  error={errorProps(errors, Field.FIXED_DAY).error}
                  errorMessage={errorProps(errors, Field.FIXED_DAY).helperText}
                />
              )}
            />
          )}

          {workingDayMode && !isSingleInstallment && (
            <Controller
              name={Field.WORKING_DAY}
              control={control}
              rules={{
                required: REQUIRED_MESSAGES[Field.WORKING_DAY],
                validate: {
                  minValue: v => Number(v) > 0 || 'O número de dias deve ser maior que zero',
                  maxValue: v =>
                    Number(v) <= 25 ||
                    'O dia útil deve ser maior que zero e igual ou inferior a 25',
                  calcDates: async () => {
                    if (skip) return true
                    return (await calculateWorkingDates()) || 'Erro ao calcular datas' // >>> TODO: review text with design
                  },
                  dateOnThePast: () => {
                    if (skip) return true
                    return (
                      isTuitionAndPending() || validateWorkingDates() || DATES_IN_THE_PAST_MESSAGE
                    )
                  },
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  data-testid="edit-due-date-working-day"
                  name="Dia útil"
                  label="Dia útil"
                  value={value}
                  id={Field.WORKING_DAY}
                  onChange={onChange}
                  type="number"
                  size={3}
                  error={isLoading ? false : Boolean(errors[Field.WORKING_DAY])}
                  errorMessage={
                    isLoading
                      ? 'Calculando datas...'
                      : errors[Field.WORKING_DAY]
                      ? errors[Field.WORKING_DAY].message
                      : ''
                  }
                />
              )}
            />
          )}
        </div>
      }
      actionButton={
        <Button
          variant="solid"
          onClick={handleSubmit(() => onConfirm?.(dueDates.current, workingDayMode))}
          disabled={!isValid}
        >
          Aplicar
        </Button>
      }
      cancelButton={
        <Button variant="ghost" onClick={onClose}>
          Cancelar
        </Button>
      }
    />
  )
}
