import { Button } from '@gravity/button'
import { ArrowGoBack } from '@gravity/icons'

type UndoCancelButtonProps = {
  onClick: (e: any) => void
}

export const UndoCancelButton = ({ onClick }: UndoCancelButtonProps) => (
  <div className="max-w-40">
    <Button variant="ghost" iconStart={<ArrowGoBack />} className="text-start" onClick={onClick}>
      Desfazer cancelamento
    </Button>
  </div>
)
