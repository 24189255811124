import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { RenegotiationFormType } from '../RenegotiationContent'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { formatCentsToReal } from '@/shared/utils'
import dayjs from 'dayjs'
import { useApi } from '@/utils/hooks/useApi'
import { parseInstallmentToReceivable } from '@/modules/guardians/utils/parseInstallmentToReceivable'
import { InstallmentDrawerInfo } from '../../types'
import { useInstallmentDrawerContext } from '@/modules/guardians/contexts/InstallmentDrawerContext'
import { useQuery } from '@/shared/hooks/useQuery'
import { SubmitHandler } from 'react-hook-form'
import { useToast } from '@gravity/toast'
import { InstallmentDrawerTypes } from '../../hooks/useDrawerType'

export const useRenegotiationDrawer = ({
  installmentInfo,
  onCloseDrawer,
}: {
  installmentInfo: InstallmentDrawerInfo
  onCloseDrawer?: () => void
}) => {
  const { api } = useApi()
  const { setOnQueryParam } = useQuery()
  const { setSelectedDrawer } = useInstallmentDrawerContext()

  const { toast } = useToast()

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const receivable = parseInstallmentToReceivable(installmentInfo)

  const handleOnClose = () => {
    setSelectedDrawer('' as InstallmentDrawerTypes)
    setOnQueryParam('true', 'refetch', 'replace')
    onCloseDrawer?.()
  }

  const submitHandler: SubmitHandler<RenegotiationFormType> = async (
    form: RenegotiationFormType
  ) => {
    const { installments, payment_method, numberOfInstallments, totalAmount } = form
    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.RENEGOTIATION,
        entity: EventDispatcherEntities.RENEGOTIATE_CONFIRM,
        action: 'click',
        customProperties: {
          $name: 'Confirmar renegociação',
          $receivable_id: receivable.id,
          $payment_selected: payment_method,
          $total_amount: formatCentsToReal(totalAmount),
        },
      })
    return await api.receivables
      .renegotiate({
        channel: 'PI-BACKOFFICE',
        to: installments.map(({ amount, due_date }) => ({
          amount,
          due_date: dayjs(due_date).utc().format('YYYY-MM-DDT00:00:00Z'),
        })),
        from: receivable.id,
        payment_methods: payment_method ? JSON.parse(payment_method) : [],
        max_installments_value: numberOfInstallments
          ? parseInt(numberOfInstallments.toString())
          : 0,
      })
      .then(() => {
        toast({ type: 'success', title: 'Renegociação realizada com sucesso.' })
        handleOnClose()
      })
      .catch(() => {
        toast({ type: 'error', title: 'Falha na renegociação. Tente novamente.' })
      })
  }

  return {
    receivable,
    onSubmit: submitHandler,
    onClose: () => setSelectedDrawer('' as InstallmentDrawerTypes),
  }
}
