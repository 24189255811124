import styled from 'styled-components'
import { Text } from '@gravity/text'

export const ContainerTuitionHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: ${props => props.theme.gravity.spacing[8]};
  gap: ${props => props.theme.gravity.spacing[4]};
`

export const LabelActualizationPeriod = styled(Text)`
  color: ${props => props.theme.gravity.colors['colors-text-main-3']};
`
