import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query'

import { IrregularEnrollmentService } from '@monorepo/enrollment/services'
import {
  IrregularStudentsRequest,
  IrregularStudentsResponse,
} from '@monorepo/enrollment/services/types'
import { useApiClient } from '@/shared/hooks/useApiClient'
import { queryClient } from '@/shared/contexts/ReactQuery'

const irregularStudentsKey = 'irregular-students'
const irregularityBigNumbersKey = 'irregularity-big-numbers-key'

export const useIrregularStudentsQuery = (
  params: IrregularStudentsRequest,
  options?: UseQueryOptions<IrregularStudentsResponse>
) => {
  const { apiClient } = useApiClient()
  const service = IrregularEnrollmentService(apiClient.privateApi)

  return useQuery<IrregularStudentsResponse>(
    [irregularStudentsKey, params],
    () => service.fetchIrregularStudents(params),
    options
  )
}

export const useCancelOpenIrregularitiesMutation = () => {
  const { apiClient } = useApiClient()
  const service = IrregularEnrollmentService(apiClient.privateApi)

  return useMutation(
    (enrollmentRequestId: uuid) => {
      return service.cancelOpenIrregularities(enrollmentRequestId)
    },
    {
      onSettled: async response => {
        await queryClient.invalidateQueries([irregularStudentsKey, irregularityBigNumbersKey])
        return response
      },
    }
  )
}
