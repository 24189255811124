import { useHistory, useRouteMatch } from 'react-router-dom'
import { Button } from '@gravity/button'
import { Grid, GridItem } from '@gravity/grid'
import { RestartOutline } from '@gravity/icons'

import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useSchoolEnrollmentCycles } from '@/shared/hooks/useSchoolEnrollmentCycles'
import { useBannerInfos } from './useBannerInfos'

import { Carousel } from './components/Carousel'
import { Banner, BannerPlaceholder } from './components/Banner'
import { Section } from '../Section'

import type { BannerInfo } from './types'

import { EventIdentifierName } from '@monorepo/school-home/models/EventIdentifierName.enum'
import { EventPageName } from '@monorepo/school-home/models/EventPageName.enum'

import { Container, Wrapper } from './styles'

export const CommunicationSection = () => {
  const history = useHistory()
  const route = useRouteMatch()

  const { school } = useSelectedSchool()
  const { activeEnrollmentCycleYear } = useSchoolEnrollmentCycles()

  const {
    isInitialized: isEventDispatcherInitialized,
    eventDispatcherClient,
  } = useEventDispatcher()

  const { data, isLoading, isError, refetch } = useBannerInfos({
    schoolId: school?.id ?? '',
    referenceYear: activeEnrollmentCycleYear ?? 0,
  })

  const handleClick = (
    info: Pick<BannerInfo, 'actionPath' | 'actionExternalLink' | 'event'>
  ) => () => {
    const { event, actionPath, actionExternalLink } = info

    if (actionExternalLink) {
      window.open(actionPath, '_blank', 'noopener noreferrer')
    } else {
      history.push(`${route.url}${actionPath}`)
    }

    if (event && isEventDispatcherInitialized) {
      eventDispatcherClient.sendEvent({
        name: event.name,
        scope: event.scope,
        customProperties: event.props,
        identifierName: EventIdentifierName.OPEN_BANNER_LINK,
        pageName: EventPageName.SCHOOL_HOME,
      })
    }
  }

  if (isError) {
    return (
      <Section
        title="Não foi possível carregar suas informações de cobranças"
        variation="secondary"
        variantText="subtitle-medium"
        description="Ocorreu um erro inesperado. Tente novamente mais tarde."
      >
        <Button onClick={refetch} title="Recarregar" iconStart={<RestartOutline />}>
          Recarregar
        </Button>
      </Section>
    )
  }

  const banners = isLoading
    ? [<BannerPlaceholder key="placeholder" />]
    : data.map(item => (
        <Banner
          title={item.title}
          titleSize={
            item.titleSize === 'normal' || item.titleSize === 'large' ? item.titleSize : 'normal'
          }
          key={item.title}
          explanation={item.explanation}
          imageSrc={item.imageSrc}
          buttonLabel={item.actionLabel}
          buttonOnClick={handleClick(item)}
          backgroundColor={item.backgroundColor}
          color={item.color}
          headingColor={item.headingColor ?? item.color}
        />
      ))

  return (
    <Wrapper>
      {banners.length > 0 ? (
        <Grid>
          <GridItem xl={12} lg={12} md={12} sm={8} xs={4}>
            <Container>
              <Carousel items={banners} />
            </Container>
          </GridItem>
        </Grid>
      ) : null}
    </Wrapper>
  )
}
