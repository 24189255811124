import { ReactElement, useState } from 'react'
import { Tabs } from '@gravity/tabs'
import { Text } from '@gravity/text'
import { useEnrollmentMixpanelEvents } from '@monorepo/enrollment/hooks/useEnrollmentMixpanelEvents'

export type FilterOption = {
  icon: ReactElement
  label: string
  value: string
}

interface ToggleButtonProps {
  filterOptions: FilterOption[]
  onChange: (value: string) => void
}

export const FilterToggle = ({ filterOptions, onChange }: ToggleButtonProps) => {
  const [value, setValue] = useState<string>(filterOptions[0].value)

  const { sendTabClickEvent } = useEnrollmentMixpanelEvents()

  const handleValueChange = (newValue: string) => {
    if (newValue !== null) {
      const currentTab = filterOptions.find(option => option.value === newValue)
      if (currentTab) sendTabClickEvent('situacao_campanha', currentTab.label)
      setValue(newValue)
      onChange(newValue)
    }
  }

  return (
    <Tabs.Root value={value} onValueChange={value => handleValueChange(value)}>
      <Tabs.List background="gray">
        {filterOptions.map(option => (
          <Tabs.Trigger key={option.value} value={option.value} iconStart={option.icon}>
            <Text variant="button-2" className="text-inherit">
              {option.label}
            </Text>
          </Tabs.Trigger>
        ))}
      </Tabs.List>
    </Tabs.Root>
  )
}
