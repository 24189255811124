import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import type { UseFormReturn } from 'react-hook-form'
import { DialogPrimitives } from '@gravity/dialog'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { Button } from '@gravity/button'

import { AddTuitionDialog } from '@monorepo/enrollment/components/AddTuitionDialog'
import { useAddBulkInstallmentStore } from '@monorepo/enrollment/store/AddBulkInstallmentStore'
import type { AddTuitionToCampaignState } from '@monorepo/enrollment/pages/Campaigns/CampaignDetails/types'
import type { FormProps } from '@monorepo/enrollment/pages/Campaigns/CampaignDetails/components/InstallmentForm/types'
import { useAddTuitionToCampaignStudentInBatch } from '@monorepo/enrollment/hooks/useAddTuitionToCampaignStudentInBatch'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useDialogVisibility } from '@monorepo/enrollment/hooks/useDialogVisibility'
import type { StepPageProps } from '@monorepo/enrollment/components/Stepper/types'

import { CustomizationStepTable } from './components/CustomizationStepTable'
import type { URLParams } from '../../types'
import { Footer } from '@gravity/footer'
import { DashedArrowLeft } from '@gravity/icons'
import { useCampaignDetails } from '../../hooks/useCampaignDetails'

export const CustomizationStep = ({ enrollmentCycle, onLeave }: StepPageProps) => {
  const { campaignId } = useParams<URLParams>()
  const { school } = useSelectedSchool()
  const { addTuitions, isLoading } = useAddTuitionToCampaignStudentInBatch({ campaignId, school })
  const { closeDialog, dialogVisible, handleDialogOpenChange, openDialog } = useDialogVisibility()
  const [enableAddInstallments, setEnableAddInstallments] = useState(false)
  const { campaignDetails } = useCampaignDetails(campaignId)

  const emptyValues: FormProps = {
    custom_monthly_amount: 0,
    duration_months: '',
    installment_due_date_rule: null,
    due_day: 0,
    due_date: null,
    due_date_month: null,
    discounts: [],
  }

  const defaultValueForm = useForm<FormProps>({
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: emptyValues,
  })

  const [
    addCustomTuitionDialogState,
    setAddCustomTuitionDialogState,
  ] = useState<AddTuitionToCampaignState>({
    enrollmentID: '',
    studentName: '',
    openDialog: false,
  })

  const { customInstallmentsFormData, setCustomInstallmentsFormData } = useAddBulkInstallmentStore()

  const handleCustomFormSubmit = (enrollmentID: uuid, form: UseFormReturn<FormProps>) => {
    setCustomInstallmentsFormData(form.getValues(), enrollmentID)
    setAddCustomTuitionDialogState({ enrollmentID: '', studentName: '', openDialog: false })
    form.reset()
  }

  const handleCloseDialog = (form: UseFormReturn<FormProps>) => {
    setAddCustomTuitionDialogState({ enrollmentID: '', studentName: '', openDialog: false })
    form.reset()
  }

  const handleEnableAddInstallmentsBtn = (status: boolean) => {
    setEnableAddInstallments(status)
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (
      addCustomTuitionDialogState.enrollmentID &&
      customInstallmentsFormData.has(addCustomTuitionDialogState.enrollmentID)
    ) {
      defaultValueForm.reset(
        customInstallmentsFormData.get(addCustomTuitionDialogState.enrollmentID)
      )
      return
    }
    defaultValueForm.reset(emptyValues)
  }, [addCustomTuitionDialogState.enrollmentID])

  return (
    <div className="flex flex-col justify-start mt-8">
      <Heading variant="heading-h3-medium">Personalização</Heading>
      <Text variant="body-2-regular" className="mt-2">
        Produto: {campaignDetails.productName}
      </Text>
      <Text variant="body-1-regular" className="mt-4">
        Você pode editar o plano de pagamento por aluno e remover aqueles para os quais não deseja
        adicionar as mensalidades agora. Lembre-se: as mensalidades dos alunos removidos precisarão
        ser adicionadas individualmente.
      </Text>
      <CustomizationStepTable
        setAddCustomTuitionDialogState={setAddCustomTuitionDialogState}
        setEnableAddInstallments={status => handleEnableAddInstallmentsBtn(status)}
      />
      <AddTuitionDialog
        enrollmentID={addCustomTuitionDialogState.enrollmentID ?? ''}
        form={defaultValueForm}
        isLoading={false}
        onClose={handleCloseDialog}
        onConfirm={handleCustomFormSubmit}
        open={addCustomTuitionDialogState.openDialog}
        studentName={addCustomTuitionDialogState.studentName}
        referenceYear={enrollmentCycle?.reference_year}
      />
      <Footer
        className="z-[99]"
        secondaryButton={{
          label: 'Anterior',
          iconStart: <DashedArrowLeft />,
          onClick: () => onLeave?.(),
        }}
        primaryButton={{
          label: 'Adicionar mensalidades',
          onClick: openDialog,
          disabled: !enableAddInstallments,
          loading: isLoading,
        }}
      />

      <DialogPrimitives.Root open={dialogVisible} onOpenChange={handleDialogOpenChange}>
        <DialogPrimitives.Overlay backdrop />
        <DialogPrimitives.Content
          size={1}
          description="Ao confirmar, o isaac irá criar as mensalidades para os alunos desta campanha."
          title="Deseja adicionar as mensalidades?"
          actionButton={
            <Button
              variant="solid"
              onClick={() => {
                closeDialog()
                addTuitions()
              }}
            >
              Sim
            </Button>
          }
          cancelButton={
            <Button variant="ghost" onClick={closeDialog}>
              Cancelar
            </Button>
          }
        />
      </DialogPrimitives.Root>
    </div>
  )
}
