import { UseFormReturn, ValidateResult } from 'react-hook-form'
import dayjs from 'dayjs'

import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

import { EnrollmentCampaignFormProps } from '@monorepo/enrollment/pages/Campaigns/NewCampaign/components/PaymentConditionsStep/types'

dayjs.extend(isSameOrBefore)

type Props = {
  form: UseFormReturn<EnrollmentCampaignFormProps, any>
}

export const useOfferList = ({ form }: Props) => {
  const { offers } = form.getValues()

  const calculateTheLatestOfferDate = (date: Date) => {
    const latestOfferDate = offers.reduce(
      (acc, curr) => (curr.expirationDate && curr.expirationDate > acc ? curr.expirationDate : acc),
      date ?? null
    )

    if (latestOfferDate) form.setValue('end_date', latestOfferDate)
  }

  const validateOfferDueDate = (date: Date | null, index: number): ValidateResult => {
    if (!date) return 'Você deve selecionar uma data de expiração'

    if (index === 0) return true

    const prevOfferDate = offers[index - 1].expirationDate
    if (prevOfferDate && dayjs(date).isSameOrBefore(dayjs(prevOfferDate))) {
      return 'A data de validade deve ser posterior à data da condição anterior.'
    }

    return true
  }

  return {
    validateOfferDueDate,
    calculateTheLatestOfferDate,
  }
}
