import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${props => props.theme.gravity.spacing[4]};

  .content {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .title {
    color: ${props => props.theme.gravity.colors['colors-text-main-3']};
  }

  .name {
    width: 26ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  .loading {
    gap: ${props => props.theme.gravity.spacing[2]};
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
