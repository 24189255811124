import { useApiClient } from '@/shared/hooks'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { onboardingAPI } from '../services/onboardingApi'
import { ToggleBypassParams } from '../services/types/onboardingBatch'

export const useToggleBypassMutation = (
  options?: UseMutationOptions<any, unknown, ToggleBypassParams, unknown>
) => {
  const { apiClient } = useApiClient()
  const api = onboardingAPI(apiClient.privateApi)

  return useMutation(async (params: ToggleBypassParams) => {
    const response = await api.toggleBypass(params)

    return response.data
  }, options)
}
