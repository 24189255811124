import { Callout } from '@gravity/callout'
import { Heading } from '@gravity/heading'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { EditStudentFormProps } from '../../../hooks/useEditStudentForm'
import { GuardianAccordion } from './GuardianAccordion'

export const GuardiansForm = () => {
  const { control } = useFormContext<EditStudentFormProps>()

  const { fields: guardians } = useFieldArray({
    control,
    name: 'guardians',
  })

  const isSingleGuardian = guardians.length === 1

  return (
    <div className="flex flex-col gap-6">
      <Heading variant="heading-h4-medium">
        {isSingleGuardian ? 'Responsável' : 'Responsáveis'}
      </Heading>

      <Callout
        color="gray"
        text="Informar os dados corretos do responsável assegura contratos seguros e facilita a gestão de cobranças."
        linkLabel="Saiba mais"
        linkTarget="_blank"
        // TODO: Use correct link
        href="https://centraldeajuda.olaisaac.io/respons%C3%A1veis-financeiros/tudo-sobre-edicao-de-dados-cadastrais-de-alunos-"
      />

      {guardians.map((guardian, index) => (
        <GuardianAccordion key={guardian.id} index={index} isSingleGuardian={isSingleGuardian} />
      ))}
    </div>
  )
}
