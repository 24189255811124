import { InstallmentType } from '@/shared/interfaces'
import { z } from 'zod'
import { InstallmentStatus } from '../../GuardianDetails/constants'

export const baseSchema = z.object({
  label: z.string(),
  value: z.string(),
  selected: z.boolean().default(false),
})

const transformSelected = <T>(data: z.infer<typeof baseSchema>[] | undefined) => {
  return (
    data &&
    data?.reduce<T[]>((acc, item) => {
      if (item.selected) acc.push(item.value as T)
      return acc
    }, [])
  )
}

export const filterSchema = z
  .object({
    contract_id: z.array(baseSchema).optional(),
    installment_statuses: z.array(baseSchema).optional(),
    installment_types: z.array(baseSchema).optional(),
    negotiation_id: z.array(baseSchema).optional(),
    product_ids: z.array(baseSchema).optional(),
    reference_years: z.array(baseSchema).optional(),
    student_ids: z.array(baseSchema).optional(),
  })
  .optional()
  .nullable()
  .transform(data => {
    return {
      ...data,
      contractId: transformSelected<string>(data?.contract_id) ?? undefined,
      installmentStatuses: transformSelected<InstallmentStatus>(data?.installment_statuses) ?? null,
      installmentTypes: transformSelected<InstallmentType>(data?.installment_types) ?? null,
      negotiationId: transformSelected<string>(data?.negotiation_id) ?? undefined,
      productsArray: transformSelected<string>(data?.product_ids) ?? null,
      referenceYears: transformSelected<string>(data?.reference_years) ?? null,
      studentsArray: transformSelected<string>(data?.student_ids) ?? null,
    }
  })

export type FilterForm = z.infer<typeof filterSchema>
