import { useState } from 'react'
import { IconButton } from '@gravity/icon-button'
import { Tooltip } from '@gravity/tooltip'
import { LoopRight } from '@gravity/icons'

import { CircularLoading } from '@/shared/components/CircularLoading'
import { ReenrollmentStatus } from '@/shared/interfaces'

type Props = {
  refreshSituation?: () => void
  status?: ReenrollmentStatus
}

export const RefreshSituationButton = ({ status, refreshSituation }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const showRefreshButton = (status: ReenrollmentStatus): boolean => {
    return status !== ReenrollmentStatus.ENROLLED
  }
  const handleRefresh = async (e: React.SyntheticEvent) => {
    if (!refreshSituation) return
    e.stopPropagation()
    setIsLoading(true)

    try {
      await refreshSituation()
    } finally {
      setIsLoading(false)
    }
  }

  if (!status) return null
  if (!showRefreshButton(status)) {
    return null
  }

  return (
    <Tooltip
      position="bottom"
      style={{ maxWidth: 400 }}
      text="Utilize caso tenha recebido algum pagamento que pode alterar a situação de matrícula do aluno. Para pagamentos em boleto, o tempo de compensação pode ser até 3 dias."
    >
      <IconButton variant="ghost" onClick={handleRefresh}>
        {isLoading ? <CircularLoading /> : <LoopRight />}
      </IconButton>
    </Tooltip>
  )
}
