import type { ChangeEvent } from 'react'
import { UseFormReturn } from 'react-hook-form'

import { Add } from '@gravity/icons'

import { FormAddTuitionProps } from '@monorepo/students/pages/Student/components/ContractsSection/types'
import { InstallmentFormDiscountItem } from '../InstallmentFormDiscountItem'
import { useManageDiscounts } from '@monorepo/students/pages/Student/components/ContractsSection/components/AddTuitionDialog/InstallmentForm/hooks/useManageDiscounts'

import * as Styled from './styles'

export type DiscountFieldsProps = {
  form: UseFormReturn<FormAddTuitionProps>
  renderDiscountBtn?: (disabled: boolean, onClick: () => void) => JSX.Element
}

export const DiscountFields = ({ form, renderDiscountBtn }: DiscountFieldsProps) => {
  const totalAmountPaid = form.watch('custom_monthly_amount')

  const {
    addNewDiscountInput,
    handleRemoveDiscountClick,
    isAddDiscountBtnDisabled,
    discountFields,
    validateDiscountAmount,
    onPercentageValueChange,
  } = useManageDiscounts(form)

  return (
    <>
      {discountFields.length > 0 && <Styled.Separator />}

      <Styled.DiscountList role="list" aria-label="Lista de descontos">
        {discountFields.map((discount, index) => (
          <InstallmentFormDiscountItem
            form={form}
            key={discount.id}
            index={index}
            totalAmountPaid={totalAmountPaid}
            onClickRemove={handleRemoveDiscountClick(index)}
            validateDiscountAmount={validateDiscountAmount}
            onPercentageValueChange={(event: ChangeEvent<HTMLInputElement>) =>
              onPercentageValueChange(event.target.value, index)
            }
          />
        ))}
      </Styled.DiscountList>

      {renderDiscountBtn ? (
        renderDiscountBtn(isAddDiscountBtnDisabled, addNewDiscountInput)
      ) : (
        <Styled.AddDiscountBtn
          fullWidth
          disabled={isAddDiscountBtnDisabled}
          variant="outline"
          iconEnd={<Add />}
          onClick={addNewDiscountInput}
          type="button"
        >
          Adicionar desconto
        </Styled.AddDiscountBtn>
      )}
    </>
  )
}
