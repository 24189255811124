import { formatDate } from '@/shared/utils/dateFormatters'

import { FINANCIAL_CATEGORY_ITEM_TYPES_LABELS } from '../../constants/FINANCIAL_CATEGORY_ITEM_TYPES_LABELS'

import type { CategoryItem } from '../../components/PayoutTransfersSectionGroup/types'
import type { FinancialOperationsCategoryItem } from '@monorepo/report/models/FinancialOperationsCategoryItem'

/**
 * Utility function to build category items
 *
 * @param data List of category items
 *
 * @returns List of formatted category items
 */
export const buildCategoryItems = (data: FinancialOperationsCategoryItem[]): CategoryItem[] => {
  const categoryItems = data.map(item => {
    const secondaryInfo = item.reference_date
      ? formatDate(item.reference_date, 'DD/MM/YYYY')
      : undefined

    let label = item.label ?? ''

    if (!label) {
      label = FINANCIAL_CATEGORY_ITEM_TYPES_LABELS[item.type] ?? ''
    }

    return {
      label,
      secondaryInfo,
      value: item.total,
    }
  })

  return categoryItems
}
