import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useApiClient } from '@/shared/hooks/useApiClient'

import { schoolHomeSearchService } from '@monorepo/school-home/services/school-home-search'

import type {
  SearchRequest,
  SearchResponse,
} from '@monorepo/school-home/services/school-home-search/types'

export const useSchoolHomeSearch = (
  { schoolId, search, type, limit }: SearchRequest,
  options?: UseQueryOptions<SearchResponse>
) => {
  const { apiClient } = useApiClient()

  const service = schoolHomeSearchService(apiClient.getClients().bffApi)

  return useQuery<SearchResponse>(
    ['school-home-search', schoolId, search, type],
    async () => {
      const data = await service.search({
        schoolId,
        search,
        type,
        limit,
      })

      return data
    },
    options
  )
}
