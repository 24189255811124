import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { useApiClient } from '@/shared/hooks/useApiClient'
import { SOFTCACHE_OPTIONS_BASE } from '@/shared/constants'
import type {
  PreviewChangeOwnershipRequest,
  PreviewChangeOwnershipResponse,
} from '@/modules/contract/services/change-ownership/types'
import { changeOwnershipService } from '@/modules/contract/services/change-ownership'

export const useChangeOwnershipPreview = (
  params: PreviewChangeOwnershipRequest,
  options?: UseQueryOptions<PreviewChangeOwnershipResponse, XMLHttpRequest>
) => {
  const { apiClient } = useApiClient()
  const service = changeOwnershipService(apiClient.getClients().privateApi)
  const queryKey = ['change-ownership-preview', params.contract_ids]

  return useQuery<PreviewChangeOwnershipResponse, XMLHttpRequest>(
    queryKey,
    () => {
      return service.previewChangeOwnership(params)
    },
    {
      ...SOFTCACHE_OPTIONS_BASE,
      ...options,
    }
  )
}
