import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { Grid, GridItem } from '@gravity/grid'

import { TuitionPayoutReportHeader } from './components/TuitionPayoutReportHeader'
import { TuitionPayoutReportTable } from './components/TuitionPayoutReportTable'
import { TuitionPayoutReportTransfersDrawer } from './components/TuitionPayoutReportTransfersDrawer'
import { TuitionPayoutReportInstallmentDetailsDrawer } from './components/TuitionPayoutReportInstallmentDetailsDrawer'
import { TuitionMainContent } from '../../components/TuitionMainContent'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useQuery } from '@/shared/hooks/useQuery'
import { useTuitionPayoutReportData } from './hooks/useTuitionPayoutReportData'

import { buildTuitionPayoutReportFilterOptions } from './utils/buildTuitionPayoutReportFilterOptions'

import { PAYOUT_REPORT_STATUS } from '../../constants/PAYOUT_REPORT_STATUS'
import { REPORT_PAGE_NAMES } from '../../constants/REPORT_PAGE_NAMES'

import type { FinancialTransactionProps } from '@monorepo/report/models/report'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

import { Container, ScrollableContent } from './styles'
import { GenericCashoutError } from '@/shared/components/CashoutErrors'
import { TuitionPayoutReportTableHeader } from './components/TuitionPayoutReportTableHeader'

type PageDrawers = 'payout_transfers' | 'student_detail'

const USER_GUIDING_ONBOARDING_ID = '98475'

/**
 * Tuition payout report page component
 */
export const TuitionPayoutReport = () => {
  const { school } = useSelectedSchool()
  const { setOnQueryParam } = useQuery()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  useEffect(() => {
    setOnQueryParam(USER_GUIDING_ONBOARDING_ID, 'guide', 'replace')
  }, [])

  const [selectedInstallment, setSelectedInstallment] = useState<FinancialTransactionProps>()
  const [isPayoutTransferDrawerOpen, setIsPayoutTransferDrawerOpen] = useState(false)
  const [isStudentDetailsDrawerOpen, setIsStudentDetailsDrawerOpen] = useState(false)

  const {
    tuitionPayoutReportList,
    currentTuitionPayoutDetails,
    isFetchingCurrentTuitionPayoutDetails,
    hasErrorOnFetch,
    refetch,
  } = useTuitionPayoutReportData()

  const drawers: Record<PageDrawers, React.Dispatch<React.SetStateAction<boolean>>> = {
    payout_transfers: setIsPayoutTransferDrawerOpen,
    student_detail: setIsStudentDetailsDrawerOpen,
  }

  const closeDrawers = () => {
    Object.entries(drawers).forEach(([, setDrawerVisibility]) => setDrawerVisibility(false))
  }

  const handleOpenDrawer = (name: PageDrawers) => {
    closeDrawers()

    drawers[name](true)
  }

  const isPayoutOpen = currentTuitionPayoutDetails?.status === PAYOUT_REPORT_STATUS.OPEN

  const availableFilters = buildTuitionPayoutReportFilterOptions(
    currentTuitionPayoutDetails?.filtersIncluded
  )

  const isTableEmpty = (currentTuitionPayoutDetails?.transactionsTotal ?? 0) === 0

  if (hasErrorOnFetch) {
    return <GenericCashoutError handleRetryButton={refetch} />
  }

  return (
    <Container>
      <ScrollableContent>
        <Grid className="gap-14">
          <GridItem xl={12} lg={12} md={12} sm={8} xs={4}>
            <TuitionPayoutReportHeader
              tuitionPayoutReportList={tuitionPayoutReportList}
              onChangeTuitionPayout={closeDrawers}
              payoutReportPeriod={{
                startDate: currentTuitionPayoutDetails?.startedAt,
                endDate: currentTuitionPayoutDetails?.endedAt,
              }}
              linkButtonText="Ver transferências programadas"
              onClickAtLinkButton={() => {
                setOnQueryParam(undefined, 'transfer_section', 'replace')
                handleOpenDrawer('payout_transfers')

                isInitialized &&
                  eventDispatcherClient.sendEvent({
                    scope: EventDispatcherEventScopes.PAYOUT_REPORT,
                    name: EventDispatcherEvents.BUTTON_CLICK,
                    action: 'click',
                    customProperties: {
                      $page_name: REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT,
                      $button_label: 'Ver transferências programadas',
                    },
                  })
              }}
            />
          </GridItem>

          <TuitionMainContent
            payoutValue={currentTuitionPayoutDetails?.payoutAmount ?? 0}
            isPayoutOpen={isPayoutOpen}
            isLoading={isFetchingCurrentTuitionPayoutDetails}
            onRequestOpenPayoutTransfersDrawer={() => handleOpenDrawer('payout_transfers')}
            payoutReportPeriod={{
              startDate: currentTuitionPayoutDetails?.startedAt,
              endDate: currentTuitionPayoutDetails?.endedAt,
            }}
            linkButtonText="Ver transferências programadas"
            incomeValue={currentTuitionPayoutDetails?.income?.total ?? 0}
            deductionsValue={currentTuitionPayoutDetails?.deductions?.total ?? 0}
            onClickAtLinkButton={() => {
              setOnQueryParam(undefined, 'transfer_section', 'replace')
              handleOpenDrawer('payout_transfers')

              isInitialized &&
                eventDispatcherClient.sendEvent({
                  scope: EventDispatcherEventScopes.PAYOUT_REPORT,
                  name: EventDispatcherEvents.BUTTON_CLICK,
                  action: 'click',
                  customProperties: {
                    $page_name: REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT,
                    $button_label: 'Ver transferências programadas',
                  },
                })
            }}
          />

          <GridItem xl={12} lg={12} md={12} sm={8} xs={4}>
            <TuitionPayoutReportTableHeader
              isLoading={isFetchingCurrentTuitionPayoutDetails}
              isNewVersionPayout={currentTuitionPayoutDetails?.isNewVersion ?? false}
              isEmptyPayout={isTableEmpty}
              payoutId={currentTuitionPayoutDetails?.payoutId ?? ''}
              fileVersion={currentTuitionPayoutDetails?.fileVersion}
              payoutReportDate={currentTuitionPayoutDetails?.payoutDueDate}
              tuitionPayoutReportStatus={currentTuitionPayoutDetails?.status}
            />
          </GridItem>

          <GridItem xl={12} lg={12} md={12} sm={8} xs={4}>
            <TuitionPayoutReportTable
              isLoading={isFetchingCurrentTuitionPayoutDetails}
              isNewVersionPayout={currentTuitionPayoutDetails?.isNewVersion ?? false}
              transactions={currentTuitionPayoutDetails?.transactions ?? []}
              availableFilters={availableFilters}
              totalItems={currentTuitionPayoutDetails?.transactionsTotal ?? 0}
              onRowClick={({ id }) => {
                if (!currentTuitionPayoutDetails) return

                const installments = currentTuitionPayoutDetails.transactions

                const newSelectedInstallment = installments.find(
                  installment => installment?.referrals?.installment_id === id
                )

                if (newSelectedInstallment) {
                  setSelectedInstallment(newSelectedInstallment)

                  // TODO: REFACTOR TO REMOVE DRAWER NAME
                  handleOpenDrawer('student_detail')
                }

                isInitialized &&
                  eventDispatcherClient.sendEvent({
                    scope: EventDispatcherEventScopes.PAYOUT_REPORT,
                    name: EventDispatcherEvents.TUITION_PAYOUT_REPORT_DETAILS,
                    action: 'click',
                    customProperties: {
                      $page_name: REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT,
                    },
                  })
              }}
            />
          </GridItem>
        </Grid>
      </ScrollableContent>

      <TuitionPayoutReportTransfersDrawer
        schoolSlug={school?.slug ?? ''}
        payoutId={currentTuitionPayoutDetails?.payoutId ?? ''}
        isPayoutOpen={isPayoutOpen}
        payoutDueDate={dayjs(currentTuitionPayoutDetails?.payoutDueDate).toDate()}
        income={currentTuitionPayoutDetails?.income}
        deductions={currentTuitionPayoutDetails?.deductions}
        bankTransfers={currentTuitionPayoutDetails?.bankTransfers}
        transactionsInfo={{
          students: currentTuitionPayoutDetails?.uniqueStudents ?? 0,
          charges: currentTuitionPayoutDetails?.transactionsCount ?? 0,
        }}
        isOpen={isPayoutTransferDrawerOpen}
        onClose={() => setIsPayoutTransferDrawerOpen(false)}
      />

      <TuitionPayoutReportInstallmentDetailsDrawer
        contractReferenceYear={selectedInstallment?.referrals.contract_reference_year}
        guardianId={selectedInstallment?.referrals.guardian_id ?? ''}
        student={{
          id: selectedInstallment?.referrals.student_id ?? '',
          name: selectedInstallment?.referrals.student_name ?? '',
        }}
        product={{
          id: selectedInstallment?.referrals.product_id ?? '',
          name: selectedInstallment?.referrals.product_name ?? '',
        }}
        referenceDate={dayjs(selectedInstallment?.reference_month).toDate()}
        feeAmount={selectedInstallment?.financial_detail.total_fee ?? 0}
        totalAmount={selectedInstallment?.financial_detail.total ?? 0}
        totalExpectedBaseAmount={
          selectedInstallment?.financial_detail.total_expected_base_amount ?? 0
        }
        events={selectedInstallment?.financial_detail.events_details ?? []}
        isOpen={isStudentDetailsDrawerOpen}
        onClose={() => setIsStudentDetailsDrawerOpen(false)}
      />
    </Container>
  )
}
