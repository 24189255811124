import { useEffect } from 'react'
import ConfirmationDialog, {
  FailureFeedbackContent,
  failureFeedbackTitle,
} from '@/shared/components/ConfirmationDialog'
import { ConfirmationEditDialogProps } from './types'
import { Text } from '@gravity/text'

const ConfirmationEditDialog = ({
  isVisible,
  submitHandler,
  handleClose,
  form,
  isFeedbackDialogOpen,
  refreshData,
  handleCloseFeedbackDialog,
}: ConfirmationEditDialogProps) => {
  const {
    handleSubmit,
    formState: { isSubmitted, isSubmitSuccessful },
  } = form

  const getFeedbackDialogParameters = (): {
    feedbackDialogContent: React.ReactNode
    feedbackDialogTitle: string
  } => {
    return {
      feedbackDialogTitle: failureFeedbackTitle,
      feedbackDialogContent: <FailureFeedbackContent />,
    }
  }

  const { feedbackDialogTitle, feedbackDialogContent } = getFeedbackDialogParameters()

  useEffect(() => {
    if (isFeedbackDialogOpen) {
      handleClose()
      return
    }
    if (isSubmitted) {
      handleClose()
      isSubmitSuccessful && refreshData()
    }
  }, [isSubmitSuccessful, isSubmitted])

  return (
    <>
      <ConfirmationDialog
        isVisible={isFeedbackDialogOpen}
        onClose={handleCloseFeedbackDialog}
        submitHandler={handleCloseFeedbackDialog}
        title={feedbackDialogTitle}
      >
        {feedbackDialogContent}
      </ConfirmationDialog>

      <ConfirmationDialog
        isVisible={isVisible}
        onClose={handleClose}
        submitHandler={handleSubmit(submitHandler)}
        buttonLabel="Sim, salvar alterações"
        backButtonLabel="Cancelar alteração"
        title="Atenção"
      >
        <Text>Essa ação irá alterar os contratos. Deseja continuar?</Text>
      </ConfirmationDialog>
    </>
  )
}

export default ConfirmationEditDialog
