import { Heading } from '@gravity/heading'
import { DashedArrowLeft, DashedArrowRight } from '@gravity/icons'

import type { GuardianCardProps } from './types'

export const GuardianCard = ({ title, arrowType, children }: GuardianCardProps) => {
  return (
    <div className="min-w-0 flex flex-col border border-solid border-colors-border-neutral-3 rounded-2 p-8 gap-2 break-words">
      <div className="mx-auto mb-3">
        {arrowType === 'entering' && (
          <DashedArrowRight
            aria-hidden="true"
            role="img"
            className="text-colors-background-semantic-colors-success-1"
          />
        )}

        {arrowType === 'leaving' && (
          <DashedArrowLeft
            aria-hidden="true"
            role="img"
            className="text-colors-background-semantic-colors-error-1"
          />
        )}
      </div>
      <Heading variant="heading-h4-medium">{title}</Heading>
      <br />
      {children}
    </div>
  )
}
