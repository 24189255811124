import styled, { css } from 'styled-components'

import type { SectionContainerProps } from './types'

export const Container = styled.section<SectionContainerProps>`
  display: flex;
  justify-content: center;

  padding-top: ${props => props.theme.gravity.spacing[8]};
  padding-bottom: ${props => props.theme.gravity.spacing[8]};
  width: 100%;
  background: ${props => props.theme.gravity.colors['colors-background-accent-3']};

  ${props =>
    props.$variation === 'primary' &&
    css`
      background: ${props => props.theme.gravity.colors['colors-background-neutral-1']};
      padding-top: 0;
    `}

  .description {
    display: flex;

    line-height: 1.5;
    margin: ${props => props.theme.gravity.spacing[2]} 0 ${props => props.theme.gravity.spacing[8]}
      0;
    color: ${props => props.theme.gravity.colors['colors-text-main-3']};
  }

  &.skeleton {
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.gravity.spacing[4]};
  }
`
