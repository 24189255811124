import { formatCentsToReal } from '@/shared/utils'
import { Text } from '@gravity/text'
import { Badge } from '@gravity/badge'
import { Popover } from '@gravity/popover'
import { RemovableChip } from '@gravity/chip'
import { useRef, useState } from 'react'
import { Discount } from '@/shared/interfaces'
import { Installment } from '@/modules/contract/services/types'
import { DiscountRule, DiscountRuleLabels, calculateDiscountPercentage } from '../../utils'

type DiscountChipProps = {
  disabled: boolean
  discount: Discount
  discountPercentage: string
  onDelete: () => void
}

const DiscountChip = ({ discount, onDelete, disabled, discountPercentage }: DiscountChipProps) => {
  const anchorEl = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)

  const discountAmount = discount.amount ?? ''

  const getDiscountRuleText = () => {
    switch (discount.days_before_due_date) {
      case 0:
        return DiscountRuleLabels[DiscountRule.DUE_PAYMENT].name
      case -1:
        return DiscountRuleLabels[DiscountRule.PERPETUAL].name
      default:
        return DiscountRuleLabels[DiscountRule.EARLY_PAYMENT].name
    }
  }

  return (
    <>
      <Popover.Root open={open}>
        <Popover.Anchor>
          <RemovableChip
            disabled={disabled}
            ref={anchorEl}
            value={discount?.description}
            text={`${formatCentsToReal(discountAmount)} (${discountPercentage}%)`}
            onRemove={onDelete}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            className="w-max px-1 border-none bg-colors-background-semantic-colors-success-2"
          />
        </Popover.Anchor>

        <Popover.Trigger asChild />

        <Popover.Content
          align="center"
          className="outline-none z-50 border border-solid border-colors-border-neutral-2 rounded-2xl"
        >
          <div className="p-4 rounded-2 mt-2 gap-8 flex flex-col">
            <div className="flex flex-col">
              <Text variant="body-2-regular" className="text-colors-text-main-3">
                Nome do desconto
              </Text>
              <Text variant="body-1-regular">{discount.description}</Text>
            </div>
            <div className="flex flex-col">
              <Text variant="body-2-regular" className="text-colors-text-main-3">
                Regra do desconto
              </Text>
              <Text variant="body-1-regular">{getDiscountRuleText()}</Text>
            </div>
          </div>
        </Popover.Content>
      </Popover.Root>
    </>
  )
}

export const DiscountChips = ({
  onDelete,
  installment,
}: {
  installment: Installment
  onDelete: (installmentId: uuid, discountIndex: number) => void
}) => {
  const discounts = installment.discounts

  if (discounts.length === 0) {
    return (
      <div className="w-max">
        <Badge variant="soft" color="neutral">
          Nenhum desconto aplicado
        </Badge>
      </div>
    )
  }

  return (
    <div className="flex py-2 flex-col gap-2">
      {discounts.map((discount, index) => {
        return (
          <DiscountChip
            key={index}
            discount={discount}
            discountPercentage={
              discount.amount !== null
                ? calculateDiscountPercentage(discount.amount, installment.amount)
                : '0'
            }
            onDelete={() => onDelete(installment.backoffice_installment_id, index)}
            disabled={!installment.can_edit}
          />
        )
      })}
    </div>
  )
}
