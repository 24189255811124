import { Controller, UseFormReturn } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { dissoc, path } from 'ramda'
import { TextField } from '@gravity/text-field'

import { formatCentsToReal } from 'src/shared/utils'

type DiscountFormFieldsProps = {
  amountDefaultValue?: cents
  amountFieldName?: string
  disableForm: boolean
  form: UseFormReturn<any>
  totalAmountPaid: cents
}

function AgglutinationDiscount({
  amountDefaultValue,
  amountFieldName = 'discount',
  form,
  totalAmountPaid,
  disableForm,
}: DiscountFormFieldsProps) {
  const { control, formState } = form
  const invalidAmount = Boolean(path(amountFieldName.split('.'), formState.errors))

  const validateDiscountValue = (value: number) => {
    if (value >= totalAmountPaid) {
      return 'Desconto não pode ser superior a 100%'
    }
    return true
  }

  return (
    <div className={`${invalidAmount && 'mb-5'} w-full`}>
      <Controller
        rules={{
          validate: {
            validateDiscountValue,
          },
        }}
        control={control}
        name={amountFieldName}
        defaultValue={amountDefaultValue && amountDefaultValue > 0 ? amountDefaultValue : undefined}
        render={({ field, field: { onChange, value }, fieldState: { error } }) => (
          <NumberFormat
            {...dissoc('onChange', field)}
            onValueChange={value => {
              onChange(value.floatValue ?? 0)
            }}
            id={amountFieldName}
            customInput={TextField}
            label="Desconto"
            format={formatCentsToReal}
            min={0}
            size={3}
            fullWidth
            placeholder="R$ 0,00"
            value={value}
            error={invalidAmount}
            errorMessage={invalidAmount ? error?.message : ''}
            disabled={disableForm}
          />
        )}
      />
    </div>
  )
}

export default AgglutinationDiscount
