import { useMemo } from 'react'
import { RadioGroup } from '@gravity/radio'
import { Text } from '@gravity/text'

import type { StepComponentProps } from '@/modules/guardians/Negotiation/types'
import { useAgreement } from 'src/escolas/contexts/agreementContext'
import { FormOfPayment } from '@/modules/guardians/models/agreement'
import { useSendCheckoutEvent } from '@/modules/guardians/Negotiation/hooks/useSendCheckoutEvents'
import { formatCentsToReal } from 'src/shared/utils'
import { useSelectPos, usePaymentCreditCard } from 'src/escolas/hooks'

import isPosAvailable from '../../helpers/isPosAvailable'
import FormOfPaymentItem from './components/FormOfPaymentItem'
import * as S from './styles'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { Callout } from '@gravity/callout'
import StepFooter from '../../components/StepFooter'
import { Separator } from '@gravity/separator'

const FormOfPaymentStep = ({ id, onFinish, goBack }: StepComponentProps) => {
  const {
    totalAmount,
    setSelectedFormOfPayment,
    selectedFormOfPayment,
    agreementSimulations,
  } = useAgreement()

  const { paymentFee } = usePaymentCreditCard()

  const isEnabledCreditCardFee = paymentFee?.enabled && paymentFee?.value > 0

  const { isLoading, listPos } = useSelectPos()

  const sendCheckoutEvent = useSendCheckoutEvent()

  const paymentForms = useMemo(() => {
    const paymentFormAvailability = new Map([
      [FormOfPayment.POS, false],
      [FormOfPayment.ONE_LUMP_SUM, false],
      [FormOfPayment.INSTALLMENTS, false],
      [FormOfPayment.POS_V2, false],
      [FormOfPayment.OTHER, false],
    ])

    paymentFormAvailability.set(FormOfPayment.OTHER, true)
    if (isPosAvailable(agreementSimulations) && listPos?.length > 0) {
      paymentFormAvailability.set(FormOfPayment.POS_V2, true)
    }

    return Array.from(paymentFormAvailability)
      .filter(availability => availability[1])
      .map(availability => availability[0])
  }, [agreementSimulations, listPos])

  function handleGoBack() {
    sendCheckoutEvent(EventDispatcherEntities.CHECKOUT_SECOND_STEP_GO_BACK, {
      name: 'Forma de pagamento - Voltar',
      location: id,
    })
    goBack()
  }

  function handleOnClickContinue() {
    sendCheckoutEvent(EventDispatcherEntities.CHECKOUT_SECOND_STEP_CONTINUE, {
      name: 'Forma de pagamento - Continuar',
      selected_payment_method: selectedFormOfPayment,
    })
    onFinish()
  }

  return (
    <S.Container>
      <S.Title>
        <Text style={{ textAlign: 'center', maxWidth: '386px' }} variant="subtitle-regular">
          Como será a forma de pagamento do valor de <b>{formatCentsToReal(totalAmount)}</b>?
        </Text>
      </S.Title>

      {isEnabledCreditCardFee && (
        <Callout
          text={`Agora os pagamentos via cartão de crédito na maquininha ou link de pagamento passam a cobrar uma taxa de ${paymentFee?.percentage}% sobre o valor a ser pago.`}
        />
      )}

      <S.ListWrapper marginTop={isEnabledCreditCardFee}>
        <RadioGroup
          className="gap-0"
          aria-label="form of payment"
          name="radio-buttons-form-of-payment"
        >
          {paymentForms.map((paymentForm, index) => (
            <div key={paymentForm}>
              {index / 2 !== 0 && <Separator color="neutral-2" />}
              <FormOfPaymentItem
                formOfPayment={paymentForm}
                isPaymentFeeEnabled={isEnabledCreditCardFee}
                isSelected={paymentForm === selectedFormOfPayment}
                onSelect={setSelectedFormOfPayment}
              />
            </div>
          ))}
        </RadioGroup>
      </S.ListWrapper>
      <StepFooter
        showHelpButton
        stepName={id}
        goBack={handleGoBack}
        btnPrimary={{
          loading: isLoading,
          disabled: !selectedFormOfPayment,
          onClick: handleOnClickContinue,
        }}
      />
    </S.Container>
  )
}

export default FormOfPaymentStep
