import { useEffect, useState } from 'react'
import { useScrollToElement } from '@/shared/hooks/useScrollToElement'

import { Select } from '@gravity/select'
import { Button } from '@gravity/button'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'
import { Skeleton } from '@gravity/skeleton'
import { Callout } from '@gravity/callout'
import { Grid, GridItem } from '@gravity/grid'

import { Add, ArchiveOutline, BookOpenOutline, CalendarOutline } from '@gravity/icons'

import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { UserGuidingGuidesIds, useUserGuidingTrigger } from '@/shared/hooks/useUserGuidingTrigger'

import { ErrorDialog } from '@/shared/components/ErrorDialog'
import { Search } from '@monorepo/enrollment/pages/SchoolEnrollmentCycle/components/TableSearch'
import { FiltersDialog } from '@/shared/components/FiltersDialog'
import { BigNumbersGroup } from './components/BigNumbersGroup'

import { useIsFirstRender } from '@/shared/hooks/useIsFirstRender'
import { useQuery } from '@/shared/hooks/useQuery'
import { useLayout } from '@/shared/hooks/useLayout'
import { useSetPageTitle } from '@/shared/hooks/useSetPageTitle'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import * as Styled from './styles'
import { EnrollmentTable } from './components/EnrollmentTable'
import { useEnrollmentFilters } from './hooks/useFiltersActions'
import { useEnrollmentData } from './hooks/useEnrollmentData'
import { useEnrollmentYear } from './hooks/useEnrollmentYear'
import { useEnrollmentActions } from './hooks/useEnrollmentActions'
import { useEnrollmentDisclaimer } from '../../hooks/useEnrollmentDisclaimer'
import { ADD_INSTALLMENTS_HELP_ANCHOR_LINK } from '../../constants/disclaimer'
import { isLegacyYear } from '@monorepo/enrollment/hooks/useInitialSupportedCycleYear'
import { useAcademicPeriod } from './hooks/useAcademicPeriod'

export const SchoolEnrollmentCycle = () => {
  const isFirstRender = useIsFirstRender()
  const [name, setName] = useState<string>('')
  const { school } = useSelectedSchool()
  const schoolId = school?.id ?? ''

  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)
  const isScoreFFEnabled = useUnleashFlag(UnleashFlags.MAT_616_ENABLE_ISAAC_SCORE)
  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)
  const isAcademicPeriodDrodpdownEnabled = useUnleashFlag(
    UnleashFlags.FAC_176_ENABLE_ACADEMIC_PERIOD_DROPDOWN
  )

  const {
    selectedYear,
    selectedCycle,
    years,
    isCampaignButtonEnabled,
    handleChangeSelectedYear,
  } = useEnrollmentYear()

  const { academicPeriods, selectedAcademicPeriod, setSelectedAcademicPeriod } = useAcademicPeriod()

  useSetPageTitle(
    selectedAcademicPeriod
      ? `Matrículas ${selectedAcademicPeriod.view} - isaac`
      : `Matrículas - isaac`
  )

  const { setOnQueryParam } = useQuery()

  const { canUseAutoTrigger } = useUserGuidingTrigger()

  const {
    isFilterDialogOpen,
    statusOptions,
    subStatusOptions,
    handleOpenFilterDialog,
    handleCloseFilterDialog,
    handleClearFilter,
    handleRemoveFilter,
    onSubmitFilters,
    filtersArray,
    filtersGroup,
    studentsEnrollmentsFilter,
    form,
    setValue,
  } = useEnrollmentFilters(selectedYear)

  const {
    enrollmentsData,
    dataSummary,
    pagination,
    updatePaginationValue,
    isLoading: isEnrollmentDataLoading,
    isLoadingSummary,
    isError,
    isErrorSummary,
  } = useEnrollmentData({
    schoolId,
    selectedYear,
    name,
    studentsEnrollmentsFilter,
    academicPeriodId: selectedAcademicPeriod?.id,
  })

  const {
    goToCampaignsPage,
    handleAddStudent,
    addContractEnabled,
    goToScorePage,
  } = useEnrollmentActions(selectedCycle, years)

  const { disclaimerMessage } = useEnrollmentDisclaimer({
    schoolId,
    referenceYear: selectedYear,
  })

  const isLegacy = isLegacyYear()

  useEffect(() => {
    if (isFirstRender) return

    if (name || studentsEnrollmentsFilter) {
      updatePaginationValue('page', 1)
    }
  }, [name, studentsEnrollmentsFilter])

  const guideId = isIntegratedSchool
    ? UserGuidingGuidesIds.ENROLLMENT_PAGE_INTEGRATED_SCHOOL
    : UserGuidingGuidesIds.ENROLLMENT_PAGE

  useEffect(() => {
    if (canUseAutoTrigger) {
      setOnQueryParam(guideId, 'guide')
    }
  }, [isIntegratedSchool])

  useLayout({ enableSideMenu: true, enableHeader: true, headerTitle: 'Matrículas' })

  useScrollToElement({
    ready: !isEnrollmentDataLoading && !isLoadingSummary && !isErrorSummary && !isError,
  })

  useEffect(() => {
    setValue(
      'enrollment_status',
      statusOptions.map(status => ({
        ...status,
        selected: studentsEnrollmentsFilter.enrollmentStatus.includes(status.value),
      }))
    )

    setValue(
      'enrollment_sub_status',
      subStatusOptions.map(subStatusOption => ({
        ...subStatusOption,
        selected: studentsEnrollmentsFilter.enrollmentSubStatus.includes(subStatusOption.value),
      }))
    )
  }, [studentsEnrollmentsFilter, statusOptions, subStatusOptions, setValue])
  return (
    <>
      <Styled.PageContainer>
        <Grid className="gap-y-8">
          <GridItem>
            <ErrorDialog isError={isError || isErrorSummary} />
          </GridItem>
          <GridItem>
            <Styled.YearAndCampaignWrapper>
              <Styled.ReferenceYear>
                {renderNewHeader ? (
                  <Heading variant="heading-h2-medium">Matrículas do ano letivo:</Heading>
                ) : (
                  <Heading variant="heading-h2-medium">Ano letivo</Heading>
                )}
                {isAcademicPeriodDrodpdownEnabled ? (
                  <Select
                    size={3}
                    options={academicPeriods.map(academicPeriod => ({
                      label: academicPeriod.view,
                      value: academicPeriod.id,
                    }))}
                    value={selectedAcademicPeriod?.id}
                    onValueChange={value => setSelectedAcademicPeriod(value)}
                    icon={<CalendarOutline />}
                  />
                ) : (
                  <Select
                    size={3}
                    options={years.map(year => ({
                      label: year.toString(),
                      value: year.toString(),
                    }))}
                    value={selectedYear.toString()}
                    onValueChange={evt => handleChangeSelectedYear(Number(evt))}
                    icon={<CalendarOutline />}
                  />
                )}
              </Styled.ReferenceYear>
              {!isLoadingSummary ? (
                isCampaignButtonEnabled && (
                  <Button
                    variant="outline"
                    iconStart={<BookOpenOutline />}
                    onClick={goToCampaignsPage}
                  >
                    Gerenciar campanhas
                  </Button>
                )
              ) : (
                <Skeleton width={223} height={40} />
              )}
            </Styled.YearAndCampaignWrapper>
          </GridItem>
          {!isLegacy && (
            <GridItem>
              <BigNumbersGroup
                data={dataSummary}
                isLoading={isLoadingSummary}
                isError={isErrorSummary}
              />
            </GridItem>
          )}
          {!!disclaimerMessage && (
            <GridItem>
              <Callout
                text={disclaimerMessage}
                linkLabel="Saiba mais"
                href={ADD_INSTALLMENTS_HELP_ANCHOR_LINK}
                linkTarget="_blank"
              />
            </GridItem>
          )}
          <GridItem>
            <Styled.TotalAndContractActions hasLegacyYears={isLegacy}>
              {!isLoadingSummary && !isErrorSummary ? (
                !isLegacy && (
                  <Text variant="body-1-medium">{`Total de alunos: ${dataSummary?.total}`}</Text>
                )
              ) : (
                <Skeleton width={157} height={40} />
              )}
              <Styled.ScoreAndContract>
                {!isLoadingSummary ? (
                  isScoreFFEnabled && (
                    <Button
                      variant="outline"
                      iconStart={<ArchiveOutline />}
                      onClick={goToScorePage}
                    >
                      isaac Score
                    </Button>
                  )
                ) : (
                  <Skeleton width={138} height={40} />
                )}
                {!isLoadingSummary ? (
                  addContractEnabled && (
                    <Button variant="solid" iconStart={<Add />} onClick={handleAddStudent}>
                      Novo aluno
                    </Button>
                  )
                ) : (
                  <Skeleton width={138} height={40} />
                )}
              </Styled.ScoreAndContract>
            </Styled.TotalAndContractActions>
          </GridItem>
          <GridItem>
            <Search value={name} setValue={setName} placeholder="Busque por aluno ou responsável" />
          </GridItem>
          <GridItem>
            <EnrollmentTable
              enrollmentsData={enrollmentsData}
              isLoading={isEnrollmentDataLoading}
              updatePaginationValue={updatePaginationValue}
              pagination={pagination}
              handleOpenFilter={handleOpenFilterDialog}
              handleRemoveFilter={handleRemoveFilter}
              enrollmentYear={selectedCycle}
              filtersArray={filtersArray}
            />
          </GridItem>
        </Grid>
      </Styled.PageContainer>
      <FiltersDialog
        form={form}
        filters={filtersGroup}
        onApplyFilters={onSubmitFilters}
        closeDialog={handleCloseFilterDialog}
        visible={isFilterDialogOpen}
        resetValues={handleClearFilter}
      />
    </>
  )
}
