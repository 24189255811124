import { Link, useRouteMatch } from 'react-router-dom'
import { Sidemenu } from '@gravity/sidemenu'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useCreditAccessStore } from '@monorepo/credit/creditAccessStore'
import { useSidemenuEvents } from '../hooks/useSidemenuEvents'
import { getNavigationURL } from '../utils/getNavigationURL'

export const FinancialCategoryItems = () => {
  const { school } = useSelectedSchool()
  const { hasCreditAccess } = useCreditAccessStore()
  const { handleClickTransfersPath, handleClickCreditPath } = useSidemenuEvents()

  const transfersPath = `/${school?.slug}/repasses`
  const creditPath = getNavigationURL({ schoolSlug: school?.slug, path: '/credito' })

  return (
    <>
      <Sidemenu.CategoryItem
        as={Link}
        text="Repasses"
        active={Boolean(useRouteMatch({ path: transfersPath }))}
        onClick={handleClickTransfersPath}
        to={transfersPath}
      />
      {hasCreditAccess && (
        <Sidemenu.CategoryItem
          as={Link}
          text="Crédito"
          active={Boolean(useRouteMatch({ path: creditPath }))}
          onClick={handleClickCreditPath}
          to={creditPath}
        />
      )}
    </>
  )
}
