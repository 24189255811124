import { useToast } from '@gravity/toast'

import { useGuardianHistoryNegotiationQuery } from './useGuardianHistoryNegotiationQuery'
import { useEffect } from 'react'

export const useGuardianHistoryNegotiation = (negotiationId?: string) => {
  const { toast } = useToast()

  const {
    guardianHistoryNegotiation,
    isError,
    isLoading,
    isSuccess,
  } = useGuardianHistoryNegotiationQuery({
    id: negotiationId,
  })

  useEffect(() => {
    if (isError) {
      toast({
        type: 'error',
        title: 'Ocorreu um erro ao buscar os detalhes da negociação.',
        description: 'Tente novamente mais tarde.',
        style: { width: '50%' },
      })
    }
  })

  return {
    guardianHistoryNegotiation,
    isError,
    isLoading,
    isSuccess,
  }
}
