import styled from 'styled-components'
import { DatepickerPrimitives as Datepicker } from '@gravity/datepicker'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const StyledDatepickerTrigger = styled(Datepicker.Trigger)<{ $width?: string }>`
  width: auto;
  > span {
    background-color: transparent;
    color: ${props => props.theme.gravity.colors['colors-text-main-1']};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: ${props => props.theme.gravity.spacing[2]};
    font-weight: ${props => props.theme.gravity.fontWeight.medium};
  }

  > span:first-of-type {
    display: none;
  }

  // hide arrow icon
  > span:last-of-type {
    display: none;
  }

  > svg {
    color: ${props => props.theme.gravity.colors['colors-text-main-1']};
  }
  border: 2px solid ${props => props.theme.gravity.colors['colors-interaction-primary-4']};
`
