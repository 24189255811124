import dayjs from 'dayjs'
import copy from 'copy-to-clipboard'
import StatusBadge, { StatusBadgeColor } from '@/shared/components/StatusBadge'
import PrintReceiptButton from '@/modules/guardians/Negotiation/components/PrintReceiptButton'
import { SimulationPaymentMethod } from '@/modules/guardians/models/agreement'
import { formatCentsToReal, getStartOfToday } from 'src/shared/utils'
import { useSendCheckoutEvent } from '@/modules/guardians/Negotiation/hooks/useSendCheckoutEvents'
import { useToast } from '@gravity/toast'
import { InvoiceStatus, PaymentTableProps } from './types'
import * as S from './styles'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { Table } from '@gravity/table'
import { Button } from '@gravity/button'

const PaymentTableCheckout = ({
  installmentLabel,
  idForReceipt,
  due_date,
  amount,
  formOfPaymentLabel,
  paymentMethod,
  paymentLink,
  invoiceStatus,
  paid_date,
  onSuccess,
}: PaymentTableProps) => {
  const sendCheckoutEvent = useSendCheckoutEvent()

  const { toast } = useToast()

  const paymentStatus = (function (): { badgeColor: StatusBadgeColor; label: string } {
    switch (invoiceStatus) {
      case InvoiceStatus.AWAITING_PAYMENT:
        return {
          badgeColor: 'primary',
          label: 'A vencer',
        }
      case InvoiceStatus.PAID:
        return {
          badgeColor: 'success',
          label: 'Paga',
        }
    }
  })()

  function copyInvoiceLink() {
    sendCheckoutEvent(EventDispatcherEntities.CHECKOUT_SUCCESSFUL_TRANSACTION_COPY_LINK, {
      name: 'Copiar link de cobrança',
    })

    copy(paymentLink)
    toast({ type: 'success', title: 'Link copiado com sucesso' })
  }

  const textPaidDate = paid_date
    ? dayjs(paid_date).utc().format('DD/MM/YYYY')
    : `Hoje, ${getStartOfToday().format('D MMMM')}`

  return (
    <S.Wrapper>
      <Table.Root>
        <Table.Head>
          <Table.HeaderCell name="installment">{installmentLabel}</Table.HeaderCell>
          <Table.HeaderCell name="payment_method">Meio de pagamento</Table.HeaderCell>
          <Table.HeaderCell name="payment_date">
            {paymentMethod === SimulationPaymentMethod.POS || invoiceStatus === InvoiceStatus.PAID
              ? `Data de pagamento`
              : `Data de vencimento`}
          </Table.HeaderCell>
          <Table.HeaderCell name="status">Situação</Table.HeaderCell>
          <Table.HeaderCell name="actions">Ações</Table.HeaderCell>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.TextCell>{formatCentsToReal(amount)}</Table.TextCell>
            <Table.TextCell>{formOfPaymentLabel}</Table.TextCell>
            <Table.TextCell>
              {paymentMethod === SimulationPaymentMethod.POS || invoiceStatus === InvoiceStatus.PAID
                ? textPaidDate
                : dayjs(due_date).utc().format('D [de] MMMM')}
            </Table.TextCell>
            <Table.CustomCell>
              <div className="flex flex-row items-center gap-2">
                <StatusBadge color={paymentStatus.badgeColor} /> {paymentStatus.label}
              </div>
            </Table.CustomCell>
            <Table.CustomCell>
              {paymentMethod === SimulationPaymentMethod.POS ||
              invoiceStatus === InvoiceStatus.PAID ? (
                <PrintReceiptButton receivableId={idForReceipt} onSuccess={onSuccess} />
              ) : (
                <Button
                  variant="ghost"
                  style={{ height: '32px', padding: 0, marginRight: 20 }}
                  onClick={copyInvoiceLink}
                >
                  Copiar link
                </Button>
              )}
            </Table.CustomCell>
          </Table.Row>
        </Table.Body>
      </Table.Root>
    </S.Wrapper>
  )
}

export default PaymentTableCheckout
