import {
  useSignatureDocumentQuery,
  useUploadSignatureMutation,
} from '@monorepo/enrollment/hooks/queries/useSignatureQueries'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useFileUpload } from './useFileUpload'
import type { SignatureDocumentType } from '@monorepo/enrollment/services/enrollmentService/types'

export const useDocumentUploadManager = (documentType: SignatureDocumentType) => {
  const { school } = useSelectedSchool()
  const uploadSignatureMutation = useUploadSignatureMutation()
  const { data: documentQuery, isLoading, isSuccess } = useSignatureDocumentQuery(
    { type: documentType, schoolId: school?.id ?? '', download_link: true },
    { enabled: !!school }
  )

  const documentUpload = useFileUpload({
    onSubmit: data => {
      if (!school) return
      uploadSignatureMutation.mutate({ file: data.file, schoolId: school.id, type: documentType })
    },
  })

  const modifiedDocumentUpload =
    isSuccess && documentQuery?.data
      ? {
          ...documentUpload,
          isLoading,
          isGetFromCloud: true,
          isFetching: isLoading,
          file: {
            name: documentQuery.data.Filename,
            size: documentQuery.data.Content?.Attrs?.Size,
          },
          handleDownload: async (file: File) => {
            if (documentQuery.data?.file_link) {
              window.open(documentQuery.data.file_link, '_blank')
            } else if (file) {
              await documentUpload.handleDownload(file)
            }
          },
        }
      : documentUpload

  return modifiedDocumentUpload
}
