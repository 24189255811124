import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useHistory } from 'react-router-dom'

export const useTableActions = () => {
  const { schoolSlug } = useSelectedSchool()
  const history = useHistory()

  const goToStudentsPage = (studentId: uuid) => history.push(`/${schoolSlug}/alunos/${studentId}`)

  const handleRowClick = (studentId: uuid) => {
    return () => goToStudentsPage(studentId)
  }

  return {
    handleRowClick,
  }
}
