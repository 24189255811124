import { StyledInput } from './styles'

import { CREDIT_CARD_PAYMENT_OPTIONS, InputCreditCardOptionProps } from './types'
import { Select } from '@gravity/select'

const InputCreditCardPaymentOptions = ({
  handleValue,
  selectedValue,
}: InputCreditCardOptionProps) => {
  return (
    <StyledInput>
      <Select
        size={3}
        name="input_credit_card_option"
        placeholder="Selecionar forma de pagamento"
        label="Forma de pagamento"
        aria-label="Forma de pagamento"
        value={selectedValue || ''}
        onValueChange={value => {
          handleValue(value)
        }}
        options={[
          {
            value: CREDIT_CARD_PAYMENT_OPTIONS.POS,
            label: 'Maquininha',
          },
          {
            value: CREDIT_CARD_PAYMENT_OPTIONS.PAYMENT_LINK,
            label: 'Link de pagamento',
          },
        ]}
      />
    </StyledInput>
  )
}

export default InputCreditCardPaymentOptions
