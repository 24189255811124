import { formatCPF } from '@/shared/utils'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { ScoreBars } from '@gravity/score-bars'
import { Card } from '@gravity/card'

import { scoreDescriptionMapping, scoreTextMapping } from '../../constants'
import { ScoreNumber } from '../../types'
interface Props {
  score: ScoreNumber
  taxId: string
}

export const ScoreCard = ({ score, taxId }: Props) => {
  const getColor = (score: ScoreNumber) =>
    score <= 2 ? 'error' : score === 3 ? 'warning' : 'success'

  return (
    <Card
      variant="ghost"
      className="flex flex-col bg-colors-background-neutral-2 my-4 gap-6 py-8 px-10"
    >
      <Text>CPF {formatCPF(taxId)}</Text>
      <div className="flex justify-between">
        <Heading variant="heading-h3-medium">Score: {score}</Heading>
        <Heading variant="heading-h3-medium">{scoreTextMapping[score]}</Heading>
      </div>
      <ScoreBars score={score} variant="solid" color={getColor(score)} />
      <Text>{scoreDescriptionMapping[score]}</Text>
    </Card>
  )
}
