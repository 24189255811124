import {
  GET_ONBOARDING_BATCH_QUERY_KEY,
  useGetOnboardingBatch,
} from '@monorepo/onboarding/hooks/useFetchOnboardingBatches'
import { useToast } from '@gravity/toast'
import { useToggleBypassMutation } from '@monorepo/onboarding/hooks/useToggleBypassMutation'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { Switch } from '@gravity/switch'
import { useEffect, useState } from 'react'

export const BypassSwitch = () => {
  const { batchID } = useParams<{ batchID: string }>()
  const queryClient = useQueryClient()
  const [checked, setChecked] = useState<boolean>(false)

  const { toast } = useToast()

  const { data, isLoading } = useGetOnboardingBatch({ batchID })

  useEffect(() => {
    if (!data) return

    setChecked(data.bypass_enabled)
  }, [data])

  const { mutateAsync: executeToggleBypass } = useToggleBypassMutation()

  const handleClick = async () => {
    // Toggle the switch value optimistically
    setChecked(value => !value)

    await executeToggleBypass(
      { batchID },
      {
        onSuccess(response) {
          queryClient.invalidateQueries([GET_ONBOARDING_BATCH_QUERY_KEY, batchID])

          // Update the switch value to the new value, to reflect the value coming from the server
          setChecked(response.bypass_enabled)

          if (response.bypass_enabled) {
            return toast({
              title: 'Escola liberada.',
              description:
                'A importação pode ser realizada mesmo com dados incompletos dos responsáveis.',
              type: 'success',
            })
          }

          return toast({
            title: 'Escola bloqueada.',
            description:
              'A escola precisa completar todos os dados de contato dos responsáveis para prosseguir com a importação.',
            type: 'success',
          })
        },
        onError() {
          // Revert the switch value to the previous value in case of error
          setChecked(value => !value)
          toast({
            title: 'Não foi possível liberar a migração.',
            description: 'Tente novamente.',
            type: 'error',
          })
        },
      }
    )
  }

  return (
    <Switch
      size={1}
      disabled={isLoading}
      checked={checked}
      onCheckedChange={handleClick}
      label="Liberar migração"
    />
  )
}
