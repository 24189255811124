import styled from 'styled-components'

const HEADER_HEIGHT = '96px'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${HEADER_HEIGHT});

  .content {
    margin-top: ${({ theme }) => theme.gravity.spacing[8]};
    height: 100%;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: center;

  margin-top: ${({ theme }) => theme.gravity.spacing[8]};
`
