import type { AxiosInstance } from 'axios'
import qs from 'qs'

import {
  PreContractStatuses,
  APIResponse,
  Contract,
  ContractCancellationReason,
} from '@/shared/interfaces'
import type {
  FetchContractsByStudentResponseDTO,
  FetchContractsByStudentRequestDTO,
  FetchContractsRequestDTO,
  FetchContractsByGuardianRequestDTO,
  FetchContractsByGuardianResponseDTO,
} from './types'

import { MATRICULAS_API_FIRST_YEAR } from '@/shared/constants'

export const contractService = (fetcher: AxiosInstance) => {
  return {
    /**
     * Function to fetch contracts by students view
     *
     * @param data.schoolId School ID
     * @param data.referenceYear Contract reference year
     * @param data.filter Object containing filter
     * @param data.filter.name Filter by student name
     * @param data.filter.contractStatus Filter by contract status
     * @param data.filter.debtStatus Filter by debt status
     * @param data.filter.isReenrollable Filter by contract is enrollable
     * @param data.filter.productId Filter by product IDs
     * @param data.filter.signPaymentStatus Filter by enrollment payment status
     * @param data.filter.nextYearSignPaymentStatus Filter by next year contract enrollment payment status
     * @param data.pagination Object containing pagination attributes
     *
     * @returns Promise with array of contracts
     */
    fetchContractsByStudent: async ({
      schoolId,
      referenceYear,
      filter: {
        name = '',
        contractStatus = [],
        debtStatus = [],
        isReenrollable = [],
        productId = [],
        signPaymentStatus = [],
        studentId = '',
        nextYearSignPaymentStatus = [],
      },
      pagination,
    }: FetchContractsByStudentRequestDTO): Promise<FetchContractsByStudentResponseDTO> => {
      const fetchContracts = async ({
        schoolId,
        referenceYear,
        filterId = '',
        fixedFilters,
        pagination,
      }: FetchContractsRequestDTO) => {
        const formattedFixedFilters = {
          ...(!!name && { name }),
          ...(!!studentId && { student_id: studentId }),
          status: fixedFilters.contractStatus,
          debt_status: fixedFilters.debtStatus,
          is_reenrollable: fixedFilters.isReenrollable,
          sign_payment_status: fixedFilters.signPaymentStatus,
          next_year_sign_payment_status: fixedFilters.nextYearSignPaymentStatus,
        }

        const { data } = await fetcher.get<FetchContractsByStudentResponseDTO>('/contract', {
          params: {
            school_id: schoolId,
            ...(referenceYear && { reference_year: referenceYear }),
            ...(filterId && { filter_id: filterId }),
            ...formattedFixedFilters,
            ...pagination,
          },
          paramsSerializer: params => qs.stringify(params, { arrayFormat: 'comma' }),
        })

        return data
      }

      if (productId.length > 0) {
        const {
          data: {
            data: { filter_id },
          },
        } = await fetcher.post<APIResponse<{ filter_id: string }>>(
          '/contract/store-filters',
          { product_id: productId },
          { params: { school_id: schoolId } }
        )

        const data = await fetchContracts({
          schoolId,
          referenceYear,
          filterId: filter_id,
          fixedFilters: {
            name,
            contractStatus,
            debtStatus,
            isReenrollable,
            signPaymentStatus,
            studentId,
            nextYearSignPaymentStatus,
          },
          pagination,
        })

        return data
      }

      const data = await fetchContracts({
        schoolId,
        referenceYear,
        fixedFilters: {
          name,
          contractStatus,
          debtStatus,
          isReenrollable,
          signPaymentStatus,
          studentId,
          nextYearSignPaymentStatus,
        },
        pagination,
      })

      return data
    },

    fetchContractsByGuardian: async ({
      guardianId,
      filter,
      schoolId,
    }: FetchContractsByGuardianRequestDTO): Promise<FetchContractsByGuardianResponseDTO> => {
      const { data } = await fetcher.get<APIResponse<FetchContractsByGuardianResponseDTO>>(
        '/contract',
        {
          params: {
            guardian_id: guardianId,
            school_id: schoolId,
            ...filter,
          },
          paramsSerializer: p => qs.stringify(p, { arrayFormat: 'comma', skipNulls: true }),
        }
      )

      return data.data
    },

    revoke: async (
      id: uuid,
      params: {
        cancellation_description: string
        cancellation_reason: ContractCancellationReason
        installment_id?: uuid
        triggered_by?: string
      },
      schoolID: uuid,
      contract: Contract
    ) => {
      const cancelAtMatriculas =
        Number(contract?.reference_year) >= MATRICULAS_API_FIRST_YEAR &&
        contract.pre_contract_status !== PreContractStatuses.EFFECTED_PCS

      if (cancelAtMatriculas) {
        return (
          await fetcher.patch<APIResponse<void>>(`/enrollment/${schoolID}/${id}/cancel`, params)
        ).data.data
      }

      return (
        await fetcher.patch<APIResponse<void>>(
          `/contract/${id}/revoke?school_id=${schoolID}`,
          params
        )
      ).data.data
    },
  }
}
