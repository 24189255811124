import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useOnboardingApi } from '../services/api'
import {
  GetBatchUnfinishedStudentsParams,
  GetBatchUnfinishedStudentsResponse,
} from '../services/types'

export const BATCH_UNFINISHED_STUDENTS_QUERY_KEY = 'v2-batch-unfinished-students'

export const useBatchUnfinishedStudentsQuery = (
  params: GetBatchUnfinishedStudentsParams,
  options?: UseQueryOptions<GetBatchUnfinishedStudentsResponse>
) => {
  const api = useOnboardingApi()

  return useQuery<GetBatchUnfinishedStudentsResponse>(
    [BATCH_UNFINISHED_STUDENTS_QUERY_KEY, params],
    async () => {
      const response = await api.getBatchUnfinishedStudents(params)

      return response.data
    },
    options
  )
}
