import styled from 'styled-components'

export const StyledMain = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const StyledContainer = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
  max-width: 620px;
`
export const StyledLoadingContainer = styled.div`
  text-align: center;
  z-index: 1;
`
