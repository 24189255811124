import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { Separator } from '@gravity/separator'
import { DialogPrimitives } from '@gravity/dialog'

import { formatter } from '@/modules/guardians/utils/formatter'
import { getDiscountTitle } from '@/modules/guardians/InstallmentsDrawerContainer/utils'

import {
  InstallmentDrawerAccordion,
  InfoRow,
  InfoText,
  InstallmentDrawerButtons,
} from '@/modules/guardians/InstallmentsDrawerContainer/components'
import { InstallmentDrawerProps } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentsDrawerContainerV2'
import { InstallmentStatus } from '@/modules/guardians/GuardianDetails/constants'
import { InstallmentDialogHeader } from '../../components/InstallmentDialogHeader'

import { useNegotiationDrawer } from './hooks/useNegotiationDrawer'
import { formatDate } from '@/shared/utils'

export const negotiation = ({ installment, onCloseDrawer }: InstallmentDrawerProps) => {
  const {
    headerTitle,
    headerStatusInfo,
    notification,
    footerMessage,
    hasCompositeAmount,
  } = useNegotiationDrawer({ installment })

  const {
    credit_card_fee,
    current_amount,
    current_fine,
    current_interest,
    discounts,
    is_from_credit_card_flow,
    negotiation,
    original_amount,
    product_name,
    status,
    student_name,
  } = installment

  if (!negotiation) return null

  const fullContent = (
    <DialogPrimitives.Root open onOpenChange={open => !open && onCloseDrawer()}>
      <DialogPrimitives.Overlay backdrop />
      <DialogPrimitives.Content
        size={2}
        title="Negociação"
        actionButton={
          <InstallmentDrawerButtons
            onCloseDrawer={onCloseDrawer}
            showMainButton
            showEditButton={false}
            showMenu={false}
            groupButtons={false}
          />
        }
        cancelButton={
          <InstallmentDrawerButtons
            onCloseDrawer={onCloseDrawer}
            showMainButton={false}
            showEditButton
            showMenu
            groupButtons={false}
          />
        }
      >
        <div className="flex flex-col w-full h-full">
          <InstallmentDialogHeader
            title={headerTitle}
            statusInfo={headerStatusInfo}
            calloutInfo={
              notification && {
                description: notification?.description,
              }
            }
          />

          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <InfoText variant="body-1-regular" className="text-colors-text-main-3">
                {student_name}
              </InfoText>
              <Heading variant="heading-h4-medium">{product_name}</Heading>
            </div>

            <Separator color="neutral-2" />

            <InstallmentDrawerAccordion
              id={negotiation?.id}
              disabled={negotiation?.receivables?.length < 1}
              title="Detalhes da negociação"
            >
              <div className="flex flex-col gap-4">
                <Text variant="body-2-regular">Parcelas negociadas</Text>
                <div className="flex flex-col gap-2">
                  {negotiation?.receivables?.map(receivable => (
                    <InfoRow
                      key={receivable?.id}
                      label={formatDate(receivable?.due_date, 'MMM. YYYY')}
                      value={formatter.amount(receivable?.current_amount)}
                    />
                  ))}
                </div>
              </div>
            </InstallmentDrawerAccordion>

            {hasCompositeAmount && (
              <InfoRow label="Valor desta parcela" value={formatter.amount(original_amount)} />
            )}

            {discounts?.map((discount, key) =>
              ['EARLY_PAYMENT_DISCOUNT', 'DUE_PAYMENT'].includes(discount.type) ? (
                <InfoRow
                  key={key}
                  label={`${getDiscountTitle(discount.type)} Válido até ${formatter.full_date(
                    discount.limit_date
                  )}`}
                  value={`- ${formatter.amount(discount.total_amount)}`}
                />
              ) : (
                <InfoRow
                  key={key}
                  label={getDiscountTitle(discount.type)}
                  value={`- ${formatter.amount(discount.total_amount)}`}
                />
              )
            )}

            {[InstallmentStatus.OVERDUE, InstallmentStatus.CANCELED].includes(status) && (
              <>
                <InfoRow label="Juros" value={`+ ${formatter.amount(current_interest)}`} />
                <InfoRow label="Multa" value={`+ ${formatter.amount(current_fine)}`} />
              </>
            )}

            {is_from_credit_card_flow && credit_card_fee && (
              <InfoRow label="Taxa do Cartão" value={`+ ${formatter.amount(credit_card_fee)}`} />
            )}

            {hasCompositeAmount && <Separator color="neutral-2" />}

            <div>
              <div className="flex justify-between">
                {[InstallmentStatus.OVERDUE, InstallmentStatus.CANCELED].includes(status) ? (
                  <Text variant="body-1-regular">Valor pendente</Text>
                ) : (
                  <Text variant="body-1-regular">Valor a pagar</Text>
                )}

                <Text variant="body-1-regular">{formatter.amount(current_amount)}</Text>
              </div>

              <Text variant="body-2-regular">{footerMessage}</Text>
            </div>
          </div>
        </div>
      </DialogPrimitives.Content>
    </DialogPrimitives.Root>
  )

  return {
    isDialog: true,
    fullContent,
  }
}
