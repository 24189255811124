import { Button } from '@gravity/button'
import { Checkbox } from '@gravity/checkbox'
import { Dialog } from '@gravity/dialog'
import { useState } from 'react'
import { Link } from '@gravity/link'
import { Text } from '@gravity/text'
import { usePostSubmitBatchMutation } from '@monorepo/onboarding/hooks/usePostSubmitBatchMutation'
import { useParams } from 'react-router-dom'
import { useToast } from '@gravity/toast'

interface StartMigrationDialogProps {
  open: boolean
  setOpen: (open: boolean) => void
}

export const StartMigrationDialog = ({ open, setOpen }: StartMigrationDialogProps) => {
  const { batchID } = useParams<{ batchID: string }>()
  const { toast } = useToast()
  const [termsChecked, setTermsChecked] = useState(false)

  const {
    mutateAsync: executeStartMigration,
    isLoading: isStartMigrationLoading,
  } = usePostSubmitBatchMutation({
    onSuccess: async () => {
      setOpen(false)
    },
    onError: error => {
      setOpen(false)
      toast({
        title: 'Erro ao tentar migrar os dados',
        type: 'error',
      })
      console.error(error)
    },
  })

  const handleSubmit = async () => {
    await executeStartMigration({
      batchID,
    })
  }

  return (
    <Dialog
      backdrop
      size={2}
      open={open}
      onOpenChange={setOpen}
      title="Aviso sobre importação de alunos"
      description={
        <div className="flex flex-col gap-8">
          <Text variant="body-2-regular">
            Ao importar os alunos para a plataforma, as famílias poderão receber comunicados de{' '}
            <Link
              href="https://centraldeajuda.olaisaac.io/iniciando-a-parceria/o-que-e-dda-e-como-apoiar-os-responsaveis-financeiros"
              target="_blank"
              rel="noopener noreferrer"
            >
              Débito Direto Autorizado (DDA)
            </Link>{' '}
            emitidos pelos seus bancos. Esses DDAs são emitidos em nome das cobranças do isaac.
          </Text>
          <Text variant="body-2-regular">
            Os kits de comunicação com materiais explicativos já foram enviados. Utilize-os para
            orientar os responsáveis sobre o processo.
          </Text>
          <div className="flex flex-row gap-2 items-start">
            <Checkbox
              size={1}
              checked={termsChecked}
              onCheckedChange={(checked: boolean) => setTermsChecked(checked)}
            />
            <Text variant="body-2-regular">
              Estou ciente de que as famílias poderão receber notificações do isaac, incluindo DDA
              referentes às cobranças, e desejo continuar com a importação.
            </Text>
          </div>
        </div>
      }
      actionButton={
        <Button loading={isStartMigrationLoading} onClick={handleSubmit} disabled={!termsChecked}>
          Iniciar migração
        </Button>
      }
      cancelButton={<Button variant="ghost">Cancelar</Button>}
    />
  )
}
