import { useNavigation } from '@/escolas/hooks'
import { useQuery } from '@/shared/hooks/useQuery'
import { useGetGuardians } from '@monorepo/enrollment/hooks/queries/enrollment'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'
import { TextField } from '@gravity/text-field'
import { RadioGroup, RadioGroupItem } from '@gravity/radio'
import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { validateCpf } from 'src/shared/utils'
import { useIsReenrollment } from '../../hooks/useIsReenrollment'
import { GuardianFormNames, GuardianFormProps, GuardianFormType } from '../../types'
import { newEmptyGuardian } from '../../utils'
import { GuardianTextFields } from './GuardianTextFields'
import { useGuardianQuery } from '@/escolas/pages/responsaveis/GuardianDetails/hooks/useGuardianQuery'
import { GUARDIAN_ID_QUERY_PARAM } from '../../constants'
import { Callout } from '@gravity/callout'
import { usePersonScoreTaxId } from '../../hooks/usePersonScoreTaxId'
import type { ChangeEvent } from 'react'
import { CircularLoading } from '@/shared/components/CircularLoading'

const NEW_GUARDIAN_OPTION = 'new'
const NO_OPTION_SELECTED = ''

const taxIDHelperText = (
  isError: boolean,
  errorMessage: string,
  isLoading: boolean,
  isFetching: boolean,
  taxIDFound: boolean
): string => {
  const conditions: [boolean, string][] = [
    [isError, errorMessage || 'Insira um CPF válido'],
    [isLoading && !isFetching, ''],
    [isFetching, 'Buscando responsável ...'],
    [taxIDFound, 'CPF já cadastrado no isaac'],
    [!taxIDFound, 'Não há cadastros com este CPF'],
  ]

  for (const [condition, helperText] of conditions) {
    if (condition) return helperText
  }

  return ''
}

const GuardianForm = ({
  form,
  setIsFeedbackDialogOpen,
  isEditing,
  setIsEditing,
}: GuardianFormProps) => {
  const { schoolId = '' } = useNavigation()
  const { query } = useQuery()
  const { control, setValue, trigger, reset, getValues, clearErrors } = form
  const [selectedGuardianId, setSelectedGuardianId] = useState(NO_OPTION_SELECTED)
  const [sameGuardianSelected, setSameGuardianSelected] = useState(false)
  const { isReenrollment, student } = useIsReenrollment()
  const [taxIDFound, setTaxIDFound] = useState(false)
  const [lastSearchedGuardian, setLastSearchedGuardian] = useState<GuardianFormType>(
    newEmptyGuardian()
  )
  const { personTaxId } = usePersonScoreTaxId()

  const formValues = getValues()
  const { data: guardiansByStudentID = [], isError, isFetching } = useGetGuardians(
    'student-guardians',
    { schoolId, studentId: student?.id ?? '' },
    { enabled: isReenrollment, placeholderData: [] }
  )

  const {
    data: guardiansByTaxID,
    refetch,
    isSuccess: isSuccessByTaxID,
    isError: isErrorByTaxID,
    isLoading: isLoadingByTaxID,
    isFetching: isFetchingByTaxID,
  } = useGetGuardians(
    'taxid-search',
    { schoolId, taxID: formValues.guardian.tax_id },
    { enabled: false }
  )

  const guardianId = query.get(GUARDIAN_ID_QUERY_PARAM) ?? ''

  const { guardian, isFetchGuardianError, isFetchGuardianFetched } = useGuardianQuery({
    guardianId,
    schoolId,
  })

  useEffect(() => {
    if (isFetchGuardianFetched) {
      setValue('guardian.tax_id', guardian?.data?.tax_id ?? '')
    } else if (personTaxId) {
      setValue('guardian.tax_id', personTaxId)
    }
  }, [isFetchGuardianFetched, personTaxId])

  useEffect(() => {
    if (!isSuccessByTaxID) {
      return
    }

    const found = guardiansByTaxID.length > 0
    const guardian = found
      ? guardiansByTaxID[0]
      : { ...newEmptyGuardian(), tax_id: formValues.guardian.tax_id }

    setValue('guardian', guardian)
    setLastSearchedGuardian(guardian)
    setTaxIDFound(found)
  }, [isSuccessByTaxID, guardiansByTaxID])

  useEffect(() => {
    setIsFeedbackDialogOpen(isError || isErrorByTaxID || isFetchGuardianError)
  }, [isError, isErrorByTaxID, isFetchGuardianError])

  useEffect(() => {
    setIsEditing(false)
  }, [selectedGuardianId, isLoadingByTaxID])

  return (
    <>
      <div className="mb-4">
        <Heading variant="heading-h4-medium" className="mb-2">
          Responsável financeiro
        </Heading>
        <Text variant="subtitle-regular">
          Estes são os dados do responsável financeiro do aluno(a).
        </Text>
      </div>

      <Callout text="Cadastro de CPFs que não correspondem a responsáveis reais e regularmente matriculados pode ser considerada fraude e sujeitar-se a medidas legais. Forneça dados corretos." />

      {isReenrollment && isFetching && (
        <div className="flex justify-start m-4">
          <CircularLoading iconSize={40} />
        </div>
      )}

      {isReenrollment && guardiansByStudentID.length > 0 && (
        <div className="mt-4">
          <Text variant="subtitle-regular">
            Selecione um responsável já associado ao aluno ou adicione um novo
          </Text>

          <Controller
            name="guardian"
            rules={{
              validate: () =>
                guardiansByStudentID.length === 0 || selectedGuardianId !== NO_OPTION_SELECTED,
            }}
            control={control}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <div className="mt-2">
                <RadioGroup
                  aria-label="Utilizar o responsável já cadastrado"
                  name="shouldUseReenrollmentGuardian"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const guardianID = event.target.value
                    const foundGuardian = guardiansByStudentID.find(e => e.id === guardianID)
                    const guardian = foundGuardian ?? lastSearchedGuardian

                    reset({ ...formValues, guardian }, { keepErrors: true })
                    clearErrors('guardian')
                    setSelectedGuardianId(guardianID)
                    setSameGuardianSelected(Boolean(foundGuardian))
                    onChange(event)
                  }}
                >
                  {guardiansByStudentID.map(guardian => (
                    <RadioGroupItem
                      size={2}
                      key={guardian.id}
                      id={guardian.id}
                      value={guardian.id}
                      label={guardian.name}
                    />
                  ))}

                  <RadioGroupItem size={2} value={NEW_GUARDIAN_OPTION} label="Outro responsável" />
                </RadioGroup>
                {Boolean(error?.type) && (
                  <div className="mt-2 ml-9">
                    <Text
                      className="text-colors-text-semantic-colors-error-1"
                      variant="caption-regular"
                    >
                      Por favor, selecione uma opção.
                    </Text>
                  </div>
                )}
              </div>
            )}
          />
        </div>
      )}

      {(!isReenrollment || selectedGuardianId === NEW_GUARDIAN_OPTION) && (
        <div className="mt-6">
          <Text variant="body-1-regular">Digite o CPF do responsável financeiro:</Text>
          <div className="w-72 mt-4 mb-8">
            <Controller
              rules={{ required: true, validate: validateCpf }}
              name={GuardianFormNames.TAX_ID}
              control={control}
              render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
                <NumberFormat
                  {...rest}
                  id={GuardianFormNames.TAX_ID}
                  type="tel"
                  value={value}
                  format="###.###.###-##"
                  placeholder="CPF"
                  onValueChange={async currentValue => {
                    clearErrors(GuardianFormNames.TAX_ID)
                    if (!currentValue) {
                      return reset(
                        {
                          ...formValues,
                          guardian: { ...formValues.guardian, tax_id: '' },
                        },
                        { keepErrors: true }
                      )
                    }
                    onChange(currentValue.value)

                    if (currentValue.value.length === 11) {
                      const valid = await trigger(GuardianFormNames.TAX_ID)
                      if (valid) refetch()
                    }
                  }}
                  fullWidth
                  size={3}
                  customInput={TextField}
                  error={Boolean(error?.type)}
                  errorMessage={taxIDHelperText(
                    Boolean(error?.type),
                    error?.message ?? '',
                    isLoadingByTaxID,
                    isFetchingByTaxID,
                    taxIDFound
                  )}
                />
              )}
            />
          </div>
        </div>
      )}

      {(sameGuardianSelected || !isLoadingByTaxID) && (
        <GuardianTextFields
          form={form}
          sameGuardianSelected={sameGuardianSelected}
          taxIDFound={taxIDFound}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      )}
    </>
  )
}

export default GuardianForm
