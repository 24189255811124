/* eslint-disable typescript-sort-keys/string-enum */
import { Charge, Discount } from '@monorepo/onboarding/services/types/onboardingCharge'

export type ChargeFieldName = keyof Omit<Charge, 'validations' | 'is_valid' | 'discounts'>

export type DiscountFieldName = keyof Omit<Discount, 'is_valid' | 'validations'>

enum ChargeErrorCode {
  // AMOUNT
  CHARGE_AMOUNT_EMPTY = 'charge.amount:required',
  CHARGE_AMOUNT_INVALID_FORMAT = 'charge.amount:invalid_format',
  CHARGE_AMOUNT_ABOVE_LIMIT = 'charge.amount:above_limit',
  CHARGE_AMOUNT_BELOW_LIMIT = 'charge.amount:below_limit',
  // DUE DATE
  CHARGE_DUE_DATE_INVALID_FORMAT = 'charge.due_date:invalid_format',
  CHARGE_DUE_DATE_IN_THE_PAST = 'charge.due_date:in_the_past',
  CHARGE_DUE_DATE_EMPTY = 'charge.due_date:required',
  // ACCRUAL_DATE
  CHARGE_ACCRUAL_DATE_EMPTY = 'charge.accrual_date:required',
  CHARGE_ACCRUAL_DATE_INVALID_FORMAT = 'charge.accrual_date:invalid_format',
  // DISCOUNTS
  CHARGE_DISCOUNTS_AMOUNT_SUM_ABOVE_CHARGE_AMOUNT = 'charge.discount:sum_discounts_exceeds_charge',
  // SITUATION
  CHARGE_SITUATION_EMPTY = 'charge.situation:required',
  CHARGE_SITUATION_INVALID = 'charge.situation:invalid',
  // TYPE
  CHARGE_TYPE_EMPTY = 'charge.type:required',
  CHARGE_TYPE_INVALID = 'charge.type:invalid',
  // PAID DATE
  CHARGE_PAID_DATE_EMPTY = 'charge.paid_date:required',
  CHARGE_PAID_DATE_INVALID = 'charge.paid_date:invalid_format',
  CHARGE_PAID_DATE_IN_FUTURE = 'charge.paid_date:in_the_future',
}

// TODO: fix those messages
const CHARGE_ERROR_MESSAGES: Record<ChargeErrorCode, string> = {
  // AMOUNT
  [ChargeErrorCode.CHARGE_AMOUNT_EMPTY]: 'Informe um valor entre R$ 1,00 e R$ 10 mil.',
  [ChargeErrorCode.CHARGE_AMOUNT_ABOVE_LIMIT]: 'Informe um valor entre R$ 1,00 e R$ 10 mil.',
  [ChargeErrorCode.CHARGE_AMOUNT_BELOW_LIMIT]: 'Informe um valor entre R$ 1,00 e R$ 10 mil.',
  [ChargeErrorCode.CHARGE_AMOUNT_INVALID_FORMAT]: 'Informe um valor entre R$ 1,00 e R$ 10 mil.',
  // DUE_DATE
  [ChargeErrorCode.CHARGE_DUE_DATE_INVALID_FORMAT]: 'Informe uma data de vencimento válida',
  [ChargeErrorCode.CHARGE_DUE_DATE_IN_THE_PAST]: 'A data de vencimento não pode estar no passado',
  [ChargeErrorCode.CHARGE_DUE_DATE_EMPTY]: 'Informe uma data de vencimento válida',
  // ACCRUAL_DATE
  [ChargeErrorCode.CHARGE_ACCRUAL_DATE_EMPTY]: 'Informe uma data de competência válida',
  [ChargeErrorCode.CHARGE_ACCRUAL_DATE_INVALID_FORMAT]: 'Informe uma data de competência válida',
  // DISCOUNTS
  [ChargeErrorCode.CHARGE_DISCOUNTS_AMOUNT_SUM_ABOVE_CHARGE_AMOUNT]:
    'Descontos separados que somam 100% da parcela não são permitidos.',
  // SITUATION
  [ChargeErrorCode.CHARGE_SITUATION_EMPTY]: 'Selecione uma situação da cobrança válida',
  [ChargeErrorCode.CHARGE_SITUATION_INVALID]: 'Selecione uma situação da cobrança válida',
  // TYPE
  [ChargeErrorCode.CHARGE_TYPE_INVALID]: 'Informe se é matrícula ou mensalidade',
  [ChargeErrorCode.CHARGE_TYPE_EMPTY]: 'Informe se é matrícula ou mensalidade',
  // PAID DATE
  [ChargeErrorCode.CHARGE_PAID_DATE_EMPTY]: 'Informe uma data de pagamento válida',
  [ChargeErrorCode.CHARGE_PAID_DATE_INVALID]: 'Informe uma data de pagamento válida',
  [ChargeErrorCode.CHARGE_PAID_DATE_IN_FUTURE]: 'Informe a data do pagamento até o mês vigente',
}

export enum ChargeDiscountErrorCode {
  // EXPIRATION_DATE
  DISCOUNT_EXPIRATION_DATE_INVALID_FORMAT = 'expiration_date:invalid_format',
  DISCOUNT_EXPIRATION_DATE_EMPTY = 'expiration_date:required',
  DISCOUNT_EXPIRATION_DATE_IN_THE_PAST = 'expiration_date:in_the_past',
  DISCOUNT_EXPIRATION_DATE_INVALID = 'expiration_date:invalid',
  // DISCOUNT
  DISCOUNT_AMOUNT_EMPTY = 'amount:required',
  DISCOUNT_AMOUNT_INVALID = 'amount:invalid_format',
  DISCOUNT_AMOUNT_BELOW_LIMIT = 'amount:below_limit',
}

export const CHARGE_DISCOUNT_ERROR_MESSAGES: Record<ChargeDiscountErrorCode, string> = {
  // EXPIRATION_DATE
  [ChargeDiscountErrorCode.DISCOUNT_EXPIRATION_DATE_INVALID_FORMAT]:
    'Insira uma data de vencimento válida',
  [ChargeDiscountErrorCode.DISCOUNT_EXPIRATION_DATE_EMPTY]: 'Insira uma data de vencimento válida',
  [ChargeDiscountErrorCode.DISCOUNT_EXPIRATION_DATE_IN_THE_PAST]:
    'A data de vencimento não pode estar no passado',
  [ChargeDiscountErrorCode.DISCOUNT_EXPIRATION_DATE_INVALID]:
    'Informe uma data antes do vencimento da parcela.',
  // DISCOUNT
  [ChargeDiscountErrorCode.DISCOUNT_AMOUNT_BELOW_LIMIT]: 'Desconto deve ser maior que zero',
  [ChargeDiscountErrorCode.DISCOUNT_AMOUNT_INVALID]: 'Formato inválido',
  [ChargeDiscountErrorCode.DISCOUNT_AMOUNT_EMPTY]: 'Informe um desconto válido',
}

export const getChargeErrorProps = (
  name: ChargeFieldName,
  charge: Charge,
  originalCharge: Charge
) => {
  const field = `charge.${name}`

  const error =
    charge?.validations?.some(validation => validation.field === field) &&
    charge[name] === originalCharge[name]

  const errorMessage =
    CHARGE_ERROR_MESSAGES[
      charge?.validations?.filter(validation => validation.field === field)[0]
        ?.error_code as ChargeErrorCode
    ]

  return { error, errorMessage }
}

export const getChargeDiscountErrorProps = (
  fieldName: DiscountFieldName,
  index: number,
  charge: Charge,
  originalCharge: Charge
) => {
  const fieldHasChanges =
    charge.discounts[index][fieldName] !== originalCharge.discounts[index][fieldName]

  if (fieldHasChanges) {
    return {
      error: false,
      errorMessage: '',
    }
  }

  const chargeErrorProps = getChargeErrorProps(
    'discount' as ChargeFieldName,
    charge,
    originalCharge
  )

  if (chargeErrorProps.error && fieldName === 'amount') {
    return chargeErrorProps
  }

  const field = `charge.discount.${index}.${fieldName}`

  const discountError = charge.validations?.find(validation => validation.field === field)

  if (!discountError) {
    return {
      error: false,
      errorMessage: '',
    }
  }

  const discountErrorCode = discountError.error_code.replace(
    `charge.discount.${index}.`,
    ''
  ) as ChargeDiscountErrorCode

  const errorMessage = CHARGE_DISCOUNT_ERROR_MESSAGES?.[discountErrorCode] ?? ''

  return { error: true, errorMessage }
}
