import { IconButton } from '@gravity/icon-button'
import { formatFileSize } from '../../utils'
import { Close, DownloadOutline } from '@gravity/icons'

type UploadFileProps = {
  allowedFormats: string
  cancelUpload?: () => void
  downloadFile: () => void
  error?: string
  fileName: string
  fileSize?: number
  isLoading: boolean
  onFileSelect: (file: File) => void
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onRemoveFile: () => void
  progressInPercentage?: number
  state: 'idle' | 'loading' | 'success' | 'error'
  timeRemaining?: string
}

export const UploadFile = ({
  state,
  fileName,
  timeRemaining,
  progressInPercentage,
  downloadFile,
  cancelUpload,
  allowedFormats,
  fileSize = 0,
  error,
  onInputChange,
  onFileSelect,
  isLoading,
}: UploadFileProps) => {
  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault()

    const file = e.dataTransfer.files[0]
    if (file) {
      onFileSelect(file)
    }
  }
  if (isLoading) return null

  if (state === 'idle')
    return (
      <div onDrop={handleDrop}>
        <input type="file" accept={allowedFormats} onChange={onInputChange} />
        {error && <span>{error}</span>}
      </div>
    )

  if (state === 'loading')
    return (
      <div>
        {fileName} - {formatFileSize(fileSize)}
        {timeRemaining} + {progressInPercentage}
        <IconButton type="button" variant="ghost" onClick={cancelUpload}>
          <Close />
        </IconButton>
      </div>
    )

  if (state === 'success')
    return (
      <>
        {fileName} - {formatFileSize(fileSize)}
        <IconButton type="button" variant="ghost" onClick={downloadFile}>
          <DownloadOutline />
        </IconButton>
      </>
    )

  return null
}
