import { PreContractStatuses as ContractStatusType } from '@/shared/interfaces'
import { Text } from '@gravity/text'
import { InformationOutline } from '@gravity/icons'
import { contractStatusDict } from './constants'
import { Container, StyledTooltip } from './styles'

export const ContractStatusBadge = ({
  status,
  enableTooltip = true,
}: {
  enableTooltip?: boolean
  status: ContractStatusType
}) => {
  if (enableTooltip) {
    return (
      <StyledTooltip text={`${contractStatusDict[status]?.label}`}>
        <Container className={`${contractStatusDict[status]?.color}`}>
          <Text variant="caption-regular"> {contractStatusDict[status]?.text}</Text>
          <InformationOutline fontSize="small" />
        </Container>
      </StyledTooltip>
    )
  }

  return (
    <>
      <Container className={`${contractStatusDict[status]?.color}`}>
        <Text variant="caption-regular"> {contractStatusDict[status]?.text}</Text>
      </Container>
    </>
  )
}
