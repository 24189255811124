import { Grid, GridItem } from '@gravity/grid'

import { CommunicationSection } from '@monorepo/school-home/pages/SchoolHome/components/CommunicationSection'
import { QuickAccessSection } from '@monorepo/school-home/pages/SchoolHome/components/QuickAccessSection'
import { HomeSearch } from './components/HomeSearch'
import { MainInfoSection } from './components/MainInfoSection'
import { AlertBannerSection } from './components/AlertBannerSection'

import { useJWT } from '@/shared/hooks/useJWT'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useLayout } from '@/shared/hooks/useLayout'
import { isIsaacPaySchool } from '@/shared/utils/isIsaacPaySchool'

import { Container } from './styles'

export const SchoolHome = () => {
  const { hasPayoutReportAccess } = useJWT()
  const { school } = useSelectedSchool()

  const isIsaacPay = isIsaacPaySchool(school)
  const showMainInfoSection = hasPayoutReportAccess && !isIsaacPay

  useLayout({
    enableSideMenu: true,
    enableHeader: true,
    headerTitle: 'Página inicial',
  })

  return (
    <>
      <AlertBannerSection />
      <Container>
        <div className="search">
          <Grid>
            <GridItem xl={12} lg={12} md={12} sm={8} xs={4}>
              <HomeSearch />
            </GridItem>
          </Grid>
        </div>

        <QuickAccessSection />

        <CommunicationSection />

        {showMainInfoSection && <MainInfoSection />}
      </Container>
    </>
  )
}
