import { Button } from '@gravity/button'
import { ReactElement, useEffect } from 'react'
import { Text } from '@gravity/text'
import { Tabs } from '@gravity/tabs'
import {
  GraduationCapOutline,
  BookOpenOutline,
  UserOutline,
  MoneyDollarCircleOutline,
  IconType,
} from '@gravity/icons'
import { useQuery } from '@/shared/hooks/useQuery'
import { usePagination } from '@/shared/hooks/usePagination'
import { LayoutTemplate } from '@monorepo/onboarding/LayoutTemplate'
import { StudentTable } from '@monorepo/onboarding/pages/components/StudentTable'
import { ContractTableByProduct } from '@monorepo/onboarding/pages/components/ContractTableByProduct'
import { GuardianTable } from '../components/GuardianTable'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useGetOnboardingBatch } from '../../hooks/useFetchOnboardingBatches'
import { useHistory, useParams } from 'react-router-dom'
import { LoadingPage } from '../Loading'
import { OverrideBatchTrigger } from '../components/OverrideBatch/OverrideBatchTrigger'
import { ContractCharges } from '../components/ContractCharges'
import { EventProvider, pageNames, useEvents } from '../../hooks/eventContext'
import { Tooltip } from '@gravity/tooltip'
import styled from 'styled-components'
import { useJWT } from '@/shared/hooks/useJWT'
import { BypassButton } from '../components/BypassButton'

const TAB_QUERY_KEY = 'tab'

enum TabKey {
  CHARGES = 'pagamentos',
  GUARDIANS = 'responsaveis',
  PRODUCTS = 'academicos',
  STUDENTS = 'alunos',
}

const tabs: { icon: ReactElement<IconType>; key: TabKey; table?: JSX.Element; title: string }[] = [
  {
    key: TabKey.PRODUCTS,
    icon: <GraduationCapOutline />,
    title: 'Dados acadêmicos',
    table: <ContractTableByProduct />,
  },
  {
    key: TabKey.STUDENTS,
    icon: <BookOpenOutline />,
    title: 'Dados de alunos',
    table: <StudentTable />,
  },
  {
    key: TabKey.GUARDIANS,
    icon: <UserOutline />,
    title: 'Dados de responsáveis',
    table: <GuardianTable />,
  },
  {
    key: TabKey.CHARGES,
    icon: <MoneyDollarCircleOutline />,
    title: 'Dados de pagamentos',
    table: <ContractCharges />,
  },
]

const BypassButtonWrapper = styled.div`
  margin-bottom: 24px;
`

const PageContent = () => {
  const { query, setOnQueryParam } = useQuery()
  const { updatePaginationValue } = usePagination()

  const handleActiveTab = (value: TabKey) => {
    setOnQueryParam(value, TAB_QUERY_KEY)
    updatePaginationValue('page', 1)
  }

  useEffect(() => {
    const tabQuery = query.get(TAB_QUERY_KEY) as TabKey
    const currentTab = tabs.map(i => i.key).includes(tabQuery) ? tabQuery : TabKey.PRODUCTS

    setOnQueryParam(currentTab, TAB_QUERY_KEY)
  }, [])

  return (
    <Tabs.Root
      value={query.get(TAB_QUERY_KEY) ?? ''}
      onValueChange={value => handleActiveTab(value as TabKey)}
    >
      <Tabs.List background="gray">
        {tabs.map(tab => (
          <Tabs.Trigger key={tab.key} value={tab.key} iconStart={tab.icon}>
            <Text variant="button-2" className="text-inherit">
              {tab.title}
            </Text>
          </Tabs.Trigger>
        ))}
      </Tabs.List>

      {tabs.map(tab => (
        <Tabs.Content key={tab.key} value={tab.key} style={{ marginTop: 32 }}>
          {tab.table}
        </Tabs.Content>
      ))}
    </Tabs.Root>
  )
}

const PageComponent = () => {
  const { schoolSlug } = useSelectedSchool()
  const { batchID } = useParams<{ batchID: string }>()
  const history = useHistory()
  const events = useEvents()
  const { isBackofficeUser } = useJWT()

  const { isLoading, isError, data: onboardingBatch } = useGetOnboardingBatch(
    { batchID },
    {
      onSuccess(data) {
        if (data.status === 'PROCESSING')
          return history.push(`/${schoolSlug}/migrar-dados/${data.id}/migrar`)

        if (data.status !== 'PENDING') return history.push(`/${schoolSlug}/migrar-dados/`)
      },
      onError(error) {
        console.error(error)
        history.push(`/${schoolSlug}/migrar-dados/`)
      },
    }
  )

  useEffect(() => {
    events?.validatePageViewed()
  }, [])

  if (isLoading || isError) {
    return <LoadingPage />
  }

  return (
    <LayoutTemplate
      title={
        <>
          {isBackofficeUser && (
            <BypassButtonWrapper>
              <BypassButton />
            </BypassButtonWrapper>
          )}
          <span>Corrigir dados da escola</span>
        </>
      }
      description="Nesta etapa, você deve informar ou corrigir todos os dados obrigatórios para a migração dos contratos. Não se preocupe com os dados que não aparecem aqui, isso significa que eles estão corretos e prontos para serem migrados."
      footer={
        <>
          <OverrideBatchTrigger pageName={pageNames.VALIDATE_PAGE} />
          {onboardingBatch.can_submit !== false ? (
            <Button
              onClick={() => {
                events?.continueBatchButtonClicked()
                return history.push(`/${schoolSlug}/migrar-dados/${batchID}/migrar/`)
              }}
            >
              Continuar
            </Button>
          ) : (
            <Tooltip text="Preencha os dados para continuar">
              {/* Tooltips don`t work well with disabled elements */}
              <div>
                <Button disabled>Continuar</Button>
              </div>
            </Tooltip>
          )}
        </>
      }
    >
      <PageContent />
    </LayoutTemplate>
  )
}

export const ValidatePage = () => {
  return (
    <EventProvider>
      <PageComponent />
    </EventProvider>
  )
}
