import { useState } from 'react'
import envConfig from '@/config'
import { useRouteMatch, Link as RRDLink, useHistory } from 'react-router-dom'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import styled, { css } from 'styled-components'
import {
  SideMenu as DsSideMenu,
  SideMenuItem,
  SideMenuList,
  SideMenuSearchResultLi,
  SideMenuLink as SideMenuLinkDS,
} from '@olaisaac/design-system'

import {
  Search,
  HomeOutline,
  BarChartOutline,
  GraduationCapOutline,
  UserGroupOutline,
  ListView,
  HandCoinOutline,
  MoneyDollarCircleOutline,
  DashboardOutline,
  ClipboardOutline,
  PuzzleOutline,
} from '@gravity/icons'
import { Text } from '@gravity/text'

import { useJWT } from '@/shared/hooks/useJWT'
import { useNavigation, useSchools } from '@/escolas/hooks'
import { useOnboardingRedirect } from '@/escolas/hooks/useOnboardingRedirect'
import { useCreditAccessStore } from '@monorepo/credit/creditAccessStore'

import { EventIdentifierName } from '@/modules/app/models/EventIdentifierName.enum'
import { EventPageName } from '@/modules/app/models/EventPageName.enum'

import { ENROLLMENT_PAGE_BASE_PATH } from '@monorepo/enrollment/constants/enrollment'
import { IRREGULARITY_PAGE_NAME } from '@monorepo/enrollment/pages/IrregularStudentEnrollments/constants'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import ConfirmationDialog from '@/shared/components/ConfirmationDialog'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'

const SideMenuLink = styled(SideMenuLinkDS)`
  .label-item {
    overflow: hidden;
    white-space: nowrap;
    color: ${({ theme }) => theme.gravity.colors['colors-text-main-3']};
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover,
      &:focus-visible {
        .label-item {
          color: ${({ theme }) => theme.gravity.colors['colors-text-main-1']};
        }
      }
    `}

  ${({ active }) =>
    active &&
    css`
      .label-item {
        color: ${({ theme }) => theme.gravity.colors['colors-text-main-1']};
      }
    `}
`

const Link = styled(RRDLink)({
  textDecoration: 'none',
})

const BottomLinks = styled.div`
  margin-top: auto;
`

const BUTTON_LABELS = {
  REPORT: 'Relatórios',
  CREDIT: 'Menu de crédito',
}

export const SchoolSideMenu = () => {
  const { push } = useHistory()
  const { isAdmin, isSchool, schoolIds, hasPayoutReportAccess } = useJWT()
  const { getNavigationUrl, schoolSlug } = useNavigation()

  const { hasCreditAccess } = useCreditAccessStore()

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const [showConfirmReonboardingMessage, setShowConfirmReonboardingMessage] = useState(false)

  const { schools, hasFetched } = useSchools()

  const { SELF_ONBOARDING_REDIRECT_URL } = envConfig

  const { data: onboardingRedirect } = useOnboardingRedirect()
  const enableSelfOnboarding = onboardingRedirect?.is_school_active
  const schoolPageSelfOnboardingUrl = `${SELF_ONBOARDING_REDIRECT_URL}/escolas/${onboardingRedirect?.self_onboarding_school_id}`

  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)

  const openMessageSelfOnboarding = () => {
    setShowConfirmReonboardingMessage(true)
  }

  const handleGoToSelfOnboarding = () => {
    schoolPageSelfOnboardingUrl && window.location.replace(schoolPageSelfOnboardingUrl)
  }

  function escapeStrSpecialChars(str: string) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }

  const handleGetOptions = async (inputValue: string) => {
    const escapedInputValue = escapeStrSpecialChars(inputValue)
    // The next code line gives this error: security/detect-non-literal-regexp
    // But, this is not an request, is just runs on a array of Schools already stored
    // So there is no problem running it
    // eslint-disable-next-line security/detect-non-literal-regexp
    return schools.filter(school => school.name.match(new RegExp(escapedInputValue, 'i')))
  }

  const homePath = `/${schoolSlug}/`
  const managementDahsboardPath = `/${schoolSlug}/painel-de-gestao`
  const transfersPath = `/${schoolSlug}/repasses`
  const guardiansPath = getNavigationUrl({ path: '/responsaveis' })
  const productsPath = getNavigationUrl({ path: '/produtos' })
  const creditPath = getNavigationUrl({ path: '/credito' })
  const reportsPath = getNavigationUrl({ path: '/relatorios' })
  const enrollmentPath = `/${schoolSlug}/${ENROLLMENT_PAGE_BASE_PATH}`
  const irregularitiesPath = `/${schoolSlug}/irregularidades`

  const hasMultipleSchools = schoolIds?.length > 1
  const showSchoolSelection = isAdmin || (isSchool && hasMultipleSchools)

  const handleChangeSchool = (schoolSlug: string) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.SIDE_MENU,
        name: EventDispatcherEvents.OPTION_SELECTED,
        pageName: EventPageName.APP,
        identifierName: EventIdentifierName.CHANGE_SCHOOL,
        customProperties: {
          $form_action: 'Troca de unidade',
          $selected_school_slug: schoolSlug,
        },
      })

    push(`/${schoolSlug}`)
  }

  const handleCreditButton = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.CREDIT,
        name: EventDispatcherEvents.MENU_CLICKED,
        pageName: EventPageName.APP,
        identifierName: EventIdentifierName.NAVIGATE_TO_FINANCIAL_CREDIT,
        customProperties: { $button_name: BUTTON_LABELS.CREDIT },
      })
  }

  return (
    <>
      <DsSideMenu
        menuList={(setSearchVisibility: (visible: boolean) => void) => (
          <SideMenuList>
            {showSchoolSelection && hasFetched && (
              <SideMenuItem>
                <SideMenuLink
                  onClick={() => setSearchVisibility(true)}
                  icon={<Search size="sm" />}
                  label={
                    <Text variant="body-1-regular" className="label-item">
                      Pesquisar unidade
                    </Text>
                  }
                />
              </SideMenuItem>
            )}

            <SideMenuItem>
              <Link to={`/${schoolSlug}`}>
                <SideMenuLink
                  icon={<HomeOutline />}
                  label={
                    <Text variant="body-1-regular" className="label-item">
                      Página Inicial
                    </Text>
                  }
                  active={Boolean(useRouteMatch({ path: homePath, exact: true }))}
                />
              </Link>
            </SideMenuItem>

            {hasPayoutReportAccess && (
              <SideMenuItem>
                <Link to={managementDahsboardPath}>
                  <SideMenuLink
                    icon={<DashboardOutline size="sm" />}
                    label={
                      <Text variant="body-1-regular" className="label-item">
                        Painel de gestão
                      </Text>
                    }
                    active={Boolean(useRouteMatch({ path: managementDahsboardPath, exact: true }))}
                    onClick={() => {
                      isInitialized &&
                        eventDispatcherClient.sendEvent({
                          scope: EventDispatcherEventScopes.SIDE_MENU,
                          name: EventDispatcherEvents.BUTTON_CLICK,
                          pageName: EventPageName.APP,
                          identifierName: EventIdentifierName.NAVIGATE_TO_AER_MANAGEMENT_DASHBOARD,
                          customProperties: {
                            $Button_name: 'Painel de gestão',
                          },
                        })
                    }}
                  />
                </Link>
              </SideMenuItem>
            )}

            <SideMenuItem>
              <Link to={enrollmentPath}>
                <SideMenuLink
                  icon={<GraduationCapOutline size="sm" />}
                  label={
                    <Text variant="body-1-regular" className="label-item">
                      Matrículas
                    </Text>
                  }
                  active={Boolean(useRouteMatch({ path: enrollmentPath }))}
                  onClick={() =>
                    isInitialized &&
                    eventDispatcherClient.sendEvent({
                      scope: EventDispatcherEventScopes.SIDE_MENU,
                      name: EventDispatcherEvents.MENU_CLICKED,
                      pageName: EventPageName.APP,
                      identifierName: EventIdentifierName.NAVIGATE_TO_OPERATION_ENROLLMENTS,
                      customProperties: {
                        $Button_name: 'MATRÍCULAS_2024_SIDE_MENU',
                        $Menu_name: 'MENU_LATERAL',
                      },
                    })
                  }
                />
              </Link>
            </SideMenuItem>

            <SideMenuItem>
              <Link to={guardiansPath} data-testid="guardiansListButton">
                <SideMenuLink
                  icon={<UserGroupOutline size="sm" />}
                  label={
                    <Text variant="body-1-regular" className="label-item">
                      Responsáveis
                    </Text>
                  }
                  active={Boolean(useRouteMatch({ path: guardiansPath }))}
                  onClick={() => {
                    isInitialized &&
                      eventDispatcherClient.sendEvent({
                        scope: EventDispatcherEventScopes.SIDE_MENU,
                        name: EventDispatcherEvents.ACCESS_GUARDIANS_LIST,
                        pageName: EventPageName.APP,
                        identifierName: EventIdentifierName.NAVIGATE_TO_OPERATION_GUARDIANS,
                      })
                  }}
                />
              </Link>
            </SideMenuItem>

            {isIntegratedSchool && (
              <SideMenuItem>
                <Link to={irregularitiesPath}>
                  <SideMenuLink
                    icon={<PuzzleOutline size="sm" />}
                    label={
                      <Text variant="body-1-regular" className="label-item">
                        {IRREGULARITY_PAGE_NAME}
                      </Text>
                    }
                    active={Boolean(useRouteMatch({ path: irregularitiesPath }))}
                  />
                </Link>
              </SideMenuItem>
            )}

            {hasPayoutReportAccess && (
              <SideMenuItem>
                <Link to={transfersPath}>
                  <SideMenuLink
                    onClick={() => {
                      isInitialized &&
                        eventDispatcherClient.sendEvent({
                          scope: EventDispatcherEventScopes.SIDE_MENU,
                          name: EventDispatcherEvents.MENU_CLICKED,
                          pageName: EventPageName.APP,
                          identifierName: EventIdentifierName.NAVIGATE_TO_FINANCIAL_TRANSFERS,
                        })
                    }}
                    icon={<MoneyDollarCircleOutline size="sm" />}
                    label={
                      <Text variant="body-1-regular" className="label-item">
                        Repasses
                      </Text>
                    }
                    active={Boolean(useRouteMatch({ path: transfersPath, exact: true }))}
                  />
                </Link>
              </SideMenuItem>
            )}
            {hasCreditAccess && hasPayoutReportAccess && (
              <SideMenuItem>
                <Link to={creditPath} onClick={handleCreditButton}>
                  <SideMenuLink
                    icon={<HandCoinOutline size="sm" />}
                    label={
                      <Text variant="body-1-regular" className="label-item">
                        Crédito
                      </Text>
                    }
                    active={Boolean(useRouteMatch({ path: creditPath, exact: true }))}
                  />
                </Link>
              </SideMenuItem>
            )}

            <SideMenuItem>
              <Link
                to={reportsPath}
                onClick={() => {
                  isInitialized &&
                    eventDispatcherClient.sendEvent({
                      name: EventDispatcherEvents.BUTTON_CLICK,
                      scope: EventDispatcherEventScopes.SIDE_MENU,
                      customProperties: {
                        $button_label: BUTTON_LABELS.REPORT,
                      },
                      pageName: EventPageName.APP,
                      identifierName: EventIdentifierName.NAVIGATE_TO_AER_REPORTS,
                    })
                }}
              >
                <SideMenuLink
                  icon={<BarChartOutline size="sm" />}
                  label={
                    <Text variant="body-1-regular" className="label-item">
                      Relatórios
                    </Text>
                  }
                  active={Boolean(useRouteMatch({ path: reportsPath }))}
                />
              </Link>
            </SideMenuItem>

            <SideMenuItem>
              <Link to={productsPath}>
                <SideMenuLink
                  icon={<ListView size="sm" />}
                  label={
                    <Text variant="body-1-regular" className="label-item">
                      Produtos
                    </Text>
                  }
                  active={Boolean(useRouteMatch({ path: productsPath }))}
                />
              </Link>
            </SideMenuItem>

            <BottomLinks>
              {enableSelfOnboarding && (
                <SideMenuItem>
                  <SideMenuLink
                    icon={<ClipboardOutline size="sm" />}
                    label={
                      <Text variant="body-1-regular" className="label-item">
                        Ferramenta de migração
                      </Text>
                    }
                    onClick={openMessageSelfOnboarding}
                  />
                </SideMenuItem>
              )}
            </BottomLinks>
          </SideMenuList>
        )}
        options={schools}
        getOptions={handleGetOptions}
        renderOptions={(schools, inputValue) => {
          const escapedInputValue = escapeStrSpecialChars(inputValue ?? '')
          return schools
            .filter(school => school.name.match(new RegExp(escapedInputValue, 'i'))) // eslint-disable-line security/detect-non-literal-regexp
            .map(school => (
              <SideMenuSearchResultLi
                key={school.id}
                onClick={() => handleChangeSchool(school.slug)}
              >
                {school.name}
              </SideMenuSearchResultLi>
            ))
        }}
      />

      <ConfirmationDialog
        size={3}
        isVisible={showConfirmReonboardingMessage}
        onClose={() => setShowConfirmReonboardingMessage(false)}
        submitHandler={handleGoToSelfOnboarding}
        backButtonLabel="Continuar na plataforma"
        buttonLabel="Fazer migração de contratos"
        title="Gostaria de migrar mais contratos para a plataforma isaac?"
      >
        <Text variant="body-1-regular" style={{ lineHeight: 2 }}>
          É possível fazer o processo de migração de várias planilhas mais uma vez através da
          ferramenta de migração. Você pode acessar a ferramenta no menu lateral.
        </Text>
      </ConfirmationDialog>
    </>
  )
}
