import dayjs from 'dayjs'

export const isTimeExpired = (date: string | null, expirationTimeInHours: number): boolean => {
  if (!date) return true

  const storedDate = dayjs(date)
  const currentDate = dayjs()

  const hoursDifference = currentDate.diff(storedDate, 'hour')

  return hoursDifference >= expirationTimeInHours
}
