import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  FailureFeedbackContent,
  FailureFeedbackDialog,
} from '@/shared/components/ConfirmationDialog'
import { useAgreement } from 'src/escolas/contexts/agreementContext'
import { useMakeAgreementMutation } from 'src/escolas/hooks/queries/useMakeAgreementMutation'

import type { PaymentOptionsValue, StepComponentProps } from '@/modules/guardians/Negotiation/types'

import { Checkout } from 'src/shared/interfaces'
import {
  AgreementInvoice,
  AgreementStatus,
  ChosenPaymentPlan,
  FormOfPayment,
  MakeAgreementBody,
  SimulationPaymentMethod,
  DuplicateAgreementRequest,
} from '@/modules/guardians/models/agreement'
import { getIdsForPayment } from '../FormOfPaymentStep/components/helpers/getIdsForPayment'
import { isPaymentByPos } from '../FormOfPaymentStep/components/helpers/isPaymentByPos'
import StatusCheckout from '../../components/StatusCheckout/StatusCheckout'
import StatusDialog from '../../components/StatusDialog'
import useFlowCheckout from '../../hooks/useFlowCheckout'
import OtherMethod from './component/Methods/OtherMethod'
import PosMethod from './component/Methods/POSMethod'
import * as Styled from './styles'
import { usePaymentCreditCard } from '@/escolas/hooks'
import { getDuplicateAgreementPaymentPlan } from '../../helpers/agreement'
import { useDuplicateAgreement } from '@/escolas/hooks/useDuplicateAgreement'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { getReceivablesByAgreementSimulation } from '../FormOfPaymentStep/components/helpers/getReceivablesByAgreementSimulation'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { NegotiationData, StatusDialogData } from './types'
import { calculateAgreementFlags } from '../FormOfPaymentStep/components/helpers/calculateAgreementFlags'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventPageName } from '@/shared/models/enums/EventPageName.enum'
import { EventIdentifierName } from '@/shared/models/enums/EventIdentifierName.enum'
import { CancelNegotiationDialog } from '../../components/CancelNegotiationDialog'
import StepFooter from '../../components/StepFooter'

const PaymentOptionStep = ({ id, onFinish, goBack, onForward }: StepComponentProps) => {
  const [isValid, setIsValid] = useState(false)
  const [chosenOption, setChosenOption] = useState<PaymentOptionsValue | undefined>()
  const [showFailModal, setShowFailModal] = useState(false)
  const [statusDialogData, setStatusDialogData] = useState<StatusDialogData>({
    isOpen: false,
    isOverdue: true,
  })

  const [goBackDialogVisible, setGoBackDialogVisible] = useState(false)

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const isEnableUnifiedInvoice = useUnleashFlag(UnleashFlags.REG_228_ENABLE_UNIFIED_INVOICE)

  const { guardianId } = useParams<{ guardianId: uuid }>()

  const { school } = useSelectedSchool()

  const {
    selectedFormOfPayment,
    positionAgreement,
    agreementSimulations,
    hasPaidAgreement,
    totalAmount,
    totalAmountWithFee,
    handlerAgreementStatus,
    setCheckoutData,
    updateAgreementSimulation,
    setPositionAgreement,
  } = useAgreement()

  const { paymentFee } = usePaymentCreditCard()

  const makeAgreementMutation = useMakeAgreementMutation()
  const duplicateAgreement = useDuplicateAgreement()

  const selectedAgreement = agreementSimulations[positionAgreement]

  const goToNextStep = (invoices: AgreementInvoice[], negotiationId?: uuid) => {
    const agreementStatus: AgreementStatus = {
      finished: true,
      invoices: invoices,
      negotiationId,
    }

    handlerAgreementStatus(positionAgreement, agreementStatus)
    onFinish()
  }

  const handleOnFinishCheckout = (checkoutData: Checkout) => {
    setCheckoutData(checkoutData)
    const invoices = [
      {
        amount: checkoutData.amount,
        due_date: dayjs().toString(),
        payment_method: SimulationPaymentMethod.POS,
        receivable_id: selectedAgreement.receivable_ids[0],
        instalment_credit_card: checkoutData.installments_count,
      },
    ]

    goToNextStep(invoices)
  }

  const checkoutValues = useFlowCheckout(handleOnFinishCheckout)

  const agreements =
    selectedFormOfPayment === FormOfPayment.OTHER ? [selectedAgreement] : agreementSimulations

  const getCurrentTotalAmount = () => {
    if (
      paymentFee?.enabled &&
      chosenOption?.paymentOption === SimulationPaymentMethod.CREDIT_CARD
    ) {
      const creditCardFee = selectedAgreement?.credit_card_fee ?? 0
      return selectedFormOfPayment === FormOfPayment.POS_V2
        ? totalAmountWithFee
        : selectedAgreement.total_amount + creditCardFee
    }
    return selectedFormOfPayment === FormOfPayment.POS_V2
      ? totalAmount
      : selectedAgreement.total_amount
  }

  const handleDownPaymentCheckout = (chosenOption: PaymentOptionsValue) => {
    const { installments, pos, paymentOption } = chosenOption

    if (isEnableUnifiedInvoice) {
      setStatusDialogData({
        isOpen: true,
        isOverdue: true,
        title: 'Gerando fatura para o pagamento',
        isTextVisible: false,
      })

      const urn = `urn:down-payment:${selectedAgreement?.origin_negotiation?.id}`
      checkoutValues.startCheckoutV2(
        installments,
        paymentOption,
        pos?.pos_id ?? '',
        [urn],
        pos?.provider
      )
      return
    }

    if (selectedAgreement?.negotiation_data?.id) {
      setStatusDialogData({
        isOpen: true,
        isOverdue: false,
        title: 'Comunicando com a maquininha',
        isTextVisible: false,
      })

      checkoutValues.startCheckout({
        numberOfInstallments: installments,
        paymentMethod: paymentOption,
        posId: pos?.pos_id,
        receivablesIds: [selectedAgreement.negotiation_data?.receivable_id],
        checkoutProvider: pos?.provider,
        downPaymentConversion: true,
      })
      return
    }

    setStatusDialogData({
      isOpen: true,
      isOverdue: false,
      title: 'Carregando informações de pagamento da entrada',
      isTextVisible: false,
    })

    const paymentPlan = getDuplicateAgreementPaymentPlan(selectedAgreement?.origin_negotiation)

    const body: DuplicateAgreementRequest = {
      simulation_id: selectedAgreement?.origin_negotiation?.simulation_id ?? '',
      guardian_id: guardianId,
      payment_plan: paymentPlan,
    }

    duplicateAgreement.mutate(body, {
      onSuccess: data => {
        const invoices = data.invoices.map(invoice => {
          if (paymentPlan.payment_method === SimulationPaymentMethod.CREDIT_CARD) {
            return { ...invoice, instalment_credit_card: paymentPlan.number_of_installments }
          }
          return invoice
        })
        const firstInvoice = invoices.find(invoice => invoice.installment?.startsWith('1/'))

        updateAgreementSimulation(positionAgreement, {
          ...selectedAgreement,
          negotiation_data: {
            id: data.id,
            short_id: data.id.substring(0, 6).toUpperCase(),
            receivable_id: firstInvoice?.receivable_id ?? '',
          },
        })

        checkoutValues.startCheckout({
          numberOfInstallments: installments,
          paymentMethod: paymentOption,
          posId: pos?.pos_id,
          receivablesIds: [firstInvoice?.receivable_id ?? ''],
          checkoutProvider: pos?.provider,
          downPaymentConversion: true,
        })
      },
      onError: () => {
        setTimeout(() => {
          setShowFailModal(true)
        }, 300)
        setStatusDialogData({
          isOpen: false,
          isOverdue: true,
        })
      },
    })
  }

  const handleCheckout = (
    chosenOption: PaymentOptionsValue,
    negotiationData?: NegotiationData[]
  ) => {
    setStatusDialogData({
      isOpen: true,
      isOverdue: true,
      title: isEnableUnifiedInvoice
        ? 'Gerando fatura para o pagamento'
        : 'Comunicando com a maquininha',
      shortIds: negotiationData?.map(({ shortId }) => shortId),
      isTextVisible: true,
    })

    const { installments, pos, paymentOption } = chosenOption

    const receivableIds = getIdsForPayment(
      agreementSimulations,
      positionAgreement,
      selectedFormOfPayment === FormOfPayment.POS || selectedFormOfPayment === FormOfPayment.POS_V2
    )

    if (isEnableUnifiedInvoice) {
      const urns =
        negotiationData?.map(({ urn }) => urn) ?? receivableIds.map(id => `urn:receivable:${id}`)
      checkoutValues.startCheckoutV2(
        installments,
        paymentOption,
        pos?.pos_id ?? '',
        urns,
        pos?.provider
      )
      return
    }

    const {
      keepDuePaymentDiscounts,
      keepEarlyPaymentDiscounts,
      keepFineAndInterest,
    } = calculateAgreementFlags(agreements)

    checkoutValues.startCheckout({
      numberOfInstallments: installments,
      paymentMethod: paymentOption,
      posId: pos?.pos_id,
      receivablesIds: receivableIds,
      checkoutProvider: pos?.provider,
      keepFineAndInterest,
      keepDuePaymentDiscounts,
      keepEarlyPaymentDiscounts,
    })
  }

  const handleInitCheckout = (chosenOption: PaymentOptionsValue) => {
    const hasOverdueReceivables = agreements.some(agreement =>
      agreement.receivables.some(receivable => receivable.status === 'OVERDUE')
    )

    if (hasOverdueReceivables && isEnableUnifiedInvoice) {
      setStatusDialogData({
        isOpen: true,
        isOverdue: true,
        title: 'Carregando informações de pagamento',
        isTextVisible: false,
      })

      const { dueDate, installments } = chosenOption

      const paymentPlan: ChosenPaymentPlan = {
        number_of_installments: installments,
        down_payment_amount: 0,
        payment_method: SimulationPaymentMethod.CREDIT_CARD,
        due_date: dayjs(dueDate).add(1, 'day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      }

      const receivablesByAgreementSimulation = getReceivablesByAgreementSimulation(agreements)

      const body: MakeAgreementBody[] = Array.from(
        receivablesByAgreementSimulation,
        ([agreementSimulationId, receivable]) => ({
          channel: 'PI-ESCOLA',
          agreement_amount: receivable.totalAmount,
          agreement_simulation_id: agreementSimulationId,
          guardian_id: guardianId,
          payment_plan: paymentPlan,
          receivable_ids: receivable.ids,
          school_id: school?.id,
        })
      )

      makeAgreementMutation.mutate(body, {
        onSuccess: data => {
          const negotiationData = data.map(negotiation => {
            return {
              shortId: negotiation.id.substring(0, 6).toUpperCase(),
              urn: `urn:down-payment:${negotiation.id}`,
            } as NegotiationData
          })

          handleCheckout(chosenOption, negotiationData)
        },
        onError: () => {
          setShowFailModal(true)
        },
      })
      return
    }

    handleCheckout(chosenOption)
  }

  const handleAgreement = (chosenOption: PaymentOptionsValue) => {
    const { dueDate, installments, minDownPayment, paymentOption } = chosenOption

    const paymentPlan: ChosenPaymentPlan = {
      number_of_installments:
        paymentOption === SimulationPaymentMethod.BANK_SLIP ? installments + 1 : installments,
      down_payment_amount:
        paymentOption === SimulationPaymentMethod.CREDIT_CARD ? 0 : minDownPayment,
      payment_method:
        paymentOption === SimulationPaymentMethod.CREDIT_CARD
          ? SimulationPaymentMethod.CREDIT_CARD
          : SimulationPaymentMethod.BANK_SLIP,
      due_date: dayjs(dueDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    }

    const body: MakeAgreementBody = {
      channel: 'PI-ESCOLA',
      agreement_amount: getCurrentTotalAmount(),
      agreement_simulation_id: selectedAgreement?.agreement_simulation_id,
      guardian_id: guardianId,
      payment_plan: paymentPlan,
      receivable_ids: selectedAgreement?.receivable_ids,
      school_id: school?.id,
    }

    makeAgreementMutation.mutate([body], {
      onSuccess: response => {
        const data = response[0]
        const invoices = data.invoices.map(invoice => {
          if (paymentPlan.payment_method === SimulationPaymentMethod.CREDIT_CARD) {
            return { ...invoice, instalment_credit_card: paymentPlan.number_of_installments }
          }
          return invoice
        })
        invoices.length > 0 ? goToNextStep(invoices, data.id) : setShowFailModal(true)
      },
      onError: () => {
        setShowFailModal(true)
      },
    })
  }

  const handleNextStep = () => {
    if (!chosenOption) return
    if (selectedAgreement?.type === 'down_payment') return handleDownPaymentCheckout(chosenOption)

    const { creditCardPaymentOption, paymentOption } = chosenOption
    if (isPaymentByPos(selectedFormOfPayment, paymentOption, creditCardPaymentOption)) {
      handleInitCheckout(chosenOption)
      return
    }
    handleAgreement(chosenOption)
  }

  const isDisableOtherPaymentOptions = useUnleashFlag(
    UnleashFlags.REG_337_DISABLE_OTHER_PAYMENT_OPTIONS
  )

  const disableGoBackButton = isDisableOtherPaymentOptions
    ? checkoutValues.waitingApi || makeAgreementMutation?.isLoading
    : hasPaidAgreement || checkoutValues.waitingApi || makeAgreementMutation?.isLoading

  const handleGoBack = () => {
    if (
      isDisableOtherPaymentOptions &&
      agreementSimulations.length > 1 &&
      positionAgreement > 0 &&
      positionAgreement < agreementSimulations.length - 1
    ) {
      setGoBackDialogVisible(true)
      return
    }

    goBack()
  }

  const handleChosenOption = (value: PaymentOptionsValue) => {
    setChosenOption(value)
  }

  const handleValidate = (value: boolean) => {
    if (!value) setChosenOption(undefined)
    setIsValid(value)
  }

  const handleOnForward = () => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.INSTALLMENT_NEGOTIATIONS,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        pageName: EventPageName.NEGOTIATION,
        identifierName: EventIdentifierName.FORWARD_PAYMENT_STEP,
        customProperties: {
          $agreement_id: selectedAgreement?.agreement_simulation_id,
          $installment_id: selectedAgreement?.receivables[0]?.id,
          $receivable_type: selectedAgreement?.receivables[0]?.receivable_type,
          $type: selectedAgreement?.type,
          $button_name: 'Pular pagamento',
        },
      })
    }

    const agreementStatus: AgreementStatus = {
      finished: true,
      invoices: [] as AgreementInvoice[],
    }
    handlerAgreementStatus(positionAgreement, agreementStatus)
    setPositionAgreement(positionAgreement + 1)
    onForward?.()
  }

  const showForwardButton =
    !chosenOption &&
    isDisableOtherPaymentOptions &&
    selectedFormOfPayment === FormOfPayment.OTHER &&
    agreementSimulations.length > 1 &&
    positionAgreement < agreementSimulations.length - 1 &&
    (selectedAgreement?.type === 'down_payment' ||
      selectedAgreement?.receivables?.every(
        receivable => receivable.receivable_type === 'ENROLLMENT'
      ))

  useEffect(() => {
    if (checkoutValues.checkoutObject || checkoutValues.showErrorDialog)
      setStatusDialogData({
        ...statusDialogData,
        isOpen: false,
      })
  }, [checkoutValues.checkoutObject, checkoutValues.showErrorDialog])

  useEffect(() => {
    setCheckoutData(null)
  }, [])

  return (
    <Styled.Container>
      {selectedFormOfPayment !== FormOfPayment.POS_V2 ? (
        <OtherMethod
          handleValidate={handleValidate}
          handleValue={handleChosenOption}
          agreement={selectedAgreement}
        />
      ) : (
        <PosMethod
          handleValidate={handleValidate}
          totalAmount={totalAmount}
          handleValue={handleChosenOption}
        />
      )}
      <StatusCheckout
        closeErrorDialog={checkoutValues.closeCheckoutErrorDialog}
        {...checkoutValues}
        pos={chosenOption?.pos}
        shortIds={statusDialogData.shortIds}
      />
      <StatusDialog
        guardianName={checkoutValues.guardianName}
        isOpen={statusDialogData.isOpen}
        isTextVisible={statusDialogData.isTextVisible}
        posName={chosenOption?.pos?.pdv_name ?? ''}
        totalAmount={getCurrentTotalAmount()}
        title={statusDialogData.title}
        shortIds={statusDialogData.shortIds}
        isOverdue={statusDialogData.isOverdue}
      />
      {showFailModal && (
        <FailureFeedbackDialog
          isVisible
          buttonLabel="Fechar"
          onClose={() => {
            setShowFailModal(false)
          }}
          submitHandler={() => {
            setShowFailModal(false)
          }}
        >
          <FailureFeedbackContent />
        </FailureFeedbackDialog>
      )}

      <StepFooter
        showHelpButton
        stepName={id}
        goBack={handleGoBack}
        disableBack={disableGoBackButton}
        btnPrimary={{
          onClick: showForwardButton ? handleOnForward : handleNextStep,
          loading:
            checkoutValues.waitingApi ||
            makeAgreementMutation?.isLoading ||
            statusDialogData.isOpen,
          label: showForwardButton ? 'Pular' : 'Próximo',
          variant: showForwardButton ? 'outline' : 'solid',
          disabled: !showForwardButton && !isValid,
          hideIcon: showForwardButton,
        }}
      />

      <CancelNegotiationDialog
        closeDialog={() => setGoBackDialogVisible(false)}
        visible={goBackDialogVisible}
        agreementId={selectedAgreement?.agreement_simulation_id}
      />
    </Styled.Container>
  )
}

export default PaymentOptionStep
