import { CircularLoading } from '@/shared/components/CircularLoading'
import { Text } from '@gravity/text'

import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.gravity.spacing[3]};
`

export const Separator = styled.div`
  border: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  height: 1px;
  margin-top: ${({ theme }) => theme.gravity.spacing[3]};
  margin-bottom: ${({ theme }) => theme.gravity.spacing[6]};
`

export const FullAmount = styled(Text)`
  text-decoration: line-through;
  font-size: 20px;
  font-weight: normal;
  margin-right: ${({ theme }) => theme.gravity.spacing[2]};
`
export const StyledLoading = styled(CircularLoading)`
  width: 40px !important;
  height: 40px !important;
`
export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
