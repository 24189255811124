import { GridItem } from '@gravity/grid'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'
import { Controller } from 'react-hook-form'
import { ContractSelectYearProps } from './types'
import { Select } from '@gravity/select'
import { useAcademicPeriod } from '@monorepo/enrollment/pages/SchoolEnrollmentCycle/hooks/useAcademicPeriod'

const ContractSelectYear = ({ setReferenceYear, form }: ContractSelectYearProps) => {
  const { enabledAcademicPeriods } = useAcademicPeriod()
  const { control, clearErrors } = form

  const validateAcademicPeriod = (academicPeriodId: string) => {
    if (!academicPeriodId) {
      return 'Selecione um ciclo letivo'
    }

    return true
  }

  return (
    <div className="mb-6">
      <div className="mb-4">
        <Heading variant="heading-h4-medium" className="mb-2">
          O contrato será referente a qual ciclo letivo?
        </Heading>
        <Text variant="subtitle-regular">Escolha o ciclo a que este contrato pertencerá.</Text>
      </div>
      <GridItem xs={4}>
        <Controller
          rules={{
            validate: value => validateAcademicPeriod(value ?? ''),
          }}
          control={control}
          name="academic_period_id"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Select
              size={3}
              name="academic_period_id"
              options={enabledAcademicPeriods.map(academicPeriod => ({
                label: academicPeriod.view,
                value: academicPeriod.id,
              }))}
              placeholder="Escolha o ciclo"
              onValueChange={value => {
                setReferenceYear(String(enabledAcademicPeriods.find(ap => ap.id === value)?.year))
                clearErrors('academic_period_id')
                onChange(value)
              }}
              value={value ? String(value) : ''}
              hasError={Boolean(error?.type)}
              enableHelperTextSlot={Boolean(error?.type)}
              helperText={error?.message}
            />
          )}
        />
      </GridItem>
    </div>
  )
}

export default ContractSelectYear
