import { Table } from '@gravity/table'
import { ComponentProps } from 'react'

export const TableLoading = ({
  columns,
}: {
  columns: Array<ComponentProps<typeof Table.HeaderCell>>
}) => (
  <>
    <Table.Row>
      {columns.map(column => (
        <Table.LoadingCell key={column.name} />
      ))}
    </Table.Row>
    <Table.Row>
      {columns.map(column => (
        <Table.LoadingCell key={column.name} />
      ))}
    </Table.Row>
    <Table.Row>
      {columns.map(column => (
        <Table.LoadingCell key={column.name} />
      ))}
    </Table.Row>
  </>
)
