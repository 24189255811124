import { Text } from '@gravity/text'

import type { InstallmentCardProps } from '@/modules/guardians/Negotiation/types'

import * as S from './styles'

const InstallmentCard = ({
  productName,
  installmentsLabel,
  installmentsPrice,
  total,
  finesAndInterest,
  discount,
  hideDiscount,
}: InstallmentCardProps) => {
  const discountLabel = (function () {
    return 'Descontos'
  })()

  return (
    <S.Wrapper>
      <S.MenuBookIcon />

      <S.Content>
        <Text variant="subtitle-medium" className="mb-6">
          {productName}
        </Text>
        <S.PriceRow>
          <S.Label>{installmentsLabel}</S.Label>
          <Text variant="body-2-regular" className="text-colors-text-main-3">
            {installmentsPrice}
          </Text>
        </S.PriceRow>

        <S.PriceRow>
          <S.Label>Encargos</S.Label>
          <Text variant="body-2-regular" className="text-colors-text-main-3">
            {finesAndInterest}
          </Text>
        </S.PriceRow>

        {!hideDiscount && (
          <S.PriceRow>
            <S.Label>{discountLabel}</S.Label>
            <Text variant="body-2-regular" className="text-colors-text-main-3">
              {discount}
            </Text>
          </S.PriceRow>
        )}

        <S.PriceRow>
          <span />
          <Text variant="body-2-regular" className="font-medium">
            {total}
          </Text>
        </S.PriceRow>
      </S.Content>
    </S.Wrapper>
  )
}

export default InstallmentCard
