import { useLocation } from 'react-router-dom'
import { GenericCashoutError, SimulationCashoutError } from '@/shared/components/CashoutErrors'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

const SIMULATION_CREDIT_PATH = '/credito/simulacao'

export const ErrorFallback = () => {
  const location = useLocation()

  if (location.pathname.includes(SIMULATION_CREDIT_PATH)) {
    const { schoolSlug } = useSelectedSchool()
    const handleRetryButton = () => {
      window.location.href = `/${schoolSlug}/credito`
    }

    return <SimulationCashoutError handleRetryButton={handleRetryButton} />
  }

  return <GenericCashoutError />
}
