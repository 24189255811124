import styled from 'styled-components'
import { Card } from '@gravity/card'

export const ContainerCard = styled(Card)`
  padding: ${props => props.theme.gravity.spacing[6]};
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[4]};
  width: 100%;
  border-radius: ${props => props.theme.gravity.spacing[2]};
  border: none;
  background-color: ${props => props.theme.gravity.colors['colors-background-neutral-2']};
  align-items: flex-start;
`
