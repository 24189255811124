import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;

  padding: ${props => props.theme.gravity.spacing[8]} 0;

  .table {
    padding: ${props => props.theme.gravity.spacing[4]} 0;
  }

  .summary {
    padding: ${props => props.theme.gravity.spacing[12]} 0;
  }

  @media (max-width: 768px) {
    .summary {
      padding: ${props => props.theme.gravity.spacing[4]} 0;
    }
  }
`
