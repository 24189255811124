import { APIResponse, Receivable } from '@/shared/interfaces'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useQuery as useQueryParams } from '@/shared/hooks/useQuery'
import { normalizeText } from '@/shared/utils'
import { useApiClient } from '@/shared/hooks'
import { reportService } from '@monorepo/report/services/report'

export type PaginationRequest = Partial<{
  page: number
  per_page: number
}>

type ExternalPaymentQueryProps = {
  endDate: string
  options?: UseQueryOptions<APIResponse<Receivable[]>>
  pagination?: PaginationRequest
  schoolId: string
  startDate: string
}

export const useIsaacPayExternalPayoutReports = ({
  schoolId,
  endDate,
  pagination,
  startDate,
  options,
}: ExternalPaymentQueryProps) => {
  const { apiClient } = useApiClient()
  const { query } = useQueryParams()

  const nameFilter = query.get('name') ?? ''
  const filter = {
    student_name: normalizeText(nameFilter),
  }

  const service = reportService(apiClient.getClients().privateApi)

  return useQuery<APIResponse<Receivable[]>>(
    ['external-payments', schoolId, startDate, endDate, filter, pagination, 'isaac-pay'],
    () => {
      return service.fetchExternalPaymentsReportList({
        schoolId,
        start_date: startDate,
        end_date: endDate,
        filter,
        pagination,
      })
    },
    options
  )
}
