import StatusBadge, { StatusBadgeColor } from '@/shared/components/StatusBadge'
import { InstallmentStatuses, InstallmentType } from '@/shared/interfaces'
import { formatCentsToReal, formatDate } from '@/shared/utils'
import { Tooltip } from '@gravity/tooltip'
import { Table } from '@gravity/table'
import { IconButton } from '@gravity/icon-button'
import { InformationOutline } from '@gravity/icons'
import { Callout } from '@gravity/callout'
import { Text } from '@gravity/text'
import { useEffect, useRef } from 'react'
import { calculateTotalDiscount, canSelectRow, splitOrderReference } from '../../utils'
import { DiscountChips } from '../DiscountChips/index'
import { Installment, NoEditReason } from '@/modules/contract/services/types'
import { InstallmentsTableProps, Row, TableColumn } from './types'
import { UndoCancelButton } from './UndoCancelButton'
import { useEvents } from '../../hooks/eventContext'
import { InstallmentTableLoading } from './InstallmentTableLoading'
import { Grid, GridItem } from '@gravity/grid'

const mapDataToRows = (
  rawData: Installment[],
  selectedIds: uuid[],
  enableCancelFF: boolean
): Row[] => {
  return rawData.map(item => ({
    ...item,
    month_reference: formatDate(item.due_date, 'MMM. YYYY'),
    order_reference: splitOrderReference(item.order_reference),
    net_amount: item.amount - calculateTotalDiscount(item.discounts),
    disabled: !canSelectRow(item, { enableCancelFF }),
    selected: selectedIds.includes(item.backoffice_installment_id),
    competence: formatDate(item.competence_date[0], 'MMM. YYYY'),
  }))
}

const paramsDict: PartialRecord<
  InstallmentStatuses,
  { color: StatusBadgeColor; detail: string | null; text: string }
> = {
  CANCELED: { color: 'grey', text: 'Cancelada', detail: null },
  OPEN: { color: 'primary', text: 'A vencer', detail: null },
  PENDING: {
    color: 'darkGrey',
    text: 'Aguardando',
    detail:
      'A parcela será gerada após o pagamento da pré-matrícula e/ou quitação dos contratos de ',
  },
  AGGLUTINATED: { color: 'grey', text: 'Aglutinada', detail: null },
  DUE_TODAY: { color: 'primary', text: 'A vencer', detail: null },
  OVERDUE: { color: 'error', text: 'Vencida', detail: null },
  PAID: { color: 'success', text: 'Paga', detail: null },
  PAID_AND_CANCELED: { color: 'grey', text: 'Paga e cancelada', detail: null },
  RENEGOTIATED: { color: 'warning', text: 'Negociada', detail: null },
}

const installmentTypeText: PartialRecord<InstallmentType, string> = {
  [InstallmentType.ENROLLMENT]: 'Pré-matrícula',
  [InstallmentType.TUITION]: 'Mensalidade',
}

const headerNegotiatedNotPresentText =
  'Parcelas negociadas não são editáveis e por isso não se encontram listadas abaixo.'

const rowDisabledHelperText =
  'Não é possível editar parcelas de pré-matricula em situação a vencer ou vencida a partir desta página de edições.'

const noEditReasonText: Record<NoEditReason, string> = {
  [NoEditReason.CANCELED]: 'Não é possível editar parcelas canceladas.',
  [NoEditReason.HAS_PAID_PAYOUT]: 'Não é possível editar parcela do mês atual.',
  [NoEditReason.NEGOTIATION]: 'Não é possível editar parcelas renegociadas.',
  [NoEditReason.OVERDUE]: 'Não é possível editar parcelas vencidas.',
  [NoEditReason.PAID]: 'Não é possível editar parcelas pagas.',
  [NoEditReason.OTHER]: 'Não é possível editar esta parcela.',
  [NoEditReason.ENROLLMENT]: rowDisabledHelperText,
}

export const renderStatus = (
  status: InstallmentStatuses,
  referenceYear: any,
  overdueForDays: number,
  paidDate: string
) => {
  const statusMessages: Record<string, string> = {
    [InstallmentStatuses.OVERDUE]: `Há ${overdueForDays} dias`,
    [InstallmentStatuses.PAID]: `Recebido em ${formatDate(paidDate, 'DD/MM/YYYY')}`,
    [InstallmentStatuses.PAID_AND_CANCELED]: `Recebido em ${formatDate(paidDate, 'DD/MM/YYYY')}`,
  }

  const statusDetail = paramsDict[status]?.detail

  return (
    <div className="flex flex-col w-max min-w-[10.25rem]">
      <div className="flex items-center gap-1 py-1">
        <StatusBadge color={paramsDict[status]?.color as StatusBadgeColor} />
        <Text variant="body-2-regular" className="px-2">
          {paramsDict[status]?.text}
        </Text>
        {statusDetail != null && (
          <Tooltip
            text={statusDetail + (referenceYear - 1)}
            position="bottom"
            style={{ maxWidth: '18rem' }}
          >
            <IconButton variant="ghost" size={1} style={{ cursor: 'help' }}>
              <InformationOutline color="gray" />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className="flex pl-5">
        <Text variant="caption-regular" className="text-colors-text-main-3">
          {statusMessages[status]}
        </Text>
      </div>
    </div>
  )
}

export const InstallmentsTable = ({
  data,
  contract,
  isLoading,
  onDeleteDiscount,
  selectedInstallmentIds,
  setSelectedInstallmentIds,
  installmentIDsToCancel,
  setInstallmentIDsToCancel,
  enableCancelFF = true,
}: InstallmentsTableProps) => {
  const events = useEvents()
  const tableRef = useRef<HTMLTableElement>(null)

  useEffect(() => {
    if (!tableRef.current) return

    const isTableTopBellowScreenTop = tableRef.current?.getBoundingClientRect().top > 0

    if (selectedInstallmentIds.length > 0 && isTableTopBellowScreenTop) {
      tableRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [selectedInstallmentIds])

  const columns: TableColumn[] = [
    {
      headerName: 'Competência',
      field: 'competence',
      content: row => row.competence,
    },
    {
      headerName: 'Parcela',
      field: 'order_reference',
      content: row => (
        <div className="flex flex-col gap-1">
          <Text variant="body-2-regular">
            {installmentTypeText[row.receivable_type] || 'Parcela'}
          </Text>
          <Text variant="body-2-regular" className="text-colors-text-main-3">
            {row.order_reference}
          </Text>
        </div>
      ),
    },
    {
      headerName: 'Vencimento',
      field: 'due_date',
      content: row => formatDate(row.due_date, 'DD/MM/YYYY'),
    },
    { headerName: 'Valor', field: 'amount', content: row => formatCentsToReal(row.amount) },
    {
      headerName: 'Desconto',
      field: 'discounts',
      content: row => <DiscountChips installment={row} onDelete={onDeleteDiscount} />,
    },
    {
      headerName: 'Valor com o desconto',
      field: 'net_amount',
      content: row => formatCentsToReal(row.net_amount),
    },
    {
      headerName: 'Situação',
      field: 'status',
      content: row =>
        installmentIDsToCancel.includes(row.backoffice_installment_id) ? (
          <UndoCancelButton
            onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.stopPropagation()
              setInstallmentIDsToCancel(
                installmentIDsToCancel.filter(i => i !== row.backoffice_installment_id)
              )
            }}
          />
        ) : (
          renderStatus(row.status, contract?.reference_year, row.overdue_for_days, row.paid_date)
        ),
    },
  ]
  const rows = mapDataToRows(data, selectedInstallmentIds, enableCancelFF)

  const handleSelectRow = (row: Row) => {
    if (row.disabled) {
      return
    }

    if (row.selected) {
      setSelectedInstallmentIds(
        selectedInstallmentIds.filter(i => i !== row.backoffice_installment_id)
      )
    } else {
      setSelectedInstallmentIds([...selectedInstallmentIds, row.backoffice_installment_id])
    }

    events?.clickRow(row)
  }

  const handleSelectAll = (checked: boolean | 'indeterminate') => {
    if (checked === 'indeterminate') return

    if (checked) {
      setSelectedInstallmentIds(rows.filter(i => !i.disabled).map(i => i.backoffice_installment_id))
      return
    }
    setSelectedInstallmentIds([])
  }

  const defineNoEditReasonText = (row: Row): string => {
    if (row.disabled)
      return (
        noEditReasonText[row.no_edit_reason as NoEditReason] || noEditReasonText[NoEditReason.OTHER]
      )
    return ''
  }

  const numSelected = selectedInstallmentIds.length
  const rowCount = rows.filter(i => !i.disabled).length

  const checkBoxSelection = () => {
    if (numSelected === 0) return false
    if (rowCount > 0 && numSelected === rowCount) return true

    return 'indeterminate'
  }

  return (
    <Grid
      className="rounded-t-2 border border-b-0 border-solid mt-6 border-colors-border-neutral-3"
      style={{
        marginLeft: 'unset',
      }}
    >
      <GridItem xl={12} lg={12} md={12} sm={8} xs={4}>
        <div className="p-4 pt-0 mt-6">
          <Text variant="button-1">Configurar parcelas</Text>
          <div className="mb-2 mt-4">
            <Callout text={headerNegotiatedNotPresentText} />
          </div>
          <Text variant="body-2-regular">
            Selecione as parcelas para aplicar descontos, alterar valores ou data de vencimento.
          </Text>
          <div className="mt-4 p-2 overflow-x-auto">
            <Table.Root ref={tableRef}>
              <Table.Head>
                <Table.CustomCell
                  enableCheckbox
                  checked={checkBoxSelection()}
                  onChange={checked => handleSelectAll(checked)}
                />
                {columns.map(column => (
                  <Table.CustomCell key={column.field}>
                    <Text variant="body-2-medium">{column.headerName}</Text>
                  </Table.CustomCell>
                ))}
              </Table.Head>
              <Table.Body>
                {isLoading && <InstallmentTableLoading />}

                {!isLoading &&
                  rows.map(row => (
                    <Table.Row
                      key={row.backoffice_installment_id}
                      className="data-[highlight=true]:hover:z-1"
                    >
                      <Table.CustomCell
                        enableCheckbox
                        checked={row.selected}
                        disabled={row.disabled}
                        onChange={() => handleSelectRow(row)}
                        title={defineNoEditReasonText(row)}
                      />

                      {columns.map(column => (
                        <Table.CustomCell key={column.field}>
                          {column.content(row)}
                        </Table.CustomCell>
                      ))}
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table.Root>
          </div>
        </div>
      </GridItem>
    </Grid>
  )
}
