import IsaacLogo from '@/escolas/components/IsaacLogo'
import { UnleashFlags, useUnleashVariant } from '@/shared/hooks'
import { Text } from '@gravity/text'
import Graphism from '@/shared/assets/graphism.svg'

export const Maintenance = () => {
  const variant = useUnleashVariant(UnleashFlags.ENABLE_MAINTENANCE_PAGE)
  const message = variant?.payload?.value

  return (
    <main className="bg-colors-background-neutral-1 h-screen relative overflow-hidden">
      <div className="pl-6 pr-6 max-w-620">
        <div className="pt-7 text-center relative z-10">
          <IsaacLogo />

          <div className="pt-7">
            <Text variant="body-2-medium">Estamos em manutenção e retornaremos em breve!</Text>
            {message && (
              <Text variant="body-2-regular" className="text-colors-text-main-3">
                {message}
              </Text>
            )}
          </div>
        </div>

        <div className="absolute bottom-0 right-0 media-max-width-600:bottom-[-150px]">
          <img src={Graphism} />
        </div>
      </div>
    </main>
  )
}
