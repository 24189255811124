import { Button } from '@gravity/button'
import { DatepickerPrimitives as Datepicker } from '@gravity/datepicker'
import styled from 'styled-components'

export const Form = styled.form`
  padding-top: ${props => props.theme.gravity.spacing[6]};
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const TextFieldWrapper = styled.div`
  input {
    width: 100%;
  }
`

export const SelectWrapper = styled.div`
  button {
    width: 100%;
  }
`

export const DatepickerTrigger = styled(Datepicker.Trigger)`
  width: 100%;
`

export const OfferButton = styled(Button)`
  width: 100%;
`

export const AddOfferBtnWrapper = styled.div`
  flex: 0 0 50%;
`
