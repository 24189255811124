import styled from 'styled-components'

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 32px 48px 32px 32px;
`

export const Item = styled.div`
  margin-left: 12px;
`
