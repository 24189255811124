/* eslint-disable react/jsx-handler-names */
import { useJWT } from '@/shared/hooks'
import { Button } from '@gravity/button'
import { Tooltip } from '@gravity/tooltip'
import { PayoutReportStatus } from '@monorepo/report/models/PayoutReportStatus'

export type ReportPageTableHeaderProps = {
  isEmptyPayout: boolean
  isLoading: boolean
  isNewVersionPayout?: boolean
  onPreviousModelDownload?: () => void
  onSheetDownload: () => void
  tuitionPayoutReportStatus?: PayoutReportStatus
}

export const ReportPageTableHeader = ({
  isLoading,
  isNewVersionPayout = true,
  isEmptyPayout,
  onPreviousModelDownload,
  onSheetDownload,
  tuitionPayoutReportStatus = 'OPEN',
}: ReportPageTableHeaderProps) => {
  const { isBackofficeUser } = useJWT()
  const showDownloadModelButton =
    isBackofficeUser && isNewVersionPayout && !!onPreviousModelDownload
  const isOpenPayout = tuitionPayoutReportStatus === 'OPEN'
  const DISABLED_BUTTON_TOOLTIP = 'Disponível apenas para repasses fechados.'
  const DOWNLOAD_SPREADSHEET_TOOLTIP = 'Baixar o arquivo .xlsx do período'

  const shouldDisableDownloadButton = () => {
    if (!isNewVersionPayout) {
      return isOpenPayout || isLoading
    }

    return isOpenPayout || isEmptyPayout || isLoading
  }

  return (
    <div className="w-full flex flex-row-reverse gap-4">
      <Tooltip text={isOpenPayout ? DISABLED_BUTTON_TOOLTIP : DOWNLOAD_SPREADSHEET_TOOLTIP}>
        <span>
          <Button
            data-testid="sheet-download-button"
            variant="outline"
            onClick={onSheetDownload}
            disabled={shouldDisableDownloadButton()}
          >
            Baixar planilha
          </Button>
        </span>
      </Tooltip>

      {showDownloadModelButton && (
        <Tooltip text={isOpenPayout ? DISABLED_BUTTON_TOOLTIP : DOWNLOAD_SPREADSHEET_TOOLTIP}>
          <span>
            <Button
              data-testid="previous-model-download-button"
              variant="outline"
              onClick={onPreviousModelDownload}
              disabled={shouldDisableDownloadButton()}
            >
              Baixar modelo anterior
            </Button>
          </span>
        </Tooltip>
      )}
    </div>
  )
}
