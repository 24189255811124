import { useHistory } from 'react-router-dom'
import { Button } from '@gravity/button'
import { DashedArrowLeft, QuestionOutline } from '@gravity/icons'

import { TopHeaderProps } from './types'
import { Container } from './styles'

/**
 * TopHeader component with Back and Help buttons
 *
 * @param props
 * @param props.backButtonLink - Path to be redirected on click at back button. If not provided, will go back to the previous page in history.
 * @param props.backButtonText - Back button text, defaults to "Voltar"
 * @param props.helpButtonText - Help button text, defaults to "Ajuda"
 * @param props.onHelpClick - Callback function called on help button click
 * @param props.className - Class name for the component
 * @example
 * ```tsx
 * <TopHeader
 *   backButtonLink="/dashboard"
 *   onHelpClick={() => {
 *     window.open('https://centraldeajuda.olaisaac.io/credito-isaac', '_blank')
 *   }}
 * />
 * ```
 *
 * // Ou sem especificar o backButtonLink para voltar automaticamente
 * ```tsx
 * <TopHeader />
 * ```
 */
export const TopHeader = ({
  backButtonLink,
  backButtonText = 'Voltar',
  helpButtonText = 'Ajuda',
  onHelpClick = () => window.open('https://centraldeajuda.olaisaac.io', '_blank'),
  showHelpButton = true,
  className,
}: TopHeaderProps) => {
  const { replace, goBack } = useHistory()

  return (
    <Container className={className}>
      <Button
        variant="ghost"
        iconStart={<DashedArrowLeft />}
        size={1}
        onClick={() => (backButtonLink ? replace(backButtonLink) : goBack())}
      >
        {backButtonText}
      </Button>

      {showHelpButton && (
        <Button variant="ghost" size={1} iconStart={<QuestionOutline />} onClick={onHelpClick}>
          {helpButtonText}
        </Button>
      )}
    </Container>
  )
}

export * from './types'
