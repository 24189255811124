import { useUnleashFlag, UnleashFlags } from '@/shared/hooks/useUnleashFlag'
import { useHasPendingIrregularitiesQuery } from '@monorepo/school-home/hooks/queries/enrollment-request/useHasPendingIrregularitiesQuery'
import { useGetSchoolEnrollmentCycles } from '@/shared/hooks/queries/useGetSchoolEnrollmentCycles'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { isIsaacPaySchool } from '@/shared/utils/isIsaacPaySchool'
import { useJWT } from '@/shared/hooks/useJWT'

import IrregularitiesBanner from '@monorepo/school-home/assets/irregularities-banner-illustration.png'
import EnrollmentCampaignIllustration from '@monorepo/school-home/assets/EnrollmentCampaignIllustration.svg'
import ReEnrollmentCampaignIllustration from '@monorepo/school-home/assets/ReEnrollmentCampaignIllustration.svg'
import IsaacScoreBannerIllustration from '@monorepo/school-home/assets/isaacScoreBannerIllustration.png'
import campanhaCredito from '@monorepo/school-home/assets/campanhaCredito.png'
import studentEdition from '@monorepo/school-home/assets/studentEditionBanner.png'
import BannerNps2025Image from '@monorepo/school-home/assets/BannerNps2025.svg'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

import type { BannerInfo, UseBannerInfosProps, UseBannerInfosReturn } from './types'

const getCreditCampaign = (): BannerInfo => {
  const title = 'Crédito fácil, rápido e feito para as escolas'
  return {
    title: title,
    titleSize: 'large',
    explanation:
      'Confira a disponibilidade dos produtos financeiros isaac para te ajudar nesse momento desafiador do ano.',
    actionLabel: 'Consultar disponibilidade',
    actionPath: `/credito`,
    imageSrc: campanhaCredito,
    backgroundColor: '#131B5C',
    color: '#FFFFFF',
    event: {
      scope: EventDispatcherEventScopes.HOME,
      name: EventDispatcherEvents.BUTTON_CLICK,
      props: {
        $origin: 'Banner',
        $button: title,
      },
    },
  }
}

const getReEnrollmentCampaignBanner = (): BannerInfo => {
  const title = 'Campanhas de rematrícula para 2025'
  return {
    title: title,
    explanation:
      'Descubra a facilidade de criar campanhas na Plataforma isaac! Antecipe o início das rematrículas e simplifique o processo para sua escola e responsáveis.',
    actionLabel: 'Acessar agora',
    actionPath: `/matriculas`,
    imageSrc: EnrollmentCampaignIllustration,
    backgroundColor: '#131B5C',
    color: '#FFFFFF',
    event: {
      scope: EventDispatcherEventScopes.HOME,
      name: EventDispatcherEvents.BUTTON_CLICK,
      props: {
        $origin: 'Banner',
        $button: title,
      },
    },
  }
}

const getEnrollmentCampaignBanner = (): BannerInfo => {
  const title = 'Matrículas 2025'
  return {
    title: title,
    explanation:
      'O novo ano letivo já está disponível para sua escola! Você já pode matricular alunos para 2025.',
    actionLabel: 'Acessar agora',
    actionPath: `/matriculas`,
    imageSrc: ReEnrollmentCampaignIllustration,
    backgroundColor: '#F7F8FD',
    event: {
      scope: EventDispatcherEventScopes.HOME,
      name: EventDispatcherEvents.BUTTON_CLICK,
      props: {
        $origin: 'Banner',
        $button: title,
      },
    },
  }
}

const getIrregularitiesBanner = (): BannerInfo => {
  const title = 'Evite bloqueios no envio de boletos: corrija o registro dos alunos'
  return {
    title: title,
    explanation:
      'Pendências de registro impedem que os alunos sejam registrados e os valores sejam repassados.',
    actionLabel: 'Liberar alunos pendentes',
    actionPath: `/irregularidades`,
    imageSrc: IrregularitiesBanner,
    backgroundColor: '#FFEAEE',
    event: {
      scope: EventDispatcherEventScopes.HOME,
      name: EventDispatcherEvents.BUTTON_CLICK,
      props: {
        $origin: 'Banner',
        $button: title,
      },
    },
  }
}

const getIsaacScoreBanner = (): BannerInfo => {
  const title = 'Evite riscos na matrícula, consulte o isaac Score'
  return {
    title: title,
    explanation:
      'Agora sua escola pode consultar a pontuação de risco dos responsáveis financeiros para tomar decisões evitando a inadimplência escolar.',
    actionLabel: 'Acessar isaac Score',
    actionPath: `/matriculas/score`,
    imageSrc: IsaacScoreBannerIllustration,
    backgroundColor: '#ECEDFB',
    headingColor: '#3D4ED7',
    titleSize: 'large',
    event: {
      scope: EventDispatcherEventScopes.HOME,
      name: EventDispatcherEvents.BUTTON_CLICK,
      props: {
        $origin: 'Banner',
        $button: title,
      },
    },
  }
}

const getStudentEditionBanner = (): BannerInfo => {
  const title = 'Edite dados cadastrais e cancele contratos em poucos cliques'
  return {
    title: title,
    explanation:
      'Rápido, fácil e sem complicação. Com esses novos recursos, sua rotina na Plataforma isaac ficou ainda mais prática e eficiente!',
    actionLabel: 'Acessar Matrículas 2025',
    actionPath: `/matriculas`,
    imageSrc: studentEdition,
    backgroundColor: '#ECEDFB',
    titleSize: 'large',
    event: {
      scope: EventDispatcherEventScopes.HOME,
      name: EventDispatcherEvents.BUTTON_CLICK,
      props: {
        $origin: 'Banner',
        $button: title,
      },
    },
  }
}

const getBannerNps2025 = (): BannerInfo => {
  const title = 'Responda a Pesquisa de Satisfação isaac'
  const explanation =
    'Verifique as mensagens do isaac no e-mail ou WhatsApp para obter o link do formulário.'
  return {
    title: title,
    explanation: explanation,
    actionLabel: 'Saiba mais',
    actionPath: `https://centraldeajuda.olaisaac.io/seguro-familiar-isaac/pesquisa-de-satisfa%C3%A7%C3%A3o-isaac`, // path local ou externo ao clicar no botão no banner;
    actionExternalLink: true,
    imageSrc: BannerNps2025Image,
    backgroundColor: '#010038',
    color: '#FFFFFF',
    event: {
      scope: EventDispatcherEventScopes.HOME,
      name: EventDispatcherEvents.BUTTON_CLICK,
      props: {
        $origin: 'Banner',
        $button: title,
      },
    },
  }
}

export const useBannerInfos = ({
  referenceYear,
  schoolId,
}: UseBannerInfosProps): UseBannerInfosReturn => {
  const infos: BannerInfo[] = []

  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)
  const canSeeCreditCampaing = useUnleashFlag(UnleashFlags.CRD_256_ENABLE_CAMPAIGN_CREDIT)
  const isIsaacScoreEnabled = useUnleashFlag(UnleashFlags.MAT_616_ENABLE_ISAAC_SCORE)
  const canEditStudent = useUnleashFlag(UnleashFlags.EFI_248_STUDENT_EDITING)

  const { hasPayoutReportAccess } = useJWT()

  const { school, retryFetchSchool, hasErrorOnFetchSchool, isLoadingSchool } = useSelectedSchool()
  const isIsaacPay = isIsaacPaySchool(school)
  const {
    data: response,
    refetch: refetchEnrollmentCycles,
    isError: isEnrollmentCyclesError,
    isFetching: isEnrollmentCyclesFetching,
  } = useGetSchoolEnrollmentCycles(school?.id || '', {
    enabled: !!school?.id,
  })

  const schoolEnrollmentCycles = response?.data
  const enrollmentRefrenceYear = 2025
  const isEnrollmentCampaignEnabled =
    schoolEnrollmentCycles?.find(cycle => cycle.reference_year === enrollmentRefrenceYear)
      ?.enabled ?? false

  const hasPendingIrregularitiesQuery = useHasPendingIrregularitiesQuery({
    referenceYear,
    schoolId,
  })

  const isPendingIrregularityLoading =
    referenceYear &&
    (hasPendingIrregularitiesQuery.isLoading || hasPendingIrregularitiesQuery.isFetching)

  function refetch(): void {
    if (hasPendingIrregularitiesQuery.isError) {
      hasPendingIrregularitiesQuery.refetch()
    }

    if (hasErrorOnFetchSchool) {
      retryFetchSchool()
    }

    if (isEnrollmentCyclesError) {
      refetchEnrollmentCycles()
    }
  }

  infos.push(getBannerNps2025())

  if (isIntegratedSchool && hasPendingIrregularitiesQuery?.data?.has_pending_irregularities) {
    infos.push(getIrregularitiesBanner())
  }

  if (isIsaacScoreEnabled) {
    infos.push(getIsaacScoreBanner())
  }

  if (canSeeCreditCampaing && hasPayoutReportAccess) {
    infos.push(getCreditCampaign())
  }

  if (canEditStudent) {
    infos.push(getStudentEditionBanner())
  }

  if (isEnrollmentCampaignEnabled) {
    const enrollmentBanner =
      isIsaacPay || isIntegratedSchool
        ? getEnrollmentCampaignBanner()
        : getReEnrollmentCampaignBanner()

    infos.push(enrollmentBanner)
  }

  return {
    isError:
      hasPendingIrregularitiesQuery.isError || hasErrorOnFetchSchool || isEnrollmentCyclesError,
    isLoading: isPendingIrregularityLoading || isLoadingSchool || isEnrollmentCyclesFetching,
    data: infos,
    refetch,
  }
}
