import styled from 'styled-components'

export const Container = styled.div`
  padding: 0 ${props => props.theme.gravity.spacing[4]};

  display: flex;
  align-items: center;
  flex: 1;

  .icon-search {
    margin-right: ${props => props.theme.gravity.spacing[4]};
    margin-left: ${props => props.theme.gravity.spacing[2]};
  }
`

export const Input = styled.input`
  flex: 1;
  border: none;
  outline: none;

  padding: ${props => props.theme.gravity.spacing[6]} 0;
  font-size: ${props => props.theme.gravity.fontSize.base};

  ::placeholder {
    color: ${props => props.theme.gravity.colors['colors-text-main-2']};
    font-weight: ${props => props.theme.gravity.fontWeight.normal};
  }

  :focus::placeholder {
    color: ${props => props.theme.gravity.colors['colors-text-main-4']};
  }
`
