import { Text } from '@gravity/text'
import styled from 'styled-components'

export const Box = styled.div`
  padding: 0 ${({ theme }) => theme.gravity.spacing[1]};
`

export const BoxCloseButton = styled(Box)`
  padding: ${({ theme }) => theme.gravity.spacing[1]} ${({ theme }) => theme.gravity.spacing[1]};
`

export const BoxLoading = styled(Box)`
  margin-bottom: ${({ theme }) => `${theme.gravity.spacing[1]}`};
`

interface withTopButton {
  readonly withButton: boolean
}

export const Image = styled.img<withTopButton>`
  text-align: center;
  padding: ${({ theme, withButton }) => {
    return `${theme.gravity.spacing[withButton ? 'none' : '3']} 0 0`
  }};
`

export const SubtitleText = styled(Text)`
  text-align: center;
  margin: unset;
  padding: unset;
`

export const IconDiv = styled.div`
  display: flex;
  justify-content: center;
`

export const HeadingDiv = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`

export const SubTitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-x: 7;
  margin-bottom: 2;
`

export const MessagesDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 0 ${({ theme }) => theme.gravity.spacing[2]};
  margin-bottom: ${({ theme }) => theme.gravity.spacing[1]};
`
