import React, { useState } from 'react'
import { Popover } from '@gravity/popover'
import { IconButton } from '@gravity/icon-button'
import { MenuOutline } from '@gravity/icons'

type Option = {
  icon?: JSX.Element
  label: string
  name: string
  onClick: () => void
}

type ContextDropdownProps = {
  options: Option[]
}

/**
 * Reusable contextual dropdown
 */
export const ContextDropdown = ({ options }: ContextDropdownProps) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      {options.length > 0 && (
        <Popover.Root open={open} onOpenChange={setOpen}>
          <Popover.Trigger
            asChild
            className="bg-colors-interaction-primary-1 hover:bg-colors-interaction-primary-2 active:bg-colors-interaction-primary-2"
            size={3}
          >
            <IconButton className="header-menu">
              <MenuOutline className="fill-colors-interaction-secondary-1 p-1" />
            </IconButton>
          </Popover.Trigger>
          <Popover.Content
            side="bottom"
            align="end"
            className="z-50 border border-solid border-colors-border-neutral-2 rounded-2xl"
          >
            {options.map(option => (
              <React.Fragment key={option.name}>
                {option.name === 'logout' && <Popover.Divider />}
                <Popover.Item
                  onClick={() => {
                    option.onClick()
                  }}
                  icon={option.icon}
                >
                  {option.label}
                </Popover.Item>
              </React.Fragment>
            ))}
          </Popover.Content>
        </Popover.Root>
      )}
    </>
  )
}
