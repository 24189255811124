import { Fragment, useMemo, useState } from 'react'
import { Table } from '@gravity/table'

import { usePagination } from 'src/shared/hooks/usePagination'

import { formatCPF } from '@/shared/utils'
import theme from '@/shared/theme'

import { TableLoading } from './components/TableLoading'
import { TableEmptyState } from './components/TableEmptyState'
import { useListGuardiansTable } from './hooks/useListGuardiansTable'
import { mapDataToRows } from './utils/mapDataToRows'
import { ListGuardiansTableProps, Rows } from './types'
import { TableContainer } from './styles'
import { DebtStatus } from '@/shared/interfaces'
import { ListGuardiansFilter } from '../ListGuardiansFilter'
import { OPTIONS } from '../ListGuardiansFilter/constants'
import { transformFilters } from '@/escolas/pages/responsaveis/ListGuardians/utils'

const ListGuardiansTable = ({
  guardians,
  isLoading,
  total,
  sortOrder,
  setSortOrder,
  searchQuery,
  setSearchQuery,
  filters,
  updateFilters,
}: ListGuardiansTableProps) => {
  const [filterIsOpen, setFilterIsOpen] = useState(false)

  const allFiltersOptions = {
    debtStatus: OPTIONS,
  }

  const activeFilters = transformFilters(filters ?? {}, allFiltersOptions)

  const { pagination, updatePaginationValue } = usePagination(1, 25)

  const {
    columns,
    formatStudentsData,
    formatDebtStatusData,
    redirectToGuardianPage,
  } = useListGuardiansTable({
    sortOrder,
    setSortOrder,
  })

  const rows: Rows = isLoading ? [] : mapDataToRows(guardians ?? [])

  const handleSearch = (query: string) => {
    setSearchQuery?.(query)
  }

  const handleAddFilter = () => {
    setFilterIsOpen(true)
    updateFilters?.({
      debt_status: activeFilters.debt_status.map(filter => filter.value as DebtStatus),
    })
  }

  const handleRemoveFilter = (filter: { text: string; value: string }) => {
    updateFilters?.({
      debt_status: activeFilters.debt_status
        .filter(activeFilter => activeFilter.value !== filter.value)
        .map(filter => filter.value as DebtStatus),
    })
  }

  const filteredRows = useMemo(() => {
    if (!searchQuery) return rows

    let result = rows.filter(
      item =>
        item.name.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        item.tax_id.toLowerCase().includes(searchQuery?.toLowerCase())
    )

    if (activeFilters.debt_status.length > 0) {
      result = result.filter(item =>
        activeFilters.debt_status.some(filter => item.debt_status === filter.value)
      )
    }

    return result ?? []
  }, [searchQuery, activeFilters, rows])

  return (
    <>
      {filters && updateFilters && (
        <ListGuardiansFilter
          filters={filters}
          updateFilters={updateFilters}
          isOpen={filterIsOpen}
          setIsOpen={setFilterIsOpen}
        />
      )}
      <TableContainer theme={theme} className="w-full overflow-x-auto p-1 mb-8">
        <Table.Root data-testid="guardiansListTable">
          <Table.Search
            variant="search-and-filters"
            initialSearch={searchQuery}
            onSearch={handleSearch}
            filters={activeFilters.debt_status}
            onClickFilterButton={handleAddFilter}
            onRemoveFilter={handleRemoveFilter}
          />
          <Table.Head>
            {columns.map(column => (
              <Table.HeaderCell
                key={column.name}
                {...column}
                data-testid={'guardiansListColumn-' + column.name}
              />
            ))}
          </Table.Head>
          <Table.Body>
            {isLoading && <TableLoading columns={columns} />}
            {!isLoading && filteredRows.length === 0 && <TableEmptyState columns={columns} />}
            {!isLoading &&
              filteredRows.length > 0 &&
              filteredRows.map((row: Record<string, any>) => (
                <Table.Row key={row.id} onClick={() => redirectToGuardianPage(row.id)}>
                  {columns.map(column => (
                    <Fragment key={column.name + row[column.name]}>
                      {column.name === 'debt_status' && (
                        <Table.BadgeCell
                          badgeVariant="soft"
                          {...formatDebtStatusData(row[column.name])}
                        />
                      )}
                      {column.name === 'students' && (
                        <Table.TextCell {...formatStudentsData(row[column.name])} />
                      )}
                      {column.name !== 'debt_status' && column.name !== 'students' && (
                        <Table.TextCell>
                          {column.name === 'tax_id'
                            ? formatCPF(row[column.name])
                            : row[column.name]}
                        </Table.TextCell>
                      )}
                    </Fragment>
                  ))}
                </Table.Row>
              ))}
          </Table.Body>
          <Table.Pagination
            data-test-id="guardiansListTablePagination"
            page={pagination.page}
            itemsPerPage={pagination.itemsPerPage}
            total={total ?? 0}
            onChangePage={newPage => {
              updatePaginationValue('page', newPage)
            }}
            onChangeItemsPerPage={newItemsPerPage => {
              updatePaginationValue('itemsPerPage', newItemsPerPage)
            }}
          />
        </Table.Root>
      </TableContainer>
    </>
  )
}

export default ListGuardiansTable
