import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { TimerOutline, MoneyDollarCircleOutline } from '@gravity/icons'

import * as Styled from './styles'

type ScoreHistoryProps = {
  earliestAcademicCycle: number
  isCurrentlyDefault: boolean
}

export const ScoreHistory = ({ earliestAcademicCycle, isCurrentlyDefault }: ScoreHistoryProps) => {
  return (
    <>
      <Heading variant="heading-h4-medium">Histórico isaac</Heading>
      <Styled.Wrapper>
        <Styled.CardWrapper variant="ghost">
          <TimerOutline />
          <Text variant="body-1-regular">{`Consta na base isaac desde ${earliestAcademicCycle}.`}</Text>
        </Styled.CardWrapper>

        <Styled.CardWrapper variant="ghost">
          <MoneyDollarCircleOutline />
          <Text variant="body-1-regular">
            {isCurrentlyDefault ? 'Possui dívidas em aberto.' : 'Não possui dívidas em aberto.'}
          </Text>
        </Styled.CardWrapper>
      </Styled.Wrapper>
    </>
  )
}
