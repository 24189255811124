import { AnticipationButton } from './styles'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { isIsaacPaySchool } from '@/shared/utils/isIsaacPaySchool'
import { useHistory } from 'react-router-dom'
import { PayoutAnticipationButtonProps } from './types'
import { useContextEligibility } from '../../contexts/PayoutAnticipationContext'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { REPORT_BUTTON_NAMES } from '../../constants/REPORT_BUTTON_NAMES'
import { Dialog } from '@gravity/dialog'
import { Text } from '@gravity/text'
import { Button } from '@gravity/button'
import { useState } from 'react'
import {
  PAYOUT_ANTICIPATION_ORDER_TYPE,
  PAYOUT_ANTICIPATION_PAGES,
} from '@monorepo/report/constants/PAYOUT_ANTICIPATION_TYPES'
import { Tooltip } from '@gravity/tooltip'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'

export type isDisabledProps = {
  creditType: string
  isEligibilityAR: boolean
  isInMaintenance: boolean
  isLocked: boolean
  isSelectedOlderMonth: boolean
}

export const isDisabled = ({
  creditType,
  isEligibilityAR,
  isLocked,
  isInMaintenance,
  isSelectedOlderMonth,
}: isDisabledProps) => {
  const orderLockedIsAnticipation = creditType === PAYOUT_ANTICIPATION_ORDER_TYPE
  return (
    isInMaintenance ||
    (isLocked && !orderLockedIsAnticipation) ||
    (!isLocked && !isEligibilityAR) ||
    isSelectedOlderMonth
  )
}

export const PayoutAnticipationButton = ({
  size = 2,
  variant = 'solid',
  color = 'accent',
  text = 'Antecipe seu repasse',
  orderLock,
  isSelectedOlderMonth = false,
  pageName,
}: PayoutAnticipationButtonProps) => {
  const { push } = useHistory()
  const { school, schoolSlug, isCollege } = useSelectedSchool()
  const { eligibility } = useContextEligibility()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const [openModal, setOpenModal] = useState(false)

  const isIsaacPay = isIsaacPaySchool(school)
  const hasPayoutAnticipationAccess = !isCollege && !isIsaacPay
  const orderLockedIsAnticipation = orderLock?.creditType === PAYOUT_ANTICIPATION_ORDER_TYPE

  const isInMaintenance = useUnleashFlag(UnleashFlags.CRD_166_DISABLE_CREDIT_FLOWS)

  const handleAnticipationOrderIsLocking = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.PAYOUT_ANTICIPATION,
        name: EventDispatcherEvents.MODAL_VIEWED,
        action: 'component_view',
        customProperties: {
          $page_name: pageName,
          $modal_name: 'Antecipação em andamento',
        },
      })

    setOpenModal(true)
  }

  const handleAnticipationButtonClick = async () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.PAYOUT_ANTICIPATION,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $page_name: pageName,
          $button_name: REPORT_BUTTON_NAMES.PAYOUT_ANTICIPATION,
        },
      })

    push(`/${schoolSlug}/${PAYOUT_ANTICIPATION_PAGES.SIMULATION}`)
  }

  text = orderLock?.isLocked && orderLockedIsAnticipation ? 'Ver antecipação em andamento' : text
  const noAROrderLock =
    orderLock && (!orderLock.isLocked || orderLock.creditType === PAYOUT_ANTICIPATION_ORDER_TYPE)

  return (
    <>
      {hasPayoutAnticipationAccess && (
        <Tooltip
          hidden={!isInMaintenance || noAROrderLock}
          position="bottom"
          text={
            isInMaintenance
              ? `Estamos em manutenção, solicitação de antecipação de repasse\n indisponível no momento. Voltamos em breve.`
              : noAROrderLock
              ? `Existe um pedido de crédito em\n andamento. Aguarde sua\n conclusão antes de simular\n uma antecipação.`
              : ''
          }
        >
          <span>
            <AnticipationButton
              size={size}
              variant={variant}
              color={color}
              onClick={
                orderLock?.isLocked && orderLockedIsAnticipation
                  ? handleAnticipationOrderIsLocking
                  : handleAnticipationButtonClick
              }
              disabled={isDisabled({
                creditType: orderLock?.creditType,
                isEligibilityAR: eligibility?.eligibility?.eligible?.AR ?? false,
                isLocked: orderLock?.isLocked,
                isInMaintenance,
                isSelectedOlderMonth,
              })}
            >
              {text}
            </AnticipationButton>
          </span>
        </Tooltip>
      )}

      <Dialog
        open={openModal || undefined}
        onOpenChange={
          openModal
            ? (open: boolean) => {
                if (!open) {
                  setOpenModal(false)
                }
              }
            : () => null
        }
        backdrop
        title="Antecipação em andamento"
        size={2}
        cancelButton={<></>}
        actionButton={
          <Button
            variant="solid"
            onClick={() => {
              setOpenModal(false)
              push(`/${schoolSlug}/${PAYOUT_ANTICIPATION_PAGES.STATUS}`)
            }}
          >
            Ver antecipação
          </Button>
        }
        content={
          <Text variant="body-2-regular">
            Há um pedido de antecipação de repasse em andamento. Aguarde a conclusão antes de
            iniciar uma nova solicitação.
          </Text>
        }
      />
    </>
  )
}
