import { IrregularityTable } from './IrregularityTable'
import { GraduationCapOutline } from '@gravity/icons'
import { IrregularityTableTitle } from './IrregularityTableTitle'
import { StudentIrregularityType, studentIrregularityParamsDict } from '../types'
import { Irregularity } from '@monorepo/enrollment/services/types'
import {
  IrregularityTableBaseboard,
  ReferenceLink,
  ReferenceMessage,
} from './IrregularityTableBaseboard'
import { IrregularityHeaderType } from './IrregularityTable/types'
import { useSortOrder } from '../hooks/useSortOrder'
import { ResolvedColumn } from './InstallmentIrregularityTable'

interface StudentIrregularityTableProps {
  externalId: string
  irregularityList: Irregularity[]
  referenceLink: string
  studentName: string
}

const neededActionColumn = {
  key: 'needed_action_column',
  label: 'Ação necessária',
  sortable: false,
}

const COLUMN_HEADERS: Array<IrregularityHeaderType> = [
  { key: 'pending_column', label: 'Pendência', sortable: true },
  { key: 'student_name_column', label: 'Nome', sortable: false },
  neededActionColumn,
  { key: 'resolved_column', label: 'Status da ação', sortable: false },
]

const getActionMessage = (referenceLink: string, externalId: string): JSX.Element => {
  return referenceLink ? (
    <ReferenceLink url={referenceLink} />
  ) : (
    <ReferenceMessage
      identifier="ID do Aluno"
      externalId={externalId}
      copyableTooltip="ID do aluno copiado"
    />
  )
}

export const StudentIrregularityTable = ({
  externalId,
  studentName,
  irregularityList,
  referenceLink,
}: StudentIrregularityTableProps) => {
  const { order, updateOrder, sortRows } = useSortOrder()

  sortRows(irregularityList, studentIrregularityParamsDict)

  let irregularitiesResolved = 0

  const rows = irregularityList.map(irregularity => {
    const value =
      studentIrregularityParamsDict[irregularity.irregularity_type as StudentIrregularityType]
    const isResolved = irregularity.resolved_at?.length > 0

    if (isResolved) irregularitiesResolved++

    const key = `${studentName}-${irregularity.irregularity_type}`
    return {
      key: key,
      resolved: isResolved,
      columns: [
        {
          text: value?.name,
        },
        {
          text: studentName || 'Nome não indicado',
          tooltip: true,
        },
        {
          text: value?.action,
          tooltip: true,
        },
        {
          component: (
            <ResolvedColumn
              isResolved={isResolved}
              title={value?.title || ''}
              description={value?.description || ''}
              videoUrl={value?.src || ''}
            />
          ),
        },
      ],
    }
  })

  const hasIrregularities = irregularityList.length > 0
  const allIrregularitiesAreResolved = irregularitiesResolved === irregularityList.length
  const shouldShowConcludedMessage = hasIrregularities && allIrregularitiesAreResolved

  let callToActionMsg = 'Corrija os dados no seu sistema'
  if (shouldShowConcludedMessage) {
    neededActionColumn.label = 'Ação feita'
    callToActionMsg = 'Dados corrigidos no seu sistema'
  }

  return (
    <div>
      <IrregularityTableTitle title="Informação do aluno" icon={<GraduationCapOutline />} />
      <IrregularityTable
        rows={rows}
        columnHeaders={COLUMN_HEADERS}
        ordering={order}
        onChangeOrdering={updateOrder}
      />
      <IrregularityTableBaseboard
        callToActionMsg={callToActionMsg}
        actionElement={
          shouldShowConcludedMessage ? <></> : getActionMessage(referenceLink, externalId)
        }
        referenceLink={referenceLink}
      />
    </div>
  )
}
