import { useHistory } from 'react-router-dom'

import { Button } from '@gravity/button'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { DashedArrowLeft } from '@gravity/icons'

import { useLayout } from '@/shared/hooks/useLayout'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'

import { ActionsWrapper, PageInfoWrapper } from './styles'
import { PaymentDisputeHelp } from '@monorepo/payment-dispute/pages/PaymentDispute/components/PaymentDisputeHelp'

export const PaymentDisputeHeader = () => {
  const history = useHistory()

  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  useLayout({ enableSideMenu: true, enableHeader: renderNewHeader })

  return (
    <div className="flex flex-col gap-4">
      <ActionsWrapper>
        <Button variant="ghost" iconStart={<DashedArrowLeft />} onClick={() => history.goBack()}>
          Voltar
        </Button>
        {!renderNewHeader && <PaymentDisputeHelp />}
      </ActionsWrapper>

      <PageInfoWrapper>
        {renderNewHeader ? (
          <div className="flex justify-between align-center pb-4">
            <Heading variant="heading-h2-medium">Cobranças indevidas</Heading>
            <PaymentDisputeHelp />
          </div>
        ) : (
          <Heading variant="heading-h3-medium">Cobranças indevidas</Heading>
        )}
        <Text variant="subtitle-regular">
          Visualize abaixo as <strong>faturas declaradas como cobrança indevida</strong> pelos
          responsáveis ao time de suporte do isaac.&nbsp;
          <strong>As contestações tem prazo de 30 dias para serem concluídas</strong> e, caso a
          escola não tome nenhuma ação dentro deste prazo, ocorrerá uma&nbsp;
          <strong>baixa automática dos valores devidos.</strong>
        </Text>
      </PageInfoWrapper>
    </div>
  )
}
