import dayjs from 'dayjs'
import { StyledInput } from './styles'
import type { InputDueDateProps } from './types'
import { DatepickerPrimitives as Datepicker } from '@gravity/datepicker'

const InputDueDate = ({
  maxDate,
  minDate,
  selectedValue,
  handleValue,
  label,
}: InputDueDateProps) => {
  return (
    <StyledInput>
      <Datepicker.Root>
        <Datepicker.Label label={label} name="inputDueDate" />
        <Datepicker.Trigger
          name="inputDueDate"
          size={3}
          variant="surface"
          placeholder="Selecione a data"
        >
          {selectedValue?.toLocaleDateString('pt-br')}
        </Datepicker.Trigger>
        <Datepicker.Calendar
          value={selectedValue}
          minDate={minDate}
          maxDate={maxDate}
          onChange={date => {
            handleValue(dayjs(date as Date))
          }}
        />
      </Datepicker.Root>
    </StyledInput>
  )
}

export default InputDueDate
