import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useGuardiansInstallmentsQuery } from '@/modules/guardians/GuardianDetailsInstallmentsTab/hooks/useGuardiansInstallmentsQuery'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useParams } from 'react-router-dom'
import { InstallmentsDrawerContainerV2 } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentsDrawerContainerV2'
import { useInstallmentDrawerContext } from '@/modules/guardians/contexts/InstallmentDrawerContext'
import { useForm } from 'react-hook-form'
import { useToast } from '@gravity/toast'

import { Agglutination } from '@/modules/guardians/GuardianDetails/features/Agglutination/Agglutination'
import { useGuardianFiltersOptionsQuery } from '@/modules/guardians/GuardianDetails/hooks/useGuardianFiltersOptionsQuery'
import { UnleashFlags, useJWT, useUnleashFlag } from '@/shared/hooks'
import { ReceivablesAgglutinationProvider } from '@/escolas/contexts/receivablesAgglutinationContext'
import { useQueryClient } from '@tanstack/react-query'
import {
  TrackDrawerClickEvents,
  useGuardianDetailsInvoiceEvents,
} from '@/modules/guardians/GuardianDetailsInvoicesTab/hooks/useGuardianDetailsInvoiceEvents'
import { useQuery } from '@/shared/hooks/useQuery'
import useUserGuiding from '@/escolas/router/responsavel/[guardianId]/contratos/hooks/useUserGuiding'
import { CONTRACT_TABLE_TAB } from '@/escolas/router/responsavel/[guardianId]/contratos/constants'
import {
  InstallmentStatus,
  InstallmentStatusDescription,
  InstallmentType,
} from '@/modules/guardians/GuardianDetails/constants'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { Installment } from '@/modules/guardians/GuardianDetails/types'
import { DEFAULT_AWAITING_TIME } from '@/modules/guardians/constants'
import { Row } from '@/modules/guardians/GuardianDetailsInvoicesTab/components/GuardianInvoicesTable/types'
import { GuardianInvoicesTable } from '@/modules/guardians/GuardianDetailsInvoicesTab/components/GuardianInvoicesTable/GuardianInvoicesTable'
import { AgglutinationDialog } from '@/modules/guardians/InstallmentsDrawerContainer/AgglutinationDialog'
import { AgglutinationMetaData } from '@/escolas/components/contract/ContractDetails'
import { GuardianDetailsFooter } from '@/modules/guardians/GuardianDetails/features/GuardianDetailsFooter/GuardianDetailsFooter'
import { EnrollmentOverdueDialog } from '@/modules/guardians/EnrollmentOverdueDialog'
import { FiltersDialog } from '@/shared/components/FiltersDialog'
import { GuardianDetailsDisclaimerModal } from './components/GuardianDetailsDisclaimerModal/GuardianDetailsDisclaimerModal'
import { UpdateEnrollmentStatus } from './components/UpdateEnrollmentStatus/UpdateEnrollmentStatus'
import { useGuardiansInvoicesFilters } from './hooks/useGuardianInvoicesFilter/useFiltersActions'
import { getModalShownDate, storeModalShownDate } from './utils/infoFromSession'
import { isTimeExpired } from './utils/getTimeExpiration'
import { getUniqueBufferInstallmentsByStudent } from './utils/getUniqueBufferInstallmentsOfStudent'
import { SHOW_MODAL_AGAIN_IN_HOURS } from './constants'

type GuardianDetailsInvoicesTabProps = {
  setShowPendingEnrollmentProcessingAlert: Dispatch<SetStateAction<boolean>>
}

export const GuardianDetailsInvoicesTab = ({
  setShowPendingEnrollmentProcessingAlert,
}: GuardianDetailsInvoicesTabProps) => {
  const { isAdmin, userId, name, email } = useJWT()
  const { guardianId } = useParams<{ guardianId: string }>()
  const { school } = useSelectedSchool()
  const { query, setOnQueryParam } = useQuery()
  const queryClient = useQueryClient()
  const { selectedInstallment, setSelectedInstallment } = useInstallmentDrawerContext()

  const [showInstallmentsDetailsDrawer, setShowInstallmentsDetailsDrawer] = useState(false)
  const [isProcessingAgglutination, setProcessingAgglutination] = useState<boolean>(false)
  const [refetchCount, setRefetchCount] = useState(0)
  const [isFirstInstallmentsFetch, setIsFirstInstallmentsFetch] = useState(true)
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false)
  const [showAgglutinationDialog, setShowAgglutinationDialog] = useState<boolean>(false)

  const { toast } = useToast()

  const agglutinationForm = useForm<AgglutinationMetaData>({
    mode: 'onChange',
  })

  const { trackRowClick, trackDrawerClick } = useGuardianDetailsInvoiceEvents()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const { filterOptions, refetchFiltersOptions } = useGuardianFiltersOptionsQuery({
    guardianId,
    schoolId: school?.id ?? '',
  })

  const {
    isFilterDialogOpen,
    filtersGroup,
    form,
    filtersArray,
    filterTable,
    handleCloseFilterDialog,
    handleOpenFilterDialog,
    handleApplyFilters,
    handleClearFilter,
    handleRemoveFilter,
    updateFieldSelection,
  } = useGuardiansInvoicesFilters({ filterOptions: filterOptions?.data })

  const {
    guardiansInstallments,
    isFetchGuardiansInstallmentsLoading,
    refetchGuardiansInstallments,
    isRefetchingGuardiansInstallments,
    isFetchGuardiansInstallmentsFetched,
  } = useGuardiansInstallmentsQuery({
    filter: filterTable,
    guardianId,
    schoolId: school?.id ?? '',
  })

  const isRenegotiateAndAgglutinateButtonEnabled = useUnleashFlag(
    UnleashFlags.REG_2042_ENABLE_RENEGOTIATE_AND_AGGLUTINATE_BUTTONS
  )

  const uniqueBufferInstallmentsByStudent = guardiansInstallments?.data
    ? getUniqueBufferInstallmentsByStudent(guardiansInstallments.data)
    : []

  const studentIds = uniqueBufferInstallmentsByStudent?.map(installment => installment.student.id)

  const isLoadingInstallments =
    isFetchGuardiansInstallmentsLoading || isRefetchingGuardiansInstallments

  const hasEnabledCheckbox = guardiansInstallments?.data?.some(installment =>
    [InstallmentStatus.DUE_TODAY, InstallmentStatus.OPEN, InstallmentStatus.OVERDUE].includes(
      installment.status
    )
  )

  const handleCloseInstallmentsDetailsDrawer = () => {
    setSelectedInstallment(undefined)
    setShowInstallmentsDetailsDrawer(false)
  }

  const handleRowClick = (row: Row) => {
    trackRowClick(row.row_type, row.contract_id)
    setSelectedInstallment(row)
    trackDrawerClick(row.urn, TrackDrawerClickEvents.OPEN)
  }

  const handleDisclaimerModalClose = () => {
    storeModalShownDate(guardianId)
    setShowDisclaimerModal(false)
  }

  const checkPendingEnrollProcessing = (data: Installment[]) => {
    const pendingEnrollProcessing = data?.reduce((result, installment) => {
      return (
        result ||
        installment.status_description === InstallmentStatusDescription.PENDING_ENROLL_PROCESSING
      )
    }, false)
    if (pendingEnrollProcessing && refetchCount < 3) {
      setTimeout(() => {
        queryClient.invalidateQueries(['guardian-details-installments'])
        setRefetchCount(refetchCount + 1)
        refetchGuardiansInstallments()
      }, DEFAULT_AWAITING_TIME)
    } else if (pendingEnrollProcessing && refetchCount === 3) {
      setShowPendingEnrollmentProcessingAlert(true)
    }
  }

  const handleAgglutinationClose = () => {
    setShowAgglutinationDialog(false)
  }

  const callbackAgglutination = () => {
    toast({
      type: 'success',
      title: 'Aglutinação realizada com sucesso',
    })
    setOnQueryParam('true', 'refetch', 'replace')
    setShowAgglutinationDialog(false)
  }

  useUserGuiding({
    userId,
    email,
    name,
    isAdmin,
    isLoading: isFetchGuardiansInstallmentsLoading,
    activeTab: CONTRACT_TABLE_TAB.INSTALLMENTS,
  })

  const shouldRefetch = Boolean(query.get('refetch'))

  useEffect(() => {
    if (isFetchGuardiansInstallmentsFetched && isFirstInstallmentsFetch) {
      setIsFirstInstallmentsFetch(false)

      if (guardiansInstallments?.data.length === 0) {
        handleClearFilter()
      }
    }
  }, [isFetchGuardiansInstallmentsFetched, isFirstInstallmentsFetch])

  useEffect(() => {
    if (shouldRefetch) {
      setOnQueryParam('', 'refetch', 'replace')
      refetchFiltersOptions()
      refetchGuardiansInstallments()
    }
  }, [shouldRefetch])

  useEffect(() => {
    updateFieldSelection('contract_id', 'contractId')
    updateFieldSelection('installment_statuses', 'installmentStatuses')
    updateFieldSelection('installment_types', 'installmentTypes')
    updateFieldSelection('negotiation_id', 'negotiationId')
    updateFieldSelection('product_ids', 'productsArray')
    updateFieldSelection('reference_years', 'referenceYears')
    updateFieldSelection('student_ids', 'studentsArray')
  }, [updateFieldSelection])

  useEffect(() => {
    if (selectedInstallment) {
      setShowInstallmentsDetailsDrawer(true)
    }
  }, [selectedInstallment])

  useEffect(() => {
    const whenModalBeenShown = getModalShownDate(guardianId)
    if (!guardiansInstallments?.data && !isFetchGuardiansInstallmentsFetched) return
    checkPendingEnrollProcessing(guardiansInstallments?.data as Installment[])
    const hasDownPaymentInstallment = guardiansInstallments?.data.some(
      installment => installment.type === InstallmentType.DOWN_PAYMENT
    )

    if (
      hasDownPaymentInstallment &&
      isTimeExpired(whenModalBeenShown, SHOW_MODAL_AGAIN_IN_HOURS) &&
      !isAdmin
    ) {
      setShowDisclaimerModal(true)
      isInitialized &&
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.INSTALLMENTS_LIST,
          entity: EventDispatcherEntities.OPEN_NEGOTIATION_MODAL,
          customProperties: {
            $name: 'Responsável com negociação em andamento',
          },
          name: EventDispatcherEvents.MODAL_VIEWED,
          action: 'component_view',
        })
    }
  }, [guardiansInstallments, isFetchGuardiansInstallmentsFetched, refetchCount])

  return (
    <>
      <EnrollmentOverdueDialog />
      <GuardianDetailsDisclaimerModal
        isOpen={showDisclaimerModal}
        onClose={handleDisclaimerModalClose}
      />

      <div className="flex flex-col gap-3">
        <div className="flex flex-row-reverse gap-4 mb-2">
          <UpdateEnrollmentStatus
            schoolId={school?.id ?? ''}
            studentIds={studentIds}
            isLoadingInstallments={isLoadingInstallments}
          />
          {isAdmin && isRenegotiateAndAgglutinateButtonEnabled && (
            <Agglutination setShowAgglutinationDialog={setShowAgglutinationDialog} />
          )}
        </div>

        <GuardianInvoicesTable
          filtersArray={filtersArray}
          loading={isLoadingInstallments}
          installments={guardiansInstallments?.data || []}
          onClickFilterButton={handleOpenFilterDialog}
          onRemoveFilter={handleRemoveFilter}
          onRowClick={handleRowClick}
        />
      </div>

      {showInstallmentsDetailsDrawer && (
        <InstallmentsDrawerContainerV2
          onClose={handleCloseInstallmentsDetailsDrawer}
          guardianId={guardianId}
          selectedInstallment={selectedInstallment}
        />
      )}

      {showAgglutinationDialog && (
        <ReceivablesAgglutinationProvider>
          <AgglutinationDialog
            onClose={handleAgglutinationClose}
            form={agglutinationForm}
            isProcessingAgglutination={isProcessingAgglutination}
            disableChecks={() => setProcessingAgglutination(true)}
            enableChecks={() => setProcessingAgglutination(false)}
            callbackAgglutination={callbackAgglutination}
          />
        </ReceivablesAgglutinationProvider>
      )}

      {hasEnabledCheckbox && <GuardianDetailsFooter />}

      <FiltersDialog
        form={form}
        filters={filtersGroup}
        onApplyFilters={handleApplyFilters}
        closeDialog={handleCloseFilterDialog}
        visible={isFilterDialogOpen}
        resetValues={handleClearFilter}
        resetOptions={{ keepValues: true }}
        size={2}
        contentClassName="mt-5"
        keepValuesOnClose
      />
    </>
  )
}
