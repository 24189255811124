import { REPORT_PAGE_NAMES } from '@monorepo/report/constants/REPORT_PAGE_NAMES'
import {
  FilterOptions,
  FinancialTransactionProps,
  TransactionEvents,
} from '@monorepo/report/models/report'

interface FilterHook<T> {
  clearFilter: () => any
  filter: T
  filterCount: number
  updateFilter: (filter: T) => void
}

export interface ColumnProps {
  description?: string
  displayName: string
  name: string
  sortable?: boolean
}

export enum PaginationEvents {
  ITEMS_PER_PAGE = 'itemsPerPage',
  PAGE = 'page',
}

export interface ReportTableProps<Filter> {
  availableFilters: FilterOptions<Filter>
  columns: ColumnProps[]
  emptyStateMessage?: string
  filterHook?: () => FilterHook<Filter>
  filterTypes?: Record<keyof Filter, string>
  handleRowClick: (row: TableData) => void
  hasFilters?: boolean
  isLoading?: boolean
  onPagination?: (event: PaginationEvents, value: string) => void
  onSearch?: (value: string) => void
  onSort?: (sortBy: string, sortOrder: string) => void
  reportPage: typeof REPORT_PAGE_NAMES[keyof typeof REPORT_PAGE_NAMES]
  rows: TableData[]
  totalItems: number
  tranformationFields?: { field: string; type: string }[]
}

export type PayoutReportTableProps<Filter> = {
  availableFilters: FilterOptions<Filter>
  isLoading?: boolean
  isMonthlyViewEnabled?: boolean
  isNewVersionPayout?: boolean
  onRowClick: (row: TableData) => void
  totalItems: number
  transactions: FinancialTransactionProps[]
}

export interface FilterItem {
  text: string
  value: string
}

export type TableData = {
  contractReferenceYear?: string
  events?: TransactionEvents
  id: string
  installment?: string
  key: string
  productName: string
  receiptDate?: datestring
  referenceMonth?: datestring
  studentName: string
  totalAmount: string
}

export type Pagination = {
  itemsPerPage: number
  page: number
}

export const DEFAULT_PAGINATION = {
  page: 1,
  itemsPerPage: 25,
}

export type SearchName = {
  name: string
}

export const DEFAULT_SEARCH_NAME = { name: '' }
