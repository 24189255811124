import * as S from './styles'
import { StepFooterProps } from './types'
import { useHelp } from '../../hooks/useHelp'
import config from '@/config'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { DashedArrowLeft, DashedArrowRight, QuestionOutline } from '@gravity/icons'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'

const StepFooter = ({
  showHelpButton = false,
  stepName,
  goBack,
  disableBack,
  helpButtonEntry,
  btnPrimary,
}: StepFooterProps) => {
  const { getMessage, sendCheckoutEvent } = useHelp({ helpButtonEntry })

  function handleOnClick() {
    window.open(encodeURI(`http://wa.me/${config.CSS_TELEPHONE}?text=${getMessage()}`), '_blank')
    sendCheckoutEvent(EventDispatcherEntities.BUTTON_BASE_HELP, {
      name: 'Ajuda',
      location: stepName,
    })
  }

  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  return (
    <S.FooterStyled
      ghostButton={
        showHelpButton && { iconStart: <QuestionOutline />, label: 'Ajuda', onClick: handleOnClick }
      }
      primaryButton={{
        ...btnPrimary,
        iconEnd: !btnPrimary.hideIcon && <DashedArrowRight />,
        label: btnPrimary.label ?? 'Próximo',
        variant: btnPrimary.variant ?? 'solid',
      }}
      secondaryButton={
        goBack && {
          iconStart: <DashedArrowLeft />,
          disabled: disableBack,
          label: 'Anterior',
          onClick: goBack,
        }
      }
      $isNewHeaderAllowed={renderNewHeader}
    />
  )
}

export default StepFooter
