import { useState } from 'react'
import envConfig from '@/config'
import { useAgreementSimulationQuery } from '@/escolas/hooks'
import { HotjarEvents, useHotjar } from '@/shared/hooks/useHotjar'
import { useAgreement } from '@/escolas/contexts/agreementContext'
import { useHistory } from 'react-router-dom'
import {
  FailureFeedbackContent,
  FailureFeedbackDialog,
} from '@/shared/components/ConfirmationDialog'
import { getParamGuardianId } from '../../utils/getParamGuardianId'
import { CURRENT_YEAR } from '@/shared/constants'
import { formatCentsToReal } from '@/shared/utils'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import adaptCombinedReceivablesToAgreementParam from './utils/adaptCombinedReceivablesToAgreementParam'
import useCheckoutCart from '@/modules/guardians/hooks/useCheckoutCart'
import { getTotalOverdueAmount, getTotalUpToDateAmount } from './utils/getTotalAmount'
import { validateCart } from './utils/validateCart'
import { MIN_CART_ITEMS } from '../../constants'
import useDownPaymentFlow from '@/modules/guardians/Negotiation/hooks/useDownPaymentFlow'
import adaptInstallmentV2ToCombinedReceivables from '@/modules/guardians/Negotiation/helpers/adaptInstallmentV2ToCombinableReceivable'
import useGuardiansInstallmentsByUrnQuery from '@/modules/guardians/ManualLiquidation/hooks/manualLiquidation/useGuardianInstallmentsQueryByUrn'
import { useToast } from '@gravity/toast'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useGuardianNavigation } from '@/shared/hooks/useGuardianNavigation'
import { ErrorDialog } from './types'
import { useNegotiationFlow } from '@/modules/guardians/Negotiation/hooks/useNegotiationFlow'
import { useNegotiation } from '@/modules/guardians/Negotiation/hooks/useNegotiation'
import { FooterStyled, PopoverContentStyled } from './styles'
import { Popover } from '@gravity/popover'
import { Badge } from '@gravity/badge'
import { InformationOutline } from '@gravity/icons'

export const GuardianDetailsFooter = () => {
  const { schoolSlug } = useSelectedSchool()
  const { sendHotjarEvent } = useHotjar()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { setPageToReturn } = useGuardianNavigation()
  const { isEnableCheckoutWithoutRestrictions } = useNegotiationFlow()

  const guardianId = getParamGuardianId()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [errorDialog, setErrorDialog] = useState<ErrorDialog>({ visible: false })

  const { checkoutCart, totalAmount } = useCheckoutCart()

  const { toast } = useToast()

  const { validateCheckoutCart, goToNegotiation } = useNegotiation({
    setLoading,
    setErrorDialog,
  })

  const { initAgreementSimulationFlow } = useAgreement()
  const { isDownPaymentEnabled } = useDownPaymentFlow()

  const agreementSimulationParams = adaptCombinedReceivablesToAgreementParam(
    checkoutCart,
    guardianId
  )

  const agreementSimulationQuery = useAgreementSimulationQuery(agreementSimulationParams)

  const invalidCheckoutCart = checkoutCart?.length < MIN_CART_ITEMS
  const disablePayButton = invalidCheckoutCart || envConfig.DISABLE_CHECKOUT_BUTTON === 'true'

  const handleErrorDialogClose = () => {
    setErrorDialog({ visible: false })
  }

  const urns = checkoutCart.map(item => item.urn)

  const guardiansInstallmentsByUrQuery = useGuardiansInstallmentsByUrnQuery(
    {
      guardianId,
      urns,
    },
    {
      enabled: false,
    }
  )

  const goToNegotiationFlow = () => {
    const url = `/${schoolSlug}/${CURRENT_YEAR}/responsavel/${guardianId}/negociacao`

    setPageToReturn()

    if (invalidCheckoutCart) {
      toast({
        type: 'error',
        title: 'Antes de pagar, selecione as parcelas a serem pagas',
      })
      return
    }

    if (isDownPaymentEnabled) {
      if (guardiansInstallmentsByUrQuery.isFetchGuardiansInstallmentsSuccess) {
        history.push(url)
        return
      }
      setLoading(true)
      guardiansInstallmentsByUrQuery
        .refetchGuardiansInstallments({ throwOnError: true })
        .then(() => {
          history.push(url)
        })
        .catch(() => {
          const tryAgain = () => {
            setErrorDialog({ visible: false })
            goToNegotiationFlow()
          }
          setErrorDialog({
            visible: true,
            onTryAgain: tryAgain,
          })
        })
        .finally(() => setLoading(false))
      return
    }

    if (agreementSimulationQuery.isSuccess) {
      if (guardiansInstallmentsByUrQuery.isFetchGuardiansInstallmentsSuccess) {
        const receivables = adaptInstallmentV2ToCombinedReceivables(
          guardiansInstallmentsByUrQuery.guardiansInstallments?.data ?? []
        )
        initAgreementSimulationFlow(agreementSimulationQuery.data, receivables)
        history.push(url)
        return
      }
      setLoading(true)
      guardiansInstallmentsByUrQuery
        .refetchGuardiansInstallments({ throwOnError: true })
        .then(response => {
          const receivables = adaptInstallmentV2ToCombinedReceivables(response.data?.data ?? [])
          initAgreementSimulationFlow(agreementSimulationQuery.data, receivables)
          history.push(url)
        })
        .catch(() => {
          const tryAgain = () => {
            setErrorDialog({ visible: false })
            goToNegotiationFlow()
          }
          setErrorDialog({
            visible: true,
            onTryAgain: tryAgain,
          })
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(true)
      Promise.all([
        agreementSimulationQuery.refetch({ throwOnError: true }),
        guardiansInstallmentsByUrQuery.refetchGuardiansInstallments({ throwOnError: true }),
      ])
        .then(results => {
          const agreements = results[0].data
          const receivables = adaptInstallmentV2ToCombinedReceivables(results[1]?.data?.data ?? [])

          initAgreementSimulationFlow(agreements ?? [], receivables)

          history.push(url)
        })
        .catch(() => {
          const tryAgain = () => {
            setErrorDialog({ visible: false })
            goToNegotiationFlow()
          }
          setErrorDialog({
            visible: true,
            onTryAgain: tryAgain,
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const sendEvent = (scope: EventDispatcherEventScopes, name: EventDispatcherEvents) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope,
        name,
        action: 'click',
      })
  }

  const handlePayButtonClick = () => {
    if (disablePayButton) return

    const isCartValid = isEnableCheckoutWithoutRestrictions
      ? validateCheckoutCart()
      : validateCart(checkoutCart)

    if (!isCartValid?.isValid) {
      toast({
        type: 'error',
        title: isCartValid.message ?? 'Erro na validação',
      })
      return
    }

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.GUARDIANS,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $name: 'Pagar resumo financeiro',
        },
      })
    }

    sendHotjarEvent(HotjarEvents.CHECKOUT)
    sendEvent(EventDispatcherEventScopes.GUARDIANS, EventDispatcherEvents.CHECKOUT_PROCESS_CLICK)

    isEnableCheckoutWithoutRestrictions ? goToNegotiation() : goToNegotiationFlow()
  }

  const totalUpToDateAmount = getTotalUpToDateAmount(checkoutCart)
  const totalOverdueAmount = getTotalOverdueAmount(checkoutCart)

  return (
    <>
      <FailureFeedbackDialog
        isVisible={errorDialog.visible}
        buttonLabel="Tentar novamente"
        onClose={handleErrorDialogClose}
        submitHandler={errorDialog.onTryAgain ?? (() => 0)}
      >
        <FailureFeedbackContent />
      </FailureFeedbackDialog>
      <FooterStyled
        amount={formatCentsToReal(totalAmount)}
        additionalInfo={
          <Popover.Root>
            <Popover.Trigger size={3} icon={<InformationOutline size="xs" />} />
            <PopoverContentStyled>
              <Popover.Item>
                <Badge className="mr-2" variant="soft" color="error">
                  Vencido
                </Badge>
                <span>{formatCentsToReal(totalOverdueAmount)}</span>
              </Popover.Item>
              <Popover.Divider />
              <Popover.Item>
                <Badge className="mr-2" variant="soft" color="accent">
                  A vencer
                </Badge>
                <span>{formatCentsToReal(totalUpToDateAmount)}</span>
              </Popover.Item>
            </PopoverContentStyled>
          </Popover.Root>
        }
        checkoutButton={{
          id: 'agreement-pay-btn',
          loading: loading,
          disabled: disablePayButton,
          onClick: handlePayButtonClick,
        }}
      />
    </>
  )
}
