import { Button } from '@gravity/button'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'

import image from './image.svg'

export const Error = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full gap-8 w-[480px] self-center mx-auto">
      <div className="flex flex-col gap-10">
        <div className="flex items-center justify-center">
          <img src={image} alt="Erro" />
        </div>
        <div className="flex items-center flex-col gap-4 text-center">
          <Heading variant="heading-h1-medium">Algo deu errado</Heading>
          <Text variant="body-1-regular">
            Por favor, verifique sua conexão com a internet ou aguarde um momento e tente novamente.
          </Text>
        </div>
      </div>
      <Button size={2} onClick={() => window.location.reload()}>
        Tentar novamente
      </Button>
    </div>
  )
}
