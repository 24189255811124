import { LayoutTemplate } from '@monorepo/onboarding/LayoutTemplate'
import { Skeleton } from '@gravity/skeleton'
import { FullSkeleton } from './FullSkeleton'

export const LoadingPage = () => {
  return (
    <LayoutTemplate
      title={<Skeleton height={34} width={305} className="mb-2" />}
      description={
        <>
          <Skeleton height={24} fullWidth className="mb-2" />
          <Skeleton height={24} fullWidth />
        </>
      }
      footer={<Skeleton height={40} width={100} />}
    >
      <FullSkeleton />
    </LayoutTemplate>
  )
}
