import styled from 'styled-components'
import { Card } from '@gravity/card'

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.gravity.colors['colors-background-neutral-2']};
  border-radius: ${props => props.theme.gravity.borderRadius[6]};
  padding: ${props => props.theme.gravity.spacing[14]};
  border-style: none;
  gap: ${props => props.theme.gravity.spacing[10]};
`

export const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
`
