import { Select } from '@gravity/select'
import styled from 'styled-components'
import { Skeleton } from '@gravity/skeleton'
import { Card } from '@gravity/card'
import { SeparatorLine } from '../SeparatorLine'
import { Text } from '@gravity/text'

// Containers
export const ContainerCard = styled(Card)`
  padding: ${props => props.theme.gravity.spacing[6]};
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[4]};
  width: 100%;
`

export const ResultAnticipationContainer = styled.div`
  padding-top: ${props => props.theme.gravity.spacing[3]};
  padding-down: none;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[4]};
`

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-end;
`

export const ColumnContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const SeparatorLineStyled = styled(SeparatorLine)`
  margin: 0.5rem;
`

// Components
export const StyledSelect = styled(Select)<{ $fullWith?: boolean }>`
  width: ${props => (props.$fullWith ? '100%' : '300px')};
  margin-bottom: ${props => props.theme.gravity.spacing[3]};
`

export const StyledSkeleton = styled(Skeleton)`
  margin-bottom: ${props => props.theme.gravity.spacing[2]};
`

export const GrayText = styled(Text)`
  color: ${props => props.theme.gravity.colors['colors-text-main-3']};
`
