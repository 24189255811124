import styled from 'styled-components'

export const CardsContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.gravity.spacing[2]};
  align-items: center;
  padding-bottom: ${props => props.theme.gravity.spacing[8]};
  padding-left: ${props => props.theme.gravity.spacing[8]};
  overflow-x: auto;
`

export const Divider = styled.span`
  border-top: 1px solid ${props => props.theme.gravity.colors['colors-border-neutral-3']};
  min-width: 16px;
  height: 2px;
`

export const EnrolledTextContainer = styled.div`
  margin: 24px 32px 32px;
  display: flex;
  gap: ${props => props.theme.gravity.spacing[2]};
  align-items: center;
`
