import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { PayoutReportType } from '@monorepo/report/models/PayoutReportType'
import {
  FetchPayoutDetailsResponseDTO,
  FetchPayoutMonthAggregationRequestDTO,
} from '@monorepo/report/services/report/types'
import { useApiClient } from '@/shared/hooks'
import { reportService } from '@monorepo/report/services/report'

export const usePayoutMonthAggregation = <T extends PayoutReportType>(
  {
    schoolID,
    startDate,
    endDate,
    filter,
    pagination,
    payoutDomain,
  }: FetchPayoutMonthAggregationRequestDTO<T>,
  options?: UseQueryOptions<FetchPayoutDetailsResponseDTO<'isaac-pay'>>
) => {
  const { apiClient } = useApiClient()

  const service = reportService(apiClient.getClients().privateApi)

  return useQuery<FetchPayoutDetailsResponseDTO<'isaac-pay'>>(
    [
      'payout-month-aggregation',
      schoolID,
      startDate,
      endDate,
      payoutDomain,
      'month-aggregation',
      JSON.stringify({ ...filter, ...pagination }),
    ],
    async () => {
      return service.fetchPayoutMonthAggregation({
        schoolID,
        startDate,
        endDate,
        payoutDomain,
        filter,
        pagination,
      })
    },
    options
  )
}
