import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'
import { formOfPaymentTextMap } from './helpers/constants'
import type { FormOfPaymentItemProps } from './types'
import * as S from './styles'
import { RadioGroupItem } from '@gravity/radio'

const FormOfPaymentItem = ({
  formOfPayment,
  isSelected,
  isPaymentFeeEnabled,
  onSelect,
}: FormOfPaymentItemProps) => {
  const { title, subtitle, subtitleWithFee } = formOfPaymentTextMap[formOfPayment]

  return (
    <S.Wrapper>
      <RadioGroupItem
        checked={isSelected}
        value={formOfPayment}
        onClick={() => onSelect(formOfPayment)}
      />
      <div onClick={() => onSelect(formOfPayment)}>
        <Heading variant="heading-h4-medium">{title}</Heading>
        <Text className="mt-4 text-colors-text-main-3" variant="body-1-regular">
          <span
            dangerouslySetInnerHTML={{
              __html: isPaymentFeeEnabled && subtitleWithFee ? subtitleWithFee : subtitle,
            }}
          />
        </Text>
      </div>
    </S.Wrapper>
  )
}

export default FormOfPaymentItem
