import { Button } from '@gravity/button'
import { Dialog } from '@gravity/dialog'
import { Text } from '@gravity/text'

type Props = {
  closeDialog: VoidFunction
  confirmationText?: string
  description: string
  onConfirm: VoidFunction
  title: string
  visible: boolean
}

export const NotAllowedDialog = ({
  onConfirm,
  title,
  description,
  confirmationText = 'Ok, entendi',
  visible,
  closeDialog,
}: Props) => {
  const handleDialogOpenChange = (open: boolean) => {
    if (!open && visible) closeDialog()
  }

  return (
    <Dialog
      open={visible}
      onOpenChange={handleDialogOpenChange}
      size={2}
      backdrop
      title={title}
      content={<Text variant="body-2-regular">{description}</Text>}
      actionButton={
        <Button variant="solid" onClick={onConfirm}>
          {confirmationText}
        </Button>
      }
    />
  )
}
