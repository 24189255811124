import { FilterOptionsType } from '../types'

export enum GuardianDetailsTabs {
  CONTRATOS = 'contratos',
  FATURAS = 'faturas',
  HISTORICO = 'historico',
  RESUMO = 'resumo',
}

export enum InstallmentStatus {
  AGGLUTINATED = 'AGGLUTINATED',
  CANCELED = 'CANCELED',
  DUE_TODAY = 'DUE_TODAY',
  OPEN = 'OPEN',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  PAID_AND_CANCELED = 'PAID_AND_CANCELED',
  PENDING = 'PENDING',
  RENEGOTIATED = 'RENEGOTIATED',
}

export enum InstallmentRowType {
  ENROLLMENT_INSTALLMENT = 'ENROLLMENT_INSTALLMENT',
  INSTALLMENT = 'INSTALLMENT',
  NEGOTIATION = 'NEGOTIATION',
  NEGOTIATION_START = 'NEGOTIATION_START',
}

export enum InstallmentStatusDescription {
  NEGOTIATION_EXPIRED = 'NEGOTIATION_EXPIRED',
  OVERDUE = 'OVERDUE',
  PAYMENT_RECEIVED = 'PAYMENT_RECEIVED',
  PENDING_DOWNPAYMENT = 'PENDING_DOWNPAYMNENT',
  PENDING_ENROLL_PROCESSING = 'PENDING_ENROLL_PROCESSING',
  PENDING_INVOICE_GENERATION = 'PENDING_INVOICE_GENERATION',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
}

export enum ReceivableType {
  ENROLLMENT = 'ENROLLMENT',
  ISAAC_PAY = 'ISAAC_PAY',
  NEGOTIATION = 'NEGOTIATION',
  TUITION = 'TUITION',
}

export enum InstallmentType {
  DOWN_PAYMENT = 'DOWN_PAYMENT',
  ENROLLMENT = 'ENROLLMENT',
  INSTALLMENT = 'INSTALLMENT',
  ISAAC_PAY = 'ISAAC_PAY',
  NEGOTIATION = 'NEGOTIATION',
  RECEIVABLE = 'RECEIVABLE',
  TUITION = 'TUITION',
}

export enum InvoiceErrorDescriptionEnum {
  CEP = 'ZIP_CODE',
  CPF = 'CPF',
  DEFAULT = 'DEFAULT',
  DUE_DATE = 'DUE_DATE',
  EARLY_PAYMENT_DISCOUNT = 'EARLY_PAYMENT_DISCOUNT',
}

export enum GuardiansInstallmentsQueryParamsNameEnum {
  COMPETENCE_DATES = 'installment_competence_dates',
  CONTRACT_ID = 'contract_id',
  CONTRACT_STATUS = 'contract_status',
  DEBT_STATUSES = 'debt_statuses',
  NEGOTIATION_ID = 'negotiation_id',
  PRODUCT_IDS = 'product_ids',
  STATUSES = 'installment_statuses',
  STUDENT_IDS = 'student_ids',
  TYPES = 'installment_types',
  YEARS = 'reference_years',
}

export const INSTALLMENT_STATUSES: FilterOptionsType[] = [
  {
    label: 'Aguardando',
    value: 'PENDING',
  },
  {
    label: 'A vencer',
    value: 'OPEN',
  },
  {
    label: 'Cancelada',
    value: 'CANCELED',
  },
  {
    label: 'Paga',
    value: 'PAID',
  },
  {
    label: 'Paga e cancelada',
    value: 'PAID_AND_CANCELED',
  },
  {
    label: 'Vence hoje',
    value: 'DUE_TODAY',
  },
  {
    label: 'Vencida',
    value: 'OVERDUE',
  },
]

export const INSTALLMENT_TYPES: FilterOptionsType[] = [
  {
    label: 'Pré-matrícula',
    value: 'ENROLLMENT',
  },
  {
    label: 'Mensalidade',
    value: 'TUITION',
  },
  {
    label: 'Negociação',
    value: 'NEGOTIATION',
  },
]

export const INSTALLMENT_CONTRACT_STATUSES: FilterOptionsType[] = [
  {
    label: 'Contrato ativo',
    value: 'OPEN',
  },
  {
    label: 'Contrato inativo',
    value: 'CANCELED',
  },
]

export const INSTALLMENT_ROW_TYPES = {
  [InstallmentRowType.ENROLLMENT_INSTALLMENT]: 'Pré-matrícula',
  [InstallmentRowType.INSTALLMENT]: 'Mensalidade',
  [InstallmentRowType.NEGOTIATION]: 'Negociação',
  [InstallmentRowType.NEGOTIATION_START]: 'Entrada da negociação',
}

export const NEW_INSTALLMENT_ROW_TYPES = {
  [InstallmentRowType.ENROLLMENT_INSTALLMENT]: 'Pré-matrícula',
  [InstallmentRowType.INSTALLMENT]: 'Mensalidade',
  [InstallmentRowType.NEGOTIATION]: 'Negociação',
  [InstallmentRowType.NEGOTIATION_START]: 'Negociação',
}

export const MIN_RECEIVABLES_ALLOWED = 2

export const DEFAULT_QUERY_STALE_TIME = 1000 * 60 * 5
export const DEFAULT_QUERY_RETRY_COUNT = 2
export const NO_QUERY_CACHE = 0

export const guardianDetailsQueries = [
  'guardian-details-installments',
  'guardian-details-contracts',
]

export const MAX_DOWN_PAYMENT_INSTALLMENTS_IN_CART = 1
export const MIN_CART_ITEMS = 1

export const invoicesErrorsPriority: InvoiceErrorDescriptionEnum[] = [
  InvoiceErrorDescriptionEnum.CEP,
  InvoiceErrorDescriptionEnum.CPF,
  InvoiceErrorDescriptionEnum.DUE_DATE,
]

export enum NegotiationType {
  AGGLUTINATION = 'AGGLUTINATION',
  NEGOTIATION_API = 'NEGOTIATION_API',
  NEGOTIATION_API_V2 = 'NEGOTIATION_API_V2',
  RENEGOTIATION = 'RENEGOTIATION',
}

export enum GuardianFinanceSummaryStatus {
  IN_NEGOTIATION = 'IN_NEGOTIATION',
  OPEN = 'OPEN',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
}

export const NEGOTIATION_CHANNEL = {
  SAMUCA: 'Analista isaac',
  'PI-ESCOLA': 'Plataforma isaac (feito pela escola)',
}
