import styled from 'styled-components'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 40% 1fr;
  align-items: flex-start;
  grid-template-rows: fit-content(100%);
  grid-column-gap: ${props => props.theme.gravity.spacing[8]};
  padding: unset;
  margin: unset;
`

export const GuardianNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${props => props.theme.gravity.spacing[6]};
  width: 100%;
  min-width: 0;
  flex-wrap: wrap;
`

export const GuardianNameContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const ContainerCardInfo = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1.5fr 2fr;
  justify-items: flex-start;
  padding-top: 17px;
  gap: ${props => props.theme.gravity.spacing[8]};
  grid-auto-flow: row;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`

export const BoxCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const InfoText = styled(Text)`
  color: ${props => props.theme.gravity.colors['colors-text-main-3']};
`

export const TitleText = styled(Heading)`
  color: ${props => props.theme.gravity.colors['colors-text-main-2']};
  font-size: ${props => props.theme.gravity.fontSize['2xl']};
  font-weight: ${props => props.theme.gravity.fontWeight.medium};
`

export const WrapText = styled(Text)`
  word-break: break-word;
  width: -webkit-fill-available;
`
