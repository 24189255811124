import { EditNotificationProps } from './types'
import { AlertBanner } from '@gravity/alert-banner'

export const EditNotification = ({ isEdit, isNewEditRfNameEnabled }: EditNotificationProps) => {
  const description =
    isNewEditRfNameEnabled && !isEdit
      ? 'Essas informações são essenciais para garantirmos o contato com o responsável financeiro.'
      : 'Essas informações são essenciais para garantirmos o contato com o responsável financeiro. As alterações irão refletir em todos os contratos desse responsável.'

  const title = isNewEditRfNameEnabled
    ? 'Mantenha os dados atualizados'
    : 'Cadastre os dados corretamente'

  const variation = isNewEditRfNameEnabled ? 'info' : 'warning'

  return <AlertBanner title={title} description={description} color={variation} className="p-4" />
}
