import type { AxiosInstance } from 'axios'

import type { GetSchoolEnrollmentCyclesResponse } from './types'

export const schoolEnrollmentCyclesService = (fetcher: AxiosInstance) => {
  return {
    /**
     * Function responsible for get all enrollment cycles for a school
     *
     * @param schoolId School ID
     *
     * @returns SchoolEnrollmentCycles
     */
    getSchoolEnrollmentCycles: async (
      schoolId: string
    ): Promise<GetSchoolEnrollmentCyclesResponse> => {
      const { data } = await fetcher.get<GetSchoolEnrollmentCyclesResponse>(
        `school_enrollment_cycle/${schoolId}`
      )
      return data
    },
  }
}
