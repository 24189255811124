import { AxiosRequestConfig } from 'axios'
import { mapObjIndexed } from 'ramda'
import qs from 'qs'

import { contractsService } from '@/escolas/services/contracts'
import { enrollmentService } from '@monorepo/enrollment/services/enrollmentService'
import { managementService } from '@monorepo/management/services/managementService'

import { paramsToQueryString } from '@/shared/utils'

import {
  AddDiscountsReqBody,
  PreContract,
  Contract,
  Student,
  School,
  Installment,
  Guardian,
  OldProduct as Product,
  APIResponse,
  Invoice,
  Renegotiation,
  Agglutination,
  RenegotiationResponseBody,
  CreateContractPayload,
  Receivable,
  LiquidationPostParams,
  Pagination,
  PresignedURL,
  RequestPresignedURL,
  ContractStatus,
  StudentSchoolMatch,
  ContractOverview,
  LiquidationGetParams,
  LiquidationInfoResponse,
  ReenrollmentStats,
  Discount,
  Checkout,
  Pos,
  StartCheckoutRequestBody,
  Address,
  CheckDuplicationPayload,
  CheckDuplicationResponse,
  CreateCampaignPayload,
  CampaignStatusResponse,
  OnboardingRedirect,
  GuardianStudentsResponse,
  GuardianStudentsPayload,
} from '@/shared/interfaces'

import {
  AgreementSimulation,
  AgreementSimulationParams,
  MakeAgreementBody,
  MakeAgreementResponse,
  DuplicateAgreementRequest,
} from '@/modules/guardians/models/agreement'

import { GetProductClassificationsData } from '@/shared/interfaces/productClassification'

import { mockApiMethods } from '@/utils/api/__mocks__'

import config from '@/config'
import {
  changeContractOwnershipPayload,
  changeContractOwnershipResponse,
} from '@/escolas/hooks/useChangeContractOwnership'
import { InvoiceStatus } from '@/escolas/router/responsavel/[guardianId]/contratos/types'
import { UseGuardiansQueryResponse } from '@/escolas/pages/responsaveis/services/types'
import { guardianService } from '@/escolas/pages/responsaveis/services'
import { ApiClient } from '@/shared/core/api'
import { CheckoutInstallmentsSimulationParams } from '@/modules/guardians/GuardianDetails/features/GuardianDetailsFooter/types'
import { UnifiedInvoice, UnifiedInvoiceRequestBody } from '@/shared/interfaces/unifiedInvoice'
import { creditService } from '@/shared/services/credit'
import type { ListStudentsFilters } from '@/shared/interfaces/student'
import type { ListProductsFilters } from '@/shared/interfaces/product'

export const PUBLIC_DOMAIN = `${config.API.URL}/api/v1`

export type GetContractParams = Partial<{
  include_guardian: boolean
  include_installments: boolean
  include_invoice: boolean
  include_product: boolean
  include_receivables: boolean
  include_signable_document?: boolean
  include_student: boolean
}>

export type GetInvoicesParams = Partial<{
  include_bankSlip: boolean
}>

export type GetInvoicesByIdsParams = {
  ids: uuid[]
  school_id: uuid
}

export type ListDebtsFilters = Partial<{
  name: string
  school_id: uuid
  school_slug: string
  status: ContractStatus
}>

type ListGuardiansFilters = Partial<{
  name: string
}>

type GetReenrollmentStatsParams = Partial<{
  reference_year: string
  school_id: uuid
}>
type WorkingDueDatesParams = {
  date: datestring
  number_due_dates: number
}

type WorkingDateFromNthDayParams = {
  date: datestring
  nth_working_day: number
}
/**
 * @deprecated Prefer use specific services files
 */
export const api = (apiClient: ApiClient, setCorrelationId: (correlationId: string) => void) => {
  const privateApi = apiClient.privateApi
  const privateApiV2 = apiClient.privateApiV2
  const basePrivateApi = apiClient.basePrivateApi
  const publicApi = apiClient.publicApi

  apiClient.attachInterceptors(privateApi, setCorrelationId)
  apiClient.attachInterceptors(basePrivateApi, setCorrelationId)

  return {
    auth: {
      logout: () => publicApi.get(`/auth/logout`),
    },
    checkout: {
      getCheckout: async (id: uuid) =>
        (
          await privateApi.get<APIResponse<Checkout>>(
            `/checkout/${id}?${paramsToQueryString({
              include_receivables: true,
              include_school: false,
            })}`
          )
        ).data.data,
      startCheckout: async (body: StartCheckoutRequestBody) =>
        (await privateApi.post<APIResponse<Checkout>>(`/checkout/`, body)).data.data,
      cancelCheckout: async (id: uuid) => await privateApi.post(`/checkout/${id}/cancel`),

      listPos: async (schoolId: uuid) => {
        return (
          await privateApi.get<APIResponse<Pos[]>>(
            `/school/${schoolId}/get-all-active-pos-by-school`
          )
        ).data.data
      },
    },
    unifiedInvoice: {
      createInvoice: async (body: UnifiedInvoiceRequestBody): Promise<UnifiedInvoice> =>
        (await privateApi.post<APIResponse<UnifiedInvoice>>(`/unified-invoice/`, body)).data.data,
    },
    preContracts: {
      get: async (id: uuid) =>
        (await privateApi.get<APIResponse<PreContract>>(`/pre-contract/${id}`)).data.data,
      save: async (id: uuid, tuition: any) =>
        privateApi.put<APIResponse<any>>(`/pre-contract/${id}/tuition`, tuition),
    },
    contracts: {
      bulkEditDiscountsCreate: async (
        id: uuid,
        params: {
          discounts: Discount[]
          edit_reason?: string
          installment_id: uuid
        },
        schoolID: uuid
      ) =>
        (
          await privateApi.patch<APIResponse<Contract>>(
            `/contract/${id}/edit-discounts?school_id=${schoolID}`,
            params
          )
        ).data.data,
      bulkEditDiscountsInfo: async (
        id: uuid,
        params: {
          discounts: Discount[]
          get_current_amount?: boolean
          installment_id: uuid
        }
      ) =>
        (
          await privateApi.post<
            APIResponse<{
              is_contract_in_flexible_period: boolean
              new_installments: Installment[]
              original_installments: Installment[]
            }>
          >(`/contract/${id}/edit-discounts-info/`, params)
        ).data.data,
      cancelContractInfo: async (id: uuid) =>
        (
          await privateApi.get<APIResponse<Installment[]>>(
            `/contract/${id}/installments-for-revoke`
          )
        ).data.data,
      changeDueDayCreate: async (
        id: uuid,
        params: {
          change_due_month: boolean
          change_reason_additional_information?: string
          due_day: number
          installment_id: uuid
          start_month: datestring
          use_working_dates: boolean
        }
      ) =>
        (await privateApi.patch<APIResponse<Contract>>(`/contract/${id}/change-due-date/`, params))
          .data.data,
      changeDueDayInfo: async (
        id: uuid,
        params: {
          change_due_month: boolean
          due_day: number
          installment_id: uuid
          start_month: datestring
          use_working_dates?: boolean
        }
      ) =>
        (
          await privateApi.get<
            APIResponse<{
              flexible_maximum_due_date: string
              is_contract_in_flexible_period: boolean
              new_installments: Installment[]
              original_installments: Installment[]
            }>
          >(`/contract/${id}/change-due-date/?${paramsToQueryString(params)}`)
        ).data.data,
      changeOwnership: async (
        contractId: uuid,
        changeContractOwnershipPayload: changeContractOwnershipPayload
      ) =>
        (
          await privateApi.post<APIResponse<changeContractOwnershipResponse>>(
            `/contract/${contractId}/change-ownership`,
            changeContractOwnershipPayload
          )
        )?.data.data,
      create: async (contract: CreateContractPayload) =>
        (await privateApi.post<APIResponse<Contract>>(`/contract/`, contract)).data.data,
      ...contractsService(privateApi),
      getOverviewsBySchoolStudent: async (schoolID: any, studentId: any) =>
        (
          await privateApi.get<APIResponse<ContractOverview[]>>(
            `/contract/guardian-overviews/${schoolID}/${studentId}`
          )
        )?.data.data,
      getReenrollmentStats: async (getParams?: GetReenrollmentStatsParams) =>
        (
          await privateApi.get<APIResponse<ReenrollmentStats>>(
            `/contract/reenrollment-stats/?${paramsToQueryString(getParams)}`
          )
        ).data,
      manualSign: async (id: uuid) =>
        (await privateApi.patch<APIResponse<void>>(`/contract/${id}/manual-sign`)).data.data,
      recreateInvoices: async (id: uuid) =>
        (await privateApi.patch<APIResponse<Contract>>(`/contract/${id}/recreate-invoices`)).data
          .data,
      checkDuplication: async (getParams?: CheckDuplicationPayload) =>
        (
          await privateApi.get<APIResponse<CheckDuplicationResponse>>(
            `/contract/check-duplicated?${paramsToQueryString(getParams)}`
          )
        ).data.data,
      createCampaign: async (campaignPayload: CreateCampaignPayload) =>
        await privateApi.post<APIResponse<void>>(`/contract/campaign`, campaignPayload),
      getCampaignStatus: async (school_id: uuid) =>
        (
          await privateApi.get<APIResponse<CampaignStatusResponse>>(
            `/contract/campaign/${school_id}/status`
          )
        ).data,
      effect: async (ids: uuid[]) =>
        await privateApi.post<APIResponse<void>>(`/contract/effect`, { ids }),
      downloadCarne: async (contractId: uuid) => {
        return await publicApi.get<Blob>(`/contract/${contractId}/print-slips`, {
          responseType: 'blob',
          validateStatus: s => s <= 500,
        })
      },
    },
    date: {
      getWorkingDueDates: async (params?: WorkingDueDatesParams) =>
        (await privateApi.post<APIResponse<datestring[]>>(`/date/working-date`, params))?.data,
      getWorkingDateFromNthDay: async (params?: WorkingDateFromNthDayParams) =>
        (
          await privateApi.get<APIResponse<datestring>>(
            `/date/working-date-from-nth-day/${params?.date}/${params?.nth_working_day}`
          )
        ).data,
    },
    agreement: {
      createSimulation: async (
        params: AgreementSimulationParams | CheckoutInstallmentsSimulationParams
      ) => {
        return (
          await privateApi.post<APIResponse<AgreementSimulation[]>>(
            '/agreements-simulations',
            params
          )
        )?.data.data
      },
      makeAgreement: async (body: MakeAgreementBody[]) => {
        return Promise.all<MakeAgreementResponse>(
          body.map(
            async agreement =>
              (
                await privateApi.post<APIResponse<MakeAgreementResponse>>(
                  '/agreements-simulations/payment-plans',
                  agreement
                )
              )?.data.data
          )
        )
      },
      duplicateAgreement: async (params: DuplicateAgreementRequest) => {
        return (
          await privateApi.post<APIResponse<MakeAgreementResponse>>(
            '/agreements-simulations/duplicate',
            params
          )
        )?.data.data
      },
    },
    guardians: {
      create: async (
        guardian: Omit<Guardian, 'address' | 'address_id' | 'created_at' | 'id'>,
        schoolID: uuid
      ) =>
        (await privateApi.post<APIResponse<Guardian>>(`/guardian?school_id=${schoolID}`, guardian))
          .data.data,
      // mover p/ guardianV2
      getListV2: async (params: Record<string, unknown>) =>
        (
          await privateApiV2.get<UseGuardiansQueryResponse>(`/guardians`, {
            params,
            paramsSerializer: p => qs.stringify(p, { arrayFormat: 'comma', skipNulls: true }),
          })
        )?.data,
      get: async (id: uuid) =>
        (await privateApi.get<APIResponse<Guardian>>(`/guardian/${id}`))?.data.data,
      getAddressFromZip: async (zip: cep) =>
        (await privateApi.get<APIResponse<Address>>(`/guardian/address/${zip}`))?.data.data,
      getContracts: async (id: uuid, reference_year: string, school_id: uuid) =>
        (await privateApi.get(`/contract/list/${id}/${school_id}/${reference_year}?per_page=200`))
          ?.data.data,
      getContractsList: async (id: uuid, schoolId: uuid) => {
        const { data } = await privateApi.get<APIResponse<Contract[]>>(
          `guardian/contracts/list?responsible_id=${id}&school_id=${schoolId}`
        )
        return data.data
      },
      getMatriculasApiLegacyEnrollments: async (
        id: uuid,
        reference_year: string,
        school_id: uuid,
        installment_status: string | null = null,
        student_id: uuid | null = null
      ) =>
        (
          await privateApi.get(
            `/enrollment/legacy/${id}/${school_id}/${reference_year}?per_page=200`,
            {
              params: {
                installment_status,
                student_id,
              },
            }
          )
        )?.data.data || [],
      getList: async (schoolID: uuid, p?: Pagination & ListGuardiansFilters) =>
        (
          await privateApi.get<APIResponse<Guardian[]>>(
            `/guardian/?${paramsToQueryString(p)}&school_id=${schoolID}`
          )
        ).data,
      getStudentSchoolMatches: async (id: uuid) =>
        (
          await privateApi.get<APIResponse<StudentSchoolMatch[]>>(
            `/guardian/${id}/student-school-matches`
          )
        )?.data.data,
      update: async (
        guardianId: uuid,
        guardian: Omit<Guardian, 'address' | 'address_id' | 'created_at' | 'id'>,
        schoolID: uuid
      ) =>
        (
          await privateApi.patch<APIResponse<Guardian>>(
            `/guardian/${guardianId}?school_id=${schoolID}`,
            guardian
          )
        )?.data.data,
    },
    guardianStudents: {
      update: async (guardianStudents: GuardianStudentsPayload, schoolID: uuid) =>
        (
          await privateApi.patch<APIResponse<GuardianStudentsResponse>>(
            `/contract/guardian-students?school_id=${schoolID}`,
            {
              guardian_id: guardianStudents.guardian_id,
              guardian_data: guardianStudents.guardian,
              students: guardianStudents.students,
            }
          )
        )?.data.data,
    },
    installments: {
      getList: async () =>
        (await privateApi.get<APIResponse<Array<Installment>>>(`/installment/`)).data,
      get: async (id: uuid, params: AxiosRequestConfig | undefined = undefined) =>
        (await privateApi.get<APIResponse<Installment>>(`/installment/${id}`, params))?.data.data,
      changeDueDayEnrollment: async (
        id: uuid,
        params: {
          due_date: datestring
          reason: string
        }
      ) =>
        (await privateApi.patch<APIResponse<Installment>>(`/installment/${id}/due-date/`, params))
          .data.data,
    },
    invoices: {
      get: async (id: uuid, getParams?: GetInvoicesParams) =>
        (
          await privateApi.get<APIResponse<Invoice>>(
            `/invoice/${id}/?${paramsToQueryString(getParams)}`
          )
        )?.data.data,
      getByIds: async (params?: GetInvoicesByIdsParams) =>
        (
          await privateApi.get<APIResponse<InvoiceStatus[]>>(
            `/invoice/by-ids/?${paramsToQueryString(params)}`
          )
        )?.data.data,
    },
    presigned: {
      uploadFile: (url: string, data?: any, _config?: AxiosRequestConfig) =>
        privateApi.put(url, data, _config),
    },
    products: {
      get: async (id: uuid) =>
        (await privateApi.get<APIResponse<Product>>(`/product/${id}`))?.data.data,
      /**
       * @deprecated Use `src/modules/products/services/products/index.ts instead`
       */
      getList: async (p?: Pagination & ListProductsFilters) =>
        (await privateApi.get<APIResponse<Product[]>>(`/product/?${paramsToQueryString(p)}`)).data,
      getClassifications: async () =>
        (
          await privateApi.get<APIResponse<GetProductClassificationsData>>(
            `/product/classifications`
          )
        ).data,
    },
    receivables: {
      addDiscounts: async (data: AddDiscountsReqBody, schoolID: uuid) =>
        (
          await privateApi.post<APIResponse<Receivable[]>>(
            `/receivable/add-discounts?school_id=${schoolID}`,
            data
          )
        )?.data.data,
      canAddDiscounts: async (data: AddDiscountsReqBody & { schoolId: uuid }) =>
        (
          await privateApi.post<APIResponse<{ is_contract_in_flexible_period: boolean }>>(
            `/receivable/can-add-discounts?school_id=${data.schoolId}`,
            data
          )
        )?.data.data,
      liquidationInfo: async (id: uuid, data: LiquidationGetParams) =>
        (
          await privateApi.get<APIResponse<LiquidationInfoResponse>>(
            `/receivable/${id}/liquidation-info?${paramsToQueryString(data)}`
          )
        )?.data.data,
      manualLiquidation: async (id: uuid, data: LiquidationPostParams, schoolID: uuid) =>
        (
          await privateApi.post<APIResponse<Receivable[]>>(
            `/receivable/${id}/manual-liquidation-with-discounts?school_id=${schoolID}`,
            data
          )
        )?.data.data,
      patchManualLiquidation: async (id: uuid, data: LiquidationPostParams, schoolID: uuid) =>
        (
          await privateApi.patch<APIResponse<Receivable[]>>(
            `/receivable/${id}/manual-liquidation-with-discounts?school_id=${schoolID}`,
            data
          )
        )?.data.data,
      printReceipt: (receivableId: uuid) =>
        privateApi.get(`/receivable/${receivableId}/print-receipt`, {
          responseType: 'blob',
        }),
      renegotiate: async (renegotiation: Renegotiation) =>
        (
          await privateApi.post<APIResponse<RenegotiationResponseBody>>(
            `/receivable/renegotiate`,
            renegotiation
          )
        )?.data.data,
      // eslint-disable-next-line sort-keys
      agglutinate: async (agglutination: Agglutination) =>
        (await privateApi.post<APIResponse<Contract>>(`/receivable/agglutinate`, agglutination))
          ?.data.data,

      changePaymentMethod: async (receivableId: uuid) =>
        (
          await privateApi.post<APIResponse<Receivable>>(
            `/receivable/${receivableId}/change-payment-method`
          )
        )?.data.data,
    },
    schools: {
      downloadFile: (fileName: string) => {
        return privateApi.get<Blob>(`${fileName}`, {
          responseType: 'blob',
          withCredentials: false,
        })
      },
      getBySlug: async (slug: string) =>
        (await privateApi.get<APIResponse<School>>(`/school/slug/${slug}`))?.data.data,
      getList: async (p?: Pagination) =>
        (await privateApi.get<APIResponse<School[]>>(`/school/?${paramsToQueryString(p)}`)).data,
      getPresignedUrl: async (body: RequestPresignedURL) =>
        (await privateApi.post<APIResponse<PresignedURL>>(`/school/signed-url`, body))?.data.data,
      processUploadedFile: async (schoolID: uuid, fileId: uuid, extension: string) =>
        (
          await privateApi.post<APIResponse<string>>(`/school/process/${schoolID}`, {
            id: fileId,
            extension,
          })
        ).data,
    },
    students: {
      getList: async (p?: Pagination & ListStudentsFilters) =>
        (await privateApi.get<APIResponse<Student[]>>(`/student/?${paramsToQueryString(p)}`)).data,
      get: async (id: uuid) =>
        (await privateApi.get<APIResponse<Student>>(`/student/${id}`))?.data.data,
      createOrUpdate: async (student: Partial<Student>, schoolID: uuid) =>
        (await privateApi.post<APIResponse<Student>>(`/student/?school_id=${schoolID}`, student))
          ?.data.data,
      update: async (id: uuid, student: Partial<Student>, schoolID: uuid) =>
        (
          await privateApi.patch<APIResponse<Student>>(
            `/student/${id}?school_id=${schoolID}`,
            student
          )
        )?.data.data,
      updateV2: async (id: uuid, student: Partial<Student>) =>
        (await privateApiV2.patch<APIResponse<Student>>(`/student/${id}`, student))?.data.data,
    },
    credit: creditService(apiClient.getClients().bffApi),
    enrollment: enrollmentService(basePrivateApi),
    guardianV2: guardianService(privateApiV2),
    management: managementService(privateApi),
    selfOnboarding: {
      onboardingRedirect: async (schoolID: uuid) =>
        (
          await privateApi.get<APIResponse<OnboardingRedirect>>(
            `/school/${schoolID}/onboarding-redirect`
          )
        )?.data.data,
    },
  }
}

export default (config.API.MOCK
  ? () =>
      mapObjIndexed(
        r =>
          mapObjIndexed(
            m => async (...args: any) => {
              await new Promise(resolve => setTimeout(resolve, config.API.MOCK_DELAY))

              return (m as any)(...args)
            },
            r as any
          ),
        mockApiMethods
      ) as typeof mockApiMethods
  : api) as typeof api
