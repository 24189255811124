import { useHistory } from 'react-router-dom'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { Button } from '@gravity/button'
import { Grid, GridItem } from '@gravity/grid'
import { Heading } from '@gravity/heading'
import { ArrowRight } from '@gravity/icons'

import { ReportCard } from '../../components/ReportCard'

import { useLayout } from '@/shared/hooks/useLayout'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { UnleashFlags, useJWT, useUnleashFlag } from '@/shared/hooks'

import { isIsaacPaySchool } from '@/shared/utils/isIsaacPaySchool'

import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventIdentifierName } from '@monorepo/account-reports/models/enums/EventIdentifierName.enum'
import { EventPageName } from '@monorepo/account-reports/models/enums/EventPageName.enum'

import { REPORT_NAME } from '../../constants'
import { PaymentReportCard } from '../../components/PaymentReportCard'
import { TicketsSummaryReportCard } from '../../components/TicketsSummaryReportCard'
import { DemonstrativeIRReportCard } from '../../components/DemonstrativeIRReportCard'

import { Container } from './styles'

export const Reports = () => {
  const { push } = useHistory()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { school } = useSelectedSchool()
  const {
    getUserRolesBySchool,
    getBackofficeUserRoles,
    isBackofficeUser,
    hasPayoutReportAccess,
  } = useJWT()

  useLayout({
    enableSideMenu: true,
    enableHeader: true,
    headerTitle: 'Relatórios',
  })

  const isDemonstrativeIRReportEnabled = useUnleashFlag(
    UnleashFlags.REG_1926_ENABLE_DEMONSTRATIVE_IR_REPORT
  )
  const isTeachingMaterialReportEnabled = useUnleashFlag(
    UnleashFlags.ISP_131_ENABLE_TEACHING_MATERIAL_REPORT_PAGE
  )
  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  const isIsaacPay = isIsaacPaySchool(school)

  const handleNavigateToFIDCReport = () => {
    push(`/${school?.slug}/relatorios/validacao-de-contratos`)

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.FIDC_REPORT,
        name: EventDispatcherEvents.NAVIGATION_TO_FIDC_REPORT,
        identifierName: EventIdentifierName.OPEN_CONTRACT_VALIDATION_REPORT_PAGE,
        pageName: EventPageName.REPORTS,
      })
    }
  }

  const goToFamilyRegularizationReport = () => {
    push(`/${school?.slug}/relatorio/${REPORT_NAME['regularizacao-das-familias']}`)

    if (isInitialized)
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.FAMILY_REGULARIZATION_REPORT,
        name: EventDispatcherEvents.BUTTON_CLICK,
        identifierName: EventIdentifierName.OPEN_FAMILY_REGULARIZATION_REPORT_PAGE,
        pageName: EventPageName.REPORTS,
        customProperties: { $Button_name: 'Acessar relatório de regularização das famílias' },
      })
  }

  const goToReceiptsReport = () => {
    push(`/${school?.slug}/relatorio/${REPORT_NAME['visao-de-recebimentos']}`)

    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICK,
        scope: EventDispatcherEventScopes.ISAAC_PAY_INCOME_PANEL,
        identifierName: EventIdentifierName.OPEN_ISAAC_PAY_RECEIVING_PANEL,
        pageName: EventPageName.REPORTS,
        customProperties: { $Button_name: 'Acessar painel de recebimentos isaac pay' },
      })
  }
  const goToTeachingMaterialReport = () => {
    push(`/${school?.slug}/relatorio/${REPORT_NAME['visao-de-vendas-do-material-didatico']}`)

    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICK,
        scope: EventDispatcherEventScopes.TEACHING_MATERIAL_INCOME_PANEL,
        identifierName: EventIdentifierName.OPEN_TEACHING_MATERIAL_REPORT_PAGE,
        pageName: EventPageName.REPORTS,
        customProperties: { $Button_name: 'Acessar painel de vendas do material didático' },
      })
  }

  const goToEnrollmentReport = () => {
    push(`/${school?.slug}/relatorio/${REPORT_NAME.matriculas}`)

    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICK,
        scope: EventDispatcherEventScopes.ENROLLMENT,
        identifierName: EventIdentifierName.OPEN_ENROLLMENT_REPORT_PAGE,
        pageName: EventPageName.REPORTS,
        customProperties: { $Button_name: 'Acessar relatório de matriculas' },
      })
  }

  const canViewIsaacPayIncomePanel = isIsaacPay && hasPayoutReportAccess

  let shouldShowReport =
    getBackofficeUserRoles().has('material_didatico_relatorio_completo') ||
    getBackofficeUserRoles().has('material_didatico_relatorio_basico')

  if (!isBackofficeUser) {
    shouldShowReport =
      getUserRolesBySchool(school?.id ?? '').has('material_didatico_relatorio_completo') ||
      getUserRolesBySchool(school?.id ?? '').has('material_didatico_relatorio_basico')
  }
  const canViewTeachingMaterialIncomePanel = shouldShowReport && isTeachingMaterialReportEnabled

  return (
    <Container>
      <Grid className="grid-container">
        {renderNewHeader && (
          <GridItem className="pb-8">
            <Heading variant="heading-h2-medium">Relatórios</Heading>
          </GridItem>
        )}

        <GridItem xl={6} lg={6} md={6} sm={8} xs={4}>
          <ReportCard
            title="Relatório de matrículas"
            description="As principais informações sobre o desempenho no ciclo de matrículas da instituição."
          >
            <Button variant="ghost" iconEnd={<ArrowRight />} onClick={goToEnrollmentReport}>
              Acessar
            </Button>
          </ReportCard>
        </GridItem>

        {canViewTeachingMaterialIncomePanel && (
          <GridItem xl={6} lg={6} md={6} sm={8} xs={4}>
            <ReportCard
              title="Visão de vendas do material didático"
              description="Visão com as principais informações sobre a venda de material didático."
            >
              <Button variant="ghost" iconEnd={<ArrowRight />} onClick={goToTeachingMaterialReport}>
                Acessar
              </Button>
            </ReportCard>
          </GridItem>
        )}
        {canViewIsaacPayIncomePanel && (
          <GridItem xl={6} lg={6} md={6} sm={8} xs={4}>
            <ReportCard
              title="Visão de recebimentos"
              description="As principais informações sobre recebimentos, atualizadas ao longo do dia"
            >
              <Button variant="ghost" iconEnd={<ArrowRight />} onClick={goToReceiptsReport}>
                Acessar
              </Button>
            </ReportCard>
          </GridItem>
        )}

        <GridItem xl={6} lg={6} md={6} sm={8} xs={4}>
          <PaymentReportCard />
        </GridItem>

        <GridItem xl={6} lg={6} md={6} sm={8} xs={4}>
          <ReportCard
            title="Relatório de regularização das famílias"
            description="As principais informações sobre a inadimplência, cobrança e regularização das famílias."
          >
            <Button
              variant="ghost"
              iconEnd={<ArrowRight />}
              onClick={goToFamilyRegularizationReport}
            >
              Acessar
            </Button>
          </ReportCard>
        </GridItem>

        {hasPayoutReportAccess && <TicketsSummaryReportCard />}

        {isDemonstrativeIRReportEnabled && <DemonstrativeIRReportCard />}

        {!isIsaacPay && (
          <GridItem xl={6} lg={6} md={6} sm={8} xs={4}>
            <ReportCard
              title="Relatório de validação de contratos 2025"
              description="Relatório com a evolução de alunos com contratos 2025 validados no meu isaac."
            >
              <Button variant="ghost" iconEnd={<ArrowRight />} onClick={handleNavigateToFIDCReport}>
                Acessar
              </Button>
            </ReportCard>
          </GridItem>
        )}
      </Grid>
    </Container>
  )
}
