import styled from 'styled-components'
import { Text } from '@gravity/text'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.gravity.spacing[6]};
  gap: ${props => props.theme.gravity.spacing[4]};
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[3]};
`

export const InfoText = styled(Text)`
  color: ${props => props.theme.gravity.colors['colors-text-main-3']};
`

export const InstallmentDrawerActionWrapper = styled.div`
  display: flex;
  gap: ${props => props.theme.gravity.spacing[2]};
`

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[3]};
`
