import qs from 'qs'
import { Button } from '@gravity/button'
import { Dialog } from '@gravity/dialog'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { Text } from '@gravity/text'
import { Separator } from '@gravity/separator'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { formatDate, formatMicroCentsToReal, prettifyFullName } from '@/shared/utils'

import { REPORT_PAGE_NAMES } from '@monorepo/report/constants/REPORT_PAGE_NAMES'
import { PAYOUT_TRANSACTION_EVENTS_LABEL } from '@monorepo/report/constants/PAYOUT_TRANSACTION_EVENTS_LABEL'

import type { PayoutTransactionEvents } from '@monorepo/report/models/PayoutTransactionEvents'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { GrayText, HeaderContainer } from './styles'
import { Heading } from '@gravity/heading'

type Event = {
  event: PayoutTransactionEvents
  reference_date: Date
  total: number
}

type TuitionPayoutReportInstallmentDetailsDrawerProps = {
  contractReferenceYear?: string
  events: Event[]
  feeAmount: number
  guardianId: string
  isOpen: boolean
  onClose: () => void
  product: {
    id: string
    name: string
  }
  referenceDate: Date
  student: {
    id: string
    name: string
  }
  totalAmount: number
  totalExpectedBaseAmount: number
}

/**
 * Tuition payout installment dialog component
 *
 * @param props
 * @param props.contractReferenceYear Installment contract reference year
 * @param props.guardianId Indicates the installment guardian ID
 * @param props.student Object containing student data
 * @param props.product Object containing product data
 * @param props.referenceDate Installment referente date
 * @param props.feeAmount Defines the installment fee value
 * @param props.totalAmount Defines the installment total net value
 * @param props.totalExpectedBaseAmount Defines the installment expected total gross value
 * @param props.events Defines the events presents on the installment
 * @param props.isOpen Indicates if dialog should be opened
 * @param props.onClose Callback function called on request close
 */
export const TuitionPayoutReportInstallmentDetailsDrawer = ({
  contractReferenceYear,
  guardianId,
  student,
  product,
  referenceDate,
  feeAmount,
  totalAmount,
  totalExpectedBaseAmount,
  events,
  isOpen,
  onClose,
}: TuitionPayoutReportInstallmentDetailsDrawerProps) => {
  const { school } = useSelectedSchool()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const handleNavigateToContract = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.PAYOUT_REPORT,
        name: EventDispatcherEvents.TUITION_PAYOUT_VIEW_CONTRACT,
        action: 'click',
        customProperties: {
          $page_name: REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT,
        },
      })

    const route = `/${school?.slug}/responsaveis/${guardianId}/faturas`

    const filtersQueryParams = qs.stringify({
      student_ids: student.id,
      product_ids: product.id,
      reference_years: contractReferenceYear,
    })

    window.open(`${route}?${filtersQueryParams}`, '_blank')
  }

  const filteredEvents = events.reduce<Array<Record<'label' | 'referenceDate' | 'value', string>>>(
    (acc, { event, total, reference_date }) => {
      const label = PAYOUT_TRANSACTION_EVENTS_LABEL[event]

      if (!label || total === 0) return acc

      const value = formatMicroCentsToReal(total)
      const referenceDate = formatDate(reference_date, 'DD/MM/YYYY')

      return [
        ...acc,
        {
          label,
          value,
          referenceDate,
        },
      ]
    },
    []
  )

  const shouldDisplayBaseAmount = totalExpectedBaseAmount > 0
  const shouldDisplayEvents = filteredEvents.length > 0
  const shouldDisplayFee = Math.abs(feeAmount) > 0

  const hasAtLeastOneSection = shouldDisplayBaseAmount || shouldDisplayEvents || shouldDisplayFee

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      onClose()
    }
  }

  const dialogContent = (
    <div className="flex-1 flex flex-col gap-4">
      <HeaderContainer>
        <Heading variant="heading-h4-medium">{prettifyFullName(student.name)}</Heading>
        <GrayText variant="body-1-regular">{product.name}</GrayText>
      </HeaderContainer>
      <div>
        <Separator orientation="horizontal" color="neutral-2" />
      </div>

      <div className="flex flex-row justify-between">
        <Text variant="body-1-medium">Competência</Text>
        <Text variant="body-1-medium">{formatDate(referenceDate, 'MMMM [de] YYYY')}</Text>
      </div>
      <div>
        <Separator orientation="horizontal" color="neutral-2" />
      </div>

      {hasAtLeastOneSection && (
        <div className="flex flex-col gap-4">
          {shouldDisplayBaseAmount && (
            <div className="flex flex-row justify-between">
              {shouldDisplayEvents ? (
                <Text variant="body-1-medium">Valor inicial da mensalidade</Text>
              ) : (
                <Text variant="body-1-medium">Mensalidade</Text>
              )}
              <Text variant="body-1-medium">{formatMicroCentsToReal(totalExpectedBaseAmount)}</Text>
            </div>
          )}

          {shouldDisplayEvents && (
            <div className="flex flex-col gap-2">
              <div className="flex flex-row justify-between">
                <Text variant="body-1-medium">Mudanças em mensalidade</Text>
                <Text variant="body-1-medium">
                  {formatMicroCentsToReal(totalAmount - feeAmount - totalExpectedBaseAmount)}
                </Text>
              </div>

              {filteredEvents.map(({ label, value, referenceDate }) => (
                <div key={label} className="flex flex-col gap-1 pl-2">
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-col">
                      <Text variant="body-1-regular">{label}</Text>
                      <GrayText variant="caption-regular">
                        Data da mudança: {referenceDate}
                      </GrayText>
                    </div>
                    <Text variant="body-1-regular">{value}</Text>
                  </div>
                </div>
              ))}
            </div>
          )}

          {shouldDisplayEvents && (
            <div>
              <Separator orientation="horizontal" color="neutral-2" />
            </div>
          )}

          {shouldDisplayEvents && (
            <div className="flex flex-row justify-between">
              <Text variant="body-1-medium">Subtotal</Text>
              <Text variant="body-1-medium">{formatMicroCentsToReal(totalAmount - feeAmount)}</Text>
            </div>
          )}

          {shouldDisplayFee && (
            <div className="flex flex-row justify-between">
              <Text variant="body-1-medium">Taxa isaac</Text>
              <Text variant="body-1-medium">{formatMicroCentsToReal(feeAmount)}</Text>
            </div>
          )}
        </div>
      )}

      <div>
        <Separator orientation="horizontal" color="neutral-2" />
      </div>
      <div className="flex flex-row justify-between">
        <Text variant="body-1-medium">Valor final</Text>
        <Text variant="body-1-medium">{formatMicroCentsToReal(totalAmount)}</Text>
      </div>
    </div>
  )

  return (
    <Dialog
      open={isOpen}
      onOpenChange={handleOpenChange}
      title=""
      size={2}
      backdrop
      content={dialogContent}
      actionButton={
        <Button fullWidth onClick={handleNavigateToContract}>
          Ir para contrato
        </Button>
      }
    />
  )
}
