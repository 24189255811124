import { useQuery } from '@tanstack/react-query'
import { useOnboardingApi } from '../services/api'
import { GetStudentDetailsParams } from '../services/types'

export const STUDENT_DETAILS_QUERY_KEY = 'student-details'

export const useStudentDetailsQuery = (params: GetStudentDetailsParams) => {
  const api = useOnboardingApi()

  return useQuery([STUDENT_DETAILS_QUERY_KEY, params], async () => {
    const response = await api.getStudentDetails(params)

    return response.data
  })
}
