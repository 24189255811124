import styled from 'styled-components'
import { Card } from '@gravity/card'

export const ReceiptsContainer = styled(Card)`
  display: flex;
  height: 318px;
  padding: 0px;
  gap: ${props => props.theme.gravity.spacing[2]};
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: ${props => props.theme.gravity.borderRadius[4]};
  border: none;
  @media screen and (max-width: 1112px) {
    width: 100%;
  }
`

export const ReceiptsCardContainer = styled(Card)`
  cursor: pointer;
  display: flex;
  width: 100%;
  max-height: 151px;
  padding: ${props => props.theme.gravity.spacing[10]};
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: ${props => props.theme.gravity.borderRadius[4]};
  border: 1px solid ${props => props.theme.gravity.colors['colors-border-neutral-3']};
`

export const RowReceipts = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  gap: ${props => props.theme.gravity.spacing[2]};
`

export const ColumnReceipts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${props => props.theme.gravity.spacing[2]};
`
