import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { IconButton } from '@gravity/icon-button'
import { ArrowLeft, ArrowRight } from '@gravity/icons'

import { StudentsSliderProps } from './types'

export const StudentsSlider = ({
  quantityDots,
  quantityItems,
  anchorEl,
  calcBase,
}: StudentsSliderProps) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const handleNext = () => {
    if (currentIndex < quantityItems - 1 && anchorEl.current) {
      setCurrentIndex(currentIndex + 1)
      anchorEl.current.scrollLeft += calcBase
    }
  }

  const handlePrev = () => {
    if (currentIndex > 0 && anchorEl.current) {
      setCurrentIndex(currentIndex - 1)
      anchorEl.current.scrollLeft -= calcBase
    }
  }

  const handleDotClick = (index: number) => {
    if (anchorEl.current) {
      setCurrentIndex(index)
      anchorEl.current.scrollLeft = index * calcBase
    }
  }

  return (
    <div className="flex flex-row items-center gap-2">
      <IconButton variant="ghost" onClick={handlePrev} disabled={currentIndex === 0} size={1}>
        <ArrowLeft />
      </IconButton>
      <div className="flex items-start gap-3">
        {Array.from({ length: quantityDots }).map((_, index) => (
          <div
            key={`student-dot-${uuidv4()}`}
            onClick={() => handleDotClick(index)}
            style={{
              width: index === currentIndex ? '1rem' : '0.5rem',
              opacity: index === currentIndex ? 1 : 0.14,
            }}
            className="h-2 bg-colors-interaction-primary-1 rounded-full cursor-pointer"
          />
        ))}
      </div>
      <IconButton
        variant="ghost"
        onClick={handleNext}
        disabled={currentIndex === quantityDots - 1}
        size={1}
      >
        <ArrowRight />
      </IconButton>
    </div>
  )
}
