import { pluralize } from '@/shared/utils/pluralize'
import { Text } from '@gravity/text'

export const SelectedInstallmentsText = ({ count }: { count: number }) => (
  <Text variant="body-2-regular">{`${pluralize('parcela', count)} ${pluralize(
    'selecionada',
    count,
    false
  )} `}</Text>
)
