import { useState } from 'react'
import type { StepComponentProps } from '@/modules/guardians/Negotiation/types'
import { useSendCheckoutEvent } from '@/modules/guardians/Negotiation/hooks/useSendCheckoutEvents'

import { useInstallmentsByStudents } from './hooks/useInstallmentsByStudents'

import { useSelectPos } from 'src/escolas/hooks'
import InstallmentsDetail from '../../components/InstallmentDetail/InstallmentDetail'

import * as Styled from './styles'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { Callout } from '@gravity/callout'

const InstallmentsOverview = ({ id, onFinish, goBack }: StepComponentProps) => {
  const [showDiscountBanner, setShowDiscountBanner] = useState<boolean>(true)
  const { installmentsByStudents, totalAmount, hasDiscounts } = useInstallmentsByStudents()

  const showBanner = showDiscountBanner && hasDiscounts

  const { isLoading } = useSelectPos()

  const sendCheckoutEvent = useSendCheckoutEvent()

  function handleGoBack() {
    sendCheckoutEvent(EventDispatcherEntities.CHECKOUT_FIRST_STEP_BACK, {
      name: 'Parcelas selecionadas - Voltar',
      location: id,
    })
    goBack()
  }

  function handleFinish() {
    sendCheckoutEvent(EventDispatcherEntities.CHECKOUT_FIRST_STEP_CONTINUE, {
      name: 'Parcelas selecionadas - Continuar',
      location: id,
    })
    onFinish()
  }

  return (
    <Styled.Container>
      {showBanner && (
        <Callout
          text="Os descontos de negociação concedidos aqui não impactam o repasse da escola."
          linkLabel="Fechar"
          onLinkClick={() => setShowDiscountBanner(false)}
        />
      )}
      <InstallmentsDetail
        goBack={handleGoBack}
        installmentsByStudents={installmentsByStudents}
        isLoading={isLoading}
        onFinish={handleFinish}
        totalAmount={totalAmount}
        stepName={id}
      />
    </Styled.Container>
  )
}

export default InstallmentsOverview
