import { Text } from '@gravity/text'

interface InfoRowProps {
  bold?: boolean
  label: string
  value: string
}

export const InfoRow = ({ bold, label, value }: InfoRowProps) => {
  return (
    <div className="flex justify-between gap-2">
      <Text variant="body-1-regular">{label}</Text>
      <Text variant={bold ? 'body-1-medium' : 'body-1-regular'}>{value}</Text>
    </div>
  )
}
