import { createContext, ReactNode, useContext, useMemo, useState } from 'react'

import { EnrollmentCampaignFormProps } from '@monorepo/enrollment/pages/Campaigns/NewCampaign/components/PaymentConditionsStep/types'
import { useSteps } from '@gravity/stepper'

const TOTAL_STEPS = 4

type CampaignProviderProps = {
  children: ReactNode
}

export type StepNavHistoryType = {
  completed: boolean
  index: number
  visited: boolean
}

type ChosenProductType = {
  id: string
  name: string
}

type CampaignContextType = {
  chosenProducts: ChosenProductType[]
  conditions: EnrollmentCampaignFormProps | null
  customEmailMsg: string
  nonParticipatingStudentsIds: string[]
  setConditions: (conditions: EnrollmentCampaignFormProps) => void
  setCustomEmailMsg: (msg: string) => void
  stepper: Pick<ReturnType<typeof useSteps>, 'activeStep' | 'goToPreviousStep' | 'goToNextStep'>
  totalParticipatingStudents: number
  updateChosenProducts: (products: ChosenProductType[]) => void
  updateNonParticipatingStudentsIds: (students: string[]) => void
  updateTotalParticipatingStudents: (total: number) => void
}

export const CampaignContext = createContext({} as CampaignContextType)
export const useCampaignContext = () => {
  const context = useContext(CampaignContext)
  if (context === undefined) {
    throw new Error('useCampaignContext must be used within a CampaignProvider')
  }
  return context
}

export const CampaignProvider = ({ children }: CampaignProviderProps) => {
  const [conditions, setConditions] = useState<EnrollmentCampaignFormProps | null>(null)
  const [customEmailMsg, setCustomEmailMsg] = useState('')
  const [chosenProducts, setChosenProducts] = useState<ChosenProductType[]>([])
  const [nonParticipatingStudentsIds, setNonParticipatingStudentsIds] = useState<string[]>([])
  const [totalParticipatingStudents, setTotalParticipatingStudents] = useState<number>(0)
  const stepper = useSteps({
    defaultStep: 0,
    count: TOTAL_STEPS,
  })

  const updateChosenProducts = (products: ChosenProductType[]) => {
    setChosenProducts(products)
  }

  const updateNonParticipatingStudentsIds = (students: string[]) => {
    setNonParticipatingStudentsIds(students)
  }

  const updateTotalParticipatingStudents = (total: number) => {
    setTotalParticipatingStudents(total)
  }

  const contextValue: CampaignContextType = useMemo(
    () => ({
      stepper,
      conditions,
      setConditions,
      customEmailMsg,
      setCustomEmailMsg,
      chosenProducts,
      updateChosenProducts,
      nonParticipatingStudentsIds,
      updateNonParticipatingStudentsIds,
      updateTotalParticipatingStudents,
      totalParticipatingStudents,
    }),
    [
      stepper.activeStep,
      conditions,
      customEmailMsg,
      chosenProducts,
      nonParticipatingStudentsIds,
      totalParticipatingStudents,
    ]
  )

  return <CampaignContext.Provider value={contextValue}>{children}</CampaignContext.Provider>
}
