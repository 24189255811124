import { useContext } from 'react'
import { ApiContext } from '@/utils/contexts/apiContext'

/**
 * @deprecated Use `useApiClient` instead to specify the API client and its fetcher.
 *
 * This ensures that you have better control over the API client configuration.
 *
 * Prefer the following approach:
 *
 * @example
 *
 * const { apiClient } = useApiClient();
 * // Using BFF API
 * const service = moduleService(apiClient.getClients().bffApi);
 * // Using Private API
 * const service = moduleService(apiClient.getClients().privateApi);
 */
export const useApi = () => {
  const { correlationId, api, setCorrelationId } = useContext(ApiContext)

  return {
    correlationId,
    setCorrelationId,
    api,
  }
}
