import { useState, useEffect, ReactNode, createContext, useCallback } from 'react'
import {
  EventDispatcherProvider as OlaisaacEventDispatcherProvider,
  useEventDispatcher,
  Environment,
  Realm,
} from '@olaisaac/event-dispatcher-sdk'

import { useJWT } from '@/shared/hooks/useJWT'

import { REALMS } from '@/shared/constants'

import envConfig from '@/config'

import { getUserRole } from '@/modules/app/utils/getUserRole'

type ComponentProps = { children: ReactNode }

/**
 * Custom component to set Event Dispatcher default settings
 */
const EntryComponent = ({ children }: ComponentProps) => {
  const { userId, isAdmin, isGroupManager, hasPayoutReportAccess: isMaintainer } = useJWT()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  useEffect(() => {
    if (isInitialized) {
      eventDispatcherClient.identifyUser({ userId })
      eventDispatcherClient.setGlobalProperties({
        application: 'backoffice',
        environment: envConfig.ENV as Environment,
        realm: (isAdmin ? REALMS.ADMIN_REALM : REALMS.SCHOOL_REALM) as Realm,
        customProperties: {
          $role: getUserRole(isGroupManager, isMaintainer),
        },
      })
    }
  }, [isInitialized, userId, isAdmin, isGroupManager, isMaintainer])

  return <>{children}</>
}

type EventDispatcherContextType = {
  handleChangeIsEnabled: (value: boolean) => void
}

export const EventDispatcherContext = createContext({} as EventDispatcherContextType)

/**
 * Custom provider to setup Event Dispatcher SDK
 */
export const EventDispatcherProvider = ({ children }: ComponentProps) => {
  const [isEnabled, setIsEnabled] = useState(true)

  const handleChangeIsEnabled = useCallback(
    (value: boolean) => {
      setIsEnabled(value)
    },
    [setIsEnabled]
  )

  return (
    <EventDispatcherContext.Provider value={{ handleChangeIsEnabled }}>
      <OlaisaacEventDispatcherProvider
        enabled={isEnabled}
        options={{
          mixpanelDebugMode: envConfig.EVENT_DISPATCHER.MIXPANEL.ENABLE_DEBUG_LOGS === 'true',
          mixpanelProjectToken: envConfig.EVENT_DISPATCHER.MIXPANEL.PROJECT_TOKEN,
        }}
        isMixpanelEnabled={envConfig.EVENT_DISPATCHER.MIXPANEL.ENABLED === 'true'}
      >
        <EntryComponent>{children}</EntryComponent>
      </OlaisaacEventDispatcherProvider>
    </EventDispatcherContext.Provider>
  )
}
