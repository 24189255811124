import { Text } from '@gravity/text'
import { Popover } from '@gravity/popover'
import { More2, InformationOutline } from '@gravity/icons'
import { IconButton } from '@gravity/icon-button'
import { Tooltip } from '@gravity/tooltip'

interface InstallmentDrawerActionsItemProps {
  disabled?: boolean
  helpDescription?: string
  hidden?: boolean
  onClick: () => void
  title: string
}

interface InstallmentDrawerActionsProps {
  items: InstallmentDrawerActionsItemProps[]
}

export const InstallmentDrawerMenu = ({ items }: InstallmentDrawerActionsProps) => {
  const visibleMenuItems = items.filter(({ hidden }) => !hidden)

  return (
    <>
      {visibleMenuItems.length > 0 && (
        <Popover.Root>
          <Popover.Trigger
            size={3}
            icon={<More2 />}
            data-testid="installment-drawer-menu"
            aria-label="Mais opções"
            aria-controls="long-menu"
            aria-haspopup="true"
          />
          <Popover.Content id="long-menu" side="top" align="end" className="z-[1300]">
            {visibleMenuItems.map(({ title, onClick, disabled, helpDescription }) => (
              <Popover.Item key={title} onClick={onClick} disabled={disabled}>
                {helpDescription ? (
                  <div className="flex items-center gap-1">
                    <Text className={disabled ? 'text-colors-text-main-4' : ''}>{title}</Text>
                    <Tooltip text={helpDescription} className="z-[1301]">
                      <IconButton size={1} variant="ghost" disabled={disabled}>
                        <InformationOutline size="xs" />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : (
                  title
                )}
              </Popover.Item>
            ))}
          </Popover.Content>
        </Popover.Root>
      )}
    </>
  )
}
