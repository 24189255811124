import dayjs from 'dayjs'
import ConfirmationDialog from '@/shared/components/ConfirmationDialog'
import { useHistory, useParams } from 'react-router-dom'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { Text } from '@gravity/text'
import { Link } from '@gravity/link'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventPageName } from '@/shared/models/enums/EventPageName.enum'
import { EventIdentifierName } from '@/shared/models/enums/EventIdentifierName.enum'

enum EventModalType {
  CLOSE = 'Fechar',
  INVOICES = 'Faturas',
  REPORTS = 'Relatórios',
}

export const IncomeTaxStatementDialog = ({
  setIsOpen,
  onClose,
}: {
  onClose: () => void
  setIsOpen: (isOpen: boolean) => void
}) => {
  const { guardianId } = useParams<{ guardianId: string }>()

  const { schoolSlug } = useSelectedSchool()

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const history = useHistory()

  const sendModalClickedEvent = (
    type: EventModalType,
    event: string,
    name = EventDispatcherEvents.BUTTON_CLICKED
  ) => {
    if (isInitialized)
      eventDispatcherClient.sendEvent({
        name,
        scope: EventDispatcherEventScopes.INCOME_TAX_STATEMENT,
        pageName: EventPageName.CONTRACTS,
        identifierName: event,
        customProperties: {
          $modal_name: 'Demonstrativos de pagamento',
          $type: type,
        },
      })
  }

  const sendLinkClickedEvent = () => {
    if (isInitialized)
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.LINK_CLICKED,
        scope: EventDispatcherEventScopes.INCOME_TAX_STATEMENT,
        pageName: EventPageName.CONTRACTS,
        identifierName: EventIdentifierName.MODAL_INCOME_TAX_STATEMENTS_GO_TO_TUTORIAL_PAGE,
        customProperties: {
          $link_name: 'Tutorial Imposto de Renda',
        },
      })
  }

  const goToReportsPage = () => {
    sendModalClickedEvent(
      EventModalType.REPORTS,
      EventIdentifierName.MODAL_INCOME_TAX_STATEMENTS_GO_TO_REPORTS_PAGE
    )

    history.push({
      pathname: `/${schoolSlug}/relatorios`,
    })

    setIsOpen(false)
    onClose()
  }

  const goToInstallmentsPage = () => {
    sendModalClickedEvent(
      EventModalType.INVOICES,
      EventIdentifierName.MODAL_INCOME_TAX_STATEMENTS_GO_TO_INSTALLMENTS_PAGE
    )

    history.push({
      pathname: `/${schoolSlug}/responsaveis/${guardianId}/faturas`,
    })

    setIsOpen(false)
    onClose()
  }

  const handleOnClose = () => {
    sendModalClickedEvent(
      EventModalType.CLOSE,
      EventIdentifierName.MODAL_INCOME_TAX_STATEMENTS_CLOSE
    )

    setIsOpen(false)
    onClose()
  }

  const handleGoToTutorialPage = () => {
    sendLinkClickedEvent()

    setIsOpen(false)
    onClose()
  }

  const currentReferenceYear = dayjs().subtract(1, 'year').year()

  return (
    <ConfirmationDialog
      isVisible
      onClose={handleOnClose}
      onCancel={goToInstallmentsPage}
      submitHandler={goToReportsPage}
      title="Demonstrativos de pagamento"
      backButtonLabel="Acessar faturas"
      buttonLabel="Acessar relatórios"
      size={2}
    >
      <div className="flex flex-col gap-4">
        <Text variant="body-2-regular" data-testid="income-tax-statement-dialog-text-1">
          {`Os `}
          <strong>demonstrativos para a Declaração de Imposto de Renda </strong>
          <strong>{currentReferenceYear}</strong>
          {` já estão disponíveis!`}
        </Text>
        <Text variant="body-2-regular" data-testid="income-tax-statement-dialog-text-2">
          {`Clique no botão `}
          <strong>"Acessar relatórios"</strong>
          {` para baixar os `}
          <strong>Demonstrativos de valores pagos</strong>
          {` em ${currentReferenceYear}. Além
            disso, os responsáveis também podem `}
          <strong>acessá-los pelo app meu isaac</strong>
          {`. Caso tenha dúvidas de
            como realizar esse download, basta seguir este `}{' '}
          <Link
            href="https://centraldeajuda.olaisaac.io/apoio-isaac-para-respons%C3%A1veis/como-emitir-o-demonstrativo-de-pagamento"
            target="_blank"
            rel="noreferrer"
            underline="none"
            onClick={handleGoToTutorialPage}
            size={2}
          >
            passo a passo
          </Link>
          {` que explica como fazê-lo no
            app meu isaac ou na Plataforma isaac.`}
        </Text>
        <Text variant="body-2-regular" data-testid="income-tax-statement-dialog-text-3">
          {`Se você precisa do documento para `}
          <strong>conferência ou comprovação de pagamento</strong>
          {`, recomendamos
            que gere um comprovante de pagamento. Para isso, `}
          <strong>acesse a página "Faturas"</strong>
          {` do
            responsável e clique em `}
          <strong>"Gerar comprovante"</strong>
          {` de uma fatura paga.`}
        </Text>
      </div>
    </ConfirmationDialog>
  )
}
