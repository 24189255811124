import styled from 'styled-components'
import { Add } from '@gravity/icons'

export const WrapperList = styled.div`
  display: flex;
  flex-direction: column;
`

export const WrapperIcon = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const StyledAddIcon = styled(Add)`
  background-color: ${({ theme }) => theme.primitiveTokens.colors.gray[3]};
  border: 15px solid ${({ theme }) => theme.primitiveTokens.colors.gray[3]};
  border-radius: 50%;
  margin-top: -21px;
  margin-bottom: -21px;
  box-sizing: initial;
`
