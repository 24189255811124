import { FinancialCategories } from '@monorepo/report/models/FinancialCategories'

/**
 * Constant that defines the titles to each financial category
 */
export const FINANCIAL_CATEGORIES_LABELS: Record<FinancialCategories, string> = {
  TRANSACTIONS: 'Mensalidades',
  FEE: 'Taxa isaac',
  EVENTS: 'Mudanças em mensalidade',
  OPERATIONAL_ADJUSTMENTS: 'Ajustes operacionais',
  UNRECONCILED_PAYMENTS: 'Pagamentos não conciliados',
  BONUS_AND_INCENTIVES: 'Bônus e incentivos',
  BALANCE_TRANSFER: 'Repasse negativo de matrículas',
  PAST_TUITION_DEBTS: 'Repasse negativo de mensalidades',
  CREDIT: 'Crédito isaac',
  CONTRACTUAL_AGREEMENT: 'Acordos contratuais',
  ADDITIONAL_INCOME: 'Recebimentos adicionais',
  ADDITIONAL_DEDUCTIONS: 'Descontos adicionais',
}
