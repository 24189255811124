import { useHistory } from 'react-router-dom'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { LayoutTemplate } from './components/LayoutTemplate'
import { Stepper, useSteps } from '@gravity/stepper'

enum StepName {
  CATEGORY = 'CATEGORY',
  DETAILS = 'DETAILS',
  PAYMENT = 'PAYMENT',
  REVIEW = 'REVIEW',
}

const Category = () => <div>Category</div>
const Details = () => <div>Details</div>
const Payment = () => <div>Payment</div>
const Review = () => <div>Review</div>

type StepProps = {
  component: () => JSX.Element
  key: StepName
  title: string
}

const steps: StepProps[] = [
  {
    key: StepName.CATEGORY,
    title: 'Categorias',
    component: Category,
  },
  {
    key: StepName.DETAILS,
    title: 'Dados do produto',
    component: Details,
  },
  {
    key: StepName.PAYMENT,
    title: 'Plano de pagamento',
    component: Payment,
  },
  {
    key: StepName.REVIEW,
    title: 'Revisão',
    component: Review,
  },
]

export const CreateProduct = () => {
  const history = useHistory()
  const { schoolSlug } = useSelectedSchool()
  const navigateToProducts = () => history.push(`/${schoolSlug}/produtos`)
  const { activeStep, goToNextStep, goToPreviousStep } = useSteps({ count: steps.length })
  const ActiveStepComponent = steps[activeStep].component

  return (
    <LayoutTemplate
      onClose={navigateToProducts}
      title="Novo Produto"
      primaryButton={{
        label: 'Próximo',
        onClick: goToNextStep,
      }}
      secondaryButton={{
        label: 'Anterior',
        onClick: goToPreviousStep,
      }}
      ghostButton={{
        label: 'Cancelar',
        onClick: navigateToProducts,
      }}
    >
      <Stepper.Root activeStep={activeStep} className="items-start">
        {steps.map((step, index) => (
          <Stepper.Step className="flex-1" key={step.key} step={index}>
            <Stepper.StatusIcon />
            <Stepper.Title>{step.title}</Stepper.Title>
          </Stepper.Step>
        ))}
      </Stepper.Root>

      <ActiveStepComponent />
    </LayoutTemplate>
  )
}
