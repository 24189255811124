import { Button } from '@gravity/button'
import { Grid, GridItem } from '@gravity/grid'
import { useHistory } from 'react-router-dom'
import { DashedArrowRight, DashedArrowLeft } from '@gravity/icons'

import * as Styled from './styles'
import type { PageFooterProps } from './types'

export const PageFooter = ({
  goBackBtnDisabled,
  goToNextStepBtnDisabled = false,
  onClickNextBtn,
  onClickGoBack,
  SubmitButton,
}: PageFooterProps) => {
  const history = useHistory()
  const handleCancelClick = history.goBack
  return (
    <Styled.FooterSpaceClearance>
      <Styled.Wrapper>
        <Grid>
          <GridItem xs={4} sm={8} md="4 / 10" lg="4 / 10" xl="4 / 10">
            <Styled.Content>
              <Button
                variant="outline"
                onClick={handleCancelClick}
                aria-label="Cancelar troca de titularidade"
              >
                Cancelar
              </Button>

              <Styled.ButtonsWrapper>
                <Button
                  disabled={goBackBtnDisabled}
                  variant="outline"
                  iconStart={<DashedArrowLeft aria-hidden="true" role="img" />}
                  aria-label="Voltar para o step anterior"
                  onClick={onClickGoBack}
                >
                  Voltar
                </Button>

                {SubmitButton || (
                  <Button
                    disabled={goToNextStepBtnDisabled}
                    aria-label="Avançar para o próximo step"
                    variant="solid"
                    iconEnd={<DashedArrowRight aria-hidden="true" role="img" />}
                    onClick={onClickNextBtn}
                  >
                    Próximo
                  </Button>
                )}
              </Styled.ButtonsWrapper>
            </Styled.Content>
          </GridItem>
        </Grid>
      </Styled.Wrapper>
    </Styled.FooterSpaceClearance>
  )
}
