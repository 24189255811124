import { useMemo, useRef } from 'react'
import { DatepickerPrimitives as Datepicker, formatInterval } from '@gravity/datepicker'
import { CalendarEventOutline } from '@gravity/icons'

import { Container, StyledDatepickerTrigger } from '@monorepo/report/components/DatePicker/styles'

export type DetailType = 'year' | 'decade'

export type BaseItem = {
  ended_at: Date
  started_at: Date
}

type WeekPickerProps<T> = {
  keyExtractor: (item: T) => string
  maxDate?: Date
  maxDetail?: DetailType
  minDate?: Date
  minDetail?: DetailType
  onChange: (item: T) => void
  options?: T[]
  value: T
  variation?: 'standard' | 'ghost'
}

/**
 * Componente personalizado para seleção de semanas usando gravity/datepicker
 *
 * @param props
 * @param props.variation Variação do gatilho do seletor
 * @param props.labelSize Tamanho do rótulo
 * @param props.maxDate Data máxima permitida
 * @param props.minDate Data mínima permitida
 * @param props.maxDetail Define o nível máximo de detalhe da data
 * @param props.minDetail Define o nível mínimo de detalhe da data
 * @param props.value Array com datas selecionadas [dataInicio, dataFim]
 * @param props.onChange Função de callback para mudança de valor
 * @param props.Options Opções personalizadas de intervalos
 */
export const WeekPicker = <T extends Record<string, any> & BaseItem>({
  value,
  onChange,
  variation = 'standard',
  options,
  maxDate,
  minDate,
  maxDetail = 'year',
  minDetail,
}: WeekPickerProps<T>) => {
  const openPickerButtonRef = useRef<HTMLButtonElement | null>(null)
  const handleSelectItem = (item: T) => {
    const option = options?.find(
      option => option.started_at === item.started_at && option.ended_at === item.ended_at
    )
    if (option) {
      onChange(option)
    }
  }

  const weekLabel = useMemo(() => {
    if (!value) {
      return 'Selecione uma semana'
    }

    return formatInterval({
      start: value.started_at,
      end: value.ended_at,
    })
  }, [value])

  return (
    <Container>
      <Datepicker.Root>
        {variation === 'standard' ? (
          <Datepicker.Trigger
            ref={openPickerButtonRef}
            size={3}
            variant="surface"
            placeholder="Selecione uma semana"
          >
            {weekLabel}
            <CalendarEventOutline />
          </Datepicker.Trigger>
        ) : (
          <StyledDatepickerTrigger
            ref={openPickerButtonRef}
            size={3}
            variant="surface"
            placeholder="Selecione uma semana"
          >
            {weekLabel}
            <CalendarEventOutline />
          </StyledDatepickerTrigger>
        )}

        {value && (
          <Datepicker.Calendar
            value={[value.started_at, value.ended_at]}
            minDate={minDate}
            maxDate={maxDate}
            maxDetail={maxDetail}
            minDetail={minDetail}
            onChange={date => {
              if (Array.isArray(date)) {
                const interval = {
                  started_at: date?.[0],
                  ended_at: date?.[1],
                }
                handleSelectItem(interval as T)
              }
            }}
            customIntervalOptions={options?.map(date => ({
              start: date.started_at,
              end: date.ended_at,
            }))}
          />
        )}
      </Datepicker.Root>
    </Container>
  )
}
