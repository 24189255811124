import { useCallback, useEffect } from 'react'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { Button } from '@gravity/button'
import { useHistory } from 'react-router-dom'
import { debounce } from 'throttle-debounce'
import { DialogPrimitives } from '@gravity/dialog'
import { useToast } from '@gravity/toast'

import { formatCentsToReal, formatDate } from '@/shared/utils'
import { useCampaignContext } from '@monorepo/enrollment/contexts/CampaignContext'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { HotjarEvents, useHotjar } from '@/shared/hooks/useHotjar'
import { useEnrollmentMixpanelEvents } from '@monorepo/enrollment/hooks/useEnrollmentMixpanelEvents'
import { useDialogVisibility } from '@monorepo/enrollment/hooks/useDialogVisibility'

import { useCreateCampaign } from './hooks/useCreateCampaign'
import * as Styled from './styles'
import {
  ArticleOutline,
  BookOpenOutline,
  CalendarEventOutline,
  DashedArrowLeft,
  UserOutline,
} from '@gravity/icons'
import theme from '@/shared/theme'
import { Footer } from '@gravity/footer'
import { NewCampaignStepProps } from '../../types'

export const ReviewStep = ({ enrollmentCycle }: NewCampaignStepProps) => {
  const { stepper, conditions, chosenProducts, totalParticipatingStudents } = useCampaignContext()
  const offers = conditions?.offers ? conditions.offers : []

  const { closeDialog, dialogVisible, openDialog } = useDialogVisibility()
  const { createCampaign, isLoading } = useCreateCampaign(enrollmentCycle)
  const { replace } = useHistory()
  const { schoolSlug } = useSelectedSchool()
  const { toast } = useToast()
  const { sendHotjarEvent } = useHotjar()
  const {
    sendPageViewEvent,
    sendButtonClickEvent,
    sendModalViewEvent,
  } = useEnrollmentMixpanelEvents()

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const createCampaignFlow = useCallback(async () => {
    try {
      await createCampaign()
      toast({
        title: 'Campanha criada com sucesso!',
        type: 'success',
        duration: 5000,
        action: 'Action',
      })
      sendHotjarEvent(HotjarEvents.CAMPAIGN_CREATED)
      replace(`/${schoolSlug}/campanhas/${enrollmentCycle.reference_year}`)
    } catch (_) {}
  }, [schoolSlug, enrollmentCycle])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const debouncedCreateCampaignFlow = useCallback(debounce(500, createCampaignFlow), [
    createCampaignFlow,
  ])

  const handleDialogSubmit = () => {
    sendButtonClickEvent('confirmar_criacao')
    closeDialog()
    debouncedCreateCampaignFlow()
  }

  const handleDialogOpenChange = (open: boolean) => {
    sendModalViewEvent('deseja_iniciar')
    if (!open && dialogVisible) closeDialog()
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    sendPageViewEvent('REVISAO_CAMPANHA')
  }, [])

  return (
    <Styled.Wrapper>
      <Heading variant="heading-h3-medium">Revisão</Heading>
      <Styled.SubtitleWrapper>
        <Text variant="body-1-regular">
          Confira as configurações gerais da sua campanha e, se necessário, edite antes de iniciar.
        </Text>
      </Styled.SubtitleWrapper>

      <Styled.Row>
        <Styled.Box style={{ flex: 3 }}>
          <BookOpenOutline />
          <Text variant="body-2-regular">{`Produto ${enrollmentCycle.reference_year} da campanha`}</Text>
          <Heading variant="heading-h4-medium">{`${conditions?.product?.name}`}</Heading>
        </Styled.Box>

        <Styled.Box>
          <CalendarEventOutline />
          <Text variant="body-2-regular">Data de validade</Text>
          <Heading variant="heading-h4-medium">
            {conditions?.end_date
              ? formatDate(conditions.end_date, 'DD/MM/YYYY')
              : 'Não disponível'}
          </Heading>
        </Styled.Box>

        <Styled.Box>
          <UserOutline />
          <Text variant="body-2-regular">Alunos</Text>
          <Heading variant="heading-h4-medium">{totalParticipatingStudents.toString()}</Heading>
        </Styled.Box>
      </Styled.Row>

      <Styled.Row style={{ marginTop: theme.gravity.spacing[6] }}>
        <Styled.Box>
          <CalendarEventOutline />
          <Text variant="body-2-regular">Produtos de origem</Text>
          {chosenProducts.map((product, index) => (
            <Heading key={index} variant="heading-h4-medium">
              {product.name}
            </Heading>
          ))}
        </Styled.Box>
      </Styled.Row>

      <Styled.Row style={{ marginTop: theme.gravity.spacing[6] }}>
        {offers.map((offer, index) => (
          <Styled.Box key={index} style={{ maxWidth: '33.33%' }}>
            <ArticleOutline />
            <Text variant="body-2-regular">{`Condição de pagamento ${index + 1}:`}</Text>
            <Text variant="body-2-regular">Valor da pré-matrícula</Text>
            <Heading variant="heading-h4-medium">{formatCentsToReal(offer.amount ?? 0)}</Heading>
            <Text variant="body-2-regular">Para pagamento até:</Text>
            <Heading variant="heading-h4-medium">
              {offer?.expirationDate
                ? formatDate(offer.expirationDate, 'DD/MM/YYYY')
                : 'Não disponível'}
            </Heading>
          </Styled.Box>
        ))}
      </Styled.Row>

      <Footer
        secondaryButton={{
          label: 'Anterior',
          iconStart: <DashedArrowLeft />,
          onClick: stepper.goToPreviousStep,
        }}
        primaryButton={{
          label: 'Iniciar campanha',
          onClick: () => {
            openDialog()
            sendButtonClickEvent('iniciar_campanha')
          },
          loading: isLoading,
        }}
      />

      <DialogPrimitives.Root open={dialogVisible} onOpenChange={handleDialogOpenChange}>
        <DialogPrimitives.Overlay backdrop />
        <DialogPrimitives.Content
          size={1}
          description="Ao confirmar, o isaac enviará os e-mails, e as condições estarão disponíveis no meu isaac para os responsáveis participantes da campanha."
          title="Deseja iniciar a campanha?"
          actionButton={
            <Button variant="solid" onClick={handleDialogSubmit}>
              Sim
            </Button>
          }
          cancelButton={
            <Button
              variant="ghost"
              onClick={() => {
                closeDialog()
                sendButtonClickEvent('cancelar_criacao')
              }}
            >
              Cancelar
            </Button>
          }
        />
      </DialogPrimitives.Root>
    </Styled.Wrapper>
  )
}
