import { PaymentDisputeSummaryState } from '@/shared/models/enums/PaymentDisputeSummaryState.enum'

export const DEFAULT_PAGE = 1
export const DEFAULT_PER_PAGE = 10

export const DEFAULT_SORT_ORDER = 'ASC'
export const DEFAULT_SORT_BY = 'created_at'

export const DEFAULT_PER_PAGE_OPTIONS = [
  {
    label: '10 por página',
    value: '10',
  },
  {
    label: '20 por página',
    value: '20',
  },
  {
    label: '30 por página',
    value: '30',
  },
  {
    label: '40 por página',
    value: '40',
  },
  {
    label: '50 por página',
    value: '50',
  },
]

export const DEFAULT_PAYMENT_DISPUTE_STATUS_FILTERS = [
  PaymentDisputeSummaryState.NOT_STARTED,
  PaymentDisputeSummaryState.STARTED,
]
