import { useParams } from 'react-router-dom'
import { LayoutTemplate } from '../../components/LayoutTemplate'
import { useBatchSummaryQuery as useBatchSummaryQueryV1 } from '@monorepo/onboarding/hooks/useBatchSummaryQuery'
import { Text } from '@gravity/text'
import { RefreshOutline } from '@gravity/icons'
import { BigNumber } from '../../components/BigNumber'
import { Loading } from '../../components/Loading'
import { GenericError } from '../../components/GenericError'
import { Heading } from '@gravity/heading'
import { OverrideBatch } from '@monorepo/onboarding/pages/components/OverrideBatch'
import { useState } from 'react'
import { StartMigrationDialog } from './StartMigrationDialog'

const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
  }).format(value)
}

export const SummaryPage = () => {
  const { batchID } = useParams<{ batchID: string }>()
  const { data, isLoading, isError } = useBatchSummaryQueryV1({ batchID })
  const [isOverrideBatchOpen, setIsOverrideBatchOpen] = useState(false)
  const [isStartMigrationDialogOpen, setIsStartMigrationDialogOpen] = useState(false)

  // TODO: Change this to the appropriate function
  const navigateToSomewhere = () => {
    console.log('navigate')
  }

  if (isLoading || !data) {
    return <Loading />
  }

  if (isError) {
    return <GenericError />
  }

  const { student_count, total_amount, total_discount_amount } = data

  return (
    <LayoutTemplate
      title="Ferramenta de importação"
      onClose={() => {
        navigateToSomewhere()
      }}
      secondaryButton={{
        label: 'Substituir planilha',
        iconStart: <RefreshOutline />,
        onClick: () => {
          setIsOverrideBatchOpen(true)
        },
      }}
      primaryButton={{
        label: 'Iniciar importação',
        onClick: () => {
          setIsStartMigrationDialogOpen(true)
        },
      }}
    >
      <div className="flex flex-col gap-8">
        <Text>
          Verifique os valores de receita, desconto e os alunos a serem importados para a Plataforma
          isaac.
        </Text>

        <div className="flex gap-14">
          <BigNumber
            variant="soft"
            color="success"
            badgeText="Receita"
            headingText={
              <div className="flex flex-row gap-2 items-center">
                <Heading variant="heading-h4-medium">R$</Heading>
                <Heading variant="heading-h2-medium">{formatCurrency(total_amount)}</Heading>
              </div>
            }
          />

          <BigNumber
            variant="soft"
            color="error"
            badgeText="Desconto"
            headingText={
              <div className="flex flex-row gap-2 items-center">
                <Heading variant="heading-h4-medium">R$</Heading>
                <Heading variant="heading-h2-medium">
                  {formatCurrency(total_discount_amount)}
                </Heading>
              </div>
            }
          />

          <BigNumber
            variant="soft"
            color="neutral"
            badgeText="Alunos"
            headingText={student_count}
          />
        </div>
      </div>

      <OverrideBatch open={isOverrideBatchOpen} onClose={() => setIsOverrideBatchOpen(false)} />

      <StartMigrationDialog
        open={isStartMigrationDialogOpen}
        setOpen={setIsStartMigrationDialogOpen}
      />
    </LayoutTemplate>
  )
}
