import { Heading } from '@gravity/heading'
import { Container, StyledCard } from './styled'
import { ContainerCardsProps } from './types'
import { GridItem } from '@gravity/grid'

export const ContainerCards = ({ cards, title }: ContainerCardsProps) => {
  return (
    <GridItem>
      <StyledCard>
        <Heading variant="heading-h2-medium">{title}</Heading>
        <Container>
          {cards.map(card => {
            return card
          })}
        </Container>
      </StyledCard>
    </GridItem>
  )
}
