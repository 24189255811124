import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useGetEnrollmentRequestsQuery } from '@/shared/hooks/queries/enrollmentRequests'
import { IrregularStudentStatusFilter } from '@monorepo/enrollment/pages/IrregularStudentEnrollments/types'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventIdentifierName } from '@/shared/models/enums/EventIdentifierName.enum'
import { EventPageName } from '@monorepo/school-home/models/EventPageName.enum'

export type GuardianAlertBannerInfos = {
  description?: string
  linkLabel?: string
  onLinkClick?: () => void
  redirectLink?: string
  shouldShowAlertBanner: boolean
}

const hasPendingIrregularities = (
  guardianTaxId: string,
  isErrorFetchingGuardian: boolean,
  isLoadingFetchingGuardian: boolean
): boolean => {
  const { school } = useSelectedSchool()
  const { data, isLoading, isError } = useGetEnrollmentRequestsQuery(
    guardianTaxId,
    school?.id || '',
    [IrregularStudentStatusFilter.NOT_STARTED, IrregularStudentStatusFilter.IN_PROGRESS],
    {
      enabled: !isErrorFetchingGuardian && !isLoadingFetchingGuardian,
    }
  )

  if (isLoading || isError) {
    return false
  }

  if (!data?.data) {
    return false
  }

  return data.pagination?.total !== undefined && data.pagination.total > 0
}

export const useGuardianAlertBannerInfos = (
  guardianTaxId: string,
  isErrorFetchingGuardian: boolean,
  isLoadingFetchingGuardian: boolean
): GuardianAlertBannerInfos => {
  const isNewUXPendenciesPageEnabled = useUnleashFlag(UnleashFlags.INT_597_NEW_UX_PENDENCIES_PAGE)
  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)

  if (!isNewUXPendenciesPageEnabled || !isIntegratedSchool) {
    return { shouldShowAlertBanner: false }
  }

  const { school } = useSelectedSchool()

  if (
    !hasPendingIrregularities(guardianTaxId, isErrorFetchingGuardian, isLoadingFetchingGuardian)
  ) {
    return { shouldShowAlertBanner: false }
  }

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const clickEvent = () => {
    if (!isInitialized) return
    return eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.INTEGRATIONS,
      pageName: EventPageName.GUARDIAN,
      name: EventDispatcherEvents.LINK_CLICKED,
      identifierName: EventIdentifierName.REDIRECT_FIX_PENDING_ISSUES,
      customProperties: {
        $link_name: 'Corrigir pendências',
      },
    })
  }

  const link = `/${school?.slug}/irregularidades`

  return {
    shouldShowAlertBanner: true,
    description:
      'Este responsável possui pendências de registro, e os alunos vinculados a ele estão estudando sem boletos de mensalidade.',
    redirectLink: link,
    linkLabel: 'Corrigir pendências',
    onLinkClick: clickEvent,
  }
}
