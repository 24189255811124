import dayjs from 'dayjs'
import { Text } from '@gravity/text'
import { formatCentsToReal, isDayBeforeToday } from 'src/shared/utils'
import { prettifyFullName } from 'src/shared/utils/namePrettifiers'
import { useAgreement } from 'src/escolas/contexts/agreementContext'
import type { AgreementDetailDrawerProps } from './types'
import { usePaymentCreditCard } from '@/escolas/hooks'
import {
  ContentContainer,
  ItemSummary,
  SeparatorSummary,
  TotalValue,
} from '../InstallmentNegotiationDialog/styles'
import { Separator } from '@gravity/separator'
import { Dialog } from '@gravity/dialog'

const AgreementDetailDialog = ({
  onClose,
  isOpen,
  creditCardFee,
  totalAmount,
}: AgreementDetailDrawerProps) => {
  const { agreementSimulations, positionAgreement } = useAgreement()
  const { isCreditCardFlow } = usePaymentCreditCard()

  const agreement = agreementSimulations?.[positionAgreement]
  const studentName = agreement?.receivables?.[0]?.student ?? ''
  const productName = agreement?.receivables?.[0]?.product ?? ''

  const discounts = agreement
    ? agreement.due_payment_discount_applied +
      agreement.early_payment_discount_applied +
      agreement.perpetual_discount_applied
    : 0

  const finesAndInterest = agreement ? agreement.fine_applied + agreement.interest_applied : 0

  const isOverdue = isDayBeforeToday(agreement?.receivables?.[0]?.due_date)

  return (
    <Dialog
      open={isOpen && !!agreement}
      backdrop
      title="Detalhes do pagamento"
      size={2}
      onOpenChange={onClose}
      content={
        <>
          <ContentContainer>
            <Text variant="body-1-regular" className="text-colors-text-main-3 mb-[10px]">
              {prettifyFullName(studentName)}
            </Text>
            <Text variant="body-1-regular" style={{ marginBottom: 10, display: 'block' }}>
              {productName}
            </Text>
            <SeparatorSummary>
              <Separator color="neutral-2" />
            </SeparatorSummary>
            {agreement?.receivables.map(receivable => (
              <ItemSummary key={receivable.id}>
                <Text variant="body-1-regular">
                  {dayjs(receivable?.competenceDate).utc().format('MMMM')}
                </Text>
                <Text variant="body-1-regular">
                  {formatCentsToReal(receivable.original_amount)}
                </Text>
              </ItemSummary>
            ))}

            <ItemSummary>
              <Text variant="body-1-regular">Multa e Juros</Text>

              <Text
                variant="body-1-regular"
                data-testid="agreement-detail-drawer-fines-and-interest"
              >
                {formatCentsToReal(finesAndInterest)}
              </Text>
            </ItemSummary>

            <ItemSummary>
              <Text variant="body-1-regular">
                {isOverdue ? 'Desconto de negociação' : 'Descontos'}
              </Text>

              <Text variant="body-1-regular" data-testid="agreement-detail-drawer-discounts">
                -{formatCentsToReal(discounts)}
              </Text>
            </ItemSummary>

            {isCreditCardFlow && Boolean(creditCardFee) && (
              <ItemSummary>
                <Text variant="body-1-regular">Taxa do cartão</Text>

                <Text
                  variant="body-1-regular"
                  data-testid="agreement-detail-drawer-credit-card-fee"
                >
                  {formatCentsToReal(creditCardFee)}
                </Text>
              </ItemSummary>
            )}
            <Separator className="mt-4 mb-2" color="neutral-2" />
            <TotalValue>
              <Text variant="body-1-regular">Total</Text>
              <Text variant="body-1-regular" data-testid="agreement-detail-drawer-total">
                {formatCentsToReal(totalAmount)}
              </Text>
            </TotalValue>
          </ContentContainer>
        </>
      }
    />
  )
}

export default AgreementDetailDialog
