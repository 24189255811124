import styled from 'styled-components'

export const TitleRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${props => props.theme.gravity.spacing[4]};
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${props => props.theme.gravity.spacing[2]};
`
