import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { CreditType, Eligibility } from '@/shared/services/credit/types'
import { useApiClient } from '@/shared/hooks'
import { creditService } from '@/shared/services/credit'

export const creditEligibilityQueryKey = 'anticipation-elegibility'

export const useAnticipationEligibility = (
  schoolId: string,
  options?: UseQueryOptions<Eligibility>
) => {
  const { apiClient } = useApiClient()

  const services = creditService(apiClient.getClients().bffApi)

  return useQuery<Eligibility>(
    [creditEligibilityQueryKey, schoolId],
    async () => {
      const { data } = await services.getSchoolEligibility(schoolId, CreditType.AR)
      return data
    },
    options
  )
}
