import styled from 'styled-components'

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    border-right: 1px solid ${props => props.theme.primitiveTokens.colors.gray[10]};
    padding-right: ${({ theme }) => theme.primitiveTokens.spacing[6]};
  }
`

export const PersonalDataContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.primitiveTokens.spacing[6]};
  padding: ${({ theme }) => theme.primitiveTokens.spacing[4]};
`
