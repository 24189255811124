import { IrregularStudentContentProps } from './types'
import { Select } from '@gravity/select'
import { Button } from '@gravity/button'
import * as Styled from './styles'
import { useEffect, useState } from 'react'
import { useProducts } from '@monorepo/products/hooks/queries/products'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { ErrorDialog } from '@/shared/components/ErrorDialog'
import { GuardianIrregularityTable } from '../GuardianIrregularityTable'
import { InstallmentIrregularityTable } from '../InstallmentIrregularityTable'
import { useIrregularStudentsEvents } from '../../hooks/useIrregularStudentsEvents'
import { TablesLoadingState } from '../LoadingState'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { CustomDialog } from '../CustomDialog'
import { useCancelOpenIrregularitiesMutation } from '../../hooks/useIrregularStudentsQuery'
import { IrregularStudentStatus } from '../../types'
import { StudentIrregularityTable } from '../StudentIrregularityTable'
import { Callout } from '@gravity/callout'
import { useToast } from '@gravity/toast'

export const IrregularStudentContent = ({ student }: IrregularStudentContentProps) => {
  const { school } = useSelectedSchool()
  const [productOption, setProductOption] = useState<string>('')
  const {
    sendClickSelectProductEvent,
    sendClickMarkAsFinishedEvent,
    sendMarkAsFinishedConfirmation,
  } = useIrregularStudentsEvents()

  const isStudentIrregularitiesEnabled = useUnleashFlag(
    UnleashFlags.INT_438_ENABLE_STUDENT_IRREGULARITIES_VALIDATION_WORKFLOW
  )

  const isNewUXPendenciesPageEnabled = useUnleashFlag(UnleashFlags.INT_597_NEW_UX_PENDENCIES_PAGE)

  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false)
  const cancelOpenIrregularitiesMutation = useCancelOpenIrregularitiesMutation()
  const { toast } = useToast()

  const productCountMap = student.enrollment_requests.reduce(
    (map: Record<string, number>, item) => {
      map[item.product_id] = (map[item.product_id] || 0) + 1
      return map
    },
    {}
  )

  const { data: productData, isLoading, isError, isFetched } = useProducts({
    schoolId: school?.id ?? '',
    pagination: { page: 1, per_page: 100 },
    ids: Object.keys(productCountMap),
  })

  const doesProductExist = (productData?.data?.length ?? 0) > 0

  const productOptions =
    isFetched && doesProductExist
      ? student.enrollment_requests.map((item, index) => {
          const productName =
            productData?.data.find(product => product.id === item.product_id)?.name ?? ''
          const label =
            productCountMap[item.product_id] > 1
              ? `${productName} (responsável ${item?.guardian_request?.name})`
              : productName
          return {
            label: label || '',
            value: index.toString(),
          }
        })
      : []

  useEffect(() => {
    if (productOptions?.length > 0) setProductOption(productOptions[0].value)
  }, [productData])

  if (isLoading) {
    return <TablesLoadingState />
  }

  const selectedIndex = Number(productOption)

  const guardianIrregularities =
    student.enrollment_requests[selectedIndex]?.guardian_request?.guardian_irregularities

  const studentIrregularities =
    student?.enrollment_requests?.[selectedIndex]?.student_request?.student_irregularities

  const hasGuardianIrregularities = guardianIrregularities?.length > 0
  const hasStudentIrregularities = studentIrregularities?.length > 0

  const hasInstallmentIrregularities = student.enrollment_requests[
    selectedIndex
  ]?.installment_requests?.some(installmentRequest => {
    return (
      Array.isArray(installmentRequest.installment_irregularities) &&
      installmentRequest.installment_irregularities.length > 0
    )
  })

  const isEnrollmentRequestNotFinished =
    student.enrollment_requests?.[selectedIndex]?.status?.toLowerCase() !==
    IrregularStudentStatus.FINISHED

  const showCancelIrregularitiesCallout =
    isEnrollmentRequestNotFinished && isNewUXPendenciesPageEnabled

  const showCancelIrregularitiesButton =
    isEnrollmentRequestNotFinished && !isNewUXPendenciesPageEnabled

  if (isFetched && !doesProductExist) {
    return <ErrorDialog isError={!doesProductExist} />
  }

  const handleConfirmCancel = async () => {
    sendMarkAsFinishedConfirmation()

    setIsOpenCancelModal(false)
    cancelOpenIrregularitiesMutation
      .mutateAsync(student.enrollment_requests[selectedIndex].id)
      .then(() => {
        toast({
          title: 'Pendências excluídas com sucesso',
          description: 'As pendências foram excluídas desta lista.',
          type: 'success',
        })

        setTimeout(() => {
          location.reload()
        }, 1500)
      })
      .catch(() => {
        toast({
          title: 'Erro ao excluir pendência',
          description: 'As pendências não foram excluídas. Você pode tentar novamente mais tarde.',
          type: 'error',
        })
      })
  }

  return (
    <Styled.ContentContainer>
      {isOpenCancelModal && (
        <CustomDialog
          submitHandler={handleConfirmCancel}
          isVisible={isOpenCancelModal}
          onClose={() => setIsOpenCancelModal(false)}
        />
      )}
      <ErrorDialog isError={isError} />
      <Styled.SelectProductContainer>
        <Select
          size={2}
          label="Produto"
          variant="surface"
          defaultValue={productOptions[0].value}
          options={productOptions}
          onValueChange={value => setProductOption(value)}
          onOpenChange={open => open && sendClickSelectProductEvent()}
        />
      </Styled.SelectProductContainer>
      {showCancelIrregularitiesCallout && (
        <Callout
          text="Você já corrigiu essas pendências no ERP? Clique em 'Marcar como resolvidas' para removê-las desta lista. Essa ação não altera os dados no sistema, apenas atualiza o status aqui na Plataforma isaac"
          linkLabel="Marcar como resolvidas"
          onLinkClick={() => {
            setIsOpenCancelModal(true)
            sendClickMarkAsFinishedEvent()
          }}
        />
      )}
      <Styled.IrregularityTableContainer />
      {hasGuardianIrregularities && (
        <GuardianIrregularityTable
          externalId={student.enrollment_requests[selectedIndex]?.external_id}
          guardianName={student.enrollment_requests[selectedIndex]?.guardian_request?.name}
          irregularityList={guardianIrregularities}
          referenceLink={student.enrollment_requests[selectedIndex]?.reference_link}
        />
      )}
      {isStudentIrregularitiesEnabled && hasStudentIrregularities && (
        <Styled.IrregularityTableContainer>
          <StudentIrregularityTable
            externalId={student.enrollment_requests[selectedIndex]?.external_id}
            studentName={student.enrollment_requests[selectedIndex]?.student_request?.name}
            irregularityList={studentIrregularities}
            referenceLink={student.enrollment_requests[selectedIndex]?.reference_link}
          />
        </Styled.IrregularityTableContainer>
      )}
      {hasInstallmentIrregularities && (
        <InstallmentIrregularityTable
          installmentRequests={student.enrollment_requests[selectedIndex]?.installment_requests}
          externalId={student.external_id}
          referenceLink={student.reference_link}
        />
      )}
      {showCancelIrregularitiesButton && (
        <Styled.CancelButtonContainer>
          <Button
            variant="outline"
            color="error"
            size={2}
            onClick={() => {
              setIsOpenCancelModal(true)
            }}
          >
            Excluir pendências
          </Button>
        </Styled.CancelButtonContainer>
      )}
    </Styled.ContentContainer>
  )
}
