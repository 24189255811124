import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import {
  GetGuardianRequest,
  GetGuardianResponse,
  GetGuardiansRequest,
  GetGuardiansResponse,
} from '@/shared/services/guardian/types'
import { guardianService } from '@/shared/services/guardian'

import { useApiClient } from '../../useApiClient'

export const useGetGuardians = (
  props: GetGuardiansRequest,
  options?: UseQueryOptions<GetGuardiansResponse['data'], XMLHttpRequest>
) => {
  const { apiClient } = useApiClient()

  const service = guardianService(apiClient.getClients().basePrivateApi)
  const queryKey = ['enrollment-guardians', props.schoolId, props.studentId, props.taxID]

  return useQuery<GetGuardiansResponse['data'], XMLHttpRequest>(
    queryKey,
    async () => {
      const data = await service.fetchGuardians(props)
      return data.data
    },
    {
      retry: false,
      ...options,
    }
  )
}

export const getGuardianQueryKey = 'guardian-details'
export const useGetGuardian = (
  props: GetGuardianRequest,
  options?: UseQueryOptions<GetGuardianResponse['data'], XMLHttpRequest>
) => {
  const { apiClient } = useApiClient()

  const service = guardianService(apiClient.getClients().privateApiV2)
  const queryKey = [getGuardianQueryKey, props.schoolId, props.guardianId]

  return useQuery<GetGuardianResponse['data'], XMLHttpRequest>(
    queryKey,
    async () => {
      const data = await service.fetchGuardian(props)
      return data.data
    },
    {
      retry: false,
      ...options,
    }
  )
}
