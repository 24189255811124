import { useMemo } from 'react'
import { formatMicroCentsToReal, formatDate, prettifyFullName } from '@/shared/utils'
import type { EventDetail, TransactionEvents } from '@monorepo/report/models/report'
import {
  ColumnProps,
  PaginationEvents,
  PayoutReportTableProps,
  TableData,
} from '@monorepo/report/components/ReportTable/types'
import { Filter, FilterObjectKeys } from '@monorepo/report/hooks/useTuitionPayoutReportFilter/types'
import { ReportTable } from '@monorepo/report/components/ReportTable'
import { useTuitionPayoutReportFilter } from '@monorepo/report/hooks/useTuitionPayoutReportFilter'
import { REPORT_PAGE_NAMES } from '@monorepo/report/constants/REPORT_PAGE_NAMES'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

type ColumnNames = 'student_name' | 'product_name' | 'reference_month' | 'total_amount' | 'events'

/**
 * Tuition payout report installments table component
 *
 * @param props
 * @param props.transactions Table data
 * @param props.availableFilters Filters that are possible to be applied
 * @param props.onRowClick Callback to be called when a row is clicked
 * @param props.totalItems Total number of items
 * @param props.isNewVersionPayout Indicates if payout is in new version
 * @param props.isLoading Indicates if loading state should be displayed
 */
export const TuitionPayoutReportTable = ({
  transactions,
  availableFilters,
  onRowClick,
  totalItems,
  isNewVersionPayout,
  isLoading = true,
}: PayoutReportTableProps<Filter>) => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const COLUMNS: ColumnProps[] = [
    { name: 'student_name', displayName: 'Aluno', sortable: true },
    { name: 'product_name', displayName: 'Produto' },
    { name: 'reference_month', displayName: 'Competência', sortable: true },
    {
      name: 'total_amount',
      displayName: 'Valor final',
      description: 'Valor calculado que já considera descontos, mudanças e a taxa isaac.',
    },
    {
      name: 'events',
      displayName: 'Mudanças',
      description: 'Mudanças feitas em parcelas de mensalidade que afetam o valor final.',
    },
  ]

  const FILTERS: Record<FilterObjectKeys, string> = {
    events: 'Mudanças',
    amountSigns: 'Valor final',
    products: 'Produtos',
  }

  const onSortTable = (sortBy: string, sortOrder: string) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.PAYOUT_REPORT,
        name: EventDispatcherEvents.TABLE_ORDERING,
        action: 'click',
        customProperties: {
          $page_name: REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT,
          $sort_by: sortBy,
          $sort_order: sortOrder,
        },
      })
  }

  const onSearchTable = (value: string) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.PAYOUT_REPORT,
        name:
          value !== '' ? EventDispatcherEvents.APPLY_FILTER : EventDispatcherEvents.RESET_FILTER,
        action: 'type',
        customProperties: {
          $page_name: REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT,
        },
      })
  }

  const onPageChange = (event: PaginationEvents, value: string) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.PAYOUT_REPORT,
        name:
          event === PaginationEvents.PAGE
            ? EventDispatcherEvents.PAGINATION_CHANGE_PAGE
            : EventDispatcherEvents.PAGINATION_CHANGE_PAGE_SIZE,
        action: 'click',
        customProperties: {
          $page_name: REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT,
          [event === PaginationEvents.PAGE ? '$page' : '$page_size']: value,
        },
      })
  }

  const handleRowClick = (row: TableData) => {
    onRowClick(row)
  }

  const rows: TableData[] = useMemo(() => {
    const parseEvents = (events: EventDetail[]) => {
      return events.reduce<TransactionEvents>((acc, { event, total }) => {
        return {
          ...acc,
          [event]: total,
        }
      }, {})
    }

    const formattedRows = transactions.map(item => ({
      id: item.referrals.installment_id,
      key: item.referrals.installment_id,
      studentName: prettifyFullName(item.referrals.student_name),
      productName: item.referrals.product_name,
      referenceMonth: formatDate(item.reference_month, 'MMM YYYY'),
      totalAmount: formatMicroCentsToReal(item.financial_detail.total),
      events: parseEvents(item.financial_detail?.events_details ?? []),
    }))

    return formattedRows
  }, [transactions])

  return (
    <ReportTable<ColumnNames, Filter>
      filterHook={useTuitionPayoutReportFilter}
      availableFilters={availableFilters}
      columns={COLUMNS}
      rows={rows}
      reportPage={REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT}
      emptyStateMessage={
        isNewVersionPayout
          ? 'Nenhum resultado encontrado'
          : 'Esse repasse só está disponível via planilha'
      }
      filterTypes={FILTERS}
      totalItems={totalItems}
      isLoading={isLoading}
      handleRowClick={handleRowClick}
      onSort={onSortTable}
      onSearch={onSearchTable}
      onPagination={onPageChange}
    />
  )
}
