import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Root, Trigger, Portal } from '@radix-ui/react-popover'
import { debounce } from 'throttle-debounce'
import { Link } from '@gravity/link'
import { Text } from '@gravity/text'
import { Callout } from '@gravity/callout'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useSchoolHomeSearch } from '@monorepo/school-home/hooks/queries/school-home-search'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { InputSearch } from './components/InputSearch'
import { ResultStoredSection } from './components/ResultStoredSection'
import { ResultSection } from './components/ResultSection'
import { ResultSectionPlaceholder } from './components/ResultSectionPlaceholder'
import { FiltersSearch } from './components/FiltersSearch'

import { MAX_SEARCH_ITEMS_IN_STORAGE } from '@monorepo/school-home/constants/MAX_SEARCH_ITEMS'
import { KEY_SEARCH_RESULT_LOCALSTORAGE } from '@monorepo/school-home/constants/KEY_SEARCH_RESULT_LOCALSTORAGE'
import { SEARCH_TYPE_ITEM } from '@monorepo/school-home/constants/SEARCH_TYPE_ITEM'
import type { StorageItemType } from '@monorepo/school-home/types/StorageItem'
import type { SearchType } from '@monorepo/school-home/types/SearchType'
import type {
  SearchResponse,
  SearchCategories,
} from '@monorepo/school-home/services/school-home-search/types'

import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventPageName } from '@monorepo/school-home/models/EventPageName.enum'
import { EventIdentifierName } from '@monorepo/school-home/models/EventIdentifierName.enum'

import { Container, Content, HeaderTitle, ResultSearch } from './styles'

export const HomeSearch = () => {
  const { school } = useSelectedSchool()
  const { eventDispatcherClient, isInitialized } = useEventDispatcher()

  const [openResults, setOpenResults] = useState(false)
  const [inputSearch, setInputSearch] = useState('')
  const [inputWithDebounce, setInputWithDebounce] = useState('')
  const [selectedFilterCategories, setSelectedFilterCategories] = useState<SearchCategories[]>([
    'students',
    'guardians',
  ])
  const [storedSearch, setStoredSearch] = useState<StorageItemType[]>([])

  const isEmptySearch = storedSearch.length === 0

  const inputRef = useRef<HTMLInputElement>(null)

  const schoolId = school?.id ?? ''

  const isRequestHookEnabled = !!schoolId && !!inputWithDebounce

  const { data: response, isFetching, isError, refetch } = useSchoolHomeSearch(
    {
      schoolId,
      search: inputWithDebounce,
      type: selectedFilterCategories,
    },
    {
      enabled: isRequestHookEnabled,
    }
  )

  const inputDebounceRef = useRef(debounce(300, input => setInputWithDebounce(input)))

  useEffect(() => inputDebounceRef.current(inputSearch), [inputSearch])

  const maxItemsPerCategory: Record<SearchCategories, number> = (() => {
    const MAX_ITEMS = 6
    const ALL_SECTION_MAX_STUDENTS = 3
    const ALL_SECTION_MAX_GUARDIANS = 2

    if (selectedFilterCategories.length > 1) {
      return {
        students: ALL_SECTION_MAX_STUDENTS,
        guardians: ALL_SECTION_MAX_GUARDIANS,
      }
    }

    return {
      students: MAX_ITEMS,
      guardians: MAX_ITEMS,
    }
  })()

  const results = useMemo<SearchResponse['data']>(() => {
    const apiResponse = response?.data ?? {}

    const slicedResults = selectedFilterCategories.reduce<SearchResponse['data']>(
      (acc, category) => {
        if (!apiResponse[category]) {
          return acc
        }

        return {
          ...acc,
          [category]: {
            results: apiResponse[category]?.results.slice(0, maxItemsPerCategory[category]),
            total: apiResponse[category]?.total,
          },
        }
      },
      {}
    )

    return slicedResults
  }, [response, selectedFilterCategories, maxItemsPerCategory])

  const isEmptyResultForSearchStored = !isFetching && !storedSearch?.length

  /* TODO: 
  Como estamos adaptando o componente de Popopover para ter um comportamento de um combobox, existem alguns comportamentos 
  indesejáveis. Nesse caso, nosso trigger utilizado é um input, que por sua vez ao pressionarmos o space o comportamento esperado seria a adição de um spaço
  na string digitada, no entanto, por questão de acessibilidade, para a popover o comportamento será fechar/abrir popover. Dessa forma, tivemos que
  implementar a handleKeyDown para lidar com a questão. Para sanar essa questão, precisaremos desenvolver um combobox com a seguinte referencia: https://ui.shadcn.com/docs/components/command
  */

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Space' && openResults) {
      e.preventDefault()

      if (!inputRef.current) {
        return
      }

      const inputElement = e.currentTarget
      const cursorPosition = inputElement.selectionStart ?? 0
      const nextCursorPosition = cursorPosition + 1

      const textBefore =
        inputElement.selectionStart !== inputElement.selectionEnd
          ? inputElement.selectionStart
          : cursorPosition

      const textAfter =
        inputElement.selectionStart !== inputElement.selectionEnd
          ? inputElement.selectionEnd
          : cursorPosition

      setInputSearch(props => props.slice(0, textBefore ?? 0) + ' ' + props.slice(textAfter ?? 0))

      setTimeout(() => {
        inputElement.selectionStart = inputElement.selectionEnd = nextCursorPosition
      }, 0)
    }
  }

  const getStorageSearches = (type?: SearchType, input?: string) => {
    const search = JSON.parse(localStorage.getItem(KEY_SEARCH_RESULT_LOCALSTORAGE) ?? '[]')

    const schoolSearches = Array.isArray(search?.[schoolId]) ? search[schoolId] : []

    if (!schoolSearches.length) return []

    if (type) {
      const inputLower = input?.toLowerCase()

      return schoolSearches.filter(
        (item: StorageItemType) =>
          item.type === type && (!inputLower || item.name.toLowerCase().includes(inputLower))
      )
    }

    return schoolSearches
  }

  const handleChangeInputSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    let recentSearches = []

    if (!event.target.value) {
      recentSearches = getStorageSearches()
    }

    setStoredSearch(recentSearches)
    setInputSearch(event.target.value)
  }

  const handleOpenSearch = () => {
    if (!inputSearch) {
      const recentSearches = getStorageSearches()

      setStoredSearch(recentSearches)
    }
  }

  const handleOnClickInInput = (event: React.MouseEvent<HTMLElement>) => {
    if (openResults) {
      event.stopPropagation()
    }

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.SEARCH_HOME,
        pageName: EventPageName.SCHOOL_HOME,
        identifierName: EventIdentifierName.CLICK_ON_SEARCH,
        customProperties: {
          $button_name: 'busca_home',
        },
      })
    }
  }

  const handleClearStorage = () => {
    localStorage.removeItem(KEY_SEARCH_RESULT_LOCALSTORAGE)

    setStoredSearch([])

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.SEARCH_HOME,
        pageName: EventPageName.SCHOOL_HOME,
        identifierName: EventIdentifierName.CLEAR_STORAGE,
        customProperties: {
          $button_name: 'limpar_historico',
        },
      })
    }
  }

  const handleRemoveItem = (id: string) => {
    const search = JSON.parse(localStorage.getItem(KEY_SEARCH_RESULT_LOCALSTORAGE) ?? '[]')

    if (search[schoolId]) {
      const searchSchool = search[schoolId] as StorageItemType[]

      const storage = {
        ...search,
        [schoolId]: searchSchool.filter(search => search.id !== id),
      }

      const itemType = searchSchool.find(search => search.id === id)?.type

      if (isInitialized) {
        eventDispatcherClient.sendEvent({
          name: EventDispatcherEvents.BUTTON_CLICKED,
          scope: EventDispatcherEventScopes.SEARCH_HOME,
          pageName: EventPageName.SCHOOL_HOME,
          identifierName: EventIdentifierName.REMOVE_RECENT_SEARCH,
          customProperties: {
            $button_name: 'apagar_busca_recente',
            $search_type: itemType ? SEARCH_TYPE_ITEM[itemType] : '',
          },
        })
      }

      localStorage.setItem(KEY_SEARCH_RESULT_LOCALSTORAGE, JSON.stringify(storage))
    }

    setStoredSearch(props => props.filter(search => search.id !== id))
  }

  const handleAddItemInStorage = (
    item: StorageItemType,
    nameEvent?: string,
    identifierEvent?: string
  ) => {
    const search = JSON.parse(localStorage.getItem(KEY_SEARCH_RESULT_LOCALSTORAGE) ?? '[]')

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.SEARCH_HOME,
        pageName: EventPageName.SCHOOL_HOME,
        identifierName: identifierEvent || EventIdentifierName.CLICK_ON_RECENT_SEARCH,
        customProperties: {
          $button_name: nameEvent || 'busca_recente',
          $search_type: SEARCH_TYPE_ITEM[item.type],
        },
      })
    }

    if (!search || !search[schoolId]) {
      const storage = {
        ...search,
        [schoolId]: [item],
      }

      return localStorage.setItem(KEY_SEARCH_RESULT_LOCALSTORAGE, JSON.stringify(storage))
    }

    const searchSchool = search[schoolId] as StorageItemType[]
    const hasItem = searchSchool.find(storageItem => storageItem.id === item.id)

    if (!hasItem) {
      const storageItens =
        searchSchool.length < MAX_SEARCH_ITEMS_IN_STORAGE ? searchSchool : searchSchool.slice(1)

      const storage = {
        ...search,
        [schoolId]: [item, ...storageItens],
      }

      localStorage.setItem(KEY_SEARCH_RESULT_LOCALSTORAGE, JSON.stringify(storage))
    }
  }

  return (
    <Container extend={openResults} tabIndex={0} data-testid="container-search">
      <Root
        open={openResults}
        onOpenChange={isOpen => {
          setOpenResults(isOpen)
        }}
      >
        <Trigger className="trigger">
          <InputSearch
            ref={inputRef}
            data-testid="input-search"
            value={inputSearch}
            onChange={handleChangeInputSearch}
            onKeyDown={handleKeyDown}
            onFocus={handleOpenSearch}
            onClick={handleOnClickInInput}
          />
        </Trigger>

        <Portal>
          <Content
            side="bottom"
            avoidCollisions={false}
            sideOffset={-8}
            onOpenAutoFocus={e => e.preventDefault()}
            data-testid="content-search"
          >
            <section data-testid="content-header">
              {!!inputSearch && (
                <FiltersSearch onChangeSelectedFilterCategory={setSelectedFilterCategories} />
              )}

              {inputSearch.length === 0 && (
                <HeaderTitle data-testid="header-title-when-input-empty">
                  <Text variant="caption-regular" className="caption-search">
                    Buscas recentes
                  </Text>

                  {!isEmptySearch && (
                    <Link
                      data-testid="clear-history"
                      size={1}
                      weight="light"
                      onClick={handleClearStorage}
                    >
                      Limpar histórico
                    </Link>
                  )}
                </HeaderTitle>
              )}
            </section>

            <ResultSearch data-testid="result-search">
              {isError && (
                <Callout
                  text="Erro ao encontrar resultados de busca"
                  linkLabel="Tentar novamente"
                  onLinkClick={() => refetch()}
                />
              )}
              {inputSearch.length === 0 && (
                <ResultStoredSection
                  inputSearch={inputSearch}
                  handleAddItemInStorage={handleAddItemInStorage}
                  handleRemoveItem={handleRemoveItem}
                  storedSearch={storedSearch}
                  isEmptyResultForSearchStored={isEmptyResultForSearchStored}
                />
              )}

              {inputSearch.length > 0 &&
                (isFetching || !isRequestHookEnabled
                  ? selectedFilterCategories.map(category => (
                      <ResultSectionPlaceholder
                        key={category}
                        items={maxItemsPerCategory[category as SearchCategories]}
                      />
                    ))
                  : Object.entries(results).map(([key, value]) => {
                      const category = key as keyof SearchResponse['data']

                      return (
                        <ResultSection
                          key={category}
                          inputSearch={inputSearch}
                          isLoading={isFetching}
                          type={category}
                          results={value.results}
                          total={value.total}
                          handleAddItemInStorage={handleAddItemInStorage}
                        />
                      )
                    }))}
            </ResultSearch>
          </Content>
        </Portal>
      </Root>
    </Container>
  )
}
