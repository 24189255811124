import type {
  FilterQueryParams as TuitionFilterQueryParams,
  Filter as TuitionPayoutReportFilter,
} from '@monorepo/report/hooks/useTuitionPayoutReportFilter/types'
import type {
  FilterQueryParams as EnrollmentFilterQueryParams,
  Filter as EnrollmentPayoutReportFilter,
} from '@monorepo/report/hooks/useEnrollmentPayoutReportFilter/types'

const FILTER_KEY_TO_FILTER_QUERY_PARAMS: Record<
  keyof TuitionPayoutReportFilter | keyof EnrollmentPayoutReportFilter,
  TuitionFilterQueryParams | EnrollmentFilterQueryParams
> = {
  amountSigns: 'amount_signs',
  events: 'events',
  products: 'products',
  referenceYears: 'reference_years',
}

export const buildQueryParams = (params: Record<string, unknown>) => {
  return Object.entries(params).reduce<Record<string, unknown | unknown[]>>((acc, [key, value]) => {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        return { ...acc, [FILTER_KEY_TO_FILTER_QUERY_PARAMS[key]]: value }
      }

      return acc
    }

    if (value) return { ...acc, [key]: value }

    return acc
  }, {})
}
