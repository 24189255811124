import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, GridItem } from '@gravity/grid'

import { MobileDisclaimer } from './components/MobileDisclaimer'
import { SchoolSelectionContent } from './components/SchoolSelectionContent'
import { CircularLoading } from '@/shared/components/CircularLoading'

import { useSchool } from '@/modules/app/hooks/queries/schools'
import { useJWT } from '@/shared/hooks/useJWT'

import SchoolSelectionGraphism from '@/modules/app/assets/school-selection-graphism.svg'

import { Container } from './styles'

export const SchoolSelection = () => {
  const { isSchool, schoolId, schoolIds } = useJWT()
  const { push } = useHistory()

  const [singleSchoolId, setSingleSchoolId] = useState<string>()
  const [hasDismissedDisclaimer, setHasDismissedDisclaimer] = useState(false)

  /**
   * TODO: refactor to not depends on deprecated hook properties
   */
  useEffect(() => {
    const uniqueSchool = !schoolIds || schoolIds?.length === 1

    if (isSchool && uniqueSchool) {
      const schoolIdToFetch = schoolIds?.[0] || schoolId

      setSingleSchoolId(schoolIdToFetch)
    }
  }, [schoolId, isSchool])

  const { data: schoolResponse, isFetching: isFetchingSchool } = useSchool(
    { schoolId: singleSchoolId ?? '' },
    { enabled: !!singleSchoolId }
  )
  const singleSchool = schoolResponse?.data

  useEffect(() => {
    if (!singleSchool) return

    push(singleSchool.slug)
  }, [singleSchool])

  return (
    <Container>
      {isFetchingSchool ? (
        <CircularLoading iconSize={55} />
      ) : (
        <Grid className="grid-container">
          <GridItem
            className="grid-item content-item"
            xl="4 / 10"
            lg="4 / 10"
            md="4 / 10"
            sm="2 / 8"
            xs={4}
            data-disclaimer-dismissed={hasDismissedDisclaimer}
          >
            <SchoolSelectionContent />
          </GridItem>

          {!hasDismissedDisclaimer && (
            <GridItem className="grid-item mobile-disclaimer-item" sm={4}>
              <MobileDisclaimer onContinue={() => setHasDismissedDisclaimer(true)} />
            </GridItem>
          )}
        </Grid>
      )}

      {!isFetchingSchool && (
        <img src={SchoolSelectionGraphism} alt="graphism" data-testid="graphism" />
      )}
    </Container>
  )
}
