import { CalendarEventOutline } from '@gravity/icons'
import { Container } from './styles'
import { EmptyState as GravityEmptyState } from '@gravity/emptystate'

export const EmptyState = () => {
  return (
    <Container data-testid="empty-state">
      <GravityEmptyState
        icon={<CalendarEventOutline />}
        title="Você ainda não possui nenhum repasse"
        subtitle="Em breve, você poderá ver aqui todos os detalhes dos valores a serem transferidos."
        type="default"
      />
    </Container>
  )
}
