import styled from 'styled-components'
import { Stepper } from '@gravity/stepper'

import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'

export const Title = styled(Heading)`
  white-space: pre-line;
  color: ${props => props.theme.gravity.colors['colors-text-main-2']};
`

export const Description = styled(Text)`
  color: ${props => props.theme.gravity.colors['colors-text-main-3']};
`

export const HeaderTitle = styled.div`
  margin-bottom: ${props => props.theme.gravity.spacing[6]};
`

export const CardStep = styled(Stepper.Step)`
  width: 431px;
`
