import { Heading } from '@gravity/heading'
import { EditStudentFormProps } from '../../../hooks/useEditStudentForm'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { ProductAccordion } from './ProductAccordion'

export const ProductsForm = () => {
  const { control } = useFormContext<EditStudentFormProps>()

  const { fields: products } = useFieldArray({
    control,
    name: 'products',
  })

  return (
    <div className="flex flex-col gap-6">
      <Heading variant="heading-h4-medium">Produtos</Heading>

      {products.map((product, index) => (
        <ProductAccordion key={product.id} index={index} />
      ))}
    </div>
  )
}
