import { GetOnboardingBatchRequest } from '@monorepo/onboarding/services/types/commonTypes'
import {
  BatchSummaryResponse,
  CancelBatchParams,
  FetchOnboardingBatchesParams,
  FetchOnboardingBatchesResponse,
  GetOnboardingBatchParams,
  OnboardingBatch,
  SubmitBatchParams,
  ToggleBypassParams,
  ToggleBypassResponse,
} from '@monorepo/onboarding/services/types/onboardingBatch'
import {
  GetStudentsResponse,
  Student,
  UpdateStudentParams,
} from '@monorepo/onboarding/services/types/student'
import { GetContractsResponse } from '@monorepo/onboarding/services/types/onboardingContract'
import { UploadOnboardingFileParams } from '@monorepo/onboarding/services/types/onboardingFile'
import { AxiosInstance } from 'axios'
import { Product, UpdateProductParams } from '../types/onboardingProduct'
import { GetGuardiansResponse, UpdateGuardianParams } from '../types/onboardingGuardian'
import { GetChargesResponse, UpdateChargeParams } from '../types/onboardingCharge'
import { BatchSummaryParams } from '../../hooks/useBatchSummaryQuery'
import { BatchCSVParams } from '../../hooks/useBatchCSVQuery'
import { NotifyBatchParams } from '@monorepo/onboarding/hooks/useNotifyBatchMutation'

const makeDefaultParams = (params: GetOnboardingBatchRequest): URLSearchParams => {
  const paramsQuery = new URLSearchParams()
  paramsQuery.append('sort_order', 'ASC')

  if (params.pagination.page) {
    paramsQuery.append('page', params.pagination.page.toString())
  }

  if (params.pagination.per_page) {
    paramsQuery.append('per_page', params.pagination.per_page.toString())
  }

  if (params.search) {
    paramsQuery.append('search', params.search)
  }

  return paramsQuery
}

export const onboardingAPI = (fetcher: AxiosInstance) => ({
  getBatchSummary: async (params: BatchSummaryParams): Promise<BatchSummaryResponse> => {
    return (
      await fetcher.get<BatchSummaryResponse>(`/onboarding_batches/${params.batchID}/summary`)
    ).data
  },
  fetchOnboardingBatches: async ({ schoolId }: FetchOnboardingBatchesParams) => {
    const response = await fetcher.get<FetchOnboardingBatchesResponse>(
      `schools/${schoolId}/onboarding_batches`
    )

    return response.data
  },
  uploadOnboardingFile: async ({ file, schoolId }: UploadOnboardingFileParams) => {
    const formData = new FormData()
    formData.append('file', file)

    const response = await fetcher.post<OnboardingBatch>(
      `schools/${schoolId}/onboarding_batches`,
      formData
    )

    return response
  },
  getStudents: async (params: GetOnboardingBatchRequest): Promise<GetStudentsResponse> => {
    return (
      await fetcher.get<GetStudentsResponse>(`/onboarding_batches/${params.batchID}/students`, {
        params: makeDefaultParams(params),
      })
    ).data
  },
  getGuardians: async (params: GetOnboardingBatchRequest): Promise<GetGuardiansResponse> => {
    return (
      await fetcher.get<GetGuardiansResponse>(`/onboarding_batches/${params.batchID}/guardians`, {
        params: makeDefaultParams(params),
      })
    ).data
  },
  getContractsByProduct: async (
    params: GetOnboardingBatchRequest
  ): Promise<GetContractsResponse> => {
    return (
      await fetcher.get<GetContractsResponse>(
        `/onboarding_batches/${params.batchID}/contracts/products`,
        {
          params: makeDefaultParams(params),
        }
      )
    ).data
  },
  getOnboardingBatch: async (params: GetOnboardingBatchParams): Promise<OnboardingBatch> => {
    return (await fetcher.get<OnboardingBatch>(`/onboarding_batches/${params.batchID}`)).data
  },
  getCharges: async (params: GetOnboardingBatchRequest): Promise<GetChargesResponse> => {
    const response = await fetcher.get(`onboarding_batches/${params.batchID}/contracts/charges`, {
      params: makeDefaultParams(params),
    })

    return response.data
  },
  updateProduct: async (params: UpdateProductParams) => {
    const response = await fetcher.patch<Product>(
      `/onboarding_batches/${params.batchID}/products/${params.product.id}`,
      { ...params.product }
    )

    return response
  },
  updateStudent: async ({ batchID, student }: UpdateStudentParams) => {
    const response = await fetcher.patch<Student>(
      `onboarding_batches/${batchID}/students/${student.id}`,
      student
    )

    return response
  },
  updateGuardian: async ({ batchID, guardian }: UpdateGuardianParams) => {
    const response = await fetcher.patch(
      `onboarding_batches/${batchID}/guardians/${guardian.id}`,
      guardian
    )

    return response
  },
  updateCharges: async (params: UpdateChargeParams) => {
    const response = await fetcher.patch(
      `onboarding_batches/${params.batchID}/contracts/${params.contractID}/charges`,
      params.charges
    )

    return response
  },
  cancelBatch: async (params: CancelBatchParams) => {
    return await fetcher.post(`/onboarding_batches/${params.batchID}/cancel`)
  },
  postSubmitBatch: async (params: SubmitBatchParams) => {
    return await fetcher.post(`/onboarding_batches/${params.batchID}/submit`)
  },
  notifyBatch: async (params: NotifyBatchParams) => {
    return await fetcher.post(`/onboarding_batches/${params.batchID}/notify`)
  },
  getBatchCSV: async (params: BatchCSVParams): Promise<Blob> => {
    return (
      await fetcher.get<Blob>(`/onboarding_batches/${params.batchID}/csv`, {
        responseType: 'blob',
      })
    ).data
  },
  toggleBypass: async (params: ToggleBypassParams) => {
    return await fetcher.post<ToggleBypassResponse>(
      `/onboarding_batches/${params.batchID}/toggle-bypass`
    )
  },
})
