import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'

import { MANAGEMENT_DASHBOARDS_TABS } from '../constants'
import type { ManagementDashboardsTab } from '../types'

export const useDashboardTabs = (isUnitManagement: boolean): Array<ManagementDashboardsTab> => {
  const enableEnrollmentPanelsFF = useUnleashFlag(
    UnleashFlags.MAT_813_ENABLE_ENROLLMENT_MANAGEMENT_PANELS
  )

  if (isUnitManagement) {
    return enableEnrollmentPanelsFF
      ? [
          {
            label: 'Receita da unidade',
            value: MANAGEMENT_DASHBOARDS_TABS.SCHOOL_UNIT,
          },
          {
            label: 'Matrículas da unidade',
            value: MANAGEMENT_DASHBOARDS_TABS.UNIT_ENROLLMENT,
          },
        ]
      : [
          {
            label: 'Receita da unidade',
            value: MANAGEMENT_DASHBOARDS_TABS.SCHOOL_UNIT,
          },
        ]
  }

  return enableEnrollmentPanelsFF
    ? [
        {
          label: 'Receita da unidade',
          value: MANAGEMENT_DASHBOARDS_TABS.SCHOOL_UNIT,
        },
        {
          label: 'Matrículas da unidade',
          value: MANAGEMENT_DASHBOARDS_TABS.UNIT_ENROLLMENT,
        },
        {
          label: 'Receita do grupo',
          value: MANAGEMENT_DASHBOARDS_TABS.SCHOOL_GROUP,
        },
        {
          label: 'Matrículas do grupo',
          value: MANAGEMENT_DASHBOARDS_TABS.SCHOOL_GROUP_ENROLLMENT,
        },
      ]
    : [
        {
          label: 'Receita da unidade',
          value: MANAGEMENT_DASHBOARDS_TABS.SCHOOL_UNIT,
        },
        {
          label: 'Receita do grupo',
          value: MANAGEMENT_DASHBOARDS_TABS.SCHOOL_GROUP,
        },
      ]
}
