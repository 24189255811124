import styled from 'styled-components'
import { Text } from '@gravity/text'

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[3]};
  align-items: flex-start;
`

export const StyledCaption = styled(Text)`
  color: ${props => props.theme.gravity.colors['colors-text-main-3']};
`
