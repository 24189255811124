import { Footer } from '@gravity/footer'
import styled, { css } from 'styled-components'

export const FooterStyled = styled(Footer)<{ $renderNewHeader: boolean }>`
  left: 96px;
  z-index: 50;

  ${({ $renderNewHeader }) =>
    $renderNewHeader &&
    css`
      left: 0;
    `}
`
