import { useMutation } from '@tanstack/react-query'
import { ChooseOfferRequest } from '@/shared/services/credit/types'
import { useApiClient } from '@/shared/hooks'
import { creditService } from '@/shared/services/credit'

export const chooseOfferQueryKey = 'anticipation-choose-offer'

export const useAnticipationChooseOffer = () => {
  const { apiClient } = useApiClient()

  const services = creditService(apiClient.getClients().bffApi)

  return useMutation([chooseOfferQueryKey], async (body: ChooseOfferRequest) =>
    services.chooseOffer(body)
  )
}
