export enum EventIdentifierName {
  CHANGE_SCHOOL = 'navegacao_de_unidades',
  NAVIGATE_TO_AER_MANAGEMENT_DASHBOARD = 'menu_aer_painel_de_gestao',
  NAVIGATE_TO_AER_REPORTS = 'menu_aer_relatorios',
  NAVIGATE_TO_FINANCIAL_CREDIT = 'menu_financeiro_credito',
  NAVIGATE_TO_FINANCIAL_TRANSFERS = 'menu_financeiro_repasses',
  NAVIGATE_TO_INITIAL_PAGE = 'menu_pagina_inicial',
  NAVIGATE_TO_OPERATION_ENROLLMENTS = 'menu_operacao_matriculas',
  NAVIGATE_TO_OPERATION_GUARDIANS = 'menu_operacao_responsaveis',
  NAVIGATE_TO_OPERATION_IRREGULARITIES = 'menu_operacao_pendencias_de_registro',
  NAVIGATE_TO_OPERATION_PRODUCTS = 'menu_operacao_produtos',
}
