import { Table } from '@gravity/table'
import { Tooltip } from '@gravity/tooltip'
import { IconButton } from '@gravity/icon-button'
import { LinkM, Pencil, FileList3, MoneyDollarBoxOutline } from '@gravity/icons'

import { useGuardianInvoicesTableActions } from './useGuardianInvoicesTableActions'
import { Installment } from '@/modules/guardians/GuardianDetails/types'

interface GuardianInvoicesTableActionsProps {
  installment: Installment
}

export const GuardianInvoicesTableActions = ({
  installment,
}: GuardianInvoicesTableActionsProps) => {
  const {
    showPrintReceipt,
    showSharePaymentLink,
    showManualLiquidation,
    showEditManualLiquidation,
    showEditContract,
    handleClickPrintReceipt,
    handleClickManualLiquidation,
    handleClickEditManualLiquidation,
    handleClickSharePaymentLink,
    handleEditContract,
  } = useGuardianInvoicesTableActions({ installment })

  if (
    !showPrintReceipt &&
    !showSharePaymentLink &&
    !showManualLiquidation &&
    !showEditManualLiquidation &&
    !showEditContract
  ) {
    return null
  }

  return (
    <Table.ActionCell
      actions={
        <>
          {showSharePaymentLink && (
            <Tooltip text="Copiar link de cobrança" position="left">
              <IconButton variant="ghost" onClick={handleClickSharePaymentLink}>
                <LinkM size="xs" />
              </IconButton>
            </Tooltip>
          )}

          {showManualLiquidation && (
            <Tooltip text="Registrar recebimento" position="left">
              <IconButton variant="ghost" onClick={handleClickManualLiquidation}>
                <MoneyDollarBoxOutline size="xs" />
              </IconButton>
            </Tooltip>
          )}

          {showEditManualLiquidation && (
            <Tooltip text="Editar recebimento" position="left">
              <IconButton variant="ghost" onClick={handleClickEditManualLiquidation}>
                <Pencil size="xs" />
              </IconButton>
            </Tooltip>
          )}

          {showPrintReceipt && (
            <Tooltip text="Gerar comprovante" position="left">
              <IconButton variant="ghost" onClick={handleClickPrintReceipt}>
                <FileList3 size="xs" />
              </IconButton>
            </Tooltip>
          )}

          {showEditContract && (
            <Tooltip text="Editar contrato" position="left" style={{ maxWidth: '262px' }}>
              <IconButton variant="ghost" onClick={handleEditContract}>
                <Pencil size="xs" />
              </IconButton>
            </Tooltip>
          )}
        </>
      }
    />
  )
}
