import { ReactNode, useEffect } from 'react'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { Router } from '@/shared/core/router'
import { ModuleRegister } from '@/shared/core/module'

import { useRegisterSchoolHomeModule } from '@monorepo/school-home'
import { useRegisterProductsModule } from '@monorepo/products'
import { useRegisterReportModule } from '@monorepo/report'
import { useNotificationModule } from '@monorepo/notification'
import { useRegisterEnrollmentModule } from '@monorepo/enrollment'
import { useEscolasModule } from '@/escolas'
import { useRegisterContractModule } from '@/modules/contract'
import { useRegisterCreditModule } from '@monorepo/credit'
import { useRegisterManagementModule } from '@monorepo/management'
import { useRegisterAccountReportsModule } from '@monorepo/account-reports'
import { useRegisterPaymentDisputeModule } from '@monorepo/payment-dispute'
import { useRegisterAccessManagementModule } from '@monorepo/access-management'
import { useRegisterStudentsModule } from '@monorepo/students'
import { useRegisterOnboardingModule } from '@monorepo/onboarding'
import { useRegisterMessagesModule } from '@monorepo/messages'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

import { useTrackPageView } from '../hooks/useTrackPageView'
import { useCreditAccessStore } from '@monorepo/credit/creditAccessStore'
import { isIsaacPaySchool } from '@/shared/utils/isIsaacPaySchool'

import { CircularLoadingPlaceholder } from '@/shared/components/CircularLoadingPlaceholder'

import { SchoolRoutesProviders } from '../contexts/SchoolRoutesProviders'

import { ON_SELECT_SCHOOL_EVENT_SESSION_STORAGE_KEY } from '../constants'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'

type SchoolUnitWrapperProps = {
  children: ReactNode
}

const SchoolUnitWrapper = ({ children }: SchoolUnitWrapperProps) => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { schoolSlug, school, isCollege } = useSelectedSchool()

  const isIsaacPay = isIsaacPaySchool(school)

  const { setHasCreditAccess } = useCreditAccessStore()

  useEffect(() => {
    setHasCreditAccess(!isIsaacPay && !isCollege && !school?.is_staged)
  }, [isIsaacPay, isCollege, school?.is_staged])

  useTrackPageView()

  useEffect(() => {
    const isDefaultSchoolEventSent =
      sessionStorage.getItem(ON_SELECT_SCHOOL_EVENT_SESSION_STORAGE_KEY) === 'true'

    if (!isDefaultSchoolEventSent) {
      isInitialized &&
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.HOME,
          name: EventDispatcherEvents.PAGE_VIEWED,
          action: 'page_view',
          customProperties: {
            $form_action: 'Acesso a escola padrão',
            $selected_school_slug: schoolSlug,
          },
        })

      sessionStorage.setItem(ON_SELECT_SCHOOL_EVENT_SESSION_STORAGE_KEY, 'true')
    }
  }, [])

  return <>{children}</>
}

export const SchoolUnitModule = () => {
  const moduleRegister = new ModuleRegister()

  useRegisterSchoolHomeModule(moduleRegister)
  useRegisterProductsModule(moduleRegister)
  useRegisterReportModule(moduleRegister)
  useNotificationModule(moduleRegister)
  useRegisterEnrollmentModule(moduleRegister)
  useRegisterContractModule(moduleRegister)
  useRegisterCreditModule(moduleRegister)
  useRegisterAccountReportsModule(moduleRegister)
  useRegisterManagementModule(moduleRegister)
  useRegisterAccessManagementModule(moduleRegister)
  useRegisterPaymentDisputeModule(moduleRegister)
  useRegisterStudentsModule(moduleRegister)
  useRegisterOnboardingModule(moduleRegister)
  useRegisterMessagesModule(moduleRegister)

  /* Register Legacy module as the last one to avoid routing errors */
  useEscolasModule(moduleRegister)

  return (
    <SchoolRoutesProviders>
      <SchoolUnitWrapper>
        <Router
          routes={moduleRegister.getRoutes()}
          loadingComponent={<CircularLoadingPlaceholder />}
        />
      </SchoolUnitWrapper>
    </SchoolRoutesProviders>
  )
}
