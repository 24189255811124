import { Tooltip } from '@gravity/tooltip'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  border-radius: 24px;
  padding: 3px 4px 3px 8px;
  align-items: center;
  width: max-content;

  p {
    margin-right: 4px;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  &.created {
    background-color: ${({ theme }) => theme.primitiveTokens.colors.yellow[20]};

    p,
    svg {
      color: ${({ theme }) => theme.primitiveTokens.colors.yellow[90]};
    }
  }

  &.effected {
    background-color: ${({ theme }) => theme.primitiveTokens.colors.green[20]};

    p,
    svg {
      color: ${({ theme }) => theme.primitiveTokens.colors.green[90]};
    }
  }
`

export const StyledTooltip = styled(Tooltip)`
  z-index: 1301;
`
