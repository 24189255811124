import { Table } from '@gravity/table'
import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-top: ${props => props.theme.gravity.spacing[8]};
  display: flex;
  overflow-x: auto;
  overflow-y: visible;

  padding: 1px;
`

export const TableHead = styled(Table.Head)`
  background-color: transparent;
`

export const TableHeaderCell = styled(Table.HeaderCell)`
  border-bottom: 1px solid ${props => props.theme.gravity.colors['colors-border-neutral-2']};
`

export const TableBody = styled(Table.Body)`
  > tr {
    border-top: unset;

    &:nth-child(even) {
      background-color: transparent;
    }

    &:nth-child(n + 3) {
      border-top: 1px solid ${props => props.theme.gravity.colors['colors-border-neutral-2']};
    }
  }
`

export const TableRowDivider = styled(Table.Row)`
  div {
    min-height: 70px;
  }
`

export const TableCustomCell = styled(Table.CustomCell)<{ small?: boolean }>`
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ small, theme }) => (small ? theme.gravity.spacing[1] : theme.gravity.spacing[4])};
`

export const TableBadge = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.gravity.spacing[2]};
`
