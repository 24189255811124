import { Skeleton } from '@gravity/skeleton'
import { Separator } from '@gravity/separator'

import {
  RowContainer,
  RowFlexContainer,
  TitleSkeleton,
  ContainerCard,
  TitleChosenOfferDate,
  SkeletonRows,
  SubTitleSkeleton,
} from './styles'

import { DefaultPageTemplate } from '../../../../templates/DefaultPageTemplate'

export const LoadingStatus = ({
  previousRoute,
  title,
}: {
  previousRoute: string
  title: string
}) => {
  return (
    <DefaultPageTemplate title={title} previousRoute={previousRoute}>
      <>
        <TitleSkeleton radius={8} width={600} height={40} />
        <SubTitleSkeleton radius={8} width={233} />

        <RowContainer>
          <ContainerCard>
            <TitleChosenOfferDate>
              <Skeleton radius={8} fullWidth />
            </TitleChosenOfferDate>
            <Separator color="neutral-2" orientation="horizontal" />

            <SkeletonRows radius={8} fullWidth />
            <SkeletonRows radius={8} fullWidth />
            <SkeletonRows radius={8} fullWidth />
            <SkeletonRows radius={8} fullWidth />
            <SkeletonRows radius={8} fullWidth />
          </ContainerCard>
        </RowContainer>

        <RowFlexContainer>
          <Skeleton radius={8} width={195} height={40} />
        </RowFlexContainer>
      </>
    </DefaultPageTemplate>
  )
}
