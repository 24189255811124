import dayjs from 'dayjs'

import { PAYOUT_REPORT_VERSIONS } from '@monorepo/report/constants/PAYOUT_REPORT_VERSIONS'

import { formatNumberMicroCentsToReal } from '@/shared/utils/numberFormatters'

import type { TuitionPayoutDetailsDTO } from '../../types'
import type { TuitionPayoutDataFormat } from './types'

/**
 * Utility function to parse a payout data into a compatible format for tuition payout report page
 *
 * @param payoutFormat Indicates the payload format
 * @param data Object to be parsed
 *
 * @returns Parsed object
 */
export const currentTuitionPayoutDetailsMapper = <T extends 'summary' | 'details'>(
  payoutFormat: T,
  data?: TuitionPayoutDataFormat[T]
): TuitionPayoutDetailsDTO | undefined => {
  if (!data) return

  if (payoutFormat === 'summary') {
    const summaryData = data as TuitionPayoutDataFormat['summary']

    return {
      payoutId: summaryData.payout_id,
      payoutDueDate: dayjs(summaryData.payout_due_date).toDate(),
      filtersIncluded: undefined,
      transactions: [],
      transactionsTotal: 0,
      income: { total: 0, items: {} },
      deductions: { total: 0, items: {} },
      bankTransfers: [],
      payoutAmount: summaryData.final_amount,
      uniqueStudents: 0,
      transactionsCount: 0,
      status: summaryData.payout_status,
      startedAt: undefined,
      endedAt: undefined,
      isNewVersion: summaryData.version === PAYOUT_REPORT_VERSIONS.DASHBOARD_PV,
    }
  }

  const detailsData = data as TuitionPayoutDataFormat['details']

  return {
    payoutId: detailsData.data.payout_id,
    payoutDueDate: dayjs(detailsData.data.payout.due_date).toDate(),
    filtersIncluded: detailsData.data.filters_included,
    transactions: detailsData.data.financial_transactions,
    transactionsTotal: detailsData.pagination?.total ?? 0,
    income: detailsData.data.agregations.financial_details?.income,
    deductions: detailsData.data.agregations.financial_details?.deductions,
    bankTransfers: detailsData.data.bank_transfers,
    payoutAmount: formatNumberMicroCentsToReal(
      detailsData.data.agregations.financial_details.total
    ),
    uniqueStudents: detailsData.data.agregations.unique_students ?? 0,
    transactionsCount: detailsData.data.agregations.platform_transaction_count ?? 0,
    fileVersion: detailsData.data.file_version,
    status: detailsData.data.payout.status,
    startedAt: dayjs(detailsData.data.started_at ?? undefined).toDate(),
    endedAt: dayjs(detailsData.data.ended_at ?? undefined).toDate(),
    isNewVersion: detailsData.data.payout.version === PAYOUT_REPORT_VERSIONS.DASHBOARD_PV,
  }
}
