import type { AxiosInstance } from 'axios'

import type {
  GetGuardianRequest,
  GetGuardianResponse,
  GetGuardiansRequest,
  GetGuardiansResponse,
} from './types'

export const guardianService = (fetcher: AxiosInstance) => {
  return {
    fetchGuardians: async (params: GetGuardiansRequest): Promise<GetGuardiansResponse> => {
      return (
        await fetcher.get<GetGuardiansResponse>('/v2/enrollment/guardian', {
          params: {
            school_id: params.schoolId,
            student_id: params.studentId,
            tax_id: params.taxID,
          },
        })
      ).data
    },
    /**
     * Function responsible for fetch Guardian data
     *
     * @param data.guardianId Guardian ID
     *
     * @returns Guardian details
     */
    fetchGuardian: async ({
      guardianId,
      schoolId,
    }: GetGuardianRequest): Promise<GetGuardianResponse> => {
      const { data } = await fetcher.get<GetGuardianResponse>(`/guardians/${guardianId}`, {
        params: { school_id: schoolId },
      })

      return data
    },
  }
}
