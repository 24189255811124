import styled from 'styled-components'
import { BookRead } from '@gravity/icons'
import { Text } from '@gravity/text'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: ${({ theme }) => theme.gravity.spacing[1]};
`

export const MenuBookIcon = styled(BookRead)`
  color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[60]};
`

export const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.gravity.spacing[1]};
`

export const Label = styled(Text).attrs(props => ({
  ...props,
  variant: 'body-2-regular',
  style: { color: props => props.theme.gravity.colors['colors-text-main-3'] },
}))`
  padding-right: ${({ theme }) => theme.gravity.spacing[2]};
`
