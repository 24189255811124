import { Avatar } from '@gravity/avatar'
import { Text } from '@gravity/text'

type AvatarDropdownUserInfoProps = {
  avatarText?: string
  subText: string
  text?: string
}

export const AvatarDropdownUserInfo = ({
  avatarText = '',
  text = '',
  subText,
}: AvatarDropdownUserInfoProps) => (
  <div className="flex items-center p-2 gap-3">
    <Avatar.Root size={40} color="gray">
      <Avatar.Fallback>{avatarText}</Avatar.Fallback>
    </Avatar.Root>

    <div>
      <Text as="p" variant="body-1-regular">
        {text}
      </Text>
      <Text as="p" variant="caption-regular">
        {subText}
      </Text>
    </div>
  </div>
)
