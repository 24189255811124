import { getParamGuardianId } from '../../utils/getParamGuardianId'

import { useEnrollmentCycle } from '@monorepo/enrollment/hooks/queries/useEnrollmentCycle'
import { DEFAULT_INITIAL_PAGE, DEFAULT_ITEMS_PER_PAGE } from './constants'
import { useSchoolEnrollmentCycleContext } from '@monorepo/enrollment/contexts/SchoolEnrollmentContext'
import { StudentsCarouselContainer } from './components/StudentsCarouselContainer'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useGetGuardian } from '@/shared/hooks/queries/guardian'

export const StudentsCarousel = () => {
  const { school } = useSelectedSchool()
  const guardianId = getParamGuardianId()

  const { data: guardian, isLoading: isFetchGuardianLoading } = useGetGuardian(
    {
      guardianId,
      schoolId: school?.id ?? '',
    },
    { enabled: !!school?.id }
  )

  const studentIds = guardian?.students?.map(s => s.id) ?? []

  const { selectedEnrollmentCycle, isFetchingCycles } = useSchoolEnrollmentCycleContext()
  const selectedYear = selectedEnrollmentCycle?.reference_year ?? 0
  const isLegacyYear = selectedYear < 2024
  const referenceYears = selectedYear ? [selectedYear, selectedYear - 1].map(String) : []
  const { data: students, isLoading: isLoadingStudents } = useEnrollmentCycle(
    {
      schoolId: school?.id ?? '',
      studentIds,
      referenceYears,
      pagination: {
        page: DEFAULT_INITIAL_PAGE,
        per_page: DEFAULT_ITEMS_PER_PAGE,
      },
    },
    { enabled: studentIds?.length > 0 && referenceYears.length > 0 && !isLegacyYear }
  )

  const isLoading =
    !isLegacyYear && (isFetchGuardianLoading || isLoadingStudents || isFetchingCycles)

  return <StudentsCarouselContainer students={students?.data} isLoading={isLoading} />
}
