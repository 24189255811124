import { PAYOUT_REPORT_STATUS } from '@monorepo/report/constants/PAYOUT_REPORT_STATUS'

import type { UnguaranteedReport } from '@monorepo/report/models/UnguaranteedReport'
import { IsaacPayPayoutReportData } from '../../../../components/IsaacPayReportDashboardCard'

/**
 * Utility function to parse payout to a compatible format with Enrollment dashboard card component
 *
 * @param payout Enrollment payout report to be parsed
 *
 * @returns Parsed enrollment payout report
 */
export const isaacPayPayoutReportDataMapper = (
  payout: UnguaranteedReport
): IsaacPayPayoutReportData => {
  const payoutValue = payout.agregations.financial_details.total

  return {
    id: payout.payout_id,
    isOpen: payout.payout.status === PAYOUT_REPORT_STATUS.OPEN,
    payoutValue: payoutValue,
    started_at: payout.started_at,
    ended_at: payout.ended_at,
  }
}
