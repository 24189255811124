import {
  CONTRACT_STATUS_OPTIONS,
  DEBT_STATUS_OPTIONS,
} from '../../components/GuardianDetailsContractsFilter/constants'

type UseTextFilterOptionsType = {
  label: string
  value: string
}

/**
 * Custom hook to manage filter options label at Guardian contracts page
 */
export const useTextFilterOptions = ({ value, label }: UseTextFilterOptionsType) => {
  const text = () => {
    const debtStatusOption = DEBT_STATUS_OPTIONS.find(
      debtStatusOption => debtStatusOption?.value === value
    )

    const contractStatusOption = CONTRACT_STATUS_OPTIONS.find(
      contractStatusOption => contractStatusOption?.value === value
    )

    if (label === debtStatusOption?.value) {
      return debtStatusOption.label
    }
    if (label === contractStatusOption?.value) {
      return contractStatusOption.label
    }

    return label
  }

  return text()
}
