import { Select } from '@gravity/select'
import { TextArea } from '@gravity/text-area'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.gravity.spacing[2]};
`

export const StyledSelect = styled(Select)`
  width: 100%;
`

export const StyledTextArea = styled(TextArea)`
  width: 100%;
`
