import { FooterCheckout } from '@gravity/footer'
import { Popover } from '@gravity/popover'
import styled from 'styled-components'

export const FooterStyled = styled(FooterCheckout)`
  left: 96px;
  z-index: 50;
`
export const PopoverContentStyled = styled(Popover.Content)`
  z-index: 999;
`
