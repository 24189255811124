import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.gravity.spacing['14']};
  gap: ${({ theme }) => theme.gravity.spacing['10']};
`

export const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.gravity.spacing['3']};
`
