import { Button } from '@gravity/button'
import { Dialog } from '@gravity/dialog'
import { useEffect, useMemo, useState } from 'react'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { REPORT_PAGE_NAMES } from '@monorepo/report/constants/REPORT_PAGE_NAMES'
import { FilterOptions } from '@monorepo/report/models/report'
import { Content } from './components/Content'

interface FilterHook<T> {
  clearFilter: () => any
  filter: T
  filterCount: number
  updateFilter: (filter: T) => void
}

type Props<T> = {
  availableFilters: FilterOptions<T>
  filterHook: () => FilterHook<T>
  filterTypes: Record<keyof T, string>
  isOpen: boolean
  onClose: () => void
  reportPage: typeof REPORT_PAGE_NAMES[keyof typeof REPORT_PAGE_NAMES]
}

// TODO: check if the Filter Dialog on `shared` can be used instead of this one
export const ReportFilterDialog = <Filter extends Record<string, string[]>>({
  isOpen,
  availableFilters,
  filterHook,
  filterTypes,
  onClose,
  reportPage,
}: Props<Filter>) => {
  const { filter, updateFilter } = filterHook()
  const [selectedFilters, setSelectedFilters] = useState<Filter>(() => filter)
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const isAnyFilterSelected = useMemo(() => {
    return Object.values(selectedFilters).some(filter => filter.length > 0)
  }, [selectedFilters])

  useEffect(() => {
    setSelectedFilters(filter)
  }, [filter, isOpen])

  const sendEventDispatcherEvent = (action: 'apply' | 'reset') => {
    const filteredCategories = Object.entries(filterTypes).reduce<string[]>((acc, [key, value]) => {
      if (selectedFilters[key] && selectedFilters[key].length > 0) {
        return [...acc, value]
      }

      return acc
    }, [])

    const eventName =
      action === 'apply' ? EventDispatcherEvents.APPLY_FILTER : EventDispatcherEvents.RESET_FILTER

    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: eventName,
        scope: EventDispatcherEventScopes.PAYOUT_REPORT,
        action: 'click',
        customProperties: {
          $page_name: reportPage,
          ...(action === 'apply' && { $filtered_categories: filteredCategories }),
        },
      })
  }

  const onFilterSelect = <K extends keyof Filter>(filterType: K, value: Filter[K][number]) => {
    const isFilterAlreadySelected = (selectedFilters[filterType] as Array<typeof value>)?.includes(
      value
    )

    setSelectedFilters({
      ...selectedFilters,
      [filterType]: isFilterAlreadySelected
        ? (selectedFilters[filterType] as Filter[K]).filter(v => v !== value)
        : [...(selectedFilters[filterType] || []), value],
    })
  }

  const applyFilters = () => {
    updateFilter(selectedFilters)
    sendEventDispatcherEvent(isAnyFilterSelected ? 'apply' : 'reset')
    onClose()
  }

  const resetFilters = () => {
    setSelectedFilters({} as Filter)
  }

  return (
    <Dialog
      open={isOpen}
      backdrop
      size={3}
      title="Filtros da tabela"
      onOpenChange={isOpen => !isOpen && onClose()}
      content={
        <Content
          availableFilters={availableFilters}
          filterTypes={filterTypes}
          onFilterSelect={onFilterSelect}
          selectedFilters={selectedFilters}
        />
      }
      actionButton={<Button onClick={applyFilters}>Aplicar filtros</Button>}
      cancelButton={
        <Button
          onClick={e => {
            e.preventDefault()
            resetFilters()
          }}
          disabled={!isAnyFilterSelected}
          variant="ghost"
        >
          Remover filtros
        </Button>
      }
    />
  )
}
