import { Text } from '@gravity/text'
import { Accordion } from '@gravity/accordion'

type InstallmentDrawerAccordionProps = {
  children?: React.ReactNode
  disabled?: boolean
  id: string
  title: string
}

export const InstallmentDrawerAccordion = ({
  children,
  disabled,
  id,
  title,
}: InstallmentDrawerAccordionProps) => {
  return (
    <Accordion.Root type="single" collapsible disabled={disabled}>
      <Accordion.Item key={id} value={`item-${id}`}>
        <Accordion.Trigger>
          <Text variant="body-1-medium">{title}</Text>
        </Accordion.Trigger>
        <Accordion.Content>{children}</Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  )
}
