import { Link, useRouteMatch } from 'react-router-dom'
import { Sidemenu } from '@gravity/sidemenu'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import { useSidemenuEvents } from '../hooks/useSidemenuEvents'
import { getNavigationURL } from '../utils/getNavigationURL'

export const OperationCategoryItems = () => {
  const { school } = useSelectedSchool()
  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)
  const {
    handleClickEnrollmentPath,
    handleClickGuardianPath,
    handleClickProductsPath,
    handleClickIrregularitiesPath,
  } = useSidemenuEvents()

  const enrollmentPath = `/${school?.slug}/matriculas`
  const studentPath = `/${school?.slug}/alunos`
  const irregularitiesPath = `/${school?.slug}/irregularidades`
  const guardiansPath = getNavigationURL({ schoolSlug: school?.slug, path: '/responsaveis' })
  const productsPath = getNavigationURL({ schoolSlug: school?.slug, path: '/produtos' })

  return (
    <>
      <Sidemenu.CategoryItem
        as={Link}
        text="Matrículas"
        active={
          Boolean(useRouteMatch({ path: enrollmentPath })) ||
          Boolean(useRouteMatch({ path: studentPath }))
        }
        to={enrollmentPath}
        onClick={handleClickEnrollmentPath}
      />
      <Sidemenu.CategoryItem
        text="Responsáveis"
        as={Link}
        active={Boolean(useRouteMatch({ path: guardiansPath }))}
        to={guardiansPath}
        onClick={handleClickGuardianPath}
      />
      <Sidemenu.CategoryItem
        as={Link}
        text="Produtos"
        active={Boolean(useRouteMatch({ path: productsPath }))}
        to={productsPath}
        onClick={handleClickProductsPath}
      />
      {isIntegratedSchool && (
        <Sidemenu.CategoryItem
          as={Link}
          text="Pendências de registro"
          active={Boolean(useRouteMatch({ path: irregularitiesPath }))}
          to={irregularitiesPath}
          onClick={handleClickIrregularitiesPath}
        />
      )}
    </>
  )
}
