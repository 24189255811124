import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react'
import { ApiClient } from '@/shared/core/api'

type ApiClientContext = {
  apiClient: ApiClient
  correlationId: null | string
  setCorrelationId: Dispatch<SetStateAction<string>> | null
}

export const ApiClientContext = createContext<ApiClientContext>({} as ApiClientContext)

const apiClient = new ApiClient()

export const ApiClientProvider = ({ children }: { children: ReactNode }) => {
  const [correlationId, setCorrelationId] = useState('')

  return (
    <ApiClientContext.Provider
      value={{
        apiClient: apiClient,
        correlationId,
        setCorrelationId,
      }}
    >
      {children}
    </ApiClientContext.Provider>
  )
}
