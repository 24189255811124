import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Table } from '@gravity/table'
import { useHistory } from 'react-router-dom'

import { Contract, ErrorDialog } from '@/modules/guardians/GuardianDetails/types'
import { debtStatusVariant } from '@/modules/guardians/GuardianDetails/components/GuardianDetailsCard/components/DebtStatus/types'
import { DebtStatus } from '@/shared/interfaces'
import { GuardianDetailsContractsTableProps } from './types'
import { contractStatusDict } from '../ContractStatus/constants'
import { contractStatusDict as contractStatusBadge } from '../ContractStatusBadge/constants'
import { GuardianDetailsContractsTableActions } from '../GuardianDetailsContractsTableActions'
import {
  FailureFeedbackContent,
  FailureFeedbackDialog,
} from '@/escolas/components/modal/FeedbackCarneDialog'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useCancelContractModal } from '@/modules/contract/pages/EditContract/components/CancelContractModal/useCancelContractModal'
import { CancelContractModal } from '@/modules/contract/pages/EditContract/components/CancelContractModal'
import { queryClient } from '@/shared/contexts/ReactQuery'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

export const GuardianDetailsContractsTable = ({
  contracts,
  contractsData,
  isLoading,
  openContractDetails,
  openCancelContractDrawer,
  onGeneratePaymentStatement,
  guardianId,
  onClickFilterButton,
  onRemoveFilter,
  filtersArray,
}: GuardianDetailsContractsTableProps) => {
  const [errorDialog, setErrorDialog] = useState<ErrorDialog>({ visible: false })

  const { school } = useSelectedSchool()
  const history = useHistory()

  const cancelContractNewRules = useUnleashFlag(UnleashFlags.EFI_215_CANCEL_CONTRACT_NEW_RULES)

  const cancelContractModalState = useCancelContractModal()

  const handleClickViewConditions = (contract: Contract) => {
    if (contract.debt_status !== DebtStatus.PENDING) openContractDetails(contract.id)
  }

  const goToChangeOwnershipPage = (contractId: uuid) => {
    if (!school) return
    history.push(
      `/${school.slug}/contratos/troca-titularidade/${guardianId}?selectedContracts=${contractId}`
    )
  }

  return (
    <div className="overflow-x-auto overflow-y-hidden p-[1px]">
      <Table.Root className="mb-16">
        <Table.Search
          variant="filters-only"
          filters={filtersArray}
          onClickFilterButton={onClickFilterButton}
          onRemoveFilter={onRemoveFilter}
        />
        <Table.Head>
          <Table.HeaderCell name="contract" minWidth={80}>
            Ano
          </Table.HeaderCell>
          <Table.HeaderCell name="status" minWidth={80}>
            Status
          </Table.HeaderCell>
          <Table.HeaderCell name="student" minWidth={80}>
            Aluno
          </Table.HeaderCell>
          <Table.HeaderCell name="product" minWidth={80}>
            Produto
          </Table.HeaderCell>
          <Table.HeaderCell name="installments" minWidth={200}>
            Situação do contrato
          </Table.HeaderCell>
          <Table.HeaderCell name="total" minWidth={180}>
            Situação financeira
          </Table.HeaderCell>
        </Table.Head>
        <Table.Body>
          {isLoading ? (
            <Table.Row>
              {Array.from({ length: 6 }).map(() => (
                <Table.LoadingCell key={uuidv4()} />
              ))}
            </Table.Row>
          ) : (
            contracts.map(contract => (
              <Table.Row
                key={contract.id}
                onClick={() => handleClickViewConditions(contract)}
                className="hover:cursor-pointer"
              >
                <Table.TextCell>{contract.reference_year}</Table.TextCell>
                <Table.TextCell>{contractStatusDict[contract.status]?.text ?? ''}</Table.TextCell>
                <Table.TextCell>{contract.student.name}</Table.TextCell>
                <Table.TextCell>{contract.product.name}</Table.TextCell>
                <Table.TextCell>
                  {contractStatusBadge[contract.pre_contract_status]?.text ?? ''}
                </Table.TextCell>
                <Table.BadgeCell
                  badgeColor={debtStatusVariant[contract.debt_status].color}
                  badgeVariant="soft"
                >
                  {debtStatusVariant[contract.debt_status].text}
                </Table.BadgeCell>
                <GuardianDetailsContractsTableActions
                  contract={contract}
                  contractData={contractsData.find(c => c.id === contract.id)}
                  openCancelContractDrawer={
                    cancelContractNewRules
                      ? cancelContractModalState.open
                      : openCancelContractDrawer
                  }
                  onGeneratePaymentStatement={onGeneratePaymentStatement}
                  goToChangeOwnershipPage={goToChangeOwnershipPage}
                  setErrorDialog={setErrorDialog}
                />
              </Table.Row>
            ))
          )}
        </Table.Body>
      </Table.Root>
      <FailureFeedbackDialog
        isVisible={errorDialog.visible}
        buttonLabel="Entendi"
        onClose={() => setErrorDialog({ visible: false })}
        submitHandler={() => setErrorDialog({ visible: false })}
      >
        <FailureFeedbackContent err={errorDialog.message} />
      </FailureFeedbackDialog>

      {cancelContractNewRules && (
        <CancelContractModal
          state={cancelContractModalState}
          onSuccess={() => {
            queryClient.invalidateQueries(['guardian-details-contracts'])
          }}
        />
      )}
    </div>
  )
}
