import styled from 'styled-components'

export const BadgeContainer = styled.div`
  margin-bottom: 16px;
`

export const TitleContainer = styled.div`
  margin-bottom: 8px;
`

export const DescriptionContainer = styled.div`
  display: block;
`
