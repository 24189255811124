import { Table } from '@gravity/table'
import { Tooltip } from '@gravity/tooltip'
import { IconButton } from '@gravity/icon-button'
import type { MouseEvent } from 'react'

import {
  CampaignClosedType,
  CampaignStatus,
} from '@monorepo/enrollment/services/enrollmentService/types'
import { formatDate } from '@/shared/utils'
import type { Ordering, SortOrder } from '@/shared/hooks/useOrdering/types'
import { CloseCampaignDialog } from '@monorepo/enrollment/components/CloseCampaignDialog'
import { useEnrollmentMixpanelEvents } from '@monorepo/enrollment/hooks/useEnrollmentMixpanelEvents'

import { useCloseCampaignDialog } from './hooks/useCloseCampaignDialog'
import type { TableRowProps, ColumnProps, SortableColumns } from './types'
import { columnNames } from './types'

import * as Styled from './styles'
import type { PaginationHookType, PaginationType } from '@/shared/hooks/usePagination/types'
import { CloseCircleOutline } from '@gravity/icons'

interface CampaignsTableProps {
  isLoading: boolean
  noDataMessage?: string
  onChangeOrdering: (sortBy: SortableColumns, sortOrder: SortOrder) => void
  onClickRow: (rowKey: string) => void
  ordering: Ordering
  pagination: PaginationType
  rows: TableRowProps[]
  totalPagination?: number
  updatePaginationValue: PaginationHookType['updatePaginationValue']
}

const setCampaignStatusSubtext = (closureType: CampaignClosedType | ''): string => {
  switch (closureType) {
    case CampaignClosedType.MANUALLY:
      return 'Manualmente'
    case CampaignClosedType.AUTOMATICALLY:
      return 'Por validade'
    default:
      return ''
  }
}

export const CampaignsTable = ({
  rows,
  isLoading = true,
  ordering,
  onChangeOrdering,
  noDataMessage,
  onClickRow,
  totalPagination,
  pagination,
  updatePaginationValue,
}: CampaignsTableProps) => {
  const { closeDialog, dialogVisible, openDialog, selectedRow } = useCloseCampaignDialog()

  const COLUMN_HEADERS: Array<ColumnProps> = [
    { key: columnNames.NAME, label: 'Campanha', sortable: true, minWidth: '196px' },
    {
      key: columnNames.STUDENTS_COUNT,
      label: 'Alunos incluídos',
      sortable: false,
      minWidth: '136px',
    },
    {
      key: columnNames.TOTAL_OF_CAMPAIGN_EMAILS_READ,
      label: 'Emails abertos',
      sortable: false,
      minWidth: '136px',
    },
    {
      key: columnNames.ENGAGED_STUDENTS_COUNT,
      label: 'Alunos interessados',
      sortable: false,
      minWidth: '136px',
    },
    {
      key: columnNames.PAYMENTS_CONFIRMED,
      label: 'Pagamentos confirmados',
      sortable: false,
      minWidth: '136px',
    },
    { key: columnNames.END_DATE, label: 'Validade', sortable: false, minWidth: '136px' },
    { key: columnNames.STATUS, label: 'Situação', sortable: false, minWidth: '136px' },
  ]
  const { sendButtonClickEvent } = useEnrollmentMixpanelEvents()

  if (!isLoading && !rows?.length) {
    return <Styled.EmptyStateContainer>{noDataMessage}</Styled.EmptyStateContainer>
  }

  return (
    <>
      <Table.Root>
        <Table.Head>
          {COLUMN_HEADERS.map(({ minWidth, label, key, sortable = false }) => (
            <Styled.TableHeaderCell
              style={{ minWidth }}
              key={key}
              name={key}
              sortable={!isLoading && sortable}
              sortOrder={
                ordering.sortBy === key && ordering.sortOrder
                  ? (ordering.sortOrder.toLowerCase() as Lowercase<SortOrder>)
                  : undefined
              }
              onSort={(sortBy, sortOrder) =>
                onChangeOrdering(
                  sortBy as SortableColumns,
                  sortOrder.toLocaleUpperCase() as Uppercase<'asc' | 'desc'>
                )
              }
            >
              {label}
            </Styled.TableHeaderCell>
          ))}
        </Table.Head>
        <Table.Body>
          {isLoading ? (
            <>
              <Table.Row>
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
              </Table.Row>

              <Table.Row>
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
              </Table.Row>

              <Table.Row>
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
                <Table.LoadingCell />
              </Table.Row>
            </>
          ) : (
            rows.map(row => {
              const statusBadgeColor = row.columns[5] === CampaignStatus.OPEN ? 'accent' : 'neutral'
              const statusBadgeLabel =
                row.columns[5] === CampaignStatus.OPEN ? 'Iniciada' : 'Encerrada'
              const statusBadgeSubtext = setCampaignStatusSubtext(
                row.columns[6] as CampaignClosedType | ''
              )

              return (
                <Table.Row key={row.key} onClick={() => onClickRow(row.key)}>
                  <Table.TextCell subtext={row.columns[1]}>{row.columns[0]}</Table.TextCell>
                  <Table.TextCell>{row.columns[2]}</Table.TextCell>
                  <Table.TextCell>{row.columns[7]}</Table.TextCell>
                  <Table.TextCell>{row.columns[3]}</Table.TextCell>
                  <Table.TextCell>{row.columns[8]}</Table.TextCell>
                  <Table.TextCell>{formatDate(row.columns[4], 'DD/MM/YYYY')}</Table.TextCell>
                  <Table.BadgeCell
                    subtext={statusBadgeSubtext}
                    badgeVariant="soft"
                    badgeColor={statusBadgeColor}
                  >
                    {statusBadgeLabel}
                  </Table.BadgeCell>

                  {row.columns[5] === CampaignStatus.OPEN && (
                    <Table.ActionCell
                      actions={
                        <Tooltip text="Encerrar campanha" position="left">
                          <IconButton
                            variant="ghost"
                            onClick={(event: MouseEvent<HTMLButtonElement>) => {
                              event.stopPropagation()
                              sendButtonClickEvent('encerrar_campanha_tabela')
                              openDialog(row)
                            }}
                          >
                            <CloseCircleOutline />
                          </IconButton>
                        </Tooltip>
                      }
                    />
                  )}
                </Table.Row>
              )
            })
          )}
        </Table.Body>
        {totalPagination && (
          <Table.Pagination
            page={pagination.page}
            itemsPerPage={pagination.itemsPerPage}
            total={totalPagination}
            onChangePage={newPage => {
              updatePaginationValue('page', newPage, true)
              window.scrollTo(0, 0)
            }}
            onChangeItemsPerPage={newItensPerPage => {
              updatePaginationValue('itemsPerPage', newItensPerPage, true)
            }}
          />
        )}
      </Table.Root>

      {selectedRow && (
        <CloseCampaignDialog
          visible={dialogVisible}
          closeDialog={closeDialog}
          campaignId={selectedRow.key}
          hasPaymentPlan={selectedRow.hasPaymentPlan}
        />
      )}
    </>
  )
}
