import { Dialog } from '@gravity/dialog'
import { Button } from '@gravity/button'
import { Text } from '@gravity/text'

type ConfirmQuitDialogProps = {
  onClose: () => void
  onConfirm: () => void
  open: boolean
}

export const ConfirmQuitDialog = ({ open, onClose, onConfirm }: ConfirmQuitDialogProps) => {
  return (
    <Dialog
      title="Cancelar edição"
      open={open}
      size={2}
      onOpenChange={onClose}
      backdrop
      content={
        <Text variant="body-1-regular">
          Se você sair da página agora, as edições não serão salvas.
        </Text>
      }
      actionButton={
        <Button variant="ghost" onClick={onClose}>
          Continuar editando
        </Button>
      }
      cancelButton={<Button onClick={onConfirm}>Sair sem salvar</Button>}
    />
  )
}
