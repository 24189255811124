import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.gravity.colors['colors-border-neutral-3']};
  border-radius: ${({ theme }) => theme.gravity.borderRadius['2']};
  padding: ${({ theme }) => theme.gravity.spacing['8']};
  gap: ${({ theme }) => theme.gravity.spacing['2']};
`

export const IconWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.gravity.spacing['3']};
`
