import { InformationOutline, CalendarEvent } from '@gravity/icons'
import { Separator } from '@gravity/separator'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'

import { convertToUTCDate, formatMicroCentsToReal } from '@/shared/utils'
import { ISAAC_PAY_DRAWER_ITEMS } from './constants/isaacPayDrawerItems'
import { GrayText, StyledTooltip } from '../../styles'

import { FetchAggregatedProvidersReportResponseDTO } from '@monorepo/report/services/report/types'
import { BankTransfer } from '@monorepo/report/models/report'

type ExternalPaymentProps = {
  bankTransfers: BankTransfer[]
  externalPaymentSummary: FetchAggregatedProvidersReportResponseDTO['data']
}

/**
 * Drawer for payout content. This content is for external payout providers
 * @param props
 * @param props.bankTransfers List of bank transfers
 * @param props.externalPaymentSummary Payout summary with totals and fees
 */
export const IsaacPayExternalPaymentDrawerContent = ({
  externalPaymentSummary,
  bankTransfers,
}: ExternalPaymentProps) => {
  return (
    <div className="flex flex-col gap-6">
      <GrayText variant="body-1-medium">Pagamentos recebidos pela escola</GrayText>
      <div className="flex justify-between items-center">
        <GrayText variant="body-1-regular">{ISAAC_PAY_DRAWER_ITEMS.TOTAL_EXTERNAL.label}</GrayText>
        <Text variant="body-1-medium">
          {formatMicroCentsToReal(externalPaymentSummary?.external_total_base_amount)}
        </Text>
      </div>

      <Separator orientation="horizontal" color="neutral-2" />
      <GrayText variant="body-1-medium">Pagamentos recebidos pelo isaac</GrayText>

      <div className="flex justify-between items-center">
        <GrayText variant="body-1-regular">{ISAAC_PAY_DRAWER_ITEMS.TOTAL_PAYOUT.label}</GrayText>
        <Text variant="body-1-medium">
          {formatMicroCentsToReal(externalPaymentSummary?.payout_total_base_amount)}
        </Text>
      </div>

      <div className="flex justify-between items-center">
        <GrayText variant="body-1-regular" className="flex items-center">
          {ISAAC_PAY_DRAWER_ITEMS.PAYOUT_FEE.label}
          <StyledTooltip text={ISAAC_PAY_DRAWER_ITEMS.PAYOUT_FEE.description}>
            <span className="flex items-center">
              <InformationOutline size="xs" />
            </span>
          </StyledTooltip>
        </GrayText>
        <Text variant="body-1-medium">
          {formatMicroCentsToReal(-1 * externalPaymentSummary?.payout_total_fee)}
        </Text>
      </div>

      <Separator orientation="horizontal" color="neutral-2" />

      <div className="flex justify-between items-center">
        <Text variant="body-1-medium">{ISAAC_PAY_DRAWER_ITEMS.TOTAL.label}</Text>
        <Text variant="body-1-medium">{formatMicroCentsToReal(externalPaymentSummary?.total)}</Text>
      </div>

      <Separator orientation="horizontal" color="neutral-2" />

      <div className="flex justify-between items-center">
        <GrayText variant="body-1-regular">{ISAAC_PAY_DRAWER_ITEMS.EXTERNAL_FEE.label}</GrayText>
        <Text variant="body-1-medium">
          {formatMicroCentsToReal(externalPaymentSummary?.external_total_fee)}
        </Text>
      </div>

      {bankTransfers.length > 0 && (
        <>
          <Separator orientation="horizontal" color="neutral-2" />

          <div className="flex flex-col gap-6">
            <div className="flex items-center gap-2">
              <CalendarEvent size="sm" />
              <Heading variant="heading-h4-regular">Transferências programadas</Heading>
            </div>

            <div className="flex flex-col gap-4">
              {bankTransfers.map((item, index) => (
                <div key={index} className="flex justify-between items-center">
                  <Text variant="body-1-regular" className="text-gray">
                    {convertToUTCDate(item.date).format('DD/MM/YYYY')}
                  </Text>

                  <Text variant="body-1-medium">{formatMicroCentsToReal(item.amount)}</Text>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
