import { useSchoolEnrollmentCycles } from '@/shared/hooks/useSchoolEnrollmentCycles'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'

// TODO: Evaluate migrating this hook to the `shared` package during the `guardians` module migration.
const useAddContractEnabled = (): boolean => {
  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)
  const { hasAnyEnabledCycle } = useSchoolEnrollmentCycles()

  return !isIntegratedSchool && hasAnyEnabledCycle
}

export default useAddContractEnabled
