import { z } from 'zod'

import { validateCpf, validatePhoneNumber } from '@/shared/utils'

import type { AddNewGuardianFormData } from './types'

export const defaultFormData: AddNewGuardianFormData = {
  tax_id: '',
  address: {
    additional_information: '',
    number: '',
    street: '',
    zip: '',
    city: '',
    state_code: '',
  },
  email: '',
  name: '',
  phone_number: '',
}

export const AddNewGuardianFormSchema = z
  .object({
    tax_id: z
      .string()
      .min(1, { message: 'Informe o CPF' })
      .refine(taxId => taxId.length === 11 && validateCpf(taxId), {
        message: 'Informe um CPF válido',
      }),
    email: z.string().email('Formato de e-mail inválido.').min(1, { message: 'Informe o e-mail' }),
    name: z.string().trim().min(1, { message: 'Informe o nome do responsável' }),
    phone_number: z
      .string({ message: 'Informe o número de celular' })
      .trim()
      .min(1, { message: 'Informe o número de celular' })
      .refine(phone => validatePhoneNumber(phone), {
        message: 'Informe um número de celular válido',
      }),
    address: z.object({
      additional_information: z.string(),
      number: z.string().min(1, { message: 'Informe o número' }),
      street: z.string().min(1, { message: 'Informe o logradouro' }),
      zip: z
        .string({ message: 'Informe o cep' })
        .min(1, { message: 'Informe o cep' })
        .length(8, { message: 'Cep inválido' }),
      city: z.string().min(1, { message: 'Informe sua cidade' }),
      state_code: z.string({ message: 'Informe seu estado' }),
    }),
  })
  .required()
