import { useEffect, useState } from 'react'

import { useQuery } from '@/shared/hooks/useQuery'
import { InstallmentsFiltersType } from '../types'
import { ContractStatus, InstallmentType } from '@/shared/interfaces'
import {
  GuardiansInstallmentsQueryParamsNameEnum,
  InstallmentStatus,
} from '../../GuardianDetails/constants'

const parseArrayToFilter = <T>(filter: string, defaultValue?: T[]) => {
  if (!filter || filter === '') {
    if (defaultValue && defaultValue.length > 0) {
      return defaultValue
    }
    return []
  }
  return filter.split(',') as T[]
}

/**
 * @description Hook to handle query params for guardian installments tab
 */
export const useGuardiansInstallmentsQueryParams = (hasDefaultFilters?: boolean) => {
  const defaultFilters: InstallmentsFiltersType = {
    installment_statuses: [],
    installment_types: [],
    reference_years: [],
    student_ids: [],
    product_ids: [],
    contract_status: undefined,
    negotiation_id: undefined,
    contract_id: undefined,
  }

  const { query, setMultipleQueryParams } = useQuery()

  const [filters, setFilters] = useState<InstallmentsFiltersType>({
    installment_statuses: parseArrayToFilter<InstallmentStatus>(
      query.get(GuardiansInstallmentsQueryParamsNameEnum.STATUSES)?.toString() ?? '',
      hasDefaultFilters ? defaultFilters?.installment_statuses : undefined
    ),
    installment_types: parseArrayToFilter<InstallmentType>(
      query.get(GuardiansInstallmentsQueryParamsNameEnum.TYPES)?.toString() ?? '',
      hasDefaultFilters ? defaultFilters?.installment_types : undefined
    ),
    reference_years: parseArrayToFilter<string>(
      query.get(GuardiansInstallmentsQueryParamsNameEnum.YEARS)?.toString() ?? '',
      hasDefaultFilters ? defaultFilters?.reference_years : undefined
    ),
    student_ids: parseArrayToFilter<string>(
      query.get(GuardiansInstallmentsQueryParamsNameEnum.STUDENT_IDS)?.toString() ?? '',
      hasDefaultFilters ? defaultFilters?.student_ids : undefined
    ),
    product_ids: parseArrayToFilter<string>(
      query.get(GuardiansInstallmentsQueryParamsNameEnum.PRODUCT_IDS)?.toString() ?? '',
      hasDefaultFilters ? defaultFilters?.product_ids : undefined
    ),
    contract_status: (query.get(GuardiansInstallmentsQueryParamsNameEnum.CONTRACT_STATUS) ??
    hasDefaultFilters
      ? defaultFilters?.contract_status
      : null) as ContractStatus,
    negotiation_id: query.get(GuardiansInstallmentsQueryParamsNameEnum.NEGOTIATION_ID)?.toString(),
    contract_id: query.get(GuardiansInstallmentsQueryParamsNameEnum.CONTRACT_ID)?.toString(),
  })

  const clearFilters = () => {
    setFilters({
      installment_statuses: [],
      installment_types: [],
      reference_years: [],
      student_ids: [],
      product_ids: [],
      contract_status: undefined,
      negotiation_id: undefined,
      contract_id: undefined,
    })
  }

  const updateFilters = (data: InstallmentsFiltersType) => {
    setFilters(data)
  }

  const filtersToQueryParams = (filters: InstallmentsFiltersType) =>
    Object.entries(filters).reduce<{ name: string; value: string | undefined }[]>(
      (prev, [name, value]) => {
        prev.push({ name, value: value && value.length ? value.toString() : undefined })
        return prev
      },
      []
    )

  useEffect(() => {
    setMultipleQueryParams([...filtersToQueryParams(filters)], 'replace')
  }, [filters])

  return {
    filters,
    clearFilters,
    updateFilters,
  }
}
