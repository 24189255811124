import type { ConclusionProps } from './types'
import * as S from './styles'
import { LiquidationPaymentMethodAdminLabel } from '@/shared/interfaces'
import { useEffect } from 'react'
import { mixpanel, MixpanelEventsEnum } from 'src/shared/integrations'
import { paramsDict } from 'src/escolas/components/contract/utils'
import { Callout } from '@gravity/callout'
import { Table } from '@gravity/table'
import StatusBadge from '@/shared/components/StatusBadge'
import dayjs from 'dayjs'
import { formatCentsToReal } from '@/shared/utils'
import PrintReceiptButton from '@/modules/guardians/Negotiation/components/PrintReceiptButton'

const Conclusion = ({ liquidationInfo, onSuccess }: ConclusionProps) => {
  useEffect(() => {
    mixpanel.trackEvent({
      name: MixpanelEventsEnum.METHODS_INCOMING_REGISTRATION_FLOW_FINISHED,
      properties: {
        $receivableId: liquidationInfo?.receivableId,
        $status: paramsDict.PAID,
      },
    })
  }, [])

  return (
    <S.Wrapper>
      <S.MarginDiv>
        <Callout text="Pagamento realizado com sucesso!" />
      </S.MarginDiv>
      <Table.Root>
        <Table.Head>
          <Table.HeaderCell name="installment">{liquidationInfo.orderReference}</Table.HeaderCell>
          <Table.HeaderCell name="payment_method">Meio de pagamento</Table.HeaderCell>
          <Table.HeaderCell name="payment_date">Data de pagamento</Table.HeaderCell>
          <Table.HeaderCell name="status">Situação</Table.HeaderCell>
          <Table.HeaderCell name="actions">Ações</Table.HeaderCell>
        </Table.Head>

        <Table.Body>
          <Table.Row>
            <Table.TextCell>{formatCentsToReal(liquidationInfo.amount)}</Table.TextCell>
            <Table.TextCell>
              {liquidationInfo.paymentMethod
                ? (LiquidationPaymentMethodAdminLabel[
                    liquidationInfo.paymentMethod as keyof typeof LiquidationPaymentMethodAdminLabel
                  ] as string)
                : 'Conta isaac'}
            </Table.TextCell>
            <Table.TextCell>
              {dayjs(liquidationInfo.paidDate).utc().format('DD/MM/YYYY')}
            </Table.TextCell>
            <Table.CustomCell>
              <div className="flex flex-row items-center gap-2">
                <StatusBadge color="success" />
                Paga
              </div>
            </Table.CustomCell>
            <Table.CustomCell>
              <PrintReceiptButton
                receivableId={liquidationInfo.receivableId}
                onSuccess={onSuccess}
              />
            </Table.CustomCell>
          </Table.Row>
        </Table.Body>
      </Table.Root>
    </S.Wrapper>
  )
}

export default Conclusion
