import { Table } from '@gravity/table'
import { useBatchUnfinishedStudentsQuery } from '../../hooks/useBatchUnfinishedStudentsQuery'
import { useHistory, useParams } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom-v5-compat'
import { debounce } from 'throttle-debounce'
import { usePagination } from '@/shared/hooks/usePagination'
import { LoadingTable } from '../../components/LoadingTable'
import { GenericError } from '@monorepo/onboarding/pages/components/GenericError'
import { GuardianTableCell } from './GuardianTableCell'
import { IconButton } from '@gravity/icon-button'
import { PencilOutline } from '@gravity/icons'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

export const StudentsTable = () => {
  const { batchID } = useParams<{ batchID: string }>()
  const [searchParams, setSearchParams] = useSearchParams()
  const { pagination, updatePaginationValue } = usePagination()
  const history = useHistory()
  const { schoolSlug } = useSelectedSchool()

  const search = searchParams.get('search') ?? ''

  const {
    data: { data: unfinishedStudents, pagination: queryPagination } = {},
    isLoading,
    isError,
  } = useBatchUnfinishedStudentsQuery(
    {
      batchID,
      search,
      page: pagination.page,
      per_page: pagination.itemsPerPage,
    },
    {
      enabled: Boolean(batchID),
    }
  )

  const handleSearch = (query: string) => {
    setSearchParams(prev => {
      prev.set('page', '1')

      // If the query is empty, remove the search param
      if (query === '') {
        prev.delete('search')
      } else {
        prev.set('search', query)
      }
      return prev
    })
  }

  const navigateToEditStudentPage = (studentID: string) => {
    history.push(`/${schoolSlug}/v2/migrar-dados/${batchID}/alunos/${studentID}`)
  }

  if (isError) {
    return <GenericError />
  }

  return (
    <Table.Root>
      <Table.Search
        initialSearch={search}
        variant="search-only"
        searchPlaceholder="Buscar aluno ou responsável..."
        onSearch={debounce(250, handleSearch)}
      />
      <Table.Head>
        <Table.HeaderCell name="student">Aluno</Table.HeaderCell>
        <Table.HeaderCell name="guardian">Responsável</Table.HeaderCell>
        <Table.HeaderCell name="status">Status</Table.HeaderCell>
      </Table.Head>

      {isLoading ? (
        <LoadingTable columns={3} rows={10} />
      ) : (
        <Table.Body>
          {unfinishedStudents?.map(student => (
            <Table.Row key={student.id}>
              <Table.TextCell>{student.name}</Table.TextCell>

              <GuardianTableCell guardians={student.guardians} />

              <Table.BadgeCell badgeVariant="soft" badgeColor="error">
                Correções pendentes
              </Table.BadgeCell>

              <Table.ActionCell
                actions={
                  <IconButton
                    size={2}
                    variant="ghost"
                    color="accent"
                    onClick={() => {
                      navigateToEditStudentPage(student.id)
                    }}
                  >
                    <PencilOutline />
                  </IconButton>
                }
              />
            </Table.Row>
          ))}
        </Table.Body>
      )}

      <Table.Pagination
        page={pagination.page}
        itemsPerPage={pagination.itemsPerPage}
        total={queryPagination?.total ?? 0}
        onChangeItemsPerPage={value => updatePaginationValue('itemsPerPage', value)}
        onChangePage={value => updatePaginationValue('page', value)}
      />
    </Table.Root>
  )
}
