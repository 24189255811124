import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom-v5-compat'

import { useContractsByGuardian } from '@/shared/hooks/queries/contracts/useContractsByGuardian'
import { SimplifiedContract } from '@/shared/interfaces'

export const useContracts = (guardianId: uuid, schoolId: uuid) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedContractIds = useMemo(() => {
    const selectedContracts = searchParams.get('selectedContracts')
    return selectedContracts ? selectedContracts.split(',') : []
  }, [searchParams])

  const contractsQuery = useContractsByGuardian({
    guardianId,
    schoolId,
  })
  const contracts = contractsQuery.data ?? []

  const selectContract = (id: uuid) =>
    setSearchParams(
      params => {
        params.set('selectedContracts', [...selectedContractIds, id].join(','))
        return params
      },
      { replace: true }
    )

  const unselectContract = (idToBeRemoved: uuid) =>
    setSearchParams(
      params => {
        params.set(
          'selectedContracts',
          selectedContractIds.filter(id => id !== idToBeRemoved).join(',')
        )
        return params
      },
      { replace: true }
    )

  const handleSelectContract = (contract: SimplifiedContract) => {
    const isSelected = selectedContractIds.includes(contract.id)
    if (isSelected) {
      unselectContract(contract.id)
      return
    }

    selectContract(contract.id)
  }

  const isContractSelected = (id: uuid) => selectedContractIds.includes(id)

  return {
    contractsQuery,
    selectedContractIds,
    contracts,
    handleSelectContract,
    isContractSelected,
  }
}
