import { useHistory } from 'react-router-dom'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { Button } from '@gravity/button'
import { DashedArrowLeft, Add } from '@gravity/icons'

import { CURRENT_YEAR } from '@/shared/constants'
import { GUARDIAN_ID_QUERY_PARAM } from '@/escolas/components/contract/create/constants'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import useAddContractEnabled from '@monorepo/enrollment/hooks/useAddContractEnabled'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'

import { CardDetailsHeaderProps } from './types'

const GuardianDetailsHeaderSkeleton = ({
  isAddContractButtonEnabled,
}: {
  isAddContractButtonEnabled: boolean
}) => {
  return (
    <div className="flex flex-row items-center p-0 w-full justify-between gap-6 mx-0 my-8">
      <Button variant="ghost" className="pr-4 pl-0 gap-3" iconStart={<DashedArrowLeft />} disabled>
        Voltar
      </Button>

      <div className="flex gap-6">
        <Button variant="ghost" disabled>
          Editar cadastro
        </Button>
        {isAddContractButtonEnabled && (
          <Button className="pr-4 gap-3" iconStart={<Add />} disabled>
            Adicionar contrato
          </Button>
        )}
      </div>
    </div>
  )
}

export const GuardianDetailsHeader = ({
  schoolSlug,
  guardianId,
  isLoading,
  openEditRegistrationDrawer,
}: CardDetailsHeaderProps) => {
  const history = useHistory()
  const isAddContractButtonEnabled = useAddContractEnabled()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const enableNewGuardianEditFlow = useUnleashFlag(
    UnleashFlags.COB_1060_ENABLE_NEW_GUARDIAN_EDIT_FLOW
  )

  const sendEvent = (scope: EventDispatcherEventScopes, name: EventDispatcherEvents) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope,
        name,
        action: 'click',
      })
  }

  if (isLoading) {
    return <GuardianDetailsHeaderSkeleton isAddContractButtonEnabled={isAddContractButtonEnabled} />
  }

  const onClickAddContractBtn = () => {
    sendEvent(EventDispatcherEventScopes.GUARDIANS, EventDispatcherEvents.CREATE_CONTRACT_GUARDIAN)

    const path = `/${schoolSlug}/contratos/${CURRENT_YEAR}`
    const params = new URLSearchParams()
    params.append(GUARDIAN_ID_QUERY_PARAM, guardianId)

    history.push({
      pathname: `${path}/novo`,
      search: params.toString(),
    })
  }

  const handleEditGuardianClick = () => {
    sendEvent(EventDispatcherEventScopes.GUARDIANS, EventDispatcherEvents.PERSONAL_DATA_CHANGE)

    if (enableNewGuardianEditFlow) {
      history.push(`/${schoolSlug}/responsaveis/${guardianId}/editar-cadastro`)
      return
    }

    openEditRegistrationDrawer()
  }

  return (
    <div className="flex flex-row items-center p-0 w-full justify-between gap-6 mx-0 my-8">
      <Button
        variant="ghost"
        className="pr-4 pl-0 gap-3"
        iconStart={<DashedArrowLeft />}
        onClick={() => history.goBack()}
        data-testid="backButton"
      >
        Voltar
      </Button>
      <div className="flex gap-6">
        <Button
          variant="ghost"
          onClick={handleEditGuardianClick}
          data-testid="guardianRegistrationViewButton"
        >
          Editar cadastro
        </Button>
        {isAddContractButtonEnabled && (
          <Button
            className="pr-4 gap-3"
            iconStart={<Add />}
            onClick={() => onClickAddContractBtn()}
            data-testid="guardianAddContractButton"
          >
            Adicionar contrato
          </Button>
        )}
      </div>
    </div>
  )
}
