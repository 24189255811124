import { Callout } from '@gravity/callout'
import { Checkbox } from '@gravity/checkbox'
import { Heading } from '@gravity/heading'
import { TextField } from '@gravity/text-field'
import NumberFormat from 'react-number-format'
import { useFormContext } from 'react-hook-form'
import { EditStudentFormProps } from '../../hooks/useEditStudentForm'
import { useState } from 'react'

export const StudentForm = () => {
  const { register, getValues, setValue } = useFormContext<EditStudentFormProps>()

  const [noTaxId, setNoTaxId] = useState(false)

  return (
    <div className="flex flex-col gap-6">
      <Heading variant="heading-h4-medium">Dados cadastrais</Heading>

      <Callout
        color="gray"
        text="Informar o CPF do aluno garante maior segurança aos contratos da instituição."
        linkLabel="Saiba mais"
        href="https://centraldeajuda.olaisaac.io/respons%C3%A1veis-financeiros/tudo-sobre-edicao-de-dados-cadastrais-de-alunos-"
      />

      {/* TODO: Refactor to remove common props like size={2} fullWidth */}
      <div className="flex flex-row gap-4">
        <TextField size={2} fullWidth label="Nome completo" {...register('name')} />
        <TextField size={2} fullWidth label="Data de nascimento" {...register('birth_date')} />
      </div>

      <div className="flex flex-row gap-4">
        <div className="w-full">
          <NumberFormat
            className="w-full"
            disabled={noTaxId}
            id="student.tax_id"
            type="text"
            format="###.###.###-##"
            label="CPF"
            customInput={TextField}
            size={2}
            value={getValues('tax_id')}
            onValueChange={currentValue => setValue('tax_id', currentValue.value)}
            variant="outlined"
            {...register('tax_id')}
          />
        </div>

        <div className="mt-10 w-full">
          <Checkbox
            size={1}
            text="Aluno não possui CPF"
            name="no_tax_id"
            checked={noTaxId}
            onCheckedChange={checked => {
              if (checked === 'indeterminate') return

              setNoTaxId(checked)
              setValue('tax_id', '')
            }}
          />
        </div>
      </div>
    </div>
  )
}
