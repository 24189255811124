import styled from 'styled-components'
import { Text } from '@gravity/text'
import { TextArea } from '@gravity/text-area'

export const LabelReason = styled(Text)`
  margin-bottom: 0.25rem;
`

export const StyledTextArea = styled(TextArea)`
  width: 100%;
`

export const StyledDialogContent = styled.div`
  width: 100%;
`
