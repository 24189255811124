import { useMemo, useState } from 'react'
import { Drawer } from '@olaisaac/design-system'
import { Text } from '@gravity/text'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { PayoutTransfersSection } from './components/PayoutTransfersSection'
import { PayoutTransfersSectionGroup } from './components/PayoutTransfersSectionGroup'
import { useApiClient, useSnackbar } from '@/shared/hooks'
import { Add, Subtract, CalendarEvent, Close } from '@gravity/icons'
import { IconButton } from '@gravity/icon-button'

import { formatDate } from '@/shared/utils/dateFormatters'
import { downloadFile } from '@/shared/utils/downloadFile'
import { formatCurrencyAmount } from '@monorepo/report/utils/formatCurrencyAmount'
import { buildCategories } from './utils/buildCategories'
import { groupCategoriesIntoInstallmentAndOtherValues } from './utils/groupCategoriesIntoInstallmentAndOtherValues'
import { orderCategories } from './utils/orderCategories'

import { REPORT_PAGE_NAMES } from '@monorepo/report/constants/REPORT_PAGE_NAMES'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { GrayText, StyledTooltip } from './styles'
import { Button } from '@gravity/button'
import { reportService } from '@monorepo/report/services/report'
import { Heading } from '@gravity/heading'
import { TuitionPayoutReportTransfersDrawerProps } from './types'
import { Separator } from '@gravity/separator'

/**
 * Component for tuition payout report details drawer
 *
 * @param props
 * @param props.schoolSlug School slug
 * @param props.payoutId Tuition payout ID
 * @param props.isPayoutOpen Indicates if payout is open
 * @param props.payoutDueDate Payout reference date
 * @param props.income Payout income
 * @param props.deductions Payout deductions
 * @param props.bankTransfers Payout bank transfers
 * @param props.transactionsInfo Payout secondary infos
 * @param props.isOpen Indicates if drawer should be opened
 * @param props.onClose Callback function called on request close
 */
export const TuitionPayoutReportTransfersDrawer = ({
  schoolSlug,
  payoutId,
  isPayoutOpen,
  payoutDueDate,
  income = { items: {}, total: 0 },
  deductions = { items: {}, total: 0 },
  bankTransfers = [],
  transactionsInfo,
  isOpen,
  onClose,
}: TuitionPayoutReportTransfersDrawerProps) => {
  const { apiClient } = useApiClient()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { setIsOpen } = useSnackbar()

  const [isLoadingDownload, setIsLoadingDownload] = useState(false)

  const handleDownloadReport = async (payoutId: string) => {
    const filename = `resumo-${schoolSlug}.pdf`

    const service = reportService(apiClient.getClients().privateApi)

    try {
      setIsLoadingDownload(true)

      await downloadFile(filename, 'pdf', () =>
        service.fetchPayoutReportFile({
          id: payoutId,
          version: 2,
          extension: 'pdf',
        })
      )
    } catch {
      setIsOpen(true, {
        variation: 'error',
        description: 'Falha ao baixar arquivo. Tente novamente mais tarde.',
      })
    } finally {
      setIsLoadingDownload(false)

      isInitialized &&
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.PAYOUT_REPORT,
          name: EventDispatcherEvents.TUITION_PAYOUT_DOWNLOAD_SUMMARY,
          action: 'file_download',
          customProperties: {
            $page_name: REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT,
          },
        })
    }
  }

  const payoutReferenceMonth = formatDate(payoutDueDate, 'MMMM')

  const [incomeInstallmentValues, incomeOtherValues] = useMemo(() => {
    const categories = buildCategories(income)

    const sortedCategories = orderCategories(categories)

    return groupCategoriesIntoInstallmentAndOtherValues(sortedCategories)
  }, [income])

  const [deductionsInstallmentValues, deductionsOtherValues] = useMemo(() => {
    const categories = buildCategories(deductions)

    const sortedCategories = orderCategories(categories)

    return groupCategoriesIntoInstallmentAndOtherValues(sortedCategories)
  }, [deductions])

  const formattedBankTransfers = bankTransfers.map(({ date, amount }) => {
    const value = amount < 0 ? 0 : amount

    return {
      date: formatDate(date, 'DD/MM/YYYY'),
      amount: formatCurrencyAmount(value),
    }
  })

  return (
    <Drawer open={isOpen}>
      <div className="flex flex-col h-full">
        <div className="flex flex-col gap-6 pt-6 items-center" data-testid="drawer-header">
          <div className="flex px-6 w-full justify-end">
            <IconButton onClick={onClose} size={2} variant="ghost" aria-label="Fechar">
              <Close />
            </IconButton>
          </div>
          <div className="flex flex-col gap-6 px-6 w-full">
            <Heading variant="heading-h3-medium">Detalhes do repasse</Heading>
            <GrayText variant="body-1-regular">
              Veja todos os valores que compõem o seu repasse.
            </GrayText>
          </div>
          <Separator orientation="horizontal" color="neutral-2" />
        </div>

        <div className="flex flex-col flex-1 overflow-auto" data-testid="drawer-content">
          <PayoutTransfersSection.Root
            icon={Add}
            iconColor="text-colors-text-main-2"
            label="Recebimentos"
            totalLabel="Valor total dos recebimentos"
            description="Soma das mensalidades e outros valores positivos no mês."
            totalAmount={income?.total ?? 0}
            isEmpty={incomeInstallmentValues.length === 0 && incomeOtherValues.length === 0}
            emptySectionMessage={`Em ${payoutReferenceMonth} seu repasse não possui nenhum valor de recebimento.`}
          >
            <PayoutTransfersSectionGroup
              categoryTitle="Valores referentes a parcelas de mensalidade"
              categories={incomeInstallmentValues}
              description={`${transactionsInfo.students} alunos | ${transactionsInfo.charges} cobranças`}
            />
            <Separator orientation="horizontal" color="neutral-2" />

            <PayoutTransfersSectionGroup
              categoryTitle="Outros valores"
              categoryTooltip="Valores que impactam seu repasse, mas não estão vinculados à contratos de mensalidades."
              categories={incomeOtherValues}
            />
          </PayoutTransfersSection.Root>
          <PayoutTransfersSection.Root
            icon={Subtract}
            iconColor="text-colors-text-main-2"
            label="Descontos"
            totalLabel="Valor total dos descontos"
            description="Valores referentes a taxa isaac, ajustes feitos ao longo do mês e serviços adicionais."
            totalAmount={deductions?.total ?? 0}
            isEmpty={deductionsInstallmentValues.length === 0 && deductionsOtherValues.length === 0}
            emptySectionMessage={`Em ${payoutReferenceMonth} seu repasse não possui nenhum valor de desconto.`}
          >
            <PayoutTransfersSectionGroup
              categoryTitle="Valores referentes a parcelas de mensalidade"
              categories={deductionsInstallmentValues}
            />
            <Separator orientation="horizontal" color="neutral-2" />
            <PayoutTransfersSectionGroup
              categoryTitle="Outros valores"
              categoryTooltip="Valores que impactam seu repasse, mas não estão vinculados à contratos de mensalidades."
              categories={deductionsOtherValues}
            />
          </PayoutTransfersSection.Root>

          {formattedBankTransfers.length > 0 && (
            <div className="flex flex-col gap-6 p-6">
              <div className="flex items-center gap-2">
                <CalendarEvent size="sm" />
                <Heading variant="heading-h4-regular">Transferências programadas</Heading>
              </div>

              <div className="flex flex-col gap-4">
                {formattedBankTransfers.map(({ date, amount }) => (
                  <div key={date} className="flex justify-between items-center">
                    <GrayText variant="body-1-regular">{date}</GrayText>

                    <Text variant="body-1-medium">{amount}</Text>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="px-6 py-8" data-testid="download-report-button">
          <StyledTooltip
            text={
              isPayoutOpen
                ? 'Disponível apenas para repasses fechados'
                : 'Baixar o arquivo .pdf do período'
            }
          >
            <Button
              onClick={() => handleDownloadReport(payoutId)}
              loading={isLoadingDownload}
              disabled={isPayoutOpen}
              fullWidth
            >
              Baixar detalhamento
            </Button>
          </StyledTooltip>
        </div>
      </div>
    </Drawer>
  )
}
