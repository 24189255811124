import { useMutation } from '@tanstack/react-query'

import { queryClient } from '@/shared/contexts/ReactQuery'
import type { GuardianStudentsPayload } from '@/shared/interfaces'
import { useApi } from '@/utils/hooks/useApi'
import { getGuardianQueryKey } from '@/shared/hooks/queries/guardian'

export const useUpdateGuardianMutation = () => {
  const { api } = useApi()

  return useMutation(
    ({
      guardian,
      students,
      schoolId,
      guardian_id,
    }: GuardianStudentsPayload & { schoolId: string }) => {
      return api.guardianStudents.update({ guardian, students, guardian_id }, schoolId)
    },
    {
      onSuccess: async (response, payload) => {
        const fullGetGuardianQueryKey = [
          getGuardianQueryKey,
          payload.schoolId,
          response.guardian.id,
        ]
        queryClient.invalidateQueries(fullGetGuardianQueryKey)
      },
    }
  )
}
