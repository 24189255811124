import { MouseEventHandler, ReactElement, useContext } from 'react'

import { SnackbarContext } from '@/shared/contexts/snackbarContext'

export type NotificationVariation = 'success' | 'warning' | 'error' | 'information' | 'disabled'
export interface NotificationProps {
  className?: string
  description: string
  floating?: boolean
  link?: ReactElement
  onClose?: MouseEventHandler<HTMLButtonElement>
  padding?: string
  title?: string
  variation?: NotificationVariation
}

export const Notification = {} as React.ComponentType<NotificationProps>

export const useSnackbar = () => {
  const context = useContext(SnackbarContext)

  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider')
  }

  const setSnackBar = (message: string, variation: NotificationProps['variation']) => {
    context.setMessage(message)
    context.setVariation(variation)
    context.setIsOpen(true)
  }

  return {
    ...context,
    setSnackBar,
  }
}

export default useSnackbar
