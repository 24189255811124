import type { ChangeEvent } from 'react'
import { Controller } from 'react-hook-form'
import type { UseFormReturn, Validate } from 'react-hook-form'
import NumberFormat from 'react-number-format'

import { TextField } from '@gravity/text-field'
import { Select } from '@gravity/select'
import { Heading } from '@gravity/heading'
import { DeleteBinOutline } from '@gravity/icons'

import { formatCentsToReal, formatRealToCents } from '@/shared/utils'
import { FormAddTuitionProps } from '@monorepo/students/pages/Student/components/ContractsSection/types'

import * as Styled from './styles'

export enum discountRule {
  DUE_DATE_DISCOUNT = 'DUE_DATE_DISCOUNT',
  EARLY_PAYMENT_DISCOUNT = 'EARLY_PAYMENT_DISCOUNT',
  PERMANENT_DISCOUNT = 'PERMANENT_DISCOUNT',
}

export const discountRuleOptions = [
  {
    label: 'Desconto permanente - desconto independe do vencimento',
    value: discountRule.PERMANENT_DISCOUNT,
  },
  {
    label: 'Mantêm até vencimento - desconto para pagamento pontual',
    value: discountRule.DUE_DATE_DISCOUNT,
  },
  {
    label: 'Antes do vencimento - desconto para pagamento adiantado',
    value: discountRule.EARLY_PAYMENT_DISCOUNT,
  },
]

type Props = {
  form: UseFormReturn<FormAddTuitionProps>
  index: number
  onClickRemove: () => void
  onPercentageValueChange: (event: ChangeEvent<HTMLInputElement>) => void
  totalAmountPaid: number
  validateDiscountAmount: Validate<number | null>
}

export const InstallmentFormDiscountItem = ({
  index,
  form,
  onClickRemove,
  totalAmountPaid,
  onPercentageValueChange,
  validateDiscountAmount,
}: Props) => {
  const title = `Desconto ${index + 1}`
  const dueDateRule = form.watch(`discounts.${index}.due_date_rule` as const)
  const discountAmount = form.watch(`discounts.${index}.amount` as const)
  const percentageValue = ((discountAmount || 0) / totalAmountPaid) * 100 || 0
  const hasInvalidAmount = !!form.formState.errors.discounts?.[index]?.amount

  return (
    <Styled.Wrapper role="listitem" aria-labelledby={title}>
      <Styled.Box>
        <Heading variant="heading-h4-medium">{title}</Heading>

        <Styled.DangerIconButton variant="soft" onClick={onClickRemove}>
          <DeleteBinOutline />
        </Styled.DangerIconButton>
      </Styled.Box>
      <Styled.InputRow>
        <Controller
          rules={{
            required: true,
          }}
          name={`discounts.${index}.description` as const}
          control={form.control}
          render={({ field }) => (
            <TextField
              label="Motivo do desconto"
              aria-label="Motivo do desconto"
              placeholder="Digite o motivo do desconto"
              size={3}
              name={field.name}
              value={field.value}
              ref={field.ref}
              // eslint-disable-next-line react/jsx-handler-names
              onChange={field.onChange}
            />
          )}
        />
      </Styled.InputRow>
      <Styled.SplitInputRow>
        <Controller
          rules={{
            required: true,
            validate: { validateDiscountAmount },
          }}
          name={`discounts.${index}.amount` as const}
          control={form.control}
          render={({ field, fieldState }) => (
            <Styled.InputWrapper hasError={!!fieldState.error?.message}>
              <NumberFormat
                placeholder="Digite o valor do desconto"
                customInput={TextField}
                format={formatCentsToReal}
                min={0}
                size={3}
                label="Valor"
                aria-label="Digite o valor do desconto"
                variant="outlined"
                value={field.value}
                error={!!fieldState.error?.message}
                errorMessage={fieldState.error?.message}
                onChange={event => {
                  field.onChange(Number(formatRealToCents(event.target.value)))
                }}
              />
            </Styled.InputWrapper>
          )}
        />

        <NumberFormat
          placeholder="Digite o percentual"
          customInput={TextField}
          label="Percentual"
          aria-label="Percentual"
          size={3}
          decimalScale={2}
          hiddenLabel
          suffix="%"
          variant="outlined"
          error={hasInvalidAmount}
          value={percentageValue}
          onChange={onPercentageValueChange}
        />
      </Styled.SplitInputRow>
      <Styled.InputRow>
        <Controller
          rules={{
            required: true,
          }}
          name={`discounts.${index}.due_date_rule` as const}
          control={form.control}
          render={({ field }) => (
            <Select
              size={3}
              fullWidth
              label="Desconto válido até"
              aria-label="Desconto válido até"
              placeholder="Selecione a regra de validade"
              options={discountRuleOptions}
              value={field.value ?? ''}
              // eslint-disable-next-line react/jsx-handler-names
              onValueChange={field.onChange}
            />
          )}
        />
      </Styled.InputRow>

      {dueDateRule === discountRule.EARLY_PAYMENT_DISCOUNT && (
        <Styled.InputRow>
          <Controller
            rules={{
              required: true,
              min: 1,
            }}
            name={`discounts.${index}.days_before_due_date` as const}
            control={form.control}
            render={({ field, fieldState }) => (
              <TextField
                label="Dias antes do vencimento"
                aria-label="Dias antes do vencimento"
                placeholder="Digite o total de dias antes do vencimento"
                size={3}
                name={field.name}
                value={field.value ?? ''}
                ref={field.ref}
                error={!!fieldState.error}
                errorMessage="Deve ter pelo menos 1 dia de antecedência"
                // eslint-disable-next-line react/jsx-handler-names
                onChange={field.onChange}
              />
            )}
          />
        </Styled.InputRow>
      )}
    </Styled.Wrapper>
  )
}
