import styled from 'styled-components'

export const SelectYearWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${props => props.theme.gravity.spacing[2]};

  button {
    width: 88px;
    min-width: 88px;
    height: 40px;
    border: 2px solid ${props => props.theme.gravity.colors['colors-interaction-primary-4']};
    border-radius: ${props => props.theme.gravity.borderRadius[2]};
  }
`

export const SelectLabelWrapper = styled.div`
  span {
    color: ${props => props.theme.gravity.colors['colors-text-main-1']};
    font-weight: 500;
  }
`

export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.gravity.spacing[10]};

  .grid-container {
    row-gap: ${({ theme }) => theme.gravity.spacing[8]};
  }
`

export const ActionsContainer = styled.div`
  margin-top: ${props => props.theme.gravity.spacing[9]};
  display: flex;
  justify-content: space-between;
  align-content: center;
  gap: ${props => props.theme.gravity.spacing[6]};
`

export const ActionButtonsWrapper = styled.div`
  display: flex;
  order: 2;
  align-items: center;
  gap: ${props => props.theme.gravity.spacing[2]};
`

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
export const SecondaryButtons = styled.div`
  display: flex;
  flex-direction: row;
`
export const FilterAndSecondary = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
