import { TextField } from '@gravity/text-field'
import { DatepickerPrimitives as DatePicker } from '@gravity/datepicker'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useIsReenrollment } from './hooks/useIsReenrollment'
import { FormProps } from './types'
import { useEffect } from 'react'
import { validateCpf, DATE_FORMAT, isDayBeforeToday } from '@/shared/utils'
import NumberFormat from 'react-number-format'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { Checkbox } from '@gravity/checkbox'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'

type StudentFormProps = {
  form: UseFormReturn<FormProps>
}

export const StudentForm = ({ form }: StudentFormProps) => {
  const { control, trigger, clearErrors, watch, getValues } = form

  const { isReenrollment, student } = useIsReenrollment()

  const noTaxId = watch('student.no_tax_id')

  const enableStudentCPFandBirthDate = useUnleashFlag(
    UnleashFlags.EFI_171_STUDENT_CPF_AND_BIRTHDATE
  )

  useEffect(() => {
    form.setValue('student', {
      ...student,
      birth_date: null,
      tax_id: '',
      no_tax_id: isReenrollment || !enableStudentCPFandBirthDate,
    })
  }, [student])

  const validateStudentTaxId = (value: string) => {
    const skipTaxIdValidation = getValues('student.no_tax_id')
    if (skipTaxIdValidation) return true

    return validateCpf(value)
  }

  // TODO: Review this rule for reenrollment flow
  const shouldShowCPFAndBirthDate = enableStudentCPFandBirthDate && !isReenrollment

  const todayDate = new Date()
  const currentDateSelected = (value: Dayjs) => dayjs.utc(value)

  const formatDateValue = (value: Dayjs) => {
    const formattedDate = new Date(value.toDate())
    return formattedDate
  }

  return (
    <>
      <div className="mb-6">
        <Heading variant="heading-h4-medium" className="mb-2">
          Aluno(a)
        </Heading>
        <Text variant="subtitle-regular">
          Este é o nome do aluno(a) que será matrículado ou rematrículado.
        </Text>
      </div>
      <Controller
        rules={{ required: true }}
        name="student.name"
        control={control}
        render={({ field: { ...rest }, fieldState: { error } }) => (
          <TextField
            {...rest}
            id="student"
            disabled={isReenrollment}
            label="Nome do aluno"
            error={Boolean(error?.type)}
            errorMessage="Insira um nome ou selecione um aluno cadastrado"
            size={3}
          />
        )}
      />

      {shouldShowCPFAndBirthDate && (
        <div className="my-3">
          <div className="mt-3 flex md:flex-wrap lg:flex-nowrap items-end my-6 gap-4">
            <Controller
              control={control}
              name="student.birth_date"
              rules={{
                validate: {
                  isInThePast: v =>
                    (v && isDayBeforeToday(v)) || 'Informe uma data válida no passado',
                },
              }}
              render={({ field, fieldState }) => (
                <div className="relative w-[16rem]">
                  <DatePicker.Root>
                    <Text
                      variant="subtitle-regular"
                      className={
                        fieldState.error?.type
                          ? 'text-colors-text-semantic-colors-error-1 font-medium'
                          : ''
                      }
                    >
                      Data de nascimento
                    </Text>
                    <DatePicker.Trigger
                      className={
                        fieldState.error?.type
                          ? 'border border-solid border-colors-background-semantic-colors-error-1'
                          : ''
                      }
                      data-testid="student-form-calendar-trigger"
                      size={3}
                      placeholder={DATE_FORMAT}
                    >
                      {field?.value && currentDateSelected(field.value).format(DATE_FORMAT)}
                    </DatePicker.Trigger>

                    <DatePicker.Calendar
                      value={field?.value ? formatDateValue(field.value) : todayDate}
                      maxDate={todayDate}
                      onChange={value => {
                        const date = dayjs(value as Date)
                        field.onChange(date)
                      }}
                    />
                  </DatePicker.Root>
                  {Boolean(fieldState.error?.type) && (
                    <div className="absolute -bottom-6 pt-2">
                      <Text
                        className="text-colors-text-semantic-colors-error-1 text-sm"
                        variant="caption-regular"
                      >
                        {fieldState.error?.message}
                      </Text>
                    </div>
                  )}
                </div>
              )}
            />
            <Controller
              rules={{
                validate: validateStudentTaxId,
              }}
              name="student.tax_id"
              control={control}
              render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
                <NumberFormat
                  {...rest}
                  disabled={noTaxId}
                  id="student.tax_id"
                  type="tel"
                  value={value}
                  format="###.###.###-##"
                  label="CPF"
                  error={Boolean(error?.type)}
                  errorMessage="Insira um CPF válido"
                  onValueChange={async currentValue => {
                    clearErrors('student.tax_id')

                    onChange(currentValue.value)

                    if (currentValue.value.length === 11) {
                      trigger('student.tax_id')
                    }
                  }}
                  customInput={TextField}
                  size={3}
                />
              )}
            />
            <div className="min-w-[180px] xl:mb-2 md:mt-2">
              <Controller
                name="student.no_tax_id"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Checkbox
                    checked={value}
                    // eslint-disable-next-line react/jsx-handler-names
                    onCheckedChange={e => {
                      onChange(e)
                      trigger('student.tax_id')
                    }}
                    text="Aluno não possui CPF"
                  />
                )}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
