import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import dayjs from 'dayjs'
import { Separator } from '@gravity/separator'
import { Text } from '@gravity/text'
import { useToast } from '@gravity/toast'

import { useApi } from '@/utils/hooks/useApi'
import SelectedReceivableAgglutination from './SelectedReceivableAgglutination'
import AgglutinationDueDate from './AgglutionationDueDate'
import AgglutinationPayment from './AgglutinationPayment'
import AgglutinationDiscount from './AgglutinationDiscount'
import { CombinableReceivable } from 'src/escolas/contexts/receivablesAgglutinationContext'
import { Contract, Agglutination } from 'src/shared/interfaces'
import { formatCentsToReal } from 'src/shared/utils'
import useCheckoutCart from '@/modules/guardians/hooks/useCheckoutCart'
import { Installment } from '@/modules/guardians/GuardianDetails/types'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { InstallmentDialogForm } from '../InstallmentDialogForm'

export type AgglutinationDialogProps = {
  callbackAgglutination?: Dispatch<SetStateAction<boolean>>
  disableChecks: () => void
  enableChecks?: () => void
  form: UseFormReturn<any>
  isProcessingAgglutination: boolean
  onClose: () => void
  orderReference?: string
  receivables?: CombinableReceivable[]
  selectedReceivableId?: uuid | null
  setContract?: (contract: Contract) => void
}

type finishAgglutinationType = {
  discount_amount: number
  due_date: string
  from: uuid[]
  payment_methods: string[]
}

export const AgglutinationDialog = ({
  callbackAgglutination,
  onClose,
  form,
  setContract,
  disableChecks,
  isProcessingAgglutination,
  enableChecks,
}: AgglutinationDialogProps) => {
  const { toast } = useToast()
  const { checkoutCart, totalAmount: baseAmount } = useCheckoutCart()
  const { api } = useApi()
  const [apiError, setApiError] = useState(null)
  const [waitingApi, setWaitingApi] = useState<boolean>(false)
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const combinedReceivables: CombinableReceivable[] = checkoutCart?.map(
    (installment: Installment) =>
      ({
        amount: installment.amount,
        due_date: installment.due_date,
        id: installment.receivable_id,
        orderReference: installment.order_reference,
      } as CombinableReceivable)
  )

  const finishAgglutination = ({
    discount_amount,
    due_date,
    payment_methods,
    from,
  }: finishAgglutinationType) => {
    const payload: Agglutination = {
      from,
      payment_methods,
      to: { discount_amount, due_date },
      channel: 'PI-BACKOFFICE',
    }
    setWaitingApi(true)
    disableChecks()
    api.receivables
      .agglutinate(payload)
      .then(contract => {
        toast({
          type: 'success',
          title: 'Aglutinação realizada com sucesso.',
        })
        setContract && setContract(contract)
        onClose()

        if (callbackAgglutination) {
          callbackAgglutination(true)
        }
      })
      .catch(e => e && setApiError(e))
      .finally(() => {
        setWaitingApi(false)
        enableChecks && enableChecks()
      })
  }

  const { watch } = form
  watch(['discount'])

  const discounts = form.getValues('discount') ?? 0

  const calculateTotalAmount = () => {
    const totalAmount = combinedReceivables.reduce((accumulator, receivable) => {
      return accumulator + receivable.amount
    }, 0)
    return totalAmount - discounts
  }

  const getParameters = () => {
    const from = combinedReceivables?.map(x => x.id)
    const date = form.getValues('due_date')
    const dueDate = date ? dayjs(date).utc().set('hour', 0).set('minute', 0).set('second', 0) : null

    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.AGGLUTINATE,
        entity: EventDispatcherEntities.AGGLUTINATION_FINISH,
        action: 'click',
        customProperties: {
          $name: 'Finalizar aglutinação',
          $receivables_ids: from,
          $total_amount: formatCentsToReal(calculateTotalAmount()),
          $payment_method: form.getValues('payment_method'),
          $due_date: dueDate?.toISOString(),
        },
      })
    finishAgglutination({
      discount_amount: discounts,
      payment_methods: form.getValues('payment_method'),
      due_date: (dueDate as unknown) as string,
      from,
    })
  }

  const canSubmit = combinedReceivables?.length > 1

  const description =
    canSubmit && `Valor da nova parcela ${formatCentsToReal(baseAmount - discounts)}`

  useEffect(() => {
    if (apiError) {
      toast({
        type: 'error',
        title: 'Aconteceu um erro inesperado',
        description: 'Por favor, tente novamente ou entre em contato com a equipe responsável.',
      })
    }
  }, [apiError])

  return (
    <InstallmentDialogForm
      title="Aglutinar parcelas"
      onClose={onClose}
      onSubmit={getParameters}
      form={form}
      isLoading={waitingApi}
    >
      <div className="flex flex-col gap-4">
        <SelectedReceivableAgglutination
          receivables={combinedReceivables}
          baseAmount={baseAmount}
        />

        <Separator color="neutral-2" />

        <AgglutinationDiscount
          totalAmountPaid={baseAmount}
          amountDefaultValue={0}
          form={form}
          disableForm={isProcessingAgglutination}
        />

        <Separator color="neutral-2" />

        <AgglutinationDueDate form={form} disableForm={isProcessingAgglutination} />

        <Separator color="neutral-2" />

        <AgglutinationPayment form={form} disableForm={isProcessingAgglutination} />

        <Separator color="neutral-2" />

        <Text variant="body-1-regular">{description}</Text>
      </div>
    </InstallmentDialogForm>
  )
}
