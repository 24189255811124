import { PropsWithChildren, useEffect } from 'react'
import { SubmitHandler, UseFormReturn } from 'react-hook-form'
import { Button } from '@gravity/button'
import { DialogPrimitives } from '@gravity/dialog'

import { useToast } from '@gravity/toast'
import { useApiClient } from '@/shared/hooks'

type InstallmentDialogFormProps = {
  description?: string
  disableFeedback?: boolean
  errorMessage?: string
  form: UseFormReturn<any>
  isEditDiscount?: boolean
  isLoading?: boolean
  onCancel?: () => void
  onClose: () => void
  onFinish?: () => void
  onSubmit?: SubmitHandler<any>
  prevDiscountsLength?: number
  successMessage?: string
  title: string
}

export const InstallmentDialogForm = ({
  children,
  disableFeedback,
  form,
  prevDiscountsLength,
  successMessage,
  errorMessage,
  onFinish,
  onSubmit,
  isLoading,
  isEditDiscount,
  onClose,
  onCancel,
  title,
  description,
}: PropsWithChildren<InstallmentDialogFormProps>) => {
  const { setCorrelationId } = useApiClient()
  const { toast } = useToast()

  if (!form) return null

  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isSubmitted, isSubmitSuccessful, isSubmitting, isValid, isDirty, touchedFields },
  } = form

  const { discounts } = getValues()
  const quantityOfDiscountsChanged = prevDiscountsLength !== discounts?.length
  const hasNotChanged = Object.keys(touchedFields).length === 0 && !quantityOfDiscountsChanged

  const resetErrors = () => {
    reset()
    setCorrelationId && setCorrelationId('')
  }

  const handleClose = () => {
    resetErrors()
    onClose()
  }

  const handleCancel = () => {
    resetErrors()
    onCancel && onCancel()
  }

  const showSuccessFeedback = isSubmitted && isSubmitSuccessful && !isSubmitting && !disableFeedback
  const showFailureFeedback =
    isSubmitted && !isSubmitSuccessful && !isSubmitting && !disableFeedback

  const formHasErrors = Object.keys(form.formState?.errors).length > 0

  useEffect(() => {
    if (showSuccessFeedback) {
      successMessage &&
        toast({
          title: successMessage,
          type: 'success',
        })
      resetErrors()
      onFinish && onFinish()
    }
  }, [showSuccessFeedback])

  useEffect(() => {
    if (showFailureFeedback) {
      errorMessage &&
        toast({
          title: errorMessage,
          type: 'error',
        })
    }
  }, [showFailureFeedback])

  return (
    <DialogPrimitives.Root open onOpenChange={open => !open && !isLoading && handleClose()}>
      <DialogPrimitives.Overlay backdrop />
      <DialogPrimitives.Content
        size={2}
        title={title}
        description={description}
        cancelButton={onCancel && <Button variant="ghost" onClick={handleCancel} />}
        actionButton={
          <Button
            variant="solid"
            disabled={
              formHasErrors ||
              isSubmitting ||
              !isValid ||
              !isDirty ||
              (isEditDiscount && hasNotChanged)
            }
            onClick={onSubmit && handleSubmit(onSubmit)}
            loading={isLoading || isSubmitting}
          >
            Confirmar
          </Button>
        }
      >
        <div className="flex flex-col w-full">{children}</div>
      </DialogPrimitives.Content>
    </DialogPrimitives.Root>
  )
}
