import { Skeleton } from '@gravity/skeleton'

export const Loading = () => {
  return (
    <div className="flex flex-col gap-6">
      <Skeleton height={91.5} fullWidth />
      <Skeleton height={91.5} fullWidth />
      <Skeleton height={91.5} fullWidth />
    </div>
  )
}
