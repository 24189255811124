import styled from 'styled-components'
import { Skeleton } from '@gravity/skeleton'

export const PayoutSkeleton = styled(Skeleton)`
  border-radius: ${props => props.theme.gravity.borderRadius[6]};
`

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const AmountContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${props => props.theme.gravity.spacing[2]};
`
