import dayjs from 'dayjs'
import { Skeleton } from '@gravity/skeleton'

import { ContractsToChange } from '@/modules/contract/services/change-ownership/types'
import { months } from '@/shared/utils'

import { useChangeOwnershipPreview } from '../../hooks/usePreviewChangeOwnership'
import { ContractCard } from '../ContractCard'
import type { SelectedContractCardsListProps } from './types'
import * as Styled from './styles'

export const SelectedContractCardsList = ({
  selectedContractIds,
}: SelectedContractCardsListProps) => {
  const changeOwnershipPreviewQuery = useChangeOwnershipPreview(
    {
      contract_ids: selectedContractIds,
    },
    { enabled: selectedContractIds.length > 0 }
  )
  const contractsToChange = changeOwnershipPreviewQuery.data?.contracts_to_change ?? []

  const getContractCardDesc = (contract: ContractsToChange) => {
    if (!contract.charges || contract.charges.length === 0) return ''

    const firstDueDate = dayjs(contract.charges[0].due_date)
    const firstDueMonthName = months[firstDueDate.month() + 1]

    const lastDueDate = dayjs(contract.charges[contract.charges.length - 1].due_date)
    const lastDueMonthName = months[lastDueDate.month() + 1]
    return `A troca será efetivada a partir da parcela de ${firstDueMonthName} de ${firstDueDate.format(
      'YYYY'
    )} até ${lastDueMonthName} de ${lastDueDate.format('YYYY')}.`
  }

  return (
    <Styled.Wrapper>
      {changeOwnershipPreviewQuery.isLoading &&
        selectedContractIds.map(id => (
          <Styled.Item key={id}>
            <Skeleton fullWidth height={237} />
          </Styled.Item>
        ))}

      {changeOwnershipPreviewQuery.isSuccess &&
        contractsToChange.map(c => (
          <Styled.Item key={c.contract_id}>
            <ContractCard
              key={c.contract_id}
              title={c.charges?.[0]?.product_name ?? ''}
              description={getContractCardDesc(c)}
            />
          </Styled.Item>
        ))}
    </Styled.Wrapper>
  )
}
