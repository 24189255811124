import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { Callout } from '@gravity/callout'
import { Skeleton } from '@gravity/skeleton'

import * as Styled from './styles'
import type { ContractSelectionSepProps } from './types'
import { SelectedContractCardsList } from '../SelectedContractCardsList'
import { PageFooter } from '../PageFooter'
import { ContractListItem } from './components/ContractListItem'

export const ContractSelectionSep = ({
  contracts,
  onSelectContract,
  isSelected,
  goBackBtnDisabled,
  onComplete,
  isLoading,
  onClickGoBack,
  selectedContractIds,
}: ContractSelectionSepProps) => {
  const goToNextStepBtnDisabled = isLoading || selectedContractIds.length === 0
  return (
    <>
      <Styled.Wrapper>
        <Heading variant="heading-h4-medium">
          Selecione os contratos que deseja trocar a titularidade
        </Heading>

        <Styled.CheckBoxWrapper>
          <Text variant="subtitle-medium">Qual contrato você deseja alterar a titularidade?</Text>
          {isLoading && (
            <>
              <Skeleton fullWidth height={24} />
              <Skeleton fullWidth height={24} />
              <Skeleton fullWidth height={24} />
              <Skeleton fullWidth height={24} />
            </>
          )}

          {contracts.map(c => (
            <ContractListItem
              onSelectContract={() => onSelectContract(c)}
              isSelected={isSelected(c.id)}
              disabled={!c.actions.can_change_ownership}
              text={`${c.product.name} - Aluno: ${c.student.name}`}
              key={c.id}
            />
          ))}
        </Styled.CheckBoxWrapper>

        <Callout text="A troca de titularidade do contrato será feita sempre a partir da primeira parcela em aberto. Parcelas pagas, negociadas e vencidas não serão consideradas na troca." />

        <SelectedContractCardsList selectedContractIds={selectedContractIds} />
      </Styled.Wrapper>

      <PageFooter
        goBackBtnDisabled={goBackBtnDisabled}
        goToNextStepBtnDisabled={goToNextStepBtnDisabled}
        onClickNextBtn={onComplete}
        onClickGoBack={onClickGoBack}
      />
    </>
  )
}
