import styled from 'styled-components'

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[3]};
`

export const FooterRightContainer = styled(ColumnContainer)`
  flex: 1;
  height: 556px;
  margin-left: ${props => props.theme.gravity.spacing[10]};
  margin-top: ${props => props.theme.gravity.spacing[7]};
  gap: ${props => props.theme.gravity.spacing[2]};
`

export const ResponsiveContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${props => props.theme.gravity.spacing[14]};
  .grid-1 {
    row-gap: ${props => props.theme.gravity.spacing[14]};
  }
  .grid-2 {
    row-gap: ${props => props.theme.gravity.spacing[6]};
    padding: ${props => props.theme.gravity.spacing[14]};
  }
`
