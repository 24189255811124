import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { Add, RefreshOutline } from '@gravity/icons'
import { useHistory } from 'react-router-dom'
import { Table } from '@gravity/table'
import { Tooltip } from '@gravity/tooltip'
import { IconButton } from '@gravity/icon-button'
import { useRefreshSituation } from '@monorepo/enrollment/hooks/useRefreshSituation'
import type { Dispatch, SetStateAction } from 'react'
import { TableRowProps } from '../EnrollmentTable/types'
import { EnrollmentCycleItem } from '@monorepo/enrollment/services/enrollmentService/types'
import { ReenrollmentStatus } from '@/shared/interfaces'

type EnrollmentTableActionsProps = {
  addContractEnabled: boolean
  enrollmentYear: number
  refreshedEnrollments: EnrollmentCycleItem[]
  rowValue: TableRowProps
  setRefreshedEnrollments: Dispatch<SetStateAction<EnrollmentCycleItem[]>>
}
export const EnrollmentTableActions = ({
  rowValue,
  enrollmentYear,
  setRefreshedEnrollments,
  refreshedEnrollments,
  addContractEnabled,
}: EnrollmentTableActionsProps) => {
  const { schoolSlug, school } = useSelectedSchool()
  const history = useHistory()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const handleAddContract = () => {
    if (!rowValue.isReenrollable) {
      return null
    }

    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_CYCLE,
        name: EventDispatcherEvents.LINK_CLICK,
        customProperties: {
          $Link_name: 'ADICIONAR_NOVO_CONTRATO_OU_REMATRICULAR',
          $table_name: 'LISTA_DE_ALUNOS',
          $value: rowValue.hasStartedEnrollment ? 'Adicionar novo contrato' : 'Rematricular',
          $situation: rowValue.columns.student_status,
        },
      })
    history.push(
      `/${schoolSlug}/contratos/${enrollmentYear}/novo`,

      {
        studentID: rowValue.studentID,
        studentName: rowValue.columns.student_name,
      }
    )
  }

  const handleRefreshStatus = useRefreshSituation(data => {
    const { status, sub_status, is_reenrollable, studentID } = data

    setRefreshedEnrollments(
      refreshedEnrollments.map(student => {
        if (student.student_id === studentID) {
          return {
            ...student,
            student_status: status,
            student_status_details: sub_status,
            is_reenrollable,
          }
        }

        return student
      })
    )

    return []
  })

  const showRefreshButton = (status: ReenrollmentStatus) => {
    return status !== ReenrollmentStatus.ENROLLED
  }
  const getTooltipText = (isReenrollable: boolean, hasStartedEnrollment: boolean) => {
    if (!isReenrollable) {
      return 'É necessário regularizar as parcelas vencidas'
    }

    return hasStartedEnrollment ? 'Adicionar novo contrato' : 'Rematricular'
  }

  return (
    <Table.ActionCell
      actions={
        <>
          {showRefreshButton(rowValue.columns.student_status as ReenrollmentStatus) && (
            <Tooltip text="Atualizar situação de matrícula" position="left">
              <IconButton
                variant="ghost"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation()
                  handleRefreshStatus(
                    school?.id ?? '',
                    rowValue.studentID,
                    enrollmentYear,
                    rowValue.columns.student_status as ReenrollmentStatus,
                    rowValue.columns.student_name
                  )
                }}
              >
                <RefreshOutline />
              </IconButton>
            </Tooltip>
          )}
          {addContractEnabled && (
            <Tooltip
              text={getTooltipText(rowValue.isReenrollable, rowValue.hasStartedEnrollment)}
              position="left"
            >
              <span>
                <IconButton
                  variant="ghost"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation()
                    handleAddContract()
                  }}
                  disabled={!rowValue.isReenrollable}
                >
                  <Add />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </>
      }
    />
  )
}
