import { Text } from '@gravity/text'
import { Skeleton } from '@gravity/skeleton'

import type { SectionProps, SectionPlaceholderProps } from './types'

import { Container } from './styles'
import { Grid, GridItem } from '@gravity/grid'

export const Section = (props: SectionProps) => {
  const {
    title,
    description,
    variation = 'primary',
    variantText = 'subtitle-regular',
    children,
    className = '',
  } = props

  return (
    <Container $variation={variation} className={className}>
      <Grid>
        <GridItem>{title && <Text variant={variantText}>{title}</Text>}</GridItem>
        {description && (
          <GridItem>
            <Text variant="body-2-regular" className="description">
              {description}
            </Text>
          </GridItem>
        )}
        <GridItem>{children}</GridItem>
      </Grid>
    </Container>
  )
}

export const SectionPlaceholder = (props: SectionPlaceholderProps) => {
  const { variation = 'primary', variantSkeleton = 'accent', children, className = '' } = props

  return (
    <Container className={`${className} skeleton`} $variation={variation}>
      <Skeleton variant={variantSkeleton} width={300} />
      <Skeleton variant={variantSkeleton} width={500} />
      {children}
    </Container>
  )
}
