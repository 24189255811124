import { formatDate } from '@/shared/utils/dateFormatters'
import { convertToUTCDate } from '@/shared/utils/convertDateToUTC'

import { PAYOUT_REPORT_STATUS } from '@monorepo/report/constants/PAYOUT_REPORT_STATUS'

import type { PayoutSummary } from '@monorepo/report/models/PayoutSummary'
import type { TuitionPayoutReportData } from '../../../../components/TuitionReportDashboardCard'

/**
 * Utility function to parse payout to a compatible format with Tuition dashboard card component
 *
 * @param payout Tuition payout report to be parsed
 *
 * @returns Parsed tuition payout report
 */
export const tuitionPayoutReportDataMapper = (payout: PayoutSummary): TuitionPayoutReportData => {
  return {
    id: payout.payout_id,
    isOpen: payout.payout_status === PAYOUT_REPORT_STATUS.OPEN,
    payoutValue: payout.final_amount,
    payoutDueDate: convertToUTCDate(formatDate(payout.payout_due_date, 'YYYY-MM')).toDate(),
  }
}
