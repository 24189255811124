import styled from 'styled-components'
import { Card } from '@gravity/card'
import { Button } from '@gravity/button'

export const ContainerHeader = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 184px;
`
export const ContainerActualizationPeriod = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: ${props => props.theme.gravity.spacing[2]};
`

export const PayoutCardContainer = styled(Card)`
  display: flex;
  min-width: 556px;
  height: 318px;
  padding: ${props => props.theme.gravity.spacing[8]};
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
`

export const StrongDescription = styled.strong`
  color: ${props => props.theme.gravity.colors['colors-text-main-2']};
  font-size: ${props => props.theme.gravity.fontSize.sm};
  font-style: normal;
  font-weight: ${props => props.theme.gravity.fontWeight.medium};
  line-height: 150%;
  letter-spacing: 0.25px;
`

export const AnticipationButton = styled(Button)`
  display: flex;
  padding: ${props => props.theme.gravity.spacing[3]};
  justify-content: center;
  align-items: center;
  gap: ${props => props.theme.gravity.spacing[2]};
  align-self: stretch;
  width: 200px;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[4]};
`

export const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
`
