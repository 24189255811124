import { Skeleton } from '@gravity/skeleton'
import { Card } from '@gravity/card'
import styled from 'styled-components'

export const ColumnContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gravity.spacing[2]};
`

export const RowContainer = styled.div`
  max-width: 1366px;

  @media screen and (min-width: 1440px) {
    margin-left: auto;
    margin-right: auto;
  }
`

export const RowFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  justify-content: right;
`

export const TitleSkeletonContainer = styled(ColumnContainer)`
  margin-top: 33px;
  margin-bottom: 53px;
`

export const PairSkeletonContainer = styled(ColumnContainer)`
  margin-bottom: ${props => props.theme.gravity.spacing[3]};
`

export const GroupSkeletonContainer = styled(ColumnContainer)`
  margin-top: ${props => props.theme.gravity.spacing[6]};
  gap: ${props => props.theme.gravity.spacing[4]};
`

export const SkeletonRows = styled(Skeleton)`
  display: flex;
  height: ${props => props.theme.gravity.spacing[6]};
  min-width: ${props => props.theme.gravity.spacing[6]};
  align-items: flex-start;
  gap: ${props => props.theme.gravity.spacing[2]};
`

export const SkeletonStyled = styled(Skeleton)`
  margin-top: ${props => props.theme.gravity.spacing[6]};
  margin-bottom: ${props => props.theme.gravity.spacing[10]};
`

export const TitleSkeleton = styled(Skeleton)`
  margin-bottom: 16px;
  width: 1270px;
  height: 768px;
`

export const SubTitleSkeleton = styled(Skeleton)`
  margin-bottom: 24px;
`

export const TitleChosenOfferDate = styled.div`
  display: flex;
  width: 34.75rem;
  width: 60%;
  align-items: flex-start;
  gap: ${props => props.theme.gravity.spacing[2]};
  flex: 1 0 0;
`

export const ContainerCard = styled(Card)`
  display: flex;
  width: 100%;
  padding: ${props => props.theme.gravity.spacing[6]};
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: ${props => props.theme.gravity.spacing[3]};
  border-radius: ${props => props.theme.gravity.borderRadius[2]};
  background: ${props => props.theme.gravity.colors['colors-background-neutral-2']};
`
