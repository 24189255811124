import styled from 'styled-components'

const StyledSpan = styled.span`
  height: 8px;
  width: 8px;
  flex-shrink: 0;
  border-radius: 50%;
  display: inline-block;
  &.success {
    background-color: ${props =>
      props.theme.gravity.colors['colors-text-semantic-colors-success-1']};
  }
  &.error {
    background-color: ${props => props.theme.gravity.colors['colors-interaction-dangerous-1']};
  }
  &.primary {
    background-color: ${props => props.theme.gravity.colors['colors-background-avatar-blue']};
  }
  &.warning {
    background-color: ${props =>
      props.theme.gravity.colors['colors-background-semantic-colors-warning-1']};
  }
  &.grey {
    background-color: ${props => props.theme.gravity.colors['colors-background-neutral-4']};
  }
  &.darkGrey {
    background-color: ${props => props.theme.gravity.colors['colors-background-neutral-5']};
  }
`

export type StatusBadgeColor = 'success' | 'error' | 'primary' | 'warning' | 'grey' | 'darkGrey'

export type StatusBadgeProps = {
  color?: StatusBadgeColor
}

const StatusBadge = ({ color }: StatusBadgeProps) => (
  <StyledSpan className={color} data-testid="status-badge" />
)

export default StatusBadge
