import type { AxiosInstance } from 'axios'
import type {
  AcceptPaymentPlanResponse,
  CloseCampaignRequest,
  CreateCampaignRequest,
  EnrollmentCycleRequest,
  EnrollmentCycleResponse,
  GetCampaignsRequest,
  GetCampaignsResponse,
  GetCampaignStudentRequest,
  GetCampaignStudentResponse,
  GetEnrollmentCycleSituationResponse,
  GetGuardiansRequest,
  GetGuardiansResponse,
  HasCampaignEnrollmentRequest,
  HasCampaignEnrollmentResponse,
  Installment,
  StatusSummaryRequest,
  StatusSummaryResponse,
  AddTuitionToCampaignResponse,
  AddTuitionBodyRequest,
  UploadSignatureDocumentRequest,
  GetSignatureDocumentResponse,
  GetSignatureDocumentRequest,
} from './types'
import type { ContractCancellationReason } from '@/shared/interfaces'

const getListStudentEnrollmentsQueryParams = (params: EnrollmentCycleRequest) => ({
  params: {
    school_id: params.schoolId,
    name: params.name,
    reference_years: params.referenceYears,
    reference_year: params.referenceYear,
    ...params.pagination,
    ...(params.studentId && { student_id: params.studentId }),
    ...(params.studentIds && { students_ids: params.studentIds }),
    status: params.enrollmentStatus,
    enrollment_sub_status: params.enrollmentSubStatus,
    ...(params.academicPeriodIds && { academic_period_ids: params.academicPeriodIds }),
  },
})

export const enrollmentService = (fetcher: AxiosInstance) => {
  return {
    fetchEnrollmentCycle: async (
      params: EnrollmentCycleRequest
    ): Promise<EnrollmentCycleResponse> => {
      return (
        await fetcher.get<EnrollmentCycleResponse>(
          '/v1/student/enrollment',
          getListStudentEnrollmentsQueryParams(params)
        )
      ).data
    },
    fetchStatusSummary: async (params: StatusSummaryRequest): Promise<StatusSummaryResponse> => {
      return (
        await fetcher.get<StatusSummaryResponse>('/v1/student/enrollment/status-summary', {
          params: {
            school_id: params.schoolId,
            reference_year: params.referenceYear,
          },
        })
      ).data
    },
    updateEnrollmentSituation: async (
      schoolID: string,
      studentID: string,
      enrollmentCycle: number
    ): Promise<GetEnrollmentCycleSituationResponse> => {
      return (
        await fetcher.get<GetEnrollmentCycleSituationResponse>(
          `/v1/student/situation/${schoolID}/${studentID}/${enrollmentCycle}`
        )
      ).data
    },
    /**
     * @deprecated Use packages/shared/src/services/guardian/index.ts instead
     */
    fetchStudentGuardians: async (params: GetGuardiansRequest): Promise<GetGuardiansResponse> => {
      return (
        await fetcher.get<GetGuardiansResponse>('/v2/enrollment/guardian', {
          params: {
            school_id: params.schoolId,
            student_id: params.studentId,
            tax_id: params.taxID,
          },
        })
      ).data
    },
    putEnrollment: async (schoolId: uuid, enrollmentId: uuid, installments: Installment[]) => {
      return await fetcher.put(`v1/installment/${schoolId}/${enrollmentId}/edit`, { installments })
    },
    createCampaign: async (schoolId: uuid, params: CreateCampaignRequest) => {
      return await fetcher.post(`v1/campaign/${schoolId}`, params)
    },
    closeCampaign: async (campaignId: uuid, params: CloseCampaignRequest) => {
      return await fetcher.post(`v1/campaign/${campaignId}/close`, params)
    },
    fetchCampaigns: async (params: GetCampaignsRequest): Promise<GetCampaignsResponse> => {
      return (
        await fetcher.get<GetCampaignsResponse>(`v1/campaign/${params.schoolId}`, {
          params: {
            campaign_id: params.campaignId,
            school_enrollment_cycle_id: params.schoolEnrollmentCycleId,
            status: params.status,
            ...params.pagination,
          },
        })
      ).data
    },
    fetchCampaignStudents: async (
      params: GetCampaignStudentRequest
    ): Promise<GetCampaignStudentResponse> => {
      const paramsQuery = new URLSearchParams()
      paramsQuery.append('sort_order', 'ASC')
      if (params.pagination.page) {
        paramsQuery.append('page', params.pagination.page.toString())
      }
      if (params.pagination.per_page) {
        paramsQuery.append('per_page', params.pagination.per_page.toString())
      }
      if (params.excludes && params.excludes?.length > 0) {
        paramsQuery.append('excludes', params.excludes[0])
        paramsQuery.append('excludes', params.excludes[1])
      }
      return (
        await fetcher.get<GetCampaignStudentResponse>(
          `v1/campaign/${params.campaignId}/students-details`,
          {
            params: paramsQuery,
          }
        )
      ).data
    },
    hasCampaignEnrollment: async (
      params: HasCampaignEnrollmentRequest
    ): Promise<HasCampaignEnrollmentResponse['data']> => {
      return (
        await fetcher.get<HasCampaignEnrollmentResponse>(
          `v1/campaign/${params.schoolId}/students/${params.studentId}/has-campaign-enrollment`,
          {
            params: {
              product_id: params.productId,
              reference_year: params.referenceYear,
            },
          }
        )
      ).data.data
    },

    acceptPaymentPlan: async (enrollmentID: uuid) => {
      return (
        await fetcher.post<AcceptPaymentPlanResponse>(
          `v1/campaign/enrollments/${enrollmentID}/accept-payment-plan`,
          {
            accepted_by: 'pi',
          }
        )
      ).data
    },
    cancel: async (
      enrollmentID: uuid,
      schoolID: uuid,
      params: {
        cancellation_description: string
        cancellation_reason: ContractCancellationReason
      }
    ) => {
      return (await fetcher.patch(`v1/enrollment/${schoolID}/${enrollmentID}/cancel`, params)).data
    },

    addTuitionToCampaign: async (campaignID: uuid, body: AddTuitionBodyRequest) => {
      return (
        await fetcher.post<AddTuitionToCampaignResponse>(
          `v1/campaign/${campaignID}/bulk-add-installments`,
          body
        )
      ).data
    },
    uploadSignatureDocument: async (schoolId: uuid, params: UploadSignatureDocumentRequest) => {
      const formData = new FormData()
      formData.append('file', params.file)
      formData.append('type', params.type)
      return await fetcher.post(`signatures/${schoolId}/documents`, formData)
    },
    getSignatureDocument: async (params: GetSignatureDocumentRequest) => {
      return await fetcher.get<GetSignatureDocumentResponse>(
        `signatures/${params.schoolId}/documents`,
        {
          params: {
            type: params.type,
            download_link: params.download_link,
          },
        }
      )
    },
    getMatriculasApiLegacyEnrollments: async (
      id: uuid,
      reference_year: string,
      school_id: uuid,
      installment_status: string | null = null,
      student_id: uuid | null = null
    ) =>
      (
        await fetcher.get(
          `v1/enrollment/legacy/${id}/${school_id}/${reference_year}?per_page=200`,
          {
            params: {
              installment_status,
              student_id,
            },
          }
        )
      )?.data.data || [],
  }
}
