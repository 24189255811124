import { RefreshOutline } from '@gravity/icons'
import { useState } from 'react'
import { Button } from '@gravity/button'
import { OverrideBatch } from '.'
import { useEvents } from '@monorepo/onboarding/hooks/eventContext'

interface OverrideBatchTriggerProps {
  pageName: string
}

export const OverrideBatchTrigger = ({ pageName }: OverrideBatchTriggerProps) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const event = useEvents()

  return (
    <>
      <Button
        variant="ghost"
        iconStart={<RefreshOutline />}
        onClick={() => {
          event?.overrideSpreadsheetButtonClicked(pageName)
          return setOpenDialog(true)
        }}
      >
        Substituir planilha
      </Button>
      <OverrideBatch
        open={openDialog}
        onClose={() => {
          setOpenDialog(false)
        }}
      />
    </>
  )
}
