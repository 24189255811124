import { useState } from 'react'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { Dialog } from '@gravity/dialog'
import { Button } from '@gravity/button'
import { Text } from '@gravity/text'
import { Checkbox } from '@gravity/checkbox'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'

type GuardianDetailsDisclaimerModalProps = {
  isOpen: boolean
  onClose: () => void
}

export const GuardianDetailsDisclaimerModal = ({
  isOpen,
  onClose,
}: GuardianDetailsDisclaimerModalProps) => {
  const [checked, setChecked] = useState(false)
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const handleChecked = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.INSTALLMENTS_LIST,
        entity: EventDispatcherEntities.OPEN_NEGOTIATION_MODAL_CONFIRM,
        customProperties: {
          $name: 'Estou Ciente',
        },
        name: EventDispatcherEvents.CHECKBOX_SELECTED,
        action: 'click',
      })
    setChecked(!checked)
  }
  return (
    <Dialog
      onOpenChange={onClose}
      open={isOpen}
      backdrop
      size={2}
      title="Este responsável possui negociação em andamento"
      content={
        <div className="flex flex-col gap-4">
          <Text variant="body-2-regular">
            Caso o pagamento da parcela de entrada seja efetuado por um meio não conciliado
            (dinheiro, cheque, permuta, etc.), por favor, registre o recebimento na plataforma no
            momento do pagamento. Caso o recebimento seja registrado em uma data posterior ao
            vencimento, a parcela terá expirado e será necessário realizar uma nova negociação,
            implicando em alterações de valores e condições de pagamento.
          </Text>
          <div className="flex items-start gap-2">
            <div className="mt-1">
              <Checkbox size={1} checked={checked} onCheckedChange={handleChecked} />
            </div>
            <Text variant="body-2-regular">
              Estou ciente, caso o responsável efetue o pagamento por um meio não conciliado, irei
              registar o recebimento no momento do pagamento.
            </Text>
          </div>
          <div className="flex justify-end mt-4">
            <Button autoFocus onClick={onClose} disabled={!checked}>
              Continuar
            </Button>
          </div>
        </div>
      }
    />
  )
}
