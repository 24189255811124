import dayjs, { Dayjs } from 'dayjs'
import { isDayBeforeToday, isDayToday, dateToOnlyDateISO } from '@/shared/utils'
import {
  Contract,
  Receivable,
  ReceivableStatuses,
  ProcessedInstallment,
  Installment,
  InstallmentStatuses,
} from '@/shared/interfaces'
import { assoc, includes } from 'ramda'

const getReceivableStatus = (
  receivable: Receivable,
  installmentStatus?: InstallmentStatuses
): ReceivableStatuses => {
  if (
    receivable.status === ReceivableStatuses.PAID &&
    installmentStatus === InstallmentStatuses.CANCELED
  ) {
    return ReceivableStatuses.PAID_AND_CANCELED
  }
  if (receivable.status !== ReceivableStatuses.OPEN) {
    return receivable.status
  }
  if (isDayBeforeToday(dateToOnlyDateISO(receivable.due_date))) {
    return ReceivableStatuses.OVERDUE
  }
  if (isDayToday(dateToOnlyDateISO(receivable.due_date))) {
    return ReceivableStatuses.DUE_TODAY
  }
  return receivable.status
}

const getProcessedInstallmentId = (activeReceivable: Receivable, isSplitAgglutinated?: boolean) => {
  if (isSplitAgglutinated) {
    return `installment::${activeReceivable?.installment_id}`
  } else {
    return activeReceivable?.id
  }
}

const buildProcessedInstallment = (
  receivable: Receivable,
  index: number,
  array: any[],
  children?: ProcessedInstallment[],
  key?: string
): ProcessedInstallment => {
  return {
    amount: receivable?.current_amount,
    children,
    due_date: receivable?.due_date,
    due_month: dayjs(receivable?.installment?.due_date).utc(),
    id: receivable?.id,
    installment_id: receivable?.installment_id,
    key: key || receivable?.id,
    orderReference: `${index + 1} de ${array.length}`,
    status: receivable?.status,
    type: receivable?.installment?.type,
  }
}

const recurBuildProcessedInstallment = (
  receivable: Receivable,
  processedReceivables: Receivable[],
  idx: number,
  arr: any[],
  isSplitAgglutinated?: boolean
): ProcessedInstallment => {
  const id = getProcessedInstallmentId(receivable, isSplitAgglutinated)

  return buildProcessedInstallment(
    {
      ...receivable,
      id,
    },
    idx,
    arr,
    processedReceivables
      ?.filter(filterReceivable =>
        filterReceivable?.original_receivables?.some(someReceivable => {
          if (!receivable) {
            return false
          }
          let isCheckoutPaid = false

          if (
            someReceivable.status === ReceivableStatuses.CHECKOUTED &&
            filterReceivable.status === ReceivableStatuses.PAID
          ) {
            const checkoutedReceivable = processedReceivables.find(
              pr => pr.id === someReceivable.id
            )
            if (
              checkoutedReceivable &&
              checkoutedReceivable.original_receivables &&
              checkoutedReceivable.original_receivables.length > 0
            ) {
              isCheckoutPaid = checkoutedReceivable.original_receivables.some(
                or => or.id === receivable.id
              )
            }
          }

          return (
            (someReceivable.id === receivable.id || isCheckoutPaid) &&
            filterReceivable.status !== ReceivableStatuses.CHECKOUTED
          )
        })
      )
      .map((mapReceivable, mapIndex, mapArr) =>
        recurBuildProcessedInstallment(mapReceivable, processedReceivables, mapIndex, mapArr)
      ),
    `${receivable?.id}::split::${idx}`
  )
}

const isReceivableNotRenegotiatedOrAgglutinated = (receivable: Receivable): boolean => {
  return ![ReceivableStatuses.RENEGOTIATED, ReceivableStatuses.AGGLUTINATED].includes(
    receivable.status
  )
}

const isReceivableRenegotiatedOrAgglutinated = (receivable: Receivable): boolean => {
  return [ReceivableStatuses.RENEGOTIATED, ReceivableStatuses.AGGLUTINATED].includes(
    receivable.status
  )
}

const makeProcessedInstallment = (
  { receivables, status }: { receivables?: Receivable[]; status?: InstallmentStatuses },
  idx: number,
  arr: Installment[]
  // TODO: fix function return type
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
): ProcessedInstallment | ProcessedInstallment[] => {
  let processedReceivables = receivables
    ?.filter(rec => {
      if (rec.base_amount < 0) {
        return false
      }
      return includes(rec.status, [
        ReceivableStatuses.OPEN,
        ReceivableStatuses.PAID,
        ReceivableStatuses.OVERDUE,
        ReceivableStatuses.DUE_TODAY,
        ReceivableStatuses.AGGLUTINATED,
        ReceivableStatuses.RENEGOTIATED,
        ReceivableStatuses.CHECKOUTED,
      ])
    })
    ?.map(rec => assoc('status', getReceivableStatus(rec, status), rec))

  const activeReceivables = processedReceivables?.filter(({ status }) =>
    includes(status, [
      ReceivableStatuses.OPEN,
      ReceivableStatuses.PAID,
      ReceivableStatuses.OVERDUE,
      ReceivableStatuses.DUE_TODAY,
      ReceivableStatuses.PAID_AND_CANCELED,
    ])
  )

  const isCanceledWithNoActiveReceivables =
    status === InstallmentStatuses.CANCELED && activeReceivables?.length === 0

  if (isCanceledWithNoActiveReceivables && receivables) {
    processedReceivables = [
      receivables
        .filter(rec => rec.status === ReceivableStatuses.CANCELED)
        .sort((a, b) => (dayjs(b.created_at).isBefore(dayjs(a.created_at)) ? -1 : 1))[0], // get the most recent receivable only
    ]
  }

  const agglutinatedReceivableIndex = processedReceivables?.findIndex(
    r => r?.status === ReceivableStatuses.AGGLUTINATED
  )

  const hasAgglutinatedReceivable = agglutinatedReceivableIndex !== -1
  const isSplitReceivable = activeReceivables?.length && activeReceivables?.length > 1

  const sortedProcessedReceivables =
    processedReceivables &&
    [...processedReceivables]?.sort((a, b) =>
      dayjs(b.created_at).isBefore(dayjs(a.created_at)) ? 1 : -1
    )
  const oldestRenegotiatedReceivableIndex = sortedProcessedReceivables?.findIndex(
    r => r?.status === ReceivableStatuses.RENEGOTIATED
  )
  const hasRenegotiatedReceivable = agglutinatedReceivableIndex !== -1

  const isSimpleRenegotiation =
    processedReceivables &&
    processedReceivables.length >= 2 &&
    processedReceivables.filter(isReceivableNotRenegotiatedOrAgglutinated).length === 1 &&
    processedReceivables.filter(isReceivableRenegotiatedOrAgglutinated).length ===
      processedReceivables.length - 1
  const hasMultipleReceivablesWithNoRenegotiation = isSplitReceivable && !hasRenegotiatedReceivable
  if (
    (isSimpleRenegotiation || hasMultipleReceivablesWithNoRenegotiation) &&
    processedReceivables
  ) {
    return processedReceivables.map(receivable => buildProcessedInstallment(receivable, idx, arr))
  }

  const isSplitAgglutinated = Boolean(isSplitReceivable && hasAgglutinatedReceivable)

  if (hasAgglutinatedReceivable) {
    // Case: split simples (split dividido em uma parcela) que foi aglutinado com uma parcela de fora
    if (
      processedReceivables &&
      agglutinatedReceivableIndex &&
      processedReceivables[agglutinatedReceivableIndex]?.original_receivables?.length
    ) {
      const original = processedReceivables[agglutinatedReceivableIndex]?.original_receivables?.[0]

      const hasOpen = activeReceivables?.length && activeReceivables?.length > 0

      if (original?.status === ReceivableStatuses.RENEGOTIATED && !hasOpen) {
        return buildProcessedInstallment(
          processedReceivables[agglutinatedReceivableIndex],
          idx,
          arr
        )
      }
    }
  }

  if (
    hasAgglutinatedReceivable &&
    processedReceivables?.length === 1 &&
    agglutinatedReceivableIndex
  ) {
    // Problema: ao incluir receivables aglutinados em activeReceivables,
    // a funcao entenderia que o receivable que recebeu a aglutinacao eh um split (L176).
    // Ainda, ao nao inclui-los nessa lista, caso o receivable fosse um aglutinado,
    // ele nao mostraria o receivable, ja que a funcao usa o activeReceivables como default pra pegar os dados.
    return buildProcessedInstallment(processedReceivables[agglutinatedReceivableIndex], idx, arr)
  }

  const activeReceivable = isCanceledWithNoActiveReceivables
    ? processedReceivables?.[0]
    : activeReceivables?.[0]

  if (
    isSplitReceivable &&
    sortedProcessedReceivables &&
    oldestRenegotiatedReceivableIndex &&
    processedReceivables
  ) {
    return recurBuildProcessedInstallment(
      sortedProcessedReceivables[oldestRenegotiatedReceivableIndex],
      processedReceivables,
      idx,
      arr,
      isSplitAgglutinated
    )
  }

  if (activeReceivable) {
    return buildProcessedInstallment(activeReceivable, idx, arr)
  }
}

export const processInstallments = (resp?: Contract): ProcessedInstallment[] | undefined => {
  if (!resp?.installments) return []
  return resp.installments.map(makeProcessedInstallment).flat().filter(Boolean)
}

export type AgglutinationMetaData = {
  discount: cents
  due_date: Dayjs
  payment_method: string[]
}

// TODO: MOVE ALL THIS FILE TO A UTILS FILE, AND FIX ALL PLACES THAT USES THIS FUNCTIONS
