import { useMemo } from 'react'
import { formatMicroCentsToReal, formatDate, prettifyFullName } from '@/shared/utils'
import {
  ColumnProps,
  PaginationEvents,
  PayoutReportTableProps,
  TableData,
} from '@monorepo/report/components/ReportTable/types'
import {
  Filter,
  FilterObjectKeys,
} from '@monorepo/report/hooks/useIsaacPayPayoutReportFilter/types'
import { ReportTable } from '@monorepo/report/components/ReportTable'
import { useIsaacPayPayoutReportFilter } from '@monorepo/report/hooks/useIsaacPayPayoutReportFilter'
import { REPORT_PAGE_NAMES } from '@monorepo/report/constants/REPORT_PAGE_NAMES'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

type ColumnNames =
  | 'student_name'
  | 'product_name'
  | 'last_receivable_paid_date'
  | 'total_amount'
  | 'events'

/**
 * IsaacPay payout report installments table component
 *
 * @param props
 * @param props.transactions Table data
 * @param props.availableFilters Filters that are possible to be applied
 * @param props.onRowClick Callback to be called when a row is clicked
 * @param props.totalItems Total number of items
 * @param props.isNewVersionPayout Indicates if payout is in new version
 * @param props.isLoading Indicates if loading state should be displayed
 */
export const IsaacPayReportTable = ({
  transactions,
  availableFilters,
  onRowClick,
  totalItems,
  isMonthlyViewEnabled,
  isNewVersionPayout,
  isLoading = true,
}: PayoutReportTableProps<Filter>) => {
  const {
    isInitialized: isEventDispatcherInitialized,
    eventDispatcherClient,
  } = useEventDispatcher()

  const enableTableFiltering = useMemo(() => {
    return (
      !!availableFilters &&
      Object.keys(availableFilters).length > 0 &&
      Object.values(availableFilters).some(filter => Array.isArray(filter) && filter.length > 0)
    )
  }, [availableFilters])

  const COLUMNS: ColumnProps[] = [
    { name: 'student_name', displayName: 'Aluno', sortable: !isMonthlyViewEnabled },
    { name: 'product_name', displayName: 'Produto' },
    {
      name: 'last_receivable_paid_date',
      displayName: 'Competência',
      sortable: !isMonthlyViewEnabled,
    },
    { name: 'total_amount', displayName: 'Valor do repasse' },
    { name: 'events', displayName: 'Mudanças' },
  ]

  const FILTERS: Record<FilterObjectKeys, string> = {
    events: 'Mudanças',
    amountSigns: 'Valor final',
    products: 'Produtos',
  }

  const handleRowClick = (row: TableData) => {
    onRowClick(row)
  }

  const onPageChange = (event: PaginationEvents, value: string) => {
    isEventDispatcherInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ISAAC_PAY_REPORT,
        name:
          event === PaginationEvents.PAGE
            ? EventDispatcherEvents.PAGINATION_CHANGE_PAGE
            : EventDispatcherEvents.PAGINATION_CHANGE_PAGE_SIZE,
        action: 'click',
        customProperties: {
          $page_name: REPORT_PAGE_NAMES.ISAAC_PAY_PAYOUT_REPORT,
          $event: event,
          $value: value,
        },
      })
  }

  const onSortTable = (sortBy: string, sortOrder: string) => {
    isEventDispatcherInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ISAAC_PAY_REPORT,
        name: EventDispatcherEvents.TABLE_ORDERING,
        action: 'click',
        customProperties: {
          $page_name: REPORT_PAGE_NAMES.ISAAC_PAY_PAYOUT_REPORT,
          $sort_by: sortBy,
          $sort_order: sortOrder,
        },
      })
  }

  const onSearchTable = (value: string) => {
    isEventDispatcherInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ISAAC_PAY_REPORT,
        name:
          value !== '' ? EventDispatcherEvents.APPLY_FILTER : EventDispatcherEvents.RESET_FILTER,
        action: 'type',
        customProperties: {
          $page_name: REPORT_PAGE_NAMES.ISAAC_PAY_PAYOUT_REPORT,
        },
      })
  }

  const rows: TableData[] = useMemo(() => {
    const formattedRows = transactions.map(item => ({
      id: item.referrals.installment_id,
      key: item.referrals.installment_id,
      studentName: prettifyFullName(item.referrals.student_name),
      productName: item.referrals.product_name,
      referenceMonth: formatDate(item.reference_month, 'MMM YYYY'),
      totalAmount: formatMicroCentsToReal(item.financial_detail.total),
      events: item.financial_detail?.events,
    }))

    return formattedRows
  }, [transactions])

  return (
    <ReportTable<ColumnNames, Filter>
      filterHook={useIsaacPayPayoutReportFilter}
      availableFilters={availableFilters}
      columns={COLUMNS}
      rows={rows}
      reportPage={REPORT_PAGE_NAMES.ISAAC_PAY_PAYOUT_REPORT}
      emptyStateMessage={
        isNewVersionPayout
          ? 'Não houveram pagamentos de matrículas neste período.'
          : 'Nenhum resultado encontrado'
      }
      filterTypes={FILTERS}
      onSearch={onSearchTable}
      onSort={onSortTable}
      onPagination={onPageChange}
      totalItems={totalItems}
      isLoading={isLoading}
      handleRowClick={handleRowClick}
      hasFilters={enableTableFiltering}
    />
  )
}
