import { useEffect, useState } from 'react'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { Button } from '@gravity/button'
import { TextArea } from '@gravity/text-area'
import { Separator } from '@gravity/separator'

import { formatCentsToReal, formatDate } from '@/shared/utils'
import { useCampaignContext } from '@monorepo/enrollment/contexts/CampaignContext'
import { ReactComponent as IsaacLogoSVG } from '@/shared/assets/isaac-logo.svg'
import EnrollmentSealImage from '@monorepo/enrollment/assets/enrollment-seal.svg'
import { useEnrollmentMixpanelEvents } from '@monorepo/enrollment/hooks/useEnrollmentMixpanelEvents'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'

import { Offer } from '../PaymentConditionsStep/types'
import * as Styled from './styles'
import { Tooltip } from '@gravity/tooltip'
import {
  ArticleOutline,
  DashedArrowLeft,
  DashedArrowRight,
  FacebookBox,
  InformationOutline,
  InstagramOutline,
  LinkedinBox,
  Youtube,
} from '@gravity/icons'
import theme from '@/shared/theme'
import { Footer } from '@gravity/footer'
import { NewCampaignStepProps } from '../../types'

export const CampaignPublicityStep = ({ onFinish, enrollmentCycle }: NewCampaignStepProps) => {
  const { stepper, conditions, setCustomEmailMsg, customEmailMsg } = useCampaignContext()
  const offers = conditions?.offers ?? []

  const [emailMsg, setEmailMsg] = useState(customEmailMsg)
  const [isDirty, setIsDirty] = useState(false)
  const isValid = emailMsg.length <= 2000
  const hasError = !isValid && isDirty

  const previousReferenceYear = enrollmentCycle.reference_year - 1

  const { sendPageViewEvent, sendButtonClickEvent } = useEnrollmentMixpanelEvents()

  const isAutoAcceptPaymentPlanEnabled = useUnleashFlag(
    UnleashFlags.MAT_231_CAMPAIGN_AUTO_ACCEPT_PAYMENT_PLAN
  )

  useEffect(() => {
    sendPageViewEvent('DIVULGACAO_CAMPANHA')
  }, [])

  const emailTextBody = (
    <>
      Olá, <Text variant="body-1-medium">PRIMEIRO NOME.</Text>
      <br />
      <br />
      Sua escola iniciou uma campanha de matrícula para o novo ano letivo de{' '}
      <Text variant="body-1-medium">NOME DO ALUNO</Text>.
      {offers.length > 0
        ? ' A pré-matrícula possui condições especiais dependendo da data de pagamento. Confira:'
        : ` Você pode iniciar o seu processo de
      matrícula para ${enrollmentCycle.reference_year} através do meu isaac.`}
    </>
  ) as React.ReactNode & string

  function handleSubmit() {
    if (hasError) return
    setCustomEmailMsg(emailMsg)
    sendButtonClickEvent('continuar_divulgacao')
    onFinish()
  }

  function renderPaymentCondition(offer: Offer, index: number) {
    const title = `Condição de pagamento ${index + 1}:`

    return (
      <Styled.PaymentConditionBox key={index}>
        <ArticleOutline />
        <Text variant="body-2-regular">{title}</Text>
        <Text variant="body-2-regular">Valor da pré-matrícula</Text>
        <Heading variant="heading-h4-medium">
          {offer.amount ? formatCentsToReal(offer.amount) : ''}
        </Heading>
        <Text variant="body-2-regular">Para pagamento até:</Text>
        <Heading variant="heading-h4-medium">
          {offer.expirationDate ? formatDate(offer.expirationDate, 'DD/MM/YYYY') : ''}
        </Heading>
      </Styled.PaymentConditionBox>
    )
  }

  return (
    <div className="flex flex-col">
      <Styled.Wrapper>
        <Heading variant="heading-h3-medium">Divulgação</Heading>
        <div style={{ marginTop: theme.gravity.spacing[4] }}>
          <Text variant="body-1-regular">
            {isAutoAcceptPaymentPlanEnabled && offers.length > 0
              ? 'Os responsáveis serão notificados pelo meu isaac e por este e-mail, com o link da fatura já gerado para pagamento. O não pagamento da parcela não resultará em multas ou juros para o responsável e a próxima condição de matrícula será gerada (se houver). O e-mail de envio será no-reply@matriculas.isaac.com.br.'
              : 'Os responsáveis serão notificados através deste e-mail e do meu isaac. Se desejar, personalize a mensagem no campo abaixo. O e-mail de envio será no-reply@matriculas.isaac.com.br.'}
          </Text>
        </div>
        <Styled.EmailWrapper>
          <Styled.EmailSenderWrapper>
            <Heading variant="heading-h4-medium">
              Sua escola iniciou o processo de matrículas
            </Heading>
            <div style={{ display: 'flex', marginTop: theme.gravity.spacing[2] }}>
              <Styled.IsaacLogoCircle>
                <Styled.IsaacLogoWrapper width="100%">
                  <IsaacLogoSVG />
                </Styled.IsaacLogoWrapper>
              </Styled.IsaacLogoCircle>
              <Styled.SenderTextWrapper>
                <Text variant="body-2-medium">De: no-reply@matriculas.isaac.com.br</Text>
                <Text variant="body-2-regular">Para: responsáveis</Text>
              </Styled.SenderTextWrapper>
            </div>
          </Styled.EmailSenderWrapper>
          <Styled.EmailHeader>
            <Styled.TitleImgWrapper>
              <Styled.IsaacLogoWrapper width="100px">
                <IsaacLogoSVG />
              </Styled.IsaacLogoWrapper>
              <Heading variant="heading-h3-medium">{`Matrículas ${enrollmentCycle.reference_year}`}</Heading>
            </Styled.TitleImgWrapper>
            <Styled.IsaacLogoWrapper width="129px">
              <img src={EnrollmentSealImage} alt="Selo de matrícula" />
            </Styled.IsaacLogoWrapper>
          </Styled.EmailHeader>
          <Styled.EmailBody>
            <Text variant="body-1-regular">{emailTextBody}</Text>
            <Styled.PaymentConditionBoxWrapper>
              {offers.map(renderPaymentCondition)}
            </Styled.PaymentConditionBoxWrapper>
            <Button>Ver mais detalhes no meu isaac</Button>
            <div>
              <div
                style={{
                  marginTop: theme.gravity.spacing[8],
                  marginBottom: theme.gravity.spacing[2],
                }}
              >
                <Text variant="subtitle-medium">Mensagem personalizada</Text>{' '}
                <Tooltip text="A mensagem personalizada não é obrigatória, mas deve respeitar o limite máximo de 2000 caracteres.">
                  <div style={{ display: 'inline-block' }}>
                    <InformationOutline size="xs" />
                  </div>
                </Tooltip>
              </div>
              <TextArea
                className="w-full"
                error={hasError}
                errorMessage="A mensagem deve ter menos de 2000 caracteres"
                placeholder="Caso queira, inclua as considerações finais do e-mail."
                size={3}
                onChange={e => setEmailMsg(e.target.value)}
                onBlur={() => setIsDirty(true)}
              />
            </div>
            <Styled.EmailSeparator>
              <Separator color="neutral-3" orientation="horizontal" />
            </Styled.EmailSeparator>
            <Text variant="body-1-regular">
              É necessário estar em dia com suas obrigações financeiras para iniciar o processo de
              matrícula.
            </Text>
            <br />
            <br />
            <Text variant="body-1-regular">
              {`Se houver pendências financeiras até o fechamento do contrato de ${previousReferenceYear}, o pagamento da pré-matrícula não garante a vaga do aluno.`}
            </Text>
            <br />
            <br />
            <Text variant="body-1-regular">
              {`O não pagamento da parcela não resultará em multas e juros, mas pode representar um atraso no processo de matrícula do aluno. Consulte sua escola para saber mais sobre os detalhes do processo de matrícula e reserva de vaga para ${enrollmentCycle.reference_year}.`}
            </Text>
          </Styled.EmailBody>
          <Styled.EmailFooter>
            <Text variant="body-1-medium">Um grande abraço, time isaac.</Text>
            <Styled.FooterIconsWrapper>
              <InstagramOutline color={theme.gravity.colors['colors-interaction-primary-1']} />
              <FacebookBox color={theme.gravity.colors['colors-interaction-primary-1']} />
              <LinkedinBox color={theme.gravity.colors['colors-interaction-primary-1']} />
              <Youtube color={theme.gravity.colors['colors-interaction-primary-1']} />
            </Styled.FooterIconsWrapper>
            <Text variant="body-1-medium">Nosso escritório:</Text>
            <Text variant="body-1-regular">Rua Augusta, 2840 - Cerqueira César - São Paulo</Text>
          </Styled.EmailFooter>
        </Styled.EmailWrapper>
      </Styled.Wrapper>
      <Footer
        secondaryButton={{
          label: 'Anterior',
          iconStart: <DashedArrowLeft />,
          onClick: stepper.goToPreviousStep,
        }}
        primaryButton={{
          label: 'Próximo',
          iconEnd: <DashedArrowRight />,
          onClick: handleSubmit,
        }}
      />
    </div>
  )
}
