import styled from 'styled-components'
import { Text } from '@gravity/text'

export const CardContainer = styled.div`
  padding: ${props => props.theme.gravity.spacing[4]};
  border: 1px solid ${props => props.theme.gravity.colors['colors-border-neutral-3']};
  border-radius: ${props => props.theme.gravity.borderRadius[2]};
  background-color: #fff;
  min-width: 360px;
  max-width: 360px;
  display: flex;
  gap: ${props => props.theme.gravity.spacing[3]};
  align-items: start;
  align-self: stretch;
`

export const CardTitle = styled(Text)`
  line-height: 24px;
  margin-bottom: 4px;
  display: block;
`

export const NoTitleContainer = styled.div`
  min-width: 200px;
  max-width: 200px;
`

// Remoção da tela temporária
export const ExternalLinkContainer = styled.div`
  display: flex;
  justify-content: end;
  display: none;
`

export const ExternalLink = styled.a`
  font-size: 14px;
  line-height: 24px;
  display: block;
  margin: 8px 0 4px;
  &::after {
    content: ' ›';
  }
`
