import React from 'react'

type UseBeforeUnloadProps = {
  callback?: (event: BeforeUnloadEvent) => any
  condition?: boolean
}

export function useBeforeUnload({ callback, condition = true }: UseBeforeUnloadProps): void {
  React.useEffect(() => {
    if (!condition) return

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      callback?.(event)
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [callback, condition])
}
