import { Button } from '@gravity/button'
import * as S from './styles'
import type { BoxFooterProps } from './types'

const BoxFooter = ({ onSave, onCancel, isValid }: BoxFooterProps) => {
  return (
    <S.StyledWrapper>
      <Button
        type="button"
        style={{ marginRight: '4px' }}
        variant="ghost"
        onMouseDown={e => {
          e.preventDefault()
        }}
        onClick={() => {
          onCancel()
        }}
      >
        Cancelar
      </Button>
      <Button
        type="button"
        onClick={() => {
          onSave()
        }}
        disabled={!isValid}
      >
        Salvar
      </Button>
    </S.StyledWrapper>
  )
}

export default BoxFooter
