import { useHistory, useParams } from 'react-router-dom'
import { LayoutTemplate } from '../../components/LayoutTemplate'
import { useStudentDetailsQuery } from '../../hooks/useStudentDetailsQuery'
import { StudentForm } from './StudentForm'
import { GuardiansForm } from './GuardiansForm'
import { ProductsForm } from './ProductsForm'
import { Contracts } from './Contracts'
import { Loading } from '../../components/Loading'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { EditStudentFormProps, useEditStudentForm } from '../../hooks/useEditStudentForm'
import { FormProvider } from 'react-hook-form'
import { useEffect, useMemo, useState } from 'react'
import { usePatchStudentDetails } from '../../hooks/usePatchStudentDetails'
import { useToast } from '@gravity/toast'
import { useBeforeUnload } from '../../hooks/useBeforeUnload'
import { Dialog } from '@gravity/dialog'
import { Button } from '@gravity/button'
import { Text } from '@gravity/text'
export const EditStudentPage = () => {
  const { batchID, studentID } = useParams<{ batchID: string; studentID: string }>()
  const history = useHistory()
  const { toast } = useToast()
  const { schoolSlug } = useSelectedSchool()
  const { data, isLoading } = useStudentDetailsQuery({ batchID, studentID })
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)

  // TODO: find out if there are alternatives in new versions of react-hook-form, like https://react-hook-form.com/docs/useform#values
  const form = useEditStudentForm({
    defaultValues: useMemo(() => {
      return data
    }, [data]),
  })

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = form

  useEffect(() => {
    reset(data)
  }, [data])

  const {
    mutateAsync: executePatchStudentDetails,
    isLoading: isLoadingPatchStudentDetails,
  } = usePatchStudentDetails({
    onSuccess: data => {
      reset(data)
      toast({
        title: 'Os dados foram salvos com sucesso.',
        type: 'success',
      })
    },
    onError: error => {
      console.error(error)
      toast({
        title: 'Erro ao atualizar dados',
        type: 'error',
      })
    },
  })

  // If the user tries to leave the page by closing the tab, we ask for confirmation
  useBeforeUnload({
    condition: isDirty,
  })

  const navigateToStudentsPage = () => {
    history.push(`/${schoolSlug}/v2/migrar-dados/${batchID}`)
  }

  const handlePatchStudentDetails = async (data: EditStudentFormProps) => {
    return executePatchStudentDetails(data)
  }

  if (isLoading) return <Loading />

  return (
    <FormProvider {...form}>
      <LayoutTemplate
        title={`Editar dados de ${data?.name}`}
        onClose={() => {
          if (isDirty) return setHasUnsavedChanges(true)

          navigateToStudentsPage()
        }}
        primaryButton={{
          loading: isLoadingPatchStudentDetails,
          type: 'submit',
          label: 'Salvar dados',
          disabled: !isDirty || isLoadingPatchStudentDetails,
          onClick: () => {
            handleSubmit(handlePatchStudentDetails)()
          },
        }}
        ghostButton={{
          label: 'Cancelar',
          onClick: () => navigateToStudentsPage(),
        }}
      >
        <div className="flex flex-col gap-14">
          <StudentForm />
          <GuardiansForm />
          <ProductsForm />
          <Contracts />
        </div>

        {/* Unsaved changes dialog */}
        <Dialog
          size={2}
          backdrop
          open={hasUnsavedChanges}
          onOpenChange={setHasUnsavedChanges}
          title="Sair sem salvar?"
          description={
            <Text variant="body-2-regular">
              Se você sair da página agora, todas as alterações feitas serão perdidas.
            </Text>
          }
          cancelButton={
            <Button size={2} variant="ghost" onClick={() => setHasUnsavedChanges(false)}>
              Cancelar
            </Button>
          }
          actionButton={
            <Button size={2} onClick={navigateToStudentsPage}>
              Sair
            </Button>
          }
        />
      </LayoutTemplate>
    </FormProvider>
  )
}
