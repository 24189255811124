import {
  formatCentsToReal,
  datePTToOnlyDateISO,
  formatCPF,
  dueDateFormatter,
} from 'src/shared/utils'
import { Drawer } from '@olaisaac/design-system'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'
import { Separator } from '@gravity/separator'
import { Button } from '@gravity/button'
import { sum, prop } from 'ramda'
import { SummaryProps } from './types'
import { SENT_BY } from 'src/shared/interfaces'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EnrollmentEventDispatcherEvents } from '@/shared/models/enums/EnrollmentEventDispatcherEvents.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { useToast } from '@gravity/toast'
import dayjs from 'dayjs'
import * as Styled from './styles'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'

function ContractSummary({
  contractCreationProgress,
  envelopeTemplateReferralId,
  installments,
  discounts,
  formValues,
  isEnrollmentEnabled,
  installmentAmount,
  isLoading,
  enrollmentAmount,
  enrollmentDiscount,
  enrollmentInstallments,
  enrollmentFirstDueDay,
  firstDueDay,
  setIsEditing,
}: SummaryProps) {
  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  const enrollmentTotalAmount = isEnrollmentEnabled
    ? (enrollmentAmount ?? 0) * (enrollmentInstallments ?? 0)
    : 0
  const enrollmentInstallmentDiscount = isEnrollmentEnabled
    ? sum(enrollmentDiscount?.map(prop('amount')) || [0])
    : 0
  const enrollmentTotalDiscount = isEnrollmentEnabled
    ? enrollmentInstallmentDiscount * (enrollmentInstallments ?? 0)
    : 0
  const enrollmentInstallment = isEnrollmentEnabled
    ? (enrollmentAmount ?? 0) - enrollmentInstallmentDiscount
    : 0
  const enrollmentDiffTotal = isEnrollmentEnabled
    ? enrollmentTotalAmount - enrollmentTotalDiscount
    : 0

  const installmentsTotalDiscount =
    discounts?.length && sum(discounts.map(prop('amount'))) * installments
  const installmentsTotal = installmentAmount * installments || 0

  const installment = installments
    ? (installmentsTotal - installmentsTotalDiscount) / installments
    : 0

  const installmentTotal = installmentsTotal - installmentsTotalDiscount

  const tuition = isEnrollmentEnabled
    ? enrollmentTotalAmount + installmentsTotal
    : installmentsTotal
  const totalDiscount = installmentsTotalDiscount + enrollmentTotalDiscount
  const amountDiffTotal = enrollmentDiffTotal + installmentTotal
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const {
    student,
    product,
    due_day,
    enrollment_due_day,
    guardian: {
      name: guardianName,
      email: guardianEmail,
      address: guardianAddress,
      phone_number: guardianPhoneNumber,
      tax_id: guardianTaxId,
    },
  } = formValues

  const firstInstallment = firstDueDay ? datePTToOnlyDateISO(firstDueDay.utc()) : '--'
  const firstEnrollmentInstallment = enrollmentFirstDueDay
    ? datePTToOnlyDateISO(enrollmentFirstDueDay.utc())
    : '--'
  const dueDay = due_day || dueDateFormatter(firstDueDay ?? dayjs())
  const enrollmentDueDay = enrollment_due_day || dueDateFormatter(enrollmentFirstDueDay ?? dayjs())

  const { state_code, street, number, additional_information, city, zip } = guardianAddress ?? {}

  const formattedEnrollmentDiscounts = enrollmentDiscount?.length
    ? `- ${formatCentsToReal(enrollmentTotalDiscount)}`
    : `- ${formatCentsToReal(0)}`

  const hasMultipleInstallments = installments > 1
  const hasMultipleEnrollmentInstallments = (enrollmentInstallments ?? 0) > 1

  const { toast } = useToast()

  return (
    <Drawer open>
      <Styled.SaveContractDrawerHeaderContainer className={renderNewHeader ? 'mt-12' : ''}>
        <Heading variant="heading-h2-medium" className={renderNewHeader ? 'mt-8 mb-2' : 'mb-2'}>
          Resumo
        </Heading>
        <Text variant="subtitle-regular">
          Confira abaixo se os dados do cadastro ao lado estão corretos para o contrato
        </Text>
      </Styled.SaveContractDrawerHeaderContainer>
      <Styled.DrawerContent>
        {isEnrollmentEnabled ? (
          <div className="mb-4">
            <div className="flex flex-row justify-between">
              <Styled.SecondaryText variant="body-2-regular">
                Total da pré-matrícula
              </Styled.SecondaryText>
              <Styled.SecondaryText variant="body-2-regular">
                {formatCentsToReal(enrollmentTotalAmount)}
              </Styled.SecondaryText>
            </div>
            <div className="flex flex-row justify-between my-2">
              <Text variant="body-2-regular">Descontos na pré-matrícula</Text>
              <Text variant="body-2-regular">{formattedEnrollmentDiscounts}</Text>
            </div>
            <div className="flex flex-row justify-between">
              <Text variant="body-2-medium">
                {`${enrollmentInstallments === 1 ? 'Parcela' : 'Parcelas'} pré-matrícula`}
              </Text>
              <Text variant="body-2-medium">
                {`${enrollmentInstallments}x de ${
                  (enrollmentInstallments ?? 0) > 0
                    ? formatCentsToReal(enrollmentInstallment)
                    : formatCentsToReal(0)
                }`}
              </Text>
            </div>
          </div>
        ) : null}
        <div className="flex flex-row justify-between">
          <Styled.SecondaryText variant="body-2-regular">
            Total das mensalidade
          </Styled.SecondaryText>
          <Styled.SecondaryText variant="body-2-regular">
            {formatCentsToReal(installmentsTotal)}
          </Styled.SecondaryText>
        </div>
        <div className="flex flex-row justify-between my-2">
          <Text variant="body-2-regular">Descontos na mensalidade</Text>
          <Text variant="body-2-regular">
            {`- ${formatCentsToReal(installmentsTotalDiscount)}`}
          </Text>
        </div>
        <div className="flex flex-row justify-between">
          <Text variant="body-2-medium">{installments === 1 ? 'Mensalidade' : 'Mensalidades'}</Text>
          <Text variant="body-2-medium">
            {`${installments}x de ${formatCentsToReal(installment)}`}
          </Text>
        </div>

        <div className="my-9">
          <Separator color="neutral-2" />
        </div>
        <div className="flex flex-row justify-between">
          <Styled.SecondaryText variant="body-2-regular">Anuidade</Styled.SecondaryText>
          <Styled.SecondaryText id="total-value-product" variant="body-2-regular">
            {formatCentsToReal(tuition)}
          </Styled.SecondaryText>
        </div>
        <div className="flex flex-row justify-between my-2">
          <Styled.SecondaryText variant="body-2-regular">Total de descontos</Styled.SecondaryText>
          <Styled.SecondaryText id="total-value-product" variant="body-2-regular">
            {`- ${formatCentsToReal(totalDiscount)}`}
          </Styled.SecondaryText>
        </div>
        <div className="flex flex-row justify-between">
          <Text variant="body-2-medium">Total com descontos</Text>
          <Text id="total-value-product" variant="body-2-medium">
            {formatCentsToReal(amountDiffTotal)}
          </Text>
        </div>
        {student ? (
          <>
            <div className="my-9">
              <Separator color="neutral-2" />
            </div>
            <div className="flex justify-between flex-col">
              <Styled.SecondaryText className="mt-1" variant="overline">
                ALUNO
              </Styled.SecondaryText>
              <Text variant="body-2-regular" id="summary-student-name">
                {student?.name}
              </Text>
              <Styled.SecondaryText className="mt-1" variant="overline">
                PRODUTO
              </Styled.SecondaryText>
              {product && (
                <Text variant="body-2-regular" id="summary-product">
                  {product.name}
                </Text>
              )}
            </div>
          </>
        ) : null}
        {firstEnrollmentInstallment || firstInstallment ? (
          <>
            <div className="my-9">
              <Separator color="neutral-2" />
            </div>
            <div className="flex justify-between flex-col">
              {isEnrollmentEnabled ? (
                <>
                  <Styled.SecondaryText className="mt-1" variant="overline">
                    VENCIMENTO PRIMEIRA PARCELA - PRÉ-MATRÍCULA
                  </Styled.SecondaryText>
                  <Text variant="body-2-regular">{firstEnrollmentInstallment}</Text>
                  <Styled.SecondaryText className="mt-1" variant="overline">
                    PARCELAS
                  </Styled.SecondaryText>
                  <Text variant="body-2-regular">{`${enrollmentInstallments}x`}</Text>
                  {hasMultipleEnrollmentInstallments && (
                    <>
                      <Styled.SecondaryText className="mt-1" variant="overline">
                        DIA DE VENCIMENTO DAS DEMAIS PARCELAS
                      </Styled.SecondaryText>
                      <Text variant="body-2-regular" id="summary-installments">
                        {enrollmentDueDay}
                      </Text>
                    </>
                  )}
                </>
              ) : null}

              <Styled.SecondaryText className="mt-1" variant="overline">
                VENCIMENTO PRIMEIRA PARCELA - MENSALIDADE
              </Styled.SecondaryText>
              <Text
                variant="body-2-regular"
                id="summary-installments-start_month"
                data-testid="summary-installments-start_month"
              >
                {firstInstallment}
              </Text>
              <Styled.SecondaryText className="mt-1" variant="overline">
                PARCELAS
              </Styled.SecondaryText>
              <Text variant="body-2-regular" id="summary-installments">{`${installments}x`}</Text>
              {hasMultipleInstallments && (
                <>
                  <Styled.SecondaryText className="mt-1" variant="overline">
                    DIA DE VENCIMENTO DAS DEMAIS MENSALIDADES
                  </Styled.SecondaryText>
                  <Text variant="body-2-regular" id="summary-installments">
                    {dueDay}
                  </Text>
                </>
              )}
            </div>
          </>
        ) : null}

        {guardianName ? (
          <>
            <div className="my-9">
              <Separator color="neutral-2" />
            </div>
            <div className="flex flex-col justify-between">
              <Styled.SecondaryText className="mt-1" variant="overline">
                RESPONSÁVEL FINANCEIRO
              </Styled.SecondaryText>
              <Text variant="body-2-regular" id="summary-guardian-name">
                {guardianName}
              </Text>
              <Styled.SecondaryText className="mt-1" variant="overline">
                CPF
              </Styled.SecondaryText>
              <Text variant="body-2-regular" id="summary-guardian-tax_id">
                {formatCPF(guardianTaxId)}
              </Text>
              <Styled.SecondaryText className="mt-1" variant="overline">
                E-MAIL
              </Styled.SecondaryText>
              <Text variant="body-2-regular" id="summary-guardian-email">
                {guardianEmail}
              </Text>
              <Styled.SecondaryText className="mt-1" variant="overline">
                CONTATO
              </Styled.SecondaryText>
              <Text variant="body-2-regular" id="summary-guardian-phone_number">
                {guardianPhoneNumber}
              </Text>
              <Styled.SecondaryText className="mt-1" variant="overline">
                ENDEREÇO
              </Styled.SecondaryText>
              <Text variant="body-2-regular" id="summary-guardian-address">
                {`${[street, number, additional_information, city].filter(Boolean).join(', ')}${
                  state_code && `/${state_code}, `
                }${zip && `CEP ${zip}`}`}
              </Text>
            </div>
          </>
        ) : null}
        <>
          <div className="my-9">
            <Separator color="neutral-2" />
          </div>
          <div className="flex flex-col justify-between">
            {isEnrollmentEnabled && (
              <>
                <Styled.SecondaryText variant="overline">Primeira cobrança</Styled.SecondaryText>
                <Text
                  variant="body-2-regular"
                  id="summary-send-first-enrollment-installment-message"
                >
                  {formValues.send_first_enrollment_installment_message
                    ? SENT_BY.ISAAC
                    : SENT_BY.SCHOOL}
                </Text>
              </>
            )}
            {envelopeTemplateReferralId && (
              <>
                <Styled.SecondaryText className="mt-1" variant="overline">
                  Envio do contrato
                </Styled.SecondaryText>
                <Text variant="body-2-regular" id="summary-disable-send-signable-document">
                  {formValues.disable_send_signable_document ? SENT_BY.SCHOOL : SENT_BY.ISAAC}
                </Text>
              </>
            )}
          </div>
        </>
      </Styled.DrawerContent>

      <Styled.SaveContractButtonDocker>
        <Styled.SecondaryText variant="body-2-regular" className="mb-4">
          Após salvar, o produto e as condições de pagamento do contrato não poderão ser editados
        </Styled.SecondaryText>

        <Button
          fullWidth
          loading={isLoading}
          size={2}
          onClick={() => {
            if (formValues.guardian.address.state_code === '') {
              setIsEditing(true)
              toast({
                type: 'error',
                title: 'UF não selecionado',
                description: 'Selecione um UF válido para salvar este contrato.',
              })
            }
            isInitialized &&
              eventDispatcherClient.sendEvent({
                scope: EventDispatcherEventScopes.ADD_NEW_CONTRACT_PAGE,
                name: EnrollmentEventDispatcherEvents.CLICKED,
                action: 'click',
                customProperties: { $Button_name: 'SALVAR' },
              })
          }}
        >
          Salvar
        </Button>
      </Styled.SaveContractButtonDocker>

      {isLoading && (
        <Styled.ContractSummaryProgressText
          variant="subtitle-regular"
          id="summary-contract-creation-progress-caption"
        >
          {contractCreationProgress}
        </Styled.ContractSummaryProgressText>
      )}
    </Drawer>
  )
}

export default ContractSummary
