import { Product } from '@monorepo/onboarding/services/types/onboardingProduct'
import { Student } from '@monorepo/onboarding/services/types/student'
import { Guardian } from '@monorepo/onboarding/services/types/onboardingGuardian'
import { useForm, UseFormProps } from 'react-hook-form'
import { Contract } from '@monorepo/onboarding/services/types/onboardingContract'

type StudentFormFields = Student

type GuardiansFormFields = {
  guardians: Guardian[]
}

type ProductsFormFields = {
  products: Product[]
}

type ContractsFormFields = {
  contracts: Contract[]
}

export type EditStudentFormProps = StudentFormFields &
  GuardiansFormFields &
  ProductsFormFields &
  ContractsFormFields

export const useEditStudentForm = (props?: UseFormProps<EditStudentFormProps>) => {
  return useForm<EditStudentFormProps>(props)
}
