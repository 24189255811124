import {
  INSTALLMENT_STATUSES,
  INSTALLMENT_TYPES,
} from '@/modules/guardians/GuardianDetails/constants'
import { GuardiansInvoicesFilterQueryParams } from './types'

type UseTextFilterOptionsType = {
  label: string
  type: GuardiansInvoicesFilterQueryParams
  value: string
}

/**
 * Custom hook to manage filter options label at Guardian contracts page
 */
export const useTextFilterOptions = ({ value, label, type }: UseTextFilterOptionsType) => {
  const text = () => {
    if (type === 'installment_statuses') {
      const installmentStatusOption = INSTALLMENT_STATUSES.find(
        installmentStatusOption => installmentStatusOption?.value === value
      )
      if (label === installmentStatusOption?.value) return installmentStatusOption.label
    }

    if (type === 'installment_types') {
      const installmentTypeOption = INSTALLMENT_TYPES.find(
        installmentTypeOption => installmentTypeOption?.value === value
      )
      if (label === installmentTypeOption?.value) return installmentTypeOption.label
    }

    return label
  }

  return text()
}
