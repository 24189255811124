import styled from 'styled-components'

export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  gap: ${props => props.theme.gravity.spacing[8]};
`

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const BackButtonWrapper = styled.div`
  margin-top: ${props => props.theme.gravity.spacing[6]};
  margin-bottom: ${props => props.theme.gravity.spacing[2]};
`

export const TitleWrapper = styled.div`
  margin-bottom: ${props => props.theme.gravity.spacing[4]};
`

export const Footer = styled.div`
  margin-top: ${props => props.theme.gravity.spacing[2]};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
