import { Heading } from '@gravity/heading'
import { Container, PageDescription } from './styles'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'

export const PageHeader = () => {
  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  return (
    <Container>
      {renderNewHeader ? (
        <Heading variant="heading-h2-medium">Repasse</Heading>
      ) : (
        <Heading variant="heading-h1-medium">Repasse</Heading>
      )}
      <PageDescription variant="body-1-regular">
        {'Encontre aqui os valores repassados à sua instituição. Apenas usuários autorizados têm acesso. ' +
          'Para liberar informações a outros, entre em contato com o suporte.'}
      </PageDescription>
    </Container>
  )
}
