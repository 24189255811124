import { ComponentProps, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Table } from '@gravity/table'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { DebtStatus } from '@/shared/interfaces'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { useGuardianNavigation } from '@/shared/hooks/useGuardianNavigation'

import { formatStudentsName } from '../utils/formatStudents'
import { ListGuardiansTableProps } from '../types'

export const useListGuardiansTable = ({ sortOrder, setSortOrder }: ListGuardiansTableProps) => {
  const history = useHistory()
  const { schoolSlug } = useSelectedSchool()
  const { defaultGuardianEntryPage } = useGuardianNavigation()

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const debtStatusVariants = {
    WARNING: { color: 'warning', text: 'Atenção' } as const,
    UP_TO_DATE: { color: 'success', text: 'Em dia' } as const,
    PENDING: { color: 'accent', text: 'Aguardando' } as const,
    OVERDUE: { color: 'error', text: 'Com pendências' } as const,
  }

  const columns: Array<ComponentProps<typeof Table.HeaderCell>> = useMemo(
    () => [
      {
        onSort: (_, direction) =>
          setSortOrder && setSortOrder(direction.toUpperCase() as 'ASC' | 'DESC'),
        sortOrder: (sortOrder ?? 'asc').toLowerCase() as 'asc' | 'desc',
        sortable: true,
        children: 'Responsável',
        name: 'name',
        minWidth: 300,
      },
      {
        children: 'CPF',
        name: 'tax_id',
        minWidth: 200,
      },
      {
        children: 'Aluno',
        name: 'students',
      },
      {
        children: 'Situação financeira',
        name: 'debt_status',
        minWidth: 200,
      },
    ],
    [setSortOrder, sortOrder]
  )

  const formatStudentsData = (students: Array<{ name: string }>) => {
    return {
      children: students.length > 1 ? `${students.length} alunos` : `${students.length} aluno`,
      subtext: formatStudentsName(students),
    }
  }

  const formatDebtStatusData = (debtStatus: DebtStatus) => {
    const { color, text } = debtStatusVariants[debtStatus]
    return {
      badgeColor: color,
      children: text,
    }
  }

  const redirectToGuardianPage = (id: string) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.SIDE_MENU,
        name: EventDispatcherEvents.ACCESS_GUARDIAN_PAGE,
        action: 'click',
      })

    history.push(`/${schoolSlug}/responsaveis/${id}/${defaultGuardianEntryPage}`)
  }

  return {
    columns,
    formatStudentsData,
    formatDebtStatusData,
    redirectToGuardianPage,
  }
}
