export enum EventIdentifierName {
  CLOSE_PAYMENT_DISPUTE_ITEM_DENY_MODAL = 'fechar_modal_negar_cobranca_indevida',
  COPY_EXTERNAL_ID = 'copiar_id_externo',
  COPY_LINK_BANK_SLIP_PAYMENT = 'copiar_link_boleto',
  COPY_STUDENT_NAME = 'copiar_nome_aluno',
  DROPDOWN_ACCESS_MANAGEMENT = 'gestão_de_acessos',
  DROPDOWN_LOGOUT = 'sair',
  DROPDOWN_PRIVACY_POLICY = 'política_de_privacidade',
  DROPDOWN_REFERENCE_YEAR = 'dropdown_ano_referencia',
  DROPDOWN_STUDENT_IRREGULARITIES = 'dropdown_pendencias_aluno',
  DROPDOWN_TERMS = 'termos_de_uso',
  FAQ_HEADER_BUTTON = 'central_de_ajuda',
  FILTER_CHIP_CLICKED_IRREGULARITIES = 'filtro_chip_clicado_pendencias',
  FORWARD_PAYMENT_STEP = 'pular_pagamento',
  IRREGULARITIES_PAGE = 'pagina_pendencias_de_registro',
  MARK_AS_FINISHED_IRREGULARITY = 'marcar_como_resolvida_pendencia',
  MARK_AS_FINISHED_IRREGULARITY_CONFIRMATION = 'confirmar_marcar_como_resolvida_pendencia',
  MODAL_CANCEL_NEGOTIATION = 'modal_cancelar_negociacao',
  MODAL_INCOME_TAX_STATEMENTS_CLOSE = 'modal_imposto_renda_fechar',
  MODAL_INCOME_TAX_STATEMENTS_GO_TO_INSTALLMENTS_PAGE = 'modal_imposto_renda_ir_para_faturas',
  MODAL_INCOME_TAX_STATEMENTS_GO_TO_REPORTS_PAGE = 'modal_imposto_renda_ir_para_relatorios',
  MODAL_INCOME_TAX_STATEMENTS_GO_TO_TUTORIAL_PAGE = 'modal_imposto_renda_ir_para_tutorial',
  MODAL_TUTORIAL_ACTIVESOFT = 'modal_tutorial_activesoft',
  NOTIFICATIONS_POPUP = 'popup_de_notificacoes',
  PAYMENT_DISPUTE_DENY = 'negar_cobranca_indevida',
  PAYMENT_DISPUTE_REGISTER_PAYMENT = 'registrar_recebimento_cobranca_indevida',
  PAYMENT_DISPUTES_PAGE = 'pagina_cobrancas_indevidas',
  REDIRECT_ACTIVESOFT_PENDING_ISSUES = 'redirecionar_corrigir_pendencias_activesoft',
  REDIRECT_FIX_PENDING_ISSUES = 'redirecionar_corrigir_pendencias',
  SEARCH_STUDENTS_IRREGULARITIES = 'buscar_alunos_pendencias',
  SEE_PAYMENT_DISPUTE_DETAILS = 'ver_detalhes_cobranca_indevida',
  SEE_PAYMENT_DISPUTE_RECEIPT = 'ver_anexo_cobranca_indevida',
  SEE_PAYMENT_DISPUTES_PAGE = 'ver_cobrancas_indevidas',
  SELECT_PRODUCT_IRREGULARITIES = 'selecionar_produto_pendencias',
  SHOWED_PAYMENT_DISPUTES_CALLOUT = 'exibiu_callout_cobrancas_indevidas',
  SUBMIT_PAYMENT_DISPUTE_ITEM_DENY_MODAL = 'salvar_negacao_cobranca_indevida',
}
