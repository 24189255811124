import { AxiosInstance } from 'axios'
import QueryString from 'qs'

import {
  BulkChangeOwnershipRequest,
  BulkChangeOwnershipResponse,
  PreviewChangeOwnershipRequest,
  PreviewChangeOwnershipResponse,
} from './types'

export const changeOwnershipService = (fetcher: AxiosInstance) => {
  return {
    previewChangeOwnership: async ({
      contract_ids,
    }: PreviewChangeOwnershipRequest): Promise<PreviewChangeOwnershipResponse> => {
      const { data: response } = await fetcher.get<{ data: PreviewChangeOwnershipResponse }>(
        '/contract/preview-change-ownership',
        {
          params: {
            contract_ids,
          },
          paramsSerializer: params => QueryString.stringify(params, { arrayFormat: 'comma' }),
        }
      )

      return response.data
    },
    bulkChangeOwnership: async ({
      contracts,
    }: BulkChangeOwnershipRequest): Promise<BulkChangeOwnershipResponse> => {
      const { data: response } = await fetcher.post<{ data: BulkChangeOwnershipResponse }>(
        '/contract/bulk/change-ownership',
        {
          contracts,
        }
      )

      return response.data
    },
  }
}
