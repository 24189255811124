import { matchPath, Router, useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { initSentry } from '@/shared/integrations/sentry'

import { routes as accountReportRoutes } from '@monorepo/account-reports/routes'
import { routes as contractRoutes } from '@/modules/contract/routes'
import { routes as creditRoutes } from '@monorepo/credit/routes'
import { routes as guardiansRoutes } from '@/modules/guardians/routes'
import { routes as enrollmentRoutes } from '@monorepo/enrollment/routes'
import { routes as managementRoutes } from '@monorepo/management/routes'
import { routes as schoolHomeRoutes } from '@monorepo/school-home/routes'
import { routes as notificationRoutes } from '@monorepo/notification/routes'
import { routes as paymentDisputeRoutes } from '@monorepo/payment-dispute/routes'
import { routes as productsRoutes } from '@monorepo/products/routes'
import { routes as reportRoutes } from '@monorepo/report/routes'
import { routes as generalRoutes } from '@/modules/app/routes'

import config from '@/config'

type SentryWrapperProps = {
  children: JSX.Element
}

const schoolRoutes = [
  ...accountReportRoutes,
  ...contractRoutes,
  ...creditRoutes,
  ...guardiansRoutes,
  ...enrollmentRoutes,
  ...managementRoutes,
  ...notificationRoutes,
  ...paymentDisputeRoutes,
  ...productsRoutes,
  ...reportRoutes,
  ...schoolHomeRoutes,
]
  .filter(route => !route.redirect)
  .reverse()
  .map(route => ({ path: `/:schoolSlug${route.path}` }))

const routes = [...schoolRoutes, ...generalRoutes.map(route => ({ path: route.path }))]

export const SentryWrapper = ({ children }: SentryWrapperProps) => {
  const history = useHistory()

  if (config.SENTRY_ENABLED) {
    initSentry({
      integrations: [
        Sentry.reactRouterV5BrowserTracingIntegration({
          history,
          routes,
          matchPath,
        }),
      ],
    })
  }

  return <Router history={history}>{children}</Router>
}
