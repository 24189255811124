import { Receivable } from '@/shared/interfaces'
import { Filter } from '@monorepo/report/hooks/useIsaacPayPayoutReportFilter/types'
import { FilterOptions } from '@monorepo/report/models/report'

/**
 * Utility function to parse filter options for isaacPay external payment report page
 *
 * @param data Filter options
 *
 * @returns Parsed filter options
 */
export const buildExternalPaymentsReportFilterOptions = (
  data?: Receivable[]
): FilterOptions<Filter> => {
  if (data) {
    const uniqueProductLabels = Array.from(
      new Set(data.map(item => item?.installment?.contract?.product?.name || ''))
    ).filter(Boolean)

    const products = uniqueProductLabels.map(label => ({
      label: label,
      value: label,
    }))

    return {
      events: [],
      products,
      amountSigns: [],
    }
  }

  return {
    events: [],
    products: [],
    amountSigns: [],
  }
}
