import { useMemo } from 'react'
import dayjs from 'dayjs'
import { DatepickerPrimitives as Datepicker } from '@gravity/datepicker'

import { Container, StyledDatepickerTrigger } from './styles'
import { CalendarEventOutline } from '@gravity/icons'

export type DetailType = 'month' | 'year' | 'decade'
export type View = 'century' | 'decade' | 'year' | 'month'

export type TileDisabledFunctionArgs = {
  activeStartDate: Date
  date: Date
  view: View
}

type DatePickerProps = {
  maxDate?: Date
  maxDetail?: DetailType
  minDate?: Date
  minDetail?: DetailType
  onChange: (date: Date) => void
  // TODO: Add TileDisabledFunc type from react-calendar in @gravity/datepicker
  tileDisabled: (args: TileDisabledFunctionArgs) => boolean
  value?: Date
  valueTemplate?: string
  variation?: 'standard' | 'ghost'
}

/**
 * Componente personalizado para seleção de datas usando gravity/datepicker
 *
 * @param props
 * @param props.variation Variação do gatilho do seletor
 * @param props.maxDate Data máxima permitida
 * @param props.minDate Data mínima permitida
 * @param props.maxDetail Define o nível máximo de detalhe da data
 * @param props.minDetail Define o nível mínimo de detalhe da data
 * @param props.value Valor da data
 * @param props.tileDisabled Função que permite desabilitar datas específicas
 * @param props.onChange Função de callback para mudança de valor
 */
export const DatePicker = ({
  value,
  onChange,
  valueTemplate = 'MMMM [de] YYYY',
  variation = 'standard',
  maxDate,
  minDate,
  maxDetail = 'year',
  tileDisabled,
}: DatePickerProps) => {
  const dateText = useMemo(() => {
    if (!value) {
      return 'Selecione uma data'
    }

    return dayjs(value).format(valueTemplate)
  }, [value, valueTemplate])

  return (
    <Container>
      <Datepicker.Root>
        {variation === 'standard' ? (
          <>
            <StyledDatepickerTrigger size={3} variant="surface" placeholder="Selecione uma data">
              {dateText}
              <CalendarEventOutline />
            </StyledDatepickerTrigger>
          </>
        ) : (
          <>
            <StyledDatepickerTrigger size={3} variant="surface" placeholder="Selecione uma data">
              {dateText}
              <CalendarEventOutline />
            </StyledDatepickerTrigger>
          </>
        )}
        <Datepicker.Calendar
          value={value}
          maxDetail={maxDetail}
          minDate={minDate}
          maxDate={maxDate}
          onChange={date => {
            onChange(date as Date)
          }}
          tileDisabled={tileDisabled}
        />
      </Datepicker.Root>
    </Container>
  )
}
