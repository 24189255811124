import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Header as DSHeader } from '@gravity/header'
import { IconButton } from '@gravity/icon-button'

import { Badge } from '@gravity/badge'
import { QuestionOutline, Notification } from '@gravity/icons'

import { useEventDispatcher } from '@/shared/hooks/useEventDispatcher'

import { AvatarDropdown } from '../AvatarDropdown'
import { NotificationDialog } from '../../../Header/components/NotificationsDialog'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventIdentifierName } from '@/shared/models/enums/EventIdentifierName.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { useGravityTheme } from '@/shared/hooks/useGravityTheme'

import { SwitchTheme } from '../../../SwitchTheme'
import { DSHighlighter } from '../../../DSHighlighter'

import config from '@/config'
export const FAQ_URL = 'https://centraldeajuda.olaisaac.io'

/**
 * Standard platform header
 */
export const Header = () => {
  const location = useLocation()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { updateGravityTheme, gravityTheme } = useGravityTheme()

  const [isNotificationDialogOpen, setIsNotificationDialogOpen] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)

  const isDevEnv = config.ENV === 'development'

  const { school } = useSelectedSchool()

  useEffect(() => {
    const handleScroll = () => {
      const HEADER_HEIGHT = 56

      setIsScrolled(window.scrollY >= HEADER_HEIGHT)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleOpenNotificationDialog = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.HEADER_MENU,
        identifierName: EventIdentifierName.NOTIFICATIONS_POPUP,
      })

    setIsNotificationDialogOpen(true)
  }

  const handleNavigateToFaq = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.HEADER_MENU,
        identifierName: EventIdentifierName.FAQ_HEADER_BUTTON,
      })

    window.open(FAQ_URL, '_blank')
  }

  const isNotificationsRoute = location.pathname.includes('/avisos')

  return (
    <DSHeader.Root state={isScrolled ? 'scroll' : 'default'}>
      <DSHeader.Navigation />
      <DSHeader.Actions>
        {school?.is_staged && (
          <Badge variant="soft" color="accent" data-testid="show-demo-badge">
            Demonstração
          </Badge>
        )}

        {isDevEnv && (
          <SwitchTheme updateGravityTheme={updateGravityTheme} gravityTheme={gravityTheme} />
        )}

        {/* remover após tombamento NGV */}
        <DSHighlighter />
        {/* remover após tombamento NGV */}

        <IconButton
          id="beamerButton"
          variant="ghost"
          size={1}
          data-beamer-click="false"
          aria-label="Visualizar menu de avisos"
          disabled={isNotificationsRoute}
          onClick={handleOpenNotificationDialog}
          data-testid="notification-button"
        >
          <Notification />
        </IconButton>

        <IconButton variant="ghost" size={1} onClick={handleNavigateToFaq} data-testid="faq-button">
          <QuestionOutline />
        </IconButton>

        <AvatarDropdown />
      </DSHeader.Actions>

      <NotificationDialog
        isOpen={isNotificationDialogOpen}
        onClose={() => setIsNotificationDialogOpen(false)}
      />
    </DSHeader.Root>
  )
}
