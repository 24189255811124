import type { ReactNode } from 'react'
import { ContainerTuitionHeader, LabelActualizationPeriod } from './styles'
import { formatDate } from '@/shared/utils'
import { Link } from '@gravity/link'
import { Heading } from '@gravity/heading'
import { SchoolReportingPeriod } from '@/shared/models/SchoolChargeOperation'
import { Select } from '@gravity/select'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'

type PayoutSubHeaderProps = {
  currentReportingPeriodView?: string
  isHourIncluded?: boolean
  linkButtonText: string
  onChangeReportingPeriod?: (reportingPeriod: SchoolReportingPeriod) => void
  onClickAtLinkButton: () => void
  payoutReportPeriod: {
    endDate?: Date
    startDate?: Date
  }
  picker: ReactNode
  shouldShowToggle?: boolean
  title: string
}

/**
 * Component for payout report page header
 *
 * @param props
 * @param props.picker Custom component to be displayed as a picker
 * @param props.title Header title
 * @param props.isLoading Indicates if loading state should be displayed for the picker
 * @param props.shouldShowToggle Boolean value that indicates the rendering of calendar toggle
 * @param props.onChangeReportingPeriod Callback function called on change payout
 */
export const PayoutSubHeader = ({
  title,
  picker,
  shouldShowToggle,
  currentReportingPeriodView,
  onChangeReportingPeriod,
  payoutReportPeriod,
  isHourIncluded,
  linkButtonText,
  onClickAtLinkButton,
}: PayoutSubHeaderProps) => {
  const startDateTemplate = isHourIncluded ? 'D [de] MMMM[,] HH[h]mm' : 'D [de] MMMM'
  const endDateTemplate = isHourIncluded ? 'D [de] MMMM[,] HH[h]mm' : 'D [de] MMMM [de] YYYY'

  const renderNewHeader = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_SIDEMENU)

  const formattedPayoutReportStartPeriodDate =
    payoutReportPeriod?.startDate && formatDate(payoutReportPeriod.startDate, startDateTemplate)

  const formattedPayoutReportEndPeriodDate =
    payoutReportPeriod?.endDate && formatDate(payoutReportPeriod.endDate, endDateTemplate)

  const VIEW_RANGE_OPTIONS = [
    { label: 'Diário', value: SchoolReportingPeriod.daily, disabled: false },
    {
      label: 'Mensal',
      value: SchoolReportingPeriod.monthly,
      disabled: false,
    },
  ]

  const onReportingPeriodSelect = (value: string) => {
    if (!onChangeReportingPeriod) return

    const selectedReportingPeriod = VIEW_RANGE_OPTIONS.find(option => option.value === value)

    if (selectedReportingPeriod) {
      onChangeReportingPeriod(selectedReportingPeriod.value)
    }
  }

  return (
    <ContainerTuitionHeader>
      <div className="flex justify-between items-end">
        <div className="flex items-center gap-4">
          {renderNewHeader ? (
            <Heading variant="heading-h2-medium">{title}</Heading>
          ) : (
            <Heading variant="heading-h1-medium">{title}</Heading>
          )}

          {shouldShowToggle && (
            <div className="flex justify-center">
              <Select
                value={VIEW_RANGE_OPTIONS.find(o => o.value === currentReportingPeriodView)?.value}
                size={3}
                variant="surface"
                onValueChange={onReportingPeriodSelect}
                defaultValue={VIEW_RANGE_OPTIONS[0].value}
                options={VIEW_RANGE_OPTIONS}
              />
            </div>
          )}

          <div>{picker}</div>
        </div>
      </div>

      <div className="flex flex-col">
        {formattedPayoutReportStartPeriodDate && formattedPayoutReportEndPeriodDate && (
          <LabelActualizationPeriod variant="subtitle-regular">
            Período de atualizações:&nbsp;
            {`${formattedPayoutReportStartPeriodDate?.toLowerCase()} até ${formattedPayoutReportEndPeriodDate?.toLowerCase()}`}
          </LabelActualizationPeriod>
        )}

        <Link className="w-fit" underline="none" size={1} onClick={onClickAtLinkButton}>
          {linkButtonText}
        </Link>
      </div>
    </ContainerTuitionHeader>
  )
}
