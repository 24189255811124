import { useEffect, useState } from 'react'
import { Table } from '@gravity/table'

import { StatusBadge } from '@monorepo/enrollment/components/StatusBadge'
import type { EnrollmentCycleResponse } from '@monorepo/enrollment/services/enrollmentService/types'
import { EnrollmentTableProducts } from '@monorepo/enrollment/pages/SchoolEnrollmentCycle/components/EnrollmentTableProducts'
import useAddContractEnabled from '@monorepo/enrollment/hooks/useAddContractEnabled'
import type { PaginationType } from '@/shared/hooks/usePagination/types'
import type { TableFilters } from '@monorepo/enrollment/pages/SchoolEnrollmentCycle/types'

import { useTableData } from './hooks/useTableData'
import { useTableActions } from './hooks/useTableActions'
import { EnrollmentTableActions } from '../EnrollmentTableActions'
import { isLegacyYear } from '@monorepo/enrollment/hooks/useInitialSupportedCycleYear'

interface TableProps {
  enrollmentsData?: EnrollmentCycleResponse
  enrollmentYear: number
  filtersArray: TableFilters[]
  handleOpenFilter: () => void
  handleRemoveFilter: (filter: TableFilters) => void
  isLoading: boolean
  pagination: PaginationType
  updatePaginationValue: (key: keyof PaginationType, value: number, replaceRoute?: boolean) => void
}

export const EnrollmentTable = ({
  enrollmentsData,
  isLoading,
  updatePaginationValue,
  pagination,
  handleOpenFilter,
  handleRemoveFilter,
  enrollmentYear,
  filtersArray,
}: TableProps) => {
  const addContractEnabled = useAddContractEnabled()
  const isLegacy = isLegacyYear()

  const { handleRowClick } = useTableActions()

  const [refreshedEnrollments, setRefreshedEnrollments] = useState(enrollmentsData?.data ?? [])

  const { rows, columnHeaders } = useTableData(refreshedEnrollments)

  useEffect(() => {
    if (enrollmentsData?.data) setRefreshedEnrollments(enrollmentsData?.data)
  }, [enrollmentsData?.data])

  return (
    <Table.Root>
      {!isLegacy && (
        <Table.Filtering
          filters={filtersArray}
          onClickFilterButton={handleOpenFilter}
          onRemoveFilter={handleRemoveFilter}
        />
      )}
      <Table.Head>
        {columnHeaders.map(({ label, key, minWidth }) => (
          <Table.HeaderCell key={key} name={key} style={{ minWidth }}>
            {label}
          </Table.HeaderCell>
        ))}
      </Table.Head>
      <Table.Body>
        {isLoading ? (
          <>
            <Table.Row>
              <Table.LoadingCell />
              <Table.LoadingCell />
              <Table.LoadingCell />
              <Table.LoadingCell />
            </Table.Row>
            <Table.Row>
              <Table.LoadingCell />
              <Table.LoadingCell />
              <Table.LoadingCell />
              <Table.LoadingCell />
            </Table.Row>
            <Table.Row>
              <Table.LoadingCell />
              <Table.LoadingCell />
              <Table.LoadingCell />
              <Table.LoadingCell />
            </Table.Row>
          </>
        ) : !enrollmentsData?.data?.length && !isLoading ? (
          <Table.Row>
            <Table.CustomCell>Nenhum aluno encontrado</Table.CustomCell>
          </Table.Row>
        ) : (
          rows.map((row, index) => (
            <Table.Row
              key={index}
              onClick={() => {
                handleRowClick(row.studentID, row.columns.student_status)
              }}
            >
              <Table.TextCell>{row.columns.student_name}</Table.TextCell>
              <Table.TextCell>{row.columns.guardian_name}</Table.TextCell>
              <Table.CustomCell>
                <EnrollmentTableProducts
                  mainProductName={row.columns.product_name}
                  otherProductsNames={row.otherProducts}
                />
              </Table.CustomCell>

              {!isLegacy && (
                <Table.CustomCell>
                  <StatusBadge status={row.columns.student_status} />
                </Table.CustomCell>
              )}

              {!isLegacy && (
                <EnrollmentTableActions
                  rowValue={row}
                  enrollmentYear={enrollmentYear}
                  setRefreshedEnrollments={setRefreshedEnrollments}
                  refreshedEnrollments={refreshedEnrollments}
                  addContractEnabled={addContractEnabled}
                />
              )}
            </Table.Row>
          ))
        )}
      </Table.Body>
      {enrollmentsData?.pagination?.total && (
        <Table.Pagination
          page={pagination.page}
          itemsPerPage={pagination.itemsPerPage}
          total={enrollmentsData?.pagination.total}
          onChangePage={newPage => {
            updatePaginationValue('page', newPage, true)
            window.scrollTo(0, 0)
          }}
          onChangeItemsPerPage={newItensPerPage => {
            updatePaginationValue('itemsPerPage', newItensPerPage, true)
          }}
        />
      )}
    </Table.Root>
  )
}
